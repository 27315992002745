import React from 'react';
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {SingleStuntingDistrEditor} from "../../data/HealthStatus/NutritionalStatus/SingleStuntingDistrEditor";
import {SingleWastingDistrEditor} from "../../data/HealthStatus/NutritionalStatus/SingleWastingDistrEditor";

class CSSingleNutStatusDistDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        firstYear        : PropTypes.number,
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        onApply          : PropTypes.func.isRequired,
        onClose          : PropTypes.func.isRequired,
        onOpenHelpDialog : PropTypes.func.isRequired,
        onStateChange    : PropTypes.func.isRequired,
    };

    static defaultProps = {
        firstYear         : 1,
        CSModvars       : [],
    };

    state = {
        saveBtnDisabled  : false, //true,
        packTable1       : null,
        packTable3       : null,
        CSModvars        : []
    };

    componentDidMount() {   
        const { CSModvars, firstYear } = this.props;

        this.setState({
            CSModvars  : CSModvars,
            packTable1 : SingleStuntingDistrEditor.Create(CSModvars, firstYear,0)[CONSTANTS.CS_PackTable],
            packTable3 : SingleWastingDistrEditor.Create(CSModvars, firstYear, 0)[CONSTANTS.CS_PackTable],
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    _onPackTable1Change = (packTable1) => {
        this.setState({
            packTable1      : packTable1,
            saveBtnDisabled : false,
        });
    };

    _onPackTable3Change = (packTable3) => {
        this.setState({
            packTable3      : packTable3,
            saveBtnDisabled : false,
        });
    };

    _onApply = () => {
        const { CSModvars, packTable1, packTable3 } = this.state;

        let CSModvarsClone;
        CSModvarsClone = SingleStuntingDistrEditor.GetSSData(packTable1, CSModvars);
        CSModvarsClone = SingleWastingDistrEditor.GetSSData(packTable3, CSModvarsClone);

        // this.props.onStateChange({
        //     unchangedProj : false,
        //     CSModvars : CSModvarsClone,
        // }, () => {
            this.props.onApply(CSModvarsClone);
        // });
    };

    _onClose = () => {
        this.props.onClose();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.state.packTable1));
        let packTable3 = JSON.parse(JSON.stringify(this.state.packTable3));

        return (
            <TDrawerWithHeader
                open={true}
                onClose={this._onClose}
                onSave={this._onApply}
                saveBtnCaption={RS('GB_stApply')}
                saveBtnDisabled={this.state.saveBtnDisabled}
                onOpenHelpDialog={this.props.onOpenHelpDialog}
                helpFile={"nutritional_status_distributions.htm"}
                appBarText={RS('GB_stNutritionStatusDist') + " - " + RS('GB_stSingleIndicator')}
                content={
                    <div className="innerPage">
                        <EditorNote
                            numberOfTables={2}
                        />

                        <CSEditor
                            mstID={CONSTANTS.CS_MstSingleStuntingDistrSheetID}
                            packTable={packTable1}
                            showTitle={true}
                            onChange={this._onPackTable1Change}
                        />

                        <CSEditor
                            mstID={CONSTANTS.CS_MstSingleWastingDistrSheetID}
                            packTable={packTable3}
                            showTitle={true}
                            onChange={this._onPackTable3Change}
                        />
                    </div>
                }
            />
        );
    }

}

export default CSSingleNutStatusDistDrawer;