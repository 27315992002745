import React from 'react';
import TRangeSlider from "@common/components/TRangeSlider";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

const DisplaySlider = (props) => {
    const { firstYear, finalYear, onStateChange } = props;
    const { firstYearIdx, finalYearIdx } = props;
    
    const [_firstYearIdx, setFirstYearIdx] = React.useState(firstYearIdx);
    const [_finalYearIdx, setFinalYearIdx] = React.useState(finalYearIdx);

    React.useEffect(() => {
        setFirstYearIdx(firstYearIdx);
        setFinalYearIdx(finalYearIdx);
    }, [firstYearIdx, finalYearIdx]);

    const onYearChangeComplete = (onStateChange) => () => {
        onStateChange({
            chartYears: {
                firstYrIdx : _firstYearIdx,
                finalYrIdx : _finalYearIdx
            }
        });
    };

    const onYearChange = (value) => {
        setFirstYearIdx(value.min - firstYear + CONSTANTS.CS_DefaultFirstYearIdx);
        setFinalYearIdx(value.max - firstYear + CONSTANTS.CS_DefaultFirstYearIdx);
    };

    let yearRange = { 
        min: firstYear + _firstYearIdx - CONSTANTS.CS_DefaultFirstYearIdx,
        max: firstYear + _finalYearIdx - CONSTANTS.CS_DefaultFirstYearIdx,
    };

    return (
        <div style={{marginBottom: '35px', width: '600px', maxWidth: "100%", margin: 'auto'}}>
            <TRangeSlider 
                style            = {{marginBottom: '20px', color: 'red'}}
                minValue         = {firstYear}
                maxValue         = {finalYear}
                minTextLabel     = {RS('GB_stFirstYear')}
                maxTextLabel     = {RS('GB_stFinalYear')}
                value            = {yearRange}
                onChange         = {onYearChange}
                onChangeComplete = {onYearChangeComplete(onStateChange)}
            />
        </div>
    );
};

export default DisplaySlider;