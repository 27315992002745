class TextMetrics {
    constructor() {
        this.context = document.createElement("canvas").getContext("2d");
    }

    getMetrics = (text, font) => {
        this.context.font = font;

        return {
            width: this.context.measureText(text).width,
            height: parseInt(this.context.font, 10)
        };
    };
}

export default TextMetrics;
