import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import TTabs2 from "@common/components/TTabs2";
import {chooseTabbedChart} from "../../../../../../data/CSUtil";

class TabbedCharts extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        id             : PropTypes.string,
        chartTitle     : PropTypes.string,
        chartType      : PropTypes.string,
        display        : PropTypes.array,
        tabs           : PropTypes.array,
        options        : PropTypes.object,
        stacked        : PropTypes.string,
        legendReversed : PropTypes.bool,
        packTable      : PropTypes.object,
        tableRef       : PropTypes.any,
        language       : PropTypes.number
    };

    static defaultProps = {
        id             : '',
        chartTitle     : '',
        chartType      : '',
        display        : [],
        tabs           : [],
        stacked        : '',
        options        : {},
        legendReversed : false,
        packTable      : {},
        tableRef       : null,
        language       : CONSTANTS.GB_English
    };

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if ((JSON.stringify(this.props.display) !== JSON.stringify(nextProps.display))) {
    //         return true;
    //     }
    //     else if (this.props.language !== nextProps.language) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderChart = (age) => {
        return chooseTabbedChart({
            age: age,
            id: this.props.id,
            packTable: this.props.packTable,
            chartTitle: this.props.chartTitle,
            chartType: this.props.chartType,
            options: this.props.options,
            stacked: this.props.stacked,
            legendReversed: this.props.legendReversed,
            tableRef: this.props.tableRef,
            choice: this.props.display[this.props.display.length - 1]
        });
    };

    renderTitle = () => {
        const styles = {
            chartTitle: {
                fontSize: 18,
                marginBottom: 15,
            }
        };
        return (
            <p style={styles.chartTitle}>{this.props.chartTitle}</p>
        )
    };

    renderTabs = () => {
        let counter = 0;
        let tabContents = [];
        let t = this;
        this.props.tabs.forEach(function(tab) {
            tabContents.push(t.renderChart(counter));
            counter++;
        });

        const styles = {
            tabSelectedStyle : {
                color: '#f78c30',
                fontWeight: 'bold'
            },
            tabRootStyle : {
                fontSize: '18px'
            },
            tabContentStyle : {
                // marginTop: '185px'
            },
            tabAppBarStyle : {
                position: "static",
            }
        };

        return (
            <div styles={{marginTop: '20px'}}>
                <TTabs2
                    tabNames={this.props.tabs}
                    tabContents={tabContents}
                    tabAppBarStyle={styles.tabAppBarStyle}
                    tabSelectedStyle={styles.tabSelectedStyle}
                    tabRootStyle={styles.tabRootStyle}
                    tabContentStyle={styles.tabContentStyle}
                />
            </div>
        );
    };


    render() {
        //this will need to be modified to accommodate more than just Effectiveness of Interventions, it will need to be used
        //for Effectiveness on incidence as well. There will need to be a renderEffectivenessOnIncidenceTabs() and
        //renderEffectivenessOnIncidenceChart with the appropriate age (which represents the column for Effectiveness in the table
        return (
            <div>
                {this.renderTitle()}
                {this.renderTabs()}
            </div>

        );

    }
}

export default TabbedCharts;