import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import YesButton from "../buttons/YesButton";
import NoButton from "../buttons/NoButton";

class FileAlreadyExistsDialog extends React.Component {
    static propTypes = {
        onStateChange    : PropTypes.func,
        onSaveProjection : PropTypes.func,
        prevProjName     : PropTypes.string
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        onSaveProjection : () => console.log('onSaveProjection'),
        prevProjName     : ""
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    onSaveProjectionClick = () => {
        this.props.onStateChange({
            newFile: false,
            dialogSaveAsOpen: false,
            dialogFileAlreadyExistsOpen: false
        }, () => this.props.onSaveProjection());
    };

    onClose = () => {
        this.props.onStateChange({
            dialogFileAlreadyExistsOpen: false
        });
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stAlreadyExists')}
                onClose={this.onClose}
                content={
                    <div>
                        {RS("GB_stOverwrite")}
                    </div>
                }
                actions={
                    <div>
                        <YesButton onClick={this.onSaveProjectionClick} />
                        <NoButton colorType='secondary' onClick={this.onClose} />
                    </div>
                }
            />
        );
    }
}

export default FileAlreadyExistsDialog;