import React from 'react';
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import RS from "@common/strings/global";

let styles = {
    progress: {
        marginBottom: 25
    }
};

class Progress extends React.Component {

    static propTypes = {
        projectionsSelected : PropTypes.array,
        proj                : PropTypes.number,
        indicatorsSelected  : PropTypes.array,
        ind                 : PropTypes.number,
        fileName            : PropTypes.string,
        done                : PropTypes.bool,
    };

    static defaultProps = {
        projectionsSelected : ["Proj1", "Proj2", "Proj3"],
        proj                : 3,
        indicatorsSelected  : ["Ind1", "Ind2", "Ind3", "Ind4", "Ind5", "Ind6"],
        ind                 : 3,
        fileName            : "Test",
        done                : false,
    };

    render() {
        const { projectionsSelected, proj, indicatorsSelected, ind, done } = this.props;

        const projName = (done) ? "" : (proj > 0) ? projectionsSelected[proj-1] : "";
        const projTotal = projectionsSelected.length;
        const projProgress = parseInt(((proj / projTotal) * 100).toFixed(0));

        const indName = (done) ? "" : (ind > 0) ? indicatorsSelected[ind-1][2] : "";
        const indTotal = indicatorsSelected.length;
        const indProgress = parseInt(((ind / indTotal) * 100).toFixed(0));

        const maxProgress = projTotal * indTotal;
        const currentProgress = ((proj - 1) * indTotal) + ind;
        const overallProgress = parseInt(((currentProgress / maxProgress) * 100).toFixed(0));

        return (
            <div style={styles.progress}>
                <p>{`${RS("DP_stProjection")} : ${projName} (${proj}/${projTotal})`}</p>
                <LinearProgress
                    variant={"determinate"}
                    value={projProgress}
                />

                <p>{`${RS("GB_stIndicator")} : ${indName} (${ind}/${indTotal})`}</p>
                <LinearProgress
                    variant={"determinate"}
                    value={indProgress}
                />

                <p>{`${RS("GB_stOverall")} : ${overallProgress}%`}</p>
                <LinearProgress
                    variant={"determinate"}
                    value={overallProgress}
                />
            </div>
        );
    }
}

export default Progress;