import React from 'react';
import BannerImage from '../../img/sunset.PNG';

const Banner = (props) => {
    let { isDesktop } = props;

    let styles = {
        imgDiv :{
            position: 'fixed',
            top: '-50%',
            left: '-50%',
            width: '200%',
            height: '200%',
            zIndex: -999
        },

        img : {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            minWidth: '50%',
            minHeight: '50%',
        }
    };

    if (isDesktop) {
        return (
            <div style={styles.imgDiv}>
                <img style={styles.img} src={BannerImage} alt='' />
            </div>
        );
    }
    else {
        return null;
    }
};

export default Banner;