import React, { useState } from "react";
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import {getModvarByTag} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";
import CSWastingDrawer from "../../drawers/Coverage/CSWastingDrawer";

const CSWastingBlueBox = (props) => {
    const { CSModvars, firstYear, captionStyle, onOpenHelpDialog, onStateChange } = props; 
    const [openDrawer, setOpenDrawer] = useState(false);

    const _onOpenDrawer = () => setOpenDrawer(true);
    const _onCloseDrawer = () => setOpenDrawer(false);

    return (
        <React.Fragment>
            <CSBlueBox2
                caption      = {RS('GB_stWasting')}
                captionStyle = {captionStyle}
                description  = {""}
                onClick      = {_onOpenDrawer}
            />

            {
                (openDrawer) ?
                    <CSWastingDrawer
                        CSModvars={CSModvars}
                        firstYear={firstYear}
                        finalYear={getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"]}
                        onClose={_onCloseDrawer}
                        onOpenHelpDialog={onOpenHelpDialog}
                        onStateChange={onStateChange}
                    />
                    : null
            }
        </React.Fragment>
    );
};

CSWastingBlueBox.propTypes = {
    CSModvars        : PropTypes.arrayOf(PropTypes.object),
    captionStyle     : PropTypes.object,
    firstYear        : PropTypes.number,
    onOpenHelpDialog : PropTypes.func,
    onStateChange    : PropTypes.func,
};

export default CSWastingBlueBox;