import React from "react";
import PropTypes from "prop-types";
import SunriseImage from '../../img/sunrise.jpg';

class HomeBannerImage extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isDeskTop : PropTypes.bool,
    };

    static defaultProps = {
        isDeskTop : true,
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    renderBannerImage = () => {

        let styles = {
            imgDiv :{
                position: 'fixed',
                top: '-30%',
                left: '-50%',
                width: '200%',
                height: '200%',
                zIndex: -999
            },

            img : {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 'auto',
                minWidth: '50%',
                minHeight: '50%',
            }
        };

        return (
            <div style={styles.imgDiv}>
                <img  style={styles.img} src={SunriseImage} alt='' />
            </div>
        );
    };

    render() {
        return (
            <div>
                {
                    (this.props.isDeskTop) ?
                        this.renderBannerImage() :
                        null
                }
            </div>
        );
    }

}

export default HomeBannerImage;