import React from 'react';
import PropTypes from "prop-types";
import TButton from "@common/components/TButton";

class ServerStatusButton extends React.Component {
    static propTypes = {
        onStateChange     : PropTypes.func
    };

    static defaultProps = {
        onStateChange     : () => console.log("onStateChange")
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        return (
            <TButton
                caption={"SpectrumWeb status"}
                onClick={() => this.props.onStateChange({dialogServerAboutOpen : true})}
                containerStyle={{float: "right"}}
                color={"secondary"}
            />
        );
    }
}

export default ServerStatusButton;