import React from 'react';
import PropTypes from "prop-types";
import TLabel from "@common/components/TLabel";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import YourResults from './YourResults';
import ResultCheckBoxes from './ResultCheckBoxes';

const styles = {
    nextButton: {
        fontSize: '16px',
        marginTop: '30px',
        marginBottom: '30px',
        marginRight: '25px'
    },

    labelStyle: {
        color: '#0c3a79',
        fontSize: '13px',
        lineHeight : '1',
        paddingBottom: '10px'
    },
};

const DPResults = new Set([CONSTANTS.DP_MstcmTotalPop, CONSTANTS.DP_MstcmPop04, CONSTANTS.DP_MstcmDispTFR, CONSTANTS.DP_MstcmBirths, CONSTANTS.DP_MstcmTotalLE]);
const AMResults = new Set([CONSTANTS.DP_MstcmNumNewInf, CONSTANTS.DP_MstcmAIDSDeaths]);

class ManageResultsContent extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        resultMenus             : PropTypes.object,
        selectedResults         : PropTypes.array,
        includeFamPlan          : PropTypes.bool,
        onSelectedResultsChange : PropTypes.func,
        availableResults        : PropTypes.array,
        language                : PropTypes.number,
        approach                : PropTypes.number
    };

    static defaultProps = {
        resultMenus             : {},
        selectedResults         : [],
        includeFamPlan          : false,
        onSelectedResultsChange : () => console.log('CSSelectResultsDrawer: onSelectedResultsChange'),
        availableResults        : [],
        language                : CONSTANTS.GB_English,
        approach                : CONSTANTS.CS_StandardApproach
    };

    state = {
        resultGroupList         : [],
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onSelectedResultsChange = (value) => {
        this.props.onSelectedResultsChange(value);
    };

    onCheckBoxChange = (checked, modID, mstID) => {
        let x = JSON.parse(JSON.stringify(this.props.selectedResults));

        let inList = false;
        let index = 0;

        let userResults = [];

        for (let i = 0; i < x.length; i++) {
            if ((x[i][0] === mstID) && (x[i][1] === modID)) {
                inList = true;
                index = i;
            }
        }

        if (inList) {
            x.splice(index, 1);
        } else {
            x.push([mstID,modID]);
        }

        //works for LiST results, when DP and AIM are added, may need to add a dimension for checking (module#)
        for (let i = 0; i < this.props.availableResults.length; i++) {
            for (let j = 0; j < x.length; j++) {
                if ((this.props.availableResults[i]['mstID'] === x[j][0]) && (this.props.availableResults[i]['modID'] === x[j][1])) {
                    userResults.push(x[j])
                }
            }
        }

        this.onSelectedResultsChange(userResults);
    };

    onClearResultsClick = () => {
        let x = JSON.parse(JSON.stringify(this.props.selectedResults));

        x.splice(0, x.length);

        this.onSelectedResultsChange(x);
    };

    //==================================================================================================================
    //
    //                                              Functions
    //
    //==================================================================================================================

    getResultLists = () => {
        let resultLists = [];
        let resultsByGroup = [];
        let menu = this.props.resultMenus;
        let language = this.props.language;

        if (menu !== null) {
            for (let i = 0; i < menu["TreeCS"].items.length; i++) {
                resultsByGroup = [];
                for (let j = 0; j < menu["TreeCS"].items[i].items.length; j++) {
                    resultsByGroup.push([
                        menu["TreeCS"].items[i].items[j].Names[language],
                        menu["TreeCS"].items[i].items[j].indID,
                        CONSTANTS.GB_CS
                    ]);
                }
                resultLists.push(resultsByGroup);
            }

            resultsByGroup = [];
            for (let i = 0; i < menu["TreeDP"].items.length; i++) {
                for (let j = 0; j < menu["TreeDP"].items[i].items.length; j++) {
                    if (DPResults.has(menu["TreeDP"].items[i].items[j].indID)) {
                        resultsByGroup.push([
                            menu["TreeDP"].items[i].items[j].Names[language],
                            menu["TreeDP"].items[i].items[j].indID,
                            CONSTANTS.GB_DP
                        ]);
                    }
                }
            }
            resultLists.push(resultsByGroup);

            resultsByGroup = [];
            for (let i = 0; i < menu["TreeAM"].items.length; i++) {
                for (let j = 0; j < menu["TreeAM"].items[i].items.length; j++) {
                    if (AMResults.has(menu["TreeAM"].items[i].items[j].indID)) {
                        resultsByGroup.push([
                            menu["TreeAM"].items[i].items[j].Names[language],
                            menu["TreeAM"].items[i].items[j].indID,
                            CONSTANTS.GB_AM
                        ]);
                    }
                }
            }
            resultLists.push(resultsByGroup);

            for (let i = 0; i < menu["TreeFP"].items.length; i++) {
                resultsByGroup = [];
                for (let j = 0; j < menu["TreeFP"].items[i].items.length; j++) {
                    resultsByGroup.push([
                        menu["TreeFP"].items[i].items[j].Names[language],
                        menu["TreeFP"].items[i].items[j].indID,
                        CONSTANTS.GB_FP
                    ]);
                }
                resultLists.push(resultsByGroup);
            }
        }

        return resultLists;
    };

    getResultGroupNames = () => {
        const showSwitch = true;
        const switchChecked = true;
        const showMoreInfoIcon = true;
        let menu = this.props.resultMenus;
        let language = this.props.language;

        let groupNames = [];

        if (menu !== null) {
            for (let i = 0; i < menu["TreeCS"].items.length; i++)
                groupNames.push([
                    menu["TreeCS"].items[i].sectID, 
                    menu["TreeCS"].items[i].Names[language],
                    !showSwitch, 
                    '', 
                    !switchChecked, 
                    !showMoreInfoIcon
                ]);

            groupNames.push([0, RS('GB_stDemography'),!showSwitch,'', !switchChecked, !showMoreInfoIcon]);
            groupNames.push([0, RS('GB_stHIVAIM'),!showSwitch,'', !switchChecked, !showMoreInfoIcon]);
            groupNames.push([0, RS('GB_stFamPlan'),!showSwitch,'', !switchChecked, !showMoreInfoIcon]);
            // if (this.props.includeFamPlan) {
            //     groupNames.push("Fertility");
            // }
            // groupNames.push([0,"HIV/AIDS",!showSwitch,'', !switchChecked, !showMoreInfoIcon])
        }

        return groupNames;
    };

    getYourResultNames = () => {
        let yourResultNames = [];
        let menu = this.props.resultMenus;
        let language = this.props.language;

        if (menu !== null) {
            for (let k = 0; k < this.props.selectedResults.length; k++) {
                for (let i = 0; i < menu["TreeCS"].items.length; i++) {
                    for (let j = 0; j < menu["TreeCS"].items[i].items.length; j++) {
                        if ((menu["TreeCS"].items[i].items[j].indID === this.props.selectedResults[k][0]) && (this.props.selectedResults[k][1] === CONSTANTS.GB_CS))
                            yourResultNames.push(<TLabel
                                caption={menu["TreeCS"].items[i].items[j].Names[language]}
                                style={styles.labelStyle}
                                key={this.props.selectedResults[k][0]}
                            />);
                    }
                }
            }

            for (let k = 0; k < this.props.selectedResults.length; k++) {
                if (this.props.selectedResults[k][1] === CONSTANTS.GB_DP) {
                    if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmTotalPop) {
                        yourResultNames.push(<TLabel
                            caption={RS('GB_stTotalPop')}
                            style={styles.labelStyle}
                            key={'TotalPop'}
                        />);
                    } else if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmPop04) {
                        yourResultNames.push(<TLabel
                            caption={RS('GB_stPop0t4')}
                            style={styles.labelStyle}
                            key={'Pop0t4'}
                        />);
                    } else if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmDispTFR) {
                        yourResultNames.push(<TLabel
                            caption={RS('GB_stTotalFertlityRate')}
                            style={styles.labelStyle}
                            key={'TFR'}
                        />);
                    } else if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmBirths) {
                        yourResultNames.push(<TLabel
                            caption={RS('GB_stTotalBirths')}
                            style={styles.labelStyle}
                            key={'TotalBirths'}
                        />);
                    }
                    else if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmTotalLE) {
                        yourResultNames.push(<TLabel
                            caption={RS('GB_stLifeExp')}
                            style={styles.labelStyle}
                            key={'LifeExp'}
                        />);
                    }
                }
            }

            for (let k = 0; k < this.props.selectedResults.length; k++) {
                for (let i = 0; i < menu["TreeAM"].items.length; i++) {
                    for (let j = 0; j < menu["TreeAM"].items[i].items.length; j++) {
                        if ((menu["TreeAM"].items[i].items[j].indID === this.props.selectedResults[k][0]) && (this.props.selectedResults[k][1] === CONSTANTS.GB_AM))
                            yourResultNames.push(<TLabel
                                caption={menu["TreeAM"].items[i].items[j].Names[language]}
                                style={styles.labelStyle}
                                key={this.props.selectedResults[k][0]}
                            />);
                    }
                }
            }

            for (let k = 0; k < this.props.selectedResults.length; k++) {
                for (let i = 0; i < menu["TreeFP"].items.length; i++) {
                    for (let j = 0; j < menu["TreeFP"].items[i].items.length; j++) {
                        if ((menu["TreeFP"].items[i].items[j].indID === this.props.selectedResults[k][0]) && (this.props.selectedResults[k][1] === CONSTANTS.GB_FP))
                            yourResultNames.push(<TLabel
                                caption={menu["TreeFP"].items[i].items[j].Names[language]}
                                style={styles.labelStyle}
                                key={this.props.selectedResults[k][0]}
                            />);
                    }
                }
            }

            // for (let k = 0; k < this.props.selectedResults.length; k++) {
            //     if (this.props.selectedResults[k][1] === CONSTANTS.GB_FP) {
            //         if (this.props.selectedResults[k][0] === CONSTANTS.FP_MstcmPrevalence) {
            //             yourResultNames.push(<TLabel
            //                 caption={"Contraceptive prevalence (CPR)"}
            //                 style={styles.labelStyle}
            //                 key={'CPR'}
            //             />);
            //         } else if (this.props.selectedResults[k][0] === CONSTANTS.FP_MstcmTF) {
            //             yourResultNames.push(<TLabel
            //                 caption={"Total fecundity"}
            //                 style={styles.labelStyle}
            //                 key={'TotalFecundity'}
            //             />);
            //         }
            //     }
            // }
            //
            // for (let k = 0; k < this.props.selectedResults.length; k++) {
            //     if (this.props.selectedResults[k][1] === CONSTANTS.GB_AM) {
            //         if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmNumHIV) {
            //             yourResultNames.push(<TLabel
            //                 caption={"HIV population"}
            //                 style={styles.labelStyle}
            //                 key={'HIVPop'}
            //             />);
            //         } else if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmAIDSDeaths) {
            //             yourResultNames.push(<TLabel
            //                 caption={"AIDS deaths"}
            //                 style={styles.labelStyle}
            //                 key={'AIDSDeaths'}
            //             />);
            //         } else if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmChNeedPMTCT) {
            //             yourResultNames.push(<TLabel
            //                 caption={"Mothers needing PMTCT"}
            //                 style={styles.labelStyle}
            //                 key={'NeedPMTCT'}
            //             />);
            //         }
            //         else if (this.props.selectedResults[k][0] === CONSTANTS.DP_MstcmChRecPMTCT) {
            //             yourResultNames.push(<TLabel
            //                 caption={"Mothers receiving PMTCT"}
            //                 style={styles.labelStyle}
            //                 key={'ReceivePMTCT'}
            //             />);
            //         }
            //     }
            // }
        }

        return yourResultNames;
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {

            section : {
                float: 'left',
            },

            section1 : {
                float: 'left',
            },

            section2 : {
                position: 'fixed'
            },
        };

        return (
            <div>

                <div className="drawerPage">

                    <div style={styles.section}>
                        <ResultCheckBoxes
                            resultGroupNames={this.getResultGroupNames()}
                            selectedResults={this.props.selectedResults}
                            famPlanIncluded={this.props.includeFamPlan}

                            checkBoxResultLists={this.getResultLists()}
                            onCheckBoxChange={this.onCheckBoxChange}
                        />
                    </div>
                    <div style={styles.section1}>
                        <div style={styles.section2}>
                            <YourResults
                                yourResultNames={this.getYourResultNames()}
                                onClearResultsClick={this.onClearResultsClick}
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default ManageResultsContent;
