import RS from "@common/strings/RS";
import {
    createSimpleDefaultPackTable,
    GB_CONSTANTS,
    getModvarCloneByTag,
    CONSTANTS,
    getCalcYearIdx,
    Get_FP_AgeName,
    Get_FP_UseSingleAgesValue,
    Get_FP_NumActiveMethods,
    Get_FP_EditorCategoryAndMethodNames,
    Get_FP_EditorMethodNames, 
    generatePackChart
} from "../../../../../results/CS/data/imports.js";
import {addRowToPackTable} from "../../../../../utilities/GB/GBUtil.js";

export const FPMethodMixEditor = {
    Create : (DPModvars, projInfo, ageRange, needConstant, forCard = false) => {
        let CalcYear = projInfo.calcYear;
        let FinalYear = projInfo.finalYear;

        let useSingleAges = Get_FP_UseSingleAgesValue(DPModvars);
        let editorName = RS('GB_stMethodMix');
        let packTable;

        if (forCard) {
            packTable = CardSetup(DPModvars, CalcYear, FinalYear);
            packTable = CardSetSSData(packTable, DPModvars, projInfo, useSingleAges, needConstant);
            packTable.Title = editorName;
            return (packTable);
        }
        else {
            packTable = Setup(DPModvars, useSingleAges, ageRange, CalcYear, FinalYear);
            packTable = SetSSData(packTable, DPModvars, projInfo, useSingleAges, ageRange, needConstant);
            packTable = FPMethodMixEditor.RecalcGrid(packTable);
            packTable.useSubHeadersInChartLbls = true;
            let packChart = generatePackChart(packTable, [packTable.tableData.value.length-1]);
            packTable.Title = editorName;
            return ([packChart, packTable]);
        }
    },

    GetSSData : (pt, DPModvars, projInfo, useSingleAges, ageRange, needConstant) => {
        let changedModvars = [];

        let MethodMixModvar = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodMix_MV);
        let ActiveMethodsValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TActiveMethods_MV)['value'];
        let MethodNameValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodName_MV)['value'];

        let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
        let CalcYear = projInfo.calcYear;
        let FinalYear = projInfo.finalYear;
        let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
        let col;

        let age = CONSTANTS.FP_All_Ages;
        if (!useSingleAges) {
            age = ageRange;
        }

        col = 1;
        for (let t = calcYearIndex; t <= finalYearIndex; t++) {
            for (let m = 0; m < CONSTANTS.FP_Max_Methods; m++) {
                if (ActiveMethodsValue[m]) {
                    for (let row = 1; row < pt.GBRowCount; row++) {
                        if (pt.tableData.value[row][0] === MethodNameValue[m]) {
                            MethodMixModvar['value'][age][m][needConstant][t] = pt.tableData.value[row][col];
                        }
                    }
                }
            }
            col++;
        }

        changedModvars.push(MethodMixModvar);

        return (changedModvars);
    },

    RecalcGrid : (pt) => {
        let total;
        let totalRow = pt.GBRowCount - 1;
        let nonBlankFound;
        for (let c = 1; c < pt.GBColCount; c++) {
            total = 0;
            nonBlankFound = false;
            for (let r = 1; r < totalRow; r++) {
                if (pt.tableData.value[r][c] !== '') {
                    total += pt.tableData.value[r][c];
                    nonBlankFound = true;
                }
            }
            pt.tableData.value[totalRow][c] = '';
            if (nonBlankFound){
                pt.tableData.value[totalRow][c] = total;
            }
        }
        return (pt);
    },

    Validate : (pt) => {
        let TotalRow = pt.GBRowCount - 1;
        let valid = true;
        for (let c = 1; c < pt.GBColCount; c++) {
            if ((pt.tableData.value[TotalRow][c] < 99.8) || (pt.tableData.value[TotalRow][c] > 100.05)){
                valid = false;
            }
        }

        if (!valid) {
            alert(RS('GB_stTotalEqual100'));
        }

        return(valid)
    },
};

const Setup = (DPModvars, useSingleAges, ageRange, CalcYear, FinalYear) => {
    let numRows = Get_FP_NumActiveMethods(DPModvars) + 1;
    let numCols = FinalYear - CalcYear + 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols, 0);

    let col = 1;
    for (let i = CalcYear; i <= FinalYear; i++) {
        pt.tableData.value[0][col] = i.toString();
        col++;
    }

    if (useSingleAges) {
        pt.tableData.value[0][0] = RS('GB_stAge') + ': 15-49';
    }
    else {
        pt.tableData.value[0][0] = RS('GB_stAge') + ': ' + Get_FP_AgeName(ageRange);
    }

    pt = Get_FP_EditorCategoryAndMethodNames(pt, DPModvars, numCols);

    pt = addRowToPackTable(pt, '');
    pt.tableData.value[pt.GBRowCount-1][0] = RS('GB_stTotal');
    pt.FontStyles[pt.GBRowCount-1][0]["intArray"] = [GB_CONSTANTS.Bold];
    for (let tc = 1; tc < numCols; tc++) {
        pt.LockedCells[pt.GBRowCount-1][tc] = true;
        // pt["FontColors"][pt.GBRowCount-1][tc] = GB_CONSTANTS.LockedCellColor;
    }

    for (let r = 1; r < pt.GBRowCount; r++) {
        for (let c = 1; c < numCols; c++) {
            pt.RDec[r][c] = 1;
            pt.MaxAllowedVal[r][c] = 100;
            pt.MinAllowedVal[r][c] = 0;
            pt.Alignments[r][c] = GB_CONSTANTS.Right;
        }
    }

    return pt;
};

const SetSSData = (pt, DPModvars, projInfo, useSingleAges, ageRange, needConstant) => {
    let MethodMixValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodMix_MV)['value'];
    let ActiveMethodsValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TActiveMethods_MV)['value'];
    let MethodNameValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodName_MV)['value'];

    let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
    let CalcYear = projInfo.calcYear;
    let FinalYear = projInfo.finalYear;
    let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
    let col;

    let age = CONSTANTS.FP_All_Ages;
    if (!useSingleAges) {
        age = ageRange;
    }

    col = 1;
    for (let t = calcYearIndex; t <= finalYearIndex; t++) {
        for (let m = 0; m < CONSTANTS.FP_Max_Methods; m++) {
            if (ActiveMethodsValue[m]) {
                for (let row = 1; row < pt.GBRowCount; row++) {
                    if (pt.tableData.value[row][0] === MethodNameValue[m]) {
                        pt.tableData.value[row][col] = MethodMixValue[age][m][needConstant][t];
                        if (pt.tableData.value[row][col] < 0) {
                            pt.tableData.value[row][col] = '';
                        }
                    }
                }
            }
        }
        col++;
    }

    return pt;
};

const CardSetup = (DPModvars, CalcYear, FinalYear) => {
    let numRows = Get_FP_NumActiveMethods(DPModvars) + 1;
    let numCols = FinalYear - CalcYear + 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    let col = 1;
    for (let i = CalcYear; i <= FinalYear; i++) {
        pt.tableData.value[0][col] = i.toString();
        for (let r = 1; r < numRows; r++) {
            pt.RDec[r][col] = 1;
            pt.MaxAllowedVal[r][col] = 100;
            pt.MinAllowedVal[r][col] = 0;
        }
        col++;
    }

    pt = Get_FP_EditorMethodNames(pt, DPModvars);

    return pt;
};

const CardSetSSData = (pt, DPModvars, projInfo, useSingleAges, needConstant) => {
    let MethodMixValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodMix_MV)['value'];
    let ActiveMethodsValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TActiveMethods_MV)['value'];
    let MethodNameValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodName_MV)['value'];

    let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
    let CalcYear = projInfo.calcYear;
    let FinalYear = projInfo.finalYear;
    let finalYearIndex = FinalYear - CalcYear + calcYearIndex;

    let ageRange = (useSingleAges) ? CONSTANTS.FP_All_Ages : CONSTANTS.FP_A25_29;

    let col = 1;
    for (let t = calcYearIndex; t <= finalYearIndex; t++) {
        for (let m = 0; m < CONSTANTS.FP_Max_Methods; m++) {
            if (ActiveMethodsValue[m]) {
                for (let row = 1; row < pt.GBRowCount; row++) {
                    if (pt.tableData.value[row][0] === MethodNameValue[m]) {
                        pt.tableData.value[row][col] = MethodMixValue[ageRange][m][needConstant][t];
                    }
                }
            }
        }
        col++;
    }

    return pt;
};
