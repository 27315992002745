import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    CloneObj, generatePackChart, getIdxFromYear, lockPackTable, add_NoHistoricalData_MsgToPackTable
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {CSConvertIndicatorToZScores} from "utilities/CS/CSDataUtil";

export const SingleStuntingDistrEditor = {
    Create : (CSModvars, EditorStartYear, RadioValue) => {
        let editorName = RS('GB_stSingleStuntingDistr');
        let packTable = Setup(CSModvars, EditorStartYear);
        packTable = SingleStuntingDistrEditor.SetSSData(packTable, CSModvars);
        packTable = SingleStuntingDistrEditor.onCheckBoxClick(packTable, RadioValue);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionalStatus;
        return ([packChart, packTable]);
    },

    onCheckBoxClick : (pt, RadioValue) => {
        pt = lockPackTable(pt, 1, RadioValue);
        return (pt);
    },

    UpdateStuntingDistr : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);
        let SingleStuntingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleStuntingDistr_MV);
        let StuntingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_StuntingDistr_MV);
        StuntingDistr["value"] = CSConvertIndicatorToZScores(SingleStuntingDistr["value"],
            StuntingDistr["value"], pt.PseudoStartIndex, pt.StartIndex);
        return (CSModvarsClone);
    },

    SetSSData : (pt, CSModvars) => {
        pt.tableData.value[1][1] = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SingleStuntingDistr_MV)["value"][pt.StartIndex];
        return pt;
    },

    GetSSData : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);
        let SingleStuntingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleStuntingDistr_MV);
        SingleStuntingDistr["value"][pt.PseudoStartIndex] = SingleStuntingDistr["value"][pt.StartIndex];
        SingleStuntingDistr["value"][pt.StartIndex] = pt.tableData.value[1][1];
        return (CSModvarsClone);
    },
};

const Setup = (CSModvars, EditorStartYear) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    pt.PseudoStartIndex = pt.StartIndex - 1;

    const numRows = 2;
    const numCols = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stSingleStunting');

    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.RDec[1][1] = 2;

    return pt;
};

