import * as React from 'react';
import PropTypes from 'prop-types';
import RS from "@common/strings/RS";
import TAppBar from '../components/APP/TAppBar';
import TAppSideBar from '../components/APP/TAppSideBar';
import TAppContent from '../components/APP/TAppContent';
import AppBarCustom from '../components/APP/AppBarCustom';
import AppSideBarCustom from "../components/APP/AppSideBarCustom";
import AppContentCustom from "../components/APP/AppContentCustom";
import AppSideBarPlaceholder from "../components/APP/AppSideBarPlaceholder";
import AppDialogs from '../components/dialogs/AppDialogs';
import CSLogo from '../components/CS/CSLogo';
import CONSTANTS from "utilities/CS/CSConst";
import DraggableDocumentWindow from "@common/components/DraggableDocumentWindow";
//import MOSideBar from "../pages/CS/TOOLS/MissedOpportunities/sideBar/MOSideBar";

class Main extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        appState                    : PropTypes.object.isRequired,
        appFuncs                    : PropTypes.object.isRequired,
        theme                       : PropTypes.object,
    };

    static defaultProps = {
        appState                    : {},
        appFuncs                    : {},
        theme                       : {},
    };

    state = {
        helpDialogURL : '',
        MOSideBarOpen : true
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    onTitleClick = () => {
        this.props.appFuncs.onPageChange("CSHomeForm");
    };

    onToggleSideBar = () => {
        this.setState({
            MOSideBarOpen: !this.state.MOSideBarOpen
        })
    };

    handleOpenHelpDialog = (helpFile) => {
        let langPath = (this.props.appState.language === CONSTANTS.GB_English) ? 'LiSTOnlineHelpEnglish/HTML/' : 'LiSTOnlineHelpFrench/HTML/';
        this.setState({
            dialogHelpOpen : true,
            helpDialogURL  : langPath + helpFile
        })
    };

    render() {
        //this serves to allow the MO interventions graph to know when nutrition and birth outcomes should include specific interventions
        let MOCountryData = JSON.parse(JSON.stringify(this.props.appState.MOCountryData));
        for (let i = 0; i < MOCountryData.length; i++) {
            for (let j = 0; j < this.props.appState.IVInfo.length; j++) {
                if (this.props.appState.IVInfo[j].MstID === MOCountryData[i].IntervMstID) {
                    MOCountryData[i].isNutritionIV = this.props.appState.IVInfo[j].isNutritionIV;
                    MOCountryData[i].isBirthOutcomeIV = this.props.appState.IVInfo[j].isBirthOutcomeIV;
                }
            }
        }

        return (
            <div>
                <TAppBar
                    position                        = "absolute"
                    logo                            = {<CSLogo isDeskTop={this.props.appState.isDeskTop} key="logo" />}
                    isDeskTop                       = {this.props.appState.isDeskTop}
                    onHamburgerClick                = {this.props.appFuncs.onHamburgerClick}
                    onTitleClick                    = {this.onTitleClick}
                    style                           = {this.props.theme.appBar}
                    showHamburger                   = {window.innerWidth < 500}
                    hamburgerMenuOpen               = {this.props.appState.hamburgerMenuOpen}
                    menuThreshold                   = {this.props.appState.menuThreshold}
                    isMobile                        = {this.props.appState.isMobile}
                    pageID                          = {this.props.appState.pageID}
                    appMode                         = {this.props.appState.appMode}
                    isGuest                         = {this.props.appState.isGuest}
                    projectCreated                  = {this.props.appState.projectCreated}
                    onExploreLiSTDataBtnClick       = {this.props.appFuncs.onExploreLiSTDataBtnClick}
                    onGuidedExplorationBtnClick     = {this.props.appFuncs.onGuidedExplorationBtnClick}
                    onExtractBtnClick               = {this.props.appFuncs.onExtractBtnClick}
                    onCreateProjBtnClick            = {this.props.appFuncs.onCreateProjBtnClick}
                    onPageChange                    = {this.props.appFuncs.onPageChange}
                >
                    <AppBarCustom
                        onPageChange                = {this.props.appFuncs.onPageChange}
                        onStateChange               = {this.props.appFuncs.onStateChange}
                        onLogout                    = {this.props.appFuncs.onLogout}
                        onCreateProjBtnClick        = {this.props.appFuncs.onCreateProjBtnClick}
                        onGuidedExplorationBtnClick = {this.props.appFuncs.onGuidedExplorationBtnClick}
                        onExploreLiSTDataBtnClick   = {this.props.appFuncs.onExploreLiSTDataBtnClick}
                        onExtractBtnClick           = {this.props.appFuncs.onExtractBtnClick}
                        onSaveProjection            = {this.props.appFuncs.onSaveProjection}
                        onCloseProjection           = {this.props.appFuncs.onCloseProjection}
                        pageID                      = {this.props.appState.pageID}
                        isGuest                     = {this.props.appState.isGuest}
                        humanName                   = {this.props.appState.humanName}
                        signedIn                    = {this.props.appState.signedIn}
                        projectCreated              = {this.props.appState.projectCreated}
                        isDeskTop                   = {this.props.appState.isDeskTop}
                        projectionName              = {this.props.appState.projectionName}
                        appMode                     = {this.props.appState.appMode}
                        projectionCountry           = {this.props.appState.selectedCountry}
                        projectionRegion            = {this.props.appState.subnatRegion}
                        projectionYears             = {this.props.appState.firstYear + "-" + this.props.appState.finalYear}
                        projectionSurvey            = {this.props.appState.subnatSurvey}
                        CSModvars                   = {this.props.appState.CSModvars}
                        EditorStartYear             = {this.props.appState.firstYear}
                        EditorEndYear               = {this.props.appState.finalYear}
                        IVInfo                      = {this.props.appState.IVInfo}
                        language                    = {this.props.appState.language}
                        showRetroYears              = {this.props.appState.showRetroYears}
                        onOpenHelpDialog            = {this.handleOpenHelpDialog}
                        projectionNotes             = {this.props.appState.projectionNotes}
                        subnatProjection            = {this.props.appState.subnatProjection}
                        // appState                 = {this.props.appState}
                    />
                </TAppBar>

                <TAppSideBar
                    open                            = {this.props.appState.sidebarOpen}
                    appMode                         = {this.props.appState.appMode}
                    menuThreshold                   = {this.props.appState.menuThreshold}
                    MOSideBarOpen                   = {this.state.MOSideBarOpen}
                >
                    <AppSideBarCustom
                        onCreateProjBtnClick           = {this.props.appFuncs.onCreateProjBtnClick}
                        onGuidedExplorationBtnClick    = {this.props.appFuncs.onGuidedExplorationBtnClick}
                        onExploreLiSTDataBtnClick      = {this.props.appFuncs.onExploreLiSTDataBtnClick}
                        onHamburgerClick               = {this.props.appFuncs.onHamburgerClick}
                        onToggleSideBar                = {this.onToggleSideBar}
                        MOSideBarOpen                  = {this.state.MOSideBarOpen}
                        onPageChange                   = {this.props.appFuncs.onPageChange}
                        onViewResults                  = {this.props.appFuncs.onViewResults}
                        onStateChange                  = {this.props.appFuncs.onStateChange}
                        onAddTasks                     = {this.props.appFuncs.onAddTasks}
                        pageID                         = {this.props.appState.pageID}
                        appMode                        = {this.props.appState.appMode}
                        selectedCountry                = {this.props.appState.selectedCountry}
                        countryISO                     = {this.props.appState.countryISO}
                        countries                      = {this.props.appState.countries}
                        IVGroupInfo                    = {this.props.appState.IVGroupInfo}
                        IVSubGroupInfo                 = {this.props.appState.IVSubGroupInfo}
                        IVInfo                         = {this.props.appState.IVInfo}
                        displayButtons                 = {this.props.appState.displayButtons}
                        displayMaternalCOD             = {this.props.appState.displayMaternalCOD}
                        displayStillbirthCOD           = {this.props.appState.displayStillbirthCOD}
                        displayNeonatalCOD             = {this.props.appState.displayNeonatalCOD}
                        displayChildCOD                = {this.props.appState.displayChildCOD}
                        displayCPR                     = {this.props.appState.displayCPR}
                        deliveryPointsContent          = {this.props.appState.deliveryPointsContent}
                        maternalCausesOfDeathContent   = {this.props.appState.maternalCausesOfDeathContent}
                        stillbirthCausesOfDeathContent = {this.props.appState.stillbirthCausesOfDeathContent}
                        neonatalCausesOfDeathContent   = {this.props.appState.neonatalCausesOfDeathContent}
                        childCausesOfDeathContent      = {this.props.appState.childCausesOfDeathContent}
                        projectionName                 = {this.props.appState.projectionName}
                        yearRange                      = {this.props.appState.yearRange}
                        projectCreated                 = {this.props.appState.projectCreated}
                        inputModvars                   = {this.props.appState.inputModvars}
                        selectedProjSideBarIdx         = {this.props.appState.selectedProjSideBarIdx}
                        MOResultToDisplay              = {this.props.appState.MOResultToDisplay}
                        maternalIntervSelected         = {this.props.appState.maternalIntervSelected}
                        stillbirthIntervSelected       = {this.props.appState.stillbirthIntervSelected}
                        neonatalIntervSelected         = {this.props.appState.neonatalIntervSelected}
                        childIntervSelected            = {this.props.appState.childIntervSelected}
                        nutritionSelected              = {this.props.appState.nutritionSelected}
                        birthOutcomeSelected           = {this.props.appState.birthOutcomeSelected}
                        subnatRegion                   = {this.props.appState.subnatRegion}
                        subnatSurvey                   = {this.props.appState.subnatSurvey}
                        viewInterventionCost           = {this.props.appState.viewInterventionCost}
                        language                       = {this.props.appState.language}
                    />
                </TAppSideBar>

                <AppSideBarPlaceholder
                    open                            = {!this.props.appState.sidebarOpen}
                    appMode                         = {this.props.appState.appMode}
                    onHamburgerClick                = {this.props.appFuncs.onHamburgerClick}
                />

                <TAppContent
                    sidebarOpen                     = {this.props.appState.sidebarOpen}
                    pageID                          = {this.props.appState.pageID}
                    appMode                         = {this.props.appState.appMode}
                    menuThreshold                   = {this.props.appState.menuThreshold}
                    MOSideBarOpen                   = {this.state.MOSideBarOpen}
                >
                    <AppContentCustom
                        onAddTasks                     = {this.props.appFuncs.onAddTasks}
                        onStateChange                  = {this.props.appFuncs.onStateChange}
                        onPageChange                   = {this.props.appFuncs.onPageChange}
                        onOpenProjection               = {this.props.appFuncs.onOpenProjection}
                        onDeleteProjection             = {this.props.appFuncs.onDeleteProjection}
                        onDownloadProjection           = {this.props.appFuncs.onDownloadProjection}
                        onCloseProjection              = {this.props.appFuncs.onCloseProjection}
                        onSaveProjection               = {this.props.appFuncs.onSaveProjection}
                        onRenameProjection             = {this.props.appFuncs.onRenameProjection}
                        onCopyProjection               = {this.props.appFuncs.onCopyProjection}
                        onCreateProjBtnClick           = {this.props.appFuncs.onCreateProjBtnClick}
                        onExploreLiSTDataBtnClick      = {this.props.appFuncs.onExploreLiSTDataBtnClick}
                        onGuidedExplorationBtnClick    = {this.props.appFuncs.onGuidedExplorationBtnClick}
                        onUploadProjection             = {this.props.appFuncs.onUploadProjection}
                        onSaveTopic                    = {this.props.appFuncs.onSaveTopic}
                        onGetTopicList                 = {this.props.appFuncs.onGetTopicList}
                        onCreateProjection             = {this.props.appFuncs.onCreateProjection}
                        onModifyProjection             = {this.props.appFuncs.onModifyProjection}
                        onViewResults                  = {this.props.appFuncs.onViewResults}
                        onGetDataPackResults           = {this.props.appFuncs.onGetDataPackResults}
                        onOpenHelpDialog               = {this.handleOpenHelpDialog}
                        handleSelectedResults          = {this.props.appFuncs.handleSelectedResults}

                        MOSideBarOpen                  = {this.state.MOSideBarOpen}
                        heartBeat                      = {this.props.appState.heartBeat}
                        apiTasks                       = {this.props.appState.apiTasks}
                        pageID                         = {this.props.appState.pageID}
                        sidebarOpen                    = {this.props.appState.sidebarOpen}
                        isGuest                        = {this.props.appState.isGuest}
                        isDeskTop                      = {this.props.appState.isDeskTop}
                        activeProjections              = {this.props.appState.activeProjections}
                        storedProjections              = {this.props.appState.storedProjections}
                        projectCreated                 = {this.props.appState.projectCreated}
                        topicList                      = {this.props.appState.topicList}
                        projectionName                 = {this.props.appState.projectionName}
                        projectionNotes                = {this.props.appState.projectionNotes}
                        selectedCountry                = {this.props.appState.selectedCountry}
                        countryISO                     = {this.props.appState.countryISO}
                        subnatProjection               = {this.props.appState.subnatProjection}
                        includeFamPlan                 = {this.props.appState.includeFamPlan}
                        countries                      = {this.props.appState.countries}
                        IVGroupInfo                    = {this.props.appState.IVGroupInfo}
                        IVSubGroupInfo                 = {this.props.appState.IVSubGroupInfo}
                        IVInfo                         = {this.props.appState.IVInfo}
                        yearRange                      = {this.props.appState.yearRange}
                        chartYears                     = {this.props.appState.chartYears}
                        appMode                        = {this.props.appState.appMode}
                        CSModvars                      = {this.props.appState.CSModvars}
                        useFamPlanOutputs              = {this.props.appState.useFamPlanOutputs}
                        intervDrawerOpen               = {this.props.appState.intervDrawerOpen}
                        displayChart                   = {this.props.appState.displayChart}
                        displayMaternalCOD             = {this.props.appState.displayMaternalCOD}
                        displayStillbirthCOD           = {this.props.appState.displayStillbirthCOD}
                        displayNeonatalCOD             = {this.props.appState.displayNeonatalCOD}
                        displayChildCOD                = {this.props.appState.displayChildCOD}
                        displayAdolescentCOD           = {this.props.appState.displayAdolescentCOD}
                        displayCPR                     = {this.props.appState.displayCPR}
                        deliveryPointsContent          = {this.props.appState.deliveryPointsContent}
                        maternalCausesOfDeathContent   = {this.props.appState.maternalCausesOfDeathContent}
                        stillbirthCausesOfDeathContent = {this.props.appState.stillbirthCausesOfDeathContent}
                        neonatalCausesOfDeathContent   = {this.props.appState.neonatalCausesOfDeathContent}
                        childCausesOfDeathContent      = {this.props.appState.childCausesOfDeathContent}
                        adolescentCausesOfDeathContent = {this.props.appState.adolescentCausesOfDeathContent}
                        sortByDeathsAverted            = {this.props.appState.sortByDeathsAverted}
                        sortByInterventionCost         = {this.props.appState.sortByInterventionCost}
                        viewInterventionCost           = {this.props.appState.viewInterventionCost}
                        MOCountryData                  = {MOCountryData}
                        inputModvars                   = {this.props.appState.inputModvars}
                        chartOptions                   = {this.props.appState.chartOptions}
                        availableResults               = {this.props.appState.availableResults}
                        firstYear                      = {this.props.appState.firstYear}
                        finalYear                      = {this.props.appState.finalYear}
                        selectedPackTables             = {this.props.appState.selectedPackTables}
                        selectedPackCharts             = {this.props.appState.selectedPackCharts}
                        resultMenus                    = {this.props.appState.resultMenus}
                        selectedResults                = {this.props.appState.selectedResults}
                        stacked                        = {this.props.appState.stacked}
                        legendReversed                 = {this.props.appState.legendReversed}
                        authorName           	       = {this.props.appState.authorName}
                        orgName                        = {this.props.appState.orgName}
                        showMOIntervSection            = {this.props.appState.showMOIntervSection}
                        topicFilter                    = {this.props.appState.topicFilter}
                        projNameSaveAs                 = {this.props.appState.projNameSaveAs}
                        maternalIntervSelected         = {this.props.appState.maternalIntervSelected}
                        stillbirthIntervSelected       = {this.props.appState.stillbirthIntervSelected}
                        neonatalIntervSelected         = {this.props.appState.neonatalIntervSelected}
                        childIntervSelected            = {this.props.appState.childIntervSelected}
                        nutritionSelected              = {this.props.appState.nutritionSelected}
                        birthOutcomeSelected           = {this.props.appState.birthOutcomeSelected}
                        adolescentsSelected            = {this.props.appState.adolescentsSelected}
                        selectAllIntervBool            = {this.props.appState.selectAllIntervBool}
                        topMOInterventions             = {this.props.appState.topMOInterventions}
                        MOResultToDisplay              = {this.props.appState.MOResultToDisplay}
                        isExpandMODrawerClosed         = {this.props.appState.isExpandMODrawerClosed}
                        displayEndpoints               = {this.props.appState.displayEndpoints}
                        displayCoverage                = {this.props.appState.displayCoverage}
                        retroYears                     = {this.props.appState.retroYears}
                        subnatRegion                   = {this.props.appState.subnatRegion}
                        subnatSurvey                   = {this.props.appState.subnatSurvey}
                        coverageDisplay                = {this.props.appState.coverageDisplay}
                        showAllInterventionsCB         = {this.props.appState.showAllInterventionsCB}
			language                       = {this.props.appState.language}
                        showRetroYears                 = {this.props.appState.showRetroYears}
                        menuThreshold                  = {this.props.appState.menuThreshold}
                        isMobile                       = {this.props.appState.isMobile}
                        approach                       = {this.props.appState.approach}
                    />

                </TAppContent>

                <AppDialogs
                    onStateChange                           = {this.props.appFuncs.onStateChange}
                    onPageChange                            = {this.props.appFuncs.onPageChange}
                    onGetTopicList                          = {this.props.appFuncs.onGetTopicList}
                    onAddTasks                              = {this.props.appFuncs.onAddTasks}
                    onSaveProjection                        = {this.props.appFuncs.onSaveProjection}
                    onCloseProjection                       = {this.props.appFuncs.onCloseProjection}
                    onCreateProjectionFromGuidedExploration = {this.props.appFuncs.onCreateProjectionFromGuidedExploration}
                    onContinueWorking                       = {this.props.appFuncs.onContinueWorking}
                    onSaveLanguage                          = {this.props.appFuncs.onSaveLanguage}
                    onSaveTopic                             = {this.props.appFuncs.onSaveTopic}
                    dialogLogInOpen                         = {this.props.appState.dialogLogInOpen}
                    dialogNotLoggedInOpen                   = {this.props.appState.dialogNotLoggedInOpen}
                    dialogProjCreatedOpen                   = {this.props.appState.dialogProjCreatedOpen}
                    dialogSaveAsOpen                        = {this.props.appState.dialogSaveAsOpen}
                    dialogAboutOpen                         = {this.props.appState.dialogAboutOpen}
                    dialogServerAboutOpen                   = {this.props.appState.dialogServerAboutOpen}
                    dialogNewSupportOpen                    = {this.props.appState.dialogNewSupportOpen}
                    dialogCloseOpen                         = {this.props.appState.dialogCloseOpen}
                    dialogErrorOpen                         = {this.props.appState.dialogErrorOpen}
                    dialogAPIOpen                           = {this.props.appState.dialogAPIOpen}
                    dialogPrivacyOpen                       = {this.props.appState.dialogPrivacyOpen}
                    dialogNutritionOpen                     = {this.props.appState.dialogNutritionOpen}
                    dialogLanguageOpen                      = {this.props.appState.dialogLanguageOpen}
                    dialogProjInfoOpen                      = {this.props.appState.dialogProjInfoOpen}
                    dialogForgotPasswordOpen                = {this.props.appState.dialogForgotPasswordOpen}
                    dialogAccountSetupOpen                  = {this.props.appState.dialogAccountSetupOpen}
                    dialogFileCopyOpen                      = {this.props.appState.dialogFileCopyOpen}
                    dialogFileRenameOpen                    = {this.props.appState.dialogFileRenameOpen}
                    dialogEditTagsOpen                      = {this.props.appState.dialogEditTagsOpen}
                    dialogFilterTagsOpen                    = {this.props.appState.dialogFilterTagsOpen}
                    dialogActionsOpen                       = {this.props.appState.dialogActionsOpen}
                    dialogNewTagOpen                        = {this.props.appState.dialogNewTagOpen}
                    dialogDeletionCheckOpen                 = {this.props.appState.dialogDeletionCheckOpen}
                    dialogMultiplicationOpen                = {this.props.appState.dialogMultiplicationOpen}
                    dialogSubnatCheckOpen                   = {this.props.appState.dialogSubnatCheckOpen}
                    dialogWarningOpen                       = {this.props.appState.dialogWarningOpen}
                    dialogAccountCreateOpen                 = {this.props.appState.dialogAccountCreateOpen}
                    dialogAccountUpdateOpen                 = {this.props.appState.dialogAccountUpdateOpen}
                    dialogFileAlreadyExistsOpen             = {this.props.appState.dialogFileAlreadyExistsOpen}
                    dialogFileWithSameNameOpen              = {this.props.appState.dialogFileWithSameNameOpen}
                    prevProjName                            = {this.props.appState.prevProjName}
                    errorMessage                            = {this.props.appState.errorMessage}
                    warningMessage                          = {this.props.appState.warningMessage}
                    apiTasks                                = {this.props.apiTasks}
                    isGuest                                 = {this.props.appState.isGuest}
                    version                                 = {this.props.appState.version}
                    explanationText                         = {this.props.appState.explanationText}
                    selectedCountry                         = {this.props.appState.selectedCountry}
                    countryISO                              = {this.props.appState.countryISO}
                    projectCreated                          = {this.props.appState.projectCreated}
                    projectionName                          = {this.props.appState.projectionName}
                    language                                = {this.props.appState.language}
                    fileToCopy                              = {this.props.appState.fileToCopy}
                    fileToRename                            = {this.props.appState.fileToRename}
                    topicList                               = {this.props.appState.topicList}
                    chosenTags                              = {this.props.appState.chosenTags}
                    fileToTag                               = {this.props.appState.fileToTag}
                    fileForActions                          = {this.props.appState.fileForActions}
                    actionNotes                             = {this.props.appState.actionNotes}
                    fileToDelete                            = {this.props.appState.fileToDelete}
                    onOpenProjection                        = {this.props.appFuncs.onOpenProjection}
                    onDeleteProjection                      = {this.props.appFuncs.onDeleteProjection}
                    onDownloadProjection                    = {this.props.appFuncs.onDownloadProjection}
                    onRenameProjection                      = {this.props.appFuncs.onRenameProjection}
                    onCopyProjection                        = {this.props.appFuncs.onCopyProjection}
                    topicFilter                             = {this.props.appState.topicFilter}
                    pageID                                  = {this.props.appState.pageID}
                    storedProjections                       = {this.props.appState.storedProjections}
                    status                                  = {this.props.appState.status}
                    apiMainMsg                              = {this.props.appState.apiMainMsg}
                    apiSubMsg                               = {this.props.appState.apiSubMsg}
                    userName                                = {this.props.appState.userName}
                    humanName                               = {this.props.appState.humanName}
                    TimeToLive                              = {this.props.appState.TimeToLive}
                    TimeIdle                                = {this.props.appState.TimeIdle}
                    TimeExpire                              = {this.props.appState.TimeExpire}
                    TimeTotal                               = {this.props.appState.TimeTotal}
                />

                <DraggableDocumentWindow
                    open={this.state.dialogHelpOpen}
                    title={RS('GB_stLiSTHelp')}
                    url={this.state.helpDialogURL}
                    footer={
                        <div>
                            <div style={{float: 'right'}}>
                                <a href={(this.props.appState.language === CONSTANTS.GB_English) ? 'LiSTOnlineHelpEnglish/HTML/index.html' : 'LiSTOnlineHelpFrench/HTML/index.html'}
                                   target="_blank" rel="noopener noreferrer">{RS('GB_stViewCompleteHelp')}</a>
                            </div>
                            <div style={{float: 'left', paddingRight: '163px'}}>
                                <a href={'https://listvisualizer.org/'} target={'_blank'}>{RS('GB_stGoToLiSTVisualizer')} </a>
                            </div>
                        </div>
                    }
                    onClose={() => {
                        this.setState({
                            dialogHelpOpen : false,
                            helpDialogURL  : "what_is_list.htm",
                        });
                    }}
                >
                </DraggableDocumentWindow>
            </div>
        );
    }
}

export default Main;