import React from 'react';
import PropTypes from 'prop-types';
import AddCircle from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FolderOpen from "@material-ui/icons/FolderOpen";
import Create from "@material-ui/icons/Create";
import ContentCopy from "@material-ui/icons/FileCopy";
import FileDownload from "@material-ui/icons/GetApp";
import Delete from "@material-ui/icons/Delete";
import TCheckBox from "@common/components/TCheckBox";
import TButton from "@common/components/TButton";
import TPaper from "@common/components/TPaper";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

class ActionsDialog extends React.Component {

    static propTypes = {
        topicList            : PropTypes.array,
        onStateChange        : PropTypes.func,
        onSaveTopic          : PropTypes.func,
        fileForActions       : PropTypes.string,
        actionNotes          : PropTypes.string,
        chosenTags           : PropTypes.string,
        onCopyProjection     : PropTypes.func,
        onRenameProjection   : PropTypes.func,
        onOpenProjection     : PropTypes.func,
        onDeleteProjection   : PropTypes.func,
        onDownloadProjection : PropTypes.func,
        passNewTag           : PropTypes.func,
    };

    static defaultProps = {
        topicList             : [],
        onStateChange         : () => console.log("onStateChange"),
        onSaveTopic           : () => console.log("onSaveTopic"),
        fileForActions        : "",
        actionNotes           : "",
        chosenTags            : "",
        onCopyProjection      : () => console.log('onCopyProjection'),
        onRenameProjection    : () => console.log('onRenameProjection'),
        onOpenProjection      : () => console.log("onOpenProjection"),
        onDeleteProjection    : () => console.log("onDeleteProjection"),
        onDownloadProjection  : () => console.log("onDownloadProjection"),
        passNewTag            : () => console.log("passNewTag"),
    };

    state = {
        chosenTags            : this.props.chosenTags.split(", "),
        topicList             : this.props.topicList,
        tagsHaveChanged       : false,
        tagLimit              : 10,
        saveBtnPos            : 5
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        let arr = JSON.parse(JSON.stringify(this.state.chosenTags));
        let topics = this.state.topicList ? JSON.parse(JSON.stringify(this.state.topicList)) : [];
        if (newProps.newTag.length > 0) {
            arr.push(newProps.newTag);
            topics.push(newProps.newTag);
            this.props.passNewTag("");
            arr.sort(function(a, b) {
                let scale = 0;
                if (a.toLowerCase() < b.toLowerCase()) {
                    scale = -1;
                } else {
                    scale = 1;
                }
                return scale;
            });
            topics.sort(function(a, b) {
                let scale = 0;
                if (a.toLowerCase() < b.toLowerCase()) {
                    scale = -1;
                } else {
                    scale = 1;
                }
                return scale;
            });
            this.setState({
                chosenTags: arr,
                topicList: topics,
                tagsHaveChanged: true
            });
        }
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({dialogActionsOpen: false});
    };

    onAddBtnClick = () => {
        if (this.state.topicList.length === this.state.tagLimit) {
            alert(RS("GB_stTagLimit") + ": " + this.state.tagLimit);
        } else {
            this.props.onStateChange({dialogNewTagOpen: true});
        }
    };

    onCheckBoxChange = (tag) => {
        let tags = JSON.parse(JSON.stringify(this.state.chosenTags));
        if (tags.includes("")) {
            tags.shift();
        }
        if (tags.length > this.state.tagLimit - 1 && !tags.includes(tag)) {
            alert(RS("GB_stTagLimit") + ": " + this.state.tagLimit);
        } else {
            if (tags.includes(tag)) {
                for (let i = tags.length - 1; i >= 0; i--) {
                    if (tags[i] === tag) {
                        tags.splice(i, 1);
                    }
                }
                //this.props.onSaveTopic(tag, this.props.fileForActions, tags.length > 0 ? tags.join(", ") : "");
            } else {
                tags.push(tag);
                tags.sort(function (a, b) {
                    let scale = 0;
                    if (a.toLowerCase() < b.toLowerCase()) {
                        scale = -1;
                    } else {
                        scale = 1;
                    }
                    return scale;
                });
                //this.props.onSaveTopic(tag, this.props.fileForActions);
            }
            this.setState({
                chosenTags: tags,
                tagsHaveChanged: true
            }, () => this.props.onStateChange({chosenTags: tags.length > 0 ? tags.join(", ") : ""}));
        }
    };

    onSaveTags = () => {
        this.props.onSaveTopic("", this.props.fileForActions, this.state.chosenTags.length > 0 ? this.state.chosenTags.join(", ") : "");
        this.setState({tagsHaveChanged: false});
    };

    onOpenButtonClick = () => {
        this.props.onOpenProjection(this.props.fileForActions);
        this.onClose();
    };

    onDeleteButtonClick = () => {
        // this.props.onDeleteProjection(this.props.fileForActions);
        // this.onClose();
        this.props.onStateChange({
            dialogDeletionCheckOpen: true,
            fileToDelete: this.props.fileForActions
        });
    };

    onDownloadButtonClick = () => {
        this.props.onDownloadProjection(this.props.fileForActions);
        this.setState({actionsOpen: -1});
    };

    onCopyButtonClick = () => {
        this.props.onStateChange({
            dialogFileCopyOpen: true,
            fileToCopy: this.props.fileForActions
        });
    };

    onRenameButtonClick = () => {
        this.props.onStateChange({
            dialogFileRenameOpen: true,
            fileToRename: this.props.fileForActions
        });
    };

    onTagBoxScroll = (e) => {
        this.setState({saveBtnPos: 5 - e.target.scrollTop});
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderActionBox = () => {
        let styles = {
            box: {
                display: "inline-block",
                width: 300,
                margin: 3,
                marginTop: 12,
                marginBottom: 9
                // margin:"15px"
            }
        };

        return (
            <div>
                {/*{this.renderActionButtons()}*/}
                <div style={styles.box}>
                    {this.renderTagBox()}
                    {this.renderNotes()}
                </div>
            </div>
        );
    };

    renderActionButtons = () => {
        let styles = {
            caption: {
                color:"black",
                marginLeft:"5px"
            },
            btn: {
                display: "block"
            },
            targ: {
                fontSize:"18px"
            },
            btnBox: {
                display: "inline-block",
                // margin:"15px",
                verticalAlign:"top",
                marginRight: '15px'
            },
            tooltip: {
                padding: "0 5px",
                borderRadius: 4,
                display: "block"
            }
        };

        return (
            <div style={styles.btnBox}>
                <IconButton style={styles.tooltip} title={RS("GB_stOpen")}>
                    <TButton
                        transparent={true}
                        shape="circle"
                        pictureLeft={<FolderOpen style={{color: "#65A7EB"}} />}
                        onClick={() => this.onOpenButtonClick()}
                        caption={RS("GB_stOpen")}
                        captionStyles={styles.caption}
                        containerStyle={styles.btn}
                        style={styles.targ}
                    />
                </IconButton>

                <IconButton style={styles.tooltip} title={RS("GB_stRename")}>
                    <TButton
                        transparent={true}
                        shape="circle"
                        pictureLeft={<Create style={{color: "#F78C30"}} />}
                        onClick={() => this.onRenameButtonClick()}
                        caption={RS("GB_stRename")}
                        captionStyles={styles.caption}
                        containerStyle={styles.btn}
                        style={styles.targ}
                    />
                </IconButton>

                <IconButton style={styles.tooltip} title={RS("GB_stDuplicate")}>
                    <TButton
                        transparent={true}
                        shape="circle"
                        pictureLeft={<ContentCopy style={{color: "#65a7eb"}} />}
                        onClick={() => this.onCopyButtonClick()}
                        caption={RS("GB_stDuplicate")}
                        captionStyles={styles.caption}
                        containerStyle={styles.btn}
                        style={styles.targ}
                    />
                </IconButton>

                <IconButton style={styles.tooltip} title={RS("GB_stDownload")}>
                    <TButton
                        transparent={true}
                        shape="circle"
                        pictureLeft={<FileDownload style={{color: "#9CC025"}} />}
                        onClick={() => this.onDownloadButtonClick()}
                        caption={RS("GB_stDownload")}
                        captionStyles={styles.caption}
                        containerStyle={styles.btn}
                        style={styles.targ}
                    />
                </IconButton>

                <IconButton style={styles.tooltip} title={RS("GB_stDelete")}>
                    <TButton
                        transparent={true}
                        shape="circle"
                        pictureLeft={<Delete style={{color: "#C03600"}} />}
                        onClick={() => this.onDeleteButtonClick()}
                        caption={RS("GB_stDelete")}
                        captionStyles={styles.caption}
                        containerStyle={styles.btn}
                        style={styles.targ}
                    />
                </IconButton>
            </div>
        )
    };

    renderTagBox = () => {
        let tags = [];
        let topics = [];

        this.state.topicList.forEach((tag) => {
            let allTags = tag.split(", ");
            allTags.forEach(function(top) {
                if (!topics.includes(top)) {
                    topics.push(top);
                }
            });
        });

        topics.sort((a, b) => {
            let scale = 0;
            if (a.toLowerCase() < b.toLowerCase()) {
                scale = -1;
            } 
            else {
                scale = 1;
            }
            return scale;
        });

        topics.forEach((topic) => {
            tags.push(
                <TCheckBox
                    key={topics.indexOf(topic)}
                    caption={topic}
                    value={this.state.chosenTags.includes(topic)}
                    onClick={() => this.onCheckBoxChange(topic)}
                />
            );
        });

        let styles = {
            tagBox: {
                //maxHeight:this.props.actionNotes.length > 0 ? "150px" : "300px",
                minHeight:"100px",
                maxHeight: 387,
                height: "auto",
                overflowY:"auto",
                margin:"auto",
                marginBottom: 0,
                padding:"10px",
                // border:"1px solid black",
                position:"relative",
                width:"300px",
            },
            addBtn: {
                float: 'right',
                height: '30px',
                width: '30px',
                cursor: 'pointer',
                marginTop:"-5px",
                color: '#f78c30',
                padding : 0,
            },
            saveBtn: {
                // float:"right",
                position:"absolute",
                bottom: this.state.saveBtnPos,
                right:"10px",
                zIndex:"1"
            },
            boxTitle: {
                fontSize: "18px",
                display:"block",
                paddingTop:"0px",
                marginBottom:"3px"
            }
        };

        return (
            <TPaper
                style={styles.tagBox}
                onScroll={this.onTagBoxScroll}
            >
                <Tooltip title={RS("GB_stAddNewTag")}>
                    <IconButton style={styles.addBtn}>
                        <AddCircle
                            onClick={this.onAddBtnClick}
                            style={styles.addBtn}
                        />
                    </IconButton>
                </Tooltip>
                <span className={"pageHeader"} style={styles.boxTitle}>{RS("GB_stTags")}</span>
                {tags}
            </TPaper>
        )
    };

    renderNotes = () => {
        let styles = {
            noteBox: {
                width: "350px",
                maxHeight:"150px",
                overflowY: "auto",
                margin:"10px",
                padding:"10px",
                //border:"1px solid black",
                display: this.props.actionNotes.length > 0 ? "block" : "none"
            },
            boxTitle: {
                fontSize: "18px",
                display:"block",
                paddingTop:"0px",
                marginBottom:"3px"
            }
        };

        return (
            <TPaper style={styles.noteBox}>
                <span className={"pageHeader"} style={styles.boxTitle}>{RS("GB_stNotes")}</span>
                {/*<hr style={{color:"lightgray"}} />*/}
                {this.props.actionNotes}
            </TPaper>
        );
    };

    render() {
        let widthStyle = {
            minWidth: 0
        };

        return (
            <CSDialog
                open={true}
                modal={true}
                title={/*RS("GB_stTags") + " " + RS("GB_stFor") + ": " + */(this.props.fileForActions.length > 20 ? this.props.fileForActions.substr(0, 20) + "..." : this.props.fileForActions)}
                onClose={this.onClose}
                titleStyle={widthStyle}
                contentStyle={widthStyle}
                actionStyle={{...widthStyle, paddingTop: 0}}
                minWidth={350}
                content={
                    <div>
                        {this.renderActionBox()}
                    </div>
                }
                actions={
                    <div>
                        <ApplyButton onClick={this.onSaveTags} disabled={!this.state.tagsHaveChanged} />
                    </div>
                }
            />
        );
    }
}

export default ActionsDialog;