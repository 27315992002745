import * as React from 'react';
import PropTypes from 'prop-types';
import TDialog from '@common/components/TDialog';
import TWifiAnimation from '@common/components/TWifiAnimation';
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class TAPIDialog extends React.Component {
    
    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        runningTasks    : PropTypes.array,
        selectedCountry : PropTypes.string,
        status          : PropTypes.object,
        apiMainMsg      : PropTypes.string,
        apiSubMsg       : PropTypes.string,
        language        : PropTypes.number
    };

    static defaultProps = {
        runningTasks    : ['API Task 1', 'API Task 2', 'API Task 3'],
        selectedCountry : '',
        status          : {},
        apiMainMsg      : {},
        apiSubMsg       : {},
        language        : CONSTANTS.GB_English
    }; 

    state = {
        title           : RS('GB_stCommunicatingWithServer'),
        waitMessage     : null,
        timer           : null
    };

    componentDidMount() {
        let t = this;
        this.setState({timer: setTimeout(function() {
            t.setState({waitMessage: RS('GB_stThisWillTakeAMoment')});
        }, 5000)})
    };

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        let styles = {
            dialog : {
                width : this.props.language === CONSTANTS.GB_English ? 500 : 590
            },
            title : {
                fontSize : '20px'
            },
            icon : {
                marginRight: '20px',  
                float: 'left'
            },
            message : {
                fontSize: '12px'
            },
            message1 : {
                fontSize: '12px',
                fontStyle: 'italic',
                color: 'gray'
            }
        };

        let sMain = this.props.apiMainMsg;
        let sSub= this.props.apiSubMsg;

        if (sMain === '') {
            sMain = sSub;
            sSub = '';
        }

        return (
            <TDialog
                caption={
                    <div style={styles.title}>{this.state.title}</div>
                }
                icon={
                    <TWifiAnimation style={styles.icon} />
                }
                message={
                    sMain + '...'
                }
                content={
                <div>
                    <div style={styles.message1}>
                        {
                        (sMain === RS('GB_stCreateProjectionFlag')) ?
                            // RS('GB_stThisWillTakeAMoment')
                            this.state.waitMessage
                            :
                            null
                        }
                    </div>

                    <div style={styles.message}>
                        <br />
                        {/* Server message: {this.props.status.serverMsg}
                        <br />
                        Session message: {this.props.status.sessionMsg}
                        <br /> */}
                        {
                            (sSub !== '') ? RS('GB_stWorkingOn') + ' ' + sSub :  null
                        }
                        {/* <br />
                        Percent: {this.props.status.percent} */}
                    </div>
                </div>
                }
                model={true}
                visible={this.props.show}
                actions={[]}
                style={styles.dialog}
            />
        );

    }
}

export default TAPIDialog;