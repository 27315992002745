import React from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/core/styles";
import RS from "@common/strings/global";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import TLabel from "@common/components/TLabel";
import { clone } from "@common/util/GBUtil";
import Stepper from "../components/Stepper";
import ButtonApply from '../components/buttons/ButtonApply';
import ButtonNext from "../components/buttons/ButtonNext";
import ButtonBack from "../components/buttons/ButtonBack";
import ResultCheckBoxes from "../components/Indicators/ResultCheckBoxes";
import YourResults from "../components/Indicators/YourResults";
import Theme from "@common/theme/CSTheme";
import TGBProjectionTable from "../../../components/GB/TGBProjectionTable";
import TButton from "../../../@common/components/TButton";

const useStyles = makeStyles(theme => ({
    yourResultsOuter : {
        float: 'left',
        '@media (max-width:1120px)': { 
            marginTop: 20,
        },
    },
    yourResultsInner : {
        position: 'fixed',
        '@media (max-width:1120px)': { 
            position: 'static',
        },
    },
}));

const styles = {
    content : {
        marginTop: 20,
        maxWidth: 615,
        minWidth: 615,
        marginLeft: 10,
    },
    fileBox: {
        border: "1px solid lightgray",
        borderRadius: 4,
        margin: "15px 0",
        padding: 10,
        paddingLeft: 0,
        minHeight: 300,
    },
    add: {
        cursor: "pointer",
        verticalAlign: "middle",
        marginTop: 2,
        marginLeft: 5,
        display: "inline-block",
    },
    label: {
        fontSize: 16,
        fontWeight: "bold",
        cursor: "pointer",
        //color: Theme.palette.secondary.main,
        position: "relative",
        top: 2
    },
    applyButton : {
        float: "right",
        margin:5,
        fontSize: 16
    },
    buttonBack : {
        float: "right",
    },
    buttonNext : {
        float: "right",
        marginLeft: 15,
    },
    listItem: {
        fontSize: 14
    },
    close: {
        cursor: "pointer",
        color: Theme.palette.secondary.main,
        verticalAlign: "middle",
        marginRight: -1,
        marginTop: -2
    },
    section0 : {
        float: 'left',
    },
    section1 : {
        float: 'left',
    },
    section2 : {
        position: 'fixed'
    },
    button: {
        color: '#000000',
        textTransform: 'none',
        fontSize: '16px',
        padding : '10px 0',
        margin: '10px 0',
        marginLeft: '-10px'
    },
    captionStyles : {
        marginLeft: '10px',
        marginRight: '10px'
    }
};  

const resultGroupNames = [
    [
      1,
      "Neonatal",
      false,
      "",
      false,
      false
    ],
    [
      2,
      "Child",
      false,
      "",
      false,
      false
    ],
    [
      3,
      "Maternal",
      false,
      "",
      false,
      false
    ],
    [
      4,
      "Stillbirth",
      false,
      "",
      false,
      false
    ],
    [
      5,
      "Mortality rates",
      false,
      "",
      false,
      false
    ],
    [
      6,
      "Nutrition",
      false,
      "",
      false,
      false
    ],
    [
      7,
      "Incidence and etiology",
      false,
      "",
      false,
      false
    ],
    [
      0,
      "Demography",
      false,
      "",
      false,
      false
    ]
  ];

const checkBoxResultLists = [
    [
      [
        "Neonatal deaths",
        1,
        15
      ],
      [
        "Neonatal deaths by cause",
        3,
        15
      ],
      [
        "Additional neonatal lives saved",
        2,
        15
      ],
      [
        "Additional neonatal lives saved by cause",
        4,
        15
      ],
      [
        "Additional neonatal lives saved by intervention",
        5,
        15
      ],
      [
        "Additional neonatal lives saved by intervention by cause",
        50,
        15
      ]
    ],
    [
      [
        "Child deaths",
        7,
        15
      ],
      [
        "Child deaths by cause",
        9,
        15
      ],
      [
        "Additional child lives saved",
        8,
        15
      ],
      [
        "Additional child lives saved by cause",
        10,
        15
      ],
      [
        "Additional child lives saved by intervention",
        11,
        15
      ],
      [
        "Additional child lives saved by intervention by cause",
        51,
        15
      ]
    ],
    [
      [
        "Maternal deaths",
        14,
        15
      ],
      [
        "Maternal deaths by cause",
        16,
        15
      ],
      [
        "Additional maternal lives saved",
        15,
        15
      ],
      [
        "Additional maternal lives saved by cause",
        17,
        15
      ],
      [
        "Additional maternal lives saved by intervention",
        18,
        15
      ],
      [
        "Additional maternal lives saved by intervention by cause",
        52,
        15
      ],
      [
        "Abortions",
        19,
        15
      ]
    ],
    [
      [
        "Stillbirths",
        20,
        15
      ],
      [
        "Stillbirths prevented",
        21,
        15
      ],
      [
        "Stillbirths prevented by intervention",
        24,
        15
      ]
    ],
    [
      [
        "Neonatal mortality rate (deaths per 1,000 live births)",
        27,
        15
      ],
      [
        "Infant mortality rate (deaths per 1,000 live births)",
        28,
        15
      ],
      [
        "Under five mortality rate (deaths per 1,000 live births)",
        29,
        15
      ],
      [
        "Maternal mortality ratio (deaths per 100,000 live births)",
        26,
        15
      ],
      [
        "Maternal mortality rate (deaths per 100,000 women aged 15-49)",
        63,
        15
      ],
      [
        "Stillbirth rate (stillbirths per 1,000 total births (live and stillbirths))",
        25,
        15
      ]/*,
      [
        "Mortality rates summary",
        30,
        15
      ],
      [
        "Reduction in mortality by intervention",
        31,
        15
      ]*/
    ],
    [
      [
        "Birth Outcomes (percent)",
        69,
        15
      ],
      [
        "Low birth weight",
        155,
        15
      ],
      [
        "Percent of children in different height for age statuses (Stunting)",
        70,
        15
      ],
      [
        "Percent of children in different weight for height statuses (Wasting)",
        71,
        15
      ],
      [
        "Global stunting (<-2 SD) rate",
        123,
        15
      ],
      [
        "Global wasting (<-2 SD) rate",
        124,
        15
      ],
      [
        "Number of stunted children",
        126,
        15
      ],
      [
        "Number of stunting cases averted",
        127,
        15
      ],
      [
        "Number of stunting cases averted by intervention",
        128,
        15
      ],
      [
        "Breastfeeding prevalence",
        35,
        15
      ],
      [
        "Percent of children (<6 mo) exclusively breastfed",
        121,
        15
      ],
      [
        "Prevalence of early initiation of breastfeeding",
        122,
        15
      ],
      [
        "Percent of women with anemia",
        118,
        15
      ],
      [
        "Number of women with anemia",
        119,
        15
      ],
      [
        "Number of anemia cases prevented",
        120,
        15
      ]
    ],
    [
      [
        "Incidence rates",
        83,
        15
      ],
      [
        "Child cases by cause",
        84,
        15
      ]//,
      // [
      //   "Child cases by intervention by cause",
      //   85,
      //   15
      // ],
      // [
      //   "Percent of 1-59 month deaths by pathogen",
      //   86,
      //   15
      // ],
      // [
      //   "Percent of 1-59 month cases by pathogen",
      //   87,
      //   15
      // ],
      // [
      //   "Number of 1-59 month deaths by pathogen",
      //   153,
      //   15
      // ],
      // [
      //   "Number of 1-59 month cases by pathogen",
      //   154,
      //   15
      // ]
    ],
    [
      [
        "Total population",
        24,
        1
      ],
      [
        "Population aged 0-4",
        27,
        1
      ],
      [
        "Total fertility rate",
        41,
        1
      ],
      [
        "Life expectancy",
        10,
        1
      ],
      [
        "Total Births",
        22,
        1
      ]
    ]
  ];

const IndicatorsPage = (props) => {
    const { onPageChange, onStateChange, onStepChange, stepsCompleted, indicatorsSelected, Theme } = props;

    const [openIndicators, setOpenIndicators] = React.useState(false);
    const [indSelected, setIndSelected] = React.useState(indicatorsSelected);

    const classes = useStyles(props);

    const onIndicatorsOpen = () => {
        setOpenIndicators(true);
    };

    const onSelectAllChanged = (arrayOfIndicators) => {
        setIndSelected(arrayOfIndicators);
    };  

    const onIndicatorsChanged = (checked, caption, modID, mstID) => {
        let x = clone(indSelected);

        if (checked) {
            x.push([mstID, modID, caption]);
            setIndSelected(x);
        }
        else {
            x = x.filter(x => (x[0] === mstID) && (x[1] === modID) ? false : true);
            setIndSelected(x);
        }
    };

    const onIndicatorsClose = () => {
        setIndSelected(indicatorsSelected);
        setOpenIndicators(false);
    };

    const onIndicatorsApply = () => {
        onStateChange({
            indicatorsSelected : indSelected
        });
        setOpenIndicators(false);
    };

    const clearAllIndicators = () => {
        setIndSelected([]);
    };
    
    const onRemoveIndicatorFromList = (value) => {
        let arr = indSelected.filter(x => {
            if (x[2] === value || (Array.isArray(value) && value.includes(x[2]))) {
            //if ((x[0] === value[0]) && (x[1] === value[1]) && (x[2] === value[2])) {
                return false;
            }
            return true;
        });

        onStateChange({
            indicatorsSelected : arr
        });
        setIndSelected(arr);
    };

    const onNextButtonClick = () => {
        // let newStepsCompleted = clone(stepsCompleted);
        // newStepsCompleted.push(1);

        // onStateChange({
        //     stepsCompleted : newStepsCompleted
        // });
        
        onPageChange("ConfigurationPage");
    };

    const getYourResultNames = () => {
        const yourResultNames = indSelected.map((x, i) => (
            <TLabel
                caption={x[2]}
                style={styles.labelStyle}
                key={i}
            />
        ));

        return yourResultNames;
    };

    return (
        <React.Fragment>
            <Stepper
                steps={[RS('GB_stChooseProj'), RS('GB_stSelectIndicators'), RS('GB_stSetConfig'), RS('GB_stProcess')]}
                activeStep={1}
                completedSteps={stepsCompleted}
                disabledSteps={indicatorsSelected.length === 0 ? [3, 4] : []}
                onStepBtnClick={onStepChange}
                useAltColors={true}
                Theme={Theme}
            />

            <div style={styles.content}>
                {/*<label onClick={onIndicatorsOpen} style={styles.label}>*/}
                {/*    {RS("GB_stSelectIndicators")}*/}
                {/*</label>*/}

                {/*<Tooltip style={styles.add} title={RS("GB_stAdd")}>*/}
                {/*    <AddCircle*/}
                {/*        color={"primary"}*/}
                {/*        onClick={onIndicatorsOpen}*/}
                {/*    />*/}
                {/*</Tooltip>*/}

                <TButton
                    caption={RS('GB_stSelectIndicators')}
                    variant={"text"}
                    style={styles.button}
                    captionStyles={styles.captionStyles}
                    containerStyle={{marginBottom: -10}}
                    onClick={onIndicatorsOpen}
                    pictureRight={<AddCircle
                        color={"primary"}
                        //onClick={onIndicatorsOpen}
                    />}
                />

                {/*{*/}
                {/*    indicatorsSelected.length > 0 ?*/}
                        <div style={{marginBottom: 20}}>
                            <TGBProjectionTable
                                storedProjections={indicatorsSelected.map(x => {return {name: x[2], lastSaved: new Date()}})}
                                tableTitle={RS("GB_stSelInds")}
                                showDetailsSwitch={false}
                                showRegion={false}
                                showTimeframe={false}
                                showLastSave={false}
                                showTags={false}
                                showFilterTagsBtn={false}
                                showUploadBtn={false}
                                meatballOptions={["Delete"]}
                                deleteMsg={RS("GB_stRemove")}
                                deleteAllMsg={RS("GB_stRemoveAll")}
                                dialogDelAll={RS("GB_stSureRemoveAllInd")}
                                onDeleteProjection={onRemoveIndicatorFromList}
                            />
                        </div>
                        {/*:*/}
                        {/*<React.Fragment>*/}
                        {/*    <br /><br />*/}
                        {/*</React.Fragment>*/}
                {/*}*/}

                {/*<div style={styles.fileBox}>*/}
                {/*    {*/}
                {/*        indicatorsSelected.map(x => (*/}
                {/*            <div key={x}>*/}
                {/*                <Tooltip title={RS("GB_stRemove")} style={styles.close} className="projectionsCloseButton">*/}
                {/*                    <Close onClick={onRemoveIndicatorFromList(x)} />*/}
                {/*                </Tooltip>*/}
                {/*                <span style={styles.listItem}>{x[2]}</span>*/}
                {/*            </div>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</div>*/}

                <ButtonNext
                    onClick={onNextButtonClick}
                    disabled={indicatorsSelected.length === 0}
                    containerStyle={styles.buttonNext}
                />

                <ButtonBack
                    onClick={() => onPageChange("ProjectionsPage")}
                    containerStyle={styles.buttonNext}
                    colorType="secondary"
                />
            </div>

            {
                (openIndicators) ?
                    <TDrawerWithHeader
                        open={true}
                        appBarText={""}
                        showSaveBtn={false}
                        showExtraBtn={true}
                        contentExtraBtn={
                            <ButtonApply
                                onClick={onIndicatorsApply}
                                containerStyle={styles.applyButton}
                            />
                        }
                        showHelp={false}
                        onClose={onIndicatorsClose}
                        content={
                            <div style={{padding: 20}}>
                                <div style={styles.section0}>
                                    <ResultCheckBoxes
                                        resultGroupNames={resultGroupNames}
                                        selectedResults={indSelected}
                                        checkBoxResultLists={checkBoxResultLists}
                                        onCheckBoxChange={onIndicatorsChanged}
                                        onSelectAllChanged={onSelectAllChanged}
                                        directions={RS('GB_stChooseIndicators')}
                                    />
                                </div>

                                <div className={classes.yourResultsOuter}>
                                    <div className={classes.yourResultsInner}>
                                        <YourResults
                                            yourResultNames={getYourResultNames()}
                                            onClearResultsClick={clearAllIndicators}
                                            title={RS("GB_stYourSelectedIndicators")}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    />
                    : 
                    null
            }

        </React.Fragment>
    );
};

export default IndicatorsPage;