const synchronizeColumnWidths = columns => {
    const synchronizedColumns = [];

    for (let column of columns) {
        let width = column.width || 0;

        let synchronizedChildren;

        if (column.children) {
            synchronizedChildren = synchronizeColumnWidths(column.children);

            const combinedWidthOfChildren = synchronizedChildren.reduce((result, child) => result + child.width, 0);

            width = Math.max(width, combinedWidthOfChildren);

            for (let synchronizedChild of synchronizedChildren) {
                synchronizedChild.width += (width - combinedWidthOfChildren) / synchronizedChildren.length;
            }
        }

        synchronizedColumns.push({
            ...column,
            width,
            children: synchronizedChildren
        });
    }

    return synchronizedColumns;
};

export default synchronizeColumnWidths;
