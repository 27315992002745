import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import TCheckBox from "@common/components/TCheckBox";
import TLabel from "@common/components/TLabel";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import {MatEfficacyEditor} from "../../data/Effectiveness/MatEfficacyEditor";
import {SBEfficacyEditor} from "../../data/Effectiveness/SBEfficacyEditor";
import {EfficacyEditor} from "../../data/Effectiveness/EfficacyEditor";

class CSEffectivenessCoverageDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                     : PropTypes.object,
        packTable2                     : PropTypes.object,
        packTable3                     : PropTypes.object,
        packTable4                     : PropTypes.object,
        onPackTable1Change             : PropTypes.func,
        onPackTable2Change             : PropTypes.func,
        onPackTable3Change             : PropTypes.func,
        onPackTable4Change             : PropTypes.func,
        selectedIVSet                  : PropTypes.array,
        IVInfo                         : PropTypes.arrayOf(PropTypes.object),
        CSModvars                      : PropTypes.array,
        showAllInterventionsCB         : PropTypes.bool,
        language                       : PropTypes.number,
        editorComboBoxIdx              : PropTypes.number,
        onEditorComboboxChange         : PropTypes.func,
        onShowAllInterventionsCBChange : PropTypes.func,
        withinTab                      : PropTypes.bool

    };

    static defaultProps = {
        packTable1                     : null,
        packTable2                     : null,
        packTable3                     : null,
        packTable4                     : null,
        onPackTable1Change             : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change             : (packTable) => console.log('onPackTable2Change'),
        onPackTable3Change             : (packTable) => console.log('onPackTable3Change'),
        onPackTable4Change             : (packTable) => console.log('onPackTable4Change'),
        selectedIVSet                  : [],
        IVInfo                         : [],
        CSModvars                      : [],
        showAllInterventionsCB         : false,
        language                       : CONSTANTS.GB_English,
        editorComboboxIdx              : 0,
        onEditorComboboxChange         : () => console.log('onEditorComboboxChange'),
        onShowAllInterventionsCBChange : () => console.log('onShowAllInterventionsCBChange'),
        withinTab                      : false
    };

    state = {
        // editorIdx        : 0,
        // checkboxChecked  : this.props.showAllInterventionsCB,
    };
   
    effectivenessEditors = [];

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onComboboxChange = (value) => {
        // this.setState({
        //     editorIdx : value
        // });
        this.props.onEditorComboboxChange(value)
    };

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable);
    };

    onPackTable2Change = (packTable) => {
        this.props.onPackTable2Change(packTable);
    };

    onPackTable3Change = (packTable) => {
        this.props.onPackTable3Change(packTable);
    };

    onPackTable4Change = (packTable) => {
        this.props.onPackTable4Change(packTable);
    };

    onShowAllInterventionsCBChange = (ShowAllItems) => {
        this.props.onShowAllInterventionsCBChange(ShowAllItems)
    };

    onCheckBoxClick = () => {
        let ShowAllItems = !this.props.showAllInterventionsCB; //!this.state.checkboxChecked;

        let ModvarsArray1 = MatEfficacyEditor.GetSSData(this.props.packTable1, this.props.CSModvars);
        let ModvarsArray2 = SBEfficacyEditor.GetSSData(this.props.packTable2, this.props.CSModvars);
        let ModvarsArray3 = EfficacyEditor.GetSSData(this.props.packTable3, this.props.CSModvars);

        let changedCSModvars = this.props.CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            let c = ModvarsArray3.find(y => y.tag === x.tag);
            return a || b || c || x;
        });

        let ModvarsArray4 = EfficacyEditor.GetSSData(this.props.packTable4, changedCSModvars);

        let changedCSModvars2 = changedCSModvars.map(x => {
            let d = ModvarsArray4.find(y => y.tag === x.tag);
            return d || x;
        });

        let packTable1 = MatEfficacyEditor.Create(this.props.IVInfo, changedCSModvars2, this.props.language, 0, ShowAllItems)[CONSTANTS.CS_PackTable];
        let packTable2 = SBEfficacyEditor.Create(this.props.IVInfo, changedCSModvars2, this.props.language, 0, ShowAllItems)[CONSTANTS.CS_PackTable];
        let packTable3 = EfficacyEditor.Create(this.props.IVInfo, changedCSModvars2, this.props.language, CONSTANTS.CS_MstEfficacyNeonatal, 0, ShowAllItems)[CONSTANTS.CS_PackTable];
        let packTable4 = EfficacyEditor.Create(this.props.IVInfo, changedCSModvars2, this.props.language, CONSTANTS.CS_MstEfficacyPostNeonatal, 0, ShowAllItems)[CONSTANTS.CS_PackTable];

        this.onPackTable1Change(packTable1);
        this.onPackTable2Change(packTable2);
        this.onPackTable3Change(packTable3);
        this.onPackTable4Change(packTable4);

        this.onShowAllInterventionsCBChange(ShowAllItems);
    };

    //==================================================================================================================
    //
    //                                                 Functions
    //
    //==================================================================================================================

    // getIsThereCoverageEffectiveness = () => {
    //
    //     let effectivenessRelevant = false;
    //
    //     for (let i = 0; i < this.props.IVInfo.length; i++) {
    //         if (this.props.selectedIVSet.includes(this.props.IVInfo[i].MstID)){
    //             if (
    //                 (this.props.IVInfo[i][CONSTANTS.CS_MaternalEff_ED]) || (this.props.IVInfo[i][CONSTANTS.CS_StillbirthEff_ED]) ||
    //                 (this.props.IVInfo[i][CONSTANTS.CS_NNEff_ED]) ||(this.props.IVInfo[i][CONSTANTS.CS_PNNEff_ED])
    //             ){
    //                 effectivenessRelevant = true;
    //                 break;
    //             }
    //         }
    //     }
    //
    //     return (
    //         effectivenessRelevant
    //     )
    // };
    
    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderNote = () => {
        const styles = {
            noteStyle: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#cb6814',
                userSelect: 'none',
                display: 'inline'
            },
            labelStyle: {
                fontSize: '16px',
                color: '#000000',
                userSelect: 'none',
                display: 'inline',
                marginLeft: '10px'
            },
            whole: {
                marginBottom: 5
            }
        };

        return (
            <div style={styles.whole}>
                <TLabel
                    caption = {RS('GB_stNote')}
                    style   = {styles.noteStyle}
                />
                <TLabel
                    caption = {RS('GB_stSelOutcome')}
                    style   = {styles.labelStyle}
                />
            </div>
        )
    };

    renderCoverageDropdown = () => {

        const styles = {
            comboBox: {
                marginBottom: 20,
                marginTop: 0,
                marginRight: 25,
                paddingLeft: 0,
                width: 435,
                display: 'inline-block'
            },
            headerStyle: {
                color: '#000000',
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: '10px',
                marginBottom: '10px',
                userSelect: 'none'
            }
        };

        // let maternalInt = 0;
        // let stillbirthInt = 0;
        // let neonatalInt = 0;
        // let postNeonatalInt = 0;

        this.effectivenessEditors = [];

        // for (let i = 0; i < this.props.IVInfo.length; i++) {
        //     if (this.props.selectedIVSet.includes(this.props.IVInfo[i].MstID)){
        //         if (this.props.IVInfo[i][CONSTANTS.CS_MaternalEff_ED]) {
        //             maternalInt++;
        //         }
        //
        //         if (this.props.IVInfo[i][CONSTANTS.CS_StillbirthEff_ED]) {
        //             stillbirthInt++;
        //         }
        //
        //         if (this.props.IVInfo[i][CONSTANTS.CS_NNEff_ED]) {
        //             neonatalInt++;
        //         }
        //
        //         if (this.props.IVInfo[i][CONSTANTS.CS_PNNEff_ED]) {
        //             postNeonatalInt++;
        //         }
        //     }
        // }

        // if (maternalInt > 0) {
            this.effectivenessEditors.push(RS('GB_stEffIntMat'));
        // }

        // if (stillbirthInt > 0) {
            this.effectivenessEditors.push(RS('GB_stEffIntSB'));
        // }

        // if (neonatalInt > 0) {
            this.effectivenessEditors.push(RS('GB_stEffIntNN'));
        // }

        // if (postNeonatalInt > 0) {
            this.effectivenessEditors.push(RS('GB_stEffIntChild'));
        // }

        return (
            <React.Fragment>
                <p style={styles.headerStyle}>{this.props.withinTab ? RS("GB_stOutcomeSelect") : RS('GB_stOutcomes')}</p>
                <TComboBox
                    caption="" //RS('GB_stSelectEffEditor')}
                    itemIndex={this.props.editorComboboxIdx}
                    style={styles.comboBox}
                    captionStyle={{color: '#609cd9'}}
                    items={this.effectivenessEditors}
                    onChange={this.onComboboxChange}
                />
            </React.Fragment>
        )
    };

    renderCoverageEffectivenessEditor = () => {

        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));
        let packTable3 = JSON.parse(JSON.stringify(this.props.packTable3));
        let packTable4 = JSON.parse(JSON.stringify(this.props.packTable4));

        let EffTable = null;
        let EffTableId = '';
        let onChange = null;

        if (this.effectivenessEditors[this.props.editorComboboxIdx] === RS('GB_stEffIntMat')) {
            EffTable = packTable1;
            EffTableId = 'MatIntEffSheet';
            onChange = this.onPackTable1Change;
        } 
        else if (this.effectivenessEditors[this.props.editorComboboxIdx] === RS('GB_stEffIntSB')) {
            EffTable = packTable2;
            EffTableId = 'SBIntEffSheet';
            onChange = this.onPackTable2Change;
        } 
        else if (this.effectivenessEditors[this.props.editorComboboxIdx] === RS('GB_stEffIntNN')) {
            EffTable = packTable3;
            EffTableId = 'NNIntEffSheet';
            onChange = this.onPackTable3Change;
        } 
        else if (this.effectivenessEditors[this.props.editorComboboxIdx] === RS('GB_stEffIntChild')) {
            EffTable = packTable4;
            EffTableId = 'PNNIntEffSheet';
            onChange = this.onPackTable4Change;
        }

        return (
            <CSEditor
                mstID={CONSTANTS["CS_MstLiSTOnline" + EffTableId + "ID"]}
                showTitle={true}
                packTable={EffTable}
                onChange={onChange}
            />
        )
    };

    renderCheckBox = () => {
        const styles = {
            checkBox: {
                marginTop: 10,
                marginBottom: 20,
                display: "block"//this.state.specialCases.includes(targ) ? "block" : "none"
            },
            checkBoxLabelStyle: {
                fontSize: '16px',
                cursor: "default"
            }
        };

        return (
            <TCheckBox
                style={styles.checkBox}
                boxStyle={styles.box}
                color='secondary'
                caption={RS('GB_stShowAll')}//GetCheckboxName(targ)}
                tooltip={""}
                labelStyle={styles.checkBoxLabelStyle}
                onClick={() => this.onCheckBoxClick()}
                value={this.props.showAllInterventionsCB} //this.state.checkboxChecked}
            />
        )
    };

    render() {

        const styles = {
            paragraph1 : {
                fontSize: 16,
            },
        };

        // if (!(this.getIsThereCoverageEffectiveness())) {
        //     return (
        //         <div className="innerPage">
        //             <p style={styles.paragraph1}>
        //                 {RS('GB_stCurrentlyNoCovEff')}
        //             </p>
        //         </div>
        //     )
        // }
        // else {
            return (
                <div className={this.props.withinTab ? null : "innerPage"}>

                    {!this.props.withinTab && this.renderNote()}

                    {this.renderCoverageDropdown()}
                    <br />
                    {this.renderCheckBox()}
                    <br />
                    {this.renderCoverageEffectivenessEditor()}
                </div>
            );
        // }
    }

}

export default CSEffectivenessCoverageDrawer;