import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

export default {
    exploreSnapshotContent : [
        {
            mstID : CONSTANTS.CS_MstOverallInformation_ED, 
            name : 'GB_stOverallInformation', 
            tabs : [], 
            checked : false
        },
    ],
    exploreCODContent: [
        {   
            mstID     : CONSTANTS.CS_MstMaternalMortalityExData,
            name      : 'GB_stMaternalMortality',
            title     : 'GB_stMaternalCause',
            editorID  : CONSTANTS.CS_MstMatDeathsByCauseSheetID,
            modvarTag : [CONSTANTS.CS_TG_PercMatDeathsByCauseLiSTOnline_MV, CONSTANTS.CS_TG_MatMortRatioLiSTOnline_MV2],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_MstStillbirthsExData,
            name      : 'GB_stStillbirths',
            title     : 'GB_stStillbirthCause',
            editorID  : CONSTANTS.CS_MstStillbirthsByCauseSheetID,
            modvarTag : [CONSTANTS.CS_TG_SBCausesLiSTOnline_MV, CONSTANTS.CS_TG_SBRateLiSTOnline_MV2],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_MstNeonatalMortalityExData,
            name      : 'GB_stNeonatalMortality',
            title     : 'GB_stNeonatalCause',
            editorID  : CONSTANTS.CS_MstDeathsByCauseNNSheetID,
            modvarTag : [CONSTANTS.CS_TG_PercDthByCauseLiSTOnline_MV2, CONSTANTS.CS_TG_BaselineMortLiSTOnline_MV],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_MstChildMortalityExData,
            name      : 'GB_stChild1_59Mortality',
            title     : 'GB_stChildCause',
            editorID  : CONSTANTS.CS_MstDeathsByCausePNNSheetID,
            modvarTag : [CONSTANTS.CS_TG_PercDthByCauseLiSTOnline_MV2, CONSTANTS.CS_TG_BaselineMortLiSTOnline_MV],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_Mst5t9Mort,
            name      : 'GB_st5t9Mort',
            title     : 'GB_st5t9Mort',
            editorID  : CONSTANTS.CS_MstDeathsByCause5t9SheetID,
            modvarTag : [CONSTANTS.CS_TG_PercAdolDeathsByCauseLiSTOnline_MV, CONSTANTS.CS_TG_AdolMortRatioLiSTOnline_MV2],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_Mst10t14Mort,
            name      : 'GB_st10t14Mort',
            title     : 'GB_st10t14Mort',
            editorID  : CONSTANTS.CS_MstDeathsByCause10t14SheetID,
            modvarTag : [CONSTANTS.CS_TG_PercAdolDeathsByCauseLiSTOnline_MV, CONSTANTS.CS_TG_AdolMortRatioLiSTOnline_MV2],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_Mst15t19FemaleMort,
            name      : 'GB_st15t19FemaleMort',
            title     : 'GB_st15t19FemaleMort',
            editorID  : CONSTANTS.CS_MstDeathsByCause15t19FemaleSheetID,
            modvarTag : [CONSTANTS.CS_TG_PercAdolDeathsByCauseLiSTOnline_MV, CONSTANTS.CS_TG_AdolMortRatioLiSTOnline_MV2],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_Mst15t19MaleMort,
            name      : 'GB_st15t19MaleMort',
            title     : 'GB_st15t19MaleMort',
            editorID  : CONSTANTS.CS_MstDeathsByCause15t19MaleSheetID,
            modvarTag : [CONSTANTS.CS_TG_PercAdolDeathsByCauseLiSTOnline_MV, CONSTANTS.CS_TG_AdolMortRatioLiSTOnline_MV2],
            tabs      : [],
            checked   : false,
        },
        {   mstID     : CONSTANTS.CS_Mst15t19Mort,
            name      : 'GB_st15t19Mort',
            title     : 'GB_st15t19Mort',
            editorID  : CONSTANTS.CS_MstDeathsByCause15t19SheetID,
            modvarTag : [CONSTANTS.CS_TG_PercAdolDeathsByCauseLiSTOnline_MV, CONSTANTS.CS_TG_AdolMortRatioLiSTOnline_MV2],
            tabs      : [],
            checked   : false,
        },
    ],
    exploreHealthStatusContent: [
        {
            mstID                : CONSTANTS.CS_MstNutritionalDeficiencies_ED,
            name                 : 'GB_stNutritionalDeficiencies',
            groupID              : CONSTANTS.CS_MstBaselineChildHealthStatusGroup_ED,
            groupName            : 'GB_stBaselineChildHealthStatus',
            editorID             : CONSTANTS.CS_MstNutrDefSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_TableChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstStatusAtBirth_ED,
            name                 : 'GB_stStatusAtBirth',
            groupID              : CONSTANTS.CS_MstBaselineChildHealthStatusGroup_ED,
            groupName            : 'GB_stBaselineChildHealthStatus',
            editorID             : CONSTANTS.CS_MstStatusAtBirthSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstIncidence_ED,
            name                 : 'GB_stIncidence',
            groupID              : CONSTANTS.CS_MstBaselineChildHealthStatusGroup_ED,
            groupName            : 'GB_stBaselineChildHealthStatus',
            editorID             : CONSTANTS.CS_MstDeathCauseIncSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_TableChart,
            tabs                 : [RS('GB_st0t1mos'), RS('GB_st1t5months'), RS('GB_st6t11months'), RS('GB_st12t23months'), RS('GB_st24t59months')],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstFaliciparum_ED,
            name                 : 'GB_stPercWomenFalciparum',
            groupID              : CONSTANTS.CS_MstBaselineMaternalHealthStatusGroup_ED,
            groupName            : 'GB_stBaselineMaternalHealthStatus',
            editorID             : CONSTANTS.CS_MstIPTpSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstLowBMIAmongWRA_ED,
            name                 : 'GB_stLowBMI',
            groupID              : CONSTANTS.CS_MstBaselineMaternalHealthStatusGroup_ED,
            groupName            : 'GB_stBaselineMaternalHealthStatus',
            editorID             : CONSTANTS.CS_MstPercWom15t49LowBMISheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstStuntingDist_ED,
            name                 : 'GB_stStuntingDist',
            groupID              : CONSTANTS.CS_MstNutritionStatusGroup_ED,
            groupName            : 'GB_stNutritionStatusDist',
            editorID             : CONSTANTS.CS_MstStuntingMatrixSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_TableChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstWastingDist_ED,
            name                 : 'GB_stWastingDist',
            groupID              : CONSTANTS.CS_MstNutritionStatusGroup_ED,
            groupName            : 'GB_stNutritionStatusDist',
            editorID             : CONSTANTS.CS_MstWastingMatrixSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_TableChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstDistAllCases_ED,
            name                 : 'GB_stDistAllCases',
            groupID              : CONSTANTS.CS_MstPathogensGroup_ED,
            groupName            : 'GB_stPathogens',
            editorID             : CONSTANTS.CS_MstPathIncMortSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [RS('GB_st1t5months'), RS('GB_st6t11months'), RS('GB_st12t23months'), RS('GB_st24t59months')],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstDistSevereCases_ED,
            name                 : 'GB_stDistSevereCases',
            groupID              : CONSTANTS.CS_MstPathogensGroup_ED,
            groupName            : 'GB_stPathogens',
            editorID             : CONSTANTS.CS_MstPathIncMortSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [RS('GB_st1t5months'), RS('GB_st6t11months'), RS('GB_st12t23months'), RS('GB_st24t59months')],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstDistFatalCases_ED ,
            name                 : 'GB_stDistFatalCases',
            groupID              : CONSTANTS.CS_MstPathogensGroup_ED,
            groupName            : 'GB_stPathogens',
            editorID             : CONSTANTS.CS_MstPathIncMortSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [RS('GB_st1t5months'), RS('GB_st6t11months'), RS('GB_st12t23months'), RS('GB_st24t59months')],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstSpontaneousAbortion_ED,
            name                 : 'GB_stSpontaneousAbortion',
            groupID              : CONSTANTS.CS_MstAbortionGroup_ED,
            groupName            : 'GB_stAbortion',
            editorID             : CONSTANTS.CS_MstPercPregEndAbortSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_TableChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstAbortionIncidenceRatio_ED,
            name                 : 'GB_stAbortionIncidenceRatio',
            groupID              : CONSTANTS.CS_MstAbortionGroup_ED,
            groupName            : 'GB_stAbortion',
            editorID             : CONSTANTS.CS_MstAbortIncRatioSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_TableChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstPovertyFoodSecurity_ED,
            name                 : 'GB_stPovertyFoodSecurity',
            groupID              : CONSTANTS.CS_MstHouseholdStatusGroup_ED,
            groupName            : 'GB_stHouseholdStatus',
            editorID             : CONSTANTS.CS_MstPerCapitaIncSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [],
            checked              : false,
        },
        {
            mstID                : CONSTANTS.CS_MstAveHouseholdSize_ED,
            name                 : 'GB_stAveHouseholdSize',
            groupID              : CONSTANTS.CS_MstHouseholdStatusGroup_ED,
            groupName            : 'GB_stHouseholdStatus',
            editorID             : CONSTANTS.CS_MstAvgHouseholdSizeSheetID,
            possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
            selectedDisplayType  : CONSTANTS.CS_ColumnChart,
            tabs                 : [],
            checked              : false,
        },
    ],
    exploreMatEffIntContent        : [
        {mstID : CONSTANTS.CS_Mat_AntepartHemorr,    name : 'GB_stAntepartumHem',     editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_IntrapartHemorr,   name : 'GB_stIntrapartumHem',    editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_PostpartHemorr,    name : 'GB_stPostpartumHem',     editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_HypertensiveDis,   name : 'GB_stHypertensive',      editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_Sepsis,            name : 'GB_stSepsis',            editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_Abortion,          name : 'GB_stAbortion',          editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_Embolism,          name : 'GB_stEmbolism',          editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_OtherDirectCauses, name : 'GB_stOtherDirectCauses', editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mat_IndirectCauses,    name : 'GB_stIndirectCauses',    editorID : CONSTANTS.CS_MstMatIntEfficacySheetID, tabs : [], checked : false},
    ],
    exploreSBEffIntContent         : [
        {mstID : CONSTANTS.CS_SB_Antepartum,  name : 'GB_stAntepartum',  editorID : CONSTANTS.CS_MstSBIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_SB_Intrapartum, name : 'GB_stIntrapartum', editorID : CONSTANTS.CS_MstSBIntEfficacySheetID, tabs : [], checked : false},
    ],
    exploreNNEffIntContent         : [
        {mstID : CONSTANTS.CS_NNDiarr,     name : 'GB_stNeoDiarr',    editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_NNSepsis,    name : 'GB_stNeoSepsis',   editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_NNPneumonia, name : 'GB_stNeoPneu',     editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_NNAsphyxia,  name : 'GB_stNeoAsphyxia', editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_NNPreterm,   name : 'GB_stNeoPrematur', editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_NNTetanus,   name : 'GB_stNeoTetan',    editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_NNCongen,    name : 'GB_stNeoCongAnom', editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_NNOther,     name : 'GB_stNeoOther',    editorID : CONSTANTS.CS_MstNNIntEfficacySheetID, tabs : [], checked : false},
    ],
    exploreChildEffIntContent      : [
        {mstID : CONSTANTS.CS_Diarrhea,      name : 'GB_stDiarrhea',        editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_Pneumonia,     name : 'GB_stPneumonia',       editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_Meningitis,    name : 'GB_stMeningitis',      editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_Measles,       name : 'GB_stMeasles',         editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_Malaria,       name : 'GB_stMalaria',         editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_Pertussis,     name : 'GB_stPertussis',       editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_AIDS,          name : 'GB_stAIDS',            editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_Injury,        name : 'GB_stInjury',          editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
        {mstID : CONSTANTS.CS_OtherInfecDis, name : 'GB_stOtherInfecDis',   editorID : CONSTANTS.CS_MstPNNIntEfficacySheetID, tabs : ['GB_st1t5months', 'GB_st6t11months', 'GB_st12t23months', 'GB_st24t59months'], checked : false},
    ],
    exploreEffIncContent           : [
        {mstID : CONSTANTS.CS_MstImpactsDiarrheaIncidence_ED,   name : 'GB_stImpactsDiar',      editorID : CONSTANTS.CS_MstDiarrIncReductionSheetID,  tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstRiskDiarrheaWhenBF_ED,         name : 'GB_stRiskDiarrheaBF',   editorID : CONSTANTS.CS_MstRelRisksDiarrheaSheetID,   tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstImpactsSeverePneuIncidence_ED, name : 'GB_stImpactsPneu',      editorID : CONSTANTS.CS_MstPneumIncReductionSheetID,  tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstRiskPneumoniaWhenBF_ED,        name : 'GB_stRiskPneumoniaBF',  editorID : CONSTANTS.CS_MstRelRisksPneumoniaSheetID,  tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstImpactsMeninIncidence_ED,      name : 'GB_stImpactsMenin',     editorID : CONSTANTS.CS_MstMeningIncReductionSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstRiskMeningitisWhenBF_ED,       name : 'GB_stRiskMeningitisBF', editorID : CONSTANTS.CS_MstRelRisksMeningitisSheetID, tabs : [], checked : false},
    ],
    exploreHerdEffContent          : [
        {mstID : CONSTANTS.CS_MstDPTVacc_ED,     name : 'GB_stDPTVacc',     editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstHibVacc_ED,     name : 'GB_stHibVacc',     editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstMeaslesVacc_ED, name : 'GB_stMeaslesVacc', editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstPneuVacc_ED,    name : 'GB_stPneumoVacc',  editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstMeninA_ED,      name : 'GB_stMeningA',     editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstRotaVacc_ED,    name : 'GB_stRotaVacc',    editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstBednets_ED,     name : 'GB_stBednets',     editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstMalariaVacc_ED, name : 'GB_stMalariaVacc', editorID : CONSTANTS.CS_MstVaccineEffSheetID, tabs : [], checked : false},
    ],
    exploreNutIntContent           : [
        {
            mstID     : CONSTANTS.CS_MstMaternalAgeAndBirthOrder_ED,
            name      : 'GB_stMatAgeAndBirthOrder',
            groupID   : CONSTANTS.CS_MstImpactsBirthOutcomesGroup_ED,
            groupName : 'GB_stImpactsBirthOutcomes',
            editorID  : CONSTANTS.CS_MstAgeAndBirthOrderSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstBirthIntervals_ED,
            name      : 'GB_stBirthIntervals',
            groupID   : CONSTANTS.CS_MstImpactsBirthOutcomesGroup_ED,
            groupName : 'GB_stImpactsBirthOutcomes',
            editorID  : CONSTANTS.CS_MstBirthIntervalsSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstMaternalNutrition_ED,
            name      : 'GB_stMaternalNutrition',
            groupID   : CONSTANTS.CS_MstImpactsBirthOutcomesGroup_ED,
            groupName : 'GB_stImpactsBirthOutcomes',
            editorID  : CONSTANTS.CS_MstMaternalNutritionSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstImpactsStunting_ED,
            name      : 'GB_stImpactsStunting',
            groupID   : CONSTANTS.CS_MstNoNutritonGroup_ED,
            groupName : '',
            editorID  : CONSTANTS.CS_MstImpactsOnStuntingSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstSevereAcuteMalnutrition_ED,
            name      : 'GB_stImpactsWasting',
            groupID   : CONSTANTS.CS_MstNoNutritonGroup_ED,
            groupName : '',
            editorID  : CONSTANTS.CS_MstImpactsOnSevereWastingSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstPregnantWomen_ED,
            name      : 'GB_stImpactsMaternalAnemia',
            groupID   : CONSTANTS.CS_MstNoNutritonGroup_ED,
            groupName : '',
            editorID  : CONSTANTS.CS_MstImpactsOnMatAnemiaSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstImpactAgeAppropriateBreastFeeding_ED,
            name      : 'GB_stImpactAgeApprBF',
            groupID   : CONSTANTS.CS_MstImpactsBreastFeedingGroup_ED,
            groupName : 'GB_stImpactsBF',
            editorID  : CONSTANTS.CS_MstImpactBFImprovementSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstImpactEarlyInitiationBreastFeeding_ED,
            name      : 'GB_stImpactEarlyBF',
            groupID   : CONSTANTS.CS_MstImpactsBreastFeedingGroup_ED,
            groupName : 'GB_stImpactsBF',
            editorID  : CONSTANTS.CS_MstImpactEarlyInitBFSheetID,
            tabs      : [],
            checked   : false
        },
        {
            mstID     : CONSTANTS.CS_MstLowBirthWeightFactors_ED,
            name      : 'GB_stLowBirthWeight',
            groupID   : CONSTANTS.CS_MstNoNutritonGroup_ED,
            groupName : '',
            editorID  : CONSTANTS.CS_MstLBWFSheetID,
            tabs      : [],
            checked   : false
        },
    ],
    exploreUnderNutContent         : [
        {mstID : CONSTANTS.CS_MstStuntingOnMort_ED,              name : 'GB_stImpactStuntingMortality', editorID  : CONSTANTS.CS_MstRelRisksStuntingSheetID,  tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstWastingOnMort_ED,               name : 'GB_stImpactWastingMortality',  editorID  : CONSTANTS.CS_MstRelRisksWastingSheetID,   tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstBirthOutcomesOnMort_ED,         name : 'GB_stImpactBOMortality',       editorID  : CONSTANTS.CS_MstRelRisksIUGR_LBWSheetID,  tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstBreastFeedingOnNeonatalMort_ED, name : 'GB_stImpactBFNeonatalMort',    editorID  : CONSTANTS.CS_MstImpactBFOnMortNNSheetID,  tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstBreastFeedingOnChildMort_ED,    name : 'GB_stImpactBFChildMort',       editorID  : CONSTANTS.CS_MstImpactBFOnMortPNNSheetID, tabs : [], checked : false},
        {mstID : CONSTANTS.CS_MstAnemiaOnMatMort_ED,             name : 'GB_stImpactAnemiaMortality',   editorID  : CONSTANTS.CS_MstOddsRatioAnemiaSheetID,   tabs : [], checked : false},
    ],
    exploreBFContent : [
        {mstID : CONSTANTS.CS_MstLessThan1Month, name : 'GB_stLessThan1Month', tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mst1_5Months,      name : 'GB_st1_5Months',      tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mst6_11Months,     name : 'GB_st6_11Months',     tabs : [], checked : false},
        {mstID : CONSTANTS.CS_Mst12_23Months,    name : 'GB_st12_23Months',    tabs : [], checked : false},
    ],
    exploreShowDataArray        : [],
    exploreCurrentKey           : 0,
}
