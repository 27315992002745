import * as React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from "@material-ui/core/IconButton/IconButton";
import RS from "@common/strings/RS";
import TButton from './TButton';

class TAvatarDropdown extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isGuest              : PropTypes.bool.isRequired,
        humanName            : PropTypes.string.isRequired,
        onSignInClick        : PropTypes.func,
        onManageAccountClick : PropTypes.func,
        onSignOutClick       : PropTypes.func,
        style                : PropTypes.object
    };

    static defaultProps = {
        isGuest              : true,
        humanName            : 'Guest',
        onSignInClick        : () => console.log('onSignInClick fired'),
        onManageAccountClick : () => console.log('onManageAccountClick fired'),
        onSignOutClick       : () => console.log('onSignOutClick fired'),
        style                : {}
    };

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onSignInClick = () => {
        this.handleClose();
        this.props.onSignInClick();
    };

    onManageAccountClick = () => {
        this.handleClose();
        this.props.onManageAccountClick();
    };

    onSignOutClick = () => {
        this.handleClose();
        this.props.onSignOutClick();
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderSignInButton = () => {
        return (
            <IconButton
                // style={{width: '30px'}}
                onClick={this.onSignInClick}
            >
                <AccountCircleIcon />
            </IconButton>
        );
    };

    renderUserDropdown = () => {

        const { anchorEl } = this.state;

        const splitName = this.props.humanName.split(' ');

        return (
            <div style={{display: 'inline-block'}}>
                {/*<TButton*/}
                    {/*caption={this.props.humanName}*/}
                    {/*aria-owns={anchorEl ? 'simple-menu' : null}*/}
                    {/*aria-haspopup="true"*/}
                    {/*transparent={true}*/}
                    {/*onClick={this.handleClick}*/}
                    {/*style={{color: '#000000', fontWeight: 'bold', textDecoration: 'underline', textTransform : 'none', ...this.props.style}}*/}
                {/*>*/}
                    {/*{this.props.humanName}*/}
                {/*</TButton>*/}

                {/*<IconButton*/}
                    {/*style={{color: '#FFFFFF', backgroundColor: '#f78c30', height: '20px', width: '20px', fontSize: '12px'}}*/}
                    {/*transparent={true}*/}
                    {/*onClick={this.handleClick}*/}
                {/*>*/}
                    {/*J*/}
                {/*</IconButton>*/}

                <TButton
                    caption={splitName.length > 1 ? splitName[0].substr(0, 1) + splitName[1].substr(0, 1) : splitName[0].substr(0, 2)}
                    captionStyles={{fontSize: '14px', fontWeight: 'bold', lineHeight: 0}}
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    variant={'fab'}
                    onClick={this.handleClick}
                    style={{color: '#FFFFFF', minHeight: '25px', minWidth: '25px', height: '25px', width: '25px', boxShadow : 'none', ...this.props.style}}
                    >
                </TButton>

                {/*<Fab*/}
                {/*    caption={splitName[0]}*/}
                {/*    captionStyles={{fontSize: '16px', fontWeight: 'bold', lineHeight: 0}}*/}
                {/*    aria-owns={anchorEl ? 'simple-menu' : null}*/}
                {/*    aria-haspopup="true"*/}
                {/*    variant={'round'}*/}
                {/*    onClick={this.handleClick}*/}
                {/*    style={{color: '#FFFFFF', minHeight: '20px', minWidth: '20px', height: '20px', width: '20px', boxShadow : 'none', ...this.props.style}}*/}
                {/*>*/}
                {/*</Fab>*/}

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                >
                    <MenuItem onClick={this.onManageAccountClick}>{RS('GB_stManageMyAccount')}</MenuItem>
                    <MenuItem onClick={this.onSignOutClick}>{RS('GB_stLogout')}</MenuItem>
                </Menu>
            </div>
        );
    };

    render() {
        return (
            (this.props.isGuest) ? this.renderSignInButton() : this.renderUserDropdown()
        );
    }

}

export default TAvatarDropdown;