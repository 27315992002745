import React from 'react';
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import TLabel from '@common/components/TLabel';
import TListItem from "@common/components/TListItem";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

const styles = {
    selected : {
        backgroundColor : '#3B78BC',
        marginTop : '10px'
    },
    notSelected : {
        backgroundColor : '#05225D',
        marginTop : '10px'
    },
    selectedPrimary : {
        color: '#FFFFFF',
        fontSize: '16px',
        marginLeft: '25px',
        marginTop: '-10px'
    },
    notSelectedPrimary: {
        color: '#95c8fc',
        fontSize: '16px',
        marginLeft: '25px',
        marginTop: '-10px'
    },
};

class CSProjectionListSideBar extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onPageChange                : PropTypes.func,
        onViewResults               : PropTypes.func,
        onStateChange               : PropTypes.func,

        pageID                      : PropTypes.string,
        projectionName              : PropTypes.string,
        selectedCountry             : PropTypes.string,
        yearRange                   : PropTypes.object,
        projectCreated              : PropTypes.bool,
        selectedProjSideBarIdx      : PropTypes.number,
        subnatRegion                : PropTypes.string,
        subnatSurvey                : PropTypes.string
    };

    static defaultProps = {
        onPageChange                : () => console.log('onPageChange'),
        onViewResults               : () => console.log('onViewResults'),
        onStateChange               : () => console.log('onStateChange'),

        pageID                      : '',
        projectionName              : '',
        selectedCountry             : 'Afghanistan',
        yearRange                   : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        projectCreated              : false,
        selectedProjSideBarIdx      : 0,
        subnatRegion                : RS("GB_stNoRegionSelected"),
        subnatSurvey                : ""
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onLivesSavedToolLinkClick = () => {
        window.open("http://livessavedtool.org/", "_blank");
    };

    onClickSideBar = (idx, pageID) => {
        this.props.onStateChange ({
            selectedProjSideBarIdx : idx
        });

        this.props.onPageChange(pageID)
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderProjectionLabel = () => {
        const styles = {
            labelStyle : {
                color: '#FFFFFF',
                marginTop: '0px',
                marginRight: '24px',
                marginLeft: '24px',
                marginBottom: '10px',
                fontSize: '18px',
                cursor: 'default',
                wordWrap: 'break-word',
                // fontWeight: 'bold',
                lineHeight: '1.2'
            }
        };
        return (
            <div>
                <TLabel
                    // caption='Projection'
                    caption={(this.props.projectCreated) ? this.props.projectionName : RS('GB_stProjection') }
                    style={styles.labelStyle}
                />

                { (this.props.projectCreated) ? this.renderCountryAndYears() : null }
            </div>
        );
    };

    renderProjectionName = () => {

        const styles = {
            projectionNameListItem: {
                color: '#FFFFFF',
                marginTop: '0px',
                marginLeft: '44px',
                fontSize: '14px',
                fontStyle: 'italic',
                cursor: 'default',
            },
        };

        if(!(this.props.projectionName === ''))  {
            return (
                <TLabel
                    style={styles.projectionNameListItem}
                    caption={this.props.projectionName}
                />
            )
        }
    };

    renderSetCountryAndYear = () => {
        return (
            <div>
                <TListItem className="MuiListItem"
                    primaryStyle={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ProjectionManagerIdx) ? styles.selectedPrimary : styles.notSelectedPrimary}
                    style={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ProjectionManagerIdx) ? styles.selected : styles.notSelected}
                    caption={RS('GB_stConfigProj')}
                    onClick={() => this.onClickSideBar(CONSTANTS.CS_ProjectionManagerIdx, 'CSProjManagerForm')}
                />
            </div>
        )
    };

    renderCountryAndYears = () => {

        const styles = {
            notSelected: {
                color: '#FFFFFF',
                fontWeight: 'normal',
                paddingLeft: '24px',
                marginLeft: '17px',
                paddingBottom: '10px',
                fontSize: '14px',
                fontStyle: 'italic',
                cursor: 'default',
                backgroundColor: '#05225D',
            },
            selected: {
                color: '#05225D',
                fontWeight: 'bold',
                paddingLeft: '64px',
                paddingBottom: '10px',
                fontSize: '14px',
                fontStyle: 'italic',
                cursor: 'default',
                backgroundColor: '#3B78BC',
            },
            block1 : {
                display: 'block',
                fontSize: '12px',
                fontStyle: 'italic',
            },
            block : {
                display: 'block',
                fontSize: '12px',
                fontStyle: 'normal',
            }
        };

        let countryName = this.props.selectedCountry;
        let regionName = this.props.subnatRegion;
        let surveyName = this.props.subnatSurvey;
        let yearRange = this.props.yearRange.min + "-" + this.props.yearRange.max;

        let s = "";
        if (this.props.subnatRegion === "National" || this.props.subnatRegion === "") {
            s = 
                <div>
                    <div style={styles.block1}>{countryName}</div>
                    <div style={styles.block}>{RS('GB_stYearsColon')} {yearRange}</div>
                    <div style={styles.block}>{RS('GB_stSurveyColon')} {surveyName}</div>
                </div>;
        }
        else {
            s =  
                <div>
                    <div style={styles.block1}>{countryName}, {regionName}</div>
                    <div style={styles.block}>{RS('GB_stYearsColon')} {yearRange}</div>
                    <div style={styles.block}>{RS('GB_stSurveyColon')} {surveyName}</div>
                </div>;
        }

        return (
            <TLabel
                caption={s}
                style={styles.notSelected}
                component={'div'}
            />
        );
    };

    renderReviewDefaultData = () => {
        return (
            <TListItem
                caption={RS('GB_stReviewDefaultData')}
                primaryStyle={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ReviewDefaultDataIdx) ? styles.selectedPrimary : styles.notSelectedPrimary}
                style={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ReviewDefaultDataIdx) ? styles.selected : styles.notSelected}
                disabled={!this.props.projectCreated}
                onClick={() => this.onClickSideBar(CONSTANTS.CS_ReviewDefaultDataIdx, 'CSReviewDefaultDataForm')}
            />
        );
    };

    renderManageInterventions = () => {
        return (
            <TListItem
                caption={RS('GB_stManageInterventions')}
                primaryStyle={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ManageInterventionIdx) ? styles.selectedPrimary : styles.notSelectedPrimary}
                style={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ManageInterventionIdx) ? styles.selected : styles.notSelected}
                disabled={!this.props.projectCreated}
                onClick={() => this.onClickSideBar(CONSTANTS.CS_ManageInterventionIdx, 'CSManageInterventionsForm')}
            />
        );
    };

    renderViewResults = () => {
        return (
            <TListItem
                caption={RS('GB_stViewResults')}
                primaryStyle={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ViewResultsIdx) ? styles.selectedPrimary : styles.notSelectedPrimary}
                style={(this.props.selectedProjSideBarIdx === CONSTANTS.CS_ViewResultsIdx) ? styles.selected : styles.notSelected}
                disabled={!this.props.projectCreated}
                onClick={this.props.onViewResults}
            />
        );
    };

    renderLivesSavedTool = () => {

        let styles = {
            headerItem2: {
                textAlign: 'left',
                fontSize: '16px',
                color: '#95c8fc',
            },
        };

        return (
            <TListItem
                caption={RS('GB_stReturnToLivesSavedTool')}
                primaryStyle={styles.headerItem2}
                onClick={this.onLivesSavedToolLinkClick}
            />
        );
    };

    render() {
        const styles = {
            divider: {
                backgroundColor: '#FFFFFF',
                height: '1px',
                marginTop: '20px'
            },
            muiList: {
                paddingTop: 0,
                paddingBottom: 0
            },
        };

        return (
            <List dense={false} style={styles.muiList} key={0}>
                {this.renderProjectionLabel()}
                <Divider style={styles.divider}/>
                {this.renderSetCountryAndYear()}
                {this.renderManageInterventions()}
                {this.renderReviewDefaultData()}
                {this.renderViewResults()}

                <Divider style={styles.divider}/>
                <br />
                {this.renderLivesSavedTool()}
            </List>
        );
    }

}

export default CSProjectionListSideBar;