import React, { useState } from "react";
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import {getModvarByTag} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";
import CSStuntingDrawer from "../../drawers/Coverage/CSStuntingDrawer";

const CSStuntingBlueBox = (props) => {
    const { CSModvars, firstYear, captionStyle, onOpenHelpDialog, onStateChange } = props; 
    const [openDrawer, setOpenDrawer] = useState(false);

    const _onOpenDrawer = () => setOpenDrawer(true);
    const _onCloseDrawer = () => setOpenDrawer(false);

    return (
        <React.Fragment>
            <CSBlueBox2
                caption      = {RS('GB_stStunting')}
                captionStyle = {captionStyle}
                description  = {""}
                onClick      = {_onOpenDrawer}
            />

            {
                (openDrawer) ?
                    <CSStuntingDrawer
                        CSModvars={CSModvars}
                        firstYear={firstYear}
                        finalYear={getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"]}
                        onClose={_onCloseDrawer}
                        onOpenHelpDialog={onOpenHelpDialog}
                        onStateChange={onStateChange}
                    />
                    : null
            }
        </React.Fragment>
    );
};

CSStuntingBlueBox.propTypes = {
    CSModvars        : PropTypes.arrayOf(PropTypes.object),
    captionStyle     : PropTypes.object,
    firstYear        : PropTypes.number,
    onOpenHelpDialog : PropTypes.func,
    onStateChange    : PropTypes.func,
};

export default CSStuntingBlueBox;

































// class CSStuntingBlueBox extends React.Component {

//     static propTypes = {
//         onStateChange    : PropTypes.func,
//         CSModvars        : PropTypes.arrayOf(PropTypes.object),
//         captionStyle     : PropTypes.object,
//         firstYear        : PropTypes.number,
//         onOpenHelpDialog : PropTypes.func,
//     };

//     static defaultProps = {
//         onStateChange    : () => console.log('onStateChange'),
//         CSModvars        : [],
//         captionStyle     : {},
//         firstYear        : -1,
//         onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
//     };

//     state = {
//         openDrawer       : false,
//     };

//     _onOpenDrawer = () => {
//         this.setState({
//             openDrawer : true,
//         });
//     };

//     _onCloseDrawer = () => {
//         this.setState({
//             openDrawer : false,
//         });
//     };

//     render() {
//         return (
//             <React.Fragment>
//                 <CSBlueBox2
//                     caption      = {RS('GB_stStunting')}
//                     captionStyle = {this.props.captionStyle}
//                     description  = {""}
//                     onClick      = {this._onOpenDrawer}
//                 />

//                 {
//                     (this.state.openDrawer) ?
//                         <CSStuntingDrawer
//                             CSModvars={this.props.CSModvars}
//                             firstYear={this.props.firstYear}
//                             finalYear={getModvarByTag(this.props.CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"]}
//                             onClose={this._onCloseDrawer}
//                             onOpenHelpDialog={this.props.onOpenHelpDialog}
//                         />
//                         : null
//                 }
//             </React.Fragment>
//         );
//     }

// }

// export default CSStuntingBlueBox;