import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class OutcomeDropdown extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onOutcomeChange : PropTypes.func,
        outcomeIdx      : PropTypes.number,
        width           : PropTypes.number,
        language        : PropTypes.number,
    };

    static defaultProps = {
        onOutcomeChange : () => console.log('onOutcomeChange'),
        outcomeIdx      : 0,
        width           : 265,
        language        : CONSTANTS.GB_English
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    
    renderTitle = () => {
        let styles = {
            headerStyle: {
                color: '#000000',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '0',
                marginTop : '0',
                userSelect: 'none'
            }
        };

        return (
            <div>
                <p style={styles.headerStyle}>{RS('GB_stOutcome')}</p>
            </div>
        );
    };

    renderDropdown = () => {

        const styles = {
            comboBox: {
                marginTop: 5,
                marginRight: 10,
                marginBottom: 10,
                paddingLeft: 5,
                width: this.props.width,
                display: 'inline-block',
                color: '#0C3A79',
                fontWeight: 'bold',
                fontSize: '13px',
                borderRadius: 4,
                border: '1px solid lightgray',
                borderBlockEndColor: '#f78c30',
                backgroundColor: "#FFFFFF"
            },
            captionStyle: {
                // marginTop: 25,
                // color: '#f78c30',
                color: '#609cd9'
            },
            newComboBox : {
                width: 262,
                margin: '20px 0'
            },
        };

        let outcomeItems = [];
        outcomeItems.push(RS('GB_stMaternalMortality'));
        outcomeItems.push(RS('GB_stStillbirth'));
        outcomeItems.push(RS('GB_stNeonatalMortality'));
        outcomeItems.push(RS('GB_stChild1_59Mortality'));
        if (this.props.adol){
            outcomeItems.push(RS('GB_stAdolescentsCOD'));
        }

        return (
            <TComboBox
                caption      = {RS('GB_stOutcome')}
                itemIndex    = {this.props.outcomeIdx}
                onChange     = {this.props.onOutcomeChange}
                // style        = {styles.comboBox}
                captionStyle = {styles.captionStyle}
                style        = {styles.newComboBox}
                items        = {outcomeItems}
            >
            </TComboBox>
        )
    };

    render() {
        return (
            <React.Fragment>
                {/* {this.renderTitle()} */}
                {this.renderDropdown()}
            </React.Fragment>
        );
    }

}

export default OutcomeDropdown;