import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSStillbirthsDrawer from "../../drawers/HealthStatus/CSStillbirthsDrawer";
import {SBRateEditor} from "../../data/HealthStatus/Stillbirths/SBRateEditor";
import {SBCausesEditor} from "../../data/HealthStatus/Stillbirths/SBCausesEditor";

class CSStillbirthsBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        CSModvars        : [],
        onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled : true,
        openDrawer      : false,
        packTable1      : null,
        packTable2      : null,
    };

    componentDidMount() {
        this.setState({
            packTable1 : SBRateEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable2 : SBCausesEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.openDrawer) {
            return {
                packTable1 : SBRateEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                packTable2 : SBCausesEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({
            openDrawer      : false,
            saveBtnDisabled : true,
            packTable1      : SBRateEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable2      : SBCausesEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    };

    onSaveBtnClick = () => {
        let goAhead = true;
        let TotalRow = this.state.packTable2.tableData.value[this.state.packTable2.GBRowCount-1];
        TotalRow.forEach(function(val) {
            if (typeof val === "number" && parseFloat(val.toFixed(1)) !== 100) {
                goAhead = false;
            }
        });

        if (goAhead) {
            let ModvarsArray1 = SBRateEditor.GetSSData(this.state.packTable1, this.props.CSModvars);
            let ModvarsArray2 = SBCausesEditor.GetSSData(this.state.packTable2, this.props.CSModvars);

            let changedCSModvars = this.props.CSModvars.map(x => {
                let a = ModvarsArray1.find(y => y.tag === x.tag);
                let b = ModvarsArray2.find(y => y.tag === x.tag);
                return a || b || x;
            });

            this.setState({
                openDrawer: false,
                saveBtnDisabled: true,
            });

            this.props.onStateChange({
                unchangedProj: false,
                CSModvars: changedCSModvars,
            });
        }
        else {
            this.props.onStateChange({
                dialogErrorOpen: true,
                errorMessage: RS("GB_stValsMustSumTo100Perc")
            });
        }
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2
                caption     = {RS('GB_stStillbirths')}
                description = ""
                onClick     = {this.onBlueBoxClick}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"stillbirth.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stStillbirths')}
                        content         = {
                            <CSStillbirthsDrawer
                                packTable1={this.state.packTable1}
                                packTable2={this.state.packTable2}
                                onPackTable1Change={this.onPackTable1Change}
                                onPackTable2Change={this.onPackTable2Change}
                            />
                        }
                    />
                }
            />
        );
    }

}

export default CSStillbirthsBlueBox;