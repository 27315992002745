import React from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import RS from "@common/strings/global";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import { clone } from "@common/util/GBUtil";
import Stepper from "../components/Stepper";
import ButtonApply from '../components/buttons/ButtonApply';
import ButtonNext from "../components/buttons/ButtonNext";
import TButton from "../../../@common/components/TButton";
import {Publish} from "@material-ui/icons";
import TGBProjectionTable from "../../../components/GB/TGBProjectionTable";
  
const ProjectionsPage = (props) => {
    const { onPageChange, onStateChange, onStepChange, stepsCompleted, storedProjections, activeProjections, projectionsSelected, indicatorsSelected, onUploadProjection,
        years, Theme } = props;

    const [openLibrary, setOpenLibrary] = React.useState(false);
    const [projsSelected, setProjsSelected] = React.useState(projectionsSelected);

    const styles = {
        content : {
            marginTop: 20,
            maxWidth: 615,
            minWidth: 615,
            marginLeft: 10,
        },
        fileBox: {
            border: "1px solid lightgray",
            borderRadius: 4,
            margin: "15px 0",
            padding: 10,
            paddingLeft: 0,
            minHeight: 300,
        },
        add: {
            cursor: "pointer",
            verticalAlign: "middle",
            marginTop: 2,
            marginLeft: 5,
            display: "inline-block",
        },
        label: {
            fontSize: 16,
            fontWeight: "bold",
            cursor: "pointer",
            //color: Theme.palette.primary.main,
            position: "relative",
            top: 2
        },
        applyButton : {
            float: "right",
            margin:5,
            fontSize: 16
        },
        buttonNext : {
            float: "right",
        },
        listItem: {
            fontSize: 14
        },
        close: {
            cursor: "pointer",
            color: Theme.palette.secondary.main,
            verticalAlign: "middle",
            marginRight: -1,
            marginTop: -2
        },
        upload: {
            // float: "right",
            // position: "relative",
            // top: -5
            verticalAlign: "middle",
            cursor: "pointer",
            color: Theme.palette.secondary.main
        },
        button: {
            color: '#000000',
            textTransform: 'none',
            fontSize: '16px',
            padding : '10px 0',
            margin: '10px 0',
            marginLeft: '-10px'
        },
        captionStyles : {
            marginLeft: '10px',
            marginRight: '10px'
        }
    };

    const updateYearIndices = (projectionsSelected) => {
        let MinProjFirstYr = 0;
        let MaxProjFinalYr = 0;
        for (let i = 0; i < storedProjections.length; i++) {
            let projO = storedProjections[i];
            if (projectionsSelected.includes(projO.name)){
                if ((MinProjFirstYr === 0) || (MinProjFirstYr > projO.calcYear)) {
                    MinProjFirstYr = projO.calcYear;
                }

                if (MaxProjFinalYr < projO.finalYear) {
                    MaxProjFinalYr = projO.finalYear;
                }
            }
        }

        let curYearsMax = years.max;

        if(curYearsMax === 0){
            curYearsMax = MaxProjFinalYr;
        }

        let newYears = {
            min : Math.max(years.min, MinProjFirstYr), max: Math.min(curYearsMax, MaxProjFinalYr)
        };

        if (MinProjFirstYr === 0){
            newYears.min = MinProjFirstYr;
        }

        onStateChange({
            MinProjFirstYr : MinProjFirstYr,
            MaxProjFinalYr : MaxProjFinalYr,
            years : newYears
        });
    };

    const onLibraryOpen = () => {
        setOpenLibrary(true);
    };

    const onLibraryChanged = (value) => {
        setProjsSelected(value);
    };

    const onLibraryClose = () => {
        setProjsSelected(projectionsSelected);
        setOpenLibrary(false);
    };

    const onLibraryApply = () => {
        onStateChange({
            projectionsSelected : projsSelected
        });
        updateYearIndices(projsSelected);
        setOpenLibrary(false);
    };

    const onRemoveProjectionFromList = (value) => {

        let arr = clone(projsSelected);

        if (Array.isArray(value)) {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (value.includes(arr[i])) {
                    arr.splice(i, 1);
                }
            }
        } else {
            const index = arr.indexOf(value);
            if (index > -1) {
                arr.splice(index, 1);
            }
        }

        onStateChange({
            projectionsSelected : arr,
            indicatorsSelected  : arr.length === 0 ? [] : indicatorsSelected
        });

        setProjsSelected(arr);

        updateYearIndices(arr);
    };

    const onNextButtonClick = () => {
        // let newStepsCompleted = clone(stepsCompleted);
        // newStepsCompleted.push(0);

        // onStateChange({
        //     stepsCompleted : newStepsCompleted
        // });

        onPageChange("IndicatorsPage");
    };

    const onOpenConfig = () => {
        document.querySelector("#hidden-ex-upload").click();
    };

    const onOpenEXFile = () => {
        let reader = new FileReader();
        reader.onload = () => {
            let ex = JSON.parse(reader.result)[0];
            let selProjNames = ex["selProjNames"];
            let savedProjNames = storedProjections.map(x => x.name);
            for (let i = selProjNames.length - 1; i >=0; i--) {
                if (!savedProjNames.includes(selProjNames[i])) {
                    selProjNames.splice(i, 1);
                }
            }
            setProjsSelected(selProjNames);
            onStateChange({
                projectionsSelected : selProjNames,
                indicatorsSelected  : ex["selInds"],
                exportFileName      : ex["outputFileName"],
                recalculateProjs    : ex["recalcProjBool"],
                years               : ex["yrsToExtract"],
                fileFormat          : ex["resFileFormat"],
                MinProjFirstYr      : ex["minYr"],
                MaxProjFinalYr      : ex["maxYr"]
            });
        };
        reader.readAsText(document.querySelector("#hidden-ex-upload").files[0]);
    };

    return (
        <React.Fragment>
            <Stepper
                steps={[RS('GB_stChooseProj'), RS('GB_stSelectIndicators'), RS('GB_stSetConfig'), RS('GB_stProcess')]}
                activeStep={0}
                completedSteps={stepsCompleted}
                onStepBtnClick={onStepChange}
                useAltColors={true}
                disabledSteps={projectionsSelected.length === 0 ? [2, 3, 4] : indicatorsSelected.length === 0 ? [3, 4] : []}
                Theme={Theme}
            />

            <div style={styles.content}>

                {/*<label onClick={onLibraryOpen} style={styles.label}>*/}
                {/*    {RS("GB_stChooseProj")}*/}
                {/*</label>*/}

                {/*<Tooltip style={styles.add} title={RS("GB_stAdd")}>*/}
                {/*    <AddCircle*/}
                {/*        color={"primary"}*/}
                {/*        onClick={onLibraryOpen}*/}
                {/*    />*/}
                {/*</Tooltip>*/}

                <TButton
                    caption={RS('GB_stChooseProj')}
                    variant={"text"}
                    style={styles.button}
                    captionStyles={styles.captionStyles}
                    containerStyle={{marginBottom: -10}}
                    onClick={onLibraryOpen}
                    pictureRight={<AddCircle
                        color={"primary"}
                        //onClick={onLibraryOpen}
                    />}
                />

                {/*<TButton*/}
                {/*    caption={"Upload configuration"}*/}
                {/*    color={"secondary"}*/}
                {/*    onClick={onOpenConfig}*/}
                {/*    containerStyle={styles.upload}*/}
                {/*/>*/}

                <input
                    type={"file"}
                    accept={".json"}
                    id={"hidden-ex-upload"}
                    style={{display: "none"}}
                    onChange={onOpenEXFile}
                />


                    {/*{*/}
                    {/*    projectionsSelected.length > 0 ?*/}
                            <div style={{marginBottom: 20}}>
                                <TGBProjectionTable
                                    storedProjections={storedProjections.filter(x => projectionsSelected.includes(x.name))}
                                    activeProjections={activeProjections}
                                    tableTitle={RS("GB_stSelProj")}
                                    showDetailsSwitch={false}
                                    showLastSave={false}
                                    showTags={false}
                                    showFilterTagsBtn={false}
                                    showUploadBtn={false}
                                    meatballOptions={["Delete"]}
                                    deleteMsg={RS("GB_stRemove")}
                                    deleteAllMsg={RS("GB_stRemoveAll")}
                                    dialogDelAll={RS("GB_stSureRemoveAllProj")}
                                    onDeleteProjection={onRemoveProjectionFromList}
                                />
                            </div>
                            {/*:*/}
                            {/*<React.Fragment>*/}
                            {/*    <br /><br />*/}
                            {/*</React.Fragment>*/}
                    {/*}*/}


                {/*<label onClick={onOpenConfig} style={styles.label}>*/}
                {/*    {RS("GB_stOpenConfig")}*/}
                {/*</label>*/}

                {/*<Tooltip style={styles.upload} title={RS("GB_stOpen")}>*/}
                {/*    <Publish*/}
                {/*        color={"primary"}*/}
                {/*        onClick={onOpenConfig}*/}
                {/*    />*/}
                {/*</Tooltip>*/}

                <TButton
                    caption={RS('GB_stOpenConfig')}
                    variant={"text"}
                    style={styles.button}
                    captionStyles={styles.captionStyles}
                    onClick={onOpenConfig}
                    pictureRight={<Publish
                        color={"secondary"}
                        //onClick={onOpenConfig}
                    />}
                />

                <ButtonNext
                    onClick={onNextButtonClick}
                    disabled={projectionsSelected.length === 0}
                    containerStyle={styles.buttonNext}
                />
            </div>

            {
                (openLibrary) ?
                    <TDrawerWithHeader
                        open={true}
                        appBarText={""}
                        showSaveBtn={false}
                        showExtraBtn={true}
                        contentExtraBtn={
                            <ButtonApply
                                onClick={onLibraryApply}
                                containerStyle={styles.applyButton}
                                disabled={projsSelected.length === 0}
                            />
                        }
                        showHelp={false}
                        onClose={onLibraryClose}
                        content={
                            <div style={{background: "white", padding: 20, paddingLeft: 10}}>
                                <TGBProjectionTable
                                    storedProjections={storedProjections}
                                    onStateChange={onStateChange}
                                    onProjectionsSelectedChanged={onLibraryChanged}
                                    multiselect={projsSelected}
                                    onUploadProjection={onUploadProjection}
                                    Theme={Theme}
                                    showAuthor={false}
                                    showOrg={false}
                                    showUploadBtn={false}
                                    showMeatballMenu={false}
                                    tableTitle={RS('GB_stChooseProj')}
                                />
                            </div>
                        }
                    />
                    : 
                    null
            }

        </React.Fragment>
    );
};

export default ProjectionsPage;