import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../../components/CS/CSEditors';

class CSHerdEffectiveness extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        DPTHerdEditorPackTable                 : PropTypes.object,
        HibHerdEditorPackTable                 : PropTypes.object,
        MeaslesHerdEditorPackTable             : PropTypes.object,
        PneumoHerdEditorPackTable              : PropTypes.object,
        RotaHerdEditorPackTable                : PropTypes.object,
        MeningAHerdEditorPackTable             : PropTypes.object,
        BednetsHerdEditorPackTable             : PropTypes.object,
        MalariaHerdEditorPackTable             : PropTypes.object,
        onDPTHerdEditorPackTableChange         : PropTypes.func,
        onHibHerdEditorPackTableChange         : PropTypes.func,
        onMeaslesHerdEditorPackTableChange     : PropTypes.func,
        onPneumoHerdEditorPackTableChange      : PropTypes.func,
        onRotaHerdEditorPackTableChange        : PropTypes.func,
        onMeningAHerdEditorPackTableChange     : PropTypes.func,
        onBednetsHerdEditorPackTableChange     : PropTypes.func,
        onMalariaHerdEditorPackTableChange     : PropTypes.func,
        herdComboboxIdx                        : PropTypes.number,
        onHerdComboboxChange                   : PropTypes.func,
    };

    static defaultProps = {
        HibHerdEditorPackTable                 : null,
        MeaslesHerdEditorPackTable             : null,
        PneumoHerdEditorPackTable              : null,
        RotaHerdEditorPackTable                : null,
        MeningAHerdEditorPackTable             : null,
        BednetsHerdEditorPackTable             : null,
        MalariaHerdEditorPackTable             : null,
        onDPTHerdEditorPackTableChange         : (packTable) => console.log('onDPTHerdEditorPackTableChange'),
        onHibHerdEditorPackTableChange         : (packTable) => console.log('onHibHerdEditorPackTableChange'),
        onMeaslesHerdEditorPackTableChange     : (packTable) => console.log('onMeaslesHerdEditorPackTableChange'),
        onPneumoHerdEditorPackTableChange      : (packTable) => console.log('onPneumoHerdEditorPackTableChange'),
        onRotaHerdEditorPackTableChange        : (packTable) => console.log('onRotaHerdEditorPackTableChange'),
        onMeningAHerdEditorPackTableChange     : (packTable) => console.log('onMeningAHerdEditorPackTableChange'),
        onBednetsHerdEditorPackTableChange     : (packTable) => console.log('onBednetsHerdEditorPackTableChange'),
        onMalariaHerdEditorPackTableChange     : (packTable) => console.log('onMalariaHerdEditorPackTableChange'),
        herdComboboxIdx                        : 0,
        onHerdComboboxChange                   : () => console.log('onHerdComboboxChange'),
    };

    state = {
        DPTHerdEditorPackTable     : JSON.parse(JSON.stringify(this.props.DPTHerdEditorPackTable)),
        HibHerdEditorPackTable     : JSON.parse(JSON.stringify(this.props.HibHerdEditorPackTable)),
        MeaslesHerdEditorPackTable : JSON.parse(JSON.stringify(this.props.MeaslesHerdEditorPackTable)),
        PneumoHerdEditorPackTable  : JSON.parse(JSON.stringify(this.props.PneumoHerdEditorPackTable)),
        RotaHerdEditorPackTable    : JSON.parse(JSON.stringify(this.props.RotaHerdEditorPackTable)),
        MeningAHerdEditorPackTable : JSON.parse(JSON.stringify(this.props.MeningAHerdEditorPackTable)),
        BednetsHerdEditorPackTable : JSON.parse(JSON.stringify(this.props.BednetsHerdEditorPackTable)),
        MalariaHerdEditorPackTable : JSON.parse(JSON.stringify(this.props.MalariaHerdEditorPackTable)),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onComboboxChange = (value) => {
        this.props.onHerdComboboxChange(value)
    };

    //==================================================================================================================
    //
    //                                                 functions
    //
    //==================================================================================================================

    getDPTVaccEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_DPT_LOL}
                packTable={this.state.DPTHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onDPTHerdEditorPackTableChange}
            />
        )
    };

    getHibVaccEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_Hib_LOL}
                packTable={this.state.HibHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onHibHerdEditorPackTableChange}
            />
        )
    };

    getMeaslesVaccEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_Measles_LOL}
                packTable={this.state.MeaslesHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onMeaslesHerdEditorPackTableChange}
            />
        )
    };

    getPneumoVaccEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_Pneumococcal_LOL}
                packTable={this.state.PneumoHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onPneumoHerdEditorPackTableChange}
            />
        )
    };

    getRotaVaccEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_Rotavirus_LOL}
                packTable={this.state.RotaHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onRotaHerdEditorPackTableChange}
            />
        )
    };

    getMeningAEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_MeninA_LOL}
                packTable={this.state.MeningAHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onMeningAHerdEditorPackTableChange}
            />
        )
    };

    getBednetsEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_Bednets_LOL}
                packTable={this.state.BednetsHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onBednetsHerdEditorPackTableChange}
            />
        )
    };

    getMalariaVaccEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_Herd_Malaria_LOL}
                packTable={this.state.MalariaHerdEditorPackTable}
                showTitle={true}
                onChange={this.props.onMalariaHerdEditorPackTableChange}
            />
        )
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    renderInstructions = () => {
        const styles = {
            paragraph1: {
                fontSize: 14,
                userSelect: 'none',
                marginBottom: 0,
                marginTop: 0,
            },
            paragraph2: {
                fontSize: 14,
                userSelect: 'none',
                marginBottom: 15,
                marginTop: -5,
            },
        };
        return (
            <div>
                <p style={styles.paragraph1}>
                    {RS('GB_stHerdEffDescription')}
                </p>
                <p style={styles.paragraph2}>
                    {RS('GB_stHerdEffDescription2')}
                </p>
            </div>
        )
    };

    renderDropdown = () => {
        const styles = {
            comboBox: {
                marginTop: 0,
                marginBottom: 20,
                marginRight: 25,
                paddingLeft: 0,
                width: 325,
                display: 'inline-block'
            },
            captionStyle: {
                marginTop: 0,
                color: '#f78c30'
            }
        };

        let items = [];
        items.push(RS('GB_stDPTVacc'));
        items.push(RS('GB_stHibVacc'));
        items.push(RS('GB_stMeaslesVacc'));
        items.push(RS('GB_stPneumoVacc'));
        items.push(RS('GB_stRotaVacc'));
        items.push(RS('GB_stMeningA'));
        items.push(RS('GB_stBednets'));
        items.push(RS('GB_stMalariaVacc'));

        return (
            <TComboBox
                caption      = ""
                itemIndex    = {this.props.herdComboboxIdx}
                style        = {styles.comboBox}
                onChange     = {this.onComboboxChange}
                captionStyle = {styles.captionStyle}
                items        = {items}
            >
            </TComboBox>
        )
    };

    renderEditors = () => {
        let DPTVacc = 0;
        let HibVacc = 1;
        let MeaslesVacc = 2;
        let PneumoVacc = 3;
        let RotaVacc = 4;
        let MeningA = 5;
        let Bednets = 6;
        let MalariaVacc = 7;

        let result = '';

        switch (this.props.herdComboboxIdx) {
            case DPTVacc     : {result = this.getDPTVaccEditors(); break;}
            case HibVacc     : {result = this.getHibVaccEditors(); break;}
            case MeaslesVacc : {result = this.getMeaslesVaccEditors(); break;}
            case PneumoVacc  : {result = this.getPneumoVaccEditors(); break;}
            case RotaVacc    : {result = this.getRotaVaccEditors(); break;}
            case MeningA     : {result = this.getMeningAEditors(); break;}
            case Bednets     : {result = this.getBednetsEditors(); break;}
            case MalariaVacc : {result = this.getMalariaVaccEditors(); break;}
            default : break;
        }

        return (result)
    };

    render() {
        return (
            <div>
                {this.renderInstructions()}
                {this.renderDropdown()}
                {this.renderEditors()}
            </div>
        );
    }

}

export default CSHerdEffectiveness;