import React from "react";
import TButton from '@common/components/TButton';
import RS from "@common/strings/global";

const ButtonDownload = (props) => {
    const { onClick, colorType = "primary", disabled, containerStyle } = props;

    const disabledColor = '#e4e4e4';
    const color1 = '#f78c30';
    const color2 = '#65A7EB';
    const color3 = '#841d9e';

    const styles = {
        btn : {
            fontSize: '16px',
            background : disabled ? disabledColor : colorType === "primary" ? color1 : colorType === "secondary" ? color2 : color3,
            marginLeft: 0,
        }
    };

    return (
        <TButton
            caption={RS("GB_stDownload")}
            variant={"contained"}
            primary={true}
            style={styles.btn}
            onClick={onClick}
            disabled={disabled}
            containerStyle={containerStyle}
        />
    );
};

export default React.memo(ButtonDownload);