import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import TRadioGroup from '@common/components/TRadioGroup';
import RS from "@common/strings/RS";
import { SessionStorageUtil } from "../../api/localstorage/util";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

class TCSProjCreatedDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isGuest               : PropTypes.bool,
        explanationText       : PropTypes.string,
        onStateChange         : PropTypes.func,
        onSaveProjection      : PropTypes.func,
        onCloseProjection     : PropTypes.func,
        onContinueWorking     : PropTypes.func,
        projectionName        : PropTypes.string
    };

    static defaultProps = {
        isGuest               : false,
        explanationText       : '',
        onStateChange         : (value) => console.log('onStateChange : ' + JSON.stringify(value)),
        onSaveProjection      : (value) => console.log('onSaveProjection : ' + JSON.stringify(value)),
        onCloseProjection     : (value) => console.log('onCloseProjection : ' + JSON.stringify(value)),
        onContinueWorking     : (value) => console.log('onContinueWorking : ' + JSON.stringify(value)),
        projectionName        : ''
    };

    state = {
        itemIndex : 0
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onRadioGroupChange = (value) => {
        this.setState({
            itemIndex : value
        })
    };

    onApply = () => {
        switch (this.state.itemIndex) {
            case 0 : this.onSaveBtnClick(); break;
            case 1 : this.onClose(); break;
            case 2 : this.onContinueBtnClick(); break;
            default : this.onClose();
        }

        // <SaveAndCloseButton onClick={this.onSaveBtnClick} colorType="primary" />
        // <CloseWithoutSavingButton onClick={this.onClose} colorType="secondary" />
        // <WorkOnProjectionButton onClick={this.onContinueBtnClick} colorType="trinary" />
    };

    onClick = (action) => {
        this.props.onStateChange({
            dialogProjCreatedOpen : false
        });

        if (action === 'save') {
            if (this.props.isGuest){
                this.props.onStateChange({
                    dialogNotLoggedInOpen : true
                });
            } 
            else {
                this.props.onSaveProjection(() => {
                    this.props.onCloseProjection();
                });
            }
            this.returnToDefault();
        } 
        else if (action === 'close') {
            this.props.onCloseProjection();
            this.returnToDefault();
        }
        else if (action === 'cancel') {
            this.props.onStateChange({
                openingProj: ""
            });
        }
        else if (action === 'continue') {
            this.props.onContinueWorking();
        }
    };

    returnToDefault = () => {
        this.props.onStateChange({
            countryISO        : 4,
            selectedCountry   : "Afghanistan",
            yearRange         : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
            projectionNotes   : "",
            authorName        : SessionStorageUtil.getHumanName(),
            orgName           : SessionStorageUtil.getOrganization(),
        });
    };

    onCancelBtnClick = () => {
        this.onClick('cancel');
    };

    onContinueBtnClick = () => {
        this.onClick('continue');
    };

    onClose = () => {
        this.onClick('close');
    };

    onSaveBtnClick = () => {
        this.onClick('save');
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {

        let projectionName = (this.props.projectionName === "") ? "[Projection not found]" : this.props.projectionName;

        const styles = {
            radioLabelStyle : {
                fontSize   : '16px',
                color: '#0c3a79',
                // fontWeight: 'bold',
                display: 'inline-block'
            },
        };

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stWhatWouldYouLikeToDo')}
                onClose={this.onCancelBtnClick}
                content={
                    <div>

                        <p>
                            {this.props.explanationText}
                        </p>

                        <p style={{wordWrap: 'break-word'}}>
                            {RS("GB_stCurrentlyOpen") + " " + projectionName}
                        </p>

                        <TRadioGroup
                            itemIndex={this.state.itemIndex}
                            items={[
                                RS('GB_stSaveAndClose'),
                                RS('GB_stCloseWithoutSave')
                            ]}
                            handleChange={this.onRadioGroupChange}
                            labelStyle={styles.radioLabelStyle}
                            radioBtnColor={'secondary'}
                            sortInRow={false}
                        />
                    </div>
                }
                actions={
                    <div>
                        <ApplyButton onClick={this.onApply} colorType="primary" />
                    </div>
                }
            />
        );
    }

}

export default TCSProjCreatedDialog;