import React from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import RS from "@common/strings/RS";

class CSProjectionInfoBtn extends React.Component {

    static propTypes = {
        caption             : PropTypes.string,
        captionStyles       : PropTypes.object,
        variant             : PropTypes.string,
        color               : PropTypes.string,
        width               : PropTypes.string,
        size                : PropTypes.string,
        disabled            : PropTypes.bool,
        pictureLeft         : PropTypes.any,
        pictureRight        : PropTypes.any,
        transparent         : PropTypes.bool,
        shape               : PropTypes.string,
        onClick             : PropTypes.func,
        style               : PropTypes.any,
        containerStyle      : PropTypes.any,
        subCaption          : PropTypes.string,
        subCaptionDetails   : PropTypes.string,
        fullCaption         : PropTypes.string,
        onEditClick         : PropTypes.func,
        onSaveClick         : PropTypes.func,
        onSaveAsClick       : PropTypes.func,
        onCloseProjBtnClick : PropTypes.func,
    };

    static defaultProps = {
        caption             : 'Caption',
        captionStyles       : {},
        variant             : 'contained',
        color               : 'primary',
        width               : null,
        size                : 'medium',
        disabled            : false,
        pictureLeft         : null,
        pictureRight        : null,
        onClick             : () => console.log('CSProjectionInfoBtn onClick'),
        transparent         : false,
        shape               : 'rectangle',
        style               : {},
        containerStyle      : {display: 'inline-block'},
        subCaption          : "",
        subCaptionDetails   : "",
        fullCaption         : "",
        onEditClick         : () => console.log('CSProjectionInfoBtn onEditClick'),
        onSaveClick         : () => console.log('CSProjectionInfoBtn onSaveClick'),
        onSaveAsClick       : () => console.log('CSProjectionInfoBtn onSaveAsClick'),
        onCloseProjBtnClick : () => console.log('CSProjectionInfoBtn onCloseProjBtnClick'),
    };    
    
    state = {
        nameAnchor          : null,
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================
    
    onEdit = () => {
        this.handleFileBtnClose();
        this.props.onEditClick();
    };

    onSave = () => {
        this.handleFileBtnClose();
        this.props.onSaveClick();
    };

    onSaveAs = () => {
        this.handleFileBtnClose();
        this.props.onSaveAsClick();
    };

    onClose = () => {
        this.handleFileBtnClose();
        this.props.onCloseProjBtnClick();
    };

    handleFileBtnClick = event => {
        this.setState({
            nameAnchor : event.currentTarget
        });
    };

    handleFileBtnClose = () => {
        this.setState ({
            nameAnchor : null
        });
    };

    renderCaption = () => {
        if (this.props.caption === '') {
            return null;
        }
        else {
            return (
                <div style={{...this.props.captionStyles}}>
                {/*<div style={{fontWeight: 'normal', ...this.props.captionStyles}}>*/}
                    {this.props.caption}
                </div>
            );
        }
    };

    render() {
        let styles = {
            margin: "3px",
            textTransform : 'none',
            width: this.props.width, ...this.props.style,
        };

        if (this.props.transparent) {
            styles.backgroundColor = 'inherit';
            styles.boxShadow = 'inherit';
        }

        if (this.props.shape === 'circle') {
            styles.borderRadius = '50%';
            styles.minWidth = '40px';
            styles.minHeight = '40px';
            styles.padding = '0';
        }

        let styles2 = {

        };

        if (this.props.disabled) {
            styles2.color = '#a9a9a9';
            styles2.textDecoration = 'none';
        }

        let nameAnchor = this.state.nameAnchor;

        return (
            <React.Fragment>
                <div style={{...this.props.containerStyle}}>
                    <Button
                        variant={this.props.variant}
                        color={this.props.color}
                        disabled={this.props.disabled}
                        size={this.props.size}
                        onClick={this.handleFileBtnClick}
                        style={{...styles, ...this.props.style, ...styles2}}
                    >
                        <div>
                            <div style={{display: 'flex'}}>
                                {this.props.pictureLeft}
                                {this.renderCaption()}
                                {this.props.pictureRight}
                            </div>

                            <div style={{
                                fontSize: '10px',
                                color: '#0000008a',
                                fontWeight: 'initial',
                                margin: '0 10px',
                            }}>
                                {this.props.subCaption}
                            </div>
                        </div>
                    </Button>
                </div>

                <Menu
                    id="name-menu"
                    anchorEl={nameAnchor}
                    open={Boolean(nameAnchor)}
                    onClose={this.handleFileBtnClose}
                    getContentAnchorEl={null}
                    anchorOrigin={styles.anchorOrigin}
                    disableAutoFocusItem={true}
                >
                    <ListItem
                        button
                        onClick={this.onEdit}
                        alignItems="flex-start"
                        style={{ width: 260, height: "auto", display: "block" }}
                    >
                        <React.Fragment>
                            <div>
                                {this.props.fullCaption}
                            </div>

                            <div style={{marginTop: 8, fontSize: 12, color: '#0000008a'}}>
                                {this.props.subCaption}
                            </div>
                            
                            <div style={{marginTop: 8, fontSize: 12, color: '#0000008a'}}>
                                {this.props.subCaptionDetails}
                            </div>
                        </React.Fragment>
                    </ListItem>

                    <Divider />

                    <MenuItem onClick={this.onSave}>
                        {RS('GB_stSave')}
                    </MenuItem>

                    <MenuItem onClick={this.onSaveAs}>
                        {RS('GB_stSaveAs')}
                    </MenuItem>

                    <MenuItem onClick={this.onClose}>
                        {RS('GB_stClose')}
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
};

export default CSProjectionInfoBtn;