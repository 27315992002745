import React from 'react';
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class CSAppBarMenu extends React.Component {
    static propTypes = {
        pageID                      : PropTypes.string,
        appMode                     : PropTypes.string,
        isGuest                     : PropTypes.bool,
        projectCreated              : PropTypes.bool,
        onExploreLiSTDataBtnClick   : PropTypes.func,
        onGuidedExplorationBtnClick : PropTypes.func,
        onExtractBtnClick           : PropTypes.func,
        onCreateProjBtnClick        : PropTypes.func,
        onPageChange                : PropTypes.func,
        display                     : PropTypes.string,
        menuThreshold               : PropTypes.bool,
        isMobile                    : PropTypes.bool
    };

    static defaultProps = {
        pageID                      : "",
        appMode                     : "",
        isGuest                     : true,
        projectCreated              : false,
        onExploreLiSTDataBtnClick   : () => console.log("onExploreLiSTDataBtnClick"),
        onGuidedExplorationBtnClick : () => console.log("onGuidedExplorationBtnClick"),
        onExtractBtnClick           : () => console.log("onExtractBtnClick"),
        onCreateProjBtnClick        : () => console.log("onCreateProjBtnClick"),
        onPageChange                : () => console.log("onPageChange"),
        display                     : "inline-block",
        menuThreshold               : false,
        isMobile                    : false
    };

    constructor(props) {
        super(props);

        this.state = {
            projAnchor: null,
            fileAnchor: null,
            saveAnchor: null,
            toolAnchor: null
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    onPageChange = (id) => {

        this.setState({
            fileAnchor : null,
            saveAnchor : null
        });

        this.props.onPageChange(id);
    };

    onProjectionLibraryClick = () => {
        this.onPageChange("CSProjectionLibraryForm");
    };

    handleCreateProjBtnClick = (event) => {
        this.setState({
            projAnchor : event.currentTarget,
            //fileAnchor: null
        });
    };

    handleCreateProjBtnClose = () => {
        this.setState({
            projAnchor : null
        });
    };

    onCreateProjBtnClick = () => {
        this.props.onCreateProjBtnClick();
        this.setState({projAnchor: null, fileAnchor: null, toolAnchor: null});
    };

    onEditClick = () => {
        this.onPageChange("CSProjManagerForm");
        this.setState({
            projAnchor : null,
            nameAnchor : null,
            fileAnchor: null,
            toolAnchor: null
        });
    };

    onToolsBtnClick = (event) => {
        this.setState({
            toolAnchor: event.currentTarget
        })
    };

    onExtractBtnClick = () => {
        this.props.onExtractBtnClick();
        this.setState({
            toolAnchor: null
        })
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderExploreLiSTDataButton = () => {

        const styles = {
            div1 : {
                display: 'inline-block'
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.appMode.includes(CONSTANTS.CS_ExploreLiSTDataMode) ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '14px',
            }
        };

        return(
            <div style={styles.div1}>
                <TButton
                    caption={RS('GB_stExploreLiSTData')}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.props.onExploreLiSTDataBtnClick}
                />
            </div>
        )
    };

    renderGuidedExploration = () => {

        const styles = {
            div1 : {
                display: 'inline-block'
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.appMode.includes(CONSTANTS.CS_MissedOpportunitiesMode) ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '14px',
            }
        };

        return(
            <div style={styles.div1}>
                <TButton
                    caption={RS('GB_stMissedOpportunities')}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.props.onGuidedExplorationBtnClick}
                />
            </div>
        )
    };

    renderCreateProjButton = () => {
        //const projAnchor = this.state.projAnchor;

        const styles = {
            menuItems: {
                color: '#000000',
                textDecoration: ["CSProjManagerForm", "CSReviewDefaultDataForm", "CSManageInterventionsForm", "CSViewResultsForm"].includes(this.props.pageID ) ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0,
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '14px',
            },
            anchorOrigin : {
                vertical: 'bottom',
                horizontal: 'left'
            }
        };

        return (
            <div style={{display: 'inline-block'}}>
                <TButton
                    caption={RS('GB_stProjection')}
                    variant={"text"}
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.props.projectCreated ? this.handleCreateProjBtnClick : this.props.onCreateProjBtnClick}
                />

                {this.renderProjMenu("desktop")}
            </div>
        )
    };

    renderProjMenu = (screen) => {
        let styles = {
            anchorOrigin : {
                vertical: screen === "desktop" ? 'bottom' : "top",
                horizontal: screen === "tablet" ? -225 : "left"
            }
        };

        let projAnchor = this.state.projAnchor;

        return (
            <Menu
                id="proj-menu"
                anchorEl={projAnchor}
                open={Boolean(projAnchor)}
                onClose={this.handleCreateProjBtnClose}
                getContentAnchorEl={null}
                anchorOrigin={styles.anchorOrigin}
            >
                <MenuItem onClick={this.onCreateProjBtnClick}>
                    {RS('GB_stCreateANewProjection')}
                </MenuItem>

                <MenuItem onClick={this.onEditClick}>
                    {/*{RS('GB_stEdit') + ": " + this.props.projectionName}*/}
                    {RS('GB_stEditYourCurrProj')}
                </MenuItem>
            </Menu>
        );
    };

    renderLibraryMenuItem = () => {
        const styles = {
            div1 : {
                display: 'inline-block',
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.pageID === "CSProjectionLibraryForm" ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '14px',
            }
        };

        return (
            <div
                id={"lib_btn"}
                style={styles.div1}
                onMouseOver={() => document.querySelector("#lib_btn").setAttribute("title", RS("GB_stProjectionLibrary"))}
            >
                {/*{this.renderDividerLine()}*/}
                <TButton
                    caption={RS("GB_stLibrary")}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.onProjectionLibraryClick}
                />
            </div>
        )
    };

    renderExtractBtn = () => {
        const styles = {
            div1 : {
                display: 'inline-block',
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.pageID === "ExtractTool" ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '14px',
            }
        };

        return (
            <div
                id={"extract_btn"}
                style={styles.div1}
                //onMouseOver={() => document.querySelector("#extract_btn").setAttribute("title", RS("GB_stProjectionLibrary"))}
            >
                {/*{this.renderDividerLine()}*/}
                <TButton
                    caption={"Extract"}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.props.onExtractBtnClick}
                />
            </div>
        )
    };

    renderToolBtn = () => {
        const styles = {
            div1 : {
                display: 'inline-block',
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.pageID === "ExtractTool" ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '14px',
            }
        };

        return (
            <div
                id={"extract_btn"}
                style={styles.div1}
                //onMouseOver={() => document.querySelector("#extract_btn").setAttribute("title", RS("GB_stProjectionLibrary"))}
            >
                {/*{this.renderDividerLine()}*/}
                <TButton
                    caption={"Tools"}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.onToolsBtnClick}
                />
            </div>
        )
    };

    renderToolMenu = () => {
        return (
            <Menu
                id="tool-menu"
                anchorEl={this.state.toolAnchor}
                open={Boolean(this.state.toolAnchor)}
                onClose={() => this.setState({toolAnchor: null})}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
            >
                <MenuItem onClick={this.onExtractBtnClick}>{"Extract"}</MenuItem>
            </Menu>
        )
    };

    render() {
        return (
            <div style={{display: this.props.display, borderTop: this.props.menuThreshold ? "1px solid lightgrey" : 0}}>
                {this.renderExploreLiSTDataButton()}

                {this.renderGuidedExploration()}

                {this.renderCreateProjButton()}

                {this.props.isGuest ? null : this.renderToolBtn()}

                {this.renderToolMenu()}

                {this.props.isGuest ? null : this.renderLibraryMenuItem()}
            </div>
        );
    }
}

export default CSAppBarMenu;