import React from "react";
import * as PropTypes from "prop-types";
import TScatterPlot from "./TScatterPlot";

const createStyles = props => ({
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap"
  },
  chartTitle: {
      fontSize: 18,
      marginBottom: 15,
  }
});

const transformData = data =>
  data.map(datum => ({
    name: datum.interventionName,
    firstYear: datum.firstYear,
    finalYear: datum.finalYear,
    data: datum.arrayOfValues.map(value => value || null),
    rDec: datum.rDec.map(value => value || null),
  }));

const TMultiChart = props => {
  const styles = createStyles(props);

  const data = transformData(props.data);

  const maximum =
    props.yAxisMaximum !== undefined
      ? props.yAxisMaximum
      : data.reduce(
          (result, datum) => Math.min(result, ...datum.data),
          Number.MIN_VALUE
        );

  return (
      <div>
            <div>
                <p style={styles.chartTitle}>{props.chartTitle}</p>
            </div>

            <div style={styles.container}>
              {data.map(datum => (
                <TScatterPlot
                  key={datum.name}
                  width={props.chartWidth}
                  title={datum.name}
                  titleFontSize={props.panelTitleFontSize}
                  titleFontWeight={props.panelTitleFontWeight}
                  yAxisTitle={props.yAxisTitle}
                  yAxisMaximum={maximum}
                  series={[datum]}
                />
              ))}
            </div>
      </div>
  );
};

TMultiChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      interventionName: PropTypes.string.isRequired,
      firstYear: PropTypes.number.isRequired,
      finalYear: PropTypes.number.isRequired,
      arrayOfValues: PropTypes.array.isRequired,
      rDec: PropTypes.array.isRequired,
    })
  ).isRequired,
  chartWidth: PropTypes.number,
  yAxisTitle: PropTypes.string.isRequired,
  panelTitleFontSize: PropTypes.number,
  panelTitleFontWeight: PropTypes.string,
  chartTitle: PropTypes.string,
};

export default TMultiChart;
