import axios from 'axios';

const allowPingMessages = false;

export const makeUpload = (url, clientId, file, successFn, errorFn) => {

    const client = axios.create();

    let contentType = '';
    let ext = file.name.split('.').pop().toLowerCase();

    switch (ext) {
        case 'csv'  : contentType = 'text/csv'; break;
        case 'xls'  : contentType = 'application/vnd.ms-excel'; break;
        case 'xlsx' : contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
        case 'GTZ'  : contentType = 'application/GoalsTesting_GTZ_FILE'; break;
        case 'pjnz' : contentType = 'application/SPECTRUM_PJNZ_FILE'; break;
        default     : contentType = '';
    }

    console.log({
        methodName : 'makeUpload',
        headers: {
            'Content-Type': contentType,
            'SessionGuid' : clientId
        },
        file: file
    });

    client
        .post(url, file, {
            headers: {
                'Content-Type': contentType,
                'SessionGuid' : clientId,
                'fileTitle'   : file.name.replace('.PJNZ','.pjnz')
            }
        })
        .then(response => {

            let output = {};
            output.methodName = 'makeUpload';
            output.result = response;
            console.log(output);

            successFn();
            //DoThen(response, successFn, errorFn);
        })
        .catch(error => {
            // errorFn();
            DoError(error, errorFn);
        });

};

export const makeRequest = (url, clientId, method, params, jsonParams, successFn, errorFn) => {

    let startTime = Date.now();

    if (
        (method !== "GBLoginService.Ping") ||
        (allowPingMessages)
    ) {
        console.log({
            _ : "SEND",
            methodName : method,
            params: {
                ...params,
                jsonMesg: jsonParams
            },
            timeSent : Date(startTime).toString()
        });
    }

    // console.log(jsonParams);

    axios
        .create({
            baseURL: url,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .post(url, {
            id: clientId,
            method: method,
            params: {
                ...params,
                jsonMesg: JSON.stringify(jsonParams)
            }
        })
        .then(response => {
            DoThen(response, startTime, successFn, errorFn);
        })
        .catch(error => {
            DoError(error, errorFn, method);
        });
};

const DoThen = (response, startTime, successFn, errorFn) => {

    let clientID = response.data.id;
    let method = JSON.parse(response.config.data).method;
    let params = JSON.parse(response.config.data).params;
    let result = response.data.result.Result;

    if (result === 'undefined') {
        result = undefined;
    }
    else if ((typeof result === 'string') && (result.charAt(0) === '{')) {
        result = JSON.parse(response.data.result.Result);
    }
    else {
        result = response.data.result.Result;
    }

    let output = {};
    output._ = "GET";
    output.methodName = method;
    output.params = params;
    output.result = result;
    output.Time = Date.now() - startTime;
    output.timeReceived = Date(Date.now().toString());
    // console.log(output);

    if (
        (method !== "GBLoginService.Ping") ||
        (allowPingMessages)
    ) {
        console.log(output);
    }

    if (result.status === 1) {
        if (typeof errorFn !== 'undefined') {
            try {
                errorFn(result.statusText);
            }
            catch(e) {
                console.error(e);
                alert('An error has occurred on the client side after receiving the server data: ' + e.message);
            }
        }
        else {
            alert('The server returned an error: ' + result.statusText);
        }
    }
    else if (typeof successFn !== 'undefined') {
        try {
            if (typeof result.result !== 'undefined') {
                successFn(result.result, clientID);
            }
            else {
                successFn(result, clientID);
            }
        }
        catch(e) {
            console.error(e);
            alert('An error has occurred on the client side after receiving the server data: ' + e.message);
        }
    }
};

const DoError = (error, errorFn, method) => {

    // If a custom error callback was passed in, call it.
    if (typeof errorFn !== 'undefined') {
        try {
            console.error(error);
            errorFn(error.message, method);
        }
        catch(err) {
            alert('An error has occurred on the client side after handling a server error: ' + err.message + '\n\nMethod: ' + method);
        }
    }

    // GENERAL ERROR HANDLER
    else {

        // Did not contact the server
        if (typeof error.response === 'undefined') {
            switch (error.message) {
                case "Network Error" :
                    alert('The server may be unavailable at this time.');
                    console.error(error);
                    break;

                default:
                    alert('An error has occurred with communicating to the server.');
                    console.error(error);
                    break;
            }
        }

        // Contacted the server, but the server had an error
        else {
            let errorMsg = JSON.parse(error.response.request.response).error.message;
            console.error('Error returned from the server: ' + errorMsg);
            alert('Error returned from the server: ' + errorMsg);
        }
    }

    // if (typeof error.response === 'undefined') {
    //
    //     switch (error.message) {
    //         case "Network Error" :
    //             alert('The server may be unavailable at this time.');
    //             break;
    //
    //         default:
    //             alert('An error has occurred with communicating to the server.');
    //             console.error(error);
    //             break;
    //     }
    //
    //     // console.error('Client/server connection was not made! The server may be unavailable at this time.');
    //     // console.log('Here is the error:');
    //     // console.warn(error);
    // }
    // else {
    //     let errorMsg = JSON.parse(error.response.request.response).error.message;
    //     console.warn('Error returned from the server: ' + errorMsg);
    //
    //     if (typeof errorFn !== 'undefined') {
    //         try {
    //             errorFn(errorMsg);
    //         }
    //         catch(err) {
    //             alert('An error has occurred on the client side after handling a server error: ' + err.message);
    //         }
    //     }
    //     else {
    //         alert('The server returned an error: ' + errorMsg);
    //     }
    // }
};