import React from "react";
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSNutStatusDistDrawer from "../../drawers/HealthStatus/CSNutStatusDistDrawer";

class CSNutStatusDistBlueBox extends React.Component {
    static propTypes = {
        firstYear        : PropTypes.number,
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        onStateChange    : PropTypes.func,
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        firstYear        : 1,
        CSModvars        : [],
        onStateChange    : () => console.log("onStateChange"),
        onOpenHelpDialog : () => console.log("onOpenHelpDialog"),
    };

    state = {
        openDrawer       : false,
    };

    _onOpenDrawer = () => {
        this.setState({
            openDrawer : true
        });
    };

    _onCloseDrawer = () => {
        this.setState({
            openDrawer : false
        });
    };   

    render() {
        return (
            <React.Fragment>
                <CSBlueBox2
                    caption={RS("GB_stNutritionStatusDist")}
                    description=""
                    onClick={this._onOpenDrawer}
                />

                {this.state.openDrawer ? (
                    <CSNutStatusDistDrawer
                        firstYear={this.props.firstYear}
                        CSModvars={this.props.CSModvars}
                        onClose={this._onCloseDrawer}
                        onOpenHelpDialog={this.props.onOpenHelpDialog}
                        onStateChange={this.props.onStateChange}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default CSNutStatusDistBlueBox;
