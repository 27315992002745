import * as React from "react";
import PropTypes from "prop-types";
import IconButton from '@material-ui/core/IconButton/index';
import Tooltip from '@material-ui/core/Tooltip/index';
import ZoomIn from '@material-ui/icons/ZoomIn';
import TButton from '@common/components/TButton';

class CSBlueBox2 extends React.Component {
    
    static propTypes = {
        caption          : PropTypes.string,
        description      : PropTypes.string,
        captionStyle     : PropTypes.object,
        descriptionStyle : PropTypes.object,
        onClick          : PropTypes.func,
        editContent      : PropTypes.any,
        isIconButton     : PropTypes.bool,
        iconBtnStyle     : PropTypes.object,
        iconBtnDisabled  : PropTypes.bool,
        iconStyle        : PropTypes.object,
        isRegularButton  : PropTypes.bool,
        regBtnDisabled   : PropTypes.bool,
        regBtnStyle      : PropTypes.object,
        buttonColor      : PropTypes.string,
        buttonVariant    : PropTypes.string,
        style            : PropTypes.object,
        pictureRight     : PropTypes.any,
        isLink           : PropTypes.bool,
    };

    static defaultProps = {
        caption          : "Default caption",
        description      : "Default description",
        captionStyle     : {
                                height: '110px',
                                width: '205px',
                                display: 'table-cell',
                                verticalAlign: 'middle',
                                margin: '0 auto',
                                textAlign: 'center',
                            },
        descriptionStyle :  {
                                position: 'absolute',
                                top: '72px',
                                left: '16px',
                                fontSize: '12px',
                                fontStyle: 'italic',
                                width: '172px',
                                textAlign: 'center'
                            },
        onClick          : () => console.log('TCSBlueBox2 onClick'),
        editContent      : null,
        isIconButton     : false,
        iconBtnDisabled  : false,
        iconBtnStyle     : {},
        iconStyle        : {},
        isRegularButton  : false,
        regBtnDisabled   : false,
        regBtnStyle      : {},
        buttonColor      : 'secondary',
        buttonVariant    : 'contained',
        style            : {},
        pictureRight     : null,
        isLink           : false
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            wrapper : {
                display: 'inline-block'
            },
            blueBox : {
                position: 'relative',
                background: '#0C3A79',
                width: 205,
                height: 110,
                margin: 0,
                display: 'inline-block',
                verticalAlign: 'top',
                color: '#FFFFFF',
                fontSize: '22px',
                textAlign: 'bottom',
                padding: '0px',
                cursor: 'pointer',
                border: '1px solid #FFFFFF',
            },
        };

        if (this.props.isIconButton && !this.props.iconBtnDisabled) {
            return (
                <div style={this.props.style}>
                    <Tooltip title={this.props.caption} disableFocusListener={true}>
                        <IconButton
                            className="icon-hover"
                            style={this.props.iconBtnStyle}
                            onClick={this.props.onClick}
                            disabled={this.props.iconBtnDisabled}
                        >
                            <ZoomIn style={this.props.iconBtnStyle} />

                        </IconButton>
                    </Tooltip>

                    {this.props.editContent}
                </div>
            );
        }
        else if (this.props.isIconButton && this.props.iconBtnDisabled) {
            return (
                <div style={this.props.style}>
                        <IconButton
                            className="icon-hover"
                            style={this.props.iconBtnStyle}
                            onClick={this.props.onClick}
                            disabled={this.props.iconBtnDisabled}
                        >
                            <ZoomIn style={this.props.iconBtnStyle} />

                        </IconButton>

                    {this.props.editContent}
                </div>
            );
        }
        else if (this.props.isRegularButton) {
            return (
                <div style={this.props.style}>
                    <TButton 
                        caption={this.props.caption}
                        style={this.props.regBtnStyle}
                        onClick={this.props.onClick}
                        color={this.props.buttonColor}
                        variant={this.props.buttonVariant}
                        disabled={this.props.regBtnDisabled}
                        pictureRight={this.props.pictureRight}
                    />

                    {this.props.editContent}
                </div>
            );
        }
        else if (this.props.isLink) {
            return (
                <div>
                    <p style={{margin:0}} onClick={this.props.onClick}>
                        {this.props.pictureLeft}
                        {this.props.caption}
                    </p>
                    {this.props.editContent}
                </div>
            )
        }
        else {
            return (
                <div style={styles.wrapper}>
                    <div style={styles.blueBox} onClick={this.props.onClick}>
                        <div style={this.props.captionStyle}>{this.props.caption}</div>
                        <div style={this.props.descriptionStyle}>{this.props.description}</div>
                    </div> 
    
                    {this.props.editContent}
                </div>
            );
        }

    }
}

export default CSBlueBox2;