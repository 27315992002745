import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import EffectivenessOfInterventionsExpPanel from "../expansionPanels/EffectivenessValues/EffectivenessOfInterventionsExpPanel";
import EffectivenessExpPanel from "../expansionPanels/EffectivenessValues/EffectivenessExpPanel";
import Data from "../../../data/ExploreDefaultData";
import CONSTANTS from "utilities/CS/CSConst";

class EffectivenessValuesOptions extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        visible         : PropTypes.bool,
        onAddDisplay    : PropTypes.func,
        language        : PropTypes.number,
    };

    static defaultProps = {
        visible         : true,
        onAddDisplay    : () => console.log('EffectivenessValuesOptions onAddDisplay'),
        language        : CONSTANTS.GB_English,
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    renderContent = () => {
        let expPanels = [];

        expPanels.push(
            <EffectivenessOfInterventionsExpPanel
                key                         = {'EffOfIntPanel'}
                onAddDisplay                = {this.props.onAddDisplay}
                language                    = {this.props.language}
            />
        );

        expPanels.push(
            <EffectivenessExpPanel
                key                         = {'EffOnIncPanel'}
                panelName                   = {RS('GB_stEffIntervInc')}
                panelKey                    = {'EffectivenessOnIncidence'}
                content                     = {Data.exploreEffIncContent}
                onAddDisplay                = {this.props.onAddDisplay}
                language                    = {this.props.language}
            />
        );

        expPanels.push(
            <EffectivenessExpPanel
                key                         = {'HerdEffPanel'}
                panelName                   = {RS('GB_stHerdEff')}
                panelKey                    = {'HerdEffectiveness'}
                content                     = {Data.exploreHerdEffContent}
                onAddDisplay                = {this.props.onAddDisplay}
                language                    = {this.props.language}
            />
        );

        expPanels.push(
            <EffectivenessExpPanel
                key                         = {'NutIntPanel'}
                panelName                   = {RS('GB_stNutritionInterv')}
                panelKey                    = {'NutritionInterventions'}
                content                     = {Data.exploreNutIntContent}
                onAddDisplay                = {this.props.onAddDisplay}
                language                    = {this.props.language}
            />
        );

        expPanels.push(
            <EffectivenessExpPanel
                key                         = {'ImpactUnderNutPanel'}
                panelName                   = {RS('GB_stImpactUnderNutrition')}
                panelKey                    = {'ImpactUnderNutrition'}
                content                     = {Data.exploreUnderNutContent}
                onAddDisplay                = {this.props.onAddDisplay}
                language                    = {this.props.language}
            />
        );

        return (expPanels);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            wrapper : {
                padding: '0 0 10px 0',
                // margin: '10px 15px 10px 0',
                margin: '0 15px 0 0',
            },
            invisible : {
                position: 'absolute',
                left: -9999,
                visibility: 'hidden',

                /* Edge doesn't obey the css visibility correctly, go figure. Here's a hack. */
                height: 0,
                top: 0,
                overflow: 'hidden',
            },
            actions : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
        };

        let wrapperStyles = 
            this.props.visible ?
                styles.wrapper :
                {...styles.wrapper, ...styles.invisible}

        return (
            <div style={wrapperStyles}>
                {
                    this.renderContent()
                }
            </div>
        );
    }

}

export default EffectivenessValuesOptions;