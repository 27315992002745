import * as React from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';

class TSwitch extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption    : PropTypes.string,
        value      : PropTypes.bool,
        name       : PropTypes.string,
        enabled    : PropTypes.bool,
        color      : PropTypes.string,
        labelStyle : PropTypes.object,
        onClick    : PropTypes.func,
        style      : PropTypes.object,
        classes    : PropTypes.object,
        boxStyle   : PropTypes.object,
        tooltip    : PropTypes.string
    };

    static defaultProps = {
        caption    : '',
        value      : null,
        name       : '',
        enabled    : true,
        color      : 'primary',
        labelStyle : {},
        onClick    : (checked) => console.log('TCheckBox onClick [' + checked + ']'),
        style      : {},
        classes    : {},
        boxStyle   : {},
        tooltip    : '',
        customProp : '',
        customProp2 : ''
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onChange = (event, checked) => {
        this.props.onClick(checked, event, this.props.name, this.props.caption, this.props.customProp, this.props.customProp2);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        const styles = {
            // height: '30px'
        };

        const unstyledComponent = props => (
            <FormGroup style={{height: 0, marginTop: 16, ...this.props.style}}>
                <FormControlLabel
                    control={
                        <Tooltip title={this.props.tooltip} >
                            <Switch
                                checked={this.props.value}
                                color={this.props.color}
                                disabled={!this.props.enabled}
                                onChange={this.onChange}
                                style={{...styles, ...this.props.boxStyle}}
                            />
                        </Tooltip>
                    }
                    label={this.props.caption}
                    classes={{
                        label: props.classes.label
                    }}
                />
            </FormGroup>
        );

        const StyledComponent = withStyles({
            label: this.props.labelStyle || {}
        })(unstyledComponent);

        return (
            <StyledComponent />
        );
    }

}

export default TSwitch;