import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getIdxFromYear,
    getYearFromIdx,
    lockPackTable,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {FindIntervRec} from "utilities/CS/CSDataUtil";

export const PercWom15t49LowBMIEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stLowBMI');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_MaternalHealth;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, IVInfo, CSModvars) => {
        let changedModvars = [];
        let PercWom15t49LowBMIMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PercWom15t49LowBMI_MV);
        PercWom15t49LowBMIMV["value"] = pt.tableData.value[1][1] / 100;

        /* Set balanced energy affected fraction and stillbirth balanced energy
           affected fraction = % of women aged 15-49 with low BMI. */
        let value = PercWom15t49LowBMIMV["value"];

        let MaternalNutritionMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_IntervForBO_MV7);
        for (let term = CONSTANTS.CS_PreTermSGA; term <= CONSTANTS.CS_TermAGA; term++){
            MaternalNutritionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_MultMicroLowBMI][term] = value;
            MaternalNutritionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CONSTANTS.CS_MultMicroLowBMI][term] = value;

            MaternalNutritionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_MultMicroNormBMI][term] = 1 - value;
            MaternalNutritionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CONSTANTS.CS_MultMicroNormBMI][term] = 1 - value;
        }

        PercWom15t49LowBMIMV["source"][0][0] = pt.Source[0];

        changedModvars.push(PercWom15t49LowBMIMV);
        changedModvars.push(MaternalNutritionMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    let numCols = 2;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stPercWom15t49LowBMI');

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.RDec[1][col] = 2;
        col++;
    }

    pt = lockPackTable(pt, 1, pt.yearArr.length > 0, false);

    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.RDec[1][1] = 2;

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let PercWom15t49LowBMI = getModvarByTag(CSModvars, CONSTANTS.CS_TG_PercWom15t49LowBMI_MV);
    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            if(PercWom15t49LowBMI["exploreLiST"][t] >= 0) {
                pt.tableData.value[1][col] = PercWom15t49LowBMI["exploreLiST"][t] * 100;
            }
            col++;
        }
    }
    else {
        pt.tableData.value[1][1] = PercWom15t49LowBMI["value"] * 100;
    }

    pt.Source[0] = PercWom15t49LowBMI["source"][0][0];

    return pt;
};

