import * as React from 'react';
import * as PropTypes from "prop-types";
import GBStdTable from './GbStdTable';
import MultiplicationDialog from "../../dialogs/MultiplicationDialog";
import SourcesDialog from "../../dialogs/SourcesDialog";
import { multiply } from "./data/transformations";
import { getAllSources } from "utilities/GB/GBUtil";

class GbStdTableMiddle extends React.PureComponent {

    static propTypes = {
        isResult           : PropTypes.bool,
        refToTable         : PropTypes.any,
        packTable          : PropTypes.object,
        onPackTableChanged : PropTypes.func,
    };

    static defaultProps = {
        isResult           : false,
        refToTable         : null,
        packTable          : null,
        onPackTableChanged : () => console.log('GbStdTableMiddle onPackTableChanged'),
    };

    state = {
        params             : undefined,
        dialogMultiplyOpen : false,
    };

    //------------------------------------------------------------------------------------

    onMultiplyRequested = (params) => {
        this.setState({
            params             : params,
            dialogMultiplyOpen : true
        });
    };

    onMultiplyClose = () => {
        this.setState({
            params             : undefined,
            dialogMultiplyOpen : false
        });
    };

    onMultiplyComplete = (value) => {
        let params = { ...this.state.params, multiplier: value };

        let result = multiply(params, this.props.packTable);
        let error = result.error;
        let tableData = result.data;

        if (error) {
            return;
        }

        let packTable = { ...this.props.packTable, tableData: { value : tableData } };

        this.setState({
            dialogMultiplyOpen : false
        }, () => {
            this.props.onPackTableChanged(packTable);   
        });
    };

    //------------------------------------------------------------------------------------

    onSourceRequested = (params) => {
        this.setState({
            params             : params,
            dialogSourceOpen   : true
        });
    };

    onSourceClose = () => {
        this.setState({
            params             : undefined,
            dialogSourceOpen   : false
        });
    };

    onSourceComplete = (value) => {
        let packTable = { ...this.props.packTable };

        if (typeof packTable.Source === 'undefined') {
            packTable.Source = [];
            for (let i = 0; i <= this.state.params.idx; i++) {
                packTable.Source[i] = "";
            }
        }

        packTable.Source[this.state.params.idx] = value;

        this.setState({
            dialogSourceOpen : false
        }, () => {
            this.props.onPackTableChanged(packTable);   
        });
    };

    //------------------------------------------------------------------------------------

    onAllSourcesRequested = () => {
        this.setState({
            params : {
                title : this.props.packTable.Title,
                source : getAllSources(this.props.packTable),
                editable : false   
            },
            dialogSourceOpen   : true
        });
    };

    onAllSourcesClose = () => {
        this.setState({
            params             : undefined,
            dialogSourceOpen   : false
        });
    };
    
    //------------------------------------------------------------------------------------

    render() {

        // Remove refToTable as a prop that gets passed into GBStdTable
        const { refToTable, isResult, ...rest } = this.props;

        return (
            <React.Fragment>
                
                <GBStdTable
                    ref={this.props.refToTable}
                    onSourceRequested={this.onSourceRequested}
                    onMultiply={this.onMultiplyRequested}
                    customContextMenu={
                        (!isResult) ?
                            [
                                {
                                    name: "All Sources",
                                    action: this.onAllSourcesRequested,
                                    disabled: false,
                                }
                            ]
                            :
                            undefined
                    }
                    {...rest}
                />

                {
                    (this.state.dialogMultiplyOpen) ?
                        <MultiplicationDialog
                            onClose={this.onMultiplyClose}
                            onApply={this.onMultiplyComplete}
                        />
                        :
                        null
                }

                {
                    (this.state.dialogSourceOpen) ?
                        <SourcesDialog
                            title={this.state.params.title}
                            source={this.state.params.source}
                            onClose={this.onSourceClose}
                            onApply={this.onSourceComplete}
                            editable={this.state.params.editable}
                        />
                        :
                        null
                }
                
            </React.Fragment>
        );
    }
}

export default GbStdTableMiddle;