import React from "react";
import RS from "@common/strings/global";
import TEdit from "@common/components/TEdit";
import Stepper from "../components/Stepper";
import ButtonBack from "../components/buttons/ButtonBack";
import ButtonProcess from "../components/buttons/ButtonProcess";
import Progress from "../components/Process/Progress";
import ButtonDownload from "../components/buttons/ButtonDownload";
import CSDialog from "../components/CSDialog";
import { Process } from "../data/Process";
import TButton from "../../../@common/components/TButton";
import {GetApp} from "@material-ui/icons";
// import { clone } from "@common/util/GBUtil";
// import { DownloadCSVFromCSVConvert } from "@common/util/GBUtil";
// import { convertArrayToCSV } from 'convert-array-to-csv';

const styles = {
    content : {
        marginTop: 20,
        maxWidth: 615,
        minWidth: 615,
        marginLeft: 10,
    },
    buttonSaveSession: {
        float: "right",
        marginLeft: 15
    },
    buttonBack : {
        float: "right",
    },
    buttonProcess : {
        float: "right",
        marginLeft: 15,
    },
    buttonDownload : {
        float: "right",
        marginLeft: 15,
    },
    progressMessage : {
        color: "#CCC",
        fontSize: 12,
    },
    title : {
        float: 'left',
    },
    wifiIcon : {
        float: 'right'
    },
    label: {
        fontSize: 16,
        fontWeight: "bold",
        cursor: "pointer",
        position: "relative",
        top: 2
    },
    download: {
        verticalAlign: "middle",
        cursor: "pointer"
    },
    button: {
        color: '#000000',
        textTransform: 'none',
        fontSize: '16px',
        padding : '10px 0',
        margin: '10px 0',
        marginLeft: '-10px'
    },
    captionStyles : {
        marginLeft: '10px',
        marginRight: '10px'
    }
};  

const ProcessPage = (props) => {
    const { fileFormat, onPageChange, onStateChange, onStepChange, stepsCompleted, API, recalculateProjs, Theme } = props;
    const { projectionsSelected, indicatorsSelected, years } = props;
    const { MinProjFirstYr, MaxProjFinalYr, exportFileName} = props;

    const [fileName, setFileName] = React.useState(exportFileName || "TestExport");
    const [showProgress, setShowProgress] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [progessMessage, setProgressMessage] = React.useState("");
    const [done, setDone] = React.useState(false);

    const [projX, setProjX] = React.useState(1);
    const [indX, setIndX] = React.useState(1);

    // const [sheet, setSheet] = React.useState([]);

    const doDownload = () => {
        
        /* Server download */
        setProgressMessage('Downloading extract file');
        API('downloadExtractFile', { fileTitle : `${fileName}`, extractFileFmt : fileFormat }, (response) => {
            let url = response.API_URL_BASE + '/' + response.response;
            let a = document.createElement("a");
            a.href = url;
            let fileName = url.split("/").pop();
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }, (msg) => {
            alert(`Error downloading with message: ${msg}`);
        });

        /* Download local CSV */
        // Download2DArrayToCSV(sheet, fileName);

        // let csvFromArrayOfArrays = convertArrayToCSV(sheet);
        // DownloadCSVFromCSVConvert(csvFromArrayOfArrays, fileName);
    };

    const onProccessSuccess = (result) => {
        setProcessing(false);
        setDone(true);   
        setProgressMessage("Complete");
        // setSheet(clone(result));
        // console.log(result);
    };

    const onProccessError = (msg) => {
        alert(msg);
        setProcessing(false);
        setDone(false);
        setShowProgress(false);
    };

    const onProcess = () => {
        if (fileName === "") {
            alert("Please enter a filename.");
            return;
        }

        if (projectionsSelected.length <= 0) {
            alert("No projections selected.");
            return;
        }

        if (indicatorsSelected.length <= 0) {
            alert("No indicators selected.");
            return;
        }

        onStateChange({
            exportFileName : fileName
        }, () => {
            setProcessing(true);
            setShowProgress(true);
            setProjX(0);
            setIndX(0);
            setDone(false); 
            Process({API, recalculateProjs, indicatorsSelected, projectionsSelected, setProgressMessage, setIndX,
                setProjX, years, onProccessSuccess, onProccessError});
        });
    };

    const onProgressClose = () => {
        setShowProgress(false);
    };

    const onSaveConfig = () => {

        let EXFile = [{
            selProjNames: projectionsSelected,
            selInds: indicatorsSelected,
            resFileFormat: fileFormat,
            yrsToExtract: years,
            recalcProjBool: recalculateProjs,
            outputFileName: fileName,
            minYr: MinProjFirstYr,
            maxYr: MaxProjFinalYr
        }];
        let JSONText = JSON.stringify(EXFile);//JSON.stringify(modVarObjArrClone);
        //JSONText = JSONText.replace(/#/gi, "REPLACENUMBER");
        let content = "data:text/json;charset=utf-8," + JSONText;
        let encodedUri = encodeURI(content);
        console.log(encodedUri);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + "_ExtractConfig.json");
        document.body.appendChild(link); // Required for FF
        link.click();

    };

    return (
        <React.Fragment>
            <Stepper
                steps={[RS('GB_stChooseProj'), RS('GB_stSelectIndicators'), RS('GB_stSetConfig'), RS('GB_stProcess')]}
                activeStep={3}
                completedSteps={stepsCompleted}
                onStepBtnClick={onStepChange}
                useAltColors={true}
                Theme={Theme}
            />

            <div style={styles.content}>
                <TEdit
                    caption={RS("GB_stFileName")}
                    value={fileName}
                    onChange={(value) => setFileName(value)}
                    foc={true}
                    fullWidth={true}
                />

                {/*<label onClick={onSaveConfig} style={styles.label}>*/}
                {/*    {RS("GB_stSaveConfig")}*/}
                {/*</label>*/}

                {/*<Tooltip style={{color: Theme.palette.secondary.main, ...styles.download}} title={RS("GB_stSave")}>*/}
                {/*    <GetApp*/}
                {/*        color={"primary"}*/}
                {/*        onClick={onSaveConfig}*/}
                {/*    />*/}
                {/*</Tooltip>*/}

                <TButton
                    caption={RS('GB_stSaveConfig')}
                    variant={"text"}
                    style={styles.button}
                    captionStyles={styles.captionStyles}
                    onClick={onSaveConfig}
                    pictureRight={<GetApp
                        color={"secondary"}
                        //onClick={onSaveConfig}
                    />}
                />

                {/*<TButton*/}
                {/*    onClick={onSaveConfig}*/}
                {/*    caption={"Download configuration"}*/}
                {/*    color={"secondary"}*/}
                {/*    containerStyle={styles.buttonSaveSession}*/}
                {/*/>*/}

                <ButtonProcess
                    onClick={onProcess}
                    containerStyle={styles.buttonProcess}
                    disabled={processing}
                />

                <ButtonBack
                    onClick={() => onPageChange("ConfigurationPage")}
                    containerStyle={styles.buttonBack}
                    colorType="secondary"
                />

                {
                    showProgress ?
                        <CSDialog
                            title={
                                <React.Fragment>
                                    {
                                        (done) ?
                                            <div style={styles.title}>Extract complete</div> :
                                            <div style={styles.title}>Extracting...</div>
                                    }
                                </React.Fragment>
                            }
                            modal={true}
                            minWidth={650}
                            onClose={done ? onProgressClose : null}
                            content={
                                <div style={{clear: "both"}}>
                                    <div style={styles.progressMessage}>
                                        Status: {progessMessage}
                                    </div>

                                    <Progress
                                        fileName={fileName}
                                        proj={projX}
                                        ind={indX}
                                        projectionsSelected={projectionsSelected}
                                        indicatorsSelected={indicatorsSelected}
                                        done={done}
                                    />

                                    {
                                        (done) ? 
                                            <ButtonDownload
                                                onClick={doDownload}
                                                containerStyle={styles.buttonDownload}
                                                disabled={processing}
                                            />
                                            :
                                            null
                                    }
                                </div>      
                            }
                        />
                        : null
                }
            </div>
        </React.Fragment>
    );
};

export default ProcessPage;