import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    getIdxFromYear,
    getYearFromIdx,
    generatePackChart,
    lockPackTable,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const AvgHouseholdSizeEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, exploreData = false) => {
        let editorName = RS('GB_stAveHouseholdSize');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, exploreData);
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_HouseholdStatus;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let AvgHouseholdSizeMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_AvgHouseholdSize_MV);
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            AvgHouseholdSizeMV["value"][t] = pt.tableData.value[1][col];
            col++;
        }
        AvgHouseholdSizeMV["source"][0][0] = pt.Source[0];

        changedModvars.push(AvgHouseholdSizeMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, exploreData) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.yearArr = [];
    let numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
    for(let t = StartIndex; t <= EndIndex; t++){
        pt.yearArr.push(t);
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.RDec[1][col] = 1;
        col++;
    }

    pt = lockPackTable(pt, 1, exploreData, false);

    pt.tableData.value[1][0] = RS('GB_stAveHouseholdSize') + ' ' + RS('GB_stNumPersonsPerHH');

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let AvgHouseholdSize = getModvarByTag(CSModvars, CONSTANTS.CS_TG_AvgHouseholdSize_MV);
    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        if(AvgHouseholdSize["value"][t] >= 0) {
            pt.tableData.value[1][col] = AvgHouseholdSize["value"][t];
        }
        col++;
    }
    pt.Source[0] = AvgHouseholdSize["source"][0][0];
    return pt;
};

