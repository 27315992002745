import RS from "@common/strings/RS";
import { RESTService3, LoginService3, ModUtilService3, SpectrumService3 } from "./server_calls_single";
import { GenericService, ComboService } from "./server_calls_combo";

// export const APIFlags = {
//     CS_FLAG_FetchSession : 'fetchSession',
//     CS_FLAG_createProjectionFlag : 'createProjectionFlag',
//     CS_FLAG_createProjectionFromGuidedExplorationFlag : 'modifyProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'openProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'saveProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'closeProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'deleteProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'calculateProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'uploadProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'importProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'copyProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'renameProjectionFlag',
//     CS_FLAG_createProjectionFlag : 'getMOCountryDataFlag',
//     CS_FLAG_createProjectionFlag : 'getSubNatEditors',
//
//
//     CS_FLAG_createProjectionFlag : 'fetchSession',
//     CS_FLAG_createProjectionFlag : 'fetchSession',
//     CS_FLAG_createProjectionFlag : 'fetchSession',
//     CS_FLAG_createProjectionFlag : 'fetchSession',
//     CS_FLAG_createProjectionFlag : 'fetchSession',
//     CS_FLAG_createProjectionFlag : 'fetchSession',
//     CS_FLAG_createProjectionFlag : 'fetchSession',
// };

export const getAPI = (flag) => {

    switch (flag) {

        // Generic calls
        case 'fetchServerInfo'                           : return { msg: RS('GB_stRetrieveServerInfoFlag'), func : GenericService.onFetchServerInfo };
        case 'fetchUserInfo'                             : return { msg: RS('GB_stRetrieveUserInfoFlag'), func : GenericService.onFetchUserInfo };
        case 'fetchProjectionInfo'                       : return { msg: RS('GB_stRetrieveProjInfoFlag'), func : GenericService.onFetchProjectionInfo };
        case 'sendProjectionInfo'                        : return { msg: RS('GB_stSendingProjInfoFlag'), func : GenericService.onSendProjectionInfo };

        //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

        // Combo calls
        // case 'loginFlag'                                 : return { msg: 'Logging in', func : ComboService.onLogin }; 
        // case 'loginAsGuestFlag'                          : return { msg: 'Logging in as guest', func : ComboService.onLoginAsGuest }; 
        case 'fetchSession'                              : return { msg: RS("GB_stFetchSessionFlag"), func : ComboService.onInitSession }; 
        case "createProjectionFlag"                      : return { msg: RS("GB_stCreateProjectionFlag"), func : ComboService.onCreateProjectionFlag };
        case "modifyProjectionFlag"                      : return { msg: RS("GB_stApplyChangesFlag"), func : ComboService.onModifyProjectionFlag };
        case "openProjectionFlag"                        : return { msg: RS("GB_stOpenProjectionFlag"), func : ComboService.onOpenProjectionFlag };
        case "saveProjectionFlag"                        : return { msg: RS("GB_stSaveProjectionFlag"), func : ComboService.onSaveProjectionFlag };
        case "closeProjectionFlag"                       : return { msg: RS("GB_stCloseProjectionFlag"), func : ComboService.onCloseProjectionFlag };
        case "deleteProjectionFlag"                      : return { msg: RS("GB_stDeleteProjectionFlag"), func : ComboService.onDeleteProjectionFlag };
        case "calculateProjectionFlag"                   : return { msg: RS("GB_stCalculateProjectionFlag"), func : ComboService.onCalculateProjectionFlag };
        case "uploadProjectionFlag"                      : return { msg: RS("GB_stUploadProjectionFlag"), func : ComboService.onUploadProjectionFlag };
        case "copyProjectionFlag"                        : return { msg: RS("GB_stCopyProjectionFlag"), func : ComboService.onCopyProjectionFlag };
        case "renameProjectionFlag"                      : return { msg: RS("GB_stRenameProjectionFlag"), func : ComboService.onRenameProjectionFlag };
        case 'getMOCountryDataFlag'                      : return { msg: RS("GB_stGetCountryDataFlag"), func : ComboService.onGetMOCountryDataFlag };
        case "getSubNatModvars"                          : return { msg: RS("GB_stGetSubNatEdFlag"), func : ComboService.getSubNatModvars };

        case 'getExploreLiSTDataFlag'                    : return { msg: RS("GB_stGetCountryDataFlag"), func : SpectrumService3.getInputModVars };
        
        //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // Single messages
        // RESTService3
        case "ping"                                      : return { msg: '', func : RESTService3.ping };
        case "getCountries"                              : return { msg: '', func : RESTService3.getCountries };
        case "getVersion"                                : return { msg: '', func : RESTService3.getVersion };
        case "getFileShareStatus"                        : return { msg: '', func : RESTService3.getFileShareStatus };
        case "writeFileShareLog"                         : return { msg: '', func : RESTService3.writeFileShareLog };
        case "getIntervList"                             : return { msg: '', func : RESTService3.getIntervList };
        case "getIntervGroupList"                        : return { msg: '', func : RESTService3.getIntervGroupList };

        // LoginService3
        case "getStatus"                                 : return { msg: '', func : LoginService3.getStatus };
        case "signedIn"                                  : return { msg: '', func : LoginService3.signedIn };
        case "login"                                     : return { msg: '', func : LoginService3.login };
        case "loginAsGuest"                              : return { msg: '', func : LoginService3.loginAsGuest };
        case "logout"                                    : return { msg: '', func : LoginService3.logout };
        case "getAccountInfo"                            : return { msg: '', func : LoginService3.getAccountInfo };

        // ModUtilService3
        case "getMOCountryData"                          : return { msg: '', func : ModUtilService3.getMOCountryData };

        // SpectrumService3
        case "keepAlive"                                 : return { msg: '', func : SpectrumService3.keepAlive };
        case "getActiveProjectionList"                   : return { msg: '', func : SpectrumService3.getActiveProjectionList };
        case "getStoredProjectionList"                   : return { msg: '', func : SpectrumService3.getStoredProjectionList };

        // case "getGBData"                                 : return { msg: RS("GB_stGetGBDataFlag"), func : SpectrumService3.getGBData };
        case "getModvars"                                : return { msg: '', func : SpectrumService3.getModvars };
        case "setModvars"                                : return { msg: '', func : SpectrumService3.setModvars };
        case "getInputModVars"                           : return { msg: '', func : SpectrumService3.getInputModVars };

        case "createProjection"                          : return { msg: '', func : SpectrumService3.createProjection };
        case "modifyProjection"                          : return { msg: '', func : SpectrumService3.modifyProjection };
        case "openProjection"                            : return { msg: '', func : SpectrumService3.openProjection };
        case "saveProjection"                            : return { msg: '', func : SpectrumService3.saveProjection };
        case "closeProjection"                           : return { msg: '', func : SpectrumService3.closeProjection };
        case "downloadProjection"                        : return { msg: '', func : SpectrumService3.downloadProjection };
        case "deleteProjection"                          : return { msg: '', func : SpectrumService3.deleteProjection };
        case "calculateProjection"                       : return { msg: '', func : SpectrumService3.calculateProjection };
        case "copyProjection"                            : return { msg: '', func : SpectrumService3.copyProjection };
        case "renameProjection"                          : return { msg: '', func : SpectrumService3.renameProjection };

        case "getResultMenus"                            : return { msg: '', func : SpectrumService3.getResultMenus };

        // case "upload"
        case "setAccountInfoFlag"                        : return { msg: '', func : SpectrumService3.setAccountInfo };
        case "getTagList"                                : return { msg: '', func : SpectrumService3.getTagList };
        case "saveTopicFlag"                             : return { msg: '', func : SpectrumService3.SetProjectionTags };
        case "addSubnatData"                             : return { msg: '', func : SpectrumService3.addSubnatData };
        case "setUserOptions"                            : return { msg: '', func : SpectrumService3.setUserOptions};

        case "getDataPackResults"                        : return { msg: 'Getting result datapacks', func : SpectrumService3.getDataPackResults};
        case "getDataPackResultsLOCAL"                   : return { msg: 'Getting result datapacks', func : SpectrumService3.getDataPackResultsLOCAL};
        case "getDataPackExtracts"                       : return { msg: 'Getting extracts', func : SpectrumService3.getDataPackExtracts};
        case "getDataPackExtractsLOCAL"                  : return { msg: 'Getting extracts', func : SpectrumService3.getDataPackExtractsLOCAL};
        
        default                                          : console.error('Flag ' + flag + ' does not exist!'); return null;
    }
};