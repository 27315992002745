import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    CloneObj,
    getIdxFromYear,
    indentPackTable,
    lockPackTable,
    addRowToPackTable, generatePackChart, addMergedCellsToArray, add_NoHistoricalData_MsgToPackTable
} from "utilities/GB/GBUtil";

import {
    CSConvertZScoresToIndicator,
    CSGetStdDevBandName,
    getAgeCohortName,
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const WastingDistrEditor = {
    Create : (CSModvars, EditorStartYear) => {
        let editorName = RS('GB_stWastingDist');
        let packTable = Setup(CSModvars, EditorStartYear);
        packTable = WastingDistrEditor.SetSSData(packTable, CSModvars);
        packTable = WastingDistrEditor.RecalcGrid(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionalStatus;
        return ([packChart, packTable]);
    },

    onCheckBoxClick : (pt, RadioValue) => {
        for(let row = 3; row < pt.GBRowCount-1; row++) {
            pt = lockPackTable(pt, row, !RadioValue);
        }
        return (pt);
    },

    UpdateSingleWastingDistr : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);
        let SingleWastingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleWastingDistr_MV);
        let WastingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_WastingDistr_MV);
        SingleWastingDistr["value"] = CSConvertZScoresToIndicator(SingleWastingDistr["value"],
            WastingDistr["value"], pt.PseudoStartIndex, pt.StartIndex);
        return (CSModvarsClone);
    },

    RecalcGrid : (pt) => {
        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            let row = 1;
            let sum = 0;
            let calculatedStdDevRow;
            for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++) {
                row++;
                /* Add the value to the sum if it is not a calculated standard deviation row. */
                if (sd !== CONSTANTS.CS_GT1STD) {
                    sum += pt.tableData.value[row][col];
                }
                /* If we are on a calculated standard deviation row, save the row number. */
                else {
                    calculatedStdDevRow = row;
                }
            }
            /* Calculate and set value for calculated standard deviation row. */
            let value = Math.max(100 - sum, 0);
            pt.tableData.value[calculatedStdDevRow][col] = value;

            row++;
            /* Add value of calculated standard deviation row to total and set total value. */
            sum = sum + value;
            pt.tableData.value[row][col] = sum;
            col++;
        }

        return (pt);
    },

    SetSSData : (pt, CSModvars) => {
        let WastingDistr = getModvarByTag(CSModvars, CONSTANTS.CS_TG_WastingDistr_MV);
        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
            let row = 1;
            let sum = 0;
            for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++) {
                row++;
                pt.tableData.value[row][col] = WastingDistr["value"][sd][a][pt.StartIndex];
                sum += pt.tableData.value[row][col];
                if (pt.tableData.value[row][col] < 0){
                    pt.tableData.value[row][col] = '';
                }
            }
            row++;
            pt.tableData.value[row][col] = sum;
            col++;
        }

        pt.Source[0] = WastingDistr["source"][0][0];

        return pt;
    },

    GetSSData : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);

        // let SingleWastingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleWastingDistr_MV)["value"];
        let WastingDistrMV = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_WastingDistr_MV);
        let WastingDistrValue = WastingDistrMV["value"];

        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            let row = 1;
            for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++) {
                row++;
                WastingDistrValue[sd][a][pt.PseudoStartIndex] = WastingDistrValue[sd][a][pt.StartIndex];
                WastingDistrValue[sd][a][pt.StartIndex] = pt.tableData.value[row][col];
            }
            col++;
        }
        // SingleWastingDistr = CSConvertZScoresToIndicator(SingleWastingDistr, WastingDistrValue, pt.StartIndex, pt.EndIndex);

        WastingDistrMV["source"][0][0] = pt.Source[0];

        return (CSModvarsClone);
    },
};

const Setup = (CSModvars, EditorStartYear) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    pt.PseudoStartIndex = pt.StartIndex - 1;

    const numRows = 1;
    const numCols = 6;

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        pt.tableData.value[0][col] = getAgeCohortName(a, true);
        col++;
    }

    let row = 1;

    pt = addRowToPackTable(pt);
    pt.tableData.value[row][0] = RS('GB_stPercChildVariousWasteStat');
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols + 1);

    for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++){
        row++;
        pt = addRowToPackTable(pt);
        if (sd === CONSTANTS.CS_GT1STD){
            pt = lockPackTable(pt, row, true);
        }
        pt.tableData.value[row][0] = CSGetStdDevBandName(sd);
        pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
    }
    row++;
    pt = addRowToPackTable(pt);
    pt.tableData.value[row][0] = RS('DP_stTotal');
    pt = lockPackTable(pt, row, true);

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
            }
        }
    }

    return pt;
};

