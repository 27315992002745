import React from "react";
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import TButton from '@common/components/TButton';
import RS from "@common/strings/RS";
import CreateProjImage from '../../img/createProj.svg';

class CreateAProjectionPaper extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange         : PropTypes.func,
        onPageChange          : PropTypes.func,
        onCreateProjBtnClick  : PropTypes.func,
        isDeskTop             : PropTypes.bool,
        isGuest               : PropTypes.bool,
        projectCreated        : PropTypes.bool,
        projectionName        : PropTypes.string,
        fromGuided            : PropTypes.bool,
    };

    static defaultProps = {
        onStateChange         : (value) => console.log('onStateChange ' + JSON.stringify(value)),
        onPageChange          : () => console.log('onPageChange'),
        onCreateProjBtnClick  : () => console.log('onCreateProjBtnClick'),
        isDeskTop             : true,
        isGuest               : true,
        projectCreated        : false,
        projectionName        : '',
        fromGuided            : false,
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onCreateProjBtnClick = () => {
        if (this.props.fromGuided) {
            this.props.onStateChange({
                dialogProjInfoOpen : true
            })
        }
        else {
            this.props.onCreateProjBtnClick();
        }
    };

    onEditBtnClick = () => {
        this.props.onPageChange('CSProjManagerForm')
    };

    onLibraryBtnClick = () => {
        this.props.onPageChange("CSProjectionLibraryForm");
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderCreateProjImage = () => {

        let styles = {
            img: {
                height: '90px',
                position: 'absolute',
                top: '10px',
                left: '200px'
            }
        };

        return (
            <img style={styles.img} src={CreateProjImage} alt='' />
        );
    };

    renderCreateProjHeader = () => {

        let styles = {
            header: {
                position: 'absolute',
                marginTop: '105px',
                marginLeft: '10px',
                // marginRight: '60px',
                textAlign: 'left',
                color: '#0c3a79',
                userSelect: 'none'
                // width: '175px'
            }
        };

        return (
            <h2 style={styles.header} >
                {(this.props.fromGuided) ? RS('GB_stCreateAProjection') : RS('GB_stProjection')}
            </h2>
        );
    };

    renderCreateProjText = () => {

        let styles = {
            text: {
                position: 'absolute',
                marginTop: '150px',
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '16px',
                textAlign: 'left',
                lineHeight: 1.4,
                width: '250px',
                userSelect: 'none'
            },
            currentlyOpen :{
                marginTop: '10px',
                color: '#C03600',
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '1.2',
                wordWrap: 'break-word',
            },
        };

        return (
            <div style={styles.text}>
                {(this.props.projectCreated) ?
                    <div>
                        {(this.props.isGuest) ?
                            <div>
                                {RS('GB_stCreateNewOrEditProj')}
                            </div>
                            :
                            <div>
                                {RS('GB_stCreateNewOrEditProj')}
                            </div>
                        }
                        <div style={styles.currentlyOpen}>
                            {RS('GB_stCurrentlyOpen') + ' ' + this.props.projectionName.substring(0, 33) + (this.props.projectionName.length > 33 ? "..." : "")}
                        </div>
                    </div>
                    :
                    <div>
                        {(this.props.isGuest) ?
                            <div>
                                {RS('GB_stCreateANewProj')}
                            </div>
                            :
                            <div>
                                {RS('GB_stCreateANewProj')}
                            </div>
                        }
                    </div>
                }
            </div>
        );
    };

    renderCreateProjBtn = () => {

        let styles = {
            guestButton: {
                width: '125px',
                fontSize: '16px',
            },
            guestButtonDiv: {
                position: 'absolute',
                top: '300px',
                left: '10px'
            },
            editButton: {
                width: '125px',
                fontSize: '16px',
            },
            editButtonDiv: {
                position: 'absolute',
                top: '300px',
                left: '160px'
            }
        };

        return (
            <div>
                <TButton
                    caption={RS('GB_stCreate')}
                    captionStyle={{marginLeft: 0, marginRight: 0}}
                    color="primary"
                    style={styles.guestButton}
                    containerStyle={styles.guestButtonDiv}
                    onClick={this.onCreateProjBtnClick}
                />

                {
                    (this.props.projectCreated) ?
                        <TButton
                            caption={RS('GB_stEdit')}
                            captionStyle={{marginLeft: 0, marginRight: 0}}
                            color="secondary"
                            style={styles.editButton}
                            containerStyle={styles.editButtonDiv}
                            onClick={this.onEditBtnClick}
                        />
                        :
                        (!this.props.isGuest) ?
                            <TButton
                                caption={RS('GB_stLibrary')}
                                captionStyle={{marginLeft: 0, marginRight: 0}}
                                color="secondary"
                                style={styles.editButton}
                                containerStyle={styles.editButtonDiv}
                                onClick={this.onLibraryBtnClick}
                            />
                            :
                            null
                }

            </div>
        );
    };

    render() {

        const styles = {
            paperBoxHoriz: {
                position: 'relative',
                width: 300,
                height: 355,
                float: (this.props.fromGuided) ? 'left' : 'right',
                marginRight: (this.props.fromGuided) ? '25px' : '0px',
                background: 'white'
            },

            paperBoxVert: {
                position: 'relative',
                width: 300,
                height: 355,
                marginBottom: '20px',
                background: 'white'
            },

            infoBox: {
                width: 275,
                margin: '0 auto'
            }
        };

        return (
            <Paper style={(this.props.isDeskTop) ? styles.paperBoxHoriz : styles.paperBoxVert}>
                <div style={styles.infoBox}>
                    {this.renderCreateProjImage()}
                    {this.renderCreateProjHeader()}
                    {this.renderCreateProjText()}
                    {this.renderCreateProjBtn()}
                </div>
            </Paper>
        )
    }

}

export default CreateAProjectionPaper;