import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import Icon from '@material-ui/core/Icon';
import TCheckBox from "@common/components/TCheckBox";
import TComboBox from "@common/components/TComboBox";
import TDownloadBtn from "@common/components/TDownloadBtn";
import TSwitch from "@common/components/TSwitch";
import { clone } from "@common/util/GBUtil";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
 
class MOChartOptions extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange            : PropTypes.func,
        displayChart             : PropTypes.bool,
        sortByDeathsAverted      : PropTypes.bool,
        sortByInterventionCost   : PropTypes.bool,
        viewInterventionCost     : PropTypes.bool,
        displayMaternalCOD       : PropTypes.bool,
        displayStillbirthCOD     : PropTypes.bool,
        displayNeonatalCOD       : PropTypes.bool,
        displayChildCOD          : PropTypes.bool,
        //These are the booleans that control the intervention outcomes displayed in the intervention drawer
        maternalIntervSelected   : PropTypes.bool,
        stillbirthIntervSelected : PropTypes.bool,
        neonatalIntervSelected   : PropTypes.bool,
        childIntervSelected      : PropTypes.bool,
        MOResultToDisplay        : PropTypes.number,
        chart                    : PropTypes.any,
        tableRef                 : PropTypes.any
    };

    static defaultProps = {
        onStateChange            : () => console.log('onStateChange'),
        displayChart             : true,
        sortByDeathsAverted      : true,
        sortByInterventionCost   : false,
        viewInterventionCost     : false,
        displayMaternalCOD       : true,
        displayStillbirthCOD     : true,
        displayNeonatalCOD       : true,
        displayChildCOD          : true,
        maternalIntervSelected   : true,
        stillbirthIntervSelected : true,
        neonatalIntervSelected   : true,
        childIntervSelected      : true,
        MOResultToDisplay        : CONSTANTS.CS_PotentialDeathsAverted,
        chart                    : null,
        tableRef                 : null
    };

    state = {
        sortByDropdownIdx : (this.props.sortByDeathsAverted) ? 0 : ((this.props.sortByInterventionCost) ? 2 : 1),
    };

    //==================================================================================================================
    //
    //                                             Functions
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onHandleSortByDropdownChange = (idx) => {
        let deathsAverted = false;
        let interventionCost = false;
        let viewCost = clone(this.props.viewInterventionCost);
        switch (idx) {
            case CONSTANTS.CS_SortByDeathsAverted : {
                deathsAverted = true;
                break;
            }
            case CONSTANTS.CS_SortByInterventionCost : {
                interventionCost = true;
                viewCost = true;
                break;
            }
            default : break;
        }

        this.setState({
            sortByDropdownIdx : idx,
        })

        this.props.onStateChange({
            sortByDeathsAverted    : deathsAverted,
            sortByInterventionCost : interventionCost,
            viewInterventionCost   : viewCost
        });
    };

    onChangeDisplay = (checked, displayType) => {
        if (displayType === 'chart') {
            this.props.onStateChange({
                displayChart : checked,
            })
        }
        else {
            this.props.onStateChange({
                displayChart : !checked,
            })
        }
    };

    onViewCostToggle = (value) => {
        this.props.onStateChange({
            viewInterventionCost : value
        })
    }

    onDownloadChart = () => {
        if (this.props.displayChart) {
            this.props.chart.current.chart.exportChart({
                // type: "image/svg+xml",
                type: "image/png",
                // type: "application/pdf",
                filename: (this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted) ?
                    ((this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stPotentialDeathsAverted') : RS('GB_stPercentRedInMort'))
                    : RS('GB_stPotentialStuntingAverted')
            });
        } else {
            this.props.tableRef.current.exportToExcel(((this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted) ?
                ((this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stPotentialDeathsAverted') : RS('GB_stPercentRedInMort'))
                : RS('GB_stPotentialStuntingAverted')) + ".xlsx");
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderDisplayOptions = () => {
        const styles = {
            divStyle2 : {
                float: 'right',
                marginTop: '0px',
                marginRight: '128px',
            }
        };
        return (
            <div style={styles.divStyle2}>

                <TCheckBox
                    caption={''}
                    key={'chart'}
                    icon={<Icon style={{transform: 'rotate(90deg) scaleX(-1)'}} className='fa fa-bar-chart'/>}
                    checkedIcon={<Icon style={{transform: 'rotate(90deg) scaleX(-1)', color:'primary'}} className='fa fa-bar-chart'/>}
                    color={'primary'}
                    style={{display: 'inline', marginRight: '-5px', verticalAlign: "top"}}
                    customProp={'chart'}
                    value={this.props.displayChart}
                    onClick={(checked, event, name, caption, displayType) => this.onChangeDisplay(checked, displayType)}
                />

                <TCheckBox
                    caption={''}
                    key={'table'}
                    icon={<Icon className='fa fa-table'/>}
                    checkedIcon={<Icon className='fa fa-table'/>}
                    color={'primary'}
                    style={{display: 'inline', marginRight: '-5px', verticalAlign: "top"}}
                    customProp={'table'}
                    value={!this.props.displayChart}
                    onClick={(checked, event, name, caption, displayType) => this.onChangeDisplay(checked, displayType)}
                />
                {this.renderDownloadButton()}
            </div>
        )
    };

    renderDownloadButton = () => {
        return (

            <TDownloadBtn
                onClick={() => this.onDownloadChart()}
            />

        )
    };

    renderSortByDropdownHeader = () => {
        const styles = {
            headerStyle: {
                color: '#000000',
                fontSize: '14px',
                fontWeight: 'bold',
                marginTop: 4,
                marginBottom: '0',
                userSelect: 'none',
                display: 'inline',
                marginRight: '10px'
            },
        };

        return (
            <div style={{float: 'right', marginTop: 3}}>
                <h1 style={styles.headerStyle}>{RS('GB_stSortBy')}</h1>
            </div>
        )
    };

    renderSortByDropdownItems = () => {
        const styles = {
            comboBox: {
                width: 150,
                height: 30,
                marginRight: 0,
                marginTop: "-5px",
                paddingLeft: '5px',
                color: '#0c3a79',
                fontSize: '14px',
                borderRadius: 4,
                border: '1px solid lightgray',
                borderBlockEndColor: '#f78c30'
            },
            divStyle : {
                float: 'right',
                marginTop: 3
            }
        };

        return (
            <div style={styles.divStyle}>
                <TComboBox
                    caption      = ""
                    itemIndex    = {this.state.sortByDropdownIdx}
                    onChange     = {this.onHandleSortByDropdownChange}
                    style        = {styles.comboBox}
                    items        = {[
                        RS('GB_stDeathsAverted'),
                        RS('GB_stDeliveryPointsSmallP'),
                        RS('GB_stInterventionCost')
                    ]}
                >
                </TComboBox>
            </div>
        )
    };

    renderSortByDropdown = () => {
        return (
            <div>
                {this.renderSortByDropdownItems()}
                {this.renderSortByDropdownHeader()}
            </div>
        )
    };

    renderShowInterventionCost = () => {
        const styles = {
            switchLabelStyle : {
                fontSize   : '14px',
                color      : '#0c3a79',
            },
        };

        return (
            <div style={{float: 'right', marginRight: 0, marginTop: -22}}>
                <TSwitch
                    caption={RS("GB_stViewCost")}
                    value={this.props.viewInterventionCost} //{this.state.viewCostValues}
                    onClick={this.onViewCostToggle}
                    labelStyle = {styles.switchLabelStyle}
                />
            </div>
        )
    };

    render() {
        return (
            <div style={{height: '30px', paddingTop: '10px', marginBottom: '10px'}}>
                {(this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted) ? this.renderSortByDropdown() : null}
                {(this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted) ? this.renderShowInterventionCost() : null}
                {this.renderDisplayOptions()}
            </div>

        );
    }
}

export default MOChartOptions;