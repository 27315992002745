import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../../components/CS/CSEditors';

class CSNutritionInterventions extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        ImpactPromoAgeAppropriateBFEditorPackTable         : PropTypes.object,
        ImpactPromoEarlyInitBFEditorPackTable              : PropTypes.object,
        ImpactsOnMaternalAnemiaEditorPackTable             : PropTypes.object,
        ImpactsOnWastingEditorPackTable                    : PropTypes.object,
        ImpactsOnStuntingEditorPackTable                   : PropTypes.object,
        LowBirthWeightFactorsEditorPackTable               : PropTypes.object,
        ReducInStuntEditorPackTable                        : PropTypes.object,
        EducAttainmentEditorPackTable                      : PropTypes.object,
        onImpactPromoAgeAppropriateBFEditorPackTableChange : PropTypes.func,
        onImpactPromoEarlyInitBFEditorPackTableChange      : PropTypes.func,
        onImpactsOnMaternalAnemiaEditorPackTableChange     : PropTypes.func,
        onImpactsOnWastingEditorPackTableChange            : PropTypes.func,
        onImpactsOnStuntingEditorPackTableChange           : PropTypes.func,
        onLowBirthWeightFactorsEditorPackTableChange       : PropTypes.func,
        onReducInStuntEditorPackTableChange                : PropTypes.func,
        onEducAttainmentEditorPackTableChange              : PropTypes.func,
        nutritionComboboxIdx                               : PropTypes.number,
        onNutritionComboboxChange                          : PropTypes.func,
    };

    static defaultProps = {
        ImpactPromoAgeAppropriateBFEditorPackTable         : null,
        ImpactPromoEarlyInitBFEditorPackTable              : null,
        ImpactsOnMaternalAnemiaEditorPackTable             : null,
        ImpactsOnWastingEditorPackTable                    : null,
        ImpactsOnStuntingEditorPackTable                   : null,
        LowBirthWeightFactorsEditorPackTable               : null,
        ReducInStuntEditorPackTable                        : null,
        EducAttainmentEditorPackTable                      : null,
        onImpactPromoAgeAppropriateBFEditorPackTableChange : (packTable) => console.log('onImpactPromoAgeAppropriateBFEditorPackTableChange'),
        onImpactPromoEarlyInitBFEditorPackTableChange      : (packTable) => console.log('onImpactPromoEarlyInitBFEditorPackTableChange'),
        onImpactsOnMaternalAnemiaEditorPackTableChange     : (packTable) => console.log('onImpactsOnMaternalAnemiaEditorPackTableChange'),
        onImpactsOnWastingEditorPackTableChange            : (packTable) => console.log('onImpactsOnWastingEditorPackTableChange'),
        onImpactsOnStuntingEditorPackTableChange           : (packTable) => console.log('onImpactsOnStuntingEditorPackTableChange'),
        onLowBirthWeightFactorsEditorPackTableChange       : (packTable) => console.log('onLowBirthWeightFactorsEditorPackTableChange'),
        onReducInStuntEditorPackTableChange                : (packTable) => console.log('onReducInStuntEditorPackTableChange'),
        onEducAttainmentEditorPackTableChange              : (packTable) => console.log('onEducAttainmentEditorPackTableChange'),
        nutritionComboboxIdx                               : 0,
        onNutritionComboboxChange                          : () => console.log('onNutritionComboboxChange'),
    };

    state = {
        ImpactPromoAgeAppropriateBFEditorPackTable : JSON.parse(JSON.stringify(this.props.ImpactPromoAgeAppropriateBFEditorPackTable)),
        ImpactPromoEarlyInitBFEditorPackTable      : JSON.parse(JSON.stringify(this.props.ImpactPromoEarlyInitBFEditorPackTable)),
        ImpactsOnMaternalAnemiaEditorPackTable     : JSON.parse(JSON.stringify(this.props.ImpactsOnMaternalAnemiaEditorPackTable)),
        ImpactsOnWastingEditorPackTable            : JSON.parse(JSON.stringify(this.props.ImpactsOnWastingEditorPackTable)),
        ImpactsOnStuntingEditorPackTable           : JSON.parse(JSON.stringify(this.props.ImpactsOnStuntingEditorPackTable)),
        LowBirthWeightFactorsEditorPackTable       : JSON.parse(JSON.stringify(this.props.LowBirthWeightFactorsEditorPackTable)),
        ReducInStuntEditorPackTable                : JSON.parse(JSON.stringify(this.props.ReducInStuntEditorPackTable)),
        EducAttainmentEditorPackTable              : JSON.parse(JSON.stringify(this.props.EducAttainmentEditorPackTable)),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onComboboxChange = (value) => {
        this.props.onNutritionComboboxChange(value)
    };

    //==================================================================================================================
    //
    //                                                 functions
    //
    //==================================================================================================================

    getImpactsStuntingEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstImpactsOnStuntingSheetID}
                packTable={this.state.ImpactsOnStuntingEditorPackTable}
                showTitle={true}
                onChange={this.props.onImpactsOnStuntingEditorPackTableChange}
            />
        )
    };

    getImpactsWastingEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstImpactsOnModerateWastingSheetID}
                packTable={this.state.ImpactsOnWastingEditorPackTable}
                showTitle={true}
                onChange={this.props.onImpactsOnWastingEditorPackTableChange}
            />
        )
    };

    getImpactsMaternalAnemiaEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstImpactsOnMatAnemiaSheetID}
                packTable={this.state.ImpactsOnMaternalAnemiaEditorPackTable}
                showTitle={true}
                onChange={this.props.onImpactsOnMaternalAnemiaEditorPackTableChange}
            />
        )
    };

    getImpactsBFEditors = () => {
        return (
            <div>
                <CSEditor
                    mstID={CONSTANTS.CS_MstImpactBFImprovementSheetID}
                    packTable={this.state.ImpactPromoAgeAppropriateBFEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onImpactPromoAgeAppropriateBFEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstImpactEarlyInitBFSheetID}
                    packTable={this.state.ImpactPromoEarlyInitBFEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onImpactPromoEarlyInitBFEditorPackTableChange}
                />
            </div>
        )
    };

    getLowBirthWeightEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstLBWFSheetID}
                packTable={this.state.LowBirthWeightFactorsEditorPackTable}
                showTitle={true}
                onChange={this.props.onLowBirthWeightFactorsEditorPackTableChange}
            />
        )
    };

    getImpactsOnLifetimeEarningEditors = () => {
        return (
            <div>
                <CSEditor
                    mstID={CONSTANTS.CS_MstReducStuntSheetID}
                    packTable={this.state.ReducInStuntEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onReducInStuntEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstEducationAttainSheetID}
                    packTable={this.state.EducAttainmentEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onEducAttainmentEditorPackTableChange}
                />
            </div>
        )
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderDropdown = () => {
        const styles = {
            comboBox: {
                marginTop: 0,
                marginBottom: 20,
                marginRight: 25,
                paddingLeft: 0,
                width: 325,
                display: 'inline-block'
            },
            captionStyle: {
                marginTop: 0,
                color: '#f78c30'
            }
        };

        let items = [];
        items.push(RS('GB_stImpactsStunting'));
        items.push(RS('GB_stImpactsWasting'));
        items.push(RS('GB_stImpactsMaternalAnemia'));
        items.push(RS('GB_stImpactsBF'));
        items.push(RS('GB_stLowBirthWeight'));
        items.push(RS('GB_stImpactOnLifeTimeEarn'));

        return (
            <TComboBox
                caption      = ""
                itemIndex    = {this.props.nutritionComboboxIdx}
                style        = {styles.comboBox}
                onChange     = {this.onComboboxChange}
                captionStyle = {styles.captionStyle}
                items        = {items}
            >
            </TComboBox>
        )
    };

    renderEditors = () => {
        let ImpactsStunting = 0;
        let ImpactsWasting = 1;
        let ImpactsMaternalAnemia = 2;
        let ImpactsBF = 3;
        let LowBirthWeight = 4;
        let ImpactsLifetimeEarn = 5;

        let result = '';

        switch (this.props.nutritionComboboxIdx) {
            case ImpactsStunting       : {result = this.getImpactsStuntingEditors(); break;}
            case ImpactsWasting        : {result = this.getImpactsWastingEditors(); break;}
            case ImpactsMaternalAnemia : {result = this.getImpactsMaternalAnemiaEditors(); break;}
            case ImpactsBF             : {result = this.getImpactsBFEditors(); break;}
            case LowBirthWeight        : {result = this.getLowBirthWeightEditors(); break;}
            case ImpactsLifetimeEarn   : {result = this.getImpactsOnLifetimeEarningEditors(); break;}
            default : break;
        }

        return (result)
    };

    render() {
        return (
            <div>
                {this.renderDropdown()}
                {this.renderEditors()}
            </div>
        );
    }

}

export default CSNutritionInterventions;