import React from 'react';
//import PropTypes from "prop-types";

class CSAdolescentsDrawer extends React.Component {
    static propTypes = {
        //
    };

    static defaultProps = {
        //
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        return (
            <div></div>
        );
    }
}

export default CSAdolescentsDrawer;