import * as React from 'react';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import TTabs2 from "@common/components/TTabs2";
import TSwitch from "@common/components/TSwitch";
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {CSEditor} from "../../../../components/CS/CSEditors";

class SubNatWizardContent extends React.Component {

    static propTypes = {
        onSave             : PropTypes.func,
        onStateChange      : PropTypes.func,
        onStepChange       : PropTypes.func,
        onQualityChange    : PropTypes.func,
        packTableFYP       : PropTypes.any,
        packTableTFR       : PropTypes.any,
        packTableInc       : PropTypes.any,
        packTablePMTCT     : PropTypes.any,
        packTableCotrim    : PropTypes.any,
        packTableART       : PropTypes.any,
        packTableCPR       : PropTypes.any,
        packTableCov       : PropTypes.any,
        packTableEarlyInit : PropTypes.any,
        packTableBF        : PropTypes.any,
        packTableStunt     : PropTypes.any,
        packTableWast      : PropTypes.any,
        onEditorChange     : PropTypes.func,
        qualityOn          : PropTypes.bool,
    };

    static defaultProps = {
        onSave             : () => console.log("onSave"),
        onStateChange      : () => console.log("onStateChange"),
        onStepChange       : () => console.log("onStepChange"),
        onQualityChange    : () => console.log("onQualityChange"),
        packTableFYP       : {},
        packTableTFR       : {},
        packTableInc       : {},
        packTablePMTCT     : {},
        packTableCotrim    : {},
        packTableART       : {},
        packTableCPR       : {},
        packTableCov       : {},
        packTableEarlyInit : {},
        packTableBF        : {},
        packTableStunt     : {},
        packTableWast      : {},
        onEditorChange     : () => console.log("onEditorChange"),
        qualityOn          : false,
    };

    state = {
        step            : 1,
        completedSteps  : [],
        editorIdx       : 0,
        comboBoxIdx     : 0
    };

    //==================================================================================================================
    //
    //                                             Event Handlers
    //
    //==================================================================================================================

    onErrorDialogShow = (msg) => {
        this.props.onStateChange({
            dialogErrorOpen : true,
            errorMessage    : msg
        });
    };

    onBackBtnClick = () => {
        // let stages = ["DemProj", "AIM", "FamPlan"];

        this.setState({
            step: this.state.step - 1,
        }, () => {
            this.props.onStepChange(this.state.step)
        });
    };

    onNextBtnClick = () => {
        if (this.state.step === 4) {
            this.props.onSave();
        } 
        else {
            let steps = JSON.parse(JSON.stringify(this.state.completedSteps));
            steps.push(this.state.step);
            // let stages = ["DemProj", "AIM", "FamPlan"];

            this.setState({
                step: this.state.step + 1,
                completedSteps: steps
            }, () => {
                this.props.onStepChange(this.state.step)
            });
        }
    };

    onStep1BtnClick = () => {
        this.setState({
            step: 1
        }, () => {
            this.props.onStepChange(1)
        });
    };

    onStep2BtnClick = () => {
        let steps = JSON.parse(JSON.stringify(this.state.completedSteps));
        if (!steps.includes(1)) {
            steps.push(1);
        }
        this.setState({
            step: 2,
            completedSteps: steps
        }, () => {
            this.props.onStepChange(2)
        });
    };

    onStep3BtnClick = () => {
        let steps = JSON.parse(JSON.stringify(this.state.completedSteps));
        if (!steps.includes(2)) {
            steps.push(2);
        }
        this.setState({
            step: 3,
            completedSteps: steps
        }, () => {
            this.props.onStepChange(3)
        });
    };

    onStep4BtnClick = () => {
        let steps = JSON.parse(JSON.stringify(this.state.completedSteps));
        if (!steps.includes(3)) {
            steps.push(3);
        }
        this.setState({
            step: 4,
            completedSteps: steps
        }, () => {
            this.props.onStepChange(4)
        });
    };

    onComboboxChange = (value) => {
        this.setState({
            editorIdx : value
        });
    };

    onCoverageComboboxChange = (value) => {
        this.setState({
            comboBoxIdx : value
        });
    };

    onLiSTTabChange = (val) => {
        this.setState({editorIdx: val});
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderStepsMenu = () => {

        let { completedSteps, step } = this.state;

        const styles= {
            activeStep: {
                fontWeight: 'bold',
                fontSize: '14px',
                textDecoration: "underline"
            },
            nonActiveStep : {
                fontWeight: 'normal',
                fontSize: '14px'
            },
            nextStep : {
                fontWeight: 'normal',
                fontSize: '14px',
                //fontStyle: "italic"
            },
            stepper : {
                marginTop: '15px',
                paddingBottom: '7px',
                paddingTop: '7px',
                minWidth: '300px',
                background: '#E6F3FF',
                boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
                borderRadius: '5px'
            }
        };

        return (
            <Stepper
                activeStep={/*step - 1*/completedSteps.length > 0 ? Math.max(...completedSteps) : 0}
                style={styles.stepper}
            >
                <Step
                    key={1}
                    completed={completedSteps.includes(1)}
                >
                    <StepButton
                        key={1}
                        onClick={this.onStep1BtnClick}
                    >
                        <StepLabel>
                            <Typography
                                style={step === 1 ? styles.activeStep : styles.nonActiveStep}
                            >
                                DemProj
                            </Typography>
                        </StepLabel>
                    </StepButton>
                </Step>
                <Step
                    key={2}
                    completed={completedSteps.includes(2)}
                    disabled={false}
                    active={true}
                >
                    <StepButton
                        key={2}
                        onClick={this.onStep2BtnClick}
                    >
                        <StepLabel>
                            <Typography
                                style={step === 2 ? styles.activeStep : step === 1 ? styles.nextStep : styles.nonActiveStep}
                            >
                                AIM
                            </Typography>
                        </StepLabel>
                    </StepButton>
                </Step>
                <Step
                    key={3}
                    completed={completedSteps.includes(3)}
                    disabled={step < 2}
                    active={completedSteps.includes(1)}
                >
                    <StepButton
                        key={3}
                        onClick={this.onStep3BtnClick}
                    >
                        <StepLabel>
                            <Typography
                                style={step === 3 ? styles.activeStep : step === 2 ? styles.nextStep : styles.nonActiveStep}
                            >
                                LiST
                            </Typography>
                        </StepLabel>
                    </StepButton>
                </Step>
                <Step
                    key={4}
                    completed={completedSteps.includes(4)}
                    disabled={step < 3}
                    active={completedSteps.includes(2)}
                >
                    <StepButton
                        key={4}
                        onClick={this.onStep4BtnClick}
                    >
                        <StepLabel>
                            <Typography
                                style={step === 4 ? styles.activeStep : step === 3 ? styles.nextStep : styles.nonActiveStep}
                            >
                                FamPlan
                            </Typography>
                        </StepLabel>
                    </StepButton>
                </Step>
            </Stepper>
        )
    };

    renderEditor = (packTable, id) => {

        let { onEditorChange } = this.props;

        let styles = {
            table: {
                margin: "auto",
                color: "#0c3a79"
            }
        };

        return (
            Object.keys(packTable).length > 1 ?
                <div style={styles.table}>
                    <CSEditor
                        mstID={id}
                        packTable={packTable}
                        showTitle={true}
                        onChange={onEditorChange}
                    />
                </div> : null
        )
    };

    renderDemProjStep = () => {

        let { packTableFYP/*, packTableTFR*/ } = this.props;

        let styles = {
            title: {
                color: '#0c3a79',
                fontSize: "20px",
                marginTop: "0"
            },
            para: {
                fontSize: "12px",
                lineHeight: 1.3
            },
            line: {
                marginBottom: "15px"
            },
            ed: {
                display: "inline-block",
                width: "390px",
                height: "300px",
                verticalAlign: "top",
                padding:"20px",
                paddingTop:"10px",
                margin:"25px",
                marginBottom: "10px"
            }
        };

        return (
            <div>
                <Paper style={styles.ed}>
                    <h2 style={styles.title}>{RS("GB_stFYP")}</h2>
                    <hr style={styles.line} />
                    {this.renderEditor(packTableFYP, CONSTANTS.CS_MstSubNatFYPSheetID)}
                    <p style={styles.para}>{RS("GB_stFirstYearPopVal")}</p>
                </Paper>
                {/*<Paper style={styles.ed}>*/}
                {/*    <h2 style={styles.title}>{RS("GB_stTFR")}</h2>*/}
                {/*    <hr style={styles.line} />*/}
                {/*    {this.renderEditor(packTableTFR, CONSTANTS.CS_MstSubNatTFRSheetID)}*/}
                {/*    <p style={styles.para}>{RS("GB_stFutureTrendTFR")}</p>*/}
                {/*</Paper>*/}
            </div>
        );
    };

    renderAIMStep = () => {

        let { packTableInc, packTablePMTCT, packTableCotrim, packTableART } = this.props;

        let styles = {
            title: {
                color: '#0c3a79',
                fontSize: "20px",
                marginTop: "0"
            },
            para: {
                fontSize: "12px",
                lineHeight: 1.3
            },
            line: {
                marginBottom: "15px"
            },
            ed: {
                display: "inline-block",
                width: "530px",
                height: "275px",
                verticalAlign: "top",
                padding:"20px",
                paddingTop:"10px",
                margin:"25px",
                marginBottom: "10px"
            }
        };

        return (
            <div>
                <Paper style={styles.ed}>
                    <h2 style={styles.title}>{RS("GB_stHIVIncidence")}</h2>
                    <hr style={styles.line} />
                    {this.renderEditor(packTableInc, CONSTANTS.CS_MstSubNatIncSheetID)}
                    <p style={styles.para}>{RS("GB_stHIVIncVal")}</p>
                </Paper>
                <Paper style={styles.ed}>
                    <h2 style={styles.title}>{RS("GB_stPMTCT")}</h2>
                    <hr style={styles.line} />
                    {this.renderEditor(packTablePMTCT, CONSTANTS.CS_MstSubNatPMTCTSheetID)}
                    <p style={styles.para}>{RS("GB_stPercPMTCT")}</p>
                </Paper>
                <Paper style={styles.ed}>
                    <h2 style={styles.title}>{RS("GB_stCotrimoxazole")}</h2>
                    <hr style={styles.line} />
                    {this.renderEditor(packTableCotrim, CONSTANTS.CS_MstSubNatCotrimSheetID)}
                    <p style={styles.para}>{RS("GB_stPercCotrimoxazole")}</p>
                </Paper>
                <Paper style={styles.ed}>
                    <h2 style={styles.title}>{RS("GB_stAdultART")}</h2>
                    <hr style={styles.line} />
                    {this.renderEditor(packTableART, CONSTANTS.CS_MstSubNatARTSheetID)}
                    <p style={styles.para}>{RS("GB_stPercAdultART")}</p>
                </Paper>
            </div>
        );
    };

    renderLiSTStep = () => {
        let { packTableCov, packTableEarlyInit, packTableBF, packTableStunt, packTableWast } = this.props;
        packTableStunt.Title = "";
        packTableWast.Title = "";
        const styles = {
            comboBox: {
                marginBottom: 20,
                marginRight: 25,
                marginLeft:25,
                paddingLeft: 0,
                width: 435,
                display: 'inline-block'
            },
            title: {
                color: '#0c3a79',
                fontSize: "20px",
                marginTop: "0"
            },
            para: {
                fontSize: "12px",
                lineHeight: 1.3
            },
            line: {
                marginBottom: "15px"
            },
            ed: {
                display: "inline-block",
                width: 'calc(100% - 50px)',
                height: "400px",
                verticalAlign: "top",
                padding:"20px",
                paddingTop:"10px",
                marginLeft: '25px',
                marginRight: '25px',
                marginTop: 0,
                marginBottom: "10px"
            },
            tabSelectedStyle : {
                color: '#65A7EB',
                fontWeight: 'bold'
            },
            tabRootStyle : {
                fontSize: '14px',
            },
            tabContentStyle : {
                // marginTop: '185px'
            },
            tabAppBarStyle : {
                position: "static",
                marginTop:10,
                marginLeft: 25,
                width: "calc(100% - 50px)",
            },
            div : {
                marginLeft: '25px',
                marginRight: '25px',
                marginTop: '-5px',
                marginBottom: '-20px'
            },
            switch : {
                height : 'auto',
                marginTop: 0
            }
        };

        return (
            <div>
                <TTabs2
                    tabAppBarStyle={styles.tabAppBarStyle}
                    tabBarOutline={"none"}
                    tabSelectedStyle={styles.tabSelectedStyle}
                    tabRootStyle={styles.tabRootStyle}
                    tabContentStyle={styles.tabContentStyle}
                    tabNames = {[
                        RS("GB_stStunting"),
                        RS("GB_stWasting"),
                        RS("GB_stBFprevalence"),
                        RS("GB_stInterventionCov")]}
                    tabUnderlineColor={'secondary'}
                    tabContents={[
                        <div style={styles.div}>
                           {this.renderEditor(packTableStunt, CONSTANTS.CS_MstLiSTOnlineStuntingSheetID)}
                        </div>,
                        <div style={styles.div}>
                           {this.renderEditor(packTableWast, CONSTANTS.CS_MstLiSTOnlineWastingSheetID)}
                        </div>,
                        <div style={styles.div}>
                            {this.renderEditor(packTableEarlyInit, CONSTANTS.CS_MstEarlyInitBFSheetID)}
                            {this.renderEditor(packTableBF, CONSTANTS.CS_MstLiSTOnlineBFPromoSheetID)}
                        </div>,
                        <div style={styles.div}>
                            <TSwitch
                                caption={RS("GB_stQuality")}
                                value={this.props.qualityOn}
                                onClick={this.props.onQualityChange}
                                style={styles.switch}
                            />
                            {this.renderEditor(packTableCov, CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID)}
                        </div>,
                    ]}
                    onTabChange = {this.onLiSTTabChange}
                    selectedTab = {this.state.editorIdx}
                />
            </div>
        );
    };

    renderFamPlanStep = () => {

        let { packTableCPR, packTableTFR } = this.props;

        let styles = {
            title: {
                color: '#0c3a79',
                fontSize: "20px",
                marginTop: "0"
            },
            para: {
                fontSize: "12px",
                lineHeight: 1.3
            },
            line: {
                marginBottom: "15px"
            },
            ed: {
                display: "inline-block",
                width: "338px",
                height: "250px",
                verticalAlign: "top",
                padding:"20px",
                paddingTop:"10px",
                margin:"25px",
                marginBottom: "10px"
            }
        };

        return (
            <div>
                <Paper style={styles.ed}>
                    <h2 style={styles.title}>{RS("GB_stContraceptivePrevalenceUse")}</h2>
                    <hr style={styles.line} />
                    {this.renderEditor(packTableCPR, CONSTANTS.CS_MstSubNatCPRSheetID)}
                    <p style={styles.para}>{RS("GB_stFutureTrendCPR")}</p>
                </Paper>
                <Paper style={styles.ed}>
                    <h2 style={styles.title}>{RS("GB_stTFR")}</h2>
                    <hr style={styles.line} />
                    {this.renderEditor(packTableTFR, CONSTANTS.CS_MstSubNatTFRSheetID)}
                    {/*<p style={styles.para}>{RS("GB_stFutureTrendTFR")}</p>*/}
                </Paper>
            </div>
        );
    };

    renderBackButton = () => {

        let { step } = this.state;

        const styles = {
            backButton: {
                fontSize: '16px',
                marginLeft: '20px',
                marginTop: '10px',
                marginBottom: '75px',
                marginRight: "25px",
                display: step === 1 ? "none" : "inline-block"
            }
        };

        return (
            <TButton
                caption={RS("GB_stBack")}
                variant={"contained"}
                primary={true}
                color={"secondary"}
                style={styles.backButton}
                onClick={this.onBackBtnClick}
                disabled={step === 0}
            />
        );
    };

    renderNextButton = () => {

        let { step } = this.state;

        const styles = {
            nextButton: {
                fontSize: '16px',
                marginRight: '20px',
                marginTop: '10px',
                marginBottom: '75px',
                marginLeft: "3px"
            }
        };

        return (
            <TButton
                caption={step < 4 ? RS("GB_stNext") : RS("GB_stApply")}
                variant={"contained"}
                primary={true}
                style={styles.nextButton}
                onClick={this.onNextBtnClick}
                disabled={false}
            />
        );
    };

    render() {

        let { step } = this.state;

        let styles = {
            btnContainer: {
                bottom: "5px"
            }
        };

        return (
            <div className="innerPage">
                {this.renderStepsMenu()}
                <div style={{display: step === 1 ? "block" : "none"}}>
                    {this.renderDemProjStep()}
                </div>
                <div style={{display: step === 2 ? "block" : "none"}}>
                    {this.renderAIMStep()}
                </div>
                <div style={{display: step === 3 ? "block" : "none"}}>
                    {this.renderLiSTStep()}
                </div>
                <div style={{display: step === 4 ? "block" : "none"}}>
                    {this.renderFamPlanStep()}
                </div>
                <div style={styles.btnContainer}>
                    {this.renderBackButton()}
                    {this.renderNextButton()}
                </div>
            </div>
        )
    }
}

export default SubNatWizardContent