import React from 'react';
import PropTypes from "prop-types";
import './App.css';
import CreateAPITask from "@common/api/createAPITask";
import TDisconnected from "@common/components/TDisconnected";
import { clone, MergeArraysNoDuplicates } from "@common/util/GBUtil";
import * as CT from '@common/api/container_calls';
import TAppSplash from "@common/components/TAppSplash";
import Theme from "@common/theme/CSTheme";
import RS from "@common/strings/RS";
import TCSUserLoggedOff from "../components/TCSUserLoggedOff";
import TCSStatusTimer from '../components/TCSStatusTimer';
import APIService from '../api/APIService';
import Pages from "../pages/Pages";
import AppBar from "./AppBar";

class App extends React.Component {

    static propTypes = {
        isStandAlone      : PropTypes.bool,
    };

    static defaultProps = {
        isStandAlone      : true,
    };

    state = {
        loading           : true,
        connected         : false,
        pageID            : "NotSignedInPage",
        backPageID        : "NotSignedInPage",

        /* Error */
        errorMessage      : "",
        dialogErrorOpen   : false,

        /* Session timer status */
        disconnectWarning : false,
        TimeToLive        : "0:60:0",
        TimeIdle          : "0:0:0",
        TimeExpire        : "1:0:0",
        TimeTotal         : "0:0:0",
        disconnecting     : false,
        status            : {},
        serverAlive       : true,
        
        /* User */
        userLoggedOff     : false,
        isGuest           : true,
        sessionExists     : false,
        signedIn          : false,
        userName          : "",
        humanName         : "",
        storedProjections : [],

        // storedProjections : [{ name : "TestProjName", country : "TestCountry", lastSaved : "TestLastSaved", years : "TestYears" }],

        /* API */ 
        useContainer      : window.App.useContainers,
        apiTasks          : [],
        apiMainMsg        : "",
        apiSubMsg         : "",

        /* Steps */
        stepsCompleted    : [],

        /* Options */
        projectionsSelected : [],
        indicatorsSelected  : [],
        // projectionsSelected : ["CS Projection 2020-2030"],
        // indicatorsSelected  : [[1,15,"Neonatal deaths"],[3,15,"Neonatal deaths by cause"],[2,15,"Additional neonatal lives saved"]],
        fileFormat          : 1,
        MinProjFirstYr      : 0,
        MaxProjFinalYr      : 0,
        years               : {min : 0, max: 0},
        transposeYears      : false,
        recalculateProjs    : false,
        localCurrency       : false,
        fullPrecision       : false,
        exportFileName      : "",
        topicFilter         : []

        // closeProjReason     : "",
        // activeProjections   : [],
        // projectCreated      : false,
        // projectionName      : "",
        // subnatRegion        : "",
        // subnatSurvey        : "",
        // projectionNotes     : "",
        // CSModvars           : [],
        // subnatProjection    : false,
        // unchangedProj       : false,
        // showMOIntervSection : false,
        // selectedProjSideBarIdx: 0,
        // yearRange           : {min: 1970, max: 2050},
        // authorName          : "",
        // orgName             : "",
        // firstYear           : 1970,
        // finalYear           : 2050,
        // selectedCountry     : "Afghanistan"
    };

    MANAGE_ACCOUNT_LINK = "https://authuser.avenirhealth.org/?sv=LOL&md=mg";
    
    componentDidMount () {
        /* DO NOT USE THIS!!!  THIS WAS A SPECIAL CASE WITH PERMISSION FROM BOB!!! */
        window.appState = this.state;
        
        // window.App.languageIdx = this.state.language - 1;

        this.onInitSession();
    }

    onPageChange = (id) => {
        if ( (typeof id === 'undefined') || (id === '') ) {
            this.setState({
                pageID : this.state.pageID
            });
            return;
        }

        if (id === 'back') {
            id = this.state.backPageID;
        }

        if (id === "reload") {
            id = this.state.pageID;

            this.setState({
                pageID: "NotAPage"
            }, () => {
                this.setState({
                    pageID: id
                });
            });
        } 
        else {
            this.setState({
                backPageID: this.state.pageID,
                pageID: id
            });
        }
    };

    onStateChange = (newState, successFn) => {
        let that = this;

        Object.keys(newState).forEach(key => {
            if (!that.state.hasOwnProperty(key)) {
                console.error('You have added in new state to <App /> that did not exist! Field is : ' + key);
            }
        });

        if (newState.APIFlag !== "closeProjection") {
            Object.keys(newState).forEach(key => {
                if (that.state.hasOwnProperty(key)) {
                    /* If DPModvars is changing, we assume its all the modvars from specific projections.
                    Change the newState coming in to include the modvars passed in as well as 
                    the rest of the modvars from App. */
                    if (key === "DPModvars") {
                        let modvars = clone(this.state.DPModvars);
                        let incomingModvars = newState.DPModvars;

                        /* Which projs are we changing? */
                        let projNums = [...new Set(newState.DPModvars.map(x => x.proj))];

                        /* Get all modvars not in the projections we are changing */
                        let otherModvars = modvars.filter(x => !projNums.includes(x.proj));

                        /* If the projection is in read only mode, remove those projection's modvars.
                          The only exception is if the modvars never existed before and we are setting
                          them for the first time. */
                        for (let i = 0; i < this.state.activeProjections.length; i++) {
                            let p = i + 1;
                            if (projNums.includes(p)) {
                                if (this.state.activeProjections[i].readOnly) {
                                    if (modvars.filter(x => x.proj === p).length > 0) {                      // Have the projection's modvars existed before
                                        let originalProjModvars = modvars.filter(x => x.proj === p);         // What modvars have existed before for this projection?
                                        incomingModvars = incomingModvars.filter(x => x.proj !== p);         // Remove the new modvars for this projection
                                        incomingModvars = [...incomingModvars, ...originalProjModvars];      // Merge the new modvars and the old modvars
                                    }
                                }
                            }
                        }

                        let newModvars = [
                            ...otherModvars,
                            ...incomingModvars
                        ];

                        newState.DPModvars = newModvars;

                        /* Merge the dataChanges */
                        newState.dataChanged = MergeArraysNoDuplicates(this.state.dataChanged, projNums);
                    }
                }
            });
        }

        this.setState(prevState => {
            let mergedState = {
                ...prevState,
                ...newState
            };

            return mergedState;
        }, () => {
            if (typeof successFn !== "undefined") {
                successFn();
            }
        });
    };

    onAddTasks = (arr) => {
        this.setState((prevState, props) => ({
            apiTasks : [...prevState.apiTasks, ...arr]
        }));
    };

    onAPIMainMsgChange = (s) => {
        this.setState({
            apiMainMsg : s
        });
    };

    onAPISubMsgChange = (s) => {
        this.setState({
            apiSubMsg : s
        });
    };

    callAPI = (name, param, successFn, errorFn) => {
        this.onAddTasks([
            CreateAPITask(name, param, successFn, errorFn)
        ]);
    };

    onInitSession = () => {
        const successFn = () => {
            this.callAPI('getAccountInfo', {}, () => {
                if (this.state.signedIn) {
                    this.callAPI('getStoredProjectionList', {}, () => {
                        console.log('Initialized');
                        this.setState({
                            connected : true,
                            loading : false,
                            pageID : "ProjectionsPage",
                        });
                    }, errorFn);
                }
                else {
                    console.log('Initialized');
                    this.setState({
                        connected : true,
                        loading : false,
                        pageID : "NotSignedInPage",
                    });
                }
            }, errorFn);
        };

        const errorFn = (msg) => {
            alert(RS('GB_stErrorInitializing') + ' ' + msg);
            this.setState({
                connected : false,
                loading : false
            });  
        };

        if (this.state.useContainer) {
            CT.ContainerLogic(successFn, errorFn); 
        }
        else {
            successFn();
        }
    };

    onLogin = (username, password, rememberMe, successFn, errorFn) => {
        const _errorFn = () => {
            errorFn(RS('ErrUserFetch'));
        };

        this.onAddTasks([
            CreateAPITask('login', { username: username, password: password },
                (response) => {
                    if (response === true) {
                        localStorage.setItem("TOOLS_REMEMBER_ME", rememberMe);

                        (rememberMe) ?
                            localStorage.setItem("TOOLS_USER", username) :
                            localStorage.setItem("TOOLS_USER", "");

                        this.setState({
                            isGuest : false
                        });

                        this.callAPI('getAccountInfo', {}, (r1) => {
                            this.callAPI('getStoredProjectionList', {}, (r2) => {
                                successFn();
                            }, _errorFn);
                        }, _errorFn);
                    }
                },
                (msg) => {
                    errorFn(RS('GB_stWrongEmailPassword'));
                }
            )
        ]);
    };

    onLogout = () => {
        this.callAPI('logout', {}, () => {}, () => {
            alert("Error logging off");
        });
    };

    onUploadProjection = (f) => {
        const { storedProjections } = this.state;

        const fileName = f.name;
        const fileNameLower = fileName.toLowerCase();
        const fileTitle = fileName.replace(".pjnz", "");

        if (!fileNameLower.includes(".pjnz")) {
            alert("[" + RS("GB_stError").toUpperCase() + "] " + RS("GB_stUploadWrongFormat"));
            return;
        }

        let alreadyTaken = false;
        storedProjections.forEach(x => {
            if (x.name === fileTitle) {
                alreadyTaken = true;
            }
        });

        if (alreadyTaken) {
            alert(RS("GB_stAlreadyExists"));
            return;
        } 


        this.callAPI('uploadProjectionFlag', {file: f},
            (r) => {
                
            },
            (msg) => {
                alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
            }
        );

        //clear the file input to make sure any choice triggers the onChange, even if it's the same, such as a retry after an error
        document.querySelector("#hidden_uploader").value = "";
    };

    onManageAccount = () => {
        window.open(this.MANAGE_ACCOUNT_LINK, "_blank");
    };

    onStepChange = (step) => {
        switch (step) {
            case 0 : this.onPageChange("ProjectionsPage"); break;
            case 1 : this.onPageChange("IndicatorsPage"); break;
            case 2 : this.onPageChange("ConfigurationPage"); break;
            case 3 : this.onPageChange("ProcessPage"); break;
            default : break;
        }
    };

    render() {
        const { isStandAlone } = this.props;

        if (this.state.loading) {
            return (
                <React.Fragment>
                    <TAppSplash />

                    <APIService
                        apiTasks={this.state.apiTasks}  // apiTasks contains functions, can't clone
                        appState={this.state}
                        onStateChange={this.onStateChange}
                        onAPIMainMsgChange={this.onAPIMainMsgChange}
                        onAPISubMsgChange={this.onAPISubMsgChange}
                    />
                </React.Fragment>
            );
        }
        else if (this.state.userLoggedOff) {
            return (
                <TCSUserLoggedOff
                    onConnect = {() => { window.location.reload(); }}
                />
            );
        }
        else if (!this.state.serverAlive) {
            console.log("serverAlive = false");
            return (
                <TDisconnected 
                    onConnect                       = {() => { window.location.reload(); }}
                />
            );
        }
        return (
            <React.Fragment>
                {
                    isStandAlone ?
                        <AppBar 
                            isGuest={this.state.isGuest}
                            humanName={this.state.humanName}
                            onTitleClick={() => this.onPageChange("HomePage")}
                            style={Theme.appBar}
                            onLogout={this.onLogout}
                            onManageAccount={this.onManageAccount}
                            onPageChange={this.onPageChange}
                            Theme={Theme}
                        />
                        :
                        false
                }

                <Pages 
                    appBarHeight={isStandAlone ? 64 : 0}
                    appState={this.state} 
                    onLogin={this.onLogin}
                    onPageChange={this.onPageChange}
                    onStateChange={this.onStateChange}
                    onStepChange={this.onStepChange}
                    onProcessProjections={this.onProcessProjections}
                    callAPI={this.callAPI}
                    onUploadProjection={this.onUploadProjection}
                    Theme={Theme}
                />

                <APIService
                    apiTasks={this.state.apiTasks}  // apiTasks contains functions, can't clone
                    appState={this.state}
                    onStateChange={this.onStateChange}
                    onAPIMainMsgChange={this.onAPIMainMsgChange}
                    onAPISubMsgChange={this.onAPISubMsgChange}
                />

                <TCSStatusTimer 
                    onAddTasks={this.onAddTasks}
                    onStateChange={this.onStateChange} 
                />
            </React.Fragment>
        );
    }
};

export default App;