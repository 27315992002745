import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {getModvarByTag, getIdxFromYear, getCalcYearIdx} from "utilities/GB/GBUtil";
import {CSConvertIndicatorToZScores} from "utilities/CS/CSDataUtil";
import { CSEditor } from "../../../../components/CS/CSEditors";
import EditorNote from "../../../../components/EditorNote";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import {StuntWastEditor} from "../../data/Coverage/CS/StuntWastEditor";
import CSSingleStuntingDrawer from "./CSSingleStuntingDrawer";

class CSStuntingDrawer extends React.Component {

    static propTypes = {
        CSModvars        : PropTypes.arrayOf(PropTypes.object).isRequired,
        firstYear        : PropTypes.number.isRequired,
        finalYear        : PropTypes.number.isRequired,
        onClose          : PropTypes.func.isRequired,
        onOpenHelpDialog : PropTypes.func.isRequired,
        onStateChange    : PropTypes.func.isRequired,
    };

    state = {
        CSModvars       : [],
        packTable       : null,
        saveBtnDisabled : true,
        openDrawer      : false,
    };

    single = false;
    subNatMode = false;

    componentDidMount () {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = this.props.finalYear;

        this.setState({
            CSModvars : CSModvars,
            packTable : StuntWastEditor.Create(CSModvars, firstYear, finalYear, CONSTANTS.CS_GroupMstID_Stunting, this.subnatMode)[CONSTANTS.CS_PackTable],
        });
    }

    _onClose = () => {
        this.props.onClose();
    };

    _onApply = () => {

        let packTable = this.state.packTable;

        let goAhead = true;

        packTable.tableData.value.forEach(function(arr) {
            arr.forEach(function(val) {
                if (typeof val === "number" && val > 100) {
                    goAhead = false;
                }
            });
        });

        if (goAhead) {
            let CSModvarsClone = StuntWastEditor.GetSSData(packTable, this.state.CSModvars, CONSTANTS.CS_GroupMstID_Stunting, this.subNatMode);

            this.props.onStateChange({
                CSModvars : CSModvarsClone,
                unchangedProj : false
            }, () => {
                this.props.onClose();   
            });
        }
        else {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : RS("GB_stValOver100")
            });
        }
    };

    _onPackTableChange = packTable => {
        packTable = StuntWastEditor.RecalcGrid(packTable);

        this.setState({
            saveBtnDisabled: false,
            packTable: packTable
        });
    };

    _onOpenInnerDrawer = () => {
        this.setState({
            openDrawer: true
        });
    };

    _onCloseInnerDrawer = () => {
        this.setState({
            openDrawer: false
        });
    };

    _onApplyInnerDrawer = (CSModvarsClone) => {
        const { packTable } = this.state;

        let FirstYear = this.props.firstYear;
        let CurrentYear = new Date().getFullYear();

        let FirstYrIxForCalc;
        /* As long as the projection's first year is earlier than the current
           year, the "First year" used for the conversion algorithm will be
           the year prior to the current year. */
        if (FirstYear < CurrentYear){
            FirstYrIxForCalc = getIdxFromYear(FirstYear, CurrentYear - 1);
        }

        /* Otherwise, we should treat the projection's actual first year as the
           first year in the conversion algorithm, as opposed to the current year. */
        else{
            FirstYrIxForCalc = getCalcYearIdx(FirstYear);
        }

        let FinalYearIdx = getIdxFromYear(FirstYear, this.props.finalYear);

        let CSModvarsClone2 = StuntWastEditor.GetSSData(packTable, CSModvarsClone, CONSTANTS.CS_GroupMstID_Stunting, this.subNatMode);

        let SingleStuntingDistr = getModvarByTag(CSModvarsClone2, CONSTANTS.CS_TG_SingleStuntingDistr_MV);
        let StuntingDistr = getModvarByTag(CSModvarsClone2, CONSTANTS.CS_TG_StuntingDistr_MV);
        StuntingDistr["value"] = CSConvertIndicatorToZScores(SingleStuntingDistr["value"],
            StuntingDistr["value"], FirstYrIxForCalc, FinalYearIdx);

        let changedCSModvars = CSModvarsClone.map(x => {
            let a = [StuntingDistr].find(y => y.tag === x.tag);
            return a || x;
        });

        this.setState({
            CSModvars  : changedCSModvars,
            packTable  : StuntWastEditor.SetSSData(packTable, changedCSModvars, CONSTANTS.CS_GroupMstID_Stunting, this.subNatMode),
            openDrawer : false,
            saveBtnDisabled: false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable = JSON.parse(JSON.stringify(this.state.packTable));

        return (
            <TDrawerWithHeader
                open={true}
                onOpenHelpDialog={this.props.onOpenHelpDialog}
                helpFile={"stunting.htm"}
                onClose={this._onClose}
                onSave={this._onApply}
                saveBtnCaption={RS("GB_stApply")}
                saveBtnDisabled={this.state.saveBtnDisabled}
                appBarText={RS("GB_stStunting") + " - " + RS("GB_stDetailedIndicators")}
                content={
                    <div className="innerPage">
                        <EditorNote numberOfTables={1} marginBottom="5px" />

                        <p>{RS("GB_stSingleIndBtnClick")}</p>

                        <CSBlueBox2
                            caption={RS("GB_stSingleIndicator")}
                            captionStyle={this.props.captionStyle}
                            description={""}
                            onClick={this._onOpenInnerDrawer}
                            isRegularButton={true}
                            regBtnStyle={{ background: "#f78c30" }}
                            regBtnDisabled={false}
                        />

                        <br />
                        <br />

                        <CSEditor
                            mstID={CONSTANTS.CS_MstLiSTOnlineStuntingSheetID}
                            showTitle={true}
                            packTable={packTable}
                            onChange={this._onPackTableChange}
                        />

                        {this.state.openDrawer ? (
                            <CSSingleStuntingDrawer
                                CSModvars={this.state.CSModvars}
                                firstYear={this.props.firstYear}
                                finalYear={this.props.finalYear}
                                onApply={this._onApplyInnerDrawer}
                                onClose={this._onCloseInnerDrawer}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />
                        ) : null}
                    </div>
                }
            />
        );
    }
}

export default CSStuntingDrawer;
