import React from 'react';
import PropTypes from "prop-types";
import TExpansionPanel from "@common/components/TExpansionPanel";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class ExploreExpansionPanel extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onShowDataBtnClick : PropTypes.func,
        content            : PropTypes.arrayOf(PropTypes.object),
        panelName          : PropTypes.string,
        showDataBtn        : PropTypes.bool,
        buttonLabel        : PropTypes.string,
        buttonWidth        : PropTypes.string,
        buttonDisabled     : PropTypes.bool,
        disabled           : PropTypes.bool,
        subPanel           : PropTypes.bool,
        defaultExpanded    : PropTypes.bool,
        panelKey           : PropTypes.string,
        language           : PropTypes.number,
    };

    static defaultProps = {
        onShowDataBtnClick : () => console.log('ExploreExpansionPanel onShowDataBtnClick'),
        content            : [],
        panelName          : '',
        showDataBtn        : true,
        buttonLabel        : RS('GB_stCreateDisplay'),
        buttonWidth        : '120px',
        buttonDisabled     : false,
        disabled           : false,
        subPanel           : false,
        defaultExpanded    : false,
        panelKey           : '',
        language           : CONSTANTS.GB_English
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let styles = {
            buttonStyle : {
                fontSize: '16px',
                width: this.props.buttonWidth
            },
            expandMoreIconStyle : {
                color: '#FFFFFF',
                marginRight: 0
            },
            expansionPanelActionStyle : {
                paddingTop: 0,
                paddingBottom: '10px',
                background: '#ebebeb', //'#FFFFFF',
                marginTop: '-8px',
                // marginRight: '-10px'
            },
            headerStyle : {
                fontSize: '16px',
                color: '#FFFFFF',
            },
            subPanelHeaderStyle  : {
                fontSize: '16px',
                color: '#FFFFFF',
                lineHeight: 1
            },
            expansionPanelStyle : {
                marginTop: 2,
                marginBottom: 2,
                boxShadow: 'none',
                width: '285px'
            },
            expansionPanelSummaryStyle : {
                backgroundColor: '#3B78BC',
                maxHeight: '50px',
                height: '50px',
                minHeight: 0,
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '285px',
                textTransform: 'uppercase'
            },
            expansionPanelDetailsStyle : {
                display: 'block',
                backgroundColor: '#ebebeb',//#FFFFFF',
                fontSize: '13px',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '5px',
                paddingBottom: '15px',
                width: '285px'
            },
            expansionSubPanelStyle : {
                marginTop: 7,
                marginBottom: 7,
                boxShadow: 'none',
                width: '265px'
            },
            expansionSubPanelSummaryStyle : {
                backgroundColor: '#0C3A79',
                maxHeight: '50px',
                height: '40px',
                minHeight: 0,
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '265px',
            },
            expansionSubPanelDetailsStyle : {
                display: 'block',
                backgroundColor: '#FFFFFF',
                fontSize: '13px',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '5px',
                paddingBottom: '15px',
                width: '265px'
            },
            expansionSubPanelActionStyle : {
                paddingTop: 0,
                paddingBottom: '10px',
                background: '#FFFFFF',
                marginTop: '-8px',
                // marginRight: '-10px'
            },
        };

        let showSwitch = false;
        let switchCaption = '';
        let switchOn = false;
        let showMoreInfoIcon = false;

        return (
            <TExpansionPanel
                items                      = {[[0, this.props.panelName, showSwitch, switchCaption, switchOn, showMoreInfoIcon]]}
                content                    = {[this.props.content]}
                defaultExpanded            = {this.props.defaultExpanded}
                disabled                   = {this.props.disabled}
                showButton                 = {this.props.showDataBtn}
                buttonLabel                = {this.props.buttonLabel}
                buttonDisabled             = {this.props.buttonDisabled}
                buttonVariant              = {'contained'}
                onButtonClick              = {this.props.onShowDataBtnClick}
                buttonStyle                = {styles.buttonStyle}
                expansionPanelStyle        = {(this.props.subPanel) ? styles.expansionSubPanelStyle : styles.expansionPanelStyle}
                expansionPanelSummaryStyle = {(this.props.subPanel) ? styles.expansionSubPanelSummaryStyle : styles.expansionPanelSummaryStyle}
                expandMoreIconStyle        = {styles.expandMoreIconStyle}
                expansionPanelDetailsStyle = {(this.props.subPanel) ? styles.expansionSubPanelDetailsStyle : styles.expansionPanelDetailsStyle}
                expansionPanelActionStyle  = {(this.props.subPanel) ? styles.expansionSubPanelActionStyle : styles.expansionPanelActionStyle}
                headerStyle                = {(this.props.subPanel) ? styles.subPanelHeaderStyle : styles.headerStyle}
                panelKey                   = {this.props.panelKey}
            />
        );
    }
}

export default ExploreExpansionPanel;