import React from 'react';
import PropTypes from "prop-types";
import TButton from '@common/components/TButton';
import RS from "@common/strings/RS";
import GuidedExplorationPaper from '../components/Home/GuidedExplorationPaper';
import ExploreLiSTDataPaper from '../components/Home/ExploreLiSTDataPaper';
import CreateAProjectionPaper from '../components/Home/CreateAProjectionPaper';
import HomeBannerImage from '../components/Home/HomeBannerImage';
import HomeBannerPaper from '../components/Home/HomeBannerPaper';

class CSHomeForm extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isGuest                     : PropTypes.bool,
        isDeskTop                   : PropTypes.bool,
        projectCreated              : PropTypes.bool,
        projectionName              : PropTypes.string,

        onExploreLiSTDataBtnClick   : PropTypes.func,
        onGuidedExplorationBtnClick : PropTypes.func,
        onCreateProjBtnClick        : PropTypes.func,
        onStateChange               : PropTypes.func,
        onPageChange                : PropTypes.func,
    };

    static defaultProps = {
        isGuest                     : true,
        isDeskTop                   : true,
        projectCreated              : false,
        projectionName              : '',

        onExploreLiSTDataBtnClick   : () => console.log('onExploreLiSTDataBtnClick'),
        onGuidedExplorationBtnClick : () => console.log('onGuidedExplorationBtnClick'),
        onCreateProjBtnClick        : () => console.log('onCreateProjBtnClick'),
        onStateChange               : () => console.log('onStateChange'),
        onPageChange                : () => console.log('onPageChange')
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {

        let styles;

        if (this.props.isDeskTop) {
            //horizontal
            styles = {
                paperBoxes: {
                    width: 940,
                    height: 355,
                    margin: '0 auto',
                    marginBottom: '10px'
                },
                page: {
                    margin: '0 auto',
                    textAlign: 'center',
                    width: '100%',
                    height: '100%',
                    background: 'transparent',
                    position: 'absolute',
                    overflow: 'auto'
                },
                btn: {
                    float: "right",
                    margin: "15px 40px"
                },
                btnCap: {
                    fontSize:"16px",
                    zIndex:1
                }
            };
        }
        else {
            // vertical
            styles = {
                paperBoxes: {
                    width: 300,
                    height: 355,
                    margin: '0 auto',
                    marginBottom: '10px'
                },
                page: {
                    margin: '0 auto',
                    textAlign: 'center',
                    width: '100%',
                    height: '100%',
                    background: 'transparent',
                    position: 'absolute',
                    overflow: 'auto'
                },
                btn: {
                    float: "right",
                    margin: "15px 40px"
                },
                btnCap: {
                    fontSize:"16px",
                    zIndex:1
                }
            };
        }

        return (
            <div style={{width: '100%'}}>
                <div style={styles.page}>
                    <HomeBannerImage
                        isDeskTop={this.props.isDeskTop}
                    />
                    {this.props.isGuest /*&& this.props.isDeskTop */ ?
                        <TButton
                            caption={RS("GB_stLogIn")}
                            containerStyle={styles.btn}
                            style={styles.btnCap}
                            color={"secondary"}
                            onClick={() => this.props.onStateChange({dialogLogInOpen : true})}
                        />
                        : null
                    }
                    <div>
                        <HomeBannerPaper
                            isDeskTop={this.props.isDeskTop}
                        />

                        <div style={styles.paperBoxes}>

                            <ExploreLiSTDataPaper
                                onAddTasks                = {() => console.log('onAddTasks')}
                                onPageChange              = {() => console.log('onPageChange')}
                                onExploreLiSTDataBtnClick = {this.props.onExploreLiSTDataBtnClick}
                                isDeskTop                 = {this.props.isDeskTop}
                                countryISO                = {4}
                                fromGuided                = {false}
                            />

                            <GuidedExplorationPaper
                                onGuidedExplorationBtnClick = {this.props.onGuidedExplorationBtnClick}
                                isDeskTop                   = {this.props.isDeskTop}
                            />

                            <CreateAProjectionPaper
                                onStateChange        = {this.props.onStateChange}
                                onPageChange         = {this.props.onPageChange}
                                onCreateProjBtnClick = {this.props.onCreateProjBtnClick}
                                isDeskTop            = {this.props.isDeskTop}
                                isGuest              = {this.props.isGuest}
                                projectCreated       = {this.props.projectCreated}
                                projectionName       = {this.props.projectionName}
                                fromGuided           = {false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CSHomeForm;