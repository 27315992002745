import { uuidv4 } from './uuidv4';

const CreateAPITask = (flag, params, success, error) => {

    let guid = '{' + uuidv4() + '}';

    return (
        {
            flag      : flag,
            params    : params,
            successFn : success,
            errorFn   : error,
            GUID      : guid,
            running   : false,
            complete  : false
        }
    );
}

export default CreateAPITask;