import React from 'react';
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import ConfigurationContent from "./ConfigurationContent";

function getChartOptions(props) {
    return props.chartOptionsArr.find(x => ((x.modID === props.modID) && (x.resultID === props.resultID)));
}

class ConfigurationDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onSave           : PropTypes.func,
        onClose          : PropTypes.func,       
        modID            : PropTypes.number,
        resultID         : PropTypes.number,
        chartOptionsArr  : PropTypes.arrayOf(PropTypes.object),
        chartYears       : PropTypes.object,
        firstYear        : PropTypes.number,
        finalYear        : PropTypes.number,
        displayEndpoints : PropTypes.bool,
        displayCoverage  : PropTypes.bool,
        retroYears       : PropTypes.bool,
        coverageDisplay  : PropTypes.string,
        onOpenHelpDialog : PropTypes.func
    };

    static defaultProps = {
        onSave           : () => console.log('onSave'),
        onClose          : () => console.log('onClose'),
        modID            : -1,
        resultID         : -1,
        chartOptionsArr  : [],
        chartYears       : {},
        firstYear        : -1,
        finalYear        : -1,
        displayEndpoints : false,
        displayCoverage  : false,
        retroYears       : false,
        coverageDisplay  : '',
        onOpenHelpDialog : () => console.log("onOpenHelpDialog")
    };

    state = {
        yearRange        : {
                                min : this.props.firstYear + this.props.chartYears['firstYrIdx'] - CONSTANTS.CS_DefaultFirstYearIdx,
                                max : this.props.firstYear + this.props.chartYears['finalYrIdx'] - CONSTANTS.CS_DefaultFirstYearIdx,
                           },
        ageIdx           : getChartOptions(this.props)['ageIdx'],
        sexIdx           : getChartOptions(this.props)['sexIdx'],
        discountRateIdx  : getChartOptions(this.props)['discountRateIdx'],
        both             : getChartOptions(this.props)['sex']['both'],
        male             : getChartOptions(this.props)['sex']['male'],
        female           : getChartOptions(this.props)['sex']['female'],
        singleAge        : getChartOptions(this.props)['age']['singleAge'],
        fiveYearAge      : getChartOptions(this.props)['age']['fiveYearAge'],
        codIdx           : getChartOptions(this.props)['codIdx'],
        showIVs          : getChartOptions(this.props)['showIVs'],
        displayEndpoints : this.props.displayEndpoints,
        displayCoverage  : this.props.displayCoverage,
        retroYears       : this.props.retroYears,
        coverageDisplay  : this.props.coverageDisplay,

        changed          : false,
        chartOptions     : getChartOptions(this.props),
    };    
    
    shouldComponentUpdate(nextProps, nextState) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState)) 
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onYearChange = (range) => {
        this.setState({
            yearRange : range,
            changed   : true
        });
    };

    onAgeCohortChange = (ageIdx) => {
        this.setState({
            ageIdx      : ageIdx,
            singleAge   : ageIdx === 0,
            fiveYearAge : ageIdx === 1,
            codIdx      : 0,
            changed     : true
        });
    };

    onSexChange = (sexIdx) => {
        this.setState({
            sexIdx  : sexIdx,
            both    : sexIdx === 0,
            male    : sexIdx === 1,
            female  : sexIdx === 2,
            changed : true
        });
    };

    onDiscountRateChange = (discountRateIdx) => {
        this.setState({
            discountRateIdx  : discountRateIdx,
            changed : true
        });
    };

    onCODChange = (codIdx) => {
        this.setState({
            codIdx  : codIdx,
            changed : true
        });
    };

    onShowIVsChange = (checked) => {
        this.setState({
            showIVs : checked,
            changed : true
        });
    };

    onDisplayEndpointsChange = (checked) => {
        this.setState({
            displayEndpoints : checked,
            changed          : true
        });
    };

    onDisplayCoverageChange = (checked) => {
        this.setState({
            displayCoverage : checked,
            changed         : true
        });
    };

    onRetroYearsChange = (checked) => {
        this.setState({
            retroYears      : checked,
            changed         : true
        });
    };

    onChangeCoverageDisplay = (type) => {
        this.setState({
            coverageDisplay : type,
            changed         : true
        });
    };

    onSave = () => {

        let chartOptionsArr = JSON.parse(JSON.stringify(this.props.chartOptionsArr));

        for (let i = 0; i < chartOptionsArr.length; i++) {
            if ((chartOptionsArr[i]['modID'] === this.props.modID) &&
                (chartOptionsArr[i]['resultID'] === this.props.resultID))
            {
                chartOptionsArr[i]['ageIdx'] = this.state.ageIdx;
                chartOptionsArr[i]['sexIdx'] = this.state.sexIdx;
                chartOptionsArr[i]['discountRateIdx'] = this.state.discountRateIdx;
                chartOptionsArr[i]['sex']['both'] = this.state.both;
                chartOptionsArr[i]['sex']['male'] = this.state.male;
                chartOptionsArr[i]['sex']['female'] = this.state.female;
                chartOptionsArr[i]['age']['singleAge'] = this.state.singleAge;
                chartOptionsArr[i]['age']['fiveYearAge'] = this.state.fiveYearAge;
                chartOptionsArr[i]['codIdx'] = this.state.codIdx;
                chartOptionsArr[i]['showIVs'] = this.state.showIVs;
                chartOptionsArr[i]['endPoints'] = this.state.displayEndpoints;
                chartOptionsArr[i]['showCoverage'] = this.state.displayCoverage;
                chartOptionsArr[i]['showRetroYears'] = this.state.retroYears;
                //the following allows the removal of line and bar charts when breastfeeding prevalence is showing all ages
                if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstBFPrevalence) {
                    if (this.state.ageIdx === 0) {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                        chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                    } else {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_TableChart];
                    }
                }
                //the following allows the removal of line and bar charts when child deaths is showing all ages
                // if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstChildDeaths) {
                //     if (this.state.ageIdx === 0) {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                //         chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                //     } else {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart];
                //     }
                // }
                // //the following allows the removal of line and bar charts when child deaths by cause is showing all ages
                // if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstChDeathsByCause) {
                //     if (this.state.ageIdx === 0) {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                //         chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                //     } else {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart];
                //     }
                // }
                // //the following allows the removal of line and bar charts when additional child lives saved by cause is showing all ages
                // if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstChDeathsPrByCause) {
                //     if (this.state.ageIdx === 0) {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                //         chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                //     } else {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart];
                //     }
                // }
                //the following allows the removal of line and bar charts when additional child lives saved by intervention is showing all ages
                // if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstChDeathsPrByInter) {
                //     if (this.state.ageIdx === 0) {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                //         chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                //     } else {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart];
                //     }
                // }
                //the following allows the removal of line and bar charts when additional child lives saved by intervention by cause is showing all ages
                // if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstChDeathsPrByInterByCause) {
                //     if (this.state.ageIdx === 0) {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                //         chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                //     } else {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart];
                //     }
                // }
                //the following allows the removal of line and bar charts when incidence rates is showing all ages
                if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstEtiIncRates) {
                    if (this.state.ageIdx === 0) {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                        chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                    } else {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_TableChart];
                    }
                }
                //removing line chart for stunting and wasting on all ages
                // if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && (chartOptionsArr[i].resultID === CONSTANTS.CS_MstPercInVarHtForAgeStunt || chartOptionsArr[i].resultID === CONSTANTS.CS_MstPercInVarWtForHtWaste)) {
                //     if (this.state.ageIdx === 0) {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                //         chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                //     } else {
                //         chartOptionsArr[i].chartTypes = [CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart];
                //     }
                // }
                //the following allows the removal of line charts when number of stunting cases averted by intervention is showing all ages
                if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstNumStuntedAvertedByInter) {
                    if (this.state.ageIdx === 0) {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                        chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                    } else {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart];
                    }
                }
                //the following allows the removal of line charts when child cases by cause is showing all ages
                if (chartOptionsArr[i].modID === CONSTANTS.GB_CS && chartOptionsArr[i].resultID === CONSTANTS.CS_MstEtiNumU5CasesByCause) {
                    if (this.state.ageIdx === 0) {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_TableChart];
                        chartOptionsArr[i].typeOfDisplay = CONSTANTS.CS_TableChart;
                    } else {
                        chartOptionsArr[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart];
                    }
                }
                break;
            }
        }

        this.props.onSave({
            chartYears   : {
                              firstYrIdx : this.state.yearRange.min - this.props.firstYear + CONSTANTS.CS_DefaultFirstYearIdx,
                              finalYrIdx : this.state.yearRange.max - this.props.firstYear + CONSTANTS.CS_DefaultFirstYearIdx,
                           },
            chartOptions    : chartOptionsArr,
            displayCoverage : this.state.displayCoverage,
            retroYears      : this.state.retroYears,
            displayEndpoints: this.state.displayEndpoints,
            coverageDisplay : this.state.coverageDisplay
        });
    };

    onClose = () => {
        this.props.onClose();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <TDrawerWithHeader
                open            = {true}
                onClose         = {this.onClose}
                onSave          = {this.onSave}
                showSaveBtn     = {true}
                saveBtnDisabled = {false}
                appBarText      = {RS('GB_stModifyConfig')}
                onOpenHelpDialog= {this.props.onOpenHelpDialog}
                helpFile        = {"configuration.htm"}
                drawerWidth     = {'55%'}
                content         = {
                                    <ConfigurationContent
                                        onYearChange             = {this.onYearChange}
                                        onAgeCohortChange        = {this.onAgeCohortChange}
                                        onSexChange              = {this.onSexChange}
                                        onCODChange              = {this.onCODChange}
                                        onDiscountRateChange     = {this.onDiscountRateChange}
                                        onShowIVsChange          = {this.onShowIVsChange}
                                        onDisplayEndpointsChange = {this.onDisplayEndpointsChange}
                                        onDisplayCoverageChange  = {this.onDisplayCoverageChange}
                                        onRetroYearsChange       = {this.onRetroYearsChange}
                                        onChangeCoverageDisplay  = {this.onChangeCoverageDisplay}

                                        firstYear                = {this.props.firstYear}
                                        finalYear                = {this.props.finalYear}
                                        yearRange                = {this.state.yearRange}
                                        ageIdx                   = {this.state.ageIdx}
                                        sexIdx                   = {this.state.sexIdx}
                                        discountRateIdx          = {this.state.discountRateIdx}
                                        both                     = {this.state.both}
                                        male                     = {this.state.male}
                                        female                   = {this.state.female}
                                        singleAge                = {this.state.singleAge}
                                        fiveYearAge              = {this.state.fiveYearAge}
                                        codIdx                   = {this.state.codIdx}
                                        showIVs                  = {this.state.showIVs}
                                        displayEndpoints         = {this.state.displayEndpoints}
                                        displayCoverage          = {this.state.displayCoverage}
                                        retroYears               = {this.state.retroYears}
                                        coverageDisplay          = {this.state.coverageDisplay}

                                        chartOptions             = {this.state.chartOptions}
                                    />
                                }
            />
        );
    }

}

export default ConfigurationDrawer;