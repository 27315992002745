import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import MOExpansionPanel from "./MOExpansionPanel";

class DeliveryPoints extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange         : PropTypes.func,
        deliveryPointsContent : PropTypes.arrayOf(PropTypes.object),
        language              : PropTypes.number
    };

    static defaultProps = {
        onStateChange         : () => console.log('onStateChange'),
        deliveryPointsContent : [],
        language              : CONSTANTS.GB_English
    };

    state = {
        deliveryPointsContent : this.props.deliveryPointsContent
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onChange = (panelContent) => {
        this.props.onStateChange({
            deliveryPointsContent : panelContent
        })
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderHeader = () => {
        let styles = {
            headerStyle: {
                color: '#000000',
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: '10px',
                marginBottom: '8px',
                userSelect: 'none'
            }
        };

        return (
            <div>
                <p style={styles.headerStyle}>{RS('GB_stContinuumOfCare')}</p>
            </div>
        )

    };

    renderContent = () => {
        return (
            <div>
                <MOExpansionPanel
                    onStateChange   = {this.props.onStateChange}
                    content         = {this.props.deliveryPointsContent}
                    panelName       = {RS('GB_stInterventionType')}
                    allMstID        = {CONSTANTS.CS_MstAllDelivPointsID}
                    defaultExtended = {false}
                    onChange        = {this.onChange}
                />
            </div>
        )
    };

    render() {
        return (
            <div>
                {this.renderHeader()}
                {this.renderContent()}
            </div>

        );
    }

}

export default DeliveryPoints;