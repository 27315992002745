import React from 'react';
import PropTypes from "prop-types";
import TTabs2 from "@common/components/TTabs2";
import {CSEditor} from "../../../../../components/CS/CSEditors";

class TabbedTables extends React.Component {
    
    static propTypes = {
        tabs       : PropTypes.array,
        packTable  : PropTypes.array,
        tableRef   : PropTypes.any,
        mstID      : PropTypes.number
    };

    static defaultProps = {
        tabs       : [],
        packTable  : [],
        tableRef   : null,
        mstID      : -1
    };

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if ((JSON.stringify(this.props.display) !== JSON.stringify(nextProps.display))) {
    //         return true;
    //     }
    //     else if (this.props.language !== nextProps.language) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderTable = (age) => {
        let packTable = JSON.parse(JSON.stringify(this.props.packTable[age] || this.props.packTable));
        packTable.Title+=" (" + this.props.tabs[age] + ")";

        return (
            <CSEditor
                mstID     = {this.props.mstID}
                showTitle = {true}
                isResult  = {true}
                packTable = {packTable}
                tableRef  = {this.props.tableRef}
                sourceEditable = {false}
            />
        );
    };

    renderTitle = () => {
        const styles = {
            chartTitle: {
                fontSize: 18,
                marginBottom: 15,
            }
        };
        return (
            <p style={styles.chartTitle}>{this.props.packTable[0] ? this.props.packTable[0].Title : this.props.packTable.Title}</p>
        )
    };

    renderTabs = () => {
        let counter = 0;
        let tabContents = [];
        
        this.props.tabs.forEach((tab) => {
            tabContents.push(this.renderTable(counter));
            counter++;
        });

        const styles = {
            tabSelectedStyle : {
                color: '#f78c30',
                fontWeight: 'bold'
            },
            tabRootStyle : {
                fontSize: '18px'
            },
            tabContentStyle : {
                // marginTop: '185px'
            },
            tabAppBarStyle : {
                position: "static",
            }
        };

        return (
            <div styles={{marginTop: '20px'}}>
                <TTabs2
                    tabNames={this.props.tabs}
                    tabContents={tabContents}
                    tabAppBarStyle={styles.tabAppBarStyle}
                    tabSelectedStyle={styles.tabSelectedStyle}
                    tabRootStyle={styles.tabRootStyle}
                    tabContentStyle={styles.tabContentStyle}
                />
            </div>
        );
    };

    render() {
        return (
            <div>
                {this.renderTitle()}
                {this.renderTabs()}
            </div>
        );
    }
}

export default TabbedTables;