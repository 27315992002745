import React from 'react';
import PropTypes from "prop-types";
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import SaveButton from "../buttons/SaveButton";

class TCSCopiedFileNameDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        fileToCopy        : PropTypes.string,
        onStateChange     : PropTypes.func,
        onCopyProjection  : PropTypes.func,
    };

    static defaultProps = {
        fileToCopy        : "",
        onStateChange     : () => console.log('onStateChange'),
        onCopyProjection  : () => console.log('onCopyProjection'),
    };

    state = {
        copiedFileName    : this.props.fileToCopy + "-copy"
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onType = (val) => {
        this.setState({
            copiedFileName: val
        });
    };

    onClose = () => {
        this.props.onStateChange({
            dialogFileCopyOpen: false
        });
    };

    onSave = () => {
        this.props.onCopyProjection(this.props.fileToCopy, this.state.copiedFileName);
        this.onClose();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {

        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0'
            }
        };

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stDuplicate") + " " + RS("GB_stProjection")}
                onClose={this.onClose}
                content={
                    <div>
                        <TEdit
                            name="CopiedFileNameField"
                            caption={RS("GB_stNewProjName")}
                            value={this.state.copiedFileName}
                            onChange={this.onType}
                            style={styles.edit}
                            foc={true}
                            shrinkLabel={true}
                        />
                    </div>
                }
                actions={
                    <div>
                        <SaveButton onClick={this.onSave} disabled={this.state.copiedFileName.length === 0} />
                    </div>
                }
            />
        );
    }

}

export default TCSCopiedFileNameDialog;