import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getModvarExists,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    addRowToPackTable,
    addMergedCellsToArray,
    indentPackTable,
    lockPackRowCol, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks,
    addComment
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

import {
    FindIntervRec,
    CSGetBirthTermAbbrev,
    CSGetMaternalIntervMstID,
} from "utilities/CS/CSDataUtil";

import GB_CONSTANTS from 'utilities/GB/GBConst';

export const MaternalNutritionEditor = {
    Create : (IVInfo, CSModvars, language, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stMaternalNutrition');
        let packTable = Setup(IVInfo, CSModvars, language, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let MaternalNutrition = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_IntervForBO_MV7);
        for (let row = 1; row < pt.GBRowCount; row++){
            if (pt.RowIds[row] > 0){
                for (let col = 1; col < pt.GBColCount; col++){
                    let indices = GetEffAfAndTermFromCol(col);
                    let EffAF = indices["EffAF"];
                    let term = indices["term"];
                    let i = pt.RowIds[row];
                    MaternalNutrition["value"][CONSTANTS.CS_Data][EffAF][i][term] = pt.tableData.value[row][col];
                    MaternalNutrition["source"][0][i] = pt.Source[pt.SourceMap[row]];
                }
            }
        }

        changedModvars.push(MaternalNutrition);

        return (changedModvars);
    },
};

const Setup = (IVInfo, CSModvars, language, EditorStartYear, EditorEndYear) => {
    let ProjectionValidDate = undefined;
    if (getModvarExists(CSModvars, CONSTANTS.CS_TG_ProjectionValidDate_MV2)){
        ProjectionValidDate = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ProjectionValidDate_MV2)["value"];
    }

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt.GBFixedRows = 2;

    let numCols = 7;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            for (let row = 1; row <= CONSTANTS.CS_24t60months; row++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
            }
            col++;
        }
    }
    else{
        for (let i = CONSTANTS.CS_PreTermSGA; i <= CONSTANTS.CS_TermSGA; i++){
            let Offset = ((i - CONSTANTS.CS_PreTermSGA) * 2);
            pt.tableData.value[0][1 + Offset] = CSGetBirthTermAbbrev(i);
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 0, 1 + Offset, 1, 2);
            pt.tableData.value[1][1 + Offset] = RS('GB_stEffectiveness');
            pt.tableData.value[1][2 + Offset] = RS('CS_stAffFract');
        }
    }

    pt.numSources = 0;

    let row = 1;
    for (let i = CONSTANTS.CS_FirstMatNutrIV; i <= CONSTANTS.CS_MaternalNutrMax; i++){
        let mstID = CSGetMaternalIntervMstID(i);
        let IVRec = FindIntervRec(IVInfo, mstID);
        if (IVRec !== null) {
            row++;
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = IVRec["Names"][language];
            switch (i) {
                case CONSTANTS.CS_MultMicroLowBMI : {
                    pt.tableData.value[row][0] += ' (low BMI women)';
                    break;
                }
                case CONSTANTS.CS_MultMicroNormBMI : {
                    pt.tableData.value[row][0] += ' (healthy BMI)';
                    break;
                }
                default :
                    break;
            }
            pt.RowIds[row] = i;
            pt.numSources++;
            pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
            pt.SourceMap[row] = pt.numSources;
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            for (let col = 1; col < pt.GBColCount; col++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
                if (!isOdd(col)) {
                    switch (i) {
                        case CONSTANTS.CS_IPTp : {
                            if ((ProjectionValidDate=== undefined) || (ProjectionValidDate === RS('GB_stUnavailable'))) {
                                pt = addComment(pt, row, col, RS('GB_stIPTpMalAffFractNote') + ' ' + RS('GB_stProjRecalcUpdate'));
                            } else {
                                pt = addComment(pt, row, col, RS('GB_stIPTpMalAffFractNote') + ' ' + RS('GB_stCalNumNeedHint'));
                            }
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }
                        case CONSTANTS.CS_MultMicroLowBMI  : {
                            pt = addComment(pt, row, col, RS('GB_stPercWom15t49LowBMI'));
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }
                        case CONSTANTS.CS_MultMicroNormBMI : {
                            pt = addComment(pt, row, col, RS('GB_stPercWom15t49HealthyBMI'));
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }
                        case CONSTANTS.CS_CalciumSupp : {
                            pt = addComment(pt, row, col, RS('GB_stCalcDef'));
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }
                        case CONSTANTS.CS_FolicAcidFort : {
                            pt = addComment(pt, row, col, RS('GB_stFolDef'));
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }
                        case CONSTANTS.CS_ZincFort : {
                            pt = addComment(pt, row, col, RS('GB_stPercZincDef'));
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }
                        case CONSTANTS.CS_ZincSuppInPreg : {
                            pt = addComment(pt, row, col, RS('GB_stPercZincDef'));
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }
                        case CONSTANTS.CS_AspirinLowDose: {
                            if (ProjectionValidDate === RS('GB_stUnavailable')) {
                                pt = addComment(pt, row, col, RS('GB_stAspirinAffFractNote') + ' ' + RS('GB_stProjRecalcUpdate'));
                            } else {
                                pt = addComment(pt, row, col, RS('GB_stAspirinAffFractNote') + ' ' + RS('GB_stCalNumNeedHint'));
                            }
                            pt = lockPackRowCol(pt, row, col, true);
                            break;
                        }

                        default :
                            break;
                    }
                }
            }
        }
    }

    return pt;
};

const isOdd = (num) =>{return num % 2;};

const GetEffAfAndTermFromCol = (col) => {
    let EffAF = 0;
    if (isOdd(col)){EffAF = CONSTANTS.CS_Efficacy;}
    else{EffAF = CONSTANTS.CS_AffecFract;}
    let term = Math.floor((col-1)/2) + 1;
    return {'EffAF' : EffAF, 'term' : term};
};

const SetSSData = (pt, CSModvars) => {
    let MaternalNutrition = getModvarByTag(CSModvars, CONSTANTS.CS_TG_IntervForBO_MV7);
    for (let row = 1; row < pt.GBRowCount; row++){
        if (pt.RowIds[row] > 0){
            for (let col = 1; col < pt.GBColCount; col++){
                let indices = GetEffAfAndTermFromCol(col);
                let EffAF = indices["EffAF"];
                let term = indices["term"];
                let i = pt.RowIds[row];
                pt.tableData.value[row][col] = MaternalNutrition["value"][CONSTANTS.CS_Data][EffAF][i][term];
                pt.Source[pt.SourceMap[row]] = MaternalNutrition["source"][0][i];
            }
        }
    }
    return pt;
};

