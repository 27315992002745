import React from "react";
import Banner from "../components/Home/Banner";
import IntroductionBox from "../components/Home/IntroductionBox";
import LoginButton from "../components/Home/LoginButton";
import ButtonBegin from "../components/buttons/ButtonBegin";

import TButton from "@common/components/TButton";

const HomePage = (props) => {
    const { isGuest, onPageChange } = props;

    return (
        <React.Fragment>
            <Banner isDesktop={true} />

            <IntroductionBox />

            {isGuest ? (
                <LoginButton onClick={() => onPageChange("LoginPage")} />
            ) : (
                <ButtonBegin onClick={() => onPageChange("ProjectionsPage")} />
            )}

            <TButton caption="ProjectionsPage" onClick={() => onPageChange("ProjectionsPage")} />

            <TButton caption="IndicatorsPage" onClick={() => onPageChange("IndicatorsPage")} />

            <TButton caption="ConfigurationPage" onClick={() => onPageChange("ConfigurationPage")} />

            <TButton caption="ProcessPage" onClick={() => onPageChange("ProcessPage")} />
        </React.Fragment>
    );
};

export default HomePage;
