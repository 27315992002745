import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import TExpansionPanel from "@common/components/TExpansionPanel";
import TCheckBox from "@common/components/TCheckBox";
import TEdit from "@common/components/TEdit";
import { Flatten2DArray } from "@common/util/GBUtil";
import RS from "@common/strings/RS";

const styles = {
    checkBoxLabelStyle: {
        color: "#0c3a79",
        fontSize: "13px",
        lineHeight: "1"
    },
    checkBoxStyle: {
        height: "37px"
    },
    boxStyle: {
        // height: '5px'
    },
    paper: {
        marginRight: "20px",
        width: "388px",
        boxShadow: "none"
    },
    expansionPanel: {
        marginTop: 0,
        marginBottom: 0
    },
    expansionPanelSummary: {
        backgroundColor: "#0c3a79",
        maxHeight: "45px",
        minHeight: 0,
        width: "390px",
        marginLeft: "-1px"
    },
    header: {
        fontSize: "22px",
        color: "#FFFFFF"
    },
    expandMoreIcon: {
        color: "#FFFFFF"
    },
    expansionPanelDetails: {
        display: "block",
        backgroundColor: "#ebebeb",
        fontSize: "25px",
        paddingLeft: "24px",
        paddingTop: "5px",
        paddingBottom: "15px"
    },
    chip: {
        visibility: "hidden"
    },
    saveColor: {
        color: "primary"
    }
};

class ResultCheckBoxes extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        resultGroupNames    : PropTypes.arrayOf(PropTypes.array),
        selectedResults     : PropTypes.array,
        checkBoxResultLists : PropTypes.arrayOf(PropTypes.array),
        onCheckBoxChange    : PropTypes.func,
        onSelectAllChanged  : PropTypes.func,
        directions          : PropTypes.string
    };

    static defaultProps = {
        resultGroupNames    : [],
        selectedResults     : [],
        checkBoxResultLists : [],
        onCheckBoxChange    : () => {},
        onSelectAllChanged  : () => {},
        directions          : RS('GB_stChooseResults')
    };

    state = {
        filterText          : ""
    };

    //==================================================================================================================
    //
    //                                              Function
    //
    //==================================================================================================================

    onFilterChange = value => {
        this.setState({
            filterText: value
        });
    };

    onCheckBoxChange = (checked, event, name, caption, mstID, modID) => {
        this.props.onCheckBoxChange(checked, caption, modID, mstID);
    };

    checkIfResultIsOn = (mstID, modID) => {
        let result = false;

        for (let i = 0; i < this.props.selectedResults.length; i++) {
            if (mstID === this.props.selectedResults[i][0] && modID === this.props.selectedResults[i][1]) {
                result = true;
                break;
            }
        }

        return result;
    };

    onSelectAllChange = (checked, event, name, caption, mstID, modID) => {
        const { checkBoxResultLists, onSelectAllChanged } = this.props;
        let arr = [];

        if (checked) {

            for (let grp = 0; grp < checkBoxResultLists.length; grp++) {
                for (let i = 0; i < checkBoxResultLists[grp].length; i++) {
                    const title = checkBoxResultLists[grp][i][0];
                    const mstID = checkBoxResultLists[grp][i][1];
                    const modID = checkBoxResultLists[grp][i][2];
    
                    arr.push([mstID, modID, title]);
                }  
            }
        }

        onSelectAllChanged(arr);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderResultContent = () => {
        let resultContent = [];

        for (let grp = 0; grp < this.props.checkBoxResultLists.length; grp++) {
            resultContent.push(this.renderResultCBsByGroup(grp));
        }

        return resultContent;
    };

    renderResultCBsByGroup = grp => {
        const { checkBoxResultLists } = this.props;
        const { filterText } = this.state;

        let resultCBs = [];

        for (let i = 0; i < checkBoxResultLists[grp].length; i++) {
            const title = checkBoxResultLists[grp][i][0];
            const filterOK = title.toLowerCase().includes(filterText.toLowerCase());

            if (filterOK) {
                resultCBs.push(
                    <TCheckBox
                        caption={title}
                        labelStyle={styles.checkBoxLabelStyle}
                        style={styles.checkBoxStyle}
                        boxStyle={styles.boxStyle}
                        key={checkBoxResultLists[grp][i][1]}
                        name={""}
                        customProp={checkBoxResultLists[grp][i][1]}
                        customProp2={checkBoxResultLists[grp][i][2]}
                        value={this.checkIfResultIsOn(
                            checkBoxResultLists[grp][i][1],
                            checkBoxResultLists[grp][i][2]
                        )}
                        onClick={this.onCheckBoxChange}
                    />
                );
            }
        }

        return resultCBs;
    };

    render() {
        const { filterText } = this.state;
        const { checkBoxResultLists, selectedResults } = this.props;

        const items = this.props.resultGroupNames.map(x => x);
        const content = this.renderResultContent();
        for (let i = 0; i < items.length; i++) {
            if (content[i].length === 0) {
                items.splice(i, 1);
                content.splice(i, 1);
                i--;
            }
        }


        /* Check if all results are selected for the select all checkbox. */
        let selectAllChecked = false;
        let array1D = Flatten2DArray(checkBoxResultLists);
        if (selectedResults.length === array1D.length) {
            selectAllChecked = true;
        }

        return (
            <Paper style={styles.paper}>
                <p style={styles.paragraph1}>
                    {this.props.directions}
                </p>

                <TEdit
                    caption={RS("GB_stFilter")}
                    value={filterText}
                    onChange={this.onFilterChange}
                    shrinkLabel={true}
                    style={{width: "100%", ...styles.filterStyle}}
                />

                <TCheckBox
                    caption={"Select all"}
                    labelStyle={styles.checkBoxLabelStyle}
                    style={styles.checkBoxStyle}
                    boxStyle={styles.boxStyle}
                    name={""}
                    // value={this.checkIfResultIsOn(
                    //     checkBoxResultLists[grp][i][1],
                    //     checkBoxResultLists[grp][i][2]
                    // )}
                    value={selectAllChecked}
                    onClick={this.onSelectAllChange}
                />

                <TExpansionPanel
                    defaultExpanded={true}
                    expansionPanelStyle={styles.expansionPanel}
                    expansionPanelSummaryStyle={styles.expansionPanelSummary}
                    headerStyle={styles.header}
                    expandMoreIconStyle={styles.expandMoreIcon}
                    expansionPanelDetailsStyle={styles.expansionPanelDetails}
                    chipStyle={styles.chip}
                    items={items}
                    content={content}
                />
            </Paper>
        );
    }
}

export default ResultCheckBoxes;
