import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
    getModvarCloneByTag,
    getIdxFromYear,
    lockPackRowCol, CloneObj
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const EarlyInitEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, SubnatMode) => {
        let editorName = RS('GB_stEarlyInitBFPrev');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, SubnatMode);
        packTable = SetSSData(packTable, CSModvars, SubnatMode);
        packTable.Title = editorName;
        packTable.ID = CONSTANTS.CS_MstEarlyInitBFSheetID;
        packTable.EditorCat = CONSTANTS.GB_Coverage;
        return (packTable);
    },

    GetSSData : (pt, CSModvars, SubnatMode) => {
        let changedModvars = [];
        let earlyInitBF = {};
        if (SubnatMode) {
            changedModvars = CloneObj(CSModvars);
            earlyInitBF = getModvarByTag(changedModvars, CONSTANTS.CS_TG_EarlyInitBFPrev_MV2);
        }
        else {
            earlyInitBF = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_EarlyInitBFPrev_MV2);
        }

        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            earlyInitBF["value"][CONSTANTS.CS_0t1months][t] = pt.tableData.value[1][col];
            col++;
        }

        earlyInitBF["source"][0][0] = pt.Source[0];

        if (!SubnatMode) {
            changedModvars.push(earlyInitBF);
        }

        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, SubnatMode) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.yearArr = [];
    let numCols;
    if (!SubnatMode) {
        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }
    else{
        numCols = 3;
        for (let t = StartIndex; t <= StartIndex + 1; t++){
            pt.yearArr.push(t);
        }
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    if (!SubnatMode) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            col++;
        }
    }
    else {
        pt.tableData.value[0][1] = RS('GB_stNational');
        pt.tableData.value[0][2] = RS('GB_stSubnational');
    }

    pt.tableData.value[0][0] = RS('GB_stInterventionPercent');

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.RDec[1][col] = 2;
        col++;
    }

    if (SubnatMode) {
        pt = lockPackRowCol(pt, 1, 1, true);
    }

    return pt;
};

const SetSSData = (pt, CSModvars, SubnatMode) => {
    let FirstTimeEnteringCoverage = {};
    let SubnatDefDataMstIDset = {};
    let SubnatDataSourceArray = {};

    if (SubnatMode){
        FirstTimeEnteringCoverage = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TFirstTimeEnteringCoverage);
        SubnatDefDataMstIDset = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TSubnatDefDataMstIDset);
        SubnatDataSourceArray = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TSubnatDataSourceArray);
    }

    let earlyInitBF = getModvarByTag(CSModvars, CONSTANTS.CS_TG_EarlyInitBFPrev_MV2);
    if (!SubnatMode) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            pt.tableData.value[1][col] = earlyInitBF["value"][CONSTANTS.CS_0t1months][t];
            col++;
        }
        pt.Source[0] = earlyInitBF["source"][0][0];
    }
    else{
        pt.tableData.value[1][1] = earlyInitBF["value"][CONSTANTS.CS_0t1months][pt.yearArr[0]];

        let MstID = CONSTANTS.CS_MstEarlyInitBF;

        /* Under appropriate circumstances, set the first year (national)
           coverage as the second year (subnational) breastfeeding distribution as well. */
        if (FirstTimeEnteringCoverage["value"] && !SubnatDefDataMstIDset["value"]["intArray"].includes(MstID)){
            pt.tableData.value[1][2] = earlyInitBF["value"][CONSTANTS.CS_0t1months][pt.yearArr[0]];
        }
        else{
            pt.tableData.value[1][2] = earlyInitBF["value"][CONSTANTS.CS_0t1months][pt.yearArr[1]];
        }

        pt.Source[0] = SubnatDataSourceArray["value"][MstID];

    }
    return pt;
};

