import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {PercDthByCauseEditor} from "../../data/HealthStatus/ChildMortality/PercDthByCauseEditor";

class CSChildMortalityDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object, 
        packTable3                : PropTypes.object,  
        onPackTable1Change        : PropTypes.func,
        onPackTable2Change        : PropTypes.func, 
        onPackTable3Change        : PropTypes.func,
        CSModvars                 : PropTypes.array,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        packTable3                : null, 
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change        : (packTable) => console.log('onPackTable2Change'),
        onPackTable3Change        : (packTable) => console.log('onPackTable3Change'),
        CSModvars                 : [],
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable); 
    };

    onPackTable2Change = (packTable) => {
        packTable = PercDthByCauseEditor.RecalcGrid(packTable);
        this.props.onPackTable2Change(packTable);
    };

    onPackTable3Change = (packTable) => {
        packTable = PercDthByCauseEditor.RecalcGrid(packTable);
        this.props.onPackTable3Change(packTable);  
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));
        let packTable3 = JSON.parse(JSON.stringify(this.props.packTable3));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {3}
                />

                {/*<GBStdTable*/}
                    {/*id={"MySheet1"}*/}
                    {/*packTable={packTable1}*/}
                    {/*minHeight={85}*/}
                    {/*maxHeight={85}*/}
                    {/*bandColor={Theme.editor.bandColor}*/}
                    {/*fixedRowColor={Theme.editor.fixedRowColor}*/}
                    {/*highlightColor={Theme.editor.highlightColor}*/}
                    {/*cellPadding={Theme.editor.cellPadding}*/}
                    {/*onSheetCellsChanged={this.onPackTable1Change}*/}
                {/*/>*/}

                <CSEditor
                    mstID={CONSTANTS.CS_MstBaselineMortSheetID}
                    packTable={packTable1}
                    showTitle={true}
                    onChange={this.onPackTable1Change}
                />

                {/*<GBStdTable*/}
                    {/*id={"MySheet2"}*/}
                    {/*packTable={packTable2}*/}
                    {/*minHeight={310}*/}
                    {/*maxHeight={310}*/}
                    {/*bandColor={Theme.editor.bandColor}*/}
                    {/*fixedRowColor={Theme.editor.fixedRowColor}*/}
                    {/*highlightColor={Theme.editor.highlightColor}*/}
                    {/*cellPadding={Theme.editor.cellPadding}*/}
                    {/*onSheetCellsChanged={this.onPackTable2Change}*/}
                {/*/>*/}

                <CSEditor
                    mstID={CONSTANTS.CS_MstDeathsByCauseNNSheetID}
                    packTable={packTable2}
                    showTitle={true}
                    onChange={this.onPackTable2Change}
                />

                {/*<GBStdTable*/}
                    {/*id={"MySheet3"}*/}
                    {/*packTable={packTable3}*/}
                    {/*minHeight={340}*/}
                    {/*maxHeight={340}*/}
                    {/*bandColor={Theme.editor.bandColor}*/}
                    {/*fixedRowColor={Theme.editor.fixedRowColor}*/}
                    {/*highlightColor={Theme.editor.highlightColor}*/}
                    {/*cellPadding={Theme.editor.cellPadding}*/}
                    {/*onSheetCellsChanged={this.onPackTable3Change}*/}
                {/*/>*/}

                <CSEditor
                    mstID={CONSTANTS.CS_MstDeathsByCausePNNSheetID}
                    packTable={packTable3}
                    showTitle={true}
                    onChange={this.onPackTable3Change}
                />

            </div>
        );
    }

}

export default CSChildMortalityDrawer;