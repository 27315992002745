import React from 'react';
import PropTypes from "prop-types";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import StepConnecter from '@material-ui/core/StepConnector';
// import StepIcon from '@material-ui/core/StepIcon';

const styles = {
    root : {
        color: '#65A7EB !important' // blue
    },
    active: {
        color: '#f78c30 !important' // orange
    },
};

class CSStepper extends React.Component {

    static propTypes = {
        steps          : PropTypes.array,
        activeStep     : PropTypes.number,
        completedSteps : PropTypes.array,
        disabledSteps  : PropTypes.array,
        onStepBtnClick : PropTypes.func,
        useAltColors   : PropTypes.bool,
    };

    static defaultProps = {
        steps          : ["Step 1", "Step 2", "Step 3", "Step 4"],
        activeStep     : 0,
        completedSteps : [0, 1, 2, 3],
        disabledSteps  : [],
        onStepBtnClick : () => console.log("onStepBtnClick"),
        useAltColors   : PropTypes.bool,
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onStepBtnClick = (index) => {
        this.props.onStepBtnClick(index);
    };

    render() {
        const styles = {
            activeStep: {
                fontWeight: 'bold',
                fontSize: '14px',
            },
            nonActiveStep : {
                fontWeight: 'normal',
                fontSize: '14px'
            },
            stepper : {
                marginTop: '15px',
                paddingBottom: '7px',
                paddingTop: '7px',
                minWidth: '300px',
                background: '#E6F3FF',
                // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
                borderRadius: '5px',
                border: '1px solid #CCC',
                // padding: '15px',
            }
        };

        return (
            <Stepper
                style      = {styles.stepper}
                nonLinear  = {true}
                activeStep = {this.props.activeStep}
                // connector  = {<StepConnecter classes={{lineHorizontal : "moo" }} />}
            >
                {this.props.steps.map((label, index) => {

                    let completed = this.props.completedSteps.indexOf(index) >= 0;
                    let isActive = this.props.activeStep === index;

                    return (
                        <Step
                            key={label}
                            completed={completed}
                            active={!this.props.disabledSteps.includes(index + 1)}
                        >
                            <StepButton
                                key={index}
                                onClick={() => this.onStepBtnClick(index)}
                                disabled={this.props.disabledSteps.includes(index + 1)}
                            >
                                <StepLabel
                                    // StepIconProps={{
                                    //     classes: {
                                    //         root : this.props.useAltColors ? this.props.classes.root : undefined,
                                    //         active: this.props.classes.active,
                                    //     }
                                    // }}
                                >
                                    <Typography
                                        style={(isActive) ? styles.activeStep : styles.nonActiveStep}
                                    >
                                        {label}
                                    </Typography>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        );
    }
};

export default withStyles(styles)(CSStepper);