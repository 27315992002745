import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    CSGetBirthTermAbbrev
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const LowBirthWeightFactorsEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stLowBirthWeight');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let LBWF = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_LBWF_MV2);

        for(let i = CONSTANTS.CS_PreTermSGA; i <= CONSTANTS.CS_TermAGA; i++){
            LBWF["value"][CONSTANTS.CS_Data][i] = pt.tableData.value[1][i];
        }

        LBWF["source"][0][0] = pt.Source[0];

        changedModvars.push(LBWF);

        return (changedModvars);
    },
};

const Setup = () => {
    const numCols = 5;
    const numRows = 2;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stPercChildrenLBW');

    for(let i = CONSTANTS.CS_PreTermSGA; i <= CONSTANTS.CS_TermAGA; i++) {
        pt.tableData.value[0][i] = CSGetBirthTermAbbrev(i);
        pt.Alignments[1][i] = CONSTANTS.GB_Right;
        pt.RDec[1][i] = 2;
        pt.MaxAllowedVal[1][i] = 1000;
    }


    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let LBWF = getModvarByTag(CSModvars, CONSTANTS.CS_TG_LBWF_MV2);

    for(let i = CONSTANTS.CS_PreTermSGA; i <= CONSTANTS.CS_TermAGA; i++){
        pt.tableData.value[1][i] = LBWF["value"][CONSTANTS.CS_Data][i];
    }

    pt.Source[0] = LBWF["source"][0][0];

    return pt;
};

