import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CountryDropdown from "./components/dropdowns/CountryDropdown";
import CountryFlags from "../../CountryFlags";
import ExploreHeading from "./components/labels/ExploreHeading";
import CONSTANTS from "utilities/CS/CSConst";
import TopicDropdown from "./components/dropdowns/TopicDropdown";
import HeaderText from "./components/labels/HeaderText";
import CauseOfDeathOptions from "./components/options/CauseOfDeathOptions";
import HealthNutritionOptions from "./components/options/HealthNutritionOptions";
import EffectivenessValuesOptions from "./components/options/EffectivenessValuesOptions";
import IntervCovOptions from "./components/options/IntervCovOptions";

class ExploreLiSTDataSideBarContent extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        selectedCountry  : PropTypes.string,
        countryISO       : PropTypes.number,
        countries        : PropTypes.array,
        language         : PropTypes.number,
        IVGroupInfo      : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo   : PropTypes.arrayOf(PropTypes.object),
        IVInfo           : PropTypes.arrayOf(PropTypes.object),
        inputModvars     : PropTypes.arrayOf(PropTypes.object),
        onStateChange    : PropTypes.func,
        onAddTasks       : PropTypes.func,
        onAddDisplay     : PropTypes.func,
        menuThreshold    : PropTypes.bool
    };

    static defaultProps = {
        selectedCountry  : "Afghanistan",
        countryISO       : 4,
        countries        : [],
        language         : CONSTANTS.GB_English,
        IVGroupInfo      : [],
        IVSubGroupInfo   : [],
        IVInfo           : [],
        inputModvars     : [],
        onStateChange    : () => console.log('ExploreLiSTDataSideBarContent onStateChange'),
        onAddTasks       : () => console.log('ExploreLiSTDataSideBarContent onAddTasks'),
        onAddDisplay     : () => console.log('ExploreLiSTDataSideBarContent onAddDisplay'),
        menuThreshold    : false
    };

    state = {
        topic : 0
    };

    onTopicChange = (itemIndex) => {
        this.setState({
            topic : itemIndex
        })
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let styles = {
            wrapper : {
                paddingLeft: '25px',
                paddingTop: '30px',
                marginTop: this.props.menuThreshold ? 25 : 0,
            },
            countryFlags : {
                width: '27px',
                marginTop: '3px',
                marginLeft: '10px'
            }
        };

        return (
            <div style={styles.wrapper}>
                <HeaderText caption={RS('GB_stExploreLiSTData')} />

                <ExploreHeading string={RS('GB_stSelectCountry') + ":"} />

                <CountryDropdown
                    onStateChange   = {this.props.onStateChange}
                    onAddTasks      = {this.props.onAddTasks}
                    selectedCountry = {this.props.selectedCountry}
                    countryISO      = {this.props.countryISO}
                    countries       = {this.props.countries}
                />

                <CountryFlags
                    selectedCountry = {this.props.selectedCountry}
                    style           = {styles.countryFlags}
                />

                <ExploreHeading string={RS('GB_stSelectTopicToExplore') + ":"}/>
                
                <TopicDropdown
                    itemIndex = {this.state.topic}
                    onChange  = {this.onTopicChange}
                    language  = {this.props.language}
                />

                <ExploreHeading string={RS('GB_stSelectOptions') + ":"} />

                <CauseOfDeathOptions
                    visible      = {this.state.topic === 0}
                    onAddDisplay = {this.props.onAddDisplay}
                    language     = {this.props.language}
                />

                <HealthNutritionOptions
                    visible      = {this.state.topic === 1}
                    onAddDisplay = {this.props.onAddDisplay}
                    language     = {this.props.language}
                />

                <IntervCovOptions
                    visible        = {this.state.topic === 2}
                    IVGroupInfo    = {this.props.IVGroupInfo}
                    IVSubGroupInfo = {this.props.IVSubGroupInfo}
                    IVInfo         = {this.props.IVInfo}
                    inputModvars   = {this.props.inputModvars}
                    language       = {this.props.language}
                    onAddDisplay   = {this.props.onAddDisplay}
                />

                <EffectivenessValuesOptions
                    visible      = {this.state.topic === 3}
                    onAddDisplay = {this.props.onAddDisplay}
                    language     = {this.props.language}
                />
                
                {/*<SnapshotDataExpPanel*/}
                {/*    onStateChange               = {this.props.onStateChange}*/}
                {/*    inputModvars                = {this.props.inputModvars}*/}
                {/*    exploreSnapshotContent      = {this.props.exploreSnapshotContent}*/}
                {/*/>*/}
            </div>
        );
    }
}

export default ExploreLiSTDataSideBarContent;