import React from "react";
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import OKButton from "../buttons/OKButton";

class ErrorDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange     : PropTypes.func,
        message           : PropTypes.string
    };

    static defaultProps = {
        onStateChange     : () => console.log('onStateChange'),
        message           : RS('GB_stErrorMessage')
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({ 
            dialogErrorOpen : false, 
            errorMessage    : ''
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stError')}
                onClose={this.onClose}
                iconType="Error"
                content={
                    <div>
                        {this.props.message}
                    </div>
                }
                actions={
                    <div>
                        <OKButton onClick={this.onClose} />
                    </div>
                }
            />
        );
    }
}

export default ErrorDialog;