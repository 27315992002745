import React from 'react';
import PropTypes from "prop-types";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {getModvarCloneByTag} from "utilities/GB/GBUtil";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {PercExposedFalciparumEditor} from "../../data/HealthStatus/MaternalHealth/PercExposedFalciparumEditor";

class CSMaternalHealthDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object, 
        packTable3                : PropTypes.object,
        packTable4                : PropTypes.object,
        packTable5                : PropTypes.object,
        onPackTable1Change        : PropTypes.func,
        onPackTable2Change        : PropTypes.func, 
        onPackTable3Change        : PropTypes.func,
        onPackTable4Change        : PropTypes.func,
        onPackTable5Change        : PropTypes.func,
        onCheckBoxClick           : PropTypes.func,
        CSModvars                 : PropTypes.array,
        passData                  : PropTypes.func,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        packTable3                : null,
        packTable4                : null,
        packTable5                : null,
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change        : (packTable) => console.log('onPackTable2Change'),
        onPackTable3Change        : (packTable) => console.log('onPackTable3Change'),
        onPackTable4Change        : (packTable) => console.log('onPackTable4Change'),
        onPackTable5Change        : (packTable) => console.log('onPackTable4Change'),
        onCheckBoxClick           : (value) => console.log('onCheckBoxClick'),
        CSModvars                 : [],
        passData                  : () => console.log('passData'),
    };

    state = {
        IPTpCBChecked : false,
    };

    componentDidMount() {
        let IPTpCBChecked = getModvarCloneByTag(this.props.CSModvars, CONSTANTS.CS_TG_IPTpCBChecked_MV2)["value"];
        this.setState({IPTpCBChecked: IPTpCBChecked},
            () => this.props.passData({IPTpCBChecked: IPTpCBChecked})
        );
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable); 
    };

    onPackTable2Change = (packTable) => {
        this.props.onPackTable2Change(packTable);  
    };

    onPackTable3Change = (packTable) => {
        this.props.onPackTable3Change(packTable);  
    };

    onPackTable4Change = (packTable) => {
        this.props.onPackTable4Change(packTable);
    };

    onPackTable5Change = (packTable) => {
        this.props.onPackTable5Change(packTable);
    };

    onCheckBoxClick = (value) => {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        packTable1 = PercExposedFalciparumEditor.onCheckBoxClick(packTable1, value);
        this.onPackTable1Change(packTable1);
        this.setState({IPTpCBChecked: value},
            () => this.props.passData({IPTpCBChecked: value, saveBtnDisabled : false})
        );
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));
        let packTable3 = JSON.parse(JSON.stringify(this.props.packTable3));
        let packTable4 = JSON.parse(JSON.stringify(this.props.packTable4));
        let packTable5 = JSON.parse(JSON.stringify(this.props.packTable5));

        const styles = {
            checkBoxLabelStyle: {
                fontSize: '16px',
                color: '#609cd9'
            },
        };

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {4}
                />

                <TCheckBox
                    style={styles.checkBox}
                    caption={RS('GB_stIPTp')}
                    tooltip={""}
                    labelStyle={styles.checkBoxLabelStyle}
                    value={this.state.IPTpCBChecked}
                    onClick={this.onCheckBoxClick}
                />

                <br />

                <CSEditor
                    mstID={CONSTANTS.CS_MstIPTpSheetID}
                    packTable={packTable1}
                    showTitle={true}
                    onChange={this.onPackTable1Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstMatNutrDefSheetID}
                    packTable={packTable2}
                    showTitle={true}
                    onChange={this.onPackTable2Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstMaternalAnemiaSheetID}
                    packTable={packTable3}
                    showTitle={true}
                    onChange={this.onPackTable3Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstMatAnemiaAFSheetID}
                    packTable={packTable4}
                    showTitle={true}
                    onChange={this.onPackTable4Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstPercWom15t49LowBMISheetID}
                    packTable={packTable5}
                    showTitle={true}
                    onChange={this.onPackTable5Change}
                />

            </div>
        );
    }

}

export default CSMaternalHealthDrawer;