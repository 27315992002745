import RS from "@common/strings/RS";
import {
    createSimpleDefaultPackTable,
    GB_CONSTANTS,
    getModvarCloneByTag,
    CONSTANTS,
    getCalcYearIdx,
    Get_FP_AgeName,
    Get_FP_UseSingleAgesValue, 
    generatePackChart,
    addComment
} from "../../../../../results/CS/data/imports.js";

export const FPProximateDeterminantsEditor = {
    Create : (DPModvars, projInfo, ageRange, forCard = false, cardOption= 0) => {
        let CalcYear = projInfo.calcYear;
        let FinalYear = projInfo.finalYear;

        let useSingleAges = Get_FP_UseSingleAgesValue(DPModvars);
        let abortionOptionValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TAbortionOption_MV)['value'];
        abortionOptionValue = 1;
        let editorName = RS('GB_stProximateDeterminants');
        let packTable;

        if (forCard) {
            packTable = CardSetUp(useSingleAges, CalcYear, FinalYear);
            packTable = CardSetSSData(packTable, DPModvars, projInfo, useSingleAges, abortionOptionValue, cardOption);
            packTable.Title = editorName;
            return (packTable);
        }
        else {
            packTable = Setup(useSingleAges, abortionOptionValue, ageRange, CalcYear, FinalYear);
            packTable = SetSSData(packTable, DPModvars, projInfo, useSingleAges, abortionOptionValue, ageRange);
            packTable = FPProximateDeterminantsEditor.RecalcGrid(packTable);
            packTable.useSubHeadersInChartLbls = true;
            let packChart = generatePackChart(packTable);
            packTable.Title = editorName;
            return ([packChart, packTable]);
        }
    },

    GetSSData : (pt, DPModvars, projInfo, useSingleAges, abortionOptionValue, ageRange) => {
        let changedModvars = [];

        let PercentInUnionModvar = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TPercentInUnion_MV);
        let PPIModvar = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TPPI_MV);
        let TARModvar = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TTAR_MV);
        let AbortionPercentModvar = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TAbortionPercent_MV);
        let SterilityModvar = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TSterility_MV);

        let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
        let CalcYear = projInfo.calcYear;
        let FinalYear = projInfo.finalYear;
        let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
        let col;

        let age = CONSTANTS.FP_All_Ages;
        if (!useSingleAges) {
            age = ageRange;
        }

        col = 1;
        for (let t = calcYearIndex; t <= finalYearIndex; t++) {
            PercentInUnionModvar['value'][age][t] = pt.tableData.value[1][col];
            PPIModvar['value'][age][t] = pt.tableData.value[2][col];
            if (abortionOptionValue === CONSTANTS.FP_specify_TAR) {
                TARModvar['value'][age][t] = pt.tableData.value[3][col];
            }
            else {
                AbortionPercentModvar['value'][age][t] = pt.tableData.value[3][col];
            }
            SterilityModvar['value'][age][t] = pt.tableData.value[4][col];

            col++;
        }

        changedModvars.push(PercentInUnionModvar);
        changedModvars.push(PPIModvar);
        changedModvars.push(TARModvar);
        changedModvars.push(AbortionPercentModvar);
        changedModvars.push(SterilityModvar);

        return (changedModvars);
    },

    RecalcGrid : (pt) => {
        return (pt);
    },
};

const Setup = (useSingleAges, abortionOptionValue, ageRange, CalcYear, FinalYear) => {
    let numRows = 5;
    let numCols = FinalYear - CalcYear + 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    let col = 1;
    for (let i = CalcYear; i <= FinalYear; i++) {
        pt.tableData.value[0][col] = i.toString();
        col++;
    }

    for (let r = 1; r < numRows; r++) {
        for (let c = 1; c < numCols; c++) {
            pt.RDec[r][c] = 1;
            pt.MaxAllowedVal[r][c] = 100;
            pt.MinAllowedVal[r][c] = 0;
            pt.Alignments[r][c] = GB_CONSTANTS.Right;
        }
        pt.FontStyles[r][0]["intArray"] = [GB_CONSTANTS.Bold];
    }

    if (useSingleAges) {
        pt.tableData.value[0][0] = RS('GB_stAge') + ': 15-49';
        pt.tableData.value[1][0] = RS('GB_stPercWomen15_49InUnion')
    }
    else {
        pt.tableData.value[0][0] = RS('GB_stAge') + ': ' + Get_FP_AgeName(ageRange);
        pt.tableData.value[1][0] = RS('GB_stPercWomen') + ' ' + Get_FP_AgeName(ageRange) + ' ' + RS('GB_stInUnionLower');
    }

    pt.tableData.value[2][0] = RS('GB_stPostPartInsus')

    if (abortionOptionValue === CONSTANTS.FP_specify_TAR) {
        pt.tableData.value[3][0] = RS('GB_stTotalAbortionRate')
    }
    else {
        pt.tableData.value[3][0] = RS('GB_stUnintendPregTerminated')
    }

    pt.tableData.value[4][0] = RS('GB_stSterilityPercent')

    pt = addComment(pt, 2, 0, RS('GB_stPostPartInsusHint'));
    // pt = addComment(pt, 3, 0, RS('GB_stPercZincDef'));
    
    if (useSingleAges) {
        pt = addComment(pt, 3, 0, RS('GB_stTARSingleAgesComment'));
    }
    else {
        pt = addComment(pt, 3, 0, RS('GB_stTARFiveYrAgeGroupsComment'));
    }

    pt = addComment(pt, 4, 0, RS('GB_stPrimarySterilityHint'));

    return pt;
};

const SetSSData = (pt, DPModvars, projInfo, useSingleAges, abortionOptionValue, ageRange) => {
    let PercentInUnionValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TPercentInUnion_MV)['value'];
    let PPIValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TPPI_MV)['value'];
    let TARValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TTAR_MV)['value'];
    let AbortionPercentValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TAbortionPercent_MV)['value'];
    let SterilityValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TSterility_MV)['value'];

    let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
    let CalcYear = projInfo.calcYear;
    let FinalYear = projInfo.finalYear;
    let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
    let col;

    let age = CONSTANTS.FP_All_Ages;
    if (!useSingleAges) {
        age = ageRange;
    }

    col = 1;
    for (let t = calcYearIndex; t <= finalYearIndex; t++) {
        pt.tableData.value[1][col] = PercentInUnionValue[age][t];
        pt.tableData.value[2][col] = PPIValue[age][t];
        if (abortionOptionValue === CONSTANTS.FP_specify_TAR) {
            pt.tableData.value[3][col] = TARValue[age][t];
        }
        else {
            pt.tableData.value[3][col] = AbortionPercentValue[age][t];
        }
        pt.tableData.value[4][col] = SterilityValue[age][t];
        
        for (let row = 1; row <= 4; row++) {
            if (pt.tableData.value[row][col] < 0) {
                pt.tableData.value[row][col] = '';
            }
        }

        col++;
    }

    return pt;
};

const CardSetUp = (useSingleAges, CalcYear, FinalYear) => {
    let numRows = (useSingleAges) ? 2 : 8;
    let numCols = FinalYear - CalcYear + 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    let col = 1;
    for (let i = CalcYear; i <= FinalYear; i++) {
        pt.tableData.value[0][col] = i.toString();
        col++;
    }

    if (useSingleAges) {
        pt.tableData.value[1][0] = '15-49';
    }
    else {
        for (let a = CONSTANTS.FP_A15_19; a <= CONSTANTS.FP_MAX_AGE; a++) {
            pt.tableData.value[a][0] = Get_FP_AgeName(a);
        }
    }

    return pt;
};

const CardSetSSData = (pt, DPModvars, projInfo, useSingleAges, abortionOptionValue, cardOption) => {
    let PercentInUnionValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TPercentInUnion_MV)['value'];
    let PPIValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TPPI_MV)['value'];
    let TARValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TTAR_MV)['value'];
    // let AbortionPercentValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TAbortionPercent_MV)['value'];
    let AbortionPercentValue = CONSTANTS.FP_specify_TAR;

    let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
    let CalcYear = projInfo.calcYear;
    let FinalYear = projInfo.finalYear;
    let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
    let col;

    if (useSingleAges) {
        col = 1;
        for (let t1 = calcYearIndex; t1 <= finalYearIndex; t1++) {
            switch (cardOption) {
                case CONSTANTS.FP_InUnion : pt.tableData.value[1][col] = PercentInUnionValue[CONSTANTS.FP_All_Ages][t1]; break;
                case CONSTANTS.FP_Insusceptibility : pt.tableData.value[1][col] = PPIValue[CONSTANTS.FP_All_Ages][t1]; break;
                case CONSTANTS.FP_Unintended : {
                    if (abortionOptionValue === CONSTANTS.FP_specify_TAR) {
                        pt.tableData.value[1][col] = TARValue[CONSTANTS.FP_All_Ages][t1];
                    }
                    else {
                        pt.tableData.value[1][col] = AbortionPercentValue[CONSTANTS.FP_All_Ages][t1];
                    }
                    break;
                }
                default : break;
            }
            col++;
        }
    }
    else {
        col = 1;
        for (let t2 = calcYearIndex; t2 <= finalYearIndex; t2++) {
            for (let a = CONSTANTS.FP_A15_19; a <= CONSTANTS.FP_A45_49; a++) {
                switch (cardOption) {
                    case CONSTANTS.FP_InUnion : pt.tableData.value[a][col] = PercentInUnionValue[a][t2]; break;
                    case CONSTANTS.FP_Insusceptibility : pt.tableData.value[a][col] = PPIValue[a][t2]; break;
                    case CONSTANTS.FP_Unintended : {
                        if (abortionOptionValue === CONSTANTS.FP_specify_TAR) {
                            pt.tableData.value[a][col] = TARValue[a][t2];
                        }
                        else {
                            pt.tableData.value[a][col] = AbortionPercentValue[a][t2];
                        }
                        break;
                    }
                    default : break;
                }
            }
            col++;
        }
    }

    return pt;
};

