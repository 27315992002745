import * as React from 'react';
import PropTypes from 'prop-types';
import CONSTANTS from "utilities/CS/CSConst";
import TAPIDialog from '../../api/TAPIDialog';
import AboutDialog from "./AboutDialog";
import AccountCreateDialog from "./AccountCreateDialog";
import AccountUpdateDialog from "./AccountUpdateDialog";
import ActionsDialog from "./ActionsDialog";
import CloseProjectionDialog from "./CloseProjectionDialog";
import DeletionCheckDialog from "./DeletionCheckDialog";
import EditTagsDialog from "./EditTagsDialog";
import ErrorDialog from "./ErrorDialog";
import TCSCopiedFileNameDialog from "./TCSCopiedFileNameDialog";
import TCSRenamedFileDialog from "./TCSRenamedFileDialog";
import FilterTagsDialog from "./FilterTagsDialog";
import ForgottenPasswordDialog from "./ForgottenPasswordDialog";
import LanguageDialog from "./LanguageDialog";
import LogInDialog from './LogInDialog';
import MultiplicationDialog from "./MultiplicationDialog";
import NewTagDialog from "./NewTagDialog";
import TCSNotLoggedInDialog from "./TCSNotLoggedInDialog";
import TCSNutritionDialog from "./TCSNutritionDialog";
import TCSProjCreatedDialog from "./TCSProjCreatedDialog";
import ProjInfoDialog from "./ProjInfoDialog";
import SaveAsDialog from "./SaveAsDialog";
import SubnatCheckDialog from "./SubnatCheckDialog";
import WarningDialog from "./WarningDialog";
import FileAlreadyExistsDialog from "./FileAlreadyExistsDialog";
import FileWithSameNameDialog from "./FileWithSameNameDialog";
import SupportDialog from './SupportDialog';
import ServerAboutDialog from "./ServerAboutDialog";
import Theme from '../../app/Theme';
import PrivacyDialog from "./PrivacyDialog";

class AppDialogs extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                           : PropTypes.func,
        onPageChange                            : PropTypes.func,
        onGetTopicList                          : PropTypes.func,
        onAddTasks                              : PropTypes.func,
        onSaveProjection                        : PropTypes.func,
        onCloseProjection                       : PropTypes.func,
        onContinueWorking                       : PropTypes.func,
        onCreateProjectionFromGuidedExploration : PropTypes.func,
        onSaveLanguage                          : PropTypes.func,
        onCopyProjection                        : PropTypes.func,
        onRenameProjection                      : PropTypes.func,
        onOpenProjection                        : PropTypes.func,
        onDeleteProjection                      : PropTypes.func,
        onDownloadProjection                    : PropTypes.func,
        onSaveTopic                             : PropTypes.func,

        dialogAPIOpen                           : PropTypes.bool,
        dialogAboutOpen                         : PropTypes.bool,
        dialogServerAboutOpen                   : PropTypes.bool,
        dialogNewSupportOpen                    : PropTypes.bool,
        dialogAccountCreateOpen                 : PropTypes.bool,
        dialogAccountUpdateOpen                 : PropTypes.bool,
        dialogActionsOpen                       : PropTypes.bool,
        dialogCloseOpen                         : PropTypes.bool,
        dialogDeletionCheckOpen                 : PropTypes.bool,
        dialogEditTagsOpen                      : PropTypes.bool,
        dialogErrorOpen                         : PropTypes.bool,
        dialogFileCopyOpen                      : PropTypes.bool,
        dialogFileRenameOpen                    : PropTypes.bool,
        dialogFilterTagsOpen                    : PropTypes.bool,
        dialogForgotPasswordOpen                : PropTypes.bool,
        dialogLanguageOpen                      : PropTypes.bool,
        dialogLogInOpen                         : PropTypes.bool,
        dialogMultiplicationOpen                : PropTypes.bool,
        dialogNewTagOpen                        : PropTypes.bool,
        dialogNotLoggedInOpen                   : PropTypes.bool,
        dialogNutritionOpen                     : PropTypes.bool,
        dialogPrivacyOpen                       : PropTypes.bool,
        dialogProjCreatedOpen                   : PropTypes.bool,
        dialogProjInfoOpen                      : PropTypes.bool,
        dialogSaveAsOpen                        : PropTypes.bool,
        dialogSubnatCheckOpen                   : PropTypes.bool,
        dialogWarningOpen                       : PropTypes.bool,
        dialogFileAlreadyExistsOpen             : PropTypes.bool,
        dialogFileWithSameNameOpen              : PropTypes.bool,

        errorMessage                            : PropTypes.string,
        warningMessage                          : PropTypes.string,
        apiTasks                                : PropTypes.array,
        isGuest                                 : PropTypes.bool,
        version                                 : PropTypes.object,
        explanationText                         : PropTypes.string,
        selectedCountry                         : PropTypes.string,
        countryISO                              : PropTypes.number,
        projectCreated                          : PropTypes.bool,
        language                                : PropTypes.number,
        fileToCopy                              : PropTypes.string,
        fileToRename                            : PropTypes.string,
        topicList                               : PropTypes.array,
        chosenTags                              : PropTypes.string,
        fileToTag                               : PropTypes.string,
        fileForActions                          : PropTypes.string,
        actionNotes                             : PropTypes.string,
        fileToDelete                            : PropTypes.string,
        topicFilter                             : PropTypes.array,
        projectionName                          : PropTypes.string,
        pageID                                  : PropTypes.string,
        storedProjections                       : PropTypes.array,
        status                                  : PropTypes.object,
        apiMainMsg                              : PropTypes.string,
        apiSubMsg                               : PropTypes.string,
        prevProjName                            : PropTypes.string,
        lastHeartBeat                           : PropTypes.string,
        userName                                : PropTypes.string,
        humanName                               : PropTypes.string,
        TimeToLive                              : PropTypes.string,
        TimeIdle                                : PropTypes.string,
        TimeExpire                              : PropTypes.string,
        TimeTotal                               : PropTypes.string
    };

    static defaultProps = {
        onStateChange                           : (value) => console.log('onStateChange : ' + JSON.stringify(value)),
        onPageChange                            : () => console.log("onPageChange"),
        onGetTopicList                          : () => console.log('onGetTopicList'),
        onAddTasks                              : () => console.log('onAddTasks'),
        onSaveProjection                        : () => console.log('onSaveProjection'),
        onCloseProjection                       : () => console.log('onCloseProjection'),
        onContinueWorking                       : () => console.log('onContinueWorking'),
        onCreateProjectionFromGuidedExploration : () => console.log('onCreateProjection'),
        onSaveLanguage                          : () => console.log('onSaveLanguage'),
        onCopyProjection                        : () => console.log('onCopyProjection'),
        onRenameProjection                      : () => console.log('onRenameProjection'),
        onOpenProjection                        : () => console.log("onOpenProjection"),
        onDeleteProjection                      : () => console.log("onDeleteProjection"),
        onDownloadProjection                    : () => console.log("onDownloadProjection"),
        onSaveTopic                             : () => console.log("onSaveTopic"),

        dialogAPIOpen                           : false,
        dialogAboutOpen                         : false,
        dialogServerAboutOpen                   : false,
        dialogNewSupportOpen                    : false,
        dialogAccountCreateOpen                 : false,
        dialogAccountUpdateOpen                 : false,
        dialogActionsOpen                       : false,
        dialogCloseOpen                         : false,
        dialogDeletionCheckOpen                 : false,
        dialogEditTagsOpen                      : false,
        dialogErrorOpen                         : false,
        dialogFileCopyOpen                      : false,
        dialogFileRenameOpen                    : false,
        dialogFilterTagsOpen                    : false,
        dialogForgotPasswordOpen                : false,
        dialogLanguageOpen                      : false,
        dialogLogInOpen                         : false,
        dialogMultiplicationOpen                : false,
        dialogNewTagOpen                        : false,
        dialogNotLoggedInOpen                   : false,
        dialogNutritionOpen                     : false,
        dialogPrivacyOpen                       : false,
        dialogProjCreatedOpen                   : false,
        dialogProjInfoOpen                      : false,
        dialogSaveAsOpen                        : false,
        dialogSubnatCheckOpen                   : false,
        dialogWarningOpen                       : false,
        dialogFileAlreadyExistsOpen             : false,
        dialogFileWithSameNameOpen              : false,

        errorMessage                            : 'Error: Unknown',
        warningMessage                          : 'Warning: Unknown',
        apiTasks                                : [],
        isGuest                                 : true,
        version                                 : {},
        explanationText                         : '',
        selectedCountry                         : "Afghanistan",
        countryISO                              : 4,
        projectCreated                          : false,
        language                                : CONSTANTS.GB_English,
        fileToCopy                              : "",
        fileToRename                            : "",
        topicList                               : [],
        chosenTags                              : "",
        fileToTag                               : "",
        fileForActions                          : "",
        actionNotes                             : "",
        fileToDelete                            : "",
        topicFilter                             : [],
        projectionName                          : "",
        pageID                                  : "",
        storedProjections                       : [],
        status                                  : {},
        apiMainMsg                              : {},
        apiSubMsg                               : {},
        prevProjName                            : "",
        lastHeartBeat                           : "",
        userName                                : "",
        humanName                               : "",
        TimeToLive                              : "",
        TimeIdle                                : "",
        TimeExpire                              : "",
        TimeTotal                               : ""
    };

    state = {
        newTag: ""
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        return (
            <div>
                {
                    (this.props.apiTasks.filter(x => x.running).filter(x => (x.flag !== 'getStatus')).length > 0) ?
                        <TAPIDialog
                            runningTasks    = {this.props.apiTasks.filter(x => x.running).filter(x => (x.flag !== 'getStatus')).map(x => x.flag) }
                            selectedCountry = {this.props.selectedCountry}
                            status          = {this.props.status}
                            apiMainMsg      = {this.props.apiMainMsg}
                            apiSubMsg       = {this.props.apiSubMsg}
                            language        = {this.props.language}
                        /> : null
                }

                {
                    (this.props.dialogAboutOpen) ?
                        <AboutDialog
                            onStateChange = {this.props.onStateChange}
                            onAddTasks    = {this.props.onAddTasks}
                            version       = {this.props.version}
                        /> : null
                }

                {this.props.dialogServerAboutOpen ? (
                    <ServerAboutDialog
                        version={this.props.version}
                        onAddTasks={this.props.onAddTasks}
                        onClose={() => this.props.onStateChange({dialogServerAboutOpen: false})}
                        Theme={Theme}
                        signedIn={this.props.signedIn}
                        userName={this.props.userName}
                        humanName={this.props.humanName}
                        isGuest={this.props.isGuest}
                        sessionExists={this.props.sessionExists}
                        disconnectWarning={this.props.disconnectWarning}
                        TimeToLive={this.props.TimeToLive}
                        TimeIdle={this.props.TimeIdle}
                        TimeExpire={this.props.TimeExpire}
                        TimeTotal={this.props.TimeTotal}
                        disconnecting={this.props.disconnecting}
                        status={this.props.status}
                        lastHeartBeat={this.props.lastHeartBeat}
                    />
                ) : null}

                {
                    (this.props.dialogNewSupportOpen) ?
                        <SupportDialog
                            onStateChange = {this.props.onStateChange}
                        /> : null
                }

                {
                    (this.props.dialogAccountCreateOpen) ?
                        <AccountCreateDialog 
                            isGuest       = {this.props.isGuest}
                            onStateChange = {this.props.onStateChange}
                            onAddTasks    = {this.props.onAddTasks}
                        /> : null
                }

                {
                    (this.props.dialogAccountUpdateOpen) ?
                        <AccountUpdateDialog
                            isGuest       = {this.props.isGuest}
                            onStateChange = {this.props.onStateChange}
                            onAddTasks    = {this.props.onAddTasks}
                        /> : null
                }

                {
                    (this.props.dialogActionsOpen) ?
                        <ActionsDialog
                            onStateChange         = {this.props.onStateChange}
                            onSaveTopic           = {this.props.onSaveTopic}
                            topicList             = {this.props.topicList}
                            fileForActions        = {this.props.fileForActions}
                            actionNotes           = {this.props.actionNotes}
                            chosenTags            = {this.props.chosenTags}
                            onOpenProjection      = {this.props.onOpenProjection}
                            onDeleteProjection    = {this.props.onDeleteProjection}
                            onDownloadProjection  = {this.props.onDownloadProjection}
                            onRenameProjection    = {this.props.onRenameProjection}
                            onCopyProjection      = {this.props.onCopyProjection}
                            newTag                = {this.state.newTag}
                            passNewTag            = {(val) => this.setState({newTag: val})}
                        /> : null
                }

                {
                    (this.props.dialogCloseOpen) ?
                        <CloseProjectionDialog
                            onStateChange     = {this.props.onStateChange}
                            onCloseProjection = {this.props.onCloseProjection}
                        /> : null
                }

                {
                    (this.props.dialogDeletionCheckOpen) ?
                        <DeletionCheckDialog
                            onStateChange         = {this.props.onStateChange}
                            onDeleteProjection    = {this.props.onDeleteProjection}
                            fileToDelete          = {this.props.fileToDelete}
                        /> : null
                }

                {
                    (this.props.dialogEditTagsOpen) ?
                        <EditTagsDialog
                            onStateChange      = {this.props.onStateChange}
                            visible            = {true}
                            topicList          = {this.props.topicList}
                            onSaveTopic        = {this.props.onSaveTopic}
                            chosenTags         = {this.props.chosenTags}
                            fileToTag          = {this.props.fileToTag}
                        /> : null
                }

                {
                    (this.props.dialogErrorOpen) ?
                        <ErrorDialog
                            onStateChange = {this.props.onStateChange}
                            message       = {this.props.errorMessage}
                        /> : null
                }

                {
                    (this.props.dialogFileCopyOpen) ?
                        <TCSCopiedFileNameDialog
                            onStateChange    = {this.props.onStateChange}
                            visible          = {true}
                            fileToCopy       = {this.props.fileToCopy}
                            onCopyProjection = {this.props.onCopyProjection}
                        /> : null
                }

                {
                    (this.props.dialogFileRenameOpen) ?
                        <TCSRenamedFileDialog
                            onStateChange      = {this.props.onStateChange}
                            fileToRename       = {this.props.fileToRename}
                            onRenameProjection = {this.props.onRenameProjection}
                        /> : null
                }

                {
                    (this.props.dialogFilterTagsOpen) ?
                        <FilterTagsDialog
                            onStateChange      = {this.props.onStateChange}
                            visible            = {true}
                            topicList          = {this.props.topicList}
                            topicFilter        = {this.props.topicFilter}
                        /> : null
                }

                {
                    (this.props.dialogForgotPasswordOpen) ?
                        <ForgottenPasswordDialog
                            onStateChange = {this.props.onStateChange}
                            onAddTasks    = {this.props.onAddTasks}
                        /> : null
                }

                {
                    (this.props.dialogLanguageOpen) ?
                        <LanguageDialog
                            onStateChange  = {this.props.onStateChange}
                            language       = {this.props.language}
                            onSaveLanguage = {this.props.onSaveLanguage}
                        /> : null
                }

                {
                    (this.props.dialogLogInOpen) ?
                        <LogInDialog
                            onStateChange={this.props.onStateChange}
                            onAddTasks={this.props.onAddTasks}
                            isGuest={this.props.isGuest}
                            onGetTopicList={this.props.onGetTopicList}
                            onPageChange={this.props.onPageChange}
                            projectCreated={this.props.projectCreated}
                            pageID={this.props.pageID}
                            storedProjections={this.props.storedProjections}
                        /> : null
                }

                {
                    (this.props.dialogMultiplicationOpen) ?
                        <MultiplicationDialog
                            onStateChange  = {this.props.onStateChange}
                        /> : null
                }

                {
                    (this.props.dialogNewTagOpen) ?
                        <NewTagDialog
                            fileForActions        = {this.props.fileForActions}
                            onSaveTopic           = {this.props.onSaveTopic}
                            passNewTag            = {(val) => this.setState({newTag: val})}
                            onStateChange         = {this.props.onStateChange}
                        /> : null
                }

                {
                    (this.props.dialogNotLoggedInOpen) ?
                        <TCSNotLoggedInDialog  
                            onStateChange = {this.props.onStateChange}
                        /> : null
                }

                {
                    (this.props.dialogNutritionOpen) ?
                        <TCSNutritionDialog
                            onStateChange = {this.props.onStateChange}
                        /> : null
                }

                {
                    (this.props.dialogPrivacyOpen) ?
                        <PrivacyDialog
                            onStateChange = {this.props.onStateChange}
                        /> : null
                }

                {
                    (this.props.dialogProjCreatedOpen) ?
                        <TCSProjCreatedDialog
                            isGuest           = {this.props.isGuest}
                            explanationText   = {this.props.explanationText}
                            onStateChange     = {this.props.onStateChange}
                            onSaveProjection  = {this.props.onSaveProjection}
                            onCloseProjection = {this.props.onCloseProjection}
                            onContinueWorking = {this.props.onContinueWorking}
                            projectionName    = {this.props.projectionName}
                        /> : null
                }

                {
                    (this.props.dialogProjInfoOpen) ?
                        <ProjInfoDialog
                            selectedCountry                         = {this.props.selectedCountry}
                            countryISO                              = {this.props.countryISO}
                            projectCreated                          = {this.props.projectCreated}
                            onStateChange                           = {this.props.onStateChange}
                            onCreateProjectionFromGuidedExploration = {this.props.onCreateProjectionFromGuidedExploration}
                         /> : null
                }

                {
                    (this.props.dialogSaveAsOpen) ?
                        <SaveAsDialog
                            onStateChange    = {this.props.onStateChange}
                            onSaveProjection = {this.props.onSaveProjection}
                        /> : null
                }

                {
                    (this.props.dialogFileAlreadyExistsOpen) ?
                        <FileAlreadyExistsDialog
                            onStateChange    = {this.props.onStateChange}
                            onSaveProjection = {this.props.onSaveProjection}
                            prevProjName     = {this.props.prevProjName}
                        /> : null
                }

                {
                    (this.props.dialogFileWithSameNameOpen) ?
                        <FileWithSameNameDialog
                            onStateChange    = {this.props.onStateChange}
                        /> : null
                }

                {
                    (this.props.dialogSubnatCheckOpen) ?
                        <SubnatCheckDialog
                            onStateChange={this.props.onStateChange}
                            onYes={() => {}}
                            onNo={() => {}}
                        /> : null
                }

                {
                    (this.props.dialogWarningOpen) ?
                        <WarningDialog
                            message       = {this.props.warningMessage}
                            onStateChange = {this.props.onStateChange}
                        /> : null
                }
            </div>
        );
    }
}

export default AppDialogs;