import React from 'react';
import PropTypes from "prop-types";
import Theme from "../../app/Theme";
import Drawer from "@material-ui/core/Drawer";
import IconButton from '@material-ui/core/IconButton';
import PlayArrow from "@material-ui/icons/PlayArrow";
import CONSTANTS from "utilities/CS/CSConst";

class AppSideBarPlaceholder extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        appMode: PropTypes.string,
        onHamburgerClick: PropTypes.func
    };

    static defaultProps = {
        open: false,
        appMode: "ProjectionMode",
        onHamburgerClick: () => console.log("onHamburgerClick")
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    render() {
        let styles = {
            btn: {
                color: this.props.appMode === CONSTANTS.CS_ProjectionMode ? "white" : "rgb(5, 34, 93)",
                zIndex: 100,
                cursor: "pointer",
                margin: 'auto',
                marginTop:90,
                padding:0,
                display: 'block'
               // border:this.props.appMode === CONSTANTS.CS_ProjectionMode ? '2px solid white' : "2px solid rgb(5, 34, 93)"
            },
            chevron: {
                fontSize:25,
                fontWeight:"bold"
            },
            drawer: {
                zIndex: Theme.zIndex.drawer,
                background:this.props.appMode === CONSTANTS.CS_ProjectionMode ? "rgb(5, 34, 93)" : "white",
                width: '75px',
                border: 'none',
            },
            sidebar: {
                height: "100%",
                width: "40px",
                // ...(this.props.appMode === CONSTANTS.CS_ProjectionMode ? Theme.standardSideBar : Theme.toolsSideBar)
            }
        };

        const open = 
            this.props.open && 
            (this.props.appMode !== CONSTANTS.CS_MissedOpportunitiesMode) && 
            (this.props.appMode !== CONSTANTS.CS_ExploreLiSTDataMode) &&  
            (this.props.appMode !== CONSTANTS.CS_ExploreLiSTDataModeStep1) && 
            (this.props.appMode !== CONSTANTS.CS_ProjectionMode) && 
            (this.props.appMode !== CONSTANTS.CS_ExtractMode) && 
            (this.props.appMode !== "");

        return (
            <Drawer
                variant="persistent"
                open={open}
                style={styles.drawer}
            >
                <div style={styles.sidebar}>
                    <IconButton
                        style={styles.btn}
                        onClick={this.props.onHamburgerClick}
                    >
                        <PlayArrow
                            style={styles.chevron}
                        />
                    </IconButton>
                </div>
            </Drawer>
        )
    }
}

export default AppSideBarPlaceholder;