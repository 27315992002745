import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";

class CODPercRedDropdown extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange : PropTypes.func,
        content       : PropTypes.arrayOf(PropTypes.object),
        panelName     : PropTypes.string,
        allMstID      : PropTypes.number,
        onChange      : PropTypes.func,
    };

    static defaultProps = {
        onStateChange : () => console.log('onStateChange'),
        content       : [],
        panelName     : '',
        allMstID      : 0,
        onChange      : () => console.log('onChange')
    };

    state = {
        content : JSON.parse(JSON.stringify(this.props.content))
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onHandleDropdownChange = (idx) => {
        let allIdx = 0;
        let panelContent = JSON.parse(JSON.stringify(this.state.content));

        if (idx === allIdx) {
            for (let j = 0; j < panelContent.length; j++) {
                panelContent[j]['checked'] = true;
            }
        }
        else {
            for (let i = 0; i < panelContent.length; i++) {
                panelContent[i]['checked'] = false;
            }
            panelContent[idx]['checked'] = true;
        }

        this.setState ({
            content : panelContent
        });

        this.props.onChange(panelContent);

        return (panelContent)
    };

    getItemIndex = () => {
        let idx = 0;

        for (let i = 0; i < this.state.content.length; i++) {
            if (this.state.content[i]['checked']) {
                idx = i;
                break;
            }
        }

        return (idx)
    };

    getItems = () => {
        let items = [];

        for (let i = 0; i < this.state.content.length; i++) {
            items.push(RS(this.state.content[i]['name']))
        }

        return (items)
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderDropdown = () => {

        const styles = {
            comboBox: {
                marginRight: 10,
                marginBottom: 10,
                paddingLeft: 5,
                width: 240,
                display: 'inline-block',
                color: '#05225d',
                fontSize: '14px',
                borderRadius: 4,
                border: '1px solid lightgray',
                borderBlockEndColor: '#f78c30'
            },
            captionStyle: {
                marginTop: '-3px',
                color: '#cb6814',
                fontWeight: 'bold'
            }
        };

        return (
            <TComboBox
                caption      = {this.props.panelName}
                itemIndex    = {this.getItemIndex()}
                onChange     = {this.onHandleDropdownChange}
                style        = {styles.comboBox}
                captionStyle = {styles.captionStyle}
                items        = {this.getItems()}
            >
            </TComboBox>
        )
    };

    render() {
        return (
            <div>
                {this.renderDropdown()}
            </div>
        );
    }

}

export default CODPercRedDropdown;