import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Drawer from "@material-ui/core/Drawer/Drawer";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MaximizeIcon from '@material-ui/icons/WebAsset';
import MinimizeIcon from '@material-ui/icons/Remove';
import HelpIcon from '@material-ui/icons/Help';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Tooltip from '@material-ui/core/Tooltip';
import DotsMenu from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import RS from "@common/strings/RS";
import TDownloadBtn from './TDownloadBtn';
import TButton from './TButton';
import CONSTANTS from "utilities/CS/CSConst";

class TDrawerWithHeader extends React.Component {

    static propTypes = {
        open                    : PropTypes.bool,
        appBarText              : PropTypes.string,
        downloadTitle           : PropTypes.string,
        appBarTextStyle         : PropTypes.object,
        onClose                 : PropTypes.func.isRequired,
        content                 : PropTypes.any,
        onSave                  : PropTypes.func,
        showSaveBtn             : PropTypes.bool,
        showExtraBtn            : PropTypes.bool,
        extraBtnCaption         : PropTypes.string,
        contentExtraBtn         : PropTypes.any,
        contentSwitch           : PropTypes.any,
        retroSwitch             : PropTypes.any,
        whoWasClicked           : PropTypes.string,
        saveBtnDisabled         : PropTypes.bool,
        saveBtnCaption          : PropTypes.string,
        showDisplayOptions      : PropTypes.bool,
        contentDisplayOptions   : PropTypes.any,
        drawerWidth             : PropTypes.string,
        isDeskTop               : PropTypes.bool,
        hideMenu                : PropTypes.bool,
        preventScroll           : PropTypes.bool,
        showDownload            : PropTypes.bool,
        chart                   : PropTypes.any,
        chartType               : PropTypes.string,
        tableRef                : PropTypes.any,
        onDownloadChart         : PropTypes.func,
        onDownloadTable         : PropTypes.func,
        onOpenHelpDialog        : PropTypes.func,
        helpFile                : PropTypes.string,
        showHelp                : PropTypes.bool,
        paren                   : PropTypes.string
       // passData                : PropTypes.func
    };

    static defaultProps = {
        onClose                 : () => console.log('onClose'),
        onSave                  : () => console.log('onSave'),
        open                    : true,
        appBarText              : 'Caption',
        downloadTitle           : "Download",
        appBarTextStyle         : { color: '#cb6814',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    marginLeft: '25px',
                                    marginTop: '15px',
                                    position: 'absolute',
                                    userSelect: 'none'},
        content                 : null,
        showSaveBtn             : true,
        showExtraBtn            : false,
        extraBtnCaption         : '',
        contentExtraBtn         : null,
        contentSwitch           : null,
        retroSwitch             : null,
        whoWasClicked           : '',
        saveBtnDisabled         : true,
        saveBtnCaption          : RS("GB_stApply"),
        showDisplayOptions      : false,
        contentDisplayOptions   : null,
        drawerWidth             : '75%',
        isDeskTop               : window.innerWidth > 1075,
        hideMenu                : false,
        preventScroll           : false,
        showDownload            : false,
        chart                   : null,
        chartType               : CONSTANTS.CS_TableChart,
        tableRef                : null,
        onDownloadChart         : (func) => func(() => {}),
        onDownloadTable         : (func) => func(() => {}),
        onOpenHelpDialog        : () => console.log('onOpenHelpDialog'),
        helpFile                : "what_is_list.htm",
        showHelp                : true,
        paren                   : ""
       // passData                : (dat) => console.log(dat)
    };

    state = {
        drawerWidth   : this.props.drawerWidth,
        expanded      : false,
        modalVisible2 : false,
        isDeskTop     : this.props.isDeskTop,
        screenSize    : window.innerWidth
    };

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.hideMenu) {
            this.setState({
                fileAnchor     : null
            });
        }
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onMinimizeDrawer = () => {
        this.setState({
            drawerWidth : this.props.drawerWidth,
            expanded    : false
        });
        window.dispatchEvent(new Event('minMax'));
    };

    onMaximizeDrawer = () => {
        this.setState({
            drawerWidth : '100%',
            expanded    : true
        }, () => this.setState({ }));
        window.dispatchEvent(new Event('minMax'));
    };

    onResize = () => {
        this.setState({isDeskTop: window.innerWidth > 1075, screenSize: window.innerWidth});
    };

    onHelp = () => {
        this.props.onOpenHelpDialog && this.props.onOpenHelpDialog(this.props.helpFile);
        // alert(RS('GB_stNotAvailableYet'));
        // this.handleFileBtnClose();
    };

    onSave = () => {
        this.props.onSave();
        this.handleFileBtnClose();
    };

    onDownloadChart = () => {
        if (this.props.chartType === CONSTANTS.CS_TableChart) {
            console.log(this.props.tableRef);
            this.props.onDownloadTable((func) => {
                this.props.tableRef.current.exportToExcel(this.props.downloadTitle + ".xlsx");
                func();
            });
            // this.props.tableRef.current.exportToExcel(this.props.downloadTitle + ".xlsx");
        } else {
            console.log(this.props.chart.current);
            this.props.onDownloadChart((func) => {
                if (this.props.chart.current.state) {
                    this.props.chart.current.state.chart.exportChart({
                        // type: "image/svg+xml",
                        type: "image/png",
                        // type: "application/pdf",
                        filename: this.props.downloadTitle
                    });
                } else {
                    this.props.chart.current.chart.exportChart({
                        type: "image/png",
                        filename: this.props.downloadTitle
                    });
                }
                func();
            });
        }
    };

    onDownloadMenuItemClick = () => {
        this.handleFileBtnClose();
        this.onDownloadChart();
    };

    handleFileBtnClick = event => {
        this.setState({
            fileAnchor : event.currentTarget
        });
    };

    handleFileBtnClose = () => {
        this.setState ({
            fileAnchor: null
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderDeskTopAppBar = () => {

        const styles = {
            icons: {
                top: '10px',
                padding: '6px',
                float: 'right',
                fill: 'currentColor'
            },
            helpIcon: {
                top: '10px',
                padding: '6px',
                float: 'right',
                fill: 'currentColor',
                color : '#65A7EB'
            },
            saveBtnDiv: {
                marginTop: 5,
                float: 'right',
            },
            extraBtnDiv: {
                marginTop: 5,
                float: 'right',
                marginRight: 15
            },
            closeBtn : {
                top: '10px',
                padding: '6px',
                float: "left",
            },
            appBar : {
                height: 48
            }
        };

        return (
            <AppBar style={styles.appBar} position="sticky" color="inherit">
                <Toolbar style={{display: 'inline', minHeight:48}}>
                    <Tooltip title={RS('GB_stClose')}>
                        <IconButton onClick={this.props.onClose} style={{...styles.closeBtn}}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>

                    <div className={"app_bar_title"} style={this.props.appBarTextStyle}>{this.props.appBarText}</div>

                    {
                        (this.state.expanded) ?
                            <Tooltip title={RS('GB_stMinimize')}>
                                <IconButton onClick={this.onMinimizeDrawer} style={{...styles.icons}}>
                                    {/*<Icon className='fa fa-window-minimize'/>*/}
                                    <MinimizeIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={RS('GB_stMaximize')}>
                                <IconButton onClick={this.onMaximizeDrawer} style={{...styles.icons}}>
                                    <MaximizeIcon />
                                    {/*<Icon className='fa fa-window-maximize'/>*/}
                                </IconButton>
                            </Tooltip>
                    }

                    <Tooltip title={RS('GB_stHelp')}>
                        <IconButton onClick={this.onHelp} style={{...styles.helpIcon}}>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>

                    {
                        (this.props.showSaveBtn) ?

                            <TButton onClick={this.props.onSave} disabled={this.props.saveBtnDisabled}
                                     containerStyle={{...styles.saveBtnDiv}} caption={this.props.saveBtnCaption}/> :
                            null
                    }
                    {
                        (this.props.showExtraBtn) ?
                            this.renderExtraBtn()
                            :
                            null
                    }

                    {
                        (this.props.showDownload) ?
                            this.renderDownloadButton()
                            :
                            null
                    }

                    {
                        (this.props.contentSwitch) ?
                            this.props.contentSwitch
                            :
                            null
                    }

                    {
                        (this.props.retroSwitch) ?
                            this.props.retroSwitch
                            :
                            null
                    }

                    {
                        (this.props.showDisplayOptions) ?
                            this.props.contentDisplayOptions
                            :
                            null
                    }

                </Toolbar>
            </AppBar>
        )
    };

    renderTabletAppBar = () => {
        const styles = {
            icons: {
                top: '12px',
                padding: '6px',
                float: 'right',
                fill: 'currentColor'
            },
            closeBtn : {
                top: '12px',
                padding: '6px',
                float: "left",
            },
            saveBtnDiv: {
                marginTop: 9,
                float: 'right',
            }
        };
        return (
            <AppBar position="sticky" color="inherit">
                <Toolbar style={{display: 'inline'}}>
                    <Tooltip title={RS('GB_stClose')}>
                        <IconButton onClick={this.props.onClose} style={{...styles.closeBtn}}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>

                    <div className={"app_bar_title"}  style={this.props.appBarTextStyle}>{this.props.appBarText}</div>

                    <div style={styles.icons}>{this.renderRightMenuDots()}</div>

                    {
                        (this.state.expanded) ?
                            <Tooltip title={RS('GB_stMinimize')}>
                                <IconButton onClick={this.onMinimizeDrawer} style={{...styles.icons}}>
                                    {/*<Icon className='fa fa-window-minimize'/>*/}
                                    <MinimizeIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={RS('GB_stMaximize')}>
                                <IconButton onClick={this.onMaximizeDrawer} style={{...styles.icons}}>
                                    <MaximizeIcon />
                                    {/*<Icon className='fa fa-window-maximize'/>*/}
                                </IconButton>
                            </Tooltip>
                    }

                    {
                        (this.props.showSaveBtn) ?
                            <TButton onClick={this.props.onSave} disabled={this.props.saveBtnDisabled}
                                     containerStyle={{...styles.saveBtnDiv}} caption={this.props.saveBtnCaption}/> :
                            null
                    }

                    {/*{*/}
                        {/*(this.props.showExtraBtn) ?*/}
                            {/*this.renderExtraBtn()*/}
                            {/*:*/}
                            {/*null*/}
                    {/*}*/}

                    {/*{*/}
                        {/*(this.props.contentSwitch) ?*/}
                            {/*this.props.contentSwitch*/}
                            {/*:*/}
                            {/*null*/}
                    {/*}*/}

                </Toolbar>
            </AppBar>
        )
    };

    renderRightMenuDots = () => {
        const { fileAnchor }  = this.state;
        return (
            <div style={{display: 'inline-block'}}>
                <IconButton
                    aria-label="Menu"
                    aria-owns={fileAnchor ? 'file-menu' : null}
                    aria-haspopup="true"
                    color={'primary'}
                    onClick={this.handleFileBtnClick}
                >
                    <DotsMenu />
                </IconButton>

                <Menu
                    id="file-menu"
                    anchorEl={fileAnchor}
                    open={Boolean(fileAnchor)}
                    onClose={this.handleFileBtnClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                >
                    {
                        (this.props.showDisplayOptions) ?
                            //<MenuItem onClick={() => this.handleFileBtnClose()} style={{height:"auto"}}>
                                this.props.contentDisplayOptions
                            //</MenuItem>
                            :
                            null
                    }

                    <Divider />

                    {
                        (this.props.showExtraBtn) ?
                            <MenuItem style={{height:"auto"}}>{this.renderExtraBtn()}</MenuItem>
                            :
                            null
                    }

                    {
                        (this.props.contentSwitch) ?
                            <MenuItem onClick={() => this.handleFileBtnClose()} style={{height:"auto"}}>{this.props.contentSwitch}</MenuItem>
                            :
                            null
                    }

                    {
                        (this.props.retroSwitch) ?
                            <MenuItem onClick={() => this.handleFileBtnClose()} style={{height:"auto"}}>{this.props.retroSwitch}</MenuItem>
                            :
                            null
                    }

                    {/*{*/}
                    {/*    (this.props.showSaveBtn) ?*/}
                    {/*        <MenuItem onClick={this.onSave}>*/}
                    {/*            {this.props.saveBtnCaption}*/}
                    {/*        </MenuItem> :*/}
                    {/*        null*/}
                    {/*}*/}

                    {
                        (this.props.showDownload) ?
                            <MenuItem onClick={() => this.onDownloadMenuItemClick()} style={{height:"auto"}}>
                                {this.renderDownloadButton()}
                                {RS("GB_stDownload")}
                            </MenuItem>
                            :
                            null
                    }

                    <Divider />

                    <MenuItem onClick={this.onHelp}>
                        <HelpIcon style={{color:"#65A7EB", marginRight: 5}} />
                        {RS('GB_stHelp')}
                    </MenuItem>
                </Menu>
            </div>
        )
    };
    
    renderExtraBtn = () => {
        return (
            this.props.contentExtraBtn
        );
    };

    renderDownloadButton = () => {
        const styles = {
            downloadButton: {
                float: "right",
                marginRight: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 20 : -20,
                marginTop: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 8 : 0,
            },
        };

        return (

            <TDownloadBtn
                style={styles.downloadButton}
                onClick={() => {if (this.state.screenSize >= CONSTANTS.CS_SmScreen) {this.onDownloadChart()}}}
            />

        )
    };

    renderDotMenu = () => {
        const { fileAnchor }  = this.state;
        return (
            <div style={{display: 'inline-block'}}>
                <IconButton
                    aria-label="Menu"
                    aria-owns={fileAnchor ? 'file-menu' : null}
                    aria-haspopup="true"
                    color={'primary'}
                    onClick={this.handleFileBtnClick}
                >
                    <DotsMenu />
                </IconButton>

                <Menu
                    id="file-menu"
                    anchorEl={fileAnchor}
                    open={Boolean(fileAnchor)}
                    onClose={this.handleFileBtnClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                >
                    {
                        (this.props.showDisplayOptions && this.state.screenSize < CONSTANTS.CS_SmScreen) ?
                            //<MenuItem onClick={() => this.handleFileBtnClose()} style={{height:"auto"}}>
                            this.props.contentDisplayOptions
                            //</MenuItem>
                            :
                            null
                    }

                    {/*<Divider />*/}

                    {
                        (this.props.showExtraBtn && this.state.screenSize < CONSTANTS.CS_MdScreen) ?
                            <MenuItem style={{height:"auto"}}>{this.renderExtraBtn()}</MenuItem>
                            :
                            null
                    }

                    {
                        (this.props.contentSwitch) ?
                            <MenuItem onClick={() => this.handleFileBtnClose()} style={{height:"auto"}}>{this.props.contentSwitch}</MenuItem>
                            :
                            null
                    }

                    {
                        (this.props.retroSwitch) ?
                            <MenuItem onClick={() => this.handleFileBtnClose()} style={{height:"auto"}}>{this.props.retroSwitch}</MenuItem>
                            :
                            null
                    }

                    {
                        (this.props.showDownload && this.state.screenSize < CONSTANTS.CS_SmScreen) ?
                            <MenuItem onClick={() => this.onDownloadMenuItemClick()} style={{height:"auto"}}>
                                {this.renderDownloadButton()}
                                {RS("GB_stDownload")}
                            </MenuItem>
                            :
                            null
                    }

                    {/*<Divider />*/}

                    {this.state.screenSize < CONSTANTS.CS_MdScreen ?
                        <MenuItem onClick={this.onHelp}>
                            <HelpIcon style={{color:"#65A7EB", marginRight: 5}} />
                            {RS('GB_stHelp')}
                        </MenuItem>
                    : null}
                </Menu>
            </div>
        )
    };

    renderAppBar = () => {
        const styles = {
            icons: {
                // top: '7px',
                // padding: '6px',
                float: 'right',
                fill: 'currentColor'
            },
            helpIcon : {
                float : 'right',
                fill  : 'currentColor',
                color : '#65A7EB',
            },
            saveBtnDiv: {
                marginTop: 3,
                float: 'right',
            },
            extraBtnDiv: {
                marginTop: 3,
                float: 'right',
                marginRight: 15
            },
            closeBtn : {
                // top: '7px',
                // padding: '6px',
                float: "left",
                marginLeft: -10
            },
            appBar : {
                minHeight: 48
            }
        };

        return (
            <AppBar style={styles.appBar} position="sticky" color="inherit">
                <Toolbar style={{display: 'inline', minHeight:48}}>
                    <Tooltip title={RS('GB_stClose')}>
                        <IconButton onClick={this.props.onClose} style={{...styles.closeBtn}}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>

                    <div className={"app_bar_title"} style={{...this.props.appBarTextStyle, ...{overflow: "hidden", whiteSpace: "nowrap", display: "block", marginLeft: 45, contain: "content", maxWidth: (this.state.screenSize < CONSTANTS.CS_MdScreen ? 200 : this.state.screenSize < CONSTANTS.CS_LgScreen ? 500 :"none"), textOverflow: "ellipsis", marginTop:(this.state.screenSize < CONSTANTS.CS_MdScreen ? 16 : 9), fontSize: (this.state.screenSize < CONSTANTS.CS_MdScreen ? 14 : this.props.appBarTextStyle.fontSize)}}}>{this.props.appBarText}<span style={{fontWeight: "normal", fontSize: 12}}>{this.props.paren.length > 0 ? " (" + this.props.paren + ")" : ""}</span></div>

                    {
                        this.state.screenSize < CONSTANTS.CS_MdScreen ||
                        (this.props.contentSwitch && this.state.screenSize < CONSTANTS.CS_LgScreen) ||
                        (this.props.retroSwitch && this.state.screenSize < CONSTANTS.CS_LgScreen) ?
                            <div style={styles.icons}>{this.renderDotMenu()}</div> : null
                    }

                    {
                        (this.state.expanded) ?
                            <Tooltip title={RS('GB_stMinimize')}>
                                <IconButton onClick={this.onMinimizeDrawer} style={{...styles.icons}}>
                                    {/*<Icon className='fa fa-window-minimize'/>*/}
                                    <MinimizeIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={RS('GB_stMaximize')}>
                                <IconButton onClick={this.onMaximizeDrawer} style={{...styles.icons}}>
                                    <MaximizeIcon />
                                    {/*<Icon className='fa fa-window-maximize'/>*/}
                                </IconButton>
                            </Tooltip>
                    }

                    {
                        this.state.screenSize >= CONSTANTS.CS_MdScreen && this.props.showHelp ?
                            <Tooltip title={RS('GB_stHelp')}>
                                <IconButton onClick={this.onHelp} style={{...styles.helpIcon}}>
                                    <HelpIcon/>
                                </IconButton>
                            </Tooltip>
                            : null
                    }

                    {
                        (this.props.showSaveBtn) ?

                            <TButton onClick={this.props.onSave} disabled={this.props.saveBtnDisabled}
                                     containerStyle={{...styles.saveBtnDiv}} caption={this.props.saveBtnCaption}/> :
                            null
                    }
                    {
                        (this.props.showExtraBtn && this.state.screenSize >= CONSTANTS.CS_MdScreen) ?
                            this.renderExtraBtn()
                            :
                            null
                    }

                    {
                        (this.props.showDownload && this.state.screenSize >= CONSTANTS.CS_SmScreen) ?
                            this.renderDownloadButton()
                            :
                            null
                    }

                    {
                        (this.props.contentSwitch && this.state.screenSize >= CONSTANTS.CS_LgScreen) ?
                            this.props.contentSwitch
                            :
                            null
                    }

                    {
                        (this.props.retroSwitch && this.state.screenSize >= CONSTANTS.CS_LgScreen) ?
                            this.props.retroSwitch
                            :
                            null
                    }

                    {
                        (this.props.showDisplayOptions && this.state.screenSize >= CONSTANTS.CS_SmScreen) ?
                            this.props.contentDisplayOptions
                            :
                            null
                    }

                </Toolbar>
            </AppBar>
        )
    };

    render() {

        const styles = {
            paperProps: {
                width: this.state.drawerWidth,
                top: 0,
                bottom: 0
            },

            innerModal: {
                height: 'calc(100% - ' + 0 + 'px)',
                overflowY: this.props.preventScroll && this.state.screenSize > 1212 ? 'hidden' : 'auto',
                position: 'relative',
                top: 0
            },
        };

        return (
            <Drawer
                open={this.props.open}
                anchor="right"
                onClose={this.props.onClose}
                PaperProps={{style : styles.paperProps}}
                ModalProps={{disableBackdropClick: true}}
            >
                {
                    // (this.props.isDeskTop) ? this.renderDeskTopAppBar() : this.renderTabletAppBar()
                    // this.renderDeskTopAppBar()
                    this.renderAppBar()
                }

                <div style={styles.innerModal}>
                    {this.props.content}
                </div>
            
            </Drawer>
        );
    }
}

export default TDrawerWithHeader;