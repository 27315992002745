import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    addRowToPackTable,
    lockPackTable,
    indentPackTable,
    addMergedCellsToArray, lockPackRowCol, addComment, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {getEffectivenessOfInterventionsPackChart} from "../../../../data/CSUtil"

import {CSGetLiSTOnlineGroupStr, CSGetGroupMstIDFromLiSTOnlineID} from "utilities/CS/CSUtil"

import {
    CSGetMstIDName,
    getChildCODName,
    getAgeCohortName, CSGetVaccCurrID, CSGetDetVaccEquivDose, DirectEntryMstIDSet
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const EfficacyEditor = {
    Create : (IVInfo, CSModvars, language, Mode, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv = true, age = 0) => {
        let editorName = (Mode === CONSTANTS.CS_MstEfficacyNeonatal) ? RS('GB_stEffIntNN') : RS('GB_stEffIntChild');
        let packTable = Setup(IVInfo, CSModvars, language, Mode, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv, age);
        packTable = SetSSData(packTable, CSModvars, age);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable.Title = editorName;
        let packChart = getEffectivenessOfInterventionsPackChart(packTable, 0);
        packTable.EditorCat = CONSTANTS.GB_Effectiveness;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let EfficacyMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_Efficacy_MV2);
        let DetVaccEffMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_DetVaccEff_MV2);

        for (let row = 2; row < pt.RowIds.length; row++) {
            let IntervRec = pt.IntervRecords[row];
            if (IntervRec !== null){
                let d = pt.RowIds[row];
                let vt = CSGetVaccCurrID(IntervRec["MstID"]);
                pt.AgeSet.forEach(function (a) {
                    let ageOffset = 0;
                    if(a > CONSTANTS.CS_0t1months) {
                        ageOffset = ((a - CONSTANTS.CS_1t6months) * 2);
                    }
                    if (vt !== 0) {
                        let dose = CSGetDetVaccEquivDose(vt);
                        DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy][vt][d][dose][a] = pt.tableData.value[row][1 + ageOffset];
                        DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][vt][d][dose][a] = pt.tableData.value[row][2 + ageOffset];
                    }
                    else {
                        EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy][IntervRec["CurrID"]][d][a] = pt.tableData.value[row][1 + ageOffset];
                        EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][IntervRec["CurrID"]][d][a] = pt.tableData.value[row][2 + ageOffset];
                    }
                });
                if (vt !== 0) {
                    DetVaccEffMV["source"][d][vt] = pt.Source[pt.SourceMap[row]];
                }
                else {
                    EfficacyMV["source"][d][IntervRec["CurrID"]] = pt.Source[pt.SourceMap[row]];
                }
            }
        }

        changedModvars.push(EfficacyMV);
        changedModvars.push(DetVaccEffMV);

        return (changedModvars);
    },
};

const GetEfficacy = (Efficacy, def, effAff, IntervRec, d, a) =>{
    let result = 0;
    let vt = CSGetVaccCurrID(IntervRec["MstID"]);
    if (vt !== 0) {
        let dose = CSGetDetVaccEquivDose(vt);
        result = Efficacy[1][def][effAff][vt][d][dose][a];
    }
    else {
        result = Efficacy[0][def][effAff][IntervRec["CurrID"]][d][a];
    }
    return (result);
};

/* Set to true if non zero efficacy value is found */
const IntervHasData = (Efficacy, d, AgeSet, IntervRec) => {
    let result = false;
    AgeSet.forEach(function (a) {
        let Eff = GetEfficacy(Efficacy, CONSTANTS.CS_Data, CONSTANTS.CS_Efficacy, IntervRec, d, a);
        if ((Eff > 0) || (Eff !== GetEfficacy(Efficacy, CONSTANTS.CS_Default, CONSTANTS.CS_Efficacy, IntervRec, d, a))) {
            result = true;
        }
    });
    return (result);
};

const IntervGroupHasData = (Efficacy, d, AgeSet, IVGroupSet) => {
    let result = false;
    IVGroupSet.forEach(function (IntervRec) {
        result = result || IntervHasData(Efficacy, d, AgeSet, IntervRec);
    });
    return (result);
};

const CODHasData = (Efficacy, d, AgeSet, IVGroupSets) => {
    let result = false;
    for (let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
        for (let i = 0; i < IVGroupSets[g].length; i++) {
            let IntervRec = IVGroupSets[g][i];
            result = result || IntervHasData(Efficacy, d, AgeSet, IntervRec);
        }
    }
    return (result);
};

const GetAgeOffset = (age, a) =>{
    let result = 0;
    if (age === 0) {
        if (a > CONSTANTS.CS_0t1months) {
            result = ((a - CONSTANTS.CS_1t6months) * 2);
        }
    }
    return result;
};

const Setup = (IVInfo, CSModvars, language, Mode, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv, age) => {
    const selectedIVSet = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SelectedIVSet_MV);
    let Efficacy = [
        getModvarByTag(CSModvars, CONSTANTS.CS_TG_Efficacy_MV2)["value"],
        getModvarByTag(CSModvars, CONSTANTS.CS_TG_DetVaccEff_MV2)["value"]
    ];

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    const numRows = 3;
    let numCols = 0;

    let filter = '';

    switch (Mode){
        case CONSTANTS.CS_MstEfficacyNeonatal :{
            numCols = 3;
            filter = CONSTANTS.CS_NNEff_ED;
            break;
        }

        case CONSTANTS.CS_MstEfficacyPostNeonatal : {
            numCols = 9;
            if (age !== 0) {
                numCols = 3;
            }
            filter = CONSTANTS.CS_PNNEff_ED;
            break;
        }

        default : {
            numCols = 0;
            filter = '';
            break;
        }
    }

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    pt.AgeSet = [];

    switch (Mode){
        case CONSTANTS.CS_MstEfficacyNeonatal :{
            pt.AgeSet = [CONSTANTS.CS_0t1months];
            break;
        }

        case CONSTANTS.CS_MstEfficacyPostNeonatal : {
            switch (age) {
                case 0 : {
                    pt.AgeSet = [CONSTANTS.CS_1t6months,
                                 CONSTANTS.CS_6t12months,
                                 CONSTANTS.CS_12t24months,
                                 CONSTANTS.CS_24t60months];
                    break;
                }

                default : {
                    pt.AgeSet = [age];
                    break;
                }
            }
            break;
        }

        default : {
            pt.AgeSet = [];
            break;
        }
    }

    pt.AgeSet.forEach(function (a) {
        let ageOffset = GetAgeOffset(age, a);
        pt.tableData.value[0][1 + ageOffset] = getAgeCohortName(a, true);
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 0, 1 + ageOffset, 1, 2);
        pt.tableData.value[1][1 + ageOffset] = RS('GB_stEffectiveness');
        pt.tableData.value[1][2 + ageOffset] = RS('CS_stAffFract');
    });

    let IVGroupSets = new Array(CONSTANTS.CS_LiSTOnline_MaxGroups);

    for (let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
        IVGroupSets[g] = [];
        if (![CONSTANTS.CS_LiSTOnline_Nutrition].includes(g)) {
            let groupMstID = CSGetGroupMstIDFromLiSTOnlineID(g);
            for (let i = 0; i < IVInfo.length; i++) {
                let IntervRec = IVInfo[i];
                if (selectedIVSet["value"]["intArray"].includes(IntervRec.MstID) || ShowNonSelectedInterv) {
                    if (IntervRec[filter]) {
                        if (IntervRec["GroupMstID"] === groupMstID) {
                            if (!DirectEntryMstIDSet().includes(IntervRec["MstID"])) {
                                IVGroupSets[g].push(IntervRec);
                            }
                        }
                    }
                }
            }
        }
    }

    let diseaseSet = [];

    if (CauseOfDeath > 0){
        diseaseSet = [CauseOfDeath];
    }
    else{
        switch (Mode){
            case CONSTANTS.CS_MstEfficacyNeonatal :{
                for (let d = CONSTANTS.CS_FirstNNDeath; d <= CONSTANTS.CS_FinalStdNNDeath; d++) {
                    diseaseSet.push(d);
                }
                break;
            }

            case CONSTANTS.CS_MstEfficacyPostNeonatal : {
                for (let d = CONSTANTS.CS_FirstPNNDeath; d <= CONSTANTS.CS_OtherInfecDis; d++) {
                    diseaseSet.push(d);
                }
                break;
            }

            default : {
                break;
            }
        }
    }

    let row = 2;

    pt.numSources = 0;

    diseaseSet.forEach(function (d) {
        if (ShowAllItems || CODHasData(Efficacy, d, pt.AgeSet, IVGroupSets)) {
            pt = addRowToPackTable(pt);
            let ChildCODName = getChildCODName(d);
            pt.tableData.value[row][0] = ChildCODName;
            pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
            pt = lockPackTable(pt, row, true);
            row++;
            for (let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
                if (IVGroupSets[g].length !== 0) {
                    if (ShowAllItems || IntervGroupHasData(Efficacy, d, pt.AgeSet, IVGroupSets[g])) {
                        pt = addRowToPackTable(pt);
                        pt.tableData.value[row][0] = CSGetLiSTOnlineGroupStr(g, true);
                        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
                        pt = lockPackTable(pt, row, true);
                        pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
                        row++;
                        for (let i = 0; i < IVGroupSets[g].length; i++) {
                            let IntervRec = IVGroupSets[g][i];
                            if (ShowAllItems || IntervHasData(Efficacy, d, pt.AgeSet, IntervRec)) {
                                pt.hasData = true;
                                pt = addRowToPackTable(pt);

                                let s;
                                if (IntervRec["MstID"] === CONSTANTS.IV_ChangesInBF) {
                                    s = RS('GB_stBFPromo');
                                } else {
                                    s = CSGetMstIDName(CSModvars, language, IntervRec);
                                }

                                pt.tableData.value[row][0] = s;
                                pt.IntervRecords[row] = IntervRec;
                                pt.RowIds[row] = d;
                                let indent = GB_CONSTANTS.GB_ED_Indent2;
                                if (IntervRec["MstID"] === CONSTANTS.IV_WaterConnectionHome){
                                    indent += GB_CONSTANTS.GB_ED_Indent1;
                                }
                                pt = indentPackTable(pt, row, true, indent);
                                for (let c = 1; c <= numCols; c++) {
                                    pt.RDec[row][c] = 3;
                                }

                                let mstID = IntervRec["MstID"];

                                let lockCells =
                                    ((d === CONSTANTS.CS_Diarrhea) &&
                                        [CONSTANTS.IV_RotavirusVacc,
                                         CONSTANTS.IV_DiarVaccPathB,
                                         CONSTANTS.IV_DiarVaccPathC].includes(mstID)) ||

                                    ((d === CONSTANTS.CS_Pneumonia) &&
                                        [CONSTANTS.IV_HibVacc,
                                         CONSTANTS.IV_PneuVacc].includes(mstID)) ||

                                    ((d === CONSTANTS.CS_Meningitis) &&
                                        [CONSTANTS.IV_HibVacc,
                                         CONSTANTS.IV_PneuVacc,
                                         CONSTANTS.IV_MeningA].includes(mstID)) ||

                                    (IntervRec["MstID"] === CONSTANTS.IV_NNVitASupp) ||

                                    (IntervRec["MstID"] === CONSTANTS.IV_VitASupp) ||

                                    (IntervRec["MstID"] === CONSTANTS.IV_ZincSupp) ||

                                    (IntervRec["MstID"] === CONSTANTS.IV_FolicAcidSupp);

                                if (lockCells){
                                    for (let index = 0; index < pt.AgeSet.length; index++) {
                                        let a = pt.AgeSet[index];
                                        let ageOffset = GetAgeOffset(age, a);
                                        pt = lockPackRowCol(pt, row, 2 + ageOffset, true);
                                        if (IntervRec["MstID"] === CONSTANTS.IV_FolicAcidSupp){
                                            for(let col = 2; col < pt.GBColCount; col += 2) {
                                                switch (d){
                                                    case CONSTANTS.CS_NNCongen : pt = addComment(pt, row, col, RS('GB_stFolateDefNNCongen')); break;
                                                    default : pt = addComment(pt, row, col, RS('GB_stFolDef')); break;
                                                }
                                            }
                                        }
                                        if (IntervRec["MstID"] === CONSTANTS.IV_NNVitASupp){
                                            for(let col = 2; col < pt.GBColCount; col += 2) {
                                                pt = addComment(pt, row, col, RS('GB_stPercVitADef'));
                                            }
                                        }
                                    }
                                }

                                pt.numSources++;
                                pt.SourceMap[row] = pt.numSources;
                                pt.SourceTitle[pt.numSources] = ChildCODName + ' - ' + pt.tableData.value[row][0];

                                row++;
                            }
                        }
                    }
                }
            }
        }
    });

    // pt = setSourceArrLength(pt, pt.numSources);

    pt.GBRowCount = row;
    pt = resizePackTable(pt, row, numCols);

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.MinAllowedVal[row][col] = 0;
                pt.MaxAllowedVal[row][col] = 1;
            }
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars, age) => {
    let Efficacy = [
        getModvarByTag(CSModvars, CONSTANTS.CS_TG_Efficacy_MV2)["value"],
        getModvarByTag(CSModvars, CONSTANTS.CS_TG_DetVaccEff_MV2)["value"]
    ];
    let EfficacySrc = [
        getModvarByTag(CSModvars, CONSTANTS.CS_TG_Efficacy_MV2)["source"],
        getModvarByTag(CSModvars, CONSTANTS.CS_TG_DetVaccEff_MV2)["source"]
    ];

    for (let row = 2; row < pt.RowIds.length; row++) {
        let IntervRec = pt.IntervRecords[row];
        pt.AgeSet.forEach(function (a) {
            let ageOffset = GetAgeOffset(age, a);
            if (IntervRec !== null) {
                let d = pt.RowIds[row];
                pt.tableData.value[row][1 + ageOffset] = GetEfficacy(Efficacy, CONSTANTS.CS_Data, CONSTANTS.CS_Efficacy, IntervRec, d, a);
                pt.tableData.value[row][2 + ageOffset] = GetEfficacy(Efficacy, CONSTANTS.CS_Data, CONSTANTS.CS_AffecFract, IntervRec, d, a);

                let vt = CSGetVaccCurrID(IntervRec["MstID"]);
                if (vt !== 0) {
                    pt.Source[pt.SourceMap[row]] = EfficacySrc[1][d][vt];
                }
                else{
                    pt.Source[pt.SourceMap[row]] = EfficacySrc[0][d][IntervRec["CurrID"]];
                }
            }
            else {
                pt.tableData.value[row][1 + ageOffset] = '';
                pt.tableData.value[row][2 + ageOffset] = '';
            }
        });
    }
    return pt;
};

