import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    getAgeCohortName,
    CSGetImpactPromoAgeAppropriateBFStr
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const ImpactPromoAgeAppropriateBFEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stImpactPromoOnBF');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let ImpactsBFPromotion = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ImpactsBFPromotion_MV3);

        let KMConBF = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_KMConBF_MV);

        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
            let opt = 1;
            for(let row = 2; row < pt.GBRowCount-1; row++){
                ImpactsBFPromotion["value"][CONSTANTS.CS_Data][CONSTANTS.CS_ImpactPromoBF][opt][CONSTANTS.CS_OddsRatio][a] = pt.tableData.value[row][col];
                ImpactsBFPromotion["source"][CONSTANTS.CS_ImpactPromoBF][opt] = pt.Source[pt.SourceMap[row]];
                opt++;
            }
            KMConBF["value"][CONSTANTS.CS_Data][CONSTANTS.CS_OddsRatio][a] = pt.tableData.value[pt.GBRowCount-1][col];
            KMConBF["source"][0][0] = pt.Source[pt.SourceMap[pt.GBRowCount-1]];
            col++;
        }

        changedModvars.push(ImpactsBFPromotion);
        changedModvars.push(KMConBF);

        return (changedModvars);
    },
};

const Setup = () => {
    const numCols = 6;
    const numRows = 6;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        pt.tableData.value[0][col] = getAgeCohortName(a, true);
        pt.tableData.value[1][col] = RS('GB_stOddsRatio');
        col++;
    }

    pt.numSources = 0;

    let row = 2;
    for(let i = CONSTANTS.CS_BFPromoHealthSystem; i <= CONSTANTS.CS_BFPromoBoth; i++){
        pt.tableData.value[row][0] = CSGetImpactPromoAgeAppropriateBFStr(i);
        pt.numSources++;
        pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
        pt.SourceMap[row] = pt.numSources;
        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            pt.Alignments[row][col] = CONSTANTS.GB_Right;
            pt.RDec[row][col] = 2;
            pt.MaxAllowedVal[row][col] = 1000;
            col++;
        }
        row++;
    }

    pt.tableData.value[row][0] = RS('GB_stKangerooMothCare');
    pt.numSources++;
    pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
    pt.SourceMap[row] = pt.numSources;
    col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
        pt.Alignments[row][col] = CONSTANTS.GB_Right;
        pt.RDec[row][col] = 2;
        pt.MaxAllowedVal[row][col] = 1000;
        col++;
    }

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let ImpactsBFPromotion = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsBFPromotion_MV3)["value"][
        CONSTANTS.CS_Data];

    let ImpactsBFPromotionSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsBFPromotion_MV3)["source"];

    let KMConBF = getModvarByTag(CSModvars, CONSTANTS.CS_TG_KMConBF_MV)["value"][
        CONSTANTS.CS_Data];

    let KMConBFSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_KMConBF_MV)["source"];

    let col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        let opt = 1;
        for(let row = 2; row < pt.GBRowCount-1; row++){
            pt.tableData.value[row][col] = ImpactsBFPromotion[CONSTANTS.CS_ImpactPromoBF][opt][CONSTANTS.CS_OddsRatio][a];
            pt.Source[pt.SourceMap[row]] = ImpactsBFPromotionSrc[CONSTANTS.CS_ImpactPromoBF][opt];
            opt++;
        }
        pt.tableData.value[pt.GBRowCount-1][col] = KMConBF[CONSTANTS.CS_OddsRatio][a];
        pt.Source[pt.SourceMap[pt.GBRowCount-1]] = KMConBFSrc[0][0];
        col++;
    }

    return pt;
};

