import React, { useState } from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import Check from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TButton from '@common/components/TButton';
import TEdit from '@common/components/TEdit';
import TComboBox from "@common/components/TComboBox";
import TCheckBox from "@common/components/TCheckBox";
import TRangeSlider from "@common/components/TRangeSlider";
import TExpansionPanel from "@common/components/TExpansionPanel";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import SubNatBlueBox from "../editors/CS/cards/SubNatWizard/SubNatBlueBox";
import CSBlueBox2 from "../components/CS/CSBlueBox2";
import SubnatCheckDialog from "../components/dialogs/SubnatCheckDialog";
import TCSNotesDialog from "../components/dialogs/TCSNotesDialog";
import { SessionStorageUtil } from "../api/localstorage/util";
import CSStepper from "../components/CS/CSStepper";
import TCSUnsavedChangesDialog from "../components/dialogs/TCSUnsavedChangesDialog";

//==================================================================================================================
//
//                                          Presentational Components
//
//==================================================================================================================

// const Header = (props) => {
//
//     let { projectCreated } = props;
//
//     const styles = {
//         pageHeader : {
//             marginTop: 15
//         },
//         block : {
//             display: "block"
//         }
//     };
//
//     if (projectCreated) {
//         return (
//             <h2 className="pageHeader" style={styles.pageHeader}>
//                 <div style={styles.block}>{RS('GB_stModifyProjection')}</div>
//             </h2>
//         );
//     }
//     else {
//         return (
//             <h2 className="pageHeader" style={styles.pageHeader}>
//                 {RS('GB_stConfigProj')}
//             </h2>
//         );
//     }
// };

const SubHeader = () => {

    const styles = {
        subHeading : {
            fontSize: "16px",
            color: "#000000de"
        },
        block : {
            display: "block"
        }
    };

    return (
        <p style={styles.subHeading}>{RS('GB_stChangesToCountryInfo')}</p>
    );
};

const ProjectionName = (props) => {

    const { projectionName, onChange } = props;

    const styles = {
        projName : {
            width: '465px',
            maxWidth: '100%',
            marginTop: '20px',
            marginBottom: '35px',
            color: '#609cd9',
            display: 'block',
        }
    };

    return (
        <TEdit
            name               = "ProjectionNameField"
            caption            = {RS('GB_stProjectionName')}
            value              = {projectionName}
            style              = {styles.projName}
            // className          = {classes.root}
            shrinkLabel        = {true}
            floatingLabelColor = {'#609cd9'}
            onChange           = {onChange}
            fullWidth          = {true}
        />
    )
};

const CountryDropDown = (props) => {

    let { disabled, items, itemIndex, onChange } = props;

    const styles = {
        comboBox: {
            // marginTop: 20,
            marginRight: 25,
            marginBottom: 35,
            paddingLeft: 0,
            width: "100%",
            display: 'inline-block'
        },
        subnatLock: {
            marginTop: -3
        },
        rule: {
            width:"100%",
            maxWidth:465,
            margin:"-12px 0 46px"
        },
        label: {
            color: "rgba(0, 0, 0, 0.54)",
            padding: 0,
            fontSize: ".75rem",
            fontFamily: "Lato, sans-serif",
            lineHeight: 1
        },
        para: {
            marginTop: 6,
            marginBottom: 13,
            fontSize: ".925rem"
        },
        captionStyle : {
            color: '#609cd9'
        }
    };

    return (
        <div id="country_dropdown">
            <TComboBox
                caption      = {RS('GB_stCountry')}
                items        = {items}
                itemIndex    = {itemIndex}
                onChange     = {onChange}
                style        = {styles.comboBox}
                captionStyle = {styles.captionStyle}
                disabled     = {disabled}
            />
        </div>
    )
};

const RegionDropDown = (props) => {

    const { disabled, items, itemIndex, onChange } = props;

    const styles = {
        comboBox: {
            marginTop: 20,
            marginRight: 25,
            marginBottom: 35,
            paddingLeft: 0,
            width: "100%",
            display: 'inline-block'
        },
        subnatLock: {
            marginTop: -3
        },
        rule: {
            width: "100%",
            maxWidth: 465,
            margin: "-12px 0 46px"
        },
        label: {
            color: "rgba(0, 0, 0, 0.54)",
            padding: 0,
            fontSize: ".75rem",
            fontFamily: "Lato, sans-serif",
            lineHeight: 1
        },
        para: {
            marginTop: 6,
            marginBottom: 13,
            fontSize: ".925rem"
        }
    };

    return (
        <div id={"region_dropdown"}>
            <TComboBox
                caption={RS('GB_stRegion')}
                items={items}
                itemIndex={itemIndex}
                style={styles.comboBox}
                captionStyle={{color: '#609cd9'}}
                onChange={onChange}
                disabled={disabled}
            >
            </TComboBox>
        </div>
    );
};

const SurveyDropDown = (props) => {

    const { disabled, items, itemIndex, onChange } = props;

    const styles = {
        comboBox: {
            marginTop: 20,
            marginRight: 25,
            marginBottom: 35,
            paddingLeft: 0,
            width: "100%",
            display: 'inline-block'
        },
        subnatLock: {
            marginTop: -3
        },
        rule: {
            width:"100%",
            maxWidth:465,
            margin:"-12px 0 46px"
        },
        label: {
            color: "rgba(0, 0, 0, 0.54)",
            padding: 0,
            fontSize: ".75rem",
            fontFamily: "Lato, sans-serif",
            lineHeight: 1
        },
        para: {
            marginTop: 6,
            marginBottom: 13,
            fontSize: ".925rem"
        }
    };

    return (
        <div id={"survey_dropdown"}>
            <TComboBox
                caption={RS('GB_stSurvey')}
                items={items}
                itemIndex={itemIndex}
                onChange={onChange}
                style={styles.comboBox}
                captionStyle={{color: '#609cd9'}}
                disabled={disabled}
            >
            </TComboBox>
        </div>
    )
};

// const ModifyProjectionName = (props) => {
//
//     let { value } = props;
//
//     const styles = {
//         projName : {
//             width: '465px',
//             maxWidth: '100%',
//             // marginTop: '20px',
//             color: '#609cd9',
//             marginBottom: "35px"
//         }
//     };
//
//     return (
//         <TEdit
//             value={value}
//             caption={RS("GB_stCountry")}
//             style={styles.projName}
//             inputStyle={{disabled: true}}
//         />
//     );
// };

const YearsSlider = (props) => {

    const { isSubnatProjection, disabledMin, yearRange, onChange, onChangeComplete } = props;

    const styles = {
        div : {
            marginBottom: '35px',
            width: '465px',
            maxWidth: '100%'
        },
        rangeSlider : {
            // marginBottom: '20px',
            // color: 'red',
            // paddingTop: 0,
        },
        p : {
            marginTop : 0
        }
    };

    return (
        <div style={styles.div}>
            {
                isSubnatProjection ?
                    <p style={styles.p}>{RS("GB_stFirstYearBySurvey")}</p> :
                    null
            }

            <TRangeSlider
                style={styles.rangeSlider}
                minValue={CONSTANTS.CS_MinYear}
                maxValue={CONSTANTS.CS_MaxYear}
                minTextLabel={RS('GB_stFirstYear')}
                maxTextLabel={RS('GB_stFinalYear')}
                disabledMin={disabledMin}
                value={yearRange}
                onChange={onChange}
                onChangeComplete={onChangeComplete}
            />
        </div>
    )
};

const SubnationalButton = (props) => {

    let { subnatDone, onClick } = props;

    const styles = {
        subNatButton: {
            fontSize: '16px',
        },
        buttonWrapper : {
            display : "inline-block",
        },
        wrapper: {
            display: "inline-block",
            verticalAlign: "top",
            marginBottom: "35px"
        },
        check : {
            paddingLeft: "5px"
        },
        subnatLabel : {
            display: 'inline-block',
            marginLeft: '10px'
        },
        red : {
            color: 'red'
        },
        gray : {
            color: 'gray'
        }
    };

    return (
        <div style={styles.wrapper}>
            <CSBlueBox2
                caption         = {RS("GB_stConfigSubnat")}
                description     = {""}
                isRegularButton = {true}
                buttonColor     = {"secondary"}
                buttonVarriant  = {"contained"}
                pictureRight    = {subnatDone ? <Check style={styles.check} /> : null}
                onClick         = {onClick}
                regBtnStyle     = {styles.subNatButton}
                style           = {styles.buttonWrapper}
            />

            <div style={styles.subnatLabel}>
                {
                    (!props.subnatDone) ?
                        <span style={styles.red}>Required</span> :
                        <span style={styles.gray}>Completed</span>
                }
            </div>
        </div>
    );
};

const ReloadDefaultDataCheckBox = (props) => {

    let { reloadDefaultData, onChange } = props;

    const styles = {
        checkBox: {
            // marginTop: 10,
            // marginBottom: 20,
            marginBottom: "35px"
        },
        reloadDataCheckBoxLabelStyle: {
            fontSize: '16px',
            color: '#f78c30'
        }
    };

    return (
        <TCheckBox
            style={styles.checkBox}
            color={'primary'}
            caption={RS('GB_stReloadDefaultData')}
            labelStyle={styles.reloadDataCheckBoxLabelStyle}
            onClick={onChange}
            value={reloadDefaultData}
        />
    );
};

// const Author = (props) => {
//
//     let { authorName, onChange } = props;
//
//     const styles = {
//         authorName : {
//             width: '465px',
//             maxWidth: '100%',
//             marginBottom: '35px',
//             color: '#609cd9',
//         }
//     };
//
//     return (
//         <TEdit
//             name="AuthorNameField"
//             caption={RS("GB_stAuthor")}
//             value={authorName}
//             onChange={onChange}
//             captionStyle={{color: '#609cd9'}}
//             style={styles.authorName}
//         />
//     )
// };
//
// const Organization = (props) => {
//
//     let { orgName, onChange } = props;
//
//     const styles = {
//         orgName : {
//             width: '465px',
//             maxWidth: '100%',
//             marginBottom: '35px',
//             color: '#609cd9',
//         }
//     };
//
//     return (
//         <TEdit
//             name="OrgField"
//             caption={RS("GB_stAuthorOrganization")}
//             value={orgName}
//             onChange={onChange}
//             captionStyle={{color: '#609cd9'}}
//             style={styles.orgName}
//         />
//     )
// };

const ProjectionNotes = (props) => {

    let { projectionNotes, onChange, onEditBtnClick, dialogNotesOpen, onDialogNotesClose, isMobile } = props;

    const styles = {
        wrapper : {
            position: "relative"
        },
        projNotes: {
            width: isMobile ? "auto" : '465px',
            maxWidth: isMobile ? "none" : '90%',
            marginBottom: 35,
            color: '#609cd9',
            marginRight: 0
        },
        icon : {
            position: isMobile ? "relative" : "absolute",
            float: isMobile ? "right" : "none",
            right: "0px",
            zIndex: "1"
        },
        edit : {
            color: '#609cd9'
        }
    };

    return (
        <div style={styles.wrapper}>
            <IconButton
                color={'primary'}
                onClick={onEditBtnClick}
                style={styles.icon}
            >
                <EditIcon />
            </IconButton>

            <TEdit
                name="ProjectionNotesField"
                caption={RS('GB_stNotesForProjection')}
                value={projectionNotes}
                onChange={onChange}
                captionStyle={styles.edit}
                style={styles.projNotes}
            />

            {
                (dialogNotesOpen) ?
                    <TCSNotesDialog
                        visible={true}
                        notes={projectionNotes}
                        onClose={onDialogNotesClose}
                    /> : null
            }
        </div>
    )
};

// const Approach = (props) => {
//     let {
//         approach,
//         onStateChange
//     } = props;
//
//     const onChangeRadioGroup = (idx) => {
//         onStateChange({approach: idx});
//     };
//
//     let styles = {
//         caption: {
//             color: Theme.palette.secondary.main,
//             fontSize: 12
//         },
//         main: {
//             marginBottom: 30
//         }
//     };
//
//     return (
//         <TRadioGroup
//             caption={RS("GB_stApproach")}
//             itemIndex={approach}
//             items={[RS("GB_stStandard"), RS("GB_stNutrition")]}
//             handleChange={onChangeRadioGroup}
//             style={styles.main}
//             captionStyle={styles.caption}
//             sortinRow={false}
//         />
//     )
// };

const NotesAndInfoExpPanel = (props) => {

    let {
        // authorName,
        // onAuthorChange,
        // orgName,
        // onOrganizationChange,
        projectionNotes,
        onProjectionNotesChange,
        isMobile
    } = props;

    let styles = {
        wrapper : {
            maxWidth: "465px",
            marginBottom: "35px"
        },
        headerStyle : {
            fontSize: "16px",
            color:"#609cd9"
        },
        details : {
            paddingBottom: "10px"
        },
        content : {
            maxWidth: "417px"
        }
    };

    let [dialogNotesOpen, setDialogNotesOpen] = useState(false);

    const onEditBtnClick = () => {
        setDialogNotesOpen(true);
    };

    const onDialogNotesClose = (action, value) => {
        setDialogNotesOpen(false);

        if (action === 'save') {
            onProjectionNotesChange(value);
        }
    };

    return (
        <div style={styles.wrapper}>
            <TExpansionPanel
                items={[[1, RS("GB_stNotes") + " " + RS("GB_stOptParen"), false, '', false, false]]}
                headerStyle={styles.headerStyle}
                expansionPanelDetailsStyle={styles.details}
                content={[
                    <div style={styles.content}>
                        {/*<Author*/}
                        {/*    authorName={authorName}*/}
                        {/*    onChange={onAuthorChange}*/}
                        {/*/>*/}
                        {/*<Organization*/}
                        {/*    orgName={orgName}*/}
                        {/*    onChange={onOrganizationChange}*/}
                        {/*/>*/}
                        <ProjectionNotes
                            projectionNotes={projectionNotes}
                            onChange={onProjectionNotesChange}
                            onEditBtnClick={onEditBtnClick}
                            dialogNotesOpen={dialogNotesOpen}
                            onDialogNotesClose={onDialogNotesClose}
                            isMobile={isMobile}
                        />
                    </div>
                ]}
            />
        </div>
    );
};

const CreateButton = (props) => {

    const { onClick } = props;

    const styles = {
        createButton: {
            fontSize: '16px',
            marginRight: '20px',
            marginTop: '10px'
        }
    };

    return (
        <TButton
            caption={RS('GB_stCreateProjection')}
            variant={"contained"}
            primary={true}
            style={styles.createButton}
            onClick={onClick}
        />
    )
};

const ModifyButton = (props) => {

    const { disabled, onClick } = props;

    const styles = {
        modifyButton: {
            fontSize: '16px',
            margin: '10px 20px 3px 3px',
            color: '#FFFFFF',
            backgroundColor: disabled ? 'rgba(0, 0, 0, .12)' : '#f78c30'
        }
    };

    return (
        <TButton
            caption={RS('GB_stApply')}
            variant={"contained"}
            disabled={disabled}
            style={styles.modifyButton}
            onClick={onClick}
        />
    )
};

//==================================================================================================================
//
//                                                Main Component
//
//==================================================================================================================

class CSProjManagerForm2 extends React.Component {

    static propTypes = {
        countries          : PropTypes.array,
        IVInfo             : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo     : PropTypes.arrayOf(PropTypes.object),

        projectCreated     : PropTypes.bool,

        projectionName     : PropTypes.string,
        selectedCountry    : PropTypes.string,
        subnatRegion       : PropTypes.string,
        subnatSurvey       : PropTypes.string,
        yearRange          : PropTypes.object,
        projectionNotes    : PropTypes.string,

        countryISO         : PropTypes.number,
        isSubnatProjection : PropTypes.bool,
        includeFamPlan     : PropTypes.bool,

        onCreateProjection : PropTypes.func,
        onModifyProjection : PropTypes.func,
        onStateChange      : PropTypes.func,
        onAddTasks         : PropTypes.func,
        language           : PropTypes.number,
        onPageChange       : PropTypes.func,
        onViewResults      : PropTypes.func,
        onOpenHelpDialog   : PropTypes.func,
        isMobile           : PropTypes.bool,
        approach           : PropTypes.number
    };

    static defaultProps = {
        countries          : [],
        IVInfo             : [],
        IVSubGroupInfo     : [],

        projectCreated     : false,

        projectionName     : '',
        selectedCountry    : 'Afghanistan',
        subnatRegion       : RS('GB_stNoRegionSelected'),
        subnatSurvey       : '',
        yearRange          : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        projectionNotes    : '',

        countryISO         : 4,
        isSubnatProjection : false,
        includeFamPlan     : true,

        onCreateProjection : () => console.log('onCreateProjection'),
        onModifyProjection : () => console.log('onModifyProjection'),
        onStateChange      : () => console.log('onStateChange'),
        onAddTasks         : () => console.log('onAddTasks'),
        language           : CONSTANTS.GB_English,
        onPageChange       : () => console.log('onPageChange'),
        onViewResults      : () => console.log('onViewResults'),
        onOpenHelpDialog   : () => console.log("onOpenHelpDialog"),
        isMobile           : false,
        approach           : CONSTANTS.CS_StandardApproach
    };

    state = {
        countryNames                      : [],
        regionNames                       : [],
        surveyNames                       : [],

        projectionName                    : '',
        countryIdx                        : 0,
        regionIdx                         : 0,
        surveyIdx                         : 0,
        yearRange                         : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        authorName                        : SessionStorageUtil.getHumanName(),
        orgName                           : SessionStorageUtil.getOrganization(),
        projectionNotes                   : '',

        isSubnatProjection                : false,
        subnatDone                        : false,
        subnatModvars                     : [],
        openSubNatDrawer                  : false,
        openSubnatDataLossDialog          : false,

        reloadDefaultData                 : false,
        countryISO                        : 4,

        changed                           : false,

        origProjectionName                : '',
        dialogUnsavedChangesOpen          : false,

        loading                           : true,
    };

    temp_callback = null;
    temp_value = null;

    componentDidMount() {

        let { countries, selectedCountry, subnatRegion, subnatSurvey } = this.props;

        let countryIdx;
        countryIdx           = countries.findIndex(x => x.countryRegionName === selectedCountry);
        countryIdx           = (countryIdx < 0) ? 0 : countryIdx;

        let regionIdx;
        regionIdx            = countries[countryIdx].Regions.findIndex(x => x.name === subnatRegion);
        regionIdx            = (regionIdx < 0) ? 0 : regionIdx;

        let surveyIdx;
        surveyIdx            = countries[countryIdx].Regions[regionIdx].Surveys.findIndex(x => x.name === subnatSurvey);
        surveyIdx            = (surveyIdx < 0) ? 0 : surveyIdx;

        let isSubnatProjection = regionIdx > 0;

        let countryISO       = countries[countryIdx].countryRegionCode;

        this.setState({
            countryNames     : countries.filter(x => (x.countryRegionCode < 900)).map(x => x.countryRegionName),
            regionNames      : countries[countryIdx].Regions.map(x => x.name),
            surveyNames      : countries[countryIdx].Regions[regionIdx].Surveys.map(x => x.name),

            projectionName   : this.props.projectionName,
            countryIdx       : countryIdx,
            regionIdx        : regionIdx,
            surveyIdx        : surveyIdx,
            yearRange        : this.props.yearRange,
            authorName       : this.props.authorName,
            orgName          : this.props.orgName,
            projectionNotes  : this.props.projectionNotes,

            isSubnatProjection: isSubnatProjection,

            countryISO       : countryISO,
            origProjectionName : this.props.projectionName,
            loading            : false,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState))
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    static getDerivedStateFromProps(props, state) {
        // If the user closes their projection while on the projection manager screen,
        // reset the projectionName (usually to blank), but keep the other state.
        if (props.projectionName !== state.origProjectionName) {
            return {
                projectionName : props.projectionName,
                origProjectionName : props.projectionName,
            }
        }
        else {
            return null;
        }
    }

    //==================================================================================================================
    //
    //                                             Event Handlers
    //
    //==================================================================================================================

    onProjNameChange = (value) => {
        if (value.length <= 80) {
            this.setState({
                projectionName : value,
                changed : value !== this.state.projectionName,
            });
        } else {
            alert(RS("GB_stFileNameTooLong"));
        }
    };

    onAttemptCountryChange = (value) => {
        (this.state.subnatDone) ?
            this.onSubnatDataLossDialogOpen(this.onCountryChange, value) :
            this.onCountryChange(value);
    };

    onCountryChange = (value) => {

        const { countries } = this.props;

        this.setState({
            countryIdx : value,
            countryISO : countries[value].countryRegionCode,
            subnatDone : false,
            subnatModvars : [],
            changed : true,
            regionNames : countries[value].Regions.map(x => x.name)
        }, () => {
            this.onRegionChange(0);
        });
    };

    onRegionChange = (value) => {

        const { countries } = this.props;
        const { countryIdx } = this.state;

        this.setState({
            regionIdx          : value,
            isSubnatProjection : value > 0,
            changed            : true,
            surveyNames        : countries[countryIdx].Regions[value].Surveys.map(x => x.name)
        }, () => {
            this.onSurveyChange(0);
        });
    };

    onSurveyChange = (value) => {
        this.setState({
            surveyIdx : value,
            changed : true,
        }, () => {
            if (this.state.isSubnatProjection) {
                const { countries } = this.props;
                const { countryIdx, regionIdx, surveyIdx } = this.state;

                let minYear = countries[countryIdx].Regions[regionIdx].Surveys[surveyIdx].Year;
                let maxYear = this.state.yearRange.max;

                this.onYearsChange({
                    min: minYear,
                    max : maxYear
                });
            }
        });
    };

    onYearsChange = (value) => {
        // if (value.min > (new Date()).getFullYear()) {
        //     value.min = (new Date()).getFullYear();
        // }
        //
        // if (value.max < value.min + 1) {
        //     value.max = value.min + 1;
        // }

        let currentYear = new Date().getFullYear();

        this.setState({
            yearRange : {
                min : value.min > currentYear ? currentYear : value.min,
                max : (value.max < value.min + 4) ? value.min + 4 : value.max
            },
            changed : true,
        });
    };

    onReloadDefaultDataCheckBoxChange = (value) => {
        this.setState({
            reloadDefaultData : value,
            changed : true,
        });
    };

    onAuthorChange = (value) => {
        this.setState({
            authorName : value,
            changed : true
        });
    };

    onOrganizationChange = (value) => {
        this.setState({
            orgName : value,
            changed : true
        });
    };

    onProjectionNotesChange = (value) => {
        this.setState({
            projectionNotes : value,
            changed : true
        });
    };

    onSubnationalBtnClick = () => {
        this.setState({
            openSubNatDrawer : true
        });
    };

    onSubnationalDrawerClose = () => {
        this.setState({
            openSubNatDrawer : false
        });
    };

    onSubnationalDrawerSave = (modvars) => {
        this.setState({
            openSubNatDrawer : false,
            subnatDone: true,
            subnatModvars : modvars,
            changed : true,
        });
    };

    onRetrievedSubnatModvars = (modvars) => {
        this.setState({
            subnatModvars : modvars,
        });
    };

    onCreateNewProjectionClick = () => {

        if (this.state.isSubnatProjection && this.state.subnatModvars.length === 0) {
            this.onErrorDialogShow(RS("GB_stConfigSubnatDat"));
            return;
        }

        if (!this.onValidate()) {
            return;
        }

        if (this.props.projectCreated) {
            this.props.onStateChange({
                dialogCloseOpen: true
            });
            return;
        }

        const { countries } = this.props;
        const { countryIdx, regionIdx, surveyIdx } = this.state;

        // The user could have selected a region and finished the subnational wizard, then
        // changed the region back to National and hit Create Projection.  When this happens,
        // we do not want to send the subnational editors to the server.  However, if they were
        // messing around with the regional dropdown, accidentally selected National, then 
        // selected a real subnational region, we would still want to maintain their subnational
        // wizard editors.  This is why this code is located in the Create Projection logic as
        // opposed to in the region dropdown change.

        let realSubNatProjection = false;
        let realSubNationModvars = [];
        if (regionIdx !== 0) {
            realSubNationModvars = this.state.subnatModvars;
            realSubNatProjection = this.state.isSubnatProjection;
        }

        this.props.onCreateProjection({
            CountryISO       : this.state.countryISO,
            selectedCountry  : countries[countryIdx].countryRegionName,
            FirstYear        : this.state.yearRange.min,
            FinalYear        : this.state.yearRange.max,
            FileName         : this.state.projectionName,
            // ModList          : (this.props.includeFamPlan) ? ['1', '2', '3', '4', '15', '35'] : ['1', '2', '4', '15', '35'],
            ModList          : (this.props.includeFamPlan) ? ['1', '2', '3', '4', '15'] : ['1', '2', '4', '15'],
            Notes            : this.state.projectionNotes,
            Author           : this.state.authorName,
            Org              : this.state.orgName,
            projectionNotes  : this.state.projectionNotes,
            includeFamPlan   : this.props.includeFamPlan,
            isSubnatProjection : realSubNatProjection,
            Region           : countries[countryIdx].Regions[regionIdx].name,
            Survey           : countries[countryIdx].Regions[regionIdx].Surveys[surveyIdx].name,
            arr              : realSubNationModvars,
            AgeGroupOption   : CONSTANTS.FP_Single_Age_Group,
        });
    };

    onModifyProjectionClick = () => {

        if (!this.onValidate()) {
            return;
        }

        this.setState({
            changed: false
        });

        const { countries } = this.props;
        const { countryIdx } = this.state;

        this.props.onModifyProjection({
            reloadDefaultData : this.state.reloadDefaultData,
            Proj              : 1,
            CountryISO        : this.state.countryISO,
            selectedCountry   : countries[countryIdx].countryRegionName,
            FileTitle         : this.state.projectionName,
            FirstYear         : this.state.yearRange.min,
            FinalYear         : this.state.yearRange.max,
            ModList           : [
                {
                    modID: 1,
                    addModule: false,
                    closeModule: false,
                    reloadModule: this.state.reloadDefaultData
                },
                {
                    modID: 2,
                    addModule: false,
                    closeModule: false,
                    reloadModule: this.state.reloadDefaultData
                },
                {
                    modID: 3,
                    addModule: this.props.includeFamPlan,
                    closeModule: !this.props.includeFamPlan,
                    reloadModule: false
                },
                {
                    modID: 4,
                    addModule: false,
                    closeModule: false,
                    reloadModule: this.state.reloadDefaultData
                },
                {
                    modID: 15,
                    addModule: false,
                    closeModule: false,
                    reloadModule: this.state.reloadDefaultData
                }
            ],
            projectionNotes   : this.state.projectionNotes,
            includeFamPlan    : this.props.includeFamPlan,
            subnatProjection  : this.state.isSubnatProjection,
            Author            : this.state.authorName,
            Org               : this.state.orgName,
        });
    };

    onErrorDialogShow = (msg) => {
        this.props.onStateChange({
            dialogErrorOpen : true,
            errorMessage    : msg
        });
    };

    onWarningDialogShow = (msg) => {
        this.props.onStateChange({
            dialogWarningOpen : true,
            warningMessage    : msg
        });
    };

    onSubnatDataLossDialogOpen = (callback, value) => {
        this.temp_callback = callback;
        this.temp_value = value;

        this.setState({
            openSubnatDataLossDialog : true,
        });
    };

    onSubnatDataLossDialogYes = () => {
        this.setState({
            openSubnatDataLossDialog : false
        });

        this.temp_callback(this.temp_value);
    };

    onSubnatDataLossDialogNo = () => {
        this.setState({
            openSubnatDataLossDialog : false
        });
    };

    //==================================================================================================================
    //
    //                                                 Other
    //
    //==================================================================================================================

    onValidate = () => {

        let valid = this.state.projectionName.replace(/^\s+$/, '') !== '';
        let legit = !this.state.projectionName.includes(":") && !this.state.projectionName.includes("/") && !this.state.projectionName.includes(".");

        if (!valid) {
            this.onWarningDialogShow(RS('GB_stPleaseEnterProjName'));
        }
        else if (!legit) {
            this.onWarningDialogShow(RS("GB_stBadChar"));
        }

        return valid && legit;
    };

    onStepChange = (idx) => {
        switch(idx) {
            case 0  : this.props.onPageChange("CSProjManagerForm"); break;
            case 1  : if (this.props.projectCreated) { this.props.onPageChange("CSManageInterventionsForm"); } break;
            case 2  : if (this.props.projectCreated) { this.props.onPageChange("CSReviewDefaultDataForm"); } break;
            case 3  : if (this.props.projectCreated) { this.props.onViewResults(false); } break;
            default : break;
        }
    };

    renderNextBtn = () => {

        const styles = {
            nextButton: {
                fontSize: '16px',
                margin: '10px 20px 3px 3px',
            }
        };

        return (
            <TButton
                caption={RS('GB_stNext')}
                color="primary"
                style={styles.nextButton}
                onClick={this.onNextBtnClick}
            />
        );
    };

    onNextBtnClick = () => {
        if (this.state.changed) {
            this.setState({
                dialogUnsavedChangesOpen : true
            });
        }
        else {
            this.props.onPageChange("CSManageInterventionsForm");
        }
    };

    onUnsavedChangesDialogClose = (value) => {
        this.setState({
            dialogUnsavedChangesOpen : false
        });

        switch (value) {
            case "mrYes" : this.props.onPageChange("CSManageInterventionsForm"); break;
            case "mrNo"  : break;
            default      : break;
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {

        const { countryIdx, regionIdx, surveyIdx, countryNames, regionNames, surveyNames } = this.state;

        let styles = {
            content : {
                margin: '0 10px'
            }
        };

        return (
            <div className="innerPage">

                <CSStepper 
                    steps={[(this.props.projectCreated) ? RS('GB_stModProjection') : RS('GB_stConfigProj'), RS('GB_stManageInterventions'), RS('GB_stReviewDefaultData'), RS('GB_stViewResults')]}
                    activeStep={0}
                    completedSteps={[]}
                    onStepBtnClick={this.onStepChange}
                    useAltColors={this.props.projectCreated}
                />

                <div style={styles.content}>
                    {/* <Header
                        projectCreated={this.props.projectCreated && !this.state.openSubNatDrawer}
                    /> */}

                    {
                        (this.props.projectCreated && !this.state.openSubNatDrawer) ?
                            <SubHeader /> :
                            null
                    }

                    <ProjectionName
                        projectionName={this.state.projectionName}
                        onChange={this.onProjNameChange}
                    />

                    {
                        (!this.state.loading) ?
                            <React.Fragment>
                                <CountryDropDown
                                    disabled={this.props.projectCreated}
                                    items={countryNames}
                                    itemIndex={countryIdx}
                                    onChange={this.onAttemptCountryChange}
                                />

                                <RegionDropDown
                                    disabled={this.props.projectCreated}
                                    items={regionNames}
                                    itemIndex={regionIdx}
                                    onChange={this.onRegionChange}
                                />

                                <SurveyDropDown
                                    disabled={this.props.projectCreated}
                                    items={surveyNames}
                                    itemIndex={surveyIdx}
                                    onChange={this.onSurveyChange}
                                />
                            </React.Fragment>
                            : null
                    }
                    
                    {/* {
                        this.props.projectCreated ?
                            <ModifyProjectionName
                                value={this.props.selectedCountry + ", " + this.props.subnatRegion + ", " + this.props.subnatSurvey}
                            />
                        : null
                    } */}

                    <YearsSlider
                        isSubnatProjection={this.state.isSubnatProjection}
                        disabledMin={this.props.projectCreated || this.state.subnatDone || this.state.isSubnatProjection}
                        yearRange={this.state.yearRange}
                        onChange={this.onYearsChange}
                        onChangeComplete={this.onYearsChange}
                    />

                    {
                        this.state.isSubnatProjection && !this.props.projectCreated ?
                            <SubnationalButton
                                onClick={this.onSubnationalBtnClick}
                                subnatDone={this.state.subnatDone}
                            /> : null
                    }

                    {
                        (this.state.openSubNatDrawer) ?
                            <SubNatBlueBox
                                onAddTasks={this.props.onAddTasks}
                                onStateChange={this.props.onStateChange}
                                onClose={this.onSubnationalDrawerClose}
                                onSave={this.onSubnationalDrawerSave}
                                subnatDone={this.state.subnatDone || this.props.projectCreated}
                                subnatModvars={this.state.subnatModvars}
                                IVInfo={this.props.IVInfo}
                                IVSubGroupInfo={this.props.IVSubGroupInfo}
                                countryISO={this.state.countryISO}
                                yearRange={this.state.yearRange}
                                subnatRegion={this.props.countries[countryIdx].Regions[regionIdx].name}
                                subnatSurvey={this.props.countries[countryIdx].Regions[regionIdx].Surveys[surveyIdx].name}
                                onRetrievedSubnatModvars={this.onRetrievedSubnatModvars}
                                projectionName={this.state.projectionName}
                                language={this.props.language}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            /> : null
                    }

                    {/*<Approach*/}
                    {/*    approach={this.props.approach}*/}
                    {/*    onStateChange={this.props.onStateChange}*/}
                    {/*/>*/}

                    {
                        (this.props.projectCreated) ?
                            <ReloadDefaultDataCheckBox
                                reloadDefaultData={this.state.reloadDefaultData}
                                onChange={this.onReloadDefaultDataCheckBoxChange}
                            /> : null
                    }

                    <NotesAndInfoExpPanel
                        authorName={this.state.authorName}
                        onAuthorChange={this.onAuthorChange}
                        orgName={this.state.orgName}
                        onOrganizationChange={this.onOrganizationChange}
                        projectionNotes={this.state.projectionNotes}
                        onProjectionNotesChange={this.onProjectionNotesChange}
                        isMobile={this.props.isMobile}
                    />

                    {
                        (this.props.projectCreated) ?
                            <div>
                                <ModifyButton
                                    disabled = {!this.state.changed}
                                    onClick  = {this.onModifyProjectionClick}
                                /> 
                                {this.renderNextBtn()}
                            </div> 
                            :
                            <CreateButton
                                onClick  = {this.onCreateNewProjectionClick}
                            />
                    }

                    {
                        (this.state.openSubnatDataLossDialog) ?
                            <SubnatCheckDialog
                                visible={true}
                                onYes={this.onSubnatDataLossDialogYes}
                                onNo={this.onSubnatDataLossDialogNo}
                            /> : null
                    }

                    {
                        (this.state.dialogUnsavedChangesOpen) ?
                            <TCSUnsavedChangesDialog 
                                onClose={this.onUnsavedChangesDialogClose}
                            /> :
                            null
                    }
                </div>
            </div>
        );
    }
}

export default CSProjManagerForm2;