import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {SBCausesEditor} from "../../data/HealthStatus/Stillbirths/SBCausesEditor";

class CSStillbirthsDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1         : PropTypes.object,
        packTable2         : PropTypes.object,
        onPackTable1Change : PropTypes.func,
        onPackTable2Change : PropTypes.func,
    };

    static defaultProps = {
        packTable1         : null,
        packTable2         : null,
        onPackTable1Change : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change : (packTable) => console.log('onPackTable2Change'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable);
    };

    onPackTable2Change = (packTable) => {
        packTable = SBCausesEditor.RecalcGrid(packTable);
        this.props.onPackTable2Change(packTable);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {2}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstStillbirthRateSheetID}
                    packTable={packTable1}
                    showTitle={true}
                    onChange={this.onPackTable1Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstStillbirthsByCauseSheetID}
                    packTable={packTable2}
                    showTitle={true}
                    onChange={this.onPackTable2Change}
                />

            </div>
        );
    }

}

export default CSStillbirthsDrawer;