import React from 'react';
import PropTypes from "prop-types";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddCircle from "@material-ui/icons/AddCircle";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FilterList from '@material-ui/icons/FilterList';
import CloseIcon from "@material-ui/icons/Close";
import FileUpload from "@material-ui/icons/Publish";
import {Close, MoreHoriz} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TButton from '@common/components/TButton';
import TEdit from '@common/components/TEdit';
import TDialog from "@common/components/TDialog";
import TSwitch from "@common/components/TSwitch";
import TCheckBox from "@common/components/TCheckBox";
import TPaper from "@common/components/TPaper";
import TLabel from "@common/components/TLabel";
import RS from "@common/strings/RS";
import Theme from "../../app/Theme";
import CSDialog from "../dialogs/CSDialog";
import NoButton from "../buttons/NoButton";
import YesButton from "../buttons/YesButton";
import ClearButton from "../buttons/ClearButton";
import ApplyButton from "../buttons/ApplyButton";
import AddButton from "../buttons/AddButton";
import SaveButton from "../buttons/SaveButton";
// import Settings from '@material-ui/icons/Settings';
// import FolderOpen from "@material-ui/icons/FolderOpen";
// import Create from "@material-ui/icons/Create";
// import ContentCopy from "@material-ui/icons/FileCopy";
// import FileDownload from "@material-ui/icons/GetApp";
// import Delete from "@material-ui/icons/Delete";

class TGBProjectionTable extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        storedProjections    : PropTypes.array.isRequired,
        activeProjections    : PropTypes.array,
        topicList            : PropTypes.array,
        onOpenProjection     : PropTypes.func,
        onDeleteProjection   : PropTypes.func,
        onDownloadProjection : PropTypes.func,
        onCopyProjection     : PropTypes.func,
        onRenameProjection   : PropTypes.func,
        onSaveTopic          : PropTypes.func,
        onGetTopicList       : PropTypes.func,
        onStateChange        : PropTypes.func,
        onProjectionsSelectedChanged: PropTypes.func,

        configureProject     : PropTypes.func,
        saveProject          : PropTypes.func,
        configurable         : PropTypes.bool,
        style                : PropTypes.object,
        rowStyle             : PropTypes.object,
        isDeskTop            : PropTypes.bool,
        topicFilter          : PropTypes.array,
        tableTitle           : PropTypes.string,
        deleteMsg            : PropTypes.string,
        deleteAllMsg         : PropTypes.string,
        dialogDelAll         : PropTypes.string,
        multiselect          : PropTypes.array,

        showRegion           : PropTypes.bool,
        showTimeframe        : PropTypes.bool,
        showLastSave         : PropTypes.bool,
        showTags             : PropTypes.bool,
        showNotes            : PropTypes.bool,
        showAuthor           : PropTypes.bool,
        showOrg              : PropTypes.bool,
        showMeatballMenu     : PropTypes.bool,
        showDetailsSwitch    : PropTypes.bool,
        showFilterTagsBtn    : PropTypes.bool,
        showUploadBtn        : PropTypes.bool,
        meatballOptions      : PropTypes.array
    };

    static defaultProps = {
        storedProjections     : [{ name : "TestProjName", country : "TestCountry", lastSaved : "TestLastSaved", years : "TestYears" }],
        activeProjections     : [],
        topicList             : [],
        onOpenProjection      : () => console.log('onOpenProjection'),
        onDeleteProjection    : () => console.log('onDeleteProjection'),
        onDownloadProjection  : () => console.log('onDownloadProjection'),
        onCopyProjection      : () => console.log('onCopyProjection'),
        onRenameProjection    : () => console.log('onRenameProjection'),
        onSaveTopic           : () => console.log('onSaveTopic'),
        onGetTopicList        : () => console.log('onGetTopicList'),
        onStateChange         : () => console.log("onStateChange"),
        onProjectionsSelectedChanged : () => console.log("onProjectionsSelectedChanged"),

        configureProject      : () => console.log('configureProject'),
        saveProject           : () => console.log('saveProject'),
        configurable          : false,
        style                 : {},
        rowStyle              : {},
        isDeskTop             : window.innerWidth > 1075,
        topicFilter           : [],
        tableTitle            : RS("GB_stProjectionLibrary"),
        deleteMsg             : RS("GB_stDelete"),
        deleteAllMsg          : RS("GB_stDeleteAll"),
        dialogDelAll          : RS("GB_stSureDeleteAll"),
        multiselect           : [],

        showRegion            : true,
        showTimeframe         : true,
        showLastSave          : true,
        showTags              : true,
        showNotes             : true,
        showAuthor            : true,
        showOrg               : true,
        showMeatballMenu      : true,
        showDetailsSwitch     : true,
        showFilterTagsBtn     : true,
        showUploadBtn         : true,
        meatballOptions       : ["Open", "Rename", "Duplicate", "Download", "Delete", "Tags"]
    };

    constructor(props) {
        super(props);

        this.state = {
            actionsOpen        : -1,
            dir                : "desc",
            colSort            : RS('GB_stLastSaved'),
            notesVis           : false,
            notesShown         : "",
            notesCap           : "",
            notesAuth          : "",
            notesOrg           : "",
            newTopic           : "",
            topicFile          : "",
            newTopicOpen       : false,
            charLimit          : false,
            showDetails        : false,
            storedProjections  : JSON.parse(JSON.stringify(this.props.storedProjections)), //storing original order to undo sort
            topicList          : this.props.topicList,
            topicFilter        : [],
            titlePos           : -1,
            anchor             : null,
            actionFile         : "",
            multiselect        : this.props.multiselect,
            deleteAllQuery     : false,
            filterTagsOpen     : false,
            chosenTags         : this.props.topicFilter,
            tagsHaveChanged    : false,
            tagsOpen           : false,
            tagManagerOpen     : false,
            newTagOpen         : false,
            fileCopyOpen       : false,
            fileRenameOpen     : false,
            copiedFileName     : "",
            renamedFile        : "",
            fileToCopy         : "",
            fileToRename       : "",
            fileForActions     : "",
            tagFiltersChanged  : false,
            saveBtnPos         : 5,
            tagNotes           : "",
            selectedTags       : [],
            tagLimit           : 10,
        };

    };

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if ((JSON.stringify(this.props) !== JSON.stringify(nextProps)) || (JSON.stringify(this.state) !== JSON.stringify(nextState))) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    componentDidMount() {
        this.props.onStateChange({topicFilter: []});
        this.props.onGetTopicList();

        setTimeout(() => {
            this.setState({
                topicList: JSON.parse(JSON.stringify(this.props.topicList))
            });
        }, 0);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            storedProjections  : JSON.parse(JSON.stringify(newProps.storedProjections)),
            //topicList          : JSON.parse(JSON.stringify(newProps.topicList)),
            // newTopic           : "",
            topicFilter        : newProps.topicFilter,
            chosenTags         : newProps.topicFilter
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onOpenButtonClick = (projectName, e) => {
        //e.stopPropagation();
        this.props.onOpenProjection(this.state.multiselect.length > 0 ? this.state.multiselect[0] : projectName);
        this.onMultiSelect(this.state.multiselect.includes(projectName), e, projectName);
        this.onMenuClose();
        //this.setState({actionsOpen: -1});
    };

    onDeleteButtonClick = (projectName) => {
        if (this.state.multiselect.length > 1) {
            //this.props.onDeleteProjection(this.state.multiselect);
            this.setState({
                deleteAllQuery: true,
                anchor: null
            });
        } else {
            this.setState({
                anchor: null,
                multiselect: []
            }, () => this.props.onDeleteProjection(projectName));
        }
        //this.setState({actionsOpen: -1});
    };

    onDeleteAll = (arr) => {
        this.setState({
            deleteAllQuery: false,
            multiselect: []
        }, () => this.props.onDeleteProjection(arr));
    };

    onDownloadButtonClick = (projectName) => {
        this.props.onDownloadProjection(this.state.multiselect.length > 0 ? this.state.multiselect[0] : projectName);
        this.onMenuClose();
        //this.setState({actionsOpen: -1});
    };

    // onConfigureButtonClick = (proj, projectName) => {
    //     this.props.configureProject(proj, projectName);
    //     this.setState({actionsOpen: -1});
    // };
    //
    // onSaveButtonClick = (proj, projectName) => {
    //     this.props.saveProject(proj, projectName);
    //     this.setState({actionsOpen: -1});
    // };

    onCopyButtonClick = (projectName) => {
        this.setState({
            fileCopyOpen: true,
            fileToCopy: this.state.multiselect.length > 0 ? this.state.multiselect[0] : projectName,
            copiedFileName: (this.state.multiselect.length > 0 ? this.state.multiselect[0] : projectName) + "-copy"
        });
        this.onMenuClose();
        //this.setState({actionsOpen: -1});
    };

    onRenameButtonClick = (projectName) => {
        this.setState({
            fileRenameOpen: true,
            fileToRename: this.state.multiselect.length > 0 ? this.state.multiselect[0] : projectName,
            renamedFile: this.state.multiselect.length > 0 ? this.state.multiselect[0] : projectName
        });
        this.onMenuClose();
        //this.setState({actionsOpen: -1});
    };

    onEditTagsClick = (file, notes, tags) => {
        this.setState({
            tagsOpen: true,
            fileForActions: file,
            tagNotes: notes,
            selectedTags: tags.split(", ")
        }, () => {
            this.onMenuClose();
        });
    };

    // onCogClick = (n) => {
    //     this.setState({actionsOpen: this.state.actionsOpen === n ? -1 : n});
    // };

    onMenuClick = (e) => {
        this.setState({
            anchor: e.currentTarget,
            actionFile: e.currentTarget.parentNode.parentNode.children[1].children[0].innerText
        });
    };

    onMenuClose = () => {
        this.setState({
            anchor: null,
            actionFile: null
        });
    };

    onFilterTopicClick = () => {
        this.setState({
            filterTagsOpen: true
        });
    };

    onUploadProjectionClick = () => {
        if (this.props.activeProjections.length === 0) {
            document.querySelector("#hidden_uploader").click();
        } else {
            alert(RS("GB_stMustCloseToUpload"));
        }
    };

    onSort = (sorter) => {
        //if it's already set, then either switch direction or return to normal in sequence
        if (sorter === this.state.colSort) {
            if (this.state.dir === "desc") {
                this.setState({dir: "asc"});
            } else {
                this.setState({colSort: ""});
            }
        } else {
            //if it's the first click of that column, start with a descending sort
            this.setState({colSort: sorter, dir: "desc"});
        }
    };

    onSaveTopicClick = (v, f) => {
        if (v === RS("GB_stEnterNewTopic")) {
            this.setState({
                newTopicOpen: true,
                topicFile: f
            });
        } else if (v === RS("GB_stRemoveTopic")) {
            this.props.onSaveTopic("", f);
        } else {
            this.props.onSaveTopic(v, f);
            this.setState({newTopicOpen: false});
        }
    };

    onShowNotes = (projName, notes, e) => {
        e.stopPropagation();
        this.setState({
            notesVis: true,
            notesShown: notes,
            notesCap:projName
        });
    };

    onParseTopic = (valu) => {
        if (valu.length <= 15) {
            this.setState({
                newTopic: valu,
                charLimit: false
            });
        } else {
            this.setState({charLimit: true});
        }
    };

    onMultiSelect = (checked, event, val, caption, customProp, customProp2) => {
        let multiselect = JSON.parse(JSON.stringify(this.state.multiselect));
        if (multiselect.includes(val)) {
            for (let i = multiselect.length - 1; i >= 0; i--) {
                if (multiselect[i] === val) {
                    multiselect.splice(i, 1);
                }
            }
        } else {
            multiselect.push(val);
        }
        this.setState({multiselect: multiselect}, () => this.props.onProjectionsSelectedChanged(multiselect));
    };

    onCheckAll = () => {
        if (this.state.multiselect.length > 0) {
            this.setState({multiselect: []}, () => this.props.onProjectionsSelectedChanged([]));
        } else {
            this.setState({multiselect: this.filterTags(this.props.storedProjections).map((item) => item.name)}, () => this.props.onProjectionsSelectedChanged(this.state.multiselect));
        }
    };

    onCloseFilterTags = () => {
        this.setState({filterTagsOpen: false});
    };

    onFilterBtnClick = () => {
        this.props.onStateChange({topicFilter: this.state.chosenTags});
        this.onCloseFilterTags();
    };

    onClearFilterBtnClick = () => {
        this.props.onStateChange({topicFilter: []});
        this.onCloseFilterTags();
    };

    onFilterCheckBoxChange = (val) => {
        let arr = JSON.parse(JSON.stringify(this.state.chosenTags));
        if (arr.includes(val)) {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (arr[i] === val) {
                    arr.splice(i, 1);
                }
            }
        }
        else {
            arr.push(val);
        }
        this.setState({
            chosenTags: arr,
            tagFiltersChanged: true
        });
    };

    onSaveTags = () => {
        this.props.onSaveTopic("", this.state.fileForActions, this.state.selectedTags.length > 0 ? this.state.selectedTags.join(", ") : "");
        this.setState({
            tagsHaveChanged: false,
            tagsOpen: false
        });
    };

    onTagsClose = () => {
        this.setState({
            tagsOpen: false,
            //topicList: this.props.topicList
        });
    };

    onTagBoxScroll = (e) => {
        this.setState({saveBtnPos: 5 - e.target.scrollTop});
    };

    onAddTagBtnClick = () => {
        if (this.state.topicList.length === this.state.tagLimit) {
            alert(RS("GB_stTagLimit") + ": " + this.state.tagLimit);
        } else {
            this.setState({newTagOpen: true});
        }
    };

    onManageTagBtnClick = () => {
        this.setState({
            tagManagerOpen: true
        });
    };

    onTagManagerClose = () => {
        this.setState({
            tagManagerOpen: false
        });
    };

    onDeleteTag = (tag) => {
        let tags = JSON.parse(JSON.stringify(this.state.topicList));
        for (let i = tags.length - 1; i >= 0; i--) {
            if (tags[i] === tag) {
                tags.splice(i, 1);
            }
        }
        this.setState({
            topicList: tags
        }, () => {
            let projs = JSON.parse(JSON.stringify(this.props.storedProjections));
            for (let j = 0; j < projs.length; j++) {
                let projTags = projs[j].tag.split(", ");
                for (let k = projTags.length - 1; k >= 0; k--) {
                    if (projTags[k] === tag) {
                        projTags.splice(k, 1);
                        this.props.onSaveTopic("", projs[j].projName, projTags.join(", "));
                    }
                }
                projs[j].tag = projTags.join(", ");
            }
            this.props.onStateChange({
                storedProjections: projs,
                tagList: tags
            })
        });
    };

    onTagsCheckBoxChange = (tag) => {
        let tags = JSON.parse(JSON.stringify(this.state.selectedTags));
        if (tags.includes("")) {
            tags.shift();
        }
        if (tags.length > this.state.tagLimit - 1 && !tags.includes(tag)) {
            alert(RS("GB_stTagLimit") + ": " + this.state.tagLimit);
        } else {
            if (tags.includes(tag)) {
                for (let i = tags.length - 1; i >= 0; i--) {
                    if (tags[i] === tag) {
                        tags.splice(i, 1);
                    }
                }
                //this.props.onSaveTopic(tag, this.props.fileForActions, tags.length > 0 ? tags.join(", ") : "");
            } else {
                tags.push(tag);
                tags.sort(function (a, b) {
                    let scale = 0;
                    if (a.toLowerCase() < b.toLowerCase()) {
                        scale = -1;
                    } else {
                        scale = 1;
                    }
                    return scale;
                });
                //this.props.onSaveTopic(tag, this.props.fileForActions);
            }
            this.setState({
                selectedTags: tags,
                tagsHaveChanged: true
            }, /*() => this.props.onStateChange({chosenTags: tags.length > 0 ? tags.join(", ") : ""})*/);
        }
    };

    onCloseNewTagDialog = () => {
        this.setState({
            newTagOpen: false,
            newTopic: ""
        });
    };

    onSaveNewTagBtnClick = () => {
        //this.props.onSaveTopic(this.state.newTopic, this.state.fileForActions);
        let arr = JSON.parse(JSON.stringify(this.state.selectedTags));
        let topics = JSON.parse(JSON.stringify(this.state.topicList));
        if (topics.includes(this.state.newTopic)) {
            alert(RS("GB_stTagExists"));
        } else {
            arr.push(this.state.newTopic);
            topics.push(this.state.newTopic);
            arr.sort(function (a, b) {
                let scale = 0;
                if (a.toLowerCase() < b.toLowerCase()) {
                    scale = -1;
                } else {
                    scale = 1;
                }
                return scale;
            });
            topics.sort(function (a, b) {
                let scale = 0;
                if (a.toLowerCase() < b.toLowerCase()) {
                    scale = -1;
                } else {
                    scale = 1;
                }
                return scale;
            });
            this.setState({
                selectedTags: arr,
                topicList: topics,
                tagsHaveChanged: true
            });
            this.onCloseNewTagDialog();
            //this.onTagsClose();
        }
    };

    onTypeFileCopy = (val) => {
        this.setState({
            copiedFileName: val
        });
    };

    onCloseFileCopy = () => {
        this.setState({
            fileCopyOpen: false
        });
    };

    onSaveFileCopy = () => {
        this.props.onCopyProjection(this.state.fileToCopy, this.state.copiedFileName);
        this.onCloseFileCopy();
    };

    onTypeFileRename = (val) => {
        this.setState({
            renamedFile: val
        });
    };

    onCloseFileRename = () => {
        this.setState({
            fileRenameOpen: false
        });
    };

    onSaveFileRename = () => {
        this.props.onRenameProjection(this.state.fileToRename, this.state.renamedFile);
        this.onCloseFileRename();
    };

    //==================================================================================================================
    //
    //                                                 Function
    //
    //==================================================================================================================


    filterTags = (src) => {
        let filter = this.state.topicFilter;
        let arr = [];
        src.forEach(function(o) {
            if (filter.length === 0) {
                arr.push(o);
            } else {
                for (let i = 0; i < filter.length; i++) {
                    if (o.tag.includes(filter[i]) && !arr.includes(o)) {
                        arr.push(o);
                    }
                }
            }
        });
        return arr;
    };

    sortTags = (src) => {
        let headerNames = [RS('GB_stName'), RS('GB_stCountry') + '/' + RS('GB_stRegion'), RS('GB_stTimeFrame'), RS('GB_stLastSaved'), RS('GB_stTags'), RS('GB_stNotes')/*, RS('GB_stAuthor'), RS('GB_stOrganization')*/];
        let sorts = ["name", "country", "years", "lastSaved", "tag", "authorNotes"/*, "author", "organization"*/];
        let sortables = [];
        //this arrangement allows the keys and values to pair no matter the language choice
        for (let i = 0; i < headerNames.length; i++) {
            sortables[headerNames[i]] = sorts[i];
        }

        let that = this;

        //if colSort is set, sort in the set order
        if (sortables[that.state.colSort]) {
            src.sort(function (a, b) {
                let ans = 0;
                //reversing the order when it's ascending
                let mult = that.state.dir === "asc" ? -1 : 1;
                //using lowercase to make sure everything stays in alphabetical order, even if some are capitalized and some not
                //basing last saved on actual value, not alphabetical
                let critA = that.state.colSort === RS('GB_stLastSaved') ? (new Date(a.lastSaved)).getTime() : a[sortables[that.state.colSort]].toLowerCase();
                let critB = that.state.colSort === RS('GB_stLastSaved') ? (new Date(b.lastSaved)).getTime() : b[sortables[that.state.colSort]].toLowerCase();
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    ans = mult * -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    ans = mult * 1;
                }
                if (critA < critB) {
                    ans = mult * -1;
                }
                if (critA > critB) {
                    ans = mult * 1;
                }
                return ans;
            });
        // } else {
        //     //set it back to original order when there's no column sort
        //     for (let i = 0; i < src.length; i++) {
        //         src[i] = this.state.storedProjections[i];
        //     }
        }
        return src;
    };

    abbrMonth = (d) => {
        return d.replace("January", "Jan").replace("February", "Feb").replace("March", "Mar").replace("April", "Apr").replace("May", "May").replace("June", "Jun").replace("July", "Jul").replace("August", "Aug").replace("September", "Sep").replace("October", "Oct").replace("November", "Nov").replace("December", "Dec");
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderTableHeader = () => {

        let headerNames = [this.renderCheckAll(), RS('GB_stName'), RS('GB_stCountry') + '/' + RS('GB_stRegion'), RS('GB_stTimeFrame'), RS('GB_stLastSaved'), RS('GB_stTags')];

        if (!this.props.showTags) {
            headerNames.splice(5, 1);
        }

        if (!this.props.showLastSave) {
            headerNames.splice(4, 1);
        }

        if (!this.props.showTimeframe) {
            headerNames.splice(3, 1);
        }

        if (!this.props.showRegion) {
            headerNames.splice(2, 1);
        }

        if (this.state.showDetails) {
            if (this.props.showNotes) {
                headerNames.push(RS('GB_stNotes'));
            }
            if (this.props.showAuthor) {
                //headerNames.push(RS('GB_stAuthor'));
            }
            if (this.props.showOrg) {
                //headerNames.push(RS('GB_stOrganization'));
            }
        }

        if (this.props.showMeatballMenu) {
            headerNames.push(" ");
        }

        return (
            <TableHead>
                <TableRow style={{...this.props.rowStyle}}>
                    {
                        headerNames.map((item, i) => (
                            <TableCell
                                key={i}
                                sortDirection={this.state.colSort === item ? this.state.dir : false}
                                style={styles.cell}
                            >
                                {item === RS('GB_stTags') && this.props.topicFilter.length  > 0 ? <FilterList style={{position:"relative", top:"10px"}} /> : null}
                                {i > 0 && i < headerNames.length - 1 ?
                                    <TableSortLabel
                                        onClick={() => item === " " ? null : this.onSort(item)}
                                        style={{cursor: item === " " ? "default" : "pointer"}}
                                        direction={this.state.dir}
                                        active={item === this.state.colSort}>{item}
                                    </TableSortLabel>
                                    : item
                                }
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
        );
    };

    renderTableBody = () => {
        const noteStyle = styles.note;

        const cellStyle = styles.cell;

        let menuAnchor = this.state.anchor;

        const tableRows =
            this.sortTags(this.filterTags(this.props.storedProjections)).map((item, i) => (
                <TableRow
                    key={i}
                    onMouseEnter={() => { if (this.props.isDeskTop) {this.setState({actionsOpen: i})}}}
                    onMouseLeave={() => { if (this.props.isDeskTop) {this.setState({actionsOpen: -1})}}}
                    // style={{'td &:hover': {background: "hotpink"}}}
                    // onClick={() => this.onEditTagsClick(item.name, item.authorNotes, item.tag)}
                    style={{background: this.state.actionsOpen === i || this.state.actionFile === item.name ? "rgb(243, 242, 241)" : "white", ...this.props.rowStyle}}
                >
                    <TableCell style={cellStyle}
                        // title={this.state.titlePos === i ? RS("GB_stFileActions") : null}
                    >
                        {/*{this.state.actionsOpen === i || this.state.multiselect.length > 0 || !this.props.isDeskTop ?*/}
                            <TCheckBox
                                caption={""}
                                onClick={this.onMultiSelect}
                                value={this.state.multiselect.includes(item.name)}
                                enabled={true}
                                name={item.name}
                                style={{padding: 0, visibility: this.state.actionsOpen === i || this.state.multiselect.length > 0 || !this.props.isDeskTop ? "visible" : "hidden"}}
                                // style={{height: 12}}
                                //boxStyle={{height: 12/*, top: -3, visibility: this.state.multiselect.length > 0 || !this.props.isDeskTop ? "visible" : "hidden"*/}}
                                //boxStyle={{color: this.state.actionsOpen === i || this.state.multiselect.length > 0 ? "black" : "white"}}
                                //style={{visibility: this.state.actionsOpen === i || this.state.multiselect.length > 0 ? "visible" : "hidden"}}
                            />
                            {/*:*/}
                            {/*<div style={{width: 42, height: 30}}></div>}*/}
                            {/*<TCheckBox caption={""} boxStyle={{color: "white"}} />*/}
                        
                        {/*<Settings*/}
                        {/*    style={{...styles.cogwheel, float: this.props.isDeskTop ? "right" : "left", visibility: this.state.actionsOpen === i || !this.props.isDeskTop ? "visible" : "hidden"}}*/}
                        {/*    onMouseOver={() => this.setState({titlePos: i})}*/}
                        {/*    onMouseLeave={() => this.setState({titlePos: -1})}*/}
                        {/*/>*/}
                    </TableCell>
                    <TableCell
                        style={{whiteSpace:"initial", width: 500, overflow: "hidden", ...cellStyle}}
                    >
                        <span style={{...styles.openLink}} onClick={(e) => this.onOpenButtonClick(item.name, e)}>{item.name}</span>
                    </TableCell>
                    {this.props.showRegion ?
                        <TableCell style={{...cellStyle, width: 200}}>{item.country}</TableCell>
                        : null
                    }
                    {this.props.showTimeframe ?
                        <TableCell style={{width: 100, ...cellStyle}}>{item.years}</TableCell>
                        : null
                    }
                    {this.props.showLastSave ?
                        <TableCell style={{width: 100, ...cellStyle}}>{this.abbrMonth(item.lastSaved)}</TableCell>
                        : null
                    }
                    {this.props.showTags ?
                        <TableCell style={{width: "auto", ...cellStyle}}>
                            {item.tag}
                            {/*{this.state.actionsOpen === i || !this.props.isDeskTop ?*/}
                            {/*    <Edit*/}
                            {/*        onClick={() => this.onEditTagsClick(item.name, item.authorNotes, item.tag)}*/}
                            {/*        color={"primary"}*/}
                            {/*        style={{*/}
                            {/*            verticalAlign: "middle",*/}
                            {/*            marginLeft: 5,*/}
                            {/*            cursor: "pointer",*/}
                            {/*            visibility: this.state.actionsOpen === i || !this.props.isDeskTop ? "visible" : "hidden"*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    : null*/}
                            {/*}*/}
                        </TableCell>
                        : null
                    }
                    {/*<TableCell*/}
                    {/*    title={this.state.titlePos === i ? RS("GB_stFileActions") : null}*/}
                    {/*>*/}
                    {/*    <Settings*/}
                    {/*        style={{...styles.cogwheel, float: this.props.isDeskTop ? "right" : "left", visibility: this.state.actionsOpen === i || !this.props.isDeskTop ? "visible" : "hidden"}}*/}
                    {/*        onMouseOver={() => this.setState({titlePos: i})}*/}
                    {/*        onMouseLeave={() => this.setState({titlePos: -1})}*/}
                    {/*    />*/}
                    {/*</TableCell>*/}
                    {this.state.showDetails && this.props.showNotes ?
                        <TableCell style={cellStyle}>
                            <p
                                style={noteStyle}
                                onClick={(e) => this.onShowNotes(item.name, item.authorNotes, e)}
                                title={item.authorNotes}
                            >
                                {item.authorNotes}
                            </p>
                        </TableCell>
                        : null
                    }
                    {/*{this.state.showDetails && this.props.showAuthor ? <TableCell style={cellStyle}>{item.author}</TableCell> : null}*/}
                    {/*{this.state.showDetails && this.props.showOrg ? <TableCell style={cellStyle}>{item.organization}</TableCell> : null}*/}
                    {this.props.showMeatballMenu ?
                        <TableCell
                            title={this.state.titlePos === i ? RS("GB_stFileActions") : null}
                            style={cellStyle}
                        >
                            {this.state.multiselect.length === 0 || this.state.multiselect.includes(item.name) ?
                                <MoreHoriz
                                    style={{
                                        ...styles.cogwheel,
                                        opacity: this.state.actionsOpen === i || !this.props.isDeskTop ? "1" : "0.5",
                                        float: "right",
                                        background: this.state.actionsOpen === i /*|| !this.props.isDeskTop*/ ? "lightgrey" : "white",
                                        border: "none"
                                    }}
                                    onMouseOver={() => this.setState({titlePos: i})}
                                    onMouseLeave={() => this.setState({titlePos: -1})}
                                    onClick={this.onMenuClick}
                                />
                                : null
                            }
                            {/*<Menu*/}
                            {/*    id={"action-menu"}*/}
                            {/*    anchorEl={menuAnchor}*/}
                            {/*    open={Boolean(menuAnchor)}*/}
                            {/*    onClose={this.onMenuClose}*/}
                            {/*    getContentAnchorEl={null}*/}
                            {/*    anchorOrigin={{*/}
                            {/*        vertical: 'bottom',*/}
                            {/*        horizontal: 'left'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <MenuItem onClick={() => this.onOpenButtonClick(this.state.actionFile)}>*/}
                            {/*        <FolderOpen style={{color: "#65A7EB"}} />*/}
                            {/*        {RS("GB_stOpen")}*/}
                            {/*    </MenuItem>*/}

                            {/*    <MenuItem onClick={() => this.onRenameButtonClick(this.state.actionFile)}>*/}
                            {/*        <Create style={{color: "#F78C30"}} />*/}
                            {/*        {RS("GB_stRename")}*/}
                            {/*    </MenuItem>*/}

                            {/*    <MenuItem onClick={() => this.onCopyButtonClick(this.state.actionFile)}>*/}
                            {/*        <ContentCopy style={{color: "#65a7eb"}} />*/}
                            {/*        {RS("GB_stDuplicate")}*/}
                            {/*    </MenuItem>*/}

                            {/*    <MenuItem onClick={() => this.onDownloadButtonClick(this.state.actionFile)}>*/}
                            {/*        <FileDownload style={{color: "#9CC025"}} />*/}
                            {/*        {RS("GB_stDownload")}*/}
                            {/*    </MenuItem>*/}

                            {/*    <MenuItem onClick={() => this.onDeleteButtonClick(this.state.actionFile)}>*/}
                            {/*        <Delete style={{color: "#C03600"}} />*/}
                            {/*        {RS("GB_stDelete")}*/}
                            {/*    </MenuItem>*/}
                            {/*</Menu>*/}
                        </TableCell>
                        : null
                    }
                </TableRow>
            ));

        return (
            <TableBody>
                {tableRows}
                <Menu
                    id={"action-menu"}
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={this.onMenuClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    {this.state.multiselect.length <= 1 && this.props.meatballOptions.includes("Open") ?
                        <MenuItem onClick={() => this.onOpenButtonClick(this.state.actionFile)}>
                            {/*<FolderOpen style={{color: "#65A7EB"}} />*/}
                            {RS("GB_stOpen")}
                        </MenuItem>
                        : null
                    }

                    {this.state.multiselect.length <= 1 && this.props.meatballOptions.includes("Rename") ?
                        <MenuItem onClick={() => this.onRenameButtonClick(this.state.actionFile)}>
                            {/*<Create style={{color: "#F78C30"}} />*/}
                            {RS("GB_stRename")}
                        </MenuItem>
                        : null
                    }

                    {this.state.multiselect.length <= 1 && this.props.meatballOptions.includes("Duplicate") ?
                        <MenuItem onClick={() => this.onCopyButtonClick(this.state.actionFile)}>
                            {/*<ContentCopy style={{color: "#65a7eb"}} />*/}
                            {RS("GB_stDuplicate")}
                        </MenuItem>
                        : null
                    }

                    {this.state.multiselect.length <= 1 && this.props.meatballOptions.includes("Download") ?
                        <MenuItem onClick={() => this.onDownloadButtonClick(this.state.actionFile)}>
                            {/*<FileDownload style={{color: "#9CC025"}} />*/}
                            {RS("GB_stDownload")}
                        </MenuItem>
                        : null
                    }

                    {this.props.meatballOptions.includes("Delete") ?
                        <MenuItem onClick={() => this.onDeleteButtonClick(this.state.actionFile)}>
                            {/*<Delete style={{color: "#C03600"}} />*/}
                            {this.state.multiselect.length > 1 ? this.props.deleteAllMsg : this.props.deleteMsg}
                        </MenuItem>
                        : null
                    }

                    {this.state.multiselect.length <= 1 && this.props.meatballOptions.includes("Tags") ?
                        <MenuItem onClick={() => this.onEditTagsClick(this.state.actionFile, this.props.storedProjections[this.props.storedProjections.map(x => x.name).indexOf(this.state.actionFile)].authorNotes, this.props.storedProjections[this.props.storedProjections.map(x => x.name).indexOf(this.state.actionFile)].tag)}>
                            {/*<FileDownload style={{color: "#9CC025"}} />*/}
                            {RS("GB_stTags")}
                        </MenuItem>
                        : null
                    }
                </Menu>
            </TableBody>
        );

    };

    renderCheckAll = () => {
        return (
            <TCheckBox
                caption={""}
                onClick={this.onCheckAll}
                value={this.state.multiselect.length > 0}
            />
        )
    };

    renderTopicCloseIcon = () => {

        let styles = {
            closeIcon : {
                float: 'right',
                height: '30px',
                width: '30px',
                cursor: 'pointer',
                marginTop: '-20px'
            }
        };

        return (
            <CloseIcon
                onClick={() => this.setState({newTopicOpen: false, newTopic:""})}
                style={styles.closeIcon}
            />
        );
    };

    renderNewTopicDialog = () => {
        return (
            <TDialog
                visible={this.state.newTopicOpen}
                caption={<h2 className="pageHeader">{RS("GB_stEnterNewTopic")}</h2>}
                content={this.renderNewTopicInput()}
                icon={this.renderTopicCloseIcon()}
                style={{paddingTop:"15px", paddingBottom:"0", width:"400px"}}
            />
        )
    };

    renderNewTopicInput = () => {
        //this random ID exists in order to confuse and prevent Google Chrome's autocomplete suggestions
        let ident = "";
        let choices ="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let rando = parseInt(Date.now().toString().substr(11),10);

        for (let i = 0; i < Math.ceil(Math.random() * rando); i++) {
            ident += choices.substr(Math.ceil(Math.random() * choices.length), 1);
        }

        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0 10px 0',
            },
            errorLabel : {
                color: "red",
                margin: 0
            },
        };

        return (
            <div>
                <TEdit
                    name={ident}
                    caption={RS('GB_stTopic')}
                    value={this.state.newTopic}
                    style={styles.edit}
                    onChange={(val) => this.onParseTopic(val)}
                    foc={true}
                    shrinkLabel={true}
                />

                <p
                    style={{
                        color: "red",
                        lineHeight: "normal",
                        margin: ".5em",
                        visibility: this.state.charLimit ? "visible" : "hidden"
                    }}
                >
                    {RS("GB_stCharacterLimit") + ": 15"}
                </p>
            </div>
        );
    };

    renderNotesPopup = () => {
        return (
            <TDialog
                visible={this.state.notesVis}
                content={this.state.notesShown}
                caption={this.state.notesCap}
                message={this.state.notesAuth + (this.state.notesAuth.length > 0 && this.state.notesOrg.length > 0 ? ", " : "") + this.state.notesOrg}
                actions={["mrClose"]}
                onClose={() => this.setState({notesVis: false})}
            />
        )
    };

    renderTableTitle = () => {
        return (
            <h2
                className="pageHeader"
                style={{...styles.pageHeader, ...window.innerWidth < 675 ? {width:"100%", float:"left"} : {}}}
            >
                {this.props.topicFilter.length > 0 ? this.props.tableTitle + " (" + RS('GB_stFilter') + ")" : this.props.tableTitle}
                {/*{this.state.multiselect.length > 1 ? this.renderDeleteButton() : null}*/}
            </h2>
        )
    };

    renderDeleteButton = () => {
        return (
            <TButton
                caption={RS("GB_stDelete")}
                containerStyle={{display: "inline-block", marginLeft: 30}}
                onClick={this.onDeleteButtonClick}
                color={"secondary"}
            />
        )
    };

    renderDetailsSwitch = () => {
        return (
            <TSwitch
                caption        = {RS("GB_stDetails")}
                value          = {this.state.showDetails}
                style          = {{float:"right", marginTop: "7px", zIndex:"1"}}
                onClick        = {() => this.setState({showDetails: !this.state.showDetails})}
            />
        )
    };

    renderFilterTagBtn = () => {
        return (
            <TButton
                caption        = {RS("GB_stFilter") + " " + RS("GB_stTags")}
                containerStyle = {{float:"right", zIndex:"1", marginRight:"10px"}}
                onClick        = {() => this.onFilterTopicClick()}
                pictureLeft    = {<FilterList />}
            />
        )
    };

    renderUploadProjectionBtn = () => {
        return (
            <TButton
                caption        = {RS("GB_stUploadProjection")}
                containerStyle = {{float:"right", zIndex:"1"}}
                onClick        = {() => this.onUploadProjectionClick()}
                pictureLeft    = {<FileUpload />}
            />
        )
    };

    renderHiddenInput = () => {
        return (
            <input
                onChange={() => this.props.onUploadProjection(document.getElementById("hidden_uploader").files[0])}
                id="hidden_uploader"
                type="file"
                style={{display:"none"}}
                accept={".pjnz"}
            />
        )
    };

    renderDeleteAllDialog = () => {
        return (
            <CSDialog
                open={this.state.deleteAllQuery}
                title={this.props.deleteAllMsg}
                onClose={() => this.setState({deleteAllQuery: false})}
                content={this.props.dialogDelAll}
                actions={
                    <React.Fragment>
                        <YesButton onClick={() => this.onDeleteAll(this.state.multiselect)} />
                        <NoButton colorType={"secondary"} onClick={() => this.setState({deleteAllQuery: false})} />
                    </React.Fragment>
                }
            />
        )
    };

    renderTagBox = () => {
        let tags = [];
        let topics = [];

        this.props.topicList.forEach((tag) => {
            let allTags = tag.split(", ");
            allTags.forEach(function(top) {
                if (!topics.includes(top)) {
                    topics.push(top);
                }
            });
        });

        topics.sort((a, b) => {
            let scale = 0;
            if (a.toLowerCase() < b.toLowerCase()) {
                scale = -1;
            }
            else {
                scale = 1;
            }
            return scale;
        });

        topics.forEach((topic) => {
            tags.push(
                <TCheckBox
                    key={topics.indexOf(topic)}
                    caption={topic}
                    value={this.state.chosenTags.includes(topic)}
                    onClick={() => this.onFilterCheckBoxChange(topic)}
                />
            );
        });

        if (topics.length === 0) {
            return "No topics are available";
        }
        else {
            return (
                <div>
                    {tags}
                </div>
            );
        }
    };

    renderFilterTagsDialog = () => {
        return (
            <CSDialog
                open={this.state.filterTagsOpen}
                modal={true}
                title={RS("GB_stFilter") + " " + RS("GB_stTags").toLowerCase()}
                onClose={this.onCloseFilterTags}
                minWidth={350}
                content={
                    <div>
                        {this.renderTagBox()}
                    </div>
                }
                actions={
                    <div>
                        <ClearButton onClick={this.onClearFilterBtnClick} disabled={this.state.chosenTags.length === 0} colorType="secondary" />
                        <ApplyButton onClick={this.onFilterBtnClick} disabled={!this.state.tagFiltersChanged} />
                    </div>
                }
            />
        );
    };

    renderTagActionBox = () => {
        let styles = {
            addBtn: {
                //float: 'right',
                // height: '30px',
                // width: '30px',
                cursor: 'pointer',
                // marginTop:"-5px",
                color: Theme.palette.primary.main,//'#f78c30',
                padding : 0,
            },
            box: {
                display: "inline-block",
                width: 300,
                margin: 3,
                marginTop: 4,
                marginBottom: 9
                // margin:"15px"
            },
            label: {
                display: "inline-block",
                marginRight: 10,
                marginTop: 0,
                marginBottom: 15,
                marginLeft: 0
            }
        };

        return (
            <div>
                {/*{this.renderActionButtons()}*/}
                <div style={styles.box}>
                    <TLabel
                        caption={RS("GB_stManageLibTags")}
                        style={styles.label}
                    />
                    <Tooltip title={RS("GB_stAddNewTag")}>
                        <IconButton onClick={this.onManageTagBtnClick} style={styles.addBtn}>
                            <AddCircle
                                // onClick={this.onManageTagBtnClick}
                                style={styles.addBtn}
                            />
                        </IconButton>
                    </Tooltip>
                    {this.renderTagDialogBox()}
                    {this.renderTagDialogNotes()}
                </div>
            </div>
        );
    };

    renderTagDialogBox = () => {
        let tags = [];
        let topics = [];

        this.state.topicList.forEach((tag) => {
            let allTags = tag.split(", ");
            allTags.forEach(function(top) {
                if (!topics.includes(top)) {
                    topics.push(top);
                }
            });
        });

        topics.sort((a, b) => {
            let scale = 0;
            if (a.toLowerCase() < b.toLowerCase()) {
                scale = -1;
            }
            else {
                scale = 1;
            }
            return scale;
        });

        topics.forEach((topic) => {
            tags.push(
                <TCheckBox
                    key={topics.indexOf(topic)}
                    caption={topic}
                    value={this.state.selectedTags.includes(topic)}
                    onClick={() => this.onTagsCheckBoxChange(topic)}
                />
            );
        });

        let styles = {
            // tagBox: {
            //     //maxHeight:this.props.actionNotes.length > 0 ? "150px" : "300px",
            //     minHeight:"100px",
            //     maxHeight: 387,
            //     height: "auto",
            //     overflowY:"auto",
            //     margin:"auto",
            //     marginBottom: 0,
            //     padding:"10px",
            //     // border:"1px solid black",
            //     position:"relative",
            //     width:"300px",
            // },
            tagBox: {
                width: '275 auto',
                height: 'auto',
                margin: '0 auto',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
                paddingBottom: 25,
                backgroundColor: "#ffffff",
            },
            addBtn: {
                float: 'right',
                height: '30px',
                width: '30px',
                cursor: 'pointer',
                marginTop:"-5px",
                color: '#f78c30',
                padding : 0,
            },
            saveBtn: {
                // float:"right",
                position:"absolute",
                bottom: this.state.saveBtnPos,
                right:"10px",
                zIndex:"1"
            },
            boxTitle: {
                display:"block",
                paddingTop:"0px",
                fontSize: 20,
                fontWeight: 'bold',
                marginBottom: 15,
                color: Theme.palette.secondary.main
            }
        };

        return (
            <TPaper
                style={styles.tagBox}
                onScroll={this.onTagBoxScroll}
            >
                {/*<Tooltip title={RS("GB_stAddNewTag")}>*/}
                {/*    <IconButton style={styles.addBtn}>*/}
                {/*        <AddCircle*/}
                {/*            onClick={this.onAddTagBtnClick}*/}
                {/*            style={styles.addBtn}*/}
                {/*        />*/}
                {/*    </IconButton>*/}
                {/*</Tooltip>*/}
                <span className={"pageHeader"} style={styles.boxTitle}>{RS("GB_stTags")}</span>
                {tags}
            </TPaper>
        )
    };

    renderTagDialogNotes = () => {
        let styles = {
            noteBox: {
                width: "350px",
                maxHeight:"150px",
                overflowY: "auto",
                margin:"10px",
                padding:"10px",
                //border:"1px solid black",
                display: this.state.tagNotes.length > 0 ? "block" : "none"
            },
            boxTitle: {
                fontSize: "18px",
                display:"block",
                paddingTop:"0px",
                marginBottom:"3px"
            }
        };

        return (
            <TPaper style={styles.noteBox}>
                <span className={"pageHeader"} style={styles.boxTitle}>{RS("GB_stNotes")}</span>
                {/*<hr style={{color:"lightgray"}} />*/}
                {this.state.tagNotes}
            </TPaper>
        );
    };

    renderTagsDialog = () => {
        let widthStyle = {
            minWidth: 0
        };

        return (
            <CSDialog
                open={this.state.tagsOpen}
                modal={true}
                title={/*RS("GB_stTags") + " " + RS("GB_stFor") + ": " + */(this.state.fileForActions.length > 20 ? this.state.fileForActions.substr(0, 20) + "..." : this.state.fileForActions)}
                onClose={this.onTagsClose}
                titleStyle={widthStyle}
                contentStyle={widthStyle}
                actionStyle={{...widthStyle, paddingTop: 0}}
                minWidth={350}
                content={
                    <div>
                        {this.renderTagActionBox()}
                    </div>
                }
                actions={
                    <div>
                        <ApplyButton onClick={this.onSaveTags} disabled={!this.state.tagsHaveChanged} />
                    </div>
                }
            />
        );
    };

    renderTagManagerDialog = () => {
        let styles = {
            addBtn: {
                // height: '30px',
                // width: '30px',
                cursor: 'pointer',
                // marginTop:"-5px",
                color: Theme.palette.primary.main,//'#f78c30',
                padding : 0,
            },
            width: {
                minWidth: 0
            },
            box: {
                display: "inline-block",
                width: 300,
                margin: 3,
                marginTop: 4,
                marginBottom: 9
                // margin:"15px"
            },
            label: {
                display: "inline-block",
                marginRight: 10,
                marginTop: 0,
                marginBottom: 15,
                marginLeft: 0
            }
        };

        return (
            <CSDialog
                open={this.state.tagManagerOpen}
                modal={true}
                title={RS("GB_stManageLibTags")}
                onClose={this.onTagManagerClose}
                titleStyle={styles.width}
                contentStyle={styles.width}
                minWidth={350}
                content={
                    <div style={styles.box}>
                        <TLabel
                            caption={RS("GB_stAddNewTag")}
                            style={styles.label}
                        />
                        <Tooltip title={RS("GB_stAddNewTag")}>
                            <IconButton onClick={this.onAddTagBtnClick} style={styles.addBtn}>
                                <AddCircle
                                    // onClick={this.onAddTagBtnClick}
                                    style={styles.addBtn}
                                />
                            </IconButton>
                        </Tooltip>
                        {this.renderTagDeletion()}
                    </div>
                }
            />
        );
    };

    renderTagDeletion = () => {
        const styles = {
            label: {
                display: "inline-block",
                height: 35
            },
            button: {
                width: 35,
                height: 35,
                padding: 0,
                float: 'right',
            },
            icon : {
                color : '#0f0d98',
                fontSize: 20,
                float: 'right',
            },
            tagBox: {
                width: '275 auto',
                height: 'auto',
                margin: '0 auto',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
                paddingBottom: 25,
                backgroundColor: "#ffffff",
            },
            boxTitle: {
                display:"block",
                paddingTop:"0px",
                fontSize: 20,
                fontWeight: 'bold',
                marginBottom: 15,
                color: Theme.palette.secondary.main
            }
        };

        let tags = [];
        let topics = [];

        this.state.topicList.forEach((tag) => {
            let allTags = tag.split(", ");
            allTags.forEach(function(top) {
                if (!topics.includes(top)) {
                    topics.push(top);
                }
            });
        });

        topics.sort((a, b) => {
            let scale = 0;
            if (a.toLowerCase() < b.toLowerCase()) {
                scale = -1;
            }
            else {
                scale = 1;
            }
            return scale;
        });

        topics.forEach((topic) => {
            tags.push(
                <div key={topics.indexOf(topic)}>
                    <Tooltip title={RS("GB_stDelete")}>
                        <IconButton
                            onClick={() => this.onDeleteTag(topic)}
                            style={styles.button}
                        >
                            <Close style={styles.icon} />
                        </IconButton>
                    </Tooltip>
                    <TLabel
                        caption={topic}
                        style={styles.label}
                    />
                </div>
            );
        });

        return (
            <TPaper style={styles.tagBox}>
                <span style={{...styles.pageHeader, ...styles.boxTitle}}>{RS("GB_stYourLibTags")}</span>
                {tags}
            </TPaper>
        )
    };

    renderNewTagDialog = () => {
        return (
            <CSDialog
                open={this.state.newTagOpen}
                modal={true}
                title={RS('GB_stEnterNewTopic')}
                onClose={this.onCloseNewTagDialog}
                minWidth={350}
                content={
                    <div>
                        {this.renderNewTopicInput()}
                    </div>
                }
                actions={
                    <div>
                        <AddButton onClick={this.onSaveNewTagBtnClick} disabled={this.state.newTopic.length === 0} />
                    </div>
                }
            />
        );
    };

    renderFileCopyDialog = () => {
        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0'
            }
        };

        return (
            <CSDialog
                open={this.state.fileCopyOpen}
                modal={true}
                title={RS("GB_stDuplicate") + " " + RS("GB_stProjection")}
                onClose={this.onCloseFileCopy}
                content={
                    <div>
                        <TEdit
                            name="CopiedFileNameField"
                            caption={RS("GB_stNewProjName")}
                            value={this.state.copiedFileName}
                            onChange={this.onTypeFileCopy}
                            style={styles.edit}
                            foc={true}
                            shrinkLabel={true}
                        />
                    </div>
                }
                actions={
                    <div>
                        <SaveButton onClick={this.onSaveFileCopy} disabled={this.state.copiedFileName.length === 0} />
                    </div>
                }
            />
        );
    };

    renderFileRenameDialog = () => {
        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0',
            }
        };

        return (
            <CSDialog
                open={this.state.fileRenameOpen}
                modal={true}
                title={RS("GB_stRename") + " " + RS("GB_stProjection")}
                onClose={this.onCloseFileRename}
                content={
                    <div>
                        <TEdit
                            name="CopiedFileNameField"
                            caption={RS("GB_stNewProjName")}
                            value={this.state.renamedFile}
                            onChange={this.onTypeFileRename}
                            style={styles.edit}
                            foc={true}
                            shrinkLabel={true}
                        />
                    </div>
                }
                actions={
                    <div>
                        <ApplyButton onClick={this.onSaveFileRename} disabled={this.state.renamedFile.length === 0} />
                    </div>
                }
            />
        );
    };

    render() {
        return (
            <div style={{padding:"10px"}}>
                {this.renderHiddenInput()}
                {this.props.showUploadBtn ? this.renderUploadProjectionBtn() : null}
                {this.props.showFilterTagsBtn ? this.renderFilterTagBtn() : null}
                {this.props.showDetailsSwitch ? this.renderDetailsSwitch() : null}
                {this.renderTableTitle()}
                {/*{this.state.multiselect.length > 1 ? this.renderDeleteButton() : null}*/}
                <Table
                    style={{...this.props.style}}
                >
                    {this.renderTableHeader()}
                    {this.renderTableBody()}
                </Table>
                {this.renderNotesPopup()}
                {this.renderNewTopicDialog()}
                {this.renderDeleteAllDialog()}
                {this.renderFilterTagsDialog()}
                {this.renderTagsDialog()}
                {this.renderTagManagerDialog()}
                {this.renderNewTagDialog()}
                {this.renderFileCopyDialog()}
                {this.renderFileRenameDialog()}
            </div>
        );
    }
}

const styles = {
    actions: {
        position: "absolute",
        right: "25px",
        border: "1px solid black",
        padding: "0 20px 20px 20px",
        background: "white"
    },
    cogwheel: {
        cursor:"pointer",
        // color:"#65A7EB",
        color: "#353535",
        //opacity:"0.25",
        opacity:"0.5",
        marginRight:"15px"
    },
    openLink: {
        color:"rgb(203, 104, 20)",
        cursor:"pointer",
        textDecoration:"underline",
        fontWeight:"bold"
    },
    pageHeader : {
        color: '#cb6814',
        paddingTop: '0px',
        zIndex:"-1",
        //display: "inline-block",
        fontSize: 24,
        borderBottom: "2px solid " + Theme.palette.secondary.main
    },
    cell : {
        padding: 0,
        fontSize: 12,
        lineHeight: 1
    },
    note : {
        textOverflow:"ellipsis",
        overflow:"hidden",
        whiteSpace:"nowrap",
        width:"200px",
        cursor:"pointer"
    }
};

export default TGBProjectionTable;