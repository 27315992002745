import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {getCheckBoxLabelStyle, getCheckBoxStyle, getBoxStyle} from "../../../../../../../../data/CSUtil";
import IntervCovExpandPanel from "./IntervCovExpandPanel";
import FilterEdit from "../../edit/FilterEdit";

//==================================================================================================================
//
//                                               Utilities
//
//==================================================================================================================

const CS_GetOutcomeName = (outcomeIdx) => {
    let outcome;

    switch (outcomeIdx) {
        case CONSTANTS.CS_MstMaternalMortalityExData : outcome = CONSTANTS.CS_MaternalEff_ED; break;
        case CONSTANTS.CS_MstStillbirthsExData       : outcome = CONSTANTS.CS_StillbirthEff_ED; break;
        case CONSTANTS.CS_MstNeonatalMortalityExData : outcome = CONSTANTS.CS_NNEff_ED; break;
        case CONSTANTS.CS_MstChildMortalityExData    : outcome = CONSTANTS.CS_PNNEff_ED; break;
        case CONSTANTS.CS_MstAdolescentsExData       : outcome = CONSTANTS.CS_AdolEff_ED; break;
        default                                      : outcome = ''; break;
    }

    return (outcome);
};

const CS_GetInterventionGroupMstIDs = (outcomeIdx, IVInfo) => {
    let outcomeName = CS_GetOutcomeName(outcomeIdx);

    let groupMstIDs = [];
    
    if ((outcomeName !== CONSTANTS.CS_MaternalEff_ED) && (outcomeName !== CONSTANTS.CS_StillbirthEff_ED)) {
        groupMstIDs.push(CONSTANTS.CS_MstBreastfeeding_ED)
    }

    if (outcomeName == CONSTANTS.CS_AdolEff_ED){
        groupMstIDs.push(CONSTANTS.CS_GroupMstID_FamPlanHIV);
    }
    else{        
        IVInfo.forEach(x => {
            let name = x[outcomeName];
            let groupMstID = x['GroupMstID'];

            if ((x.MstID !== CONSTANTS.IV_PMTCT) &&
                (x.MstID !== CONSTANTS.IV_Cotrimoxazole) &&
                (x.MstID !== CONSTANTS.IV_ART)) {
                if (name) {
                    if (groupMstIDs.indexOf(groupMstID) === -1) {
                        groupMstIDs.push(groupMstID);
                    }
                }
            }
        });
    }

    if (outcomeName !== CONSTANTS.CS_AdolEff_ED){
        groupMstIDs.splice(groupMstIDs.indexOf(CONSTANTS.CS_GroupMstID_FamPlanHIV));
    }

    return (groupMstIDs);
};

const CS_GetInterventionGroupCurrIDs = (groupMstIDs, interventionGroupList) => {
    let groupCurrIDs = [];

    for (let i = 0; i < groupMstIDs.length; i++) {
        for (let j = 0; j < interventionGroupList.length; j++) {
            if (groupMstIDs[i] === interventionGroupList[j].MstID) {
                groupCurrIDs.push(interventionGroupList[j].CurrID)
            }
        }
    }

    for (let k = 0; k < groupCurrIDs.length; k++) {
        for (let m = 0; m < (groupCurrIDs.length - k - 1); m++) {
            if (groupCurrIDs[m] > groupCurrIDs[m + 1]) {
                [groupCurrIDs[m], groupCurrIDs[m + 1]] = [groupCurrIDs[m + 1], groupCurrIDs[m]];
            }
        }
    }

    return (groupCurrIDs);
};

const CS_GetInterventionGroupMstIDFromCurrID = (groupCurrID, interventionGroupList) => {
    let groupMstID = 0;
    for (let i = 0; i < interventionGroupList.length; i++) {
        if (interventionGroupList[i].CurrID === groupCurrID) {
            groupMstID = interventionGroupList[i].MstID;
        }
    }

    return (groupMstID);
};

const CS_GetSubGroupMstIDs = (outcome, groupMstID, IVInfo) => {
    let subGroupMstIDs = [];

    if (groupMstID === CONSTANTS.CS_MstBreastfeeding_ED) {
        subGroupMstIDs.push(-1)
    }
    else {
        IVInfo.forEach(x => {
            if ((x['GroupMstID'] === groupMstID) && (x[outcome])) {
                if (subGroupMstIDs.indexOf(x['SubGroupMstID']) === -1) 
                    subGroupMstIDs.push(x['SubGroupMstID']);
            }
        })
    }
    
    return (subGroupMstIDs);
};

const CS_GetPanelName = (groupCurrID, interventionGroupList, language) => {
    let name = '';

    for (let i = 0; i < interventionGroupList.length; i++) {
        if (interventionGroupList[i].CurrID === groupCurrID) {
            name = interventionGroupList[i].Names[language]
        }
    }

    return (name);
};

const CS_GetSubGroupNameStyled = (subGroupMstID, interventionSubGroupList, language) => {
    let name = '';

    for (let i = 0; i < interventionSubGroupList.length; i++) {
        if (interventionSubGroupList[i].MstID === subGroupMstID) {
            name = interventionSubGroupList[i].Names[language]
        }
    }

    let styles = {
        nameStyle : {
            fontSize: '16px',
            color: '#3B78BC',
            marginBottom: '8px'
        }
    };

    return ({
        type  : "subGroupName",
        key   : 'n' + subGroupMstID,
        style : styles.nameStyle,
        name  : name,
    });
};

const CS_CheckIfInterventionIsOn = (mstID, selectedIVSet) => {
    return selectedIVSet.includes(mstID);
};

const CS_GetPanelContent = (outcomeIdx, groupCurrID, exploreBFContent, IVInfo, selectedIVSet, interventionGroupList, language, interventionSubGroupList, onCheckBoxChange, filterText) => {
    let panelContent = [];
    let outcome = CS_GetOutcomeName(outcomeIdx);
    let groupMstID = CS_GetInterventionGroupMstIDFromCurrID(groupCurrID, interventionGroupList);
    let subGroupMstIDs = CS_GetSubGroupMstIDs(outcome, groupMstID, IVInfo);

    const styles = {
        dividerStyle: {
            width: '265px',
            marginLeft: '-10px',
            marginTop: '10px',
            marginBottom: '10px'
        },
        checkBoxLabelStyle: {
            color: '#0c3a79',
            fontSize: '13px',
            lineHeight: '1'
        },
        checkBoxStyle: {
            height: '37px'
        },
        boxStyle: {
            // height: '5px'
        }
    };

    if (groupMstID === CONSTANTS.CS_MstBreastfeeding_ED) {
        for (let a = 0; a < exploreBFContent.length; a++) {

            const name = RS(exploreBFContent[a]['name']);
            const filterOK = name.toLowerCase().includes(filterText.toLowerCase());

            if (filterOK) {
                panelContent.push({
                    type        : "checkBox",
                    caption     : name,
                    labelStyle  : styles.checkBoxLabelStyle,
                    style       : styles.checkBoxStyle,
                    boxStyle    : styles.boxStyle,
                    key         : exploreBFContent[a]['name'],
                    color       : 'primary',
                    name        : '',
                    customProp  : exploreBFContent[a]['mstID'],
                    customProp2 : groupMstID,
                    value       : exploreBFContent[a]['checked'],
                    onClick     : (checked, event, name, caption, mstID, groupMstID) => this.props.onCheckBoxChange(checked, groupMstID, mstID, caption)
                });
            }
        }
    }
    else {
        if (groupMstID === CONSTANTS.CS_MstPregnancy_ED) {
            for (let j = 0; j < IVInfo.length; j++) {
                if ([CONSTANTS.IV_ANC1, CONSTANTS.IV_ANC4].includes(IVInfo[j]['MstID'])) {
                    const name = IVInfo[j]['Names'][language];
                    const filterOK = name.toLowerCase().includes(filterText.toLowerCase());

                    if (filterOK) {
                        panelContent.push({
                            type        : "checkBox",
                            caption     : name,
                            labelStyle  : getCheckBoxLabelStyle(IVInfo, IVInfo[j]['MstID']),
                            style       : getCheckBoxStyle(IVInfo, IVInfo[j]['MstID']),
                            boxStyle    : getBoxStyle(IVInfo, IVInfo[j]['MstID'], 'white'),
                            key         : IVInfo[j]['MstID'],
                            color       : 'primary',
                            name        : '',
                            customProp  : IVInfo[j]['MstID'],
                            customProp2 : groupMstID,
                            value       : CS_CheckIfInterventionIsOn(IVInfo[j]['MstID'], selectedIVSet),
                            onClick     : (checked, event, name, caption, mstID, groupMstID) => onCheckBoxChange(checked, groupMstID, mstID, caption)
                        });
                    }
                }
            }
        }

        if (groupMstID === CONSTANTS.CS_MstChildbirth_ED) {
            for (let j = 0; j < IVInfo.length; j++) {
                if ([CONSTANTS.IV_HealthFacDelivery].includes(IVInfo[j]['MstID'])) {
                    const name = IVInfo[j]['Names'][language];
                    const filterOK = name.toLowerCase().includes(filterText.toLowerCase());

                    if (filterOK) {
                        panelContent.push({
                            type        : "checkBox",
                            caption     : name,
                            labelStyle  : getCheckBoxLabelStyle(IVInfo, IVInfo[j]['MstID']),
                            style       : getCheckBoxStyle(IVInfo, IVInfo[j]['MstID']),
                            boxStyle    : getBoxStyle(IVInfo, IVInfo[j]['MstID'], 'white'),
                            key         : IVInfo[j]['MstID'],
                            color       : 'primary',
                            name        : '',
                            customProp  : IVInfo[j]['MstID'],
                            customProp2 : groupMstID,
                            value       : CS_CheckIfInterventionIsOn(IVInfo[j]['MstID'], selectedIVSet),
                            onClick     : (checked, event, name, caption, mstID, groupMstID) => onCheckBoxChange(checked, groupMstID, mstID, caption)
                        });
                    }
                }
            }
        }

        for (let i = 0; i < subGroupMstIDs.length; i++) {
            if ((subGroupMstIDs[i] !== CONSTANTS.CS_MstCurativeSubGroupHIV) && (subGroupMstIDs[i] !== CONSTANTS.CS_MstPregnancySubGroupHIV)) {
                
                let subGroupExists = false;
                let subGroupNameStyled;
                let arr2 = [];

                if (subGroupMstIDs[i] !== -1) {
                    subGroupExists = true;
                    subGroupNameStyled = CS_GetSubGroupNameStyled(subGroupMstIDs[i], interventionSubGroupList, language);
                }

                for (let j = 0; j < IVInfo.length; j++) {
                    if (![CONSTANTS.IV_MstContraceptiveUse, 
                          CONSTANTS.CS_MstHIVInt,
                          CONSTANTS.IV_MstCotrimoxazole, 
                          CONSTANTS.CS_MstPercOnART, 
                          CONSTANTS.IV_MstPMTCT].includes(IVInfo[j]['MstID'])){
                        if (
                            (IVInfo[j]['GroupMstID'] === groupMstID) &&
                            (IVInfo[j]['SubGroupMstID'] === subGroupMstIDs[i]) &&
                            (IVInfo[j][outcome])
                        ) {
                            const name = IVInfo[j]['Names'][language];
                            const filterOK = name.toLowerCase().includes(filterText.toLowerCase());
                
                            if (filterOK) {
                                arr2.push({
                                    type        : "checkBox",
                                    caption     : name,
                                    labelStyle  : getCheckBoxLabelStyle(IVInfo, IVInfo[j]['MstID']),
                                    style       : getCheckBoxStyle(IVInfo, IVInfo[j]['MstID']),
                                    boxStyle    : getBoxStyle(IVInfo, IVInfo[j]['MstID'], 'white'),
                                    key         : IVInfo[j]['MstID'],
                                    color       : 'primary',
                                    name        : '',
                                    customProp  : IVInfo[j]['MstID'],
                                    customProp2 : groupMstID,
                                    value       : CS_CheckIfInterventionIsOn(IVInfo[j]['MstID'], selectedIVSet),
                                    onClick     : (checked, event, name, caption, mstID, groupMstID) => onCheckBoxChange(checked, groupMstID, mstID, caption)
                                });
                            }
                        }
                    }
                }

                if (arr2.length > 0) {
                    if (subGroupExists) {
                        panelContent.push(subGroupNameStyled);
                    }

                    panelContent = panelContent.concat(arr2);
                }

                let numSubGroupMstIDs = subGroupMstIDs.length -1;
                if ((subGroupMstIDs[subGroupMstIDs.length -1] === CONSTANTS.CS_MstCurativeSubGroupHIV) || (subGroupMstIDs[subGroupMstIDs.length -1] === CONSTANTS.CS_MstPregnancySubGroupHIV)) {
                    numSubGroupMstIDs = subGroupMstIDs.length -2
                }
                if ((subGroupMstIDs.length > 1) && (i < numSubGroupMstIDs)) {
                    if (arr2.length > 0) {
                        panelContent.push({
                            type        : "divider",
                            key         : 'd' + i,
                            style       : styles.dividerStyle
                        });
                    }
                }
            }
        }
    }

    // Remove final divider line if it exists
    let len = panelContent.length;
    if (len > 0) {
        if (panelContent[len-1].type === "divider") {
            panelContent.pop();
        }
    }

    if (panelContent.length > 0) {
        return (panelContent);
    }
    else {
        return null;
    }
};

const CS_RenderContent = (outcomeIdx, IVInfo, selectedIVSet, interventionGroupList, exploreBFContent, language, interventionSubGroupList, defaultExpanded, onShowDataBtnClick, onCheckBoxChange, filterText) => {
    let expPanels = [];

    let groupMstIDs = CS_GetInterventionGroupMstIDs(outcomeIdx, IVInfo);
    let groupCurrIDs = CS_GetInterventionGroupCurrIDs(groupMstIDs, interventionGroupList);

    for (let i = 0; i < groupCurrIDs.length; i++) {
        if (
            (CS_GetInterventionGroupMstIDFromCurrID(groupCurrIDs[i], interventionGroupList) !== CONSTANTS.CS_GroupMstID_FertilityRisks) &&
            (CS_GetInterventionGroupMstIDFromCurrID(groupCurrIDs[i], interventionGroupList) !== CONSTANTS.CS_GroupMstID_Breastfeeding) &&
            (CS_GetInterventionGroupMstIDFromCurrID(groupCurrIDs[i], interventionGroupList) !== CONSTANTS.CS_GroupMstID_NutritionalStatus)
        ) {
            const content = CS_GetPanelContent(outcomeIdx, groupCurrIDs[i], exploreBFContent, IVInfo, selectedIVSet, interventionGroupList, language, interventionSubGroupList, onCheckBoxChange, filterText);

            if (content !== null) {
                expPanels.push({
                    type: "expansionPanel",
                    onShowDataBtnClick: onShowDataBtnClick,
                    content: content,
                    panelName: CS_GetPanelName(groupCurrIDs[i], interventionGroupList, language),
                    showDataBtn: false,
                    subPanel: true,
                    defaultExpanded: defaultExpanded,
                    key: i,
                });
            }
        }
    }

    return (expPanels);
};

//==================================================================================================================
//
//                                               REACT
//
//==================================================================================================================

class InterventionExpPanels extends React.Component {

    static propTypes = {
        onCheckBoxChange         : PropTypes.func,
        outcomeIdx               : PropTypes.number,
        interventionSubGroupList : PropTypes.arrayOf(PropTypes.object),
        interventionGroupList    : PropTypes.arrayOf(PropTypes.object),
        selectedIVSet            : PropTypes.arrayOf(PropTypes.any),
        IVInfo                   : PropTypes.arrayOf(PropTypes.object),
        exploreBFContent         : PropTypes.arrayOf(PropTypes.object),
        defaultExpanded          : PropTypes.bool,
        language                 : PropTypes.number,
        onAddDisplay             : PropTypes.func,
    };

    static defaultProps = {
        onCheckBoxChange         : () => {},
        outcomeIdx               : 0,
        interventionSubGroupList : [],
        interventionGroupList    : [],
        selectedIVSet            : [],
        IVInfo                   : [],
        exploreBFContent         : [],
        defaultExpanded          : false,
        language                 : CONSTANTS.GB_English,
        onAddDisplay             : () => {},
    };  
    
    state = {
        filterText               : ''
    };

    onFilterChange = (value) => {
        this.setState({ 
            filterText: value 
        });
    };

    render() {
        const { outcomeIdx, IVInfo, selectedIVSet, interventionGroupList, exploreBFContent, language, interventionSubGroupList, defaultExpanded, onCheckBoxChange } = this.props;
        const { filterText } = this.state;

        let UIData = CS_RenderContent(outcomeIdx, IVInfo, selectedIVSet, interventionGroupList, exploreBFContent, language, interventionSubGroupList, defaultExpanded, this.props.onAddDisplay, onCheckBoxChange, filterText);

        let components = UIData.map(x => {
            return (
                <IntervCovExpandPanel
                    key={x.key}
                    onShowDataBtnClick={x.onShowDataBtnClick}
                    UIData={x.content}
                    panelName={x.panelName}
                    showDataBtn={x.showDataBtn}
                    subPanel={x.subPanel}
                    defaultExpanded={x.defaultExpanded}
                />
            );
        });

        return (
            <React.Fragment>
                <FilterEdit
                    value={this.state.filterText}
                    onChange={this.onFilterChange}
                />
                {components}
            </React.Fragment>
        )
    }

}

export default InterventionExpPanels;