import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../components/CS/CSBlueBox2";
import ResultDrawerContent from "../drawers/ResultDrawerContent";
import ConfigurationButton from '../configurations/ConfigurationButton';
import DisplayTypes from '../../../components/DisplayTypes'
import {getAgeCohortName, getSexName, getChildCODName, getMatCODName, getSBCODName, CSGetDiscountRateName} from "utilities/CS/CSDataUtil";
import {addPackTableRow} from "../../../utilities/GB/GBUtil";

function getChartOptions(props) {
    return props.chartOptions.find(x => ((x.modID === props.modID) && (x.resultID === props.resultID)));
}

class CSExpandResultBlueBox extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange   : PropTypes.func,
        caption         : PropTypes.string,
        resultsContent  : PropTypes.object,
        modID           : PropTypes.number,
        resultID        : PropTypes.number,
        chartOptions    : PropTypes.arrayOf(PropTypes.object),
        isDeskTop       : PropTypes.bool,
        firstYear       : PropTypes.number,
        finalYear       : PropTypes.number,
        chartYears      : PropTypes.object,
        stacked         : PropTypes.string,
        legendReversed  : PropTypes.bool,
        iconBtnDisabled : PropTypes.bool,
        displayCoverage : PropTypes.bool,
        retroYears      : PropTypes.bool,
        displayEndpoints: PropTypes.bool,
        IVInfo          : PropTypes.array,
        IVGroupInfo     : PropTypes.array,
        CSModvars       : PropTypes.array,
        projectionName  : PropTypes.string,
        coverageDisplay : PropTypes.string,
        language        : PropTypes.number,
        resultCats      : PropTypes.array,
        onOpenHelpDialog: PropTypes.func
    };

    static defaultProps = {
        onStateChange   : () => console.log('onStateChange'),
        caption         : '',
        resultsContent  : {},
        modID           : 0,
        resultID        : 0,
        chartOptions    : [],
        isDeskTop       : true,
        firstYear       : new Date().getFullYear(),
        finalYear       : CONSTANTS.CS_DefaultFinalYear,
        chartYears      : {},
        stacked         : '',
        legendReversed  : false,
        iconBtnDisabled : false,
        displayCoverage : false,
        retroYears      : false,
        displayEndpoints: false,
        IVInfo          : [],
        IVGroupInfo     : [],
        CSModvars       : [],
        projectionName  : "",
        coverageDisplay : CONSTANTS.CS_TableChart,
        language        : CONSTANTS.GB_English,
        resultCats      : [],
        onOpenHelpDialog: () => console.log("onOpenHelpDialog")
    };

    state = {
        saveBtnDisabled          : true,
        openDrawer               : this.props.openDrawer,
        coverageDisplay          : this.props.coverageDisplay,
        hideMenu                 : false,
        chart                    : null,
        tableRef                 : React.createRef(),
        resultsContent           : this.props.resultsContent
        //chartWidth               : this.props.chartWidth
    };

    componentDidMount() {
        let orig = JSON.parse(JSON.stringify(this.state.resultsContent));
        orig.packChart.title = this.props.caption + this.getExtraCaption(this.props);
        this.setState({resultsContent: orig});
    }

    UNSAFE_componentWillReceiveProps (newProps) {
       // this.setState({chartWidth: newProps.chartWidth});
        let orig = JSON.parse(JSON.stringify(newProps.resultsContent));
        orig.packChart.title = newProps.caption + this.getExtraCaption(newProps);
        orig.packTable.Title = newProps.caption + this.getExtraCaption(newProps);
        this.setState({
            resultsContent: orig,
            //openDrawer: newProps.openDrawer
        })
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        // this.setState({
        //     openDrawer : true,
        // });
        //
        // let idx = 0;
        // for (let i = 0; i < this.props.chartOptions.length; i++){
        //     if ((this.props.chartOptions[i]['modID'] === this.props.modID) &&
        //         (this.props.chartOptions[i]['resultID'] === this.props.resultID)) {
        //         idx = i;
        //         break;
        //     }
        // }
        //
        // if (this.props.chartOptions[idx]['typeOfDisplay'] === CONSTANTS.CS_BarChart) {
        //     this.props.onStateChange({
        //         stacked: 'normal',
        //         legendReversed: true
        //     })
        // }
        // else {
        //     this.props.onStateChange({
        //         stacked: '',
        //         legendReversed: false
        //     })
        // }
    };

    onCloseDrawerBtnClick = () => {
        this.setState({
            openDrawer      : false,
            // saveBtnDisabled : true,
        }, () => this.props.passData({openDrawer: false}));
    };

    onChangeDisplay = (displayType) => {
        //let display = JSON.parse(JSON.stringify(this.props.typeOfDisplay));
        let chartOptions = JSON.parse(JSON.stringify(this.props.chartOptions));
        for (let i = 0; i < chartOptions.length; i++) {
            if ((chartOptions[i]['modID'] === this.props.modID) &&
                (chartOptions[i]['resultID'] === this.props.resultID)) {
                chartOptions[i]['typeOfDisplay'] = displayType;
                break;
            }
        }

        if (displayType === 'line') {
            //display[this.props.resultID] = CONSTANTS.CS_LineChart;
            this.props.onStateChange({
                chartOptions : chartOptions,
                stacked: '',
                legendReversed: false
            });
        }
        else if (displayType === 'bar') {
           // display[this.props.resultID] = CONSTANTS.CS_BarChart;
            this.props.onStateChange({
                chartOptions : chartOptions,
                stacked: 'normal',
                legendReversed: true
            });
        }
        else if (displayType === 'column') {
            //display[this.props.resultID] = CONSTANTS.CS_ColumnChart;
            this.props.onStateChange({
                chartOptions : chartOptions,
                stacked: 'normal',
                legendReversed: false
            });
        }
        else {
            //display[this.props.resultID] = CONSTANTS.CS_TableChart;
            this.props.onStateChange({
                chartOptions : chartOptions,
                stacked: '',
                legendReversed: false
            })
        }
    };

    onChangeCoverageDisplay = (type) => {
        //this.setState({coverageDisplay: type});
    };

    onDownloadTable = (func) => {
        let orig = JSON.parse(JSON.stringify(this.state.resultsContent));
        let clon = JSON.parse(JSON.stringify(orig));
        let titleRow = [this.props.caption + this.getExtraCaption(this.props)];
        if (clon.packTable.tableData.value) {
            for (let i = 1; i < clon.packTable.tableData.value[0].length; i++) {
                titleRow.push("");
            }
        }
        clon.packTable = addPackTableRow(clon.packTable, 0);
        clon.packTable.tableData.value[0] = titleRow;
        // clon.packTable.MergedCells.forEach((cell) => {
        //     cell.startRow+=1;
        // });
        // clon.packTable.GBRowCount = clon.packTable.GBRowCount + 1;
        // if (clon.packTable.Alignments) clon.packTable.Alignments.unshift(clon.packTable.Alignments[0]);
        // if (clon.packTable.FontColors) clon.packTable.FontColors.unshift(clon.packTable.FontColors[0]);
        // if (clon.packTable.FontStyles) clon.packTable.FontStyles.unshift(clon.packTable.FontStyles[0]);
        // if (clon.packTable.GBCellComment) clon.packTable.GBCellComment.unshift(clon.packTable.GBCellComment[0]);
        // if (clon.packTable.GBUseTriangle) clon.packTable.GBUseTriangle.unshift(clon.packTable.GBUseTriangle[0]);
        // if (clon.packTable.IndentRowText) clon.packTable.IndentRowText.unshift(clon.packTable.IndentRowText[0]);
        // if (clon.packTable.IntervRecords) clon.packTable.IntervRecords.unshift(clon.packTable.IntervRecords[0]);
        // if (clon.packTable.LockedCells) clon.packTable.LockedCells.unshift(clon.packTable.LockedCells[0]);
        // if (clon.packTable.MaxAllowedVal) clon.packTable.MaxAllowedVal.unshift(clon.packTable.MaxAllowedVal[0]);
        // if (clon.packTable.MinAllowedVal) clon.packTable.MinAllowedVal.unshift(clon.packTable.MinAllowedVal[0]);
        // if (clon.packTable.RDec) clon.packTable.RDec.unshift(clon.packTable.RDec[0]);
        // if (clon.packTable.RowIds) clon.packTable.RowIds.unshift(clon.packTable.RowIds[0]);
        // clon.packTable.tableData.value.unshift(titleRow);
        setTimeout(() => {
            this.setState({resultsContent: clon}, () => func(() => this.setState({resultsContent: orig})));
        }, 0);
    };

    onDownloadChart = (func) =>  {
        let orig = JSON.parse(JSON.stringify(this.state.resultsContent));
        let clon = JSON.parse(JSON.stringify(orig));
        clon.packChart.title = this.props.caption + this.getExtraCaption(this.props);
        this.setState({resultsContent: clon}, () => func(() => this.setState({resultsContent: orig})));
    };


    //==================================================================================================================
    //
    //                                              Functions
    //
    //==================================================================================================================

    hideMenu = () => {
        this.setState({hideMenu: true}, () => this.setState({hideMenu: false}));
    };

    getExtraCaption = (props) => {
        let options = JSON.parse(JSON.stringify(getChartOptions(props)));

        let age = '';
        if (Array.isArray(options['age'])) {
            if (options['age'].length > 0) {
                age = getAgeCohortName(options['ageIdx'], options['age'].length > 3);
            }
        }
        else{
            if(options.age.singleAge){
                age = 'Single age';
            }
            else if (options.age.fiveYearAge){
                age = 'Five year age';
            }
        }

        let sex = '';
        if (Array.isArray(options['sex'])) {
            if (options['sex'].length > 0) {
                sex = getSexName(options['sexIdx']);
            }
        }
        else{
           if(options.sex.both){
              sex = getSexName(CONSTANTS.DP_BothSexes);
           }
           else if (options.sex.male){
              sex = getSexName(CONSTANTS.DP_Male);
           }
           else if (options.sex.female){
              sex = getSexName(CONSTANTS.DP_Female);
           }
        }


        let discount = '';
        if (Array.isArray(options['discountRates'])) {
            if (options['discountRates'].length > 0) {
                discount = CSGetDiscountRateName(options['discountRates'][options['discountRateIdx']]);
            }
        }

        let cod = '';
        let idx = 0;
        if (options['cod'].length > 0) {
            switch (options['codCat']) {
                case CONSTANTS.CS_MstMaternalCODCat : {
                    idx = options['cod'][options['codIdx']];
                    cod = getMatCODName(idx);
                    break;
                }

                case CONSTANTS.CS_MstStillbirthCODCat : {
                    idx = options['cod'][options['codIdx']];
                    cod = getSBCODName(idx);
                    break;
                }

                case CONSTANTS.CS_MstNeonatalCODCat : {
                    idx = options['cod'][options['codIdx']];
                    cod = getChildCODName(idx);
                    break;
                }

                case CONSTANTS.CS_MstChildCODCat : {
                    if (options['ageIdx'] === CONSTANTS.CS_1t59months) {
                        idx = options['cod'][options['codIdx'] + CONSTANTS.CS_FinalStdNNDeath]
                    }
                    else {
                        idx = options['cod'][options['codIdx']]
                    }
                    cod = getChildCODName(idx);

                    break;
                }
                default : break;
            }
        }

        let extraCaption = '';
        let capNotEmpty = false;

        if (age !== '') {
            extraCaption += ' (' + age;
            capNotEmpty = true;
        }
        if (sex !== '') {
            if (capNotEmpty) {
                extraCaption += ', ' + sex;
            } else {
                extraCaption += ' (' + sex;
            }
            capNotEmpty = true;
        }
        if (discount !== '') {
            if (capNotEmpty) {
                extraCaption += ', ' + discount;
            } else {
                extraCaption += ' (' + discount;
            }
            capNotEmpty = true;
        }
        if (cod !== '') {
            if (capNotEmpty) {
                extraCaption += ', ' + cod;
            } else {
                extraCaption += ' (' + cod;
            }
            capNotEmpty = true;
        }

        if (capNotEmpty) {
            extraCaption += ')'
        }

        return (extraCaption);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            appBarTextStyle  : {
                color: '#cb6814',
                fontSize: '22px',
                fontWeight: 'bold',
                display: 'inline-block',
                marginLeft: '15px',
                marginTop: '16px',
                position: 'absolute',
                userSelect: 'none'
            },
            iconBtnStyle : {
                float: 'right',
                width: 20,
                height: 20,
                padding: 2,
                cursor: 'pointer',
                boxSizing: 'border-box',
                fontSize: '14px',
                color: "#000000"
            },
            iconBtnStyleDisabled : {
                float: 'right',
                width: 20,
                height: 20,
                padding: 2,
                cursor: 'pointer',
                boxSizing: 'border-box',
                color: '#959595',
                fontSize: '14px',
            },
            iconStyle : {
                fontSize: '14px',
                color: "#000000"
            },

            extraBtnStyle : {
                marginTop: 1.5,
                float: 'right',
                marginRight: 15
            }
        };

        let resultCat = "";

        let helpFile = "expanded-result.htm";

        if ([CONSTANTS.CS_MstAnemiaCasesPreventedResults, 
             CONSTANTS.CS_MstNumStuntedAverted, 
             CONSTANTS.CS_MstNumStuntedAvertedByInter].includes(this.props.resultID)){
            helpFile = "results.htm";
        }

        return (
            <CSBlueBox2
                caption={RS('GB_stExpand')}
                captionStyle={{}}
                description=''
                onClick={this.onBlueBoxClick}
                isIconButton={true}
                iconBtnDisabled={this.props.iconBtnDisabled}
                iconBtnStyle={(this.props.iconBtnDisabled) ? styles.iconBtnStyleDisabled : styles.iconBtnStyle}
                iconStyle={styles.iconStyle}
                editContent={
                    <TDrawerWithHeader
                        open={this.props.openDrawer}
                        onClose={this.onCloseDrawerBtnClick}
                        hideMenu={this.state.hideMenu}
                        showSaveBtn={false}
                        appBarText={resultCat}
                        downloadTitle={this.props.caption + this.getExtraCaption(this.props)}
                        appBarTextStyle={styles.appBarTextStyle}
                        isDeskTop={this.props.isDeskTop}
                        chart={this.state.chart}
                        tableRef={this.state.tableRef}
                        chartType={this.props.chartOptions.find(x => ((x.modID === this.props.modID) && (x.resultID === this.props.resultID)))['typeOfDisplay']}
                        onDownloadChart     = {this.onDownloadChart}
                        onDownloadTable={this.onDownloadTable}
                        onOpenHelpDialog  = {this.props.onOpenHelpDialog}
                        helpFile={helpFile}
                        passData            = {(dat) => this.setState(dat)}
                        content={
                            <ResultDrawerContent
                                onStateChange={this.props.onStateChange}
                                chartYears={this.props.chartYears}
                                resultsContent={this.state.resultsContent}
                                firstYear={this.props.firstYear}
                                finalYear={this.props.finalYear}
                                stacked={this.props.stacked}
                                legendReversed={this.props.legendReversed}
                                chartOptions={this.props.chartOptions}
                                modID={this.props.modID}
                                resultID={this.props.resultID}
                                displayEndpoints={this.props.displayEndpoints}
                                displayCoverage={this.props.displayCoverage}
                                retroYears={this.props.retroYears}
                                IVInfo={this.props.IVInfo}
                                IVGroupInfo={this.props.IVGroupInfo}
                                CSModvars={this.props.CSModvars}
                                projectionName={this.props.projectionName}
                                coverageDisplay={this.props.coverageDisplay}
                                passData={(o) => this.setState(o)}
                                tableRef={this.state.tableRef}
                                language={this.props.language}
                                //chartWidth     = {this.state.chartWidth}
                            />
                        }
                        showExtraBtn={true}
                        contentExtraBtn={
                            <ConfigurationButton
                                modID={this.props.modID}
                                resultID={this.props.resultID}
                                chartOptionsArr={this.props.chartOptions}
                                chartYears={this.props.chartYears}
                                firstYear={this.props.firstYear}
                                finalYear={this.props.finalYear}
                                displayEndpoints={this.props.displayEndpoints}
                                displayCoverage={this.props.displayCoverage}
                                retroYears={this.props.retroYears}
                                coverageDisplay={this.props.coverageDisplay}

                                onStateChange={this.props.onStateChange}
                                style={styles.extraBtnStyle}
                                isLink={window.innerWidth < CONSTANTS.CS_MdScreen}
                                hideMenu={this.hideMenu}
                                language={this.props.language}
                                onOpenHelpDialog  = {this.props.onOpenHelpDialog}
                            />
                        }
                        showDisplayOptions={true}
                        contentDisplayOptions={
                            <DisplayTypes
                                onChangeDisplay={this.onChangeDisplay}
                                resultID={this.props.resultID}
                                modID={this.props.modID}
                                chartOptions={this.props.chartOptions}
                                isDeskTop={this.props.isDeskTop}
                                hideMenu={this.hideMenu}
                            />
                        }
                        showDownload={true}
                    />
                }
            />

        );
    }

}

export default CSExpandResultBlueBox;