import RS from "@common/strings/RS";
import CONSTANTS from '../CS/CSConst.js';
import GB_CONSTANTS from "./GBConst";
import { Get_FP_FirstMethod, Get_FP_LastMethod, Get_FP_MethodTypeName } from "../CS/CSDataUtil";

export const CloneObj = (obj) => {
    try {
        return JSON.parse(JSON.stringify(obj));
    }
    catch {
        return obj;
    }
};

export const clone = (x) => {
    return JSON.parse(JSON.stringify(x));
};

export const uuidv4 = () => {
    let crypto = window.crypto || window.msCrypto;

    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
};

export const getModvarExists = (modvars, modvarTag) => {
    return Object.keys(modvars).some(function(i) {return modvars[i].tag === modvarTag;});
}

export const getModvarByTag = (modvars, modvarTag) => {
    return(modvars.find(x => (x.tag === modvarTag)));
};

export const getModvarCloneByTag = (modvars, modvarTag) => {
    return CloneObj(getModvarByTag(modvars, modvarTag));
};

export const getNewPT = () => {
    return CloneObj(GB_CONSTANTS.BASIC_ResultPackTable);
};

export const getNewPC = () => {
    return CloneObj(GB_CONSTANTS.BASIC_ResultPackChart)
};

export const getCalcYearIdx = (firstYear) => {
    let CalcYear = firstYear;
    if (firstYear > GB_CONSTANTS.GB_MaxNativeProjectionYear) {
        CalcYear = firstYear + 1;
    }
    return (CalcYear - firstYear + 1);
};

export const getInCalcStateMode = (firstYear) => {
    return (getCalcYearIdx(firstYear) === 2)
};

export const getYearFromIdx = (firstYear, idx) => {
    return (idx + firstYear - getCalcYearIdx(firstYear));
};

export const getIdxFromYear = (firstYear, year) => {
    return (year - firstYear + getCalcYearIdx(firstYear));
};

export const resizePackTable = (pt, numRows, numCols) =>{
    let table = JSON.parse(JSON.stringify(pt));
    table.GBRowCount = numRows;
    table.GBColCount = numCols;
    table.RDec = size2dArray(table.RDec, numRows, numCols, 0);
    table.tableData.value = size2dArray(table.tableData.value, numRows, numCols, "");
    table.IntervRecords = size1dArray(table.IntervRecords, numRows, null);
    table.RowIds = size1dArray(table.RowIds, numRows, 0);
    table.LockedCells = size2dArray(table.LockedCells, numRows, numCols, false);
    table.MinAllowedVal = size2dArray(table.MinAllowedVal, numRows, numCols, 0);
    table.MaxAllowedVal = size2dArray(table.MaxAllowedVal, numRows, numCols, 100);
    table.FontStyles = size2dArray(table.FontStyles, numRows, numCols, {intArray:[]});
    table.FontColors = size2dArray(table.FontColors, numRows, numCols, 0);
    table.FontSizes = size2dArray(table.FontSizes, numRows, numCols, 10);
    table.GBCellComment = size2dArray(table.GBCellComment, numRows, numCols, '');
    table.GBUseTriangle = size2dArray(table.GBUseTriangle, numRows, numCols, false);
    table.IndentRowText = size1dArray(table.IndentRowText, numRows, {xoffset : 0, Value : false});
    table.Alignments = size2dArray(table.Alignments, numRows, numCols, GB_CONSTANTS.GB_Left);
    switch (table.SourceMode) {
        case GB_CONSTANTS.GB_RowSrcEditor : {table.SourceMap = size1dArray(table.SourceMap, numRows, ''); break;}
        case GB_CONSTANTS.GB_ColSrcEditor : {table.SourceMap = size1dArray(table.SourceMap, numCols, ''); break;}
        default : {table.sourceMap = []}
    }
    table.GBColWidths.push(300);
    for (let i = 1; i < numCols; i++) {
        table.GBColWidths.push(90)
    }
    return (table)
};

const size1dArray = (array, row, defaultVal = 0) => {
    let array2 = [];
    for (let x = 0; x < row; x++) {
        array2[x] = defaultVal;
    }

    if (Math.min(row - 1, array.length - 1) > 0) {
        for (let x = 0; x <= Math.min(row - 1, array.length - 1); x++) {
            array2[x] = array[x];
        }
    }

    return array2;


    // for (var x = 0; x < row; x++) {
    //     array[x] = [];
    //     for (var y = 0; y < col; y++) {
    //         array[x][y] = defaultVal;
    //     }
    // }
};

const size2dArray = (array, row, col, defaultVal = 0) => {
    let array2 = [];
    for (let x = 0; x < row; x++) {
        array2[x] = [];
        for (let y = 0; y < col; y++) {
            array2[x][y] = CloneObj(defaultVal);
        }
    }

    if (Math.min(row - 1, array.length - 1) > 0) {
        for (let x = 0; x <= Math.min(row - 1, array.length - 1); x++) {
            if (Math.min(col - 1, array[x].length - 1) > 0) {
                for (let y = 0; y <= Math.min(col - 1, array[x].length - 1); y++) {
                    array2[x][y] = array[x][y];
                }
            }
        }
    }

    return array2;


    // for (var x = 0; x < row; x++) {
    //     array[x] = [];
    //     for (var y = 0; y < col; y++) {
    //         array[x][y] = defaultVal;
    //     }
    // }
};

export const addRowToPackTable = (pt, defaultVal = 0, rDec = 0) => {
    // if (!defaultVal) defaultVal = 0;
    // if (!rDec) rDec = 0;

    /* March 11, 2021: DO NOT clone the packTable and things will significantly speed up */
    /* We only should call this function if we want the packTable that's coming in to change. */
    let table = pt; // let table = CloneObj(pt);
    let tableData = table.tableData.value;

    tableData[tableData.length] = [];       //add a row
    for (let y = 0; y <= tableData[tableData.length - 2].length - 1; y++) {  //duplicate column length of previous last row for new last row
        tableData[tableData.length - 1][y] = defaultVal;
    }
    table.GBRowCount = tableData.length;

    let RDec = table.RDec;
    RDec[RDec.length] = [];                                                  //add a row
    for (let y = 0; y <= RDec[RDec.length - 2].length - 1; y++) {            //duplicate column length of previous last row for new last row
        RDec[RDec.length - 1][y] = rDec;
    }

    if(table.IntervRecords !== undefined){
        table.IntervRecords[table.IntervRecords.length] = null;
    }

    table.RowIds[table.RowIds.length] = 0;

    if (table.SourceMode === GB_CONSTANTS.GB_RowSrcEditor) {
        table.SourceMap[table.SourceMap.length] = '';
    }

    let LockedCells = table.LockedCells;
    LockedCells[LockedCells.length] = [];
    for (let y = 0; y <= LockedCells[LockedCells.length - 2].length - 1; y++) {
        LockedCells[LockedCells.length - 1][y] = false;
    }

    let MinAllowedVal = table.MinAllowedVal;
    MinAllowedVal[MinAllowedVal.length] = [];
    for (let y = 0; y <= MinAllowedVal[MinAllowedVal.length - 2].length - 1; y++) {
        MinAllowedVal[MinAllowedVal.length - 1][y] = 0;
    }

    let MaxAllowedVal = table.MaxAllowedVal;
    MaxAllowedVal[MaxAllowedVal.length] = [];
    for (let y = 0; y <= MaxAllowedVal[MaxAllowedVal.length - 2].length - 1; y++) {
        MaxAllowedVal[MaxAllowedVal.length - 1][y] = 100;
    }

    let FontStyles = table.FontStyles;
    FontStyles[FontStyles.length] = [];
    for (let y = 0; y <= FontStyles[FontStyles.length - 2].length - 1; y++) {
        FontStyles[FontStyles.length - 1][y] = {intArray:[]};
    }

    let FontColors = table.FontColors;
    FontColors[FontColors.length] = [];
    for (let y = 0; y <= FontColors[FontColors.length - 2].length - 1; y++) {
        FontColors[FontColors.length - 1][y] = 0;
    }

    if(table.FontSizes !== undefined){
        let FontSizes = table.FontSizes;
        FontSizes[FontSizes.length] = [];
        for (let y = 0; y <= FontSizes[FontSizes.length - 2].length - 1; y++) {
            FontSizes[FontSizes.length - 1][y] = 17;
        }
    }

    if(table.HasCheckBox !== undefined){
        let HasCheckBox = table.HasCheckBox;
        HasCheckBox[HasCheckBox.length] = [];
        for (let y = 0; y <= HasCheckBox[HasCheckBox.length - 2].length - 1; y++) {
            HasCheckBox[HasCheckBox.length - 1][y] = false;
        }
    }

    if(table.CheckBoxState !== undefined){
        let CheckBoxState = table.CheckBoxState;
        CheckBoxState[CheckBoxState.length] = [];
        for (let y = 0; y <= CheckBoxState[CheckBoxState.length - 2].length - 1; y++) {
            CheckBoxState[CheckBoxState.length - 1][y] = false;
        }
    }

    if(table.BGColors !== undefined){
        let BGColors = table.BGColors;
        BGColors[BGColors.length] = [];
        for (let y = 0; y <= BGColors[BGColors.length - 2].length - 1; y++) {
            BGColors[BGColors.length - 1][y] = 0;
        }
    }

    let GBCellComment = table.GBCellComment;
    GBCellComment[GBCellComment.length] = [];
    for (let y = 0; y <= GBCellComment[GBCellComment.length - 2].length - 1; y++) {
        GBCellComment[GBCellComment.length - 1][y] = '';
    }

    let GBUseTriangle = table.GBUseTriangle;
    GBUseTriangle[GBUseTriangle.length] = [];
    for (let y = 0; y <= GBUseTriangle[GBUseTriangle.length - 2].length - 1; y++) {
        GBUseTriangle[GBUseTriangle.length - 1][y] = false;
    }

    let Alignments = table.Alignments;
    Alignments[Alignments.length] = [];
    for (let y = 0; y <= Alignments[Alignments.length - 2].length - 1; y++) {
        Alignments[Alignments.length - 1][y] = GB_CONSTANTS.GB_Left;
    }

    let IndentRowText = table.IndentRowText;
    IndentRowText[IndentRowText.length] = {xoffset : 0, Value : false};

    return (table)
};

// function addColtoPackTable(array, defaultVal) {
//     for (var x = 0; x < array.length - 1; x++) {    //for every row, add a column
//         array[x][array[x].length] = defaultVal;
//     }
// }

export const addMergedCellsToArray = (array, startRow, startCol, numRows, numCols) => {

    let array1 = JSON.parse(JSON.stringify(array));

    array1[array1.length] = {
        "startCol" : startCol,
        "startRow" : startRow,
        "numCols" : numCols,
        "numRows" : numRows
    };

    return (array1)
};

export const lockPackRowCol = (pt, row, col, lock, gray = true) => {
    if (lock) {
        pt["LockedCells"][row][col] = true;
        if (gray) {
            pt["FontColors"][row][col] = 8421504;
        }
    } else {
        pt["LockedCells"][row][col] = false;
        pt["FontColors"][row][col] = 0;
    }
    return (pt)
};

export const lockPackTable = (pt, row, lock, gray = true) => {
    for(let col = 1; col <= pt["GBColCount"]; col++) {
        pt = lockPackRowCol(pt, row, col, lock, gray);
    }
    return (pt)
};

export const indentPackTable = (pt, row, indent, offset) => {
    if (indent){
        pt.IndentRowText[row] = {xoffset : offset, Value : true};
    }
    else{
        pt.IndentRowText[row] = {xoffset : 0, Value : false};
    }
    return (pt)
};

export const setSourceArrLength = (pt, numElements) => {
    pt.Source = new Array(numElements+1);
    pt.SourceTitle = new Array(numElements+1);
    return (pt)
};

export const getAllSources = (pt) => {
    let result = '';

    switch (pt.SourceMode){
        case GB_CONSTANTS.GB_OneEditorSrc : {
            result = pt.Source[0];
            break;
        }

        case GB_CONSTANTS.GB_RowSrcEditor : {
            for (let r = pt.GBFixedRows; r < pt.GBRowCount; r++) {
                let idx = pt.SourceMap[r];
                if (idx > 0 && pt.Source[idx] !== '') {
                    result += pt.tableData.value[r][0] + ':\n';
                    result += pt.Source[idx];
                    if (r !== pt.GBRowCount - 1) {
                        result += '\n\n';
                    }
                }
            }
            break;
        }

        case GB_CONSTANTS.GB_ColSrcEditor : {
            for (let c = pt.GBFixedCols; c < pt.GBColCount; c++) {
                let idx = pt.SourceMap[c];
                if (idx > 0 && pt.Source[idx] !== '') {
                    result += pt.tableData.value[0][c] + ':\n';
                    result += pt.Source[idx];
                    if (c !== pt.GBColCount - 1) {
                        result += '\n\n';
                    }
                }
            }
            break;
        }

        case GB_CONSTANTS.GB_RowSecSrcEditor : {
            for (let i = 1; i < pt.Source.length; i++) {
                if (pt.Source[i] !== '') {
                    result += pt.SourceTitle[i] + ':\n';
                    result += pt.Source[i];
                    if (i !== pt.Source.length - 1) {
                        result += '\n\n';
                    }
                }
            }
            break;
        }
        default : break;
    }

    return result;
};

export const add_NoHistoricalData_MsgToPackTable = (pt) => {
    // let wholeTableEmpty = true;
    // let noDataRowArr = new Array(pt.GBRowCount);

    for (let row = pt.GBFixedRows; row < pt.GBRowCount; row++) {
        let rowMerged = false;
        for(let i = 0; i < pt.MergedCells.length; i++){
            rowMerged = rowMerged || (pt.MergedCells[i].startRow === row);
        }
        if (!rowMerged) {
            let noDataRow = true;
            for (let col = 1; col < pt.GBColCount; col++) {
                if (pt.tableData.value[row][col] !== '') {
                    noDataRow = false;
                    // wholeTableEmpty = false;
                }
            }
            // noDataRowArr[row] = noDataRow;
            if (noDataRow && pt.tableData.value[row][0] !== RS('GB_stTotal')){
                pt.tableData.value[row][0] += ' --- (No historical data)';
            }
        }
    }

    // let doneOnce = false;
    //
    // for (let row = 1; row < pt.GBRowCount; row++) {
    //     if (noDataRowArr[row] ){//&& (!doneOnce || !wholeTableEmpty)) {
    //         pt.tableData.value[row][0] += ' --- (No historical data)';
    //         pt.GBColWidths[1] = 300;
            // pt["FontSizes"][row][1] = 3;
            // for (let col = 1; col < pt.GBColCount; col++) {
            //     pt.Alignments[row][col] = GB_CONSTANTS.GB_Left;
            // }
    //         doneOnce = true;
    //     }
    // }
    return pt;
};

export const convertNegativeOnesToBlanks = (pt) => {
    for (let row = 0; row < pt.GBRowCount; row++){
        for (let col = 0; col < pt.GBColCount; col++){
            if (pt.tableData.value[row][col] < 0) {
                pt.tableData.value[row][col] = '';
            }
        }
    }
    return (pt);
};

export const addComment = (pt, row, col, comment) =>{
    pt.GBCellComment[row][col] = comment;
    pt.GBUseTriangle[row][col] = true;
    return (pt);
};

export const generatePackChart = (pt, noShowRowSet = []) => {
    let pc = getNewPC();

    pc.RDec = pt.RDec[0][0];

    pc.hasData = pt.hasData;
    pc.hasDeselectedIntervs = pt.hasDeselectedIntervs;

    for (let c = 1; c < pt.tableData.value[0].length; c++) {
        pc.pointLabels.push(pt.tableData.value[0][c]);
    }

    let header = '';

    for (let row = pt.GBFixedRows; row < pt.tableData.value.length; row++ ){
        if(!noShowRowSet.includes(row)){
            let rowMerged = false;

            for(let i = 0; i < pt.MergedCells.length; i++){
                rowMerged = rowMerged || (pt.MergedCells[i].startRow === row);
            }

            if (!rowMerged) {
                if ((pt.tableData.value.length === (pt.GBFixedRows + 1)) && pt.tableData.value[row][0] === ''){
                    pc.subsetLabels.push(pt.title);
                }
                else if (pt.useSubHeadersInChartLbls){
                    if (pt.useHeadersInChartLbls) {
                        pc.subsetLabels.push(header + ' - ' + pt.tableData.value[row][0]);
                    }
                    else{
                        pc.subsetLabels.push(pt.tableData.value[row][0]);
                    }
                }
                else{
                    pc.subsetLabels.push(header);
                }

                let subsetData = [];
                for (let col = 1; col < pt.tableData.value[row].length; col++) {
                    subsetData.push(pt.tableData.value[row][col]);
                }
                pc.chartData.push(subsetData);
            }
            else{
                header = pt.tableData.value[row][0];
            }
        }
    }

    pc.subsetColors.push(GB_CONSTANTS.CS_NNChartColor);
    pc.subsetColors.push(GB_CONSTANTS.CS_ChildChartColor);

    return pc;
};

export const addPackTableRow = (pt, rowIndex) => {
    for (let k in pt) {
        if (Array.isArray(pt[k]) && pt[k].length === pt.GBRowCount) {
            pt[k].splice(rowIndex, 0, pt[k][0]);
        }
    }
    if (pt.MergedCells) {
        pt.MergedCells.forEach((cell) => {
            if (rowIndex > cell.startRow && rowIndex <= cell.startRow + cell.numRows) {
                //if the new row falls in the middle of merged cells, include it and thus expand it by one
                cell.numRows++;
            } else if (cell.startRow >= rowIndex) {
                //if the new row is below the cells, push them up by one
                cell.startRow++;
            }
        });
    }
    pt.GBRowCount++;
    pt.tableData.value.splice(rowIndex, 0, []);
    return pt;
};

export const deletePackTableRow = (pt, rowIndex) => {
    for (let k in pt) {
        if (Array.isArray(pt[k]) && pt[k].length === pt.GBRowCount) {
            pt[k].splice(rowIndex, 1);
        }
    }
    if (pt.MergedCells) {
        pt.MergedCells.forEach((cell) => {
            if (rowIndex > cell.startRow && rowIndex <= cell.startRow + cell.numRows) {
                cell.numRows--;
            } else if (cell.startRow >= rowIndex) {
                cell.startRow--;
            }
        });
    }
    pt.GBRowCount--;
    pt.tableData.value.splice(rowIndex, 1);
    return pt;
};

//------------------------------------------------------------------------------------------------------

export function ConvertToJSON(response) {
    //var json = JSON.parse(response);
    var str = stringify(response, { indent: 4, maxLength: Infinity });
    return str;
    // document.getElementById("myJSON2").innerHTML = str;
    
    // var prettyText = syntaxHighlight(str);
    // document.getElementById("responseConverted").innerHTML = prettyText;
}

 // https://github.com/lydell/json-stringify-pretty-compact/blob/master/index.js + custom changes

function stringify (obj, options) {
    options = options || {};
    var indent = JSON.stringify([1], null, get(options, 'indent', 2)).slice(2, -3);
    var addMargin = get(options, 'margins', false);
    var maxLength = (indent === '' ? Infinity : get(options, 'maxLength', 80));

    return (function _stringify (obj, currentIndent, reserved) {
        if (obj && typeof obj.toJSON === 'function') {
            obj = obj.toJSON()
        }

        var string = JSON.stringify(obj);

        if (string === undefined) {
            return string
        }

        var length = maxLength - currentIndent.length - reserved;

        if (string.length <= length) {
            if (string[0] !== '{') {                                             // TGP
                if (!((string[0] === '[') && (string[1] === '['))) {             // TGP
                    if (!((string[0] === '[') && (string[1] === '{'))) {         // TGP
                        var prettified = prettify(string, addMargin);
                        if (prettified.length <= length) {
                            return prettified
                        }
                    }
                }
            }
        }

        if (typeof obj === 'object' && obj !== null) {
            var nextIndent = currentIndent + indent;
            var items = [];
            var delimiters;
            var comma = function (array, index) {
                return (index === array.length - 1 ? 0 : 1)
            };

            if (Array.isArray(obj)) {
                for (var index = 0; index < obj.length; index++) {
                    items.push(
                        _stringify(obj[index], nextIndent, comma(obj, index)) || 'null'
                    )
                }
                delimiters = '[]'
            } else {
                Object.keys(obj).forEach(function (key, index, array) {
                    var keyPart = JSON.stringify(key) + ': ';
                    var value = _stringify(obj[key], nextIndent, keyPart.length + comma(array, index));
                    if (value !== undefined) {
                        items.push(keyPart + value)
                    }
                });
                delimiters = '{}'
            }

            if (items.length > 0) {
                return [
                    delimiters[0],
                    indent + items.join(',\n' + nextIndent),
                    delimiters[1]
                ].join('\n' + currentIndent)
            }
        }

        return string
    }(obj, '', 0))
}

// Note: This regex matches even invalid JSON strings, but since we’re
// working on the output of `JSON.stringify` we know that only valid strings
// are present (unless the user supplied a weird `options.indent` but in
// that case we don’t care since the output would be invalid anyway).
var stringOrChar = /("(?:[^\\"]|\\.)*")|[:,\][}{]/g;

function prettify (string, addMargin) {
    var m = addMargin ? ' ' : '';
    var tokens = {
        '{': '{' + m,
        '[': '[' + m,
        '}': m + '}',
        ']': m + ']',
        ',': ', ',
        ':': ': '
    };
    return string.replace(stringOrChar, function (match, string) {
        return string ? match : tokens[match]
    })
}

function get (options, name, defaultValue) {
    return (name in options ? options[name] : defaultValue)
}

export const GB_ShallowCompareComponent = (oldProps, nextProps, oldState, newState) => {
    for (let key in nextProps){
        if (nextProps.hasOwnProperty(key)) {
            if (nextProps[key] !== oldProps[key]) {
                if (key === 'style') {
                    if (JSON.stringify(nextProps[key]) !== JSON.stringify(oldProps[key])) {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
        }
    }

    for (let key in newState){
        if (newState.hasOwnProperty(key)) {
            if (newState[key] !== oldState[key]) {
                if (key === 'style') {
                    if (JSON.stringify(newState[key]) !== JSON.stringify(oldState[key])) {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
        }
    }

    return false;
};

export const GBGetModName = (modID) => {
    let result = 0;
    switch (modID) {
        case GB_CONSTANTS.GB_CS : result = RS('GB_stLiST'); break;
        case GB_CONSTANTS.GB_DP : result = RS('GB_stDemProj'); break;
        case GB_CONSTANTS.GB_FP : result = RS('GB_stFP'); break;
        case GB_CONSTANTS.GB_AM : result = RS('GB_stAIM'); break;
        default              : break;
    }
    return result;
};

export const ConvertCSVTo2DArray = (csvText) => {
    var csvRows = [];

    function parseCSVLine(line) {
        let i;
        line = line.split(",");

        // check for splits performed inside quoted strings and correct if needed
        for (i = 0; i < line.length; i++) {
            var chunk = line[i].replace(/^[\s]*|[\s]*$/g, "");
            var quote = "";
            if (chunk.charAt(0) === '"' || chunk.charAt(0) === "'") quote = chunk.charAt(0);
            if (quote !== "" && chunk.charAt(chunk.length - 1) === quote) quote = "";

            if (quote !== "") {
                var j = i + 1;

                if (j < line.length) chunk = line[j].replace(/^[\s]*|[\s]*$/g, "");

                while (j < line.length && chunk.charAt(chunk.length - 1) !== quote) {
                    line[i] += "," + line[j];
                    line.splice(j, 1);
                    chunk = line[j].replace(/[\s]*$/g, "");
                }

                if (j < line.length) {
                    line[i] += "," + line[j];
                    line.splice(j, 1);
                }
            }
        }

        for (i = 0; i < line.length; i++) {
            // remove leading/trailing whitespace
            line[i] = line[i].replace(/^[\s]*|[\s]*$/g, "");

            // remove leading/trailing quotes
            if (line[i].charAt(0) === '"') line[i] = line[i].replace(/^"|"$/g, "");
            else if (line[i].charAt(0) === "'") line[i] = line[i].replace(/^'|'$/g, "");
        }

        return line;
    }

    function csvTo2DArray(csvText) {
        let i;
        var error = false;

        if (csvText === "") {
            error = true;
        }

        if (!error) {
            csvRows = csvText.split(/[\r\n]/g); // split into rows

            // get rid of empty rows
            for (i = 0; i < csvRows.length; i++) {
                if (csvRows[i].replace(/^[\s]*|[\s]*$/g, "") === "") {
                    csvRows.splice(i, 1);
                    i--;
                }
            }

            if (csvRows.length < 2) {
                error = true;
            } else {
                for (i = 0; i < csvRows.length; i++) {
                    csvRows[i] = parseCSVLine(csvRows[i]);
                }

                return csvRows;
            }
        }
    }

    let array2D = csvTo2DArray(csvText);
    return array2D;
};

export const DownloadCSV = (csvContent, fileName = "data") => {
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
};

export const Download2DArrayToCSV = (array2D, fileName = "data") => {
    /* Array2D to CSV */
    let csvContent = "data:text/csv;charset=utf-8," + array2D.map(e => e.join(",")).join("\n");

    DownloadCSV(csvContent, fileName);
};

export const DownloadCSVFromCSVConvert = (csvContent, fileName = "data") => {
    let csvContent2 = "data:text/csv;charset=utf-8," + csvContent;
    DownloadCSV(csvContent2, fileName);
};

export const CSVTextToFile = (csvText, fileName = "data") => {
    // let array2D = ConvertCSVTo2DArray(`1,2,3
    // 4,5,6
    // 7,8,9`);

    let array2D = ConvertCSVTo2DArray(csvText);

    /* Array2D to CSV */
    let csvContent = "data:text/csv;charset=utf-8," + array2D.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
};

export const CSVTextToFile2 = (csvText, fileName = "data") => {
    // let csvText = `1,2,3
    // 4,5,6
    // 7,8,9`;

    /* Array2D to CSV */
    let csvContent = "data:text/csv;charset=utf-8," + csvText;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
};

// https://stackoverflow.com/questions/8572826/generic-deep-diff-between-two-objects
export let deepDiffMapper = function () {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      map: function(obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw new Error('Invalid argument. Function given, object expected.');
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj1 === undefined ? obj2 : obj1
          };
        }
  
        var diff = {};
        for (let key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }
  
          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }
  
          diff[key] = this.map(obj1[key], value2);
        }
        for (let key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }
  
          diff[key] = this.map(undefined, obj2[key]);
        }
  
        return diff;
  
      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === '[object Function]';
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === '[object Array]';
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === '[object Date]';
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === '[object Object]';
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x);
      }
    }
  }();

export const createArray = (dim, bounds, defaultVal = "", _arr = undefined) => {

    if (typeof _arr === "undefined") {
        _arr = [];
    }

    let len = bounds.slice().reverse()[dim-1];

    if (dim > 1) {
        for (let i = 0; i < len; i++) {
            _arr[i] = createArray(dim - 1, bounds, defaultVal, _arr[i]);
        }
        return _arr;
    }
    else {
        for (let i = 0; i < len; i++) {
            _arr[i] = clone(defaultVal);
        }
        return _arr;
    }

    //---------------------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = createArray(1, [3], "test");               a[0] = "different"; console.log(a);
    // let b = createArray(1, [3], {test: "test"});       b[0].test = "different"; console.log(b);

    // let c = createArray(2, [3, 3], "test");            c[0][0] = "different"; console.log(c);
    // let d = createArray(2, [3, 3], {test: "test"});    d[0][0].test = "different"; console.log(d);

    // let e = createArray(2, [5, 6], "test");            e[0][0] = "different"; console.log(e);
    // let f = createArray(2, [5, 6], {test: "test"});    f[0][0].test = "different"; console.log(f);

    // let g = createArray(3, [1, 2, 3], "test");         g[0][0][0] = "different"; console.log(g);
    // let h = createArray(3, [1, 2, 3], {test: "test"}); h[0][0][0].test = "different"; console.log(h);

    // let g = createArray(10, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], "test"); console.log(g);
    //---------------------------------------------------------------------------------------------------

    //---------------------------------------------------------------------------------------------------
    // Notes
    //
    // TGP: Bad! Elements by reference
    //     return Array(length).fill(clone(value));
    //
    // TGP : Bad!  Elements by reference
    //     let len = bounds.shift();
    //
    //     if (dim > 1) {
    //         return Array.from({length: len}, x => createArray(dim - 1, clone(bounds), value));
    //     } else {
    //         return Array(len).fill(value);
    //     }
    //---------------------------------------------------------------------------------------------------
};

export const createVector = (length, defaultVal = 0) => {

    return createArray(1, [length], defaultVal);

    // let arr = [];

    // for (let r = 0; r < length; r++) {
    //     arr[r] = clone(defaultVal);
    // }

    // return arr;

    //--------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = createVector(2, { intArray: [] }); a[0].intArray = [2]; console.log(a);
    //--------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------
    // Notes
    //
    // TGP: Bad! Elements by reference
    // return Array(length).fill(clone(value));
    //--------------------------------------------------------------------------------------
};

export const createMatrix = (rowCount, columnCount, defaultVal = 0) => {

    return createArray(2, [rowCount, columnCount], defaultVal);

    // let arr = [];

    // for (let r = 0; r < rowCount; r++) {
    //     arr[r] = [];
    //     for (let c = 0; c < columnCount; c++) {
    //         arr[r][c] = clone(defaultVal);
    //     }
    // }

    // return arr;

    //--------------------------------------------------------------------------------------
    // Passing tests
    //
    // let a = createMatrix(2, 2, { intArray: [] }); a[0][0].intArray = [2]; console.log(a);
    //--------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------
    // Notes
    //
    // TGP: Bad! Elements by reference
    // return Array.from({length: rowCount}, x => Array(columnCount).fill(clone(value)));
    //
    // TGP: Bad! Elements by reference
    // return Array(rowCount).fill(Array(columnCount).fill(value));
    //--------------------------------------------------------------------------------------
};

/* If any changes are done here, please remember to check the utilities
  file and change transposePackTable and resizePackTable when needed.  Also,
  change createDefaultPackTable below. */
export const createSimpleDefaultPackTable = (
    rowCount = 1,
    columnCount = 1,
    BGColor = 536870911
) => ({
    tableData: {
        value : createMatrix(rowCount, columnCount, "")
    },
    GBFixedRows: 1,
    GBFixedCols: 1,
    GBRowCount: rowCount,
    GBColCount: columnCount,
    TotalRow: 0,
    TotalCol: 0,
    WordWrappedCols: createVector(columnCount, false),
    RDec: createMatrix(rowCount, columnCount, 0),
    PercRDec: createMatrix(rowCount, columnCount, 1),
    MinAllowedVal: createMatrix(rowCount, columnCount, 0),
    MaxAllowedVal: createMatrix(rowCount, columnCount, 0),
    FontStyles: createMatrix(rowCount, columnCount, { intArray: [] }),

    FontBold: createMatrix(rowCount, columnCount, 0),
    FontItalic: createMatrix(rowCount, columnCount, 0),
    FontUnderline: createMatrix(rowCount, columnCount, 0),

    FontColors: createMatrix(rowCount, columnCount, "#000"),
    BGColors: createMatrix(rowCount, columnCount, BGColor),
    GBCellComment: createMatrix(rowCount, columnCount, ""),
    GBUseTriangle: createMatrix(rowCount, columnCount, false),
    LockedCells: createMatrix(rowCount, columnCount, false),
    MergedCells: [],
    IndentRowText: createVector(rowCount, {
        xoffset: 0,
        Value: false
    }),
    RowSubHeadings: createVector(rowCount, false),
    HasCheckBox: createMatrix(rowCount, columnCount, false),
    CheckBoxState: createMatrix(rowCount, columnCount, false),
    Alignments: createMatrix(rowCount, columnCount, 0),
    allowPercent: createMatrix(rowCount, columnCount, 0),
    showPercent: createMatrix(rowCount, columnCount, 0),
    Title: "",
    editorMstID: 0,
    Proj: 0,
    ModID: 0,
    RowIds: createMatrix(rowCount, 2, [0, 0]),
    ColIds: createMatrix(columnCount, 2, [0, 0]),
    SourceMode: 0,
    SourceMap: [],
    Source: [""],
    GBRowHeights : createVector(rowCount, -1),
    GBColWidths : createVector(columnCount, -1),
});

export const Get_FP_EditorCategoryAndMethodNames = (pt, DPModvars,numCols) => {
    let ActiveMethodsValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TActiveMethods_MV)['value'];
    let MethodNameValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodName_MV)['value'];

    let row = 1;
    let addCategory;
    let methods;

    for (let cat = CONSTANTS.FP_Condom; cat <= CONSTANTS.FP_CustomMethods; cat++) {
        methods = [];
        addCategory = false;
        for (let i = Get_FP_FirstMethod(cat); i <= Get_FP_LastMethod(cat); i++) {
            if (ActiveMethodsValue[i]) {
                addCategory = true;
                methods.push(MethodNameValue[i])
            }
        }
        if (addCategory) {
            pt = addRowToPackTable(pt, '');
            pt.tableData.value[row][0] = Get_FP_MethodTypeName(Get_FP_FirstMethod(cat));
            pt.FontStyles[row][0]["intArray"] = [GB_CONSTANTS.GB_Bold];
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
            for (let c = 1; c < numCols; c++) {
                pt.LockedCells[row][c] = true;
            }
            row++;
            for (let m = 0; m < methods.length; m++) {
                pt.tableData.value[row][0] = methods[m];
                pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
                row++;
            }
        }
    }

    return (pt)
};

export const RemoveRowsFromEndOfPackTable = (pt, numDataRowsToKeep) =>{
    let GBFixedRows = pt.GBFixedRows;
    for (let r = pt.GBFixedRows; r <= pt.GBRowCount - 1; r++) {
        if (pt.tableData.value[r][1] === '') {
            GBFixedRows++;
        }
    }
    let numDataRows = pt.GBRowCount - GBFixedRows;
    if (numDataRows > numDataRowsToKeep) {
        pt.tableData.value.splice(GBFixedRows + 1, numDataRows - numDataRowsToKeep)
    }

    pt.GBRowCount = pt.GBRowCount - (numDataRows - numDataRowsToKeep);

    return pt;
};
