import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    generatePackChart, getIdxFromYear, getYearFromIdx, add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const SevereAnemiaEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stSevereAnemia');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_MaternalHealth;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let RelRisksAnemiaMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_RelRisksAnemia_MV2);

        let value = pt.tableData.value[1][1];
        for (let d = CONSTANTS.CS_Mat_AntepartHemorr; d <= CONSTANTS.CS_Mat_PostpartHemorr; d++) {
            for (let an = CONSTANTS.CS_Mat_NotAnemic; d <= CONSTANTS.CS_Mat_Anemic; d++) {
                RelRisksAnemiaMV["value"][CONSTANTS.CS_Data][d][an][CONSTANTS.CS_AffecFract] = value;
            }
        }
        RelRisksAnemiaMV["PercWomenWithSevereAnemiaSource"] = pt.Source[0];
        changedModvars.push(RelRisksAnemiaMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    let numCols = 2;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stPercPregSeverelyAnemic');

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.RDec[1][col] = 3;
        col++;
    }

    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.RDec[1][1] = 3;

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let RelRisksAnemia = getModvarByTag(CSModvars, CONSTANTS.CS_TG_RelRisksAnemia_MV2);
    //let MaternalAnemia = getModvarByTag(CSModvars, CONSTANTS.CS_TG_MaternalAnemia_MV);

    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            pt.tableData.value[1][col] = RelRisksAnemia["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Mat_MinDeathCauses][
                CONSTANTS.CS_Mat_NotAnemic][CONSTANTS.CS_AffecFract];
            col++;
        }
    }
    else{
        pt.tableData.value[1][1] = RelRisksAnemia["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Mat_MinDeathCauses][
            CONSTANTS.CS_Mat_NotAnemic][CONSTANTS.CS_AffecFract];

    }

    pt.Source[0] = RelRisksAnemia["PercWomenWithSevereAnemiaSource"];

    return pt;
};

