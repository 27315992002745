import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
    getModvarCloneByTag,
    getIdxFromYear,
    indentPackTable,
    lockPackTable,
    addMergedCellsToArray,
    addRowToPackTable, lockPackRowCol, CloneObj
} from "utilities/GB/GBUtil";

import {
    getAgeCohortName,
    CSGetFirstBFType,
    CSGetBreastfeedingTypeName, GetBFMstFromBFandAge
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const BFPrevEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, SubnatMode) => {
        let editorName = RS('GB_stBFprevalence');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, SubnatMode);
        packTable = SetSSData(packTable, CSModvars, SubnatMode);
        packTable = BFPrevEditor.RecalcGrid(packTable);
        packTable.Title = editorName;
        packTable.ID = CONSTANTS.CS_MstLiSTOnlineBFPromoSheetID;
        packTable.EditorCat = CONSTANTS.GB_Coverage;
        return (packTable);
    },

    RecalcGrid : (pt) => {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let row = 0;
            for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_12t24months; a++) {
                row++;
                let sum = 0;
                let calculatedStdDevRow;
                for (let bf = CSGetFirstBFType(a); bf <= CONSTANTS.CS_MaxBFType; bf++){
                    row++;
                    /* Add the value to the sum if it is not a calculated standard deviation row. */
                    if (bf !== CONSTANTS.CS_NotBF) {
                        sum += pt.tableData.value[row][col];
                    }
                    /* If we are on a calculated standard deviation row, save the row number. */
                    else {
                        calculatedStdDevRow = row;
                    }
                }
                /* Calculate and set value for calculated standard deviation row. */
                let value = Math.max(100 - sum, 0);
                pt.tableData.value[calculatedStdDevRow][col] = value;

                row++;
                /* Add value of calculated standard deviation row to total and set total value. */
                sum = sum + value;
                pt.tableData.value[row][col] = sum;
            }
            col++;
        }

        return (pt);
    },

    GetSSData : (pt, CSModvars, SubnatMode) => {
        let changedModvars = [];
        let BFPrevMV = {};
        if (SubnatMode) {
            changedModvars = CloneObj(CSModvars);
            BFPrevMV = getModvarByTag(changedModvars, CONSTANTS.CS_TG_BreastfeedPrev_MV);
        }
        else {
            BFPrevMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_BreastfeedPrev_MV);
        }

        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            let row = 0;
            for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_12t24months; a++) {
                row++;
                for (let bf = CSGetFirstBFType(a); bf <= CONSTANTS.CS_MaxBFType; bf++){
                    row++;
                    BFPrevMV["value"][bf][a][t] = pt.tableData.value[row][col];
                }
                row++;
            }
            col++;
        }

        BFPrevMV["source"][0][0] = pt.Source[0];

        if (!SubnatMode) {
            changedModvars.push(BFPrevMV);
        }

        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, SubnatMode) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    let pt = getNewPT();

    if (!SubnatMode) {
        pt.SourceMode = CONSTANTS.GB_OneEditorSrc;
    }
    else{
        pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;
    }

    pt.yearArr = [];
    let numCols;
    if (!SubnatMode) {
        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }
    else{
        numCols = 3;
        for (let t = StartIndex; t <= StartIndex + 1; t++){
            pt.yearArr.push(t);
        }
    }

    const numRows = 1;

    pt = resizePackTable(pt, numRows, numCols);

    if (!SubnatMode) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            col++;
        }
    }
    else {
        pt.tableData.value[0][1] = RS('GB_stNational');
        pt.tableData.value[0][2] = RS('GB_stSubnational');
    }

    pt.tableData.value[0][0] = RS('GB_stInterventionPercent');

    let row = 0;
    pt.numSources = 0;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_12t24months; a++){
        row++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = getAgeCohortName(a, true);
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
        pt = lockPackTable(pt, row, true);
        for (let bf = CSGetFirstBFType(a); bf <= CONSTANTS.CS_MaxBFType; bf++){
            row++;
            pt = addRowToPackTable(pt);
            if (bf === CONSTANTS.CS_NotBF){
                pt = lockPackTable(pt, row, true);
            }
            pt.tableData.value[row][0] = CSGetBreastfeedingTypeName(a, bf);
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            pt.numSources++;
            pt.SourceMap[row] = pt.numSources;
            pt.SourceTitle[pt.numSources] = getAgeCohortName(a, true) + ' - ' + CSGetBreastfeedingTypeName(a, bf);
        }
        row++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = RS('DP_stTotal');
        pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
        pt = lockPackTable(pt, row, true);
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 1;
            }
        }
        if (SubnatMode){
            pt = lockPackRowCol(pt, row, 1, true);
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars, SubnatMode) => {
    let FirstTimeEnteringCoverage = {};
    let SubnatDefDataMstIDset = {};
    let SubnatDataSourceArray = {};

    if (SubnatMode){
        FirstTimeEnteringCoverage = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TFirstTimeEnteringCoverage);
        SubnatDefDataMstIDset = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TSubnatDefDataMstIDset);
        SubnatDataSourceArray = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TSubnatDataSourceArray);
    }

    let BFPrev = getModvarByTag(CSModvars, CONSTANTS.CS_TG_BreastfeedPrev_MV);
    if (!SubnatMode) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            let row = 0;
            for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_12t24months; a++) {
                row++;
                let sum = 0;
                for (let bf = CSGetFirstBFType(a); bf <= CONSTANTS.CS_MaxBFType; bf++) {
                    row++;
                    pt.tableData.value[row][col] = BFPrev["value"][bf][a][t];
                    sum += pt.tableData.value[row][col];
                }
                row++;
                pt.tableData.value[row][col] = sum;
            }
            col++;
        }
        pt.Source[0] = BFPrev["source"][0][0];
    }
    else{
        let row = 0;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_12t24months; a++) {
            row++;
            let sum = 0;
            for (let bf = CSGetFirstBFType(a); bf <= CONSTANTS.CS_MaxBFType; bf++) {
                row++;
                pt.tableData.value[row][1] = BFPrev["value"][bf][a][pt.yearArr[0]];
                sum += pt.tableData.value[row][1];
            }
            row++;
            pt.tableData.value[row][1] = sum;
        }

        row = 0;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_12t24months; a++) {
            row++;
            let sum = 0;
            for (let bf = CSGetFirstBFType(a); bf <= CONSTANTS.CS_MaxBFType; bf++) {
                row++;
                let MstID = GetBFMstFromBFandAge(bf, a);

                /* Under appropriate circumstances, set the first year (national)
                   coverage as the second year (subnational) breastfeeding distribution as well. */
                if (FirstTimeEnteringCoverage["value"] && !SubnatDefDataMstIDset["value"]["intArray"].includes(MstID)){
                    pt.tableData.value[row][2] = BFPrev["value"][bf][a][pt.yearArr[0]];
                }
                else{
                    pt.tableData.value[row][2] = BFPrev["value"][bf][a][pt.yearArr[1]];
                }
                sum += pt.tableData.value[row][2];

                pt.Source[pt.SourceMap[row]] = SubnatDataSourceArray["value"][MstID];
            }
            row++;
            pt.tableData.value[row][2] = sum;
        }
    }
    return pt;
};

