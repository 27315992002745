import React from "react";
import PropTypes from "prop-types";
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

class MultiplicationDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onClose          : PropTypes.func,
        onApply          : PropTypes.func,
        onStateChange    : PropTypes.func,
    };

    static defaultProps = {
        onClose          : () => console.log('onClose'),
        onApply          : () => console.log('onApply'),
        onStateChange    : () => console.log('onStateChange'),
    };

    state = {
        multiplier       : '',
    };

    componentDidMount() {
        this.props.onStateChange({
            multiplier : 1,
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onClose();
        // this.props.onStateChange({
        //     dialogMultiplicationOpen : false,
        // });
    };

    onApply = () => {
        this.props.onApply(parseFloat(this.state.multiplier));

        // this.props.onStateChange({
        //     dialogMultiplicationOpen : false,
        //     multiplier               : parseFloat(this.state.multiplier),
        // });
    };

    onNumberChange = (value) => {
        let litmus = value.substring(value.length - 1);
        let prev = value.substring(0, value.length - 2);
        let digits = prev.includes(".") && litmus === "." ? ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""] : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ".", ""];

        //only allow numbers or blanks and only one decimal
        if (digits.includes(litmus)) {
            this.setState({
                multiplier: value
            });
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0',
            }
        };

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stMultiply')}
                onClose={this.onClose}
                content={
                    <div>
                        <TEdit
                            name="MultiplierField"
                            caption={RS('GB_stMultiplier')}
                            value={this.state.multiplier}
                            onChange={this.onNumberChange}
                            style={styles.edit}
                        />
                    </div>
                }
                actions={
                    <div>
                        <ApplyButton onClick={this.onApply} colorType="primary" disabled={this.state.multiplier === ""} />
                    </div>
                }
            />
        );
    }
}

export default MultiplicationDialog;