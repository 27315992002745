import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import MOExpansionPanel from "./MOExpansionPanel";
import CODPercRedDropdown from "./CODPercRedDropdown";

class CausesOfDeath extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        MOResultToDisplay              : PropTypes.number,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        language                       : CONSTANTS.GB_English
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onChangeMaternal = (panelContent) => {
        this.props.onStateChange({
            maternalCausesOfDeathContent : panelContent
        })
    };

    onChangeStillbirth = (panelContent) => {
        this.props.onStateChange({
            stillbirthCausesOfDeathContent : panelContent
        })
    };

    onChangeNeonatal = (panelContent) => {
        this.props.onStateChange({
            neonatalCausesOfDeathContent : panelContent
        })
    };

    onChangeChild = (panelContent) => {
        this.props.onStateChange({
            childCausesOfDeathContent : panelContent
        })
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderExpansionPanelContent = () => {
        const styles = {
            panelStyle : {
                marginBottom: '20px'
            }
        };

        return (
            <div style={styles.panelStyle}>
                {
                    (this.props.displayMaternalCOD) ?

                        <MOExpansionPanel
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.maternalCausesOfDeathContent}
                            panelName     = {RS('GB_stMaternal')}
                            allMstID      = {CONSTANTS.CS_Mat_MstCODAll}
                            onChange      = {this.onChangeMaternal}
                        /> :
                        null
                }
                {
                    (this.props.displayStillbirthCOD) ?

                        <MOExpansionPanel
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.stillbirthCausesOfDeathContent}
                            panelName     = {RS('GB_stStillbirth')}
                            allMstID      = {CONSTANTS.CS_SB_MstCODAll}
                            onChange      = {this.onChangeStillbirth}
                        /> :
                        null
                }
                {
                    (this.props.displayNeonatalCOD) ?

                        <MOExpansionPanel
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.neonatalCausesOfDeathContent}
                            panelName     = {RS('GB_stNeonatal0_1Month')}
                            allMstID      = {CONSTANTS.CS_MstNNCODAll}
                            onChange      = {this.onChangeNeonatal}
                        /> :
                        null
                }
                {
                    (this.props.displayChildCOD) ?

                        <MOExpansionPanel
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.childCausesOfDeathContent}
                            panelName     = {RS('GB_stChildren1_59Months')}
                            allMstID      = {CONSTANTS.CS_MstChildCODAll}
                            onChange      = {this.onChangeChild}
                        /> :
                        null
                }
            </div>
        )
    };

    renderDropdownContent = () => {
        const styles = {
            panelStyle : {
                marginBottom: '10px'
            }
        };

        return (
            <div style={styles.panelStyle}>
                {
                    (this.props.displayMaternalCOD) ?

                        <CODPercRedDropdown
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.maternalCausesOfDeathContent}
                            panelName     = {RS('GB_stMaternal')}
                            allMstID      = {CONSTANTS.CS_Mat_MstCODAll}
                            onChange      = {this.onChangeMaternal}
                        /> :
                        null
                }
                {
                    (this.props.displayStillbirthCOD) ?

                        <CODPercRedDropdown
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.stillbirthCausesOfDeathContent}
                            panelName     = {RS('GB_stStillbirth')}
                            allMstID      = {CONSTANTS.CS_SB_MstCODAll}
                            onChange      = {this.onChangeStillbirth}
                        /> :
                        null
                }
                {
                    (this.props.displayNeonatalCOD) ?

                        <CODPercRedDropdown
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.neonatalCausesOfDeathContent}
                            panelName     = {RS('GB_stNeonatal0_1Month')}
                            allMstID      = {CONSTANTS.CS_MstNNCODAll}
                            onChange      = {this.onChangeNeonatal}
                        /> :
                        null
                }
                {
                    (this.props.displayChildCOD) ?

                        <CODPercRedDropdown
                            onStateChange = {this.props.onStateChange}
                            content       = {this.props.childCausesOfDeathContent}
                            panelName     = {RS('GB_stChildren1_59Months')}
                            allMstID      = {CONSTANTS.CS_MstChildCODAll}
                            onChange      = {this.onChangeChild}
                        /> :
                        null
                }
            </div>
        )
    };

    renderHeader = () => {
        let styles = {
            headerStyle: {
                color: '#000000',
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: '10px',
                marginBottom: '8px',
                userSelect: 'none'
            }
        };

        return (
            <div>
                <p style={styles.headerStyle}>{RS('GB_stCausesOfDeath')}</p>
            </div>
        )

    };

    render() {
        return (
            (this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted) ?
                <div>
                    {this.renderHeader()}
                    {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? this.renderExpansionPanelContent() : this.renderDropdownContent()}
                </div>
                :
                null

        );
    }

}

export default CausesOfDeath;