import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

    //==================================================================================================================
    //
    //                                             Functions
    //
    //==================================================================================================================

export const getMOChartSubTitle = (MOResultToDisplay, selectedCountry, displayMaternalCOD, displayStillbirthCOD,
                                 displayNeonatalCOD, displayChildCOD, deliveryPointsContent, displayAdolescentCOD, displayNutritionCOD, displayBirthOutcomeCOD) => {
    let subTitle = '';

    if(MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) {
        subTitle = RS('GB_stFor') + getDeliveryPoints(deliveryPointsContent) + ' ' + RS('GB_stInterventionsIn') + ' ' +
            selectedCountry + ' ' + RS('GB_stFromScalingUp') + ' ' + CONSTANTS.CS_MOFinalYear
    }
    else {
        subTitle = RS('GB_stFor') + getDisplayOutcomes(displayMaternalCOD, displayStillbirthCOD, displayNeonatalCOD, displayChildCOD, displayAdolescentCOD, displayNutritionCOD, displayBirthOutcomeCOD) +
            ' ' + RS('GB_stIn') + ' ' + selectedCountry + ' ' + RS('GB_stFromScalingUp') + ' ' + CONSTANTS.CS_MOFinalYear
    }

    return (subTitle)
};

const getDisplayOutcomes = (displayMaternalCOD, displayStillbirthCOD, displayNeonatalCOD, displayChildCOD, displayAdolescentCOD, displayNutritionCOD, displayBirthOutcomeCOD) => {
        let displayOutcomes = [];

        if ((displayMaternalCOD) && (displayStillbirthCOD) && (displayNeonatalCOD) && (displayChildCOD)) {
            displayOutcomes.push(' ' + RS('GB_stAllOutcomesQuotes'))
        }
        else {
            if (displayMaternalCOD) {
                displayOutcomes.push(' ' + RS('GB_stMaternalQuotes'))
            }
            if (displayStillbirthCOD) {
                displayOutcomes.push(' ' + RS('GB_stStillbirthsQuotes'))
            }
            if ((displayNeonatalCOD) && (displayChildCOD)) {
                displayOutcomes.push(' ' + RS('GB_stChildrenUnder5Quotes'))
            }
            else {
                if (displayNeonatalCOD) {
                    displayOutcomes.push(' ' + RS('GB_stNeonatalQuotes'))
                }
                if (displayChildCOD) {
                    displayOutcomes.push(' ' + RS('GB_stChildrenQuotes'))
                }
            }
            if (displayAdolescentCOD) {
                displayOutcomes.push(' ' + RS('GB_stAdolescentsQuotes'))
            }
            // if (displayNutritionCOD) {
            //     displayOutcomes.push(' ' + RS('GB_stNutritionQuotes'));
            // }
            // if (displayBirthOutcomeCOD) {
            //     displayOutcomes.push(' ' + RS('GB_stBirthOutcomeQuotes'));
            // }
        }
        return(displayOutcomes)
    };

const getDeliveryPoints = (deliveryPointsContent) => {
        let deliveryOutputs = [];

        if (deliveryPointsContent[CONSTANTS.CS_MstAllDelivPointsID]['checked']) {
            deliveryOutputs.push(' ' + RS('GB_stAllDeliveryPointsQuotes'))
        }
        else {
            if (deliveryPointsContent[CONSTANTS.CS_MstPericonceptualID]['checked']) {
                deliveryOutputs.push(' ' + RS('GB_stPerconceptualQuotes'))
            }
            if (deliveryPointsContent[CONSTANTS.CS_MstPregnancyID]['checked']) {
                deliveryOutputs.push(' ' + RS('GB_stPregnancyQuotes'))
            }
            if (deliveryPointsContent[CONSTANTS.CS_MstChildbirthID]['checked']) {
                deliveryOutputs.push(' ' + RS('GB_stChildbirthQuotes'))
            }
            if (deliveryPointsContent[CONSTANTS.CS_MstPreventiveID]['checked']) {
                deliveryOutputs.push(' ' + RS('GB_stPreventiveQuotes'))
            }
            if (deliveryPointsContent[CONSTANTS.CS_MstVaccinesID]['checked']) {
                deliveryOutputs.push(' ' + RS('GB_stVaccineQuotes'))
            }
            if (deliveryPointsContent[CONSTANTS.CS_MstCurativeID]['checked']) {
                deliveryOutputs.push(' ' + RS('GB_stCurativeQuotes'))
            }
        }
        return(deliveryOutputs)
    };


