import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LiSTIcon from '@common/img/LiSTIcon.png';
import TAvatarDropdown from '@common/components/TAvatarDropdown';

const AppBarHamburgerIcon = (props) => {
    const { visible, onClick } = props;

    const styles = {
        hamburger : {
            marginLeft: -12,
            marginRight: 20,
        }
    };

    if (visible) {
        return (
            <IconButton 
                aria-label="Menu" 
                style={styles.hamburger} 
                onClick={onClick}
            >
                <MenuIcon />
            </IconButton>
        )
    }

    return null;
};

const AppBarTitle = (props) => {
    const { onClick } = props;

    const styles = {
        logo : {
            marginRight: '15px', 
            cursor: 'pointer',
            marginTop: "10px",
        },
        img : {
            width: "72%",
        }
    }

    return (
        <div style={styles.logo} onClick={onClick} >
            <img style={styles.img} src={LiSTIcon} alt={""} />
        </div>
    );
}

const AppBarTitle2 = (props) => {
    const { caption, onClick, style } = props;

    const styles = {
        title : {
            flex: 1,
            fontSize: '28px', 
            cursor: 'pointer',
            ...style
        },
    };

    return (
        <Typography 
            variant="h2" 
            color="inherit"
            style={styles.title} 
            onClick={onClick}
        >
            {caption}
        </Typography>
    );
};

class TAppBar extends React.Component {

    static propTypes = {
        caption            : PropTypes.string,
        showHamburger      : PropTypes.bool,
        onHamburgerClick   : PropTypes.func,
        onTitleClick       : PropTypes.func,
        position           : PropTypes.string,
        style              : PropTypes.object,
        children           : PropTypes.any,
        titleStyle         : PropTypes.object,
        isGuest            : PropTypes.bool,
        humanName          : PropTypes.string,
        onLogout           : PropTypes.func,
        onPageChange       : PropTypes.func,
        onManageAccount    : PropTypes.func,
        Theme              : PropTypes.any.isRequired,
    };

    static defaultProps = {
        caption            : "",
        showHamburger      : false,
        onTitleClick       : () => console.log('onTitleClick fired'),
        onHamburgerClick   : () => console.log('onHamburgerClick fired'),
        position           : "absolute",                                    // static 
        style              : { background : '#508005' },                    // minHeight : '48px'
        children           : null,
        titleStyle         : {},
        isGuest            : true,
        humanName          : "Guest",
    };

    shouldComponentUpdate(nextProps, nextState) {
        for (let key in nextProps){
            if (nextProps.hasOwnProperty(key)) {
               if (nextProps[key] !== this.props[key]) {
                   if (key === 'style') {
                       if (JSON.stringify(nextProps[key]) !== JSON.stringify(this.props[key])) {
                           return true;
                       }
                    }
                    else {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onHamburgerClick = () => {
        this.props.onHamburgerClick();
    };

    onTitleClick = () => {
        this.props.onTitleClick();
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        const { Theme } = this.props;
        
        const styles = {
            appBar : {
                zIndex: Theme.zIndex.drawer + 1, 
                ...Theme.appBar, 
                ...this.props.style
            },
            logInButton: {
                paddingLeft: '0px',
                marginLeft: '10px',
                marginRight: '10px'
            },
        };

        return (
            <AppBar 
                position={this.props.position} 
                style={styles.appBar}
            >

                <Toolbar>
                    <AppBarHamburgerIcon
                        visible={this.props.showHamburger}
                        onClick={this.onHamburgerClick}
                    />

                    <AppBarTitle 
                        onClick={this.onTitleClick}
                    />

                    <AppBarTitle2
                        onClick={this.onTitleClick}
                        style={this.props.titleStyle}
                    />

                    <TAvatarDropdown
                        isGuest={this.props.isGuest}
                        humanName={this.props.humanName}
                        onSignInClick={() => this.props.onPageChange("LoginPage")}
                        onManageAccountClick={this.props.onManageAccount}
                        onSignOutClick={this.props.onLogout}
                        style={styles.logInButton}
                    />
                </Toolbar>

            </AppBar>
        );
    }
}

export default TAppBar;