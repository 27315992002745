import React from 'react';
import RS from "@common/strings/RS";
const styles = {
    labelStyle : {
        userSelect: 'none',
        fontSize: '12px'
    }
};

const DisplayIVUnselectedLabel = (props) => {
    const { showIVs, hasDeselectedIntervs, displayCoverage } = props;

    if ((showIVs && hasDeselectedIntervs) || displayCoverage) {
        return (
            <p style={styles.labelStyle}> {RS('GB_stUnselectedInterventions')}</p>
        );
    }

    return null;
};

export default DisplayIVUnselectedLabel;