import RS from "@common/strings/RS";
import {
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    lockPackRowCol,
    addMergedCellsToArray,
    generatePackChart,
    getModvarByTag, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks,
    addComment
} from "utilities/GB/GBUtil";

import {
    FindIntervRec
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const ImpactsOnMaternalAnemiaEditor = {
    Create : (IVInfo, CSModvars, language) => {
        let editorName;
        editorName = RS('GB_stImpactsOnMaternalAnemia');
        let packTable = Setup(IVInfo, language);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let ImpactsOnMatAnemia = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnMatAnemia_MV3);

        let modValue = ImpactsOnMatAnemia["value"][CONSTANTS.CS_Data];

        let i = CONSTANTS.CS_IronFolatePW_MatAnemia;
        modValue[CONSTANTS.CS_Preg][i][CONSTANTS.CS_Efficacy]    = pt.tableData.value[2][1];
        modValue[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract]  = pt.tableData.value[2][3];
        ImpactsOnMatAnemia["source"][0][i] = pt.Source[pt.SourceMap[2]];

        i = CONSTANTS.CS_IronFortNPW_MatAnemia;
        modValue[CONSTANTS.CS_Preg][i][CONSTANTS.CS_Efficacy]    = pt.tableData.value[3][1];
        modValue[CONSTANTS.CS_NonPreg][i][CONSTANTS.CS_Efficacy] = pt.tableData.value[3][2];
        modValue[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract]  = pt.tableData.value[3][3];
        ImpactsOnMatAnemia["source"][0][i] = pt.Source[pt.SourceMap[3]];

        i = CONSTANTS.CS_IPTP_MatAnemia;
        modValue[CONSTANTS.CS_Preg][i][CONSTANTS.CS_Efficacy]   = pt.tableData.value[4][1];
        modValue[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract] = pt.tableData.value[4][3];
        ImpactsOnMatAnemia["source"][0][i] = pt.Source[pt.SourceMap[4]];

        i = CONSTANTS.CS_ITN_MatAnemia;
        modValue[CONSTANTS.CS_NonPreg][i][CONSTANTS.CS_Efficacy] = pt.tableData.value[5][2];
        modValue[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract]  = pt.tableData.value[5][3];
        ImpactsOnMatAnemia["source"][0][i] = pt.Source[pt.SourceMap[5]];

        changedModvars.push(ImpactsOnMatAnemia);

        return (changedModvars);
    },
};

const Setup = (IVInfo, language) => {
    const numCols = 4;
    const numRows = 6;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    pt.tableData.value[0][1] = RS('GB_stEffectiveness');
    pt.tableData.value[0][3] = RS('CS_stAffFract');
    pt.tableData.value[1][1] = RS('GB_stPregnantWomen');
    pt.tableData.value[1][2] = RS('GB_stNonPregnantWomen');

    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 0, 1, 1, 2);

    pt.numSources = 0;

    for (let row = 2; row <= 5; row++) {
        switch(row) {
            case 2 : {
                let IntervRec = FindIntervRec(IVInfo, CONSTANTS.IV_IronSuppPreg);
                if (IntervRec !== null) {
                    pt.tableData.value[row][0] = RS('GB_stIronFolateSupp');
                    pt.IntervRecords[row] = IntervRec;
                }
                break;
            }
            case 3 : {
                let IntervRec = FindIntervRec(IVInfo, CONSTANTS.IV_BlanketIronSuppFort);
                if (IntervRec !== null) {
                    pt.tableData.value[row][0] = IntervRec["Names"][language];
                    pt.IntervRecords[row] = IntervRec;
                }
                break;
            }
            case 4 : {
                let IntervRec = FindIntervRec(IVInfo, CONSTANTS.IV_IPT_Mal);
                if (IntervRec !== null) {
                    pt.tableData.value[row][0] = IntervRec["Names"][language];
                    pt.IntervRecords[row] = IntervRec;
                }
                break;
            }
            case 5 : {
                let IntervRec = FindIntervRec(IVInfo, CONSTANTS.IV_ITN_IRS);
                if (IntervRec !== null) {
                    pt.tableData.value[row][0] = IntervRec["Names"][language];
                    pt.IntervRecords[row] = IntervRec;
                }
                break;
            }
            default : break;
        }
        pt.numSources++;
        pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
        pt.SourceMap[row] = pt.numSources;
        for (let col = 1; col <= 3; col++) {
            pt.Alignments[row][col] = CONSTANTS.GB_Right;
            pt.RDec[row][col] = 3;
            pt.MaxAllowedVal[row][col] = 1;
            if((col === 3) || ((col === 2) && ((row === 2) || (row === 4))) || ((col === 1) && (row === 5))) {
                pt = lockPackRowCol(pt, row, col, true);
            }
        }
    }

    pt = addComment(pt, 2, 0, RS('GB_stIronSupplementationCmnt')); //Iron Supplementation
    pt = addComment(pt, 2, 3, RS('GB_stPercWRAIronDefic')); //Affected fraction Iron supplementation
    pt = addComment(pt, 3, 3, RS('GB_stPercWRAIronDefic')); //Affected fraction Iron fortification
    pt = addComment(pt, 4, 3, RS('GB_stPercWomenFalciparum')); //Affected fraction IPTp
    pt = addComment(pt, 5, 3, RS('GB_stPercWomenFalciparum')); //Affected fraction ITN ownership

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let ImpactsOnMatAnemia = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnMatAnemia_MV3)["value"][
        CONSTANTS.CS_Data];
    let ImpactsOnMatAnemiaSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnMatAnemia_MV3)["source"];

    let i = CONSTANTS.CS_IronFolatePW_MatAnemia;
    pt.tableData.value[2][1] = ImpactsOnMatAnemia[CONSTANTS.CS_Preg][i][CONSTANTS.CS_Efficacy];
    pt.tableData.value[2][2] = '';
    pt.tableData.value[2][3] = ImpactsOnMatAnemia[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract];
    pt.Source[pt.SourceMap[2]] = ImpactsOnMatAnemiaSrc[0][i];

    i = CONSTANTS.CS_IronFortNPW_MatAnemia;
    pt.tableData.value[3][1] = ImpactsOnMatAnemia[CONSTANTS.CS_Preg][i][CONSTANTS.CS_Efficacy];
    pt.tableData.value[3][2] = ImpactsOnMatAnemia[CONSTANTS.CS_NonPreg][i][CONSTANTS.CS_Efficacy];
    pt.tableData.value[3][3] = ImpactsOnMatAnemia[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract];
    pt.Source[pt.SourceMap[3]] = ImpactsOnMatAnemiaSrc[0][i];

    i = CONSTANTS.CS_IPTP_MatAnemia;
    pt.tableData.value[4][1] = ImpactsOnMatAnemia[CONSTANTS.CS_Preg][i][CONSTANTS.CS_Efficacy];
    pt.tableData.value[4][2] = '';
    pt.tableData.value[4][3] = ImpactsOnMatAnemia[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract];
    pt.Source[pt.SourceMap[4]] = ImpactsOnMatAnemiaSrc[0][i];

    i = CONSTANTS.CS_ITN_MatAnemia;
    pt.tableData.value[5][1] = '';
    pt.tableData.value[5][2] = ImpactsOnMatAnemia[CONSTANTS.CS_NonPreg][i][CONSTANTS.CS_Efficacy];
    pt.tableData.value[5][3] = ImpactsOnMatAnemia[CONSTANTS.CS_Preg][i][CONSTANTS.CS_AffecFract];
    pt.Source[pt.SourceMap[5]] = ImpactsOnMatAnemiaSrc[0][i];

    return pt;
};

