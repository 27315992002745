import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    lockPackTable,
    getIdxFromYear,
    getYearFromIdx,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const PercExposedFalciparumEditor = {
    Create : (CSModvars, IPTpCBChecked, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stPercWomenFalciparum');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = PercExposedFalciparumEditor.onCheckBoxClick(packTable, IPTpCBChecked);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_MaternalHealth;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let PercExposedFalciparumMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PercExposedFalciparum_MV);
        PercExposedFalciparumMV["value"] = pt.tableData.value[1][1];
        PercExposedFalciparumMV["source"][0][0] = pt.Source[0];
        changedModvars.push(PercExposedFalciparumMV);
        return (changedModvars);
    },

    onCheckBoxClick : (pt, IPTpCBChecked) => {
        if (pt.yearArr.length > 0) {
            pt = lockPackTable(pt, 1, true, false);
        }
        else{
            pt = lockPackTable(pt, 1, !IPTpCBChecked);
        }
        return (pt);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    let numCols = 2;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stPercWomenFalciparum');

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.RDec[1][col] = 2;
        col++;
    }

    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.RDec[1][1] = 2;

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let PercExposedFalciparum = getModvarByTag(CSModvars, CONSTANTS.CS_TG_PercExposedFalciparum_MV);
    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            if(PercExposedFalciparum["exploreLiST"][t] >= 0) {
                pt.tableData.value[1][col] = PercExposedFalciparum["exploreLiST"][t];
            }
            col++;
        }
    }
    else{
        pt.tableData.value[1][1] = PercExposedFalciparum["value"];
    }

    pt.Source[0] = PercExposedFalciparum["source"][0][0];

    return pt;
};

