import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import Icon from '@material-ui/core/Icon';
import MenuItem from "@material-ui/core/MenuItem";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

function getTypeOfDisplay (chartOptions, resultID, modID)  {
    let typeOfDisplay = CONSTANTS.CS_TableChart;
    for (let i = 0; i < chartOptions.length; i++){
        if ((chartOptions[i]['resultID'] === resultID) && (chartOptions[i]['modID'] === modID)) {
            typeOfDisplay = chartOptions[i]['typeOfDisplay'];
            break;
        }
    }
    return typeOfDisplay
}

class DisplayTypes extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onChangeDisplay : PropTypes.func,
        chartOptions    : PropTypes.arrayOf(PropTypes.object),
        resultID        : PropTypes.number,
        modID           : PropTypes.number,
        isDeskTop       : PropTypes.bool,
        hideMenu        : PropTypes.func
    };

    static defaultProps = {
        onChangeDisplay : () => console.log('onChangeDisplay'),
        chartOptions    : [],
        resultID        : 0,
        modID           : 0,
        isDeskTop       : true,
        hideMenu        : () => console.log("hideMenu")
    };

    state = {
        screenSize: window.innerWidth
    };

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    };

    onResize = () => {
        this.setState({screenSize: window.innerWidth});
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderDisplayOptions = () => {
        let chartTypes = [];
        for (let i = 0; i < this.props.chartOptions.length; i++){
            if (this.props.chartOptions[i]['resultID'] === this.props.resultID) {
                chartTypes = this.props.chartOptions[i]['chartTypes'];
                break;
            }
        }
        return (
            <div style={{float: 'right', marginTop: '9px', width: this.state.screenSize >= CONSTANTS.CS_SmScreen ? "auto" : "100%"}}>
                {
                    (chartTypes.indexOf(CONSTANTS.CS_LineChart) !== -1) ?
                        this.renderLineChartOption(): null
                }

                {
                    (chartTypes.indexOf(CONSTANTS.CS_BarChart) !== -1) ?
                        this.renderBarChartOption(): null
                }

                {
                    (chartTypes.indexOf(CONSTANTS.CS_ColumnChart) !== -1) ?
                        this.renderColumnChartOption(): null
                }

                {
                    (chartTypes.indexOf(CONSTANTS.CS_TableChart) !== -1) ?
                        this.renderTableChartOption(): null
                }
            </div>
        )
    };

    renderLineChartOption = () => {
        return (
            this.state.screenSize >= CONSTANTS.CS_SmScreen ?
                <TCheckBox
                    caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stLineChart")}
                    key={'line'}
                    icon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-line-chart'/>}
                    checkedIcon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-line-chart'/>}
                    color={'primary'}
                    style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                    customProp={'line'}
                    value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID) === CONSTANTS.CS_LineChart}
                    onClick={(checked, event, name, caption, displayType) => this.props.onChangeDisplay(displayType)}
                />
                :
                <MenuItem onClick={() => {this.props.onChangeDisplay("line"); this.props.hideMenu();}}>
                    <TCheckBox
                        caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stLineChart")}
                        key={'line'}
                        icon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-line-chart'/>}
                        checkedIcon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-line-chart'/>}
                        color={'primary'}
                        style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                        customProp={'line'}
                        value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID) === CONSTANTS.CS_LineChart}
                        onClick={(checked, event, name, caption, displayType) => {this.props.onChangeDisplay(displayType); this.props.hideMenu();}}
                    />
                </MenuItem>
        )
    };

    renderColumnChartOption = () => {
        return (
            this.state.screenSize >= CONSTANTS.CS_SmScreen ?
                <TCheckBox
                    caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stColumnChart")}
                    key={'column'}
                    icon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-bar-chart'/>}
                    checkedIcon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-bar-chart'/>}
                    color={'primary'}
                    style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                    customProp={'column'}
                    value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID)=== CONSTANTS.CS_ColumnChart}
                    onClick={(checked, event, name, caption, displayType) => this.props.onChangeDisplay(displayType)}
                />
                :
                <MenuItem onClick={() => {this.props.onChangeDisplay("column"); this.props.hideMenu();}}>
                    <TCheckBox
                        caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stColumnChart")}
                        key={'column'}
                        icon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-bar-chart'/>}
                        checkedIcon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-bar-chart'/>}
                        color={'primary'}
                        style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                        customProp={'column'}
                        value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID)=== CONSTANTS.CS_ColumnChart}
                        onClick={(checked, event, name, caption, displayType) => {this.props.onChangeDisplay(displayType); this.props.hideMenu();}}
                    />
                </MenuItem>
        )
    };

    renderBarChartOption = () => {
        return (
            this.state.screenSize >= CONSTANTS.CS_SmScreen ?
                <TCheckBox
                    caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stBarChart")}
                    key={'bar'}
                    icon={<Icon style={{width: '22px', fontSize: '20px', transform: 'rotate(90deg) scaleX(-1)'}} className='fa fa-bar-chart'/>}
                    checkedIcon={<Icon style={{width: '22px', fontSize: '20px', transform: 'rotate(90deg) scaleX(-1)', color:'primary'}} className='fa fa-bar-chart'/>}
                    color={'primary'}
                    style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                    customProp={'bar'}
                    value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID)=== CONSTANTS.CS_BarChart}
                    onClick={(checked, event, name, caption, displayType) => this.props.onChangeDisplay(displayType)}
                />
                :
                <MenuItem onClick={() => {this.props.onChangeDisplay("bar"); this.props.hideMenu();}}>
                    <TCheckBox
                        caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stBarChart")}
                        key={'bar'}
                        icon={<Icon style={{width: '22px', fontSize: '20px', transform: 'rotate(90deg) scaleX(-1)'}} className='fa fa-bar-chart'/>}
                        checkedIcon={<Icon style={{width: '22px', fontSize: '20px', transform: 'rotate(90deg) scaleX(-1)', color:'primary'}} className='fa fa-bar-chart'/>}
                        color={'primary'}
                        style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                        customProp={'bar'}
                        value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID)=== CONSTANTS.CS_BarChart}
                        onClick={(checked, event, name, caption, displayType) => {this.props.onChangeDisplay(displayType); this.props.hideMenu();}}
                    />
                </MenuItem>
        )
    };

    renderTableChartOption = () => {
        return (
            this.state.screenSize >= CONSTANTS.CS_SmScreen ?
                <TCheckBox
                    caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stTable")}
                    key={'table'}
                    icon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-table'/>}
                    checkedIcon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-table'/>}
                    color={'primary'}
                    style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                    customProp={'table'}
                    value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID)=== CONSTANTS.CS_TableChart}
                    onClick={(checked, event, name, caption, displayType) => this.props.onChangeDisplay(displayType)}
                />
                :
                <MenuItem onClick={() => {this.props.onChangeDisplay("table"); this.props.hideMenu();}}>
                    <TCheckBox
                        caption={this.state.screenSize >= CONSTANTS.CS_SmScreen ? '' : RS("GB_stTable")}
                        key={'table'}
                        icon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-table'/>}
                        checkedIcon={<Icon style={{width: '22px', fontSize: '20px'}} className='fa fa-table'/>}
                        color={'primary'}
                        style={{fontSize: '20px', display: this.state.screenSize >= CONSTANTS.CS_SmScreen ? 'inline' : 'block'}}
                        customProp={'table'}
                        value={getTypeOfDisplay(this.props.chartOptions, this.props.resultID, this.props.modID)=== CONSTANTS.CS_TableChart}
                        onClick={(checked, event, name, caption, displayType) => {this.props.onChangeDisplay(displayType); this.props.hideMenu();}}
                    />
                </MenuItem>
        )
    };

    render() {
        return (
            <div style={{height: '26px'}}>
                {this.renderDisplayOptions()}
            </div>
        );
    }
}

export default DisplayTypes;