import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from "../../../../components/CS/CSEditors";
import EditorNote from "../../../../components/EditorNote";
import { SingleStuntWastEditor } from "../../data/Coverage/CS/SingleStuntWastEditor";

class CSSingleWastingDrawer extends React.Component {

    static propTypes = {
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        firstYear        : PropTypes.number,
        finalYear        : PropTypes.number,
        onApply          : PropTypes.func.isRequired,
        onClose          : PropTypes.func.isRequired,
        onOpenHelpDialog : PropTypes.func.isRequired,
    };

    state = {
        CSModvars        : [],
        packTable        : null,
        saveBtnDisabled  : false, //true,
    };    

    single = true;
    subnatMode = false;
    
    componentDidMount () {
        const { CSModvars, firstYear, finalYear } = this.props;
        
        this.setState({
            CSModvars : CSModvars,
            packTable : SingleStuntWastEditor.Create(CSModvars, firstYear, finalYear, CONSTANTS.CS_GroupMstID_Wasting)[CONSTANTS.CS_PackTable],
        });
    }

    _onClose = () => {
        this.props.onClose();
    };

    _onApply = () => {
        let packTable = this.state.packTable;

        let goAhead = true;

        packTable.tableData.value.forEach(function(arr) {
            arr.forEach(function(val) {
                if (typeof val === "number" && val > 100) {
                    goAhead = false;
                }
            });
        });

        if (goAhead) {
            let CSModvarsClone = SingleStuntWastEditor.GetSSData(packTable, this.state.CSModvars, CONSTANTS.CS_GroupMstID_Wasting);
            this.props.onApply(CSModvarsClone);
        }
        else {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : RS("GB_stValOver100")
            });
        }
    };

    _onPackTableChange = packTable => {
        // packTable = StuntWastEditor.RecalcGrid(packTable);
        //
        this.setState({
            saveBtnDisabled : false,
            packTable : packTable
        });
    };

    render() {
        let packTable = JSON.parse(JSON.stringify(this.state.packTable));
        // if (packTable) {
        //     packTable.Title = packTable.Title + " (" + RS('GB_stNoHistYearValChange') + ")";
        // }

        return (
            <TDrawerWithHeader
                open={true}
                onOpenHelpDialog={this.props.onOpenHelpDialog}
                helpFile={"wasting.htm"}
                onClose={this._onClose}
                onSave={this._onApply}
                saveBtnCaption={RS("GB_stApply")}
                saveBtnDisabled={this.state.saveBtnDisabled}
                appBarText={RS("GB_stWasting") + " - " + RS("GB_stSingleIndicator")}
                //paren={RS('GB_stNoHistYearValChange')}
                content={
                    <div className="innerPage">
                        <EditorNote numberOfTables={1} marginBottom="5px" />

                        <p></p>

                        <CSEditor
                            mstID={CONSTANTS.CS_MstLiSTOnlineSingleWastingSheetID}
                            showTitle={true}
                            packTable={packTable}
                            paren={RS('GB_stNoHistYearValChange')}
                            onChange={this._onPackTableChange}
                        />
                    </div>
                }
            />
        );
    }
}

export default CSSingleWastingDrawer;