import RS from "@common/strings/RS";
import CONSTANTS from './CSConst.js';

export const getCSCoDActive = (CSModvars, CoDType, d) => {
    // let modvar = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CustomCoD_MV);
    // let modValue = modvar["value"];
    let result = false;

    switch (CoDType) {
        case CONSTANTS.CS_CoD_MstNNPostNN : {
            if (getCSCoDActive(CSModvars, CONSTANTS.CS_CoD_MstNeonatal, d) || getCSCoDActive(CSModvars, CONSTANTS.CS_CoD_MstPostNeonatal, d))
                result = true;
            break;
        }

        case CONSTANTS.CS_CoD_MstNeonatal : {
            /* Standard */
            if (CONSTANTS.CS_NNDeathArray.indexOf(d) > -1) {
                result = true;
            }
            /* Custom */
            // else if (CONSTANTS.CS_NNDeathCustomArray.indexOf(d) > -1) {
            //     if (modValue[CONSTANTS.CS_CustomCoDNN][d - CONSTANTS.CS_CustomNNCoD1 + 1]["status"]) {// if CS.GetCustomCOD(CS_CustomCoDNN, d - CS_CustomNNCOD1 + 1).status < > CS_NotActive then
            //         result = true;
            //     }
            // }
            break;
        }

        case CONSTANTS.CS_CoD_MstPostNeonatal : {
            /* Standard */
            if (CONSTANTS.CS_PNNDeathArray.indexOf(d) > -1) {
                result = true;
            }
            /* Custom */
            // else if (CONSTANTS.CS_PNNDeathCustomArray.indexOf(d) > -1) {
            //     if (modValue[CONSTANTS.CS_CustomCoDPNN][d - CONSTANTS.CS_CustomPNNCoD1 + 1]["status"]) {// if CS.GetCustomCOD(CS_CustomCoDPNN, d - CS_CustomPNNCOD1 + 1).status < > CS_NotActive then
            //         result = true;
            //     }
            // }
            break;
        }

        case CONSTANTS.CS_CoD_MstStillbirth : {
            /* Standard Only */
            result = true;
            break;
        }

        case CONSTANTS.CS_CoD_MstMaternal : {
            /* Standard Only */
            result = true;
            break;
        }

        default : {
            break;
        }
    }
    return result;
};

export const getCSGetDeathCause = (CSModvars, d) => {
    // let modvar = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CustomCoD_MV);
    // let modValue = modvar["value"];
    let result = '';

    if ((CONSTANTS.CS_NNDeathArray.indexOf(d) > -1) || (CONSTANTS.CS_PNNDeathArray.indexOf(d) > -1)) {  //if d in [CS_NNDiarr..CS_NNOther, CS_Diarrhea..CS_Other] then
        switch (d) {
            case CONSTANTS.CS_NNDiarr          : {result = RS('GB_stNeoDiarr'); break;}
            case CONSTANTS.CS_NNSepsis         : {result = RS('GB_stNeoSepsis'); break;}
            case CONSTANTS.CS_NNPneumonia      : {result = RS('GB_stNeoPneu'); break;}
            case CONSTANTS.CS_NNAsphyxia       : {result = RS('GB_stNeoAsphyxia'); break;}
            case CONSTANTS.CS_NNPreterm        : {result = RS('GB_stNeoPrematur'); break;}
            case CONSTANTS.CS_NNTetanus        : {result = RS('GB_stNeoTetan'); break;}
            case CONSTANTS.CS_NNCongen         : {result = RS('GB_stNeoCongAnom'); break;}
            case CONSTANTS.CS_NNOther          : {result = RS('GB_stNeoOther'); break;}
            case CONSTANTS.CS_Diarrhea         : {result = RS('GB_stDiarrhea'); break;}
            case CONSTANTS.CS_Pneumonia        : {result = RS('GB_stPneumonia'); break;}
            case CONSTANTS.CS_Meningitis       : {result = RS('GB_stMeningitis'); break;}
            case CONSTANTS.CS_Measles          : {result = RS('GB_stMeasles'); break;}
            case CONSTANTS.CS_Malaria          : {result = RS('GB_stMalaria'); break;}
            case CONSTANTS.CS_Pertussis        : {result = RS('GB_stPertussis'); break;}
            case CONSTANTS.CS_AIDS             : {result = RS('GB_stAIDS'); break;}
            case CONSTANTS.CS_Injury           : {result = RS('GB_stInjury'); break;}
            case CONSTANTS.CS_OtherInfecDis    : {result = RS('GB_stOtherInfecDis'); break;}
            case CONSTANTS.CS_OtherNCD         : {result = RS('GB_stOtherNCD'); break;}

            default : {
                break;
            }
        }   // result = CSGetDeathCause(d);
    }
    // else if (CONSTANTS.CS_NNDeathCustomArray.indexOf(d) > -1) {  //if d in [CS_CustomNNCod1..CS_CustomNNCod4] then
    //     result = modValue[CONSTANTS.CS_CustomCoDNN][d - CONSTANTS.CS_CustomNNCoD1 + 1]["name"];//CS.GetCustomCOD(CS_CustomCodNN, d - CS_CustomNNCoD1 + 1).Name
    // }
    // else if (CONSTANTS.CS_PNNDeathCustomArray.indexOf(d) > -1) {   //if d in [CS_CustomPNNCod1..CS_CustomPNNCod4] then
    //     result = modValue[CONSTANTS.CS_CustomCoDPNN][d - CONSTANTS.CS_CustomPNNCoD1 + 1]["name"];//CS.GetCustomCOD(CS_CustomCodPNN, d - CS_CustomPNNCoD1 + 1).Name;
    // }

    return result;
};

//current IDs for input Modvars
export const getChildCODName = (d) => {
    let result = '';
    switch (d) {
        case CONSTANTS.CS_NNDiarr     : {result = RS('GB_stNeoDiarr'); break;}
        case CONSTANTS.CS_NNSepsis    : {result = RS('GB_stNeoSepsis'); break;}
        case CONSTANTS.CS_NNPneumonia : {result = RS('GB_stNeoPneu'); break;}
        case CONSTANTS.CS_NNAsphyxia  : {result = RS('GB_stNeoAsphyxia'); break;}
        case CONSTANTS.CS_NNPreterm   : {result = RS('GB_stNeoPrematur'); break;}
        case CONSTANTS.CS_NNTetanus   : {result = RS('GB_stNeoTetan'); break;}
        case CONSTANTS.CS_NNCongen    : {result = RS('GB_stNeoCongAnom'); break;}
        case CONSTANTS.CS_NNOther     : {result = RS('GB_stNeoOther'); break;}
        case CONSTANTS.CS_Diarrhea    : {result = RS('GB_stDiarrhea'); break;}
        case CONSTANTS.CS_Pneumonia   : {result = RS('GB_stPneumonia'); break;}
        case CONSTANTS.CS_Meningitis  : {result = RS('GB_stMeningitis'); break;}
        case CONSTANTS.CS_Measles     : {result = RS('GB_stMeasles'); break;}
        case CONSTANTS.CS_Malaria     : {result = RS('GB_stMalaria'); break;}
        case CONSTANTS.CS_Pertussis   : {result = RS('GB_stPertussis'); break;}
        case CONSTANTS.CS_AIDS        : {result = RS('GB_stAIDS'); break;}
        case CONSTANTS.CS_Injury      : {result = RS('GB_stInjury'); break;}
        case CONSTANTS.CS_OtherInfecDis : {result = RS('GB_stOtherInfecDis'); break;}
        case CONSTANTS.CS_OtherNCD    : {result = RS('GB_stOtherNCD'); break;}
        default : {
            break;
        }
    }
    return (result)
};

//current IDs for input Modvars
export const getSBCODName = (d) => {
    let result = '';
    switch (d) {
        case CONSTANTS.CS_SB_Antepartum  : {result = RS('GB_stAntepartum'); break;}
        case CONSTANTS.CS_SB_Intrapartum : {result = RS('GB_stIntrapartum'); break;}
        default : {
            break;
        }
    }
    return (result)
};

//current IDs for input Modvars
export const getMatCODName = (d) => {
    let result = '';
    switch (d) {
        case CONSTANTS.CS_Mat_AntepartHemorr    : {result = RS('GB_stAntepartumHem'); break;}
        case CONSTANTS.CS_Mat_IntrapartHemorr   : {result = RS('GB_stIntrapartumHem'); break;}
        case CONSTANTS.CS_Mat_PostpartHemorr    : {result = RS('GB_stPostpartumHem'); break;}
        case CONSTANTS.CS_Mat_HypertensiveDis   : {result = RS('GB_stHypertensive'); break;}
        case CONSTANTS.CS_Mat_Sepsis            : {result = RS('GB_stSepsis'); break;}
        case CONSTANTS.CS_Mat_Abortion          : {result = RS('GB_stAbortion'); break;}
        case CONSTANTS.CS_Mat_Embolism          : {result = RS('GB_stEmbolism'); break;}
        case CONSTANTS.CS_Mat_OtherDirectCauses : {result = RS('GB_stOtherDirectCauses'); break;}
        case CONSTANTS.CS_Mat_IndirectCauses    : {result = RS('GB_stIndirectCauses'); break;}
        default : {
            break;
        }
    }
    return (result)
};

export const getAgeCohortName = (a, skipCS_1t59months = false) => {
    let result = '';
    if (!skipCS_1t59months){
        switch (a) {
            case CONSTANTS.CS_AgeSummary  : {result = RS('GB_stTotal0t59mos'); break;}
            case CONSTANTS.CS_Birth       : {result = RS('GB_stBirth'); break;}
            case CONSTANTS.CS_0t1months   : {result = RS('GB_st0t1mos'); break;}
            case CONSTANTS.CS_1t59months  : {result = RS('GB_st1t59months'); break;}
            case CONSTANTS.CS_1t6months   : {result = RS('GB_st1t5months'); break;}
            case CONSTANTS.CS_6t12months  : {result = RS('GB_st6t11months'); break;}
            case CONSTANTS.CS_12t24months : {result = RS('GB_st12t23months'); break;}
            case CONSTANTS.CS_24t60months : {result = RS('GB_st24t59months'); break;}
            case CONSTANTS.CS_24t36months : {result = RS('GB_st24t36mos'); break;}
            case CONSTANTS.CS_36t48months : {result = RS('GB_st36t48mos'); break;}
            case CONSTANTS.CS_48t60months : {result = RS('GB_st48t60mos'); break;}

            default : {
                break;
            }
        }
    }
    else{
        switch (a) {
            case CONSTANTS.CS_AgeSummary  : {result = RS('GB_stTotal0t59mos'); break;}
            case CONSTANTS.CS_Birth       : {result = RS('GB_stBirth'); break;}
            case CONSTANTS.CS_0t1months   : {result = RS('GB_st0t1mos'); break;}
            case CONSTANTS.CS_1t6months   : {result = RS('GB_st1t5months'); break;}
            case CONSTANTS.CS_6t12months  : {result = RS('GB_st6t11months'); break;}
            case CONSTANTS.CS_12t24months : {result = RS('GB_st12t23months'); break;}
            case CONSTANTS.CS_24t60months : {result = RS('GB_st24t59months'); break;}
            case CONSTANTS.CS_24t36months : {result = RS('GB_st24t36mos'); break;}
            case CONSTANTS.CS_36t48months : {result = RS('GB_st36t48mos'); break;}
            case CONSTANTS.CS_48t60months : {result = RS('GB_st48t60mos'); break;}

            default : {
                break;
            }
        }
    }
    return (result)
};

/* This function returns a string for the year span */
export const CSGetYearName = (AgeId) => {
    let result = '';
    switch(AgeId) {
        case CONSTANTS.CS_0t1Yr : {result = '0-1 ' + RS('GB_stYearsLowerCase'); break;}
        case CONSTANTS.CS_1t2Yr : {result = '1-2 ' + RS('GB_stYearsLowerCase'); break;}
        case CONSTANTS.CS_2t3Yr : {result = '2-3 ' + RS('GB_stYearsLowerCase'); break;}
        case CONSTANTS.CS_3t4Yr : {result = '3-4 ' + RS('GB_stYearsLowerCase'); break;}
        case CONSTANTS.CS_4t5Yr : {result = '4-5 ' + RS('GB_stYearsLowerCase'); break;}
        default : break;
    }
    return (result);
};

export const getSexName = (s) => {
    let result = '';

    switch (s) {
        case CONSTANTS.DP_BothSexes  : {result = RS('GB_stBothSexes'); break;}
        case CONSTANTS.DP_Male       : {result = RS('GB_stMale'); break;}
        case CONSTANTS.DP_Female     : {result = RS('GB_stFemale'); break;}

        default : {
            break;
        }
    }
    return (result)
};

export const GetLastIncRedInterv = (d) => {
    let result = 0;

    switch (d) {
        case CONSTANTS.CS_Diarrhea   : {result = CONSTANTS.CS_NumDiarIntervs; break;}
        case CONSTANTS.CS_Pneumonia  : {result = CONSTANTS.CS_NumPneumIntervs; break;}
        case CONSTANTS.CS_Meningitis : {result = CONSTANTS.CS_NumMeningIntervs; break;}
        default : break;
    }
    return (result)
};

/* This function retrieves the string for Diarrhea interventions */
export const CSGetDiarrheaInterv = (i) => {
    let result = '';
    switch (i) {
        case CONSTANTS.CS_ImprovedExcretaDisp_DiarInterv   : {result = RS('GB_stImprovedExcretaDisp'); break;}
        case CONSTANTS.CS_ImpWaterSource_DiarInterv        : {result = RS('GB_stUseOfImprovedWaterSrce'); break;}
        case CONSTANTS.CS_WaterConnectionHome_DiarInterv   : {result = RS('GB_stUseOfWaterConnection'); break;}
        case CONSTANTS.CS_HandWashWSoap_DiarInterv         : {result = RS('GB_stHandWashWSoap'); break;}
        case CONSTANTS.CS_HygDisposalStools_DiarInterv     : {result = RS('GB_stHygDisposalStools'); break;}
        case CONSTANTS.CS_ZincP_DiarInterv                 : {result = RS('CS_stZincPrevent'); break;}
        case CONSTANTS.CS_VitAPTwoDoses_DiarInterv         : {result = RS('GB_stVitaAPrev'); break;}
        case CONSTANTS.CS_RotavirusVacc_DiarInterv         : {result = RS('GB_stRotavirus'); break;}
        case CONSTANTS.CS_DiarVaccPathB_DiarInterv         : {result = RS('GB_stVaccineB'); break;}
        case CONSTANTS.CS_DiarVaccPathC_DiarInterv         : {result = RS('GB_stVaccineC'); break;}
        case CONSTANTS.CS_BFPromotion_DiarInterv           : {result = RS('GB_stBreastFeed'); break;}
        default : break;
    }
    return (result)
};

/* This function retrieves the string for Pneumonia interventions */
export const CSGetPneumoniaInterv = (i) => {
    let result = '';
    switch (i) {
        case CONSTANTS.CS_HibVacc_PneumInterv   : {result = RS('CS_stHibVacc'); break;}
        case CONSTANTS.CS_PneumVacc_PneumInterv : {result = RS('CS_stPneumVacc'); break;}
        case CONSTANTS.CS_ZincP_PneumInterv     : {result = RS('CS_stZincPrevent'); break;}
        default : break;
    }
    return (result)
};

/* This function retrieves the string for Meningitis interventions */
export const CSGetMeningitisInterv = (i) => {
    let result = '';
    switch (i) {
        case CONSTANTS.CS_HibVacc_MeningInterv   : {result = RS('CS_stHibVacc'); break;}
        case CONSTANTS.CS_PneumVacc_MeningInterv : {result = RS('CS_stPneumVacc'); break;}
        case CONSTANTS.CS_MeningA_MeningInterv   : {result = RS('GB_stMeningA'); break;}
        default : break;
    }
    return (result)
};

export const CSGetCauseDeathInterv = (d, i) => {
    let result = '';
    switch (d) {
        case CONSTANTS.CS_Diarrhea   : {result = CSGetDiarrheaInterv(i); break;}
        case CONSTANTS.CS_Pneumonia  : {result = CSGetPneumoniaInterv(i); break;}
        case CONSTANTS.CS_Meningitis : {result = CSGetMeningitisInterv(i); break;}
        default : break;
    }
    return (result)
};

export const GetLastPathogen = (d) => {
    let result = 0;

    switch (d) {
        case CONSTANTS.CS_Diarrhea   : {result = CONSTANTS.CS_NumPathsDiar; break;}
        case CONSTANTS.CS_Pneumonia  : {result = CONSTANTS.CS_NumPathsPneum; break;}
        case CONSTANTS.CS_Meningitis : {result = CONSTANTS.CS_NumPathsMenin; break;}
        default : break;
    }
    return (result)
};

export const CSGetPathogenName = (deathCause, pathogen) => {

    let result = '';

    switch (deathCause) {
        case CONSTANTS.CS_Diarrhea :
            switch (pathogen) {
                case CONSTANTS.CS_RotaPathDiar   : {result = RS('GB_stRotavirus'); break;}
                case CONSTANTS.CS_PathBDiar      : {result = RS('GB_stPathogenB'); break;}
                case CONSTANTS.CS_PathCDiar      : {result = RS('GB_stPathogenC'); break;}
                case CONSTANTS.CS_OtherPathsDiar : {result = RS('GB_stAllOtherPathogens'); break;}
                case CONSTANTS.CS_AllPathsDiar   : {result = RS('DP_stTotal'); break;}
                default : break;
            }
            break;

        case CONSTANTS.CS_Pneumonia :
            switch (pathogen) {
                case CONSTANTS.CS_HibPathPneum       : {result = RS('GB_stHib'); break;}
                case CONSTANTS.CS_PneumocPathPneum   : {result = RS('GB_stSPneumococcus'); break;}
                case CONSTANTS.CS_InfluenzaPathPneum : {result = RS('GB_stInfluenza'); break;}
                case CONSTANTS.CS_OtherPathsPneum    : {result = RS('GB_stAllOtherPathogens'); break;}
                case CONSTANTS.CS_AllPathsPneum      : {result = RS('DP_stTotal'); break;}
                default : break;
            }
            break;

        case CONSTANTS.CS_Meningitis :
            switch (pathogen) {
                case CONSTANTS.CS_HibPathMenin       : {result = RS('GB_stHib'); break;}
                case CONSTANTS.CS_PneumocPathMenin   : {result = RS('GB_stSPneumococcus'); break;}
                case CONSTANTS.CS_MeningitAPathMenin : {result = RS('GB_stNMeningTypeA'); break;}
                case CONSTANTS.CS_OtherPathsMenin    : {result = RS('GB_stAllOtherPathogens'); break;}
                case CONSTANTS.CS_AllPathsMenin      : {result = RS('DP_stTotal'); break;}
                default : break;
            }
            break;
        default : break;
    }
    return (result);
};

/* This function retrieves the string for Meningitis interventions */
export const CSGetStdDevBandName = (i) => {
    let result = '';
    switch (i) {
        case CONSTANTS.CS_GT1STD : {result = RS('GB_stGT1STD'); break;}
        case CONSTANTS.CS_1t2STD : {result = RS('GB_s1t2STD'); break;}
        case CONSTANTS.CS_2t3STD : {result = RS('GB_st2t3STD'); break;}
        case CONSTANTS.CS_GT3STD : {result = RS('GB_stGT3STD'); break;}
        default : break;
    }
    return (result)
};

export const  CSGetFirstBFType = (a) => {
    let result = 0;
    switch(a){
        case CONSTANTS.CS_0t1months   : {result = CONSTANTS.CS_ExclusiveBF; break;}
        case CONSTANTS.CS_1t6months   : {result = CONSTANTS.CS_ExclusiveBF; break;}
        case CONSTANTS.CS_6t12months  : {result = CONSTANTS.CS_PartialBF; break;}
        case CONSTANTS.CS_12t24months : {result = CONSTANTS.CS_PartialBF; break;}
        default : break;
    }
    return (result);
};

/* <1 month, 1-5 months (a = CS_0t1Months, CS_1t6Months):

Exclusive breastfeeding  (bf = CS_ExclusiveBF)
Predominant breastfeeding (bf = CS_PredominantBF)
Partial breastfeeding (bf = CS_PartialBF)
Not breastfeeding (bf = CS_NotBF)

6-11 months, 12-23 months (a = CS_6t12Months, CS_12t24Months):

Any breastfeeding (bf = CS_PartialBF)
Not breastfeeding (bf = CS_NotBF)
*/
export const CSGetBreastfeedingTypeName = (a, bf) => {
    let result = '';

    if (bf === CONSTANTS.CS_NotBF){
        result = RS('GB_stNotBF');
    }

    else{
        if ([CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months].includes(a)) {
            switch (bf) {
                case CONSTANTS.CS_ExclusiveBF   : {result = RS('GB_stExBF'); break;}
                case CONSTANTS.CS_PredominantBF : {result = RS('GB_stPredomBF'); break;}
                case CONSTANTS.CS_PartialBF     : {result = RS('GB_stParBF'); break;}
                default : break;
            }
        }

        else if ([CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months].includes(a)) {
            if (bf === CONSTANTS.CS_PartialBF) {result = RS('GB_stAnyBreastfeeding');}
        }
    }

    return (result);
};

/* This function retrieves the string for SGA and AGA */
export const CSGetBirthTerm = (i) => {
    let result = '';
    switch (i) {
        case CONSTANTS.CS_PreTermSGA : {result = RS('GB_stPreTermSGA'); break;}
        case CONSTANTS.CS_PreTermAGA : {result = RS('GB_stPreTermAGA'); break;}
        case CONSTANTS.CS_TermSGA    : {result = RS('GB_stTermSGA'); break;}
        case CONSTANTS.CS_TermAGA    : {result = RS('GB_stTermAGA'); break;}
        default : break;
    }
    return (result);
};

export const CSGetDetVaccDiseases = (vt) => {
    let result = [];
    switch(vt) {
        case CONSTANTS.CS_Meas_Det    : {result = [CONSTANTS.CS_Measles]; break;}
        case CONSTANTS.CS_Malaria_Det : {result = [CONSTANTS.CS_Malaria]; break;}
        case CONSTANTS.CS_Rota_Det    : {result = [CONSTANTS.CS_Diarrhea]; break;}
        case CONSTANTS.CS_DPT_Det     : {result = [CONSTANTS.CS_Pertussis]; break;}
        case CONSTANTS.CS_HiB_Det     : {result = [CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis]; break;}
        case CONSTANTS.CS_MenA_Det    : {result = [CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis]; break;}
        case CONSTANTS.CS_PCV_Det     : {result = [CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis, CONSTANTS.CS_OtherInfecDis, CONSTANTS.CS_OtherNCD]; break;}
        case CONSTANTS.CS_VaccB_Det   : {result = [CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Measles, CONSTANTS.CS_Malaria, CONSTANTS.CS_Meningitis, CONSTANTS.CS_Pertussis, CONSTANTS.CS_OtherInfecDis, CONSTANTS.CS_OtherNCD]; break;}
        case CONSTANTS.CS_VaccC_Det   : {result = [CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Measles, CONSTANTS.CS_Malaria, CONSTANTS.CS_Meningitis, CONSTANTS.CS_Pertussis, CONSTANTS.CS_OtherInfecDis, CONSTANTS.CS_OtherNCD]; break;}
        case CONSTANTS.CS_VaccD_Det   : {result = [CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Measles, CONSTANTS.CS_Malaria, CONSTANTS.CS_Meningitis, CONSTANTS.CS_Pertussis, CONSTANTS.CS_OtherInfecDis, CONSTANTS.CS_OtherNCD]; break;}
        default : break;
    }
    return (result);
};

export const CSGetDetVaccName = (vt) => {
    let result = '';
    switch(vt) {
        case CONSTANTS.CS_BCG_Det     : {result = RS('GB_stBCG'); break;}
        case CONSTANTS.CS_Polio_Det   : {result = RS('GB_stPolio'); break;}
        case CONSTANTS.CS_DPT_Det     : {result = RS('GB_stDPT'); break;}
        case CONSTANTS.CS_HiB_Det     : {result = RS('GB_stHib'); break;}
        case CONSTANTS.CS_HepB_Det    : {result = RS('GB_stHepB'); break;}
        case CONSTANTS.CS_PCV_Det     : {result = RS('GB_stPneumococcal'); break;}
        case CONSTANTS.CS_Rota_Det    : {result = RS('GB_stRotavirus'); break;}
        case CONSTANTS.CS_Meas_Det    : {result = RS('CS_stMeasles'); break;}
        case CONSTANTS.CS_MenA_Det    : {result = RS('GB_stMeningA'); break;}
        case CONSTANTS.CS_VaccB_Det   : {result = RS('GB_stDiarrheaVaccPathB'); break;}
        case CONSTANTS.CS_VaccC_Det   : {result = RS('GB_stDiarrheaVaccPathC'); break;}
        case CONSTANTS.CS_Malaria_Det : {result = RS('GB_stMalariaVaccine'); break;}
        case CONSTANTS.CS_VaccD_Det   : {result = RS('GB_stVaccineD'); break;}
	default : break;
    }
    return (result);
};

/* Return the max doses for various detailed vaccines-- the rest have no doses */
/* the max dose has been specified by Bill Winfrey 12/4/11 aside from BCG, which
I put as one, because that's the max that we can enter in coverage */
export const CSGetDetVaccMaxDose = (vt) => {
    let result = 0;
    switch (vt) {
        case CONSTANTS.CS_BCG_Det     : {result = CONSTANTS.CS_VaccDoseOne; break;}
        case CONSTANTS.CS_Polio_Det   : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_HepB_Det    : {result = CONSTANTS.CS_VaccBooster; break;}
        case CONSTANTS.CS_DPT_Det     : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_HiB_Det     : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_PCV_Det     : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_Rota_Det    : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_Meas_Det    : {result = CONSTANTS.CS_VaccDoseTwo; break;}
        case CONSTANTS.CS_MenA_Det    : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_VaccB_Det   : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_VaccC_Det   : {result = CONSTANTS.CS_VaccDoseThree; break;}
        case CONSTANTS.CS_Malaria_Det : {result = CONSTANTS.CS_VaccBooster; break;}
        case CONSTANTS.CS_VaccD_Det   : {result = CONSTANTS.CS_VaccDoseThree; break;}
	default : break;
    }
    return (result);
};

/* Detailed vaccine & associated information */
export const CSGetDoseString = (index) => {
    let result = '';
    switch(index) {
        case CONSTANTS.CS_VaccDoseZero  : {result = RS('GB_stZeroDoses'); break;}
        case CONSTANTS.CS_VaccDoseOne   : {result = RS('GB_stSingleDose'); break;}
        case CONSTANTS.CS_VaccDoseTwo   : {result = RS('GB_stTwoDoses'); break;}
        case CONSTANTS.CS_VaccDoseThree : {result = RS('GB_stThreeDoses'); break;}
        case CONSTANTS.CS_VaccBooster   : {result = RS('GB_stBooster'); break;}
	default : break;
    }
    return (result);
};

/* For Guided exploration: Setting the selections for cause of death to all, need to set all causes to true */
export const CSGetGuidedCODContentForPercRed = (content) => {
    let totalSelected = 0;

    for (let i = 0; i < content.length; i++) {
        if (content[i]['checked']) {
            totalSelected++;
        }
    }
    if ((totalSelected === 0) ||(totalSelected > 1)) {
        for (let j = 0; j < content.length; j++) {
            content[j]['checked'] = true;
        }
    }
    return (content)
};

export const  CSGetGroupMstIDFromLiSTOnlineID = (id) => {
    let result = 0;
    switch(id) {
        case CONSTANTS.CS_LiSTOnline_Periconcep       : {result = CONSTANTS.CS_GroupMstID_Periconceptual; break;}
        case CONSTANTS.CS_LiSTOnline_Preg             : {result = CONSTANTS.CS_GroupMstID_Pregnancy; break;}
        case CONSTANTS.CS_LiSTOnline_Childbirth       : {result = CONSTANTS.CS_GroupMstID_Childbirth; break;}
        case CONSTANTS.CS_LiSTOnline_Nutrition        : {result = CONSTANTS.CS_GroupMstID_NutritionalStatus; break;}
        case CONSTANTS.CS_LiSTOnline_Preventive       : {result = CONSTANTS.CS_GroupMstID_Preventive; break;}
        case CONSTANTS.CS_LiSTOnline_Vaccines         : {result = CONSTANTS.CS_GroupMstID_Vaccines; break;}
        case CONSTANTS.CS_LiSTOnline_Curative         : {result = CONSTANTS.CS_GroupMstID_Curative; break;}
        default : break;
    }
    return (result);
};


export const CSGetGroupStr = (id) => {
    let result = '';
    switch(id) {
        case CONSTANTS.CS_GroupMstID_Periconceptual  : {result = RS('GB_stPericonceptual'); break;}
        case CONSTANTS.CS_GroupMstID_Pregnancy       : {result = RS('GB_stPregnancy'); break;}
        case CONSTANTS.CS_GroupMstID_Childbirth      : {result = RS('GB_stChildbirth'); break;}
        case CONSTANTS.CS_GroupMstID_Breastfeeding   : {result = RS('GB_stBreastFeed'); break;}
        case CONSTANTS.CS_GroupMstID_Preventive      : {result = RS('GB_stPreventive'); break;}
        case CONSTANTS.CS_GroupMstID_Vaccines        : {result = RS('GB_stVaccines'); break;}
        case CONSTANTS.CS_GroupMstID_Curative        : {result = RS('GB_stCurative'); break;}
        default : break;
    }
    return (result);
};

export const CSGetLiSTOnlineGroupStr = (id, nutrAsBF) => {
    let result = CSGetGroupStr(CSGetGroupMstIDFromLiSTOnlineID(id));
    if (result === '') {
        switch(id) {
            case CONSTANTS.CS_LiSTOnline_Nutrition : {
                if (nutrAsBF) {
                    result = CSGetGroupStr(CONSTANTS.CS_GroupMstID_Breastfeeding);
                }
                else{
                    result = RS('GB_stNutritionalStatus');
                }
                break;
            }
            default : break;
        }
    }
    return (result);
};

export const  CSGetModName = (modID) => {
    let result = 0;
    switch(modID) {
        case CONSTANTS.GB_CS : {result = RS('GB_stLiST'); break;}
        case CONSTANTS.GB_DP : {result = RS('GB_stDemProj'); break;}
        case CONSTANTS.GB_FP : {result = RS('GB_stFP'); break;}
        case CONSTANTS.GB_AM : {result = RS('GB_stAIM'); break;}
        default : {break;}
    }
    return (result);
};
