import React from 'react';
import PropTypes from "prop-types";
import TSwitch from "@common/components/TSwitch";
import TCheckBox from "@common/components/TCheckBox";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from "../../../../components/EditorNote";
import {CoverageEditor} from "../../data/Coverage/CS/CoverageEditor";
import {CloneObj, getModvarByTag, getModvarCloneByTag} from "utilities/GB/GBUtil";
// import CSEffectivenessCoverageBlueBox from '../../cards/Effectiveness/CSEffectivenessCoverageBlueBox';

const SelectedIVSetTag = CONSTANTS.CS_TG_SelectedIVSet_MV;

class CSCoverageDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        CSModvars              : PropTypes.array,
        firstYear              : PropTypes.number,
        finalYear              : PropTypes.number,
        language               : PropTypes.number,
        IVInfo                 : PropTypes.arrayOf(PropTypes.object),
        onOpenHelpDialog       : PropTypes.func,
        onCloseDrawer          : PropTypes.func,
        onStateChange          : PropTypes.func,
        showAllInterventionsCB : PropTypes.bool,
        showRetroSwitch        : PropTypes.bool,
        showQualitySwitch      : PropTypes.bool,
        showBFCB               : PropTypes.bool,
    };

    static defaultProps = {
	    CSModvars              : [],
        firstYear              : 1900,
        finalYear              : 1909,
        language               : 1,
        IVInfo                 : [],
        onOpenHelpDialog       : () => {},
        onCloseDrawer          : () => {},
        onStateChange          : () => {},
        showAllInterventionsCB : false,
        showRetroSwitch        : false,
        showRetroYears         : false,
        showQualitySwitch      : true,
        showBFCB               : false,
    };

    state = {
        pentaActive            : false,
        PentaAutoCalc          : false,
        HealthSystem           : false,
        HomeCommunity          : false,
        packTable              : null,
        saveBtnDisabled        : true,
        selectedIVSet          : [],
        CSModvars              : this.props.CSModvars,
        qualityOn              : false,
        showExtraBtn           : true,
        showRetroYears         : this.props.showRetroYears,
        fromRetroYears         : false,
    };

    componentDidMount () {
        let CSModvars      = this.props.CSModvars;
        let firstYear      = this.props.firstYear;
        let finalYear      = this.props.finalYear;
        let language       = this.props.language;
        let IVInfo         = this.props.IVInfo;
        let showRetroYears = this.props.showRetroSwitch && this.state.showRetroYears;
        let SubnatMode     = false;
        let exploreData    = false;

        let selectedIVSet = CSModvars.find(x => (x.tag === SelectedIVSetTag) ? true : false).value.intArray;

        let pentaActive = false;
        selectedIVSet.forEach(function(MstID) {
            if (MstID === CONSTANTS.IV_PentaVacc){
                pentaActive = true;
            }
        });

        let PentaAutoCalc = false;
        let CalcCov_MV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CalcCovFromInputs_MV2);
        if (CalcCov_MV.value.intArray.includes(CONSTANTS.CS_Pentavalent_CCFI)) {
            PentaAutoCalc = true;
        }

        let BFPromoCB = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_BreastFeedPromoCB_MV)['value'];

        let HealthSystem = BFPromoCB[CONSTANTS.CS_BFPromoHealthSystem] || BFPromoCB[CONSTANTS.CS_BFPromoBoth];
        let HomeCommunity = BFPromoCB[CONSTANTS.CS_BFPromoHomeComm] || BFPromoCB[CONSTANTS.CS_BFPromoBoth];

        let qualityOn = Boolean(CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_ANCMode_MV)).value);

        this.setState({
            packTable         : CoverageEditor.Create(IVInfo, CSModvars, language, firstYear, finalYear, qualityOn, exploreData, showRetroYears, SubnatMode)[CONSTANTS.CS_PackTable],
            qualityOn         : qualityOn,
            selectedIVSet     : selectedIVSet,
            pentaActive       : pentaActive,
            PentaAutoCalc     : PentaAutoCalc,
            HealthSystem      : HealthSystem,
            HomeCommunity     : HomeCommunity
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTableChange = (packTable) => {
        let new_packTable = CoverageEditor.RecalcGrid(packTable, this.state.PentaAutoCalc);

        if (this.state.fromRetroYears) {
            this.setState({
                packTable: new_packTable,
                fromRetroYears: false,
            });
        }
        else {
            this.setState({
                packTable: new_packTable,
                saveBtnDisabled: false,
            });
        }
    };

    onPentaCheckBoxClick = () => {
        let PentaAutoCalc = !this.state.PentaAutoCalc;
        let new_packTable = CoverageEditor.UpdatePentaRows(this.state.packTable, PentaAutoCalc);
        let new_packTable2 = CoverageEditor.RecalcGrid(new_packTable, PentaAutoCalc);

        this.setState({
            PentaAutoCalc   : PentaAutoCalc,
            packTable       : new_packTable2,
            saveBtnDisabled : false,
        });
    };

    onHealthSysCheckBoxClick = () => {
        let HealthSystem = !this.state.HealthSystem;
        let HomeCommunity = this.state.HomeCommunity;

        if (HealthSystem || HomeCommunity) {
            this.setState({
                HealthSystem: HealthSystem,
                saveBtnDisabled: false,
            });
        }
    };

    onHomeCommunCheckBoxClick = () => {
        let HealthSystem = this.state.HealthSystem;
        let HomeCommunity = !this.state.HomeCommunity;

        if (HealthSystem || HomeCommunity) {
            this.setState({
                HomeCommunity: HomeCommunity,
                saveBtnDisabled: false,
            });
        }
    };

    onSaveBtnClick = () => {
        let packTable = this.state.packTable;
        let goAhead = true;

        packTable.tableData.value.forEach(function(arr) {
            arr.forEach(function(val) {
                if (typeof val === "number" && parseFloat(val.toFixed(1)) > 100) {
                    goAhead = false;
                }
            });
        });

        if (goAhead) {
            let PentaAutoCalc = this.state.PentaAutoCalc;
            let HealthSystem  = this.state.HealthSystem;
            let HomeCommunity = this.state.HomeCommunity;
            let CSModvars     = this.state.CSModvars;
            let language      = this.props.language;
            let qualityOn     = this.state.qualityOn;

            let ModvarsArray1 = CoverageEditor.GetSSData(packTable, CSModvars, language, PentaAutoCalc, false);

            let BFPromoCB = CSModvars.find(x => x.tag === CONSTANTS.CS_TG_BreastFeedPromoCB_MV);

            BFPromoCB['value'][CONSTANTS.CS_BFPromoHealthSystem] = HealthSystem;
            BFPromoCB['value'][CONSTANTS.CS_BFPromoHomeComm] = HomeCommunity;

            let ANCMode = {...CSModvars.find(x => x.tag === CONSTANTS.CS_TG_ANCMode_MV), value: Number(qualityOn)};
            let ChildbirthMode = {...CSModvars.find(x => x.tag === CONSTANTS.CS_TG_ChildbirthMode_MV), value: Number(qualityOn)};

            let changedCSModvars = CSModvars.map(x => {
                let a = ModvarsArray1.find(y => y.tag === x.tag);
                let b = (x.tag === CONSTANTS.CS_TG_BreastFeedPromoCB_MV) ? BFPromoCB : undefined;
                let c = (x.tag === CONSTANTS.CS_TG_ANCMode_MV) ? ANCMode : undefined;
                let d = (x.tag === CONSTANTS.CS_TG_ChildbirthMode_MV) ? ChildbirthMode : undefined;
                return a || b || c || d || x;
            });

            let CCFI = getModvarByTag(changedCSModvars, CONSTANTS.CS_TG_CalcCovFromInputs_MV2).value.intArray;
            /* If the modvar doesn't have the Penta flag in it, and the Penta checkbox is checked,
               add the Penta flag to the modvar. */
            if (!CCFI.includes(CONSTANTS.CS_Pentavalent_CCFI)) {
                if (PentaAutoCalc) {
                    CCFI.push(CONSTANTS.CS_Pentavalent_CCFI);
                }
            }
            /* Otherwise, if the modvar *does have the Penta flag in it, and the Penta checkbox is *unchecked,
               *remove the Penta flag from the modvar. */
            else if (!PentaAutoCalc){
                CCFI.splice(CCFI.indexOf(CONSTANTS.CS_Pentavalent_CCFI), 1);
            }

            this.setState({
                saveBtnDisabled : true,
                CSModvars       : changedCSModvars,
            }, () => {
                this.props.onStateChange({
                    unchangedProj  : false,
                    CSModvars      : changedCSModvars,
                    // showRetroYears : this.state.showRetroYears,
                }, () => {
                    this.props.onCloseDrawer();
                });
            });
        } else {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : RS("GB_stValOver100")
            });
        }
    };

    onQualitySwitch = (checked) => {
        let showRetroYears = this.props.showRetroSwitch && this.state.showRetroYears;
        let PentaAutoCalc = this.state.PentaAutoCalc;
        let packTable     = this.state.packTable;
        let CSModvars     = this.state.CSModvars;
        let SubnatMode    = false;
        let IVInfo        = this.props.IVInfo;
        let language      = this.props.language;
        let firstYear     = this.props.firstYear;
        let qualityOn     = checked;
        let exploreData   = false;

        /* First, update the PentaCov array of the packTable, if the Penta checkbox is checked (to save any pentavalent
           values they may have entered or modified). */
        if (PentaAutoCalc) {
            packTable = CoverageEditor.UpdatePentaCovArr(packTable);
            this.onPackTableChange(packTable);
        }

        /* Next, get an updated copy of the modvars from the packTable. */
        let ModvarsArray  = CoverageEditor.GetSSData(packTable, CSModvars, language, PentaAutoCalc, SubnatMode);

        let changedCSModvars = CSModvars.map(x => {
            let a = ModvarsArray.find(y => y.tag === x.tag);
            return a || x;
        });

        let finalYear = this.props.finalYear;

        /* Create a new packTable with the updated Coverage. */
        let newPackTable = CoverageEditor.Create(IVInfo, changedCSModvars, language, firstYear, finalYear, qualityOn,
            exploreData, showRetroYears, SubnatMode)[CONSTANTS.CS_PackTable];

        /* Appropriately update the pentavalent rows of the packtable the user is about to be editing. */
        newPackTable = CoverageEditor.UpdatePentaRows(newPackTable, PentaAutoCalc, true);

        this.onPackTableChange(newPackTable);

        /* Update state, including the most recently updated modvar values. */
        this.setState({
            qualityOn       : qualityOn,
            saveBtnDisabled : false,
            CSModvars       : changedCSModvars
        });
    };

    onRetroSwitch = () => {
        let showRetroYears = !this.state.showRetroYears;
        let PentaAutoCalc  = this.state.PentaAutoCalc;
        let packTable      = this.state.packTable;
        let CSModvars      = this.state.CSModvars;
        let SubnatMode     = false;
        let IVInfo         = this.props.IVInfo;
        let language       = this.props.language;
        let firstYear      = this.props.firstYear;
        let qualityOn      = this.state.qualityOn;
        let exploreData    = false;

        /* First, update the PentaCov array of the packTable, if the Penta checkbox is checked (to save any pentavalent
           values they may have entered or modified). */
        if (PentaAutoCalc) {
            packTable = CoverageEditor.UpdatePentaCovArr(packTable);
            this.onPackTableChange(packTable);
        }

        let ModvarsArray = CoverageEditor.GetSSData(packTable, CSModvars, language, PentaAutoCalc, SubnatMode);

        let changedCSModvars = CSModvars.map(x => {
            let a = ModvarsArray.find(y => y.tag === x.tag);
            return a || x;
        });

        let finalYear = this.props.finalYear;

        let newPackTable = CoverageEditor.Create(IVInfo, changedCSModvars, language, firstYear, finalYear, qualityOn,
            exploreData, showRetroYears, SubnatMode)[CONSTANTS.CS_PackTable];

        /* Appropriately update the pentavalent rows of the packtable the user is about to be editing. */
        newPackTable = CoverageEditor.UpdatePentaRows(newPackTable, PentaAutoCalc, true);

        // this.onPackTableChange(newPackTable);

        /* Update state, including the most recently updated modvar values. */
        this.setState({
            showRetroYears  : showRetroYears,
            fromRetroYears  : true,
            // saveBtnDisabled : false,
            CSModvars       : changedCSModvars
        }, () => {
            this.onPackTableChange(newPackTable);
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable = CloneObj(this.state.packTable);

        const styles = {
            BFTitle: {
                color: '#000000',
                fontSize: "20px",
                marginTop: "0",
                display: this.props.showBFCB ? "block" : "none"
            },
            BFPara: {
                fontSize: "12px",
                lineHeight: 1.3,
                display: this.props.showBFCB ? "block" : "none"
            },
            pentaCheckBox: {
                marginTop: 10,
                marginBottom: 20,
                display: this.state.pentaActive ? "block" : "none"
            },
            BFCheckBox: {
                marginTop: 10,
                marginBottom: 20,
                display: this.props.showBFCB ? "block" : "none"
            },
            checkBoxLabelStyle: {
                fontSize: '16px',
                cursor: "default"
            },
            switch : {
                float : "right", 
                marginTop : 9
            },
            effectivenessBtn : {
                marginTop: 3, 
                float: 'right', 
                marginRight: 15
            }
        };

        return (
            <TDrawerWithHeader
                open             = {true}
                onOpenHelpDialog = {this.props.onOpenHelpDialog}
                helpFile         = {"coverage.htm"}
                onClose          = {this.props.onCloseDrawer}
                onSave           = {this.onSaveBtnClick}
                saveBtnCaption   = {RS('GB_stApply')}
                saveBtnDisabled  = {this.state.saveBtnDisabled}
                appBarText       = {RS('GB_stCoverage')}
                content          = {
                                    <div className="innerPage">
                                        <EditorNote
                                            numberOfTables = {1}
                                        />

                                        <h2 style={styles.BFTitle}>{RS('GB_stBFPromo')}</h2>

                                        <p style={styles.BFPara}>{RS('GB_stBFPromoLbl')}</p>

                                        <TCheckBox
                                            caption={RS('GB_stHealthSysSmallS')}
                                            color='secondary'
                                            tooltip={""}
                                            onClick={this.onHealthSysCheckBoxClick}
                                            value={this.state.HealthSystem}
                                            boxStyle={styles.box}
                                            labelStyle={styles.checkBoxLabelStyle}
                                            style={styles.BFCheckBox}
                                        />

                                        <TCheckBox
                                            caption={RS('GB_stHomeCommunity')}
                                            color='secondary'
                                            tooltip={""}
                                            onClick={this.onHomeCommunCheckBoxClick}
                                            value={this.state.HomeCommunity}
                                            boxStyle={styles.box}
                                            labelStyle={styles.checkBoxLabelStyle}
                                            style={styles.BFCheckBox}
                                        />

                                        <CSEditor
                                            showTitle={true}
                                            packTable={packTable}
                                            onChange={this.onPackTableChange}
                                            mstID={CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID}
                                        />

                                        <TCheckBox
                                            caption={'*' + RS('GB_stPentavalentAutoCalcComp')}
                                            color='secondary'
                                            tooltip={""}
                                            onClick={this.onPentaCheckBoxClick}
                                            value={this.state.PentaAutoCalc}
                                            boxStyle={styles.box}
                                            labelStyle={styles.checkBoxLabelStyle}
                                            style={styles.pentaCheckBox}
                                        />
                                    </div>
                                }
                showExtraBtn    = {this.state.showExtraBtn}
                // contentExtraBtn = {
                //                     <CSEffectivenessCoverageBlueBox
                //                         onStateChange={this.props.onStateChange}
                //                         IVInfo={this.props.IVInfo}
                //                         CSModvars={this.state.CSModvars}
                //                         language={this.props.language}
                //                         showAllInterventionsCB={this.props.showAllInterventionsCB}
                //                         onOpenHelpDialog={this.props.onOpenHelpDialog}
                //                         style={styles.effectivenessBtn}
                //                     />
                //                 }
                contentSwitch ={ this.props.showQualitySwitch ?
                                    <TSwitch
                                        caption={RS("GB_stQuality")}
                                        value={this.state.qualityOn}
                                        onClick={this.onQualitySwitch}
                                        style={styles.switch}
                                    /> : null
                                }
                retroSwitch = { this.props.showRetroSwitch ?
                                    <TSwitch
                                        caption={RS('GB_stShowRetroYears')}
                                        value={this.state.showRetroYears}
                                        onClick={this.onRetroSwitch}
                                        style={styles.switch}
                                    /> : null
                            }
            />
        );




        
        // <CSCoverageDrawer
        //     packTable={this.state.packTable}
        //     onPackTableChange={this.onPackTableChange}
        //     selectedIVSet={this.state.selectedIVSet}
        //     CSModvars={this.state.CSModvars}
        //     passData={(o) => this.setState(o)}
        // />

        // return (
        //     <div className="innerPage">

        //         <EditorNote
        //             numberOfTables = {1}
        //         />

        //         <CSEditor
        //             showTitle={true}
        //             packTable={packTable}
        //             onChange={this.onPackTableChange}
        //             mstID={CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID}
        //         />

        //         <TCheckBox
        //             style={styles.checkBox}
        //             boxStyle={styles.box}
        //             color='secondary'
        //             caption={'*' + RS('GB_stPentavalentAutoCalcComp')}
        //             tooltip={""}
        //             labelStyle={styles.checkBoxLabelStyle}
        //             onClick={() => this.onCheckBoxClick()}
        //             value={this.state.PentaAutoCalc}
        //         />
        //     </div>
        // );
    }
}

export default CSCoverageDrawer;