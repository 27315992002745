import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import DisplayIVUnselectedLabel from "./DisplayIVUnselectedLabel";
import DisplayCoverage from "./DisplayCoverage";
import DisplayTable from "./DisplayTable";
import DisplayChart from "./DisplayChart";
import DisplaySlider from "./DisplaySlider";
import { clone } from "@common/util/GBUtil";

function getChartOptions(props) {
    return props.chartOptions.find(x => ((x.modID === props.modID) && (x.resultID === props.resultID)));
}

class CSExpandResultDrawer extends React.Component {

    static propTypes = {
        onStateChange    : PropTypes.func,
        passData         : PropTypes.func,
        firstYear        : PropTypes.number,
        finalYear        : PropTypes.number,
        chartYears       : PropTypes.object,
        resultsContent   : PropTypes.object,
        stacked          : PropTypes.string,
        legendReversed   : PropTypes.bool,
        modID            : PropTypes.number,
        resultID         : PropTypes.number,
        chartOptions     : PropTypes.arrayOf(PropTypes.object),
        displayCoverage  : PropTypes.bool,
        retroYears       : PropTypes.bool,
        displayEndpoints : PropTypes.bool,
        IVInfo           : PropTypes.array,
        IVGroupInfo      : PropTypes.array,
        CSModvars        : PropTypes.array,
        coverageDisplay  : PropTypes.string,
        tableRef         : PropTypes.any,
        language         : PropTypes.number,
    };

    static defaultProps = {
        onStateChange    : () => console.log("onStateChanged"),
        passData         : () => console.log("passData"),
        firstYear        : new Date().getFullYear(),
        finalYear        : CONSTANTS.CS_DefaultFinalYear,
        chartYears       : {},
        resultsContent   : {},
        stacked          : '',
        legendReversed   : false,
        modID            : 0,
        resultID         : 0,
        chartOptions     : [],
        displayCoverage  : false,
        retroYears       : false,
        displayEndpoints : false,
        IVInfo           : [],
        IVGroupInfo      : [],
        CSModvars        : [],
        coverageDisplay  : CONSTANTS.CS_TableChart,
        tableRef         : null,
        language         : CONSTANTS.GB_English,
    };

    state = {
        showIVs         : clone(getChartOptions(this.props)['showIVs']),
    };

    constructor(props) {
        super(props);
        this.chart = React.createRef();
    }

    componentDidMount() {
        this.props.passData({chart: this.chart});
    }

    static getDerivedStateFromProps(props, state) {
        // console.log(`${state.showIVs} ${getChartOptions(props)['showIVs']}`);
        
        if (state.showIVs !== getChartOptions(props)['showIVs']) {
            return {
                showIVs : JSON.parse(JSON.stringify((getChartOptions(props)['showIVs'])))
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let chartType = getChartOptions(this.props)['typeOfDisplay'];

        return (
            <div className="innerPage">
                <DisplayIVUnselectedLabel 
                    showIVs={this.state.showIVs}
                    hasDeselectedIntervs={this.props.resultsContent.packTable.hasDeselectedIntervs}
                    displayCoverage={this.props.displayCoverage}
                />

                {
                    (chartType === CONSTANTS.CS_TableChart) ? 
                        <DisplayTable
                            resultsContent={this.props.resultsContent} 
                            tableRef={this.props.tableRef}
                            passData={this.props.passData}
                        />
                        : 
                        <React.Fragment>
                            <DisplayChart
                                resultsContent={this.props.resultsContent}
                                stacked={this.props.stacked}
                                legendReversed={this.props.legendReversed}
                                chartOptions={this.props.chartOptions}
                                modID={this.props.modID}
                                resultID={this.props.resultID}
                                chartRef={this.chart}
                            />

                            <DisplaySlider
                                firstYear={this.props.firstYear}
                                finalYear={this.props.finalYear}
                                onStateChange={this.props.onStateChange}
                                firstYearIdx={this.props.chartYears['firstYrIdx']}
                                finalYearIdx={this.props.chartYears['finalYrIdx']}
                            />
                        </React.Fragment>
                }

                {
                    this.props.displayCoverage ? 
                        <DisplayCoverage
                            firstYear={this.props.firstYear}
                            finalYear={this.props.finalYear}
                            projectionName={this.props.projectionName}
                            displayEndpoints={this.props.displayEndpoints}
                            IVInfo={this.props.IVInfo}
                            IVGroupInfo={this.props.IVGroupInfo}
                            CSModvars={this.props.CSModvars}
                            language={this.props.language}
                            chartYears={this.props.chartYears}
                            retroYears={this.props.retroYears}
                            coverageDisplay={this.props.coverageDisplay}
                            stacked={this.props.stacked}
                            legendReversed={this.props.legendReversed}
                        /> 
                        : null
                }
            </div>
        );
    }

}

export default CSExpandResultDrawer;