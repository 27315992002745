import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import ErrorBoundaryPage from '@common/components/ErrorBoundaryPage';
import RS from "@common/strings/RS";
import GBDeveloperForm from '../../pages/GBDeveloperFm';
import CSHomeForm from '../../pages/CSHomeFm';
import CSProjManagerForm2 from '../../pages/CSProjManagerFm2';
import CSProjectionLibraryForm from '../../pages/CSProjectionLibraryFm';
import CSReviewDefaultDataForm from '../../pages/CSReviewDefaultDataFm';
import CSManageInterventionsForm2 from '../../pages/CSManageInterventionsFm2';
import CSViewResultsForm from '../../pages/CSViewResultsFm';
import CSMissedOpportunitiesForm from '../../pages/CS/TOOLS/MissedOpportunities/content/CSMissedOpportunitiesFm';
import ExploreData from '../../pages/CS/TOOLS/ExploreData/ExploreData';
import CSCountryListForm from '../../pages/CS/TOOLS/CSCountryListFm';
import { default as Extract } from "Tools/Extract/app/App";

class AppContentCustom extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onAddTasks                     : PropTypes.func,
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onOpenProjection               : PropTypes.func,
        onDeleteProjection             : PropTypes.func,
        onDownloadProjection           : PropTypes.func,
        onCloseProjection              : PropTypes.func,
        onSaveProjection               : PropTypes.func,
        onCreateProjBtnClick           : PropTypes.func,
        onExploreLiSTDataBtnClick      : PropTypes.func,
        onGuidedExplorationBtnClick    : PropTypes.func,
        onUploadProjection             : PropTypes.func,
        onSaveTopic                    : PropTypes.func,
        onGetTopicList                 : PropTypes.func,
        onCreateProjection             : PropTypes.func,
        onModifyProjection             : PropTypes.func,
        onViewResults                  : PropTypes.func,
        onOpenHelpDialog               : PropTypes.func,
        onGetDataPackResults           : PropTypes.func,
        handleSelectedResults          : PropTypes.func,

        MOSideBarOpen                  : PropTypes.bool,
        heartBeat                      : PropTypes.number,
        apiTasks                       : PropTypes.array,
        pageID                         : PropTypes.string,
        sidebarOpen                    : PropTypes.bool,
        isGuest                        : PropTypes.bool,
        isDeskTop                      : PropTypes.bool,
        activeProjections              : PropTypes.array,
        storedProjections              : PropTypes.array,
        projectCreated                 : PropTypes.bool,
        topicList                      : PropTypes.array,
        projectionName                 : PropTypes.string,
        projectionNotes                : PropTypes.string,
        selectedCountry                : PropTypes.string,
        countryISO                     : PropTypes.number,
        subnatProjection               : PropTypes.bool,
        includeFamPlan                 : PropTypes.bool,
        countries                      : PropTypes.array,
        IVGroupInfo                    : PropTypes.array,
        IVSubGroupInfo                 : PropTypes.array,
        IVInfo                         : PropTypes.array,
        yearRange                      : PropTypes.object,
        chartYears                     : PropTypes.object,
        appMode                        : PropTypes.string,
        CSModvars                      : PropTypes.arrayOf(PropTypes.object),
        useFamPlanOutputs              : PropTypes.bool,
        intervDrawerOpen               : PropTypes.bool,
        displayChart                   : PropTypes.bool,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayAdolescentCOD           : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        adolescentCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        inputModvars                   : PropTypes.arrayOf(PropTypes.object),
        chartOptions                   : PropTypes.arrayOf(PropTypes.object),
        availableResults               : PropTypes.array,
        firstYear                      : PropTypes.number,
        finalYear                      : PropTypes.number,
        selectedPackTables             : PropTypes.array,
        selectedPackCharts             : PropTypes.array,
        resultMenus                    : PropTypes.object,
        selectedResults                : PropTypes.array,
        stacked                        : PropTypes.string,
        legendReversed                 : PropTypes.bool,
        authName                       : PropTypes.string,
        orgName                        : PropTypes.string,
        showMOIntervSection            : PropTypes.bool,
        topicFilter                    : PropTypes.array,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        adolescentsSelected            : PropTypes.bool,
        selectAllIntervBool            : PropTypes.bool,
        topMOInterventions             : PropTypes.array,
        MOResultToDisplay              : PropTypes.number,
        isExpandMODrawerClosed         : PropTypes.bool,
        displayCoverage                : PropTypes.bool,
        retroYears                     : PropTypes.bool,
        displayEndpoints               : PropTypes.bool,
        subnatRegion                   : PropTypes.string,
        subnatSurvey                   : PropTypes.string,
        coverageDisplay                : PropTypes.string,
        showAllInterventionsCB         : PropTypes.bool,
        language                       : PropTypes.number,
        menuThreshold                  : PropTypes.bool,
        isMobile                       : PropTypes.bool,
        approach                       : PropTypes.number
    };

    static defaultProps = {
        onAddTasks                     : () => console.log('onAddTasks'),
        onStateChange                  : () => console.log('onStateChange'),
        onPageChange                   : () => console.log('onPageChange'),
        onOpenProjection               : () => console.log('onOpenProjection'),
        onDeleteProjection             : () => console.log('onDeleteProjection'),
        onDownloadProjection           : () => console.log('onDownloadProjection'),
        onCloseProjection              : () => console.log('onCloseProjection'),
        onSaveProjection               : () => console.log('onSaveProjection'),
        onCreateProjBtnClick           : () => console.log('onCreateProjBtnClick'),
        onExploreLiSTDataBtnClick      : () => console.log('onExploreLiSTDataBtnClick'),
        onGuidedExplorationBtnClick    : () => console.log('onGuidedExplorationBtnClick'),
        onUploadProjection             : () => console.log('onUploadProjection'),
        onSaveTopic                    : () => console.log('onSaveTopic'),
        onGetTopicList                 : () => console.log('onGetTopicList'),
        onCreateProjection             : () => console.log('onCreateProjection'),
        onModifyProjection             : () => console.log('onModifyProjection'),
        onViewResults                  : () => console.log('onViewResults'),
        onOpenHelpDialog               : () => console.log('onOpenHelpDialog'),
        onGetDataPackResults           : () => console.log('onGetDataPackResults'),
        handleSelectedResults          : () => console.log('handleSelectedResults'),

        MOSideBarOpen                  : true,
        heartBeat                      : 0,
        apiTasks                       : [],
        pageID                         : "CSHomeForm",
        sidebarOpen                    : 'false',
        isGuest                        : true,
        isDeskTop                      : true,
        activeProjections              : [],
        storedProjections              : [],
        projectCreated                 : false,
        topicList                      : [],
        projectionName                 : '',
        projectionNotes                : '',
        selectedCountry                : "Afghanistan",
        countryISO                     : 4,
        subnatProjection               : false,
        includeFamPlan                 : true,
        countries                      : [],
        IVGroupInfo                    : [],
        IVSubGroupInfo                 : [],
        IVInfo                         : [],
        yearRange                      : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        chartYears                     : {},
        appMode                        : '',
        CSModvars                      : [],
        useFamPlanOutputs              : false,
        intervDrawerOpen               : false,
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        dsiplayAdolescentCOD           : false,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        adolescentCausesOfDeathContent : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        inputModvars                   : [],
        chartOptions                   : [],
        availableResults               : [],
        firstYear                      : new Date().getFullYear(),
        finalYear                      : CONSTANTS.CS_DefaultFinalYear,
        selectedPackTables             : [],
        selectedPackCharts             : [],
        resultMenus                    : {},
        selectedResults                : [],
        stacked                        : '',
        legendReversed                 : false,
        authName                       : '',
        orgName                        : '',
        showMOIntervSection            : false,
        topicFilter                    : [],
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        adolescentsSelected            : false,
        selectAllIntervBool            : false,
        topMOInterventions             : [],
        MOResultToDisplay              : CONSTANTS.MOResultToDisplay,
        isExpandMODrawerClosed         : true,
        displayCoverage                : false,
        retroYears                     : false,
        displayEndpoints               : false,
        subnatRegion                   : RS("GB_stNoRegionSelected"),
        subnatSurvey                   : "",
        coverageDisplay                : CONSTANTS.CS_TableChart,
        showAllInterventionsCB         : false,
        language                       : CONSTANTS.GB_English,
        menuThreshold                  : false,
        isMobile                       : false,
        approach                       : CONSTANTS.CS_StandardApproach
    };

    componentDidMount() {
        if (this.props.projectCreated) {
            this.props.onPageChange('CSProjManagerForm');
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderPage = () => {

        let styles = {
            AppPage : {
                left : this.props.sidebarOpen ? '250px' : '0',
                padding: 20
            },
            AppPageFull : {
                left : this.props.sidebarOpen ? '250px' : '0',
            }
        };

        let page;

        switch (this.props.pageID) {

            case 'ExtractTool'                 : page =
                                                    <Extract 
                                                        isStandAlone={false}
                                                    />;
                                                    break;

            case 'GBDeveloperForm'             : page =
                                                    <ErrorBoundaryPage style={styles.AppPage}>
                                                        <GBDeveloperForm
                                                            heartBeat  = {this.props.heartBeat}
                                                            apiTasks   = {this.props.apiTasks}
                                                            onAddTasks = {this.props.onAddTasks}
                                                            style      = {styles.AppPage}
                                                        />
                                                    </ErrorBoundaryPage>;
                                                    break;

            case 'CSProjectionLibraryForm'     : page = 
                                                    <CSProjectionLibraryForm
                                                        isGuest              = {this.props.isGuest}
                                                        isDeskTop            = {this.props.isDeskTop}
                                                        activeProjections    = {this.props.activeProjections}
                                                        storedProjections    = {this.props.storedProjections}
                                                        projectCreated       = {this.props.projectCreated}
                                                        topicList            = {this.props.topicList}
                                                        onPageChange         = {this.props.onPageChange}
                                                        onOpenProjection     = {this.props.onOpenProjection}
                                                        onDeleteProjection   = {this.props.onDeleteProjection}
                                                        onDownloadProjection = {this.props.onDownloadProjection}
                                                        onCloseProjection    = {this.props.onCloseProjection}
                                                        onSaveProjection     = {this.props.onSaveProjection}
                                                        onRenameProjection   = {this.props.onRenameProjection}
                                                        onCopyProjection     = {this.props.onCopyProjection}
                                                        onCreateProjBtnClick = {this.props.onCreateProjBtnClick}
                                                        onUploadProjection   = {this.props.onUploadProjection}
                                                        onSaveTopic          = {this.props.onSaveTopic}
                                                        onGetTopicList       = {this.props.onGetTopicList}
                                                        onStateChange        = {this.props.onStateChange}
                                                        topicFilter          = {this.props.topicFilter}
                                                    />; 
                                                    break;

            case 'CSHomeForm'                   : page =
                                                    <CSHomeForm
                                                        isGuest                     = {this.props.isGuest}
                                                        isDeskTop                   = {this.props.isDeskTop}
                                                        projectCreated              = {this.props.projectCreated}
                                                        projectionName              = {this.props.projectionName}
                                                        onExploreLiSTDataBtnClick   = {this.props.onExploreLiSTDataBtnClick}
                                                        onGuidedExplorationBtnClick = {this.props.onGuidedExplorationBtnClick}
                                                        onCreateProjBtnClick        = {this.props.onCreateProjBtnClick}
                                                        onStateChange               = {this.props.onStateChange}
                                                        onPageChange                = {this.props.onPageChange}
                                                    />;
                                                    break;

            case 'CSProjManagerForm'            : page = 
                                                    <CSProjManagerForm2
                                                        projNameSaveAs     = {this.props.projNameSaveAs}
                                                        projectionName     = {this.props.projectionName}
                                                        projectionNotes    = {this.props.projectionNotes}
                                                        selectedCountry    = {this.props.selectedCountry}
                                                        countryISO         = {this.props.countryISO}
                                                        isSubnatProjection = {this.props.subnatProjection}
                                                        includeFamPlan     = {this.props.includeFamPlan}
                                                        countries          = {this.props.countries}
                                                        projectCreated     = {this.props.projectCreated}
                                                        yearRange          = {this.props.yearRange}
                                                        chartYears         = {this.props.chartYears}
							                            authorName         = {this.props.authorName}
                                                        orgName            = {this.props.orgName}
                                                        onCreateProjection = {this.props.onCreateProjection}
                                                        onModifyProjection = {this.props.onModifyProjection}
                                                        onStateChange      = {this.props.onStateChange}
                                                        onAddTasks         = {this.props.onAddTasks}
                                                        subnatRegion       = {this.props.subnatRegion}
                                                        subnatSurvey       = {this.props.subnatSurvey}
                                                        language           = {this.props.language}
                                                        onPageChange       = {this.props.onPageChange}
                                                        onViewResults      = {this.props.onViewResults}
                                                        IVSubGroupInfo     = {this.props.IVSubGroupInfo}
                                                        IVInfo             = {this.props.IVInfo}
                                                        onOpenHelpDialog   = {this.props.onOpenHelpDialog}
                                                        isMobile           = {this.props.isMobile}
                                                        approach           = {this.props.approach}
                                                    />; break;

            case 'CSReviewDefaultDataForm'      : page = 
                                                    <CSReviewDefaultDataForm
                                                        onPageChange            = {this.props.onPageChange}
                                                        onStateChange           = {this.props.onStateChange}
                                                        onViewResults           = {this.props.onViewResults}
                                                        onOpenHelpDialog        = {this.props.onOpenHelpDialog}
                                                        selectedCountry         = {this.props.selectedCountry}
                                                        IVInfo                  = {this.props.IVInfo}
                                                        useFamPlanOutputs       = {this.props.useFamPlanOutputs}
                                                        CSModvars               = {this.props.CSModvars}
                                                        language                = {this.props.language}
                                                        firstYear               = {this.props.firstYear}
                                                        approach                = {this.props.approach}
                                                        showAllInterventionsCB  = {this.props.showAllInterventionsCB}
                                                        />;
                                                    break;

            case 'CSManageInterventionsForm'    : page = 
                                                    <CSManageInterventionsForm2
                                                        onPageChange                   = {this.props.onPageChange}
                                                        onAddTasks                     = {this.props.onAddTasks}
                                                        onStateChange                  = {this.props.onStateChange}
                                                        onOpenHelpDialog               = {this.props.onOpenHelpDialog}
                                                        handleSelectedResults          = {this.props.handleSelectedResults}
                                                        CSModvars                      = {this.props.CSModvars}
                                                        selectedCountry                = {this.props.selectedCountry}
                                                        includeFamPlan                 = {this.props.includeFamPlan}
                                                        IVGroupInfo                    = {this.props.IVGroupInfo}
                                                        IVSubGroupInfo                 = {this.props.IVSubGroupInfo}
                                                        IVInfo                         = {this.props.IVInfo}
                                                        intervDrawerOpen               = {this.props.intervDrawerOpen}
                                                        displayChart                   = {this.props.displayChart}
                                                        displayMaternalCOD             = {this.props.displayMaternalCOD}
                                                        displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                                                        displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                                                        displayChildCOD                = {this.props.displayChildCOD}
                                                        displayAdolescentCOD           = {this.props.displayAdolescentCOD}
                                                        displayCPR                     = {this.props.displayCPR}
                                                        deliveryPointsContent          = {this.props.deliveryPointsContent}
                                                        maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                                                        stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                                                        neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                                                        childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                                                        adolescentCausesOfDeathContent = {this.props.adolescentCausesOfDeathContent}
                                                        sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                                                        sortByInterventionCost         = {this.props.sortByInterventionCost}
                                                        viewInterventionCost           = {this.props.viewInterventionCost}
                                                        MOCountryData                  = {this.props.MOCountryData}
                                                        isDeskTop                      = {this.props.isDeskTop}
                                                        firstYear                      = {this.props.firstYear}
                                                        projectionName                 = {this.props.projectionName}
                                                        showMOIntervSection            = {this.props.showMOIntervSection}
                                                        maternalIntervSelected         = {this.props.maternalIntervSelected}
                                                        stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                                                        neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                                                        childIntervSelected            = {this.props.childIntervSelected}
                                                        nutritionSelected              = {this.props.nutritionSelected}
                                                        birthOutcomeSelected           = {this.props.birthOutcomeSelected}
                                                        adolescentsSelected            = {this.props.adolescentsSelected}
                                                        selectAllIntervBool            = {this.props.selectAllIntervBool}
                                                        topMOInterventions             = {this.props.topMOInterventions}
                                                        MOResultToDisplay              = {this.props.MOResultToDisplay}
                                                        isExpandMODrawerClosed         = {this.props.isExpandMODrawerClosed}
                                                        showAllInterventionsCB         = {this.props.showAllInterventionsCB}
                                                        language                       = {this.props.language}
                                                        showRetroYears                 = {this.props.showRetroYears}
                                                        onViewResults                  = {this.props.onViewResults}
                                                        approach                       = {this.props.approach}
                                                    />; break;

            case 'CSViewResultsForm'            : page =
                                                    <ErrorBoundaryPage style={styles.AppPage}>
                                                        <CSViewResultsForm
                                                            onPageChange       = {this.props.onPageChange}
                                                            onStateChange      = {this.props.onStateChange}
                                                            chartOptions       = {this.props.chartOptions}
                                                            chartYears         = {this.props.chartYears}
                                                            availableResults   = {this.props.availableResults}
                                                            firstYear          = {this.props.firstYear}
                                                            finalYear          = {this.props.finalYear}
                                                            projectionName     = {this.props.projectionName}
                                                            IVInfo             = {this.props.IVInfo}
                                                            IVGroupInfo        = {this.props.IVGroupInfo}
                                                            CSModvars          = {this.props.CSModvars}
                                                            selectedPackTables = {this.props.selectedPackTables}
                                                            selectedPackCharts = {this.props.selectedPackCharts}
                                                            resultMenus        = {this.props.resultMenus}
                                                            selectedResults    = {this.props.selectedResults}
                                                            includeFamPlan     = {this.props.includeFamPlan}
                                                            isDeskTop          = {this.props.isDeskTop}
                                                            stacked            = {this.props.stacked}
                                                            legendReversed     = {this.props.legendReversed}
                                                            displayEndpoints   = {this.props.displayEndpoints}
                                                            displayCoverage    = {this.props.displayCoverage}
                                                            retroYears         = {this.props.retroYears}
                                                            coverageDisplay    = {this.props.coverageDisplay}
                                                            language           = {this.props.language}
                                                            onViewResults      = {this.props.onViewResults}
                                                            onOpenHelpDialog   = {this.props.onOpenHelpDialog}
                                                            onGetDataPackResults = {this.props.onGetDataPackResults}
                                                            activeProjections  = {this.props.activeProjections}
                                                            approach           = {this.props.approach}
                                                        />
                                                    </ErrorBoundaryPage>;
                                                    break;

            case 'CSCountryListForm'            : page =
                                                    <CSCountryListForm
                                                        onStateChange               = {this.props.onStateChange}
                                                        onPageChange                = {this.props.onPageChange}
                                                        onAddTasks                  = {this.props.onAddTasks}
                                                        appMode                     = {this.props.appMode}
                                                        isDeskTop                   = {this.props.isDeskTop}
                                                        language                    = {this.props.language}
                                                        menuThreshold               = {this.props.menuThreshold}
                                                    />; break;

            case 'CSMissedOpportunitiesForm'     : page =
                                                    <CSMissedOpportunitiesForm
                                                        onStateChange                  = {this.props.onStateChange}
                                                        onPageChange                   = {this.props.onPageChange}
                                                        onAddTasks                     = {this.props.onAddTasks}
                                                        onExploreLiSTDataBtnClick      = {this.props.onExploreLiSTDataBtnClick}
                                                        onGuidedExplorationBtnClick    = {this.props.onGuidedExplorationBtnClick}
                                                        onCreateProjBtnClick           = {this.props.onCreateProjBtnClick}
                                                        MOSideBarOpen                  = {this.props.MOSideBarOpen}
                                                        appMode                        = {this.props.appMode}
                                                        displayChart                   = {this.props.displayChart}
                                                        selectedCountry                = {this.props.selectedCountry}
                                                        countryISO                     = {this.props.countryISO}
                                                        displayMaternalCOD             = {this.props.displayMaternalCOD}
                                                        displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                                                        displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                                                        displayChildCOD                = {this.props.displayChildCOD}
                                                        displayCPR                     = {this.props.displayCPR}
                                                        deliveryPointsContent          = {this.props.deliveryPointsContent}
                                                        maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                                                        stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                                                        neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                                                        childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                                                        sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                                                        sortByInterventionCost         = {this.props.sortByInterventionCost}
                                                        viewInterventionCost           = {this.props.viewInterventionCost}
                                                        MOCountryData                  = {this.props.MOCountryData}
                                                        sidebarOpen                    = {this.props.sidebarOpen}
                                                        isGuest                        = {this.props.isGuest}
                                                        isDeskTop                      = {this.props.isDeskTop}
                                                        maternalIntervSelected         = {this.props.maternalIntervSelected}
                                                        stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                                                        neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                                                        childIntervSelected            = {this.props.childIntervSelected}
                                                        nutritionSelected              = {this.props.nutritionSelected}
                                                        birthOutcomeSelected           = {this.props.birthOutcomeSelected}
                                                        MOResultToDisplay              = {this.props.MOResultToDisplay}
                                                        language                       = {this.props.language}
                                                    />; break;

            case 'CSExploreLiSTDataForm'        : page =
                                                    <ExploreData
                                                        selectedCountry                = {this.props.selectedCountry}
                                                        countryISO                     = {this.props.countryISO}
                                                        countries                      = {this.props.countries}
                                                        language                       = {this.props.language}
                                                        IVGroupInfo                    = {this.props.IVGroupInfo}
                                                        IVSubGroupInfo                 = {this.props.IVSubGroupInfo}
                                                        IVInfo                         = {this.props.IVInfo}
                                                        inputModvars                   = {this.props.inputModvars}
                                                        onStateChange                  = {this.props.onStateChange}
                                                        onAddTasks                     = {this.props.onAddTasks}
                                                        standAloneMode                 = {false}
                                                        menuThreshold                  = {this.props.menuThreshold}
                                                    />; break;

            default                             : page = 
                                                    <div className="innerPage">
                                                        I DON'T EXIST YET!  MY PAGE ID IS "{this.props.pageID}"
                                                    </div>;
                                                    break;
        }

        return page;
    };

    render() {
        return (
            <div style={{height: '100%'}}>
                {this.renderPage()}
            </div>
        );
    }
}

export default AppContentCustom;