import { createMuiTheme } from '@material-ui/core/styles';

const LiSTPrimary = '#f78c30';
const LiSTSecondary = '#65A7EB';

const muiTheme = createMuiTheme({
    typography: {
        fontFamily: 'Lato, sans-serif',
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: LiSTPrimary,
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: LiSTSecondary,
            contrastText: '#FFFFFF',
        }
    },
    appBar: {
        backgroundColor: '#FFFFFF',
        minHeight: '64px',
    },
     standardSideBar : {
        backgroundColor: '#05225d',
        border: 'none'
    },
    toolsSideBar : {
        backgroundColor: '#FFFFFF',
        border: 'none',
    },
    editor: {
        bandColor: '#f0f6df',
        fixedRowColor: "#9cc025",
        highlightColor: "#fff1a6",
        cellPadding: "3"
    },
    bandColor : {
        backgroundColor: '#f0f6df'
    },
    commandBar : {
        backgroundColor: 'hotpink',
        color: '#000000'
    },
    errorTitle : {
        margin: '0 0 10px 0',
        fontWeight: 'bold'
    },
    errorParagraph : {
        margin: '0 0 10px 0'
    },
    errorBox : {
        background: '#EF9A9A',
        borderRadius: '5px',
        padding: '10px',
        marginBottom: '20px'
    },
    paperAnchorDockedLeft:{
        borderRight: 'none'
    }

});




export default muiTheme;