import React, { Component } from 'react';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";

const appBarHeight = 64;
const appStatusBar = 0; //30;

class TCSUserLoggedOff extends Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        title           : PropTypes.string,
        connected       : PropTypes.bool,
        connecting      : PropTypes.bool,
        onConnect       : PropTypes.func
    };

    static defaultProps = {
        title           : RS('GB_stThankYou'),
        connected       : false,
        connecting      : false,
        onConnect       : () => console.log('TCSUserLoggedOff onConnect'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onConnect = () => {
        this.props.onConnect();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderPaperBox = () => {

        let styles = {
            paperBox: {
                width: 600,
                margin: '0 auto'
            },
            title : {
                paddingTop: 0,
                marginTop: 0,
                fontWeight: 'normal'
            },
            loginBox: {
                width: 300,
                margin: '0 auto'
            },
            errorParagraph : {
                margin: '0 0 10px 0'
            },
            page: {
                transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
                height: 'calc(100% - ' + appBarHeight + 'px - ' + appStatusBar + 'px)',
                overflowY: 'auto',
                //marginLeft: (this.props.sideBarVisible) ? 256 : 0,
                position: 'absolute',
                right: 0,
                left: 0,
                top: appBarHeight,
            }
        };

        return (
            <div style={styles.page}>
                <Paper style={styles.paperBox}>

                        <div style={{padding: 20}}>
                            <h1 style={styles.title}>{this.props.title}</h1>
                            <p style={styles.errorParagraph}>{RS('GB_stStartNewSession')}</p>

                            {/*{*/}
                            {/*    (this.props.connecting) ?*/}
                            {/*        <div style={{marginBottom: '20'}} >*/}
                            {/*            <CircularProgress size={118} style={{marginTop: '10', marginBottom: '10', margin: '0 auto', display: 'block'}} />*/}
                            {/*        </div> :*/}
                            {/*        (this.props.connected) ?*/}
                            {/*            this.renderSuccessPanel() :*/}
                            {/*            this.renderErrorPanel()*/}
                            {/*}*/}

                            <TButton
                                caption={RS('GB_stConnect')}
                                onClick={this.onConnect}
                                disabled={this.props.connecting}
                            />
                        </div>

                </Paper>
            </div>
        );
    };

    renderSuccessPanel = () => {

        let styles = {
            errorTitle : {
                margin: '0 0 10px 0',
                fontWeight: 'bold'
            },
            errorParagraph : {
                margin: '0 0 10px 0'
            },
            errorBox : {
                background: '#43a047',
                borderRadius: '5px',
                padding: '10px',
                marginBottom: '20px'
            }
        };

        return (
            <div style={styles.errorBox}>
                <p style={styles.errorTitle}>Connected!</p>
                <p style={styles.errorParagraph}>Attempting to begin session.</p>
            </div>
        );
    };

    renderErrorPanel = () => {

        let styles = {
            errorTitle : {
                margin: '0 0 10px 0',
                fontWeight: 'bold'
            },
            errorParagraph : {
                margin: '0 0 10px 0'
            },
            errorBox : {
                background: '#EF9A9A',
                borderRadius: '5px',
                padding: '10px',
                marginBottom: '20px'
            }
        };

        return (
            <div style={styles.errorBox}>
                <p style={styles.errorTitle}>Failed to connect</p>
                <p style={styles.errorParagraph}>Could not establish a connection to the server.</p>
            </div>
        );
    };

    render() {
        return (
            this.renderPaperBox()
        );
    }
}

export default TCSUserLoggedOff;
