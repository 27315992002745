import RS from "@common/strings/RS";
import {
    createSimpleDefaultPackTable,
    GB_CONSTANTS,
    getModvarCloneByTag,
    CONSTANTS,
    getCalcYearIdx,
    Get_FP_AgeName,
    Get_FP_UseSingleAgesValue, 
    generatePackChart
} from "../../../../../results/CS/data/imports.js";

export const FPCPREditor = {
    Create : (DPModvars, projInfo, ageRange, forCard = false) => {
        let CalcYear = projInfo.calcYear;
        let FinalYear = projInfo.finalYear;

        let useSingleAges = Get_FP_UseSingleAgesValue(DPModvars);
        let needOptionCBValue = Boolean(getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TNeedOption_MV)['value']);
        let editorName = RS('GB_stContraceptivePrevalenceCPR');
        let packTable;

        if (forCard) {
            packTable = CardSetUp(useSingleAges, CalcYear, ageRange, FinalYear);
            packTable = CardSetSSData(packTable, DPModvars, projInfo, useSingleAges, needOptionCBValue, ageRange);
            packTable.Title = editorName;
            return (packTable);
        }
        else {
            packTable = Setup(useSingleAges, needOptionCBValue, ageRange, CalcYear, FinalYear);
            packTable = SetSSData(packTable, DPModvars, projInfo, useSingleAges, needOptionCBValue, ageRange);
            packTable = FPCPREditor.RecalcGrid(packTable, projInfo, needOptionCBValue);
            packTable.useSubHeadersInChartLbls = true;
            let packChart = generatePackChart(packTable);
            packTable.Title = editorName;
            return ([packChart, packTable]);
        }
    },

    GetSSData : (pt, DPModvars, projInfo, useSingleAges, needOptionCBValue, ageRange) => {
        let changedModvars = [];

        let PrevalenceModvar = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_Tprevalence_MV);
        let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
        let CalcYear = projInfo.calcYear;
        let FinalYear = projInfo.finalYear;
        let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
        let col;

        if (useSingleAges) {
            if (needOptionCBValue) {
                col = 1;
                for (let t1 = calcYearIndex; t1 <= finalYearIndex; t1++) {
                    for (let r = CONSTANTS.FP_spacing; r <= CONSTANTS.FP_limiting; r++) {
                        PrevalenceModvar['value'][CONSTANTS.FP_All_Ages][r][t1] = pt.tableData.value[r][col];
                    }
                    col++;
                }
            }
            else {
                col = 1;
                for (let t = calcYearIndex; t <= finalYearIndex; t++) {
                    PrevalenceModvar['value'][CONSTANTS.FP_All_Ages][CONSTANTS.FP_all_need][t] = pt.tableData.value[1][col];
                    col++;
                }
            }
        }
        else {
            if (needOptionCBValue) {
                col = 1;
                for (let t2 = calcYearIndex; t2 <= finalYearIndex; t2++) {
                    for (let r1 = CONSTANTS.FP_spacing; r1 <= CONSTANTS.FP_limiting; r1++) {
                        PrevalenceModvar['value'][ageRange][r1][t2] = pt.tableData.value[r1][col];
                    }
                    col++;
                }
            }
            else {
                col = 1;
                for (let t2 = calcYearIndex; t2 <= finalYearIndex; t2++) {
                    PrevalenceModvar['value'][ageRange][CONSTANTS.FP_all_need][t2] = pt.tableData.value[1][col];
                    col++;
                }
            }
        }

        changedModvars.push(PrevalenceModvar);

        return (changedModvars);
    },

    RecalcGrid : (pt, projInfo, needOptionCBValue) => {
        let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
        let CalcYear = projInfo.calcYear;
        let FinalYear = projInfo.finalYear;
        let finalYearIndex = FinalYear - CalcYear + calcYearIndex;

        if (needOptionCBValue) {
            let spacingRow = 1;
            let limitingRow = 2;
            let totalRow = 3;
            let col = 1;
            for (let t = calcYearIndex; t <= finalYearIndex; t++) {
                pt.tableData.value[totalRow][col] = pt.tableData.value[spacingRow][col] + pt.tableData.value[limitingRow][col];
                col++;
            }
        }

        return (pt);
    },

    Validate : (pt) => {
        let totalRow = 3;
        let valid = true;
        for (let col = 1; col <= pt.tableData.value[0].length -1; col++) {
            if (pt.tableData.value[totalRow][col] > 100.1) {
                valid = false;
            }
        }
        if (!valid) {
            alert(RS('GB_stTotalForCPR100'));
        }
        return valid;
    },
};

const Setup = (useSingleAges, needOptionCBValue, ageRange, CalcYear, FinalYear) => {
    // let numRows = (needOptionCBValue) ? 4 : ((useSingleAges) ? 2 : 8);
    let numRows = 2;
    let numCols = FinalYear - CalcYear + 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    let col = 1;
    for (let i = CalcYear; i <= FinalYear; i++) {
        pt.tableData.value[0][col] = i.toString();
        col++;
    }

    for (let r = 1; r < numRows; r++) {
        for (let c = 1; c < numCols; c++) {
            pt.RDec[r][c] = 1;
            pt.MaxAllowedVal[r][c] = 100;
            pt.MinAllowedVal[r][c] = 0;
            pt.Alignments[r][c] = GB_CONSTANTS.GB_Right;
        }
    }

    if (useSingleAges) {
        pt.tableData.value[0][0] = RS('GB_stAge') + ': 15-49';
    }
    else {
        pt.tableData.value[0][0] = RS('GB_stAge') + ': ' + Get_FP_AgeName(ageRange);
    }

    pt.GBColWidths[0] = 125;

    if (!needOptionCBValue) {
        pt.tableData.value[1][0] = RS('GB_stCPR');
        pt.FontStyles[1][0]["intArray"] = [GB_CONSTANTS.GB_Bold];
    }
    else if (needOptionCBValue) {
        pt.tableData.value[1][0] = RS('GB_stSpacing');
        pt.FontStyles[1][0]["intArray"] = [GB_CONSTANTS.GB_Bold];

        pt.tableData.value[2][0] = RS('GB_stLimiting');
        pt.FontStyles[2][0]["intArray"] = [GB_CONSTANTS.GB_Bold];

        pt.tableData.value[3][0] = RS('GB_stTotal');
        pt.FontStyles[3][0]["intArray"] = [GB_CONSTANTS.GB_Bold];
        for (let tc = 1; tc < numCols; tc++) {
            pt["LockedCells"][3][tc] = true;
            pt["FontColors"][3][tc] = GB_CONSTANTS.LockedCellColor;
        }
    }

    return pt;
};

const SetSSData = (pt, DPModvars, projInfo, useSingleAges, needOptionCBValue, ageRange) => {
    let PrevalenceModvarValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_Tprevalence_MV)['value'];
    let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
    let CalcYear = projInfo.calcYear;
    let FinalYear = projInfo.finalYear;
    let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
    let col;

    if (useSingleAges) {
        if (needOptionCBValue) {
            col = 1;
            for (let t1 = calcYearIndex; t1 <= finalYearIndex; t1++) {
                for (let r = CONSTANTS.FP_spacing; r <= CONSTANTS.FP_limiting; r++) {
                    pt.tableData.value[r][col] = PrevalenceModvarValue[CONSTANTS.FP_All_Ages][r][t1];
                    if (pt.tableData.value[r][col] < 0) {
                        pt.tableData.value[r][col] = '';
                    }
                }
                col++;
            }
        }
        else {
            col = 1;
            for (let t = calcYearIndex; t <= finalYearIndex; t++) {
                pt.tableData.value[1][col] = PrevalenceModvarValue[CONSTANTS.FP_All_Ages][CONSTANTS.FP_all_need][t];
                if (pt.tableData.value[1][col] < 0) {
                    pt.tableData.value[1][col] = '';
                }
                col++;
            }
        }
    }
    else {
        if (needOptionCBValue) {
            col = 1;
            for (let t2 = calcYearIndex; t2 <= finalYearIndex; t2++) {
                for (let r1 = CONSTANTS.FP_spacing; r1 <= CONSTANTS.FP_limiting; r1++) {
                    pt.tableData.value[r1][col] = PrevalenceModvarValue[ageRange][r1][t2];
                    if (pt.tableData.value[r1][col] < 0) {
                        pt.tableData.value[r1][col] = '';
                    }
                }
                col++;
            }
            pt.tableData.value[0][0] = RS('GB_stAge') + ': ' + Get_FP_AgeName(ageRange);
        }
        else {
            col = 1;
            for (let t2 = calcYearIndex; t2 <= finalYearIndex; t2++) {
                pt.tableData.value[1][col] = PrevalenceModvarValue[ageRange][CONSTANTS.FP_all_need][t2];
                if (pt.tableData.value[1][col] < 0) {
                    pt.tableData.value[1][col] = '';
                }
                col++;
            }
        }
    }
        
    return pt;
};

const CardSetUp = (useSingleAges, CalcYear, ageRange, FinalYear) => {
    let numRows = (useSingleAges) ? 2 : 8;
    let numCols = FinalYear - CalcYear + 2;
    let pt = createSimpleDefaultPackTable(numRows, numCols);

    let col = 1;
    for (let i = CalcYear; i <= FinalYear; i++) {
        pt.tableData.value[0][col] = i.toString();
        col++;
    }

    for (let r = 1; r < numRows; r++) {
        for (let c = 1; c < numCols; c++) {
            pt.RDec[r][c] = 1;
            pt.MaxAllowedVal[r][c] = 100;
            pt.MinAllowedVal[r][c] = 0;
        }
    }

    if (useSingleAges) {
        pt.tableData.value[1][0] = RS('GB_stAllNeeds');
    }
    else {
        pt.tableData.value[1][0] = Get_FP_AgeName(ageRange);
    }

    return pt;
};

const CardSetSSData = (pt, DPModvars, projInfo, useSingleAges, needOptionCBValue, ageRange) => {
    let PrevalenceModvarValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_Tprevalence_MV)['value'];
    let calcYearIndex = getCalcYearIdx(projInfo.calcYear);
    let CalcYear = projInfo.calcYear;
    let FinalYear = projInfo.finalYear;
    let finalYearIndex = FinalYear - CalcYear + calcYearIndex;
    let col;

    if (useSingleAges) {
        col = 1;
        for (let t1 = calcYearIndex; t1 <= finalYearIndex; t1++) {
            if (needOptionCBValue) {
                pt.tableData.value[1][col] = PrevalenceModvarValue[CONSTANTS.FP_All_Ages][CONSTANTS.FP_spacing][t1] +
                    PrevalenceModvarValue[CONSTANTS.FP_All_Ages][CONSTANTS.FP_limiting][t1];
            }
            else {
                pt.tableData.value[1][col] = PrevalenceModvarValue[CONSTANTS.FP_All_Ages][CONSTANTS.FP_all_need][t1];
            }
            col++;
        }
    }
    else {
        col = 1;
        for (let t2 = calcYearIndex; t2 <= finalYearIndex; t2++) {
            if (needOptionCBValue) {
                pt.tableData.value[1][col] = PrevalenceModvarValue[ageRange][CONSTANTS.FP_spacing][t2] +
                    PrevalenceModvarValue[ageRange][CONSTANTS.FP_limiting][t2];
            }
            else {
                pt.tableData.value[1][col] = PrevalenceModvarValue[ageRange][CONSTANTS.FP_all_need][t2];
            }
            col++;
        }
    }

    return pt;
};


