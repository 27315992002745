import * as React from "react";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

class TPaper extends React.Component {

    static propTypes = {
        style        : PropTypes.object,
        onScroll     : PropTypes.func
    };

    static defaultProps = {
        style        : { width: 200, height: 200, background: '#EEEEEE', margin: 20},
        onScroll     : () => {}
    };

    render() {
        return (
            <Paper onScroll={(e) => this.props.onScroll(e)} style={this.props.style}>
                {this.props.children}
            </Paper>
        );
    }
}

export default TPaper;