import React from 'react';
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton/IconButton";
import TRangeSlider from "@common/components/TRangeSlider";
import TDownloadBtn from "@common/components/TDownloadBtn";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { getAllSources } from "utilities/GB/GBUtil";
import ShowDataSectionHeader from "./ShowDataSectionHeader";
import ShowDataDisplayTypes from "./ShowDataDisplayTypes";
import ShowDataContent from "./ShowDataContent";
// import ShowDataSource from "./ShowDataSource";
import SourcesIcon from '../../../../../../img/sourcesIcon.png'
import SourcesDialog from "../../../../../../components/dialogs/SourcesDialog";

class ShowDataSection extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onChangeDisplay      : PropTypes.func,
        onChangeYearComplete : PropTypes.func,
        exploreShowDataItem  : PropTypes.object,
        IVInfo               : PropTypes.arrayOf(PropTypes.object),
        inputModvars         : PropTypes.arrayOf(PropTypes.object),
        selectedCountry      : PropTypes.string,

        onStateChange        : PropTypes.func,
        showDataContent      : PropTypes.arrayOf(PropTypes.object),
        language             : PropTypes.number,
        onRemoveDisplay      : PropTypes.func,
    };

    static defaultProps = {
        onChangeDisplay      : () => console.log('onChangeDisplay'),
        onChangeYearComplete : () => console.log('onChangeYearComplete'),
        exploreShowDataItem  : {},
        IVInfo               : [],
        inputModvars         : [],
        selectedCountry      : '',

        onStateChange        : () => console.log('onStateChange'),
        showDataContent      : [],
        language             : CONSTANTS.GB_English,
        onRemoveDisplay      : () => console.log('onRemoveDisplay'),
    };

    state = {
        yearRange : {
            min: this.props.exploreShowDataItem['firstYear'],
            max: this.props.exploreShowDataItem['finalYear'],
        },
        chart: null,
        source: "",
        srcVis: false,
        tableTitle: ""
    };

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {

        // Black magic!  Compare the display variable without checking the inputmodvars since those do not change!
        let x = this.props.exploreShowDataItem;
        //var {inputModvars, ...a} = x;

        let y = nextProps.exploreShowDataItem;
        //var {inputModvars, ...b} = y;

        // We do a deep compare on the object since object references change when we do a setState
        if (
            (this.props.onRemoveDisplay                     !== nextProps.onRemoveDisplay) ||
            (this.props.onChangeDisplay                     !== nextProps.onChangeDisplay) ||
            (this.props.onChangeYearComplete                !== nextProps.onChangeYearComplete) ||
            // JSON.stringify(this.props.exploreShowDataItem)  !== JSON.stringify(nextProps.exploreShowDataItem) ||
            (this.props.IVInfo                              !== nextProps.IVInfo) ||
            (this.props.inputModvars                        !== nextProps.inputModvars) ||
            (this.props.selectedCountry                     !== nextProps.selectedCountry) ||
            (this.props.onStateChange                       !== nextProps.onStateChange) ||
            (this.props.showDataContent                     !== nextProps.showDataContent) ||
            (this.props.language                            !== nextProps.language) ||
            (this.state.yearRange                           !== nextState.yearRange) ||
            (this.state.tableTitle                          !== nextState.tableTitle) ||
            (this.state.chart                               !== nextState.chart) ||

            // JSON.stringify(this.props.exploreShowDataItem)  !== JSON.stringify(nextProps.exploreShowDataItem) ||
            // (this.props.inputModvars                        !== nextProps.inputModvars) ||
            JSON.stringify(x)  !== JSON.stringify(y)
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onDownloadBtnClick = () => {
        if (this.props.exploreShowDataItem['selectedDisplayType'] === CONSTANTS.CS_TableChart) {
            const name = RS(this.props.exploreShowDataItem.title) + " " + this.props.selectedCountry + ".xlsx";  // this.props.exploreShowDataItem.selectedCountry
            this.setState({tableTitle: name}, () => this.tableRef.current.exportToExcel(name));
            setTimeout(() => {
                this.setState({tableTitle: ""});
            }, 0);
        } 
        else {
            this.state.chart.current.chart.exportChart({ // this.state.chart.current.state.chart.exportChart({
                // type: "image/svg+xml",
                type: "image/png",
                // type: "application/pdf",
                filename: RS(this.props.exploreShowDataItem.title) + " " + this.props.selectedCountry  // this.props.exploreShowDataItem.selectedCountry
            });
        }
    };

    onChangeYear = (value) => {
        this.setState({
            yearRange : value
        }, () => {
            // this.props.onChangeYearComplete(this.props.exploreShowDataItem['itemKey'], this.state.yearRange)
        });
    };

    onChangeYearComplete = () => {
        this.props.onChangeYearComplete(this.props.exploreShowDataItem['itemKey'], this.state.yearRange)
    };

    onInfoClick = () => {
        this.setState({srcVis: !this.state.srcVis}, () => this.forceUpdate());
    };

    onSetSource = (display) => {
        const CS_PackTable = CONSTANTS.CS_PackTable;
        let packTable = Array.isArray(display[CS_PackTable]) ? display[CS_PackTable][0] : display[CS_PackTable];
        this.setState({source: getAllSources(packTable)});
    };

    passData = (o) => {
        this.setState(o);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            divStyle : {
                borderBottom: '1px solid lightgray',
                marginBottom: '15px',
                paddingTop: '10px'
            },
            divStyle1 : {
                borderBottom: '1px solid lightgray',
                marginBottom: '15px',
                paddingTop: '10px'
            },
            section1Style : {
                display: 'inline-block',
                width: '100%'
            },
            section2TableStyle : {
                marginTop: '14px',
                marginBottom: '15px',
                marginLeft: '10px'
            },
            section2ChartStyle : {
                marginTop: '10px',
                marginBottom: '15px'
            },
            displayTypesStyle :{
                float : 'left',
                marginLeft : '25px',
                marginTop : '15px'
            },
            infoStyle: {
                cursor: "pointer",
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#65A7EB"
            },
            iconButton : {
                fontSize: '24px',
                minHeight: '40px',
                minWidth: '40px',
                width: '48px',
                top:"-10px",
                left:"-12px"
            },
            img : {
                height: '23px',
                width: '22px',
                verticalAlign: "top",//"middle",
            },
            div1 : {
                display: 'inline-block',
                verticalAlign:"top",
                cursor:"pointer"
            },
            downloadBtn : {
                top: -2,
                position: "relative"
            }
            // sourceStyle :{
            //     marginLeft : '25px',
            //     marginTop  : '10px'
            // }
        };

        return (
            <div>
                <div style={styles.section1Style}>
                    <ShowDataSectionHeader
                        onCloseBtnClick = {this.props.onRemoveDisplay}
                        itemKey         = {this.props.exploreShowDataItem['itemKey']}
                    />

                    <div style={styles.displayTypesStyle}>
                        <ShowDataDisplayTypes
                            onChangeDisplay      = {this.props.onChangeDisplay}
                            selectedDisplayType  = {this.props.exploreShowDataItem['selectedDisplayType']}
                            possibleDisplayTypes = {this.props.exploreShowDataItem['possibleDisplayTypes']}
                            itemKey              = {this.props.exploreShowDataItem['itemKey']}
                        />
                        <TDownloadBtn
                            onClick={this.onDownloadBtnClick}
                            style={styles.downloadBtn}
                        />
                        {/*<Info*/}
                        {/*    onClick={this.onInfoClick}*/}
                        {/*    style={styles.infoStyle}*/}
                        {/*/>*/}

                        <div style={styles.div1}>
                            <IconButton
                                color="secondary"
                                style={styles.iconButton}
                                onClick={this.onInfoClick}
                            >
                                <img style={styles.img} src={SourcesIcon} alt='' />
                            </IconButton>
                        </div>
                    </div>
                </div>

                {this.state.srcVis ?
                    <SourcesDialog
                        source={this.state.source}
                        editable={false}
                        onClose={this.onInfoClick}
                    /> : null}

                <div style={(this.props.exploreShowDataItem['selectedDisplayType'] === CONSTANTS.CS_TableChart) ? styles.section2TableStyle : styles.section2ChartStyle}>
                    <ShowDataContent
                        exploreShowDataItem = {this.props.exploreShowDataItem}
                        IVInfo              = {this.props.IVInfo}
                        inputModvars        = {this.props.inputModvars}
                        passData            = {this.passData}
                        onSetSource         = {this.onSetSource}
                        tableRef            = {this.tableRef}
                        language            = {this.props.language}
                        selectedCountry     = {this.props.selectedCountry}
                        tableTitle          = {this.state.tableTitle}
                    />
                </div>

                {
                    (this.props.exploreShowDataItem['mode'] !== CONSTANTS.CS_ExploreEffectivenessValuesMode) ?
                        <div style={{textAlign: 'center', marginBottom: '-65px', marginTop: '-15px'}}>
                            <div style={{
                                marginBottom: '35px',
                                width: '600px',
                                maxWidth: "100%",
                                display: 'inline-block'
                            }}>
                                <TRangeSlider
                                    style={{marginBottom: '20px', color: 'red'}}
                                    minValue={this.props.exploreShowDataItem['hFirstYr']}
                                    maxValue={this.props.exploreShowDataItem['hFinalYr']}
                                    minTextLabel={RS('GB_stFirstYear')}
                                    maxTextLabel={RS('GB_stFinalYear')}
                                    value={this.state.yearRange}
                                    onChange={this.onChangeYear}
                                    onChangeComplete={this.onChangeYearComplete}
                                />
                            </div>
                        </div> :
                        null
                }

                {/*<div style={styles.sourceStyle}>*/}
                {/*    <ShowDataSource*/}
                {/*        exploreShowDataItem = {this.props.exploreShowDataItem}*/}
                {/*        inputModvars        = {this.props.inputModvars}*/}
                {/*    />*/}
                {/*</div>*/}

                <div style={(this.props.exploreShowDataItem['mode'] === CONSTANTS.CS_ExploreEffectivenessValuesMode) ? styles.divStyle1 : styles.divStyle}></div>
            </div>
        )
    }

}

export default ShowDataSection;