import CONSTANTS from "utilities/CS/CSConst";
import IncidenceTabbedChart from "../pages/CS/TOOLS/ExploreData/content/TabbedCharts/IncidenceTabbedChart";
import PathogenTabbedChart from "../pages/CS/TOOLS/ExploreData/content/TabbedCharts/PathogenTabbedChart";
import React from "react";
import RS from "@common/strings/RS";
import EffectivenessValuesTabbedChart from "../pages/CS/TOOLS/ExploreData/content/TabbedCharts/EffectivenessValuesTabbedChart";
import StuntingWastingTabbedChart from "../pages/CS/TOOLS/ExploreData/content/TabbedCharts/StuntingWastingTabbedChart";
import {DirectEntryMstIDSet, getIntervRec} from "utilities/CS/CSDataUtil";

export const isSubGroupInterv = (IVRec) => {
    return ((IVRec["RequiredIntervs"].intArray.length > 0) && !IVRec["SumTotal"] && (IVRec["MstID"] !== CONSTANTS.IV_PentaVacc));
};

export const getCheckBoxLabelStyle = (IVInfo, mstID) => {
    const styles = {
        checkBoxLabelStyle1: {
            color: '#0c3a79',
            fontSize: '13px',
            lineHeight: '1'
        },
        checkBoxLabelStyle2: {
            color: '#f78c30',
            fontSize: '13px',
            lineHeight: '1'
        },
        checkBoxLabelStyle3: {
            color: '#65A7EB',
            fontSize: '13px',
            lineHeight: '1'
        },
        checkBoxLabelStyle4: {
            color: '#65A7EB',
            fontSize: '13px',
            lineHeight: '1',
            marginLeft: '15px'
        },
    };

    let result = '';

    let IVRec = getIntervRec(IVInfo, mstID);

    if (DirectEntryMstIDSet().includes(mstID)) {
        result = styles.checkBoxLabelStyle3;
    }
    else if (isSubGroupInterv(IVRec)){
        result = styles.checkBoxLabelStyle4;
    }
    else {
        result = styles.checkBoxLabelStyle1;
    }

    return result;
};

export const getCheckBoxStyle = (IVInfo, mstID) => {
    const styles = {
        checkBoxStyle1 : {
            height: '37px',
        },
        checkBoxStyle2 : {
            height: '37px',
            marginTop: '-7px'
        },
    };

    let result = '';

    let IVRec = getIntervRec(IVInfo, mstID);

    if (isSubGroupInterv(IVRec)){
        result = styles.checkBoxStyle2;
    }
    else {
        result = styles.checkBoxStyle1;
    }

    return result;
};

export const getBoxStyle = (IVInfo, mstID, color) => {
    const styles = {
        grayBoxStyle : {
            color: '#EBEBEB'
        },
        whiteBoxStyle : {
            color: '#FFFFFF'
        },
    };

    let result = {};

    let IVRec = getIntervRec(IVInfo, mstID);

    if (isSubGroupInterv(IVRec) && (color === 'gray')){
        result = styles.grayBoxStyle;
    }

    if (isSubGroupInterv(IVRec) && (color === 'white')){
        result = styles.whiteBoxStyle;
    }

    return result;
};

export const chooseTabbedChart = (o) => {
    let chart = null;
    if (o.choice === "EffectivenessValues") {
        chart = <EffectivenessValuesTabbedChart
            id={"CDChart" + o.id + o.age}
            dat={o.packTable[o.age]}
            age={o.age}
            chartTitle={o.chartTitle}
            options={o.options}
            chartType={o.chartType}
            stacked={o.stacked}
            legendReversed={o.legendReversed}
            key={"CDChart" + o.id + o.age}
            tableRef={o.tableRef}
        />;
    } else if (o.choice === "Incidence") {
        chart = <IncidenceTabbedChart
            id={"CDChart" + o.id + o.age}
            dat={o.packTable}
            age={o.age}
            chartTitle={o.chartTitle}
            options={o.options}
            chartType={o.chartType}
            stacked={o.stacked}
            legendReversed={o.legendReversed}
            key={"CDChart" + o.id + o.age}
            tableRef={o.tableRef}
        />;
    } else if (o.choice.includes("Pathogen")) {
        chart = <PathogenTabbedChart
            id={"CDChart" + o.id + o.age}
            dat={o.packTable}
            age={o.age}
            chartTitle={o.chartTitle}
            options={o.options}
            chartType={o.chartType}
            stacked={o.stacked}
            legendReversed={o.legendReversed}
            key={"CDChart" + o.id + o.age}
            tableRef={o.tableRef}
            choice={o.choice}
        />;
    } else if (o.choice === "StuntingWasting") {
        chart = <StuntingWastingTabbedChart
            id={"CDChart" + o.id + o.age}
            dat={o.packTable}
            age={o.age}
            chartTitle={o.chartTitle}
            options={o.options}
            chartType={o.chartType}
            stacked={o.stacked}
            legendReversed={o.legendReversed}
            key={"CDChart" + o.id + o.age}
            tableRef={o.tableRef}
            choice={o.choice}
        />;
    }
    return (chart)
};

export const getEffectivenessOfInterventionsPackChart = (pt, a) => {
    let pointLabels    = [];
    let chartDataAF    = [];
    let chartDataEff   = [];
    let chartData      = [];
    let subsetLabels   = [];
    let subsetColors   = [];
    let verticalLabels = [];

    a *= 2;

    let xAxisLabel = RS('GB_stInterventions');

    for (let i = 3; i < pt.tableData.value.length; i++) {
        if ((pt.tableData.value[i][1 + a] !== '') || (pt.tableData.value[i][2 + a] !== '')) {
            pointLabels.push(pt.tableData.value[i][0]);
            chartDataEff.push(pt.tableData.value[i][1 + a] * 100);
            chartDataAF.push(pt.tableData.value[i][2 + a] * 100);
        }
    }

    let labels = [];
    let numInterv = [];

    for (let j = 3; j < pt.tableData.value.length; j++) {
        if ((pt.tableData.value[j][1 + a] === '') && (pt.tableData.value[j][2 + a] === '')) {
            labels.push(pt.tableData.value[j][0]);
        }
    }

    let num = -1;
    for (let j = 3; j < pt.tableData.value.length; j++) {
        if ((pt.tableData.value[j][1 + a] === '') && (pt.tableData.value[j][2 + a] === '')) {
            if (num > -1) {
                numInterv.push(num)
            }
        }
        else {
            num++;
        }
    }

    for (let k = 0; k < labels.length; k++) {
        if (k < labels.length -1) {
            verticalLabels.push([numInterv[k], labels[k]])
        }
        else {
            verticalLabels.push([null, labels[k]])
        }
    }

    if (verticalLabels.length > 0) {
        xAxisLabel = '';
    }

    chartData.push(chartDataEff);
    subsetLabels.push(RS('GB_stEffectiveness'));
    subsetColors.push(CONSTANTS.CS_EffectivenessChartColor);

    chartData.push(chartDataAF);
    subsetLabels.push(RS('CS_stAffFract'));
    subsetColors.push(CONSTANTS.CS_AffectedFractionChartColor);

    return (
        {
            "title": '',
            "alignTitle": 'left',
            "subTitle": '',
            "alignSubTitle": 'left',
            "MultiSubTitles": ["", ""],
            "chartType": 12,
            "showLegend": true,
            "legendLocation" : 0,
            "legendAlign" : 'center',

            "xAxisLabel": xAxisLabel,
            "pointLabels": pointLabels,

            "yAxisLabel": '',
            "ManualMinY": 0,
            "ManualMaxY": 0,
            "ManualStackedMinY": 0,
            "ManualStackedMaxY": 0,

            "RYAxisLabel": "Right Y Axis",
            "ManualMinRY": 0,
            "RYAxisComparisonSubsets": 0,

            "subsetLabels": subsetLabels,
            "chartData": chartData,
            "subsetColors": subsetColors,
            "subsetChartTypes": [0],
            "subsetPointTypes": [-1],
            "subsetLineTypes": [-1],
            "SubsetsToLegend": [0],

            "verticalLabels" : verticalLabels,
            "chartLabelLeftMargin" : 350,
        }
    )
};

export const getIncidencePackChart = (pt, a) => {
    let pointLabels    = [];
    let chartDataInc1   = [];
    let chartDataInc2   = [];
    let chartDataInc3   = [];
    let chartDataInc4   = [];
    let chartData      = [];
    let subsetLabels   = [];
    let subsetColors   = [];
    let verticalLabels = [];

    let xAxisLabel = "";//RS('GB_stInterventions');

    for (let i = 1; i < pt[a].tableData.value[0].length; i++) {
        //if ((pt[a].tableData.value[i][1 + a] !== '') || (pt[a].tableData.value[i][2 + a] !== '')) {
        let year = pt[a].tableData.value[0][i];
        pointLabels.push(year);
        chartDataInc1.push(pt[a].tableData.value[1][i]);
        chartDataInc2.push(pt[a].tableData.value[2][i] * 100);
        chartDataInc3.push(pt[a].tableData.value[3][i] * 100);
        chartDataInc4.push(pt[a].tableData.value[4][i] * 100);
        //}
    }
    // chartDataInc1.push(pt[a].tableData.value[1][1 + a] * 100);
    // chartDataInc2.push(pt[a].tableData.value[2][1 + a] * 100);
    // chartDataInc3.push(pt[a].tableData.value[3][1 + a] * 100);
    // chartDataInc4.push(pt[a].tableData.value[4][1 + a] * 100);

    let labels = [];
    let numInterv = [];

    for (let j = 3; j < pt[a].tableData.value.length; j++) {
        if ((pt[a].tableData.value[j][1 + a] === '') && (pt[a].tableData.value[j][2 + a] === '')) {
            labels.push(pt[a].tableData.value[j][0]);
        }
    }

    let num = -1;
    for (let j = 3; j < pt[a].tableData.value.length; j++) {
        if ((pt[a].tableData.value[j][1 + a] === '') && (pt[a].tableData.value[j][2 + a] === '')) {
            if (num > -1) {
                numInterv.push(num)
            }
        }
        else {
            num++;
        }
    }

    for (let k = 0; k < labels.length; k++) {
        if (k < labels.length -1) {
            verticalLabels.push([numInterv[k], labels[k]])
        }
        else {
            verticalLabels.push([null, labels[k]])
        }
    }

    if (verticalLabels.length > 0) {
        xAxisLabel = '';
    }

    chartData.push(chartDataInc1);
    chartData.push(chartDataInc2);
    chartData.push(chartDataInc3);
    chartData.push(chartDataInc4);
    subsetLabels.push(RS('GB_stDiarInc'));
    subsetLabels.push(RS('GB_stIncSevereDiarr'));
    subsetLabels.push(RS('GB_stPneumInc'));
    subsetLabels.push(RS('GB_stMeningInc'));
    subsetColors.push(CONSTANTS.CS_DiarIncChartColor);
    subsetColors.push(CONSTANTS.CS_SevDiarIncChartColor);
    subsetColors.push(CONSTANTS.CS_PneumIncChartColor);
    subsetColors.push(CONSTANTS.CS_MeningIncChartColor);
    // chartData.push(chartDataAF);
    // subsetLabels.push(RS('CS_stAffFract'));
    // subsetColors.push(CONSTANTS.CS_AffectedFractionChartColor);

    return (
        {
            "title": '',
            "alignTitle": 'left',
            "subTitle": '',
            "alignSubTitle": 'left',
            "MultiSubTitles": ["", ""],
            "chartType": 12,
            "showLegend": true,
            "legendLocation" : 0,
            "legendAlign" : 'center',

            "xAxisLabel": xAxisLabel,
            "pointLabels": pointLabels,

            "yAxisLabel": '',
            "ManualMinY": 0,
            "ManualMaxY": 0,
            "ManualStackedMinY": 0,
            "ManualStackedMaxY": 0,

            "RYAxisLabel": "Right Y Axis",
            "ManualMinRY": 0,
            "RYAxisComparisonSubsets": 0,

            "subsetLabels": subsetLabels,
            "chartData": chartData,
            "subsetColors": subsetColors,
            "subsetChartTypes": [0],
            "subsetPointTypes": [-1],
            "subsetLineTypes": [-1],
            "SubsetsToLegend": [0],

            "verticalLabels" : verticalLabels,
            "chartLabelLeftMargin" : 350,
        }
    )
};

export const getPathogenPackChart = (pt, a, choice) => {
    let pointLabels    = pt[a].tableData.value[0].slice(1);//[];
    // let chartDataPath  = [];
    let chartData      = [];
    let subsetLabels   = [];
    let subsetColors   = [];
    let verticalLabels = [];

    // pointLabels.push(...pointLabels, ...pointLabels);
    // //pointLabels.flat(Infinity);
    // pointLabels.sort();
    // console.log(pointLabels);

    // let choices = ["PathogenAll", "PathogenSevere", "PathogenFatal"];
    // let skip = a + (choices.indexOf(choice) * 4);

    let xAxisLabel = "";//RS('GB_stInterventions');

    for (let i = 1; i < pt[a].tableData.value.length; i++) {
        let dat1 = [];
        // let dat2 = [];
        // let dat3 = [];
        for (let j = 0; j < pt[a].tableData.value[i].length; j++) {
            let datPoint = pt[a].tableData.value[i][j];
            if (j === 0) {
                subsetLabels.push(datPoint);
            } else if (i < 6) {
                dat1.push(datPoint);
            } else if (i < 12) {
                dat1.push(datPoint);
            } else if (i < 18) {
                dat1.push(datPoint);
            }
        }
        chartData.push(dat1);
    }

    // for (let i = 1; i < pt[a].tableData.value.length; i++) {
    //     //if ((pt.tableData.value[i][1 + a] !== '') || (pt.tableData.value[i][2 + a] !== '')) {
    //     for (let j = 1; j < pt[a].tableData.value[i].length; j++) {
    //         if (i === 1) {
    //             pointLabels.push(pt[a].tableData.value[0][j]);
    //         }
    //         chartDataPath.push(pt[a].tableData.value[i][j]);
    //     }
    //     chartData.push(chartDataPath);
    //     //}
    // }

    let labels = [];
    let numInterv = [];

    for (let j = 3; j < pt[a].tableData.value.length; j++) {
        if ((pt[a].tableData.value[j][1 + a] === '') && (pt[a].tableData.value[j][2 + a] === '')) {
            labels.push(pt[a].tableData.value[j][0]);
        }
    }

    let num = -1;
    for (let j = 3; j < pt[a].tableData.value.length; j++) {
        if ((pt[a].tableData.value[j][1 + a] === '') && (pt[a].tableData.value[j][2 + a] === '')) {
            if (num > -1) {
                numInterv.push(num)
            }
        }
        else {
            num++;
        }
    }

    for (let k = 0; k < labels.length; k++) {
        if (k < labels.length -1) {
            verticalLabels.push([numInterv[k], labels[k]])
        }
        else {
            verticalLabels.push([null, labels[k]])
        }
    }

    if (verticalLabels.length > 0) {
        xAxisLabel = '';
    }

    //chartData.push(chartDataPath);
    //subsetLabels.push(RS('GB_stPathogens'));
    // subsetColors.push(CONSTANTS.CS_EffectivenessChartColor);
    //
    // chartData.push(chartDataAF);
    // subsetLabels.push(RS('CS_stAffFract'));
    // subsetColors.push(CONSTANTS.CS_AffectedFractionChartColor);

    return (
        {
            "title": '',
            "alignTitle": 'left',
            "subTitle": '',
            "alignSubTitle": 'left',
            "MultiSubTitles": ["", ""],
            "chartType": 12,
            "showLegend": true,
            "legendLocation" : 0,
            "legendAlign" : 'center',

            "xAxisLabel": xAxisLabel,
            "pointLabels": pointLabels,

            "yAxisLabel": '',
            "ManualMinY": 0,
            "ManualMaxY": 0,
            "ManualStackedMinY": 0,
            "ManualStackedMaxY": 0,

            "RYAxisLabel": "Right Y Axis",
            "ManualMinRY": 0,
            "RYAxisComparisonSubsets": 0,

            "subsetLabels": subsetLabels,
            "chartData": chartData,
            "subsetColors": subsetColors,
            "subsetChartTypes": [0],
            "subsetPointTypes": [-1],
            "subsetLineTypes": [-1],
            "SubsetsToLegend": [0],

            "verticalLabels" : verticalLabels,
            "chartLabelLeftMargin" : 350,
        }
    )
};