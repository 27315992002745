import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";

class TCSNutritionDialog extends React.Component {

    static propTypes = {
        onStateChange : PropTypes.func,
    };

    static defaultProps = {
        onStateChange : () => console.log('onStateChange'),
    };

    onClose = () => {
        this.props.onStateChange({
            dialogNutritionOpen : false
        });
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stNutritionInterv')}
                onClose={this.onClose}
                content={RS('GB_stNutritionIntervInfo')}
            />
        );
    }

}

export default TCSNutritionDialog;