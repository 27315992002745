import * as React from 'react';

class ErrorBoundaryEditor extends React.Component {

    state = { 
        hasError: false 
    };
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { 
            hasError: true 
        };
    }
  
    componentDidCatch(error, info) {
        console.log('Logged: ' + error + ' ------ ' + info);
    }
  
    render() {
        let styles = {
            error: {
                width: '200px',
                height: '200px',
                background: '#ffd0ce',
                padding: '20px',
                boxSizing: 'border-box',
                borderRadius: '5px',
                border: '1px solid #BBBBBB',
                margin: 0,
                fontSize: '24px',
                fontWeight: 'bold'
            },
            wrapper : {
                margin: '5px 0',
                paddingBottom: '20px'
            }
        };

        if (this.state.hasError) {
            return (
                <p style={styles.error}>
                    Error: The editor failed to render.
                </p>
            );
        }
  
        return (
            <div style={styles.wrapper}>
                {this.props.children}
            </div>
        );
    }
}

export default ErrorBoundaryEditor;