import RS from "@common/strings/RS";
import { makeRequest, makeUpload } from './makeRequest';
import CONSTANTS from "utilities/CS/CSConst";
import {SessionStorageUtil} from './localstorage/util';
import {GetCTToken} from './container_calls';
import axios from 'axios';

//======================================================================================================================
//
//                                             SERVER CALLS
//
//======================================================================================================================

export const RESTService2 = {

    ping : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.Ping', {}, {
            modID : params.modID
        }, successFn, errorFn),

    getCountries : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.GetCountries', {}, {
            modID : params.modID,
            ignoreSampleCountry : true,
            ignoreNoHouseholdDataCountry: params.ignoreNoHouseholdDataCountry ? params.ignoreNoHouseholdDataCountry : true,
            subnatMode : params.subnatMode
        }, (response) => {

            // Add a "National" region to each country
            response.forEach(x => {
                x.Regions[0] = {
                    Surveys : [
                        {
                            Year: -1,
                            name: x.LiSTSurveyYear
                        }
                    ],
                    name : RS("GB_stNoRegionSelected")
                };
            });

            successFn(response);
        }, errorFn),

    getIntervGroupList : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.GetIntervGroupList', {}, {}, (response) => {
            successFn(response);
        }, errorFn),

    getIntervSubGroupList : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.GetIntervSubGroupList', {}, {}, (response) => {
            successFn(response);
        }, errorFn),

    getIntervList : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.GetIntervList', {}, {}, (response) => {
            successFn(response);
        }, errorFn),

    getVersion : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.GetVersion', {}, {
            modID : params.modID
        }, successFn, errorFn),

    getFileShareStatus : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.GetFileShareStatus', {}, {}, successFn, errorFn),

    writeFileShareLog : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.REST_CLIENT_ID, 'GeneralService.WriteFileShareLog', {
			
		}, {text:new Date()}, successFn, errorFn),

};

export const LoginService2 = {

    getStatus : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBLoginService.Ping', {}, {}, successFn, errorFn),

    signedIn : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBLoginService.SignedIn', {}, {}, successFn, errorFn),

    login : (params, successFn, errorFn) => {
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBLoginService.Login', {}, {
            appID       : 'LOL',

            containerID : window.App.CONTAINER_PASS_PREFIX + window.App.CLIENT_ID.substr(1, window.App.CLIENT_ID.length-2).replace(/-/g,''),
            baseURL     : SessionStorageUtil.getContainerBaseURL(), 
            token       : SessionStorageUtil.getToken(),
            linkToken   : GetCTToken(),

            username    : params.username,
            password    : params.password
        }, successFn, errorFn);
    },

    loginAsGuest : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBLoginService.Login', {}, {
            appID: 'LOL',
            
            containerID : window.App.CONTAINER_PASS_PREFIX + window.App.CLIENT_ID.substr(1, window.App.CLIENT_ID.length-2).replace(/-/g,''),
            baseURL     : SessionStorageUtil.getContainerBaseURL(), 
            token       : SessionStorageUtil.getToken(),
            linkToken   : GetCTToken(),

            username: 'guest',
            password: 123456
        }, successFn, errorFn),

    logout : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBLoginService.Logout', {}, {}, successFn, errorFn),

    getAccountInfo : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBLoginService.getAccountInfo', {}, {}, successFn, errorFn),

};

export const ModUtilService2 = {
    
    getMOCountryData : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'ModUtilService.CS_GetMOCountryData', {}, {
            countryCode: params.countryISO,
        }, mySuccess, myError),

};

export const SpectrumService2 = {

    doRun : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.Run', {}, {
            serviceList : params.arr  
        }, successFn, errorFn),

    keepAlive : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.Alive', {}, {}, successFn, errorFn),
        
    getActiveProjectionList : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.GetActiveProjectionList', {}, {}, successFn, errorFn),

    getStoredProjectionList : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.GetStoredProjectionList', {}, {}, successFn, errorFn),

    //------------------------------------------------------------------------------------------------------------------

    // getGBData : (params, successFn, errorFn) =>
    //     makeRequest(API_URL, CLIENT_ID, 'GBSpectrumService.GetGB', {}, {}, (response) => {

    //         successFn(response);

    //         // // Only return what we need to keep the state smaller and more maintainable
    //         // successFn({
    //         //     FirstProj         : response.FirstProj,   
    //         //     FinalProj         : response.FinalProj, 
    //         //     CountryRegionCode : response.CountryRegionCode,
    //         // });
    //     }, errorFn),

    getModvars : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.GetModvars', {}, {
            modvars : params.arr
        }, successFn, errorFn),

    setModvars : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.SetModvars', {}, {
            modvars : params.arr
        }, successFn, errorFn),

    getInputModVars : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.getInputModVars', {}, {
            countryCode: params.countryISO,
            modList: params.modList,
            firstYear : CONSTANTS.CS_MODefaultFirstYear,
            finalYear : new Date().getFullYear(),
            useRawData : params.useRawData,
        }, mySuccess, myError),

    //------------------------------------------------------------------------------------------------------------------

    createProjection : (params, mySuccess, myError) => {
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.CreateProjection', {}, {
            countryISO: params.CountryISO,
            firstYear: params.FirstYear,
            finalYear: params.FinalYear,
            fileName: params.FileName,
            modList: params.ModList,
            notes: params.Notes,
            author: params.Author,
            organization: params.Org,
            subnatStep1: params.subnatStep1,
            subnatStep2: params.subnatStep2,
            region: params.Region,
            survey: params.Survey,
            AgeGroupOption : params.AgeGroupOption,
        }, mySuccess, myError)
    },

    modifyProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.ModifyProjection', {}, {
            proj         : params.Proj,
            fileTitle    : params.FileTitle,
            finalYear    : params.FinalYear,
            countryISO   : params.CountryISO,
            modList      : params.ModList,
            author       : params.Author,
            organization : params.Org,
            notes        : params.Notes,
        }, mySuccess, myError),

    openProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.OpenProjection', {}, {
            fileTitle : params.FileTitle
        }, mySuccess, myError),

    saveProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.SaveProjection', {}, {
            proj      : params.Proj,
            fileTitle : params.FileTitle,
			tag       : ''
        }, mySuccess, myError),

    closeProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.CloseProjection', {}, {
            proj : params.Proj
        }, mySuccess, myError),

    downloadProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.DownloadProjection', {}, {
            fileTitle : params.FileTitle
        }, (response) => mySuccess({response: response, API_URL_BASE : window.App.API_URL_BASE}), myError),

    deleteProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.DeleteProjection', {}, {
            fileTitle : params.FileTitle
        }, mySuccess, myError),

    calculateProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.CalculateProjection', {}, {
            proj : params.Proj
        }, mySuccess, myError),

    copyProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, "GBSpectrumService.CopyProjection", {}, {
            oldFileTitle: params.oldName,
            newFileTitle: params.newName
        }, mySuccess, myError),

    renameProjection : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, "GBSpectrumService.RenameProjection", {}, {
            oldFileTitle: params.oldName,
            newFileTitle: params.newName
        }, mySuccess, myError),

    getResultMenus : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, "GBSpectrumService.GetResultMenus", {}, {}, mySuccess, myError),
    
    upload : (params, mySuccess, myError) =>
        makeUpload(window.App.API_URL_BASE, window.App.CLIENT_ID, 
            params.file, 
        mySuccess, myError),

    setAccountInfo : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, "GBSpectrumService.setAccountInfo", {}, params, mySuccess, myError),

    getTagList : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.GetTagList', {}, {}, successFn, errorFn),

    SetProjectionTags : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.SetProjectionTags', {}, {
            projections : [{
                fileTitle: params.fileTitle + ".pjnz",
                tag: params.tag
            }]
        }, successFn, errorFn),

    addSubnatData : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'ModUtilService.CS_AddSubnatData', {}, {
            countryISO: params.CountryISO,
            region: params.Region,
            survey: params.Survey,
            useWizard : params.useWizard,
        }, successFn, errorFn),

    setUserOptions : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, "GBSpectrumService.SetUserOptions", {}, {
            options: {
                language: params.lang
            }
        }, successFn, errorFn),

    //------------------------------------------------------------------------------------------------------------------

    // Unused
    getResultsData : (params, successFn, errorFn) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.GetResults', {}, {
            getAll  : params.getAll,
            results : params.results
        }, successFn, errorFn),

    getResultTables : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, "GBSpectrumService.GetResultTables", {}, {
            results : params.results,
        }, mySuccess, myError),

    getResultCharts : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, "GBSpectrumService.GetResultCharts", {}, {
            results : params.results,
        }, mySuccess, myError),

    getResultTable : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'GBSpectrumService.GetResultTable', {}, {
            proj         : params.Proj,
            resultID     : params.resultID,
            chartOptions : params.ChartOptions
        }, mySuccess, myError),

    getDataPackResults : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'DataPackService.Results', {}, {
            indicators : params.indicators,
            chartYears : params.chartYears,
            language : params.language
        }, mySuccess, myError),

    getDataPackResultsLOCAL : (params, mySuccess, myError) => {
        const json = {
            indicators : params.indicators,
            chartYears : params.chartYears,
            language : params.language,
            modvars : params.modvars,
            activeProjections : params.activeProjections,
            IVInfo : params.IVInfo,
            IVGroupInfo : params.IVGroupInfo
        };

        const URL = "http://localhost:8089/api/v1/";

        axios.post(URL + "results", json).then(response => {
            mySuccess(response.data);
        }, myError); 
    },

    getDataPackExtracts : (params, mySuccess, myError) =>
        makeRequest(window.App.API_URL, window.App.CLIENT_ID, 'DataPackService.Extract', {}, {
            indicators : params.indicators,
            chartYears : params.chartYears,
            language : params.language
        }, mySuccess, myError),

    getDataPackExtractsLOCAL : (params, mySuccess, myError) => {
        const json = {
            indicators : params.indicators,
            chartYears : params.chartYears,
            language : params.language,
            modvars : params.modvars,
            activeProjections : params.activeProjections,
            IVInfo : params.IVInfo,
            IVGroupInfo : params.IVGroupInfo
        };

        const URL = "http://localhost:8089/api/v1/";

        axios.post(URL + "extracts", json).then(response => {
            mySuccess(response.data);
        }, myError); 
    }

};