import React from 'react';
//import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSAdolescentsDrawer from "../../drawers/HealthStatus/CSAdolescentsDrawer";

class CSAdolescentsBlueBox extends React.Component {
    static propTypes = {
        //
    };

    static defaultProps = {
        //
    };

    constructor(props) {
        super(props);

        this.state = {
            saveBtnDisabled : true,
            openDrawer      : false,
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({
            openDrawer : false,
        });
    };

    onSaveBtnClick = () => {
        //
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            box: {
                height: '110px',
                width: '205px',
                display: 'table-cell',
                verticalAlign: 'middle',
                margin: '0 auto',
                textAlign: 'center',
                //the above reproduces the CSBlueBox2 default
                //below is padding to prevent the title from brushing against the border
                padding: 5
            }
        };

        return (
            <CSBlueBox2
                caption     = {RS('GB_stAdolescentMort')}
                description = ""
                onClick     = {this.onBlueBoxClick}
                captionStyle= {styles.box}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        //helpFile        = {"abortion_list.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stAdolescentMort')}
                        content         = {
                            <CSAdolescentsDrawer
                                //
                            />
                        }
                    />
                }
            />
        );
    }
}

export default CSAdolescentsBlueBox;