import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
    CloneObj,
    getIdxFromYear,
    indentPackTable,
    lockPackTable,
    lockPackRowCol,
    addMergedCellsToArray,
    addRowToPackTable, generatePackChart,
} from "utilities/GB/GBUtil";

import {
    CSGetStdDevBandName,
    getAgeCohortName,
    GetStuntMstFromSDandAge,
    GetWastMstFromSDandAge,
    //CSConvertZScoresToIndicator
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const StuntWastEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, StuntWast, SubnatMode, exploreData = false) => {
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, SubnatMode, exploreData);
        packTable = StuntWastEditor.SetSSData(packTable, CSModvars, StuntWast, SubnatMode);
        packTable = StuntWastEditor.RecalcGrid(packTable);
        switch (StuntWast) {
            case CONSTANTS.CS_GroupMstID_Stunting :{
                packTable.Title = RS('GB_stStuntingDist');
                packTable.ID = CONSTANTS.CS_MstLiSTOnlineStuntingSheetID;
                break;
            }
            case CONSTANTS.CS_GroupMstID_Wasting :{
                packTable.Title = RS('GB_stWastingDist');
                packTable.ID = CONSTANTS.CS_MstLiSTOnlineWastingSheetID;
                break;
            }
            default : break;
        }
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_Coverage;
        return ([packChart, packTable]);
    },

    SetSSData : (pt, CSModvars, StuntWast, SubnatMode) => {
        let FirstTimeEnteringCoverage = {};
        let SubnatDefDataMstIDset = {};
        let SubnatDataSourceArray = {};

        if (SubnatMode){
            FirstTimeEnteringCoverage = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TFirstTimeEnteringCoverage);
            SubnatDefDataMstIDset = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TSubnatDefDataMstIDset);
            SubnatDataSourceArray = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TSubnatDataSourceArray);
        }

        let tag;
        switch (StuntWast) {
            case CONSTANTS.CS_GroupMstID_Stunting : {tag = CONSTANTS.CS_TG_StuntingDistr_MV; break;}
            case CONSTANTS.CS_GroupMstID_Wasting  : {tag = CONSTANTS.CS_TG_WastingDistr_MV; break;}
            default : break;
        }
        let Distr = getModvarByTag(CSModvars, tag);

        let row = 0;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            row++;
            for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++) {
                row++;
                if (!SubnatMode) {
                    let col = 1;
                    for (let yr = 0; yr < pt.yearArr.length; yr++) {
                        let t = pt.yearArr[yr];
                        pt = SetValue(pt, Distr, sd, a, t, row, col);
                        col++;
                    }
                    pt.Source[0] = Distr["source"][0][0];
                }
                else{
                    pt = SetValue(pt, Distr, sd, a, pt.yearArr[0], row, 1);

                    /* Under appropriate circumstances, set the first year (national)
                       coverage as the second year (subnational) stunting distribution as well. */
                    let MstID;
                    switch (StuntWast) {
                        case CONSTANTS.CS_GroupMstID_Stunting : {MstID = GetStuntMstFromSDandAge(sd, a); break;}
                        case CONSTANTS.CS_GroupMstID_Wasting  : {MstID = GetWastMstFromSDandAge(sd, a); break;}
                        default : break;
                    }
                    if (FirstTimeEnteringCoverage["value"] && !SubnatDefDataMstIDset["value"]["intArray"].includes(MstID)){
                        pt = SetValue(pt, Distr, sd, a, pt.yearArr[0], row, 2);
                    }
                    else{
                        pt = SetValue(pt, Distr, sd, a, pt.yearArr[1], row, 2);
                    }
                    pt.Source[pt.SourceMap[row]] = SubnatDataSourceArray["value"][MstID];
                }
            }
            row++;
        }

        return pt;
    },

    RecalcGrid : (pt) => {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let row = 0;
            for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
                row++;
                let sum = "";
                let sumStarted = false;
                let calculatedStdDevRow;
                for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++) {
                    row++;
                    /* Add the value to the sum if it is not a calculated standard deviation row. */
                    if (sd !== CONSTANTS.CS_GT1STD) {
                        if (pt.tableData.value[row][col] !== "") {
                            if (!sumStarted){
                                sum = 0;
                                sumStarted = true;
                            }
                            sum += pt.tableData.value[row][col];
                        }
                    }
                    /* If we are on a calculated standard deviation row, save the row number. */
                    else {
                        calculatedStdDevRow = row;
                    }
                }
                /* Calculate and set value for calculated standard deviation row. */
                let value = "";
                if (sum !== ""){
                    value = Math.max(100 - sum, 0);
                }
                pt.tableData.value[calculatedStdDevRow][col] = value;

                row++;
                /* Add value of calculated standard deviation row to total and set total value. */
                sum = sum + value;
                pt.tableData.value[row][col] = sum;
            }
            col++;
        }

        return (pt);
    },

    GetSSData : (pt, CSModvars, StuntWast, SubnatMode) => {
        let CSModvarsClone = CloneObj(CSModvars);

        //let SingleDistr;
        let Distr;

        switch (StuntWast) {
            case CONSTANTS.CS_GroupMstID_Stunting :{
                //SingleDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleStuntingDistr_MV)["value"];
                Distr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_StuntingDistr_MV);
                break;
            }
            case CONSTANTS.CS_GroupMstID_Wasting :{
                //SingleDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleWastingDistr_MV)["value"];
                Distr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_WastingDistr_MV);
                break;
            }
            default : break;
        }

        let FirstTimeEnteringCoverage = {};
        if (SubnatMode) {
            FirstTimeEnteringCoverage = getModvarByTag(CSModvarsClone, CONSTANTS.VW_TG_TFirstTimeEnteringCoverage);
            FirstTimeEnteringCoverage["value"] = false;
        }

        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            let row = 0;
            for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
                row++;
                for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++) {
                    row++;
                    Distr["value"][sd][a][t] = pt.tableData.value[row][col];
                }
                row++;
            }
            col++;
        }
        //SingleDistr = CSConvertZScoresToIndicator(SingleDistr, Distr["value"], pt.StartIndex, pt.EndIndex);
        if (!SubnatMode) {
            Distr["source"][0][0] = pt.Source[0];
        }

        return (CSModvarsClone);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, SubnatMode, exploreData) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let pt = getNewPT();

    if (!SubnatMode) {
        pt.SourceMode = CONSTANTS.GB_OneEditorSrc;
    }
    else{
        pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;
    }

    pt.StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    pt.EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    pt.yearArr = [];
    let numCols;
    if (!SubnatMode) {
        numCols = pt.EndIndex - pt.StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = pt.StartIndex; t <= pt.EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }
    else{
        numCols = 3;
        for (let t = pt.StartIndex; t <= pt.StartIndex + 1; t++){
            pt.yearArr.push(t);
        }
    }

    const numRows = 1;

    pt = resizePackTable(pt, numRows, numCols);

    if (!SubnatMode) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            col++;
        }
    }
    else {
        pt.tableData.value[0][1] = RS('GB_stNational');
        pt.tableData.value[0][2] = RS('GB_stSubnational');
    }

    pt.tableData.value[0][0] = RS('GB_stInterventionPercent');

    let row = 0;
    pt.numSources = 0;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        row++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = getAgeCohortName(a, true);
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
        pt = lockPackTable(pt, row, true);
        for (let sd = CONSTANTS.CS_GT1STD; sd <= CONSTANTS.CS_GT3STD; sd++){
            row++;
            pt = addRowToPackTable(pt);
            if (sd === CONSTANTS.CS_GT1STD){
                pt = lockPackTable(pt, row, true);
            }
            if (exploreData){
                pt = lockPackTable(pt, row, true, false);
            }
            pt.tableData.value[row][0] = CSGetStdDevBandName(sd);
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            pt.numSources++;
            pt.SourceMap[row] = pt.numSources;
            pt.SourceTitle[pt.numSources] = getAgeCohortName(a, true) + ' - ' + CSGetStdDevBandName(sd);
        }
        row++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = RS('DP_stTotal');
        pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
        pt = lockPackTable(pt, row, true);
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 1;
            }
        }
        if (SubnatMode){
            pt = lockPackRowCol(pt, row, 1, true);
        }
    }

    return pt;
};

const SetValue = (pt, Distr, sd, a, t, row, col) => {
    pt.tableData.value[row][col] = Distr["value"][sd][a][t];
    if (pt.tableData.value[row][col] < 0) {
        pt.tableData.value[row][col] = '';
    }
    return (pt);
};

