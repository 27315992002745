import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSBreastfeedingDrawer from "../../drawers/Coverage/CSBreastfeedingDrawer";
import {EarlyInitEditor} from "../../data/Coverage/CS/EarlyInitEditor";
import {BFPrevEditor} from "../../data/Coverage/CS/BFPrevEditor";
import {getModvarByTag} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";

class CSBreastfeedingBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        captionStyle     : PropTypes.object,
        firstYear        : PropTypes.number,
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        captionStyle     : {},
        firstYear        : -1,
        onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled       : true,
        openDrawer            : false,
        packTable1            : null,
        packTable2            : null,
    };

    componentDidMount() {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        let subnatMode = false;
        this.setState({
            packTable1 : EarlyInitEditor.Create(CSModvars, firstYear, finalYear, subnatMode),
            packTable2 : BFPrevEditor.Create(CSModvars, firstYear, finalYear, subnatMode),
        });
    }

    static getDerivedStateFromProps(props, state) {
        let CSModvars = props.CSModvars;
        let firstYear = props.firstYear;
        let finalYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        let subnatMode = false;
        if (!state.openDrawer) {
            return {
                packTable1 : EarlyInitEditor.Create(CSModvars, firstYear, finalYear, subnatMode),
                packTable2 : BFPrevEditor.Create(CSModvars, firstYear, finalYear, subnatMode),
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
    };

    onCloseDrawerBtnClick = () => {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        let subnatMode = false;
        this.setState({
            openDrawer      : false,
            saveBtnDisabled : true,
            packTable1      : EarlyInitEditor.Create(CSModvars, firstYear, finalYear, subnatMode),
            packTable2      : BFPrevEditor.Create(CSModvars, firstYear, finalYear, subnatMode),
        });
    };

    onSaveBtnClick = () => {
        let packTable1 = this.state.packTable1;
        let packTable2 = this.state.packTable2;

        let goAhead = true;

        let subnatMode = false;

        packTable2.tableData.value.forEach(function(arr) {
            arr.forEach(function(val) {
                if (typeof val === "number" && parseFloat(val.toFixed(2)) > 100) {
                    goAhead = false;
                }
            });
        });

        if (goAhead) {

            let ModvarsArray1 = EarlyInitEditor.GetSSData(packTable1, this.props.CSModvars, subnatMode);
            let ModvarsArray2 = BFPrevEditor.GetSSData(packTable2, this.props.CSModvars, subnatMode);

            let changedCSModvars = this.props.CSModvars.map(x => {
                let a = ModvarsArray1.find(y => y.tag === x.tag);
                let b = ModvarsArray2.find(y => y.tag === x.tag);
                return a || b || x;
            });

            this.setState({
                openDrawer      : false,
                saveBtnDisabled : true,
            });

            this.props.onStateChange({
                CSModvars : changedCSModvars,
                unchangedProj: false
            });
        }
        else {
            this.props.onStateChange({
                dialogErrorOpen: true,
                errorMessage: RS("GB_stValOver100")
            });
        }

    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2
                caption      = {RS('GB_stBreastfeeding')}
                captionStyle = {this.props.captionStyle}
                description  = {RS('GB_stPrevalence')}
                onClick      = {this.onBlueBoxClick}
                editContent  = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"breastfeeding.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stBreastfeeding')}
                        content         = {
                                            <CSBreastfeedingDrawer
                                                packTable1={this.state.packTable1}
                                                packTable2={this.state.packTable2}
                                                onPackTable1Change={this.onPackTable1Change}
                                                onPackTable2Change={this.onPackTable2Change}
                                            />
                                          }
                    />
                }
            />

        );
    }

}

export default CSBreastfeedingBlueBox;