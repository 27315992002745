import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    generatePackChart, add_NoHistoricalData_MsgToPackTable, setSourceArrLength
} from "utilities/GB/GBUtil";

import {CSBaselineMortStr} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const BaselineMortEditor = {
    Create : (CSModvars) => {
        let editorName = RS('GB_stBaselineChildMortality');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ChildMortality;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let BaselineMortMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_BaselineMort_MV);
        for(let c = 1; c <= CONSTANTS.CS_MaxBaselineMort; c++) {
            BaselineMortMV['value'][c] = pt.tableData.value[1][c];
            BaselineMortMV["source"][0][c] = pt.Source[pt.SourceMap[c]];
        }

        changedModvars.push(BaselineMortMV);
        return (changedModvars);
    },
};

const Setup = () => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_ColSrcEditor;

    const numRows = 2;
    const numCols = 4;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stBaselineChildMortality');

    for(let c = 1; c <= CONSTANTS.CS_MaxBaselineMort; c++) {
        pt.tableData.value[0][c] = CSBaselineMortStr(c);
        pt.Alignments[1][c] = CONSTANTS.GB_Right;
        pt.RDec[1][c] = 2;
        pt.SourceMap[c] = c;
        pt.MaxAllowedVal[1][c] = 500;
    }

    pt = setSourceArrLength(pt, CONSTANTS.CS_MaxBaselineMort);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let BaselineMort = getModvarByTag(CSModvars, CONSTANTS.CS_TG_BaselineMort_MV);
    for(let c = 1; c <= CONSTANTS.CS_MaxBaselineMort; c++) {
        pt.tableData.value[1][c] = BaselineMort["value"][c];
        pt.Source[pt.SourceMap[c]] = BaselineMort["source"][0][c];
    }
    return pt;
};

