import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class TDisconnectionWarning extends React.Component {

    static propTypes = {
        show        : PropTypes.bool,
        TimeExpire  : PropTypes.string,
        onKeepAlive : PropTypes.func,
        onLogoff    : PropTypes.func,
        language    : PropTypes.number
    };

    static defaultProps = {
        show        : false,
        TimeExpire  : '',
        onKeepAlive : () => { console.log('TDisconnectionWarning: onKeepAlive'); },
        onLogoff    : () => { console.log('TDisconnectionWarning: onLogoff'); },
        language    : CONSTANTS.GB_English
    };

    state = {
        open       : true,
        Transition : Slide
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState)) 
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    onClose = () => {
        this.setState({
            open : false
        });
    }

    render() {

        if (this.props.show) {
            return (
                <Snackbar
                    open                = {this.state.open}
                    onClose             = {this.handleClose}
                    TransitionComponent = {Slide}
                    ContentProps        = {{'aria-describedby': 'message-id'}}
                    message             = {
                                            <span id="message-id">
                                                {RS('GB_stSessionWillDiscontinue')}
                                                <br />
                                                {RS('GB_stDisconnectIn')} {this.props.TimeExpire}
                                            </span>
                                        }
                    action              = {[
                                            <Button key="keepAlive" aria-label="KeepAlive" color="inherit" onClick={this.props.onKeepAlive}>
                                                Stay connected
                                            </Button>,
                                            <Button key="logout" aria-label="LogOut" color="inherit" onClick={this.props.onLogoff}>
                                                Logout
                                            </Button>
                                        ]}
                />
            );
        }
        else {
            return null;
        }
    }
    
}

export default TDisconnectionWarning;