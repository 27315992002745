import { maximumUndoLevels } from "../other/constants";
import { clone } from "../other/utilities";

class UndoHistory {
    constructor() {
        this.history = [];
        this.latestHistoryIndex = -1;
    }

    push(packTable) {
        this.latestHistoryIndex++;

        this.history = [...this.history.slice(0, this.latestHistoryIndex), clone(packTable)].slice(-maximumUndoLevels);
    }

    undo() {
        if (this.latestHistoryIndex <= 0) {
            return;
        }

        return clone(this.history[--this.latestHistoryIndex]);
    }

    canUndo() {
        return this.latestHistoryIndex > 0;
    }

    redo() {
        if (this.history.length === this.latestHistoryIndex - 1) {
            return;
        }

        return clone(this.history[++this.latestHistoryIndex]);
    }

    canRedo() {
        return this.history.length - 1 !== this.latestHistoryIndex;
    }
}

export default UndoHistory;
