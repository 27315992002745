import React from 'react';
import PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';
import TPaper from '@common/components/TPaper';
import TLabel from '@common/components/TLabel';
import TButton from '@common/components/TButton';
import CreateAPITask from "../api/util/createAPITask";
import CONSTANTS from "utilities/CS/CSConst";

function Convert(response) {
    //var json = JSON.parse(response);
    var str = stringify(response, { indent: 4, maxLength: Infinity });
    return str;
    // document.getElementById("myJSON2").innerHTML = str;
    
    // var prettyText = syntaxHighlight(str);
    // document.getElementById("responseConverted").innerHTML = prettyText;
}

 // https://github.com/lydell/json-stringify-pretty-compact/blob/master/index.js + custom changes

 function stringify (obj, options) {
    options = options || {}
    var indent = JSON.stringify([1], null, get(options, 'indent', 2)).slice(2, -3)
    var addMargin = get(options, 'margins', false)
    var maxLength = (indent === '' ? Infinity : get(options, 'maxLength', 80))

    return (function _stringify (obj, currentIndent, reserved) {
        if (obj && typeof obj.toJSON === 'function') {
            obj = obj.toJSON()
        }

        var string = JSON.stringify(obj)

        if (string === undefined) {
            return string
        }

        var length = maxLength - currentIndent.length - reserved

        if (string.length <= length) {
            if (string[0] !== '{') {                                             // TGP
                if (!((string[0] === '[') && (string[1] === '['))) {             // TGP
                    if (!((string[0] === '[') && (string[1] === '{'))) {         // TGP
                        var prettified = prettify(string, addMargin)
                        if (prettified.length <= length) {
                            return prettified
                        }
                    }
                }
            }
        }

        if (typeof obj === 'object' && obj !== null) {
            var nextIndent = currentIndent + indent
            var items = []
            var delimiters
            var comma = function (array, index) {
                return (index === array.length - 1 ? 0 : 1)
            }

            if (Array.isArray(obj)) {
                for (var index = 0; index < obj.length; index++) {
                    items.push(
                        _stringify(obj[index], nextIndent, comma(obj, index)) || 'null'
                    )
                }
                delimiters = '[]'
            } else {
                Object.keys(obj).forEach(function (key, index, array) {
                    var keyPart = JSON.stringify(key) + ': '
                    var value = _stringify(obj[key], nextIndent, keyPart.length + comma(array, index))
                    if (value !== undefined) {
                        items.push(keyPart + value)
                    }
                })
                delimiters = '{}'
            }

            if (items.length > 0) {
                return [
                    delimiters[0],
                    indent + items.join(',\n' + nextIndent),
                    delimiters[1]
                ].join('\n' + currentIndent)
            }
        }

        return string
    }(obj, '', 0))
}

// Note: This regex matches even invalid JSON strings, but since we’re
// working on the output of `JSON.stringify` we know that only valid strings
// are present (unless the user supplied a weird `options.indent` but in
// that case we don’t care since the output would be invalid anyway).
var stringOrChar = /("(?:[^\\"]|\\.)*")|[:,\][}{]/g

function prettify (string, addMargin) {
    var m = addMargin ? ' ' : ''
    var tokens = {
        '{': '{' + m,
        '[': '[' + m,
        '}': m + '}',
        ']': m + ']',
        ',': ', ',
        ':': ': '
    }
    return string.replace(stringOrChar, function (match, string) {
        return string ? match : tokens[match]
    })
}

function get (options, name, defaultValue) {
    return (name in options ? options[name] : defaultValue)
}

// const {
//     Divider,
//     CircularProgress
// } = window['material-ui'];

class TAPIButton extends React.Component {

    static propTypes = {
        caption    : PropTypes.string,
        onClick    : PropTypes.func,
        style      : PropTypes.object
    };

    static defaultProps = {
        caption    : "",
        onClick    : () => {} ,
        style      : {}
    };

    render() {

        let styles = {
            apiButton : {
                width: 230
            }
        };

        return (
            <TButton
                caption={this.props.caption}
                onClick={this.props.onClick}
                style={{...styles.apiButton, ...this.props.style}}
            />
        );
    }
}

class GBDeveloperFm extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        heartBeat  : PropTypes.number,
        apiTasks   : PropTypes.array,
        onAddTasks : PropTypes.func,
        style      : PropTypes.object,
    };

    static defaultProps = {
        heartBeat  : 0,
        apiTasks   : [],
        onAddTasks : () => console.log('onAddTasks'),
        style      : {},
    };

    state = {
        papers : []
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onSuccess = (flag, response, task) => {

        let styles = {
            paper : {
                width: 250, 
                height: 'calc(100% - 20px)',
                display: 'inline-block', 
                overflow: 'auto',
                boxSizing: 'border-box', 
                padding: 5, 
                margin: 10, 
                background: '#beffbe' 
            }
        };  

        this.setState((prevState, props) => ({
            papers : [
                <TPaper 
                    style={styles.paper} 
                    key={task.GUID}
                    // key={prevState.papers.length}
                >
                    <p style={{fontWeight: 'bold', margin: 5}}>{flag}</p>
                    <pre style={{margin: 5, fontSize: 8}}>{Convert(response)}</pre>
                </TPaper>,
                ...prevState.papers.filter(x => (x.key !== task.GUID) ? true : false),
            ]

            // papers : [
            //     <TPaper 
            //         style={styles.paper} 
            //         key={task.GUID}
            //         // key={prevState.papers.length}
            //     >
            //         <p style={{fontWeight: 'bold', margin: 5}}>{flag}</p>
            //         {/* <pre style={{margin: 5, fontSize: 8}}>{response.toString()}</pre>  */}
            //         <pre style={{margin: 5, fontSize: 8}}>{Convert(response)}</pre>
            //     </TPaper>,
            //     ...prevState.papers
            // ]
        }));
    };

    onError = (flag, msg, task) => {
        
        let styles = {
            paper : {
                width: 250, 
                height: 'calc(100% - 20px)',
                display: 'inline-block', 
                overflow: 'auto',
                boxSizing: 'border-box', 
                padding: 5, 
                margin: 10, 
                background: '#ffcaca' 
            }
        }; 

        let message = (typeof msg === 'string') ? msg : msg.message;

        this.setState((prevState, props) => ({
            papers : [
                <TPaper 
                    style={styles.paper} 
                    key={task.GUID}
                    // key={prevState.papers.length}
                >
                    <p style={{fontWeight: 'bold', margin: 5}}>{flag}</p>
                    <pre style={{margin: 5, fontSize: 8}}>{message}</pre> 
                </TPaper>,
                ...prevState.papers.filter(x => (x.key !== task.GUID) ? true : false),
            ]
            // papers : [
            //     <TPaper 
            //         style={styles.paper} 
            //         key={task.GUID}
            //         // key={prevState.papers.length}
            //     >
            //         <p style={{fontWeight: 'bold', margin: 5}}>{flag}</p>
            //         <pre style={{margin: 5, fontSize: 8}}>{msg}</pre> 
            //     </TPaper>,
            //     ...prevState.papers
            // ]
        }));
    };

    onButtonClick = (flag, params) => {

        let styles = {
            paper : {
                width: 250, 
                height: 'calc(100% - 20px)',
                display: 'inline-block', 
                overflow: 'auto',
                boxSizing: 'border-box', 
                padding: 5, 
                margin: 10, 
                background: '#eeeeee' 
            }
        };  

        let task = CreateAPITask(flag, params, () => {}, () => {});

        this.setState((prevState, props) => ({
            papers : [
                <TPaper 
                    style={styles.paper} 
                    key={task.GUID}
                    // key={prevState.papers.length}
                >
                    <p style={{fontWeight: 'bold', margin: 5}}>{flag}</p>
                    <CircularProgress size={100} style={{marginTop: '10', marginBottom: '10', margin: '0 auto', display: 'block'}} />
                </TPaper>,
                ...prevState.papers.filter(x => (x.key !== task.GUID) ? true : false),
            ]
            // papers : [
            //     ...prevState.papers,
            //     <TPaper 
            //         style={styles.paper} 
            //         key={task.GUID}
            //         // key={prevState.papers.length}
            //     >
            //         <p style={{fontWeight: 'bold', margin: 5}}>{flag}</p>
            //         <CircularProgress size={100} style={{marginTop: '10', marginBottom: '10', margin: '0 auto', display: 'block'}} />
            //     </TPaper>
            // ]
        }));

        this.props.onAddTasks([
            CreateAPITask(flag, params, (response) => this.onSuccess(flag, response, task), (msg) => this.onError(flag, msg, task))
        ]);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        let styles = {
            topDiv : {
                width: '100%'
            },
            clear : {
                clear : 'both'
            },
            label : {
                float: 'left',
                marginRight: 10
            },
            apiButtonsDiv : {
                position: 'absolute',
                bottom: 40, 
                top: 75, 
                left: 20, 
                width: 260, 
                overflow: 'auto'
            },
            apiButton : {
                width: 230
            },
            papersDiv : {
                position: 'absolute', 
                bottom: 40, 
                top: 75, 
                left: 300, 
                right: 0,
                whiteSpace: 'nowrap', 
                overflowX: 'auto'
            },
            badButton : {
                background: 'red'
            }
        };

        let heartBeat = this.props.heartBeat;
        let running = this.props.apiTasks.filter(x => (x.running === true)).length;
        let complete = this.props.apiTasks.filter(x => (x.complete === true)).length;
        let success = this.props.apiTasks.filter(x => (x.success === true)).length;
        let error = this.props.apiTasks.filter(x => (x.error === true)).length;
        let fail = this.props.apiTasks.filter(x => (x.fail === true)).length;

        // let sentTime = this.props.apiTasks.map(x => 'Sent: ' + x.sentTime).join("<br>");
        // let returnTime = this.props.apiTasks.map(x => 'Returned: ' + x.returnTime).join("/n");
        // let textarea = sentTime + returnTime;
        //console.log(textarea);

        let 
            username  = '', 
            password  = '',
            modvarArr = [{proj: 1, modID: 1, tag: '<BigPop MV3>'}],
            // editorArr = [{ proj : 1, modID : 4, editorMstID : 10 }],
            resultsArr = [{ modID : 1, mstIndID : 1 }],
            createProjObj = {
                CountryISO : 4,
                FirstYear : 1970,
                FinalYear : 2020,
                FileName : "MooMooProjectionName",
                ModList : [1, 4, 15],
                AgeGroupOption : CONSTANTS.FP_Single_Age_Group,
            },
            modifyProjObj = {
                Proj: 1,
                FileTitle : "CowCowProjectionName",
                FinalYear : 2025,
                CountryISO : 404,
                ModList : [
                    {
                        modID: 1,
                        addModule: false,
                        closeModule: false,
                        reloadModule: true
                    },{
                        modID: 4,
                        addModule: false,
                        closeModule: false,
                        reloadModule: true
                    },{
                        modID: 15,
                        addModule: false,
                        closeModule: false,
                        reloadModule: true
                    }
                ]
            },
            FileTitle = 'TestSeptermber2018',
            Proj = 1,
            FileTitle2 = 'MooMooProjectionName';

        return (
            <div style={this.props.style}>
                <div>API calls</div>

                <div style={styles.topDiv}>
                    <TLabel style={styles.label} caption={"heartBeat : " + heartBeat} />
                    <TLabel style={styles.label} caption={"running : " + running} />
                    <TLabel style={styles.label} caption={"complete : " + complete} />
                    <TLabel style={styles.label} caption={"success : " + success} />
                    <TLabel style={styles.label} caption={"error : " + error} />
                    <TLabel style={styles.label} caption={"fail : " + fail} />
                </div>

                <div style={styles.clear}></div>

                <div style={styles.apiButtonsDiv}>

                    <p>RESTService</p>
                    
                    <TAPIButton caption="ping"                      onClick={() => this.onButtonClick('ping', { modID : 15 })} />
                    <TAPIButton caption="getCountries"              onClick={() => this.onButtonClick('getCountries', { modID : 15 })} />
                    <TAPIButton caption="getVersion"                onClick={() => this.onButtonClick('getVersion', { modID : 15 })} />

                    <p>LoginService</p>

                    <TAPIButton caption="getStatus"                 onClick={() => this.onButtonClick('getStatus')} />
                    <TAPIButton caption="signedIn"                  onClick={() => this.onButtonClick('signedIn')} />
                    <TAPIButton caption="login"                     onClick={() => this.onButtonClick('login', { username: username, password: password })} />
                    <TAPIButton caption="loginAsGuest"              onClick={() => this.onButtonClick('loginAsGuest')} />
                    <TAPIButton caption="logout"                    onClick={() => this.onButtonClick('logout')} />
                    <TAPIButton caption="getAccountInfo"            onClick={() => this.onButtonClick('getAccountInfo')} />
                    
                    <p>SpectrumService</p>

                    <TAPIButton caption="keepAlive"                 onClick={() => this.onButtonClick('keepAlive')} />
                    <TAPIButton caption="getEverything"             onClick={() => this.onButtonClick('getEverything')} />
                    <TAPIButton caption="getActiveProjectionList"   onClick={() => this.onButtonClick('getActiveProjectionList')} />
                    <TAPIButton caption="getStoredProjectionList"   onClick={() => this.onButtonClick('getStoredProjectionList')} />
                    <TAPIButton caption="getGBData"                 onClick={() => this.onButtonClick('getGBData')} />

                    <p>SpectrumService</p>

                    <TAPIButton caption="createProjection"          onClick={() => this.onButtonClick('createProjection', createProjObj)} />
                    <TAPIButton caption="modifyProjection"          onClick={() => this.onButtonClick('modifyProjection', modifyProjObj)} />
                    <TAPIButton caption="openProjection"            onClick={() => this.onButtonClick('openProjection', { FileTitle : FileTitle })} />
                    <TAPIButton caption="saveProjection"            onClick={() => this.onButtonClick('saveProjection', { Proj : Proj, FileTitle : FileTitle2 })} />
                    <TAPIButton caption="closeProjection"           onClick={() => this.onButtonClick('closeProjection', { Proj : Proj })} />
                    <TAPIButton caption="downloadProjection"        onClick={() => this.onButtonClick('downloadProjection', { FileTitle : FileTitle2 })} style={styles.badButton} />
                    <TAPIButton caption="deleteProjection"          onClick={() => this.onButtonClick('deleteProjection', { FileTitle : FileTitle2 })} />
                    <TAPIButton caption="calculateProjection"       onClick={() => this.onButtonClick('calculateProjection', { Proj: 0 })} />

                    <p>SpectrumService</p>

                    <TAPIButton caption="getModvars"                onClick={() => this.onButtonClick('getModvars', { arr : modvarArr })} />
                    <TAPIButton caption="setModvars"                onClick={() => this.onButtonClick('setModvars', { arr : [] })} />
                    {/*<TAPIButton caption="getEditors"                onClick={() => this.onButtonClick('getEditors', { arr : editorArr })} /> */}
                    {/*<TAPIButton caption="setEditors"                onClick={() => this.onButtonClick('setEditors', { arr : [] })} />*/}
                    <TAPIButton caption="getResultsData"            onClick={() => this.onButtonClick('getResultsData', { arr : resultsArr })} />
                    
                    <p>SpectrumService</p>

                    <TAPIButton caption="getResultMenus"            onClick={() => this.onButtonClick('getResultMenus')} />
                    <TAPIButton caption="getResultTable"            onClick={() => this.onButtonClick('getResultTable')} style={styles.badButton} />
                    <TAPIButton caption="getResultTables"           onClick={() => this.onButtonClick('getResultTables')} style={styles.badButton} />
                    <TAPIButton caption="getResultCharts"           onClick={() => this.onButtonClick('getResultCharts')} style={styles.badButton} />

                    <p>Custom flags</p>

                    <TAPIButton caption="fetchSession"              onClick={() => this.onButtonClick('fetchSession')} />
                    <TAPIButton caption="fetchSessionInfo"          onClick={() => this.onButtonClick('fetchSessionInfo')} />
                    <TAPIButton caption="fetchSessionGeneral"       onClick={() => this.onButtonClick('fetchSessionGeneral')} />
                    <TAPIButton caption="fetchSessionInputs"        onClick={() => this.onButtonClick('fetchSessionInputs', { firstProj : 1, finalProj: 1 })} />

                    {/* -------------------------------------------------------- */}

                    <TAPIButton caption="fetchSessionResults"       onClick={() => this.onButtonClick('fetchSessionResults', { finalProj: 1 })} />
                    <TAPIButton caption="sendSession"               onClick={() => this.onButtonClick('sendSession', { firstProj : 1, finalProj : 1 })} />
                    <TAPIButton caption="sendAndFetchSession"       onClick={() => this.onButtonClick('sendAndFetchSession', { FirstProj : 1, FinalProj : 1 })} />

                    {/* -------------------------------------------------------- */}

                    <TAPIButton caption="createProjectionFlag"      onClick={() => this.onButtonClick('createProjectionFlag', createProjObj)} />
                    <TAPIButton caption="openProjectionFlag"        onClick={() => this.onButtonClick('openProjectionFlag', { FileTitle : "MooMooProjectionName" })} style={styles.badButton} />
                    <TAPIButton caption="saveProjectionFlag"        onClick={() => this.onButtonClick('saveProjectionFlag', { Proj : 1, FileTitle : "MooMooProjectionName" })} style={styles.badButton} />
                    <TAPIButton caption="closeProjectionFlag"       onClick={() => this.onButtonClick('closeProjectionFlag', { Proj : Proj })} />
                    <TAPIButton caption="deleteProjectionFlag"      onClick={() => this.onButtonClick('deleteProjectionFlag', { FileTitle : 'MooMooProjectionName' })} />
                    <TAPIButton caption="calculateProjectionFlag"   onClick={() => this.onButtonClick('calculateProjectionFlag', { Proj : 0 })} />
                    <TAPIButton caption="downloadProjectionFlag"    onClick={() => this.onButtonClick('downloadProjectionFlag', { FileTitle  : 'MooMooProjectionName' })} style={styles.badButton} />

                    {/* -------------------------------------------------------- */}
                
                </div>

                <div style={styles.papersDiv}>
                    {this.state.papers}
                </div>

                {/* <hr />s

                <textarea 
                    style={{width: '40%', margin: '10%', minHeight: '100px', display: 'inline-block'}}
                    value={sentTime}
                    readOnly={true}
                />

                <textarea 
                    style={{width: '40%', margin: '10%', minHeight: '100px', display: 'inline-block'}}
                    value={returnTime}
                    readOnly={true}
                /> */}

            </div>
        );
    }
}

export default GBDeveloperFm;