import { fieldNamePrefix, maximumDecimalPlaces } from "../other/constants";
import { fieldToColumnIndex, isNumeric, createMatrix } from "../other/utilities";

const calculateTotalRow = packTable => {
    if (!packTable.TotalRow) {
        return;
    }

    const data = packTable.tableData.value;

    if (!data.length) {
        return;
    }

    const totalRow = Array(data[0].length).fill(0);

    for (let c = packTable.GBFixedCols; c < data[0].length; c++) {
        for (let r = packTable.GBFixedRows; r < data.length - 1; r++) {
            if (isNumeric(data[r][c])) {
                totalRow[c] += parseFloat(data[r][c]);
            }
        }
    }

    //   data.slice(0, -1).forEach(row => {
    //     for (let c = packTable.GBFixedCols; c < row.length; c++) {
    //       if (isNumeric(row[c])) {
    //         totalRow[c] += row[c];
    //       }
    //     }
    //   });

    return totalRow;
};

const calculateTotalColumn = packTable => {
    if (!packTable.TotalCol) {
        return;
    }

    const data = packTable.tableData.value;

    if (!data.length) {
        return;
    }

    return data
        .slice(packTable.GBFixedRows)
        .map(row =>
            row.slice(packTable.GBFixedCols, -1).reduce((result, value) => result + (isNumeric(value) ? value : 0), 0)
        );
};

const expandRowRangeIfRequired = (selectedRange, packTable) => {
    let { firstRowIndex, lastRowIndex } = selectedRange;

    if (firstRowIndex < lastRowIndex) {
        return selectedRange;
    }

    return {
        ...selectedRange,
        firstRowIndex: packTable.GBFixedRows,
        lastRowIndex: packTable.GBRowCount - (packTable.TotalRow ? 1 : 0) - 1
    };
};

const expandColumnRangeIfRequired = (selectedRange, packTable) => {
    if (selectedRange.columnIds.length !== 1) {
        return selectedRange;
    }

    const columnIds = Array.from(
        {
            length: packTable.GBColCount - packTable.GBFixedCols - (packTable.TotalCol ? 1 : 0)
        },
        (_, i) => `${fieldNamePrefix}${packTable.GBFixedCols + i + 1}`
    );

    return {
        ...selectedRange,
        columnIds
    };
};

const interpolateVector = vector => {
    const numericVector = vector.filter(isNumeric);

    if (!numericVector.length) {
        return vector;
    }

    const delta = (numericVector[numericVector.length - 1] - numericVector[0]) / (numericVector.length - 1);

    const interpolatedVector = [];

    let currentValue = numericVector[0];

    for (let i = 0; i < vector.length; ++i) {
        if (isNumeric(vector[i])) {
            interpolatedVector.push(currentValue);
            currentValue += delta;
        } else {
            interpolatedVector.push(vector[i]);
        }
    }

    return interpolatedVector;
};

const normalizeVector = vector => {
    const numericVector = vector.filter(isNumeric);

    if (!numericVector.length) {
        return vector;
    }

    const total = Math.abs(numericVector.reduce((result, value) => result + value, 0));

    if (total < Number.EPSILON) {
        return vector;
    }

    const normalizedVector = [];

    for (let i = 0; i < vector.length; ++i) {
        if (isNumeric(vector[i])) {
            normalizedVector.push((vector[i] / total) * 100);
        } else {
            normalizedVector.push(vector[i]);
        }
    }

    return normalizedVector;
};

export const recalculateTotals = packTable => {
    let data = packTable.tableData.value;

    if (packTable.TotalRow) {
        const totalRow = calculateTotalRow(packTable).map((value, index) =>
            index < packTable.GBFixedCols ? data[data.length - 1][index] : value
        );

        data = [...data.slice(0, data.length - 1), totalRow];
    }

    if (packTable.TotalCol) {
        const totalColumn = calculateTotalColumn(packTable);

        const newData = [];

        for (let r = 0; r < data.length; r++) {
            if (r < packTable.GBFixedRows) {
                newData.push(data[r]);
            } else {
                newData.push([...data[r].slice(0, -1), totalColumn[r]]);
            }
        }

        data = newData;
    }

    return {
        ...packTable,
        tableData: {
            value: data
        }
    };
};

export const incrementPrecision = (params, packTable, by = 1) => {
    if (typeof packTable.RDec === "undefined") {
        packTable.RDec = createMatrix(packTable.GBRowCount, packTable.GBColCount, 0);
    }

    const decimalPlaces = packTable.RDec.map(row => [...row]);

    for (let r = params.firstRowIndex; r <= params.lastRowIndex; r++) {
        params.columnIds.forEach(columnId => {
            const columnIndex = fieldToColumnIndex(columnId);

            let cellDecimalPlaces = decimalPlaces[r][columnIndex] + by;

            cellDecimalPlaces = Math.min(Math.max(cellDecimalPlaces, 0), maximumDecimalPlaces);

            // noinspection JSValidateTypes
            decimalPlaces[r][columnIndex] = cellDecimalPlaces;
        });
    }

    return {
        ...packTable,
        RDec: decimalPlaces
    };
};

export const duplicateAcrossRows = (params, packTable) => {
    const selectedRange = expandColumnRangeIfRequired(params, packTable);

    const columnIndices = selectedRange.columnIds.map(columnId => fieldToColumnIndex(columnId));

    const sourceColumnIndex =
        params.columnIds.length !== 1 ? Math.min(...columnIndices) : fieldToColumnIndex(params.columnIds[0]);

    const data = packTable.tableData.value;
    const newData = data.map(row => [...row]);

    for (let r = params.firstRowIndex; r <= params.lastRowIndex; r++) {
        columnIndices.forEach(c => {
            if (isNumeric(newData[r][c])) {
                newData[r][c] = data[r][sourceColumnIndex];
            }
        });
    }

    return recalculateTotals({
        ...packTable,
        tableData: {
            value: newData
        }
    });
};

export const duplicateDownColumns = (params, packTable) => {
    const selectedRange = expandRowRangeIfRequired(params, packTable);

    const { firstRowIndex, lastRowIndex } = selectedRange;

    const data = packTable.tableData.value;
    const newData = data.map(row => [...row]);

    for (let r = firstRowIndex; r <= lastRowIndex; r++) {
        params.columnIds.forEach(columnId => {
            const columnIndex = fieldToColumnIndex(columnId);

            if (isNumeric(newData[r][columnIndex])) {
                newData[r][columnIndex] = data[params.firstRowIndex][columnIndex];
            }
        });
    }

    return recalculateTotals({
        ...packTable,
        tableData: {
            value: newData
        }
    });
};

export const interpolate = (params, packTable) => {
    const selectedRange =
        params.columnIds.length === 1 && params.firstRowIndex < params.lastRowIndex
            ? params
            : expandColumnRangeIfRequired(params, packTable);

    const { firstRowIndex, lastRowIndex } = selectedRange;

    const data = packTable.tableData.value;
    const newData = data.map(row => [...row]);

    if (selectedRange.columnIds.length !== 1) {
        const columnIndices = selectedRange.columnIds.map(columnId => fieldToColumnIndex(columnId));

        for (let r = firstRowIndex; r <= lastRowIndex; r++) {
            const vector = columnIndices.map(columnIndex => data[r][columnIndex]);

            const interpolatedVector = interpolateVector(vector);

            columnIndices.forEach(columnIndex => {
                newData[r][columnIndex] = interpolatedVector[columnIndex - columnIndices[0]];
            });
        }
    } else {
        const columnIndex = fieldToColumnIndex(params.columnIds[0]);

        const vector = [];

        for (let r = firstRowIndex; r <= lastRowIndex; r++) {
            vector.push(data[r][columnIndex]);
        }

        const interpolatedVector = interpolateVector(vector);

        for (let r = firstRowIndex; r <= lastRowIndex; r++) {
            newData[r][columnIndex] = interpolatedVector[r - firstRowIndex];
        }
    }

    return recalculateTotals({
        ...packTable,
        tableData: {
            value: newData
        }
    });
};

export const normalize = (params, packTable) => {
    const selectedRange =
        params.firstRowIndex === params.lastRowIndex && params.columnIds.length > 1
            ? params
            : expandRowRangeIfRequired(params, packTable);

    const { firstRowIndex, lastRowIndex } = selectedRange;

    const data = packTable.tableData.value;
    const newData = data.map(row => [...row]);

    if (firstRowIndex < lastRowIndex) {
        selectedRange.columnIds.forEach(columnId => {
            const columnIndex = fieldToColumnIndex(columnId);

            const vector = [];

            for (let r = firstRowIndex; r <= lastRowIndex; r++) {
                vector.push(data[r][columnIndex]);
            }

            const normalizedVector = normalizeVector(vector);

            for (let r = firstRowIndex; r <= lastRowIndex; r++) {
                newData[r][columnIndex] = normalizedVector[r - firstRowIndex];
            }
        });
    } else {
        const columnIndices = selectedRange.columnIds.map(columnId => fieldToColumnIndex(columnId));

        for (let r = firstRowIndex; r <= lastRowIndex; r++) {
            const vector = columnIndices.map(columnIndex => data[r][columnIndex]);

            const normalizedVector = normalizeVector(vector);

            columnIndices.forEach(columnIndex => {
                newData[r][columnIndex] = normalizedVector[columnIndex - columnIndices[0]];
            });
        }
    }

    return recalculateTotals({
        ...packTable,
        tableData: {
            value: newData
        }
    });
};

export const multiply = (params, packTable) => {
    const { firstRowIndex, lastRowIndex, columnIds, multiplier } = params;

    const data = packTable.tableData.value;
    const newData = data.map(row => [...row]);

    columnIds.forEach(columnId => {
        const c = fieldToColumnIndex(columnId);

        for (let r = firstRowIndex; r <= lastRowIndex; r++) {
            let value = data[r][c];
            if (isNumeric(value)) {
                let newValue = data[r][c] * multiplier;
                let minAllowed = packTable.MinAllowedVal[r][c];
                let maxAllowed = packTable.MaxAllowedVal[r][c];

                if (newValue >= minAllowed && newValue <= maxAllowed) {
                    newData[r][c] = value * multiplier;
                } else {
                    alert(`Value must be between ${minAllowed} and ${maxAllowed}`);
                    return {
                        error: true,
                        data: undefined
                    };
                }
            }
        }
    });

    return {
        error: false,
        data: newData
    };
};
