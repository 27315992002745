import RS from "@common/strings/RS";
import {
    getModvarCloneByTag,
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
    addRowToPackTable,
    addMergedCellsToArray,
    lockPackTable,
    lockPackRowCol,
    indentPackTable,
    getIdxFromYear,
    CloneObj, generatePackChart,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import {
    getCSIvRs,
    CSGetMstIDName,
    CSGetAbbrevQualityMeasureStr,
    CSGetVaccCurrID,
    CSGetDetVaccEquivDose,
    CSGetUtilizationIV,
    FindIntervRec,
    DirectEntryMstIDSet,
    getIntervRec
} from "utilities/CS/CSDataUtil";

import {CSGetLiSTOnlineGroupStr, CSGetGroupMstIDFromLiSTOnlineID} from "utilities/CS/CSUtil";

import CS_CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const CoverageEditor = {
    HeaderRowMstID   : 0,
    QualityRowMstID  : 1,
    CoverageRowMstID : 2,
    CalcCovRowMstID  : 3,

    Create : (IVInfo, CSModvars, language, EditorStartYear, EditorEndYear, QualityMode, exploreData, showRetroYears, SubnatMode) => {
        let editorName = (SubnatMode) ? '' : RS('GB_stInterventionCov');
        let CalcCov_MV = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_CalcCovFromInputs_MV2);
        let PentaAutoCalc = CalcCov_MV.value.intArray.includes(CS_CONSTANTS.CS_Pentavalent_CCFI);
        let packTable = Setup(IVInfo, CSModvars, language, EditorStartYear, EditorEndYear, QualityMode, PentaAutoCalc, exploreData, showRetroYears, SubnatMode);
        packTable = CoverageEditor.SetSSData(packTable, CSModvars, language, PentaAutoCalc, exploreData, SubnatMode);
        packTable = CoverageEditor.RecalcGrid(packTable, PentaAutoCalc);
        if (exploreData) {
            packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        }
        packTable.Title = editorName;
        packTable.ID = CS_CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID;
        let packChart = generatePackChart(packTable, packTable.noShowRowSet);
        let intervObjArr = generateIntervObjArr(packTable, language, EditorStartYear, EditorEndYear);
        packTable.EditorCat = CS_CONSTANTS.GB_Coverage;
        return ([packChart, packTable, intervObjArr]);
    },

    SetSSData : (pt, CSModvars, language, PentaAutoCalc, exploreData, SubnatMode) => {
        let coverageSrc = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_Coverage_MV3)["source"];

        let FirstTimeEnteringCoverage = {};
        let SubnatDefDataMstIDset = {};
        let SubnatDataSourceArray = {};

        if (SubnatMode){
            FirstTimeEnteringCoverage = getModvarByTag(CSModvars, CS_CONSTANTS.VW_TG_TFirstTimeEnteringCoverage);
            SubnatDefDataMstIDset = getModvarByTag(CSModvars, CS_CONSTANTS.VW_TG_TSubnatDefDataMstIDset);
            SubnatDataSourceArray = getModvarByTag(CSModvars, CS_CONSTANTS.VW_TG_TSubnatDataSourceArray);
        }

        for (let row = 2; row < pt.RowIds.length; row++) {
            let IntervRec = pt.IntervRecords[row];
            if (IntervRec !== null) {
                let vt = CSGetVaccCurrID(IntervRec["MstID"]);
                if (!SubnatMode) {
                    let col = 1;
                    for (let yr = 0; yr < pt.yearArr.length; yr++) {
                        let t = pt.yearArr[yr];
                        pt = SetValue(pt, CSModvars, language, IntervRec, exploreData, vt, col, row, t);
                        col++;
                    }
                    pt.Source[pt.SourceMap[row]] = coverageSrc[0][IntervRec["CurrID"]];
                }
                else {
                    pt = SetValue(pt, CSModvars, language, IntervRec, exploreData, vt, 1, row, pt.yearArr[0]);

                    /* Under appropriate circumstances, set the first year (national)
                       coverage as the second year (subnational) coverage as well. */
                    if (FirstTimeEnteringCoverage["value"] && !SubnatDefDataMstIDset["value"]["intArray"].includes(IntervRec["MstID"])){
                        pt = SetValue(pt, CSModvars, language, IntervRec, exploreData, vt, 2, row, pt.yearArr[0]);
                    }
                    else{
                        pt = SetValue(pt, CSModvars, language, IntervRec, exploreData, vt, 2, row, pt.yearArr[1]);
                    }
                    // if (pt.tableData.value[row][1] !== pt.tableData.value[row][2]){
                    //     pt["FontColors"][row][2] = 12745394;
                    // }
                    pt.Source[pt.SourceMap[row]] = SubnatDataSourceArray["value"][IntervRec["MstID"]];
                }

                if (pt.UpdatePentaCov && (IntervRec["MstID"] === CS_CONSTANTS.IV_MstPentaVacc)) {
                    pt.PentaCov = CloneObj(pt.tableData.value[row]);
                    if (!PentaAutoCalc) {
                        let c = 1;
                        for (let yr = 0; yr < pt.yearArr.length; yr++) {
                            pt.tableData.value[row][c] = '';
                            c++;
                        }
                    }
                    pt.UpdatePentaCov = false;
                }
            }
            else{
                let c = 1;
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    pt.tableData.value[row][c] = '';
                    c++;
                }
            }
        }
        return pt;
    },

    GetSSData : (pt, CSModvars, language, PentaAutoCalc, SubnatMode) => {
        let changedModvars = [];
        let coverageMV = {};
        let vaccinesMV = {};
        let pentaMV = {};
        let CPRMV = {};
        let FirstTimeEnteringCoverage = {};
        if (SubnatMode) {
            changedModvars = CloneObj(CSModvars);
            coverageMV = getModvarByTag(changedModvars, CS_CONSTANTS.CS_TG_Coverage_MV3);
            vaccinesMV = getModvarByTag(changedModvars, CS_CONSTANTS.CS_TG_RoutDetVaccCov_MV3);
            pentaMV = getModvarByTag(changedModvars, CS_CONSTANTS.CS_TG_PentavalentDetVaccCov_MV);
            CPRMV = getModvarByTag(changedModvars, CS_CONSTANTS.FP_TG_Tprevalence_MV);
            FirstTimeEnteringCoverage = getModvarByTag(changedModvars, CS_CONSTANTS.VW_TG_TFirstTimeEnteringCoverage);
            FirstTimeEnteringCoverage["value"] = false;
        }
        else {
            coverageMV = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_Coverage_MV3);
            vaccinesMV = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_RoutDetVaccCov_MV3);
            pentaMV = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_PentavalentDetVaccCov_MV);
            CPRMV = getModvarCloneByTag(CSModvars, CS_CONSTANTS.FP_TG_Tprevalence_MV);
        }

        for (let row = 2; row < pt.RowIds.length; row++) {
            let IntervRec = pt.IntervRecords[row];
            if (IntervRec !== null){
                let vt = CSGetVaccCurrID(IntervRec["MstID"]);
                let col = 1;
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let t = pt.yearArr[yr];
                    let value = pt.tableData.value[row][col];
                    if (IntervRec["MstID"] === CS_CONSTANTS.CS_MstCPR){
                        CPRMV["value"][CS_CONSTANTS.FP_All_Ages][CS_CONSTANTS.FP_all_need][t] = value;
                    }
                    else {
                        if(IntervRec["Names"][language] === RS('GB_stPentavalent')){
                            if (value !== '') {
                                if (t < 1) {
                                    pentaMV["retro"][t + 3] = value;
                                } else {
                                    pentaMV["value"][t] = value;
                                }
                            }
                        }
                        else if (vt !== 0){
                            if (t < 1){
                                vaccinesMV["retro"][vt][CSGetDetVaccEquivDose(vt)][t+3] = value;
                            }
                            else{
                                vaccinesMV["value"][vt][CSGetDetVaccEquivDose(vt)][t] = value;
                            }
                        }
                        else{
                            if (![CS_CONSTANTS.IV_IPT_Mal, CS_CONSTANTS.IV_MalCaseMgmt].includes(IntervRec["MstID"]) || pt.IPTpCBChecked) {
                                switch (pt.RowIds[row]) {
                                    case CoverageEditor.QualityRowMstID  : {
                                        coverageMV["quality"][IntervRec["CurrID"]][t] = value;
                                        break;
                                    }
                                    case CoverageEditor.CoverageRowMstID : {
                                        coverageMV["value"][IntervRec["CurrID"]][t] = value;
                                        // let qualityValue = GetQualityFromCalcCovRow(pt, row, col, CSModvars, t, IntervRec);
                                        // if (qualityValue > -1) {
                                        //     coverageMV["quality"][IntervRec["CurrID"]][a][t] = qualityValue;
                                        // }
                                        break;
                                    }
                                    case CoverageEditor.CalcCovRowMstID  : {
                                        coverageMV["value"][IntervRec["CurrID"]][t] = value;
                                        break;
                                    }
                                    default :
                                        break;
                                }
                            }
                        }
                    }
                    col++;
                }

                if (IntervRec["MstID"] === CS_CONSTANTS.IV_MstPentaVacc){
                    if(!PentaAutoCalc){
                        let col = 1;
                        for (let yr = 0; yr < pt.yearArr.length; yr++){
                            let t = pt.yearArr[yr];
                            coverageMV["value"][IntervRec["CurrID"]][t] = CloneObj(pt.PentaCov[col]);
                            col++;
                        }
                    }
                }
                if (!SubnatMode) {
                    coverageMV["source"][0][IntervRec["CurrID"]] = pt.Source[pt.SourceMap[row]];
                }
            }
        }

        if (!SubnatMode) {
            changedModvars.push(coverageMV);
            changedModvars.push(vaccinesMV);
            changedModvars.push(pentaMV);
            changedModvars.push(CPRMV);
        }

        return (changedModvars);
    },

    // GetSSData : (pt, CSModvars, PentaAutoCalc) => {
    //     let changedModvars = [];
    //
    //     let coverageMV    = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_Coverage_MV2);
    //     let qualityMV     = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_Coverage_MV2);
    //     let vaccinesMV    = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_RoutDetVaccCov_MV3);
    //     let CPRMV         = getModvarCloneByTag(CSModvars, CS_CONSTANTS.FP_TG_Tprevalence_MV);
    //
    //     for (let row = 2; row < pt.RowIds.length; row++) {
    //         let IntervRec = pt.IntervRecords[row];
    //         if (IntervRec !== null){
    //             let vt = CSGetVaccCurrID(IntervRec["MstID"]);
    //             let col = 1;
    //             for (let yr = 0; yr < pt.yearArr.length; yr++){
    //                 let t = pt.yearArr[yr];
    //                 let value = pt.tableData.value[row][col];
    //                 if (IntervRec["MstID"] === CS_CONSTANTS.CS_MstCPR){
    //                     CPRMV["value"][CS_CONSTANTS.FP_All_Ages][CS_CONSTANTS.FP_All_Need][t] = value;
    //                 }
    //                 else {
    //                     if (vt !== 0){
    //                         vaccinesMV["value"][vt][CSGetDetVaccEquivDose(vt)][t] = value;
    //                     }
    //                     else{
    //                         let a = CS_CONSTANTS.CS_AgeSummary;
    //                         switch(pt.RowIds[row]){
    //                             case CoverageEditor.QualityRowMstID  : {qualityMV["quality"][IntervRec["CurrID"]][a][t] = value; break;}
    //                             case CoverageEditor.CoverageRowMstID : {
    //                                 coverageMV["value"][IntervRec["CurrID"]][a][t] = value;
    //                                 // let qualityValue = GetQualityFromCalcCovRow(pt, row, col, CSModvars, t, IntervRec);
    //                                 // if (qualityValue > -1) {
    //                                 //     qualityMV["quality"][IntervRec["CurrID"]][a][t] = qualityValue;
    //                                 // }
    //                                 break;
    //                             }
    //                             case CoverageEditor.CalcCovRowMstID  : {coverageMV["value"][IntervRec["CurrID"]][a][t] = value; break;}
    //                             default : break;
    //                         }
    //                     }
    //                 }
    //                 col++;
    //             }
    //
    //             if (IntervRec["MstID"] === CS_CONSTANTS.IV_MstPentaVacc){
    //                 if(!PentaAutoCalc){
    //                     let col = 1;
    //                     for (let yr = 0; yr < pt.yearArr.length; yr++){
    //                         let t = pt.yearArr[yr];
    //                         coverageMV["value"][IntervRec["CurrID"]][CS_CONSTANTS.CS_AgeSummary][t] = CloneObj(pt.PentaCov[col]);
    //                         col++;
    //                     }
    //                 }
    //             }
    //             if (vt !== 0) {
    //                 vaccinesMV["source"][0][IntervRec["CurrID"]] = pt.Source[pt.SourceMap[row]];
    //             }
    //             else {
    //                 coverageMV["source"][0][IntervRec["CurrID"]] = pt.Source[pt.SourceMap[row]];
    //             }
    //         }
    //     }
    //
    //     changedModvars.push(coverageMV);
    //     changedModvars.push(qualityMV);
    //     changedModvars.push(vaccinesMV);
    //     changedModvars.push(CPRMV);
    //
    //     return (changedModvars);
    // },

    UpdatePentaCovArr : (pt) =>{
        pt.PentaCov = CloneObj(pt.tableData.value[pt.PentaRow]);
        return (pt);
    },

    UpdatePentaRows : (pt, PentaAutoCalc, onCBClick = true) => {
        if (pt.PentaRow !== -1) {

            let PentaRequiredIntervSet = pt.IntervRecords[pt.PentaRow]["RequiredIntervs"]["intArray"];

            if (PentaAutoCalc) {
                if (onCBClick) {
                    pt.tableData.value[pt.PentaRow] = CloneObj(pt.PentaCov);
                    pt.UpdatePentaCov = true;
                }

                pt = lockPackTable(pt, pt.PentaRow, false);
                // pt = lockPackTable(pt, pt.PentaRow+1, true);
                // pt = lockPackTable(pt, pt.PentaRow+2, true);
                // pt = lockPackTable(pt, pt.PentaRow+3, true);

                for (let r = 2; r < pt.RowIds.length; r++) {
                    let IntervRec = pt.IntervRecords[r];
                    if (IntervRec !== null) {
                        if (PentaRequiredIntervSet.includes(IntervRec["MstID"])) {
                            pt = lockPackTable(pt, r, true);
                            for (let c = 1; c < pt.GBColCount; c++) {
                                pt.tableData.value[r][c] = pt.tableData.value[pt.PentaRow][c];
                            }
                        }
                    }
                }
            }
            else {
                if (onCBClick && pt.UpdatePentaCov) {
                    pt.PentaCov = CloneObj(pt.tableData.value[pt.PentaRow]);
                    pt.UpdatePentaCov = false;
                }

                pt = lockPackTable(pt, pt.PentaRow, true);
                // pt = lockPackTable(pt, pt.PentaRow+1, false);
                // pt = lockPackTable(pt, pt.PentaRow+2, false);
                // pt = lockPackTable(pt, pt.PentaRow+3, false);

                for (let c = 1; c < pt.GBColCount; c++) {
                    pt.tableData.value[pt.PentaRow][c] = '';
                }

                for (let r = 2; r < pt.RowIds.length; r++) {
                    let IntervRec = pt.IntervRecords[r];
                    if (IntervRec !== null) {
                        if (PentaRequiredIntervSet.includes(IntervRec["MstID"])) {
                            pt = lockPackTable(pt, r, false);
                        }
                    }
                }
            }
        }

        return (pt);
    },

    RecalcGrid : (pt, PentaAutoCalc) => {
        for (let i = 0; i < pt.CalcCovRowSet.length; i++){
            let CalcCovRow = pt.CalcCovRowSet[i];
            let IntervRec = pt.IntervRecords[CalcCovRow];
            if (IntervRec !== null) {
                if (![CS_CONSTANTS.IV_IPT_Mal, CS_CONSTANTS.IV_MalCaseMgmt].includes(IntervRec["MstID"]) || pt.IPTpCBChecked) {
                    pt = SetCalcCovRow(pt, CalcCovRow, IntervRec);
                }
            }
        }

        for (let i = 0; i < pt.SumTotalRowSet.length; i++){
            let sumRow = pt.SumTotalRowSet[i];
            for (let c = 1; c < pt.GBColCount; c++) {
                pt.tableData.value[sumRow][c] = "";
            }

            let flag = new Array(pt.GBColCount);
            for (let c = 1; c < pt.GBColCount; c++) {
                flag[c] = false;
            }

            for (let r = 2; r < pt.RowIds.length; r++) {
                if ([CoverageEditor.CoverageRowMstID, CoverageEditor.CalcCovRowMstID].includes(pt.RowIds[r])){
                    let IntervRec = pt.IntervRecords[r];
                    if (IntervRec !== null) {
                        if (pt.IntervRecords[sumRow]["RequiredIntervs"]["intArray"].includes(IntervRec["MstID"])) {
                            for (let c = 1; c < pt.GBColCount; c++) {
                                if (pt.tableData.value[r][c] !== "") {
                                    if (!flag[c]) {
                                        pt.tableData.value[sumRow][c] = 0;
                                        flag[c] = true;
                                    }
                                    let val = pt.tableData.value[sumRow][c] + pt.tableData.value[r][c];
                                    if (!isNaN(val)) {
                                        pt.tableData.value[sumRow][c] = val;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (PentaAutoCalc){
            pt = CoverageEditor.UpdatePentaRows(pt, PentaAutoCalc, false);
        }

        return (pt);
    },
};

const SetValue = (pt, CSModvars, language, IntervRec, exploreData, vt, col, row, t) => {
    let coverage      = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_Coverage_MV3)["value"];
    let quality       = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_Coverage_MV3)["quality"];
    let vaccines      = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_RoutDetVaccCov_MV3)["value"];
    let retroVacc     = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_RoutDetVaccCov_MV3)["retro"];
    let Pentavalent   = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_PentavalentDetVaccCov_MV)["value"];
    let retroPenta    = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_PentavalentDetVaccCov_MV)["retro"];
    let CPR           = getModvarByTag(CSModvars, CS_CONSTANTS.FP_TG_Tprevalence_MV)["value"];

    pt.tableData.value[row][col] = '';

    if ((IntervRec["MstID"] === CS_CONSTANTS.CS_MstCPR) || (exploreData && (IntervRec["MstID"] === CS_CONSTANTS.IV_ContraceptiveUse))) {
        pt.tableData.value[row][col] = CPR[CS_CONSTANTS.FP_All_Ages][CS_CONSTANTS.FP_all_need][t];
    } else {
        if(IntervRec["Names"][language] === RS('GB_stPentavalent')){
            if (t < 1){
                pt.tableData.value[row][col] = retroPenta[t+3];
            }
            else{
                pt.tableData.value[row][col] = Pentavalent[t];
            }
        }
        else if (vt !== 0) {
            if (t < 1){
                pt.tableData.value[row][col] = retroVacc[vt][CSGetDetVaccEquivDose(vt)][t+3];
            }
            else{
                pt.tableData.value[row][col] = vaccines[vt][CSGetDetVaccEquivDose(vt)][t];
            }
        } else if (!exploreData || ![CS_CONSTANTS.IV_PMTCT,
                                     CS_CONSTANTS.IV_Cotrimoxazole,
                                     CS_CONSTANTS.IV_ART].includes(IntervRec["MstID"])) {
            let CurrID = IntervRec["CurrID"];
            if (![CS_CONSTANTS.IV_IPT_Mal, CS_CONSTANTS.IV_MalCaseMgmt].includes(IntervRec["MstID"]) || pt.IPTpCBChecked || exploreData) {
                switch (pt.RowIds[row]) {
                    case CoverageEditor.QualityRowMstID  : {
                        pt.tableData.value[row][col] = quality[CurrID][t];
                        break;
                    }
                    case CoverageEditor.CoverageRowMstID : {
                        pt.tableData.value[row][col] = coverage[CurrID][t];
                        break;
                    }
                    case CoverageEditor.CalcCovRowMstID  : {
                        pt = SetCalcCovRow(pt, row, IntervRec);
                        break;
                    }
                    default :
                        break;
                }
            }
            else{
                pt.tableData.value[row][col] = '';
            }
        }
    }

    if (pt.tableData.value[row][col] < 0) {
        pt.tableData.value[row][col] = '';
    }

    return (pt);
};

const SetCalcCovRow = (pt, CalcCovRow, IntervRec) => {
    let UtilRow = -1;

    switch (IntervRec["UtilityID"]) {
        case CS_CONSTANTS.CS_ANC1Mode      : {UtilRow = pt.ANC1Row; break;}
        case CS_CONSTANTS.CS_ANC4Mode      : {UtilRow = pt.ANC4Row; break;}
        case CS_CONSTANTS.CS_FascDelivMode : {UtilRow = pt.healthFacDeliveryRow; break;}
        default : break;
    }

    if (UtilRow !== -1) {
        let QualityRow = CalcCovRow - 1;
        let tableData = pt.tableData.value;
        for (let c = 1; c < pt.GBColCount; c++) {
            tableData[CalcCovRow][c] = tableData[QualityRow][c] * (tableData[UtilRow][c] / 100);
        }
    }

    return (pt);
};

// const GetQualityFromCalcCovRow = (pt, CalcCovRow, col, CSModvars, t, IntervRec) => {
//     let result = -1;
//
//     let coverage = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_Coverage_MV2)["value"];
//
//     let UtilIV = null;
//     switch (IntervRec["UtilityID"]) {
//         case CS_CONSTANTS.CS_ANC1Mode      : {UtilIV = FindIntervRec(pt.IVInfo, CS_CONSTANTS.IV_ANC1); break;}
//         case CS_CONSTANTS.CS_ANC4Mode      : {UtilIV = FindIntervRec(pt.IVInfo, CS_CONSTANTS.IV_ANC4); break;}
//         case CS_CONSTANTS.CS_FascDelivMode : {UtilIV = FindIntervRec(pt.IVInfo, CS_CONSTANTS.IV_HealthFacDelivery); break;}
//         default : break;
//     }
//
//     if (UtilIV !== null) {
//         result = pt.tableData.value[CalcCovRow][col] / coverage[UtilIV["CurrID"]][CS_CONSTANTS.CS_AgeSummary][t] * 100;
//     }
//
//     return (result);
// };

/* This function adds all necessary utilization rows based on user selection of interventions. */
const AddUtilRows = (record, headerStr, groupID) => {
    let row = record.pt.GBRowCount;
    let DoneSet = new Set();
    if (record.IVGroupSets[groupID].length !== 0) {
        record.pt = addRowToPackTable(record.pt);
        record.pt.tableData.value[row][0] = headerStr;
        record.pt.FontStyles[row][0]["intArray"] = [CS_CONSTANTS.GB_Bold];
        record.pt.MergedCells = addMergedCellsToArray(record.pt.MergedCells, row, 0, 1, record.numCols + 1);
        record.pt = lockPackTable(record.pt, row, true);
        row++;
        for (let i = 0; i < record.IVGroupSets[groupID].length; i++){
            let IntervRec = record.IVGroupSets[groupID][i];
            let UtilIV = CSGetUtilizationIV(IntervRec["UtilityID"]);
            if (!DoneSet.has(UtilIV)) {
                let UtilIntervRec = FindIntervRec(record.pt.IVInfo, UtilIV);
                if (UtilIntervRec !== null) {
                    record.pt = addRowToPackTable(record.pt);
                    record.pt.tableData.value[row][0] = getCSIvRs(record.CSModvars, record.language, UtilIntervRec);
                    record.pt.IntervRecords[row] = UtilIntervRec;
                    record.pt.RowIds[row] = CoverageEditor.CoverageRowMstID;
                    record.pt = indentPackTable(record.pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
                    for (let c = 1; c <= record.numCols; c++) {
                        record.pt.RDec[row][c] = 1;
                    }
                    switch(UtilIntervRec["MstID"]){
                        case CS_CONSTANTS.IV_ANC1              : {record.pt.ANC1Row = row; break;}
                        case CS_CONSTANTS.IV_ANC4              : {record.pt.ANC4Row = row; break;}
                        case CS_CONSTANTS.IV_HealthFacDelivery : {record.pt.healthFacDeliveryRow = row; break;}
                        default : break;
                    }
                    record.pt.numSources++;
                    record.pt.SourceMap[row] = record.pt.numSources;
                    record.pt.SourceTitle[record.pt.numSources] = record.pt.tableData.value[row][0];
                    row++;
                    DoneSet.add(UtilIV);
                }
            }
        }
    }
    record.pt.GBRowCount = row;
    return (record.pt);
};

const Setup = (IVInfo, CSModvars, language, EditorStartYear, EditorEndYear, QualityMode, PentaAutoCalc, exploreData, showRetroYears, SubnatMode) => {
    let BYIndex = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    if (showRetroYears){
        StartIndex -= 4;
    }

    const selectedIVSet = getModvarByTag(CSModvars, CS_CONSTANTS.CS_TG_SelectedIVSet_MV);

    let MenARecommended = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_MenARecommended)["value"];

    let pt = getNewPT();

    pt.IPTpCBChecked = getModvarCloneByTag(CSModvars, CS_CONSTANTS.CS_TG_IPTpCBChecked_MV2)["value"];

    if (QualityMode) {
        pt.SourceMode = CS_CONSTANTS.GB_RowSecSrcEditor;
    }
    else{
        pt.SourceMode = CS_CONSTANTS.GB_RowSrcEditor;
    }

    pt.PentaRow = -1;

    pt.ANC1Row = -1;
    pt.ANC4Row = -1;
    pt.healthFacDeliveryRow = -1;

    pt.CalcCovRowSet = [];
    pt.SumTotalRowSet = [];

    pt.PentaCov = [];
    pt.UpdatePentaCov = true;

    pt.noShowRowSet = [];

    pt.IVInfo = CloneObj(IVInfo);

    pt.yearArr = [];
    let numCols;
    if (!SubnatMode) {
        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }
    else{
        numCols = 3;
        for (let t = StartIndex; t <= StartIndex + 1; t++){
            pt.yearArr.push(t);
        }
    }

    const numRows = 1;

    pt = resizePackTable(pt, numRows, numCols);

    if (!SubnatMode) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CS_CONSTANTS.GB_Right;
            col++;
        }
    }
    else {
        pt.tableData.value[0][1] = RS('GB_stNational');
        pt.tableData.value[0][2] = RS('GB_stSubnational');
    }

    pt.tableData.value[0][0] = RS('GB_stInterventionPercent');
    pt.hasData = false;

    let IVGroupSets = new Array(CS_CONSTANTS.CS_LiSTOnline_MaxGroups);

    for (let g = 1; g <= CS_CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
        IVGroupSets[g] = [];
        let groupMstID = CSGetGroupMstIDFromLiSTOnlineID(g);
        if (!showRetroYears || (groupMstID === CS_CONSTANTS.CS_GroupMstID_Vaccines)) {
            for (let i = 0; i < IVInfo.length; i++) {
                let IntervRec = IVInfo[i];
                if (selectedIVSet["value"]["intArray"].includes(IntervRec.MstID)) {
                    if (IntervRec["GroupMstID"] === groupMstID) {
                        if (!DirectEntryMstIDSet().includes(IntervRec["MstID"])) {
                            if (!exploreData || ![CS_CONSTANTS.IV_AgeAndBirthOrder,
                                                  CS_CONSTANTS.IV_BirthIntervals,
                                                  CS_CONSTANTS.CS_MstCPR].includes(IntervRec["MstID"])) {
                                IVGroupSets[g].push(IntervRec);
                            }
                        }
                    }
                }
            }
        }
    }

    let row = 1;

    pt.numSources = 0;

    if (QualityMode) {
        let record = {
            'pt'               : pt,
            'IVGroupSets'      : IVGroupSets,
            'numCols'          : numCols,
            'CSModvars'        : CSModvars,
            'language'         : language};
        pt.GBRowCount = row;
        pt = AddUtilRows(record, RS('GB_stInitUtilization'), CS_CONSTANTS.CS_LiSTOnline_Preg);
        pt = AddUtilRows(record, RS('GB_stBirthSurveyData'), CS_CONSTANTS.CS_LiSTOnline_Childbirth);
        row = pt.GBRowCount;
    }

    for (let g = 1; g <= CS_CONSTANTS.CS_LiSTOnline_MaxGroups; g++){
        if (IVGroupSets[g].length !== 0) {
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = CSGetLiSTOnlineGroupStr(g, true);
            pt.FontStyles[row][0]["intArray"] = [CS_CONSTANTS.GB_Bold];
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
            pt = lockPackTable(pt, row, true);
            row++;

            for (let i = 0; i < IVGroupSets[g].length; i++){
                let IntervRec = IVGroupSets[g][i];

                pt.hasData = true;
                pt = addRowToPackTable(pt);

                let s;
                if (exploreData && (IntervRec["MstID"] === CS_CONSTANTS.IV_ContraceptiveUse)){
                    s = CSGetMstIDName(CSModvars, language, getIntervRec(IVInfo, CS_CONSTANTS.CS_MstPercUsingContra));
                }
                else if (IntervRec["MstID"] === CS_CONSTANTS.IV_ChangesInBF){
                    s = RS('GB_stBFPromo');
                }
                else{
                    s = CSGetMstIDName(CSModvars, language, IntervRec);
                }

                let xoffset = GB_CONSTANTS.GB_ED_Indent1;

                if (IntervRec["RequiredIntervs"].intArray.length > 0) {
                    if (!IntervRec["SumTotal"] && (IntervRec["MstID"] !== CS_CONSTANTS.IV_PentaVacc)) {
                        xoffset += GB_CONSTANTS.GB_ED_Indent1;
                    }
                }

                let lockRow = false;
                /* Lock the rows belonging to interventions who's coverage is calculated automatically.  For
                   Pentavalent, DPT, Hib, and HepB, which of them are automatically calculated/editable depends on
                   the state of the checkbox at the bottom of the coverage drawer, represented by "PentaAutoCalc." */
                if (exploreData ||
                    (IntervRec["SumTotal"] && (IntervRec["MstID"] !== CS_CONSTANTS.IV_PentaVacc)) ||
                    (!PentaAutoCalc && (IntervRec["MstID"] === CS_CONSTANTS.IV_PentaVacc)) ||
                    (PentaAutoCalc && [CS_CONSTANTS.IV_DPT_Vacc, CS_CONSTANTS.IV_HibVacc,
                        CS_CONSTANTS.IV_HepBVacc].includes(IntervRec["MstID"])) ||
                    ((IntervRec["MstID"] === CS_CONSTANTS.IV_MeningA) && !MenARecommended) ||
                    ((IntervRec["MstID"] === CS_CONSTANTS.IV_IPT_Mal) && !pt.IPTpCBChecked) ||
                    ((IntervRec["MstID"] === CS_CONSTANTS.IV_MalCaseMgmt) && !pt.IPTpCBChecked)) {
                        lockRow = true;
                }

                /* Normal coverage editor */
                if (!QualityMode || (IntervRec["UtilityID"] === CS_CONSTANTS.CS_QualityInactive)) {
                    pt.tableData.value[row][0] = s;
                    pt.IntervRecords[row] = IntervRec;
                    pt.RowIds[row] = CoverageEditor.CoverageRowMstID;
                    pt.SourceTitle[pt.numSources+1] = s;
                    pt = indentPackTable(pt, row, true, xoffset);
                    for(let c = 1; c <= numCols; c++){
                        pt.RDec[row][c] = 1;
                    }
                    if (IntervRec["MstID"] === CS_CONSTANTS.IV_PentaVacc) {
                        pt.tableData.value[row][0] = '*' + pt.tableData.value[row][0];
                        pt.PentaRow = row;
                    }
                    if (lockRow){
                        pt = lockPackTable(pt, row, true, !exploreData);
                    }
                    if (IntervRec["SumTotal"]){
                        pt.SumTotalRowSet.push(row);
                    }
                    if ((IntervRec["MstID"] === CS_CONSTANTS.IV_PentaVacc) ||
                        IntervRec["SumTotal"]){
                        pt.noShowRowSet.push(row);
                    }
                    pt.numSources++;
                    pt.SourceMap[row] = pt.numSources;
                }

                /* Quality coverage editor */
                else {
                    /* Add a header row for this intervention. */
                    pt.tableData.value[row][0] = s + CSGetAbbrevQualityMeasureStr(IntervRec["UtilityID"]);
                    pt.RowIds[row] = CoverageEditor.HeaderRowMstID;
                    pt = indentPackTable(pt, row, true, xoffset);
                    // pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols + 1);
                    pt = lockPackTable(pt, row, true);

                    pt.numSources++;

                    pt.SourceMap[row] = pt.numSources;
                    pt.SourceTitle[pt.numSources] = s;

                    if (IntervRec["RequiredIntervs"].intArray.length > 0) {
                        if (IntervRec["SumTotal"] || (IntervRec["MstID"] === CS_CONSTANTS.IV_PentaVacc)) {
                            pt.tableData.value[row][0] = '*' + pt.tableData.value[row][0];
                        }
                    }

                    row++;

                    /* If this intervention has quality, add both a quality and a "calculated coverage" row.
                       "Calculated coverage" row will be locked (uneditable as it is calculated automatically). */
                    // if (IntervRec["UtilityID"] !== CS_CONSTANTS.CS_QualityInactive){
                        pt = addRowToPackTable(pt);
                        pt.tableData.value[row][0] = RS('DP_stQuality');
                        pt.IntervRecords[row] = IntervRec;
                        pt.RowIds[row] = CoverageEditor.QualityRowMstID;
                        pt = indentPackTable(pt, row, true, xoffset + GB_CONSTANTS.GB_ED_Indent1);
                        for(let c = 1; c <= numCols; c++){
                            pt.RDec[row][c] = 1;
                        }
                        if (lockRow){
                            pt = lockPackTable(pt, row, true);
                        }
                        pt.SourceMap[row] = pt.numSources;

                        row++;

                        pt = addRowToPackTable(pt);
                        pt.tableData.value[row][0] = RS('GB_stCoverage');
                        pt.IntervRecords[row] = IntervRec;
                        pt.RowIds[row] = CoverageEditor.CalcCovRowMstID;
                        pt.CalcCovRowSet.push(row);
                        pt = indentPackTable(pt, row, true, xoffset + GB_CONSTANTS.GB_ED_Indent1);
                        pt = lockPackTable(pt, row, true);
                        for(let col = 1; col <= numCols; col++){
                            pt.RDec[row][col] = 1;
                        }
                        pt.SourceMap[row] = pt.numSources;
                    // }
                    /* If this intervention does not have quality, add an editable coverage row (user will enter values
                       directly). */
                    // else{
                    //     pt = addRowToPackTable(pt);
                    //     pt.tableData.value[row][0] = RS('GB_stCoverage');
                    //     pt.IntervRecords[row] = IntervRec;
                    //     pt.RowIds[row] = CoverageEditor.CoverageRowMstID;
                    //     pt = indentPackTable(pt, row, true, xoffset + GB_CONSTANTS.GB_ED_Indent1);
                    //     for(let c = 1; c <= numCols; c++){
                    //         pt.RDec[row][c] = 1;
                    //     }
                    //     if (lockRow){
                    //         pt = lockPackTable(pt, row, true);
                    //     }
                    //     if (IntervRec["SumTotal"]){
                    //         pt.SumTotalRowSet.push(row);
                    //     }
                    //     if (IntervRec["MstID"] === CS_CONSTANTS.IV_PentaVacc) {
                    //         pt.PentaRow = row;
                    //     }
                    // }
                }

                row++;
            }
        }
    }

    pt.GBRowCount = row;
    pt = resizePackTable(pt, row, numCols);

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CS_CONSTANTS.GB_Right;
            }
        }
        if (SubnatMode){
            pt = lockPackRowCol(pt, row, 1, true);
        }
    }

    // pt = setSourceArrLength(pt, pt.numSources);

    return pt;
};

const generateIntervObjArr = (pt, language, firstYear, finalYear) => {
    let result = [];
    for (let row = 0; row < pt.GBRowCount; row++){
        let IntervRec = pt.IntervRecords[row];
        if ((IntervRec !== null) && !IntervRec["SumTotal"] && (IntervRec["MstID"] !== CS_CONSTANTS.IV_MstPentaVacc)) {
            if ([CoverageEditor.CoverageRowMstID, CoverageEditor.CalcCovRowMstID].includes(pt.RowIds[row])) {
                result.push({
                    interventionName: IntervRec["Names"][language],
                    firstYear: firstYear,
                    finalYear: finalYear,
                    arrayOfValues: pt.tableData.value[row].slice(1, pt.yearArr.length + 1),
                    rDec: pt.RDec[row].slice(1, pt.yearArr.length + 1),
                });
            }
        }
    }
    return (result);
};

