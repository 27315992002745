import React from "react";
import PropTypes from "prop-types";
import Settings from '@material-ui/icons/Settings';
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../components/CS/CSBlueBox2";
import ConfigurationDrawer from "./ConfigurationDrawer";

class CSConfigBlueBox2 extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        modID                   : PropTypes.number.isRequired,
        resultID                : PropTypes.number.isRequired,
        chartOptionsArr         : PropTypes.arrayOf(PropTypes.object).isRequired,
        chartYears              : PropTypes.object.isRequired,
        firstYear               : PropTypes.number.isRequired,
        finalYear               : PropTypes.number.isRequired,
        displayEndpoints        : PropTypes.bool.isRequired,
        displayCoverage         : PropTypes.bool.isRequired,
        retroYears              : PropTypes.bool.isRequired,
        coverageDisplay         : PropTypes.string.isRequired,
        onStateChange           : PropTypes.func.isRequired,
        style                   : PropTypes.object.isRequired,
        isLink                  : PropTypes.bool.isRequired,
        hideMenu                : PropTypes.func.isRequired,
        language                : PropTypes.number,
        onOpenHelpDialog        : PropTypes.func
    };

    static defaultProps = {
        onStateChange           : () => console.log("onStateChange"),
        hideMenu                : () => console.log("hideMenu"),
        language                : CONSTANTS.GB_English,
        onOpenHelpDialog        : () => console.log("onOpenHelpDialog")
    };

    state = {
        openDrawer              : false,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState)) 
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClick = () => {
        this.setState({
            openDrawer : true
        });
    };

    onSave = (value) => {
        this.setState({
            openDrawer : false
        }, this.props.onStateChange(value));
    };

    onClose = () => {
        this.setState({
            openDrawer : false
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            settings: {
                float:"left",
                color:"grey",
                verticalAlign:"center",
                marginRight: 5
            }
        };

        return (
            <CSBlueBox2
                caption          = {RS('GB_stConfig')}
                isLink           = {this.props.isLink}
                pictureLeft      = {this.props.isLink ? <Settings style={styles.settings} /> : null}
                captionStyle     = {{}}
                description      = ''
                onClick          = {this.onClick}
                isRegularButton  = {!this.props.isLink}
                style            = {this.props.style}
                editContent      = {
                    (this.state.openDrawer) ?
                        <ConfigurationDrawer
                            onSave           = {this.onSave}
                            onClose          = {this.onClose}
                            modID            = {this.props.modID}
                            resultID         = {this.props.resultID}
                            chartOptionsArr  = {this.props.chartOptionsArr}
                            chartYears       = {this.props.chartYears}
                            firstYear        = {this.props.firstYear}
                            finalYear        = {this.props.finalYear}
                            displayEndpoints = {this.props.displayEndpoints}
                            displayCoverage  = {this.props.displayCoverage}
                            retroYears       = {this.props.retroYears}
                            coverageDisplay  = {this.props.coverageDisplay}
                            onOpenHelpDialog  = {this.props.onOpenHelpDialog}
                        /> : 
                        null
                }
                
            />
        );
    }

};

export default CSConfigBlueBox2;