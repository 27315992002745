import * as React from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TLabel from '@common/components/TLabel';

class TRadioGroup extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption       : PropTypes.string,
        handleChange  : PropTypes.func,
        style         : PropTypes.object,
        itemIndex     : PropTypes.number,
        items         : PropTypes.arrayOf(PropTypes.string),
        classes       : PropTypes.object,
        labelStyle    : PropTypes.object,
        radioBtnColor : PropTypes.string,
        sortInRow     : PropTypes.bool,
        captionStyle  : PropTypes.object,
        radioStyle    : PropTypes.object
    };

    static defaultProps = {
        caption       : '',
        handleChange  : () => console.log('onClick'),
        style         : {},
        classes       : {},
        itemIndex     : 0,
        items         : ["Item1", "Item2", "Item3"],
        labelStyle    : {},
        radioBtnColor : 'secondary',
        sortInRow     : true,
        captionstyle  : {},
        radioStyle    : {}
    };

    state = {
        itemIndex : 0
    };

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    handleChange = (obj, value) => {
        this.props.handleChange(parseInt(value, 10));
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderItems = (props) => {
        let items = [];

        for (let i = 0; i <= this.props.items.length-1; i++) {
            items.push(
                <FormControlLabel
                    value={i.toString()}
                    control={<Radio style={this.props.radioStyle} color={this.props.radioBtnColor} />}
                    label={this.props.items[i]}
                    key={i}
                    classes={{
                        label: props.classes.label
                    }}
                />
            );
        }

        return items;
    };

    render() {

        //
        // return (
        //     <FormControl component="fieldset" >
        //         <TLabel style={{color: '#CCCCCC'}} caption={this.props.caption} />
        //         <RadioGroup
        //             aria-label="Gender"
        //             name="gender1"
        //             value={this.props.itemIndex}
        //             onChange={this.handleChange}
        //             row={true}
        //         >
        //             {this.renderItems()}
        //         </RadioGroup>
        //     </FormControl>
        // );

            const unstyledComponent = props => (
                <FormControl style={this.props.style} component="fieldset">
                    <TLabel style={{color: '#CCCCCC', ...this.props.captionStyle}} caption={this.props.caption}/>
                    <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        value={this.props.itemIndex.toString()}
                        onChange={this.handleChange}
                        row={this.props.sortInRow}
                    >
                        {this.renderItems(props)}
                    </RadioGroup>
                </FormControl>
            );

        const StyledComponent = withStyles({
            label: this.props.labelStyle || {}
        })(unstyledComponent);

        return (
            <StyledComponent />
        );
    }
}

export default TRadioGroup;