import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import TSwitch from "@common/components/TSwitch";
import TLabel from "@common/components/TLabel";
import RS from "@common/strings/RS";
import IVApproachFilter from "./IVApproachFilter";

class InterventionOptions extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange            : PropTypes.func,
        onOutcomeSwitchChange    : PropTypes.func,
        maternalIntervSelected   : PropTypes.bool,
        stillbirthIntervSelected : PropTypes.bool,
        neonatalIntervSelected   : PropTypes.bool,
        childIntervSelected      : PropTypes.bool,
        nutritionSelected        : PropTypes.bool,
        birthOutcomeSelected     : PropTypes.bool,
        adolescentsSelected      : PropTypes.bool,
        MOResultToDisplay        : PropTypes.number,
    };

    static defaultProps = {
        onStateChange            : () => console.log('onStateChange'),
        onOutcomeSwitchChange    : () => console.log('onOutcomeSwitchChange'),
        maternalIntervSelected   : true,
        stillbirthIntervSelected : true,
        neonatalIntervSelected   : true,
        childIntervSelected      : true,
        nutritionSelected        : true,
        birthOutcomeSelected     : true,
        adolescentsSelected      : false,
        MOResultToDisplay        : CONSTANTS.CS_PotentialDeathsAverted,
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderHeader = () => {
        const styles = {
            labelStyle : {
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#000000',
                userSelect: 'none',
                marginBottom: '10px'
            }
        };

        return (
            <TLabel
                caption = {RS('GB_stOutcomes')}
                style   = {styles.labelStyle}
            />
        )
    };

    renderFilter = () => {
        return (
            <IVApproachFilter
                onStateChange={this.props.onStateChange}
                onOutcomeSwitchChange={this.props.onOutcomeSwitchChange}
                nutritionSelected={this.props.nutritionSelected}
                birthOutcomeSelected={this.props.birthOutcomeSelected}
            />
        )
    };

    renderSwitches = () => {

        const styles = {
            switchLabelStyle : {
                fontSize   : '14px',
                color      : '#0c3a79',
            },
            switchStyle : {
                marginTop: 0,
            },
            divStyle : {
                float: 'left',
                height: '30px',
                paddingRight: '40px'
            },
        };

        return (
            <div style={{height: '120px'}}>
                <div  style={styles.divStyle} key={CONSTANTS.CS_MstMaternalMortality}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stMaternal')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.maternalIntervSelected}
                        name       = {''}
                        customProp = {CONSTANTS.CS_MstMaternalMortality}
                        onClick    = {(checked, event, name, caption, groupID) => this.props.onOutcomeSwitchChange(checked, groupID)}
                    />
                </div>
                <div style={styles.divStyle} key={CONSTANTS.CS_MstStillbirths}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stStillbirth')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.stillbirthIntervSelected}
                        name       = {''}
                        customProp = {CONSTANTS.CS_MstStillbirths}
                        onClick    = {(checked, event, name, caption, groupID) => this.props.onOutcomeSwitchChange(checked, groupID)}
                    />
                </div>
                <div style={styles.divStyle} key={CONSTANTS.CS_MstNeonatalMortality}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stNeonatal')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.neonatalIntervSelected}
                        name       = {''}
                        customProp = {CONSTANTS.CS_MstNeonatalMortality}
                        onClick    = {(checked, event, name, caption, groupID) => this.props.onOutcomeSwitchChange(checked, groupID)}
                    />
                </div>
                <div  style={styles.divStyle} key={CONSTANTS.CS_MstChildMortality}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stChildren')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.childIntervSelected}
                        name       = {''}
                        customProp = {CONSTANTS.CS_MstChildMortality}
                        onClick    = {(checked, event, name, caption, mstID) => this.props.onOutcomeSwitchChange(checked, mstID)}
                    />
                </div>
                {/*<div  style={styles.divStyle} key={CONSTANTS.CS_MstNutrition}>*/}
                {/*    <TSwitch*/}
                {/*        style      = {styles.switchStyle}*/}
                {/*        caption    = {RS('GB_stNutrition')}*/}
                {/*        labelStyle = {styles.switchLabelStyle}*/}
                {/*        value      = {this.props.nutritionSelected}*/}
                {/*        name       = {''}*/}
                {/*        customProp = {CONSTANTS.CS_MstNutrition}*/}
                {/*        onClick    = {(checked, event, name, caption, mstID) => this.props.onOutcomeSwitchChange(checked, mstID)}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div  style={styles.divStyle} key={CONSTANTS.CS_MstBirthOutcomes}>*/}
                {/*    <TSwitch*/}
                {/*        style      = {styles.switchStyle}*/}
                {/*        caption    = {RS('GB_stBirthOutcomes')}*/}
                {/*        labelStyle = {styles.switchLabelStyle}*/}
                {/*        value      = {this.props.birthOutcomeSelected}*/}
                {/*        name       = {''}*/}
                {/*        customProp = {CONSTANTS.CS_MstBirthOutcomes}*/}
                {/*        onClick    = {(checked, event, name, caption, mstID) => this.props.onOutcomeSwitchChange(checked, mstID)}*/}
                {/*    />*/}
                {/*</div>*/}
                {
                    // (window.location.hostname === "localhost" || window.location.hostname === "beta.avstaging.org") &&
                        <div  style={styles.divStyle} key={CONSTANTS.CS_MstAdolescents}>
                            <TSwitch
                                style      = {styles.switchStyle}
                                caption    = {RS('GB_stAdolescents')}
                                labelStyle = {styles.switchLabelStyle}
                                value      = {this.props.adolescentsSelected}
                                name       = {''}
                                customProp = {CONSTANTS.CS_MstAdolescents}
                                onClick    = {(checked, event, name, caption, mstID) => this.props.onOutcomeSwitchChange(checked, mstID)}
                            />
                        </div>
                }
            </div>
        )

    };

    render() {
        return (
            <div>
                {this.renderFilter()}
                {this.renderHeader()}
                {this.renderSwitches()}
            </div>
        );
    }

}

export default InterventionOptions;