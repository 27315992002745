import React from 'react';
import Addbutton from "@material-ui/icons/AddCircle";
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";

const styles = {
    addIcon : {
        color: '#f78c30',
        padding : 0,
    },
    button: {
        color: '#000000',
        textTransform: 'none',
        fontSize: '16px',
        padding : '10px 0',
        margin: '10px 0',
        marginLeft: '-10px'
    },
    captionStyles : {
        marginLeft: '10px',
        marginRight: '10px'
    },
};

const ManageSelectedResultsButton = React.memo(({onClick, style}) => {
    return (
        <TButton 
            caption={RS('GB_stManageSelectedResults')}
            variant={"text"}
            style={{...styles.button, ...style}}
            captionStyles={styles.captionStyles}
            onClick={onClick}
            pictureRight={<Addbutton style={styles.addIcon} onClick={onClick} />}
        />
    );
});

export default ManageSelectedResultsButton;