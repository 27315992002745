import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    lockPackTable,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {CSGetBirthTerm} from "utilities/CS/CSDataUtil";

export const StatusAtBirthEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stStatusAtBirth');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = StatusAtBirthEditor.RecalcGrid(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ChildHealth;
        return ([packChart, packTable]);
    },

    RecalcGrid : (pt) => {
        for(let col = 1; col < pt.GBColCount; col++) {
            let Sum = "";
            let setSum = false;
            for (let i = 1; i <= CONSTANTS.CS_TermSGA; i++) {
                if (pt.tableData.value[i][col] !== "") {
                    if (!setSum){
                        Sum = 0;
                        setSum = true;
                    }
                    Sum += pt.tableData.value[i][col];
                }
            }
            if (setSum) {
                pt.tableData.value[CONSTANTS.CS_TermAGA][col] = 100 - Sum;
                pt.tableData.value[5][col] = 100;
            }
        }
        return (pt);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let StatusAtBirthMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_StatusAtBirth_MV);
        for(let i = 1; i < CONSTANTS.CS_TermAGA; i++) {
            StatusAtBirthMV["value"][i] = pt.tableData.value[i][1];
        }

        /* Set the Affected Fraction for KMC on Breastfeeding to 0.58 * percent of births that
           are premature, which is a country-specific value. */
        let value = 0.58 * (StatusAtBirthMV["value"][CONSTANTS.CS_PreTermSGA] + StatusAtBirthMV["value"][CONSTANTS.CS_PreTermAGA]);
        let KMConBFMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_KMConBF_MV);
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
            KMConBFMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][a] = value;
            KMConBFMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][a] = value;
        }

        StatusAtBirthMV["source"][0][0] = pt.Source[0];

        changedModvars.push(StatusAtBirthMV);
        changedModvars.push(KMConBFMV);

        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    let numCols = 2;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 6;

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        for(let i = 1; i <= CONSTANTS.CS_TermAGA+1; i++) {
            pt.Alignments[i][col] = CONSTANTS.GB_Right;
            pt.RDec[i][col] = 2;
        }
        col++;
    }

    for(let i = 1; i <= CONSTANTS.CS_TermAGA; i++) {
        pt.tableData.value[i][0] = CSGetBirthTerm(i);
        pt.Alignments[i][1] = CONSTANTS.GB_Right;
        pt.RDec[i][1] = 2;
    }

    pt.tableData.value[5][0] = RS('GB_stTotal');
    pt.Alignments[5][1] = CONSTANTS.GB_Right;
    pt.RDec[5][1] = 2;

    pt = lockPackTable(pt, 4, true);
    pt = lockPackTable(pt, 5, true);

    for(let i = 1; i <= CONSTANTS.CS_TermAGA; i++) {
        pt = lockPackTable(pt, i, pt.yearArr.length > 0, false);
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let StatusAtBirth = getModvarByTag(CSModvars, CONSTANTS.CS_TG_StatusAtBirth_MV);
    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            for (let i = 1; i < CONSTANTS.CS_TermAGA; i++) {
                if (StatusAtBirth["exploreLiST"][i][t] >= 0) {
                    pt.tableData.value[i][col] = StatusAtBirth["exploreLiST"][i][t];
                }
            }
            col++;
        }
    }
    else {
        for (let i = 1; i < CONSTANTS.CS_TermAGA; i++) {
            pt.tableData.value[i][1] = StatusAtBirth["value"][i];
        }
    }

    pt.Source[0] = StatusAtBirth["source"][0][0];

    return pt;
};

