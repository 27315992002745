import React from 'react';
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {getModvarByTag} from "utilities/GB/GBUtil";
import {CSConvertIndicatorToZScores} from "utilities/CS/CSDataUtil";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {StuntingDistrEditor} from "../../data/HealthStatus/NutritionalStatus/StuntingDistrEditor";
import {WastingDistrEditor} from "../../data/HealthStatus/NutritionalStatus/WastingDistrEditor";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSSingleNutStatusDistDrawer from "./CSSingleNutStatusDistDrawer";

// const modvarTag2 = CONSTANTS.CS_TG_UseDetailedStuntingMatrix_MV;
// const modvarTag3 = CONSTANTS.CS_TG_UseDetailedWastingMatrix_MV;

class CSNutStatusDistDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        firstYear        : PropTypes.number,
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        onClose          : PropTypes.func.isRequired,
        onOpenHelpDialog : PropTypes.func.isRequired,
        onStateChange    : PropTypes.func.isRequired,
    };

    static defaultProps = {
        firstYear        : 1,
        CSModvars        : [],
    }; 

    state = {
        openDrawer      : false,
        saveBtnDisabled : true,
        packTable2      : null,
        packTable4      : null,
        CSModvars       : []
    };

    componentDidMount() {
        const { CSModvars, firstYear } = this.props;
        
        this.setState({
            CSModvars  : CSModvars,
            packTable2 : StuntingDistrEditor.Create(CSModvars, firstYear)[CONSTANTS.CS_PackTable],
            packTable4 : WastingDistrEditor.Create(CSModvars, firstYear)[CONSTANTS.CS_PackTable],
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable2Change = (packTable2) => {
        const { CSModvars } = this.state;

        packTable2 = StuntingDistrEditor.RecalcGrid(packTable2);
        let CSModvarsClone = StuntingDistrEditor.GetSSData(packTable2, CSModvars);
        CSModvarsClone = StuntingDistrEditor.UpdateSingleStuntingDistr(packTable2, CSModvarsClone);

        this.setState({
            packTable2      : packTable2,
            CSModvars       : CSModvarsClone,
            saveBtnDisabled : false,
        });
    };

    onPackTable4Change = (packTable4) => {
        const { CSModvars } = this.state;

        packTable4 = WastingDistrEditor.RecalcGrid(packTable4);
        let CSModvarsClone = WastingDistrEditor.GetSSData(packTable4, CSModvars);
        CSModvarsClone = WastingDistrEditor.UpdateSingleWastingDistr(packTable4, CSModvarsClone);
        
        this.setState({
            packTable4      : packTable4,
            CSModvars       : CSModvarsClone,
            saveBtnDisabled : false,
        });
    };

    _onClose = () => {
        this.props.onClose();
    };

    _onApply = () => {

        const { CSModvars, packTable2, packTable4 } = this.state;

        let packTable = packTable2;
        let goAhead = true;
        packTable.tableData.value.forEach(function(arr) {
            arr.forEach(function(val) {
                if (typeof val === "number" && parseFloat(val.toFixed(2)) > 100) {
                    goAhead = false;
                }
            });
        });

        if (goAhead) {
            let packTable = packTable4;
            packTable.tableData.value.forEach(function(arr) {
                arr.forEach(function(val) {
                    if (typeof val === "number" && parseFloat(val.toFixed(2)) > 100) {
                        goAhead = false;
                    }
                });
            });
        }

        if (goAhead) {
            let CSModvarsClone;
            // let CSModvarsClone = SingleStuntingDistrEditor.GetSSData(packTable1, CSModvars);
            CSModvarsClone = StuntingDistrEditor.GetSSData(packTable2, CSModvars);
            // CSModvarsClone = SingleWastingDistrEditor.GetSSData(packTable3, CSModvarsClone);
            CSModvarsClone = WastingDistrEditor.GetSSData(packTable4, CSModvarsClone);

            // CSModvarsClone = CSModvarsClone.map(x =>
            //     (x.tag === modvarTag1) ? {...x, value: RadioValue} :
            //         (x.tag === modvarTag2) ? {...x, value: RadioValue} :
            //             (x.tag === modvarTag3) ? {...x, value: RadioValue} :
            //                 x);

            this.props.onStateChange({
                unchangedProj : false,
                CSModvars : CSModvarsClone,
            }, () => {
                this.props.onClose();   
            });
        }
        else {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : RS("GB_stValOver100")
            });
        }
    };

    _onOpenInnerDrawer = () => {
        this.setState({
            openDrawer: true
        });
    };

    _onInnerDrawerApply = (CSModvars) => {
        const { packTable2, packTable4 } = this.state;

        let CSModvarsClone = StuntingDistrEditor.GetSSData(packTable2, CSModvars);
        CSModvarsClone = WastingDistrEditor.GetSSData(packTable4, CSModvarsClone);

        let SingleStuntingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleStuntingDistr_MV);
        let StuntingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_StuntingDistr_MV);
        if (SingleStuntingDistr["value"][packTable2.PseudoStartIndex] !==
            SingleStuntingDistr["value"][packTable2.StartIndex]) {
            StuntingDistr["value"] = CSConvertIndicatorToZScores(SingleStuntingDistr["value"],
                StuntingDistr["value"], packTable2.PseudoStartIndex, packTable2.StartIndex);
        }

        let SingleWastingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleWastingDistr_MV);
        let WastingDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_WastingDistr_MV);
        if (SingleWastingDistr["value"][packTable4.PseudoStartIndex] !==
            SingleWastingDistr["value"][packTable4.StartIndex]) {
            WastingDistr["value"] = CSConvertIndicatorToZScores(SingleWastingDistr["value"],
                WastingDistr["value"], packTable4.PseudoStartIndex, packTable4.StartIndex);
        }

        let changedCSModvars = CSModvarsClone.map(x => {
            let a = [StuntingDistr, WastingDistr].find(y => y.tag === x.tag);
            return a || x;
        });

        this.setState({
            CSModvars  : changedCSModvars,
            packTable2 : StuntingDistrEditor.SetSSData(packTable2, changedCSModvars),
            packTable4 : WastingDistrEditor.SetSSData(packTable4, changedCSModvars),
            openDrawer : false
        });
    };

    _onInnerDrawerClose = () => {
        this.setState({
            openDrawer : false
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable2 = JSON.parse(JSON.stringify(this.state.packTable2));
        let packTable4 = JSON.parse(JSON.stringify(this.state.packTable4));

        return (
            <TDrawerWithHeader
                open             = {true}
                onOpenHelpDialog = {this.props.onOpenHelpDialog}
                helpFile         = {"nutritional_status_distributions.htm"}
                onClose          = {this._onClose}
                onSave           = {this._onApply}
                saveBtnCaption   = {RS('GB_stApply')}
                saveBtnDisabled  = {this.state.saveBtnDisabled}
                appBarText       = {RS('GB_stNutritionStatusDist') + " - " + RS('GB_stDetailedIndicators')}
                content          = {
                    <div className="innerPage">
                        <EditorNote
                            numberOfTables={4}
                        />

                        <p>{RS("GB_stSingleIndBtnClick")}</p>

                        <CSBlueBox2
                            caption={RS('GB_stSingleIndicator')}
                            description=""
                            onClick={this._onOpenInnerDrawer}
                            isRegularButton={true}
                            regBtnStyle={{background:"#f78c30"}}
                            regBtnDisabled={false}
                        />
                        <br />
                        <br />

                        <CSEditor
                            mstID={CONSTANTS.CS_MstStuntingDistrSheetID}
                            packTable={packTable2}
                            showTitle={true}
                            onChange={this.onPackTable2Change}
                        /> 

                        <CSEditor
                            mstID={CONSTANTS.CS_MstWastingDistrSheetID}
                            packTable={packTable4}
                            showTitle={true}
                            onChange={this.onPackTable4Change}
                        />

                        {
                            (this.state.openDrawer) ?
                                <CSSingleNutStatusDistDrawer
                                    firstYear={this.props.firstYear}
                                    CSModvars={this.state.CSModvars}
                                    onApply={this._onInnerDrawerApply}
                                    onClose={this._onInnerDrawerClose}
                                    onOpenHelpDialog={this.props.onOpenHelpDialog}
                                    onStateChange={this.props.onStateChange}
                                />
                                : null
                        }
                    </div>
                }
            />
        );
    }

}

export default CSNutStatusDistDrawer;