import React from 'react';
import CONSTANTS from "utilities/CS/CSConst";
import {CSEditor} from '../../../components/CS/CSEditors';

const styles = {
    table : {
        color : 'rgb(203, 104, 20)'
    }
};

const DisplayTable = (props) => {
    const { resultsContent, tableRef, passData } = props;

    const onEditorChange = (resultsContent) => (pt) => { 
        let resultsContentNew = JSON.parse(JSON.stringify(resultsContent));
        resultsContentNew.packTable = pt;
        passData({resultsContent: resultsContentNew});
    };

    return (
        <CSEditor
            tableRef={tableRef}
            mstID={CONSTANTS.CS_MstExpandResultsSheetID}
            showTitle={true}
            packTable={resultsContent.packTable}
            isResult={true}
            onChange={onEditorChange(resultsContent)}
            style={styles.table}
        />
    )
};

export default DisplayTable;