
import React from 'react';
import PropTypes from "prop-types";

class HeaderText extends React.PureComponent {

    static propTypes = {
        caption : PropTypes.string,
    };

    static defaultProps = {
        caption : "",
    };

    render() {
        const styles = {
            exploreDataText: {
                color: '#cb6814',
                fontSize: '22px',
                fontWeight: 'bold',
                userSelect: 'none',
                marginTop: '0px',
                marginBottom: '20px'
            }
        };
        
        return (
            <p style={styles.exploreDataText}>
                {this.props.caption}
            </p>
        );
    }
}

export default HeaderText;