import React from 'react';
import PropTypes from "prop-types";
// import RS from "@common/strings/RS";
import TGBProjectionTable from '../components/GB/TGBProjectionTable';
// import TButton from "@common/components/TButton";
// import TSwitch from "@common/components/TSwitch";
// import TComboBox from "@common/components/TComboBox";
// import FileUpload from '@material-ui/icons/Publish';
// import FilterList from '@material-ui/icons/FilterList';

class CSProjectionLibraryForm extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isGuest               : PropTypes.bool,
        activeProjections     : PropTypes.array,
        storedProjections     : PropTypes.array,
        projectCreated        : PropTypes.bool,
        topicList             : PropTypes.array,
        isDeskTop             : PropTypes.bool,
        topicFilter           : PropTypes.array,

        onPageChange          : PropTypes.func,
        onOpenProjection      : PropTypes.func,
        onDeleteProjection    : PropTypes.func,
        onDownloadProjection  : PropTypes.func,
        onCloseProjection     : PropTypes.func,
        onSaveProjection      : PropTypes.func,
        onRenameProjection    : PropTypes.func,
        onCopyProjection      : PropTypes.func,
        onCreateProjBtnClick  : PropTypes.func,
        onUploadProjection    : PropTypes.func,
        onSaveTopic           : PropTypes.func,
        onGetTopicList        : PropTypes.func,
    };

    static defaultProps = {
        isGuest               : true,
        activeProjections     : [],
        storedProjections     : [],
        projectCreated        : false,
        topicList             : [],
        isDeskTop             : true,
        topicFilter           : [],

        onPageChange          : () => console.log('onPageChange'),
        onOpenProjection      : () => console.log('onOpenProjection'),
        onDeleteProjection    : () => console.log('onDeleteProjection'),
        onDownloadProjection  : () => console.log('onDownloadProjection'),
        onCloseProjection     : () => console.log('onCloseProjection'),
        onSaveProjection      : () => console.log('onSaveProjection'),
        onRenameProjection    : () => console.log('onRenameProjection'),
        onCopyProjection      : () => console.log('onCopyProjection'),
        onCreateProjBtnClick  : () => console.log('onCreateProjBtnClick'),
        onUploadProjection    : () => console.log('onUploadProjection'),
        onSaveTopic           : () => console.log('onSaveTopic'),
        onGetTopicList        : () => console.log('onGetTopicList'),
    };

    state = {
        topicFilter    : 0,
        topicFilterTag : "",
        topicList      : ["", ...this.props.topicList],
        details        : false
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({topicList: ["", ...newProps.topicList]})
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    doUpload = (f) => {
        this.props.onUploadProjection(f);
    };

    uploadProjection = () => {
        document.querySelector("#hidden_uploader").click();
    };

    onFilterTopicClick = () => {
        // this.setState({
        //     topicFilter: v,
        //     topicFilterTag: this.state.topicList[v]
        // });
        this.props.onStateChange({
            dialogFilterTagsOpen: true
        });
    };

    render() {
        // let styles = {
        //     pageHeader : {
        //         color: '#cb6814',
        //         paddingTop: '0px',
        //         zIndex:"-1"
        //     },
        //     closeIcon : {
        //         float: 'right',
        //         height: '30px',
        //         width: '30px',
        //         cursor: 'pointer',
        //         marginTop: '-20px'
        //     },
        //     dialogs: {
        //         width:"400px"
        //     }
        // };

        return (
            <div style={{overflow:"auto"}} className="innerPage">
                {/*<input*/}
                    {/*onChange={() => this.doUpload(document.getElementById("hidden_uploader").files[0])}*/}
                    {/*id="hidden_uploader"*/}
                    {/*type="file"*/}
                    {/*//accept="application/SPECTRUM_PJNZ_FILE"*/}
                    {/*style={{display:"none"}}*/}
                {/*/>*/}
                {/*<TButton*/}
                    {/*caption        = {RS("GB_stUploadProjection")}*/}
                    {/*containerStyle = {{float:"right", zIndex:"1"}}*/}
                    {/*onClick        = {() => this.uploadProjection()}*/}
                    {/*pictureLeft    = {<FileUpload />}*/}
                {/*/>*/}
                {/*<TButton*/}
                    {/*caption        = {RS("GB_stFilter") + " " + RS("GB_stTags")}*/}
                    {/*containerStyle = {{float:"right", zIndex:"1", marginRight:"10px"}}*/}
                    {/*onClick        = {() => this.onFilterTopicClick()}*/}
                    {/*pictureLeft    = {<FilterList />}*/}
                {/*/>*/}
                {/*<div style={{float:"right"}}>*/}
                    {/*<TComboBox*/}
                        {/*caption={"Filter Tags"}*/}
                        {/*itemIndex={this.state.topicFilter}*/}
                        {/*onChange={(val) => this.onFilterTopicClick(val)}*/}
                        {/*style={{width:231, float: "right"}}*/}
                        {/*captionStyle={{color: '#609cd9'}}*/}
                        {/*items={this.state.topicList}*/}
                    {/*/>*/}
                {/*</div>*/}
                {/*<TSwitch*/}
                    {/*caption        = {RS("GB_stDetails")}*/}
                    {/*style          = {{float:"right", marginTop: "7px", zIndex:"1"}}*/}
                    {/*onClick        = {() => this.setState({details: !this.state.details})}*/}
                {/*/>*/}
                {/*<h2 className="pageHeader" style={styles.pageHeader}>{this.props.topicFilter.length > 0 ? RS("GB_stProjectionLibrary") + " (" + RS('GB_stFilter') + ")" : RS("GB_stProjectionLibrary")}</h2>*/}
                <TGBProjectionTable
                    storedProjections    = {this.props.storedProjections}
                    activeProjections    = {this.props.activeProjections}
                    topicList            = {this.props.topicList}
                    isDeskTop            = {this.props.isDeskTop}
                    onOpenProjection     = {this.props.onOpenProjection}
                    onDeleteProjection   = {this.props.onDeleteProjection}
                    onDownloadProjection = {this.props.onDownloadProjection}
                    onUploadProjection   = {this.props.onUploadProjection}
                    onCopyProjection     = {this.props.onCopyProjection}
                    onRenameProjection   = {this.props.onRenameProjection}
                    onSaveTopic          = {this.props.onSaveTopic}
                    onGetTopicList       = {this.props.onGetTopicList}
                    onStateChange        = {this.props.onStateChange}
                    //details              = {this.state.details}
                    fileToCopy           = {this.props.fileToCopy}
                    fileToRename         = {this.props.fileToRename}
                    topicFilter          = {this.props.topicFilter}
                />
            </div>
        );
    }
}

export default CSProjectionLibraryForm;