import React from 'react';
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import GBStdChart from "../../../components/GB/GBStdChart";

const HCOptions = {
    tooltip : {
        shared: false,
        formatter: undefined
    },
    title: {
        style: {
            color: "rgb(203, 104, 20)"
        }
    }
};

const DisplayChart = (props) => {
    const { resultsContent, stacked, legendReversed, chartOptions, modID, resultID } = props;
    const { chartRef } = props;

    function getChartOptions() {
        return chartOptions.find(x => ((x.modID === modID) && (x.resultID === resultID)));
    }

    let chartType = getChartOptions()['typeOfDisplay'];

    let packChart = JSON.parse(JSON.stringify(resultsContent.packChart));
    if (packChart.subsetLabels.length > 0) {
        if (packChart.subsetLabels[0].includes("Total") && chartType === 'bar') {
            packChart.subsetLabels.shift();
            packChart.chartData.shift();
        }
    }

    if (
        (
            packChart.title === RS("GB_stAbortions") ||
            packChart.title === RS("CS_stChDeathsPrev") || 
            packChart.title === RS("GB_stNumStunted") || 
            packChart.title === RS("GB_stNumStuntedAverted")
        ) && 
        (
            chartType === CONSTANTS.CS_ColumnChart || 
            chartType === CONSTANTS.CS_BarChart
        )
    ) {
        packChart.chartData.shift();
        packChart.subsetLabels.shift();
    }

    return (
        <GBStdChart
            id             = {"chart"}
            packChart      = {packChart}
            minHeight      = {500}
            maxHeight      = {500}
            chartType      = {chartType}
            stacked        = {stacked}
            legendReversed = {legendReversed}
            ref            = {chartRef}
            options        = {HCOptions}
        />
    );
};

export default DisplayChart;