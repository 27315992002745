import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CreateAPITask from "../../../../../../../api/util/createAPITask";
// import CONSTANTS from "utilities/CS/CSConst";

class CountryDropdown extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange         : PropTypes.func,
        onAddTasks            : PropTypes.func,
        selectedCountry       : PropTypes.string,
        countryISO            : PropTypes.number,
        countries             : PropTypes.array,
    };

    static defaultProps = {
        onStateChange         : () => console.log('onStateChange'),
        onAddTasks            : () => console.log('onAddTasks'),
        selectedCountry       : "Afghanistan",
        countryISO            : 4,
        countries             : []
    };

    state = {
        countryNames : this.props.countries.filter(x => (x.countryRegionCode < 7000)).map(x => x.countryRegionName),
    };

    componentDidMount() {
        this.setState({
            countryNames : this.props.countries.filter(x => (x.countryRegionCode < 7000)).map(x => x.countryRegionName),
        });
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onCountryChange = (value) => {
        this.props.onStateChange({
            countryISO            : this.props.countries[value]["countryRegionCode"],
            selectedCountry       : this.state.countryNames[value],
        });

        this.props.onAddTasks([
            CreateAPITask('getExploreLiSTDataFlag', {countryISO: this.props.countries[value]["countryRegionCode"], modList: [15, 3], useRawData: true},
                () => {
                    // this.onSuccessCountryChange();
                },
                (msg) => {
                    alert(RS('GB_stErrorRetrieveCountryData'));
                }
            )
        ]);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    
    render() {

        const styles = {
            comboBox: {
                marginTop: 0, //10,
                marginRight: 10,
                marginBottom: 20,
                paddingLeft: 5,
                width: 220,
                display: 'inline-block',
                color: '#05225d',
                fontWeight: 'bold',
                borderRadius: 4,
                border: '1px solid lightgray',
                borderBlockEndColor: '#f78c30'
            },
            captionStyle: {
                marginTop: 25,
                color: '#f78c30'
            }
        };

        let countryIdx = 0;
        for (let i = 0; i < this.props.countries.length; i++) {
            if (this.props.countryISO === this.props.countries[i]["countryRegionCode"]){
                countryIdx = i;
            }
        }

        return (
            <TComboBox
                caption      = ""
                itemIndex    = {countryIdx}
                onChange     = {this.onCountryChange}
                style        = {styles.comboBox}
                captionStyle = {styles.captionStyle}
                items        = {this.state.countryNames}
            >
            </TComboBox>
        );
    }

}

export default CountryDropdown;