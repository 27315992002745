import React from "react";
import PropTypes from "prop-types";
import TMultiChart from "@common/components/TMultiChart";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import GBStdChart from "../../../../../../components/GB/GBStdChart";
import { getMatCODName, getSBCODName, getChildCODName, getAdolFullName2 } from "utilities/CS/CSDataUtil";
import TabbedCharts from "./TabbedCharts";
import TabbedTables from "../TabbedTables";
import { generateCODDisplay } from "../GenerateCODShowData";
import { CSEditor } from "../../../../../../components/CS/CSEditors";
import { CoverageEditor } from "../../../../../../editors/CS/data/Coverage/CS/CoverageEditor";
import { FPCPREditor } from "../../../../../../editors/CS/data/Coverage/FP/FPCPREditor";
import { FPMethodMixEditor } from "../../../../../../editors/CS/data/Coverage/FP/FPMethodMixEditor";
import { FPProximateDeterminantsEditor } from "../../../../../../editors/CS/data/Coverage/FP/FPProximateDeterminantsEditor";
import { HVCoverageEditor } from "../../../../../../editors/CS/data/Coverage/HVCoverageEditor";
import { StuntWastEditor } from "../../../../../../editors/CS/data/Coverage/CS/StuntWastEditor";
import { MatEfficacyEditor } from "../../../../../../editors/CS/data/Effectiveness/MatEfficacyEditor";
import { SBEfficacyEditor } from "../../../../../../editors/CS/data/Effectiveness/SBEfficacyEditor";
import { EfficacyEditor } from "../../../../../../editors/CS/data/Effectiveness/EfficacyEditor";
import { NutritionalDeficienciesEditor } from "../../../../../../editors/CS/data/HealthStatus/ChildHealth/NutritionalDeficienciesEditor";
import { IncidenceEditor } from "../../../../../../editors/CS/data/HealthStatus/ChildHealth/IncidenceEditor";
import { AbortionIncRatioEditor } from "../../../../../../editors/CS/data/HealthStatus/Abortion/AbortIncRatioEditor";
import { PercPregEndAbortEditor } from "../../../../../../editors/CS/data/HealthStatus/Abortion/PercPregEndAbortEditor";
import { StatusAtBirthEditor } from "../../../../../../editors/CS/data/HealthStatus/ChildHealth/StatusAtBirthEditor";
import { SevereAnemiaEditor } from "../../../../../../editors/CS/data/HealthStatus/MaternalHealth/SevereAnemiaEditor";
import { PercWom15t49LowBMIEditor } from "../../../../../../editors/CS/data/HealthStatus/MaternalHealth/PercWom15t49LowBMIEditor";
import { PercExposedFalciparumEditor } from "../../../../../../editors/CS/data/HealthStatus/MaternalHealth/PercExposedFalciparumEditor";
import { MaternalAnemiaEditor } from "../../../../../../editors/CS/data/HealthStatus/MaternalHealth/MaternalAnemiaEditor";
import { PathogenEditor } from "../../../../../../editors/CS/data/HealthStatus/Pathogens/PathogenEditor";
import { AvgHouseholdSizeEditor } from "../../../../../../editors/CS/data/HealthStatus/HouseholdStatus/AvgHouseholdSizeEditor";
import { PerCapitaIncEditor } from "../../../../../../editors/CS/data/HealthStatus/HouseholdStatus/PerCapitaIncEditor";
import { DiarrIncEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/EffOnIncidence/Diarrhea/DiarrIncEditor";
import { RelRiskBFDiarrEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/EffOnIncidence/Diarrhea/RelRiskBFDiarrEditor";
import { PneumIncEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/EffOnIncidence/Pneum/PneumIncEditor";
import { RelRiskBFPneumEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/EffOnIncidence/Pneum/RelRiskBFPneumEditor";
import { RelRiskBFMeningEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/EffOnIncidence/Mening/RelRiskBFMeningEditor";
import { MeninIncEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/EffOnIncidence/Mening/MeninIncEditor";
import { HerdEffectivenessEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/HerdEffectivenessEditor";
import { MatAgeAndBirthOrderEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/MatAgeAndBirthOrderEditor";
import { BirthIntervalsEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/BirthIntervalsEditor";
import { MaternalNutritionEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/MaternalNutritionEditor";
import { ImpactsOnStuntingEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnStuntingEditor";
import { ImpactsOnWastingEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnWastingEditor";
import { ImpactsOnMaternalAnemiaEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnMaternalAnemiaEditor";
import { LowBirthWeightFactorsEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/LowBirthWeightFactorsEditor";
import { ImpactPromoAgeAppropriateBFEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBF/ImpactPromoAgeAppropriateBFEditor";
import { ImpactPromoEarlyInitBFEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBF/ImpactPromoEarlyInitBFEditor";
import { ImpactsAnemiaMatMortEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsAnemiaMatMortEditor";
import { ImpactsBirthOutcomesOnMortEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBirthOutcomesOnMortEditor";
import { ImpactsStuntOnMortEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsStuntOnMortEditor";
import { ImpactsWasteOnMortEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsWasteOnMortEditor";
import { ImpactsBFOnMortPNNEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBFOnMort/ImpactsBFOnMortPNNEditor";
import { ImpactsBFOnMortNNEditor } from "../../../../../../editors/CS/data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBFOnMort/ImpactsBFOnMortNNEditor";
import { addPackTableRow } from "../../../../../../utilities/GB/GBUtil";

class ShowDataContent extends React.Component {
    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange: PropTypes.func,
        onSetSource: PropTypes.func,
        exploreShowDataItem: PropTypes.object,
        IVInfo: PropTypes.arrayOf(PropTypes.object),
        inputModvars: PropTypes.arrayOf(PropTypes.object),
        passData: PropTypes.func,
        tableRef: PropTypes.any,
        language: PropTypes.number,
        selectedCountry: PropTypes.string,
        tableTitle: PropTypes.string,
    };

    static defaultProps = {
        onStateChange: () => console.log("onStateChange"),
        onSetSource: () => console.log("onSetSource"),
        exploreShowDataItem: {},
        IVInfo: [],
        inputModvars: [],
        passData: () => console.log("passData"),
        tableRef: null,
        language: CONSTANTS.GB_English,
        selectedCountry: PropTypes.string,
        tableTitle: "",
    };

    constructor(props) {
        super(props);

        this.state = {
            tableTitle: this.props.tableTitle,
        };

        this.chart = React.createRef();
    }

    componentDidMount() {
        this.props.passData({ chart: this.chart });
        this.props.onSetSource(this.getDisplayDataArray());
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Black magic!  Compare the display variable without checking the inputmodvars since those do not change!
        let x = this.props.exploreShowDataItem;
        // var {inputModvars, ...a} = x;

        let y = nextProps.exploreShowDataItem;
        // var {inputModvars, ...b} = y;

        // We do a deep compare on the object since object references change when we do a setState
        if (
            JSON.stringify(this.props.exploreShowDataItem) !== JSON.stringify(nextProps.exploreShowDataItem) ||
            this.props.IVInfo !== nextProps.IVInfo ||
            this.props.inputModvars !== nextProps.inputModvars ||
            this.props.passData !== nextProps.passData ||
            this.props.tableRef !== nextProps.tableRef ||
            this.props.language !== nextProps.language ||
            this.props.selectedCountry !== nextProps.selectedCountry ||
            this.props.tableTitle !== nextProps.tableTitle ||
            JSON.stringify(x) !== JSON.stringify(y)
        ) {
            return true;
        } else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    getTabNames = () => {
        const tabs = this.props.exploreShowDataItem["tabs"]; //.map(x => RS(x) || x);
        return tabs;
    };

    getDelphiColorToHex = (color) => {
        if (color >= 0) {
            let hexString = color.toString(16);
            hexString = hexString.padStart(6, 0);

            let R = hexString.slice(-2);
            let G = hexString.slice(-4, -2);
            let B = hexString.slice(-6, -4);

            return "#" + (R + G + B);
        } else {
            return "";
        }
    };

    getDisplayDataArray = () => {
        let display = [];
        let tabbedChart = "";
        let IVInfo = this.props.IVInfo;
        let CSModvars = this.props.inputModvars;
        let firstYear = this.props.exploreShowDataItem["firstYear"];
        let finalYear = this.props.exploreShowDataItem["finalYear"];
        let mstID = this.props.exploreShowDataItem["mstID"];
        let editorID = this.props.exploreShowDataItem["editorID"];

        let selectedCountry = this.props.selectedCountry; // this.props.exploreShowDataItem.selectedCountry;

        switch (this.props.exploreShowDataItem["mode"]) {
            case CONSTANTS.CS_ExploreSnapshotDataMode: {
                display = [];

                if (display.length > 0) {
                    display[CONSTANTS.CS_PackTable].Title =
                        display[CONSTANTS.CS_PackTable].Title + " " + RS("GB_stFor") + " " + selectedCountry;
                    display[CONSTANTS.CS_PackChart].title = display[CONSTANTS.CS_PackTable].Title;
                }
                break;
            }
            case CONSTANTS.CS_ExploreInterventionCoverageMode: {
                let projInfo = {
                    firstYear: firstYear,
                    calcYear: firstYear,
                    finalYear: finalYear,
                    projName: this.props.projectionName,
                };

                let ageIdx = CONSTANTS.FP_A15_19;
                let method = CONSTANTS.HA_FirstMethod;
                let HIVAdolIntervsSelected = this.props.exploreShowDataItem["HIVAdolIntervsSelected"];

                let AgeGroupOption = CONSTANTS.FP_Five_Year_Age_Groups;
                let inputModvars = this.props.inputModvars.map((x) =>
                    x.tag === CONSTANTS.FP_TG_TAgeGroupOption_MV ? { ...x, value: AgeGroupOption } : x
                );
                CSModvars = inputModvars;

                switch (editorID) {
                    case CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID:
                        // Make sure to replace the intervention list correctly for Coverage tables, or else no rows will show.
                        let obj = { intArray: this.props.exploreShowDataItem.selectedIVSet };
                        let inputModvars = this.props.inputModvars.map((x) =>
                            x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV
                                ? { ...x, value: JSON.parse(JSON.stringify(obj)) }
                                : x
                        );
                        CSModvars = inputModvars;

                        display = CoverageEditor.Create(
                            IVInfo,
                            CSModvars,
                            this.props.language,
                            firstYear,
                            finalYear,
                            false,
                            true,
                            false,
                            false
                        );
                        break;
                    case CONSTANTS.FP_mstPrevSheetID:
                        display = FPCPREditor.Create(CSModvars, projInfo, ageIdx);
                        break;
                    case CONSTANTS.FP_mstMethMixSheetID:
                        display = FPMethodMixEditor.Create(CSModvars, projInfo, ageIdx, CONSTANTS.FP_all_need);
                        break;
                    case CONSTANTS.FP_mstProxDetSheetID:
                        display = FPProximateDeterminantsEditor.Create(
                            CSModvars,
                            projInfo,
                            ageIdx,
                            CONSTANTS.FP_all_need
                        );
                        break;
                    case CONSTANTS.HV_mstCoverageSheetID:
                        display = HVCoverageEditor.Create(
                            IVInfo,
                            CSModvars,
                            this.props.language,
                            projInfo,
                            method,
                            HIVAdolIntervsSelected
                        );
                        break;
                }

                let outcome = "";
                switch (this.props.exploreShowDataItem["outcome"]) {
                    case CONSTANTS.CS_MstMaternalMortalityExData: {
                        outcome = "GB_stMaternal";
                        break;
                    }
                    case CONSTANTS.CS_MstStillbirthsExData: {
                        outcome = "GB_stStillbirth";
                        break;
                    }
                    case CONSTANTS.CS_MstNeonatalMortalityExData: {
                        outcome = "GB_stNeonatal0_1Month";
                        break;
                    }
                    case CONSTANTS.CS_MstChildMortalityExData: {
                        outcome = "GB_stChildren1_59Months";
                        break;
                    }
                    case CONSTANTS.CS_MstAdolescentsExData: {
                        outcome = "GB_stAdolescentsCOD";
                        break;
                    }
                    default: {
                        break;
                    }
                }

                display[CONSTANTS.CS_PackTable].Title =
                    RS(outcome) +
                    ": " +
                    display[CONSTANTS.CS_PackTable].Title +
                    " " +
                    RS("GB_stIn") +
                    " " +
                    selectedCountry;
                display[CONSTANTS.CS_PackChart].title = display[CONSTANTS.CS_PackTable].Title;
                break;
            }
            case CONSTANTS.CS_ExploreCausesOfDeathMode: {
                display = generateCODDisplay(this.props.exploreShowDataItem, this.props.inputModvars);
                display[CONSTANTS.CS_PackChart].manualMaxY = 100;
                display[CONSTANTS.CS_PackChart].manualStackedMaxY = 100;
                break;
            }
            case CONSTANTS.CS_ExploreEffectivenessValuesMode: {
                let cod = this.props.exploreShowDataItem["cod"];
                let codName = "";
                let codArr = [
                    CONSTANTS.CS_DPT_Herd,
                    CONSTANTS.CS_Hib_Herd,
                    CONSTANTS.CS_Measles_Herd,
                    CONSTANTS.CS_Pneumococcal_Herd,
                    CONSTANTS.CS_MeningococcalA_Herd,
                    CONSTANTS.CS_Rotavirus_Herd,
                    CONSTANTS.CS_Bednets_Herd,
                    CONSTANTS.CS_Malaria_Herd,
                ];
                tabbedChart = "EffectivenessValues";
                switch (editorID) {
                    case CONSTANTS.CS_MstMatIntEfficacySheetID:
                        display = MatEfficacyEditor.Create(IVInfo, CSModvars, this.props.language, cod, false, true);
                        codName = getMatCODName(cod);
                        break;
                    case CONSTANTS.CS_MstSBIntEfficacySheetID:
                        display = SBEfficacyEditor.Create(IVInfo, CSModvars, this.props.language, cod, false, true);
                        codName = getSBCODName(cod);
                        break;
                    case CONSTANTS.CS_MstNNIntEfficacySheetID:
                        display = EfficacyEditor.Create(
                            IVInfo,
                            CSModvars,
                            this.props.language,
                            CONSTANTS.CS_MstEfficacyNeonatal,
                            cod,
                            false,
                            true
                        );
                        codName = getChildCODName(cod);
                        break;
                    case CONSTANTS.CS_MstPNNIntEfficacySheetID:
                        display = [
                            EfficacyEditor.Create(
                                IVInfo,
                                CSModvars,
                                this.props.language,
                                CONSTANTS.CS_MstEfficacyPostNeonatal,
                                cod,
                                false,
                                true
                            )[CONSTANTS.CS_PackChart],
                            [
                                EfficacyEditor.Create(
                                    IVInfo,
                                    CSModvars,
                                    this.props.language,
                                    CONSTANTS.CS_MstEfficacyPostNeonatal,
                                    cod,
                                    false,
                                    true,
                                    CONSTANTS.CS_1t6months
                                )[CONSTANTS.CS_PackTable],
                                EfficacyEditor.Create(
                                    IVInfo,
                                    CSModvars,
                                    this.props.language,
                                    CONSTANTS.CS_MstEfficacyPostNeonatal,
                                    cod,
                                    false,
                                    true,
                                    CONSTANTS.CS_6t12months
                                )[CONSTANTS.CS_PackTable],
                                EfficacyEditor.Create(
                                    IVInfo,
                                    CSModvars,
                                    this.props.language,
                                    CONSTANTS.CS_MstEfficacyPostNeonatal,
                                    cod,
                                    false,
                                    true,
                                    CONSTANTS.CS_12t24months
                                )[CONSTANTS.CS_PackTable],
                                EfficacyEditor.Create(
                                    IVInfo,
                                    CSModvars,
                                    this.props.language,
                                    CONSTANTS.CS_MstEfficacyPostNeonatal,
                                    cod,
                                    false,
                                    true,
                                    CONSTANTS.CS_24t60months
                                )[CONSTANTS.CS_PackTable],
                            ],
                        ];
                        codName = getChildCODName(cod);
                        break;

                    case CONSTANTS.CS_MstDiarrIncReductionSheetID:
                        display = DiarrIncEditor.Create(this.props.IVInfo, CSModvars);
                        break;
                    case CONSTANTS.CS_MstRelRisksDiarrheaSheetID:
                        display = RelRiskBFDiarrEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstPneumIncReductionSheetID:
                        display = PneumIncEditor.Create(this.props.IVInfo, CSModvars);
                        break;
                    case CONSTANTS.CS_MstRelRisksPneumoniaSheetID:
                        display = RelRiskBFPneumEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstMeningIncReductionSheetID:
                        display = MeninIncEditor.Create(this.props.IVInfo, CSModvars);
                        break;
                    case CONSTANTS.CS_MstRelRisksMeningitisSheetID:
                        display = RelRiskBFMeningEditor.Create(CSModvars);
                        break;

                    case CONSTANTS.CS_MstVaccineEffSheetID:
                        display = HerdEffectivenessEditor.Create(CSModvars, codArr[cod], true);
                        break;

                    case CONSTANTS.CS_MstAgeAndBirthOrderSheetID:
                        display = MatAgeAndBirthOrderEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstBirthIntervalsSheetID:
                        display = BirthIntervalsEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstMaternalNutritionSheetID:
                        display = MaternalNutritionEditor.Create(IVInfo, CSModvars, this.props.language);
                        break;
                    case CONSTANTS.CS_MstImpactsOnStuntingSheetID:
                        display = ImpactsOnStuntingEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstImpactsOnSevereWastingSheetID:
                        display = ImpactsOnWastingEditor.Create(CSModvars);
                        break;
                    // case CONSTANTS.CS_MstImpactsOnModerateWastingSheetID : display = this.splitWasting(ImpactsOnWastingEditor.Create(CSModvars), cod); break;
                    // case CONSTANTS.CS_MstImpactCompFeedWastingSheetID    : display = this.splitWasting(ImpactsOnWastingEditor.Create(CSModvars), cod); break;
                    case CONSTANTS.CS_MstImpactsOnMatAnemiaSheetID:
                        display = ImpactsOnMaternalAnemiaEditor.Create(IVInfo, CSModvars, this.props.language);
                        break;
                    case CONSTANTS.CS_MstImpactBFImprovementSheetID:
                        display = ImpactPromoAgeAppropriateBFEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstImpactEarlyInitBFSheetID:
                        display = ImpactPromoEarlyInitBFEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstLBWFSheetID:
                        display = LowBirthWeightFactorsEditor.Create(CSModvars);
                        break;

                    case CONSTANTS.CS_MstRelRisksStuntingSheetID:
                        display = ImpactsStuntOnMortEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstRelRisksWastingSheetID:
                        display = ImpactsWasteOnMortEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstRelRisksIUGR_LBWSheetID:
                        display = ImpactsBirthOutcomesOnMortEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstImpactBFOnMortNNSheetID:
                        display = ImpactsBFOnMortNNEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstImpactBFOnMortPNNSheetID:
                        display = ImpactsBFOnMortPNNEditor.Create(CSModvars);
                        break;
                    case CONSTANTS.CS_MstOddsRatioAnemiaSheetID:
                        display = ImpactsAnemiaMatMortEditor.Create(CSModvars);
                        break;
                    default:
                        display = [];
                        break;
                }

                if (editorID === CONSTANTS.CS_MstPNNIntEfficacySheetID) {
                    tabbedChart = "EffectivenessValues";
                    let tabs = [];
                    for (let i = 0; i < display[1].length; i++) {
                        let pt = display[1][i];
                        tabs.push(pt.tableData.value[0][1]);
                    }
                    this.props.exploreShowDataItem["tabs"] = tabs;
                }

                // if (display.length > 0) {
                //     display[CONSTANTS.CS_PackTable].Title = display[CONSTANTS.CS_PackTable].Title + ' ' + RS('GB_stIn') + ' ' + selectedCountry +
                //         ((display[CONSTANTS.CS_PackTable].tableData.value.length > 2) ? (' (' + display[CONSTANTS.CS_PackTable].tableData.value[2][0] + ')') : '');
                //     display[CONSTANTS.CS_PackChart].title = display[CONSTANTS.CS_PackTable].Title;
                // }

                if (display.length > 0) {
                    let title = display[CONSTANTS.CS_PackTable].Title
                        ? display[CONSTANTS.CS_PackTable].Title + " " + RS("GB_stFor") + " " + selectedCountry
                        : display[CONSTANTS.CS_PackTable][0].Title + " " + RS("GB_stFor") + " " + selectedCountry;
                    if (Array.isArray(display[CONSTANTS.CS_PackTable])) {
                        for (let i = 0; i < display[CONSTANTS.CS_PackTable].length; i++) {
                            display[CONSTANTS.CS_PackTable][i].Title = title;
                        }
                    } else {
                        display[CONSTANTS.CS_PackTable].Title = title;
                    }
                    display[CONSTANTS.CS_PackChart].title = title;
                }

                break;
            }
            case CONSTANTS.CS_ExploreHealthStatusMode: {
                let subnatMode = false;
                switch (mstID) {
                    case CONSTANTS.CS_MstNutritionalDeficiencies_ED:
                        display = NutritionalDeficienciesEditor.Create(CSModvars, firstYear, finalYear);
                        break;
                    case CONSTANTS.CS_MstStatusAtBirth_ED:
                        display = StatusAtBirthEditor.Create(CSModvars, firstYear, finalYear);
                        break;
                    case CONSTANTS.CS_MstIncidence_ED:
                        display = [
                            IncidenceEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackChart],
                            [
                                IncidenceEditor.Create(CSModvars, firstYear, finalYear, CONSTANTS.CS_0t1months)[
                                    CONSTANTS.CS_PackTable
                                ],
                                IncidenceEditor.Create(CSModvars, firstYear, finalYear, CONSTANTS.CS_1t6months)[
                                    CONSTANTS.CS_PackTable
                                ],
                                IncidenceEditor.Create(CSModvars, firstYear, finalYear, CONSTANTS.CS_6t12months)[
                                    CONSTANTS.CS_PackTable
                                ],
                                IncidenceEditor.Create(CSModvars, firstYear, finalYear, CONSTANTS.CS_12t24months)[
                                    CONSTANTS.CS_PackTable
                                ],
                                IncidenceEditor.Create(CSModvars, firstYear, finalYear, CONSTANTS.CS_24t60months)[
                                    CONSTANTS.CS_PackTable
                                ],
                            ],
                        ];
                        tabbedChart = "Incidence";
                        break;
                    // case CONSTANTS.CS_MstIncidence_ED                : display = IncidenceEditor.Create(CSModvars, firstYear, finalYear); tabbedChart="Incidence"; break;
                    case CONSTANTS.CS_MstFaliciparum_ED:
                        display = PercExposedFalciparumEditor.Create(CSModvars, false, firstYear, finalYear);
                        break;
                    case CONSTANTS.CS_MstAnemiaWRA_ED:
                        display = MaternalAnemiaEditor.Create(CSModvars, firstYear, finalYear);
                        break;
                    case CONSTANTS.CS_MstSevereAnemia_ED:
                        display = SevereAnemiaEditor.Create(CSModvars, firstYear, finalYear);
                        break;
                    case CONSTANTS.CS_MstLowBMIAmongWRA_ED:
                        display = PercWom15t49LowBMIEditor.Create(CSModvars, firstYear, finalYear);
                        break;
                    case CONSTANTS.CS_MstStuntingDist_ED:
                        display = StuntWastEditor.Create(
                            CSModvars,
                            firstYear,
                            finalYear,
                            CONSTANTS.CS_GroupMstID_Stunting,
                            subnatMode,
                            true
                        );
                        break;
                    case CONSTANTS.CS_MstWastingDist_ED:
                        display = StuntWastEditor.Create(
                            CSModvars,
                            firstYear,
                            finalYear,
                            CONSTANTS.CS_GroupMstID_Wasting,
                            subnatMode,
                            true
                        );
                        break;
                    case CONSTANTS.CS_MstDistAllCases_ED:
                        display = [
                            PathogenEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackChart],
                            [
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_All,
                                    CONSTANTS.CS_1t6months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_All,
                                    CONSTANTS.CS_6t12months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_All,
                                    CONSTANTS.CS_12t24months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_All,
                                    CONSTANTS.CS_24t60months,
                                ])[CONSTANTS.CS_PackTable],
                            ],
                        ];
                        tabbedChart = "PathogenAll";
                        break;
                    case CONSTANTS.CS_MstDistSevereCases_ED:
                        display = [
                            PathogenEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackChart],
                            [
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Sevr,
                                    CONSTANTS.CS_1t6months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Sevr,
                                    CONSTANTS.CS_6t12months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Sevr,
                                    CONSTANTS.CS_12t24months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Sevr,
                                    CONSTANTS.CS_24t60months,
                                ])[CONSTANTS.CS_PackTable],
                            ],
                        ];
                        tabbedChart = "PathogenSevere";
                        break;
                    case CONSTANTS.CS_MstDistFatalCases_ED:
                        display = [
                            PathogenEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackChart],
                            [
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Fatal,
                                    CONSTANTS.CS_1t6months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Fatal,
                                    CONSTANTS.CS_6t12months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Fatal,
                                    CONSTANTS.CS_12t24months,
                                ])[CONSTANTS.CS_PackTable],
                                PathogenEditor.Create(CSModvars, firstYear, finalYear, [
                                    CONSTANTS.CS_Distr_Fatal,
                                    CONSTANTS.CS_24t60months,
                                ])[CONSTANTS.CS_PackTable],
                            ],
                        ];
                        tabbedChart = "PathogenFatal";
                        break;
                    // case CONSTANTS.CS_MstDistAllCases_ED             : display = PathogenEditor.Create(CSModvars, firstYear, finalYear); tabbedChart="PathogenAll"; break;
                    // case CONSTANTS.CS_MstDistSevereCases_ED          : display = PathogenEditor.Create(CSModvars, firstYear, finalYear); tabbedChart="PathogenSevere"; break;
                    // case CONSTANTS.CS_MstDistFatalCases_ED           : display = PathogenEditor.Create(CSModvars, firstYear, finalYear); tabbedChart="PathogenFatal"; break;
                    case CONSTANTS.CS_MstSpontaneousAbortion_ED:
                        display = PercPregEndAbortEditor.Create(CSModvars, firstYear, finalYear);
                        break;
                    case CONSTANTS.CS_MstAbortionIncidenceRatio_ED:
                        display = AbortionIncRatioEditor.Create(CSModvars, firstYear, finalYear, false, true);
                        break;
                    case CONSTANTS.CS_MstPovertyFoodSecurity_ED:
                        display = PerCapitaIncEditor.Create(CSModvars, firstYear, finalYear, true);
                        break;
                    case CONSTANTS.CS_MstAveHouseholdSize_ED:
                        display = AvgHouseholdSizeEditor.Create(CSModvars, firstYear, finalYear, true);
                        break;
                    default:
                        display = [];
                        break;
                }

                if (display.length > 0) {
                    let title = display[CONSTANTS.CS_PackTable].Title
                        ? display[CONSTANTS.CS_PackTable].Title + " " + RS("GB_stFor") + " " + selectedCountry
                        : display[CONSTANTS.CS_PackTable][0].Title + " " + RS("GB_stFor") + " " + selectedCountry;
                    if (tabbedChart === "PathogenAll") {
                        title += " (" + RS("GB_stDistAllCases") + ")";
                    } else if (tabbedChart === "PathogenSevere") {
                        title += " (" + RS("GB_stDistSevereCases") + ")";
                    } else if (tabbedChart === "PathogenFatal") {
                        title += " (" + RS("GB_stDistFatalCases") + ")";
                    }
                    if (Array.isArray(display[CONSTANTS.CS_PackTable])) {
                        for (let i = 0; i < display[CONSTANTS.CS_PackTable].length; i++) {
                            display[CONSTANTS.CS_PackTable][i].Title = title;
                        }
                    } else {
                        display[CONSTANTS.CS_PackTable].Title = title;
                    }
                    display[CONSTANTS.CS_PackChart].title = title;
                }

                if (mstID === CONSTANTS.CS_MstStuntingDist_ED || mstID === CONSTANTS.CS_MstWastingDist_ED) {
                    tabbedChart = "StuntingWasting";
                    let titleRow = display[CONSTANTS.CS_PackTable].tableData.value.shift();
                    let counter = 0;
                    let packTab = [];
                    let tab = [titleRow];
                    let tabs = [];
                    display[CONSTANTS.CS_PackTable].tableData.value.forEach((row) => {
                        counter++;
                        if (counter === 7) {
                            counter = 1;
                        }
                        if (counter === 1) {
                            tabs.push(row[0]);
                        }
                        tab.push(row);
                        if (counter === 6) {
                            let datVal = JSON.parse(JSON.stringify(display[CONSTANTS.CS_PackTable]));
                            datVal.tableData.value = tab;
                            packTab.push(datVal);
                            tab = [titleRow];
                        }
                    });
                    display[CONSTANTS.CS_PackTable] = packTab;
                    this.props.exploreShowDataItem["tabs"] = tabs;
                }

                //removing Total from charts
                if (display[CONSTANTS.CS_PackChart].length > 1) {
                    for (let j = 0; j < display[CONSTANTS.CS_PackChart].length; j++) {
                        for (let i = display[CONSTANTS.CS_PackChart][j].subsetLabels.length - 1; i >= 0; i--) {
                            if (display[CONSTANTS.CS_PackChart][j].subsetLabels[i] === RS("GB_stTotal")) {
                                display[CONSTANTS.CS_PackChart][j].subsetLabels.splice(i, 1);
                                display[CONSTANTS.CS_PackChart][j].chartData.splice(i, 1);
                            }
                        }
                    }
                } else {
                    for (let i = display[CONSTANTS.CS_PackChart].subsetLabels.length - 1; i >= 0; i--) {
                        if (display[CONSTANTS.CS_PackChart].subsetLabels[i] === RS("GB_stTotal")) {
                            display[CONSTANTS.CS_PackChart].subsetLabels.splice(i, 1);
                            display[CONSTANTS.CS_PackChart].chartData.splice(i, 1);
                        }
                    }
                }

                if (
                    tabbedChart === "StuntingWasting" &&
                    this.props.exploreShowDataItem["selectedDisplayType"] !== CONSTANTS.CS_TableChart
                ) {
                    display[CONSTANTS.CS_PackTable].forEach((pt) => {
                        pt.tableData.value.splice(1, 1);
                    });
                }

                if (
                    display[CONSTANTS.CS_PackTable].length > 1 &&
                    this.props.exploreShowDataItem["selectedDisplayType"] !== CONSTANTS.CS_TableChart
                ) {
                    for (let j = 0; j < display[CONSTANTS.CS_PackTable].length; j++) {
                        for (let i = display[CONSTANTS.CS_PackTable][j].tableData.value.length - 1; i >= 0; i--) {
                            if (display[CONSTANTS.CS_PackTable][j].tableData.value[i][0] === RS("GB_stTotal")) {
                                display[CONSTANTS.CS_PackTable][j].tableData.value.splice(i, 1);
                            }
                        }
                    }
                }

                break;
            }
            default: {
                break;
            }
        }
        display.push(tabbedChart);

        return display;
    };

    getChartOptions = (packChart) => {
        if (this.props.exploreShowDataItem["mode"] === CONSTANTS.CS_ExploreInterventionCoverageMode) {
            //this serves to keep the scatter plot visible and its tooltip showing the data we want
            let options = {
                plotOptions: {
                    series: {
                        marker: {
                            enabled: true,
                        },
                    },
                    scatter: {
                        tooltip: {
                            pointFormat: "{point.y}",
                            valueDecimals: 2,
                        },
                    },
                },
            };

            let mstID = this.props.exploreShowDataItem["mstID"];

            if (mstID == CONSTANTS.FP_mstMethMixSheetID) {
                options["yAxis"] = [
                    {
                        title: {
                            text: RS("GB_stPercent"),
                        },
                        min: 0,
                        max: 100,
                        tickInterval: 10,
                    },
                ];
            }

            if ([CONSTANTS.FP_mstMethMixSheetID, CONSTANTS.FP_mstPrevSheetID].includes(mstID)) {
                options["tooltip"] = {
                    valueDecimals: 1,
                };
            }

            return options;

            // if (mstID == CONSTANTS.FP_mstPrevSheetID){
            //     options['tooltip'] = [{
            //         valueDecimals: 1,
            //     }]
            // }

            // if ([CONSTANTS.FP_mstMethMixSheetID].includes(mstID)){
            //     const getSeries= (pc) => {
            //         const { subsetLabels, subsetColors, SubsetsToLegend, chartData } = pc;

            //         let series = chartData.map( (arr2, subset) => {
            //             return {
            //                 name: subsetLabels[subset],
            //                 color: this.getDelphiColorToHex(subsetColors[subset]),
            //                 index: SubsetsToLegend[subset],
            //                 visible: !(SubsetsToLegend[subset] === -1),
            //                 data: chartData[subset],
            //                 ttData: chartData[subset],
            //                 type: CONSTANTS.CS_ColumnChart,
            //             };
            //         });

            //         return series;
            //     };

            //     let options2 = ({
            //         plotOptions:options.plotOptions,
            //         yAxis: [{
            //             min : 0,
            //             max : 100,
            //             tickInterval : 10,
            //         }],
            //         tooltip: {
            //             valueDecimals: 1,
            //             formatter : function() {

            //                 let name = this.series.name;
            //                 if (name === RS('GB_stStandardDailyRegimen')){
            //                     name = 'Pill: ' + name;
            //                 }

            //                 return name;
            //             }
            //         },

            //         series:getSeries(packChart),
            //     });

            //     return (options2);
            // }

            // return (options);
        } else if (
            this.props.exploreShowDataItem["selectedDisplayType"] === CONSTANTS.CS_ColumnChart &&
            this.props.exploreShowDataItem["mode"] === CONSTANTS.CS_ExploreCausesOfDeathMode
        ) {
            return this.getDualAxisChartOptions(packChart);
        } else if (this.props.exploreShowDataItem["tabs"].length !== 0) {
            return this.getTabbedChartOptions(packChart);
        } else {
            return this.getStandardChartOptions(packChart);
        }
    };

    getDualAxisChartOptions = (packChart) => {
        const RYAxisOptions = {
            title: {
                text: packChart.RYAxisLabel,
            },
            opposite: true,
            lineWidth: 0,
            gridLineWidth: 0,
            alignTicks: false,
            tickInterval: 1,
            min: 0,
        };

        if (packChart.adol) {
            RYAxisOptions["max"] = packChart.RYAxisMax;
        }

        const getSeries = (pc) => {
            const { subsetLabels, subsetMstIDs, subsetColors, SubsetsToLegend, chartData, RYAxisLabel, adol } = pc;

            let series = chartData.map((arr2, subset) => {
                if (subset === chartData.length - 1) {
                    // let chartDat = [];
                    // chartData[subset].forEach(function(datum) {
                    //     if (datum === null) {
                    //         chartDat.push(0);
                    //     } else {
                    //         chartDat.push(datum);
                    //     }
                    // });
                    return {
                        name: subsetLabels[subset],
                        color: "#000000",
                        index: SubsetsToLegend[subset],
                        visible: !(SubsetsToLegend[subset] === -1),
                        data: chartData[subset],
                        ttData: chartData[subset],
                        type: "line",
                        yAxis: 1,
                        marker: {
                            enabled: true,
                            symbol: "square",
                        },
                    };
                } else {
                    return {
                        name: subsetLabels[subset],
                        adol: adol,
                        ID: subsetMstIDs[subset],
                        color: this.getDelphiColorToHex(subsetColors[subset]),
                        index: SubsetsToLegend[subset],
                        visible: !(SubsetsToLegend[subset] === -1),
                        data: chartData[subset],
                        ttData: chartData[subset],
                        type: CONSTANTS.CS_ColumnChart,
                    };
                }
            });

            return series;
        };

        let options = {
            tooltip: {
                shared: false,
                valueDecimals:
                    packChart.subsetLabels[packChart.subsetLabels.length - 1] === "Maternal mortality ratio" ? 0 : 2,
                formatter: function () {
                    let title = "<b>" + this.point.category + "</b><br/>";

                    if (
                        this.series.name === RS("GB_stMatMortRatio") ||
                        this.series.name === RS("GB_stStillbirthRate") ||
                        this.series.name === RS("GB_stNNMortRate") ||
                        this.series.name === RS("GB_stUnder5MortRate") ||
                        this.series.name === RS("GB_stAdolMortRate")
                    ) {
                        return (
                            title +
                            '<span style="color:' +
                            this.point.color +
                            '"> ■ </span> ' +
                            this.series.name +
                            ": " +
                            this.point.y.toFixed(2)
                        );
                    } else {
                        let name = this.series.name;
                        if (this.series.userOptions.adol) {
                            name = getAdolFullName2(this.series.userOptions.ID);
                        }

                        return (
                            title +
                            '<span style="color:' +
                            this.point.color +
                            '"> ● </span> ' +
                            name +
                            ": " +
                            this.point.y.toFixed(2) +
                            " %"
                        );
                    }
                },
            },
            legend: {
                reversed: true,
            },
            yAxis: [
                {
                    title: {
                        text: RS("GB_stPercentDeaths"),
                    },
                    lineWidth: 1,
                    alignTicks: false,
                    tickInterval: 5,
                    min: 0,
                    max: 100,
                },
                RYAxisOptions,
            ],

            series: getSeries(packChart),
        };

        return options;
    };

    getStandardChartOptions = (packChart) => {
        const getSeries = (subsetLabels, subsetColors, subsetsToLegend, chartData) => {
            let series = chartData.map((arr2, subset) => {
                return {
                    name: subsetLabels[subset],
                    color: this.getDelphiColorToHex(subsetColors[subset]),
                    index: subsetsToLegend[subset],
                    visible: !(subsetsToLegend[subset] === -1),
                    data: chartData[subset],
                    ttData: chartData[subset],
                    marker: {
                        enabled: true,
                    },
                };
            });

            return series;
        };

        let options = {
            tooltip: {
                shared: false,
                formatter: function () {
                    let title = "<b>" + this.point.category + "</b><br/>";

                    if (
                        this.series.name.includes(RS("GB_stAveHouseholdSize")) ||
                        this.series.name.includes(RS("GB_stAbortionIncidenceRatio"))
                    ) {
                        return (
                            title +
                            '<span style="color:' +
                            this.point.color +
                            '"> ■ </span> ' +
                            this.series.name +
                            ": " +
                            this.point.y.toFixed(2)
                        );
                    } else {
                        return (
                            title +
                            '<span style="color:' +
                            this.point.color +
                            '"> ● </span> ' +
                            this.series.name +
                            ": " +
                            this.point.y.toFixed(2) +
                            " %"
                        );
                    }
                },
            },
            legend: {
                reversed: true,
            },
            chart:
                this.props.exploreShowDataItem["mode"] === CONSTANTS.CS_ExploreEffectivenessValuesMode
                    ? {
                          events: {
                              render() {
                                  var chart = this,
                                      rr = chart.renderer,
                                      rules = [],
                                      axis = chart.xAxis[0],
                                      rulesDef = axis.userOptions.horizontalRules,
                                      x1 = chart.plotLeft,
                                      x2 = chart.plotLeft + chart.plotWidth;

                                  // Draw rules
                                  rulesDef.forEach(function (rule, index) {
                                      var y = axis.toPixels(rule[0] + 0.5),
                                          line,
                                          label;

                                      if (!chart.horRules) {
                                          if (rule[0] !== null) {
                                              line = rr
                                                  .path(["M", x1, y, "L", x2, y, "z"])
                                                  .attr({
                                                      stroke: "#aaa",
                                                      "stroke-width": 1,
                                                      zIndex: 9,
                                                  })
                                                  .add();
                                          }
                                          label = rr
                                              .label(rule[1], 0, 0)
                                              .attr({
                                                  rotation: -90,
                                                  "text-anchor": "middle",
                                                  "dominant-baseline": "central",
                                              })
                                              .add();
                                      } else {
                                          if (rule[0] !== null) {
                                              line = chart.horRules[index].line;
                                          }
                                          label = chart.horRules[index].label;

                                          if (rule[0] !== null) {
                                              line.attr({
                                                  d: `M ${x1} ${y} L ${x2} ${y} z`,
                                              });
                                          }
                                      }

                                      // Draw labels
                                      // First
                                      if (index === 0) {
                                          label.attr({
                                              y: axis.toPixels(rule[0] / 2),
                                          });
                                          // Last
                                      } else if (index === rulesDef.length - 1) {
                                          label.attr({
                                              y:
                                                  axis.toPixels(rulesDef[index - 1][0] + 0.5) +
                                                  (axis.toPixels(axis.dataMax) -
                                                      axis.toPixels(rulesDef[index - 1][0])) /
                                                      2,
                                          });
                                          // Another
                                      } else {
                                          label.attr({
                                              y:
                                                  axis.toPixels(rulesDef[index - 1][0] + 0.5) +
                                                  (axis.toPixels(rule[0]) - axis.toPixels(rulesDef[index - 1][0])) / 2,
                                          });
                                      }
                                      rules.push({
                                          line: line,
                                          label: label,
                                      });
                                  });
                                  chart.horRules = rules;
                              },
                          },
                          marginLeft: packChart.chartLabelLeftMargin,
                      }
                    : {},

            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                    },
                },
                scatter: {
                    tooltip: {
                        pointFormat: "{point.y}",
                        valueDecimals: 2,
                    },
                },
            },

            xAxis: {
                labels: {
                    padding: 20,
                },
                horizontalRules: packChart.verticalLabels,
            },
            series: getSeries(
                packChart.subsetLabels,
                packChart.subsetColors,
                packChart.SubsetsToLegend,
                packChart.chartData
            ),
        };

        return options;
    };

    getTabbedChartOptions = (packChart) => {
        let options = {
            tooltip: {
                shared: false,
                formatter: function () {
                    let title = "<b>" + this.point.category + "</b><br/>";

                    if (this.series.name.includes(RS("DP_stIncidence"))) {
                        return (
                            title +
                            '<span style="color:' +
                            this.point.color +
                            '"> ■ </span> ' +
                            this.series.name +
                            ": " +
                            this.point.y.toFixed(2)
                        );
                    } else {
                        return (
                            title +
                            '<span style="color:' +
                            this.point.color +
                            '"> ● </span> ' +
                            this.series.name +
                            ": " +
                            this.point.y.toFixed(2) +
                            " %"
                        );
                    }
                },
            },
            legend: {
                reversed: true,
            },
            chart: {
                events: {
                    render() {
                        var chart = this,
                            rr = chart.renderer,
                            rules = [],
                            axis = chart.xAxis[0],
                            rulesDef = axis.userOptions.horizontalRules,
                            x1 = chart.plotLeft,
                            x2 = chart.plotLeft + chart.plotWidth;

                        // Draw rules
                        rulesDef.forEach(function (rule, index) {
                            var y = axis.toPixels(rule[0] + 0.5),
                                line,
                                label;

                            if (!chart.horRules) {
                                if (rule[0] !== null) {
                                    line = rr
                                        .path(["M", x1, y, "L", x2, y, "z"])
                                        .attr({
                                            stroke: "#aaa",
                                            "stroke-width": 1,
                                            zIndex: 9,
                                        })
                                        .add();
                                }
                                label = rr
                                    .label(rule[1], 0, 0)
                                    .attr({
                                        rotation: -90,
                                        "text-anchor": "middle",
                                        "dominant-baseline": "central",
                                    })
                                    .add();
                            } else {
                                if (rule[0] !== null) {
                                    line = chart.horRules[index].line;
                                }
                                label = chart.horRules[index].label;

                                if (rule[0] !== null) {
                                    line.attr({
                                        d: `M ${x1} ${y} L ${x2} ${y} z`,
                                    });
                                }
                            }

                            // Draw labels
                            // First
                            if (index === 0) {
                                label.attr({
                                    y: axis.toPixels(rule[0] / 2),
                                });
                                // Last
                            } else if (index === rulesDef.length - 1) {
                                label.attr({
                                    y:
                                        axis.toPixels(rulesDef[index - 1][0] + 0.5) +
                                        (axis.toPixels(axis.dataMax) - axis.toPixels(rulesDef[index - 1][0])) / 2,
                                });
                                // Another
                            } else {
                                label.attr({
                                    y:
                                        axis.toPixels(rulesDef[index - 1][0] + 0.5) +
                                        (axis.toPixels(rule[0]) - axis.toPixels(rulesDef[index - 1][0])) / 2,
                                });
                            }
                            rules.push({
                                line: line,
                                label: label,
                            });
                        });
                        chart.horRules = rules;
                    },
                },
                marginLeft: packChart.chartLabelLeftMargin,
            },

            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                    },
                },
                scatter: {
                    tooltip: {
                        pointFormat: "{point.y}",
                        valueDecimals: 2,
                    },
                },
            },

            xAxis: {
                labels: {
                    padding: 20,
                },
                horizontalRules: packChart.verticalLabels,
            },
        };

        return options;
    };

    getStacked = () => {
        let stacked = "";

        if (this.props.exploreShowDataItem["mode"] !== CONSTANTS.CS_ExploreEffectivenessValuesMode) {
            //if ((this.props.exploreShowDataItem['selectedDisplayType'] === CONSTANTS.CS_BarChart) || (this.props.exploreShowDataItem['selectedDisplayType'] === CONSTANTS.CS_ColumnChart)) {
            stacked = "normal";
            //}
        }

        if (this.props.exploreShowDataItem.mstID === 0 && this.props.exploreShowDataItem.editorID === 3) {
            stacked = "";
        }

        return stacked;
    };

    getReversed = () => {
        let reversed = false;

        if (
            (this.props.exploreShowDataItem["selectedDisplayType"] === CONSTANTS.CS_BarChart ||
                this.props.exploreShowDataItem["selectedDisplayType"] === CONSTANTS.CS_ColumnChart) &&
            this.props.exploreShowDataItem["mode"] !== CONSTANTS.CS_ExploreEffectivenessValuesMode
        ) {
            reversed = true;
        }

        return reversed;
    };

    showDisplay = (display, options) => {
        let showDisplay = "";
        //this.props.onSetSource(display);
        //this.props.passData({source: display[1].Source || display[1][0].Source});
        switch (this.props.exploreShowDataItem["selectedDisplayType"]) {
            case CONSTANTS.CS_TableChart:
                showDisplay =
                    this.props.exploreShowDataItem["tabs"].length === 0 ? (
                        <CSEditor
                            mstID={this.props.exploreShowDataItem["editorID"]}
                            showTitle={true}
                            isResult={true}
                            packTable={display[CONSTANTS.CS_PackTable]}
                            tableRef={this.props.tableRef}
                            sourceEditable={false}
                        />
                    ) : (
                        <TabbedTables
                            mstID={this.props.exploreShowDataItem["editorID"]}
                            tabs={this.getTabNames()}
                            tableRef={this.props.tableRef}
                            packTable={display[CONSTANTS.CS_PackTable]}
                        />
                    );
                break;

            case CONSTANTS.CS_MultiChart:
                for (let i = 0; i < display[CONSTANTS.CS_PackObjArray].length; i++) {
                    for (let j = 0; j < display[CONSTANTS.CS_PackTable].tableData.value.length; j++) {
                        if (
                            display[CONSTANTS.CS_PackTable].tableData.value[j][0].includes(
                                display[CONSTANTS.CS_PackObjArray][i].interventionName
                            ) &&
                            display[CONSTANTS.CS_PackTable].tableData.value[j][0].includes("No historical data")
                        ) {
                            display[CONSTANTS.CS_PackObjArray][i].interventionName +=
                                "<br /><span style='font-weight: normal' >No historical data</span>";
                        }
                    }
                }
                showDisplay = (
                    <TMultiChart
                        data={display[CONSTANTS.CS_PackObjArray]}
                        chartWidth={400}
                        yAxisTitle={RS("GB_stCoveragePerc")}
                        yAxisMaximum={100}
                        chartTitle={display[CONSTANTS.CS_PackChart].title}
                    />
                );
                break;

            default:
                if (this.props.exploreShowDataItem["selectedDisplayType"] === CONSTANTS.CS_ScatterChart) {
                    for (let i = 0; i < display[CONSTANTS.CS_PackChart].subsetLabels.length; i++) {
                        display[CONSTANTS.CS_PackChart].subsetLabels[i] = display[CONSTANTS.CS_PackChart].subsetLabels[
                            i
                        ].replace("--- ", "");
                    }
                }
                showDisplay =
                    this.props.exploreShowDataItem["tabs"].length === 0 ? (
                        <GBStdChart
                            id={"CDChart" + this.props.exploreShowDataItem["itemKey"]}
                            packChart={display[CONSTANTS.CS_PackChart]}
                            minHeight={325}
                            maxHeight={325}
                            options={options}
                            chartType={this.props.exploreShowDataItem["selectedDisplayType"]}
                            stacked={this.getStacked()}
                            legendReversed={this.getReversed()}
                            ref={this.chart}
                        />
                    ) : (
                        <TabbedCharts
                            id={"TabbedChart" + this.props.exploreShowDataItem["itemKey"]}
                            chartTitle={display[CONSTANTS.CS_PackChart].title}
                            chartType={this.props.exploreShowDataItem["selectedDisplayType"]}
                            display={display}
                            tabs={this.getTabNames()}
                            options={options}
                            stacked={this.getStacked()}
                            legendReversed={this.getReversed()}
                            packTable={display[CONSTANTS.CS_PackTable]}
                            tableRef={this.chart}
                            language={this.props.language}
                        />
                    );
                break;
        }

        return showDisplay;
    };

    render() {
        let options = {};
        let result = {};
        let display = this.getDisplayDataArray();
        // display[CONSTANTS.CS_PackChart].manualMaxY = 100;
        // display[CONSTANTS.CS_PackChart].manualStackedMaxY = 100;

        if (this.props.tableTitle.length > 0) {
            let clon = JSON.parse(JSON.stringify(display));
            let titleRow = [this.props.tableTitle.replace(".xlsx", "")];
            //expanding packTable to accommodate new row for title at top
            if (Array.isArray(clon[CONSTANTS.CS_PackTable])) {
                for (let i = 1; i < clon[CONSTANTS.CS_PackTable][0].tableData.value[0].length; i++) {
                    titleRow.push("");
                }
                clon[CONSTANTS.CS_PackTable].forEach((packTable) => {
                    packTable = addPackTableRow(packTable, 0);
                    packTable.tableData.value[0] = titleRow;
                });
            } else {
                for (let i = 1; i < clon[CONSTANTS.CS_PackTable].tableData.value[0].length; i++) {
                    titleRow.push("");
                }
                clon[CONSTANTS.CS_PackTable] = addPackTableRow(clon[CONSTANTS.CS_PackTable], 0);
                clon[CONSTANTS.CS_PackTable].tableData.value[0] = titleRow;
            }
            // for (let k in clon[CONSTANTS.CS_PackTable]) {
            //     if (Array.isArray(clon[CONSTANTS.CS_PackTable][k]) && clon[CONSTANTS.CS_PackTable][k].length === clon[CONSTANTS.CS_PackTable].GBRowCount) {
            //         clon[CONSTANTS.CS_PackTable][k].unshift(clon[CONSTANTS.CS_PackTable][k][0]);
            //     }
            // }
            // clon[CONSTANTS.CS_PackTable].GBRowCount = clon[CONSTANTS.CS_PackTable].GBRowCount + 1;
            // clon[CONSTANTS.CS_PackTable].tableData.value.unshift(titleRow);
            display = clon;
        }

        display.length > 0 ? (options = this.getChartOptions(display[CONSTANTS.CS_PackChart])) : (options = {});

        display.length > 0 ? (result = this.showDisplay(display, options)) : (result = null);

        return result;
    }
}

export default ShowDataContent;
