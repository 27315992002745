import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {CSGetGuidedCODContentForPercRed} from "utilities/CS/CSDataUtil";

class ResultToDisplay extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        //These are the booleans that control the intervention outcomes displayed in the intervention drawer
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        viewInterventionCost           : PropTypes.bool,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayCPR                     : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        viewInterventionCost           : false,
        language                       : CONSTANTS.GB_English
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onHandleDropdownChange = (idx) => {
        if (idx === CONSTANTS.CS_PercentReductionInMortality) {
            let numOn = 0;

            if (this.props.displayMaternalCOD) {numOn++}
            if (this.props.displayStillbirthCOD) {numOn++}
            if (this.props.displayNeonatalCOD) {numOn++}
            if (this.props.displayChildCOD) {numOn++}

            if (numOn > 1) {
                this.props.onStateChange({
                    displayMaternalCOD : true,
                    displayStillbirthCOD : false,
                    displayNeonatalCOD : false,
                    displayChildCOD : false,
                    maternalIntervSelected : true,
                    stillbirthIntervSelected : false,
                    neonatalIntervSelected : false,
                    childIntervSelected : false,
                })
            }
            this.props.onStateChange({
                MOResultToDisplay : idx
            });

        }
        else {
            this.props.onStateChange({
                MOResultToDisplay    : idx,
                //set the causes of death selected back to what they were prior to changing to percent
                displayMaternalCOD   : this.props.maternalIntervSelected,
                displayStillbirthCOD : this.props.stillbirthIntervSelected,
                displayNeonatalCOD   : this.props.neonatalIntervSelected,
                displayChildCOD      : this.props.childIntervSelected
            });
        }

        if (idx === CONSTANTS.CS_PotentialStuntingAverted) {
            this.props.onStateChange({
                viewInterventionCost : false
            })
        }

        let maternalContent = JSON.parse(JSON.stringify(this.props.maternalCausesOfDeathContent));
        let stillbirthContent = JSON.parse(JSON.stringify(this.props.stillbirthCausesOfDeathContent));
        let neonatalContent = JSON.parse(JSON.stringify(this.props.neonatalCausesOfDeathContent));
        let childContent = JSON.parse(JSON.stringify(this.props.childCausesOfDeathContent));

        if (this.props.displayMaternalCOD) {
            maternalContent = CSGetGuidedCODContentForPercRed(maternalContent)
        }

        else if (this.props.displayStillbirthCOD) {
            stillbirthContent = CSGetGuidedCODContentForPercRed(stillbirthContent)
        }

        else if (this.props.displayNeonatalCOD) {
            neonatalContent = CSGetGuidedCODContentForPercRed(neonatalContent)
        }

        else if (this.props.displayChildCOD) {
            childContent = CSGetGuidedCODContentForPercRed(childContent)
        }

        this.props.onStateChange ({
            maternalCausesOfDeathContent   : maternalContent,
            stillbirthCausesOfDeathContent : stillbirthContent,
            neonatalCausesOfDeathContent   : neonatalContent,
            childCausesOfDeathContent      : childContent,
        })
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderHeader = () => {
        let styles = {
            headerStyle: {
                color: '#000000',
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: '10px',
                marginBottom: '-10px',
                userSelect: 'none'
            }
        };

        return (
            <div>
                <p style={styles.headerStyle}>{RS('GB_stResultToDisplay')}</p>
            </div>
        );
    };

    renderDropdown = () => {

        const styles = {
            comboBox: {
                marginRight: 10,
                marginBottom: 10,
                paddingLeft: 5,
                width: 240,
                display: 'inline-block',
                color: '#05225d',
                fontSize: '14px',
                borderRadius: 4,
                border: '1px solid lightgray',
                borderBlockEndColor: '#f78c30'
            },
            captionStyle: {
                marginTop: 25,
                color: '#f78c30'
            }
        };

        return (
            <TComboBox
                caption      = ""
                itemIndex    = {this.props.MOResultToDisplay}
                onChange     = {this.onHandleDropdownChange}
                style        = {styles.comboBox}
                captionStyle = {styles.captionStyle}
                items={[
                    RS('GB_stPotentialDeathsAverted'),
                    RS('GB_stPercentRedInMort'),
                    RS('GB_stPotentialStuntingAverted')
                ]}
            >
            </TComboBox>
        )
    };

    render() {
        return (
            <div>
                {this.renderHeader()}
                {this.renderDropdown()}
            </div>
        );
    }

}

export default ResultToDisplay;