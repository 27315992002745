import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSEconStatusDrawer from "../../drawers/HealthStatus/CSEconStatusDrawer";
import {GNIPerCapEditor} from "../../data/HealthStatus/EconStatus/GNIPerCapEditor";
import {LaborShareIncEditor} from "../../data/HealthStatus/EconStatus/LaborShareIncEditor";
import {LaborForceParRateEditor} from "../../data/HealthStatus/EconStatus/LaborForceParRateEditor";
import {getModvarCloneByTag} from "utilities/GB/GBUtil";

class CSEconStatusBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange     : PropTypes.func,
        CSModvars         : PropTypes.arrayOf(PropTypes.object),
        onOpenHelpDialog  : PropTypes.func,
    };

    static defaultProps = {
        onStateChange     : () => console.log('onStateChange'),
        CSModvars         : [],
        onOpenHelpDialog  : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled   : true,
        openDrawer        : false,
        packTable1        : null,
        packTable2        : null,
        packTable3        : null,
        onCombobox1Change : 0,
        onCombobox2Change : 0,
        CSModvars         : this.props.CSModvars
    };

    getFinalYear = (CSModvars) => {
        return getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
    };

    getFirstAgeAtWorkMV = (CSModvars) => {
        return getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FirstYearAtWork_MV);
    };

    getLastAgeAtWorkMV = (CSModvars) => {
        return getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_LastYearAtWork_MV);
    };

    getCSModvarsFromPackTables = (state) =>{
        let ModvarsArray1 = GNIPerCapEditor.GetSSData(state.packTable1, state.CSModvars);
        let ModvarsArray2 = LaborShareIncEditor.GetSSData(state.packTable2, state.CSModvars);
        let ModvarsArray3 = LaborForceParRateEditor.GetSSData(state.packTable3, state.CSModvars);

        let result = state.CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            let c = ModvarsArray3.find(y => y.tag === x.tag);
            return a || b || c || x;
        });

        return result;
    };

    componentDidMount() {
        let CSModvars = this.state.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = this.getFinalYear(CSModvars);
        let LastAgeAtWork = this.getLastAgeAtWorkMV(CSModvars)["value"];
        this.setState({
            packTable1 : GNIPerCapEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
            packTable2 : LaborForceParRateEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
            packTable3 : LaborShareIncEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
        });
    }

    static getDerivedStateFromProps(props, state) {
        let CSModvars = state.CSModvars;
        let firstYear = props.firstYear;
        let finalYear = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        let LastAgeAtWork = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_LastYearAtWork_MV)["value"];
        if (!state.openDrawer) {
            return {
                packTable1 : GNIPerCapEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
                packTable2 : LaborForceParRateEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
                packTable3 : LaborShareIncEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
    };

    onCloseDrawerBtnClick = () => {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = this.getFinalYear(CSModvars);
        let LastAgeAtWork = this.getLastAgeAtWorkMV(CSModvars)["value"];
        this.setState({
            openDrawer        : false,
            saveBtnDisabled   : true,
            CSModvars         : CSModvars,
            packTable1        : GNIPerCapEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
            packTable2        : LaborForceParRateEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
            packTable3        : LaborShareIncEditor.Create(CSModvars, firstYear, finalYear, LastAgeAtWork)[CONSTANTS.CS_PackTable],
        });
    };

    onSaveBtnClick = () => {
        let CSModvars = this.getCSModvarsFromPackTables(this.state);

        this.setState({
            CSModvars : CSModvars,
            openDrawer : false,
            saveBtnDisabled : true,
        });

        this.props.onStateChange({
            unchangedProj : false,
            CSModvars : CSModvars,
        });
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable3Change = (packTable) => {
        this.setState({
            packTable3      : packTable,
            saveBtnDisabled : false,
        });
    };

    updateFirstAgeAtWork = (value) => {
        let LastYearAtWork = this.getLastAgeAtWorkMV(this.state.CSModvars)["value"];

        /* If new first age at work is greater than current last age at work, set it as both the first and last
           ages at work. */
        if (value > LastYearAtWork) {
            this.updateLastAgeAtWork(value, true)
        }

        /* Otherwise, just set the new first age at work. */
        else {
            let ModvarArray = [this.getFirstAgeAtWorkMV(this.state.CSModvars)];

            ModvarArray[0]["value"] = value;

            let CSModvars = this.state.CSModvars.map(x => {
                let a = ModvarArray.find(y => y.tag === x.tag);
                return a || x;
            });

            this.setState({
                CSModvars : CSModvars,
                saveBtnDisabled : false,
            });
        }
    };

    updateLastAgeAtWork = (value, updateFirstAge = false) => {
        /* Need to get latest data from packtables so it can be used when the tables are recreated below.  Packtables
           need to be recreated because their sizes may change as the last age of work is being updated. */
        let ptData = this.getCSModvarsFromPackTables(this.state);

        let firstYear = this.props.firstYear;
        let finalYear = this.getFinalYear(this.state.CSModvars);

        /* Create local copies of age modvar(s) to be given updated value and reinserted into state CSModvars. */
        let AgeData = [this.getLastAgeAtWorkMV(this.state.CSModvars)];

        if (updateFirstAge){
            AgeData.push(this.getFirstAgeAtWorkMV(this.state.CSModvars));
        }

        /* Set value into local last age at work modvar, and when appropriate, also the local first age at work
           modvar. */
        for (let i = 0; i < AgeData.length; i++) {
            AgeData[i]["value"] = value;
        }

        /* Combine packTable data and age data into new copy of CSModvars. */
        let CSModvars = ptData.map(x => {
            let a = AgeData.find(y => y.tag === x.tag);
            return a || x;
        });

        this.setState({
            CSModvars       : CSModvars,
            packTable1      : GNIPerCapEditor.Create(CSModvars, firstYear, finalYear, value)[CONSTANTS.CS_PackTable],
            packTable2      : LaborForceParRateEditor.Create(CSModvars, firstYear, finalYear, value)[CONSTANTS.CS_PackTable],
            packTable3      : LaborShareIncEditor.Create(CSModvars, firstYear, finalYear, value)[CONSTANTS.CS_PackTable],
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2
                caption     = {RS('GB_stEconomicStat')}
                description = ""
                onClick     = {this.onBlueBoxClick}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"economicstatus.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stEconomicStat')}
                        content         = {
                            <CSEconStatusDrawer
                                packTable1={this.state.packTable1}
                                packTable2={this.state.packTable2}
                                packTable3={this.state.packTable3}
                                onPackTable1Change={this.onPackTable1Change}
                                onPackTable2Change={this.onPackTable2Change}
                                onPackTable3Change={this.onPackTable3Change}
                                updateFirstAgeAtWork={this.updateFirstAgeAtWork}
                                updateLastAgeAtWork={this.updateLastAgeAtWork}
                                FirstAgeAtWork={this.getFirstAgeAtWorkMV(this.state.CSModvars)["value"]}
                                LastAgeAtWork={this.getLastAgeAtWorkMV(this.state.CSModvars)["value"]}
                            />
                        }
                    />
                }
            />

        );
    }

}

export default CSEconStatusBlueBox;