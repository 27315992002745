import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {FirstYearPopEditor} from "../../data/HealthStatus/Demography/FirstYearPopEditor";

class DemographyDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object,
        onPackTable1Change        : PropTypes.func,
        onPackTable2Change        : PropTypes.func,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change        : (packTable) => console.log('onPackTable2Change'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        packTable = FirstYearPopEditor.RecalcGrid(packTable);
        this.props.onPackTable1Change(packTable);
    };

    onPackTable2Change = (packTable) => {
        this.props.onPackTable2Change(packTable);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {2}
                />

                <CSEditor
                    mstID={CONSTANTS.DP_MstFirstYearPopSheetID}
                    packTable={packTable1}
                    showTitle={true}
                    onChange={this.onPackTable1Change}
                />

                <CSEditor
                    mstID={CONSTANTS.FP_MstTFRSheetID}
                    packTable={packTable2}
                    showTitle={true}
                    onChange={this.onPackTable2Change}
                />

            </div>
        );
    }

}

export default DemographyDrawer;