import { uuidv4 } from '@common/api/uuidv4';

const LANGUAGE         = window.App.LS.LANGUAGE;
const USER             = window.App.LS.USER;
const REMEMBER_ME      = window.App.LS.REMEMBER_ME;
    
const CLIENT_ID        = window.App.SS.CLIENT_ID;
const HUMAN_NAME       = window.App.SS.HUMAN_NAME;
const ORGANIZATION     = window.App.SS.ORGANIZATION;
const LINKTOKEN        = window.App.SS.LINKTOKEN;
const CONTAINERBASEURL = window.App.SS.CONTAINERBASEURL;
const TOKEN            = window.App.SS.TOKEN;

const GB_English = 1;

export const LocalStorageUtil = {

    getLanguage            : ()      => localStorage.getItem(LANGUAGE) ? Math.max(GB_English, Number(localStorage.getItem(LANGUAGE))) : GB_English,
    setLanguage            : (value) => localStorage.setItem(LANGUAGE, value),
    deleteLanguage         : ()      => localStorage.removeItem(LANGUAGE),
    
    getUser                : ()      => localStorage.getItem(USER) ? localStorage.getItem(USER) : "",
    setUser                : (value) => localStorage.setItem(USER, value),
    deleteUser             : ()      => localStorage.removeItem(USER),
    
    getRememberMe          : ()      => localStorage.getItem(REMEMBER_ME) ? localStorage.getItem(REMEMBER_ME) === "true" : false,
    setRememberMe          : (value) => localStorage.setItem(REMEMBER_ME, value),
    deleteRememberMe       : ()      => localStorage.removeItem(REMEMBER_ME),

};

export const SessionStorageUtil = {

    getClientID            : ()      => sessionStorage.getItem(CLIENT_ID) ? sessionStorage.getItem(CLIENT_ID) : '{' + uuidv4() + '}',
    setClientID            : (value) => sessionStorage.setItem(CLIENT_ID, value),
    deleteClientID         : ()      => sessionStorage.removeItem(CLIENT_ID),
    
    getHumanName           : ()      => sessionStorage.getItem(HUMAN_NAME) ? sessionStorage.getItem(HUMAN_NAME) : "",
    setHumanName           : (value) => sessionStorage.setItem(HUMAN_NAME, value),
    deleteHumanName        : ()      => sessionStorage.removeItem(HUMAN_NAME),
    
    getOrganization        : ()      => sessionStorage.getItem(ORGANIZATION) ? sessionStorage.getItem(ORGANIZATION) : "",
    setOrganization        : (value) => sessionStorage.setItem(ORGANIZATION, value),
    deleteOrganization     : ()      => sessionStorage.removeItem(ORGANIZATION),
    
    getLinkToken           : ()      => sessionStorage.getItem(LINKTOKEN) ? sessionStorage.getItem(LINKTOKEN) : "",
    setLinkToken           : (value) => sessionStorage.setItem(LINKTOKEN, value),
    deleteLinkToken        : ()      => sessionStorage.removeItem(LINKTOKEN),

    getContainerBaseURL    : ()      => sessionStorage.getItem(CONTAINERBASEURL) ? sessionStorage.getItem(CONTAINERBASEURL) : "",
    setContainerBaseURL    : (value) => sessionStorage.setItem(CONTAINERBASEURL, value),
    deleteContainerBaseURL : ()      => sessionStorage.removeItem(CONTAINERBASEURL),

    getToken               : ()      => sessionStorage.getItem(TOKEN) ? sessionStorage.getItem(TOKEN) : "",
    setToken               : (value) => sessionStorage.setItem(TOKEN, value),
    deleteToken            : ()      => sessionStorage.removeItem(TOKEN),

};

SessionStorageUtil.setClientID(SessionStorageUtil.getClientID());
LocalStorageUtil.setLanguage(LocalStorageUtil.getLanguage()); 
window.App.CLIENT_ID      = SessionStorageUtil.getClientID();
window.App.REST_CLIENT_ID = '{' + uuidv4() + '}';
window.App.languageIdx    = LocalStorageUtil.getLanguage() - 1;