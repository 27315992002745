import React from 'react';
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import { GB_ShallowCompareComponent } from "utilities/GB/GBUtil";
import ExploreExpansionPanel from "../ExploreExpansionPanel";

class IntervCovExpandPanel extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onShowDataBtnClick : PropTypes.func,
        UIData             : PropTypes.arrayOf(PropTypes.object),
        panelName          : PropTypes.string,
        showDataBtn        : PropTypes.bool,
        buttonLabel        : PropTypes.string,
        buttonWidth        : PropTypes.string,
        buttonDisabled     : PropTypes.bool,
        disabled           : PropTypes.bool,
        subPanel           : PropTypes.bool,
        defaultExpanded    : PropTypes.bool,
        panelKey           : PropTypes.string
    };

    static defaultProps = {
        onShowDataBtnClick : () => console.log('IntervCovExpandPanel onShowDataBtnClick'),
        UIData             : [],
        panelName          : '',
        showDataBtn        : true,
        buttonLabel        : RS('GB_stCreateDisplay'),
        buttonWidth        : '120px',
        buttonDisabled     : false,
        disabled           : false,
        subPanel           : false,
        defaultExpanded    : false,
        panelKey           : ''
    };

    shouldComponentUpdate(nextProps, nextState) {
        let result = JSON.stringify(nextProps.UIData) !== JSON.stringify(this.props.UIData);
        result = result && GB_ShallowCompareComponent(this.props, nextProps, this.state, nextState);
        return result;
    }

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderContent() {

        let content = this.props.UIData.map(x => {
            switch (x.type) {
                case "subGroupName" : 
                    return (
                        <div
                            key={x.key} 
                            style={x.style}
                        >
                            {x.name}
                        </div>
                    );

                case "checkBox" :
                    return (
                        <TCheckBox
                            key={x.key}
                            caption={x.caption}
                            labelStyle={x.labelStyle}
                            style={x.style}
                            boxStyle={x.boxStyle}
                            color={x.color}
                            name={x.name}
                            customProp={x.customProp}
                            customProp2={x.customProp2}
                            value={x.value}
                            onClick={x.onClick}
                        />
                    );

                case "divider" : 
                    return (
                        <Divider 
                            key={x.key} 
                            style={x.style}
                        />
                    );

                default : 
                    return undefined;
            }
        }).filter(x => x);

        return (
            <ExploreExpansionPanel
                onShowDataBtnClick={this.props.onShowDataBtnClick}
                content={content}
                panelName={this.props.panelName}
                showDataBtn={this.props.showDataBtn}
                subPanel={this.props.subPanel}
                defaultExpanded={this.props.defaultExpanded}
            />
        );
    }

    render() {
        return this.renderContent();
    }
}

export default IntervCovExpandPanel;