import React from 'react';
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {CSGetEditorCatName} from "utilities/CS/CSDataUtil";
import { uuidv4 } from "utilities/GB/GBUtil";

import { BFPrevEditor } from "./Coverage/CS/BFPrevEditor";
import { CoverageEditor } from "./Coverage/CS/CoverageEditor";
import { FertilityRisksEditor } from "./Coverage/CS/FertilityRisksEditor";
import { StuntWastEditor } from "./Coverage/CS/StuntWastEditor";

import { EfficacyEditor } from "./Effectiveness/EfficacyEditor";
import { MatEfficacyEditor } from "./Effectiveness/MatEfficacyEditor";
import { SBEfficacyEditor } from "./Effectiveness/SBEfficacyEditor";

import { AbortionIncRatioEditor } from "./HealthStatus/Abortion/AbortIncRatioEditor";
import { PercPregEndAbortEditor } from "./HealthStatus/Abortion/PercPregEndAbortEditor";

import { IncidenceEditor } from "./HealthStatus/ChildHealth/IncidenceEditor";
import { NutritionalDeficienciesEditor } from "./HealthStatus/ChildHealth/NutritionalDeficienciesEditor";
import { StatusAtBirthEditor } from "./HealthStatus/ChildHealth/StatusAtBirthEditor";

import { BaselineMortEditor } from "./HealthStatus/ChildMortality/BaselineMortEditor";
import { PercDthByCauseEditor } from "./HealthStatus/ChildMortality/PercDthByCauseEditor";

import { FirstYearPopEditor } from "./HealthStatus/Demography/FirstYearPopEditor";
import { TFREditor } from "./HealthStatus/Demography/TFREditor";

import { DiarrIncEditor } from "./HealthStatus/Effectiveness/EffOnIncidence/Diarrhea/DiarrIncEditor";
import { RelRiskBFDiarrEditor } from "./HealthStatus/Effectiveness/EffOnIncidence/Diarrhea/RelRiskBFDiarrEditor";

import { MeninIncEditor } from "./HealthStatus/Effectiveness/EffOnIncidence/Mening/MeninIncEditor";
import { RelRiskBFMeningEditor } from "./HealthStatus/Effectiveness/EffOnIncidence/Mening/RelRiskBFMeningEditor";

import { PneumIncEditor } from "./HealthStatus/Effectiveness/EffOnIncidence/Pneum/PneumIncEditor";
import { RelRiskBFPneumEditor } from "./HealthStatus/Effectiveness/EffOnIncidence/Pneum/RelRiskBFPneumEditor";

import { HerdEffectivenessEditor } from "./HealthStatus/Effectiveness/HerdEffectivenessEditor";

import { ImpactsAnemiaMatMortEditor } from "./HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsAnemiaMatMortEditor";
import { ImpactsBFOnMortNNEditor } from "./HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBFOnMort/ImpactsBFOnMortNNEditor";
import { ImpactsBFOnMortPNNEditor } from "./HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBFOnMort/ImpactsBFOnMortPNNEditor";
import { ImpactsBirthOutcomesOnMortEditor } from "./HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBirthOutcomesOnMortEditor";
import { ImpactsStuntOnMortEditor } from "./HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsStuntOnMortEditor";
import { ImpactsWasteOnMortEditor } from "./HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsWasteOnMortEditor";

import { ImpactPromoAgeAppropriateBFEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBF/ImpactPromoAgeAppropriateBFEditor";
import { ImpactPromoEarlyInitBFEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBF/ImpactPromoEarlyInitBFEditor";
import { BirthIntervalsEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/BirthIntervalsEditor";
import { MatAgeAndBirthOrderEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/MatAgeAndBirthOrderEditor";
import { MaternalNutritionEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/MaternalNutritionEditor";
import { ImpactsOnMaternalAnemiaEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnMaternalAnemiaEditor";
import { ImpactsOnStuntingEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnStuntingEditor";
import { ImpactsOnWastingEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnWastingEditor";
import { LowBirthWeightFactorsEditor } from "./HealthStatus/Effectiveness/NutritionInterventions/LowBirthWeightFactorsEditor";

import { AvgHouseholdSizeEditor } from "./HealthStatus/HouseholdStatus/AvgHouseholdSizeEditor";
import { PerCapitaIncEditor } from "./HealthStatus/HouseholdStatus/PerCapitaIncEditor";

import { MaternalAnemiaEditor } from "./HealthStatus/MaternalHealth/MaternalAnemiaEditor";
import { PercExposedFalciparumEditor } from "./HealthStatus/MaternalHealth/PercExposedFalciparumEditor";
import { PercWom15t49LowBMIEditor } from "./HealthStatus/MaternalHealth/PercWom15t49LowBMIEditor";
import { SevereAnemiaEditor } from "./HealthStatus/MaternalHealth/SevereAnemiaEditor";

import { MatMortRatioEditor } from "./HealthStatus/MaternalMortality/MatMortRatioEditor";
import { PercMatDeathsByCauseEditor } from "./HealthStatus/MaternalMortality/PercMatDeathsByCauseEditor";

import { PathogenEditor } from "./HealthStatus/Pathogens/PathogenEditor";
import { SBCausesEditor } from "./HealthStatus/Stillbirths/SBCausesEditor";
import { SBRateEditor } from "./HealthStatus/Stillbirths/SBRateEditor";

const TestPackTables = (props) => {
    const {
        CSModvars,
        EditorStartYear,
        EditorEndYear,
        IVInfo,
        language
    } = props;

    let startTime = Date.now();

    let selectedIVSet     = CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV) ? true : false).value;
    let IPTpCBChecked     = CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_IPTpCBChecked_MV2) ? true : false).value;
    let useFamPlanOutputs = CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_AbortCalcFamPlan_MV2) ? true : false).value;
    let DPSrc             = CSModvars.find(x => (x.tag === CONSTANTS.DP_TGX_Src) ? true : false).UPD.Sources;

    //let single = false;
    let showRetroYears = false;
    let QualityMode = false;
    let SubnatMode = false;
    let exploreData = false;
    let ShowNonSelectedInterv = false;
    let ExploreData = false;
    let ShowAllItems = false;

    let CauseOfDeath = 0;
    let vt = 0;
    let age = 0;

    let Editors = [
        CoverageEditor.Create(IVInfo, CSModvars, language, EditorStartYear, EditorEndYear, QualityMode, exploreData, showRetroYears, SubnatMode)[CONSTANTS.CS_PackTable],
        FertilityRisksEditor.Create(CSModvars, EditorStartYear, EditorEndYear, FertilityRisksEditor.MatAgeAndBirthOrderID),
        FertilityRisksEditor.Create(CSModvars, EditorStartYear, EditorEndYear, FertilityRisksEditor.BirthIntervalsID),
        EfficacyEditor.Create(IVInfo, CSModvars, language, CONSTANTS.CS_MstEfficacyNeonatal, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv)[CONSTANTS.CS_PackTable],
        EfficacyEditor.Create(IVInfo, CSModvars, language, CONSTANTS.CS_MstEfficacyPostNeonatal, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv)[CONSTANTS.CS_PackTable],
        MatEfficacyEditor.Create(IVInfo, CSModvars, language, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv)[CONSTANTS.CS_PackTable],
        MatEfficacyEditor.Create(IVInfo, CSModvars, language, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv)[CONSTANTS.CS_PackTable],
        SBEfficacyEditor.Create(IVInfo, CSModvars, language, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv)[CONSTANTS.CS_PackTable],
        AbortionIncRatioEditor.Create(CSModvars, EditorStartYear, EditorEndYear, useFamPlanOutputs, exploreData)[CONSTANTS.CS_PackTable],
        PercPregEndAbortEditor.Create(CSModvars, EditorStartYear, EditorEndYear)[CONSTANTS.CS_PackTable],
        IncidenceEditor.Create(CSModvars, EditorStartYear, EditorEndYear, age)[CONSTANTS.CS_PackTable],
        NutritionalDeficienciesEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        StatusAtBirthEditor.Create(CSModvars, EditorStartYear, EditorEndYear)[CONSTANTS.CS_PackTable],
        BaselineMortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        PercDthByCauseEditor.Create(CSModvars, CONSTANTS.CS_Neonatal)[CONSTANTS.CS_PackTable],
        PercDthByCauseEditor.Create(CSModvars, CONSTANTS.CS_PostNN)[CONSTANTS.CS_PackTable],
        FirstYearPopEditor.Create(CSModvars),
        TFREditor.Create(CSModvars, EditorStartYear),
        DiarrIncEditor.Create(IVInfo, CSModvars)[CONSTANTS.CS_PackTable],
        RelRiskBFDiarrEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        MeninIncEditor.Create(IVInfo, CSModvars)[CONSTANTS.CS_PackTable],
        RelRiskBFMeningEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        PneumIncEditor.Create(IVInfo, CSModvars)[CONSTANTS.CS_PackTable],
        RelRiskBFPneumEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsAnemiaMatMortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsBFOnMortNNEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsBFOnMortPNNEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsAnemiaMatMortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsBirthOutcomesOnMortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsStuntOnMortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsWasteOnMortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactPromoAgeAppropriateBFEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactPromoEarlyInitBFEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        BirthIntervalsEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        MatAgeAndBirthOrderEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        MatAgeAndBirthOrderEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        MaternalNutritionEditor.Create(IVInfo, CSModvars, language)[CONSTANTS.CS_PackTable],
        ImpactsOnMaternalAnemiaEditor.Create(IVInfo, CSModvars, language)[CONSTANTS.CS_PackTable],
        ImpactsOnStuntingEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        ImpactsOnWastingEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        LowBirthWeightFactorsEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        HerdEffectivenessEditor.Create(CSModvars, vt, ExploreData)[CONSTANTS.CS_PackTable],
        AvgHouseholdSizeEditor.Create(CSModvars, EditorStartYear, EditorEndYear, exploreData)[CONSTANTS.CS_PackTable],
        PerCapitaIncEditor.Create(CSModvars, EditorStartYear, EditorEndYear, exploreData)[CONSTANTS.CS_PackTable],
        MaternalAnemiaEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        PercExposedFalciparumEditor.Create(CSModvars, IPTpCBChecked)[CONSTANTS.CS_PackTable],
        PercWom15t49LowBMIEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        SevereAnemiaEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        MatMortRatioEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        PercMatDeathsByCauseEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        PathogenEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        SBCausesEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
        SBRateEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
    ];

    if (selectedIVSet["intArray"].includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF)){
        Editors.push(BFPrevEditor.Create(CSModvars, EditorStartYear, EditorEndYear, SubnatMode));
    }

    if (selectedIVSet["intArray"].includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfStunting)){
        Editors.push(StuntWastEditor.Create(CSModvars, EditorStartYear, EditorEndYear, CONSTANTS.CS_GroupMstID_Stunting, SubnatMode, exploreData)[CONSTANTS.CS_PackTable]);
    }

    if (selectedIVSet["intArray"].includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting)){
        Editors.push(StuntWastEditor.Create(CSModvars, EditorStartYear, EditorEndYear, CONSTANTS.CS_GroupMstID_Wasting, SubnatMode, exploreData)[CONSTANTS.CS_PackTable]);
    }

    let edCats = [
        RS("GB_stDemography"),

        RS("GB_stChildHealth"),
        RS("GB_stMaternalHealth"),
        RS("GB_stNutStat"),
        RS("GB_stPathogens"),
        RS("GB_stChildMortality"),
        RS("GB_stMaternalMortality"),
        RS("GB_stAbortion"),
        RS("GB_stStillbirths"),
        RS("GB_stHouseholdStatus"),
        RS("GB_stCoverage"),
        RS("GB_stEffectiveness"),
        RS("GB_stImpactsDiar"),
        RS("GB_stImpactsMenin"),
        RS("GB_stImpactsPneu"),
        RS("GB_stHerdEff"),
        RS("GB_stNutritionInterv"),
        RS("GB_stImpactUnderNutrition")
    ];
    let editorCats = {};
    edCats.sort(function(a, b) {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    });
    edCats.forEach(function(edCat) {
        editorCats[edCat] = [];
    });

    let endTime = Date.now();
    let elapsed = endTime - startTime;
    console.log(elapsed);

    let styles = {
        overall : {
            fontSize: 16,
            marginBottom: 0,
            paddingLeft: 20
        },
        heading : {
            fontSize: 14,
            marginBottom: 0,
            marginTop: 0,
            marginLeft: 20,
            paddingLeft: 20
        },
        src : {
            fontSize: 14,
            marginTop: 0,
            paddingLeft: 20,
            wordBreak: "break-word"
        },
        cat : {
            color: "rgb(12, 58, 121)",//"#9cc025",
            fontSize: 18,
            marginBottom: 0
        }
    };

    const getAllSources = (pt) => {
        let result = [];
        let temp = [];

        switch (pt.SourceMode){
            case CONSTANTS.GB_OneEditorSrc : {
                if (pt.Source[0] !== '' && pt.Source[0] !== ' ')
                {
                    result.push(<blockquote key={uuidv4()} style={styles.src}>{pt.Source[0]}</blockquote>);
                }
                break;
            }

            case CONSTANTS.GB_RowSrcEditor : {
                for (let r = pt.GBFixedRows; r < pt.GBRowCount; r++) {
                    let idx = pt.SourceMap[r];
                    if (idx > 0 && pt.Source[idx] !== '' && pt.Source[idx] !== ' ') {
                        temp.push({
                            h3: pt.tableData.value[r][0],
                            quote: pt.Source[idx]
                        });
                    }
                }
                break;
            }

            case CONSTANTS.GB_ColSrcEditor : {
                for (let c = pt.GBFixedCols; c < pt.GBColCount; c++) {
                    let idx = pt.SourceMap[c];
                    if (idx > 0 && pt.Source[idx] !== '' && pt.Source[idx] !== ' ') {
                        temp.push({
                            h3: pt.tableData.value[0][c],
                            quote: pt.Source[idx]
                        });
                    }
                }
                break;
            }

            case CONSTANTS.GB_RowSecSrcEditor : {
                for (let i = 1; i < pt.Source.length; i++) {
                    if (pt.Source[i] !== '' && pt.Source[i] !== ' ') {
                        temp.push({
                            h3: pt.SourceTitle[i],
                            quote: pt.Source[i]
                        });
                    }
                }
                break;
            }
            default : break;
        }

        //alphabetization
        if (temp.length > 0) {
            temp.sort(function(a, b) {
                return a.h3.toLowerCase() > b.h3.toLowerCase() ? 1 : -1;
            });
            temp.forEach(function(o, idx) {
                result.push(<h3 key={uuidv4()} style={styles.heading}>{o.h3}</h3>);
                result.push(<blockquote key={uuidv4()} style={styles.src}>{o.quote}</blockquote>);
            });
        }

        return result;
    };

    const dpStrings = {
        "Age Mortality"             : RS("GB_stAgeMort"),
        "Infant/child mortality"    : RS("GB_stInfantMort"),
        "International migration"   : RS("GB_stIntlMigration"),
        "Life expectancy at birth"  : RS("GB_stLifeExpAtBirth"),
        "Total fertility rate"      : RS("DP_stTotalFRate"),
        "Total population"          : RS("GB_stTotalPop")
    };
    let extra = [<h1 key={uuidv4()} style={styles.cat}>{RS("GB_stDemography")}</h1>];
    DPSrc.sort(function(a, b) {
        return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1;
    });
    DPSrc.forEach(function(src) {
        if (src.Summary.length > 0) {
            extra.push(<h2 key={uuidv4()} style={styles.overall}>{dpStrings[src.Name]}</h2>);
            extra.push(<blockquote key={uuidv4()} style={styles.src}>{src.Summary}</blockquote>);
        }
    });

    if (extra.length === 1) {
        extra.pop();
    }

    Editors.forEach(function(editor) {
        if (editor.EditorCat > 0) {
            editorCats[CSGetEditorCatName(editor.EditorCat)].push(editor);
        }
    });

    let sources = [];
    let taken = [];

    for (let k in editorCats) {

        if (k === RS("GB_stDemography")) {
            sources.push(extra);
        } else {
            let editorCat = editorCats[k];

            if (editorCat.length > 0) {
                sources.push(<h1 key={k} style={styles.cat}>{k}</h1>)
                editorCat.sort(function (a, b) {
                    return a.Title > b.Title ? 1 : -1;
                });

                let count = 0;
                for (let i = 0; i < editorCat.length; i++) {
                    let packTable = editorCat[i];
                    let allPackTableSources = getAllSources(packTable);
                    if (allPackTableSources.length > 0 && !taken.includes(packTable.Title)) {
                        count++;
                        sources.push(<h2 key={uuidv4()} style={styles.overall}>{packTable.Title}</h2>);
                        taken.push(packTable.Title);
                        sources.push(allPackTableSources);
                    }
                }
                //only include the category if there are sources under the category
                if (count === 0) {
                    sources.pop();
                }
            }
        }
    }

    return sources.flat();
};

export default TestPackTables;