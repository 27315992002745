import React from 'react';
import PropTypes from "prop-types";
import GBStdChart from '../../../../../../components/GB/GBStdChart';
import {getIncidencePackChart} from "../../../../../../data/CSUtil";

class IncidenceTabbedChart extends React.Component {
    static propTypes = {
        dat            : PropTypes.any,
        age            : PropTypes.number,
        id             : PropTypes.string,
        options        : PropTypes.any,
        chartType      : PropTypes.any,
        stacked        : PropTypes.bool,
        legendReversed : PropTypes.bool,
        chartTitle     : PropTypes.string,
        tableRef       : PropTypes.any
    };

    static defaultProps = {
        dat            : [],
        age            : -1,
        id             : "TabbedChart",
        options        : [],
        chartType      : "",
        stacked        : false,
        legendReversed : false,
        chartTitle     : "",
        tableRef       : null
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderIncidenceTabbedChart = () => {
        let packChart = getIncidencePackChart(this.props.dat, this.props.age);
        let ages = ["<1 month", "1-5 months", "6-11 months", "12-23 months", "24-59 months"];
        packChart.title = this.props.chartTitle + " (" + ages[this.props.age] + ")";

        let chart =
            <GBStdChart
                id={"CDChart" + this.props.id + this.props.age}
                packChart={packChart}
                minHeight={325}
                maxHeight={325}
                options={this.props.options}
                chartType={this.props.chartType}
                stacked={false}
                legendReversed={this.props.legendReversed}
                key={"CDChart" + this.props.id + this.props.age}
                ref={this.props.tableRef}
            />;
        return (chart)
    };

    render() {
        return (
            this.renderIncidenceTabbedChart()
        );
    }
}

export default IncidenceTabbedChart;