import { RESTService3, LoginService3, ModUtilService3, SpectrumService3 } from "./server_calls_single";
import CSTemplate from 'data/CSTemplate';

export const GenericService = {

    onFetchServerInfo : (params, successFn, errorFn) => {

        let countriesParams = {...params, modID : 15, subnatMode: true};

        // RESTService3.ping(params, (r1) => {
            RESTService3.getVersion(params, (r2) => {
                RESTService3.getCountries(countriesParams, (r3) => {
                    RESTService3.getIntervGroupList(params, (r4) => {
                        RESTService3.getIntervSubGroupList(params, (r5) => {
                            RESTService3.getIntervList(params, (r6) => {
                                successFn({
                                    // ping : r1,
                                    getVersion: r2,
                                    getCountries: r3,
                                    getIntervGroupList: r4,
                                    getIntervSubGroupList: r5,
                                    getIntervList: r6,
                                });
                            }, errorFn);
                        }, errorFn);
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        // }, errorFn);
    },

    onFetchUserInfo : (params, successFn, errorFn) => {
        LoginService3.getAccountInfo(params, (r1) => {
            LoginService3.getStatus(params, (r2) => {
                SpectrumService3.getActiveProjectionList(params, (r3) => {
                    SpectrumService3.getStoredProjectionList(params, (r4) => {
                        SpectrumService3.getResultMenus(params, (r5) => {
                            successFn({
                                getAccountInfo : r1,
                                getStatus: r2, 
                                getActiveProjectionList: r3,
                                getStoredProjectionList: r4,
                                getResultMenus: r5,
                            });
                        }, errorFn);
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        }, errorFn);
    },

    onFetchProjectionInfo : (params, successFn, errorFn) => {

        if (!window.appState.projectCreated) {
            successFn({
                msg        : "Projection not created",
                getModvars : [],
                // getEditors : [],
            });
            return;
        }

        SpectrumService3.getModvars({...params, arr: undefined}, (r1) => {
            // SpectrumService3.getEditors({...params, arr: undefined}, (r2) => {
                successFn({
                    getModvars: r1,
                    // getEditors: r2,
                });
            // }, errorFn);
        }, errorFn);
    },

    onSendProjectionInfo : (params, successFn, errorFn) => {

        const MV_InputVariable = 8;

        // Send ONLY the input modvars.  No need to send the result modvars and eat up time.
        let modvarParams = {...params, arr : window.appState.CSModvars.filter(x => typeof x.FlagSet !== 'undefined').filter(x => x.FlagSet.includes(MV_InputVariable))};
        // let editorParams = {...params, arr : window.appState.CSEditors};

        if (!window.appState.projectCreated) {
            successFn({
                msg        : "Projection not created",
                setModvars : '',
                // setEditors : '',
            });
            return;
        }

        SpectrumService3.setModvars(modvarParams, (r1) => {
            // SpectrumService3.setEditors(editorParams, (r2) => {
                successFn({
                    setModvars: r1,
                    // setEditors: r2,
                });
            // }, errorFn);
        }, errorFn);
    },

};

export const ComboService = {

    onInitSession : (params, successFn, errorFn) => {
        GenericService.onFetchServerInfo(params, (r1) => {
            LoginService3.signedIn(params, (r2) => {
                if (r2) {
                    GenericService.onFetchUserInfo(params, (r3) => {
                        GenericService.onFetchProjectionInfo(params, (r4) => {
                            successFn({
                                onFetchServerInfo : r1,
                                onSignedIn : r2, 
                                onFetchUserInfo : r3,
                                onFetchProjectionInfo : r4,
                            });
                        }, errorFn);
                    }, errorFn);
                }
                else {
                    LoginService3.loginAsGuest(params, (r3) => {
                        GenericService.onFetchUserInfo(params, (r4) => {
                            GenericService.onFetchProjectionInfo(params, (r5) => {
                                successFn({
                                    onFetchServerInfo : r1,
                                    onSignedIn : r2, 
                                    loginAsGuest : r3,
                                    onFetchUserInfo : r4,
                                    onFetchProjectionInfo : r5,
                                });
                            }, errorFn);
                        }, errorFn);
                    }, errorFn);
                }
            }, errorFn);
        }, errorFn);
    },

    //------------------------------------------------------------------

    onLogin : (params, successFn, errorFn) => {

        // let theParams = {
        //     ...params,
        //     username : params.username,
        //     password : params.password,
        // };

        LoginService3.login(params, (r1) => {
            GenericService.onFetchUserInfo(params, (r2) => {
                successFn({
                    login : r1, 
                    onFetchUserInfo : r2
                });
            }, errorFn);
        }, errorFn);
    },

    onLoginAsGuest : (params, successFn, errorFn) => {

        // let theParams = {
        //     ...params,
        //     username : params.username,
        //     password : params.password,
        // };

        LoginService3.loginAsGuest(params, (r1) => {
            GenericService.onFetchUserInfo(params, (r2) => {
                successFn({
                    loginAsGuest : r1, 
                    onFetchUserInfo : r2
                });
            }, errorFn);
        }, errorFn);
    },

    onCreateProjectionFlag : (params, successFn, errorFn) => {

        let createParams = {
            ...params,
            CountryISO       : params.CountryISO,
            FirstYear        : params.FirstYear,
            FinalYear        : params.FinalYear,
            FileName         : params.FileName,
            ModList          : params.ModList,
            Notes            : params.Notes,
            Author           : params.Author,
            Org              : params.Org,
            subnatStep2      : params.arr.length > 0,
            AgeGroupOption   : params.AgeGroupOption,
        };

        let MODataParams = {
            ...params, 
            countryISO : params.CountryISO
        };

        let modvarParams = {
            ...params,
            arr : params.arr,
        };

        const CS_TG_SelectedIVSet_MV = '<Selected intervention set MV>';

        /* This ensures projections made with the subnational wizard will not have any interventions selected by default. */
        for (let i = 0; i < modvarParams.arr.length; i++){
            if (modvarParams.arr[i].tag === CS_TG_SelectedIVSet_MV){
                modvarParams.arr.splice(i, 1);
            }
        }

        let subnatParams = {
            ...params,
            CountryISO       : params.CountryISO,
            Region           : params.Region,
            Survey           : params.Survey,
            useWizard        : params.arr.length > 0
        };

        if (createParams.subnatStep2) {
            SpectrumService3.createProjection(createParams, (r1) => {
                SpectrumService3.setModvars(modvarParams, (r2) => {
                    SpectrumService3.addSubnatData(subnatParams, (r3) => {
                        GenericService.onFetchUserInfo(params, (r4) => {
                            GenericService.onFetchProjectionInfo(params, (r5) => {
                                ModUtilService3.getMOCountryData(MODataParams, (r6) => {    
                                    successFn({
                                        createProjection : r1, 
                                        setModvars : r2,
                                        addSubnatData : r3, 
                                        onFetchUserInfo : r4, 
                                        onFetchProjectionInfo : r5, 
                                        getMOCountryData : r6, 
                                    });
                                }, errorFn);
                            }, errorFn);
                        }, errorFn);
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        }






        // else {

            // let cParams = {
            //     countryISO       : params.CountryISO,
            //     firstYear        : params.FirstYear,
            //     finalYear        : params.FinalYear,
            //     fileName         : params.FileName,
            //     modList          : params.ModList,
            //     notes            : params.Notes,
            //     author           : params.Author,
            //     org              : params.Org,
            //     subnatStep2      : params.arr.length > 0,
            // }
    
            // let sParams = {
            //     countryISO       : params.CountryISO,
            //     region           : params.Region,
            //     survey           : params.Survey,
            //     useWizard        : params.arr.length > 0
            // };

            // let mParams = {
            //     modvars          : CSTemplate.CSModvars.map(x => ({ ...x, proj : 1 }))
            // };
        //     debugger;

        //     SpectrumService3.doRun(
        //         {
        //             ...params,
        //             arr : [
        //                 { serviceName : 'CreateProjection', ...cParams },
        //                 // { serviceName : 'AddSubnatData', ...sParams },
        //                 // { serviceName : 'GetAccountInfo' },
        //                 // { serviceName : 'GetStatus' },
        //                 { serviceName : 'GetActiveProjectionList' },
        //                 { serviceName : 'GetStoredProjectionList' },
        //                 { serviceName : 'GetResultMenus' },
        //                 { serviceName : 'GetModvars', ...mParams}, 
        //             ]
        //         },
        //         (response) => {
        //             debugger;
        //             ModUtilService3.getMOCountryData(MODataParams, (r6) => { 
        //                 successFn({
        //                     response : response, 
        //                     getMOCountryData : r6,
        //                 });
        //             }, errorFn);               
        //         }, (msg) => {
                
        //         }
        //     );
        // }


 










        else {
            SpectrumService3.createProjection(createParams, (r1) => {
                SpectrumService3.addSubnatData(subnatParams, (r3) => {
                    GenericService.onFetchUserInfo(params, (r4) => {
                        GenericService.onFetchProjectionInfo(params, (r5) => {
                            ModUtilService3.getMOCountryData(MODataParams, (r6) => {    
                                successFn({
                                    createProjection : r1, 
                                    addSubnatData : r3, 
                                    onFetchUserInfo : r4, 
                                    onFetchProjectionInfo : r5,
                                    getMOCountryData : r6,
                                });
                            }, errorFn);
                        }, errorFn);
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        }
    },

    onModifyProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            Proj          : params.Proj,
            CountryISO    : params.CountryISO,
            FinalYear     : params.FinalYear,
            FileTitle     : params.FileTitle,
            ModList       : params.ModList,
            Notes         : params.Notes,
            Author        : params.Author,
            Org           : params.Org,
            SubnatStep2   : params.SubnatStep2,
            countryChange : params.countryChange,
        };

        let MODataParams = {
            ...params, 
            countryISO : params.CountryISO
        };

        if (theParams.countryChange) {
            SpectrumService3.modifyProjection(theParams, (r1) => {
                GenericService.onFetchUserInfo(params, (r2) => {
                    GenericService.onFetchProjectionInfo(params, (r3) => {
                        ModUtilService3.getMOCountryData(MODataParams, (r4) => {   
                            successFn({
                                modifyProjection : r1,
                                onFetchUserInfo : r2, 
                                onFetchProjectionInfo : r3,
                                getMOCountryData : r4,
                            });
                        }, errorFn);
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        } 
        else {
            SpectrumService3.modifyProjection(theParams, (r1) => {
                GenericService.onFetchUserInfo(params, (r2) => {
                    GenericService.onFetchProjectionInfo(params, (r3) => {  
                        successFn({
                            modifyProjection : r1,
                            onFetchUserInfo : r2, 
                            onFetchProjectionInfo : r3
                        });
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        }
    },

    onOpenProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            FileTitle : params.FileTitle
        };

        SpectrumService3.openProjection(theParams, (r1) => {
            GenericService.onFetchUserInfo(params, (r2) => {
                GenericService.onFetchProjectionInfo(params, (r3) => {
                    successFn({
                        openProjection : r1,
                        onFetchUserInfo : r2,
                        onFetchProjectionInfo : r3,
                    });
                }, errorFn);
            }, errorFn);
        }, errorFn);
    },

    onSaveProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            Proj      : params.Proj,
            FileTitle : params.FileTitle
        };

        GenericService.onSendProjectionInfo(params, (r1) => {
            SpectrumService3.saveProjection(theParams, (r2) => {
                SpectrumService3.getStoredProjectionList(params, (r3) => {
                    successFn({
                        onSendProjectionInfo : r1, 
                        saveProjection : r2, 
                        getStoredProjectionList : r3,
                    });
                });
            }, errorFn);
        }, errorFn);
    },

    onCloseProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            Proj : params.Proj
        };

        SpectrumService3.closeProjection(theParams, (r1) => {
            SpectrumService3.getActiveProjectionList(params, (r2) => {
                successFn({
                    closeProjection : r1, 
                    getActiveProjectionList : r2,
                });
            }, errorFn);
        }, errorFn);
    },

    onCalculateProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params, 
            Proj: params.Proj
        };

        GenericService.onSendProjectionInfo(params, (r1) => {
            SpectrumService3.calculateProjection(theParams, (r2) => {
                GenericService.onFetchProjectionInfo(params, (r3) => {
                    successFn({
                        onSendProjectionInfo : r1, 
                        calculateProjection : r2, 
                        onFetchProjectionInfo : r3, 
                    });
                }, errorFn);
            }, errorFn);
        }, errorFn);
    },

    //------------------------------------------------------------------

    onDeleteProjectionFlag : (params, successFn, errorFn) => {
        
        let theParams = {
            ...params,
            FileTitle : params.FileTitle
        };

        SpectrumService3.deleteProjection(theParams, (r1) => {
            SpectrumService3.getStoredProjectionList(params, (r2) => {
                successFn({
                    deleteProjection : r1,
                    getStoredProjectionList : r2, 
                });
            }, errorFn);
        }, errorFn);
    },

    onUploadProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            file : params.file
        };

        let closeParams = {
            ...params,
            Proj : "0"
        };

        let saveParams = {
            ...params,
            Proj : "1", 
            FileTitle : params.file.name.replace('.PJNZ','.pjnz')
        };

        SpectrumService3.closeProjection(closeParams, (r1) => {
            SpectrumService3.upload(theParams, (r2) => {
                SpectrumService3.saveProjection(saveParams, (r3) => {
                    SpectrumService3.closeProjection(closeParams, (r4) => {
                        SpectrumService3.getStoredProjectionList(params, (r5) => {
                            successFn({
                                closeProjection1 : r1,
                                upload : r2, 
                                saveProjection : r3,
                                closeProjection2 : r4,
                                getStoredProjectionList : r5,
                            });
                        }, errorFn);
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        }, errorFn);
    },

    onCopyProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            oldName: params.oldFileTitle,
            newName: params.newFileTitle
        };

        SpectrumService3.copyProjection(theParams, (r1) => {
            SpectrumService3.getStoredProjectionList(params, (r2) => {
                successFn({
                    copyProjection : r1, 
                    getStoredProjectionList : r2,
                });
            }, errorFn);
        }, errorFn);
    },

    onRenameProjectionFlag : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            oldName: params.oldFileTitle,
            newName: params.newFileTitle
        };

        SpectrumService3.renameProjection(theParams, (r1) => {
            SpectrumService3.getStoredProjectionList(params, (r2) => {
                successFn({
                    renameProjection : r1, 
                    getStoredProjectionList : r2,
                });
            }, errorFn);
        }, errorFn);
    },

    //------------------------------------------------------------------

    onGetMOCountryDataFlag : (params, successFn, errorFn) => {
        ModUtilService3.getMOCountryData(params, (r1) => {
            successFn({
                getMOCountryData : r1,
            });
            // ModUtilService3.getInputModVars(params, (r2) => {
            //     successFn({
            //         getMOCountryData : r1,
            //         getInputModVars : r2,
            //     });
            // }, errorFn);
        }, errorFn);
    },

    getSubNatModvars : (params, successFn, errorFn) => {

        let theParams = {
            ...params,
            ProjectCreated : params.ProjectCreated
        };

        let modvarParams = {
            ...params,
            arr : CSTemplate.CSSubNatModvars.map(x => ({ ...x, proj : 1 }))
        };

        let closeParams = {
            ...params,
            Proj : "1"
        };

        if (theParams.ProjectCreated) {
            SpectrumService3.getModvars(modvarParams, (r1) => {
                successFn({
                    getModvars : r1,
                });
            }, errorFn);
        }
        else {
            SpectrumService3.createProjection(params, (r1) => {
                SpectrumService3.getModvars(modvarParams, (r2) => {
                    SpectrumService3.closeProjection(closeParams, (r3) => {
                        successFn({
                            createProjection : r1,
                            getModvars : r2,
                            closeProjection : r3,
                        });
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        }
    },

};

/* Useful when doing multiple calls at once.  Keep this here for backup!!! */

// this.doRun([
//     { serviceName : 'getAccountInfo' },
//     { serviceName : 'GetStatus' },
//     { serviceName : 'GetActiveProjectionList' },
//     { serviceName : 'GetStoredProjectionList' },
//     { serviceName : 'GetGB' },
//     { serviceName : 'GetCountries' },
//     { serviceName : 'CalculateProjection' },
//     { serviceName : 'GetResultMenus' },
//     { serviceName : 'GetGB' },
//     { serviceName : 'GetGB' },
// ]);