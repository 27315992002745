import React from 'react';
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
//import { CSEditor } from '../../../../components/CS/CSEditors';
// import TLabel from "@common/components/TLabel";
import EditorNote from "../../../../components/EditorNote";
import TTabs2 from "../../../../@common/components/TTabs2";
import {FPCPREditor} from "../../data/Coverage/FP/FPCPREditor";
import {FPMethodMixEditor} from "../../data/Coverage/FP/FPMethodMixEditor";
import {FPProximateDeterminantsEditor} from "../../data/Coverage/FP/FPProximateDeterminantsEditor";
import {HVCoverageEditor} from "../../data/Coverage/HVCoverageEditor"
import { CSEditor } from "../../../../components/CS/CSEditors";
import { Get_FP_UseSingleAgesValue, Get_FP_AgeName } from "../../../../utilities/CS/CSDataUtil";
import { getModvarCloneByTag } from "../../../../utilities/GB/GBUtil";

const SelectedIVSetTag = CONSTANTS.CS_TG_SelectedIVSet_MV;

class CSFamPlanHIVDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        CSModvars              : PropTypes.array,
        firstYear              : PropTypes.number,
        finalYear              : PropTypes.number,
        projectionName         : PropTypes.string,
        language               : PropTypes.number,
        HIVAdolIntervsSelected : PropTypes.bool,
        IVInfo                 : PropTypes.arrayOf(PropTypes.object),
        onOpenHelpDialog       : PropTypes.func,
        onCloseDrawer          : PropTypes.func,
        onStateChange          : PropTypes.func,
    };

    static defaultProps = {
        CSModvars                    : [],
        firstYear                    : 1900,
        finalYear                    : 1909,
        projectionName               : "",
        language                     : 1,
        HIVAdolInterventionsSelected : false,
        IVInfo                       : [],
        onOpenHelpDialog             : () => {},
        onCloseDrawer                : () => {},
        onStateChange                : () => {},
    };

    state = {
        packTable1             : null,
        packTable2             : null,
        packTable3             : null,
        packTable4             : null,
        packTable5             : null,
        packTable6             : null,
        packTable7             : null,
        packTable8             : null,
        saveBtnDisabled        : true,
        selectedIVSet          : this.props.CSModvars.find(x => (x.tag === SelectedIVSetTag) ? true : false).value.intArray,
        CSModvars              : this.props.CSModvars,
        projInfo               : {
            "firstYear"  : this.props.firstYear,
             "calcYear"  : this.props.firstYear,
             "finalYear" : this.props.finalYear,
             "projName"  : this.props.projectionName
        },
        useSingleAges          : Get_FP_UseSingleAgesValue(this.props.CSModvars),
        ageIdx                 : Get_FP_UseSingleAgesValue(this.props.CSModvars) ? CONSTANTS.FP_All_Ages : CONSTANTS.FP_A15_19,
        method                 : CONSTANTS.HA_FirstMethod,
        fit                    : 0,
    };

    componentDidMount () {
        let IVInfo = this.props.IVInfo;
        let CSModvars = this.props.CSModvars;
        let language = this.props.language;
        let HIVAdolIntervsSelected = this.props.HIVAdolIntervsSelected;

        let projInfo = this.state.projInfo;
        let ageIdx   = this.state.ageIdx;
        let method   = this.state.method;
        // let fit      = this.state.fit;

        this.setState({
            packTable1        : FPCPREditor.Create(CSModvars, projInfo, ageIdx)[CONSTANTS.CS_PackTable],
            packTable2        : FPMethodMixEditor.Create(CSModvars, projInfo, ageIdx, CONSTANTS.FP_all_need)[CONSTANTS.CS_PackTable],
            packTable3        : FPProximateDeterminantsEditor.Create(CSModvars, projInfo, ageIdx)[CONSTANTS.CS_PackTable],
            packTable4        : HVCoverageEditor.Create(IVInfo, CSModvars, language, projInfo, method, HIVAdolIntervsSelected)[CONSTANTS.CS_PackTable],
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1: FPCPREditor.RecalcGrid(packTable, this.state.projInfo, 0),
            saveBtnDisabled: false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2: FPMethodMixEditor.RecalcGrid(packTable),
            saveBtnDisabled: false,
        });
    };

    onPackTable3Change = (packTable) => {
        this.setState({
            packTable3: FPProximateDeterminantsEditor.RecalcGrid(packTable),
            saveBtnDisabled: false,
        });
    };

    onPackTable4Change = (packTable) => {
        this.setState({
            packTable4: packTable,
            saveBtnDisabled: false,
        });
    };

    getStatePackTables = () => {
        return [this.state.packTable1,
                this.state.packTable2,
                this.state.packTable3,
                this.state.packTable4];
    };

    getPTModvars = () => {
        let packTables    = this.getStatePackTables();

        let CSModvars     = this.state.CSModvars;
        let projInfo      = this.state.projInfo;

        let ageIdx        = this.state.ageIdx;
        let useSingleAges = this.state.useSingleAges;
        let needOptionCBValue = Boolean(getModvarCloneByTag(CSModvars, CONSTANTS.FP_TG_TNeedOption_MV)['value']);
        let abortionOptionValue = getModvarCloneByTag(CSModvars, CONSTANTS.FP_TG_TAbortionOption_MV)['value'];

        let method = this.state.method;
        // let fit = this.state.fit;

        let HIVAdolIntervsSelected = this.props.HIVAdolIntervsSelected;

        let ModvarsArray1 = FPCPREditor.GetSSData(packTables[0], CSModvars, projInfo, useSingleAges, needOptionCBValue, ageIdx);
        let ModvarsArray2 = FPMethodMixEditor.GetSSData(packTables[1], CSModvars, projInfo, useSingleAges, ageIdx, CONSTANTS.FP_all_need);
        let ModvarsArray3 = FPProximateDeterminantsEditor.GetSSData(packTables[2], CSModvars, projInfo, useSingleAges, abortionOptionValue, ageIdx);
        let ModvarsArray4 = HVCoverageEditor.GetSSData(packTables[3], CSModvars, method, HIVAdolIntervsSelected);

        return CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            let c = ModvarsArray3.find(y => y.tag === x.tag);
            let d = ModvarsArray4.find(y => y.tag === x.tag);
            return a || b || c ||  d ||  x;
        });
    };

    onSaveBtnClick = () => {
        let packTables = this.getStatePackTables();
        let goAhead = true;

        const falsify = () => {
            goAhead = false;
        };

        for (let i = 0; i < packTables.length; i++) {
            if (packTables[i]) {
                packTables[i].tableData.value.forEach(function (arr) {
                    arr.forEach(function (val) {
                        if (typeof val === "number" && parseFloat(val.toFixed(1)) > 100) {
                            falsify();//goAhead = false;
                        }
                    });
                });
            }
        }

        if (goAhead) {
            let changedCSModvars = this.getPTModvars();

            this.setState({
                saveBtnDisabled : true,
                CSModvars       : changedCSModvars,
            }, () => {
                this.props.onStateChange({
                    unchangedProj  : false,
                    CSModvars      : changedCSModvars,
                }, () => {
                    this.props.onCloseDrawer();
                });
            });
        } else {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : RS("GB_stValOver100")
            });
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    onChangeAgeFilter = (itemIndex) => {
        let CSModvars = this.getPTModvars();
        let projInfo = this.state.projInfo;

        let ageIdx = itemIndex + CONSTANTS.FP_A15_19;

        this.setState({
            ageIdx      : ageIdx,
            CSModvars   : CSModvars,
            packTable1  : FPCPREditor.Create(CSModvars, projInfo, ageIdx)[CONSTANTS.CS_PackTable],
            packTable2  : FPMethodMixEditor.Create(CSModvars, projInfo, ageIdx, CONSTANTS.FP_all_need)[CONSTANTS.CS_PackTable],
            packTable3  : FPProximateDeterminantsEditor.Create(CSModvars, projInfo, ageIdx)[CONSTANTS.CS_PackTable],

        });
    };

    // onChangeMethodFilter = (itemIndex) => {
    //     let CSModvars = this.getPTModvars();
    //     let projInfo = this.state.projInfo;
    //
    //     let method = itemIndex + CONSTANTS.HA_FirstMethod;
    //
    //     this.setState({
    //         method      : method,
    //         CSModvars   : CSModvars,
    //         packTable5  : HVPrEPEditor.Create(CSModvars, projInfo, method),
    //     });
    // };
    //
    // onChangeFitterFilter = (itemIndex) => {
    //     let CSModvars = this.getPTModvars();
    //     let projInfo = this.state.projInfo;
    //
    //     let fit = itemIndex;
    //
    //     this.setState({
    //         fit         : fit,
    //         CSModvars   : CSModvars,
    //         packTable7  : HVPercMalesCircum.Create(CSModvars, projInfo, fit),
    //     });
    // };

    getNumTables = () => {
        let result = 0;
        if (this.state.selectedIVSet.includes(CONSTANTS.CS_MstFamPlanInt)){result += 3;}
        if (this.state.selectedIVSet.includes(CONSTANTS.CS_MstHIVInt)){result += 1;}
        return result;
    };

    getTabNames = () => {
        let result = [];
        if (this.state.selectedIVSet.includes(CONSTANTS.CS_MstFamPlanInt)){result.push(RS('GB_stFP'));}
        if (this.state.selectedIVSet.includes(CONSTANTS.CS_MstHIVInt)){result.push(RS('GB_stHIVAIM'));}
        return result;
    };

    getFamPlanContent = () => {
        let result = [];

        if (!this.state.useSingleAges){

            let ages = [];
            for (let a = CONSTANTS.FP_A15_19; a <= CONSTANTS.FP_A45_49; a++){
                ages.push(Get_FP_AgeName(a));
            }

            result.push(
                <TComboBox
                    key={"AgeRangeKey"}
                    caption={RS('GB_stAgeRange')}
                    items={ages}
                    style={{
                        marginTop: 20,
                        marginBottom: 20
                    }}
                    itemIndex={this.state.ageIdx - CONSTANTS.FP_A15_19}
                    onChange={this.onChangeAgeFilter}
                />
            );
        }

        result.push(
            <CSEditor
                key={"pt1"}
                mstID={CONSTANTS.FP_mstPrevSheetID}
                packTable={this.state.packTable1}
                onChange={this.onPackTable1Change}
                showTitle={true}
                readOnly={false}
            />
        );

        // result.push(
        //     <TLabel
        //         caption = {RS('GB_stCPRNote')}
        //         // style   = {styles.noteStyle}
        //     />                
        // );

        result.push(
            <CSEditor
                key={"pt2"}
                mstID={CONSTANTS.FP_mstMethMixSheetID}
                packTable={this.state.packTable2}
                onChange={this.onPackTable2Change}
                showTitle={true}
                readOnly={false}
            />
        );

        result.push(
            <CSEditor
                key={"pt3"}
                mstID={CONSTANTS.FP_mstProxDetSheetID}
                packTable={this.state.packTable3}
                onChange={this.onPackTable3Change}
                showTitle={true}
                readOnly={false}
            />
        );

        return result;
    };

    getHIVContent = () => {
        let result = [];

        // let methods = [];
        // for (let m = CONSTANTS.HA_FirstMethod; m <= CONSTANTS.HA_FinalMethod; m++){
        //     methods.push(HA_PrEpIntervStr(m));
        // }

        // result.push(
        //     <TComboBox
        //         caption={''}
        //         items={methods}
        //         style={{
        //             marginTop: 0,
        //             marginBottom: 20
        //         }}
        //         itemIndex={this.state.method - CONSTANTS.HA_FirstMethod}
        //         onChange={this.onChangeMethodFilter}
        //     />
        // );

        result.push(
            <CSEditor
                mstID={CONSTANTS.HV_mstCoverageSheetID}
                packTable={this.state.packTable4}
                onChange={this.onPackTable4Change}
                showTitle={true}
                readOnly={false}
            />
        );

        // result.push(
        //     <TComboBox
        //         caption={''}
        //         items={[RS('GB_stUseLogisticsCurve'), RS('GB_stUseInterpolatedValues')]}
        //         style={{
        //             marginTop: 0,
        //             marginBottom: 20
        //         }}
        //         itemIndex={this.state.fit}
        //         onChange={this.onChangeFitterFilter}
        //     />
        // );

        return result;
    };

    getTabContents = () => {
        let result = [];
        if (this.state.selectedIVSet.includes(CONSTANTS.CS_MstFamPlanInt)){
            result.push(
                <React.Fragment key={"FPContent"}>
                    {this.getFamPlanContent()}
                </React.Fragment>
            );
        }
        if (this.state.selectedIVSet.includes(CONSTANTS.CS_MstHIVInt)){
            result.push(
                <React.Fragment key={"HIVContent"}>
                    {this.getHIVContent()}
                </React.Fragment>
            );
        }
        return result;
    };

    render() {
        const styles = {
            tabSelectedStyle : {
                color: '#f78c30',
                fontWeight: 'bold'
            },
            tabRootStyle : {
                fontSize: '14px'
            },
            tabContentStyle : {
                // marginTop: '185px'
            },
            tabAppBarStyle : {
                position: "static",
            }
        };

        return (
            <TDrawerWithHeader
                open             = {true}
                onOpenHelpDialog = {this.props.onOpenHelpDialog}
                helpFile         = {"coverage.htm"}
                onClose          = {this.props.onCloseDrawer}
                onSave           = {this.onSaveBtnClick}
                saveBtnCaption   = {RS('GB_stApply')}
                saveBtnDisabled  = {this.state.saveBtnDisabled}
                appBarText       = {RS('GB_stFamPlanHIV')}
                content          = {
                    <div className="innerPage">

                        <EditorNote
                            numberOfTables = {this.getNumTables()}
                        />
                        <div styles={{marginTop: '20px'}}>
                            <TTabs2
                                tabNames={this.getTabNames()}
                                tabContents={this.getTabContents()}
                                tabAppBarStyle={styles.tabAppBarStyle}
                                tabSelectedStyle={styles.tabSelectedStyle}
                                tabRootStyle={styles.tabRootStyle}
                                tabContentStyle={styles.tabContentStyle}
                                onTabChange={this.props.onTabChange}
                            />
                        </div>
                    </div>
                }
            />
        );
    }
}

export default CSFamPlanHIVDrawer;