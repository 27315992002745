import React from 'react';
import PropTypes from "prop-types";
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CausesOfDeath from "./CausesOfDeath";
import ResultToDisplay from "./ResultToDisplay";
import Outcomes from "./Outcomes";
import DeliveryPoints from "./DeliveryPoints";
import IVApproachFilter from "../../../../../components/SelectInterventions/IVApproachFilter";

class MOSideBar extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onOutcomeSwitchChange          : PropTypes.func,
        displayButtons                 : PropTypes.bool,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        selectedCountry                : PropTypes.string,
        MOResultToDisplay              : PropTypes.number,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        onPageChange                   : () => console.log('onPageChange'),
        onOutcomeSwitchChange          : () => console.log("onOutcomeSwitchChange"),
        displayButtons                 : true,
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayCPR                     : true,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        selectedCountry                : 'Afghanistan',
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        viewInterventionCost           : false,
        language                       : CONSTANTS.GB_English
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    createProjBtnClick = () => {
        this.props.onStateChange({
            dialogProjInfoOpen : true
        })
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let styles = {
            sideBarStyle : {
                marginLeft: '25px',
            },
            divStyle: {
                // marginTop: '5px',
                width: '300px',
                minHeight: '100px',
                overflow: 'hidden',
            },

            createButton: {
                fontSize: '16px',
                marginLeft: '0px',
                marginTop: '25px',
                marginRight: '0px',
                width: '285px',
                backgroundColor : '#C03600',
                color: '#FFFFFF'
            },

            filter: {
                marginTop: 10
            }

        };
        return (
            <div style={styles.divStyle}>

                <ResultToDisplay
                    onStateChange                  = {this.props.onStateChange}
                    displayMaternalCOD             = {this.props.displayMaternalCOD}
                    displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                    displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                    displayChildCOD                = {this.props.displayChildCOD}
                    displayCPR                     = {this.props.displayCPR}
                    MOResultToDisplay              = {this.props.MOResultToDisplay}
                    maternalIntervSelected         = {this.props.maternalIntervSelected}
                    stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                    neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                    childIntervSelected            = {this.props.childIntervSelected}
                    maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                    stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                    neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                    childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                    viewInterventionCost           = {this.props.viewInterventionCost}
                    language                       = {this.props.language}
                />

                <IVApproachFilter
                    onStateChange={this.props.onStateChange}
                    onOutcomeSwitchChange={this.props.onOutcomeSwitchChange}
                    nutritionSelected={this.props.nutritionSelected}
                    birthOutcomeSelected={this.props.birthOutcomeSelected}
                    style={styles.filter}
                />

                {
                    (this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted) ?
                        <Outcomes
                            onStateChange                  = {this.props.onStateChange}
                            displayMaternalCOD             = {this.props.displayMaternalCOD}
                            displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                            displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                            displayChildCOD                = {this.props.displayChildCOD}
                            displayCPR                     = {this.props.displayCPR}
                            MOResultToDisplay              = {this.props.MOResultToDisplay}
                            maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                            stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                            neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                            childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                            language                       = {this.props.language}
                        /> : null
                }

                <CausesOfDeath
                    onStateChange                  = {this.props.onStateChange}
                    displayMaternalCOD             = {this.props.displayMaternalCOD}
                    displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                    displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                    displayChildCOD                = {this.props.displayChildCOD}
                    maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                    stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                    neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                    childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                    MOResultToDisplay              = {this.props.MOResultToDisplay}
                    language                       = {this.props.language}
                />

                <DeliveryPoints
                    onStateChange             = {this.props.onStateChange}
                    deliveryPointsContent     = {this.props.deliveryPointsContent}
                    language                  = {this.props.language}
                />

                {(this.props.displayButtons) ?
                    <div>
                        <div>
                            <TButton
                                caption={RS('GB_stCreateProjFor') + ' ' + this.props.selectedCountry}
                                color="primary"
                                style={styles.createButton}
                                onClick={this.createProjBtnClick}
                            />
                        </div>
                    </div>:
                    null
                }

            </div>
        );
    }
}

export default MOSideBar;