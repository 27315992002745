import './App.css';
import * as React from 'react';
import CreateAPITask from "../api/util/createAPITask";
import TAppSplash from '@common/components/TAppSplash';
import TDisconnected from '@common/components/TDisconnected';
import RS from "@common/strings/RS";
import Main from './Main';
import APIService from '../api/APIService';
import CONSTANTS from "utilities/CS/CSConst";
import Theme from './Theme';
import CSTemplate from '../data/CSTemplate';
import TCSStatusTimer from '../components/CS/TCSStatusTimer';
import TCSDisconnectionWarning from '../components/CS/TCSDisconnectionWarning';
import TCSUserLoggedOff from '../components/CS/TCSUserLoggedOff';
import { LocalStorageUtil } from '../api/localstorage/util';
import * as CT from '../api/container_calls';
import TestContainers from '../api/tests/TestContainers';

class App extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    state = {

        developerMode                       : false,
        useContainer                        : window.App.useContainers,

        //--------------------------------------------
        // Client
        //--------------------------------------------

        // API
        apiMainMsg                          : "",
        apiSubMsg                           : "",

        // General
        title                               : 'LiST Online',
        loading                             : true,
        sidebarOpen                         : false,
        isDeskTop                           : true,
        language                            : LocalStorageUtil.getLanguage(),

        // Navigation
        pageID                              : "CSHomeForm",
        backPageID                          : "CSHomeForm",
        appMode                             : CONSTANTS.CS_ProjectionMode,

        // Dialogs
        dialogAPIOpen                       : false,
        dialogAboutOpen                     : false,
        dialogServerAboutOpen               : false,
        dialogNewSupportOpen                : false,
        dialogAccountCreateOpen             : false,   // Not finished
        dialogAccountUpdateOpen             : false,   // Not finished
        dialogActionsOpen                   : false,
        dialogCloseOpen                     : false,
        dialogDeletionCheckOpen             : false,
        dialogEditTagsOpen                  : false,
        dialogErrorOpen                     : false,
        dialogFileCopyOpen                  : false,
        dialogFileRenameOpen                : false,
        dialogFilterTagsOpen                : false,
        dialogForgotPasswordOpen            : false,    // Not finished
        dialogLanguageOpen                  : false,
        dialogLogInOpen                     : false,
        dialogMultiplicationOpen            : false,
        dialogNewTagOpen                    : false,
        dialogNotLoggedInOpen               : false,
        dialogNutritionOpen                 : false,
        dialogPrivacyOpen                   : false,
        dialogProjCreatedOpen               : false,
        dialogProjInfoOpen                  : false,
        dialogSaveAsOpen                    : false,
        dialogSubnatCheckOpen               : false,
        dialogWarningOpen                   : false,
        dialogFileAlreadyExistsOpen         : false,
        dialogFileWithSameNameOpen          : false,

        // Messages + Dialog helpers
        warningMessage                      : '',
        errorMessage                        : '',
        explanationText                     : '',
        closeProjReason                     : '',
        multiplier                          : 1,

        // Input
        projNameSaveAs                      : '',
        prevProjName                        : '',

        // API
        apiTasks                            : [],
        heartBeat                           : 0,

        toFetchResults                      : [
                                                { proj : 1, modID : 15, resultID : CONSTANTS.CS_MstChildDeaths },
                                                { proj : 1, modID : 15, resultID : CONSTANTS.CS_MstChDeathsByCause },
                                                { proj : 1, modID : 15, resultID : CONSTANTS.CS_MstChDeathsPrByInter },
                                                { proj : 1, modID : 15, resultID : CONSTANTS.CS_MstMortalityRates },
                                                { proj : 1, modID : 15, resultID : CONSTANTS.CS_MstU5MortalityRate },
    
                                                { proj : 1, modID : 15, resultID : 1 },
                                                { proj : 1, modID : 15, resultID : 3 },
                                                { proj : 1, modID : 15, resultID : 14 },
                                                { proj : 1, modID : 15, resultID : 14 },
                                              ],
        selectedResults                     : [
                                                /* Neonatal */
                                                //[CONSTANTS.CS_MstNNDeaths, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNNDeathsByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNNDeathsPrev, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNNDeathsPrByCause, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstNNDeathsPrByInter, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNNDeathsPrByInterByCause, CONSTANTS.GB_CS],

                                                /* Child */
                                                // [CONSTANTS.CS_MstChildDeaths, CONSTANTS.GB_CS],
                                                // [CONSTANTS.CS_MstChDeathsByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstChDeathsPrev, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstChDeathsPrByCause, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstChDeathsPrByInter, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstChDeathsPrByInterByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstDAvtdByVaccByCohort, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstDAvtdByVaccByCohortByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstChildDeathsByYearAgeGroup, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstChDeathsByBirthCoh, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstChDeathsByCauseByBirthCoh, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstVaccineCoverage, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumPersVaccByInterv, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumPersVaccByIntervBy1YrAgeGrps, CONSTANTS.GB_CS],

                                                /* Maternal */
                                                //[CONSTANTS.CS_MstMDeaths, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstMDeathsByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstMDeathsPrev, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstMDeathsPrByCause, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstMDeathsPrByInter, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstMDeathsPrByInterByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstAbortion, CONSTANTS.GB_CS],

                                                /* Stillbirth */
                                                //[CONSTANTS.CS_MstSB, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstSBByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstSBPrev, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstSBPrByCause, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstSBPrByInter, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstSBPrByInterByCause, CONSTANTS.GB_CS],

                                                /* Mortality rates */
                                                [CONSTANTS.CS_MstNNMortalityRate, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstIMortalityRate, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstU5MortalityRate, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstMMortalityRatio, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstMMortalityRate, CONSTANTS.GB_CS],
                                                [CONSTANTS.CS_MstSBRate, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstMortalityRates, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstRedMortByInterv, CONSTANTS.GB_CS],

                                                /* Nutrition */
                                                //[CONSTANTS.CS_MstBirthTermResults, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstPercInVarHtForAgeStunt, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstPercInVarWtForHtWaste, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstGlobalStuntRate, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstGlobalWasteRate, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNumStunted, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNumStuntedAverted, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNumStuntedAvertedByInter, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstAttrStuntReduct, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstBFPrevalence, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstPercExclusiveBF, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstPrevEarlyInitBF, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstPercWomenAnemicResults, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstNumWomenAnemicResults, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstAnemiaCasesPreventedResults, CONSTANTS.GB_CS],

                                                /* Incidence and etiology */
                                                //[CONSTANTS.CS_MstEtiIncRates, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumU5CasesByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumU5CasesAvtdByCause, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiPerc1t59MoDeathsByPath, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiPerc1t59MoCasesByPath, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumDeathsByPath, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumCasesByPath, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumDeathsByPathBy1YrAgeGrps, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumCasesByPathBy1YrAgeGrps, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNumU5CasesByCauseBirthCoh, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNum1t59MoDeathsByPathBirthCoh, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNum1t59MoCasesByPathBirthCoh, CONSTANTS.GB_CS],
                                                //[CONSTANTS.CS_MstEtiNum1t59MoCasesAvtdByCauseYr1stDose, CONSTANTS.GB_CS],

                                                /* Demography */
                                                //[CONSTANTS.DP_MstcmPop04, CONSTANTS.GB_DP],
                                                //[CONSTANTS.DP_MstcmDispTFR, CONSTANTS.GB_DP],
                                                //[CONSTANTS.DP_MstcmTotalLE, CONSTANTS.GB_DP],
                                                //[CONSTANTS.DP_MstcmBirths, CONSTANTS.GB_DP]
                                              ],
        availableResults                    : [
                                                /* Neonatal */
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNNDeaths},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNNDeathsByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNNDeathsPrev},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNNDeathsPrByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNNDeathsPrByInter},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNNDeathsPrByInterByCause},

                                                 /* Child */
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChildDeaths},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChDeathsByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChDeathsPrev},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChDeathsPrByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChDeathsPrByInter},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChDeathsPrByInterByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstDAvtdByVaccByCohort},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstDAvtdByVaccByCohortByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChildDeathsByYearAgeGroup},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChDeathsByBirthCoh},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstChDeathsByCauseByBirthCoh},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstVaccineCoverage},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumPersVaccByInterv},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumPersVaccByIntervBy1YrAgeGrps},

                                                 /* Maternal */
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMDeaths},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMDeathsByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMDeathsPrev},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMDeathsPrByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMDeathsPrByInter},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMDeathsPrByInterByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstAbortion},

                                                 /* Stillbirth */
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSB},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSBByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSBPrev},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSBPrByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSBPrByInter},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSBPrByInterByCause},

                                                 /* Mortality rates */
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNNMortalityRate},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstIMortalityRate},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstU5MortalityRate},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMMortalityRatio},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMMortalityRate},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSBRate},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstMortalityRates},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstRedMortByInterv},

                                                 /* Nutrition */
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstBirthTermResults},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstSubOpBOAvertByInterInd},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstLowBirthWeight},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstPercInVarHtForAgeStunt},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstPercInVarWtForHtWaste},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstGlobalStuntRate},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstGlobalWasteRate},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNumStunted},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNumStuntedAverted},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNumStuntedAvertedByInter},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstAttrStuntReduct},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstAdditionalYrsSchoolingGainedInd},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstPercIncEarnStuntDeclineInd},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstImpLifeEarnDueToStuntReducInd},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstLifetimeEarningsObtainedInd},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstBFPrevalence},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstPercExclusiveBF},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstPrevEarlyInitBF},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstPercWomenAnemicResults},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstNumWomenAnemicResults},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstAnemiaCasesPreventedResults},

                                                 /* Incidence and etiology */
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiIncRates},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumU5CasesByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumU5CasesAvtdByCause},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiPerc1t59MoDeathsByPath},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiPerc1t59MoCasesByPath},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumDeathsByPath},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumCasesByPath},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumDeathsByPathBy1YrAgeGrps},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumCasesByPathBy1YrAgeGrps},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNumU5CasesByCauseBirthCoh},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNum1t59MoDeathsByPathBirthCoh},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNum1t59MoCasesByPathBirthCoh},
                                                {modID : CONSTANTS.GB_CS, mstID : CONSTANTS.CS_MstEtiNum1t59MoCasesAvtdByCauseYr1stDose},

                                                /* Demography */
                                                {modID : CONSTANTS.GB_DP, mstID : CONSTANTS.DP_MstcmTotalPop},
                                                {modID : CONSTANTS.GB_DP, mstID : CONSTANTS.DP_MstcmPop04},
                                                {modID : CONSTANTS.GB_DP, mstID : CONSTANTS.DP_MstcmDispTFR},
                                                {modID : CONSTANTS.GB_DP, mstID : CONSTANTS.DP_MstcmTotalLE},
                                                {modID : CONSTANTS.GB_DP, mstID : CONSTANTS.DP_MstcmBirths},

                                                /* AIM/HIV */
                                                {modID : CONSTANTS.GB_AM, mstID : CONSTANTS.DP_MstcmNumNewInf},
                                                {modID : CONSTANTS.GB_AM, mstID : CONSTANTS.DP_MstcmAIDSDeaths},

                                                /* FamPlan */
                                                {modID : CONSTANTS.GB_FP, mstID : CONSTANTS.FP_MstcmPregnancies},
                                                {modID : CONSTANTS.GB_FP, mstID : CONSTANTS.FP_MstcmNumUnintendPreg},
                                                {modID : CONSTANTS.GB_FP, mstID : CONSTANTS.FP_MstcmAbortions},
                                                {modID : CONSTANTS.GB_FP, mstID : CONSTANTS.FP_MstcmIllAbortions},
                                                {modID : CONSTANTS.GB_FP, mstID : CONSTANTS.FP_MstcmUsers},
                                               ],
        selectedPackTables                  : [],
        selectedPackCharts                  : [],
        legendReversed                      : false,
        stacked                             : '',
        chartYears                          : {},
        chartOptions                        : [],
        inputModvars                        : [],

        //--------------------------------------------
        // Server
        //--------------------------------------------

        // Server info
        serverAlive                         : false,

        version                             : {},
        countries                           : [],
        IVGroupInfo                         : [],
        IVSubGroupInfo                      : [],
        IVInfo                              : [],

        resultMenus                         : {},
        DPTree                              : [],                // resultMenus
        AMTree                              : [],                // resultMenus
        FPTree                              : [],                // resultMenus
        CSTree                              : [],                // resultMenus
        
        //--------------------------------------------

        // Account info
        signedIn                            : false,
        userName                            : '',
        humanName                           : '',
        isGuest                             : true,

        //--------------------------------------------

        // Session status
        sessionExists                       : false,
        disconnectWarning                   : false,
        userLoggedOff                       : false,
        TimeToLive                          : "0:60:0",
        TimeIdle                            : "0:0:0",
        TimeExpire                          : "1:0:0",
        TimeTotal                           : "0:0:0",
        disconnecting                       : false,
        status                              : {},

        //--------------------------------------------

        // Session data
        storedProjections                   : [],
        activeProjections                   : [],

        //--------------------------------------------

        // Projection status
        GB                                  : {},

        // Projection general
        projectCreated                      : false,
        projectionName                      : '',
        projectionNotes                     : '',
        selectedCountry                     : 'Afghanistan',
        countryISO                          : 4,
        firstYear                           : new Date().getFullYear(),
        finalYear                           : CONSTANTS.CS_DefaultFinalYear,
        finalYearIdx                        : (CONSTANTS.CS_DefaultFinalYear - (new Date()).getFullYear() - 4),
        yearRange                           : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        subnatProjection                    : false,
        includeFamPlan                      : true,
        useFamPlanOutputs                   : false,
        saveBtnDisabled                     : true,
        topicList                           : [],
        fileToCopy                          : "",
        fileToRename                        : "",
        fileToTag                           : "",
        chosenTags                          : "",
        fileForActions                      : "",
        actionNotes                         : "",
        fileToDelete                        : "",
        topicFilter                         : [],
        selectedProjSideBarIdx              : CONSTANTS.CS_ProjectionManagerIdx,
        maternalIntervSelected              : true,
        stillbirthIntervSelected            : true,
        neonatalIntervSelected              : true,
        childIntervSelected                 : true,
        nutritionSelected                   : true,
        birthOutcomeSelected                : true,
        adolescentsSelected                 : false,
        selectAllIntervBool                 : false,
        isExpandMODrawerClosed              : true,
        showAllInterventionsCB              : false,
        approach                            : CONSTANTS.CS_StandardApproach,

        // Projection details
        CSModvars                           : [],
        CSResults                           : [],

        //Guided exploration
        causesOfDeathContent                : [],
        deliveryPointsContent               : [],
        maternalCausesOfDeathContent        : [],
        stillbirthCausesOfDeathContent      : [],
        neonatalCausesOfDeathContent        : [],
        childCausesOfDeathContent           : [],
        adolescentCausesOfDeathContent      : [],

        displayMaternalCOD                  : true,
        displayStillbirthCOD                : true,
        displayNeonatalCOD                  : true,
        displayChildCOD                     : true,
        displayAdolescentCOD                : false,
        displayCPR                          : true,
        sortByDeathsAverted                 : true,
        sortByInterventionCost              : true,
        viewInterventionCost                : true,
        displayChart                        : true,
        topMOInterventions                  : [],
        MOResultToDisplay                   : CONSTANTS.CS_PotentialDeathsAverted,

        MOOptions                           : {},
        MOCountryData                       : [],

        intervDrawerOpen                    : false,
        showMOIntervSection                 : false,

        //miscellaneous
        openingProj                         : "",
        authorName                          : "",
        orgName                             : "",
        displayEndpoints                    : false,
        displayCoverage                     : false,
        retroYear                           : false,
        showRetroYears                      : false,
        retroYears                          : false,
        subnatRegion                        : RS("GB_stNoRegionSelected"),
        subnatSurvey                        : "",
        unchangedProj                       : false,
        coverageDisplay                     : CONSTANTS.CS_TableChart,
        newFile                             : false,
        hamburgerMenuOpen                   : false,
        isMobile                            : false,
        menuThreshold                       : false,
        exportFileName                      : null,
        AgeGroupOption                      : null
    };

    constructor(props){
        super(props);

        this.appFuncs = {
            onPageChange                            : this.onPageChange,
            onContinueWorking                       : this.onContinueWorking,
            onStateChange                           : this.onStateChange,
            onModvarChange                          : this.onModvarChange,
            onPackTableChange                       : this.onPackTableChange,
            onAddTasks                              : this.onAddTasks,
            onDeleteTask                            : this.onDeleteTask,
            onHamburgerClick                        : this.onHamburgerClick,
            onSelectedResultsSaved                  : this.onNotLoggedInDialogClose,
            onNotLoggedInDialogClose                : this.onNotLoggedInDialogClose,
            onProjCreatedDialogClose                : this.onProjCreatedDialogClose,
            onCreateProjBtnClick                    : this.onCreateProjBtnClick,
            onGuidedExplorationBtnClick             : this.onGuidedExplorationBtnClick,
            onExploreLiSTDataBtnClick               : this.onExploreLiSTDataBtnClick,
            onCreateProjection                      : this.onCreateProjection,
            onCreateProjectionFromGuidedExploration : this.onCreateProjectionFromGuidedExploration,
            onModifyProjection                      : this.onModifyProjection,
            onOpenProjection                        : this.onOpenProjection,
            onCloseProjection                       : this.onCloseProjection,
            onSaveProjection                        : this.onSaveProjection,
            onDeleteProjection                      : this.onDeleteProjection,
            onDownloadProjection                    : this.onDownloadProjection,
            onCopyProjection                        : this.onCopyProjection,
            onRenameProjection                      : this.onRenameProjection,
            onUploadProjection                      : this.onUploadProjection,
            onImportProjection                      : this.onImportProjection,
            onLogout                                : this.onLogout,
            onViewResults                           : this.onViewResults,
            onSaveLanguage                          : this.onSaveLanguage,
            onSaveTopic                             : this.onSaveTopic,
            onGetTopicList                          : this.onGetTopicList,
            onAPIMainMsgChange                      : this.onAPIMainMsgChange,
            onAPISubMsgChange                       : this.onAPISubMsgChange,
            onExtractBtnClick                       : this.onExtractBtnClick,
            onGetDataPackResults                    : this.onGetDataPackResults,
            handleSelectedResults                   : this.handleSelectedResults
        }
    }

    componentDidMount () {
        /* DO NOT USE THIS!!!  THIS WAS A SPECIAL CASE WITH PERMISSION FROM BOB!!! */
        window.appState = this.state;
        
        this.onResize();
        window.addEventListener("resize", this.onResize);
        
        this.onInitDefaultSession(() => {
            window.App.languageIdx = this.state.language - 1;
            this.handleSelectedResults(true, 0);
            this.onInitSession();
        });

        // //a function to get URL parameters
        // function grab(param) {
        //     var grabs = {};
        //     var grab = window.location.search.substring(1);
        //     grab = grab.split("&");
        //     for (let i=0; i < grab.length; i++) {
        //         var pair = grab[i].split("=");
        //         grabs[pair[0]] = pair[1];
        //     }
        //     return decodeURIComponent(grabs[param]);
        // }

        // if (grab("tool") === "Extract") {
        //     this.setState({pageID: "ExtractTool"});
        // }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    componentDidUpdate() {
        /* DO NOT USE THIS!!!  THIS WAS A SPECIAL CASE WITH PERMISSION FROM BOB!!! */
        window.appState = this.state;

        window.App.languageIdx = this.state.language - 1;
    }  

    //==================================================================================================================
    //
    //                                                 Setup
    //
    //==================================================================================================================

    onInitSession = () => {

        const startSession = () => {

            this.setState({
                connecting : true
            });
    
            this.onAddTasks([
                CreateAPITask('fetchSession', {}, () => {

                    if (this.state.projectCreated) {

                        this.onAddTasks([
                            CreateAPITask('getMOCountryDataFlag', {countryISO: this.state.countryISO, useRawData: false}, () => {
                                console.log('Initialized');
                                this.setState({
                                    connected  : true,
                                    connecting : false,
                                    loading    : false
                                });
                            }, (msg) => {
                                alert(RS('GB_stErrorInitializing') + ' ' + msg);
                                this.setState({
                                    connected  : false,
                                    connecting : false,
                                    loading    : false
                                });
                            })
                        ]);

                    }
                    else {
                        console.log('Initialized');
                        this.setState({
                            connected  : true,
                            connecting : false,
                            loading    : false
                        });
                    }
                }, (msg) => {
                    alert(RS('GB_stErrorInitializing') + ' ' + msg);
                    this.setState({
                        connected  : false,
                        connecting : false,
                        loading    : false
                    });
                })
            ]);
        };

        const getContainerURLSuccessFn = (response) => {
            startSession(); 
        };

        const getContainerURLErrorFn = (msg) => {
            this.setState({
                loading : false
            });  
        };

        if (this.state.useContainer) {
            CT.ContainerLogic(getContainerURLSuccessFn, getContainerURLErrorFn); 
        }
        else {
            startSession();
        }
    };

    onInitDefaultSession = (successFn) => {
        this.onStateChange({

            // Server info
            serverAlive       : CSTemplate.ServerInfo.serverAlive,

            version           : CSTemplate.ServerInfo.version,
            countries         : CSTemplate.ServerInfo.countries,
            IVGroupInfo       : CSTemplate.ServerInfo.IVGroupInfo,
            IVSubGroupInfo    : CSTemplate.ServerInfo.IVSubGroupInfo,
            IVInfo            : CSTemplate.ServerInfo.IVInfo,

            resultMenus       : CSTemplate.ServerInfo.resultMenus,
            DPTree            : CSTemplate.ServerInfo.DPTree,
            AMTree            : CSTemplate.ServerInfo.AMTree,
            FPTree            : CSTemplate.ServerInfo.FPTree,
            CSTree            : CSTemplate.ServerInfo.CSTree,

            //--------------------------------------------

            // Account info
            language          : LocalStorageUtil.getLanguage(),
            signedIn          : CSTemplate.AccountInfo.signedIn,
            userName          : CSTemplate.AccountInfo.userName,
            humanName         : CSTemplate.AccountInfo.humanName,
            isGuest           : CSTemplate.AccountInfo.isGuest,

            //--------------------------------------------

            // Session status
            sessionExists     : CSTemplate.SessionInfo.sessionExists,
            userLoggedOff     : CSTemplate.SessionInfo.userLoggedOff,
            disconnectWarning : CSTemplate.SessionInfo.disconnectWarning,
            TimeToLive        : CSTemplate.SessionInfo.TimeToLive,
            TimeIdle          : CSTemplate.SessionInfo.TimeIdle,
            TimeExpire        : CSTemplate.SessionInfo.TimeExpire,
            TimeTotal         : CSTemplate.SessionInfo.TimeTotal,
            disconnecting     : CSTemplate.SessionInfo.disconnecting,
            
            //--------------------------------------------

            // Session data
            storedProjections : CSTemplate.SessionData.storedProjections,
            activeProjections : CSTemplate.SessionData.activeProjections,

            //--------------------------------------------

            projectCreated           : CSTemplate.Projection.projectCreated,
            projectionName           : CSTemplate.Projection.projectionName,
            projectionNotes          : CSTemplate.Projection.projectionNotes,
            selectedCountry          : CSTemplate.Projection.selectedCountry,
            countryISO               : CSTemplate.Projection.countryISO,
            firstYear                : CSTemplate.Projection.firstYear,
            finalYear                : CSTemplate.Projection.finalYear,
            finalYearIdx             : CSTemplate.Projection.finalYearIdx,
            subnatProjection         : CSTemplate.Projection.subnatProjection,
            includeFamPlan           : CSTemplate.Projection.includeFamPlan,
            useFamPlanOutputs        : CSTemplate.Projection.useFamPlanOutputs,
            saveBtnDisabled          : CSTemplate.Projection.saveBtnDisabled,
            legendReversed           : CSTemplate.Projection.legendReversed,
            stacked                  : CSTemplate.Projection.stacked,
            chartOptions             : CSTemplate.Projection.chartOptions,
            chartYears               : CSTemplate.Projection.chartYears,
            inputModvars             : CSTemplate.Projection.inputModvars,
            intervDrawerOpen         : CSTemplate.Projection.intervDrawerOpen,
            showMOIntervSection      : CSTemplate.Projection.showMOIntervSection,
            selectedProjSideBarIdx   : CSTemplate.Projection.selectedProjSideBarIdx,
            maternalIntervSelected   : CSTemplate.Projection.maternalIntervSelected,
            stillbirthIntervSelected : CSTemplate.Projection.stillbirthIntervSelected,
            neonatalIntervSelected   : CSTemplate.Projection.neonatalIntervSelected,
            childIntervSelected      : CSTemplate.Projection.childIntervSelected,
            selectAllIntervBool      : CSTemplate.Projection.selectAllIntervBool,
            isExpandMODrawerClosed   : CSTemplate.Projection.isExpandMODrawerClosed,
            showAllInterventionsCB   : CSTemplate.Projection.showAllInterventionsCB,

            //--------------------------------------------

            CSModvars         : CSTemplate.CSModvars.map(x => ({ ...x, proj : 1 })),
            CSResults         : CSTemplate.CSResults.map(x => ({ ...x, proj : 1 })),

            //---------------------------------------------
            deliveryPointsContent          : CSTemplate.GuidedExploration.MOOptions.deliveryPointsContent,
            maternalCausesOfDeathContent   : CSTemplate.GuidedExploration.MOOptions.maternalCausesOfDeathContent,
            stillbirthCausesOfDeathContent : CSTemplate.GuidedExploration.MOOptions.stillbirthCausesOfDeathContent,
            neonatalCausesOfDeathContent   : CSTemplate.GuidedExploration.MOOptions.neonatalCausesOfDeathContent,
            childCausesOfDeathContent      : CSTemplate.GuidedExploration.MOOptions.childCausesOfDeathContent,
            displayMaternalCOD             : CSTemplate.GuidedExploration.MOOptions.displayMaternalCOD,
            displayStillbirthCOD           : CSTemplate.GuidedExploration.MOOptions.displayStillbirthCOD,
            displayNeonatalCOD             : CSTemplate.GuidedExploration.MOOptions.displayNeonatalCOD,
            displayChildCOD                : CSTemplate.GuidedExploration.MOOptions.displayChildCOD,
            displayCPR                     : CSTemplate.GuidedExploration.MOOptions.displayCPR,
            sortByDeathsAverted            : CSTemplate.GuidedExploration.MOOptions.sortByDeathsAverted,
            sortByInterventionCost         : CSTemplate.GuidedExploration.MOOptions.sortByInterventionCost,
            viewInterventionCost           : CSTemplate.GuidedExploration.MOOptions.viewInterventionCost,
            displayChart                   : CSTemplate.GuidedExploration.MOOptions.displayChart,
            MOResultToDisplay              : CSTemplate.GuidedExploration.MOOptions.MOResultToDisplay,
            MOCountryData                  : CSTemplate.GuidedExploration.MOCountryData,
            topMOInterventions             : CSTemplate.GuidedExploration.topMOInterventions,

            //------------------------------------------------------------------------------------
        }, successFn);
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onResize = () => {
        this.setState({
            isDeskTop       : window.innerWidth > 1150,
            isMobile        : window.innerWidth < 500,
            menuThreshold   : window.innerWidth < 1375
        });
    };

    onPageChange = (id) => {
        const urlName = 'https://list.spectrumweb.org/';
        if (id === 'CSProjManagerForm') {
            window.fathom && window.fathom.trackPageview({
                url : urlName + 'Projection'
            });
            this.setState ({
                sidebarOpen            : false,
                appMode                : CONSTANTS.CS_ProjectionMode,
                selectedProjSideBarIdx : CONSTANTS.CS_ProjectionManagerIdx
            });
        }

        if (id === 'CSReviewDefaultDataForm') {
            this.setState ({
                // sidebarOpen : true,
                appMode                : CONSTANTS.CS_ProjectionMode,
                selectedProjSideBarIdx : CONSTANTS.CS_ReviewDefaultDataIdx
            });
        }

        if (id === 'CSManageInterventionsForm') {
            this.setState ({
                // sidebarOpen : true,
                appMode                : CONSTANTS.CS_ProjectionMode,
                selectedProjSideBarIdx : CONSTANTS.CS_ManageInterventionIdx
            });
        }

        if (id === 'CSViewResultsForm') {
            this.setState ({
                // sidebarOpen : true,
                appMode                : CONSTANTS.CS_ProjectionMode,
                selectedProjSideBarIdx : CONSTANTS.CS_ViewResultsIdx
            });
        }

        if (id === 'CSProjectionLibraryForm') {
            this.setState ({
                sidebarOpen : false,
                appMode     : CONSTANTS.CS_ProjectionMode
            });
        }

        if (id === 'CSCountryListForm') {
            this.setState ({
                sidebarOpen : false,
            });
        }

        if (id === 'CSExploreLiSTDataForm') {
            window.fathom && window.fathom.trackPageview({
                url : urlName + 'Explore Data'
            });
            this.setState ({
                sidebarOpen          : true,
                appMode              : CONSTANTS.CS_ExploreLiSTDataModeStep1,
            });
        }

        if (id === 'CSMissedOpportunitiesForm') {
            window.fathom && window.fathom.trackPageview({
                url : urlName + 'Missed Opportunities'
            });
            this.setState ({
                sidebarOpen           : true,
                appMode               : CONSTANTS.CS_MissedOpportunitiesModeStep1,
                topMOInterventions    : [],
                MOResultToDisplay     : CONSTANTS.CS_PotentialDeathsAverted,
            });
        }

        if (id === 'ExtractTool') {
            this.setState ({
                sidebarOpen          : false,
                appMode              : CONSTANTS.CS_ExtractMode,
            });
        }

        if ((id === 'CSHomeForm') && (!this.state.projectCreated)) {
            this.setState ({
                sidebarOpen: false,
                appMode     : CONSTANTS.CS_ProjectionMode
            });
        }

        if ( (typeof id === 'undefined') || (id === '') ) {
            this.setState({
                pageID  : this.state.pageID,
                appMode : CONSTANTS.CS_ProjectionMode
            });
            return;
        }

        // if (id === 'GBLoginFm') {
        //     this.setState({
        //         loginOpen : true
        //     });
        //     return;
        // }

        if (id === 'back') {
            id = this.state.backPageID;
        }

        this.setState({
            backPageID : this.state.pageID,
            pageID : id
        });
    };

    onAPIMainMsgChange = (s) => {
        this.setState({
            apiMainMsg : s
        });
    };

    onAPISubMsgChange = (s) => {
        this.setState({
            apiSubMsg : s
        });
    };

    //on the click of the Continue Working button, it should take users right to the project manager, unless already on one of the sub-pages.
    onContinueWorking = () => {
        let goal = "CSProjManagerForm";
        if (this.state.pageID === "CSReviewDefaultDataForm" || this.state.pageID === "CSManageInterventionsForm" || this.state.pageID === "CSViewResultsForm") {
            goal = this.state.pageID;
        }
        this.onPageChange(goal);
    };

    onSaveTopic = (v, name, vals) => {
        let json = JSON.parse(JSON.stringify(this.state.storedProjections));
        let multTags = "";
        json.forEach(function(o) {
            if (o.name === name) {
                let tagArr = o.tag.split(", ");
                if (tagArr.includes("")) {
                    for (let i = tagArr.length - 1; i >= 0; i--) {
                        if (tagArr[i] === "") {
                            tagArr.splice(i, 1);
                        }
                    }
                }
                if (v.length > 0) {
                    tagArr.push(v);
                }
                tagArr.sort(function(a, b) {
                    let scale = 0;
                    if (a.toLowerCase() < b.toLowerCase()) {
                        scale = -1;
                    } else {
                        scale = 1;
                    }
                    return scale;
                });
                multTags = tagArr.join(", ");
                o.tag = vals || vals === "" ? vals.replace(/^,\s/, "") : multTags;
            }
        });

        this.onAddTasks([
            CreateAPITask("saveTopicFlag",
                {fileTitle: name, tag: vals || vals === "" ? vals.replace(/^,\s/, "") : multTags},
                (res) => {
                    this.onGetTopicList();
                    // let json = JSON.parse(JSON.stringify(this.state.storedProjections));
                    // json.forEach(function(o) {
                    //     if (o.name === name) {
                    //         o.tag = v;
                    //     }
                    // });
                    this.setState({
                        storedProjections: json,
                        dialogNewTagOpen: false,
                        dialogActionsOpen: false
                    }, () => this.onGetTopicList());
                },
                (res) => {
                    console.log(res);
                })
        ])
    };

    onGetTopicList = () => {
        let tags = [];
        this.state.storedProjections.forEach(function(proj) {
            let projTags = proj.tag.split(", ");
            projTags.forEach(function(projTag) {
                if (!tags.includes(projTag)) {
                    tags.push(projTag);
                }
            });
        });
        tags.sort(function(a, b) {
            let scale = 1;
            if (a.toLowerCase() < b.toLowerCase()) {
                scale = -1;
            }
            return scale;
        });
        this.setState({topicList: tags.includes("") ? tags.splice(1) : tags});
        // this.onAddTasks([
        //     CreateAPITask("getTagList", {}, () => {}, (msg) => console.log(msg))
        // ]);
    };

    // onSaveLanguage = (lang) => {
    //     if (this.state.projectCreated) {
    //         let modvars = [];
    //         this.state.CSModvars.forEach(function(modvar) {
    //             if (modvar.tag === "<InterventionList MV>" || modvar.tag === "<InterventionGroupList MV>" || modvar.tag === "<InterventionSubGroupList MV>") {
    //                 modvars.push(modvar);
    //             }
    //         });
    //         this.onAddTasks([
    //             CreateAPITask("setModvars",
    //                 {arr: this.state.CSModvars},
    //                 (r) => {
    //                     this.onAddTasks([
    //                         CreateAPITask("setUserOptions",
    //                             {lang: lang},
    //                             (res) => {
    //                                 this.onAddTasks([
    //                                     CreateAPITask("getModvars",
    //                                         {arr: this.state.CSModvars},
    //                                         (resp) => {
    //                                             LocalStorageUtil.setLanguage(lang);
    //                                         },
    //                                         (e) => {
    //                                             console.log(e);
    //                                         })
    //                                 ])
    //                             },
    //                             (err) => {
    //                                 console.log(err);
    //                             })
    //                     ])
    //             },
    //             (msg) => {
    //                 console.log(msg);
    //             })
    //         ])
    //     } else {
    //         this.onAddTasks([
    //             CreateAPITask("setUserOptions",
    //                 {lang: lang},
    //                 (response) => {
    //                     LocalStorageUtil.setLanguage(lang);
    //                 },
    //                 (er) => {
    //                     console.log(er);
    //                 })
    //         ])
    //     }
    // };

    onSaveLanguage = (lang) => {
        this.onAddTasks([
            CreateAPITask("setUserOptions",
                {lang: lang},
                (response) => {
                    LocalStorageUtil.setLanguage(lang);
                },
                (er) => {
                    console.log(er);
                })
        ])
    };

    onStateChange = (newState, successFn) => {

        let that = this;

        Object.keys(newState).forEach(function(key, index) {
            if (!that.state.hasOwnProperty(key)) {
                console.error('You have added in new state to <App /> that did not exist! Field is : ' + key);
            }
        });

        // /* Debug test */
        // Object.keys(newState).forEach(key => {
        //     if (that.state.hasOwnProperty(key)) {
        //         if (key === "chartYears") {
        //             if (isNaN(newState.chartYears.finalYrIdx)) {
        //                 debugger;
        //             }
        //         }
        //     }
        // });

        this.setState((prevState, props) => ({
            ...prevState,
            ...newState
        }), () => {
            
        // this.setState((prevState, props) => (
        //     newState
        // ), () => {
            if (typeof successFn !== "undefined") {
                successFn();
            }
        });
    };

    onAddTasks = (arr) => {
        this.setState((prevState, props) => ({
            apiTasks  : [...prevState.apiTasks, ...arr]
        }));
    };

    onDeleteTask = (GUID) => {
        this.setState((prevState, props) => ({
            apiTasks  : prevState.apiTasks.filter((el) => (el.GUID === GUID ? false : true))
        }));
    };

    onHamburgerClick = (value) => {
        // if (
        //     (this.state.appMode !== CONSTANTS.CS_MissedOpportunitiesMode) &&
        //     (this.state.appMode !== CONSTANTS.CS_ExploreLiSTDataMode) &&
        //     (this.state.appMode !== CONSTANTS.CS_ProjectionMode) &&
        //     (this.state.appMode !== CONSTANTS.CS_ExtractMode)
        // ) {
        //     this.setState({
        //         sidebarOpen: !this.state.sidebarOpen,
        //     });
        // }
        this.setState({
            hamburgerMenuOpen: !this.state.hamburgerMenuOpen
        })
    };

    onSaveLanguageClick = () => {
        this.setState({
            dialogLanguageOpen : false
        })
    };

    //---------------------------------------------------

    onSelectedResultsSaved = (resultsArray) => {
        this.setState({
            selectedResults : resultsArray
        });
    };

    onNotLoggedInDialogClose = (action) => {
        if (action === 'logIn') {
            this.setState({
                dialogLogInOpen       : true,
                dialogNotLoggedInOpen : false
            });
        }
        else {
            this.setState({
                dialogNotLoggedInOpen : false
            });
        }
    };

    onProjCreatedDialogClose = (action) => {
        this.setState({
            dialogProjCreatedOpen : false
        }, () => {
            if (action === 'save') {
                if (this.state.isGuest) {
                    this.setState({
                        dialogNotLoggedInOpen : true
                    });
                }
                else {
                    this.onSaveProjection();
                    this.onCloseProjection();
                }

            }
            else if (action === 'close') {
                this.onCloseProjection();
            }
        });
    };

    //---------------------------------------------------

    onCreateProjBtnClick = () => {
        if (this.state.appMode === CONSTANTS.CS_MissedOpportunitiesModeStep1) {
            this.setState ({
                selectedCountry     : this.state.selectedCountry,
                showMOIntervSection : true
            });
        }
        else {
            this.setState ({
                showMOIntervSection : false
            })
        }

        if (this.state.projectCreated) {
            this.setState({
                explanationText       : RS('GB_stOnlyOneProj'),
                dialogProjCreatedOpen : true,
                closeProjReason       : CONSTANTS.CS_ProjectionMode
            });
        }
        else {
            this.onPageChange("CSProjManagerForm");
        }
    };

    onGuidedExplorationBtnClick = () => {

        if (this.state.projectCreated) {
            this.setState({
                explanationText        : RS('GB_stCloseProjFirst'),
                dialogProjCreatedOpen  : true,
                closeProjReason        : CONSTANTS.CS_MissedOpportunitiesMode
            });
        }
        else {
            this.setState({
                appMode     : CONSTANTS.CS_MissedOpportunitiesMode
            });
            this.onPageChange("CSCountryListForm");
        }
    };

    onExploreLiSTDataBtnClick = () => {
        if (this.state.projectCreated) {
            this.setState({
                explanationText       : RS('GB_stCloseProjFirst'),
                dialogProjCreatedOpen : true,
                closeProjReason       : CONSTANTS.CS_ExploreLiSTDataMode
            });
        }
        else {
            this.setState({
                appMode     : CONSTANTS.CS_ExploreLiSTDataMode
            });
            this.onPageChange("CSCountryListForm");
        }
    };

    onExtractBtnClick = () => {
        if (this.state.projectCreated) {
            this.setState({
                explanationText       : RS('GB_stCloseProjFirst'),
                dialogProjCreatedOpen : true,
                closeProjReason       : CONSTANTS.CS_ExtractMode
            });
        }
        else {
            this.setState({
                appMode     : CONSTANTS.CS_ExtractMode
            });
            this.onPageChange("ExtractTool");
        }
    };

    onCreateProjection = (params) => {

        if (this.state.projectCreated) {
            this.setState({
                dialogCloseOpen : true,
            });
            return;
        } 

        this.onAddTasks([
            CreateAPITask('createProjectionFlag',
                {
                    CountryISO : params.CountryISO,
                    FirstYear  : params.FirstYear,
                    FinalYear  : params.FinalYear,
                    FileName   : params.FileName,
                    Notes      : params.Notes,
                    Author     : params.Author,
                    Org        : params.Org,
                    ModList    : params.ModList,
                    Region     : params.Region,
                    Survey     : params.Survey,
                    arr        : params.arr,
                    AgeGroupOption : params.AgeGroupOption,
                },
                () => {
                    this.setState({
                        //To set up interventions and MO correctly when creating a new projection after a projection was already open
                        displayChart             : true,
                        sortByDeathsAverted      : true,
                        sortByInterventionCost   : false,
                        viewByInterventionCost   : false,
                        displayMaternalCOD       : true,
                        displayStillbirthCOD     : true,
                        displayNeonatalCOD       : true,
                        displayChildCOD          : true,
                        displayCPR               : true,
                        maternalIntervSelected   : true,
                        stillbirthIntervSelected : true,
                        neonatalIntervSelected   : true,
                        childIntervSelected      : true,
                        selectAllIntervBool      : false,
                        newFile                  : true,
                        MOResultToDisplay        : CONSTANTS.CS_PotentialDeathsAverted,
                    });
                    this.onPageChange('CSManageInterventionsForm')
                },
                () => {
                    this.setState({
                        dialogErrorOpen  : true,
                        errorMessage : RS('GB_stErrorCreate')
                    });
                }
            )
        ]);
    };

    onCreateProjectionFromGuidedExploration = (params) => {

        if (this.state.projectCreated) {
            this.setState({
                dialogCloseOpen : true,
            });
            return;
        }

        this.onAddTasks([
            CreateAPITask('createProjectionFlag',
                {
                    CountryISO : params.CountryISO,
                    FirstYear  : params.FirstYear,
                    FinalYear  : params.FinalYear,
                    FileName   : params.FileName,
                    Notes      : params.Notes,
                    Author     : params.Author,
                    Org        : params.Org,
                    ModList    : params.ModList,
                    // Region     : params.Region,
                    // Survey     : params.Survey,
                    arr        : [],
                    AgeGroupOption : params.AgeGroupOption,
                },

                // (response) => {
                //     let updateCSModvars = response.CSModvars;

                () => {
                    this.setState({
                        dialogProjInfoOpen     : false,
                        intervDrawerOpen       : true,
                        showMOIntervSection    : true,
                        newFile                : true
                    });
                    this.onPageChange('CSManageInterventionsForm')
                },
                () => {
                    this.setState({
                        dialogErrorOpen  : true,
                        errorMessage : RS('GB_stErrorCreate')
                    });
                }
            )
        ]);
    };

    onModifyProjection = (params) => {

        let alreadyTaken = false;
        this.state.storedProjections.forEach(function(o) {
            if (o.name === params.FileTitle) {
                alreadyTaken = true;
            }
        });

        if (alreadyTaken && params.FileTitle !== this.state.projectionName) {
            this.setState({
                dialogFileAlreadyExistsOpen: true,
                prevProjName: params.FileTitle
            });
        } else {
            this.onAddTasks([
                CreateAPITask('modifyProjectionFlag',
                    {
                        Proj: params.Proj,
                        CountryISO: params.CountryISO,
                        FileTitle: params.FileTitle,
                        FinalYear: params.FinalYear,
                        FirstYear: params.FirstYear,
                        ModList: params.ModList,
                        SubnatStep2: params.SubnatStep2,
                        Author: params.Author,
                        Org: params.Org,
                        Notes: params.projectionNotes,
                        countryChange: (this.state.countryISO !== params.CountryISO)
                    },
                    () => {
                        this.onPageChange('CSManageInterventionsForm');
                    },
                    () => {
                        this.setState({
                            dialogErrorOpen: true,
                            errorMessage: RS('GB_stErrorModify')
                        });
                    }
                )
            ]);
        }
    };

    onOpenProjection = (projectName) => {

        if (projectName === this.state.projectionName) {
            // this.onPageChange("CSProjManagerForm");
            this.setState({dialogFileWithSameNameOpen: true});
        } 
        else if (this.state.projectCreated) {
            this.setState({
                dialogProjCreatedOpen : true,
                openingProj: projectName,
                //closeProjReason: CONSTANTS.CS_ProjectionMode
            });
        }
        else {
            this.openProj(projectName);
        }
    };

    openProj = (proj) => {
        this.onAddTasks([
            CreateAPITask('openProjectionFlag', {FileTitle : proj},
                () => {
                    this.onPageChange("CSProjManagerForm");
                },
                (msg) => {
                    alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                }
            )
        ]);
    };

    onCloseProjection = () => {
        let Proj = 1;
        let successFn = null;

        if (this.state.closeProjReason === CONSTANTS.CS_ProjectionMode) {
            if (this.state.openingProj.length > 0) {
                successFn = () => {
                    this.openProj(this.state.openingProj);
                    this.setState({
                        openingProj: ""
                    });
                }
            } else {
                successFn = () => {
                    this.onPageChange('CSProjManagerForm');
                }
            }
        }
        else if (this.state.closeProjReason === CONSTANTS.CS_CloseUnderProjectionName) {
            if (this.state.openingProj.length > 0) {
                successFn = () => {
                    this.openProj(this.state.openingProj);
                    this.setState({
                        openingProj: ""
                    });
                }
            } else {
                successFn = () => {
                    this.onPageChange('CSHomeForm');
                }
            }
        }
        else if (this.state.closeProjReason === CONSTANTS.CS_MissedOpportunitiesMode) {
            successFn = () => {
                this.onPageChange('CSCountryListForm');
            }
        }
        else if (this.state.closeProjReason === CONSTANTS.CS_ExploreLiSTDataMode) {
            successFn = () => {
                this.onPageChange('CSCountryListForm');
            }
        }
        else if (this.state.closeProjReason === CONSTANTS.CS_ExtractMode) {
            successFn = () => {
                this.onPageChange('ExtractTool');
            }
        }
        else if (this.state.closeProjReason === CONSTANTS.CS_DeletedFile) {
            successFn = () => {
                this.onPageChange('CSProjectionLibraryForm');
            }
        }
        else if (this.state.openingProj.length > 0) {
            successFn = () => {
                this.openProj(this.state.openingProj);
                this.setState({
                    openingProj: ""
                });
            }
        }
        else {
            successFn = () => {
                this.onLogout();
            }
        }

        //--------------------------------------------------------------------------------

        let pid = "CSHomeForm";
        let amod = CONSTANTS.CS_ProjectionMode;
        let side = true;
        if (this.state.closeProjReason === CONSTANTS.CS_MissedOpportunitiesMode) {
            pid = "CSCountryListForm";
            amod = CONSTANTS.CS_MissedOpportunitiesMode;
            side = false;
        }
        else if (this.state.closeProjReason === CONSTANTS.CS_ExploreLiSTDataMode) {
            pid = "CSCountryListForm";
            amod = CONSTANTS.CS_ExploreLiSTDataMode;
            side = false;
        }

        this.setState({
            closeProjReason : '',
            pageID                            : pid,
            appMode                           : amod,
            sidebarOpen                       : side,
            selectedProjSideBarIdx            : CONSTANTS.CS_ProjectionManagerIdx,
        }, () => {
            this.onAddTasks([
                CreateAPITask('closeProjectionFlag', {Proj : Proj},
                    () => {
                        /* Note 5/13/20 - Currently setting this here will not work due to derived state problems */
                        // this.setState({
                        //     pageID : pid,
                        // })
                        successFn();
                    },
                    (msg) => {
                        alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                    }
                )
            ]);    
        });

        //--------------------------------------------------------------------------------

    };

    onSaveProjection = (successFn) => {
        let proj = 1;
        let projName = '';

        (this.state.dialogSaveAsOpen) ?
            projName = this.state.projNameSaveAs :
            projName = this.state.projectionName;

        let alreadyTaken = false;
        this.state.storedProjections.forEach(function(o) {
            if (o.name === projName) {
                alreadyTaken = true;
            }
        });

        if (alreadyTaken && (this.state.dialogSaveAsOpen || this.state.newFile)) {
            this.setState({
                dialogFileAlreadyExistsOpen: true,
                prevProjName: projName
            });
        } else {
            this.onAddTasks([
                CreateAPITask('saveProjectionFlag', {Proj: proj, FileTitle: projName},
                    () => {
                        console.log('Saved projection!');

                        if (typeof successFn !== 'undefined') {
                            successFn();
                        }
                        this.setState({
                            newFile: false,
                            projectionName: this.state.prevProjName.length > 0 ? this.state.prevProjName : projName,
                            prevProjName: ""
                        }, () => this.onGetTopicList());
                    },
                    (msg) => {
                        console.log('Failed to save projection!');
                        alert(msg);
                    }
                )
            ]);
        }
    };

    onDeleteProjection = (projectName) => {
        //the check for an array exists for the library's multiselect option - SV
        this.onAddTasks([
            CreateAPITask('deleteProjectionFlag', { FileTitle : Array.isArray(projectName) ? projectName.pop() : projectName },
                () => {
                    if (Array.isArray(projectName) && projectName.length > 0) {
                        this.onDeleteProjection(projectName);
                    } else {
                        this.onPageChange('');
                    }
                    // if (projectName === this.state.projectionName) {
                    //     this.setState({
                    //         countryISO        : 4,
                    //         selectedCountry   : "Afghanistan",
                    //         yearRange         : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
                    //         projectionNotes   : "",
                    //         authorName        : SessionStorageUtil.getHumanName(),
                    //         orgName           : SessionStorageUtil.getOrganization(),
                    //         closeProjReason   : CONSTANTS.CS_DeletedFile
                    //     }, () => this.onCloseProjection());
                    // }
                },
                (msg) => {
                    alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                }
            )
        ]);
    };

    onDownloadProjection = (projectName) => {
        this.onAddTasks([
            CreateAPITask('downloadProjection', { FileTitle : projectName },
                () => {
                    this.onPageChange('');
                },
                (msg) => {
                    alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                }
            )
        ]);
    };

    onCopyProjection = (oldProj, newProj) => {
        let alreadyTaken = false;
        this.state.storedProjections.forEach(function(o) {
            if (o.name === newProj) {
                alreadyTaken = true;
            }
        });

        if (alreadyTaken) {
            alert(RS("GB_stAlreadyExists"));
        } 
        else {
            this.onAddTasks([
                CreateAPITask('copyProjectionFlag', {oldFileTitle: oldProj, newFileTitle: newProj},
                    () => {
                        this.onAddTasks([
                            CreateAPITask("getStoredProjectionList",
                                {},
                                (res) => {
                                    console.log(res);
                                    this.setState({dialogFileCopyOpen: false})
                                },
                                (msg) => {
                                    alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                                }
                            )
                        ]);
                    },
                    (msg) => {
                        alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                    }
                )
            ]);
        }
    };

    onRenameProjection = (oldProj, newProj) => {
        let alreadyTaken = false;
        this.state.storedProjections.forEach(function(o) {
            if (o.name === newProj) {
                alreadyTaken = true;
            }
        });

        if (alreadyTaken) {
            alert(RS("GB_stAlreadyExists"));
        } 
        else {
            this.onAddTasks([
                CreateAPITask('renameProjectionFlag', {oldFileTitle: oldProj, newFileTitle: newProj},
                    () => {
                        this.onAddTasks([
                            CreateAPITask("getStoredProjectionList",
                                {},
                                (res) => {
                                    console.log(res);
                                    this.setState({
                                        fileForActions: newProj,
                                        dialogFileRenameOpen: false
                                    })
                                },
                                (msg) => {
                                    alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                                }
                            )
                        ]);
                    },
                    (msg) => {
                        alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                    }
                )
            ]);
        }
    };

    onUploadProjection = (f) => {
        if (f.name.toLowerCase().includes(".pjnz")) {
            let alreadyTaken = false;
            this.state.storedProjections.forEach(function(o) {
                if (o.name === f.name.replace(".pjnz", "")) {
                    alreadyTaken = true;
                }
            });

            if (alreadyTaken) {
                alert(RS("GB_stAlreadyExists"));
            } 
            else {
                this.onAddTasks([
                    CreateAPITask('uploadProjectionFlag', {file: f},
                        (r) => {
                            this.onPageChange("CSProjectionLibraryForm");
                        },
                        (msg) => {
                            alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                        }
                    )
                ]);
            }
        } 
        else {
            alert("[" + RS("GB_stError").toUpperCase() + "] " + RS("GB_stUploadWrongFormat"));
        }
        //clear the file input to make sure any choice triggers the onChange, even if it's the same, such as a retry after an error
        document.querySelector("#hidden_uploader").value = "";
    };

    onLogout = () => {

        if (this.state.projectCreated) {
            this.setState({
                explanationText       : RS('GB_stBeforeYouLogOut'),
                dialogProjCreatedOpen : true,
                closeProjReason       : CONSTANTS.CS_LogoutMode
            });
        }
        else {
            this.onAddTasks([
                CreateAPITask('logout', {},
                    () => {
                        this.onPageChange("CSHomeForm");
                    },
                    (msg) => {
                        alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                    }
                )
            ]);
        }
    };

    onViewResults = (fromSideBar = true) => {
        if (fromSideBar) {
            this.setState({
                selectedProjSideBarIdx: CONSTANTS.CS_ViewResultsIdx
            });
        }

        let CSModvars = CSTemplate.CSModvars.map(x => ({ ...x, proj : 1 }));

        if (this.state.unchangedProj) {
            this.onPageChange('CSViewResultsForm');
        } 
        else {
            this.onAddTasks([
                CreateAPITask('calculateProjectionFlag', {Proj: 0, arr: CSModvars},
                    () => {
                        this.setState({unchangedProj: true}, () => this.onPageChange('CSViewResultsForm'));
                    },
                    (msg) => {
                        alert(RS('GB_stErrorCalculating'));
                    }
                )
            ]);
        }
    };

    onGetDataPackResults = (params, successFn) => {        
        this.onAddTasks([
            CreateAPITask('getDataPackResults', { indicators : params.indicators, chartYears : params.chartYears, language: params.language },
            // CreateAPITask('getDataPackResultsLOCAL', { ...params },
                (response) => {
                    successFn(response)
                },
                (msg) => {
                    alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                }
            )
        ]);
    };

    handleSelectedResults = (checked, category) => {
        //category check ternary operator allows certainty that it will register as correct boolean
        let maternal = category === CONSTANTS.CS_MstMaternalMortality ? checked : this.state.maternalIntervSelected;
        let stillbirth = category === CONSTANTS.CS_MstStillbirths ? checked : this.state.stillbirthIntervSelected;
        let neonatal = category === CONSTANTS.CS_MstNeonatalMortality ? checked : this.state.neonatalIntervSelected;
        let child = category === CONSTANTS.CS_MstChildMortality ? checked : this.state.childIntervSelected;
        let nutrition = category === CONSTANTS.CS_MstNutrition ? checked : this.state.nutritionSelected;
        let birth = category === CONSTANTS.CS_MstBirthOutcomes ? checked : this.state.birthOutcomeSelected;

        let selectedResults = [];
        if (nutrition && !birth) {
            selectedResults.push([CONSTANTS.CS_MstPercInVarHtForAgeStunt, CONSTANTS.GB_CS]);//*percent of children in different height for age statuses (stunting)
            selectedResults.push([CONSTANTS.CS_MstPercInVarWtForHtWaste, CONSTANTS.GB_CS]);//*percent of children in different weight for height statuses (wasting)
            selectedResults.push([CONSTANTS.CS_MstBirthTermResults, CONSTANTS.GB_CS]);//birth outcomes (percent)
            selectedResults.push([CONSTANTS.CS_MstLowBirthWeight, CONSTANTS.GB_CS]);//low birth weight
            selectedResults.push([CONSTANTS.CS_MstPercWomenAnemicResults, CONSTANTS.GB_CS]);//percent of women with anemia
            selectedResults.push([CONSTANTS.CS_MstNNDeathsPrByInter, CONSTANTS.GB_CS]);//*additional neonatal lives saved by intervention
            selectedResults.push([CONSTANTS.CS_MstChDeathsPrByInter, CONSTANTS.GB_CS]);//*additional child lives saved by intervention
            selectedResults.push([CONSTANTS.CS_MstMDeathsPrByInter, CONSTANTS.GB_CS]);//*additional maternal lives saved by intervention
            //X discounted present value of the lifetime earnings obtained by a birth cohort
        } else if (birth && !nutrition) {
            selectedResults.push([CONSTANTS.CS_MstBirthTermResults, CONSTANTS.GB_CS]);//*birth outcomes (percent)
            selectedResults.push([CONSTANTS.CS_MstLowBirthWeight, CONSTANTS.GB_CS]);//*low birth weight
            selectedResults.push([CONSTANTS.CS_MstGlobalStuntRate, CONSTANTS.GB_CS]);//global stunting rate
            selectedResults.push([CONSTANTS.CS_MstNNDeathsPrByInter, CONSTANTS.GB_CS]);//additional neonatal lives saved by intervention
            selectedResults.push([CONSTANTS.CS_MstChDeathsPrByInter, CONSTANTS.GB_CS]);//additional child lives saved by intervention
            selectedResults.push([CONSTANTS.CS_MstNNMortalityRate, CONSTANTS.GB_CS]);//neonatal mortality rate
        } else {
            if (maternal) {
                if (!stillbirth && !neonatal && !child) {
                    selectedResults.push([CONSTANTS.CS_MstMDeaths, CONSTANTS.GB_CS]);//Maternal deaths
                    selectedResults.push([CONSTANTS.CS_MstMDeathsByCause, CONSTANTS.GB_CS]);//Maternal deaths by cause
                }
                selectedResults.push([CONSTANTS.CS_MstMDeathsPrByInter, CONSTANTS.GB_CS]);//Additional maternal lives saved by intervention
                selectedResults.push([CONSTANTS.CS_MstMMortalityRatio, CONSTANTS.GB_CS]);//Maternal mortality ratio
                selectedResults.push([CONSTANTS.CS_MstMMortalityRate, CONSTANTS.GB_CS]);//Maternal mortality rate
            }
            if (stillbirth) {
                if (!maternal && !neonatal && !child) {
                    selectedResults.push([CONSTANTS.CS_MstSB, CONSTANTS.GB_CS]);//Stillbirths
                }
                selectedResults.push([CONSTANTS.CS_MstSBPrByInter, CONSTANTS.GB_CS]);//Stillbirths prevented by intervention
                selectedResults.push([CONSTANTS.CS_MstSBRate, CONSTANTS.GB_CS]);//Stillbirth rate
            }
            if (neonatal) {
                if (!maternal && !stillbirth && !child) {
                    selectedResults.push([CONSTANTS.CS_MstNNDeaths, CONSTANTS.GB_CS]);//Neonatal deaths
                    selectedResults.push([CONSTANTS.CS_MstNNDeathsByCause, CONSTANTS.GB_CS]);//Neonatal deaths by cause
                }
                selectedResults.push([CONSTANTS.CS_MstNNDeathsPrByInter, CONSTANTS.GB_CS]);//Additional neonatal lives saved by intervention
                selectedResults.push([CONSTANTS.CS_MstNNMortalityRate, CONSTANTS.GB_CS]);//Neonatal mortality rate
            }
            if (child) {
                if (!maternal && !stillbirth && !neonatal) {
                    selectedResults.push([CONSTANTS.CS_MstChildDeaths, CONSTANTS.GB_CS]);//Child deaths
                    selectedResults.push([CONSTANTS.CS_MstChDeathsByCause, CONSTANTS.GB_CS]);//Child deaths by cause
                }
                selectedResults.push([CONSTANTS.CS_MstChDeathsPrByInter, CONSTANTS.GB_CS]);//Additional child lives saved by intervention
                selectedResults.push([CONSTANTS.CS_MstU5MortalityRate, CONSTANTS.GB_CS]);//Under 5 mortality rate
            }
        }

        this.onStateChange({
            selectedResults: selectedResults
        })
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let clonedState = this.state;  // NO MORE CLONING!!!

        if (this.state.loading) {
            return (
                <div>
                    <TAppSplash />

                    <APIService
                        apiTasks                    = {this.state.apiTasks} // apiTasks contains functions, can't clone
                        appState                    = {clonedState}
                        onStateChange               = {this.onStateChange}
                        onAPIMainMsgChange          = {this.onAPIMainMsgChange}
                        onAPISubMsgChange           = {this.onAPISubMsgChange}
                    />
                </div>
            );
        }
        else if (this.state.userLoggedOff) {
            return (
                <TCSUserLoggedOff
                    onConnect                       = {() => { window.location.reload(); }}
                />
            );
        }
        // else if (this.state.disconnecting) {
        else if (!this.state.sessionExists) {
            console.log("sessionExists = false");
            return (
                <TDisconnected 
                    onConnect                       = {() => { window.location.reload(); }}
                />
            );
        }
        else if (!this.state.serverAlive) {
            console.log("serverAlive = false");
            return (
                <TDisconnected 
                    onConnect                       = {() => { window.location.reload(); }}
                />
            );
        }
        else {
            return (
                <div>
                    {
                        (this.state.developerMode) ?
                            <TestContainers 
                                CSModvars = {this.state.CSModvars}
                                onAddTasks = {this.onAddTasks}
                                apiTasks = {this.state.apiTasks}
                            />
                            :
                            null
                    }

                    <Main
                        // key                         = {this.state.language}
                        appState                    = {clonedState}
                        appFuncs                    = {this.appFuncs}
                        pageID                      = {clonedState.pageID}
                        isGuest                     = {clonedState.isGuest}
                        selectedResults             = {clonedState.selectedResults}
                        sidebarOpen                 = {clonedState.sidebarOpen}
                        theme                       = {Theme}
                        onStateChange               = {this.onStateChange}
                        onAddTasks                  = {this.onAddTasks}
                        onPageChange                = {this.onPageChange}
                        onHamburgerClick            = {this.onHamburgerClick}
                        onViewResults               = {this.onViewResults}
                        onSelectedResultsSaved      = {this.onSelectedResultsSaved}
                        onOpenProjection            = {this.onOpenProjection}
                        onDeleteProjection          = {this.onDeleteProjection}
                        onDownloadProjection        = {this.onDownloadProjection}
                        onSaveProjection            = {this.onSaveProjection}
                        onCloseProjection           = {this.onCloseProjection}
                        onCreateProjBtnClick        = {this.onCreateProjBtnClick}
                        onCreateProjection          = {this.onCreateProjection}
                        onCreateProjectionFromGuidedExploration = {this.onCreateProjectionFromGuidedExploration}
                        onModifyProjection          = {this.onModifyProjection}
                        onGuidedExplorationBtnClick = {this.onGuidedExplorationBtnClick}
                        onExploreLiSTDataBtnClick   = {this.onExploreLiSTDataBtnClick}
                        onExtractBtnClick           = {this.onExtractBtnClick}
                        onLogout                    = {this.onLogout}
                        apiTasks                    = {this.state.apiTasks}  // apiTasks contains functions, can't clone
                    />

                    <APIService
                        apiTasks                    = {this.state.apiTasks}  // apiTasks contains functions, can't clone
                        appState                    = {clonedState}
                        onStateChange               = {this.onStateChange}
                        onAPIMainMsgChange          = {this.onAPIMainMsgChange}
                        onAPISubMsgChange           = {this.onAPISubMsgChange}
                    />

                    <TCSStatusTimer 
                        onAddTasks                  = {this.onAddTasks}
                        onStateChange               = {this.onStateChange} 
                    />

                    {/* {
                        (this.state.apiTasks.filter(x => x.running).filter(x => (x.flag !== 'getStatus')).length > 0) ?
                            <TCSStatusTimer2 
                                onAddTasks                  = {this.onAddTasks}
                                onStateChange               = {this.onStateChange} 
                            /> : null
                    }  */}

                    <TCSDisconnectionWarning 
                        show                        = {this.state.disconnectWarning}
                        TimeExpire                  = {this.state.TimeExpire}
                        onAddTasks                  = {this.onAddTasks}
                        language                    = {this.state.language}
                    />
                </div>
            );
        }
    }
}

export default App;
