import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import TLabel from '@common/components/TLabel';
import RS from "@common/strings/RS";
 
class EditorNote extends React.Component {

    static propTypes = {
        numberOfTables : PropTypes.number,
        marginBottom   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    static defaultProps = {
        numberOfTables : 1,
        marginBottom   : '25px',
    };

    render() {
        const styles = {
            noteStyle : {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#cb6814',
                userSelect: 'none',
                display: 'inline'
            },
            labelStyle : {
                fontSize: '16px',
                color: '#000000',
                userSelect: 'none',
                display: 'inline',
                marginLeft: '10px'
            }
        };

        return (
            <div style={{marginBottom: this.props.marginBottom}}>
                <TLabel
                    caption = {RS('GB_stNote')}
                    style   = {styles.noteStyle}
                />
                <TLabel
                    caption = {(this.props.numberOfTables > 1) ? RS('GB_stClickOnTables') : RS('GB_stClickOnTable')}
                    style   = {styles.labelStyle}
                />
            </div>
        )
    }
}

export default EditorNote;