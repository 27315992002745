import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    generatePackChart, getIdxFromYear, getYearFromIdx, add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const MaternalAnemiaEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stAnemiaWRA');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_MaternalHealth;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let MaternalAnemiaMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_MaternalAnemia_MV);

        let value1 = pt.tableData.value[1][1];
        MaternalAnemiaMV["value"][CONSTANTS.CS_Preg_Anemia] = value1/100;

        let value2 = pt.tableData.value[2][1];
        let value3 = (value2/value1);
        MaternalAnemiaMV["value"][CONSTANTS.CS_Preg_IronDeficAnemia] = value3;

        value1 = pt.tableData.value[3][1];
        MaternalAnemiaMV["value"][CONSTANTS.CS_NonPreg_Anemia] = value1/100;

        value2 = pt.tableData.value[4][1];
        value3 = (value2/value1);
        MaternalAnemiaMV["value"][CONSTANTS.CS_NonPreg_IronDeficAnemia] = value3;

        MaternalAnemiaMV["source"][0][0] = pt.Source[0];

        changedModvars.push(MaternalAnemiaMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    let numCols = 2;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 5;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stPercPregAnemic');
    pt.tableData.value[2][0] = RS('GB_stPercPregAnemicIronDefic');
    pt.tableData.value[3][0] = RS('GB_stPercNonPregAnemic');
    pt.tableData.value[4][0] = RS('GB_stPercNonPregAnemicIronDefic');

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        for(let i = 1; i <= CONSTANTS.CS_MaxMaternalAnemia; i++) {
            pt.Alignments[i][col] = CONSTANTS.GB_Right;
            pt.RDec[i][col] = 2;
        }
        col++;
    }

    for(let i = 1; i <= CONSTANTS.CS_MaxMaternalAnemia; i++) {
        pt.Alignments[i][1] = CONSTANTS.GB_Right;
        pt.RDec[i][1] = 2;
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let MaternalAnemia = getModvarByTag(CSModvars, CONSTANTS.CS_TG_MaternalAnemia_MV);
    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            let pregAnemia = MaternalAnemia["exploreLiST"][CONSTANTS.CS_Preg_Anemia][t];
            let nonPregAnemia = MaternalAnemia["exploreLiST"][CONSTANTS.CS_NonPreg_Anemia][t];
            if(pregAnemia >= 0) {
                pt.tableData.value[1][col] = pregAnemia * 100;
                pt.tableData.value[2][col] = pregAnemia * MaternalAnemia["exploreLiST"][CONSTANTS.CS_Preg_IronDeficAnemia][t] * 100;
            }
            if(nonPregAnemia >= 0) {
                pt.tableData.value[3][col] = nonPregAnemia * 100;
                pt.tableData.value[4][col] = nonPregAnemia * MaternalAnemia["exploreLiST"][CONSTANTS.CS_NonPreg_IronDeficAnemia][t] * 100;
            }
            col++;
        }
    }
    else{
        let pregAnemia = MaternalAnemia["value"][CONSTANTS.CS_Preg_Anemia];
        let nonPregAnemia = MaternalAnemia["value"][CONSTANTS.CS_NonPreg_Anemia];
        pt.tableData.value[1][1] = pregAnemia * 100;
        pt.tableData.value[2][1] = pregAnemia * MaternalAnemia["value"][CONSTANTS.CS_Preg_IronDeficAnemia] * 100;
        pt.tableData.value[3][1] = nonPregAnemia * 100;
        pt.tableData.value[4][1] = nonPregAnemia * MaternalAnemia["value"][CONSTANTS.CS_NonPreg_IronDeficAnemia] * 100;
    }

    pt.Source[0] = MaternalAnemia["source"][0][0];

    return pt;
};

