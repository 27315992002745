import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    indentPackTable,
    addMergedCellsToArray,
    generatePackChart, addRowToPackTable, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    CSGetBFTypeStr,
    getCSGetDeathCause
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const ImpactsBFOnMortNNEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stAgeAppBFNN');
        let packTable = Setup(CSModvars);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ImpactUnderNutrition;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let ImpactBFOnMortNeoNat = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ImpactBFOnMortNeoNat_MV);

        let row = 2;
        pt.CauseOfDeathSet.forEach(function(d){
            row++;
            for (let bf = 1; bf <= CONSTANTS.CS_MaxBFType; bf++){
                let col = 1;
                for (let i = CONSTANTS.CS_EarlyInitBF; i <= CONSTANTS.CS_LateInitBF; i++){
                    ImpactBFOnMortNeoNat["value"][CONSTANTS.CS_Data][d][CONSTANTS.CS_RelRisk][i][bf] = pt.tableData.value[row][col];
                    col++;
                }
                ImpactBFOnMortNeoNat["source"][d][bf] = pt.Source[pt.SourceMap[row]];
                row++;
            }
        });

        changedModvars.push(ImpactBFOnMortNeoNat);

        return (changedModvars);
    },
};

const Setup = (CSModvars) => {
    const numCols = 3;
    const numRows = 2;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let i = CONSTANTS.CS_EarlyInitBF; i <= CONSTANTS.CS_LateInitBF; i++){
        switch (i){
            case CONSTANTS.CS_EarlyInitBF : {
                pt.tableData.value[0][col] = RS('GB_stEarlyInitBFLbl');
                break;
            }
            case CONSTANTS.CS_LateInitBF : {
                pt.tableData.value[0][col] = RS('GB_stLateInitBFLbl');
                break;
            }
            default : break;
        }
        pt.tableData.value[1][col] = RS('GB_stRelRisk');
        col++;
    }

    pt.CauseOfDeathSet = [
        CONSTANTS.CS_NNDiarr,
        CONSTANTS.CS_NNSepsis,
        CONSTANTS.CS_NNPneumonia
    ];

    pt.numSources = 0;

    let row = 2;
    pt.CauseOfDeathSet.forEach(function(d){
        pt = addRowToPackTable(pt);
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, 6);
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        pt.tableData.value[row][0] = getCSGetDeathCause(CSModvars, d);
        row++;
        for (let bf = 1; bf <= CONSTANTS.CS_MaxBFType; bf++){
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = CSGetBFTypeStr(bf);
            pt.numSources++;
            pt.SourceTitle[pt.numSources] = getCSGetDeathCause(CSModvars, d) + ' - ' + CSGetBFTypeStr(bf);
            pt.SourceMap[row] = pt.numSources;
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            let col = 1;
            for (let a = CONSTANTS.CS_1t6months; a <= CONSTANTS.CS_24t60months; a++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
                pt.MaxAllowedVal[row][col] = 1000;
                col++;
            }
            row++;
        }
    });

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let ImpactBFOnMortNeoNat = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactBFOnMortNeoNat_MV)["value"][
        CONSTANTS.CS_Data];

    let ImpactBFOnMortNeoNatSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactBFOnMortNeoNat_MV)["source"];

    let row = 2;
    pt.CauseOfDeathSet.forEach(function(d){
        row++;
        for (let bf = 1; bf <= CONSTANTS.CS_MaxBFType; bf++){
            let col = 1;
            for (let i = CONSTANTS.CS_EarlyInitBF; i <= CONSTANTS.CS_LateInitBF; i++){
                pt.tableData.value[row][col] = ImpactBFOnMortNeoNat[d][CONSTANTS.CS_RelRisk][i][bf];
                pt.Source[pt.SourceMap[row]] = ImpactBFOnMortNeoNatSrc[d][bf];
                col++;
            }
            row++;
        }
    });

    return pt;
};

