import React from 'react';
import PropTypes from 'prop-types';
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import AddButton from "../buttons/AddButton";

class NewTagDialog extends React.Component {

    static propTypes = {
        fileForActions    : PropTypes.string,
        onSaveTopic       : PropTypes.func,
        passNewTag        : PropTypes.func,
        onStateChange     : PropTypes.func,
    };

    static defaultProps = {
        fileForActions    : "",
        onSaveTopic       : () => console.log("onSaveTopic"),
        passNewTag        : () => console.log("passNewTag"),
        onStateChange     : () => console.log("onStateChange"),
    };

    state = {
        newTopic          : "",
        charLimit         : false
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogNewTagOpen: false
        });
    };

    onParseTopic = (value) => {
        if (value.length <= 15) {
            this.setState({
                newTopic: value,
                charLimit: false
            });
        } 
        else {
            this.setState({
                charLimit: true
            });
        }
    };

    onSaveBtnClick = () => {
        //this.props.onSaveTopic(this.state.newTopic, this.props.fileForActions);
        // this.props.passNewTag(this.state.newTopic);
        this.props.onStateChange({dialogNewTagOpen: false}, () => this.props.passNewTag(this.state.newTopic));
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderNewTopicInput = () => {
        //this random ID exists in order to confuse and prevent Google Chrome's autocomplete suggestions
        let ident = "";
        let choices ="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let rando = parseInt(Date.now().toString().substr(11),10);

        for (let i = 0; i < Math.ceil(Math.random() * rando); i++) {
            ident += choices.substr(Math.ceil(Math.random() * choices.length), 1);
        }

        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0 10px 0',
            },
            errorLabel : {
                color: "red", 
                margin: 0
            },
        };

        return (
            <div>
                <TEdit
                    name={ident}
                    caption={RS('GB_stTopic')}
                    value={this.state.newTopic}
                    style={styles.edit}
                    onChange={(val) => this.onParseTopic(val)}
                    foc={true}
                    shrinkLabel={true}
                />

                <p
                    style={{
                        color: "red",
                        lineHeight: "normal",
                        margin: ".5em",
                        visibility: this.state.charLimit ? "visible" : "hidden"
                    }}
                >
                    {RS("GB_stCharacterLimit") + ": 15"}
                </p>
            </div>
        );
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stEnterNewTopic')}
                onClose={this.onClose}
                minWidth={350}
                content={
                    <div>
                        {this.renderNewTopicInput()}
                    </div>
                }
                actions={
                    <div>
                        <AddButton onClick={this.onSaveBtnClick} disabled={this.state.newTopic.length === 0} />
                    </div>
                }
            />
        );
    }
}

export default NewTagDialog;