import React from 'react';
import PropTypes from "prop-types";
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CreateAPITask from "../../api/util/createAPITask";
import { SessionStorageUtil } from "../../api/localstorage/util";
import CSDialog from "./CSDialog";
import CreateAccountButton from "../buttons/CreateAccountButton";
import UpdateAccountButton from "../buttons/UpdateAccountButton";

const styles = {
    entryText : {
        // display:"block",
        width: "100%"
    }
};

class AccountUpdateDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isGuest        : PropTypes.bool,
        onStateChange  : PropTypes.func,
        onAddTasks     : PropTypes.func,
    };

    static defaultProps = {
        isGuest        : true,
        onStateChange  : () => console.log('onStateChange'),
        onAddTasks     : () => console.log('onAddTasks'),
    };

    state = {
        emailVal : "",
        passwordVal : "",
        confirmPasswordVal : "",
        firstNameVal : "",
        lastNameVal : "",
        locationVal : "",
        orgVal : "",
        newFirstNameVal: "",
        newMiddleNameVal: "",
        newLastNameVal: "",
        oldPasswordVal: "",
        newPasswordVal: "",
        newSalutationVal: "",
        newOrgVal: ""
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogAccountSetupOpen: false
        });
    };

    onCreateAccountBtnClick = () => {
        if (this.props.isGuest) {
            //
        } else {
            let params = {};
            let fullName = SessionStorageUtil.getHumanName().split(" ");
            if (this.state.newFirstNameVal.length > 0) {
                params.newFirstName = this.state.newFirstNameVal;
                fullName[0] = this.state.newFirstNameVal;
            }
            if (this.state.newMiddleNameVal.length > 0) {
                params.newMiddleName = this.state.newMiddleNameVal;
            }
            if (this.state.newLastNameVal.length > 0) {
                params.newLastName = this.state.newLastNameVal;
                fullName[1] = this.state.newLastNameVal;
            }
            if (this.state.oldPasswordVal.length > 0) {
                params.password = this.state.oldPasswordVal;
            }
            if (this.state.newPasswordVal.length > 0) {
                params.newPassword = this.state.newPasswordVal;
            }
            if (this.state.newOrgVal.length > 0) {
                params.organization = this.state.newOrgVal;
            }
            if (this.state.newSalutationVal.length > 0) {
                params.newSalutation = this.state.newSalutationVal;
            }
            fullName = fullName.join(" ");
            if (fullName !== SessionStorageUtil.getHumanName()) {
                params.author = fullName;
            }
            this.props.onAddTasks([
                CreateAPITask('setAccountInfoFlag',
                    params,
                    (res) => {
                        console.log(res);
                    },
                    (msg) => {
                        alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                    }
                )
            ]);
        }
        this.onClose();
    };

    onEmailType = (val) => {
        this.setState({emailVal: val});
    };

    onPasswordType = (val) => {
        this.setState({passwordVal: val});
    };

    onConfirmPasswordType = (val) => {
        this.setState({confirmPasswordVal: val});
    };

    onFirstNameType = (val) => {
        this.setState({firstNameVal: val});
    };

    onLastNameType = (val) => {
        this.setState({lastNameVal: val});
    };

    onLocationType = (val) => {
        this.setState({locationVal: val});
    };

    onOrgType = (val) => {
        this.setState({orgVal: val});
    };

    onNewSalutationType = (val) => {
        this.setState({newSalutationVal: val});
    };

    onNewFirstNameType = (val) => {
        this.setState({newFirstNameVal: val})
    };

    onNewMiddleNameType = (val) => {
        this.setState({newMiddleNameVal: val})
    };

    onNewLastNameType = (val) => {
        this.setState({newLastNameVal: val})
    };

    onOldPasswordType = (val) => {
        this.setState({oldPasswordVal: val})
    };

    onNewPasswordType = (val) => {
        this.setState({newPasswordVal: val})
    };

    onNewOrgType = (val) => {
        this.setState({newOrgVal: val})
    };

    refocus = (elem) => {
        document.querySelector(elem).focus();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderUpdateAccountContent = () => {
        return (
            <div>
                <p>{RS("GB_stUpdateAccountText")}</p>
                <TEdit
                    name="NewSalutationField"
                    caption={RS("GB_stNew").toUpperCase() + " " + RS("GB_stSalutation")}
                    value={this.state.newSalutationVal}
                    onChange={this.onNewSalutationType}
                    style={styles.entryText}
                    foc={true}
                    hitEnter={() => this.refocus("#NewFirstNameField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="NewFirstNameField"
                    caption={RS("GB_stNew").toUpperCase() + " " + RS("GB_stFirstName")}
                    value={this.state.newFirstNameVal}
                    onChange={this.onNewFirstNameType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#NewMiddleNameField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="NewMiddleNameField"
                    caption={RS("GB_stNew").toUpperCase() + " " + RS("GB_stMiddleName")}
                    value={this.state.newMiddleNameVal}
                    onChange={this.onNewMiddleNameType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#NewLastNameField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="NewLastNameField"
                    caption={RS("GB_stNew").toUpperCase() + " " + RS("GB_stLastName")}
                    value={this.state.newLastNameVal}
                    onChange={this.onNewLastNameType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#OldPasswordField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="OldPasswordField"
                    caption={RS("GB_stOld").toUpperCase() + " " + RS("GB_stPassword")}
                    type="password"
                    value={this.state.oldPasswordVal}
                    onChange={this.onOldPasswordType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#NewPasswordField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="NewPasswordField"
                    caption={RS("GB_stNew").toUpperCase() + " " + RS("GB_stPassword")}
                    type="password"
                    value={this.state.newPasswordVal}
                    onChange={this.onNewPasswordType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#NewOrgField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="NewOrgField"
                    caption={RS("GB_stNew").toUpperCase() + " " + RS("GB_stOrganization")}
                    value={this.state.newOrgVal}
                    onChange={this.onNewOrgType}
                    style={styles.entryText}
                    hitEnter={() => this.onCreateAccountBtnClick()}
                    shrinkLabel={true}
                />
            </div>
        )
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stAccountUpdate")}
                onClose={this.onClose}
                content={
                    <div>
                        {this.renderUpdateAccountContent()}
                    </div>
                }
                actions={
                    <div>
                        {
                            this.props.isGuest ? 
                                <CreateAccountButton onClick={this.onCreateAccountBtnClick} /> :
                                <UpdateAccountButton onClick={this.onCreateAccountBtnClick} />
                        }
                    </div>
                }
            />
        );
    }

}

export default AccountUpdateDialog;