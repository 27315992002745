import React from 'react';
import PropTypes from "prop-types";
import GBStdChart from '../../../../../../components/GB/GBStdChart';
import {getPathogenPackChart} from "../../../../../../data/CSUtil";

class PathogenTabbedChart extends React.Component {
    static propTypes = {
        dat            : PropTypes.any,
        age            : PropTypes.number,
        id             : PropTypes.string,
        options        : PropTypes.any,
        chartType      : PropTypes.any,
        stacked        : PropTypes.bool,
        legendReversed : PropTypes.bool,
        chartTitle     : PropTypes.string,
        tableRef       : PropTypes.any,
        choice         : PropTypes.string
    };

    static defaultProps = {
        dat            : [],
        age            : -1,
        id             : "TabbedChart",
        options        : [],
        chartType      : "",
        stacked        : false,
        legendReversed : false,
        chartTitle     : "",
        tableRef       : null,
        choice         : "PathogenAll"
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderPathogenTabbedChart = () => {
        let packChart = getPathogenPackChart(this.props.dat, this.props.age, this.props.choice);
        let ages = ["1-5", "6-11", "12-23", "24-59"];
        packChart.title = this.props.chartTitle.replace(")", ", " + ages[this.props.age] + " months)");

        let chart =
            <GBStdChart
                id={"CDChart" + this.props.id + this.props.age}
                packChart={packChart}
                minHeight={325}
                maxHeight={325}
                options={this.props.options}
                chartType={this.props.chartType}
                stacked={false}
                legendReversed={this.props.legendReversed}
                key={"CDChart" + this.props.id + this.props.age}
                ref={this.props.tableRef}
            />;
        return (chart)
    };

    render() {
        return (
            this.renderPathogenTabbedChart()
        );
    }
}

export default PathogenTabbedChart;