import * as React from 'react';
import PropTypes from 'prop-types';

class TTimer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        interval        : PropTypes.number,
        failLimit       : PropTypes.number,
        waitForResponse : PropTypes.bool,
        onTimer         : PropTypes.func, 
        onSuccess       : PropTypes.func,
        onError         : PropTypes.func,
    };

    static defaultProps = {
        interval        : 5000,
        failLimit       : 3,
        waitForResponse : true,
        onTimer         : () => { console.log('TTimer: onTimer'); },
        onSuccess       : () => { console.log('TTimer: onSuccess'); },
        onError         : () => { console.log('TTimer: onError'); },
    };

    state = {      
        isOn            : false,
        startTime       : 0,
        timeElapsed     : 0,
        failedCount     : 0,
        sent            : false
    };

    _timer = undefined;
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.startTimer();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        this.stopTimer();
    }

    //==================================================================================================================
    //
    //                                                 Actions
    //
    //==================================================================================================================

    startTimer() {
        this.setState({
            isOn        : true,
            startTime   : Date.now() - this.state.time,
            timeElapsed : this.state.time
        });

        this._timer = setInterval(this.runAction, this.props.interval);
    }
    
    stopTimer() {
        this.setState({
            isOn: false
        });

        clearInterval(this._timer);
    }
    
    resetTimer() {
        this.setState({
            isOn: false,
            time: 0 
        });
    }

    runAction = () => {

        this.setState({
            time : Date.now() - this.state.start
        });

        const successFn = (response) => {
            if (this._isMounted) {
                this.setState({
                    failedCount : 0,
                    sent        : false
                });
            }

            this.props.onSuccess(response);
        };

        const errorFn = () => {

            let failedCount = this.state.failedCount + 1;

            if (failedCount >= this.props.failLimit) {
                this.stopTimer();
                console.error('Error: TTimer failed passed the allowed failedCount! Stopping the timer!');
                this.props.onError();
            }

            if (this._isMounted) {
                this.setState((prevState, props) => ({
                    failedCount : prevState.failedCount + 1,
                    sent        : false
                }));
            }
        };

        if (this.props.waitForResponse) {
            if (!this.state.sent) {
                this.setState({
                    sent : true
                });
    
                this.props.onTimer({}, successFn, errorFn);
            }
        }
        else {
            this.setState({
                sent : true
            });

            this.props.onTimer({}, successFn, errorFn);
        }
    }
    
    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {

        return null;

        // let styles = {
        //     div : {
        //         position: 'absolute',
        //         top: 0,
        //         bottom: 0,
        //         left: 0,
        //         right: 0,
        //         background: '#FF0000',
        //         color: 'black'
        //     },
        //     // https://stackoverflow.com/questions/28455100/how-to-center-div-vertically-inside-of-absolutely-positioned-parent-div
        //     AbsoluteCenter : {
        //         margin: 'auto',
        //         textAlign: 'center',
        //         position: 'absolute',
        //         top: 0, 
        //         left: 0, 
        //         bottom: 0, 
        //         right: 0,
        //         height: 1,
        //         zIndex: '2000',
        //     },
        //     parentDisable : {
        //         position: 'fixed',
        //         top: 0, 
        //         left: 0, 
        //         bottom: 0, 
        //         right: 0,
        //         background: '#000',
        //         opacity: '0.8',
        //         zIndex: '2001',
        //         height: '100%',
        //         width: '100%',
        //     }
        // };

        // if (this.state.failedCount >= this.props.failLimit) {
        //     return (
        //         <div>
        //             <div style={styles.parentDisable}>
        //                 <div style={styles.AbsoluteCenter}>
        //                     Failed
        //                 </div>
        //             </div> 
        //         </div>
        //     );  
        // }
        // else {
        //     return null;
        // }
    }

}

export default TTimer;