import * as React from "react";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

class TButton extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption        : PropTypes.string,
        captionStyles  : PropTypes.object,
        variant        : PropTypes.string,
        color          : PropTypes.string,
        width          : PropTypes.string,
        size           : PropTypes.string,
        disabled       : PropTypes.bool,
        pictureLeft    : PropTypes.any,
        pictureRight   : PropTypes.any,
        transparent    : PropTypes.bool,
        shape          : PropTypes.string,
        onClick        : PropTypes.func,
        style          : PropTypes.any,
        containerStyle : PropTypes.any
    };

    static defaultProps = {
        caption        : 'Caption',
        captionStyles  : {},
        variant        : 'contained',
        color          : 'primary',
        width          : null,
        size           : 'medium',
        disabled       : false,
        pictureLeft    : null,
        pictureRight   : null,
        onClick        : () => console.log('TButton onClick'),
        transparent    : false,
        shape          : 'rectangle',
        style          : {},
        containerStyle : {display: 'inline-block'}
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onClick = (event) => {
        this.props.onClick(event);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderCaption = () => {
        if (this.props.caption === '') {
            return null;
        }
        else {
            return (
                <div style={{...this.props.captionStyles}}>
                {/*<div style={{fontWeight: 'normal', ...this.props.captionStyles}}>*/}
                    {this.props.caption}
                </div>
            );
        }
    };

    render() {

        let styles = {
            margin: "3px",
            textTransform : 'none',
            width: this.props.width, ...this.props.style,
        };

        if (this.props.transparent) {
            styles.backgroundColor = 'inherit';
            styles.boxShadow = 'inherit';
        }

        if (this.props.shape === 'circle') {
            styles.borderRadius = '50%';
            styles.minWidth = '40px';
            styles.minHeight = '40px';
            styles.padding = '0';
        }

        let styles2 = {
            //height: 36
        };

        if (this.props.variant !== "fab") {
            styles2.height = 36;
            styles2.paddingTop = 4;
            styles2.fontSize = 16;
        }

        if (this.props.disabled) {
            styles2.color = '#a9a9a9';
            styles2.textDecoration = 'none';
        }

        if (this.props.variant === "fab") {
            return (
                <div style={{...this.props.containerStyle}}>
                    <Fab
                        // variant={this.props.variant}
                        color={this.props.color}
                        disabled={this.props.disabled}
                        size={this.props.size}
                        onClick={this.onClick}
                        style={{...styles, ...this.props.style, ...styles2}}
                    >
                        {this.props.pictureLeft}
                        {this.renderCaption()}
                        {this.props.pictureRight}
                    </Fab>
                </div>
            );
        } else {

            return (
                <div style={{...this.props.containerStyle}}>
                    <Button
                        variant={this.props.variant}
                        color={this.props.color}
                        disabled={this.props.disabled}
                        size={this.props.size}
                        onClick={this.onClick}
                        style={{...styles, ...this.props.style, ...styles2}}
                    >
                        {this.props.pictureLeft}
                        {this.renderCaption()}
                        {this.props.pictureRight}
                    </Button>
                </div>
            );
        }
    }
}

export default TButton;