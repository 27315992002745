import React from 'react';
import PropTypes from "prop-types";

class CountryFlags extends React.PureComponent {

    static propTypes = {
        selectedCountry : PropTypes.string,
        style           : PropTypes.object,
    };

    static defaultProps = {
        selectedCountry : "Afghanistan",
        style           : {}
    };

    render() {
        let flag = 'flags/' + this.props.selectedCountry + '.svg';
        return (
            <img style={this.props.style} src={flag} alt={""}/>
        );
    }

}

export default CountryFlags;