import * as React from 'react';
import PropTypes from 'prop-types';
import TTimer from '@common/components/TTimer';
import CreateAPITask from "../../api/util/createAPITask";

class TStatusTimer extends React.Component {

    static propTypes = {
        onAddTasks    : PropTypes.func,
        onStateChange : PropTypes.func,
    };

    static defaultProps = {
        onAddTasks    : () => { console.log('TStatusTimer: onAddTasks'); },
        onStateChange : () => { console.log('TStatusTimer: onStateChange'); },
    };
    
    render() {
        return (
            <TTimer 
                interval       = {60000}
                onTimer        = {
                                    (params, successFn, errorFn) => {
                                        this.props.onAddTasks([
                                            CreateAPITask('getStatus', params, successFn, errorFn)
                                        ]);
                                    }
                                }
                onSuccess      = {
                                    () => {}
                               }
                onError        = {
                                    () => {
                                        this.props.onStateChange({
                                            serverAlive : false
                                        });
                                    }
                                }
            />
        );
    }

}

export default TStatusTimer;