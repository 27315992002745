import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    addRowToPackTable, generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    CSGetBirthTermAbbrev,
    CSGetBirthInterval
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const BirthIntervalsEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stBirthIntervals');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let BirthIntervals = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_BirthIntervals_MV2);

        let col = 1;
        for (let term = CONSTANTS.CS_PreTermSGA; term <= CONSTANTS.CS_TermSGA; term++){
            let row = 1;
            for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GE24Mths; i++) {
                row++;
                BirthIntervals["value"][CONSTANTS.CS_Data][CONSTANTS.CS_RelRisk][i][term] = pt.tableData.value[row][col];
                BirthIntervals["source"][0][i] = pt.Source[pt.SourceMap[row]];
            }
            col++;
        }
        changedModvars.push(BirthIntervals);

        return (changedModvars);
    },
};

const Setup = () => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    const numCols = 4;
    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let i = CONSTANTS.CS_PreTermSGA; i <= CONSTANTS.CS_TermSGA; i++){
        pt.tableData.value[0][col] = CSGetBirthTermAbbrev(i);
        pt.tableData.value[1][col] = RS('GB_stRelRisk');
        col++;
    }

    pt.numSources = 0;

    let row = 1;
    for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GE24Mths; i++){
        row++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = CSGetBirthInterval(i);
        pt.numSources++;
        pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
        pt.SourceMap[row] = pt.numSources;
    }

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 3;
            }
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let BirthIntervals = getModvarByTag(CSModvars, CONSTANTS.CS_TG_BirthIntervals_MV2)["value"][
        CONSTANTS.CS_Data][CONSTANTS.CS_RelRisk];
    let BirthIntervalsSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_BirthIntervals_MV2)["source"];
    let col = 1;
    for (let term = CONSTANTS.CS_PreTermSGA; term <= CONSTANTS.CS_TermSGA; term++){
        let row = 1;
        for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GE24Mths; i++) {
            row++;
            pt.tableData.value[row][col] = BirthIntervals[i][term];
            pt.Source[pt.SourceMap[row]] = BirthIntervalsSrc[0][i];
        }
        col++;
    }
    return pt;
};

