import RS from "@common/strings/RS";
import { RESTService2, LoginService2, ModUtilService2, SpectrumService2 } from "./server_calls";
import CSTemplate from '../data/CSTemplate';
import CONSTANTS from "utilities/CS/CSConst";
import { SessionStorageUtil } from "./localstorage/util";

export const RESTService3 = {

    ping : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stPingFlag"));

        RESTService2.ping(params, (response) => {
            params.onStateChange({
                serverAlive : response
            }, () => successFn(response));
        }, (msg) => {
            params.onStateChange({
                serverAlive : false
            }, () => errorFn(msg));
        });
    },

    getCountries : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stGetCountriesFlag"));

        RESTService2.getCountries(params, (response) => {
            params.onStateChange({
                countries : response
            }, () => successFn(response));
        }, errorFn);
    },

    getIntervGroupList : (params, successFn, errorFn) => {
        params.statusFn('');//RS("GB_stGetCountriesFlag"));

        RESTService2.getIntervGroupList(params, (response) => {
            params.onStateChange({
                IVGroupInfo : response
            }, () => successFn(response));
        }, errorFn);
    },

    getIntervSubGroupList : (params, successFn, errorFn) => {
        params.statusFn('');//RS("GB_stGetCountriesFlag"));

        RESTService2.getIntervSubGroupList(params, (response) => {
            params.onStateChange({
                IVSubGroupInfo : response
            }, () => successFn(response));
        }, errorFn);
    },

    getIntervList : (params, successFn, errorFn) => {
        params.statusFn('');//RS("GB_stGetCountriesFlag"));

        RESTService2.getIntervList(params, (response) => {
            params.onStateChange({
                IVInfo : response
            }, () => successFn(response));
        }, errorFn);
    },

    getVersion : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stGetVersionFlag"));

        RESTService2.getVersion(params, (response) => {
            params.onStateChange({
                version : response
            }, () => successFn(response));
        }, (msg) => {
            params.onStateChange({
                version : "unknown"
            }, () => errorFn(msg));
        });
    },

    getFileShareStatus : (params, successFn, errorFn) => {
        params.statusFn('');

        RESTService2.getFileShareStatus(params, (response) => {
            params.onStateChange({
                // version : response
            }, () => successFn(response));
        }, (msg) => {
            params.onStateChange({
                // version : "unknown"
            }, () => errorFn(msg));
        });
    },

    writeFileShareLog : (params, successFn, errorFn) => {
        params.statusFn('');

        RESTService2.writeFileShareLog(params, (response) => {
            params.onStateChange({
                // version : response
            }, () => successFn(response));
        }, (msg) => {
            params.onStateChange({
                // version : "unknown"
            }, () => errorFn(msg));
        });
    },
    
};
    
export const LoginService3 = {

    getStatus : (params, successFn, errorFn) => {
        // Note: We do not want to set the status message here as this call is set on a timer to run automatically.
        // params.statusFn(RS("GB_stGetStatusFlag"));

        LoginService2.getStatus(params, (response) => {

            if (response.sessionExists === false) {
                SessionStorageUtil.deleteHumanName();
                SessionStorageUtil.deleteOrganization();
                SessionStorageUtil.deleteClientID();
                SessionStorageUtil.deleteContainerBaseURL();
                SessionStorageUtil.deleteLinkToken();
            }

            params.onStateChange({
                sessionExists     : response.sessionExists,
                disconnectWarning : response.disconnectWarning,
                TimeToLive        : response.TimeToLive,
                TimeIdle          : response.TimeIdle,
                TimeExpire        : response.TimeExpire,
                TimeTotal         : response.status.time,
                disconnecting     : response.disconnecting,
                status            : response.status,
            }, () => successFn(response));
        }, errorFn);
    }, 

    signedIn : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stSignedInFlag"));

        LoginService2.signedIn(params, (response) => {
            if (response === true) {
                params.onStateChange({
                    signedIn  : true
                }, () => successFn(response));
            }
            else {
                params.onStateChange({
                    humanName : '',
                    signedIn  : false,
                    isGuest   : false,
                }, () => successFn(response));
            }
        }, errorFn);
    },

    login : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stLogInFlag"));

        LoginService2.login(params, (response) => {
            if (response === true) {
                params.onStateChange({
                    humanName     : params.username,
                    signedIn      : true,
                    isGuest       : false,
                    sessionExists : true,
                }, () => successFn(response));
            }
            else {
                successFn(response);
            }
        }, errorFn);
    },

    loginAsGuest : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stLogInAsGuestFlag"));

        LoginService2.loginAsGuest(params, (response) => {
            if (response === true) {
                params.onStateChange({
                    signedIn      : true,
                    humanName     : 'Guest',
                    isGuest       : true,
                    sessionExists : true
                }, () => successFn(response));
            }
            else {
                successFn(response);
            }
        }, errorFn);
    },

    logout : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stLogOutFlag"));

        LoginService2.logout(params, (response) => {
            if (response === true) {

                SessionStorageUtil.deleteHumanName();
                SessionStorageUtil.deleteOrganization();
                SessionStorageUtil.deleteClientID();
                SessionStorageUtil.deleteContainerBaseURL();
                SessionStorageUtil.deleteLinkToken();

                params.onStateChange({
                    humanName     : '',
                    signedIn      : false,
                    isGuest       : false,
                    sessionExists : false,
                    userLoggedOff : true,
                    // pageID        : 'CSHomeForm',
                }, () => successFn(response));
            }
            else {
                console.log(RS('GB_stUnableToLogOut'));
                successFn(response);
            }
        }, errorFn);
    },

    getAccountInfo : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stGetAccountInfoFlag"));

        LoginService2.getAccountInfo(params, (response) => {

            if (response.fAuthor !== "Guest") {
                SessionStorageUtil.setHumanName(response.fAuthor);
                SessionStorageUtil.setOrganization(response.fOrganization);
            }
            else {
                SessionStorageUtil.deleteHumanName();
                SessionStorageUtil.deleteOrganization();
            }

            params.onStateChange({
                signedIn  : response.fSignedIn,
                userName  : response.fUserName,
                humanName : response.fHumanName,
                isGuest   : response.fIsGuest,
            }, () => successFn(response));
        }, errorFn);
    },

};
    
export const ModUtilService3 = {
        
    getMOCountryData : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stRetrieveMODataFlag"));

        ModUtilService2.getMOCountryData(params, (response) => {
            params.onStateChange({
                MOCountryData : response,
            }, () => successFn(response));
        }, errorFn);
    },

};
    
export const SpectrumService3 = {

    doRun : (params, successFn, errorFn) => {
        params.statusFn("Doing run");

        SpectrumService2.doRun(params, (response) => {
            successFn(response);
        }, errorFn);
    },
    
    keepAlive : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stKeepAliveFlag"));

        SpectrumService2.keepAlive(params, (response) => {
            successFn(response);
        }, errorFn);
    }, 

    getActiveProjectionList : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stGetActiveProjectionListFlag"));

        SpectrumService2.getActiveProjectionList(params, (response) => {

            let projYears = (response.length > 0) ? response[0].years.split("-") : [(new Date()).getFullYear(), CONSTANTS.CS_DefaultFinalYear];
            let yearRange = {min: parseInt(projYears[0], 10), max: parseInt(projYears[1], 10)};

            let selectedCountry = {};
            if (response.length > 0) {
                selectedCountry = {
                    selectedCountry : response[0].country
                }
            };

            params.onStateChange({
                activeProjections : response,
                projectCreated    : response.length > 0,
                projectionName    : (response.length > 0) ? response[0].name : '',  
                subnatRegion      : (response.length > 0) ? response[0].subnatRegion : '',
                subnatSurvey      : (response.length > 0) ? response[0].subnatSurvey : '',
                yearRange         : yearRange,
                authorName        : (response.length > 0) ? response[0].author : "",
                orgName           : (response.length > 0) ? response[0].organization : "",
                projectionNotes   : (response.length > 0) ? response[0].authorNotes : "",
                subnatProjection  : (response.length > 0) ? response[0].subnatWizProj : false,
                firstYear         : (response.length > 0) ? parseInt(projYears[0], 10) : CONSTANTS.CS_DefaultFinalYear,
                finalYear         : (response.length > 0) ? parseInt(projYears[1], 10) : (CONSTANTS.CS_DefaultFinalYear - (new Date()).getFullYear() - 4),
                // selectedCountry   : (response.length > 0) ? response[0].country : '',
                ...selectedCountry
            }, () => successFn(response));
        }, errorFn);
    }, 

    getStoredProjectionList : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stGetStoredProjectionListFlag"));

        SpectrumService2.getStoredProjectionList(params, (response) => {
            params.onStateChange({
                storedProjections : response
            }, () => successFn(response));
        }, errorFn);
    },

    //----------------------------------------------------------------

    // getGBData : (params, successFn, errorFn) => {
    //     params.statusFn('getGBData');

    //     SpectrumService2.getGBData(params, (response) => {
    //         params.onStateChange({
    //             GB         : response,
    //             countryISO : response.CountryRegionCode[1],   // || 4
    //         }, () => successFn(response));
    //     }, errorFn);
    // },

    getModvars : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stGetModvarsFlag"));

        let CSModvars;

        if (typeof params.arr === 'undefined') {
            CSModvars = CSTemplate.CSModvars.map(x => ({ ...x, proj : 1 }));
        }
        else {
            CSModvars = params.arr;
        }

        SpectrumService2.getModvars({...params, arr : CSModvars}, (response) => {
            
            for (let i = 0; i < response.length; i++) {

                let modvar = response[i];
                let tag = modvar.tag;
                let pos1 = CSModvars.map(x => x.tag).indexOf(tag);

                if (pos1 !== -1) {
                    CSModvars[pos1] = modvar;
                }
            }

            params.onStateChange({
                CSModvars : response,
            }, () => successFn(response));
        }, errorFn);
    },

    setModvars : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stSetModvarsFlag"));

        SpectrumService2.setModvars(params, (response) => {
            successFn(response);
        }, errorFn);
    },

    getInputModVars : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stRetrieveInputModvarsFlag"));

        SpectrumService2.getInputModVars(params, (response) => {
            params.onStateChange({
                inputModvars  : response
            }, () => successFn(response));
        }, errorFn);
    },

    //----------------------------------------------------------------

    createProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stCreateProjectionFlag"));

        SpectrumService2.createProjection(params, (response) => {
            params.onStateChange({
                projectCreated         : true,
                yearRange              : { min: params.FirstYear, max : params.FinalYear },
                projectionName         : params.FileName,
                //selectedCountry        : params.selectedCountry,
                countryISO             : params.CountryISO,
                subnatProjection       : params.subnatProjection,
                firstYear              : params.FirstYear,
                finalYear              : params.FinalYear,
                chartYears             : { 
                                           firstYrIdx: CONSTANTS.CS_DefaultFirstYearIdx,
                                           finalYrIdx: params.FinalYear - params.FirstYear + CONSTANTS.CS_DefaultFirstYearIdx
                                         },
                authorName             : params.Author,
                orgName                : params.Org,
                projectionNotes        : params.projectionNotes,
                subnatRegion           : params.Region,
                subnatSurvey           : params.Survey,
                AgeGroupOption         : params.AgeGroupOption,
                // selectedProjSideBarIdx : CONSTANTS.CS_ManageInterventionIdx,
            }, () => successFn(response));
        }, errorFn);
    },

    modifyProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stApplyChangesFlag"));

        SpectrumService2.modifyProjection(params, (response) => {
            params.onStateChange({
                yearRange              : { min: params.FirstYear, max : params.FinalYear },
                projectionName         : params.FileTitle,
                selectedCountry        : params.selectedCountry,
                countryISO             : params.CountryISO,
                subnatProjection       : params.subnatProjection,
                // firstYear              : params.FirstYear,
                finalYear              : params.FinalYear,
                chartYears             : { 
                                            firstYrIdx: CONSTANTS.CS_DefaultFirstYearIdx,
                                            finalYrIdx: params.FinalYear - params.FirstYear + CONSTANTS.CS_DefaultFirstYearIdx
                                        },
                authorName             : params.Author,
                orgName                : params.Org,
                projectionNotes        : params.projectionNotes,
                // subnatRegion           : params.Region,
                // subnatSurvey           : params.Survey,
                // selectedProjSideBarIdx : CONSTANTS.CS_ManageInterventionIdx,
            }, () => successFn(response));
        }, errorFn);
    },

    openProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stOpenProjectionFlag"));

        SpectrumService2.openProjection(params, (response) => {
            params.onStateChange({
                projectCreated         : true,
                showMOIntervSection    : false,
                selectedProjSideBarIdx : CONSTANTS.CS_ProjectionManagerIdx,
            }, () => successFn(response));
        }, errorFn);
    },

    saveProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stSaveProjectionFlag"));

        params.onStateChange({
            projNameSaveAs   : '',
        }, () => {
            SpectrumService2.saveProjection(params, (response) => {
                params.onStateChange({
                    projectionName : params.FileTitle
                }, () => successFn(response));

            }, (msg) => {
                errorFn(msg);
            });
        });
    }, 

    closeProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stCloseProjectionFlag"));

        params.onStateChange({
            closeProjReason                   : '',
        }, () => {
            SpectrumService2.closeProjection(params, (response) => {
                params.onStateChange({
                    projectCreated         : false,
                    projectionName         : params.subnatStep1 ? params.FileName : '',
                    subnatRegion           : '',
                    subnatSurvey           : '',
                    projectionNotes        : '',
                    CSModvars              : [],
                    subnatProjection       : false,
                    unchangedProj          : false,
                }, () => successFn(response));
            }, errorFn);
        });
    },

    downloadProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stDownloadProjectionFlag"));

        SpectrumService2.downloadProjection(params, (response) => {

            let url = response.API_URL_BASE + '/' + response.response;
            let a = document.createElement("a");
            a.href = url;
            let fileName = url.split("/").pop();
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();

            successFn(response);
        }, errorFn);
    }, 

    deleteProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stDeleteProjectionFlag"));

        SpectrumService2.deleteProjection(params, (response) => {
            successFn(response);
        }, errorFn);
    },

    calculateProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stCalculateProjectionFlag"));

        SpectrumService2.calculateProjection(params, (response) => {
            successFn(response);
        }, errorFn);
    }, 

    copyProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stCopyProjectionFlag"));

        SpectrumService2.copyProjection(params, (response) => {
            successFn(response);
        }, errorFn);
    },    
    
    renameProjection : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stRenameProjectionFlag"));

        SpectrumService2.renameProjection(params, (response) => {
            successFn(response);
        }, errorFn);
    },

    //----------------------------------------------------------------

    getResultMenus : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stGetResultMenusFlag"));

        SpectrumService2.getResultMenus(params, (response) => {
            params.onStateChange({
                resultMenus : response,
                DPTree      : response.TreeDP,
                AMTree      : response.TreeAM,
                CSTree      : response.TreeCS,
                FPTree      : response.TreeFP,
            }, () => successFn(response));
        }, errorFn);
    },

    setAccountInfo : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stSetAccountInfoFlag"));

        SpectrumService2.setAccountInfo(params, (response) => {
            params.onStateChange({
                // this exists only to update the state and thus close the API dialog
            }, () => successFn(response));
        }, errorFn);
    },

    getTagList : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stRetrieveTagListFlag"));

        SpectrumService2.getTagList(params, (response) => {
            params.onStateChange({
                topicList : response.includes("") ? response.splice(1) : response
            }, () => successFn(response));
        }, errorFn);
    },

    SetProjectionTags : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stSendingTagListFlag"));

        SpectrumService2.SetProjectionTags(params, successFn, errorFn);
    },

    addSubnatData : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stAddSubnatDataFlag"));

        SpectrumService2.addSubnatData(params, successFn, errorFn);
    },

    setUserOptions : (params, successFn, errorFn) => {
        params.statusFn(RS("GB_stAddSubnatDataFlag"));

        SpectrumService2.setUserOptions(params, successFn, errorFn);
    },

    upload : (params, successFn, errorFn) => {
        params.statusFn('Uploading...');

        SpectrumService2.upload(params, (response) => {
            params.onStateChange({
                // ???
            }, () => successFn(response));
        }, errorFn);
    },








    
    // Unused
    getResultsData : (params, successFn, errorFn) => {
        params.statusFn('getResultsData');

        SpectrumService2.getResultsData(params, (response) => {
            params.onStateChange({
                // ???
            }, () => successFn(response));
        }, errorFn);
    },

    getResultTable : (params, successFn, errorFn) => {
        params.statusFn('getResultTable');
        
        SpectrumService2.getResultTable(params, (response) => {
            params.onStateChange({
                // ???
            }, () => successFn(response));
        }, errorFn);
    }, 

    getDataPackResults : (params, successFn, errorFn) => {
        params.statusFn('getDataPackResults');
        SpectrumService2.getDataPackResults(params, (response) => {

            // TEMPORARY FOR JARED REFRACTOR CHANGES BEFORE DATAPACKSERVER UPDATES
            if (response.results.length > 0) {
                if (Array.isArray(response.results[0].data)) {
                    let newResults = [];

                    for (let i = 0; i < response.results.length; i++) {
                        let x = response.results[i];
                        newResults.push({
                            chartOptions: x.chartOptions, modID: x.modID, mstID: x.mstID, packChart: x.data[0],
                            packTable: x.data[1], resultName: x.data[2]
                        });
                    }

                    response.results = newResults;
                }
            }

            successFn(response);
        }, errorFn);
    }, 

    getDataPackResultsLOCAL : (params, successFn, errorFn) => {
        params.statusFn('getDataPackResultsLOCAL');
        SpectrumService2.getDataPackResultsLOCAL(params, (response) => {
            successFn(response);
        }, errorFn);
    }, 

    getDataPackExtracts : (params, successFn, errorFn) => {
        params.statusFn('getDataPackExtracts');
        SpectrumService2.getDataPackExtracts(params, (response) => {
            successFn(response);
        }, errorFn);
    }, 

    getDataPackExtractsLOCAL : (params, successFn, errorFn) => {
        params.statusFn('getDataPackExtractsLOCAL');
        SpectrumService2.getDataPackExtractsLOCAL(params, (response) => {
            successFn(response);
        }, errorFn);
    }, 
    

};