import { fieldNamePrefix } from "../other/constants";

const getCoreColumnDefinitions = (packTable, headerLabels) => {
    if (headerLabels.length > 0) {
        headerLabels = headerLabels[packTable.GBFixedRows - 1];
    }

    return headerLabels.map((value, index) => {
        const columnWidth = packTable.GBColWidths[index];

        const originalWidth = columnWidth >= 0 ? columnWidth : undefined;

        return {
            ids: packTable.ColIds[index],
            headerName: value,
            field: `${fieldNamePrefix}${index + 1}`,
            originalWidth,
            width: originalWidth,
            pinned: index < packTable.GBFixedCols ? "left" : undefined,
            wordWrap: packTable.WordWrappedCols[index]
        };
    });
};

const getContainingMergedCellDefinition = (mergedCellDefinitions, rowIndex, columnIndex) =>
    mergedCellDefinitions.find(mergedCellDefinition => {
        const endRow = mergedCellDefinition.startRow + mergedCellDefinition.rowCount - 1;
        const endColumn = mergedCellDefinition.startColumn + mergedCellDefinition.columnCount - 1;

        return (
            mergedCellDefinition.startRow <= rowIndex &&
            endRow >= rowIndex &&
            mergedCellDefinition.startColumn <= columnIndex &&
            endColumn >= columnIndex
        );
    });

const getDenseMergedHeaderCellDefinitions = (packTable, mergedHeaderCellDefinitions) => {
    const denseMergedCellDefinitions = [...mergedHeaderCellDefinitions];

    for (let r = 0; r < packTable.GBFixedRows; r++) {
        for (let c = 0; c < packTable.GBColCount; c++) {
            if (!getContainingMergedCellDefinition(mergedHeaderCellDefinitions, r, c)) {
                denseMergedCellDefinitions.push({
                    startColumn: c,
                    startRow: r,
                    columnCount: 1,
                    rowCount: 1
                });
            }
        }
    }

    return denseMergedCellDefinitions;
};

const getHeaderSubgroups = (
    headerLabels,
    coreColumnDefinitions,
    headerRowIndex,
    startColumn,
    columnCount,
    mergedHeaderCellDefinitions
) => {
    let children = mergedHeaderCellDefinitions.filter(
        cellDefinition =>
            cellDefinition.startRow === headerRowIndex + 1 &&
            cellDefinition.startColumn >= startColumn &&
            cellDefinition.startColumn < startColumn + columnCount
    );

    children = children.flatMap(child => {
        child.headerName = headerLabels[child.startRow][child.startColumn];

        const grandchildren = getHeaderSubgroups(
            headerLabels,
            coreColumnDefinitions,
            headerRowIndex + 1,
            child.startColumn,
            child.columnCount,
            mergedHeaderCellDefinitions
        );

        return !grandchildren.length
            ? coreColumnDefinitions.slice(child.startColumn, child.startColumn + child.columnCount)
            : {
                  ...child,
                  children: grandchildren
              };
    });

    return children.sort((a, b) => a.startRow - b.startRow || a.startColumn - b.startColumn);
};

const getGroupedColumnDefinitions = (packTable, headerLabels, mergedCellDefinitions) => {
    const coreColumnDefinitions = getCoreColumnDefinitions(packTable, headerLabels).map(
        (coreColumnDefinition, index) => ({
            ...coreColumnDefinition,
            startColumn: index,
            startRow: packTable.GBFixedRows - 1
        })
    );

    if (packTable.GBFixedRows <= 1) {
        return coreColumnDefinitions;
    }

    const mergedHeaderCellDefinitions = mergedCellDefinitions.filter(
        mergedCellDefinition => mergedCellDefinition.startRow < packTable.GBFixedRows - 1
    );

    const denseMergedHeaderCellDefinitions = getDenseMergedHeaderCellDefinitions(
        packTable,
        mergedHeaderCellDefinitions
    );

    return getHeaderSubgroups(
        headerLabels,
        coreColumnDefinitions,
        -1,
        0,
        coreColumnDefinitions.length,
        denseMergedHeaderCellDefinitions
    );
};

export default getGroupedColumnDefinitions;
