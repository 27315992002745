import React from 'react';
import PropTypes from "prop-types";
import { CSEditor } from '../../../../components/CS/CSEditors';
import CONSTANTS from "utilities/CS/CSConst";
import EditorNote from "../../../../components/EditorNote";
import {FertilityRisksEditor} from "../../data/Coverage/CS/FertilityRisksEditor";

class CSFertilityRisksDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object,
        onPackTable1Change        : PropTypes.func,
        onPackTable2Change        : PropTypes.func,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change        : (packTable) => console.log('onPackTable2Change'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        packTable = FertilityRisksEditor.RecalcGrid(packTable, FertilityRisksEditor.MatAgeAndBirthOrderID);
        this.props.onPackTable1Change(packTable);
    };

    onPackTable2Change = (packTable) => {
        packTable = FertilityRisksEditor.RecalcGrid(packTable, FertilityRisksEditor.BirthIntervalsID);
        this.props.onPackTable2Change(packTable);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {2}
                />

                <CSEditor
                mstID={CONSTANTS.CS_MstLiSTOnlineAgeAndBirthOrderSheetID}
                showTitle={true}
                packTable={packTable1}
                onChange={this.onPackTable1Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstLiSTOnlineBirthIntervalsSheetID}
                    showTitle={true}
                    packTable={packTable2}
                    onChange={this.onPackTable2Change}
                />

            </div>
        );

    }

}

export default CSFertilityRisksDrawer;