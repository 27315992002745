import React from 'react';
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

class TCSNotesDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onClose         : PropTypes.func,
        notes           : PropTypes.string
    };

    static defaultProps = {
        onClose         : () => console.log('onClose'),
        notes           : ''
    };

    state = {
        projectionNotes : this.props.notes,
        applyBtnEnabled : false
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    handleChange = () => event => {
        this.setState({
            projectionNotes: event.target.value,
            applyBtnEnabled : true
        });
    };

    onClose = () => {
        this.props.onClose('close');
    };

    onApplyBtnClick = () => {
        this.props.onClose('save', this.state.projectionNotes);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stNotesForProjection")}
                onClose={this.onClose}
                content={
                    <div>
                        <TextField
                            id="projectionNotes"
                            label={RS('GB_stNotesForProjection')}
                            multiline
                            rows="6"
                            margin="normal"
                            variant="outlined"
                            style={{marginTop: 15, width: '465px'}}
                            value={this.state.projectionNotes}
                            onChange={this.handleChange()}
                        />
                    </div>
                }
                actions={
                    <ApplyButton
                        onClick={this.onApplyBtnClick}
                        disabled={!this.state.applyBtnEnabled}
                    />
                }
            />
        );
    }

}

export default TCSNotesDialog;