import React from "react";
import RS from "@common/strings/global";
import TCheckBox from "@common/components/TCheckBox";
import TComboBox from "@common/components/TComboBox";
import TRangeSlider from "@common/components/TRangeSlider";
import Stepper from "../components/Stepper";
import ButtonNext from "../components/buttons/ButtonNext";
import ButtonBack from "../components/buttons/ButtonBack";

const ConfigurationPage = (props) => {
    const { onPageChange, onStateChange, onStepChange, stepsCompleted, Theme } = props;
    const { fileFormat, years, recalculateProjs } = props;
    // const { transposeYears, localCurrency, fullPrecision } = props;
    const { MinProjFirstYr, MaxProjFinalYr } = props;

    const styles = {
        content : {
            marginTop: 20,
            maxWidth: 615,
            minWidth: 615,
            marginLeft: 10,
        },
        checkBox : {
            // marginLeft : 10,
            marginTop: 15
        },
        buttonBack : {
            float: "right",
        },
        buttonNext : {
            float: "right",
            marginLeft: 15,
        }
    };

    const onNextButtonClick = () => {
        // let newStepsCompleted = clone(stepsCompleted);
        // newStepsCompleted.push(2);

        // onStateChange({
        //     stepsCompleted : newStepsCompleted
        // });
        
        onPageChange("ProcessPage");
    };

    return (
        <React.Fragment>
            <Stepper
                steps={[RS('GB_stChooseProj'), RS('GB_stSelectIndicators'), RS('GB_stSetConfig'), RS('GB_stProcess')]}
                activeStep={2}
                completedSteps={stepsCompleted}
                onStepBtnClick={onStepChange}
                useAltColors={true}
                Theme={Theme}
            />

            <div style={styles.content}>
                <TComboBox
                    caption={RS("GB_stResultFileFormat")}
                    style={{marginBottom: 10}}
                    items={[
                        RS("GB_stOneIndicPerWorksheet"),
                        RS("GB_stOneProjPerWorksheet"),
                        RS("GB_stSingleSheetCSV"),
                        // RS("GB_stTransposedCSVCap")                                    // 1/27/21 Bob said to remove for now
                    ]}
                    itemIndex={fileFormat}
                    onChange={(value) => onStateChange({fileFormat : value})}
                />

                <p style={{marginBottom: 5}}>{RS("GB_stEXYearRangeLbl")} </p>

                <TRangeSlider
                    maxTextLabel={RS("GB_stFinalYear")}
                    minTextLabel={RS("DP_stFirstYr")}
                    style={{width: "65%"}}
                    value={years}
                    minValue={MinProjFirstYr}
                    maxValue={MaxProjFinalYr}
                    onChange={(value) => onStateChange({years : value})}
                />

                {/* <TCheckBox
                    caption={RS("GB_stTransposeCBLbl")}
                    value={transposeYears}
                    onClick={() => onStateChange({transposeYears : !transposeYears})}
                    style={styles.checkBox}
                /> */}

                {/* <p style={styles.checkboxTitle}>General</p> */}

                <TCheckBox
                    caption={RS("GB_stRecalcProjections")}
                    value={recalculateProjs}
                    onClick={() => onStateChange({recalculateProjs : !recalculateProjs})}
                    style={styles.checkBox}
                />

                {/* <TCheckBox
                    caption={RS("GB_stExtractCostsInLocalCurr")}
                    value={localCurrency}
                    onClick={() => onStateChange({localCurrency : !localCurrency})}
                    style={styles.checkBox}
                /> */}

                {/* <TCheckBox
                    caption={RS("GB_stExtractFullPrecision")}
                    value={fullPrecision}
                    onClick={() => onStateChange({fullPrecision : !fullPrecision})}
                    style={styles.checkBox}
                /> */}

                <ButtonNext
                    onClick={onNextButtonClick}
                    containerStyle={styles.buttonNext}
                />

                <ButtonBack
                    onClick={() => onPageChange("IndicatorsPage")}
                    containerStyle={styles.buttonNext}
                    colorType="secondary"
                />
            </div>
        </React.Fragment>
    );
};

export default ConfigurationPage;