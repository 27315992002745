import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CountryDropdown from "./CountryDropdown"
import MOSideBar from "./MOSideBar"
import CountryFlags from "../../CountryFlags";
import CONSTANTS from "utilities/CS/CSConst";

class MissedOpportunitiesSideBar extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onAddTasks                     : PropTypes.func,
        appMode                        : PropTypes.string,
        selectedCountry                : PropTypes.string,
        countryISO                     : PropTypes.number,
        countries                      : PropTypes.array,
        displayButtons                 : PropTypes.bool,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        MOResultToDisplay              : PropTypes.number,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        onPageChange                   : () => console.log('onPageChange'),
        onAddTasks                     : () => console.log('onAddTasks'),
        appMode                        : '',
        selectedCountry                : "Afghanistan",
        countryISO                     : 4,
        countries                      : [],
        displayButtons                 : true,
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayCPR                     : true,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        viewInterventionCost           : false,
        language                       : CONSTANTS.GB_English
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderSideBarHeader = () => {
        let styles = {
            divStyle: {
                marginTop: '-26px',
                // height: '55px',
                width: '300px',
                backgroundColor: '#FFFFFF',

            },
            headerStyle: {
                color: '#cb6814',
                fontSize: '22px',
                fontWeight: 'bold',
                userSelect: 'none',
                marginTop: '0px',
                marginBottom: '0px'
            }
        };

        return (
            <div style={styles.divStyle}>
                <p style={styles.headerStyle}>{RS('GB_stMissedOpportunities')}</p>
            </div>
        )
    };

    renderSideBarBody = () => {
        return (
            <MOSideBar
                onStateChange                  = {this.props.onStateChange}
                onPageChange                   = {this.props.onPageChange}
                displayButtons                 = {true}
                displayMaternalCOD             = {this.props.displayMaternalCOD}
                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                displayChildCOD                = {this.props.displayChildCOD}
                displayCPR                     = {this.props.displayCPR}
                deliveryPointsContent          = {this.props.deliveryPointsContent}
                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                selectedCountry                = {this.props.selectedCountry}
                MOResultToDisplay              = {this.props.MOResultToDisplay}
                maternalIntervSelected         = {this.props.maternalIntervSelected}
                stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                childIntervSelected            = {this.props.childIntervSelected}
                nutritionSelected              = {this.props.nutritionSelected}
                birthOutcomeSelected           = {this.props.birthOutcomeSelected}
                viewInterventionCost           = {this.props.viewInterventionCost}
                language                       = {this.props.language}
            />
        )
    };

    render() {
        let styles = {
            sideBarStyle : {
                marginLeft: '25px',
            },
            divStyle: {
                marginTop: '5px',
                width: '300px',
                minHeight: '100px',
                overflow: 'hidden',
            },
        };
        return (
            <div style={styles.sideBarStyle}>
                {this.renderSideBarHeader()}

                <div>
                    <CountryDropdown
                        onStateChange         = {this.props.onStateChange}
                        onAddTasks            = {this.props.onAddTasks}
                        selectedCountry       = {this.props.selectedCountry}
                        countryISO            = {this.props.countryISO}
                        countries             = {this.props.countries}
                    />

                    <CountryFlags
                        selectedCountry       = {this.props.selectedCountry}
                        style                 = {{width: '27px',marginTop: '25px',marginLeft: '10px'}}
                    />
                </div>

                {this.renderSideBarBody()}
            </div>
        );
    }
}

export default MissedOpportunitiesSideBar;