import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSMaternalHealthDrawer from "../../drawers/HealthStatus/CSMaternalHealthDrawer";
import {PercExposedFalciparumEditor} from "../../data/HealthStatus/MaternalHealth/PercExposedFalciparumEditor";
import {MaternalNutritionalDeficienciesEditor} from "../../data/HealthStatus/MaternalHealth/MaternalNutritionalDeficienciesEditor";
import {MaternalAnemiaEditor} from "../../data/HealthStatus/MaternalHealth/MaternalAnemiaEditor";
import {SevereAnemiaEditor} from "../../data/HealthStatus/MaternalHealth/SevereAnemiaEditor";
import {PercWom15t49LowBMIEditor} from "../../data/HealthStatus/MaternalHealth/PercWom15t49LowBMIEditor";

class CSMaternalHealthBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        IVInfo           : PropTypes.arrayOf(PropTypes.object),
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        IVInfo           : [],
        CSModvars        : [],
        onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled  : true,
        openDrawer       : false,
        packTable1       : null,
        packTable2       : null,
        packTable3       : null,
        packTable4       : null,
        packTable5       : null,
        IPTpCBChecked    : this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_IPTpCBChecked_MV2) ? true : false).value,
    };

    componentDidMount() {
        this.setState({
            packTable1 : PercExposedFalciparumEditor.Create(this.props.CSModvars, this.state.IPTpCBChecked)[CONSTANTS.CS_PackTable],
            packTable2 : MaternalNutritionalDeficienciesEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable3 : MaternalAnemiaEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable4 : SevereAnemiaEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable5 : PercWom15t49LowBMIEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.openDrawer) {
            return {
                packTable1 : PercExposedFalciparumEditor.Create(props.CSModvars, state.IPTpCBChecked)[CONSTANTS.CS_PackTable],
                packTable2 : MaternalNutritionalDeficienciesEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                packTable3 : MaternalAnemiaEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                packTable4 : SevereAnemiaEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                packTable5 : PercWom15t49LowBMIEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true, 
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({ 
            openDrawer      : false,
            saveBtnDisabled : true,
            packTable1      : PercExposedFalciparumEditor.Create(this.props.CSModvars, this.state.IPTpCBChecked)[CONSTANTS.CS_PackTable],
            packTable2      : MaternalNutritionalDeficienciesEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable3      : MaternalAnemiaEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable4      : SevereAnemiaEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable5      : PercWom15t49LowBMIEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    };

    onSaveBtnClick = () => {
        let ModvarsArray1 = PercExposedFalciparumEditor.GetSSData(this.state.packTable1, this.props.CSModvars);
        let ModvarsArray2 = MaternalNutritionalDeficienciesEditor.GetSSData(this.state.packTable2, this.props.IVInfo, this.props.CSModvars);
        let ModvarsArray3 = MaternalAnemiaEditor.GetSSData(this.state.packTable3, this.props.CSModvars);
        let ModvarsArray4 = SevereAnemiaEditor.GetSSData(this.state.packTable4, this.props.CSModvars);
        let ModvarsArray5 = PercWom15t49LowBMIEditor.GetSSData(this.state.packTable5, this.props.IVInfo, this.props.CSModvars);

        let ModvarIPTpCB = {...this.props.CSModvars.find(x => x.tag === CONSTANTS.CS_TG_IPTpCBChecked_MV2), value: Boolean(this.state.IPTpCBChecked)};

        let changedCSModvars = this.props.CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            let c = ModvarsArray3.find(y => y.tag === x.tag);
            let d = ModvarsArray4.find(y => y.tag === x.tag);
            let e = ModvarsArray5.find(y => y.tag === x.tag);
            let f = (x.tag === CONSTANTS.CS_TG_IPTpCBChecked_MV2) ? ModvarIPTpCB : undefined;
            return a || b || c || d || e || f || x;
        });

        this.setState({
            openDrawer      : false,
            saveBtnDisabled : true,
        });

        this.props.onStateChange({
            unchangedProj: false,
            CSModvars: changedCSModvars,
        });
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable3Change = (packTable) => {
        this.setState({
            packTable3      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable4Change = (packTable) => {
        this.setState({
            packTable4      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable5Change = (packTable) => {
        this.setState({
            packTable5      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2 
                caption     = {RS('GB_stMaternalHealth')}
                description = ""  
                onClick     = {this.onBlueBoxClick}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"baseline-maternal-health-statu.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stMaternalHealth')}
                        content         = {
                                            <CSMaternalHealthDrawer
                                                packTable1={this.state.packTable1}
                                                packTable2={this.state.packTable2}
                                                packTable3={this.state.packTable3}
                                                packTable4={this.state.packTable4}
                                                packTable5={this.state.packTable5}
                                                onPackTable1Change={this.onPackTable1Change}
                                                onPackTable2Change={this.onPackTable2Change}
                                                onPackTable3Change={this.onPackTable3Change}
                                                onPackTable4Change={this.onPackTable4Change}
                                                onPackTable5Change={this.onPackTable5Change}
                                                CSModvars={this.props.CSModvars}
                                                passData={(o) => this.setState(o)}
                                            />
                                        }
                    />
                }
            />
        );
    }

}

export default CSMaternalHealthBlueBox;