import React, { useState } from "react";
import * as PropTypes from "prop-types";
import DraggableWindow from "./DraggableWindow";

const styles = {
  buttonWrapper: {
    display: "inline-block"
  },
  content: {
    borderStyle: "solid",
    borderWidth: "1px 0 1px",
    borderColor: "#d3d3d3",
    // padding: "1rem 0",
    marginLeft: '5px',
    width: "100%",
    height: "100%"
  },
  contentWrapper: {
    width: "calc(100% - 5px)",
    height: "calc(100% - 2rem)"
  }
};

const DraggableDocumentWindow = ({ children, url, ...rest }) => {
  const [isDraggable, setDraggable] = useState(false);

  const handleDragStart = () => {
    setDraggable(true);
  };

  const handleDragStop = () => {
    setDraggable(false);
  };

  return (
    <div>
      <div className="draggable-document">
        <DraggableWindow
          anchorRef={rest.anchorRef}
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          {...rest}>
          <div style={{
            ...styles.contentWrapper,
            pointerEvents: isDraggable ? "none" : undefined
          }}>
            <iframe title={url} src={url} style={styles.content} />
          </div>
        </DraggableWindow>
      </div>
    </div>
  );
};

DraggableDocumentWindow.propTypes = {
  anchorRef: PropTypes.object,
  children: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  url: PropTypes.string.isRequired,
  footer: PropTypes.element,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultPositionOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  onClose: PropTypes.func.isRequired
};

export default DraggableDocumentWindow;
