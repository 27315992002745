import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import LoginButton from "../buttons/LoginButton";

class TCSNotLoggedInDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange  : PropTypes.func,
    };

    static defaultProps = {
        onStateChange  : (value) => console.log('onStateChange ' + JSON.stringify(value)),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogNotLoggedInOpen : false
        });
    };

    onLogInBtnClick = () => {
        this.props.onStateChange({
            dialogNotLoggedInOpen : false,
            dialogLogInOpen       : true,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stLogInToSaveProj')}
                onClose={this.onClose}
                content={
                    <div>
                        {RS('GB_stMustLogIn')}
                    </div>
                }
                actions={
                    <div>
                        <LoginButton onClick={this.onLogInBtnClick} />
                    </div>
                }
            />
        );
    }

}

export default TCSNotLoggedInDialog;