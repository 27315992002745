import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    indentPackTable,
    lockPackTable,
    addMergedCellsToArray,
    addRowToPackTable, generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    CSGetBirthOrder,
    CSGetAgeGivingBirth,
    CSGetBirthTermAbbrev,
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const MatAgeAndBirthOrderEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stMatAgeAndBirthOrder');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let AgeAndBirthOrder = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_AgeAndBirthOrder_MV2);
        let col = 1;
        for (let term = CONSTANTS.CS_PreTermSGA; term <= CONSTANTS.CS_TermSGA; term++){
            let row = 1;
            for (let a = CONSTANTS.CS_LT18Yrs; a <= CONSTANTS.CS_35_49Yrs; a++) {
                row++;
                for (let bo = CONSTANTS.CS_FirstBirth; bo <= CONSTANTS.CS_GTThirdBirth; bo++) {
                    row++;
                    AgeAndBirthOrder["value"][CONSTANTS.CS_Data][CONSTANTS.CS_RelRisk][a][bo][term] = pt.tableData.value[row][col];
                    AgeAndBirthOrder["source"][a][bo] = pt.Source[pt.SourceMap[row]];
                }
            }
            col++;
        }

        changedModvars.push(AgeAndBirthOrder);

        return (changedModvars);
    },
};

const Setup = () => {
    const numCols = 4;
    const numRows = 2;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let i = CONSTANTS.CS_PreTermSGA; i <= CONSTANTS.CS_TermSGA; i++){
        pt.tableData.value[0][col] = CSGetBirthTermAbbrev(i);
        pt.tableData.value[1][col] = RS('GB_stRelRisk');
        col++;
    }

    pt.numSources = 0;

    let row = 1;
    for (let a = CONSTANTS.CS_LT18Yrs; a <= CONSTANTS.CS_35_49Yrs; a++){
        row++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = CSGetAgeGivingBirth(a);
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
        pt = lockPackTable(pt, row, true);
        for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GTThirdBirth; i++){
            row++;
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = CSGetBirthOrder(i);
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            pt.numSources++;
            pt.SourceTitle[pt.numSources] = CSGetAgeGivingBirth(a) + ' - ' + CSGetBirthOrder(i);
            pt.SourceMap[row] = pt.numSources;
        }
    }

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 3;
            }
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let AgeAndBirthOrder = getModvarByTag(CSModvars, CONSTANTS.CS_TG_AgeAndBirthOrder_MV2)["value"][
        CONSTANTS.CS_Data][CONSTANTS.CS_RelRisk];
    let AgeAndBirthOrderSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_AgeAndBirthOrder_MV2)["source"];
    let col = 1;
    for (let term = CONSTANTS.CS_PreTermSGA; term <= CONSTANTS.CS_TermSGA; term++){
        let row = 1;
        for (let a = CONSTANTS.CS_LT18Yrs; a <= CONSTANTS.CS_35_49Yrs; a++) {
            row++;
            for (let bo = CONSTANTS.CS_FirstBirth; bo <= CONSTANTS.CS_GTThirdBirth; bo++) {
                row++;
                pt.tableData.value[row][col] = AgeAndBirthOrder[a][bo][term];
                pt.Source[pt.SourceMap[row]] = AgeAndBirthOrderSrc[a][bo];
            }
        }
        col++;
    }

    return pt;
};

