import React from 'react';
import PropTypes from "prop-types";
import ExploreExpansionPanel from "../ExploreExpansionPanel";
import Divider from "@material-ui/core/Divider";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class EffectivenessExpPanel extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        panelName            : PropTypes.string,
        panelKey             : PropTypes.string,
        content              : PropTypes.array,
        onAddDisplay         : PropTypes.func,
        language             : PropTypes.number,
    };

    static defaultProps = {
        panelName            : '',
        panelKey             : '',
        content              : [],
        onAddDisplay         : () => console.log('EffectivenessExpPanel onAddDisplay'),
        language             : CONSTANTS.GB_English
    };

    state = {
        content              : this.props.content,
        buttonDisabled       : true,
        outcomeIdx           : 0
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onShowDataBtnClick = () => {
        let newData = this.state.content.map(x => {
            if (x.checked) {
                return ({
                    editorID             : x['editorID'],
                    mode                 : CONSTANTS.CS_ExploreEffectivenessValuesMode,
                    firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                    finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                    possibleDisplayTypes : [CONSTANTS.CS_TableChart],
                    selectedDisplayType  : CONSTANTS.CS_TableChart,
                    mstID                : x['mstID'],
                    cod                  : x['mstID'],
                    tabs                 : x['tabs']
                });
            }
            return undefined;
        }).filter(x => x);
        
        this.props.onAddDisplay(newData);
        this.resetPanelToDefault();
    };

    resetPanelToDefault = () => {
        this.setState({
            content        : this.state.content.map(x => ({ ...x, checked : false })),
            buttonDisabled : true
        });
    };

    onCheckBoxChange = (checked, event, name, caption, mstID) => {
        let panelContent = JSON.parse(JSON.stringify(this.state.content));
        let noneSelected = true;

        for (let i = 0; i < panelContent.length; i++) {
            if (panelContent[i]['mstID'] === mstID) {
                panelContent[i]['checked'] = checked;
            }
        }

        for (let j = 0; j < panelContent.length; j++) {
            if (panelContent[j]['checked']) {
                noneSelected = false;
            }
        }

        this.setState({
            content        : panelContent,
            buttonDisabled : noneSelected
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderContent = () => {
        let panelContent = [];

        const styles = {
            checkBoxLabelStyle: {
                color: '#05225d',
                fontSize: '13px',
                lineHeight: '1'
            },
            checkBoxStyle: {
                height: '37px'
            },
        };

        for (let i = 0; i < this.state.content.length; i++) {
            panelContent.push(
                <TCheckBox
                    caption={RS(this.state.content[i]['name'])}
                    labelStyle={styles.checkBoxLabelStyle}
                    style={styles.checkBoxStyle}
                    boxStyle={this.props.boxStyle}
                    key={this.state.content[i]['mstID']}
                    color={'primary'}
                    name={''}
                    customProp={this.state.content[i]['mstID']}
                    value={this.state.content[i]['checked']}
                    onClick={this.onCheckBoxChange}
                />
            )
        }
        return (panelContent);
    };

    renderContent2 = () => {
        let panelContent = [];
        let groupID = -1;

        const styles = {
            checkBoxLabelStyle: {
                color: '#05225d',
                fontSize: '13px',
                lineHeight: '1'
            },
            checkBoxStyle: {
                height: '37px'
            },
            groupNameStyle : {
                fontSize: '16px',
                color: '#3B78BC',
                marginBottom: '8px'
            },
            dividerStyle: {
                width: '265px',
                marginLeft: '-10px',
                marginTop: '10px',
                marginBottom: '10px'
            },
        };

        for (let i = 0; i < this.state.content.length; i++) {
            if (this.state.content[i]['groupID'] !== groupID) {
                if (groupID !== -1) {
                    panelContent.push(
                        (<Divider key={'d' + i} style={styles.dividerStyle}/>)
                    )
                }
                groupID = this.state.content[i]['groupID'];
                if (groupID !== CONSTANTS.CS_MstNoNutritonGroup_ED) {
                    panelContent.push(
                        <div key={this.state.content[i]['groupName']} style={styles.groupNameStyle}>
                            {RS(this.state.content[i]['groupName'])}
                        </div>
                    )
                }
            }
            if (this.state.content[i]['groupID'] === groupID) {
                panelContent.push(
                    <TCheckBox
                        caption={RS(this.state.content[i]['name'])}
                        labelStyle={styles.checkBoxLabelStyle}
                        style={styles.checkBoxStyle}
                        boxStyle={this.props.boxStyle}
                        key={this.state.content[i]['mstID']}
                        color={'primary'}
                        name={''}
                        customProp={this.state.content[i]['mstID']}
                        value={this.state.content[i]['checked']}
                        onClick={this.onCheckBoxChange}
                    />
                )
            }
        }

        return (panelContent);
    };

    render() {
        return (
            <ExploreExpansionPanel
                onShowDataBtnClick = {this.onShowDataBtnClick}
                content            = {(this.props.panelKey === 'NutritionInterventions') ? this.renderContent2() : this.renderContent()}
                panelName          = {this.props.panelName}
                showDataBtn        = {true}
                buttonLabel        = {RS('GB_stCreateDisplay')}
                buttonWidth        = {'auto'}
                buttonDisabled     = {this.state.buttonDisabled}
                subPanel           = {true}
                panelKey           = {this.props.panelKey}
                key                = {this.props.panelKey}
                language           = {this.props.language}
            />
        );
    }

}

export default EffectivenessExpPanel;