import React from 'react';
import LoginBox from "../components/Login/LoginBox";

const LoginPage = (props) => {
    const { onLogin, onPageChange, Theme } = props;

    return (
        <LoginBox
            onLogin={onLogin}
            onPageChange={onPageChange}
            Theme={Theme}
        />
    );
};

export default LoginPage;