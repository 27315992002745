import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    lockPackTable, addRowToPackTable, add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {getCSGetDeathCause} from "utilities/CS/CSDataUtil";

export const PercDthByCauseEditor = {
    Create : (CSModvars, Mode) => {
        let editorName = (Mode === CONSTANTS.CS_Neonatal) ? RS('GB_stNNProximateCauses') : RS('GB_stPostNeonatalCause');
        let packTable = Setup(CSModvars, Mode);
        packTable = SetSSData(packTable, CSModvars);
        packTable = PercDthByCauseEditor.RecalcGrid(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ChildMortality;
        return ([packChart, packTable]);
    },

    RecalcGrid : (pt) => {
        let Sum = 0;
        for (let row = 1; row < pt.RowIds.length-1; row++) {
            Sum += pt.tableData.value[row][1];
        }
        pt.tableData.value[pt.RowIds.length-1][1] = Sum;
        return (pt);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let PercDthByCauseMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PercDthByCause_MV2);

        let BaselineMortMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_BaselineMort_MV);
        let NNMR = BaselineMortMV["value"][CONSTANTS.CS_NeonatalMR];
        let U5MR = BaselineMortMV["value"][CONSTANTS.CS_U5MR];

        let ageSet;
        switch (pt.Mode){
            case CONSTANTS.CS_Neonatal : {ageSet = [CONSTANTS.CS_0t1months]; break;}
            case CONSTANTS.CS_PostNN   : {ageSet = [CONSTANTS.CS_1t6months,
                                                    CONSTANTS.CS_6t12months,
                                                    CONSTANTS.CS_12t24months,
                                                    CONSTANTS.CS_24t60months]; break;}
            default                    : {ageSet = []; break;}
        }

        for (let row = 1; row < pt.RowIds.length; row++) {
            let d = pt.RowIds[row];
            if (d > 0) {
                let value = pt.tableData.value[row][1];
                if (U5MR !== 0) {
                    switch(pt.Mode){
                        case CONSTANTS.CS_Neonatal : {value = (value * NNMR / U5MR) * 100; break;}
                        case CONSTANTS.CS_PostNN   : {value = (value * (U5MR - NNMR) / U5MR) / 100; break;}
                        default : break;
                    }
                }
                else {
                    value = 0;
                }
                ageSet.forEach(function (a) {
                    PercDthByCauseMV["value"][CONSTANTS.CS_DxC_Default][d][a] = value;
                });
            }
        }

        PercDthByCauseMV["source"][0][pt.Mode] = pt.Source[0];

        changedModvars.push(PercDthByCauseMV);
        changedModvars.push(BaselineMortMV);

        return (changedModvars);
    },
};

const Setup = (CSModvars, Mode) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.Mode = Mode;
    pt.diseaseSet = [];

    switch (Mode){
        case CONSTANTS.CS_Neonatal :{
            for (let d = CONSTANTS.CS_FirstNNDeath; d <= CONSTANTS.CS_FinalStdNNDeath; d++) {
                pt.diseaseSet.push(d);
            }
            break;
        }

        case CONSTANTS.CS_PostNN : {
            for (let d = CONSTANTS.CS_FirstPNNDeath; d <= CONSTANTS.CS_FinalStdPNNDeath; d++) {
                pt.diseaseSet.push(d);
            }
            break;
        }

        default : {
            break;
        }
    }

    const numRows = 1;
    const numCols = 2;

    pt = resizePackTable(pt, numRows, numCols);

    let r = 1;
    pt.diseaseSet.forEach(function (d) {
        pt = addRowToPackTable(pt);
        pt.RowIds[r] = d;
        pt.tableData.value[r][0] = getCSGetDeathCause(CSModvars, d);
        pt.Alignments[r][1] = CONSTANTS.GB_Right;
        pt.RDec[r][1] = 2;
        r++;
    });

    pt = addRowToPackTable(pt);
    pt.tableData.value[r][0] = RS('GB_stTotal');
    pt.Alignments[r][1] = CONSTANTS.GB_Right;
    pt.RDec[r][1] = 2;
    pt.SourceMap[r] = 1;

    pt = lockPackTable(pt, r, true);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let PercDthByCause = getModvarByTag(CSModvars, CONSTANTS.CS_TG_PercDthByCause_MV2);

    let value = PercDthByCause["value"][CONSTANTS.CS_DxC_Default];

    let a;
    switch (pt.Mode){
        case CONSTANTS.CS_Neonatal : {a = CONSTANTS.CS_0t1months; break;}
        case CONSTANTS.CS_PostNN   : {a = CONSTANTS.CS_1t6months; break;}
        default                    : {a = -1; break;}
    }

    if (a > -1) {
        let sum = 0;
        pt.diseaseSet.forEach(function (d) {
            sum += value[d][a];
        });

        for (let row = 1; row < pt.RowIds.length; row++) {
            let d = pt.RowIds[row];
            if (d > 0) {
                if (sum > 0) {
                    pt.tableData.value[row][1] = ((value[d][a] / sum) * 100);
                } else {
                    pt.tableData.value[row][1] = 0;
                }
            }
        }
    }

    pt.Source[0] = PercDthByCause["source"][0][pt.Mode];

    return pt;
};

