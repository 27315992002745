import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from '@material-ui/core/CircularProgress';
import RS from "@common/strings/RS";
import CreateAPITask from "../../api/util/createAPITask";
import CSDialog from "./CSDialog";
import TButton from "../../@common/components/TButton";
import {SessionStorageUtil} from "../../api/localstorage/util";

const DialogServerAbout = (props) => {
    const {
        Theme,
        version,
        onClose,
        userName,
        //humanName,
        TimeIdle,
        TimeExpire,
        TimeTotal,
        // disconnecting,
        // status,
        // lastHeartBeat,
        // signedIn,
        // isGuest,
        // sessionExists,
        // disconnectWarning,
        // TimeToLive,
        onAddTasks,
    } = props;

    const [isRefreshing, setIsRefreshing] = React.useState(false);
    ;
    const styles = {
        container : {
            lineHeight: "23px",
            marginTop: -20
        },
        heading : {
            fontWeight: "bold",
            marginBottom: 0
        },
        hr: {
            marginTop: 14,
            border: "1px solid lightgray",
            borderTop: "none",
            // marginLeft: -22,
            // width: "calc(100% + 44px)",
            //height: 1
        }
    };

    const onRefresh = () => {
        setIsRefreshing(true);

        onAddTasks([
            CreateAPITask('getStatus', {}, (response) => {
                console.log(response);
                setIsRefreshing(false);
            }, () => {
                alert("Error retrieving status.");
                setIsRefreshing(false);
            })
        ]);
    };

    let serverBuildLabel;
    (version.ServerBetaNum === 0) ?
        serverBuildLabel = `${version.ServerVersionNum}` :
        serverBuildLabel = `${version.ServerVersionNum} beta ${version.ServerBetaNum}`;

    let spectrumLabel;
    (version.SpecBetaNum === 0) ?
        spectrumLabel = `${version.SpecVersionNum}` :
        spectrumLabel = `${version.SpecVersionNum} beta ${version.SpecBetaNum}`;

    const KeyValueLine = (props) => {
        const { text, value, hint } = props;

        return (
            <div style={{display: "block", marginLeft: 25}}>
                <Tooltip title={hint}>
                    <span style={{cursor: "help"}}>{text}</span>
                </Tooltip>
                <span>{value}</span>
            </div>
        );
    };

    return (
        <CSDialog
            open={true}
            modal={true}
            onClose={onClose}
            Theme={Theme}
            title={RS("GB_stAbout") + " SpectrumWeb"}
            content={
                <div style={styles.container}>
                    <p style={styles.heading}>Version</p>

                    {/*{serverBuildLabel} <br />*/}
                    {/*{spectrumLabel} <br />*/}

                    <KeyValueLine
                        text="Server build: "
                        value={serverBuildLabel}
                        hint=""
                    />

                    <KeyValueLine
                        text="Spectrum: "
                        value={spectrumLabel}
                        hint=""
                    />

                    <KeyValueLine
                        text="URL: "
                        value={sessionStorage.getItem("LOL_CONTAINER_BASE_URL")}
                        hint="Controller URL"
                    />

                    <hr style={styles.hr} />

                    <p style={styles.heading}>DataPack Server</p>

                    < KeyValueLine
                        text="Version: "
                        value={version.dataPackVersion}
                        hint="DataPack Version"
                    />

                    <KeyValueLine
                        text="URL: "
                        value={version.dataPackURL}
                        hint="DataPack URL"
                    />

                    <hr style={styles.hr} />

                    <p style={styles.heading}>Account</p>

                    <KeyValueLine
                        text="Login: "
                        value={userName}
                        hint="Email"
                    />

                    <KeyValueLine
                        text="Name: "
                        value={SessionStorageUtil.getHumanName() || "Guest"}
                        hint="Username"
                    />

                    <hr style={styles.hr} />

                    <p style={styles.heading}>Status</p>

                    <TButton
                        caption={
                            isRefreshing ?
                                <CircularProgress size={30} style={{color: 'red', marginTop: 12}} /> :
                                <div>Refresh</div>
                        }
                        onClick={onRefresh}
                        containerStyle={{float: 'right'}}
                        width="100px"
                    />

                    <KeyValueLine
                        text="Idle time: "
                        value={TimeIdle}
                        hint="Time spent without a non status API call to the server"
                    />

                    <KeyValueLine
                        text="Expiration time: "
                        value={TimeExpire}
                        hint="Time until session disconnects"
                    />

                    <KeyValueLine
                        text="Total time: "
                        value={TimeTotal}
                        hint="Total time connected"
                    />

                    {/* <hr />

                    lastHeartBeat: {lastHeartBeat} <br /> */}

                    {/* sessionExists: {sessionExists.toString()} <br /><br />
                    disconnecting: {disconnecting.toString()} <br /><br />
                    disconnectWarning: {disconnectWarning.toString()} <br /><br /> */}


                    {/* status: {status} <br /><br /> */}
                </div>
            }
            actions={undefined}
        />
    );
};

export default React.memo(DialogServerAbout);