import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { clone } from "utilities/GB/GBUtil";
import ManageResultsDrawer from 'components/SelectResults/ManageResultsDrawer';
import TCSDsCard from '../results/CS/cards/TCSDsCard';
import CSStepper from "../components/CS/CSStepper";
import ManageResultsButton from "../components/SelectResults/ManageResultsButton";
import {getModvarByTag, RemoveRowsFromEndOfPackTable} from "utilities/GB/GBUtil";
import { Get_FP_AgeName } from "../utilities/CS/CSDataUtil";
import TSwitch from '@common/components/TSwitch';
// import TCSCoverageSparkLinesTable from "../components/CS/TCSCoverageSparkLinesTable";

const styles = {
    content : {
        margin: '0 10px'
    },
    cards : {
        //
    }   
};

const LiSTProjectionStepper = React.memo(({onStepChange}) => {
    let steps = [RS('GB_stModProjection'),
                 RS('GB_stManageInterventions'),
                 RS('GB_stReviewDefaultData'),
                 RS('GB_stViewResults')];

    return (
        <CSStepper
            steps={steps}
            activeStep={3}
            completedSteps={[]}
            onStepBtnClick={onStepChange}
            useAltColors={true}
        />
    );
});

//======================================================================================================================
//
//   These procedures are needed to compensate for the fact that some results share an indicator ID on the datapack
//                            server but have differing indicator ID's in LiSTOnline.
//
//======================================================================================================================

const getMstIDFromLiSTOnlineResult = (selectedResult) => {
    let modID = selectedResult[1];
    let result = selectedResult[0];

    if (modID === CONSTANTS.GB_DP) {
        if (result === CONSTANTS.DP_MstcmPop04) {
            result = CONSTANTS.DP_MstcmTotalPop;
        }
    }

    if (modID === CONSTANTS.GB_FP) {
        if (result === CONSTANTS.FP_MstcmNumUnintendPreg) {
            result = CONSTANTS.FP_MstcmPregnancies;
        }
    }

    return result;
};

const getMstIDFromDataPackResult = (dataPackResult) => {
    let modID = dataPackResult.modID;
    let result = dataPackResult.mstID;

    if (modID === CONSTANTS.GB_DP) {
        if ((result === CONSTANTS.DP_MstcmTotalPop) && (dataPackResult.chartOptions["HiAge"] === 4)) {
            result = CONSTANTS.DP_MstcmPop04;
        }
    }

    if (modID === CONSTANTS.GB_FP) {
        if ((result === CONSTANTS.FP_MstcmPregnancies) && (dataPackResult.chartOptions["DisAgPreg"])) {
            result = CONSTANTS.FP_MstcmNumUnintendPreg;
        }
    }

    return result;
};

const getResultName = (props, dataPackResult) => {
    let resultMenu = clone(props.resultMenus);

    let modID = dataPackResult.modID;
    let mstID = getMstIDFromDataPackResult(dataPackResult);

    let result = dataPackResult.data.resultName;

    if (modID === CONSTANTS.GB_DP) {
        switch (mstID) {
            case CONSTANTS.DP_MstcmTotalPop :
                result = RS("GB_stTotalPop");
                break;
            case CONSTANTS.DP_MstcmPop04 :
                result += ' ' + RS('GB_stAged') + ' ' +
                    dataPackResult.chartOptions["LowAge"] + '-' +
                    dataPackResult.chartOptions["HiAge"];
                break;
            default :
                break;
        }
    }

    if (modID === CONSTANTS.GB_FP) {
        let TreeFP = resultMenu["TreeFP"];
        let language = props.language;
        for (let i = 0; i <TreeFP.items.length; i++) {
            for (let j = 0; j < TreeFP.items[i].items.length; j++) {
                if ((TreeFP.items[i].items[j].indID === mstID)) {
                    result = TreeFP.items[i].items[j].Names[language]
                }
            }
        }
    }

    return result;
};

const updateDataPackRDec = (dataPackResult) => {
    let modID = dataPackResult.modID;

    let result = dataPackResult;

    if (modID === CONSTANTS.GB_FP) {
        let pt = result.data.packTable;
        for(let row = 0; row < pt.GBRowCount; row++){
            for(let col = 0; col < pt.GBColCount; col++) {
                pt.RDec[row][col] = 2;
            }
        }

        result.data.packChart.rDec = 2;
    }

    return result;
};

export const getChartOptionsBasedOnInputs = (DPModvars, chartOptions) => {
    let options = clone(chartOptions);

    let AgeGroupOption = getModvarByTag(DPModvars, CONSTANTS.FP_TG_TAgeGroupOption_MV)['value'];

    if (AgeGroupOption === CONSTANTS.FP_Single_Age_Group) {
        options.DisAgAges = false;
        options.ages = [CONSTANTS.FP_All_Ages];
        options.LowAge = 0;
        options.HiAge = 80;
    }

    if (options.modID === CONSTANTS.GB_DP && options.resultID === CONSTANTS.DP_MstcmPop04) {
        options.HiAge = 4;
    }

    return (options)
};

export const updateDataPackTable = (DPModvars, dataPackResult) => {
    let modID = dataPackResult.modID;
    let mstID = getMstIDFromDataPackResult(dataPackResult);

    let result = dataPackResult;

    if (modID === CONSTANTS.GB_FP) {
        if (!result.hasBeenProcessed) {
            result.data.packTable = RemoveRowsFromEndOfPackTable(result.data.packTable, 1);

            if (mstID === CONSTANTS.FP_MstcmIllAbortions){
                let AgeGroupOption = getModvarByTag(DPModvars, CONSTANTS.FP_TG_TAgeGroupOption_MV)['value'];

                if (AgeGroupOption === CONSTANTS.FP_Five_Year_Age_Groups) {
                    result.data.packTable.tableData.value[1][0] = Get_FP_AgeName(CONSTANTS.FP_A15_19);
                }
            }

            result.hasBeenProcessed = true;
        }
    }

    return result;
};

//======================================================================================================================
//
//
//
//======================================================================================================================

class CSViewResultsFm extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        chartOptions           : PropTypes.arrayOf(PropTypes.object),
        chartYears             : PropTypes.object,
        availableResults       : PropTypes.array,
        firstYear              : PropTypes.number,                       // is this really the calcyear?...
        finalYear              : PropTypes.number,
        projectionName         : PropTypes.string,
        IVInfo                 : PropTypes.arrayOf(PropTypes.object),
        IVGroupInfo            : PropTypes.arrayOf(PropTypes.object),
        CSModvars              : PropTypes.arrayOf(PropTypes.object),
        selectedPackTables     : PropTypes.array,
        selectedPackCharts     : PropTypes.array,
        resultMenus            : PropTypes.object,
        selectedResults        : PropTypes.array,
        includeFamPlan         : PropTypes.bool,
        isDeskTop              : PropTypes.bool,
        stacked                : PropTypes.string,
        legendReversed         : PropTypes.bool,
        displayCoverage        : PropTypes.bool,
        retroYears             : PropTypes.bool,
        displayEndpoints       : PropTypes.bool,
        coverageDisplay        : PropTypes.string,
        language               : PropTypes.number,
        activeProjections      : PropTypes.array,
        onPageChange           : PropTypes.func,
        onStateChange          : PropTypes.func,
        onOpenHelpDialog       : PropTypes.func,
        onGetDataPackResults   : PropTypes.func,
        approach               : PropTypes.number
    };

    static defaultProps = {
        chartOptions           : [],
        chartYears             : {},
        availableResults       : [],
        firstYear              : new Date().getFullYear(),
        finalYear              : CONSTANTS.CS_DefaultFinalYear,
        projectionName         : '',
        IVInfo                 : [],
        IVGroupInfo            : [],
        CSModvars              : [],
        selectedPackTables     : [],
        selectedPackCharts     : [],
        resultMenus            : {},
        selectedResults        : [],
        includeFamPlan         : false,
        isDeskTop              : true,
        stacked                : '',
        legendReversed         : false,
        displayCoverage        : false,
        retroYears             : false,
        displayEndpoints       : false,
        coverageDisplay        : CONSTANTS.CS_TableChart,
        language               : CONSTANTS.GB_English,
        activeProjections      : [],
        onPageChange           : () => console.log("onPageChange"),
        onStateChange          : () => console.log("onStateChange"),
        onOpenHelpDialog       : () => console.log("onOpenHelpDialog"),
        onGetDataPackResults   : () => console.log("onGetDataPackResults"),
        approach               : CONSTANTS.CS_StandardApproach
    };

    state = {
        loading : true,
        AddResultsDrawerOpen : false,
        dataPackResults : [],
        groupResults : false,
    };

    componentDidMount() {
        const { firstYear, finalYear, onStateChange } = this.props;

        /* When first mounting the results page, always set the chartYears to the max range of years - 5/14/20 */
        onStateChange({
            chartYears : {
                firstYrIdx: CONSTANTS.CS_DefaultFirstYearIdx,
                finalYrIdx: finalYear - firstYear + CONSTANTS.CS_DefaultFirstYearIdx
            }
        }, () => {
            this.getDataPacks();
            this.setState({
                loading: false
            });
        });
    }

    componentDidUpdate(prevProps) {
        let fetchedDataPacks = false;

        // If there is a new selectedResult, we need to grab the new dataPacks
        if (this.props.selectedResults !== prevProps.selectedResults) {
            let needDataPacks = false;

            this.props.selectedResults.forEach(x => {
                let mstID = x[0]; 
                let modID = x[1]; 

                if (!prevProps.selectedResults.find(y => y[0] === mstID && y[1] === modID)) {
                    needDataPacks = true;
                }
            });

            if (needDataPacks) {
                this.getDataPacks();
                fetchedDataPacks = true;
            }
        }

        // If the chartYears change, grab dataPacks (check for the values specifically)
        if (!fetchedDataPacks) {
            if (
                (this.props.chartYears.firstYrIdx !== prevProps.chartYears.firstYrIdx) ||
                (this.props.chartYears.finalYrIdx !== prevProps.chartYears.finalYrIdx)
            ) {
                this.getDataPacks();
                fetchedDataPacks = true;
            }
        }

        // If the endpoints change, grab dataPacks
        if (!fetchedDataPacks) {
            if (this.props.displayEndpoints !== prevProps.displayEndpoints) {
                this.getDataPacks();
                fetchedDataPacks = true;
            }
        }

        // If the showIVs change, grab dataPacks
        if (!fetchedDataPacks) {
            let needDataPacks = false;

            this.props.selectedResults.forEach(x => {
                let mstID = x[0]; 
                let modID = x[1]; 
                let prevShowIVs = prevProps.chartOptions.find(y => ((y.modID === modID) && (y.resultID === mstID)))['showIVs'];
                let nextShowIVs = this.props.chartOptions.find(y => ((y.modID === modID) && (y.resultID === mstID)))['showIVs'];

                if (prevShowIVs !== nextShowIVs) {
                    needDataPacks = true;
                }
            });

            if (needDataPacks) {
                this.getDataPacks();
                fetchedDataPacks = true;
            }
        }

        // If the chartOptions change within "showable" results, grab dataPacks
        if (!fetchedDataPacks) {
            if (this.props.chartOptions !== prevProps.chartOptions) {
                let needDataPacks = false;

                this.props.selectedResults.forEach(x => {
                    let mstID = x[0]; 
                    let modID = x[1]; 

                    let options1 = clone(prevProps.chartOptions.find(y => y.resultID === mstID && y.modID === modID));
                    let options2 = clone(this.props.chartOptions.find(y => y.resultID === mstID && y.modID === modID));

                    /* Coverage table is still on the client, not the datapack server */
                    delete options1.showCoverage;
                    delete options2.showCoverage;

                    /* Don't refetch datapacks if you change the type of display */
                    delete options1.typeOfDisplay;
                    delete options2.typeOfDisplay;
    
                    if (!needDataPacks) {
                        if (JSON.stringify(options1) !== JSON.stringify(options2)) {
                            
                            // let differences = deepDiffMapper.map(options1, options2);
                            // console.log(differences);
                            // debugger;

                            needDataPacks = true;
                        }
                    }
                });

                if (needDataPacks) {
                    this.getDataPacks();
                    fetchedDataPacks = true;
                }
            }
        }

        // // If display coverage changed, fetch.
        // if (!fetchedDataPacks) {
        //     if (this.props.coverageDisplay !== prevProps.coverageDisplay) {
        //         this.getDataPacks();
        //         fetchedDataPacks = true;
        //     }
        // }

        // // If retroYears changed, fetch.
        // if (!fetchedDataPacks) {
        //     if (this.props.retroYears !== prevProps.retroYears) {
        //         this.getDataPacks();
        //         fetchedDataPacks = true;
        //     }
        // }

        // if (!fetchedDataPacks) {
        //     if (this.props.coverageDisplay) {
        //         this.props.selectedResults.forEach(x => {
        //             let mstID = x[0]; 
        //             let modID = x[1]; 
    
        //             if (modID === 15 && mstID === CONSTANTS.CS_MstCoverageInd) {
        //                 if (!prevProps.selectedResults.find(y => y[0] === mstID && y[1] === modID)) {
        //                     needDataPacks = true;
        //                 }
        //             }
        //         });
        //         onSelectedResultsSaved
        //     }
        // }

        // Check to remove any unneeded dataPacks (don't refetch).
        if (!fetchedDataPacks) {
            if (this.props.selectedResults.length < this.state.dataPackResults.length) {
                let newDataPacks = []; 

                this.props.selectedResults.forEach(x => {
                    let mstID = x[0]; 
                    let modID = x[1]; 

                    let dataPack = this.state.dataPackResults.find(y => y.modID === modID && getMstIDFromDataPackResult(y) === mstID);
                    if (dataPack) {
                        newDataPacks.push(dataPack);
                    }
                });

                this.setState({
                    dataPackResults: newDataPacks
                });
            }
        }
    }

    //==================================================================================================================
    //
    //                                                Functions
    //
    //==================================================================================================================

    onStepChange = (idx) => {
        switch(idx) {
            case 0: this.props.onPageChange("CSProjManagerForm"); break;
            case 1: this.props.onPageChange("CSManageInterventionsForm"); break;
            case 2: this.props.onPageChange("CSReviewDefaultDataForm"); break;
            case 3: this.props.onViewResults(false); break;
            default : break;
        }
    };

    onAddButtonClick = () => {
        this.setState({
            AddResultsDrawerOpen : true
        });
    };

    onAddResultsDrawerClose = () => {
        this.setState({
            AddResultsDrawerOpen : false
        });
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    getDataPacks = () => {
        const { selectedResults, chartOptions, chartYears, CSModvars, language } = this.props;

        let params = {
            indicators : selectedResults.map(x => ({
                modID: x[1],
                mstID: getMstIDFromLiSTOnlineResult(x),
                chartOptions : getChartOptionsBasedOnInputs(CSModvars, chartOptions.find(y => ((y.modID === x[1]) &&
                    (y.resultID === x[0]))))
            })),
            chartYears : chartYears,
            language : language,

            /* Note: These are used to debug DIRECTLY the datapack-server */
            // modvars : this.props.CSModvars,
            // IVInfo : this.props.IVInfo,
            // IVGroupInfo : this.props.IVGroupInfo,
            // activeProjections : this.props.activeProjections
        };

        this.props.onGetDataPackResults(params, (response) => {
            this.setState({
                dataPackResults : response.results,
            });
        });
    };    
    
    //------------------------------------------------------------------------------------

    getResultLists = () => {
        const { dataPackResults } = this.state;

        let resultLists = [];
        let resultsByGroup = [];
        let menu = this.props.resultMenus;
        let language = this.props.language;

        const pushToResultsByGroup = (item) => {
            resultsByGroup.push(item);
        };

        if (menu !== null) {
            for (let i = 0; i < menu["TreeCS"].items.length; i++) {
                resultsByGroup = [];
                for (let j = 0; j < menu["TreeCS"].items[i].items.length; j++) {
                    dataPackResults.forEach(x => {
                        if (
                            (x.mstID === menu["TreeCS"].items[i].items[j].indID) &&
                            (x.modID === 15)
                        ) {
                            pushToResultsByGroup([
                                menu["TreeCS"].items[i].Names[language],
                                this.getResultCard(x)
                            ]);
                        }
                    });
                }
                resultLists.push(resultsByGroup);
            }

            resultsByGroup = [];
            for (let i = 0; i < menu["TreeDP"].items.length; i++) {
                for (let j = 0; j < menu["TreeDP"].items[i].items.length; j++) {
                    dataPackResults.forEach(x => {
                        if (
                            (x.mstID === menu["TreeDP"].items[i].items[j].indID) &&
                            (x.modID === 1)
                        ) {
                            pushToResultsByGroup([
                                RS('GB_stDemography'),
                                this.getResultCard(x)
                            ]);
                        }
                    });
                }
            }
            resultLists.push(resultsByGroup);

            resultsByGroup = [];
            for (let i = 0; i < menu["TreeAM"].items.length; i++) {
                for (let j = 0; j < menu["TreeAM"].items[i].items.length; j++) {
                    dataPackResults.forEach(x => {
                        if (
                            (x.mstID === menu["TreeAM"].items[i].items[j].indID) &&
                            (x.modID === 1)
                        ) {
                            pushToResultsByGroup([
                                RS('GB_stHIVAIM'),
                                this.getResultCard(x)
                            ]);
                        }
                    });
                }
            }
            resultLists.push(resultsByGroup);

            for (let i = 0; i < menu["TreeFP"].items.length; i++) {
                resultsByGroup = [];
                for (let j = 0; j < menu["TreeFP"].items[i].items.length; j++) {
                    dataPackResults.forEach(x => {
                        if (
                            (x.mstID === menu["TreeFP"].items[i].items[j].indID) &&
                            (x.modID === 3)
                        ) {
                            pushToResultsByGroup([
                                RS('GB_stFamPlan'),
                                this.getResultCard(x)
                            ]);
                        }
                    });
                }
                resultLists.push(resultsByGroup);
            }
        }

        return resultLists;
    };

    getResultCard = (x) => {
        // Skip the "Display coverage" result for the cards
        if (x.mod === CONSTANTS.GB_CS && x.mstID === CONSTANTS.CS_MstCoverageInd) {
            return null;
        }
        else {
            if (x.data) {
                x = updateDataPackRDec(x);
                x = updateDataPackTable(this.props.CSModvars, x);
                return (
                    <TCSDsCard
                        key={x.modID + "-" + x.chartOptions.resultID}
                        modID={x.modID}
                        resultID={x.chartOptions.resultID}
                        chartType={x.chartOptions["cardChartType"]}
                        cardTitle={getResultName(this.props, x)}
                        packChart={x.data.packChart}
                        packTable={x.data.packTable}
                        onStateChange={this.props.onStateChange}
                        selectedResults={this.props.selectedResults}
                        chartOptions={this.props.chartOptions}
                        firstYear={this.props.firstYear}
                        finalYear={this.props.finalYear}
                        chartYears={this.props.chartYears}
                        stacked={this.props.stacked}
                        legendReversed={this.props.legendReversed}
                        displayEndpoints={this.props.displayEndpoints}
                        displayCoverage={this.props.displayCoverage}
                        retroYears={this.props.retroYears}
                        IVInfo={this.props.IVInfo}
                        IVGroupInfo={this.props.IVGroupInfo}
                        CSModvars={this.props.CSModvars}
                        projectionName={this.props.projectionName}
                        coverageDisplay={this.props.coverageDisplay}
                        language={this.props.language}
                        resultCats={this.props.resultMenus["TreeCS"].items}
                        onOpenHelpDialog={this.props.onOpenHelpDialog}
                        cardTitleBackground={'#9cc025'}
                        chartID={"Chart" + x.chartOptions.resultID}
                        tableID={"Table" + x.chartOptions.resultID}
                        isDeskTop={window.innerWidth > CONSTANTS.CS_ResultsDrawerBreakpoint}
                    />
                );
            }
        }
    };
    
    //------------------------------------------------------------------------------------

    renderSelectedCards_NEW = () => {
        const { groupResults, dataPackResults } = this.state;

        if (groupResults) {
            const resultList = this.getResultLists();
    
            let usedGroups = [];
            let components =
                resultList.map(resultGroup => {
                    return resultGroup.map((x, i) => {
                        let groupName = x[0];
                        if (!usedGroups.includes(groupName)) {
                            usedGroups.push(groupName);
                            return (
                                <React.Fragment key={i}>
                                    <div style={{fontSize: 16, fontWeight: "bold"}}>{x[0]}</div>
                                    <hr style={{margin: "15px 0"}} />
                                    <div style={{display: "inline-block"}}>{x[1]}</div>
                                </React.Fragment>
                            );
                        }
                        else {
                            return (
                                <React.Fragment key={i}>
                                    {x[1]}
                                </React.Fragment>
                            );
                        }
                    }).filter(x => x)
                });
            return components;
        }
        else {
            let selectedCards = [];
            dataPackResults.forEach((x, i) => {
                // Skip the "Display coverage" result for the cards
                if (x.mod === CONSTANTS.GB_CS && x.mstID === CONSTANTS.CS_MstCoverageInd) {
                    // Do nothing
                }
                else {
                    if (x.data) {
                        x = updateDataPackRDec(x);
                        x = updateDataPackTable(this.props.CSModvars, x);
                        selectedCards.push(
                            <TCSDsCard
                                key={i}
                                modID={x.modID}
                                resultID={x.chartOptions.resultID}
                                chartType={x.chartOptions["cardChartType"]}
                                cardTitle={getResultName(this.props, x)}
                                packChart={x.data.packChart}
                                packTable={x.data.packTable}
                                onStateChange={this.props.onStateChange}
                                selectedResults={this.props.selectedResults}
                                chartOptions={this.props.chartOptions}
                                firstYear={this.props.firstYear}
                                finalYear={this.props.finalYear}
                                chartYears={this.props.chartYears}
                                stacked={this.props.stacked}
                                legendReversed={this.props.legendReversed}
                                displayEndpoints={this.props.displayEndpoints}
                                displayCoverage={this.props.displayCoverage}
                                retroYears={this.props.retroYears}
                                IVInfo={this.props.IVInfo}
                                IVGroupInfo={this.props.IVGroupInfo}
                                CSModvars={this.props.CSModvars}
                                projectionName={this.props.projectionName}
                                coverageDisplay={this.props.coverageDisplay}
                                language={this.props.language}
                                resultCats={this.props.resultMenus["TreeCS"].items}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                                cardTitleBackground={'#9cc025'}
                                chartID={"Chart" + i + 5}
                                tableID={"Table" + i + 5}
                                isDeskTop={window.innerWidth > CONSTANTS.CS_ResultsDrawerBreakpoint}
                            />
                        );
                    }
                }
            });

            return (selectedCards);
        }
    };

    onToggleGroupRusults = () => {
        this.setState(prevState => ({
            groupResults : !prevState.groupResults
        }));
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        let resultMenus = JSON.parse(JSON.stringify(this.props.resultMenus));
        let selectedResults = JSON.parse(JSON.stringify(this.props.selectedResults));

        let TreeCSItems = resultMenus.TreeCS.items;

        if (this.props.approach === CONSTANTS.CS_NutritionApproach) {
            resultMenus.TreeDP.items = [];
            resultMenus.TreeCS.items = [TreeCSItems[1], TreeCSItems[4], TreeCSItems[5]];
            resultMenus.TreeCS.items[0].items = [TreeCSItems[0].items[0], TreeCSItems[0].items[1], TreeCSItems[0].items[4]];
            resultMenus.TreeCS.items[1].items = [TreeCSItems[1].items[2]]
            //selectedResults = [];
        }

        return (
            <div className="innerPage">
                <LiSTProjectionStepper
                    onStepChange={this.onStepChange}
                />

                <div style={styles.content}>
                    <ManageResultsButton
                        onClick={this.onAddButtonClick}
                        style={{padding: 0}}
                    />

                    <TSwitch
                        caption="Group results"
                        value={this.state.groupResults}
                        onClick={this.onToggleGroupRusults}
                        style={{marginLeft: 35, display: "inline-block"}}
                    />

                    {
                        this.state.AddResultsDrawerOpen ?
                            <ManageResultsDrawer
                                onPageChange           = {this.props.onPageChange}
                                onStateChange          = {this.props.onStateChange}
                                selectedResults        = {selectedResults}
                                includeFamPlan         = {this.props.includeFamPlan}
                                resultMenus            = {resultMenus}
                                style                  = {{ margin: 3, position: 'absolute', right: 15, top: 65 }}
                                availableResults       = {this.props.availableResults}
                                onClose                = {this.onAddResultsDrawerClose}
                                onOpenHelpDialog       = {this.props.onOpenHelpDialog}
                                language               = {this.props.language}
                                approach               = {this.props.approach}
                            /> :
                            null
                    }
                    
                    <div style={styles.cards}>
                        {this.renderSelectedCards_NEW()}
                    </div>
                </div>
            </div>
        );
    }
}

export default CSViewResultsFm;