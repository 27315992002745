import { fieldNamePrefix } from "../other/constants";
import { formatNumber } from "../other/utilities";
import { createDefaultPackTable } from "./defaultPackTable";
import getGroupedColumnDefinitions from "./groupedColumns";
import synchronizeColumnWidths from "./synchronizeColumnWidths";
import TextMetrics from "./textMetrics";

// Note: 22 works fine on chrome, but not firefox.
const textPadding = 26; //22 //6

const textMetrics = new TextMetrics();

const ConvertDelphiColorToHex = color => {
    if (color >= 0) {
        let hexString = color.toString(16).padStart(6, "0");

        let R = hexString.slice(-2);
        let G = hexString.slice(-4, -2);
        let B = hexString.slice(-6, -4);

        return "#" + (R + G + B);
    } else {
        return "";
    }
};

const getTextWidth = (text, font, bold = true) => {
    font = `${bold ? "bold " : ""}${font}`;

    const width = textMetrics.getMetrics(text, font).width;

    return width + textPadding;
};

const getDimensions = packTable => ({
    columnCount: packTable.GBColCount,
    rowCount: packTable.GBRowCount
});

const getValues = packTable => (packTable.tableData && packTable.tableData.value) || [];

const getRowFormats = packTable => {
    const { rowCount } = getDimensions(packTable);

    const rowFormats = [];

    for (let i = 0; i < rowCount; i++) {
        rowFormats.push({
            ids: packTable.RowIds[i],
            indentation: packTable.IndentRowText[i].Value ? packTable.IndentRowText[i].xoffset : 0,
            subheading: packTable.RowSubHeadings[i],
            height: packTable.GBRowHeights && packTable.GBRowHeights[i]
        });
    }

    return rowFormats;
};

const getData = packTable => {
    const values = getValues(packTable).slice(packTable.GBFixedRows);

    return values.map(datum =>
        datum.reduce((result, value, index) => {
            result[`${fieldNamePrefix}${index + 1}`] = value;

            return result;
        }, {})
    );
};

const getMergedCellDefinitions = packTable =>
    packTable.MergedCells.map(mergedCell => ({
        startColumn: mergedCell.startCol,
        startRow: mergedCell.startRow,
        columnCount: mergedCell.numCols,
        rowCount: mergedCell.numRows
    })).filter(mergedCellDefinition => mergedCellDefinition.columnCount > 0 && mergedCellDefinition.rowCount > 0);

const getCellFormats = packTable => {
    const { columnCount, rowCount } = getDimensions(packTable);

    const cellFormats = [];

    for (let r = 0; r < rowCount; r++) {
        const item = {};

        for (let c = 0; c < columnCount; c++) {
            let alignment;

            switch (packTable.Alignments[r][c]) {
                case 1:
                    alignment = "right";

                    break;

                case 2:
                    alignment = "center";

                    break;

                default:
                    alignment = "left";

                    break;
            }

            let verticalAlignment = "middle";

            if (packTable.vAlignments) {
                switch (packTable.vAlignments[r][c]) {
                    case 0:
                        verticalAlignment = "top";

                        break;

                    case 1:
                        verticalAlignment = "bottom";

                        break;

                    default:
                        verticalAlignment = "middle";

                        break;
                }
            }

            let fontStyles;

            if (typeof packTable.FontStyles[r][c].intArray !== "undefined") {
                fontStyles = packTable.FontStyles[r][c].intArray
                    .map(fontStyle => {
                        switch (fontStyle) {
                            case 0:
                                return "bold";

                            case 1:
                                return "italic";

                            case 2:
                                return "underline";

                            default:
                                return "";
                        }
                    })
                    .filter(fontStyle => fontStyle);
            } else {
                fontStyles = packTable.FontStyles[r][c]
                    .map(fontStyle => {
                        switch (fontStyle) {
                            case 0:
                                return "bold";

                            case 1:
                                return "italic";

                            case 2:
                                return "underline";

                            default:
                                return "";
                        }
                    })
                    .filter(fontStyle => fontStyle);
            }

            item[`${fieldNamePrefix}${c + 1}`] = {
                minimumValue: packTable.MinAllowedVal[r][c],
                maximumValue: packTable.MaxAllowedVal[r][c],
                decimalPlaces: packTable.RDec[r][c],
                alignment,
                verticalAlignment,
                hasCheckbox: packTable.HasCheckBox[r][c],
                checkboxState: packTable.HasCheckBox[r][c] ? packTable.CheckBoxState[r][c] : undefined,
                component: packTable.components && packTable.components[r][c],
                fontStyles: fontStyles,
                fontColor: ConvertDelphiColorToHex(packTable.FontColors[r][c]) || "black",
                BGColor:
                    packTable.BGColors[r][c] === 536870911 || packTable.BGColors[r][c] === 0
                        ? "transparent"
                        : ConvertDelphiColorToHex(packTable.BGColors[r][c]),
                comment: packTable.GBCellComment[r][c],
                showCommentIndicator: packTable.GBUseTriangle[r][c],
                locked: c < packTable.GBFixedCols || packTable.LockedCells[r][c]
            };
        }

        cellFormats.push(item);
    }

    return cellFormats;
};

// const getSources = packTable => {
//     switch (packTable.SourceMode) {
//         case 0:
//             return [packTable.Source[0]];
//
//         default:
//             return packTable.Source.map((source, index) => ({
//                 index: packTable.SourceMap[index],
//                 source: source
//             })).filter(source => source.source);
//     }
// };

const findIndicesOfRowsContainingMergedCells = normalizedPackTable => {
    const indicesOfRowsWithMergedCells = new Set();

    for (let mergedCellDefinition of normalizedPackTable.mergedDataCellDefinitions) {
        Array.from(
            { length: mergedCellDefinition.rowCount },
            (_, index) => mergedCellDefinition.startRow + index
        ).forEach(rowIndex => {
            indicesOfRowsWithMergedCells.add(rowIndex);
        });
    }

    return indicesOfRowsWithMergedCells;
};

const assignLeafCount = columnDefinition => {
    if (!columnDefinition.children) {
        return {
            ...columnDefinition,
            leafCount: 1
        };
    }

    const children = columnDefinition.children.map(child => assignLeafCount(child));

    return {
        ...columnDefinition,
        children: children,
        leafCount: children.reduce((result, child) => result + child.leafCount, 0)
    };
};

const findMaximumHeaderNameWidth = (columnDefinition, font, maximumWidth) => {
    if (columnDefinition.pinned === "left") {
        return maximumWidth;
    }

    const headerNameWidth = getTextWidth(columnDefinition.headerName, font);

    if (!columnDefinition.children) {
        return Math.max(maximumWidth, headerNameWidth);
    }

    const childHeaderNameWidths = columnDefinition.children.map(child =>
        findMaximumHeaderNameWidth(child, font, maximumWidth)
    );

    return Math.max(headerNameWidth, maximumWidth, ...childHeaderNameWidths);
};

const findMaximumNonNumericCellTextWidth = (normalizedPackTable, font) => {
    let maximumWidth = 0;

    for (const row of normalizedPackTable.data.slice(normalizedPackTable.headerRowCount)) {
        for (const value of Object.values(row).slice(normalizedPackTable.fixedColumnFields.length)) {
            if (isNaN(value)) {
                const width = getTextWidth(value, font);

                maximumWidth = Math.max(maximumWidth, width);
            }
        }
    }

    return maximumWidth;
};

const findMaximumCellTextWidth = (normalizedPackTable, font, showThousandsSeparator) => {
    const columnDefinition = assignLeafCount({
        headerName: "",
        children: normalizedPackTable.columnDefinitions
    });

    let maximumCellTextWidth = findMaximumHeaderNameWidth(columnDefinition, font, -1) + textPadding;

    for (let cellFormatRow of normalizedPackTable.cellFormats) {
        for (let cellFormat of Object.values(cellFormatRow)) {
            const maximumValue = formatNumber(
                cellFormat.maximumValue,
                cellFormat.decimalPlaces,
                showThousandsSeparator
            );

            maximumCellTextWidth = Math.max(maximumCellTextWidth, getTextWidth(maximumValue, font));
        }
    }

    return maximumCellTextWidth;
};

// const calculatePinnedColumnWidth = (columnDefinition, packTable, font) => {
//   let width = columnDefinition.width;

//   if (columnDefinition.pinned !== "left" || width) {
//     return width;
//   }

//   width = getTextWidth(columnDefinition.headerName, font);

//   if (!columnDefinition.field) {
//     return width;
//   }

//   const data = packTable.data;

//   return data.reduce((result, row) => {
//     const cellWidth = getTextWidth(row[columnDefinition.field], font);

//     return Math.max(result, cellWidth);
//   }, width);
// };

// const assignPinnedColumnWidths = (columnDefinitions, packTable, font) => {
//   const newColumnDefinitions = [];

//   for (let columnDefinition of columnDefinitions) {
//     const width = calculatePinnedColumnWidth(columnDefinition, packTable, font);

//     const children =
//       columnDefinition.children &&
//       assignPinnedColumnWidths(columnDefinition.children, packTable, font);

//     newColumnDefinitions.push({
//       ...columnDefinition,
//       width: width,
//       children: children
//     });
//   }

//   return newColumnDefinitions.length ? newColumnDefinitions : undefined;
// };

const assignColumnWidths = (columnDefinition, maximumCellTextWidth) => {
    if (columnDefinition.pinned !== "left" && !columnDefinition.children) {
        const width = columnDefinition.width || maximumCellTextWidth;

        return {
            ...columnDefinition,
            width
        };
    }

    const children =
        columnDefinition.children &&
        columnDefinition.children.map(child => assignColumnWidths(child, maximumCellTextWidth));

    return {
        ...columnDefinition,
        children
    };
};

const setUninitializedHeaderColumnWidths = (columnDefinitions, font, maximumCellTextWidth) => {
    for (let columnDefinition of columnDefinitions) {
        if (columnDefinition.pinned !== "left" && typeof width === "undefined") {
            const headerTextWidth = getTextWidth(columnDefinition.headerName, font);

            columnDefinition.width = Math.max(headerTextWidth, maximumCellTextWidth);
        }

        if (columnDefinition.children) {
            setUninitializedHeaderColumnWidths(columnDefinition.children, font, maximumCellTextWidth);
        }
    }
};

const setFixedColumnWidths = (columnDefinition, packTable, font) => {
    if (!columnDefinition.children) {
        if (columnDefinition.pinned === "left") {
            let columnWidth = columnDefinition.originalWidth;

            if (columnWidth !== 0) {
                columnWidth = columnWidth || -1;
            }

            if (columnWidth < 0) {
                columnWidth = packTable.data.reduce((result, row, index) => {
                    //   const cellValue = row[columnDefinition.field] || "";
                    const cellWidth = getTextWidth(row[columnDefinition.field] || "", font);

                    return Math.max(
                        result,
                        packTable.rowFormats[index + +packTable.headerRowCount].containsMergedCell ? 0 : cellWidth
                    );
                }, getTextWidth(columnDefinition.headerName, font));
            }

            columnDefinition.width = columnWidth;
        }

        return;
    }

    columnDefinition.children.forEach(child => {
        setFixedColumnWidths(child, packTable, font);
    });
};

const calculateTableWidth = columnDefinition => {
    if (!columnDefinition.children) {
        return columnDefinition.width;
    }

    return columnDefinition.children.reduce((result, child) => result + calculateTableWidth(child), 0);
};

export const normalizePackTable = (packTable, frameworkComponents, font, showThousandsSeparator = true) => {
    const defaultPackTable = createDefaultPackTable(
        packTable.tableData,
        packTable.GBFixedRows || 0,
        packTable.GBFixedCols || 0,
        packTable.GBRowCount,
        packTable.GBColCount,
        packTable.TotalRow === 1,
        packTable.TotalCol === 1
    );

    packTable = {
        ...defaultPackTable,
        ...packTable
    };

    packTable.GBColWidths = packTable.GBColWidths || Array(packTable.GBColCount).fill(-1);

    packTable = {
        ...packTable,
        components:
            packTable.components &&
            frameworkComponents &&
            packTable.components.map(row => row.map(component => frameworkComponents[component] && component))
    };

    let mergedCellDefinitions = getMergedCellDefinitions(packTable);

    const groupedColumnDefinitions = getGroupedColumnDefinitions(
        packTable,
        getValues(packTable).slice(0, packTable.GBFixedRows),
        mergedCellDefinitions
    );

    let normalizedPackTable = {
        masterId: packTable.editorMstID,
        projectionId: packTable.Proj,
        moduleId: packTable.ModID,
        title: packTable.Title,
        columnDefinitions: groupedColumnDefinitions,
        columnCount: packTable.GBColCount,
        headerRowCount: packTable.GBFixedRows,
        fixedColumnFields: Array.from(Array(packTable.GBFixedCols), (_, x) => `${fieldNamePrefix}${x + 1}`),
        data: getData(packTable),
        components: packTable.components,
        rowFormats: getRowFormats(packTable),
        cellFormats: getCellFormats(packTable),
        mergedDataCellDefinitions: getMergedCellDefinitions(packTable).filter(
            mergedCellDefinition => mergedCellDefinition.startRow >= packTable.GBFixedRows
        ),
        totalColumn: packTable.TotalCol > 0,
        totalRow: packTable.TotalRow > 0,
        sourceMode: packTable.SourceMode,
        sourceMap: packTable.SourceMap,
        sources: packTable.Source, //getSources(packTable)
        sourceTitles: packTable.SourceTitle //getSources(packTable)
    };

    const indicesOfRowsContainingMergedCells = findIndicesOfRowsContainingMergedCells(normalizedPackTable);

    normalizedPackTable.rowFormats = normalizedPackTable.rowFormats.map((rowFormat, index) => ({
        ...rowFormat,
        containsMergedCell: indicesOfRowsContainingMergedCells.has(index)
    }));

    let maximumCellTextWidth = findMaximumCellTextWidth(normalizedPackTable, font, showThousandsSeparator);

    const maximumNonNumericCellTextWidth = findMaximumNonNumericCellTextWidth(normalizedPackTable, font);

    maximumCellTextWidth = Math.max(maximumCellTextWidth, maximumNonNumericCellTextWidth);

    let sizedColumnDefinitions = assignColumnWidths(
        {
            children: normalizedPackTable.columnDefinitions
        },
        maximumCellTextWidth
    ).children;

    setFixedColumnWidths(
        {
            children: sizedColumnDefinitions
        },
        normalizedPackTable,
        font
    );

    setUninitializedHeaderColumnWidths(sizedColumnDefinitions, font, maximumCellTextWidth);

    sizedColumnDefinitions = synchronizeColumnWidths(sizedColumnDefinitions);

    normalizedPackTable = {
        ...normalizedPackTable,
        columnDefinitions: sizedColumnDefinitions,
        tableWidth: calculateTableWidth({
            children: sizedColumnDefinitions
        }),
        maximumCellTextWidth: maximumCellTextWidth
    };

    normalizedPackTable = {
        ...normalizedPackTable,
        data: normalizedPackTable.data.map((row, index) => {
            if (!normalizedPackTable.rowFormats[normalizedPackTable.headerRowCount + index].containsMergedCell) {
                return row;
            }

            return Object.keys(row).reduce((result, key) => {
                result[key] = key === "field1" ? row[key] : "";

                return result;
            }, {});
        })
    };

    return normalizedPackTable;
};
