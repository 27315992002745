import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    indentPackTable,
    lockPackRowCol,
    addMergedCellsToArray,
    generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    getAgeCohortName,
    ImpactsOnWastingStr
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const ImpactsOnWastingEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stImpactsWasting');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = ImpactsOnWastingEditor.RecalcGrid(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    RecalcGrid : (pt) => {
          for (let col = 3; col < pt.GBColCount; col++) {
              let sum = 0;
              for (let row = 0; row < pt.GBRowCount; row++) {
                  if ([CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat,
                       CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovModWastWithTreat,
                       CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovSevWastWithTreat].includes(pt.RowIds[row])) {
                      sum += pt.tableData.value[row][col];
                  }
              }
              for (let row = 0; row < pt.GBRowCount; row++) {
                  if (pt.RowIds[row] === -1){
                      pt.tableData.value[row][col] = sum;
                  }
              }
          }
          return (pt);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let ImpactsOnWasting = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnWasting_MV4);
        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
            for(let row = 2; row < pt.GBRowCount; row++){
                let i = pt.RowIds[row];
                if (i > 0) {
                    if (!pt.lockCells[row].includes(col)) {
                        ImpactsOnWasting["value"][CONSTANTS.CS_Data][i][CONSTANTS.CS_Efficacy][a] = pt.tableData.value[row][col];
                    }
                    if ([CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat,
                        CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovModWastWithTreat,
                        CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovSevWastWithTreat].includes(i)){
                        ImpactsOnWasting["source"][0][CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat] = pt.Source[pt.SourceMap[row]];
                    }
                    else{
                        ImpactsOnWasting["source"][0][i] = pt.Source[pt.SourceMap[row]];
                    }
                }
            }
            col++;
        }

        changedModvars.push(ImpactsOnWasting);

        return (changedModvars);
    },
};

const Setup = () => {
    const numCols = 6;
    const numRows = 14;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        pt.tableData.value[0][col] = getAgeCohortName(a, true);
        pt.tableData.value[1][col] = RS('GB_stEffectiveness');
        col++;
    }

    let HeaderSet = [2, 4, 9];

    pt.lockCells = [
        [],
        [],
        [1, 2, 3, 4, 5],
        [1, 2],
        [1, 2, 3, 4, 5],
        [1, 2],
        [1, 2],
        [1, 2],
        [1, 2, 3, 4, 5],
        [1, 2, 3, 4, 5],
        [],
        [],
        [],
        []
    ];

    let normalRows = [
        0,
        0,
        0,
        CONSTANTS.CS_ImpactsOnWaste_Therafeed,
        0,
        CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat,
        CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovModWastWithTreat,
        CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovSevWastWithTreat,
        -1,
        0,
        CONSTANTS.CS_FoodSecureWPromo,
        CONSTANTS.CS_FoodSecureWOPromo,
        CONSTANTS.CS_InsecureWPromoSupp,
        CONSTANTS.CS_InsecureWOPromoSupp
    ];

    pt.numSources = 0;
    let isTreatModAcuteMalNut = false;

    for(let row = 2; row < normalRows.length; row++){
        if (HeaderSet.includes(row)){
            isTreatModAcuteMalNut = false;
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, 6);
            pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
            switch (row){
                case 2  : {pt.tableData.value[row][0] = RS('GB_stTreatSevereAcuteMal'); break;}
                case 4  : {
                    pt.tableData.value[row][0] = RS('GB_stTreatModAcuteMal');
                    pt.numSources++;
                    pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
                    isTreatModAcuteMalNut = true;
                    break;
                }
                case 9  : {pt.tableData.value[row][0] = RS('GB_stImpactCompFeedWasting'); break;}
                default : break;
            }
        }
        else {
            let i = normalRows[row];
            if (i !== -1) {
                pt.tableData.value[row][0] = ImpactsOnWastingStr(i);
                if (!isTreatModAcuteMalNut){
                    pt.numSources++;
                    pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
                }
                pt.SourceMap[row] = pt.numSources;
            }
            else{
                pt.tableData.value[row][0] = RS('GB_stTotal');
            }
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            let col = 1;
            for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 3;
                pt.MaxAllowedVal[row][col] = 1;
                if (pt.lockCells[row].includes(col)) {
                    pt = lockPackRowCol(pt, row, col, true);
                }
                col++;
            }
            pt.RowIds[row] = i;
        }
    }

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let ImpactsOnWasting = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnWasting_MV4)["value"][
        CONSTANTS.CS_Data];
    let ImpactsOnWastingSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnWasting_MV4)["source"];
    let col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        for(let row = 2; row < pt.GBRowCount; row++){
            let i = pt.RowIds[row];
            if (i > 0) {
                pt.tableData.value[row][col] = ImpactsOnWasting[i][CONSTANTS.CS_Efficacy][a];
                if ([CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat,
                    CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovModWastWithTreat,
                    CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovSevWastWithTreat].includes(i)){
                    pt.Source[pt.SourceMap[row]] = ImpactsOnWastingSrc[0][CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat];
                }
                else{
                    pt.Source[pt.SourceMap[row]] = ImpactsOnWastingSrc[0][i];
                }
                if (pt.lockCells[row].includes(col)){
                    pt.tableData.value[row][col] = '';
                }
            }
        }
        col++;
    }

    return pt;
};

