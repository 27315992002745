import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import IconButton from "@material-ui/core/IconButton";
import TAvatarDropdown from '@common/components/TAvatarDropdown';

const DialogTitleCustom = (props) => {

    const { title, onClose, minWidth, titleStyle } = props;

    const styles = {
        dialogTitle : {
            minWidth: minWidth,
            maxWidth: '800px',
            borderBottom: '1px solid lightgray',
            userSelect: 'none',
            padding: '10px 24px',
            minHeight: '57px',
        },
        title : {
            fontSize: '20px',
            color: '#cb6814',
            fontWeight: 'bold',
            padding: '5px 50px 0 0px',
        },
        closeIcon : {
            height: '30px',
            width: '30px',
            // cursor: 'pointer',
            // position: 'absolute',
            // top: '14px',
            // right: '20px',
        },
        iconBtn: {
            // height: '30px',
            // width: '30px',
            cursor: 'pointer',
            position: 'absolute',
            top: '4px',
            right: '20px',
            color: '#cb6814'
        }
    };

    return (
        <DialogTitle id="alert-dialog-title" style={{...styles.dialogTitle, ...titleStyle}}>
            <div style={styles.title}>
                {title}
            </div>
            { 
                (onClose) ?
                    <IconButton onClick={onClose} style={styles.iconBtn}>
                        <CloseIcon
                            // onClick={onClose}
                            style={styles.closeIcon}
                        />
                    </IconButton>
                    :
                    null
            }
        </DialogTitle>
    );
};

const DialogContentCustom = (props) => {

    const { content, iconType, minWidth, maxWidth = 500 } = props;
    
    const styles = {
        content : {
            margin: '10px 0',
            padding: '0 22px',
            minWidth: minWidth,
            maxWidth: maxWidth,//'800px',
            minHeight: '50px',
        },
        icon : {
            marginRight: '20px', 
            width: '40px', 
            height: '40px', 
            float: 'left', 
            color: '#C03600'
        },
    };

    let icon;
    switch (iconType) {
        case "Warning" : icon = <WarningIcon style={styles.icon} />; break;
        case "Error"   : icon = <ErrorIcon style={styles.icon} />; break;
        case "Avatar"  : icon = <TAvatarDropdown style={styles.icon} />; break;
        default        : icon = undefined;
    }

    return (
        <DialogContent style={{...styles.content, ...props.contentStyle}}>
            {icon}
            {content}
        </DialogContent>
    );
};

const DialogActionsCustom = (props) => {

    const { actions, minWidth } = props;

    if (typeof actions === 'undefined') {
        return null;
    }

    const styles = {
        actions : {
            // borderTop : '1px solid lightgray',
            minHeight: '30px',
            userSelect: 'none',
            // padding: '10px 24px',
            padding: '10px 24px 15px 24px',
            margin: 0,
            minWidth: minWidth,
            maxWidth: '800px',
        },
    };

    return (
        <DialogActions style={{...styles.actions, ...props.actionStyle}}>
            {actions}
        </DialogActions>
    );
};

const CSDialog = (props) => {

    const { open = true, title, content, actions, onClose, modal = false, iconType, maxWidth, minWidth = 400, style={}, titleStyle, contentStyle, actionStyle } = props;

    // const styles = {
    //     wrapper : {
    //         minWidth: '400px',
    //         maxWidth: '800px',
    //         border: '1px solid #CCC',
    //         borderRadius: '2px',
    //         margin: '50px 20px',
    //         position: 'relative',
    //         background: 'white',
    //     }
    // };

    const styles = {
        dialog: {
            minWidth: minWidth,
            maxWidth: 650,
            ...style
        }
    };

    const onBackdropClick = () => {
        if (!modal) {
            onClose('mrClose');
        }
    };

    return (
        // <div style={styles.wrapper}
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            onBackdropClick={onBackdropClick}
            // maxWidth="md"
            PaperProps={{
                style: styles.dialog
            }}
        >
            <DialogTitleCustom title={title} onClose={onClose} minWidth={minWidth} titleStyle={titleStyle} />
            <DialogContentCustom content={content} iconType={iconType} minWidth={minWidth} maxWidth={maxWidth} contentStyle={contentStyle} />
            <DialogActionsCustom actions={actions} minWidth={minWidth} actionStyle={actionStyle} />
        </Dialog>
        // </div>
    );
};

export default CSDialog;