import React from 'react';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSMaternalMortalityBlueBox from '../editors/CS/cards/HealthStatus/CSMaternalMortalityBlueBox';
import CSChildMortalityBlueBox from '../editors/CS/cards/HealthStatus/CSChildMortalityBlueBox';
import CSHouseholdStatusBlueBox from '../editors/CS/cards/HealthStatus/CSHouseholdStatusBlueBox';
import CSMaternalHealthBlueBox from '../editors/CS/cards/HealthStatus/CSMaternalHealthBlueBox';
import CSChildHealthBlueBox from '../editors/CS/cards/HealthStatus/CSChildHealthBlueBox';
import CSNutStatusDistBlueBox from '../editors/CS/cards/HealthStatus/CSNutStatusDistBlueBox';
import CSAbortionBlueBox from '../editors/CS/cards/HealthStatus/CSAbortionBlueBox';
import CSEconStatusBlueBox from '../editors/CS/cards/HealthStatus/CSEconStatusBlueBox';
import CSStillbirthsBlueBox from '../editors/CS/cards/HealthStatus/CSStillbirthsBlueBox';
import CSPathogensBlueBox from '../editors/CS/cards/HealthStatus/CSPathogensBlueBox';
import CSEffectivenessBlueBox from '../editors/CS/cards/HealthStatus/CSEffectivenessBlueBox';
import DemographyBlueBox from '../editors/CS/cards/HealthStatus/DemographyBlueBox';
import CSStepper from "../components/CS/CSStepper";
import CSAdolescentsBlueBox from "../editors/CS/cards/HealthStatus/CSAdolescentsBlueBox";

class CSReviewDefaultDataForm extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onPageChange            : PropTypes.func,
        onStateChange           : PropTypes.func,
        onViewResults           : PropTypes.func,
        onOpenHelpDialog        : PropTypes.func,
        selectedCountry         : PropTypes.string,
        IVInfo                  : PropTypes.arrayOf(PropTypes.object),
        useFamPlanOutputs       : PropTypes.bool,
        CSModvars               : PropTypes.arrayOf(PropTypes.object),
        firstYear               : PropTypes.number,
        language                : PropTypes.number,
        approach                : PropTypes.number,
        showAllInterventionsCB  : PropTypes.bool
    };
s
    static defaultProps = {
        onPageChange            : (value) => console.log('onPageChange ' + value),
        onStateChange           : () => console.log('onStateChange'),
        onViewResults           : () => console.log('onViewResults'),
        onOpenHelpDialog        : () => console.log('onOpenHelpDialog'),
        selectedCountry         : '',
        useFamPlanOutputs       : false,
        IVInfo                  : [],
        CSModvars               : [],
        firstYear               : -1,
        language                : CONSTANTS.GB_English,
        approach                : CONSTANTS.CS_StandardApproach,
        showAllInterventionsCB  : false,
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBackBtnClick = () => {
        this.props.onPageChange("CSManageInterventionsForm");
    };

    onCalculateAndViewResultsClick = () => {
        this.props.onViewResults(false);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    renderBackBtn = () => {

        const styles = {
            nextButton: {
                fontSize: '16px',
                marginTop: '150px',
                marginRight: '25px'
            }
        };

        return (
            <TButton
                caption={RS('GB_stBack')}
                color="secondary"
                style={styles.nextButton}
                onClick={this.onBackBtnClick}
            />
        );
    };

    renderCalculateAndViewResultsBtn = () => {

        const styles = {
            nextButton: {
                fontSize: '16px',
                marginTop: '150px',
                marginRight: '25px'
            }
        };

        return (
            <TButton
                caption={RS('GB_stCalculateAndView')}
                color="primary"
                style={styles.nextButton}
                onClick={this.onCalculateAndViewResultsClick}
            />
        );
    };

    onStepChange = (idx) => {
        switch(idx) {
            case 0: this.props.onPageChange("CSProjManagerForm"); break;
            case 1: this.props.onPageChange("CSManageInterventionsForm"); break;
            case 2: this.props.onPageChange("CSReviewDefaultDataForm"); break;
            case 3: this.props.onViewResults(false); break;
            default : break;
        }
    };

    render() {

        const styles = {
            paragraph1 : {
                // paddingLeft: 25,
                // paddingTop: 25,
                fontSize: 16,
                userSelect: 'none',
            },
            paragraph2 : {
                // paddingLeft: 25,
                fontSize: 16,
                margin: '0 0 25px 0',
                userSelect: 'none',
            },
            outsidePaper : {
                // width: 600,
                // height: 390,
                // background: 'green',
                // margin: '0 auto',
                boxShadow: 'none'
            },
            paper : {
                width: 625,
                height: 430,
                // background: 'green',
                // margin: '0 auto',
                boxShadow: 'none'
            },
            captionStyle : {
                height: '110px',
                width: '205px',
                display: 'table-cell',
                verticalAlign: 'middle',
                margin: '0 auto',
                textAlign: 'center',
            },
            nextButton: {
                fontSize: '16px',
                marginTop: '30px',
                marginRight: '25px'
            },
            content : {
                margin: '0 10px'
            }
        };

        return (
            <div className="innerPage">

                <CSStepper 
                    steps={[RS('GB_stModProjection'), RS('GB_stManageInterventions'), RS('GB_stReviewDefaultData'), RS('GB_stViewResults')]}
                    activeStep={2}
                    completedSteps={[]}
                    onStepBtnClick={this.onStepChange}
                    useAltColors={true}
                />

                <div style={styles.content}>
                    {/* <h2 className="pageHeader" style={{color: '#cb6814', marginTop:15}}>
                        {RS('GB_stReviewDefaultData')}
                    </h2> */}

                    <Paper style={styles.outsidePaper}>

                        <p style={styles.paragraph1}>
                            {RS('GB_stHereIsDefaultHealthStatus')}&nbsp; { this.props.selectedCountry }.&nbsp;
                            {RS('GB_stReviewOrEditData')}
                        </p>

                        <p style={styles.paragraph2}>
                            {RS('GB_stDataReview')}
                        </p>

                        <Paper style={styles.paper}>

                            <CSAbortionBlueBox
                                onStateChange={this.props.onStateChange}
                                useFamPlanOutputs={this.props.useFamPlanOutputs}
                                CSModvars={this.props.CSModvars}
                                firstYear={this.props.firstYear}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            {
                                (window.location.hostname === "localhost" || window.location.hostname === "beta.avstaging.org") &&
                                    <CSAdolescentsBlueBox
                                        //
                                    />
                            }

                            <CSChildHealthBlueBox
                                onStateChange={this.props.onStateChange}
                                IVInfo={this.props.IVInfo}
                                CSModvars={this.props.CSModvars}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSChildMortalityBlueBox
                                onStateChange={this.props.onStateChange}
                                CSModvars={this.props.CSModvars}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <DemographyBlueBox
                                onStateChange={this.props.onStateChange}
                                CSModvars={this.props.CSModvars}
                                firstYear={this.props.firstYear}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSEconStatusBlueBox
                                onStateChange={this.props.onStateChange}
                                CSModvars={this.props.CSModvars}
                                firstYear={this.props.firstYear}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSEffectivenessBlueBox
                                onStateChange={this.props.onStateChange}
                                IVInfo={this.props.IVInfo}
                                CSModvars={this.props.CSModvars}
                                language={this.props.language}
                                firstYear={this.props.firstYear}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSHouseholdStatusBlueBox
                                onStateChange={this.props.onStateChange}
                                CSModvars={this.props.CSModvars}
                                firstYear={this.props.firstYear}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSMaternalHealthBlueBox
                                onStateChange={this.props.onStateChange}
                                IVInfo={this.props.IVInfo}
                                CSModvars={this.props.CSModvars}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSMaternalMortalityBlueBox
                                onStateChange={this.props.onStateChange}
                                CSModvars={this.props.CSModvars}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSNutStatusDistBlueBox
                                firstYear={this.props.firstYear}
                                CSModvars={this.props.CSModvars}
                                onStateChange={this.props.onStateChange}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />

                            <CSPathogensBlueBox
                                onStateChange={this.props.onStateChange}
                                IVInfo={this.props.IVInfo}
                                CSModvars={this.props.CSModvars}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                                showAllInterventionsCB={this.props.showAllInterventionsCB}
                            />

                            <CSStillbirthsBlueBox
                                onStateChange={this.props.onStateChange}
                                CSModvars={this.props.CSModvars}
                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                            />
                        </Paper>
                    </Paper>

                    {this.renderBackBtn()}

                    {this.renderCalculateAndViewResultsBtn()}
                </div>
            </div>
        );
    }

}

export default CSReviewDefaultDataForm;