import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

let interventionCostData = [];
let MOTopInterventions = [];
let packChart = null;
let packTable = null;

export const generateDataPack = (countryName, countryData, options) => {

    let selectedSortedData = [];

    if (options['MOResultToDisplay'] !== CONSTANTS.CS_PotentialStuntingAverted) {
        selectedSortedData = getDeathsAvertedMO(countryData, options);
        interventionCostData = getInterventionCostData(selectedSortedData);
        MOTopInterventions = getMOTopInterventions(selectedSortedData);
        packChart = getDeathsAvertedPackChart(countryName, options, selectedSortedData, interventionCostData);
        packTable = getDeathsAvertedPackTable(packChart, options);
    } else {
        selectedSortedData = getStuntingMO(countryData, options);
        MOTopInterventions = getStuntingMOTopInterventions(selectedSortedData);
        packChart = getStuntingPackChart(countryName, options, selectedSortedData);
        packTable = getStuntingPackTable(packChart);
    }

    return (
        [packChart, packTable, MOTopInterventions]
    )
};

const getDeathsAvertedMO = (countryData, options) => {

    let selectedDeliveryPointsData_Step1 = [];
    let selectedOutcomesData_Step2 = [];
    let selectedCPR_Step3 = [];
    let selectedCOD_Step4 = [];
    let delivPointIdx = 0;
    let stillbirthIdx = 0;
    let maternalIdx = 0;
    let neonatalIdx = 0;
    let childIdx = 0;
    let adolescentIdx = 0;
    let outcomeSelected = false;

    let sbSelected = options['displayStillbirthCOD'];
    let matSelected = options['displayMaternalCOD'];
    let nnSelected = options['displayNeonatalCOD'];
    let childSelected = options['displayChildCOD'];
    let adolSelected = options['displayAdolescentCOD'];
    let nutritionSelected = options['displayNutritionCOD'];
    let birthOutcomeSelected = options['displayBirthOutcomeCOD'];

    let sortedData = [];

    //Step 1: Determine which delivery points are selected, if a delivery point is selected, add interventions associated
    //with that delivery point into the selectedDeliveryPointsData_Step1 array
    for (let i = 0; i < countryData.length; i++ ) {
        delivPointIdx = getDelivPointIdx(countryData[i]['delivPoint']);
        if (options['deliveryPointsContent'][delivPointIdx]['checked']) {
            selectedDeliveryPointsData_Step1.push(countryData[i]);
        }
    }

    //Step 2: Determine which outcomes are selected, if an outcome is selected, put the data for that outcome into the
    //selectedOutcomesData_Step2 array. this array contains data that is for selected delivery points and selected outcomes only
    for (let j = 0; j < selectedDeliveryPointsData_Step1.length; j++) {
        outcomeSelected = getOutcomeSelected(selectedDeliveryPointsData_Step1[j]['Outcome'], sbSelected, matSelected, nnSelected, childSelected, adolSelected);
        let nutritionSelection = nutritionSelected && selectedDeliveryPointsData_Step1[j].isNutritionIV;
        let birthOutcomeSelection = birthOutcomeSelected && selectedDeliveryPointsData_Step1[j].isBirthOutcomeIV;
        if (outcomeSelected && ((nutritionSelected && birthOutcomeSelected) || nutritionSelection || birthOutcomeSelection)) {
            selectedOutcomesData_Step2.push(selectedDeliveryPointsData_Step1[j]);
        }
    }

    //Step 3: Determine if CPR should be in the list
    for (let k = 0; k < selectedOutcomesData_Step2.length; k++) {
        if (selectedOutcomesData_Step2[k]['IntervMstID'] !== CONSTANTS.CS_MstCPR) {
            if (options['showTopInterv']) {
                if ((selectedOutcomesData_Step2[k]['IntervMstID'] !== CONSTANTS.IV_MstCotrimoxazole) &&
                    (selectedOutcomesData_Step2[k]['IntervMstID'] !== CONSTANTS.IV_MstART) &&
                    (selectedOutcomesData_Step2[k]['IntervMstID'] !== CONSTANTS.IV_MstPMTCT)) {
                    selectedCPR_Step3.push(selectedOutcomesData_Step2[k]);
                }
            }
            else {
                selectedCPR_Step3.push(selectedOutcomesData_Step2[k]);
            }
        }
        else if ((selectedOutcomesData_Step2[k]['IntervMstID'] === CONSTANTS.CS_MstCPR) && (options['displayCPR']) && (options['MOResultToDisplay'] === CONSTANTS.CS_PotentialDeathsAverted)){
            selectedCPR_Step3.push(selectedOutcomesData_Step2[k]);
        }
    }

    //Step4: Determine if COD for the specific item has been selected, based on outcome, if deaths averted are greater than zero
    //weed out the items in the array that do not have a value for deaths averted greater than zero
    for (let m = 0; m < selectedCPR_Step3.length; m++) {
        if (selectedCPR_Step3[m]['DeathsAverted'] > 0) {
            if (selectedCPR_Step3[m]['IntervMstID'] === CONSTANTS.CS_MstCPR) {
                selectedCOD_Step4.push(selectedCPR_Step3[m]);
            } else {
                switch (selectedCPR_Step3[m]['Outcome']) {

                    case CONSTANTS.CS_CoD_MstStillbirth : {
                        stillbirthIdx = getStillbirthIdx(selectedCPR_Step3[m]['CauseOfDeath']);
                        if (options['stillbirthCausesOfDeathContent'][stillbirthIdx]['checked']) {
                            selectedCOD_Step4.push(selectedCPR_Step3[m]);
                        }
                        break;
                    }

                    case CONSTANTS.CS_CoD_MstNeonatal : {
                        neonatalIdx = getNeonatalIdx(selectedCPR_Step3[m]['CauseOfDeath']);
                        if (options['neonatalCausesOfDeathContent'][neonatalIdx]['checked']) {
                            selectedCOD_Step4.push(selectedCPR_Step3[m]);
                        }
                        break;
                    }

                    case CONSTANTS.CS_CoD_MstPostNeonatal : {
                        childIdx = getChildIdx(selectedCPR_Step3[m]['CauseOfDeath']);
                        if (options['childCausesOfDeathContent'][childIdx]['checked']) {
                            selectedCOD_Step4.push(selectedCPR_Step3[m]);
                        }
                        break;
                    }

                    case CONSTANTS.CS_CoD_MstMaternal : {
                        maternalIdx = getMaternalIdx(selectedCPR_Step3[m]['CauseOfDeath']);
                        if (options['maternalCausesOfDeathContent'][maternalIdx]['checked']) {
                            selectedCOD_Step4.push(selectedCPR_Step3[m]);
                        }
                        break;
                    }

                    case CONSTANTS.CS_CoD_MstAdolescent : {
                        adolescentIdx = getAdolescentIdx(selectedCPR_Step3[m]['CauseOfDeath']);
                        if (options['adolescentCausesOfDeathContent'][adolescentIdx]['checked']) {
                            selectedCOD_Step4.push(selectedCPR_Step3[m]);
                        }
                        break;
                    }

                    default : {
                        break;
                    }
                }
            }
        }
    }

    let summedDeathsAvertedData = getSummedDeathsAvertedData(selectedCOD_Step4, options);

    if(options["sortByDeathsAverted"]) {
        sortedData = getDataSortedByDeathsAverted(summedDeathsAvertedData);
    }
    else if (options['sortByInterventionCost']) {
        sortedData = getDataSortedByInterventionCost(summedDeathsAvertedData)
    }
    else {
        sortedData = getDataSortedByDeliveryPoints(summedDeathsAvertedData);
    }

    return (sortedData)
};

const getSummedDeathsAvertedData = (selectedData, options) => {
    let summedDataPlus = [];
    let interventionData = [];

    let mstID = (selectedData.length > 0) ? selectedData[0]['IntervMstID'] : 0;

    for (let i = 0; i < selectedData.length; i++) {
        if (selectedData[i]['IntervMstID'] === mstID) {
            interventionData.push(selectedData[i]);
            if (i === selectedData.length - 1) {
                summedDataPlus = getSummedDeathsForIntervention(summedDataPlus,interventionData, options);
            }
        } else {
            summedDataPlus = getSummedDeathsForIntervention(summedDataPlus,interventionData, options);
            mstID = selectedData[i]['IntervMstID'];
            interventionData = [];
            i--;
        }
    }
    return (summedDataPlus)
};

const getSummedDeathsForIntervention = (summedDataPlus, interventionData, options) => {
    let summedData = [];
    let outcomeAdded = false;

    let outcomeID = (interventionData.length > 0) ? interventionData[0]['Outcome'] : 0;
    let sumDeathsAverted = 0;
    let sumDeaths = 0;
    let cod = [];
    let codText = [];

    let intervName    = (interventionData.length > 0) ? interventionData[0]['name'] : '';
    let mstID         = (interventionData.length > 0) ? interventionData[0]['IntervMstID'] : 0;
    let baseCoverage  = (interventionData.length > 0) ? interventionData[0]['baseCov'] : 0;
    let scaleCoverage = (interventionData.length > 0) ? interventionData[0]['scaleUpCov'] : 0;
    let deliveryPoint = (interventionData.length > 0) ? interventionData[0]['delivPoint'] : 0;
    let totDeaths     = (interventionData.length > 0) ? interventionData[0]['TotDeaths'] : 0;
    let cost          = (interventionData.length > 0) ? interventionData[0]['IVCost'] : 0;

    for (let j = 0; j < interventionData.length; j++) {
        if (interventionData[j]['Outcome'] === outcomeID) {
            sumDeathsAverted = sumDeathsAverted + interventionData[j]['DeathsAverted'];
            sumDeaths = sumDeaths + interventionData[j]['Deaths'];
            codText.push(getCauseOfDeathString(outcomeID, interventionData[j]['CauseOfDeath'], options));
            cod.push(interventionData[j]['CauseOfDeath']);
            if (j === interventionData.length - 1) {
                summedData.push({
                    name          : intervName,
                    IntervMstID   : mstID,
                    Outcome       : outcomeID,
                    delivPoint    : deliveryPoint,
                    baseCov       : baseCoverage,
                    scaleUpCov    : scaleCoverage,
                    CausesOfDeath : cod,
                    CodStrings    : codText,
                    DeathsAverted : sumDeathsAverted,
                    Deaths        : sumDeaths,
                    totDeaths     : totDeaths,
                    IVCost        : cost,
                })
            }
        }
        else {
            summedData.push({
                name          : intervName,
                IntervMstID   : mstID,
                Outcome       : outcomeID,
                delivPoint    : deliveryPoint,
                baseCov       : baseCoverage,
                scaleUpCov    : scaleCoverage,
                CausesOfDeath : cod,
                CodStrings    : codText,
                DeathsAverted : sumDeathsAverted,
                Deaths        : sumDeaths,
                totDeaths     : totDeaths,
                IVCost        : cost,
            });
            outcomeID = interventionData[j]['Outcome'];
            sumDeathsAverted = 0;
            sumDeaths = 0;
            cod = [];
            codText = [];
            j--;
        }
    }

    for (let oc = 1; oc <= 4; oc++) {
        outcomeAdded = false;
        for (let k = 0; k < summedData.length; k++) {
            if (summedData[k]['Outcome'] === oc) {
                summedDataPlus.push(summedData[k]);
                outcomeAdded = true;
            }
        }
        if (!outcomeAdded) {
            summedDataPlus.push({
                name          : intervName,
                IntervMstID   : mstID,
                Outcome       : oc,
                delivPoint    : deliveryPoint,
                baseCov       : baseCoverage,
                scaleUpCov    : scaleCoverage,
                CausesOfDeath : [],
                CodStrings    : [],
                DeathsAverted : 0,
                Deaths        : 0,
                totDeaths     : 0,
                IVCost        : 0,
            });
        }
    }

    return(summedDataPlus)
};

const getDataSortedByDeathsAverted = (summedDeathsAvertedData) => {

    let sortedData = summedDeathsAvertedData;
    let temp = [];
    let sum1 = 0;
    let sum2 = 0;

    for (let i = 0; i < sortedData.length - 4; i++) {
        sum1 = 0;
        sum2 = 0;
        for (let j = i; j < i + 4; j++) {
            sum1 = sum1 + sortedData[j]['DeathsAverted'];
            sum2 = sum2 + sortedData[j+4]['DeathsAverted'];
        }
        if (sum1 < sum2) {
            temp = [];
            for (let k = i; k < i + 4; k++) {
                temp.push(sortedData[k]);
                sortedData[k] = sortedData[k+4];
            }
            sortedData[i+4] = temp[0];
            sortedData[i+5] = temp[1];
            sortedData[i+6] = temp[2];
            sortedData[i+7] = temp[3];

            i = -1;
        }
        else {
            i = i + 3;
        }
    }

    return(sortedData)
};

const getDataSortedByDeliveryPoints = (summedDeathsAvertedData) => {

    let sortedData = summedDeathsAvertedData;
    let temp = [];
    let delID1 = 0;
    let delID2 = 0;
    let sum1 = 0;
    let sum2 = 0;

    for (let i = 0; i < sortedData.length - 4; i++) {
        delID1 = sortedData[i]['delivPoint'];
        delID2 = sortedData[i+4]['delivPoint'];

        if (delID2 < delID1) {
            temp = [];
            for (let j = i; j < i + 4; j++) {
                temp.push(sortedData[j]);
                sortedData[j] = sortedData[j+4];
            }
            sortedData[i+4] = temp[0];
            sortedData[i+5] = temp[1];
            sortedData[i+6] = temp[2];
            sortedData[i+7] = temp[3];

            i = -1;

            delID1 = 0;
            delID2 = 0;
        }
        else {
            i = i + 3;
        }
    }

    for (let i = 0; i < sortedData.length - 4; i++) {
        sum1 = 0;
        sum2 = 0;
        for (let j = i; j < i + 4; j++) {
            sum1 = sum1 + sortedData[j]['DeathsAverted'];
            sum2 = sum2 + sortedData[j+4]['DeathsAverted'];

            delID1 = sortedData[j]['delivPoint'];
            delID2 = sortedData[j+4]['delivPoint'];
        }
        if ((sum1 < sum2) && (delID1 === delID2)) {
            temp = [];
            for (let k = i; k < i + 4; k++) {
                temp.push(sortedData[k]);
                sortedData[k] = sortedData[k+4];
            }
            sortedData[i+4] = temp[0];
            sortedData[i+5] = temp[1];
            sortedData[i+6] = temp[2];
            sortedData[i+7] = temp[3];

            i = -1;
        }
        else {
            i = i + 3;
        }
    }

    return(sortedData)
};

const getDataSortedByInterventionCost = (summedDeathsAvertedData) => {

    let sortedData = summedDeathsAvertedData;
    let temp = [];
    let cost1 = 0;
    let cost2 = 0;

    for (let i = 0; i < sortedData.length - 4; i++) {
        cost1 =  sortedData[i]['IVCost'];
        cost2 =  sortedData[i+4]['IVCost'];
        for (let j = i+1; j < i + 4; j++) {
            if (cost1 < sortedData[j]['IVCost']) {
                cost1 = sortedData[j]['IVCost']
            }
            if (cost2 < sortedData[j+4]['IVCost']) {
                cost2 = sortedData[j+4]['IVCost']
            }
        }
        if (cost1 < cost2) {
            temp = [];
            for (let k = i; k < i + 4; k++) {
                temp.push(sortedData[k]);
                sortedData[k] = sortedData[k+4];
            }
            sortedData[i+4] = temp[0];
            sortedData[i+5] = temp[1];
            sortedData[i+6] = temp[2];
            sortedData[i+7] = temp[3];

            i = -1;
        }
        else {
            i = i + 3;
        }
    }

    return(sortedData)
};

const getInterventionCostData = (selectedSortedData) => {
    let interventionCostData = [];

    for (let i = 0; i < selectedSortedData.length; i++) {
        let cost = 0;
        for (let j = i; j < i+4; j++) {
            if(cost < selectedSortedData[j]['IVCost']){
                cost = selectedSortedData[j]['IVCost']
            }
        }
        interventionCostData.push(cost);
        i = i + 3;
    }

    return (interventionCostData)
}

const getMOTopInterventions = (selectedSortedData) => {
    let MOTopInterventions = [];
    let totalDeathsAverted = 0;

    for (let i = 0; i < selectedSortedData.length; i++) {
        totalDeathsAverted += selectedSortedData[i]['DeathsAverted']
    }
    let ninetyPercDeathsAverted = 0.9 * totalDeathsAverted;

    let sumDeathsAverted = [];
    for (let j = 0; j < selectedSortedData.length; j += 4) {
        sumDeathsAverted.push(
            {
                IntervMstID   : selectedSortedData[j]['IntervMstID'],
                DeathsAverted : selectedSortedData[j]['DeathsAverted'] +
                                selectedSortedData[j+1]['DeathsAverted'] +
                                selectedSortedData[j+2]['DeathsAverted'] +
                                selectedSortedData[j+3]['DeathsAverted']
            }
        )
    }

    let sum = 0;
    for (let k = 0; k < sumDeathsAverted.length; k++) {
        sum += sumDeathsAverted[k]['DeathsAverted'];
        if (sum <= ninetyPercDeathsAverted) {
            MOTopInterventions.push(sumDeathsAverted[k]['IntervMstID'])
        }
    }

    return (MOTopInterventions)
};

const getDeathsAvertedPackChart = (countryName, options, selectedSortedData, interventionCostData) => {
    let pointLabels      = [];
    let chartDataSB      = [];
    let chartDataNN      = [];
    let chartDataChild   = [];
    let chartDataMat     = [];
    let chartData        = [];
    let subsetLabels     = [];
    let subsetColors     = [];
    let toolTipDataSB    = [];
    let toolTipDataNN    = [];
    let toolTipDataChild = [];
    let toolTipDataMat   = [];
    let deliveryPoints   = [];
    //This represents the top 15 interventions, one for each category (mat, sb, nn and child)
    let top15ByFour     = 60;
    let xAxisLabel = RS('GB_stInterventions');
    //This is used for percent reduction, it is the index associated with "All", if "All" is
    //checked that means we need to use Total deaths, not deaths in the equations
    let allIdx = 0;

    let numDisplay = selectedSortedData.length;
    if (options['showTopInterv']) {
        if (numDisplay > top15ByFour) {
            numDisplay = top15ByFour
        }
    }

    for (let i = 0; i < numDisplay; i++) {
        pointLabels.push(selectedSortedData[i]['name']);
        i = i + 3;
    }

    if (!(options['sortByDeathsAverted'] || (options['sortByInterventionCost']))) {
        let delString = '';
        let delID1 = 0;
        let delID2 = 0;

        let delivPointData = [];

        xAxisLabel = '';

        for (let k = 0; k < numDisplay; k = k + 4) {
            delivPointData.push(selectedSortedData[k])
        }

        for (let d = 0; d < delivPointData.length - 1; d++) {
            delID1 = delivPointData[d]['delivPoint'];
            delID2 = delivPointData[d+1]['delivPoint'];

           if ((delID1 !== delID2) || (d === delivPointData.length - 2 )) {
                for (let j = 1; j < options['deliveryPointsContent'].length; j++) {
                    if (options['deliveryPointsContent'][j]['mstID'] === delID1) {
                        delString = RS(options['deliveryPointsContent'][j]['name'])
                    }
                }
                if (d === delivPointData.length - 2 ) {
                    deliveryPoints.push([null, delString])
                }
                else {
                    deliveryPoints.push([d, delString]);
                }
            }
        }
    }

    for (let i = 0; i < numDisplay; i++) {
        if (options['MOResultToDisplay'] === CONSTANTS.CS_PotentialDeathsAverted) {
            chartDataSB.push(selectedSortedData[i]['DeathsAverted']);
            chartDataNN.push(selectedSortedData[i + 1]['DeathsAverted']);
            chartDataChild.push(selectedSortedData[i + 2]['DeathsAverted']);
            chartDataMat.push(selectedSortedData[i + 3]['DeathsAverted']);
        }
        else {
            let sbDeaths = selectedSortedData[i]['Deaths'];
            if (options['stillbirthCausesOfDeathContent'][allIdx]['checked']) {
                sbDeaths = selectedSortedData[i]['totDeaths']
            }
            if (selectedSortedData[i]['DeathsAverted'] + sbDeaths > 0) {
                chartDataSB.push((selectedSortedData[i]['DeathsAverted']/(selectedSortedData[i]['DeathsAverted'] + sbDeaths))*100);
            }
            else {
                chartDataSB.push(0);
            }

            let nnDeaths = selectedSortedData[i + 1]['Deaths'];
            if (options['neonatalCausesOfDeathContent'][allIdx]['checked']) {
                nnDeaths = selectedSortedData[i + 1]['totDeaths']
            }
            if (selectedSortedData[i + 1]['DeathsAverted'] + nnDeaths > 0) {
                chartDataNN.push((selectedSortedData[i + 1]['DeathsAverted']/(selectedSortedData[i + 1]['DeathsAverted'] + nnDeaths))*100);
            }
            else {
                chartDataNN.push(0);
            }

            let childDeaths = selectedSortedData[i + 2]['Deaths'];
            if (options['childCausesOfDeathContent'][allIdx]['checked']) {
                childDeaths = selectedSortedData[i + 2]['totDeaths']
            }
            if (selectedSortedData[i + 2]['DeathsAverted'] + childDeaths > 0) {
                chartDataChild.push((selectedSortedData[i + 2]['DeathsAverted']/(selectedSortedData[i + 2]['DeathsAverted'] + childDeaths))*100);
            }
            else {
                chartDataChild.push(0);
            }

            let matDeaths = selectedSortedData[i + 3]['Deaths'];
            if (options['maternalCausesOfDeathContent'][allIdx]['checked']) {
                matDeaths = selectedSortedData[i + 3]['totDeaths']
            }
            if (selectedSortedData[i + 3]['DeathsAverted'] + matDeaths > 0) {
                chartDataMat.push((selectedSortedData[i + 3]['DeathsAverted']/(selectedSortedData[i + 3]['DeathsAverted'] + matDeaths))*100);
            }
            else {
                chartDataMat.push(0);
            }
        }

        toolTipDataSB.push(selectedSortedData[i]['baseCov']);
        toolTipDataNN.push(selectedSortedData[i+1]['baseCov']);
        toolTipDataChild.push(selectedSortedData[i+2]['baseCov']);
        toolTipDataMat.push(selectedSortedData[i+3]['baseCov']);
        i = i + 3;
    }

    if (options['displayChildCOD']) {
        chartData.push(chartDataChild);
        subsetLabels.push(RS('GB_stChildren'));
        subsetColors.push(getHexColorToDelphi(CONSTANTS.CS_ChildChartColor));
    }
    if (options['displayNeonatalCOD']) {
        chartData.push(chartDataNN);
        subsetLabels.push(RS('GB_stNeonatal'));
        subsetColors.push(getHexColorToDelphi(CONSTANTS.CS_NNChartColor));
    }
    if (options['displayStillbirthCOD']) {
        chartData.push(chartDataSB);
        subsetLabels.push(RS('GB_stStillbirth'));
        subsetColors.push(getHexColorToDelphi(CONSTANTS.CS_SBChartColor));
    }
    if (options['displayMaternalCOD']) {
        chartData.push(chartDataMat);
        subsetLabels.push(RS('GB_stMaternal'));
        subsetColors.push(getHexColorToDelphi(CONSTANTS.CS_MatChartColor));
    }

    if (options['viewInterventionCost']) {
        chartData.push(interventionCostData);
        subsetLabels.push(RS('GB_stCost'));
        subsetColors.push(getHexColorToDelphi(CONSTANTS.CS_IVCostChartColor));
    }

    // if (options['displayNutritionCOD']) {
    //     chartData.push(chartDataMat);
    //     subsetLabels.push(RS("GB_stNutrition"));
    // }
    //
    // if (options['displayBirthOutcomeCOD']) {
    //     chartData.push(chartDataMat);
    //     subsetLabels.push(RS("GB_stBirthOutcomes"));
    // }

    return (
        (options['viewInterventionCost']) ?
            ({
                "title": "",
                "alignTitle": 'left',
                "subTitle": "",
                "alignSubTitle": 'left',
                "MultiSubTitles": ["", ""],
                "chartType": 12,
                "showLegend": true,
                "legendLocation": 0,
                "legendAlign": 'center',

                "xAxisLabel": xAxisLabel,
                "pointLabels": pointLabels,

                "yAxisLabel": (options['MOResultToDisplay'] === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stCasesDeathAverted') : RS('GB_stPercentRedInMort'),
                "ManualMinY": 0,
                "ManualMaxY": 0,
                "ManualStackedMinY": 0,
                "ManualStackedMaxY": 0,

                "RYAxisLabel": "Right Y Axis",
                "ManualMinRY": 0,
                "RYAxisComparisonSubsets": 0,

                "subsetLabels": subsetLabels,
                "chartData": chartData,
                "subsetColors": subsetColors,
                "subsetChartTypes": [0],
                "subsetPointTypes": [-1],
                "subsetLineTypes": [-1],
                "SubsetsToLegend": [0],

                "verticalLabels": deliveryPoints,

                "toolTipData": [toolTipDataChild, toolTipDataNN, toolTipDataSB, toolTipDataMat]
            }) :
            ({
                "title": "",
                "alignTitle": 'left',
                "subTitle": "",
                "alignSubTitle": 'left',
                "MultiSubTitles": ["", ""],
                "chartType": 12,
                "showLegend": true,
                "legendLocation": 0,
                "legendAlign": 'center',

                "xAxisLabel": xAxisLabel,
                "pointLabels": pointLabels,

                "yAxisLabel": (options['MOResultToDisplay'] === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stCasesDeathAverted') : RS('GB_stPercentRedInMort'),
                "ManualMinY": 0,
                "ManualMaxY": 0,
                "ManualStackedMinY": 0,
                "ManualStackedMaxY": 0,

                "RYAxisLabel": "Right Y Axis",
                "ManualMinRY": 0,
                "RYAxisComparisonSubsets": 0,

                "subsetLabels": subsetLabels,
                "chartData": chartData,
                "subsetColors": subsetColors,
                "subsetChartTypes": [0],
                "subsetPointTypes": [-1],
                "subsetLineTypes": [-1],
                "SubsetsToLegend": [0],

                "verticalLabels": deliveryPoints,

                "toolTipData": [toolTipDataChild, toolTipDataNN, toolTipDataSB, toolTipDataMat]
            })
    )
};

const getDeathsAvertedPackTable = (packChart, options) => {
    let child = 0;
    let neo   = 1;
    let still = 2;
    let mat   = 3;
    let cost  = 4;
    let dataIdx = [];

    let includeStill = false;
    let includeNeo   = false;
    let includeChild = false;
    let includeMat   = false;
    let includeCost  = false;

    let colHeadings = [];
    let rows = [];
    let wordWrap = [];
    let colWidth = [];
    let rightDec = [];
    let locked = [];
    let justified = [];

    let mergeCells = [{startCol: 0, startRow: 0, numCols: 0, numRows: 0}];
    let indents = [{xoffset: 0, Value: false}];
    let fonts = [[{intArray: []}, {intArray: []}, {intArray: []}, {intArray: []}, {intArray: []}, {intArray: []}, {intArray: []}, {intArray: []}, {intArray: []}, {intArray: []}]];
    let pointLabels = JSON.parse(JSON.stringify(packChart['pointLabels']));
    let chartData = JSON.parse(JSON.stringify(packChart['chartData']));

    let counter = 0;

    if (options['displayChildCOD']) {
        includeChild = true;
        dataIdx.push('child');
    }
    if (options['displayNeonatalCOD']) {
        includeNeo = true;
        dataIdx.push('neonatal');
    }
    if (options['displayStillbirthCOD']) {
        includeStill = true;
        dataIdx.push('stillbirth');
    }
    if (options['displayMaternalCOD']) {
        includeMat = true;
        dataIdx.push('maternal');
    }
    if (options['viewInterventionCost']) {
        includeCost = true;
        dataIdx.push('cost');
    }

    if ((includeMat) || (includeStill) || (includeNeo) || (includeChild) || (includeCost)) {
        colHeadings = [''];
    }
    if (includeMat) {colHeadings.push(RS('GB_stMaternal'))}
    if (includeStill) {colHeadings.push(RS('GB_stStillbirth'))}
    if (includeNeo) {colHeadings.push(RS('GB_stNeonatal'))}
    if (includeChild) {colHeadings.push(RS('GB_stChildren'))}
    if (includeCost) {colHeadings.push(RS('GB_stCost'))}
    rows.push(colHeadings);

    packChart['pointLabels'].forEach(function(pt) {
        indents.push((options.sortByDeathsAverted || options.sortByInterventionCost) ? {xoffset: 0, Value: false} : {xoffset: 15, Value: true});
        let singleFont = [];
        for (let i = 0; i < colHeadings.length; i++) {
            singleFont.push({intArray: []});
        }
        fonts.push(singleFont);
    });

    if (!(options['sortByDeathsAverted'] || options['sortByInterventionCost'])) {
        let dp = JSON.parse(JSON.stringify(packChart['verticalLabels']));
        if (options['displayCPR'] && (options['MOResultToDisplay'] === CONSTANTS.CS_PotentialDeathsAverted)) {
            dp[0][1] = RS('GB_stCPR')
        }
        for (let i = 0; i < dp.length; i++) {
            let line = dp[i - 1] ? dp[i - 1][0] + 1 + counter : 0;
            let heading = dp[i][1];
            counter++;
            pointLabels.splice(line, 0, heading);
            indents.splice(line + 1, 0, {xoffset: 0, Value: false});
            let oneFont = [];
            for (let j = 0; j < colHeadings.length; j++) {
                oneFont.push({intArray: [0]});
            }
            fonts.splice(line + 1, 0, oneFont);
            mergeCells.push({startCol: 0, startRow: line + 1, numCols: 6, numRows: 1});
            chartData.forEach(function(dat) {
                dat.splice(line, 0, heading);
            });
        }
    }

    let oneRow = [];
    for (let j = 0; j < pointLabels.length; j++){
        oneRow.push(pointLabels[j]);
        if (includeMat) {
            for (let m = 0; m < dataIdx.length; m++) {
                if (dataIdx[m] === 'maternal') {
                    mat = m;
                }
            }
            oneRow.push(chartData[mat][j])
        }
        if (includeStill) {
            for (let s = 0; s < dataIdx.length; s++) {
                if (dataIdx[s] === 'stillbirth') {
                    still = s;
                }
            }
            oneRow.push(chartData[still][j])
        }
        if (includeNeo) {
            for (let n = 0; n < dataIdx.length; n++) {
                if (dataIdx[n] === 'neonatal') {
                    neo = n;
                }
            }
            oneRow.push(chartData[neo][j])}
        if (includeChild) {
            for (let c = 0; c < dataIdx.length; c++) {
                if (dataIdx[c] === 'child') {
                    child = c;
                }
            }
            oneRow.push(chartData[child][j])
        }
        if (includeCost) {
            for (let d = 0; d < dataIdx.length; d++) {
                if (dataIdx[d] === 'cost') {
                    cost = d;
                }
            }
            oneRow.push(chartData[cost][j])
        }
        rows.push(oneRow);
        oneRow = [];
    }

    colWidth.push(500);
    wordWrap.push(true);
    for (let k = 1; k < colHeadings.length; k++) {
        colWidth.push(90);
        wordWrap.push(false);
    }

    for (let r = 0; r < rows.length; r++) {
        let rd = [];
        let loc = [];
        let just = [];
        for (let c = 0; c < colHeadings.length; c++) {
            if (options['MOResultToDisplay'] === CONSTANTS.CS_PotentialDeathsAverted) {
                rd.push(0);
            }
            else if (colHeadings[c] === RS('GB_stCost')) {
                rd.push(0);
            }
            else {
                rd.push(2);
            }
            loc.push(true);
            if (r === 0) {
                just.push(CONSTANTS.CS_CenterJust)
            }
            else if (c === 0) {
                just.push(CONSTANTS.CS_LeftJust)
            }
            else {
                just.push(CONSTANTS.CS_RightJust)
            }
        }
        rightDec.push(rd);
        locked.push(loc);
        justified.push(just);
    }

    return(
            {
                "tableData":{
                    "value": rows
                },
                "GBFixedRows":1,
                "GBFixedCols":1,
                "GBRowCount":rows.length,
                "GBColCount":colHeadings.length,
                "GBColWidths":colWidth,
                "WordWrappedCols":wordWrap,
                "RDec":rightDec,
                "LockedCells":locked,
                "Alignments":justified,
                "MergedCells": mergeCells,
                "IndentRowText": indents,
                "FontStyles": fonts
            }
    )
};


const getStuntingMO = (countryData, options) => {

    let selectedStuntingData = [];
    let mstID = 0;
    let delivPointIdx = 0;

    for (let i = 0; i < countryData.length; i++ ) {
        delivPointIdx = getDelivPointIdx(countryData[i]['delivPoint']);
        if ((countryData[i]['stuntingAverted'] > 0) &&
            (countryData[i]['IntervMstID'] !== mstID) &&
            (options['deliveryPointsContent'][delivPointIdx]['checked'])) {
            selectedStuntingData.push({
                name            : countryData[i]['name'],
                delivPoint      : options['deliveryPointsContent'][delivPointIdx]['name'],
                stuntingAverted : countryData[i]['stuntingAverted'],
                baseCov         : countryData[i]['baseCov'],
                IntervMstID     : countryData[i]['IntervMstID'],
            });
            mstID = countryData[i]['IntervMstID'];
        }
    }

    let sortedStuntingData = getSortedStuntingData(selectedStuntingData);

    return (sortedStuntingData)
};

const getSortedStuntingData = (selectedStuntingData) => {

    let sortedData = selectedStuntingData;
    let temp = [];

    for (let i = 0; i < sortedData.length - 1; i++) {
        if (sortedData[i]['stuntingAverted'] < sortedData[i+1]['stuntingAverted']) {
            temp = sortedData[i];
            sortedData[i] = sortedData[i+1];
            sortedData[i+1] = temp;
            i = -1;
        }
    }

    return(sortedData)
};

const getStuntingMOTopInterventions = (selectedSortedData) => {
    let percToSelect = 1.0;
    let MOTopInterventions = [];
    let totalStuntingAverted = 0;

    for (let i = 0; i < selectedSortedData.length; i++) {
        totalStuntingAverted += selectedSortedData[i]['stuntingAverted']
    }
    let ninetyPercStuntingAverted = percToSelect * totalStuntingAverted; //0.9 * totalStuntingAverted;

    let sum = 0;
    for (let j = 0; j < selectedSortedData.length; j++) {
        sum += selectedSortedData[j]['stuntingAverted'];
        if (sum <= ninetyPercStuntingAverted) {
            MOTopInterventions.push(selectedSortedData[j]['IntervMstID'])
        }
    }

    return (MOTopInterventions)
};

const getStuntingPackChart = (countryName, options, selectedSortedData) => {
    let pointLabels = [];
    let chartData = [];
    let top15 = 15;

    let numDisplay = selectedSortedData.length;
    if (options['showTopInterv']) {
        if (numDisplay > top15) {
            numDisplay = top15
        }
    }

    for (let i = 0; i < numDisplay; i++) {
        pointLabels.push(selectedSortedData[i]['name']);
        chartData.push(selectedSortedData[i]['stuntingAverted']);
    }

    return (
        {
            "title": '',
            "alignTitle": 'left',
            "subTitle": "",
            "alignSubTitle": 'left',
            "MultiSubTitles": ["", ""],
            "chartType": 12,
            "ShowLegend": false,

            "xAxisLabel": RS('GB_stInterventions'),
            "pointLabels": pointLabels,

            "yAxisLabel": RS('GB_stCasesStuntingAverted'),
            "ManualMinY": 0,
            "ManualMaxY": 0,
            "ManualStackedMinY": 0,
            "ManualStackedMaxY": 0,

            "RYAxisLabel": "Right Y Axis",
            "ManualMinRY": 0,
            "RYAxisComparisonSubsets": 0,

            "subsetLabels": [RS('GB_stCasesStuntingAverted')],
            "chartData": [chartData,],
            "subsetColors": [10508580],
            "subsetChartTypes": [0],
            "subsetPointTypes": [-1],
            "subsetLineTypes": [-1],
            "SubsetsToLegend": [0],
        }
    )
};

const getStuntingPackTable = (packChart) => {
    let colHeadings = [];
    let rows = [];
    let wordWrap = [];
    let colWidth = [];
    let rightDec = [];
    let locked = [];
    let justified = [];

    if (packChart['pointLabels'].length > 0) {
        colHeadings.push('');
        colHeadings.push(RS('GB_stCasesAverted'));
        rows.push(colHeadings);
    }

    let oneRow = [];
    for (let j = 0; j < packChart['pointLabels'].length; j++){
        oneRow.push(packChart['pointLabels'][j]);
        oneRow.push(packChart['chartData'][0][j]);
        rows.push(oneRow);
        oneRow = [];
    }

    //column width and word wrap for two columns
    colWidth.push(300);
    colWidth.push(100);
    wordWrap.push(true);
    wordWrap.push(false);

    for (let r = 0; r < rows.length; r++) {
        let rd = [];
        let loc = [];
        let just = [];
        for (let c = 0; c < colHeadings.length; c++) {
            rd.push(0);
            loc.push(true);
            if (r === 0) {
                just.push(CONSTANTS.CS_CenterJust)
            }
            else if (c === 0) {
                just.push(CONSTANTS.CS_LeftJust)
            }
            else {
                just.push(CONSTANTS.CS_RightJust)
            }
        }
        rightDec.push(rd);
        locked.push(loc);
        justified.push(just);
    }

    return(
        {
            //"Title" : RS('GB_stPotentialStuntingAverted'),
            "tableData":{
                "value": rows
            },
            "GBFixedRows":1,
            "GBFixedCols":1,
            "GBRowCount":rows.length,
            "GBColCount":colHeadings.length,
            "GBColWidths":colWidth,
            "WordWrappedCols":wordWrap,
            "RDec":rightDec,
            "LockedCells":locked,
            "Alignments":justified,
        }
    )
};


const getDelivPointIdx = (deliveryPoint) => {
    let delivPointIdx = 0;

    switch(deliveryPoint) {
        case CONSTANTS.CS_MstPericonceptualID : {
            delivPointIdx = 1;
            break;
        }
        case CONSTANTS.CS_MstPregnancyID : {
            delivPointIdx = 2;
            break;
        }
        case CONSTANTS.CS_MstChildbirthID : {
            delivPointIdx = 3;
            break;
        }
        case CONSTANTS.CS_MstVaccinesID : {
            delivPointIdx = 5;
            break;
        }
        case CONSTANTS.CS_MstPreventiveID : {
            delivPointIdx = 4;
            break;
        }
        case CONSTANTS.CS_MstCurativeID : {
            delivPointIdx = 6;
            break;
        }
        default : {
            delivPointIdx = 0;
            break;
        }
    }

    return(delivPointIdx)
};

const getOutcomeSelected = (outcomeID, sbSelected, matSelected, nnSelected, childSelected, adolSelected)  => {

    let outcomeSelected = false;

    switch(outcomeID) {
        case CONSTANTS.CS_CoD_MstStillbirth  : {
            if (sbSelected) {
                outcomeSelected = true;
            }
            break;
        }
        case CONSTANTS.CS_CoD_MstNeonatal : {
            if (nnSelected) {
                outcomeSelected = true;
            }
            break;
        }
        case CONSTANTS.CS_CoD_MstPostNeonatal : {
            if (childSelected) {
                outcomeSelected = true;
            }
            break;
        }
        case CONSTANTS.CS_CoD_MstMaternal : {
            if (matSelected) {
                outcomeSelected = true;
            }
            break;
        }
        case CONSTANTS.CS_CoD_MstAdolescent : {
            if (adolSelected) {
                outcomeSelected = true;
            }
            break;
        }
        default : {
            outcomeSelected = false;
            break;
        }
    }

    return (outcomeSelected)
};

const getStillbirthIdx = (causeOfDeath) => {
    let stillbirthIdx = 0;

    switch(causeOfDeath) {
        case CONSTANTS.CS_SB_MstAntepartum : {
            stillbirthIdx = 1;
            break;
        }
        case CONSTANTS.CS_SB_MstIntrapartum : {
            stillbirthIdx = 2;
            break;
        }
        default : {
            stillbirthIdx = 0;
            break;
        }
    }

    return(stillbirthIdx)
};

const getNeonatalIdx = (causeOfDeath) => {
    let neonatalIdx = 0;

    switch(causeOfDeath) {
        case CONSTANTS.CS_MstNNDiarr : {
            neonatalIdx = 1;
            break;
        }
        case CONSTANTS.CS_MstNNSepsis : {
            neonatalIdx = 2;
            break;
        }
        case CONSTANTS.CS_MstNNPneu : {
            neonatalIdx = 3;
            break;
        }
        case CONSTANTS.CS_MstNNAsphyxia : {
            neonatalIdx = 4;
            break;
        }
        case CONSTANTS.CS_MstNNPreterm : {
            neonatalIdx = 5;
            break;
        }
        case CONSTANTS.CS_MstNNTetanus : {
            neonatalIdx = 6;
            break;
        }
        case CONSTANTS.CS_MstNNCongen : {
            neonatalIdx = 7;
            break;
        }
        case CONSTANTS.CS_MstNNOther : {
            neonatalIdx = 8;
            break;
        }
        default : {
            neonatalIdx = 0;
            break;
        }
    }

    return(neonatalIdx)
};

const getChildIdx = (causeOfDeath) => {
    let childIdx = 0;

    switch(causeOfDeath) {
        case CONSTANTS.CS_MstDiarrhea : {
            childIdx = 1;
            break;
        }
        case CONSTANTS.CS_MstPneumonia  : {
            childIdx = 2;
            break;
        }
        case CONSTANTS.CS_MstMeningitis : {
            childIdx = 3;
            break;
        }
        case CONSTANTS.CS_MstMeasles : {
            childIdx = 4;
            break;
        }
        case CONSTANTS.CS_MstMalaria : {
            childIdx = 5;
            break;
        }
        case CONSTANTS.CS_MstPertussis : {
            childIdx = 6;
            break;
        }
        case CONSTANTS.CS_MstAIDS : {
            childIdx = 7;
            break;
        }
        case CONSTANTS.CS_MstInjury : {
            childIdx = 8;
            break;
        }
        case CONSTANTS.CS_MstOtherInfecDis : {
            childIdx = 9;
            break;
        }
        case CONSTANTS.CS_MstOtherNCD : {
            childIdx = 10;
            break;
        }
        default : {
            childIdx = 0;
            break;
        }
    }

    return(childIdx)
};

const getMaternalIdx = (causeOfDeath) => {
    let maternalIdx = 0;

    switch(causeOfDeath) {
        case CONSTANTS.CS_Mat_MstAntepartHemorr : {
            maternalIdx = 1;
            break;
        }
        case CONSTANTS.CS_Mat_MstIntrapartHemorr  : {
            maternalIdx = 2;
            break;
        }
        case CONSTANTS.CS_Mat_MstPostpartHemorr : {
            maternalIdx = 3;
            break;
        }
        case CONSTANTS.CS_Mat_MstHypertensiveDis : {
            maternalIdx = 4;
            break;
        }
        case CONSTANTS.CS_Mat_MstSepsis : {
            maternalIdx = 5;
            break;
        }
        case CONSTANTS.CS_Mat_MstAbortion : {
            maternalIdx = 6;
            break;
        }
        case CONSTANTS.CS_Mat_MstEmbolism : {
            maternalIdx = 7;
            break;
        }
        case CONSTANTS.CS_Mat_MstOtherDirectCauses : {
            maternalIdx = 8;
            break;
        }
        case CONSTANTS.CS_Mat_MstIndirectCauses : {
            maternalIdx = 9;
            break;
        }
        default : {
            maternalIdx = 0;
            break;
        }
    }

    return(maternalIdx)
};

const getAdolescentIdx = (causeOfDeath) => {
    return 0;
};

const getCauseOfDeathString = (outcomeID, CodID, options) => {
    let idx = 0;
    let CodString = '';

    switch (outcomeID) {
        case CONSTANTS.CS_CoD_MstStillbirth  : {
            idx = getStillbirthIdx(CodID);
            CodString = options['stillbirthCausesOfDeathContent'][idx]['name'];
            break;
        }
        case CONSTANTS.CS_CoD_MstNeonatal : {
            idx = getNeonatalIdx(CodID);
            CodString = options['neonatalCausesOfDeathContent'][idx]['name'];
            break;
        }
        case CONSTANTS.CS_CoD_MstPostNeonatal : {
            idx = getChildIdx(CodID);
            CodString = options['childCausesOfDeathContent'][idx]['name'];
            break;
        }
        case CONSTANTS.CS_CoD_MstMaternal : {
            idx = getMaternalIdx(CodID);
            CodString = options['maternalCausesOfDeathContent'][idx]['name'];
            break;
        }
        default : {
            CodString = '';
            break;
        }
    }
    return(CodString)
};

const getHexColorToDelphi = (color) => {

    if (color) {
        let str = color;
        str = str.slice(1); // Remove #

        let B = str.slice(-2);
        let G = str.slice(-4, -2);
        let R = str.slice(-6, -4);

        let c = B + G + R;

        let delphiColor = parseInt(c, 16);

        return delphiColor;
    }
    else {
        return '';
    }
};