import * as React from 'react';
import PropTypes from 'prop-types';
import { getAPI } from './server_calls_cases';

class APIService extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        apiTasks                : PropTypes.array.isRequired,
        appState                : PropTypes.object,
        onStateChange           : PropTypes.func,
        onAPIMainMsgChange      : PropTypes.func,
        onAPISubMsgChange       : PropTypes.func,
    };

    static defaultProps = {
        apiTasks                : [],
        appState                : {},
        onStateChange           : () => console.log('APIService onStateChange'),
        onAPIMainMsgChange      : () => console.log('APIService onAPIMainMsgChange'),
        onAPISubMsgChange       : () => console.log('APIService onAPISubMsgChange'),
    };

    componentDidUpdate() {
        this.onHandleTasks();
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onHandleTasks = () => {
        let i;
        let task = null;
        let tasks = this.props.apiTasks;
        let len = tasks.length;

        for (i = 0; i < len; i++) {
            task = tasks[i];

            if (!task.complete && !task.running) {
                this.RunServiceCall(
                    task.flag,
                    task.params,
                    task.successFn,
                    task.errorFn,
                    task
                );

                // Only call one API call at a time.  Rerendering the app will continue to go through the list.
                return;
            }
        }
    };

    onIncrementCallsSent = (task) => {
        this.props.onStateChange({
            apiTasks  : this.props.apiTasks.map(el => (el.GUID === task.GUID ? {
                ...el, 
                running : true, 
                complete : false, 
                success : false, 
                error : false, 
                fail : false,
                sentTime : new Date().toTimeString()
            } : el))
        });
    };

    onIncrementCallsSuccess = (task) => {
        this.props.onStateChange({
            heartBeat : 0,
            apiTasks  : this.props.apiTasks.map(el => (el.GUID === task.GUID ? {
                ...el, 
                running : false, 
                complete : true, 
                success : true, 
                error : false, 
                fail : false,
                returnTime : new Date().toTimeString()
            } : el))
        });
    };

    onIncrementCallsError = (task) => {
        this.props.onStateChange({
            apiTasks  : this.props.apiTasks.map(el => (el.GUID === task.GUID ? {
                ...el, 
                running : false, 
                complete : true, 
                success : false, 
                error : true, 
                fail : false,
                returnTime : new Date().toTimeString()
            } : el))         
        });
    };

    onIncrementCallsFail = (task) => {
        this.props.onStateChange({
            apiTasks  : this.props.apiTasks.map(el => (el.GUID === task.GUID ? {
                ...el, 
                running : false, 
                complete : true, 
                success : false, 
                error : false, 
                fail : true,
                returnTime : new Date().toTimeString()
            } : el))           
        });
    };
    
    onDeleteTask = (task) => {
        this.props.onStateChange({
            apiTasks  : this.props.apiTasks.filter(el => (el.GUID === task.GUID ? false : true))           
        });
    };

    RunServiceCall = (flag, params, successFn, errorFn, task) => {
        let onIncrementCallsSent    = this.onIncrementCallsSent;
        // let onIncrementCallsSuccess = this.onIncrementCallsSuccess;
        let onIncrementCallsError   = this.onIncrementCallsError;
        let onIncrementCallsFail    = this.onIncrementCallsFail;
        let onDeleteTask            = this.onDeleteTask;
        let apiObject               = null;

        if (typeof successFn === 'undefined') {
            successFn = () => {};
        }

        if (typeof errorFn === 'undefined') {
            errorFn = (msg) => {
                console.error('Caught error: ' + msg);
                alert('Caught error: ' + msg);
            };
        }

        apiObject = getAPI(flag);

        if (apiObject === null) {
            onIncrementCallsSent(task);
            onIncrementCallsFail(task);
            errorFn('API call [' + flag + '] is not available');
        }
        else {
            try {
                onIncrementCallsSent(task);

                if (flag !== "getStatus") {
                    this.props.onAPIMainMsgChange(apiObject.msg);
                    params.statusFn = this.props.onAPISubMsgChange;
                }

                params.onStateChange = this.props.onStateChange;
                params.appState = this.props.appState;

                apiObject.func(params,
                    (response) => {
                        //onIncrementCallsSuccess(task);

                        onDeleteTask(task);
                        successFn(response);
                    },
                    (msg) => {
                        onIncrementCallsError(task);
                        errorFn(msg);
                    }
                );
            }
            catch(e) {
                onIncrementCallsFail(task);
                console.error("APIService catch all error occured: ", e);
                errorFn(e.message);
            }
        }
    };

    render() {
        return null;
    }
}

export default APIService;