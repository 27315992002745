import RS from "@common/strings/RS";
import {
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const TFREditor = {
    Create : (CSModvars, firstYear) => {
        let editorName = RS('FP_stTFR');
        let packTable = Setup(CSModvars, firstYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable.Title = editorName;
        return (packTable);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let BYIndex = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        let TFRMV = getModvarCloneByTag(CSModvars, CONSTANTS.FP_TG_TTFR_MV);

        TFRMV["value"][CONSTANTS.FP_All_Ages][BYIndex] = pt.tableData.value[1][1];

        changedModvars.push(TFRMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, firstYear) => {
    let BYIndex = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];

    let pt = getNewPT();

    pt = resizePackTable(pt, 2, 2);

    pt.tableData.value[0][1] = getYearFromIdx(firstYear, BYIndex).toString();
    pt.Alignments[0][1] = CONSTANTS.GB_Right;

    pt.tableData.value[1][0] = RS('FP_stTFR');
    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.RDec[1][1] = 2;

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let BYIndex = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let TFR = getModvarCloneByTag(CSModvars, CONSTANTS.FP_TG_TTFR_MV)["value"];
    pt.tableData.value[1][1] = TFR[CONSTANTS.FP_All_Ages][BYIndex];
    return pt;
};

