import { clone } from "@common/util/GBUtil";
import RS from "@common/strings/RS";
import CSTemplate from "data/CSTemplate";
import { getIdxFromYear, getCalcYearIdx } from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";
import { InitResultExArray } from "../../../utilities/Extract/Extract";
import { SpectrumService2 } from "../api/server_calls";

const GB_English     = 1;
// const GB_French      = 2;
// const GB_Arabic      = 3;
// const GB_Spanish     = 4;
// const GB_Russian     = 5;
// const GB_Portuguese  = 6;
// const GB_Chinese     = 7;
// const GB_Indonesian  = 8;

export const Process = (props) => {
    const { API, recalculateProjs, indicatorsSelected, projectionsSelected, setProgressMessage, setIndX, setProjX,
        years, onProccessSuccess, onProccessError } = props;

    //====================================================================================
    //  Local variables
    //====================================================================================

    let allChartOptions = CSTemplate.Projection.chartOptions;
    let language = GB_English;
    let activeProjections = [];
    let result;

    // let result = [
    //     [1, 2, 3], 
    //     [4, 5, 6]
    // ];

    //====================================================================================
    //  API
    //====================================================================================

    const openProjection = (projName, successFn, errorFn) => {
        setProgressMessage(RS("GB_stOpenProjectionFlag"));
        API('openProjection', { FileTitle : projName }, () => {
            successFn();
        }, errorFn);
    };

    const calculateProjection = (successFn, errorFn) => {
        if (recalculateProjs) {
            API('calculateProjection', { Proj : 1 }, () => {
                successFn();
            }, errorFn);      
        }
        else {
            successFn();
        }
    };

    const getActiveProjectionList = (successFn, errorFn) => {
        setProgressMessage(RS("GB_stGetActiveProjectionListFlag"));
        API('getActiveProjectionList', {}, (response) => {
            activeProjections = response;
            successFn();
        }, errorFn);
    };

    const closeProjection = (successFn, errorFn) => {
        setProgressMessage(RS("GB_stCloseProjectionFlag"));
        API('closeProjection', { Proj : 1 }, () => {
            successFn();
        }, errorFn);
    };

    const closeAllProjections = (successFn, errorFn) => {
        setProgressMessage(RS("GB_stCloseProjectionFlag"));
        API('closeProjection', { Proj : 0 }, () => {
            successFn();
        }, errorFn);
    };

    const destroyExtractFile = (successFn, errorFn) => {
        setProgressMessage('Destroying previous extract file');
        API('destroyExtractFile', { }, () => {
            successFn();
        }, errorFn);
    };

    const appendExtractFile = (params, successFn, errorFn) => {
        let extractLine = params.extractLine;
        setProgressMessage('Appending extract file');
        API('appendExtractFile', { extractLine : extractLine }, () => {
            successFn();
        }, errorFn);
    };

    //====================================================================================
    //  Utilities
    //====================================================================================

    const GetProjectionInfo = (proj, activeProjections) => {
        let Projection = activeProjections[proj - 1];

        const projInfo = {
            ...Projection,
            projName: Projection.name,
            // firstYear: Projection.firstYear,
            // calcYear: Projection.calcYear,
            // finalYear: Projection.finalYear,
            // projName: Projection.name
        };

        projInfo["firstYear"] = projInfo["calcYear"];

        return projInfo;
    };

    const GetChartOptions = (modID, indMstID, errorFn) => {
        let mstID = indMstID;
        
        let chartOptions = allChartOptions.find(x => (
            (x.modID === modID) && (x.resultID === mstID)
        ));

        if (chartOptions) {
            chartOptions["endPoints"] = false;
            return clone(chartOptions);
        }
        else {
            console.error(`ChartOptions were not found for indicator ${indMstID}!`);
            // errorFn(`ChartOptions were not found for indicator ${indMstID}!`);
        }
    };

    const  GetFirstExYear = (projInfo) => {
        /* First year of data which will be written out for the current projection being processed. */
        return Math.max(years.min, projInfo.calcYear);
    };

    const  GetFinalExYear = (projInfo) => {
        /* Final year of data which will be written out for the current projection being processed. */
        return Math.min(years.max, projInfo.finalYear);
    };

    const  GetFirstExYearIndex = (projInfo) => {
        /* Index of the "FirstExYear." */
        return GetFirstExYear(projInfo) - projInfo.calcYear + getCalcYearIdx(projInfo.firstYear);
    };

    const  GetFinalExYearIndex = (projInfo) => {
        /* Index of the "FinalExYear." */
        return GetFinalExYear(projInfo) - projInfo.calcYear + getCalcYearIdx(projInfo.firstYear);
    };

    const  GetExDataStartCol = (projInfo) => {
        /* Column in output file at which data will begin for current projection. */
        let result = CONSTANTS.EX_FirstYearCol;
        if (projInfo.calcYear > years.min) {
            result = result + (projInfo.calcYear - years.min);
        }
        return result;
    };

    const GetChartYears = (projInfo) => {
        return {
            /* Index of the first year of the current projection. */
            firstYrIdx : getIdxFromYear(projInfo.calcYear, projInfo.calcYear),

            /* Index of the final year of current projection. */
            finalYrIdx : getIdxFromYear(projInfo.calcYear, projInfo.finalYear),

            /* Index of the first year of data which will be written out for the current projection. */
            firstExYearIndex : GetFirstExYearIndex(projInfo),

            /* Index of the final year of data which will be written out for the current projection. */
            finalExYearIndex : GetFinalExYearIndex(projInfo),

            /* Column in output file at which data will begin for current projection. */
            ExDataStartCol : GetExDataStartCol(projInfo),

            min : projInfo.calcYear,
            max : projInfo.finalYear,
        };
    };

    const _errorCleanUp = (msg) => {
        closeAllProjections(() => {
            onProccessError(msg);
        }, () => {
            onProccessError(msg);
        });
    };

    //====================================================================================
    //  Indicator work
    //==================================================================================== 
    
    const updateIndicatorMstIDs = (indicators) => {
        for (let i = 0; i < indicators.length; i++){
            let mstID = indicators[i].mstID;

            if (indicators[i].modID === CONSTANTS.GB_DP) {
                if (mstID === CONSTANTS.DP_MstcmPop04) {
                    mstID = CONSTANTS.DP_MstcmTotalPop;
                }
            }

            if (indicators[i].modID === CONSTANTS.GB_FP) {
                if (mstID === CONSTANTS.FP_MstcmNumUnintendPreg) {
                    mstID = CONSTANTS.FP_MstcmPregnancies;
                }
            }
            indicators[i].mstID = mstID;
        }
        
        return indicators;
    };

    const processIndicator_datapackserver = async (successFn, errorFn) => {
        await new Promise(resolve => setTimeout(resolve, 50));

        try {
            let indicators = indicatorsSelected.map(x => ({ modID: x[1], mstID: x[0], chartOptions: GetChartOptions(x[1], x[0], errorFn) }));
            let projInfo = GetProjectionInfo(1, activeProjections);
            let chartYears = GetChartYears(projInfo);

            indicators = updateIndicatorMstIDs(indicators);

            SpectrumService2.getDataPackExtracts({
                indicators : indicators,
                chartYears : chartYears,
                language : language,
            }, (response) => {
                let array2D = response.sheet;

                for (let row = 0; row < array2D.length; row++) {
                    result.push(array2D[row]);
                }

                for (let i = 1; i <= indicators.length; i++) {
                    setIndX(i);
                }

                successFn();
            }, (msg) => {
                errorFn(msg);
            });
        }
        catch (e) {
            console.error(`Error: processIndicator_datapackserver encountered an error during extracting.`, e);
            errorFn();
            // errorFn(`Indicator "${indName}" encountered an error during extracting.`);
            // throw `Error: Indicator "${indName}" encountered an error during extracting.`;
        }

        /* Example */
        // await new Promise(resolve => setTimeout(resolve, 2000));                       
        // result.push([`Indicator index ${i}`]);
        // result.push(["r0_1", "r1_1", "r2_1"]);
        // result.push(["r0_2", "r1_2", "r2_2"]);
        // result.push(["r0_3", "r1_3", "r2_3"]);
    };

    //====================================================================================
    //  Main
    //====================================================================================
    
    const processIndicators = async (successFn, errorFn) => {
        setProgressMessage("Working on indicators");

        processIndicator_datapackserver(successFn, errorFn);
    };

    const doSingleProjection = (p, successFn, errorFn) => {
        if (p > projectionsSelected.length) {
            successFn(result);
            return;
        }

        setProjX(p);
        setIndX(0);

        let projName = projectionsSelected[p - 1];

        result = [];
        if (p === 1){
            result = InitResultExArray(years);
        }

        openProjection(projName, () => {
            calculateProjection(() => {
                getActiveProjectionList(() => {
                    processIndicators(() => {
                        appendExtractFile({extractLine: result}, () => {
                            closeProjection(() => {
                                doSingleProjection(p + 1, successFn);
                            }, errorFn);
                        }, errorFn);
                    }, errorFn);
                }, errorFn);
            }, errorFn);
        }, errorFn);
    };

    const doAllProjections = (successFn, errorFn) => {
        closeAllProjections(() => {
            destroyExtractFile(() => {
                doSingleProjection(1, () => {
                    successFn(result);
                }, errorFn);
            }, errorFn);
        }, errorFn);
    };

    try {
        doAllProjections(onProccessSuccess, _errorCleanUp);
    }
    catch (e) {
    }
};

