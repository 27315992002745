import React from 'react';
import TButton from '@common/components/TButton';

const NotSignedInPage = (props) => {
    const { onPageChange } = props;

    const onLoginClick = () => {
        onPageChange("LoginPage");
    };

    return (
        <React.Fragment>
            <label>You must login to use Extract.</label>
            <br />
            <br />
            <TButton
                caption="Log in"
                onClick={onLoginClick}
            />
        </React.Fragment>
    );
};

export default NotSignedInPage;