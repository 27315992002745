export const clone = obj => {
    return JSON.parse(JSON.stringify(obj));
};

export const MergeArraysNoDuplicates = (array1, array2) => {
    return [...new Set([...array1 ,...array2])];
};

export const MergeArraysWithDuplicates = (array1, array2) => {
    return [...array1,...array2];
};

export const Flatten2DArray = (array2D) => {
    return [].concat(...array2D);
};

export const GBPrettyPrint = (json) => {

    // https://github.com/lydell/json-stringify-pretty-compact/blob/master/index.js + custom changes

    function stringify (obj, options) {
        options = options || {}
        var indent = JSON.stringify([1], null, get(options, 'indent', 2)).slice(2, -3)
        var addMargin = get(options, 'margins', false)
        var maxLength = (indent === '' ? Infinity : get(options, 'maxLength', 80))

        return (function _stringify (obj, currentIndent, reserved) {
            if (obj && typeof obj.toJSON === 'function') {
                obj = obj.toJSON()
            }

            var string = JSON.stringify(obj)

            if (string === undefined) {
                return string
            }

            var length = maxLength - currentIndent.length - reserved

            if (string.length <= length) {
                if (string[0] !== '{') {                                             // TGP
                    if (!((string[0] === '[') && (string[1] === '['))) {             // TGP
                        if (!((string[0] === '[') && (string[1] === '{'))) {         // TGP
                            var prettified = prettify(string, addMargin)
                            if (prettified.length <= length) {
                                return prettified
                            }
                        }
                    }
                }
            }

            if (typeof obj === 'object' && obj !== null) {
                var nextIndent = currentIndent + indent
                var items = []
                var delimiters
                var comma = function (array, index) {
                    return (index === array.length - 1 ? 0 : 1)
                }

                if (Array.isArray(obj)) {
                    for (var index = 0; index < obj.length; index++) {
                        items.push(
                            _stringify(obj[index], nextIndent, comma(obj, index)) || 'null'
                        )
                    }
                    delimiters = '[]'
                } else {
                    Object.keys(obj).forEach(function (key, index, array) {
                        var keyPart = JSON.stringify(key) + ': '
                        var value = _stringify(obj[key], nextIndent, keyPart.length + comma(array, index))
                        if (value !== undefined) {
                            items.push(keyPart + value)
                        }
                    })
                    delimiters = '{}'
                }

                if (items.length > 0) {
                    return [
                        delimiters[0],
                        indent + items.join(',\n' + nextIndent),
                        delimiters[1]
                    ].join('\n' + currentIndent)
                }
            }

            return string
        }(obj, '', 0))
    }

    // Note: This regex matches even invalid JSON strings, but since we’re
    // working on the output of `JSON.stringify` we know that only valid strings
    // are present (unless the user supplied a weird `options.indent` but in
    // that case we don’t care since the output would be invalid anyway).
    var stringOrChar = /("(?:[^\\"]|\\.)*")|[:,\][}{]/g

    function prettify (string, addMargin) {
        var m = addMargin ? ' ' : ''
        var tokens = {
            '{': '{' + m,
            '[': '[' + m,
            '}': m + '}',
            ']': m + ']',
            ',': ', ',
            ':': ': '
        }
        return string.replace(stringOrChar, function (match, string) {
            return string ? match : tokens[match]
        })
    }

    function get (options, name, defaultValue) {
        return (name in options ? options[name] : defaultValue)
    }

    var str = stringify(json, { indent: 4, maxLength: Infinity });
    return str;
};

export const ConvertCSVToJSON = (csvText) => {
    var csvRows = [];
    var objArr = [];

    function parseCSVLine(line) {
        let i;
        line = line.split(",");

        // check for splits performed inside quoted strings and correct if needed
        for (i = 0; i < line.length; i++) {
            var chunk = line[i].replace(/^[\s]*|[\s]*$/g, "");
            var quote = "";
            if (chunk.charAt(0) === '"' || chunk.charAt(0) === "'") quote = chunk.charAt(0);
            if (quote !== "" && chunk.charAt(chunk.length - 1) === quote) quote = "";

            if (quote !== "") {
                var j = i + 1;

                if (j < line.length) chunk = line[j].replace(/^[\s]*|[\s]*$/g, "");

                while (j < line.length && chunk.charAt(chunk.length - 1) !== quote) {
                    line[i] += "," + line[j];
                    line.splice(j, 1);
                    chunk = line[j].replace(/[\s]*$/g, "");
                }

                if (j < line.length) {
                    line[i] += "," + line[j];
                    line.splice(j, 1);
                }
            }
        }

        for (i = 0; i < line.length; i++) {
            // remove leading/trailing whitespace
            line[i] = line[i].replace(/^[\s]*|[\s]*$/g, "");

            // remove leading/trailing quotes
            if (line[i].charAt(0) === '"') line[i] = line[i].replace(/^"|"$/g, "");
            else if (line[i].charAt(0) === "'") line[i] = line[i].replace(/^'|'$/g, "");
        }

        return line;
    }

    function csvToJson(csvText) {
        let i;
        let j;
        var error = false;

        if (csvText === "") {
            error = true;
        }

        if (!error) {
            csvRows = csvText.split(/[\r\n]/g); // split into rows

            // get rid of empty rows
            for (i = 0; i < csvRows.length; i++) {
                if (csvRows[i].replace(/^[\s]*|[\s]*$/g, "") === "") {
                    csvRows.splice(i, 1);
                    i--;
                }
            }

            if (csvRows.length < 2) {
                error = true;
            } else {
                objArr = [];

                for (i = 0; i < csvRows.length; i++) {
                    csvRows[i] = parseCSVLine(csvRows[i]);
                }

                for (i = 1; i < csvRows.length; i++) {
                    if (csvRows[i].length > 0) objArr.push({});

                    for (j = 0; j < csvRows[i].length; j++) {
                        objArr[i - 1][csvRows[0][j]] = csvRows[i][j];
                    }
                }

                // jsonText = JSON.stringify(objArr, null, "\t");

                return objArr;
            }
        }
    }

    let json = csvToJson(csvText);
    return json;
};

export const ConvertCSVTo2DArray = (csvText) => {
    var csvRows = [];

    function parseCSVLine(line) {
        let i;
        line = line.split(",");

        // check for splits performed inside quoted strings and correct if needed
        for (i = 0; i < line.length; i++) {
            var chunk = line[i].replace(/^[\s]*|[\s]*$/g, "");
            var quote = "";
            if (chunk.charAt(0) === '"' || chunk.charAt(0) === "'") quote = chunk.charAt(0);
            if (quote !== "" && chunk.charAt(chunk.length - 1) === quote) quote = "";

            if (quote !== "") {
                var j = i + 1;

                if (j < line.length) chunk = line[j].replace(/^[\s]*|[\s]*$/g, "");

                while (j < line.length && chunk.charAt(chunk.length - 1) !== quote) {
                    line[i] += "," + line[j];
                    line.splice(j, 1);
                    chunk = line[j].replace(/[\s]*$/g, "");
                }

                if (j < line.length) {
                    line[i] += "," + line[j];
                    line.splice(j, 1);
                }
            }
        }

        for (i = 0; i < line.length; i++) {
            // remove leading/trailing whitespace
            line[i] = line[i].replace(/^[\s]*|[\s]*$/g, "");

            // remove leading/trailing quotes
            if (line[i].charAt(0) === '"') line[i] = line[i].replace(/^"|"$/g, "");
            else if (line[i].charAt(0) === "'") line[i] = line[i].replace(/^'|'$/g, "");
        }

        return line;
    }

    function csvTo2DArray(csvText) {
        let i;
        var error = false;

        if (csvText === "") {
            error = true;
        }

        if (!error) {
            csvRows = csvText.split(/[\r\n]/g); // split into rows

            // get rid of empty rows
            for (i = 0; i < csvRows.length; i++) {
                if (csvRows[i].replace(/^[\s]*|[\s]*$/g, "") === "") {
                    csvRows.splice(i, 1);
                    i--;
                }
            }

            if (csvRows.length < 2) {
                error = true;
            } else {
                for (i = 0; i < csvRows.length; i++) {
                    csvRows[i] = parseCSVLine(csvRows[i]);
                }

                return csvRows;
            }
        }
    }

    let array2D = csvTo2DArray(csvText);
    return array2D;
};

export const DownloadCSV = (csvContent, fileName = "data") => {
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
};

export const Download2DArrayToCSV = (array2D, fileName = "data") => {
    /* Array2D to CSV */
    let csvContent = "data:text/csv;charset=utf-8," + array2D.map(e => e.join(",")).join("\n");

    DownloadCSV(csvContent, fileName);
};

export const DownloadCSVFromCSVConvert = (csvContent, fileName = "data") => {
    let csvContent2 = "data:text/csv;charset=utf-8," + csvContent;
    DownloadCSV(csvContent2, fileName);
};

export const CSVTextToFile = (csvText, fileName = "data") => {
    // let array2D = ConvertCSVTo2DArray(`1,2,3
    // 4,5,6
    // 7,8,9`);

    let array2D = ConvertCSVTo2DArray(csvText);

    /* Array2D to CSV */
    let csvContent = "data:text/csv;charset=utf-8," + array2D.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
};

export const CSVTextToFile2 = (csvText, fileName = "data") => {
    // let csvText = `1,2,3
    // 4,5,6
    // 7,8,9`;

    /* Array2D to CSV */
    let csvContent = "data:text/csv;charset=utf-8," + csvText;

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
};