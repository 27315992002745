const CONSTANTS = {
    
    //============================================
    // MODULE
    //============================================

    GB_DP                        : 1,
    GB_FP                        : 3,
    GB_AM                        : 4,
    GB_CS                        : 15,
    GB_CS_SubNat                 : 39,

    //============================================
    // LANGUAGE
    //============================================

    GB_English                   : 1,
    GB_French                    : 2,
    GB_Arabic                    : 3,
    GB_Spanish                   : 4,
    GB_Russian                   : 5,
    GB_Portuguese                : 6,
    GB_Chinese                   : 7,
    GB_Indonesian                : 8,

    //============================================
    // DEFAULT
    //============================================

    GB_MaxNativeProjectionYear   : 1985,

    //============================================
    // EXTRACT
    //============================================

    /* Standard format column and row constants */
    EX_FQNameCol                 : 0,
    EX_CountryNameCol            : 1,
    EX_ISOCodeCol                : 2,
    EX_SubnatRegNameCol          : 3,
    EX_ModNameCol                : 4,
    EX_IndNameCol                : 5,
    EX_ConfigCol                 : 6,
    EX_FirstYearCol              : 7,
    EX_NumStandardCols           : 8,

    EX_VersionRow                : 0,
    EX_VersionCol                : 0,
    EX_HeadingsRow               : 1,

    //============================================
    // TABLE
    //============================================

    CS_ChildChartColor           : 12351547,
    CS_NNChartColor              : 15443813,

    GB_ED_Indent1                : 15,
    GB_ED_Indent2                : 30,
    GB_ED_Indent3                : 45,
    GB_ED_Indent4                : 60,

    GB_Bold                      : 0,
    GB_Italic                    : 1,
    GB_Underline                 : 2,

    GB_Left                      : 0,
    GB_Right                     : 1,
    GB_Center                    : 2,

    LockedCellColor              : 8421504,

    GB_OneEditorSrc              : 0,
    GB_RowSrcEditor              : 1,
    GB_ColSrcEditor              : 2,
    GB_RowSecSrcEditor           : 3,

    BASIC_ResultPackTable : {
        ID                       : 0,
        GBRowCount               : 1,
        GBColCount               : 1,
        GBFixedRows              : 1,
        GBFixedCols              : 1,
        tableData                : {
            value                : []
        },
        RDec                     : [],
        MergedCells              : [],
        LockedCells              : [],
        FontColors               : [],
        GBColWidths              : [],
        GBCellComment            : [],
        GBUseTriangle            : [],
        title                    : '',
        hasData                  : true,
        hasDeselectedIntervs     : false,
        IntervRecords            : [null],
        RowIds                   : [],
        MinAllowedVal            : [],
        MaxAllowedVal            : [],
        FontStyles               : [],
        FontSizes                : [],
        IndentRowText            : [{xoffset : 0, Value : false}],
        Alignments               : [],
        yearArr                  : [],
        Source                   : [],
        SourceTitle              : [],
        SourceMap                : [],
        SourceMode               : 1,
        EditorCat                : 0,
        useHeadersInChartLbls    : false,
        useSubHeadersInChartLbls : true,
    },

    BASIC_ResultPackChart : {
        title                    : '',
        alignTitle               : 'middle',
        subTitle                 : '',
        alignSubTitle            : 'middle',
        MultiSubTitles           : ['', ''],
        chartType                : 'line',
        legendAlign              : 'center',
        ShowLegend               : true,
        xAxisLabel               : '',
        pointLabels              : [],
        yAxisLabel               : '',
        RYAxisLabel              : '',
        ManualMinY               : 0,
        ManualMaxY               : 0,
        ManualStackedMinY        : 0,
        ManualStackedMaxY        : 0,
        chartData                : [],
        subsetLabels             : [],
        subsetColors             : [],
        subsetChartTypes         : [0],
        subsetPointTypes         : [-1],
        subsetLineTypes          : [-1],
        SubsetsToLegend          : [0],
        hasData                  : true,
        hasDeselectedIntervs     : false,
        RDec                     : 0,
        verticalLabels           : [],
        chartLabelLeftMargin     : 75,
    },
};

export default CONSTANTS;