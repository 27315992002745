const RS = (strID) => {
    let returnedString = '';

    if (typeof strID === "number") {
        returnedString = window.strings[strID]['lang'][window.languageIdx];
    }
    else {
        let found = false;

        for (let i = 0; i < window.strings.length; i++) {
            if (window.strings[i]['stringID'] === strID) {
                returnedString = window.strings[i]['lang'][window.App.languageIdx];
                found = true;
                break;
            }
        }

        if (!found) {
            let badStringID = strID;
            if (badStringID === '') {
                badStringID = "*The strID was blank*";
            }
            console.error("RS stringID does not exist: " + badStringID);
        }
    }

    return returnedString;
};

export default RS;
