import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    generatePackChart, addRowToPackTable, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    CSGetBirthTermAbbrev,
    getCSGetDeathCause
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const ImpactsBirthOutcomesOnMortEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stImpactBirthOutcomesOnMort');
        let packTable = Setup(CSModvars);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ImpactUnderNutrition;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let RelRisksIUGR_LBW = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_RelRisksIUGR_LBW_MV);

        let row = 2;
        pt.CauseOfDeathSet.forEach(function(d){
            let col = 1;
            for (let i = CONSTANTS.CS_TermAGA; i >= CONSTANTS.CS_PreTermSGA; i--){
                RelRisksIUGR_LBW["value"][CONSTANTS.CS_Data][d][i] = pt.tableData.value[row][col];
                col++;
            }
            row++;
        });

        RelRisksIUGR_LBW["source"][0][0] = pt.Source[0];

        changedModvars.push(RelRisksIUGR_LBW);

        return (changedModvars);
    },
};

const Setup = (CSModvars) => {
    const numCols = 5;
    const numRows = 2;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let i = CONSTANTS.CS_TermAGA; i >= CONSTANTS.CS_PreTermSGA; i--){
        pt.tableData.value[0][col] = CSGetBirthTermAbbrev(i);
        pt.tableData.value[1][col] = RS('GB_stRelRisk');
        col++;
    }

    pt.CauseOfDeathSet = [
        CONSTANTS.CS_NNDiarr,
        CONSTANTS.CS_NNSepsis,
        CONSTANTS.CS_NNPneumonia,
        CONSTANTS.CS_NNAsphyxia,
        CONSTANTS.CS_NNPreterm,
        CONSTANTS.CS_NNTetanus,
        CONSTANTS.CS_NNCongen,
        CONSTANTS.CS_NNOther
    ];

    let row = 2;
    pt.CauseOfDeathSet.forEach(function(d){
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = getCSGetDeathCause(CSModvars, d);
        let col = 1;
        for (let i = CONSTANTS.CS_TermAGA; i >= CONSTANTS.CS_PreTermSGA; i--){
            pt.Alignments[row][col] = CONSTANTS.GB_Right;
            pt.RDec[row][col] = 3;
            pt.MaxAllowedVal[row][col] = 1000;
            col++;
        }
        row++;
    });

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let RelRisksIUGR_LBW = getModvarByTag(CSModvars, CONSTANTS.CS_TG_RelRisksIUGR_LBW_MV)["value"][
        CONSTANTS.CS_Data];

    let RelRisksIUGR_LBWSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_RelRisksIUGR_LBW_MV)["source"];

    let row = 2;
    pt.CauseOfDeathSet.forEach(function(d){
        let col = 1;
        for (let i = CONSTANTS.CS_TermAGA; i >= CONSTANTS.CS_PreTermSGA; i--){
            pt.tableData.value[row][col] = RelRisksIUGR_LBW[d][i];
            col++;
        }
        row++;
    });

    pt.Source[0] = RelRisksIUGR_LBWSrc[0][0];

    return pt;
};

