import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag, generatePackChart, add_NoHistoricalData_MsgToPackTable
} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";

export const SBRateEditor = {
    Create : (CSModvars) => {
        let editorName = RS('GB_stStillbirth');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_Stillbirths;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let SBRateMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_SBRate_MV2)
        SBRateMV["value"] = pt.tableData.value[1][1];
        SBRateMV["source"][0][0] = pt.Source[0];

        changedModvars.push(SBRateMV);
        return (changedModvars);
    },
};

const Setup = () => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    const numRows = 2;
    const numCols = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[1][0] = RS('GB_stStillbirthRatePer1000');
    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.RDec[1][1] = 2;

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let SBRate = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SBRate_MV2);
    pt.tableData.value[1][1] = SBRate["value"];
    pt.Source[0] = SBRate["source"][0][0];
    return pt;
};

