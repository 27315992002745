import * as React from "react";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
// import { makeStyles } from '@material-ui/styles';

const TEdit = (props) => {
    
    const {
        caption,
        name,
        value,
        type,
        onChange,
        className,
        style,
        inputStyle,
        foc,
        disabled,
        shrinkLabel,
        // floatingLabelColor,
        fullWidth
    } = props;

    const styles = {
        edit : {
            marginRight: 25,
            paddingLeft: 0,
            marginBottom: 30,
            ...style
        }
    };

    // const classes = (makeStyles({
    //     root: {
    //         color: floatingLabelColor 
    //     }
    // }))();

    const onChangeFn = (event) => {
        onChange(event.target.value);
    };
    
    return (
        <TextField
            className       = {className}
            label           = {caption}
            value           = {value}
            disabled        = {disabled}
            id              = {name}
            style           = {styles.edit}
            type            = {type}
            inputProps      = {inputStyle}
            autoFocus       = {foc || false}
            onChange        = {onChangeFn} 
            InputLabelProps = {{ shrink: shrinkLabel }}
            // InputLabelProps = {{ shrink: shrinkLabel, FormLabelClasses : { root: classes.root } }}
            fullWidth       = {fullWidth}
        />
    );
};

TEdit.propTypes = {
    className          : PropTypes.string, 
    caption            : PropTypes.string,
    name               : PropTypes.string,
    value              : PropTypes.string,
    type               : PropTypes.string,
    onChange           : PropTypes.func,
    style              : PropTypes.object,
    inputStyle         : PropTypes.object,
    foc                : PropTypes.bool,
    disabled           : PropTypes.bool,
    shrinkLabel        : PropTypes.bool,
    floatingLabelColor : PropTypes.string,
    fullWidth          : PropTypes.bool,
};

TEdit.defaultProps = {
    className          : undefined, 
    caption            : 'Caption',
    name               : 'TEdit1',
    value              : '',
    type               : '',
    style              : {},
    inputStyle         : {},
    foc                : false,
    disabled           : false,
    shrinkLabel        : undefined,
    floatingLabelColor : undefined,
    fullWidth          : false,
    onChange           : (value) => console.log('onChange [' + value + ']'),
};

export default TEdit;