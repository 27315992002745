import React from "react";
import RS from "@common/strings/global";

const IntroductionBox = (props) => {
    const styles = {
        title: {
            fontSize: "34px",
            fontWeight: "bold",
        },
        p: {
            fontSize: "24px",
            lineHeight: "35px",
            marginBottom: 0,
        },
        backgroundDiv: {
            backgroundColor : 'rgba(84, 113, 143, .5)',
            borderRadius : 5,
        },
        textDiv: {
            color: "#FFFFFF",
            padding: 20,
        },
        subTitle: {
            fontSize: "18px",
            display: "inline-block",
        },
    };

    return (
        <div style={styles.backgroundDiv}>
            <div style={styles.textDiv}>
                <span style={styles.title}>
                    {RS("GB_stWelcomeToExtract")} &nbsp;
                    <div style={styles.subTitle}>{RS("GB_stExtractSubTitle")}</div>
                </span>

                <p style={styles.p}>{RS("GB_stExtractHomeText")}</p>
            </div>
        </div>
    );
};

export default IntroductionBox;