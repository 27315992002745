import React from 'react';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import TExpansionPanel from "@common/components/TExpansionPanel";
import RS from "@common/strings/RS";

class YourResults extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        yourResultNames     : PropTypes.arrayOf(PropTypes.object),
        onClearResultsClick : PropTypes.func
    };

    static defaultProps = {
        yourResultNames     : [],
        onClearResultsClick : () => {}
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render () {
        const styles = {
            paperBox: {
                width: '388px',
                marginBottom: '15px',
                position: 'absolute',
                float: 'left',
                background: '#FFFFFF',
                borderRadius: 0,
                border: '1px solid #ebebeb',
                boxShadow: 'none',
            },
            expansionPanel: {
                marginTop: 0,
                marginBottom: 0
            },
            expansionPanelSummary : {
                backgroundColor: '#FFFFF',
                maxHeight: '45px',
                minHeight: 0,
                width: '390px',
                marginLeft: '-1px'
            },
            header : {
                fontSize: '22px',
                fontWeight: 'bold',
                color: '#cb6814'
            },
            expandMoreIcon : {
                color: '#000000'
            },
            expansionPanelDetails : {
                display: 'block',
                backgroundColor: '#ebebeb',
                fontSize: '13px',
                paddingLeft: '24px',
                paddingTop: '5px',
                paddingBottom: '15px'
            },
            expansionPanelAction : {
                paddingTop : 0,
                paddingBottom: 0
            },
            buttonStyle : {
                fontSize: 16,
                //border: "none"
            }
        };

        return(
            <Paper style={styles.paperBox}>
                <TExpansionPanel
                    defaultExpanded={true}
                    expansionPanelStyle={styles.expansionPanel}
                    expansionPanelSummaryStyle={styles.expansionPanelSummary}
                    headerStyle={styles.header}
                    expandMoreIconStyle={styles.expandMoreIcon}
                    expansionPanelDetailsStyle={styles.expansionPanelDetails}
                    expansionPanelActionStyle={styles.expansionPanelAction}
                    showButton={(this.props.yourResultNames.length > 0)}
                    buttonLabel={RS("GB_stClear")}
                    buttonStyle={styles.buttonStyle}
                    buttonVariant={"text"}
                    onButtonClick={this.props.onClearResultsClick}
                    items={[[0, RS("GB_stYourSelectedResults"), false, '', false]]}
                    content={[[this.props.yourResultNames]]}
                />
            </Paper>
        );
    }

}

export default YourResults;
