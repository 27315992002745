import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import DisplayMO from './DisplayMO'
import MOChartOptions from './MOChartOptions'
import CONSTANTS from "utilities/CS/CSConst";
// import CSMissedOpportunitiesForm from "./CSMissedOpportunitiesFm";
 
class MOSection extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        displayChart                   : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        MOSideBarOpen                  : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        displayChart                   : true,
        selectedCountry                : 'Afghanistan',
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        displayCPR                     : true,
        MOSideBarOpen                  : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        language                       : CONSTANTS.GB_English
    };

    state = {
        chart: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            tableRef: null
        };
    }

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    getLegendOptions = () => {
        let legend = {
            reversed : true,
        };

        return(legend)
    };

    renderMOChartOptions = () => {
        return (
            <MOChartOptions
                onStateChange            = {this.props.onStateChange}
                displayChart             = {this.props.displayChart}
                sortByDeathsAverted      = {this.props.sortByDeathsAverted}
                sortByInterventionCost   = {this.props.sortByInterventionCost}
                viewInterventionCost     = {this.props.viewInterventionCost}
                displayMaternalCOD       = {this.props.displayMaternalCOD}
                displayStillbirthCOD     = {this.props.displayStillbirthCOD}
                displayNeonatalCOD       = {this.props.displayNeonatalCOD}
                displayChildCOD          = {this.props.displayChildCOD}
                maternalIntervSelected   = {this.props.maternalIntervSelected}
                stillbirthIntervSelected = {this.props.stillbirthIntervSelected}
                neonatalIntervSelected   = {this.props.neonatalIntervSelected}
                childIntervSelected      = {this.props.childIntervSelected}
                MOResultToDisplay        = {this.props.MOResultToDisplay}
                chart                    = {this.state.chart}
                tableRef                 = {this.state.tableRef}
                passData                 = {(o) => this.setState(o)}
            />
        )
    };

    renderDisplayMO = () => {
        return (
            <DisplayMO
                displayChart                   = {this.props.displayChart}
                showLegend                     = {(this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted)}
                minHeightChart                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 600 : 1000}
                maxHeightChart                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 600 : 1000}
                minHeightTable                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 400 : 1000}
                maxHeightTable                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 400 : 1000}
                chartMarginLeft                = {300}
                xAxisLabelsPadding             = {25}
                stretch                        = {false}
                spaceReduction                 = {0}
                legend                         = {this.getLegendOptions()}
                creditsEnabled                 = {true}
                MOChartID                      = {'MOChart-MOSection'}
                MOTableID                      = {'MOTable-MOSection'}
                showTopInterv                  = {false}
                displayMODownloadButton        = {true}
                selectedCountry                = {this.props.selectedCountry}
                displayMaternalCOD             = {this.props.displayMaternalCOD}
                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                displayChildCOD                = {this.props.displayChildCOD}
                displayNutritionCOD            = {this.props.nutritionSelected}
                displayBirthOutcomeCOD         = {this.props.birthOutcomeSelected}
                displayCPR                     = {this.props.displayCPR}
                deliveryPointsContent          = {this.props.deliveryPointsContent}
                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                sortByInterventionCost         = {this.props.sortByInterventionCost}
                viewInterventionCost           = {this.props.viewInterventionCost}
                MOCountryData                  = {this.props.MOCountryData}
                onStateChange                  = {this.props.onStateChange}
                MOResultToDisplay              = {this.props.MOResultToDisplay}
                passData                       = {(o) => this.setState(o)}
                language                       = {this.props.language}
                //tableRef                       = {this.state.tableRef}
            />
        )
    };

    render() {
        return (

                <div>
                    {this.renderMOChartOptions()}
                    {this.renderDisplayMO()}
                </div>

        );
    }
}

export default MOSection;