import React from 'react';
import PropTypes from "prop-types";
import TCheckBox from "@common/components/TCheckBox";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

class EditTagsDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange     : PropTypes.func,
        visible           : PropTypes.bool,
        topicList         : PropTypes.array,
        onSaveTopic       : PropTypes.func,
        chosenTags        : PropTypes.string,
        fileToTag         : PropTypes.string
    };

    static defaultProps = {
        onStateChange     : () => console.log('onStateChange'),
        visible           : false,
        topicList         : [],
        onSaveTopic       : () => console.log("onSaveTopic"),
        chosenTags        : "",
        fileToTag         : ""
    };

    state = {
        chosenTags        : []
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogEditTagsOpen: false
        });
    };

    onEditBtnClick = () => {
        this.onClose();
    };

    onType = (val) => {
        this.setState({
            copiedFileName: val
        });
    };

    onEnter = (k) => {
        if (k === "Enter") {
            this.onCopyBtnClick();
        }
    };

    onCheckBoxChange = (tag) => {
        alert(tag);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderTagBox = () => {
        let tags = [];

        this.props.topicList.forEach((topic) => {
            tags.push(
                <TCheckBox
                    caption={topic}
                    value={this.state.chosenTags.includes(topic)}
                    onChange={() => this.onCheckBoxChange(topic)}
                />
            );
        });

        if (this.props.topicList.length === 0) {
            return "No tags are available";
        }
        else {
            return (
                <div>
                    {tags}
                </div>
            );
        }
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title="Edit Tag"
                onClose={this.onClose}
                content={
                    <div>
                        {this.renderTagBox()}
                    </div>
                }
                actions={
                    <div>
                        <ApplyButton onClick={this.onEditBtnClick} />
                    </div>
                }
            />
        );
    }

}

export default EditTagsDialog;