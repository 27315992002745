import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import ManageResultsContent from "./ManageResultsContent";

class ManageResultsDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onPageChange     : PropTypes.func,
        onStateChange    : PropTypes.func,
        selectedResults  : PropTypes.arrayOf(PropTypes.array),
        includeFamPlan   : PropTypes.bool,
        resultMenus      : PropTypes.object,
        style            : PropTypes.object,
        availableResults : PropTypes.array,
        onClose          : PropTypes.func,
        onOpenHelpDialog : PropTypes.func,
        language         : PropTypes.number,
        approach         : PropTypes.number
    };

    static defaultProps = {
        onPageChange     : () => console.log('onPageChange'),
        onStateChange    : () => console.log('onStateChange'),
        selectedResults  : [],
        includeFamPlan   : false,
        resultMenus      : {},
        style            : {},
        availableResults : [],
        onClose          : () => console.log('onClose'),
        onOpenHelpDialog : () => console.log("onOpenHelpDialog"),
        language         : CONSTANTS.GB_English,
        approach         : CONSTANTS.CS_StandardApproach
    };

    state = {
        selected        : [],
        openDrawer      : true,
        saveBtnDisabled : true,
    };

    componentDidMount () {
        this.setState({
            selected : JSON.parse(JSON.stringify(this.props.selectedResults)),
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            this.setState({
                selected : JSON.parse(JSON.stringify(nextProps.selectedResults)),
            });
        }
        return true;
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onSaveBtnClick = () => {
        this.props.onStateChange({
            selectedResults : this.state.selected
        }, () => {
            this.setState({
                saveBtnDisabled : true,
            });

            this.props.onClose();
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({
            selected        : JSON.parse(JSON.stringify(this.props.selectedResults)),
            saveBtnDisabled : true,
        });

        this.props.onClose();
    };

    onSelectedResultsChange = (value) => {
        this.setState({
            selected        : value,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <TDrawerWithHeader
                open            = {this.state.openDrawer}
                onClose         = {this.onCloseDrawerBtnClick}
                onSave          = {this.onSaveBtnClick}
                saveBtnCaption  = {RS('GB_stApply')}
                saveBtnDisabled = {this.state.saveBtnDisabled}
                onOpenHelpDialog= {this.props.onOpenHelpDialog}
                helpFile        = {"manage-selected-results.htm"}
                appBarText      = {RS('GB_stWhatAdditional')}
                content         = {
                                    <ManageResultsContent
                                        resultMenus             = {this.props.resultMenus}
                                        selectedResults         = {this.state.selected}
                                        includeFamPlan          = {this.props.includeFamPlan}
                                        onSelectedResultsChange = {this.onSelectedResultsChange}
                                        availableResults        = {this.props.availableResults}
                                        language                = {this.props.language}
                                        approach                = {this.props.approach}
                                    />
                                    }
            />
        );
    }

}

export default ManageResultsDrawer;
