import * as React from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';

class TCheckBox extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption     : PropTypes.string,
        value       : PropTypes.bool,
        name        : PropTypes.string,
        enabled     : PropTypes.bool,
        color       : PropTypes.string,
        labelStyle  : PropTypes.object,
        onClick     : PropTypes.func,
        style       : PropTypes.object,
        classes     : PropTypes.object,
        boxStyle    : PropTypes.object,
        tooltip     : PropTypes.string,
        icon        : PropTypes.any,
        checkedIcon : PropTypes.any,
        customProp  : PropTypes.any,
        customProp2 : PropTypes.any,
        isIcon      : PropTypes.bool
    };

    static defaultProps = {
        caption     : 'Caption',
        value       : null,
        name        : '',
        enabled     : true,
        color       : 'primary',
        labelStyle  : {},
        onClick     : (checked) => console.log('TCheckBox onClick [' + checked + ']'),
        style       : {},
        classes     : {},
        boxStyle    : {},
        tooltip     : '',
        // icon        : '',
        // checkedIcon : '',
        customProp  : '',
        customProp2 : '',
        isIcon      : false
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onChange = (event, checked) => {
        if (this.props.enabled) {
            this.props.onClick(checked, event, this.props.name, this.props.caption, this.props.customProp, this.props.customProp2);
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        const styles = {
            height: this.props.isIcon ? 42 : '30px',
            top: this.props.isIcon ? -6 : 0,
            color: this.props.enabled ? this.props.boxStyle.color : "lightgrey"
        };

        const unstyledComponent = props => (
            <FormGroup style={{height: 30, ...this.props.style}}>
                <FormControlLabel
                    control={
                        <Tooltip title={this.props.tooltip} >
                            <Checkbox
                                checked={this.props.value}
                                color={this.props.color}
                                disabled={!this.props.enabled}
                                onChange={this.onChange}
                                style={{...styles, ...this.props.boxStyle}}
                                icon={this.props.icon}
                                checkedIcon={this.props.checkedIcon}
                            />
                        </Tooltip>
                    }
                    label={this.props.caption}
                    classes={{
                        label: props.classes.label
                    }}
                />
            </FormGroup>
        );

        const StyledComponent = withStyles({
            label: {...this.props.labelStyle, color: this.props.enabled ? this.props.labelStyle.color : "lightgrey"} || {}
        })(unstyledComponent);

        return (
            <StyledComponent />
        );
    }

}

export default TCheckBox;