import * as React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import ExploreLiSTDataSideBar from "./sideBar/ExploreLiSTDataSideBar";
import ExploreDataBodyContent from "./content/ExploreDataBodyContent";
import CONSTANTS from "utilities/CS/CSConst";
import TAppBar from '../../../../components/APP/TAppBar';
import CSLogo from '../../../../components/CS/CSLogo';

class ExploreData extends React.PureComponent {

    static propTypes = {
        selectedCountry             : PropTypes.string,
        countryISO                  : PropTypes.number,
        countries                   : PropTypes.array,
        language                    : PropTypes.number,
        IVGroupInfo                 : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo              : PropTypes.arrayOf(PropTypes.object),
        IVInfo                      : PropTypes.arrayOf(PropTypes.object),
        inputModvars                : PropTypes.arrayOf(PropTypes.object),
        onStateChange               : PropTypes.func,
        onAddTasks                  : PropTypes.func,
        standAloneMode              : PropTypes.bool,
        menuThreshold               : PropTypes.bool,
    };

    static defaultProps = {
        selectedCountry             : '',
        countryISO                  : 4,
        countries                   : [],
        language                    : CONSTANTS.GB_English,
        IVGroupInfo                 : [],
        IVSubGroupInfo              : [],
        IVInfo                      : [],
        inputModvars                : [],
        onStateChange               : () => console.log('ExploreData onStateChange'),
        onAddTasks                  : () => console.log('ExploreData onAddTasks'),
        standAloneMode              : false,
        menuThreshold               : false
    };

    state = {
        sideBarOpen                 : true,
        displays                    : [],
        currentKey                  : 0,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.selectedCountry !== this.props.selectedCountry) {
            let newDisplays = this.state.displays.map(x => ({
                ...x,
                selectedCountry : this.props.selectedCountry
            }));
            this.onUpdateDisplays(newDisplays);
        }
    }

    onSideBarToggle = () => {
        this.setState(prevState => ({
            sideBarOpen : !prevState.sideBarOpen
        }), () => {
            window.dispatchEvent(new Event('minMax'));
        });
    };

    onAddDisplay = (newData) => {
        // Add a unique key to each display object
        let key = this.state.currentKey;
        let updatedData = newData.map(x => {
            key++;
            return {
                ...x, 
                itemKey : key, 
                selectedCountry : this.props.selectedCountry,
                // inputModvars : this.props.inputModvars,
            };
        });

        // Merge the two display arrays
        let oldDataArray = JSON.parse(JSON.stringify(this.state.displays));
        let newDataArray = oldDataArray.concat(updatedData);

        this.setState({
            displays   : newDataArray,
            currentKey : key
        });
    };

    onRemoveDisplay = (key) => {
        let newDataArray = this.state.displays.filter(x => (x.itemKey !== key));

        this.setState({
            displays : newDataArray
        })
    };

    onUpdateDisplays = (dataArray) => {
        this.setState({
            displays : dataArray,
        });
    };

    render() {
        const left = this.state.sideBarOpen ? 335 : 40;
        const top = this.props.standAloneMode ? this.props.menuThreshold ? 105 : 64 : 0;
        const bottom = 0;
        
        let styles = {
            appBar : {
                // background : '#494949',
            },
            titleStyle : {
                color: '#65a7eb',
                fontSize: 22,
                fontWeight: 'bold',
                fontFamily: 'lato'
            },
            content : {
                // position: 'absolute', 
                // left: this.state.sideBarOpen ? 335 : 40, 
                // right: 0, 
        
                padding: 10,
                height: 'calc(100% - ' + top + 'px - ' + bottom + 'px)',
                overflowY: 'auto',
                position: 'absolute',
                top: top,
                left: left,
                right: 0,
                bottom: bottom,
            }
        };

        return (
            <React.Fragment>
                
                {
                    this.props.standAloneMode ?
                        <TAppBar
                            caption                  = {RS('GB_stExploreLiSTData')}
                            showHamburger            = {false}
                            style                    = {styles.appBar}
                            logo                     = {<CSLogo />}
                            titleStyle               = {styles.titleStyle}
                        /> :
                        null
                }

                <ExploreLiSTDataSideBar 
                    sideBarOpen          = {this.state.sideBarOpen}
                    selectedCountry      = {this.props.selectedCountry}
                    countryISO           = {this.props.countryISO}
                    countries            = {this.props.countries}
                    language             = {this.props.language}
                    IVGroupInfo          = {this.props.IVGroupInfo}
                    IVSubGroupInfo       = {this.props.IVSubGroupInfo}
                    IVInfo               = {this.props.IVInfo}
                    inputModvars         = {this.props.inputModvars}
                    onStateChange        = {this.props.onStateChange}
                    onAddTasks           = {this.props.onAddTasks}
                    onAddDisplay         = {this.onAddDisplay}
                    onSideBarToggle      = {this.onSideBarToggle}
                    menuThreshold        = {this.props.menuThreshold}
                />
            
                <ExploreDataBodyContent 
                    selectedCountry      = {this.props.selectedCountry}
                    displays             = {this.state.displays}
                    IVInfo               = {this.props.IVInfo}
                    inputModvars         = {this.props.inputModvars}
                    language             = {this.props.language}
                    onStateChange        = {this.props.onStateChange}
                    onUpdateDisplays     = {this.onUpdateDisplays}
                    onRemoveDisplay      = {this.onRemoveDisplay}
                    style                = {styles.content}
                />

            </React.Fragment>
        );
    }

}

export default ExploreData;