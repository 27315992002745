import React from 'react';
import PropTypes from "prop-types";
import TTabs2 from "@common/components/TTabs2";
import RS from "@common/strings/RS";
import EditorNote from '../../../../components/EditorNote';
import CSEffOnIncidence from "./EffectivenessTabs/CSEffOnIncidence";
import CSHerdEffectiveness from "./EffectivenessTabs/CSHerdEffectiveness";
import CSEffRiskBO from "./EffectivenessTabs/CSEffRiskBO";
import CSNutritionInterventions from "./EffectivenessTabs/CSNutritionInterventions";
import CSImpactUnderNutrition from "./EffectivenessTabs/CSImpactUnderNutrition";
import CSEffectivenessCoverageDrawer from "../Effectiveness/CSEffectivenessCoverageDrawer";
import CONSTANTS from "../../../../utilities/CS/CSConst";
import {MatEfficacyEditor} from "../../data/Effectiveness/MatEfficacyEditor";
import {SBEfficacyEditor} from "../../data/Effectiveness/SBEfficacyEditor";
import {EfficacyEditor} from "../../data/Effectiveness/EfficacyEditor";

class CSEffectivenessDrawer extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onTabChange                            : PropTypes.func,

        DiarrIncEditorPackTable                : PropTypes.object,
        RelRiskBFDiarrEditorPackTable          : PropTypes.object,
        MeninIncEditorPackTable                : PropTypes.object,
        RelRiskBFMeningEditorPackTable         : PropTypes.object,
        PneumIncEditorPackTable                : PropTypes.object,
        RelRiskBFPneumEditorPackTable          : PropTypes.object,
        onDiarrIncEditorPackTableChange        : PropTypes.func,
        onRelRiskBFDiarrEditorPackTableChange  : PropTypes.func,
        onMeninIncEditorPackTableChange        : PropTypes.func,
        onRelRiskBFMeningEditorPackTableChange : PropTypes.func,
        onPneumIncEditorPackTableChange        : PropTypes.func,
        onRelRiskBFPneumEditorPackTableChange  : PropTypes.func,
        EffOnIncidenceComboboxIdx              : PropTypes.number,
        onEffOnIncidenceComboboxChange         : PropTypes.func,

        DPTHerdEditorPackTable                 : PropTypes.object,
        HibHerdEditorPackTable                 : PropTypes.object,
        MeaslesHerdEditorPackTable             : PropTypes.object,
        PneumoHerdEditorPackTable              : PropTypes.object,
        RotaHerdEditorPackTable                : PropTypes.object,
        MeningAHerdEditorPackTable             : PropTypes.object,
        BednetsHerdEditorPackTable             : PropTypes.object,
        MalariaHerdEditorPackTable             : PropTypes.object,
        onDPTHerdEditorPackTableChange         : PropTypes.func,
        onHibHerdEditorPackTableChange         : PropTypes.func,
        onMeaslesHerdEditorPackTableChange     : PropTypes.func,
        onPneumoHerdEditorPackTableChange      : PropTypes.func,
        onRotaHerdEditorPackTableChange        : PropTypes.func,
        onMeningAHerdEditorPackTableChange     : PropTypes.func,
        onBednetsHerdEditorPackTableChange     : PropTypes.func,
        onMalariaHerdEditorPackTableChange     : PropTypes.func,
        herdComboboxIdx                        : PropTypes.number,
        onHerdComboboxChange                   : PropTypes.func,

        ImpactPromoAgeAppropriateBFEditorPackTable         : PropTypes.object,
        ImpactPromoEarlyInitBFEditorPackTable              : PropTypes.object,
        BirthIntervalsEditorPackTable                      : PropTypes.object,
        MatAgeAndBirthOrderEditorPackTable                 : PropTypes.object,
        MaternalNutritionEditorPackTable                   : PropTypes.object,
        ImpactsOnMaternalAnemiaEditorPackTable             : PropTypes.object,
        ImpactsOnWastingEditorPackTable                    : PropTypes.object,
        ImpactsOnStuntingEditorPackTable                   : PropTypes.object,
        LowBirthWeightFactorsEditorPackTable               : PropTypes.object,
        ReducInStuntEditorPackTable                        : PropTypes.object,
        EducAttainmentEditorPackTable                      : PropTypes.object,
        onImpactPromoAgeAppropriateBFEditorPackTableChange : PropTypes.func,
        onImpactPromoEarlyInitBFEditorPackTableChange      : PropTypes.func,
        onBirthIntervalsEditorPackTableChange              : PropTypes.func,
        onMatAgeAndBirthOrderEditorPackTableChange         : PropTypes.func,
        onMaternalNutritionEditorPackTableChange           : PropTypes.func,
        onImpactsOnMaternalAnemiaEditorPackTableChange     : PropTypes.func,
        onImpactsOnWastingEditorPackTableChange            : PropTypes.func,
        onImpactsOnStuntingEditorPackTableChange           : PropTypes.func,
        onLowBirthWeightFactorsEditorPackTableChange       : PropTypes.func,
        onReducInStuntEditorPackTableChange                : PropTypes.func,
        onEducAttainmentEditorPackTableChange              : PropTypes.func,
        nutritionComboboxIdx                               : PropTypes.number,
        onNutritionComboboxChange                          : PropTypes.func,

        ImpactsAnemiaMatMortEditorPackTable               : PropTypes.object,
        ImpactsBirthOutcomesOnMortEditorPackTable         : PropTypes.object,
        ImpactsStuntOnMortEditorPackTable                 : PropTypes.object,
        ImpactsWasteOnMortEditorPackTable                 : PropTypes.object,
        ImpactsBFOnMortNNEditorPackTable                  : PropTypes.object,
        ImpactsBFOnMortPNNEditorPackTable                 : PropTypes.object,
        onImpactsAnemiaMatMortEditorPackTableChange       : PropTypes.func,
        onImpactsBirthOutcomesOnMortEditorPackTableChange : PropTypes.func,
        onImpactsStuntOnMortEditorPackTableChange         : PropTypes.func,
        onImpactsWasteOnMortEditorPackTableChange         : PropTypes.func,
        onImpactsBFOnMortNNEditorPackTableChange          : PropTypes.func,
        onImpactsBFOnMortPNNEditorPackTableChange         : PropTypes.func,
        underNutritionComboboxIdx                         : PropTypes.number,
        onUnderNutritionComboboxChange                    : PropTypes.func,

        IVInfo                                            : PropTypes.arrayOf(PropTypes.object),
        CSModvars                                         : PropTypes.arrayOf(PropTypes.object),
        language                                          : PropTypes.number,
        showAllInterventionsCB                            : PropTypes.bool
    };

    static defaultProps = {
        onTabChange                            : () => console.log('onTabChange'),
        DiarrIncEditorPackTable                : null,
        RelRiskBFDiarrEditorPackTable          : null,
        MeninIncEditorPackTable                : null,
        RelRiskBFMeningEditorPackTable         : null,
        PneumIncEditorPackTable                : null,
        RelRiskBFPneumEditorPackTable          : null,
        onDiarrIncEditorPackTableChange        : (packTable) => console.log('onDiarrIncEditorPackTableChange'),
        onRelRiskBFDiarrEditorPackTableChange  : (packTable) => console.log('onRelRiskBFDiarrEditorPackTableChange'),
        onMeninIncEditorPackTableChange        : (packTable) => console.log('onMeninIncEditorPackTableChange'),
        onRelRiskBFMeningEditorPackTableChange : (packTable) => console.log('onRelRiskBFMeningEditorPackTableChange'),
        onPneumIncEditorPackTableChange        : (packTable) => console.log('onPneumIncEditorPackTableChange'),
        onRelRiskBFPneumEditorPackTableChange  : (packTable) => console.log('onRelRiskBFPneumEditorPackTableChange'),
        EffOnIncidenceComboboxIdx              : 0,
        onEffOnIncidenceComboboxChange         : () => console.log('onEffOnIncidenceComboboxChange'),

        DPTHerdEditorPackTable                 : null,
        HibHerdEditorPackTable                 : null,
        MeaslesHerdEditorPackTable             : null,
        PneumoHerdEditorPackTable              : null,
        RotaHerdEditorPackTable                : null,
        MeningAHerdEditorPackTable             : null,
        BednetsHerdEditorPackTable             : null,
        MalariaHerdEditorPackTable             : null,
        onDPTHerdEditorPackTableChange         : (packTable) => console.log('onDPTHerdEditorPackTableChange'),
        onHibHerdEditorPackTableChange         : (packTable) => console.log('onHibHerdEditorPackTableChange'),
        onMeaslesHerdEditorPackTableChange     : (packTable) => console.log('onMeaslesHerdEditorPackTableChange'),
        onPneumoHerdEditorPackTableChange      : (packTable) => console.log('onPneumoHerdEditorPackTableChange'),
        onRotaHerdEditorPackTableChange        : (packTable) => console.log('onRotaHerdEditorPackTableChange'),
        onMeningAHerdEditorPackTableChange     : (packTable) => console.log('onMeningAHerdEditorPackTableChange'),
        onBednetsHerdEditorPackTableChange     : (packTable) => console.log('onBednetsHerdEditorPackTableChange'),
        onMalariaHerdEditorPackTableChange     : (packTable) => console.log('onMalariaHerdEditorPackTableChange'),
        herdComboboxIdx                        : 0,
        onHerdComboboxChange                   : () => console.log('onHerdComboboxChange'),

        ImpactPromoAgeAppropriateBFEditorPackTable         : null,
        ImpactPromoEarlyInitBFEditorPackTable              : null,
        BirthIntervalsEditorPackTable                      : null,
        MatAgeAndBirthOrderEditorPackTable                 : null,
        MaternalNutritionEditorPackTable                   : null,
        ImpactsOnMaternalAnemiaEditorPackTable             : null,
        ImpactsOnWastingEditorPackTable                    : null,
        ImpactsOnStuntingEditorPackTable                   : null,
        LowBirthWeightFactorsEditorPackTable               : null,
        ReducInStuntEditorPackTable                        : null,
        EducAttainmentEditorPackTable                      : null,
        onImpactPromoAgeAppropriateBFEditorPackTableChange : (packTable) => console.log('onImpactPromoAgeAppropriateBFEditorPackTableChange'),
        onImpactPromoEarlyInitBFEditorPackTableChange      : (packTable) => console.log('onImpactPromoEarlyInitBFEditorPackTableChange'),
        onBirthIntervalsEditorPackTableChange              : (packTable) => console.log('onBirthIntervalsEditorPackTableChange'),
        onMatAgeAndBirthOrderEditorPackTableChange         : (packTable) => console.log('onMatAgeAndBirthOrderEditorPackTableChange'),
        onMaternalNutritionEditorPackTableChange           : (packTable) => console.log('onMaternalNutritionEditorPackTableChange'),
        onImpactsOnMaternalAnemiaEditorPackTableChange     : (packTable) => console.log('onImpactsOnMaternalAnemiaEditorPackTableChange'),
        onImpactsOnWastingEditorPackTableChange            : (packTable) => console.log('onImpactsOnWastingEditorPackTableChange'),
        onImpactsOnStuntingEditorPackTableChange           : (packTable) => console.log('onImpactsOnStuntingEditorPackTableChange'),
        onLowBirthWeightFactorsEditorPackTableChange       : (packTable) => console.log('onLowBirthWeightFactorsEditorPackTableChange'),
        onReducInStuntEditorPackTableChange                : (packTable) => console.log('onReducInStuntEditorPackTableChange'),
        onEducAttainmentEditorPackTableChange              : (packTable) => console.log('onEducAttainmentEditorPackTableChange'),
        nutritionComboboxIdx                               : 0,
        onNutritionComboboxChange                          : () => console.log('onNutritionComboboxChange'),

        ImpactsAnemiaMatMortEditorPackTable               : null,
        ImpactsBirthOutcomesOnMortEditorPackTable         : null,
        ImpactsStuntOnMortEditorPackTable                 : null,
        ImpactsWasteOnMortEditorPackTable                 : null,
        ImpactsBFOnMortNNEditorPackTable                  : null,
        ImpactsBFOnMortPNNEditorPackTable                 : null,
        onImpactsAnemiaMatMortEditorPackTableChange       : (packTable) => console.log('onImpactsAnemiaMatMortEditorPackTableChange'),
        onImpactsBirthOutcomesOnMortEditorPackTableChange : (packTable) => console.log('onImpactsBirthOutcomesOnMortEditorPackTableChange'),
        onImpactsStuntOnMortEditorPackTableChange         : (packTable) => console.log('onImpactsStuntOnMortEditorPackTableChange'),
        onImpactsWasteOnMortEditorPackTableChange         : (packTable) => console.log('onImpactsWasteOnMortEditorPackTableChange'),
        onImpactsBFOnMortNNEditorPackTableChange          : (packTable) => console.log('onImpactsBFOnMortNNEditorPackTableChange'),
        onImpactsBFOnMortPNNEditorPackTableChange         : (packTable) => console.log('onImpactsBFOnMortPNNEditorPackTableChange'),
        underNutritionComboboxIdx                         : 0,
        onUnderNutritionComboboxChange                    : () => console.log('onUnderNutritionComboboxChange'),

        IVInfo                                            : [],
        CSModvars                                         : [],
        language                                          : CONSTANTS.GB_English,
        showAllInterventionsCB                            : false
    };

    state = {
        packTable1             : null,
        packTable2             : null,
        packTable3             : null,
        packTable4             : null,
        selectedIVSet          : [],
        showAllInterventionsCB : this.props.showAllInterventionsCB,
        helpFile               : "effectiveness_of_interventions_maternal_full.htm",
        editorCombBoxIdx       : 0,
    };

    componentDidMount () {
        this.setState({
            packTable1           : MatEfficacyEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language, 0, this.state.showAllInterventionsCB)[CONSTANTS.CS_PackTable],
            packTable2           : SBEfficacyEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language, 0, this.state.showAllInterventionsCB)[CONSTANTS.CS_PackTable],
            packTable3           : EfficacyEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language, CONSTANTS.CS_MstEfficacyNeonatal, 0, this.state.showAllInterventionsCB)[CONSTANTS.CS_PackTable],
            packTable4           : EfficacyEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language, CONSTANTS.CS_MstEfficacyPostNeonatal, 0, this.state.showAllInterventionsCB)[CONSTANTS.CS_PackTable],
            selectedIVSet        : this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV) ? true : false).value.intArray, // readOnly
        }, () => {
            this.props.passData({
                MatEfficacyEditorPackTable                : this.state.packTable1,
                SBEfficacyEditorPackTable                 : this.state.packTable2,
                NeoNatalEfficacyEditor                    : this.state.packTable3,
                PostNatalEfficacyEditor                   : this.state.packTable4
            })
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable
        }, () => {
            this.props.passData({
                MatEfficacyEditorPackTable: packTable,
                saveBtnDisabled : false
            });
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
        }, () => {
            this.props.passData({
                SBEfficacyEditorPackTable: packTable,
                saveBtnDisabled : false
            });
        });
    };

    onPackTable3Change = (packTable) => {
        this.setState({
            packTable3      : packTable,
        }, () => {
            this.props.passData({
                NeoNatalEfficacyEditor: packTable,
                saveBtnDisabled : false
            });
        });
    };

    onPackTable4Change = (packTable) => {
        this.setState({
            packTable4      : packTable,
        }, () => {
            this.props.passData({
                PostNatalEfficacyEditor: packTable,
                saveBtnDisabled : false
            });
        });
    };

    onShowAllInterventionsCBChange = (value) => {
        this.setState({
            showAllInterventionsCB : value
        }, () => {
            this.props.passData({showAllInterventionsCB: value});
        });
    };

    onEditorComboboxChange = (value) => {
        this.setState({
            editorComboboxIdx : value
        });

        const maternal = 0;
        const stillbirth = 1;
        const neonatal = 2;
        const children = 3;

        switch (value) {
            case maternal : {
                this.props.passData({
                    selIVHelpFile: "effectiveness_of_interventions_maternal_full.htm",
                    helpFile: "effectiveness_of_interventions_maternal_full.htm"
                });
                break;
            }
            case stillbirth : {
                this.props.passData({
                    selIVHelpFile: "effectiveness_of_interventions_stillbirths.htm",
                    helpFile: "effectiveness_of_interventions_stillbirths.htm"
                });
                break;
            }
            case neonatal : {
                this.props.passData({
                    selIVHelpFile: "effectiveness_of_interventions_less_than_1_month.htm",
                    helpFile: "effectiveness_of_interventions_less_than_1_month.htm"
                });
                break;
            }
            case children : {
                this.props.passData({
                    selIVHelpFile: "effectiveness_of_interventions_1_59_months.htm",
                    helpFile: "effectiveness_of_interventions_1_59_months.htm"
                });
                break;
            }
            default :
                break;
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderTabs = () => {
        const styles = {
            tabSelectedStyle : {
                color: '#f78c30',
                fontWeight: 'bold'
            },
            tabRootStyle : {
                fontSize: '14px'
            },
            tabContentStyle : {
                // marginTop: '185px'
            },
            tabAppBarStyle : {
                position: "static",
            }
        };

        return (
            <div styles={{marginTop: '20px'}}>
                <TTabs2
                    tabNames={[RS("GB_stEffInterventions"), RS('GB_stEffIntervInc'), RS('GB_stHerdEff'), RS('GB_stEffRiskBO'), RS('GB_stNutritionInterv'), RS('GB_stImpactUnderNutrition')]}
                    tabContents={[
                        <CSEffectivenessCoverageDrawer
                            packTable1={this.state.packTable1}
                            packTable2={this.state.packTable2}
                            packTable3={this.state.packTable3}
                            packTable4={this.state.packTable4}
                            onPackTable1Change={this.onPackTable1Change}
                            onPackTable2Change={this.onPackTable2Change}
                            onPackTable3Change={this.onPackTable3Change}
                            onPackTable4Change={this.onPackTable4Change}
                            selectedIVSet={this.state.selectedIVSet}
                            IVInfo={this.props.IVInfo}
                            CSModvars={this.props.CSModvars}
                            showAllInterventionsCB={this.state.showAllInterventionsCB}
                            onShowAllInterventionsCBChange={this.onShowAllInterventionsCBChange}
                            language={this.props.language}
                            editorComboboxIdx={this.state.editorComboboxIdx}
                            onEditorComboboxChange={this.onEditorComboboxChange}
                            withinTab={true}
                        />,
                        <CSEffOnIncidence
                            DiarrIncEditorPackTable                = {this.props.DiarrIncEditorPackTable}
                            RelRiskBFDiarrEditorPackTable          = {this.props.RelRiskBFDiarrEditorPackTable}
                            MeninIncEditorPackTable                = {this.props.MeninIncEditorPackTable}
                            RelRiskBFMeningEditorPackTable         = {this.props.RelRiskBFMeningEditorPackTable}
                            PneumIncEditorPackTable                = {this.props.PneumIncEditorPackTable}
                            RelRiskBFPneumEditorPackTable          = {this.props.RelRiskBFPneumEditorPackTable}

                            onDiarrIncEditorPackTableChange        = {this.props.onDiarrIncEditorPackTableChange}
                            onRelRiskBFDiarrEditorPackTableChange  = {this.props.onRelRiskBFDiarrEditorPackTableChange}
                            onMeninIncEditorPackTableChange        = {this.props.onMeninIncEditorPackTableChange}
                            onRelRiskBFMeningEditorPackTableChange = {this.props.onRelRiskBFMeningEditorPackTableChange}
                            onPneumIncEditorPackTableChange        = {this.props.onPneumIncEditorPackTableChange}
                            onRelRiskBFPneumEditorPackTableChange  = {this.props.onRelRiskBFPneumEditorPackTableChange}

                            EffOnIncidenceComboboxIdx              = {this.props.EffOnIncidenceComboboxIdx}
                            onEffOnIncidenceComboboxChange         = {this.props.onEffOnIncidenceComboboxChange}
                        />,
                        <CSHerdEffectiveness
                            DPTHerdEditorPackTable                 = {this.props.DPTHerdEditorPackTable}
                            HibHerdEditorPackTable                 = {this.props.HibHerdEditorPackTable}
                            MeaslesHerdEditorPackTable             = {this.props.MeaslesHerdEditorPackTable}
                            PneumoHerdEditorPackTable              = {this.props.PneumoHerdEditorPackTable}
                            RotaHerdEditorPackTable                = {this.props.RotaHerdEditorPackTable}
                            MeningAHerdEditorPackTable             = {this.props.MeningAHerdEditorPackTable}
                            BednetsHerdEditorPackTable             = {this.props.BednetsHerdEditorPackTable}
                            MalariaHerdEditorPackTable             = {this.props.MalariaHerdEditorPackTable}

                            onDPTHerdEditorPackTableChange         = {this.props.onDPTHerdEditorPackTableChange}
                            onHibHerdEditorPackTableChange         = {this.props.onHibHerdEditorPackTableChange}
                            onMeaslesHerdEditorPackTableChange     = {this.props.onMeaslesHerdEditorPackTableChange}
                            onPneumoHerdEditorPackTableChange      = {this.props.onPneumoHerdEditorPackTableChange}
                            onRotaHerdEditorPackTableChange        = {this.props.onRotaHerdEditorPackTableChange}
                            onMeningAHerdEditorPackTableChange     = {this.props.onMeningAHerdEditorPackTableChange}
                            onBednetsHerdEditorPackTableChange     = {this.props.onBednetsHerdEditorPackTableChange}
                            onMalariaHerdEditorPackTableChange     = {this.props.onMalariaHerdEditorPackTableChange}

                            herdComboboxIdx                        = {this.props.herdComboboxIdx}
                            onHerdComboboxChange                   = {this.props.onHerdComboboxChange}
                        />,
                        <CSEffRiskBO
                            BirthIntervalsEditorPackTable                      = {this.props.BirthIntervalsEditorPackTable}
                            MatAgeAndBirthOrderEditorPackTable                 = {this.props.MatAgeAndBirthOrderEditorPackTable}
                            MaternalNutritionEditorPackTable                   = {this.props.MaternalNutritionEditorPackTable}

                            onBirthIntervalsEditorPackTableChange              = {this.props.onBirthIntervalsEditorPackTableChange}
                            onMatAgeAndBirthOrderEditorPackTableChange         = {this.props.onMatAgeAndBirthOrderEditorPackTableChange}
                            onMaternalNutritionEditorPackTableChange           = {this.props.onMaternalNutritionEditorPackTableChange}
                        />,
                        <CSNutritionInterventions
                            ImpactPromoAgeAppropriateBFEditorPackTable         = {this.props.ImpactPromoAgeAppropriateBFEditorPackTable}
                            ImpactPromoEarlyInitBFEditorPackTable              = {this.props.ImpactPromoEarlyInitBFEditorPackTable}
                            ImpactsOnMaternalAnemiaEditorPackTable             = {this.props.ImpactsOnMaternalAnemiaEditorPackTable}
                            ImpactsOnWastingEditorPackTable                    = {this.props.ImpactsOnWastingEditorPackTable}
                            ImpactsOnStuntingEditorPackTable                   = {this.props.ImpactsOnStuntingEditorPackTable}
                            LowBirthWeightFactorsEditorPackTable               = {this.props.LowBirthWeightFactorsEditorPackTable}
                            ReducInStuntEditorPackTable                        = {this.props.ReducInStuntEditorPackTable}
                            EducAttainmentEditorPackTable                      = {this.props.EducAttainmentEditorPackTable}

                            onImpactPromoAgeAppropriateBFEditorPackTableChange = {this.props.onImpactPromoAgeAppropriateBFEditorPackTableChange}
                            onImpactPromoEarlyInitBFEditorPackTableChange      = {this.props.onImpactPromoEarlyInitBFEditorPackTableChange}
                            onImpactsOnMaternalAnemiaEditorPackTableChange     = {this.props.onImpactsOnMaternalAnemiaEditorPackTableChange}
                            onImpactsOnWastingEditorPackTableChange            = {this.props.onImpactsOnWastingEditorPackTableChange}
                            onImpactsOnStuntingEditorPackTableChange           = {this.props.onImpactsOnStuntingEditorPackTableChange}
                            onLowBirthWeightFactorsEditorPackTableChange       = {this.props.onLowBirthWeightFactorsEditorPackTableChange}
                            onReducInStuntEditorPackTableChange                = {this.props.onReducInStuntEditorPackTableChange}
                            onEducAttainmentEditorPackTableChange              = {this.props.onEducAttainmentEditorPackTableChange}

                            nutritionComboboxIdx                               = {this.props.nutritionComboboxIdx}
                            onNutritionComboboxChange                          = {this.props.onNutritionComboboxChange}
                        />,
                        <CSImpactUnderNutrition
                            ImpactsAnemiaMatMortEditorPackTable               = {this.props.ImpactsAnemiaMatMortEditorPackTable}
                            ImpactsBirthOutcomesOnMortEditorPackTable         = {this.props.ImpactsBirthOutcomesOnMortEditorPackTable}
                            ImpactsStuntOnMortEditorPackTable                 = {this.props.ImpactsStuntOnMortEditorPackTable}
                            ImpactsWasteOnMortEditorPackTable                 = {this.props.ImpactsWasteOnMortEditorPackTable}
                            ImpactsBFOnMortNNEditorPackTable                  = {this.props.ImpactsBFOnMortNNEditorPackTable}
                            ImpactsBFOnMortPNNEditorPackTable                 = {this.props.ImpactsBFOnMortPNNEditorPackTable}

                            onImpactsAnemiaMatMortEditorPackTableChange       = {this.props.onImpactsAnemiaMatMortEditorPackTableChange}
                            onImpactsBirthOutcomesOnMortEditorPackTableChange = {this.props.onImpactsBirthOutcomesOnMortEditorPackTableChange}
                            onImpactsStuntOnMortEditorPackTableChange         = {this.props.onImpactsStuntOnMortEditorPackTableChange}
                            onImpactsWasteOnMortEditorPackTableChange         = {this.props.onImpactsWasteOnMortEditorPackTableChange}
                            onImpactsBFOnMortNNEditorPackTableChange          = {this.props.onImpactsBFOnMortNNEditorPackTableChange}
                            onImpactsBFOnMortPNNEditorPackTableChange         = {this.props.onImpactsBFOnMortPNNEditorPackTableChange}

                            underNutritionComboboxIdx                         = {this.props.underNutritionComboboxIdx}
                            onUnderNutritionComboboxChange                    = {this.props.onUnderNutritionComboboxChange}
                        />]}
                    tabAppBarStyle={styles.tabAppBarStyle}
                    tabSelectedStyle={styles.tabSelectedStyle}
                    tabRootStyle={styles.tabRootStyle}
                    tabContentStyle={styles.tabContentStyle}
                    onTabChange={this.props.onTabChange}
                />
            </div>
        );
    };

    render() {


        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {2}
                />

                {this.renderTabs()}

            </div>
        );
    }

}

export default CSEffectivenessDrawer;