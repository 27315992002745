import * as React from 'react';
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import SubNatWizardContent from "./SubNatWizardContent";

class SubNatDrawer extends React.Component {
    
    static propTypes = {
        open               : PropTypes.bool,
        onClose            : PropTypes.func,
        appBarText         : PropTypes.string,
        onSave             : PropTypes.func,
        onStateChange      : PropTypes.func,
        onStepChange       : PropTypes.func,
        onQualityChange    : PropTypes.func,
        packTableFYP       : PropTypes.any,
        packTableTFR       : PropTypes.any,
        packTableInc       : PropTypes.any,
        packTablePMTCT     : PropTypes.any,
        packTableCotrim    : PropTypes.any,
        packTableART       : PropTypes.any,
        packTableCPR       : PropTypes.any,
        packTableCov       : PropTypes.any,
        packTableEarlyInit : PropTypes.any,
        packTableBF        : PropTypes.any,
        packTableStunt     : PropTypes.any,
        packTableWast      : PropTypes.any,
        onEditorChange     : PropTypes.func,
        subnatReady        : PropTypes.bool,
        qualityOn          : PropTypes.bool,
        onOpenHelpDialog   : PropTypes.func,
        subnatStep         : PropTypes.number
    };

    static defaultProps = {
        open               : false,
        onClose            : () => console.log("onClose"),
        appBarText         : "",
        onSave             : () => console.log("onSave"),
        onStateChange      : () => console.log("onStateChange"),
        onStepChange       : () => console.log("onStepChange"),
        onQualityChange    : () => console.log("onQualityChange"),
        packTableFYP       : {},
        packTableTFR       : {},
        packTableInc       : {},
        packTablePMTCT     : {},
        packTableCotrim    : {},
        packTableART       : {},
        packTableCPR       : {},
        packTableCov       : {},
        packTableEarlyInit : {},
        packTableBF        : {},
        packTableStunt     : {},
        packTableWast      : {},
        onEditorChange     : () => console.log("onEditorChange"),
        subnatReady        : false,
        qualityOn          : false,
        onOpenHelpDialog   : () => console.log("onOpenHelpDialog"),
        subnatStep         : 1
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const help = [
            "demproj",
            "aim",
            "list",
            "famplan"
        ];

        return (
            <TDrawerWithHeader
                open            = {this.props.open}
                onClose         = {this.props.onClose}
                appBarText      = {this.props.appBarText}
                onSave          = {this.props.onSave}
                saveBtnCaption  = {RS('GB_stApply')}
                saveBtnDisabled = {!this.props.subnatReady}
                onOpenHelpDialog= {this.props.onOpenHelpDialog}
                helpFile        = {help[this.props.subnatStep - 1] + "-subnational.htm"}
                content={
                    <SubNatWizardContent
                        onSave             = {this.props.onSave}
                        onStateChange      = {this.props.onStateChange}
                        onStepChange       = {this.props.onStepChange}
                        onQualityChange    = {this.props.onQualityChange}
                        packTableFYP       = {this.props.packTableFYP}
                        packTableTFR       = {this.props.packTableTFR}
                        packTableInc       = {this.props.packTableInc}
                        packTablePMTCT     = {this.props.packTablePMTCT}
                        packTableCotrim    = {this.props.packTableCotrim}
                        packTableART       = {this.props.packTableART}
                        packTableCPR       = {this.props.packTableCPR}
                        packTableCov       = {this.props.packTableCov}
                        packTableEarlyInit = {this.props.packTableEarlyInit}
                        packTableBF        = {this.props.packTableBF}
                        packTableStunt     = {this.props.packTableStunt}
                        packTableWast      = {this.props.packTableWast}
                        onEditorChange     = {this.props.onEditorChange}
                        qualityOn          = {this.props.qualityOn}
                    />
                }
            />
        )
    }
}

export default SubNatDrawer;