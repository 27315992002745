import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";

class PrivacyDialog extends React.Component {
    static propTypes = {
        onStateChange    : PropTypes.func
    };

    static defaultProps = {
        onStateChange     : () => console.log("PrivacyDialog onStateChange")
    };

    constructor(props) {
        super(props);

        this.state = {
            //html: null
        };
    };

    // componentDidMount() {
    //     let ajax = new XMLHttpRequest();
    //     ajax.open("POST", "https://www.avenirhealth.org/privacy.php/", true);
    //     ajax.send();
    //     ajax.onreadystatechange = () => {
    //         if (ajax.readyState == 4 && ajax.status == 200) {
    //             this.setState({
    //                 html: ajax.responseText
    //             })
    //         }
    //     }
    // }

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogPrivacyOpen: false
        })
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderPrivacyContent = () => {
        const styles = {
            iframe: {
                width: "100%",
                height: 450
            }
        };

        return (
            <iframe
               src={"https://www.avenirhealth.org/privacy.php/"}
               style={styles.iframe}
               title={RS('GB_stPrivacyPolicy')}
            />
            // <React.Fragment>
            //     {this.state.html}
            // </React.Fragment>
        )
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stPrivacyPolicy')}
                onClose={this.onClose}
                content={this.renderPrivacyContent()}
                actions={undefined}
            />
        );
    }
}

export default PrivacyDialog;