import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class TopicDropdown extends React.PureComponent {

    static propTypes = {
        itemIndex : PropTypes.number,
        onChange  : PropTypes.func,
        language  : PropTypes.number,
    };

    static defaultProps = {
        itemIndex : 0,
        onChange  : () => console.log("onChange"),
        language  : CONSTANTS.GB_English
    };

    render() {
        const styles = {
            comboBox: {
                marginTop: 0, //10,
                marginRight: 10,
                marginBottom: 20,
                paddingLeft: 5,
                width: 265,
                display: 'inline-block',
                color: '#05225d',
                fontWeight: 'bold',
                borderRadius: 4,
                border: '1px solid lightgray',
                borderBlockEndColor: '#f78c30'
            },
            captionStyle: {
                marginTop: 25,
                color: '#f78c30'
            },
        };
        
        return (
            <TComboBox
                caption      = ""
                itemIndex    = {this.props.itemIndex}
                onChange     = {this.props.onChange}
                style        = {styles.comboBox}
                captionStyle = {styles.captionStyle}
                items        = {[
                    RS('GB_stMortality'),
                    RS('GB_stHealthAndNutrition'),
                    RS('GB_stInterventionCov'),
                    RS('GB_stEffectivenessValues'),
                ]}
            />
        );
    }
}

export default TopicDropdown;