import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from "utilities/GB/GBConst";
import { getChildCODName, getMatCODName, getSBCODName, getAdolFullName } from "utilities/CS/CSDataUtil";
import {
    addRowToPackTable,
    getNewPC,
    getNewPT,
    resizePackTable,
    lockPackTable,
    addMergedCellsToArray,
} from "utilities/GB/GBUtil";

let packChart = null;
let packTable = null;

export const getCODSources = (exploreShowDataItem, inputModvars) => {
    let source = RS("GB_stNoSources");
    let causeOfDeathSource = inputModvars.find((x) => x.tag === exploreShowDataItem["modvarTag0"]).source;
    let mortalitySource = inputModvars.find((x) => x.tag === exploreShowDataItem["modvarTag1"]).source;

    switch (exploreShowDataItem["mstID"]) {
        case CONSTANTS.CS_MstMaternalMortality: {
            source =
                RS("GB_stCauseOfDeath") +
                ": " +
                causeOfDeathSource[0] +
                "; " +
                RS("GB_stMaternalMortality") +
                ": " +
                mortalitySource[0];
            break;
        }

        case CONSTANTS.CS_MstStillbirths: {
            source =
                RS("GB_stStillbirthCauses1") +
                ": " +
                causeOfDeathSource[0] +
                "; " +
                RS("GB_stStillbirthRate") +
                ": " +
                mortalitySource[0];
            break;
        }

        case CONSTANTS.CS_MstNeonatalMortality: {
            source =
                RS("GB_stCauseOfDeath") +
                ": " +
                causeOfDeathSource[0][CONSTANTS.CS_Neonatal] +
                "; " +
                RS("GB_stNeonatalMortRate") +
                ": " +
                mortalitySource[0][CONSTANTS.CS_NeonatalMR];
            break;
        }

        case CONSTANTS.CS_MstChildMortality: {
            source =
                RS("GB_stCauseOfDeath") +
                ": " +
                causeOfDeathSource[0][CONSTANTS.CS_PostNN] +
                "; " +
                RS("GB_stUnder5MortRate") +
                ": " +
                mortalitySource[0][CONSTANTS.CS_U5MR];
            break;
        }

        default:
            source = RS("GB_stNoSources");
            break;
    }

    return source;
};

export const generateCODDisplay = (exploreShowDataItem, inputModvars) => {
    let firstYrIdx = exploreShowDataItem["firstYear"] - CONSTANTS.CS_FirstYearHistoricalCountryData + 2;
    let finalYrIdx = exploreShowDataItem["finalYear"] - CONSTANTS.CS_FirstYearHistoricalCountryData + 2;
    let causeOfDeathMV = inputModvars.find((x) => x.tag === exploreShowDataItem["modvarTag0"]);
    let mortalityMV = inputModvars.find((x) => x.tag === exploreShowDataItem["modvarTag1"]).value;

    let adol = [
        CONSTANTS.CS_Mst5t9Mort,
        CONSTANTS.CS_Mst10t14Mort,
        CONSTANTS.CS_Mst15t19FemaleMort,
        CONSTANTS.CS_Mst15t19MaleMort,
        CONSTANTS.CS_Mst15t19Mort,
    ].includes(exploreShowDataItem["mstID"]);

    let codData = getCodData(
        exploreShowDataItem["mstID"],
        firstYrIdx,
        finalYrIdx,
        exploreShowDataItem["selectedDisplayType"],
        causeOfDeathMV.value,
        mortalityMV,
        adol
    );

    packChart = getCodPackChart(
        exploreShowDataItem["mstID"],
        firstYrIdx,
        finalYrIdx,
        exploreShowDataItem["title"],
        exploreShowDataItem["selectedCountry"],
        codData,
        adol
    );
    packTable = getCodPackTable(
        exploreShowDataItem["mstID"],
        firstYrIdx,
        finalYrIdx,
        exploreShowDataItem["title"],
        exploreShowDataItem["selectedCountry"],
        codData
    );

    switch (exploreShowDataItem["mstID"]) {
        case CONSTANTS.CS_MstNeonatalMortality: {
            packTable.Source[0] = causeOfDeathMV["source"][0][CONSTANTS.CS_Neonatal];
            break;
        }
        case CONSTANTS.CS_MstChildMortality: {
            packTable.Source[0] = causeOfDeathMV["source"][0][CONSTANTS.CS_PostNN];
            break;
        }
        default: {
            packTable.Source[0] = causeOfDeathMV["source"][0][0];
            break;
        }
    }

    return [packChart, packTable];
};

const getCodData = (mstID, firstYrIdx, finalYrIdx, selectedDisplayType, causeOfDeathMV, mortalityMV, adol) => {
    let data = [];
    let codVal = [];
    let mortVal = [];
    let cod = [];

    let maxMortVal = 0;

    if (adol) {
        for (let s = 0; s <= 2; s++) {
            for (let a = 1; a <= 3; a++) {
                for (let cm = firstYrIdx; cm <= finalYrIdx; cm++) {
                    maxMortVal = Math.max(maxMortVal, mortalityMV[s][a][cm]);
                }
            }
        }
    }

    switch (mstID) {
        case CONSTANTS.CS_MstMaternalMortality: {
            for (let m = CONSTANTS.CS_Mat_MinDeathCauses; m <= CONSTANTS.CS_Mat_MaxDeathCauses; m++) {
                codVal = [];
                for (let mt = firstYrIdx; mt <= finalYrIdx; mt++) {
                    codVal.push(causeOfDeathMV[m][mt] * 100);
                }
                cod.push({
                    name: getMatCODName(m),
                    values: codVal,
                    ID: m,
                });
            }
            for (let mm = firstYrIdx; mm <= finalYrIdx; mm++) {
                mortVal.push(mortalityMV[mm]);
            }
            data = { causes: cod, mortality: mortVal };

            break;
        }
        case CONSTANTS.CS_MstStillbirths: {
            for (let s = CONSTANTS.CS_SB_FirstSBCause; s <= CONSTANTS.CS_SB_MaxCauses; s++) {
                codVal = [];
                for (let st = firstYrIdx; st <= finalYrIdx; st++) {
                    codVal.push(causeOfDeathMV[s][st] * 100);
                }
                cod.push({
                    name: getSBCODName(s),
                    values: codVal,
                    ID: s,
                });
            }
            for (let sm = firstYrIdx; sm <= finalYrIdx; sm++) {
                mortVal.push(mortalityMV[sm]);
            }
            data = { causes: cod, mortality: mortVal };

            break;
        }
        case CONSTANTS.CS_MstNeonatalMortality: {
            for (let n = CONSTANTS.CS_FirstNNDeath; n <= CONSTANTS.CS_FinalStdNNDeath; n++) {
                codVal = [];
                for (let nt = firstYrIdx; nt <= finalYrIdx; nt++) {
                    codVal.push(causeOfDeathMV[CONSTANTS.CS_DxC_Default][n][1][nt] * 100);
                }
                cod.push({
                    name: getChildCODName(n),
                    values: codVal,
                    ID: n,
                });
            }
            for (let sm = firstYrIdx; sm <= finalYrIdx; sm++) {
                mortVal.push(mortalityMV[1][sm]);
            }
            data = { causes: cod, mortality: mortVal };

            break;
        }
        case CONSTANTS.CS_MstChildMortality: {
            for (let c = CONSTANTS.CS_FirstPNNDeath; c <= CONSTANTS.CS_FinalStdPNNDeath; c++) {
                codVal = [];
                for (let ct = firstYrIdx; ct <= finalYrIdx; ct++) {
                    codVal.push(causeOfDeathMV[CONSTANTS.CS_DxC_Default][c][2][ct] * 100);
                }
                cod.push({
                    name: getChildCODName(c),
                    values: codVal,
                    ID: c,
                });
            }
            for (let cm = firstYrIdx; cm <= finalYrIdx; cm++) {
                mortVal.push(mortalityMV[3][cm]);
            }
            data = { causes: cod, mortality: mortVal };

            break;
        }
        case CONSTANTS.CS_Mst5t9Mort: {
            for (let c = CONSTANTS.CS_Adol_MaxCauses; c >= CONSTANTS.CS_Adol_FirstCause; c--) {
                codVal = [];
                for (let ct = firstYrIdx; ct <= finalYrIdx; ct++) {
                    codVal.push(causeOfDeathMV[c][0][1][ct]);
                }
                cod.push({
                    name: getAdolFullName(c),
                    values: codVal,
                    ID: c,
                });
            }
            for (let cm = firstYrIdx; cm <= finalYrIdx; cm++) {
                mortVal.push(mortalityMV[0][1][cm]);
            }
            data = { causes: cod, mortality: mortVal, maxMortVal: maxMortVal };

            break;
        }
        case CONSTANTS.CS_Mst10t14Mort: {
            for (let c = CONSTANTS.CS_Adol_MaxCauses; c >= CONSTANTS.CS_Adol_FirstCause; c--) {
                codVal = [];
                for (let ct = firstYrIdx; ct <= finalYrIdx; ct++) {
                    codVal.push(causeOfDeathMV[c][0][2][ct]);
                }
                cod.push({
                    name: getAdolFullName(c),
                    values: codVal,
                    ID: c,
                });
            }
            for (let cm = firstYrIdx; cm <= finalYrIdx; cm++) {
                mortVal.push(mortalityMV[0][2][cm]);
            }
            data = { causes: cod, mortality: mortVal, maxMortVal: maxMortVal };

            break;
        }
        case CONSTANTS.CS_Mst15t19FemaleMort: {
            for (let c = CONSTANTS.CS_Adol_MaxCauses; c >= CONSTANTS.CS_Adol_FirstCause; c--) {
                codVal = [];
                for (let ct = firstYrIdx; ct <= finalYrIdx; ct++) {
                    codVal.push(causeOfDeathMV[c][2][3][ct]);
                }
                cod.push({
                    name: getAdolFullName(c),
                    values: codVal,
                    ID: c,
                });
            }
            for (let cm = firstYrIdx; cm <= finalYrIdx; cm++) {
                mortVal.push(mortalityMV[2][3][cm]);
            }
            data = { causes: cod, mortality: mortVal, maxMortVal: maxMortVal };

            break;
        }
        case CONSTANTS.CS_Mst15t19MaleMort: {
            for (let c = CONSTANTS.CS_Adol_MaxCauses; c >= CONSTANTS.CS_Adol_FirstCause; c--) {
                codVal = [];
                for (let ct = firstYrIdx; ct <= finalYrIdx; ct++) {
                    codVal.push(causeOfDeathMV[c][1][3][ct]);
                }
                cod.push({
                    name: getAdolFullName(c),
                    values: codVal,
                    ID: c,
                });
            }
            for (let cm = firstYrIdx; cm <= finalYrIdx; cm++) {
                mortVal.push(mortalityMV[1][3][cm]);
            }
            data = { causes: cod, mortality: mortVal, maxMortVal: maxMortVal };

            break;
        }
        case CONSTANTS.CS_Mst15t19Mort: {
            for (let c = CONSTANTS.CS_Adol_MaxCauses; c >= CONSTANTS.CS_Adol_FirstCause; c--) {
                codVal = [];
                for (let ct = firstYrIdx; ct <= finalYrIdx; ct++) {
                    codVal.push(causeOfDeathMV[c][0][3][ct]);
                }
                cod.push({
                    name: getAdolFullName(c),
                    values: codVal,
                    ID: c,
                });
            }
            for (let cm = firstYrIdx; cm <= finalYrIdx; cm++) {
                mortVal.push(mortalityMV[0][3][cm]);
            }
            data = { causes: cod, mortality: mortVal, maxMortVal: maxMortVal };

            break;
        }
        default: {
            break;
        }
    }

    return data;
};

const getMortalityRateTitle = (mstID) => {
    let result = "";

    switch (mstID) {
        case CONSTANTS.CS_MstMaternalMortality: {
            result = RS("GB_stMaternalMortRatio");
            break;
        }
        case CONSTANTS.CS_MstStillbirths: {
            result = RS("GB_stStillbirthRatePer1000");
            break;
        }
        case CONSTANTS.CS_MstNeonatalMortality: {
            result = RS("GB_stNNMRPer1000Birth");
            break;
        }
        case CONSTANTS.CS_MstChildMortality: {
            result = RS("GB_stUnder5MortRatePer1000");
            break;
        }
        case CONSTANTS.CS_Mst5t9Mort: {
            result = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol5t9");
            break;
        }
        case CONSTANTS.CS_Mst10t14Mort: {
            result = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol10t14");
            break;
        }
        case CONSTANTS.CS_Mst15t19FemaleMort: {
            result = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol15t19Female");
            break;
        }
        case CONSTANTS.CS_Mst15t19MaleMort: {
            result = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol15t19Male");
            break;
        }
        case CONSTANTS.CS_Mst15t19Mort: {
            result = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol15t19Both");
            break;
        }
    }

    return result;
};

const getCodTitle = (mstID, title, country = "") => {
    let result = getMortalityRateTitle(mstID);
    if (country !== "") {
        result += " " + RS("GB_stIn") + " " + country;
    }

    return result;
};

const getCodPackChart = (mstID, firstYrIdx, finalYrIdx, title, country, data, adol) => {
    let pc = getNewPC();
    let mort = [];
    let cod = [];

    pc.title = getCodTitle(mstID, title, country);
    pc.alignTitle = "left";

    pc.adol = false;

    if (adol) {
        pc.adol = true;
        pc.RYAxisMax = data["maxMortVal"];
    }

    pc.subsetMstIDs = [];

    for (let i = 0; i < data["causes"].length; i++) {
        cod = [];
        pc.subsetLabels.push(data["causes"][i]["name"]);
        pc.subsetMstIDs.push(data["causes"][i]["ID"]);

        if (adol) {
            let id = data["causes"][i]["ID"];

            switch (id) {
                case CONSTANTS.CS_Adol_HIV: {
                    pc.subsetColors.push(11111110);
                    break;
                }
                case CONSTANTS.CS_Adol_Diarrhoeal: {
                    pc.subsetColors.push(11111115);
                    break;
                }
                case CONSTANTS.CS_Adol_Measles: {
                    pc.subsetColors.push(11111110);
                    break;
                }
                case CONSTANTS.CS_Adol_Malaria: {
                    pc.subsetColors.push(11111115);
                    break;
                }
                case CONSTANTS.CS_Adol_LRI: {
                    pc.subsetColors.push(11111120);
                    break;
                }
                case CONSTANTS.CS_Adol_TB: {
                    pc.subsetColors.push(11111125);
                    break;
                }
                case CONSTANTS.CS_Adol_Maternal: {
                    pc.subsetColors.push(11111130);
                    break;
                }
                case CONSTANTS.CS_Adol_OtherCMPN: {
                    pc.subsetColors.push(11111135);
                    break;
                }

                case CONSTANTS.CS_Adol_Congenital: {
                    pc.subsetColors.push(452246);
                    break;
                }
                case CONSTANTS.CS_Adol_Neoplasm: {
                    pc.subsetColors.push(452266);
                    break;
                }
                case CONSTANTS.CS_Adol_Cardiovascular: {
                    pc.subsetColors.push(452286);
                    break;
                }
                case CONSTANTS.CS_Adol_Digestive: {
                    pc.subsetColors.push(452306);
                    break;
                }
                case CONSTANTS.CS_Adol_OtherNCD: {
                    pc.subsetColors.push(452310);
                    break;
                }

                case CONSTANTS.CS_Adol_RTI: {
                    pc.subsetColors.push(12351547);
                    break;
                }
                case CONSTANTS.CS_Adol_Drowning: {
                    pc.subsetColors.push(12351552);
                    break;
                }
                case CONSTANTS.CS_Adol_NatDis: {
                    pc.subsetColors.push(12351557);
                    break;
                }
                case CONSTANTS.CS_Adol_InterpVio: {
                    pc.subsetColors.push(12351562);
                    break;
                }
                case CONSTANTS.CS_Adol_CollectVio: {
                    pc.subsetColors.push(12351567);
                    break;
                }
                case CONSTANTS.CS_Adol_SelfHarm: {
                    pc.subsetColors.push(12351572);
                    break;
                }
                case CONSTANTS.CS_Adol_OtherInj: {
                    pc.subsetColors.push(12351577);
                    break;
                }
                default: {
                    break;
                }
            }
        }

        for (let v = 0; v < data["causes"][i]["values"].length; v++) {
            if (data["causes"][i]["values"][v] > -1) {
                cod.push(data["causes"][i]["values"][v]);
            } else {
                cod.push(null);
            }
        }
        pc.chartData.push(cod);
    }

    for (let t = firstYrIdx - 2; t <= finalYrIdx - 2; t++) {
        pc.pointLabels.push((CONSTANTS.CS_FirstYearHistoricalCountryData + t).toString());
    }

    for (let m = 0; m < data["mortality"].length; m++) {
        if (data["mortality"][m] > -1) {
            mort.push(data["mortality"][m]);
        } else {
            mort.push(null);
        }
    }

    pc.chartData.push(mort);

    switch (mstID) {
        case CONSTANTS.CS_MstMaternalMortality: {
            pc.subsetLabels.push(RS("GB_stMatMortRatio"));
            pc.RYAxisLabel = RS("GB_stMaternalMortRatio");
            break;
        }
        case CONSTANTS.CS_MstStillbirths: {
            pc.subsetLabels.push(RS("GB_stStillbirthRate"));
            pc.RYAxisLabel = RS("GB_stStillbirthRatePer1000");
            break;
        }
        case CONSTANTS.CS_MstNeonatalMortality: {
            pc.subsetLabels.push(RS("GB_stNeonatalMortRate"));
            pc.RYAxisLabel = RS("GB_stNNMRPer1000Birth");
            break;
        }
        case CONSTANTS.CS_MstChildMortality: {
            pc.subsetLabels.push(RS("GB_stUnder5MortRate"));
            pc.RYAxisLabel = RS("GB_stUnder5MortRatePer1000");
            break;
        }
        case CONSTANTS.CS_Mst5t9Mort: {
            pc.subsetLabels.push(RS("GB_stMortalityRate"));
            pc.RYAxisLabel = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol5t9");
            break;
        }
        case CONSTANTS.CS_Mst10t14Mort: {
            pc.subsetLabels.push(RS("GB_stMortalityRate"));
            pc.RYAxisLabel = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol10t14");
            break;
        }
        case CONSTANTS.CS_Mst15t19FemaleMort: {
            pc.subsetLabels.push(RS("GB_stMortalityRate"));
            pc.RYAxisLabel = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol15t19");
            break;
        }
        case CONSTANTS.CS_Mst15t19MaleMort: {
            pc.subsetLabels.push(RS("GB_stMortalityRate"));
            pc.RYAxisLabel = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol15t19");
            break;
        }
        case CONSTANTS.CS_Mst15t19Mort: {
            pc.subsetLabels.push(RS("GB_stMortalityRate"));
            pc.RYAxisLabel = RS("GB_stMortalityRate") + " " + RS("GB_stDeathsPerAdol15t19");
            break;
        }
        default: {
            break;
        }
    }

    return pc;
};

const getCodPackTable = (mstID, firstYrIdx, finalYrIdx, title, country, data) => {
    let pt = getNewPT();
    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;
    let numRows = 2; //year row

    pt.Title = getCodTitle(mstID, title, country);
    pt.GBRowCount = numRows;
    const numCols = data["causes"][0]["values"].length + 1;
    pt.GBColCount = numCols;
    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let t = firstYrIdx - 2; t <= finalYrIdx - 2; t++) {
        pt.tableData.value[0][col] = CONSTANTS.CS_FirstYearHistoricalCountryData + t;
        col++;
    }

    let row = 0;

    // if (adol) {
    pt = addRowToPackTable(pt);
    row++;
    pt = lockPackTable(pt, row, true, false);
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
    pt.FontStyles[row][0]["intArray"] = [GB_CONSTANTS.GB_Bold];
    pt.tableData.value[row][0] = getMortalityRateTitle(mstID);

    pt = addRowToPackTable(pt);
    row++;
    pt = lockPackTable(pt, row, true, false);
    pt.tableData.value[row][0] = "";
    col = 1;
    for (let t = 0; t < data["mortality"].length; t++) {
        if (data["mortality"][t] !== -1) pt.tableData.value[row][col] = data["mortality"][t];
        else pt.tableData.value[row][col] = "";
        col++;
    }

    pt = addRowToPackTable(pt);
    row++;
    pt = lockPackTable(pt, row, true, false);
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
    pt.FontStyles[row][0]["intArray"] = [GB_CONSTANTS.GB_Bold];
    pt.tableData.value[row][0] = RS("GB_stPercentDeaths");
    // }

    for (let i = 0; i < data["causes"].length; i++) {
        pt = addRowToPackTable(pt); //add a row to the table
        row++;

        pt = lockPackTable(pt, row, true, false);
        pt.tableData.value[row][0] = data["causes"][i]["name"];
        let col = 1;
        for (let t = 0; t < data["causes"][0]["values"].length; t++) {
            if (data["causes"][i]["values"][t] !== -1) pt.tableData.value[row][col] = data["causes"][i]["values"][t];
            else pt.tableData.value[row][col] = "";
            col++;
        }
    }

    pt = addRowToPackTable(pt);
    row++;

    pt = lockPackTable(pt, row, true, false);

    pt.tableData.value[row][0] = RS("GB_stTotal");
    for (let col = 1; col < pt.GBColCount; col++) {
        pt.tableData.value[row][col] = "";
    }

    for (let v = 0; v < data["causes"][0]["values"].length; v++) {
        let sum = 0;
        let valuesFound = false;
        for (let d = 0; d < data["causes"].length; d++) {
            if (data["causes"][d]["values"][v] > 0) {
                sum = sum + data["causes"][d]["values"][v];
                valuesFound = true;
            }
        }
        if (valuesFound) {
            pt.tableData.value[row][v + 1] = Math.round(sum);
        }
    }

    pt.GBRowCount = row + 1;
    pt = resizePackTable(pt, row + 1, numCols);

    pt.GBColWidths[0] = 220;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for (let row = 0; row < pt.GBRowCount; row++) {
        for (let col = 0; col < pt.GBColCount; col++) {
            pt.RDec[row][col] = 2;
        }
    }

    for (let row = 0; row < pt.GBRowCount; row++) {
        for (let col = 1; col < pt.GBColCount; col++) {
            pt.Alignments[row][col] = 1;
        }
    }

    return pt;
};
