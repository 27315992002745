import React from 'react';
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

const styles = theme => ({
  
    cssLabel: {
    //   color : 'green'
    },
  
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `#0000003b !important`,
        }
    },
  
    cssFocused: {
        color: "#000000de !important", 
        '&$cssFocused $notchedOutline': {
            borderColor: `$#0000003b !important`,
        }
    },
  
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#0000003b !important'
    },
  
});

class SourcesDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        title   : PropTypes.string,
        source  : PropTypes.string,
        onClose : PropTypes.func,
        onApply : PropTypes.func,
        editable : PropTypes.bool,
    };

    static defaultProps = {
        title   : '',
        source  : '',
        onClose : () => console.log('onClose'),
        onApply : () => console.log('onApply'),
        editable : true,
    };

    state = {
        source : this.props.source,
        applyBtnEnabled : false
    }; 

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onChange = (event) => {
        if (this.props.editable) {
            this.setState({
                source : event.target.value,
                applyBtnEnabled : true
            });
        }
    };

    onClose = () => {
        this.props.onClose();
    };

    onApply = () => {
        this.props.onApply(this.state.source);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    
    render() {
        const { classes } = this.props;

        //https://stackblitz.com/edit/material-ui-custom-outline-color

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stSource")}
                onClose={this.onClose}
                maxWidth={750}
                content={
                    <div>
                        <Typography style={{fontWeight: 'bold'}}>{this.props.title}</Typography>
                        <TextField
                            id="sourceNotes"
                            label={RS('GB_stSource')}
                            multiline
                            rows="6"
                            margin="normal"
                            variant="outlined"
                            style={{marginTop: 15, width: '600px'}}
                            value={this.state.source}
                            onChange={this.onChange}
                            placeholder={RS('GB_stNoSourceAvailable')}
                            InputLabelProps = {{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            spellCheck={false}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                        />
                    </div>
                }
                actions={
                    <div>
                        {
                            (this.props.editable) ? 
                                <ApplyButton
                                    onClick={this.onApply}
                                    disabled={!this.state.applyBtnEnabled}
                                /> : null
                        }
                    </div>
                }
            />
        );
    }

}

export default withStyles(styles)(SourcesDialog);