import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {getIVGroupRecFromMstID, DirectEntryMstIDSet} from "utilities/CS/CSDataUtil";
import CSInterventionsBlueBox from "../editors/CS/cards/SelectInterventions/CSInterventionsBlueBox";
import CSCoverageBlueBox from "../editors/CS/cards/Coverage/CSCoverageBlueBox";
import CSBreastfeedingBlueBox from "../editors/CS/cards/Coverage/CSBreastfeedingBlueBox";
import CSStuntingBlueBox from "../editors/CS/cards/Coverage/CSStuntingBlueBox";
import CSWastingBlueBox from "../editors/CS/cards/Coverage/CSWastingBlueBox";
import CSFertilityRisksBlueBox from "../editors/CS/cards/Coverage/CSFertilityRisksBlueBox";
import CSStepper from "../components/CS/CSStepper";
import CSFamPlanHIVBlueBox from "../editors/CS/cards/Coverage/CSFamPlanHIVBlueBox";

const modvarTag1 = CONSTANTS.CS_TG_UseDetailedStuntingMatrix_MV;
const modvarTag2 = CONSTANTS.CS_TG_UseDetailedWastingMatrix_MV;
const modvarTag3 = CONSTANTS.CS_TG_EnterBFImproveData_MV2;
const modvarTag4 = CONSTANTS.CS_TG_ANCMode_MV;
const modvarTag5 = CONSTANTS.CS_TG_ChildbirthMode_MV;
const modvarTag6 = CONSTANTS.CS_TG_SelectedIVSet_MV;

class CSManageInterventionsForm2 extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onPageChange                   : PropTypes.func,
        onAddTasks                     : PropTypes.func,
        onStateChange                  : PropTypes.func,
        onViewResults                  : PropTypes.func,
        onOpenHelpDialog               : PropTypes.func,
        handleSelectedResults          : PropTypes.func,
        CSModvars                      : PropTypes.arrayOf(PropTypes.object),
        IVGroupInfo                    : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo                 : PropTypes.arrayOf(PropTypes.object),
        IVInfo                         : PropTypes.arrayOf(PropTypes.object),
        selectedCountry                : PropTypes.string,
        includeFamPlan                 : PropTypes.bool,
        intervDrawerOpen               : PropTypes.bool,
        displayChart                   : PropTypes.bool,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayAdolescentCOD           : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        adolescentCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        isDeskTop                      : PropTypes.bool,
        showMOIntervSection            : PropTypes.bool,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        adolescentsSelected            : PropTypes.bool,
        selectAllIntervBool            : PropTypes.bool,
        topMOInterventions             : PropTypes.array,
        MOResultToDisplay              : PropTypes.number,
        isExpandMODrawerClosed         : PropTypes.bool,
        firstYear                      : PropTypes.number,
        projectionName                 : PropTypes.string,
        showAllInterventionsCB         : PropTypes.bool,
        language                       : PropTypes.number,
        approach                       : PropTypes.number
    };

    static defaultProps = {
        onPageChange                   : () => console.log('onPageChange'),
        onAddTasks                     : () => console.log('onAddTasks'),
        onStateChange                  : (value) => console.log('CSManageInterventionsForm2: onStateChange ' + JSON.stringify(value)),
        onViewResults                  : () => console.log('onViewResults'),
        onOpenHelpDialog               : () => console.log('onOpenHelpDialog'),
        handleSelectedResults          : () => console.log('handleSelectedResults'),
        CSModvars                      : [],
        IVGroupInfo                    : [],
        IVSubGroupInfo                 : [],
        IVInfo                         : [],
        selectedCountry                : 'Afghanistan',
        includeFamPlan                 : false,
        intervDrawerOpen               : false,
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayAdolescentCOD           : false,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        adolescentCausesOfDeathContent : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        isDeskTop                      : true,
        showMOIntervSection            : false,
        maternalIntervSelected         : true,
        selectAllIntervBool            : false,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        adolescentsSelected            : false,
        topMOInterventions             : [],
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        isExpandMODrawerClosed         : false,
        firstYear                      : -1,
        projectionName                 : "",
        showAllInterventionsCB         : false,
        language                       : CONSTANTS.GB_English,
        approach                       : CONSTANTS.CS_StandardApproach
    };

    state = {
        selectedIVSet           : [],
        breastFeedRadioValue    : 0,
        stuntRadioValue         : 0,
        wasteRadioValue         : 0,
        pregnancyRadioValue     : 0,
        childbirthRadioValue    : 0
    };
    
    static getDerivedStateFromProps(props, state) {
        // Update state so the next render will show the fallback UI.
        return { 
            stuntRadioValue      : props.CSModvars.find(x => (x.tag === modvarTag1) ? true : false).value,
            wasteRadioValue      : props.CSModvars.find(x => (x.tag === modvarTag2) ? true : false).value,
            breastFeedRadioValue : props.CSModvars.find(x => (x.tag === modvarTag3) ? true : false).value,
            pregnancyRadioValue  : props.CSModvars.find(x => (x.tag === modvarTag4) ? true : false).value,
            childbirthRadioValue : props.CSModvars.find(x => (x.tag === modvarTag5) ? true : false).value,
            selectedIVSet        : JSON.parse(JSON.stringify(props.CSModvars.find(x => (x.tag === modvarTag6) ? true : false).value.intArray || [])),
        };
    }

    componentDidMount () {
        this.setState({
            stuntRadioValue      : this.props.CSModvars.find(x => (x.tag === modvarTag1) ? true : false).value,
            wasteRadioValue      : this.props.CSModvars.find(x => (x.tag === modvarTag2) ? true : false).value,
            breastFeedRadioValue : this.props.CSModvars.find(x => (x.tag === modvarTag3) ? true : false).value,
            pregnancyRadioValue  : this.props.CSModvars.find(x => (x.tag === modvarTag4) ? true : false).value,
            childbirthRadioValue : this.props.CSModvars.find(x => (x.tag === modvarTag5) ? true : false).value,
            selectedIVSet        : JSON.parse(JSON.stringify(this.props.CSModvars.find(x => (x.tag === modvarTag6) ? true : false).value.intArray)),
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if ((JSON.stringify(this.props) !== JSON.stringify(nextProps)) || (JSON.stringify(this.state) !== JSON.stringify(nextState))) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBackBtnClick = () => {
        this.props.onPageChange("CSProjManagerForm");
    };

    onNextBtnClick = () => {
        this.props.onPageChange("CSReviewDefaultDataForm");
    };

    //==================================================================================================================
    //
    //                                              Functions
    //
    //==================================================================================================================

    getNumberInterventionsSelected = () => {

        let numberInterventionsSelected = 0;

        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (![CONSTANTS.CS_MstFamPlanInt, CONSTANTS.CS_MstHIVInt].includes(this.props.IVInfo[i].MstID)) {
                if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID)) {
                    numberInterventionsSelected++;
                }
            }
        }

        return numberInterventionsSelected;
    };

    getHIVAdolIntervsSelected = () => {

        let numberHIVAdolIntervsSelected = 0;

        if (this.props.adolescentsSelected) {
            for (let i = 0; i < this.props.IVInfo.length; i++) {
                let IVRec = this.props.IVInfo[i];
                if ([CONSTANTS.CS_MstPercUsingCondWNonMarContacts,
                     CONSTANTS.CS_MstPercUsingPrEP,
                     CONSTANTS.CS_MstPercOnART,
                     CONSTANTS.CS_MstPercMalesCircum,
                     CONSTANTS.CS_MstPercReceivCompSexEd,
                     CONSTANTS.CS_MstPercFemalesReceivEconStrength].includes(IVRec.MstID)) {
                    if (this.state.selectedIVSet.includes(IVRec.MstID)) {
                        if (IVRec[CONSTANTS.CS_AdolEff_ED]) {
                            numberHIVAdolIntervsSelected++;
                        }
                    }
                }
            }
        }

        return numberHIVAdolIntervsSelected > 0;
    };

    getNumberCoverageInterventionsSelected = () => {

        let numberInterventionsSelected = 0;

        let FamPlanHIVGroupRecord = getIVGroupRecFromMstID(this.props.IVGroupInfo, CONSTANTS.CS_GroupMstID_FamPlanHIV);
        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID)) {
               if (!DirectEntryMstIDSet().includes(this.props.IVInfo[i].MstID) &&
                   (FamPlanHIVGroupRecord["MstID"] !== this.props.IVInfo[i]["GroupMstID"])){
                    numberInterventionsSelected++;
                }
            }
        }

        return numberInterventionsSelected;
    };

    getNumberFamPlanHIVInterventionsSelected = () => {
        let numberInterventionsSelected = 0;

        let FamPlanHIVGroupRecord = getIVGroupRecFromMstID(this.props.IVGroupInfo, CONSTANTS.CS_GroupMstID_FamPlanHIV);
        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (![CONSTANTS.CS_MstFamPlanInt, CONSTANTS.CS_MstHIVInt].includes(this.props.IVInfo[i].MstID)) {
                if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID) &&
                    (FamPlanHIVGroupRecord["MstID"] === this.props.IVInfo[i]["GroupMstID"])) {
                    numberInterventionsSelected++;
                }
            }
        }

        return (
            numberInterventionsSelected
        )
    };

    getNumberQualityInterventionsSelected = () => {
        let numberInterventionsSelected = 0;

        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID) && (this.props.IVInfo[i].qualityInterv)) {
                numberInterventionsSelected++;
            }
        }

        return (
            numberInterventionsSelected
        )
    };

    getNumberVaccineInterventionsSelected = () => {
        let numberInterventionsSelected = 0;

        let VaccineGroupRecord = getIVGroupRecFromMstID(this.props.IVGroupInfo, CONSTANTS.CS_GroupMstID_Vaccines);
        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID) &&
                (VaccineGroupRecord["MstID"] === this.props.IVInfo[i]["GroupMstID"])) {
                numberInterventionsSelected++;
            }
        }

        return (
            numberInterventionsSelected
        )
    };

    getBFPromoSelected = () => {
        let result = false;

        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID) &&
                (this.props.IVInfo[i].MstID) === CONSTANTS.IV_MstChangesInBF_BFPromo) {
                result = true;
            }
        }

        return (
            result
        )
    };

    isDirectEntrySelected = (DirectMstID) => {
        let directMstIDSelected = false;

        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID) && (this.props.IVInfo[i].MstID === DirectMstID)) {
                directMstIDSelected = true;
            }
        }

        return directMstIDSelected;
    };
    
    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderQualityString = () => {
        let qualityString = '';
        let pregnancyGroupMstID = 4;
        let childbirthGroupMstID = 7;
        let qualityOption = 1;
        let numberPregnancyInterventionsSelected = 0;
        let numberChildbirthInterventionsSelected = 0;

        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (this.state.selectedIVSet.includes(this.props.IVInfo[i].MstID) && (this.props.IVInfo[i].qualityInterv)) {
                if ((this.props.IVInfo[i].GroupMstID === pregnancyGroupMstID) && (this.state.pregnancyRadioValue === qualityOption)) {
                    numberPregnancyInterventionsSelected++;
                } else if ((this.props.IVInfo[i].GroupMstID === childbirthGroupMstID) && (this.state.childbirthRadioValue === qualityOption)) {
                    numberChildbirthInterventionsSelected++;
                }
            }
        }

        if ((numberPregnancyInterventionsSelected === 0) && (numberChildbirthInterventionsSelected === 0)) {
            qualityString = '(' + RS('GB_stNoQualitySelected') + ')'
        }
        else if ((numberPregnancyInterventionsSelected > 0) && (numberChildbirthInterventionsSelected === 0)) {
            qualityString = '(' + RS('GB_stPregnancy') + ': ' + numberPregnancyInterventionsSelected + RS('GB_stInterventionsSmallI') + ')'
        }
        else if ((numberPregnancyInterventionsSelected === 0) && (numberChildbirthInterventionsSelected > 0)) {
            qualityString = '(' + RS('GB_stChildbirth') + ': ' + numberChildbirthInterventionsSelected + RS('GB_stInterventionsSmallI') + ')'
        }
        else if ((numberPregnancyInterventionsSelected > 0) && (numberChildbirthInterventionsSelected > 0)) {
            qualityString = '(' + RS('GB_stPregnancy') + ': ' + numberPregnancyInterventionsSelected +  RS('GB_stInterventionsSmallI') + ', (' + RS('GB_stChildbirth') + ': ' + numberChildbirthInterventionsSelected + RS('GB_stInterventionsSmallI') + ')'
        }

        return (
            qualityString
        )
    };

    renderBackBtn = () => {

        const styles = {
            backButton: {
                fontSize: '16px',
                marginTop: '30px',
                marginRight: '25px'
            }
        };

        return (
            <TButton
                caption={RS('GB_stBack')}
                color="secondary"
                style={styles.backButton}
                onClick={this.onBackBtnClick}
            />
        );
    };

    renderNextBtn = () => {

        const styles = {
            nextButton: {
                fontSize: '16px',
                marginTop: '30px',
                marginRight: '25px'
            }
        };

        return (
            <TButton
                caption={RS('GB_stNext')}
                color="primary"
                style={styles.nextButton}
                onClick={this.onNextBtnClick}
            />
        );
    };

    onStepChange = (idx) => {
        switch(idx) {
            case 0: this.props.onPageChange("CSProjManagerForm"); break;
            case 1: this.props.onPageChange("CSManageInterventionsForm"); break;
            case 2: this.props.onPageChange("CSReviewDefaultDataForm"); break;
            case 3: this.props.onViewResults(false); break;
            default : break;
        }
    };

    render() {

        const styles = {
            paragraph1 : {
                fontSize: 16,
                userSelect: 'none',
            },
            paragraph2 : {
                fontSize: 16,
                margin: '20px 0 10px 0',
                userSelect: 'none',
            },
            outsidePaper : {
                boxShadow: 'none'
            },
            paper : {
                width: 625,
                boxShadow: 'none'
            },

            captionStyle : {
                height: '80px',
                width: '205px',
                display: 'table-cell',
                verticalAlign: 'middle',
                margin: '0 auto',
                textAlign: 'center',
            },

            descriptionStyle : {
                position: 'absolute',
                top: '72px',
                left: '16px',
                fontSize: '12px',
                fontStyle: 'italic',
                width: '172px',
                textAlign: 'center'
            },
            content : {
                margin: '0 10px'
            }
    
        };
        
        return (
            <div className="innerPage">       

                <CSStepper
                    steps={[RS('GB_stModProjection'), RS('GB_stManageInterventions'), RS('GB_stReviewDefaultData'), RS('GB_stViewResults')]}
                    activeStep={1}
                    completedSteps={[]}
                    onStepBtnClick={this.onStepChange}
                    useAltColors={true}
                />

                <div style={styles.content}>
                    {/* <h2 className="pageHeader" style={{color: '#cb6814', marginTop:15}}>{RS('GB_stManageInterventions')}</h2> */}

                    <Paper style={styles.outsidePaper}>
                        <p style={styles.paragraph1}>
                            {RS('GB_stHereSelectModify')} { this.props.selectedCountry }{RS('GB_stBeginSelecting')}
                        </p>

                        <p style={styles.paragraph2}><b style={{color: '#cb6814', userSelect: 'none',}}>
                            {RS('GB_stStep1')}</b>&nbsp;{RS('GB_stSelectInterventions')}
                        </p>

                        <CSInterventionsBlueBox
                            onStateChange                  = {this.props.onStateChange}
                            onPageChange                   = {this.props.onPageChange}
                            onAddTasks                     = {this.props.onAddTasks}
                            handleSelectedResults          = {this.props.handleSelectedResults}
                            CSModvars                      = {this.props.CSModvars}
                            IVGroupInfo                    = {this.props.IVGroupInfo}
                            IVSubGroupInfo                 = {this.props.IVSubGroupInfo}
                            IVInfo                         = {this.props.IVInfo}
                            includeFamPlan                 = {this.props.includeFamPlan}
                            captionStyle                   = {styles.captionStyle}
                            description                    = {"(" + String(this.getNumberInterventionsSelected()) + " " + RS('GB_stSelected') + ')'}
                            intervDrawerOpen               = {this.props.intervDrawerOpen}
                            displayChart                   = {this.props.displayChart}
                            selectedCountry                = {this.props.selectedCountry}
                            displayMaternalCOD             = {this.props.displayMaternalCOD}
                            displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                            displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                            displayChildCOD                = {this.props.displayChildCOD}
                            displayAdolescentCOD           = {this.props.displayAdolescentCOD}
                            displayCPR                     = {this.props.displayCPR}
                            deliveryPointsContent          = {this.props.deliveryPointsContent}
                            maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                            stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                            neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                            childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                            adolescentCausesOfDeathContent = {this.props.adolescentCausesOfDeathContent}
                            sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                            sortByInterventionCost         = {this.props.sortByInterventionCost}
                            viewInterventionCost           = {this.props.viewInterventionCost}
                            MOCountryData                  = {this.props.MOCountryData}
                            isDeskTop                      = {this.props.isDeskTop}
                            showMOIntervSection            = {this.props.showMOIntervSection}
                            maternalIntervSelected         = {this.props.maternalIntervSelected}
                            stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                            neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                            childIntervSelected            = {this.props.childIntervSelected}
                            nutritionSelected              = {this.props.nutritionSelected}
                            birthOutcomeSelected           = {this.props.birthOutcomeSelected}
                            adolescentsSelected            = {this.props.adolescentsSelected}
                            selectAllIntervBool            = {this.props.selectAllIntervBool}
                            topMOInterventions             = {this.props.topMOInterventions}
                            MOResultToDisplay              = {this.props.MOResultToDisplay}
                            isExpandMODrawerClosed         = {this.props.isExpandMODrawerClosed}
                            language                       = {this.props.language}
                            onOpenHelpDialog               = {this.props.onOpenHelpDialog}
                        />

                        {
                            (this.getNumberInterventionsSelected() > 0) ?
                                <div>
                                    <p style={styles.paragraph2}><b style={{color: '#cb6814', userSelect: 'none',}}>{RS('GB_stStep2')} </b>&nbsp;{RS('GB_stModifyCoverage')}</p>

                                    <Paper style={styles.paper}>
                                        {
                                            (this.getNumberCoverageInterventionsSelected() > 0) ?
                                            <CSCoverageBlueBox
                                                onStateChange={this.props.onStateChange}
                                                onAddTasks={this.props.onAddTasks}
                                                IVInfo={this.props.IVInfo}
                                                CSModvars={this.props.CSModvars}
                                                firstYear={this.props.firstYear}
                                                captionStyle={styles.captionStyle}
                                                description={'(' + String(this.getNumberCoverageInterventionsSelected()) + " " + RS('GB_stInterventionsSmallI') +  ')'}
                                                showExtraBtn={this.getNumberCoverageInterventionsSelected() > 0}
                                                showAllInterventionsCB={this.props.showAllInterventionsCB}
                                                language={this.props.language}
                                                showRetroYears={this.props.showRetroYears}
                                                onOpenHelpDialog={this.props.onOpenHelpDialog}
                                                showQualitySwitch={this.getNumberQualityInterventionsSelected() > 0}
                                                showRetroSwitch={this.getNumberVaccineInterventionsSelected() > 0}
                                                showBFCB={this.getBFPromoSelected()}
                                            /> : null
                                        }

                                        {
                                            (this.isDirectEntrySelected(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF)) ?
                                                <CSBreastfeedingBlueBox
                                                    onStateChange={this.props.onStateChange}
                                                    captionStyle={styles.captionStyle}
                                                    descriptionStyle={styles.descriptionStyle}
                                                    CSModvars={this.props.CSModvars}
                                                    firstYear={this.props.firstYear}
                                                    onOpenHelpDialog={this.props.onOpenHelpDialog}
                                                /> : null
                                        }

                                        {
                                            (this.isDirectEntrySelected(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfStunting)) ?
                                                <CSStuntingBlueBox
                                                    onStateChange={this.props.onStateChange}
                                                    CSModvars={this.props.CSModvars}
                                                    captionStyle={styles.captionStyle}
                                                    firstYear={this.props.firstYear}
                                                    onOpenHelpDialog={this.props.onOpenHelpDialog}
                                                /> : null
                                        }

                                        {
                                            (this.isDirectEntrySelected(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting)) ?
                                                <CSWastingBlueBox
                                                    onStateChange={this.props.onStateChange}
                                                    CSModvars={this.props.CSModvars}
                                                    captionStyle={styles.captionStyle}
                                                    firstYear={this.props.firstYear}
                                                    onOpenHelpDialog={this.props.onOpenHelpDialog}
                                                /> : null
                                        }

                                        {
                                            (this.isDirectEntrySelected(CONSTANTS.CS_MstDirectEntryFertilityRisks)) ?
                                                <CSFertilityRisksBlueBox
                                                    onStateChange={this.props.onStateChange}
                                                    CSModvars={this.props.CSModvars}
                                                    captionStyle={styles.captionStyle}
                                                    firstYear={this.props.firstYear}
                                                    onOpenHelpDialog={this.props.onOpenHelpDialog}
                                                /> : null
                                        }

                                        {
                                            (this.getNumberFamPlanHIVInterventionsSelected() > 0) ?
                                                <CSFamPlanHIVBlueBox
                                                    onStateChange={this.props.onStateChange}
                                                    onAddTasks={this.props.onAddTasks}
                                                    IVInfo={this.props.IVInfo}
                                                    CSModvars={this.props.CSModvars}
                                                    firstYear={this.props.firstYear}
                                                    projectionName={this.props.projectionName}
                                                    captionStyle={styles.captionStyle}
                                                    description={'(' + String(this.getNumberFamPlanHIVInterventionsSelected()) + " " + RS('GB_stInterventionsSmallI') +  ')'}
                                                    showExtraBtn={this.getNumberFamPlanHIVInterventionsSelected() > 0}
                                                    showAllInterventionsCB={this.props.showAllInterventionsCB}
                                                    language={this.props.language}
                                                    showRetroYears={this.props.showRetroYears}
                                                    onOpenHelpDialog={this.props.onOpenHelpDialog}
                                                    showQualitySwitch={this.getNumberQualityInterventionsSelected() > 0}
                                                    showRetroSwitch={this.getNumberVaccineInterventionsSelected() > 0}
                                                    HIVAdolIntervsSelected={this.getHIVAdolIntervsSelected()}
                                                /> : null
                                        }

                                    </Paper>
                                </div> : null
                        }
                    </Paper>

                    {this.renderBackBtn()}

                    {this.renderNextBtn()}
                </div>
            </div>
        );
    }

}

export default CSManageInterventionsForm2;