import React from 'react';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import TExpansionPanel from "@common/components/TExpansionPanel";
import RS from "@common/strings/RS";

class YourInterventions extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        yourInterventionNames     : PropTypes.arrayOf(PropTypes.object),
        onClearInterventionsClick : PropTypes.func
    };

    static defaultProps = {
        yourInterventionNames     : [],
        onClearInterventionsClick : () => {}
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render () {
        const styles = {
            paperBox: {
                width: '388px',
                marginBottom: '15px',
                //position: 'absolute',
                float: 'left',
                background: '#FFFFFF',
                borderRadius: 0,
                border: '1px solid #ebebeb',
                boxShadow: 'none',
            },
            expansionPanel: {
                marginTop: 0,
                marginBottom: 0
            },
            expansionPanelSummary : {
                backgroundColor: '#3B78BC',
                maxHeight: '45px',
                minHeight: 0,
                width: '388px',
                marginLeft: '-1px'
            },
            header : {
                fontSize: '22px',
                //fontWeight: 'bold',
                color: '#FFFFFF'
            },
            expandMoreIcon : {
                color: '#FFFFFF'
            },
            expansionPanelDetails : {
                display: 'block',
                backgroundColor: '#ebebeb',
                fontSize: '13px',
                paddingLeft: '24px',
                paddingTop: '5px',
                paddingBottom: '15px'
            },
            expansionPanelActionStyle : {
                paddingTop: 5,
                paddingBottom: 5
            },
            buttonStyle: {
                fontSize: '16px',
            },
        };

        return(
            <Paper style={styles.paperBox}>
                <TExpansionPanel
                    onButtonClick={this.props.onClearInterventionsClick}
                    items={[[0, RS('GB_stYourInterventions'), false, '', false]]}
                    content={[[this.props.yourInterventionNames]]}
                    defaultExpanded={true}
                    expansionPanelStyle={styles.expansionPanel}
                    expansionPanelSummaryStyle={styles.expansionPanelSummary}
                    expandMoreIconStyle={styles.expandMoreIcon}
                    expansionPanelDetailsStyle={styles.expansionPanelDetails}
                    expansionPanelActionStyle={styles.expansionPanelActionStyle}
                    headerStyle={styles.header}
                    showButton={(this.props.yourInterventionNames.length > 0)}
                    buttonLabel={RS('GB_stClear')}
                    buttonStyle={styles.buttonStyle}
                    buttonVariant={'text'}
                />
            </Paper>
        );
    }

}

export default YourInterventions;
