import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    getAgeCohortName,
    CSGetImpactPromoAgeAppropriateBFStr
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const ImpactPromoEarlyInitBFEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stImpactPromoEarlyBF');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let ImpactsBFPromotion = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ImpactsBFPromotion_MV3);

        let opt = 1;
        for(let row = 2; row < pt.GBRowCount; row++){
            ImpactsBFPromotion["value"][CONSTANTS.CS_Data][CONSTANTS.CS_ImpactPromoEarlyBF][opt][
                CONSTANTS.CS_OddsRatio][CONSTANTS.CS_0t1months] = pt.tableData.value[row][1];
            ImpactsBFPromotion["source"][CONSTANTS.CS_ImpactPromoEarlyBF][opt] = pt.Source[pt.SourceMap[row]];
            opt++;
        }

        changedModvars.push(ImpactsBFPromotion);

        return (changedModvars);
    },
};

const Setup = () => {
    const numCols = 2;
    const numRows = 5;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    pt.tableData.value[0][1] = getAgeCohortName(CONSTANTS.CS_0t1months, true);
    pt.tableData.value[1][1] = RS('GB_stOddsRatio');

    pt.numSources = 0;

    let row = 2;
    for(let i = CONSTANTS.CS_BFPromoHealthSystem; i <= CONSTANTS.CS_BFPromoBoth; i++){
        pt.tableData.value[row][0] = CSGetImpactPromoAgeAppropriateBFStr(i);
        pt.numSources++;
        pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
        pt.SourceMap[row] = pt.numSources;
        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            pt.Alignments[row][col] = CONSTANTS.GB_Right;
            pt.RDec[row][col] = 2;
            pt.MaxAllowedVal[row][col] = 1000;
            col++;
        }
        row++;
    }

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let ImpactsBFPromotion = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsBFPromotion_MV3)["value"][
        CONSTANTS.CS_Data];

    let ImpactsBFPromotionSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsBFPromotion_MV3)["source"];

    let opt = 1;
    for(let row = 2; row < pt.GBRowCount; row++){
        pt.tableData.value[row][1] = ImpactsBFPromotion[CONSTANTS.CS_ImpactPromoEarlyBF][opt][CONSTANTS.CS_OddsRatio][
            CONSTANTS.CS_0t1months];
        pt.Source[pt.SourceMap[row]] = ImpactsBFPromotionSrc[CONSTANTS.CS_ImpactPromoEarlyBF][opt];
        opt++;
    }

    return pt;
};

