import React from 'react';
import PropTypes from "prop-types";
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import Theme from "../../app/Theme";
import CONSTANTS from "utilities/CS/CSConst";

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minWidth: 0, // So the Typography noWrap works
    },
    toolbar: theme.mixins.toolbar,
    paper: {
        borderRight: "none"
    }

});

class TAppSideBar extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        open             : PropTypes.bool,
        appMode          : PropTypes.string,
        menuThreshold    : PropTypes.bool,
        classes          : PropTypes.object,
        MOSideBarOpen    : PropTypes.bool
    };

    static defaultProps = {
        open             : true,
        appMode          : CONSTANTS.CS_ProjectionMode,
        menuThreshold    : false,
        MOSideBarOpen    : true
    };

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (
    //         (this.props.open             !== nextProps.open) ||
    //         (this.props.appMode          !== nextProps.appMode) ||
    //         (this.props.classes          !== nextProps.classes)
    //     ) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderSideBarDiv = () => {
        if ((this.props.appMode === CONSTANTS.CS_MissedOpportunitiesModeStep1) ||
            (this.props.appMode === CONSTANTS.CS_ExploreLiSTDataModeStep1)) {
            return(this.renderToolsSideBarDiv());
        }
        else {
            return(this.renderStandardSideBarDiv());
        }
    };

    renderStandardSideBarDiv = () => {
        return(
            <div style={{height: '100%', width: '327px', marginTop: this.props.menuThreshold ? 25 : 0, ...Theme.standardSideBar}}>
                {
                    (this.props.children) ?
                        this.props.children :
                        <div>No children given</div>
                }
            </div>
        )
    };

    renderToolsSideBarDiv = () => {
        return(
            <div style={{height: '100%', width: this.props.MOSideBarOpen ? '325px' : '50px', marginTop: this.props.menuThreshold ? 25 : 0, /*borderRight: 'none',*/ ...Theme.toolsSideBar}}>
                {
                    (this.props.children) ?
                        this.props.children :
                        <div>No children given</div>
                }
            </div>
        )
    };

    render() {
        const open = 
            this.props.open && 
            (this.props.appMode !== CONSTANTS.CS_MissedOpportunitiesMode) && 
            (this.props.appMode !== CONSTANTS.CS_ExploreLiSTDataMode) && 
            (this.props.appMode !== CONSTANTS.CS_ExploreLiSTDataModeStep1) && 
            (this.props.appMode !== CONSTANTS.CS_ProjectionMode) && 
            (this.props.appMode !== CONSTANTS.CS_ExtractMode) && 
            (this.props.appMode !== "");

        return (
            <Drawer
                variant="persistent"
                open={open}
                // classes={{ paperAnchorDockedLeft: this.props.classes.paper }}
                style={{zIndex: Theme.zIndex.drawer, width: '325px', border: 'none'}}
            >
                <div className={this.props.classes.toolbar} />

                {this.renderSideBarDiv()}
            </Drawer>
        );
    }
}

export default withStyles(styles)(TAppSideBar);