import * as React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import SubNatDrawer from "../../drawers/SubNatWizard/SubNatDrawer";
import CreateAPITask from "../../../../api/util/createAPITask";
import {FirstYearPopEditor} from "../../data/SubNatWizard/FirstYearPopEditor";
import {TFREditor} from "../../data/SubNatWizard/TFREditor";
import {HIVIncidenceEditor} from "../../data/SubNatWizard/HIVIncidenceEditor";
import {CPREditor} from "../../data/SubNatWizard/CPREditor";
import {PMTCTEditor} from "../../data/SubNatWizard/PMTCTEditor";
import {CotrimoxazoleEditor} from "../../data/SubNatWizard/CotrimoxazoleEditor";
import {AdultARTEditor} from "../../data/SubNatWizard/AdultARTEditor";
import CONSTANTS from "utilities/CS/CSConst";
import {CoverageEditor} from "../../data/Coverage/CS/CoverageEditor";
import {EarlyInitEditor} from "../../data/Coverage/CS/EarlyInitEditor";
import {BFPrevEditor} from "../../data/Coverage/CS/BFPrevEditor";
import {StuntWastEditor} from "../../data/Coverage/CS/StuntWastEditor";
import CSDialog from "../../../../components/dialogs/CSDialog";
import NoButton from "../../../../components/buttons/NoButton";
import YesButton from "../../../../components/buttons/YesButton";

const SubNatNotCompleteDialog = (props) => {

    let { onYes, onNo, /*itemIndex, onChange*/ } = props;

    return (
        <CSDialog
            title={RS('GB_stConfigNotComplete')}
            open={true}
            modal={true}
            onClose={onNo}
            content={
                <div>
                    <p>{RS('GB_stMustVisitAllThreePages')}</p>

                    <p>{RS('GB_stAreYouSureCancel')}</p>
                </div>

            }
            actions={
                <div>
                    <YesButton onClick={onYes} />
                    <NoButton onClick={onNo} colorType="secondary" />
                </div>
            }
        />
    );
};

const SubNatLoseDataDialog = (props) => {

    let { onYes, onNo, /*itemIndex, onChange*/ } = props;

    return (
        <CSDialog
            title={RS('GB_stAreYouSureQuestion')}
            open={true}
            modal={true}
            onClose={onNo}
            content={
                <div>
                    <p>{RS('GB_stUnsavedChanges')}</p>
                </div>

            }
            contentStyle={{marginBottom: 0, paddingBottom: 0}}
            actions={
                <div>
                    <YesButton onClick={onYes} />
                    <NoButton onClick={onNo} colorType="secondary" />
                </div>
            }
            actionStyle={{marginTop: 0, paddingTop: 0}}
        />
    );
};

class SubNatBlueBox extends React.Component {

    static propTypes = {
        onAddTasks            : PropTypes.func,
        onStateChange         : PropTypes.func,
        onClose               : PropTypes.func,
        onSave                : PropTypes.func,
        onRetrievedSubnatModvars : PropTypes.func,
        subnatDone            : PropTypes.bool,
        subnatModvars         : PropTypes.arrayOf(PropTypes.object),
        IVInfo                : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo        : PropTypes.arrayOf(PropTypes.object),
        countryISO            : PropTypes.number,
        yearRange             : PropTypes.object,
        projectionName        : PropTypes.string,
        subnatRegion          : PropTypes.string,
        subnatSurvey          : PropTypes.string,
        language              : PropTypes.number,
        onOpenHelpDialog      : PropTypes.func
    };

    static defaultProps = {
        onAddTasks            : () => console.log("onAddTasks"),
        onStateChange         : () => console.log("onStateChange"),
        onClose               : () => console.log("onClose"),
        onSave                : () => console.log("onSave"),
        onRetrievedSubnatModvars : () => console.log("onRetrievedSubnatModvars"),
        subnatDone            : false,
        subnatModvars         : [],
        IVInfo                : [],
        IVSubGroupInfo        : [],
        countryISO            : -1,
        yearRange             : {min: CONSTANTS.CS_MinYear, max: CONSTANTS.CS_MaxYear},
        projectionName        : '',
        subnatRegion          : "",
        subnatSurvey          : "",
        language              : CONSTANTS.GB_English,
        onOpenHelpDialog      : () => console.log("onOpenHelpDialog")
    };

    state = {
        loading               : true,
        subnatReady           : false,
        subnatStep            : 1,
        _subnatModvars        : this.props.subnatModvars,
        packTableFYP          : {},
        packTableTFR          : {},
        packTableInc          : {},
        packTablePMTCT        : {},
        packTableCotrim       : {},
        packTableART          : {},
        packTableCPR          : {},
        packTableCov          : {},
        packTableEarlyInit    : {},
        packTableBF           : {},
        packTableStunt        : {},
        packTableWast         : {},
        showLoseDataDialog    : false,
        showNotCompleteDialog : false,
        dataChanged           : false,
        qualityOn             : true,
        configIndex           : 0,
        dataLossIndex         : 0
    };

    componentDidMount() {

        const { subnatDone, subnatModvars, IVInfo, language, yearRange } = this.props;
        let subnatMode = true;
        //let singleMode = true;
        let QualityMode = this.state.qualityOn;
        let exploreData = false;
        let showRetroYears = false;

        if (subnatDone) {
            this.setState({
                packTableFYP       : FirstYearPopEditor.Create(subnatModvars)[CONSTANTS.CS_PackTable],
                packTableTFR       : TFREditor.Create(subnatModvars)[CONSTANTS.CS_PackTable],
                packTableInc       : HIVIncidenceEditor.Create(subnatModvars)[CONSTANTS.CS_PackTable],
                packTablePMTCT     : PMTCTEditor.Create(subnatModvars)[CONSTANTS.CS_PackTable],
                packTableCotrim    : CotrimoxazoleEditor.Create(subnatModvars)[CONSTANTS.CS_PackTable],
                packTableART       : AdultARTEditor.Create(subnatModvars)[CONSTANTS.CS_PackTable],
                packTableCPR       : CPREditor.Create(subnatModvars)[CONSTANTS.CS_PackTable],
                packTableCov       : CoverageEditor.Create(IVInfo, subnatModvars, language, yearRange.min, yearRange.max, QualityMode, exploreData, showRetroYears, subnatMode)[CONSTANTS.CS_PackTable],
                packTableEarlyInit : EarlyInitEditor.Create(subnatModvars, yearRange.min, yearRange.max, subnatMode),
                packTableBF        : BFPrevEditor.Create(subnatModvars, yearRange.min, yearRange.max, subnatMode),
                packTableStunt     : StuntWastEditor.Create(subnatModvars, yearRange.min, yearRange.max, CONSTANTS.CS_GroupMstID_Stunting, subnatMode)[CONSTANTS.CS_PackTable],
                packTableWast      : StuntWastEditor.Create(subnatModvars, yearRange.min, yearRange.max, CONSTANTS.CS_GroupMstID_Wasting, subnatMode)[CONSTANTS.CS_PackTable],
                loading            : false,
            });
            return;
        }

        this.props.onAddTasks([
            CreateAPITask("getSubNatModvars",
                {
                    FileName       : this.props.projectionName,      // this.props.projectionName,
                    CountryISO     : this.props.countryISO,
                    FirstYear      : this.props.yearRange.min,
                    FinalYear      : this.props.yearRange.max,
                    ModList        : ['1', '2', '3', '4', '15'],
                    Notes          : "",                               // this.props.projectionNotes,
                    Author         : "",                               // this.props.authorName,
                    Org            : "",                               // this.props.orgName,
                    Region         : this.props.subnatRegion,
                    Survey         : this.props.subnatSurvey,
                    ProjectCreated : false,                            // this.props.projectCreated
                    subnatStep1    : true,
                    subnatStep2    : false,
                    AgeGroupOption : CONSTANTS.FP_Single_Age_Group,
                },
                (res) => {
                    let modvars = res.getModvars;
                    let selectedIVSet = [];

                    for (let i = 0; i < this.props.IVInfo.length; i++) {
                        if (this.props.IVInfo[i][CONSTANTS.CS_Coverage_ED]){
                            if(
                                (this.props.IVInfo[i]['MstID'] !== CONSTANTS.IV_MstContraceptiveUse) &&
                                (this.props.IVInfo[i]['MstID'] !== CONSTANTS.IV_MstCotrimoxazole) &&
                                (this.props.IVInfo[i]['MstID'] !== CONSTANTS.IV_MstART) &&
                                (this.props.IVInfo[i]['MstID'] !== CONSTANTS.IV_MstPMTCT) &&
                                (this.props.IVInfo[i]['MstID'] !== CONSTANTS.IV_MstChangesInBF_BFPromo)
                            ) {
                                selectedIVSet.push(this.props.IVInfo[i]['MstID'])
                            }
                        }
                    }

                    let value = {
                        intArray : selectedIVSet
                    };

                    this.setState(
                        {
                            _subnatModvars: modvars.map(x =>
                                (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV) ? {...x, value: value} : x
                            )
                        },
                        () => {
                            const {IVInfo, language, yearRange} = this.props;
                            const {_subnatModvars} = this.state;

                            let subnatMode = true;
                            //let singleMode = true;
                            let QualityMode = this.state.qualityOn;
                            let exploreData = false;
                            let showRetroYears = false;

                            this.setState({
                                packTableFYP       : FirstYearPopEditor.Create(_subnatModvars)[CONSTANTS.CS_PackTable],
                                packTableTFR       : TFREditor.Create(_subnatModvars)[CONSTANTS.CS_PackTable],
                                packTableInc       : HIVIncidenceEditor.Create(_subnatModvars)[CONSTANTS.CS_PackTable],
                                packTablePMTCT     : PMTCTEditor.Create(_subnatModvars)[CONSTANTS.CS_PackTable],
                                packTableCotrim    : CotrimoxazoleEditor.Create(_subnatModvars)[CONSTANTS.CS_PackTable],
                                packTableART       : AdultARTEditor.Create(_subnatModvars)[CONSTANTS.CS_PackTable],
                                packTableCPR       : CPREditor.Create(_subnatModvars)[CONSTANTS.CS_PackTable],
                                packTableCov       : CoverageEditor.Create(IVInfo, _subnatModvars, language, yearRange.min, yearRange.max, QualityMode, exploreData, showRetroYears, subnatMode)[CONSTANTS.CS_PackTable],
                                packTableEarlyInit : EarlyInitEditor.Create(_subnatModvars, yearRange.min, yearRange.max, subnatMode),
                                packTableBF        : BFPrevEditor.Create(_subnatModvars, yearRange.min, yearRange.max, subnatMode),
                                packTableStunt     : StuntWastEditor.Create(_subnatModvars, yearRange.min, yearRange.max, CONSTANTS.CS_GroupMstID_Stunting, subnatMode)[CONSTANTS.CS_PackTable],
                                packTableWast      : StuntWastEditor.Create(_subnatModvars, yearRange.min, yearRange.max, CONSTANTS.CS_GroupMstID_Wasting, subnatMode)[CONSTANTS.CS_PackTable],
                                loading            : false,
                                dataChanged        : true,
                            });

                            this.props.onRetrievedSubnatModvars(_subnatModvars);

                        }
                    );
                },
                (msg) => {
                    console.log(msg);
                }
            )
        ]);
    }

    //==================================================================================================================
    //
    //                                             Event Handlers
    //
    //==================================================================================================================

    onAttemptClose = () => {

        if (!this.props.subnatDone) {
            this.setState({
                showNotCompleteDialog : true
            });
        }
        else if (this.state.dataChanged) {
            this.setState({
                showLoseDataDialog : true
            });
        }
        else {
            this.onClose();
        }
    };

    onLoseDataDialogYes = () => {
        this.setState({
            showLoseDataDialog : false,
            showNotCompleteDialog : false
        }, () => {
            this.onClose();
        });
    };

    onLoseDataDialogNo = () => {
        this.setState({
            showLoseDataDialog : false,
            showNotCompleteDialog : false
        });
    };

    onClose = () => {
        this.props.onClose();
    };

    onSave = () => {
        let subnatMode = true;
        //let singleMode = true;
        let PentaAutoCalc = false;

        let newSubnatModvars = FirstYearPopEditor.GetSSData(this.state.packTableFYP, this.props.subnatModvars);
        newSubnatModvars = TFREditor.GetSSData(this.state.packTableTFR, newSubnatModvars);
        newSubnatModvars = HIVIncidenceEditor.GetSSData(this.state.packTableInc, newSubnatModvars);
        newSubnatModvars = PMTCTEditor.GetSSData(this.state.packTablePMTCT, newSubnatModvars);
        newSubnatModvars = CotrimoxazoleEditor.GetSSData(this.state.packTableCotrim, newSubnatModvars);
        newSubnatModvars = AdultARTEditor.GetSSData(this.state.packTableART, newSubnatModvars);
        newSubnatModvars = CPREditor.GetSSData(this.state.packTableCPR, newSubnatModvars);
        newSubnatModvars = CoverageEditor.GetSSData(this.state.packTableCov, newSubnatModvars, this.props.language, PentaAutoCalc, subnatMode);
        newSubnatModvars = EarlyInitEditor.GetSSData(this.state.packTableEarlyInit, newSubnatModvars, subnatMode);
        newSubnatModvars = BFPrevEditor.GetSSData(this.state.packTableBF, newSubnatModvars, subnatMode);
        newSubnatModvars = StuntWastEditor.GetSSData(this.state.packTableStunt, newSubnatModvars, CONSTANTS.CS_GroupMstID_Stunting, subnatMode);
        newSubnatModvars = StuntWastEditor.GetSSData(this.state.packTableWast, newSubnatModvars, CONSTANTS.CS_GroupMstID_Wasting, subnatMode);
        this.props.onSave(newSubnatModvars);
    };

    onQualityChange = (mode) => {
        const {IVInfo, language, yearRange} = this.props;
        const {_subnatModvars} = this.state;

        let subnatMode = true;
        let exploreData = false;
        let showRetroYears = false;
        let PentaAutoCalc = false;

        let ModvarsArray  = CoverageEditor.GetSSData(this.state.packTableCov, _subnatModvars, this.props.language, PentaAutoCalc, subnatMode);

        let changedCSModvars = _subnatModvars.map(x => {
            let a = ModvarsArray.find(y => y.tag === x.tag);
            return a || x;
        });

        /* Create a new packTable with the updated Coverage. */
        let newPackTable = CoverageEditor.Create(IVInfo, changedCSModvars, language, yearRange.min, yearRange.max,
            mode, exploreData, showRetroYears, subnatMode)[CONSTANTS.CS_PackTable];

        /* Appropriately update the pentavalent rows of the packtable the user is about to be editing. */
        newPackTable = CoverageEditor.UpdatePentaRows(newPackTable, PentaAutoCalc, true);

        this.setState({
            qualityOn      : mode,
            packTableCov   : newPackTable,
            _subnatModvars : changedCSModvars
        })
    };

    onSubNatStepChange = (step) => {
        this.setState({
            subnatReady : this.state.subnatReady || (this.props.subnatDone && this.state.dataChanged) || (step > 3),
            subnatStep: step
        });
    };

    onEditorChange = (pt) => {
        let sel = pt.ID;

        if (sel === CONSTANTS.CS_MstSubNatFYPSheetID) {
            pt = FirstYearPopEditor.RecalcGrid(pt);
            this.setState({
                packTableFYP : pt,
                dataChanged  : true,
                subnatReady  : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstSubNatTFRSheetID) {
            pt = TFREditor.RecalcGrid(pt);
            this.setState({
                packTableTFR : pt,
                dataChanged  : true,
                subnatReady  : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstSubNatIncSheetID) {
            pt = HIVIncidenceEditor.RecalcGrid(pt);
            this.setState({
                packTableInc : pt,
                dataChanged  : true,
                subnatReady  : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstSubNatPMTCTSheetID) {
            this.setState({
                packTablePMTCT : pt,
                dataChanged    : true,
                subnatReady    : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstSubNatCotrimSheetID) {
            this.setState({
                packTableCotrim : pt,
                dataChanged     : true,
                subnatReady     : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstSubNatARTSheetID) {
            this.setState({
                packTableART : pt,
                dataChanged  : true,
                subnatReady  : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstSubNatCPRSheetID) {
            pt = CPREditor.RecalcGrid(pt);
            this.setState({
                packTableCPR : pt,
                dataChanged  : true,
                subnatReady  : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID) {
            pt = CoverageEditor.RecalcGrid(pt, false);
            this.setState({
                packTableCov : pt,
                dataChanged  : true,
                subnatReady  : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstEarlyInitBFSheetID) {
            this.setState({
                packTableEarlyInit : pt,
                dataChanged : true,
                subnatReady : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstLiSTOnlineBFPromoSheetID) {
            pt = BFPrevEditor.RecalcGrid(pt);
            this.setState({
                packTableBF : pt,
                dataChanged : true,
                subnatReady : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstLiSTOnlineStuntingSheetID) {
            pt = StuntWastEditor.RecalcGrid(pt);
            this.setState({
                packTableStunt : pt,
                dataChanged    : true,
                subnatReady    : (this.props.subnatDone && true)
            });
        }
        else if (sel === CONSTANTS.CS_MstLiSTOnlineWastingSheetID) {
            pt = StuntWastEditor.RecalcGrid(pt);
            this.setState({
                packTableWast : pt,
                dataChanged   : true,
                subnatReady   : (this.props.subnatDone && true)
            });
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <div>
                {
                    (!this.state.loading) ?
                        <SubNatDrawer
                            open               = {true}
                            onClose            = {this.onAttemptClose}
                            appBarText         = {RS("GB_stConfigsubNatProj")}
                            onSave             = {this.onSave}
                            onStateChange      = {this.props.onStateChange}
                            onStepChange       = {this.onSubNatStepChange}
                            onQualityChange    = {this.onQualityChange}
                            packTableFYP       = {this.state.packTableFYP}
                            packTableTFR       = {this.state.packTableTFR}
                            packTableInc       = {this.state.packTableInc}
                            packTablePMTCT     = {this.state.packTablePMTCT}
                            packTableCotrim    = {this.state.packTableCotrim}
                            packTableART       = {this.state.packTableART}
                            packTableCPR       = {this.state.packTableCPR}
                            packTableCov       = {this.state.packTableCov}
                            packTableEarlyInit = {this.state.packTableEarlyInit}
                            packTableBF        = {this.state.packTableBF}
                            packTableStunt     = {this.state.packTableStunt}
                            packTableWast      = {this.state.packTableWast}
                            onEditorChange     = {this.onEditorChange}
                            subnatReady        = {this.state.subnatReady}
                            qualityOn          = {this.state.qualityOn}
                            onOpenHelpDialog   = {this.props.onOpenHelpDialog}
                            subnatStep         = {this.state.subnatStep}
                        />
                        : null
                }

                {
                    (this.state.showNotCompleteDialog) ? 
                        <SubNatNotCompleteDialog
                            onYes={this.onLoseDataDialogYes}
                            onNo={this.onLoseDataDialogNo}
                            itemIndex={this.state.configIndex}
                            onChange={(value) => this.setState({configIndex: value})}
                        />
                        : null
                }

                {
                    (this.state.showLoseDataDialog) ?
                        <SubNatLoseDataDialog 
                            onYes={this.onLoseDataDialogYes}
                            onNo={this.onLoseDataDialogNo}
                            itemIndex={this.state.dataLossIndex}
                            onChange={(value) => this.setState({dataLossIndex: value})}
                        /> 
                        : null
                }
            </div>
        );
    }
}

export default SubNatBlueBox;