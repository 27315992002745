import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import RS from "@common/strings/RS";
import CreateAPITask from "../../../api/util/createAPITask";
import CountryTabs from "./CountryTabs";
import AppBar from "@material-ui/core/AppBar";
import CONSTANTS from "utilities/CS/CSConst";
// import {getSnapshotData} from "./ExploreData/content/GenerateShowData";
// import * as Highmaps from 'highcharts/highmaps'
// import worldMap from "./world_map";
 
class CSCountryListForm extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange               : PropTypes.func,
        onPageChange                : PropTypes.func,
        onAddTasks                  : PropTypes.func,
        appMode                     : PropTypes.string,
        isDeskTop                   : PropTypes.bool,
        language                    : PropTypes.number,
        menuThreshold               : PropTypes.bool
    };

    static defaultProps = {
        onStateChange               : () => console.log('onStateChange'),
        onPageChange                : () => console.log('onPageChange'),
        onAddTasks                  : () => console.log('onAddTasks'),
        appMode                     : '',
        isDeskTop                   : true,
        language                    : CONSTANTS.GB_English,
        menuThreshold               : false
    };

    //==================================================================================================================
    //
    //                                             Event Handlers
    //
    //==================================================================================================================

    onCountryLabelClick = (selectedCountry, countryISO) => {
        if (this.props.appMode === CONSTANTS.CS_MissedOpportunitiesMode) {
            this.props.onStateChange({
                selectedCountry        : selectedCountry,
                countryISO             : countryISO,
                displayMaternalCOD     : true,
                displayStillbirthCOD   : true,
                displayNeonatalCOD     : true,
                displayChildCOD        : true,
                displayCPR             : false,
                sortByDeathsAverted    : true,
                sortByInterventionCost : false,
                viewInterventionCost   : false,
                displayChart           : true,
                MOResultToDisplay      : CONSTANTS.CS_PotentialDeathsAverted,
            });

            this.props.onAddTasks([
                CreateAPITask('getMOCountryDataFlag', {countryISO: countryISO, useRawData: false},
                    () => {
                        this.props.onPageChange('CSMissedOpportunitiesForm')
                    },
                    (msg) => {
                        alert(RS('GB_stErrorRetrieveCountryData'));
                    }
                )
            ]);
        }
        else if (this.props.appMode === CONSTANTS.CS_ExploreLiSTDataMode) {
            // let snapshotData = [];
            // let inputModvars = [];
            this.props.onStateChange({
                selectedCountry       : selectedCountry,
                countryISO            : countryISO,
            });

            this.props.onAddTasks([
                CreateAPITask('getExploreLiSTDataFlag', {countryISO: countryISO, modList: [15, 3], useRawData: true},
                    () => {
                        this.props.onPageChange('CSExploreLiSTDataForm')
                    },
                    (msg) => {
                        alert(RS('GB_stErrorRetrieveCountryData'));
                    }
                )
            ]);
        }
    };

    //==================================================================================================================
    //
    //                                                  Render
    //
    //==================================================================================================================

    renderHeader = () => {
        const styles = {
            headerText: {
                color: '#cb6814',
                fontSize: '22px',
                fontWeight: 'bold',
                userSelect: 'none',
                marginTop: '25px',
                marginBottom: '10px',
                display: "inline-block",
                marginRight: "25px"
            },
        };

        if (this.props.appMode === CONSTANTS.CS_MissedOpportunitiesMode) {
            return (
                <p style={styles.headerText}>{RS('GB_stMissedOpportunities')}</p>
            );
        }
        else if (this.props.appMode === CONSTANTS.CS_ExploreLiSTDataMode) {
            return (
                <p style={styles.headerText}>{RS('GB_stExploreLiSTData')}</p>
            );
        }
    };

    renderSelectACountry = () => {
        return (
            <h3 style={{userSelect: 'none', display: "inline-block"}}>{RS('GB_stSelectACountry')}</h3>
        );
    };

    // renderMap = () => {
    //     const styles = {
    //         map: {
    //             //float:"right",
    //             maxWidth:"100%",
    //             width: this.props.isDeskTop ? window.innerWidth - 750 : "100%",
    //             margin:"auto"
    //         }
    //     }
    //
    //     return (
    //         <div id="world_map" style={styles.map}></div>
    //     )
    // };
    //
    // makeMap = (dat) => {
    //     Highmaps.mapChart('world_map', {
    //         chart: {
    //             map: 'custom/world',
    //         },
    //         title: {
    //             text: ''
    //         },
    //         legend: {
    //             enabled: false
    //         },
    //         credits: {
    //             enabled: false
    //         },
    //         mapNavigation: {
    //             enabled: true,
    //             buttonOptions: {
    //                 verticalAlign: 'top',
    //                 align:'right'
    //             }
    //         },
    //         tooltip: {
    //             useHTML: true,
    //             formatter: function() {
    //                 let imgSrc = "flags/" + this.point.name + ".svg";
    //                 let imgStyle="width:25px; float:left; margin-right:10px";
    //                 let flag = '<img style="' + imgStyle + '" src="' + imgSrc + '"/>';
    //                 return '<p style="padding-right:35px">' + flag + this.point.name + '</p>';
    //             }
    //         },
    //         series: [
    //             {
    //                 data: dat,
    //                 mapData: worldMap,
    //                 joinBy: ['iso-a3', 'ISO3'],
    //                 name: 'LiST Countries',
    //                 states: {
    //                     hover: {
    //                         color: '#a4edba'
    //                     }
    //                 }
    //             }
    //         ]
    //     })
    // };

    render() {
        const styles = {
            headerStyle : {
                width : '100%',
                marginRight: '25px',
                paddingLeft: '20px',
                marginTop: this.props.menuThreshold ? 105 : 65,
                backgroundColor: '#FFFFFF'
            },
            appBarStyle : {
                marginLeft: '0px',
                backgroundColor: '#FFFFFF',
                width: '100%',
                boxShadow: 'none'
            }
        };

        return (
            <div className="innerPage">
                <AppBar position="fixed" color="default" style={styles.appBarStyle}>
                    {/*<Toolbar style={{display: 'inline'}}>*/}
                        <div style={styles.headerStyle}>
                            {this.renderHeader()}
                            {this.renderSelectACountry()}
                        </div>
                    {/*</Toolbar>*/}
                </AppBar>
                <CountryTabs
                    onCountryLabelClick = {this.onCountryLabelClick}
                    isDeskTop           = {this.props.isDeskTop}
                />
            </div>
        );
    }
}

export default CSCountryListForm;