import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    lockPackTable, addRowToPackTable, add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {getMatCODName} from "utilities/CS/CSDataUtil";

export const PercMatDeathsByCauseEditor = {
    Create : (CSModvars) => {
        let editorName = RS('GB_stMaternalCause');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = PercMatDeathsByCauseEditor.RecalcGrid(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_MaternalMortality;
        return ([packChart, packTable]);
    },

    RecalcGrid : (pt) => {
        let Sum = 0;
        for (let row = 1; row < pt.RowIds.length-1; row++) {
            Sum += pt.tableData.value[row][1];
        }
        pt.tableData.value[pt.RowIds.length-1][1] = Sum;
        return (pt);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let PercMatDeathsByCauseMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PercMatDeathsByCause_MV);
        for (let row = 1; row < pt.RowIds.length; row++) {
            let d = pt.RowIds[row];
            if (d > 0) {
                PercMatDeathsByCauseMV["value"][d] = pt.tableData.value[row][1] / 100;
            }
        }
        PercMatDeathsByCauseMV["source"][0][0] = pt.Source[0];
        changedModvars.push(PercMatDeathsByCauseMV);
        return (changedModvars);
    },
};

const Setup = () => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.diseaseSet = [];

    for (let d = CONSTANTS.CS_Mat_MinDeathCauses; d <= CONSTANTS.CS_Mat_MaxDeathCauses; d++) {
        pt.diseaseSet.push(d);
    }

    const numRows = 1;
    const numCols = 2;

    pt = resizePackTable(pt, numRows, numCols);

    let r = 1;
    pt.diseaseSet.forEach(function (d) {
        pt = addRowToPackTable(pt);
        pt.RowIds[r] = d;
        pt.tableData.value[r][0] = getMatCODName(d);
        pt.Alignments[r][1] = CONSTANTS.GB_Right;
        pt.RDec[r][1] = 2;
        pt.SourceMap[r] = 1;
        r++;
    });

    pt = addRowToPackTable(pt);
    pt.tableData.value[r][0] = RS('GB_stTotal');
    pt.Alignments[r][1] = CONSTANTS.GB_Right;
    pt.RDec[r][1] = 2;

    pt = lockPackTable(pt, r, true);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let PercMatDeathsByCause = getModvarByTag(CSModvars, CONSTANTS.CS_TG_PercMatDeathsByCause_MV);
    for (let row = 1; row < pt.RowIds.length; row++) {
        let d = pt.RowIds[row];
        if (d > 0) {
            pt.tableData.value[row][1] = PercMatDeathsByCause["value"][d] * 100;
        }
        pt.Source[0] = PercMatDeathsByCause["source"][0][0];
    }
    return pt;
};

