import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    add_NoHistoricalData_MsgToPackTable,
    lockPackTable, setSourceArrLength
} from "utilities/GB/GBUtil";

import {
    getAgeCohortName
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const IncidenceEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, age = 0) => {
        let editorName = RS('DP_stIncidence') + ' (' + RS('GB_stNumCasesPerChildYear') + ')';
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, age);
        packTable = SetSSData(packTable, CSModvars, age);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ChildHealth;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let DiarIncMV        = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_DiarInc_MV);
        let SevereDiarIncMV  = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_SevereDiarInc_MV);
        let PneumIncMV       = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PneumInc_MV);
        let MeningIncMV      = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_MeningInc_MV);

        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            DiarIncMV["value"][a]       = pt.tableData.value[1][a];
            SevereDiarIncMV["value"][a] = pt.tableData.value[2][a];
            PneumIncMV["value"][a]      = pt.tableData.value[3][a];
            MeningIncMV["value"][a]     = pt.tableData.value[4][a];
        }

        DiarIncMV["source"][0][0]       = pt.Source[pt.SourceMap[1]];
        SevereDiarIncMV["source"][0][0] = pt.Source[pt.SourceMap[2]];
        PneumIncMV["source"][0][0]      = pt.Source[pt.SourceMap[3]];
        MeningIncMV["source"][0][0]     = pt.Source[pt.SourceMap[4]];

        changedModvars.push(DiarIncMV);
        changedModvars.push(SevereDiarIncMV);
        changedModvars.push(PneumIncMV);
        changedModvars.push(MeningIncMV);

        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, age) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    let numCols;
    let BaseYear;
    pt.yearArr = [];

    if (age !== 0) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }
    else {
        numCols = 6;
    }

    const numRows = 5;

    pt = resizePackTable(pt, numRows, numCols);

    if (age !== 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            for (let r = 1; r <= 4; r++) {
                pt.Alignments[r][col] = CONSTANTS.GB_Right;
                pt.RDec[r][col] = 3;
            }
            col++;
        }
    }
    else {
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            pt.tableData.value[0][a] = getAgeCohortName(a, true);
        }
    }

    pt.tableData.value[1][0] = RS('GB_stDiarInc');
    pt.tableData.value[2][0] = RS('GB_stIncSevereDiarr');
    pt.tableData.value[3][0] = RS('GB_stPneumInc');
    pt.tableData.value[4][0] = RS('GB_stMeningInc');

    for (let r = 1; r <= 4; r++) {
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            pt.Alignments[r][a] = CONSTANTS.GB_Right;
            pt.RDec[r][a] = 3;
        }
        pt = lockPackTable(pt, r, pt.yearArr.length > 0, false);
        pt.SourceMap[r] = r;
    }

    pt = setSourceArrLength(pt, 4);

    return pt;
};

const SetSSData = (pt, CSModvars, age) => {
    let DiarInc       = getModvarByTag(CSModvars, CONSTANTS.CS_TG_DiarInc_MV);
    let SevereDiarInc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SevereDiarInc_MV);
    let PneumInc      = getModvarByTag(CSModvars, CONSTANTS.CS_TG_PneumInc_MV);
    let MeningInc     = getModvarByTag(CSModvars, CONSTANTS.CS_TG_MeningInc_MV);

    if (pt.yearArr.length > 0){
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            if(DiarInc["exploreLiST"][age][t] >= 0) {
                pt.tableData.value[1][col] = DiarInc["exploreLiST"][age][t];
            }
            if(SevereDiarInc["exploreLiST"][age][t] >= 0) {
                pt.tableData.value[2][col] = SevereDiarInc["exploreLiST"][age][t];
            }
            if(PneumInc["exploreLiST"][age][t] >= 0) {
                pt.tableData.value[3][col] = PneumInc["exploreLiST"][age][t];
            }
            if(MeningInc["exploreLiST"][age][t] >= 0) {
                pt.tableData.value[4][col] = MeningInc["exploreLiST"][age][t];
            }
            col++;
        }
    }
    else {
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            pt.tableData.value[1][a] = DiarInc["value"][a];
            pt.tableData.value[2][a] = SevereDiarInc["value"][a];
            pt.tableData.value[3][a] = PneumInc["value"][a];
            pt.tableData.value[4][a] = MeningInc["value"][a];
        }
    }

    pt.Source[pt.SourceMap[1]] = DiarInc["source"][0][0];
    pt.Source[pt.SourceMap[2]] = SevereDiarInc["source"][0][0];
    pt.Source[pt.SourceMap[3]] = PneumInc["source"][0][0];
    pt.Source[pt.SourceMap[4]] = MeningInc["source"][0][0];

    return pt;
};

