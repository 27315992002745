import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    getIdxFromYear,
    getYearFromIdx,
    generatePackChart,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const GNIPerCapEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, LastAgeAtWork, lockTable = false) => {
        let editorName = RS('GB_stGNIPerCap');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, LastAgeAtWork);
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_EconomicStatus;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let GNIPerCapMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_GNIPerCap_MV);
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            GNIPerCapMV["value"][t] = pt.tableData.value[1][col];
            col++;
        }
        GNIPerCapMV["source"][0][0] = pt.Source[0];
        changedModvars.push(GNIPerCapMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, LastAgeAtWork) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear) + LastAgeAtWork;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.yearArr = [];
    let numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
    for(let t = StartIndex; t <= EndIndex; t++){
        pt.yearArr.push(t);
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBColWidths[0] = 500;

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.MaxAllowedVal[1][col] = 1000000;
        pt.RDec[1][col] = 2;
        col++;
    }

    pt.tableData.value[1][0] = RS('GB_stDefaultGNILab');

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let GNIPerCap = getModvarByTag(CSModvars, CONSTANTS.CS_TG_GNIPerCap_MV);
    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        if (GNIPerCap["value"][t] >= 0) {
            pt.tableData.value[1][col] = GNIPerCap["value"][t];
        }
        col++;
    }
    pt.Source[0] = GNIPerCap["source"][0][0];
    return pt;
};

