import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSPathogensDrawer from "../../drawers/HealthStatus/CSPathogensDrawer";
import {PathogenEditor} from "../../data/HealthStatus/Pathogens/PathogenEditor";

class CSPathogensBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        IVInfo           : PropTypes.arrayOf(PropTypes.object),
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        IVInfo           : [],
        onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled : true,
        openDrawer      : false,
        packTable1      : null,
    };

    componentDidMount() {
        this.setState({
            packTable1 : PathogenEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.openDrawer) {
            return {
                packTable1 : PathogenEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable]
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true, 
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({ 
            openDrawer      : false,
            saveBtnDisabled : true,
            packTable1      : PathogenEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    };

    onSaveBtnClick = () => {
        const over100 = 1;
        const under0 = 2;

        let goAhead = true;
        let errorType = -1;
        let tableData = this.state.packTable1.tableData.value;
        for (let row = 1; row < this.state.packTable1.GBRowCount; row++){
            for (let col = 1; col < this.state.packTable1.GBColCount; col++) {
                let value = tableData[row][col];
                if (typeof value === "number") {
                    if (value > 100){
                        goAhead = false;
                        errorType = over100;
                    }
                    if (value < 0){
                        goAhead = false;
                        errorType = under0;
                    }
                }
            }
        }

        if (goAhead) {
            let ModvarsArray1 = PathogenEditor.GetSSData(this.state.packTable1, this.props.IVInfo, this.props.CSModvars);

            let changedCSModvars = this.props.CSModvars.map(x => {
                let a = ModvarsArray1.find(y => y.tag === x.tag);
                return a || x;
            });

            this.setState({
                openDrawer: false,
                saveBtnDisabled: true,
            });

            this.props.onStateChange({
                unchangedProj: false,
                CSModvars: changedCSModvars
            });
        }
        else {
            let msg;
            switch(errorType){
                case over100 : {msg = RS("GB_stValOver100"); break;}
                case under0 : {msg = RS("GB_stValUnderZero"); break;}
                default : {break;}
            }
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : msg
            });
        }
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2 
                caption     = {RS('GB_stPathogens')}
                description = ""  
                onClick     = {this.onBlueBoxClick}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"pathogens.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stPathogens')}
                        content         = {
                                            <CSPathogensDrawer
                                                packTable1={this.state.packTable1}
                                                onPackTable1Change={this.onPackTable1Change}
                                            />
                                        }
                    />
                }
            />
        );
    }

}

export default CSPathogensBlueBox;