import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    lockPackTable,
    add_NoHistoricalData_MsgToPackTable,
    setSourceArrLength
} from "utilities/GB/GBUtil";

import {FindIntervRec} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const NutritionalDeficienciesEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stNutrDeficiencies');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ChildHealth;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, IVInfo, CSModvars) => {
        let changedModvars = [];

        let NutritionalDeficienciesMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_NutritionalDeficiencies_MV2);
        NutritionalDeficienciesMV["value"][CONSTANTS.CS_VitADefic] = pt.tableData.value[1][1];
        NutritionalDeficienciesMV["value"][CONSTANTS.CS_ZincDefic] = pt.tableData.value[2][1];

        /* Set affected fractions that rely on the HealthStatus variable. */
        let DiarrIncReductionMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_DiarrIncReduction_MV3);
        let PneumIncReductionMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PneumIncReduction_MV);
        let EfficacyMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_Efficacy_MV2);

        let VitASupp = FindIntervRec(IVInfo, CONSTANTS.IV_VitASupp);
        let ZincSupp = FindIntervRec(IVInfo, CONSTANTS.IV_ZincSupp);

        for (let a = CONSTANTS.CS_AgeSummary; a <= CONSTANTS.CS_24t60months; a++){
            /* Vitamin A */
            let value = NutritionalDeficienciesMV["value"][CONSTANTS.CS_VitADefic]/100;
            DiarrIncReductionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_VitAPTwoDoses_DiarInterv][CONSTANTS.CS_AffecFract][a] = value;
            DiarrIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_VitAPTwoDoses_DiarInterv][CONSTANTS.CS_AffecFract][a] = value;

            /* Zinc */
            value = NutritionalDeficienciesMV["value"][CONSTANTS.CS_ZincDefic]/100;
            DiarrIncReductionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_ZincP_DiarInterv][CONSTANTS.CS_AffecFract][a] = value;
            DiarrIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_ZincP_DiarInterv][CONSTANTS.CS_AffecFract][a] = value;

            /* Zinc */
            value = NutritionalDeficienciesMV["value"][CONSTANTS.CS_ZincDefic]/100;
            PneumIncReductionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_ZincP_PneumInterv][CONSTANTS.CS_AffecFract][a] = value;
            PneumIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_ZincP_PneumInterv][CONSTANTS.CS_AffecFract][a] = value;

            for (let d = 1; d <= CONSTANTS.CS_MaxDeathCauses; d++) {
                /* Vitamin A */
                value = NutritionalDeficienciesMV["value"][CONSTANTS.CS_VitADefic] / 100;
                if (VitASupp !== null) {
                    EfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][VitASupp["CurrID"]][d][a] = value;
                    EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][VitASupp["CurrID"]][d][a] = value;
                }

                /* Zinc */
                value = NutritionalDeficienciesMV["value"][CONSTANTS.CS_ZincDefic] / 100;
                if (ZincSupp !== null) {
                    EfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][ZincSupp["CurrID"]][d][a] = value;
                    EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][ZincSupp["CurrID"]][d][a] = value;
                }
            }
        }

        NutritionalDeficienciesMV["source"][0][CONSTANTS.CS_VitADefic] = pt.Source[pt.SourceMap[1]];
        NutritionalDeficienciesMV["source"][0][CONSTANTS.CS_ZincDefic] = pt.Source[pt.SourceMap[2]];

        changedModvars.push(NutritionalDeficienciesMV);
        changedModvars.push(DiarrIncReductionMV);
        changedModvars.push(PneumIncReductionMV);
        changedModvars.push(EfficacyMV);

        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    let numCols = 2;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    if (pt.yearArr.length > 0) {
        const numRows = 15;
        pt = resizePackTable(pt, numRows, numCols);
        pt.tableData.value[1][0] = RS('GB_stPercVitADef');
        pt.tableData.value[2][0] = RS('GB_stVitB6Def');
        pt.tableData.value[3][0] = RS('GB_stVitB12Def');
        pt.tableData.value[4][0] = RS('GB_stVitCDef');
        pt.tableData.value[5][0] = RS('GB_stCalcDef');
        pt.tableData.value[6][0] = RS('GB_stCoppDef');
        pt.tableData.value[7][0] = RS('GB_stFolDef');
        pt.tableData.value[8][0] = RS('GB_stIronDef');
        pt.tableData.value[9][0] = RS('GB_stMagDef');
        pt.tableData.value[10][0] = RS('GB_stNiacinDef');
        pt.tableData.value[11][0] = RS('GB_stPhosDef');
        pt.tableData.value[12][0] = RS('GB_stRiboDef');
        pt.tableData.value[13][0] = RS('GB_stThiamDef');
        pt.tableData.value[14][0] = RS('GB_stPercZincDef');
    }
    else {
        const numRows = 3;
        pt = resizePackTable(pt, numRows, numCols);
        pt.tableData.value[1][0] = RS('GB_stPercVitADef');
        pt.tableData.value[2][0] = RS('GB_stPercZincDef');
    }

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        for (let r = 1; r < pt.GBRowCount; r++) {
            pt.Alignments[r][col] = CONSTANTS.GB_Right;
            pt.RDec[r][col] = 2;
        }
        col++;
    }

    for (let r = 1; r < pt.GBRowCount; r++) {
        pt.Alignments[r][1] = CONSTANTS.GB_Right;
        pt.RDec[r][1] = 2;
        pt = lockPackTable(pt, r, pt.yearArr.length > 0, false);
        pt.SourceMap[r] = r;
    }

    pt = setSourceArrLength(pt, 2);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let NutritionalDeficiencies = getModvarByTag(CSModvars, CONSTANTS.CS_TG_NutritionalDeficiencies_MV2);

    if (pt.yearArr.length > 0){
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitADefic][t] >= 0) {
                pt.tableData.value[1][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitADefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitB6Defic][t] >= 0) {
                pt.tableData.value[2][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitB6Defic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitB12Defic][t] >= 0) {
                pt.tableData.value[3][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitB12Defic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitCDefic][t] >= 0) {
                pt.tableData.value[4][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_VitCDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_CalcDefic][t] >= 0) {
                pt.tableData.value[5][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_CalcDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_CoppDefic][t] >= 0) {
                pt.tableData.value[6][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_CoppDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_FolDefic][t] >= 0) {
                pt.tableData.value[7][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_FolDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_IronDefic][t] >= 0) {
                pt.tableData.value[8][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_IronDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_MagDefic][t] >= 0) {
                pt.tableData.value[9][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_MagDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_NiaDefic][t] >= 0) {
                pt.tableData.value[10][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_NiaDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_PhosDefic][t] >= 0) {
                pt.tableData.value[11][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_PhosDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_RiboDefic][t] >= 0) {
                pt.tableData.value[12][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_RiboDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_ThiaDefic][t] >= 0) {
                pt.tableData.value[13][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_ThiaDefic][t];
            }

            if(NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_ZincDefic][t] >= 0) {
                pt.tableData.value[14][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_ZincDefic][t];
            }

            col++;
        }

        pt.Source[pt.SourceMap[1]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_VitADefic];
        pt.Source[pt.SourceMap[2]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_VitB6Defic];
        pt.Source[pt.SourceMap[3]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_VitB12Defic];
        pt.Source[pt.SourceMap[4]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_VitCDefic];
        pt.Source[pt.SourceMap[5]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_CalcDefic];
        pt.Source[pt.SourceMap[6]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_CoppDefic];
        pt.Source[pt.SourceMap[7]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_FolDefic];
        pt.Source[pt.SourceMap[8]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_IronDefic];
        pt.Source[pt.SourceMap[9]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_MagDefic];
        pt.Source[pt.SourceMap[10]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_NiaDefic];
        pt.Source[pt.SourceMap[11]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_PhosDefic];
        pt.Source[pt.SourceMap[12]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_RiboDefic];
        pt.Source[pt.SourceMap[13]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_ThiaDefic];
        pt.Source[pt.SourceMap[14]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_ZincDefic];
    }

    else {
        pt.tableData.value[1][1] = NutritionalDeficiencies["value"][CONSTANTS.CS_VitADefic];
        pt.tableData.value[2][1] = NutritionalDeficiencies["value"][CONSTANTS.CS_ZincDefic];

        pt.Source[pt.SourceMap[1]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_VitADefic];
        pt.Source[pt.SourceMap[2]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_ZincDefic];
    }

    return pt;
};

