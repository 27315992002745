import * as React from "react";
import PropTypes from "prop-types";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TButton from "./TButton";
import TSwitch from "./TSwitch";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

class TExpansionPanel extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange              : PropTypes.func,
        onButtonClick              : PropTypes.func,
        onQualitySwitchChange      : PropTypes.func,
        onMoreInfoIconClick        : PropTypes.func,
        items                      : PropTypes.array,
        content                    : PropTypes.array,
        defaultExpanded            : PropTypes.bool,
        disabled                   : PropTypes.bool,
        expansionPanelStyle        : PropTypes.object,
        expansionPanelSummaryStyle : PropTypes.object,
        expandMoreIconStyle        : PropTypes.object,
        expansionPanelDetailsStyle : PropTypes.object,
        expansionPanelActionStyle  : PropTypes.object,
        headerStyle                : PropTypes.object,
        showButton                 : PropTypes.bool,
        buttonLabel                : PropTypes.string,
        buttonStyle                : PropTypes.object,
        buttonDisabled             : PropTypes.bool,
        switchStyle                : PropTypes.object,
        buttonVariant              : PropTypes.string,
        buttonColor                : PropTypes.string,
        buttonToolTip              : PropTypes.string,
        showHeaderIcon             : PropTypes.bool,
        headerIconStyle            : PropTypes.object,
        panelKey                   : PropTypes.string,
    };

    static defaultProps = {
        onStateChange              : () => console.log('onStateChange'),
        onButtonClick              : () => console.log('onButtonClick'),
        onQualitySwitchChange      : () => console.log('onSwitchChange'),
        onMoreInfoIconClick        : () => console.log('onMoreInfoIconClick'),
        items                      : [
                                        [1, 'Header Caption 1', true, 'Switch Caption 1', true, true],
                                        [2, 'Header Caption 2', false, 'Switch Caption 2', false, true],
                                        [3, 'Header Caption 3', true, 'Switch Caption 3', false, false],
                                     ],
        content                    : [<Typography>Content</Typography>],
        disabled                   : false,
        defaultExpanded            : false,
        expansionPanelStyle        : {},
        expansionPanelSummaryStyle : {},
        expandMoreIconStyle        : {},
        expansionPanelDetailsStyle : {},
        expansionPanelActionStyle  : {},
        headerStyle                : {},
        buttonChip                 : false,
        buttonLabel                : '',
        buttonStyle                : {border: 'none'},
        buttonDisabled             : false,
        buttonVariant              : 'outlined',
        buttonColor                : 'primary',
        buttonToolTip              : '',
        switchStyle                : {},
        showHeaderIcon             : false,
        headerIconStyle            : {},
        panelKey                   : ''
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderContent = (items) => {
        return (items);
    };

    renderPanels = () => {

        const styles = {
            switchStyle : {
                color: '#f78c30',
                fontSize: '16px',
                // fontWeight: 'bold'
            },
            icons: {
                color: '#65A7EB',
                fontSize: '16px'
                // marginLeft: '20px',
                // padding: 0,
                // top: '-3px'
            },
        };


        const groupID          = 0;
        const headerName       = 1;
        const showSwitch       = 2;
        const switchCaption    = 3;
        const switchOn         = 4;
        const showMoreInfoIcon = 5;

        let s = [];

        for (let i = 0; i < this.props.items.length; i++) {

            let switchGroupID  = this.props.items[i][groupID];
            let headerTitle    = this.props.items[i][headerName];
            let switchShow     = this.props.items[i][showSwitch];
            let switchTitle    = this.props.items[i][switchCaption];
            let switchTurnedOn = this.props.items[i][switchOn];
            let showMoreInfo   = this.props.items[i][showMoreInfoIcon];

            let showHeaderIcon = this.props.showHeaderIcon;

            s.push(
                <Accordion
                    disabled={this.props.disabled}
                    defaultExpanded={this.props.defaultExpanded} 
                    style={{...this.props.expansionPanelStyle}} 
                    key={'ExpansionPanel' + i + this.props.panelKey}
                >
                    <AccordionSummary
                        style={{...this.props.expansionPanelSummaryStyle}} 
                        expandIcon={<ExpandMoreIcon 
                        style={{...this.props.expandMoreIconStyle}} />}
                        key={'ExpansionPanelSummary' + i + this.props.panelKey}
                    >
                        <div>
                            <Typography style={{...this.props.headerStyle}}>
                                {headerTitle}
                            </Typography>
                        </div>
                        {
                            (showHeaderIcon) ?
                                <div style={{height: '55px', width: '75px'}}>
                                    <IconButton
                                        onClick={this.onClick}
                                        style={{...styles.icons}}>
                                        <Icon className='fa fa-info-circle'/>
                                    </IconButton>
                                </div>
                                :
                                null
                        }
                    </AccordionSummary>

                    <AccordionDetails
                        style={{...this.props.expansionPanelDetailsStyle}} 
                        key={'ExpansionPanelD' + i + this.props.panelKey}
                    >
                        {
                            (switchShow) ?
                                <div style={{marginTop: '0px', marginBottom: '35px'}}>
                                    <div style={{float: 'right'}}>
                                        <TSwitch
                                            style={{...this.props.switchStyle}}
                                            caption={switchTitle}
                                            labelStyle={styles.switchStyle}
                                            value={switchTurnedOn}
                                            name={''}
                                            customProp={switchGroupID}
                                            onClick={(checked, event, name, caption, groupID) => this.props.onQualitySwitchChange(checked, groupID)}
                                        />
                                    </div>
                                </div>
                                :
                                null
                        }

                        <div key={'ExpansionPanelDetails' + i + this.props.panelKey}>
                            {this.renderContent(this.props.content[i])}
                        </div>
                    </AccordionDetails>

                    {
                        (this.props.showButton) ?
                            <AccordionActions style={this.props.expansionPanelActionStyle}>
                                <Tooltip title={this.props.buttonToolTip} disableFocusListener={true}>
                                    <div>
                                        <TButton
                                            style={this.props.buttonStyle}
                                            caption={this.props.buttonLabel}
                                            disabled={this.props.buttonDisabled}
                                            color={this.props.buttonColor}
                                            variant={this.props.buttonVariant}
                                            onClick={this.props.onButtonClick}
                                        />
                                    </div>
                                </Tooltip>
                            </AccordionActions>:
                            null
                    }

                    {
                        (showMoreInfo) ?
                            <AccordionActions style={{paddingTop: 0, paddingBottom: 0, background: '#ebebeb', marginTop: '-35px'}}>
                                <IconButton
                                    color='secondary'
                                    aria-label="More information"
                                    onClick={this.props.onMoreInfoIconClick}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </AccordionActions> :
                            null
                    }

                </Accordion>
            );
        }

        return s;
    };

    render() {
        return (
            <div style={{width: '100%'}} >
                {this.renderPanels()}
            </div>
        );
    }
}

export default TExpansionPanel;