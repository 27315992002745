import * as React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import MOSection from "./MOSection";

class CSMissedOpportunitiesForm extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onAddTasks                     : PropTypes.func,
        onExploreLiSTDataBtnClick      : PropTypes.func,
        onGuidedExplorationBtnClick    : PropTypes.func,
        onCreateProjBtnClick           : PropTypes.func,
        MOSideBarOpen                  : PropTypes.bool,
        appMode                        : PropTypes.string,
        displayChart                   : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        countryISO                     : PropTypes.number,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        sidebarOpen                    : PropTypes.bool,
        isGuest                        : PropTypes.bool,
        isDeskTop                      : PropTypes.bool,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        onPageChange                   : () => console.log('onPageChange'),
        onAddTasks                     : () => console.log('onAddTasks'),
        onExploreLiSTDataBtnClick      : () => console.log('onExploreLiSTDataBtnClick'),
        onGuidedExplorationBtnClick    : () => console.log('onGuidedExplorationBtnClick'),
        onCreateProjBtnClick           : () => console.log('onCreateProjBtnClick'),
        MOSideBarOpen                  : true,
        appMode                        : '',
        displayChart                   : true,
        selectedCountry                : 'Afghanistan',
        countryISO                     : 4,
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        sidebarOpen                    : true,
        isGuest                        : true,
        isDeskTop                      : true,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        language                       : CONSTANTS.GB_English
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderBody = () => {
        return (
            <MOSection
                onStateChange                  = {this.props.onStateChange}
                displayChart                   = {this.props.displayChart}
                selectedCountry                = {this.props.selectedCountry}
                displayMaternalCOD             = {this.props.displayMaternalCOD}
                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                displayChildCOD                = {this.props.displayChildCOD}
                displayCPR                     = {this.props.displayCPR}
                MOSideBarOpen                  = {this.props.MOSideBarOpen}
                deliveryPointsContent          = {this.props.deliveryPointsContent}
                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                sortByInterventionCost         = {this.props.sortByInterventionCost}
                viewInterventionCost           = {this.props.viewInterventionCost}
                MOCountryData                  = {this.props.MOCountryData}
                maternalIntervSelected         = {this.props.maternalIntervSelected}
                stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                childIntervSelected            = {this.props.childIntervSelected}
                nutritionSelected              = {this.props.nutritionSelected}
                birthOutcomeSelected           = {this.props.birthOutcomeSelected}
                MOResultToDisplay              = {this.props.MOResultToDisplay}
                language                       = {this.props.language}
            />
        )
    };

    render() {
        return (
            <div className="innerPage">
                {this.renderBody()}
            </div>
        );
    }
}

export default CSMissedOpportunitiesForm;