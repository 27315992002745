import axios from 'axios';
import { SessionStorageUtil } from "../api/localstorage/util";

//============================================================================================================
//                                            REQUIRED
//============================================================================================================

// These global window variables must be declared in a global javascript file / oconfig file.
//
// window.App.useContainers
// window.App.CONTAINER_PASS_PREFIX
// window.App.CLIENT_ID
// window.App.CONTAINER_BASE_URLs
// window.App.API_URL
// window.App.API_URL_BASE

//============================================================================================================
//                                            VARIABLES
//============================================================================================================

const CONTAINER_LINKTOKEN_TIMEOUT = 30;
let CLIENT_LINKTOKEN_TRIES        = 0;

let container_url_index         = -1;

let CONTAINER_USE               = window.App.useContainers; // true
let CONTAINER_ALLOW_INVALID     = true;
    
let CONTAINER_BASE_URL          = SessionStorageUtil.getContainerBaseURL();

let CONTAINER_LOGIN_URL         = CONTAINER_BASE_URL + '/api/v1/session/';          // Talk to controller to ask for a container; get a token back
let CONTAINER_STATUS_URL        = CONTAINER_BASE_URL + '/api/v1/server/';           // Talk to controller to check 'token' until container is ready
let CONTAINER_SESSION_URL       = CONTAINER_BASE_URL + '/r/';                       // Talk to RemObjects server on the container
let CONTAINER_NAME              = '';                                               // Ability to declare a custom container to get. // "spectrum.azurecr.io/spectrum:latest"; // ""

let CONTAINER_USER              = 'guest';
let CONTAINER_PASS              = window.App.CONTAINER_PASS_PREFIX + window.App.CLIENT_ID;             //uuidv4(); // 'ListOnline1';
let CONTAINER_TOKEN             = '';
let CONTAINER_LINK_TOKEN        = SessionStorageUtil.getLinkToken();

let CONTAINER_TOKEN_URL         = '';
let CONTAINER_LINKTOKEN_URL     = '';
let CONTAINER_API_URL           = '';
let CONTAINER_API_BASE_URL      = '';

const GetCTUseContainers        = () => CONTAINER_USE;
const GetCTAllowInvalid         = () => CONTAINER_ALLOW_INVALID;
const GetCTBaseURL              = () => CONTAINER_BASE_URL;
// const GetCTBaseURL1             = () => CONTAINER_BASE_URL1;
// const GetCTBaseURL2             = () => CONTAINER_BASE_URL2;
// const GetCTBaseURL3             = () => CONTAINER_BASE_URL3;
const GetCTLoginURL             = () => CONTAINER_LOGIN_URL;
const GetCTStatusURL            = () => CONTAINER_STATUS_URL;
const GetCTSessionURL           = () => CONTAINER_SESSION_URL;
const GetCTContainerName        = () => CONTAINER_NAME;
const GetCTUser                 = () => CONTAINER_USER;
const GetCTPassword             = () => CONTAINER_PASS;
const GetCTToken                = () => CONTAINER_TOKEN;
const GetCTLinkToken            = () => CONTAINER_LINK_TOKEN;
const GetCTTokenURL             = () => CONTAINER_TOKEN_URL;
const GetCTLinkTokenURL         = () => CONTAINER_LINKTOKEN_URL;
const GetCTAPIURL               = () => CONTAINER_API_URL;
const GetCTAPIBaseURL           = () => CONTAINER_API_BASE_URL;

const SetCTUseContainers        = (value) => { CONTAINER_USE = value;            update(); };
const SetCTAllowInvalid         = (value) => { CONTAINER_ALLOW_INVALID = value;  update(); };
const SetCTBaseURL              = (value) => { CONTAINER_BASE_URL = value;       update(); };
// const SetCTBaseURL1             = (value) => { CONTAINER_BASE_URL1 = value;      update(); };
// const SetCTBaseURL2             = (value) => { CONTAINER_BASE_URL2 = value;      update(); };
// const SetCTBaseURL3             = (value) => { CONTAINER_BASE_URL3 = value;      update(); };
const SetCTLoginURL             = (value) => { CONTAINER_LOGIN_URL = value;      update(); };
const SetCTStatusURL            = (value) => { CONTAINER_STATUS_URL = value;     update(); };
const SetCTSessionURL           = (value) => { CONTAINER_SESSION_URL = value;    update(); };
const SetCTName                 = (value) => { CONTAINER_NAME = value;           update(); };
const SetCTUser                 = (value) => { CONTAINER_USER = value;           update(); };
const SetCTPassword             = (value) => { CONTAINER_PASS = value;           update(); };
const SetCTToken                = (value) => { CONTAINER_TOKEN = value;          update(); };
const SetCTLinkToken            = (value) => { CONTAINER_LINK_TOKEN = value;     update(); };
const SetCTTokenURL             = (value) => { CONTAINER_TOKEN_URL = value;      update(); };
const SetCTLinkTokenURL         = (value) => { CONTAINER_LINKTOKEN_URL = value;  update(); };
const SetCTAPIURL               = (value) => { CONTAINER_API_URL = value;        update(); };
const SetCTAPIBaseURL           = (value) => { CONTAINER_API_BASE_URL = value;   update(); };

//============================================================================================================
//                                              OnChange
//============================================================================================================

const update = () => {
    if (CONTAINER_USE) {    

        // Use existing url if possible
        if ((container_url_index === -1) && (CONTAINER_BASE_URL === ""))  {
            container_url_index++;
            CONTAINER_BASE_URL    = window.App.CONTAINER_BASE_URLs[container_url_index];
        }
        else if (container_url_index >= 0) {
            CONTAINER_BASE_URL    = window.App.CONTAINER_BASE_URLs[container_url_index];
        }

        CONTAINER_LOGIN_URL       = CONTAINER_BASE_URL + '/api/v1/session/';          // Talk to controller to ask for a container; get a token back
        CONTAINER_STATUS_URL      = CONTAINER_BASE_URL + '/api/v1/server/';           // Talk to controller to check 'token' until container is ready
        CONTAINER_SESSION_URL     = CONTAINER_BASE_URL + '/r/';                       // Talk to RemObjects server on the container

        CONTAINER_TOKEN_URL       = CONTAINER_LOGIN_URL + CONTAINER_USER + '?password=' + CONTAINER_PASS;
        CONTAINER_LINKTOKEN_URL   = CONTAINER_STATUS_URL + CONTAINER_TOKEN;
        CONTAINER_API_URL         = CONTAINER_SESSION_URL + CONTAINER_LINK_TOKEN + '/JSON';
        CONTAINER_API_BASE_URL    = CONTAINER_SESSION_URL + CONTAINER_LINK_TOKEN;

        window.App.API_URL        = CONTAINER_API_URL;
        window.App.API_URL_BASE   = CONTAINER_API_BASE_URL;

        if (CONTAINER_TOKEN !== "") {
            SessionStorageUtil.setToken(CONTAINER_TOKEN); // Set for Elric only.  Never get.
        }

        SessionStorageUtil.setLinkToken(CONTAINER_LINK_TOKEN);
        SessionStorageUtil.setContainerBaseURL(CONTAINER_BASE_URL);
    }
};

update();

//============================================================================================================
//                                              API Calls
//============================================================================================================

const Assigned = (value) => (typeof value !== 'undefined') && (value !== '');

const RequestToken = (params, successFn, errorFn) => { 
    // const user     = CONTAINER_USER; // params.user;
    // const password = CONTAINER_PASS; // params.password;
    const url      = CONTAINER_TOKEN_URL; //LOGIN_URL + user + '?password=' + password;

    axios
        .create({
            baseURL: url,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
        .post(url)
        .then(response => {
            if (!CONTAINER_ALLOW_INVALID && !Assigned(response.data.token)) {
                console.error("Client did not receive an expected token");
                errorFn({message : "Client did not receive an expected token"});
                return;
            }
                
            CONTAINER_TOKEN = response.data.token;
            console.log("Token received: " + CONTAINER_TOKEN); 

            update();
            successFn(response);
        })
        .catch(err => {
            console.error(err);
            errorFn(err);
        });
};

const RequestLinkToken = (params, successFn, errorFn) => {
    let token         = CONTAINER_TOKEN; // params.token;
    let containerName = CONTAINER_NAME;
    let url           = CONTAINER_LINKTOKEN_URL; //STATUS_URL + token;

    axios
        .create({
            baseURL: url,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'SpecW-Auth' : 'token ' + token		
            }
        })
        .post(url, { 
            "customContainer" : containerName 
        })
        .then(response => {
            if (response.data.ready) { 
                CONTAINER_LINK_TOKEN = response.data.linkToken; 
                console.log("LinkToken received: " + CONTAINER_LINK_TOKEN); 

                update();
            }
            successFn(response);
        })
        .catch(err => {
            console.error(err);
            errorFn(err);
        });
};

const PingSpectrumServer = (params, successFn, errorFn) => {
    let url      = CONTAINER_API_URL; // params.url;
    let clientId = "{2c8ff04b-a83e-4121-b126-9a4e36f8e332}"; // params.clientId;
    let method   = "GeneralService.Ping";

    axios
        .create({
            baseURL: url,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .post(url, {
            id: clientId,
            method: method,
            params: {
                jsonMesg: ""
            }
        })
        .then(response => {
            successFn(response);
        })
        .catch(err => {
            console.error(err);
            errorFn(err);
        });
};

//============================================================================================================
//                                              APP Logic
//============================================================================================================

const ContainerLogic = (successFn, errorFn) => {
    if (CONTAINER_LINK_TOKEN === "") {
        GetNewContainer(successFn, errorFn);
        return;
    }
    else {
        console.log('Attempting to ping: ' + CONTAINER_API_URL);
        PingSpectrumServer({},
            () => { console.log('Ping was successful.  Reusing existing container.'); successFn(); },
            () => { GetNewContainer(successFn, errorFn); }
        );
    }
};

const GetNewContainer = (successFn, errorFn) => {

    const _errorFn = () => {
        container_url_index++;

        if (container_url_index < window.App.CONTAINER_BASE_URLs.length) {
            update();
            GetNewContainer(successFn, errorFn);
        }
        else {
            console.log("All controllers are at max capacity.");
            errorFn('All controllers are at max capacity.'); 
            return;
        }   
    };

    doRequestToken1((response) => {
        if (response.data.atCapacity) {
            console.log("This api is at capacity.  Moving on to the next...");
            _errorFn();
        }
        else {
            CLIENT_LINKTOKEN_TRIES = 0;

            doIsTokenReady1(() => {
                doPingAppServer1(() => {
                    console.log('Container is good to go!');
                    successFn();
                }, _errorFn);
            }, _errorFn);
        }
    }, (e) => {
        console.log('Errored when requesting a token, moving on to the next API location...');
        _errorFn();   
    });
};

const IsTokenReady = (successFn, errorFn) => {

    const _successFn = (response) => {
        if (response.data.atCapacity) {
            console.error('Error: Containers are at full capacity');
            errorFn('Error: Containers are at full capacity');
        }
        else if (response.data.ready) {
            successFn(response);
        }
        else {
            setTimeout(() => {
                console.log('...');

                CLIENT_LINKTOKEN_TRIES++;

                if (CLIENT_LINKTOKEN_TRIES >= CONTAINER_LINKTOKEN_TIMEOUT) {
                    console.log('Client timed out while waiting for linkToken.');
                    errorFn("Client timed out waiting for linkToken.")
                }
                else {
                    RequestLinkToken({}, _successFn, _errorFn);
                }
            }, 1000);
        }
    };

    const _errorFn = (err) => {
        console.error("Client function IsTokenReady received an error", err);
        errorFn("Client function IsTokenReady received an error");
    };

    RequestLinkToken({}, _successFn, _errorFn);
};

const doRequestToken1 = (successFn, errorFn) => {
    console.log('Getting a token on ' + CONTAINER_BASE_URL + '...'); 
    RequestToken({}, successFn, errorFn);
};

const doIsTokenReady1 = (successFn, errorFn) => {
    console.log('Getting a linkToken...'); 
    IsTokenReady(successFn, errorFn);
};

const doPingAppServer1 = (successFn, errorFn) => {
    console.log('Attempting to ping the app server...'); 
    PingSpectrumServer({}, successFn, errorFn);
};

export {
    update,
    RequestToken,
    RequestLinkToken,
    PingSpectrumServer,
    
    ContainerLogic,

    GetCTUseContainers        ,
    GetCTAllowInvalid         ,
    GetCTBaseURL              ,
    GetCTLoginURL             ,
    GetCTStatusURL            ,
    GetCTSessionURL           ,
    GetCTContainerName        ,
    GetCTUser                 ,
    GetCTPassword             ,
    GetCTToken                ,
    GetCTLinkToken            ,
    GetCTTokenURL             ,
    GetCTLinkTokenURL         ,
    GetCTAPIURL               ,
    GetCTAPIBaseURL           ,

    SetCTUseContainers        ,
    SetCTAllowInvalid         ,
    SetCTBaseURL              ,
    SetCTLoginURL             ,
    SetCTStatusURL            ,
    SetCTSessionURL           ,
    SetCTName                 ,
    SetCTUser                 ,
    SetCTPassword             ,
    SetCTToken                ,
    SetCTLinkToken            ,
    SetCTTokenURL             ,
    SetCTLinkTokenURL         ,
    SetCTAPIURL               ,
    SetCTAPIBaseURL           ,
};