import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    CloneObj, lockPackTable, generatePackChart
} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";

export const FirstYearPopEditor = {
    Create : (CSModvars) => {
        let editorName = ''; // RS('GB_stFYP');
        let packTable = Setup(CSModvars);
        packTable = SetSSData(packTable, CSModvars);
        packTable = FirstYearPopEditor.RecalcGrid(packTable);
        packTable.Title = editorName;
        packTable.ID = CONSTANTS.CS_MstSubNatFYPSheetID;
        let packChart = generatePackChart(packTable);
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);
        let totalPop = getModvarByTag(CSModvarsClone, CONSTANTS.VW_TG_TTotalPop)["value"];
        totalPop[1] = pt.tableData.value[1][1];
        totalPop[2] = pt.tableData.value[2][1];
        return (CSModvarsClone);
    },

    RecalcGrid : (pt) => {
        let TotalPop = pt.tableData.value[1][1];
        let SubNatTotalPop = pt.tableData.value[2][1];
        pt.tableData.value[3][1] = (SubNatTotalPop / TotalPop) * 100;
        return (pt);
    },
};

const Setup = (CSModvars) => {
    let pt = getNewPT();

    const numRows = 4;
    const numCols = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[0][1] = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TFirstYear)["value"];
    pt.tableData.value[1][0] = RS('GB_stNationalPop');
    pt.tableData.value[2][0] = RS('GB_stSubnationalPop');
    pt.tableData.value[3][0] = RS('GB_stPercNatPop');

    pt.Alignments[0][1] = CONSTANTS.GB_Right;
    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.Alignments[2][1] = CONSTANTS.GB_Right;
    pt.Alignments[3][1] = CONSTANTS.GB_Right;

    pt.RDec[1][1] = 0;
    pt.RDec[2][1] = 0;
    pt.RDec[3][1] = 2;

    pt = lockPackTable(pt, 1, true);
    pt = lockPackTable(pt, 3, true);

    pt.MaxAllowedVal[2][1] = Number.MAX_SAFE_INTEGER;

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let totalPop = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TTotalPop)["value"];
    pt.tableData.value[1][1] = totalPop[1];
    pt.tableData.value[2][1] = totalPop[2];
    return pt;
};

