import React from 'react';
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import RS from "@common/strings/RS";
import Data from "../../../data/ExploreDefaultData";
import CONSTANTS from "utilities/CS/CSConst";
import CreateDisplayButton from "../buttons/CreateDisplayButton";
import OptionCheckBox from "../checkboxes/OptionCheckBox";

class HealthNutritionOptions extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        visible         : PropTypes.bool,
        onAddDisplay    : PropTypes.func,
        language        : PropTypes.number,
    };

    static defaultProps = {
        visible         : true,
        onAddDisplay    : () => console.log('HealthNutritionOptions onAddDisplay'),
        language        : CONSTANTS.GB_English,
    };

    state = {
        content         : Data.exploreHealthStatusContent,
        buttonDisabled  : true
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onShowDataBtnClick = () => {
        let newData = this.state.content.map(x => {
            if (x.checked) { 
                let mstID = x.mstID;

                if (
                    (mstID === CONSTANTS.CS_MstNutritionalDeficiencies_ED)||
                    (mstID === CONSTANTS.CS_MstStatusAtBirth_ED)||
                    (mstID === CONSTANTS.CS_MstIncidence_ED)||
                    (mstID === CONSTANTS.CS_MstFaliciparum_ED)||
                    (mstID === CONSTANTS.CS_MstAnemiaWRA_ED)||
                    (mstID === CONSTANTS.CS_MstSevereAnemia_ED)||
                    (mstID === CONSTANTS.CS_MstLowBMIAmongWRA_ED)||
                    (mstID === CONSTANTS.CS_MstStuntingDist_ED)||
                    (mstID === CONSTANTS.CS_MstWastingDist_ED)||
                    (mstID === CONSTANTS.CS_MstDistAllCases_ED)||
                    (mstID === CONSTANTS.CS_MstDistSevereCases_ED)||
                    (mstID === CONSTANTS.CS_MstDistFatalCases_ED)||
                    (mstID === CONSTANTS.CS_MstSpontaneousAbortion_ED)||
                    (mstID === CONSTANTS.CS_MstAbortionIncidenceRatio_ED)||
                    (mstID === CONSTANTS.CS_MstPovertyFoodSecurity_ED)||
                    (mstID === CONSTANTS.CS_MstAveHouseholdSize_ED)
                ) {
                    return ({
                        editorID             : x['editorID'],
                        mode                 : CONSTANTS.CS_ExploreHealthStatusMode,
                        firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                        finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                        hFirstYr             : CONSTANTS.CS_FirstYearHistoricalCountryData,
                        hFinalYr             : CONSTANTS.CS_FinalYearHistoricalCountryData,
                        possibleDisplayTypes : x['possibleDisplayTypes'],
                        selectedDisplayType  : CONSTANTS.CS_TableChart,
                        mstID                : x['mstID'],
                        tabs                 : x['tabs']
                    });
                }
            }
            return undefined;
        }).filter(x => x);

        this.props.onAddDisplay(newData);
        this.resetPanelToDefault();
    };

    resetPanelToDefault = () => {
        this.setState({
            content        : this.state.content.map(x => ({ ...x, checked : false })),
            buttonDisabled : true
        });
    };

    onCheckBoxChange = (checked, event, name, caption, mstID) => {
        let newContent = this.state.content.map(x => (x.mstID === mstID) ? {...x, checked : checked} : x);
        let checkedContent = newContent.filter(x => x.checked);

        this.setState({
            content        : newContent,
            buttonDisabled : checkedContent.length === 0
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderContent = () => {
        let panelContent = [];
        let groupID = -1;

        const styles = {
            groupNameStyle : {
                fontSize: '16px',
                color: '#3B78BC',
                marginBottom: '8px'
            },
            dividerStyle: {
                width: '265px',
                marginLeft: '-10px',
                marginTop: '10px',
                marginBottom: '10px'
            },
        };

        for (let i = 0; i < this.state.content.length; i++) {
            if (this.state.content[i]['groupID'] !== groupID) {
                if (groupID !== -1) {
                    panelContent.push(
                        (<Divider key={'d' + i} style={styles.dividerStyle}/>)
                    )
                }
                groupID = this.state.content[i]['groupID'];
                if (groupID !== CONSTANTS.CS_MstNoHealthStatusGroup_ED) {
                    panelContent.push(
                        <div key={this.state.content[i]['groupName']} style={styles.groupNameStyle}>
                            {RS(this.state.content[i]['groupName'])}
                        </div>
                    )
                }
            }
            if (this.state.content[i]['groupID'] === groupID) {
                panelContent.push(
                    <OptionCheckBox
                        caption={RS(this.state.content[i]['name'])}
                        value={this.state.content[i]['checked']}
                        key={this.state.content[i]['mstID']}
                        customProp={this.state.content[i]['mstID']}
                        onChange={this.onCheckBoxChange}
                    />
                )
            }
        }
        
        return (panelContent);
    };

    render() {
        const styles = {
            wrapper : {
                padding: '0 0 10px 0',
                // margin: '10px 15px 10px 0',
                margin: '0 15px 0 0',
            },
            invisible : {
                position: 'absolute',
                left: -9999,
                visibility: 'hidden',

                /* Edge doesn't obey the css visibility correctly, go figure. Here's a hack. */
                height: 0,
                top: 0,
                overflow: 'hidden',
            },
            actions : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
        };

        let wrapperStyles = 
            this.props.visible ?
                styles.wrapper :
                {...styles.wrapper, ...styles.invisible};

        return (
            <div style={wrapperStyles}>
                {
                    this.renderContent()
                }

                <div style={styles.actions}>
                    <CreateDisplayButton
                        disabled={this.state.buttonDisabled}
                        onClick={this.onShowDataBtnClick}
                        language={this.props.language}
                    />
                </div>
                
            </div>
        );
    }

}

export default HealthNutritionOptions;