import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSAbortionDrawer from "../../drawers/HealthStatus/CSAbortionDrawer";
import {PercPregEndAbortEditor} from "../../data/HealthStatus/Abortion/PercPregEndAbortEditor";
import {AbortionIncRatioEditor} from "../../data/HealthStatus/Abortion/AbortIncRatioEditor";
import {getModvarCloneByTag} from "utilities/GB/GBUtil";

const modvarTag3 = CONSTANTS.CS_TG_AbortCalcFamPlan_MV2;

class CSAbortionBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange     : PropTypes.func,
        CSModvars         : PropTypes.arrayOf(PropTypes.object),
        useFamPlanOutputs : PropTypes.bool,
        onOpenHelpDialog  : PropTypes.func,
    };

    static defaultProps = {
        onStateChange     : () => console.log('onStateChange'),
        CSModvars         : [],
        useFamPlanOutputs : false,
        onOpenHelpDialog  : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled : true,
        openDrawer      : false,
        packTable1      : null,
        packTable2      : null,
    };

    componentDidMount() {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        let useFamPlanOutputs = CSModvars.find(x => (x.tag === modvarTag3) ? true : false).value;
        this.setState({
            packTable1        : PercPregEndAbortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
            packTable2        : AbortionIncRatioEditor.Create(CSModvars, firstYear, finalYear, useFamPlanOutputs)[CONSTANTS.CS_PackTable],
            useFamPlanOutputs : useFamPlanOutputs,
        });
    }

    static getDerivedStateFromProps(props, state) {
        let CSModvars = props.CSModvars;
        let firstYear = props.firstYear;
        let finalYear = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        let useFamPlanOutputs = CSModvars.find(x => (x.tag === modvarTag3) ? true : false).value;
        if (!state.openDrawer) {
            return {
                packTable1 : PercPregEndAbortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
                packTable2 : AbortionIncRatioEditor.Create(CSModvars, firstYear, finalYear, useFamPlanOutputs)[CONSTANTS.CS_PackTable],
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
    };

    onCloseDrawerBtnClick = () => {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        let useFamPlanOutputs = CSModvars.find(x => (x.tag === modvarTag3) ? true : false).value;
        this.setState({
            openDrawer        : false,
            saveBtnDisabled   : true,
            packTable1        : PercPregEndAbortEditor.Create(CSModvars)[CONSTANTS.CS_PackTable],
            packTable2        : AbortionIncRatioEditor.Create(CSModvars, firstYear, finalYear, useFamPlanOutputs)[CONSTANTS.CS_PackTable],
            useFamPlanOutputs : useFamPlanOutputs,
        });
    };

    onSaveBtnClick = () => {
        let useFamPlanOutputs = this.state.useFamPlanOutputs;

        let ModvarsArray1 = PercPregEndAbortEditor.GetSSData(this.state.packTable1, this.props.CSModvars);
        let ModvarsArray2 = AbortionIncRatioEditor.GetSSData(this.state.packTable2, this.props.CSModvars, useFamPlanOutputs);
        let ModvarFamPlanOutputs = {...this.props.CSModvars.find(x => x.tag === modvarTag3), value: Boolean(useFamPlanOutputs)};

        let changedCSModvars = this.props.CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            let c = (x.tag === modvarTag3) ? ModvarFamPlanOutputs : undefined;
            return a || b || c || x;
        });

        this.setState({
            openDrawer: false,
            saveBtnDisabled: true,
        });

        this.props.onStateChange({
            unchangedProj: false,
            CSModvars : changedCSModvars,
        });
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    onUseFamPlanOutputsChange = (value) => {
        this.setState({
            useFamPlanOutputs : value,
            saveBtnDisabled   : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2
                caption     = {RS('GB_stAbortion')}
                description = ""
                onClick     = {this.onBlueBoxClick}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"abortion_list.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stAbortion')}
                        content         = {
                                            <CSAbortionDrawer
                                                packTable1={this.state.packTable1}
                                                packTable2={this.state.packTable2}
                                                useFamPlanOutputs={this.state.useFamPlanOutputs}
                                                onPackTable1Change={this.onPackTable1Change}
                                                onPackTable2Change={this.onPackTable2Change}
                                                onUseFamPlanOutputsChange={this.onUseFamPlanOutputsChange}
                                            />
                                          }
                    />
                }
            />

        );
    }

}

export default CSAbortionBlueBox;