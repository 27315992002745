import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: "24px 0", ...props.style }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class TTabs extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        tabNames          : PropTypes.arrayOf(PropTypes.any),
        tabContents       : PropTypes.arrayOf(PropTypes.any),
        centered          : PropTypes.bool,
        tabUnderlineColor : PropTypes.string,
        tabAppBarStyle    : PropTypes.object,
        tabStyle          : PropTypes.object,
        tabSelectedStyle  : PropTypes.object,
        tabRootStyle      : PropTypes.object,
        tabContentStyle   : PropTypes.object,
        selectedTab       : PropTypes.number,
        onTabChange       : PropTypes.func,
    };

    static defaultProps = {
        tabNames          : ['Item 1', 'Item 2', 'Item 3'],
        tabContents       : ['Content 1', 'Content 2', 'Content 3'],
        centered          : false,
        tabUnderlineColor : 'primary',
        tabAppBarStyle    : {},
        tabStyle          : {},
        tabSelectedStyle  : {},
        tabRootStyle      : {},
        tabContentStyle   : {},
        selectedTab       : 0,
        onTabChange       : () => console.log("TTabs onTabChange"),
        tabBarOutline     : undefined
    };

    state = {
        value: 0
    };

    componentDidMount() {
        this.setState({
            value : this.props.selectedTab
        });
    }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    handleChange = (event, value) => {
        this.setState({ value });
        this.props.onTabChange(value);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderTabs = (props) => {
        let arr = [];

        for (let i = 0; i < this.props.tabNames.length; i++) {
            arr.push(<Tab label={this.props.tabNames[i]} classes={{ root: props.classes.tabRoot, selected: props.classes.tabSelected }} key={i} />);
        }

        return arr;
    };

    renderContents = (value) => {
        return (
            <TabContainer style={this.props.tabContentStyle}>{this.props.tabContents[value]}</TabContainer>
        );
    };

    render() {
        const { value } = this.state;

        const innerStyles = {
            appBar: {
                // nothing by default
            },
        };

        if (typeof this.props.tabBarOutline !== 'undefined') {
            innerStyles.appBar.boxShadow = this.props.tabBarOutline;
        }

        const unstyledComponent2 = props => (
            <div className={props.classes.root}>
                <AppBar color="default" style={{...innerStyles.appBar, ...this.props.tabAppBarStyle}}>
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        classes={{root: props.classes.tabsRoot}}
                        centered={this.props.centered}
                        indicatorColor={this.props.tabUnderlineColor}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.renderTabs(props)}
                    </Tabs>
                </AppBar>

                <div style={this.props.tabContentStyle}>
                    {this.renderContents(value)}
                </div>
            </div>
        );

        // const unstyledComponent = props => (
        //     <div className={props.classes.root}>
        //         <AppBar position="static" color="default">
        //             <Tabs
        //                 value={value}
        //                 onChange={this.handleChange}
        //                 classes={{root: props.classes.tabsRoot}}
        //             >
        //                 <Tab label="Item One" classes={{ root: props.classes.tabRoot, selected: props.classes.tabSelected }} />
        //                 <Tab label="Item Two" classes={{ root: props.classes.tabRoot, selected: props.classes.tabSelected }} />
        //                 <Tab label="Item Three"classes={{ root: props.classes.tabRoot, selected: props.classes.tabSelected }} />
        //             </Tabs>
        //         </AppBar>
        //         {value === 0 && <TabContainer>Item One</TabContainer>}
        //         {value === 1 && <TabContainer>Item Two</TabContainer>}
        //         {value === 2 && <TabContainer>Item Three</TabContainer>}
        //     </div>
        // );

        const StyledComponent = withStyles({
            root : {
                flexGrow: 1,
                // backgroundColor: 'gray',
            },
            tabRoot: {
                textTransform: 'initial',
                ...this.props.tabRootStyle
            },
            tabsRoot: {
                // borderBottom: '1px solid #e8e8e8',
                backgroundColor: '#FFFFFF',
                ...this.props.tabStyle
            },
            tabsIndicator: {
                backgroundColor: '#f78c30',
            },
            tabSelected: {
                ...this.props.tabSelectedStyle
            }
        })(unstyledComponent2);

        return (
            <StyledComponent />
        );
    }
}

export default TTabs;