import React from "react";
import * as PropTypes from "prop-types";

const CheckboxCellRenderer = props => (
    <input
        type="checkbox"
        checked={props.value}
        onChange={event => {
            props.onChange(props.field, props.rowIndex, event.target.checked);
        }}
    />
);

CheckboxCellRenderer.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    field: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func
};

CheckboxCellRenderer.defaultProps = {
    value: false
};

export default CheckboxCellRenderer;
