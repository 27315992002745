import * as React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from './Theme';
import App from './App';
// import App from '../Tools/Extract/app/App';

const Root = () => (
    <MuiThemeProvider theme={muiTheme}>
        <App />
    </MuiThemeProvider>
);

export default Root;