import * as React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText  from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

class TListItem extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        icon               : PropTypes.any,
        caption            : PropTypes.string,
        subcaption         : PropTypes.string,
        disabled           : PropTypes.bool,
        primaryStyle       : PropTypes.object,
        hasCollapse        : PropTypes.bool,
        collapseItemsStyle : PropTypes.object,
        onClick            : PropTypes.func,
        style              : PropTypes.object
    };

    static defaultProps = {
        icon               : null,
        caption            : 'Caption',
        subcaption         : '',
        disabled           : false,
        primaryStyle       : {},
        hasCollapse        : false,
        collapseItemsStyle : {},
        onClick            : () => {},
        style              : {}
    };

    state = { 
        open : false 
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onClick = () => {
        this.props.onClick();
    };

    onCollapseClick = () => {
        this.setState({open : !this.state.open});
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        return (
            <div>
                <ListItem button
                          onClick={(this.props.hasCollapse) ? this.onCollapseClick : this.onClick}
                          style={{...this.props.style}}
                          disabled={this.props.disabled}
                >
                    {
                        (this.props.icon !== null) ?
                            <ListItemIcon style={{marginLeft: '-10px', marginRight: '0'}}>
                                {this.props.icon}
                            </ListItemIcon>
                            :
                            null
                    }

                    <ListItemText
                        disableTypography
                        primary={<Typography style={{...this.props.primaryStyle }}>{this.props.caption}</Typography>}
                        // primary={this.props.caption}
                        secondary={this.props.subcaption}
                        style={{...this.props.style}}
                    />

                    {
                        (this.props.hasCollapse) ?

                            (this.state.open) ?
                                <KeyboardArrowUp /> :
                                <KeyboardArrowDown />

                            : null
                    }

                </ListItem>

                <Collapse in={this.state.open} timeout="auto" unmountOnExit style={{...this.props.collapseItemsStyle}}>
                    {this.props.children}
                </Collapse>
            </div>
        );
    }

}

export default TListItem;