import React from "react";
import PropTypes from "prop-types";
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import SaveButton from "../buttons/SaveButton";

class SaveAsDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        onSaveProjection : PropTypes.func,
        onPageChange     : PropTypes.func
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        onSaveProjection : () => console.log('onSaveProjection'),
        onPageChange     : () => console.log('onPageChange')
    };

    state = {
        projNameSaveAs   : '',
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onEditChange = (value) => {
        this.setState({
            projNameSaveAs : value
        });
    };

    onClose = () => {
        this.props.onStateChange({
            dialogSaveAsOpen : false
        });
    };

    onSaveProjectionClick = () => {
        this.props.onStateChange({
            //dialogSaveAsOpen : false,
            projNameSaveAs : this.state.projNameSaveAs,
            //projectionName : this.state.projNameSaveAs
        }, () => {
            this.props.onSaveProjection(this.onClose);
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            edit: {
                width: '100%',
                margin: '30px 0'
            },
        };

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stSaveProjAs')}
                onClose={this.onClose}
                content={
                    <div>
                        <TEdit
                            name="ProjectionNameField"
                            caption={RS('GB_stNewProjName')}
                            value={this.state.projNameSaveAs}
                            onChange={this.onEditChange}
                            style={styles.edit}
                            shrinkLabel={true}
                        />
                    </div>
                }
                actions={
                    <div>
                        <SaveButton onClick={this.onSaveProjectionClick} disabled={this.state.projNameSaveAs === ""} />
                    </div>
                }
            />
        );
    }
}

export default SaveAsDialog;