import React from 'react';
import PropTypes from 'prop-types';
import * as colors from "@material-ui/core/colors";
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import TButton from "@common/components/TButton";
import TEdit from "@common/components/TEdit";
import TLabel from "@common/components/TLabel";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import CreateAPITask from "../../api/util/createAPITask";
import { LocalStorageUtil } from '../../api/localstorage/util';
import CSDialog from "./CSDialog";

class LogInDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange       : PropTypes.func,
        onGetTopicList      : PropTypes.func,
        onAddTasks          : PropTypes.func,
        onPageChange        : PropTypes.func,
        projectCreated      : PropTypes.bool,
        pageID              : PropTypes.string,
        storedProjections   : PropTypes.array
    };

    static defaultProps = {
        onStateChange       : () => console.log('onStateChange'),
        onGetTopicList      : () => console.log('onGetTopicList'),
        onAddTasks          : () => console.log('onAddTasks'),
        onPageChange        : () => console.log('onPageChange'),
        projectCreated      : false,
        pageID              : "",
        storedProjections   : []
    };

    state = {
        userNameValue       : LocalStorageUtil.getRememberMe() ? LocalStorageUtil.getUser() : "",
        passwordValue       : '',
        rememberMe          : LocalStorageUtil.getRememberMe(),
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onLoginBtnClick = () => {
        if ((this.state.userNameValue !== '') && (this.state.passwordValue !== '')) {

            this.props.onAddTasks([
                CreateAPITask('login',
                    {
                        username: this.state.userNameValue,
                        password: this.state.passwordValue
                    },
                    (response) => {
                        if (response === true) {
                            this.props.onStateChange({
                                dialogLogInOpen : false
                            });
                            
                            if (this.state.rememberMe) {
                                LocalStorageUtil.setUser(this.state.userNameValue);
                            } 
                            else {
                                LocalStorageUtil.deleteUser();
                            }
                            
                            LocalStorageUtil.setRememberMe(this.state.rememberMe);

                            this.props.onAddTasks([
                                CreateAPITask('fetchUserInfo', {}, () => {
                                    this.props.onGetTopicList();
                                }, (msg) => {
                                    this.props.onStateChange({
                                        dialogErrorOpen : true,
                                        errorMessage : 'Error fetching user information',
                                    });
                                })
                            ]);
                        }
                    },
                    (msg) => {
                        this.props.onStateChange({
                            dialogErrorOpen : true,
                            errorMessage : RS('GB_stWrongEmailPassword')
                        });
                    }
                )
            ]);
        }
        else if ((this.state.userNameValue === '') && (this.state.passwordValue === ''))  {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage : RS('GB_stEnterEmailPassword')
            });
        }
        else if (this.state.userNameValue === '') {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage : RS('GB_stEnterEmail')
            });
        }
        else if (this.state.passwordValue === '') {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage : RS('GB_stEnterPassword')
            });
        }
    };

    onForgotPasswordClick = () => {
        window.open("https://authuser.avenirhealth.org/?sv=LOL&md=fg", "_blank");
        // this.props.onStateChange({
        //     dialogForgotPasswordOpen: true
        // });
    };

    onCreateAccountBtnClick = () => {
        window.open("https://authuser.avenirhealth.org/?sv=LOL&md=new", "_blank");
        // this.props.onStateChange({
        //     dialogAccountSetupOpen: true
        // });
    };

    onClose = () => {
        this.props.onStateChange({
            dialogLogInOpen : false
        });
    };

    onUserNameChange = (value) => {
        this.setState({
            userNameValue     : value,
        });
    };

    onPasswordChange = (value) => {
        this.setState({
            passwordValue     : value,
        });
    };


    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderCloseIcon = () => {

        let styles = {
            closeIcon : {
                float: 'right', 
                height: '30px', 
                width: '30px', 
                cursor: 'pointer', 
                marginTop: '-20px'
            }
        };
        
        return (
            <CloseIcon 
                onClick={this.onClose} 
                style={styles.closeIcon}
            />
        );
    };

    renderLogInContent = () => {

        let styles = {
            paperBox : {
                width: 350,
                margin: '0 auto',
                marginBottom: '10px'
            },
            loginBox : {
                width: 300,
                margin: '0 auto',
                marginTop: '15px'
            }
        };

        return (
            <Paper style={styles.paperBox}>
                <div style={styles.loginBox}>
                    {this.renderUsernameEdit()}
                    {this.renderPasswordEdit()}
                    {this.renderRememberMe()}
                    {this.renderForgotPasswordLabel()}
                    {this.renderLoginBtn()}
                    <Divider />
                    {this.renderCreateAccountBtn()}
                </div>
            </Paper>
        );
    };

    renderLogInLabel = () => {

        let styles = {
            logIn : {
                paddingTop: 25,
                fontWeight: 'normal'
            }
        };

        return (
            <h1 style={styles.logIn}>{RS('GB_stLogIn')}</h1>
        );
    };

    renderUsernameEdit = () => {

        let styles = {
            edit : {
                marginTop: '10px', 
                width: '100%'
            }
        };

        return (
            <TEdit
                name="UsernameEdit"
                caption={RS('GB_stEmail')}
                value={this.state.userNameValue}
                onChange={this.onUserNameChange}
                style={styles.edit}
                type={"email"}
            />
        );
    };

    renderPasswordEdit = () => {

        let styles = {
            edit : {
                width: '100%'
            }
        };

        return (
            <TEdit
                name="PasswordEdit"
                caption={RS('GB_stPassword')}
                value={this.state.passwordValue}
                onChange={this.onPasswordChange}
                type="Password"
                style={styles.edit}
            />
        );
    };

    renderRememberMe = () => {
        return (
            <TCheckBox
                caption={RS("GB_stRememberMe")}
                value={this.state.rememberMe}
                boxStyle={{paddingRight:3}}
                onClick={() => this.setState({rememberMe: !this.state.rememberMe})}
            />
        )
    };

    renderForgotPasswordLabel = () => {

        let styles = {
            forgotPasswordStyle : {
                fontSize: 12,
                textAlign: 'right',
                paddingRight: 0,
                color: colors.grey[500],
                float: 'right',
                //marginTop: -10,
                marginBottom: 10,
                textTransform : 'uppercase',
                cursor: 'pointer'
            }
        };

        return (
            <TLabel
                caption={RS('GB_stForgotPassword')}
                onClick={this.onForgotPasswordClick}
                style={styles.forgotPasswordStyle}
            />
        );
    };

    renderLoginBtn = () => {

        let styles = {
            raisedButton : {
                marginTop: 20,
                marginBottom: 30,
                marginRight: 20,
                width: '100%',
                fontSize: '16px'
            },
            containerStyle : {
                display: 'block'
            }
        };

        return (
            <TButton
                caption={RS('GB_stLogIn')}
                color="primary"
                onClick={this.onLoginBtnClick}
                style={styles.raisedButton}
                containerStyle={styles.containerStyle}
            />
        );
    };

    renderCreateAccountBtn = () => {

        let styles = {
            raisedButtonCreate : {
                marginTop: 30,
                marginBottom: 30,
                marginRight: 20,
                width: '100%',
                fontSize: '16px'
            },
            containerStyle : {
                display: 'block'
            }
        };

        return (
            <TButton
                caption={this.props.isGuest ? RS('GB_stCreateAccount') : RS('GB_stManageMyAccount')}
                color="secondary"
                onClick={this.onCreateAccountBtnClick}
                style={styles.raisedButtonCreate}
                containerStyle={styles.containerStyle}
            />
        );
    };

    render() {
        return (
            <CSDialog
                title={RS('GB_stLogInAccount')}
                content={this.renderLogInContent()}
                onClose={this.onClose}
            />
        );
    }
}

export default LogInDialog;