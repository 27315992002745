import * as React from 'react';
import PropTypes from 'prop-types';
import CreateAPITask from "../util/createAPITask";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import CONSTANTS from "utilities/CS/CSConst";
// import axios from 'axios';

class TestContainers extends React.Component {

    static propTypes = {
        CSModvars       : PropTypes.any,
        CSEditors       : PropTypes.any,
        onAddTasks      : PropTypes.any,
        apiTasks        : PropTypes.any,
    };

    static defaultProps = {
        CSModvars       : [],
        CSEditors       : [],
        onAddTasks      : () => console.log('TestContainers onAddTasks'),
        apiTasks        : [],
    };

    state = {
        open : true
    };
    
    componentDidMount() {
        // Make the DIV element draggable:
        dragElement(document.getElementById("TestContainersDebug"));

        function dragElement(elmnt) {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            if (document.getElementById(elmnt.id + "Header")) {
                // if present, the header is where you move the DIV from:
                document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
            } else {
                // otherwise, move the DIV from anywhere inside the DIV:
                elmnt.onmousedown = dragMouseDown;
            }

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            }

            function closeDragElement() {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }
    }

    //-------------------------------------------------------------------------------

    CreateProjectionFlagClick = () => {
        this.props.onAddTasks([
            CreateAPITask('createProjectionFlag', 
                {
                    CountryISO: 4,
                    FirstYear: 2019,
                    FinalYear: 2030,
                    FileName: "TestProjection",
                    ModList: [1, 2, 3, 4, 15],
                    Notes: "MyNotes",
                    Author: "MyAuthor",
                    Organization: "MyOrganization",
                    SubnatStep1: false,
                    SubnatStep2: false,
                    Region: "MyRegion",
                    Survey: "MySurvey",
                    arr : [],
                    AgeGroupOption : CONSTANTS.FP_Single_Age_Group,
                },
                () => {
                    alert('createProjection done');
                },
                (msg) => {
                    alert('createProjection failed: ' + msg);
                }
            )
        ]);
    };

    SetProblemModvarClick = () => {

        let arr = this.props.CSModvars;

        arr = arr.filter(x => ( 
            (x.tag === '<Child deaths prevented by intervention by cause MV3>')
        ));

        this.props.onAddTasks([
            CreateAPITask('setModvars', {arr: arr},
                () => {
                    alert('setModvars done');
                },
                (msg) => {
                    alert('setModvars failed: ' + msg);
                }
            )
        ]);
    };

    SetModvarsClick = () => {

        let arr = this.props.CSModvars;

        // let halfwayThrough = Math.floor(arr.length / 2)
        // let arrayFirstHalf = arr.slice(0, halfwayThrough);

        // let myarr = arr.slice(68, 70);  THE TWO BAD ONES
        // let myarr = arr.slice(66, 68);  // TWO GOOD ONES

        // for (var i = 0; i < arr.length; i++){ 
        //     if ( 
        //         (arr[i].tag === '<Efficacy MV2>') ||
        //         (arr[i].tag === '<DetVaccEff MV2>') ||
        //         (arr[i].tag === '<Child deaths prevented by intervention by cause MV3>') ||
        //         (arr[i].tag === '<Maternal deaths prevented by intervention by cause MV3>')
        //     ) {
        //         arr.splice(i, 1); 
        //         i--;
        //     }
        // }

        this.props.onAddTasks([
            CreateAPITask('setModvars', {arr: arr},
                () => {
                    alert('setModvars done');
                },
                (msg) => {
                    alert('setModvars failed: ' + msg);
                }
            )
        ]);
    };

    GetStatusClick = () => {
        this.props.onAddTasks([
            CreateAPITask('getStatus', {},
                () => {
                    alert('getStatus done');
                },
                (msg) => {
                    alert('getStatus failed: ' + msg);
                }
            )
        ]);
    };

    //-------------------------------------------------------------------------------

    SetModvarsEditorsTogetherClick = () => {

        let arr1 = this.props.CSModvars;
        // let arr2 = this.props.CSEditors;

        this.props.onAddTasks([
            CreateAPITask('setModvars', {arr: arr1},
                () => {
                    alert('setModvars done');
                },
                (msg) => {
                    alert('setModvars failed: ' + msg);
                }
            ),
            // CreateAPITask('setEditors', {arr: arr2},
            //     () => {
            //         alert('setEditors done');
            //     },
            //     (msg) => {
            //         alert('setEditors failed: ' + msg);
            //     }
            // ),
        ]);
    };

    SetModvarsEditorsSeperatelyClick = () => {

        let arr1 = this.props.CSModvars;
        // let arr2 = this.props.CSEditors;

        this.props.onAddTasks([
            CreateAPITask('setModvars', {arr: arr1},
                () => {
                        alert('setModvars done');
                    // this.props.onAddTasks([
                    //     CreateAPITask('setEditors', {arr: arr2},
                    //         () => {
                    //             alert('setModvars and setEditors done');
                    //         },
                    //         (msg) => {
                    //             alert('setModvars and setEditors ' + msg);
                    //         }
                    //     )
                    // ]);
                },
                (msg) => {
                    alert('setModvars failed: ' + msg);
                }
            ),
        ]);
    };

    SendProjectionInfoClick = () => {
        this.props.onAddTasks([
            CreateAPITask('sendProjectionInfo', {},
                () => {
                    alert('sendProjectionInfo done');
                },
                (msg) => {
                    alert('sendProjectionInfo failed: ' + msg);
                }
            )
        ]);
    };

    //-------------------------------------------------------------------------------

    SetModvarsWindowAppStateClick = () => {

        let arr = window.appState.CSModvars;

        this.props.onAddTasks([
            CreateAPITask('setModvars', {arr: arr},
                () => {
                    alert('setModvars done');
                },
                (msg) => {
                    alert('setModvars failed: ' + msg);
                }
            )
        ]);
    };

    //-------------------------------------------------------------------------------

    SetModvarsMULTISameTimeClick = () => {

        let arr = this.props.CSModvars;
        let tasks = [];

        for (let i = 0; i < arr.length; i++) {

            let MV = arr[i];

            tasks.push(               
                CreateAPITask('setModvars', {arr: [MV]},
                    () => {
                        // console.log(MV.tag + ' done');
                    },
                    (msg) => {
                        console.error(MV.tag + ' failed', msg);
                    }
                )
            );
        }

        this.props.onAddTasks(tasks);
    };

    //-------------------------------------------------------------------------------

    AXIOS_ping = async () => {

        let startTime = Date.now();

        // const response = await axios.post(
        //     window.App.API_URL,
        //     {
        //         id: window.App.CLIENT_ID,
        //         method: "GeneralService.Ping",
        //         params: { jsonMesg: "" }
        //     },
        // );

        return Date.now() - startTime;

        //return response.data.result;
    };

    AXIOS_setModvars = async (json) => {

        let startTime = Date.now();
        
        // const response = await axios.post(
        //     window.App.API_URL,
        //     {
        //         id: window.App.CLIENT_ID,
        //         method: "GBSpectrumService.SetModvars",
        //         params: { jsonMesg: JSON.stringify(json) }
        //     },
        // );

        return Date.now() - startTime;

        //return response.data.result;
    };

    SetModvarsAndPingX10 = async (modvars) => {

        console.log('Calling setModvars with a single modvar, then REST ping.  This is done 10x for each modvar.  Stats are below.');

        for (let i = 0; i < modvars.length; i++) {

            if (i >= modvars.length) {
                // STOP
            }
            else {
                let MV = modvars[i];

                let setModvars = this.AXIOS_setModvars;
                let ping = this.AXIOS_ping;

                // Have a different call after setting a modvar so we get an accurate timing.
                // Remember, one long bad modvar should make whatever call comes afterwards super slow.
                const setModvarsMsg1 = await setModvars({ modvars : [MV] }); const pingMsg1 = await ping();
                const setModvarsMsg2 = await setModvars({ modvars : [MV] }); const pingMsg2 = await ping();
                const setModvarsMsg3 = await setModvars({ modvars : [MV] }); const pingMsg3 = await ping();
                const setModvarsMsg4 = await setModvars({ modvars : [MV] }); const pingMsg4 = await ping();
                const setModvarsMsg5 = await setModvars({ modvars : [MV] }); const pingMsg5 = await ping();
                const setModvarsMsg6 = await setModvars({ modvars : [MV] }); const pingMsg6 = await ping();
                const setModvarsMsg7 = await setModvars({ modvars : [MV] }); const pingMsg7 = await ping();
                const setModvarsMsg8 = await setModvars({ modvars : [MV] }); const pingMsg8 = await ping();
                const setModvarsMsg9 = await setModvars({ modvars : [MV] }); const pingMsg9 = await ping();
                const setModvarsMsg10 = await setModvars({ modvars : [MV] }); const pingMsg10 = await ping();

                let pingMin = Math.min(pingMsg1, pingMsg2, pingMsg3, pingMsg4, pingMsg5, pingMsg6, pingMsg7, pingMsg8, pingMsg9, pingMsg10);
                let pingMax = Math.max(pingMsg1, pingMsg2, pingMsg3, pingMsg4, pingMsg5, pingMsg6, pingMsg7, pingMsg8, pingMsg9, pingMsg10);
                let arrPing = [pingMsg1, pingMsg2, pingMsg3, pingMsg4, pingMsg5, pingMsg6, pingMsg7, pingMsg8, pingMsg9, pingMsg10];
                let pingSum = arrPing.reduce(function(a, b) { return a + b; });
                let pingAvg = Math.floor(pingSum / arrPing.length);

                let min = Math.min(setModvarsMsg1, setModvarsMsg2, setModvarsMsg3, setModvarsMsg4, setModvarsMsg5, setModvarsMsg6, setModvarsMsg7, setModvarsMsg8, setModvarsMsg9, setModvarsMsg10);
                let max = Math.max(setModvarsMsg1, setModvarsMsg2, setModvarsMsg3, setModvarsMsg4, setModvarsMsg5, setModvarsMsg6, setModvarsMsg7, setModvarsMsg8, setModvarsMsg9, setModvarsMsg10);
                let arrMV = [setModvarsMsg1, setModvarsMsg2, setModvarsMsg3, setModvarsMsg4, setModvarsMsg5, setModvarsMsg6, setModvarsMsg7, setModvarsMsg8, setModvarsMsg9, setModvarsMsg10];
                let sum = arrMV.reduce(function(a, b) { return a + b; });
                let avg = Math.floor(sum / arrMV.length);

                console.log(
                    'Modvar #' + i + ', ' + 
                    'Ping - ' + 
                    'min: ' + pingMin + ', ' + 
                    'max: ' + pingMax + ', ' + 
                    'avg: ' + pingAvg + ', ' + 
                    'SetModvar - ' +
                    'min: ' + min + ', ' + 
                    'max: ' + max + ', ' + 
                    'avg: ' + avg + ', ' + 
                    'Tag: ' + MV.tag
                );
            }

        }
    };

    SetModvarsMULTIWaitGoodOnesClick = () => {

        let arr = this.props.CSModvars;

        arr = arr.filter(x => !( 
            (x.tag === '<Efficacy MV2>') ||
            (x.tag === '<DetVaccEff MV2>') ||
            (x.tag === '<Child deaths prevented by intervention by cause MV3>') ||
            (x.tag === '<Maternal deaths prevented by intervention by cause MV3>') ||
            (x.tag === '<ChDeathsPrevdxInt MV3>') ||
            (x.tag === '<DAvtdByVaccByCohortByCause MV3>')
        ));

        this.SetModvarsAndPingX10(arr);
    };

    SetModvarsMULTIWaitSlowOnesClick = () => {

        let arr = this.props.CSModvars;

        arr = arr.filter(x => ( 
            (x.tag === '<Efficacy MV2>') ||
            (x.tag === '<DetVaccEff MV2>') ||
            (x.tag === '<Maternal deaths prevented by intervention by cause MV3>') ||
            (x.tag === '<ChDeathsPrevdxInt MV3>') ||
            (x.tag === '<DAvtdByVaccByCohortByCause MV3>')
        ));

        this.SetModvarsAndPingX10(arr);
    };

    SetModvarsMULTIWaitBadOnesClick = () => {

        let arr = this.props.CSModvars;

        arr = arr.filter(x => ( 
            (x.tag === '<Child deaths prevented by intervention by cause MV3>')
        ));

        this.SetModvarsAndPingX10(arr);
    };

    //-------------------------------------------------------------------------------

    SetModvarsInputs = () => {
        let arr = this.props.CSModvars;

        let arr2 = arr.filter(x => typeof x.FlagSet === 'undefined');
        let arr3 = arr.filter(x => typeof x.FlagSet !== 'undefined');

        let arr4 = arr3.filter(x => x.FlagSet.includes(8));
        console.log(arr);
        console.log(arr2);
        console.log(arr3);
        console.log(arr4);

        this.props.onAddTasks([
            CreateAPITask('setModvars', {arr: arr4},
                () => {
                    alert('setModvars done');
                },
                (msg) => {
                    alert('setModvars failed: ' + msg);
                }
            )
        ]);
    };

    //-------------------------------------------------------------------------------

    onClose = () => {
        this.setState({
            open : false
        });
    };

    //-------------------------------------------------------------------------------

    render() {

        if (!this.state.open) {
            return null;
        }

        let apiTasksActive = this.props.apiTasks.filter(x => x.running).length > 0;

        const styles = {
            TestContainersDebug : {
                // position: 'absolute',
                border: '1px solid #d3d3d3',
                textAlign: 'center',

                zIndex: 2000, 
                width: 470, 
                position: 'relative', 
                backgroundColor: '#d0e7ff',
                borderRadius: 10,
            },
              
            TestContainersDebugHeader : {
                padding: '10px',
                cursor: 'move',
                zIndex: 10,
                backgroundColor: '#2196F3',
                color: '#fff',
                borderRadius: '10px 10px 0 0',
            },

            pattern08 : {
                backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkAQMAAABKLAcXAAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMAGovxNEIAAAAoSURBVDhPYxBEAgIMSkhAgcEFCTgwdCCBBoZRfaP6RvWN6hvVR5Y+APADQlQnmrINAAAAAElFTkSuQmCC)'
            }
        };
        return (
            <div id="TestContainersDebug" style={{...styles.TestContainersDebug, ...styles.pattern08}}>

                <div id="TestContainersDebugHeader" style={styles.TestContainersDebugHeader}>Click here to move</div>

                <CloseIcon style={{position: 'absolute', top: 8, right: 8, cursor: 'pointer'}} onClick={this.onClose} />
                
                <div style={{position: 'relative', height: 550, overflow: 'auto'}}>

                    {
                        (apiTasksActive) ?
                            <div>
                                <CircularProgress size={100} style={{position: 'absolute', right: 30, top: 10}} /> 
                                <div style={{position: 'absolute', right: 37, top: 125, fontSize: 12}}>API tasks running</div>
                            </div> :
                            null
                    }

                    <button style={{position: 'absolute', left: 10, top: 10}} onClick={this.CreateProjectionFlagClick}>Step 1: Create Projection (Combo)</button>
                    <button style={{position: 'absolute', left: 10, top: 30}} onClick={this.SetProblemModvarClick}>Step 2: SetModvars (only problem modvar)</button>
                    <button style={{position: 'absolute', left: 10, top: 50}} onClick={this.GetStatusClick}>Step 3: GetStatus</button>


                    <button style={{position: 'absolute', left: 10, top: 110}} onClick={this.CreateProjectionFlagClick}>Create Projection (Combo)</button>
                    <button style={{position: 'absolute', left: 10, top: 130}} onClick={this.SetModvarsClick}>SetModvars</button>
                    <button style={{position: 'absolute', left: 10, top: 150}} onClick={this.GetStatusClick}>GetStatus</button>
                    {/*<button style={{position: 'absolute', left: 10, top: 170}} onClick={this.SetEditorsClick}>SetEditors</button>*/}
                    <button style={{position: 'absolute', left: 10, top: 190}} onClick={this.SetProblemModvarClick}>SetModvars (only problem modvar)</button>

                    <button style={{position: 'absolute', left: 10, top: 220}} onClick={this.SetModvarsEditorsTogetherClick}>Set modvars and editors together</button>
                    <button style={{position: 'absolute', left: 10, top: 240}} onClick={this.SetModvarsEditorsSeperatelyClick}>Set modvars and editors one after another</button>
                    <button style={{position: 'absolute', left: 10, top: 260}} onClick={this.SendProjectionInfoClick}>Send projection info</button>

                    <button style={{position: 'absolute', left: 10, top: 290}} onClick={this.SetModvarsWindowAppStateClick}>Set Modvars using window.appState</button>
                    {/*<button style={{position: 'absolute', left: 10, top: 310}} onClick={this.SetEditorsWindowAppStateClick}>Set Editors using window.appState</button>*/}

                    <button style={{position: 'absolute', left: 10, top: 340}} onClick={this.SetModvarsMULTISameTimeClick}>Set modvars individually at the same time</button>

                    <button style={{position: 'absolute', left: 10, top: 370}} onClick={this.SetModvarsMULTIWaitGoodOnesClick}>Set modvars individually x10, wait until each is done (quick ones)</button>
                    <button style={{position: 'absolute', left: 10, top: 390}} onClick={this.SetModvarsMULTIWaitSlowOnesClick}>Set modvars individually x10, wait until each is done (slower ones)</button>
                    <button style={{position: 'absolute', left: 10, top: 410}} onClick={this.SetModvarsMULTIWaitBadOnesClick}>Set modvars individually x10, wait until each is done (bad ones)</button>

                    <button style={{position: 'absolute', left: 10, top: 440}} onClick={this.SetModvarsInputs}>Set input modvars only</button>
                </div>
            </div>
        );
    }
 
}

export default TestContainers;