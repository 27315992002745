import React from "react";
import PropTypes from "prop-types";
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from '@material-ui/core/styles';
import RS from "@common/strings/RS";
import { ConvertToJSON } from "utilities/GB/GBUtil";
import CSDialog from "./CSDialog";
import CreateAPITask from "../../api/util/createAPITask";
import LiSTIcon from "../../img/LiSTIcon.png";
import avenirLogo from "../../img/avenirLogo.png";
import ServerStatusButton from "../buttons/ServerStatusButton";

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  

class AboutDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange   : PropTypes.func,
        onAddTasks      : PropTypes.func,
        version         : PropTypes.object,
    };

    static defaultProps = {
        onStateChange   : () => console.log('onStateChange'),
        onAddTasks      : () => console.log('onAddTasks'),
        version         : {},
    };
    
    state = {
        keyPresses       : [],

        gettingStatus    : false,
        getStatusPassed  : false,
        getStatusFailed  : false,
        getStatusResult  : "",

        gettingStatus2   : false,
        getStatusPassed2 : false,
        getStatusFailed2 : false,
        getStatusResult2 : "",

        writeFileShareLogLoading : false,
        writeFileShareLogPassed : false,
        writeFileShareLogFailed : false,
        writeFileShareLogResult : "",
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogAboutOpen : false
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderAboutContent = () => {
        return (
            <div>
                {this.renderAboutLiST()}
                <Divider /> <br/>
                {this.renderAboutAvenirHealth()}
                {this.renderFlagIconCredit()}
                {this.renderPrivacyPolicy()}

                {/*<TButton*/}
                {/*    caption={RS('GB_stServerStatus')}*/}
                {/*    onClick={() => this.props.onStateChange({dialogServerAboutOpen : true})}*/}
                {/*/>*/}
            </div>
        )
    };

    renderAboutLiST = () => {
        return (
            <div>
                <a href={"http://www.livessavedtool.org/"} target={"_blank"}>
                    <img style={{width: '250px'}} src={LiSTIcon} alt=''/>
                </a>
                <p>
                    {RS('GB_stAboutLiST_1')}
                </p>
                <p>
                    {RS('GB_stAboutLiST_10')}
                </p>
                <p>
                    {RS('GB_stAboutLiST_2')}
                    &nbsp;
                    <a href={"https://www.jhsph.edu/"} target ={"_blank"}>{RS('GB_stAboutLiST_3')} </a>
                    {RS('GB_stAboutLiST_4')}&nbsp;
                    <a href={"https://www.international.gc.ca/global-affairs-affaires-mondiales/home-accueil.aspx?lang=eng"} target ={"_blank"}>{RS('GB_stAboutLiST_5')} </a>
                    {RS('GB_stAboutLiST_6')}&nbsp;
                    <a href={"https://www.international.gc.ca/gac-amc"} target ={"_blank"}>{RS('GB_stAboutLiST_7')} </a>
                    {RS('GB_stAboutLiST_8')}&nbsp;
                    <a href={"https://www.gatesfoundation.org/"} target ={"_blank"}>{RS('GB_stAboutLiST_9')} </a>.
                </p>
                <p>
                    {RS('GB_stAboutLiST_11')} <a href={"http://www.livessavedtool.org/"} target={"_blank"}>{RS('GB_stAboutLiST_12')}</a>.
                </p>
                <p>
                    {RS('GB_stAboutLiST_13')}&nbsp; <a href={"http://support.avenirhealth.org"} target={"_blank"}>http://support.avenirhealth.org</a>.
                </p>
            </div>
        )
    };

    renderAboutAvenirHealth = () => {

        // const styles = {
        //     version: {
        //         fontWeight: 'normal',
        //         display: 'inline-block',
        //         marginBottom: 0,
        //         marginTop :0,
        //         color: '#65A7EB',
        //         fontStyle: 'italic',
        //         float: 'right'
        //     },
        // };
        //
        // const onKeyDown = (e) => {
        //
        //     let keys = JSON.parse(JSON.stringify(this.state.keyPresses));
        //     keys.push(e.keyCode);
        //
        //     this.setState({
        //         keyPresses : keys
        //     }, () => {
        //         let len = this.state.keyPresses.length;
        //
        //         if (
        //             (this.state.keyPresses[len-1] === 71) &&
        //             (this.state.keyPresses[len-2] === 85) &&
        //             (this.state.keyPresses[len-3] === 66) &&
        //             (this.state.keyPresses[len-4] === 69) &&
        //             (this.state.keyPresses[len-5] === 68)
        //         ) {
        //             this.props.onStateChange({
        //                 developerMode : true
        //             }, () => {
        //                 alert("DeveloperMode is now active!");
        //             });
        //         }
        //     });
        // };
        //
        // const onVersionClick = () => {
        //
        //     this.setState({
        //         gettingStatus : true,
        //         getStatusPassed : false,
        //         getStatusFailed : false,
        //         getStatusResult : "",
        //
        //         gettingStatus2 : true,
        //         getStatusPassed2 : false,
        //         getStatusFailed2 : false,
        //         getStatusResult2 : "",
        //     });
        //
        //     this.props.onAddTasks([
        //         CreateAPITask('getStatus', {}, (res) => {
        //             this.setState({
        //                 gettingStatus : false,
        //                 getStatusPassed : true,
        //                 getStatusFailed : false,
        //                 getStatusResult : res,
        //             });
        //         }, (msg) => {
        //             this.setState({
        //                 gettingStatus : false,
        //                 getStatusPassed : false,
        //                 getStatusFailed : true,
        //                 getStatusResult : msg,
        //             });
        //             // alert('Error: ' + msg);
        //         })
        //     ]);
        //
        //     this.props.onAddTasks([
        //         CreateAPITask('getFileShareStatus', {}, (res) => {
        //             this.setState({
        //                 gettingStatus2 : false,
        //                 getStatusPassed2 : true,
        //                 getStatusFailed2 : false,
        //                 getStatusResult2 : res,
        //             });
        //         }, (msg) => {
        //             this.setState({
        //                 gettingStatus2 : false,
        //                 getStatusPassed2 : false,
        //                 getStatusFailed2 : true,
        //                 getStatusResult2 : msg,
        //             });
        //             // alert('Error: ' + msg);
        //         })
        //     ]);
        // };

        const onWriteFileShareLog = () => {

            this.setState({
                writeFileShareLogLoading : true,
                writeFileShareLogPassed : false,
                writeFileShareLogFailed : false,
                writeFileShareLogResult : false,
            });

            this.props.onAddTasks([
                CreateAPITask('writeFileShareLog', { text: 'Some text' }, (res) => {
                    this.setState({
                        writeFileShareLogLoading : false,
                        writeFileShareLogPassed : true,
                        writeFileShareLogFailed : false,
                        writeFileShareLogResult : res,
                    });
                }, (msg) => {
                    this.setState({
                        writeFileShareLogLoading : false,
                        writeFileShareLogPassed : false,
                        writeFileShareLogFailed : true,
                        writeFileShareLogResult : msg,
                    });
                    // alert('Error: ' + msg);
                })
            ]);
        };

        // let version = this.props.version.ServerVersionNum;
        // let beta = this.props.version.ServerBetaNum;
        // let fullVersion;
        //
        // if (beta === 0) {
        //     fullVersion = version;
        // }
        // else {
        //     fullVersion = version + ' beta ' + beta;
        // }

        return (
            <div>
                <a href={"http://www.avenirhealth.org/"} target={"_blank"}>
                    <img style={{width: '125px'}} src={avenirLogo} alt=''/>
                </a>
                <p>
                    {RS('GB_stAboutAvenir_1')}
                    &nbsp; <a href={"http://www.avenirhealth.org/"} target={"_blank"}>{RS('GB_stAboutAvenir_2')}</a> &nbsp;
                    {RS('GB_stAboutAvenir_3')}
                </p>

                <ServerStatusButton
                    onStateChange={this.props.onStateChange}
                />

                {/*<h4 */}
                {/*    onClick={onVersionClick} */}
                {/*    onKeyDown={onKeyDown} */}
                {/*    tabIndex="1" */}
                {/*    style={styles.version}*/}
                {/*> */}
                {/*    {RS('GB_stAboutAvenir_4')} &nbsp;{fullVersion}*/}
                {/*</h4>*/}

                <div style={{float: 'right'}}>
                    {this.state.gettingStatus ? <CircularProgress size={22} /> : null}
                    {this.state.getStatusPassed ? <HtmlTooltip title={`getStatus ${ConvertToJSON(this.state.getStatusResult).replace(/(?:\\[rn]|[\r\n]+)+/g, "")}`}><CheckCircle /></HtmlTooltip> : null}
                    {this.state.getStatusFailed ? <HtmlTooltip title={`getStatus ${ConvertToJSON(this.state.getStatusResult).replace(/(?:\\[rn]|[\r\n]+)+/g, "")}`}><Error /></HtmlTooltip> : null}
                </div>

                <div style={{float: 'right'}}>
                    {this.state.gettingStatus2 ? <CircularProgress size={22} /> : null}
                    {this.state.getStatusPassed2 ? <HtmlTooltip title={`getFileShareStatus ${this.state.getStatusResult2}`}><CheckCircle onClick={onWriteFileShareLog}/></HtmlTooltip> : null}
                    {this.state.getStatusFailed2 ? <HtmlTooltip title={`getFileShareStatus ${this.state.getStatusResult2}`}><Error onClick={onWriteFileShareLog}/></HtmlTooltip> : null}
                </div>

                <div style={{float: 'right'}}>
                    {this.state.writeFileShareLogLoading ? <CircularProgress size={22} /> : null}
                    {this.state.writeFileShareLogPassed ? <HtmlTooltip title={`writeFileShareLog ${this.state.writeFileShareLogResult}`}><CheckCircle /></HtmlTooltip> : null}
                    {this.state.writeFileShareLogFailed ? <HtmlTooltip title={`writeFileShareLog ${this.state.writeFileShareLogResult}`}><Error /></HtmlTooltip> : null}
                </div>
                
                <div style={{clear: 'both'}}></div>
            </div>
        );
    };

    renderFlagIconCredit = () => {
        const styles = {
            section : {
                fontSize: '10px',
                // marginTop: '20px',
                // marginBottom: "-20px"
            }
        };

        return (
            <p style={styles.section}>
                {RS('GB_stFlagCredit_1')}&nbsp;<a href={"https://www.freepik.com/"} target={"_blank"}>{RS('GB_stFlagCredit_2')}</a> &nbsp;from&nbsp;
                <a href={"https://www.flaticon.com/"} target={"_blank"}>{RS('GB_stFlagCredit_3')}</a>
            </p>
        );
    };

    renderPrivacyPolicy = () => {
        const styles = {
            section : {
                fontSize: '12px',
                // marginTop: '20px',
                // marginBottom: "-20px"
            }
        };

        return (
            <p style={styles.section}>
                <a href={"https://www.Avenirhealth.org/privacy.php/"} target={"_blank"}>{RS('GB_stPrivacyPolicy')}</a>
            </p>
        );
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stAboutLiST')}
                onClose={this.onClose}
                content={this.renderAboutContent()}
                actions={undefined}
            />
        );
    }
}

export default AboutDialog;