import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    addRowToPackTable,
    addMergedCellsToArray,
    indentPackTable,
    lockPackRowCol, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {
    CSGetDiarrheaInterv,
    CSGetSevereDiarrheaInterv,
    getAgeCohortName,
    CSGetDiarrheaIntervMstID,
    CSGetSevereDiarrheaIntervMstID,
    FindIntervRec,
    CSGetDiarrheaIntervCurrID,
    CSGetSevereDiarrheaIntervCurrID
} from "utilities/CS/CSDataUtil";
import {CSGetGroupStr} from "utilities/CS/CSUtil";
import GB_CONSTANTS from 'utilities/GB/GBConst';

const Diarrhea = 1;
const SevereDiarrhea = 2;

export const DiarrIncEditor = {
    Create : (IVInfo, CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stImpactsDiar');
        let packTable = Setup(IVInfo, CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ImpactsOnDiarrheaInc;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let DiarrIncReduction = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_DiarrIncReduction_MV3);
        let SevDiarrIncReduction = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_SevDiarrIncReduction_MV);

        for (let row = 1; row < pt.GBRowCount; row++){
            if (pt.IntervRecords[row] !== null){
                for (let col = 1; col < pt.GBColCount; col++){
                    let indices = GetEffAfAndAgeFromCol(col);
                    let EffAF = indices["EffAF"];
                    let age = indices["age"];
                    switch(pt.RowIds[row]){
                        case Diarrhea : {
                            let d = CSGetDiarrheaIntervCurrID(pt.IntervRecords[row]["MstID"]);
                            DiarrIncReduction["value"][CONSTANTS.CS_Data][d][EffAF][age] = pt.tableData.value[row][col];
                            DiarrIncReduction["source"][0][d] = pt.Source[pt.SourceMap[row]];
                            break;
                        }
                        case SevereDiarrhea :{
                            let d = CSGetSevereDiarrheaIntervCurrID(pt.IntervRecords[row]["MstID"]);
                            SevDiarrIncReduction["value"][CONSTANTS.CS_Data][d][EffAF][age] = pt.tableData.value[row][col];
                            SevDiarrIncReduction["source"][0][d] = pt.Source[pt.SourceMap[row]];
                            break;
                        }

                        default : break;
                    }
                }
            }
        }

        changedModvars.push(DiarrIncReduction);
        changedModvars.push(SevDiarrIncReduction);

        return (changedModvars);
    },
};

const groupNameFunc1 = (i) => {
    return CSGetGroupStr(i);
};

const groupNameFunc2 = (i) => {
    return RS('GB_stImpactsSevDiarInc');
};

const addIntervRow = (pt, mstFunc, nameFunc, groupNameFunc, CSModvars, IVInfo, GroupIDArr, numCols, row, d, id2) => {
    let mstID = mstFunc(d);
    let IVRec = FindIntervRec(IVInfo, mstID);
    if (!GroupIDArr.includes(IVRec["GroupMstID"])) {
        row++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = groupNameFunc(IVRec["GroupMstID"]);
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
        GroupIDArr.push(IVRec["GroupMstID"]);
    }
    row++;
    pt = addRowToPackTable(pt);
    pt.tableData.value[row][0] = nameFunc(d);
    pt.numSources++;
    pt.SourceTitle[pt.numSources] = groupNameFunc(IVRec["GroupMstID"]) + ' - ' + pt.tableData.value[row][0];
    pt.SourceMap[row] = pt.numSources;
    pt.IntervRecords[row] = IVRec;
    pt.RowIds[row] = id2;
    pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
    for(let col = 1; col < pt.GBColCount; col++) {
        pt.Alignments[row][col] = CONSTANTS.GB_Right;
        pt.RDec[row][col] = 2;
        if (!isOdd(col)){
            if (((id2 === Diarrhea) &&[
                CONSTANTS.CS_ZincP_DiarInterv,
                CONSTANTS.CS_VitAPTwoDoses_DiarInterv,
                CONSTANTS.CS_RotavirusVacc_DiarInterv,
                CONSTANTS.CS_DiarVaccPathB_DiarInterv,
                CONSTANTS.CS_DiarVaccPathC_DiarInterv].includes(d)) ||
                ((id2 === SevereDiarrhea) && [CONSTANTS.CS_RotavirusVacc_SevDiarInterv].includes(d))){
                    pt = lockPackRowCol(pt, row, col, true);
            }
        }
    }
    return ([pt, row]);
};

const Setup = (IVInfo, CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    pt.GBFixedRows = 2;

    pt.AgeSet = [CONSTANTS.CS_0t1months,
                 CONSTANTS.CS_1t6months,
                 CONSTANTS.CS_6t12months,
                 CONSTANTS.CS_12t24months,
                 CONSTANTS.CS_24t60months];

    let numCols = 11;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            for (let row = 1; row <= CONSTANTS.CS_24t60months; row++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
            }
            col++;
        }
    }
    else{
        pt.AgeSet.forEach(function (a) {
            let ageOffset = ((a - CONSTANTS.CS_0t1months) * 2);
            pt.tableData.value[0][1 + ageOffset] = getAgeCohortName(a, true);
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 0, 1 + ageOffset, 1, 2);
            pt.tableData.value[1][1 + ageOffset] = RS('GB_stEffectiveness');
            pt.tableData.value[1][2 + ageOffset] = RS('CS_stAffFract');
        });
    }

    let GroupIDArr = [];
    let row = 1;

    pt.numSources = 0;

    for (let d = CONSTANTS.CS_FirstDiarInterv; d <= CONSTANTS.CS_NumDiarIntervs-1; d++){
        let result = addIntervRow(
            pt,
            CSGetDiarrheaIntervMstID,
            CSGetDiarrheaInterv,
            groupNameFunc1,
            CSModvars,
            IVInfo,
            GroupIDArr,
            numCols,
            row,
            d,
            Diarrhea);
        pt = result[0];
        row = result[1];
    }

    GroupIDArr = [];
    for (let d = CONSTANTS.CS_RotavirusVacc_SevDiarInterv; d <= CONSTANTS.CS_RotavirusVacc_SevDiarInterv; d++){
        let result = addIntervRow(
            pt,
            CSGetSevereDiarrheaIntervMstID,
            CSGetSevereDiarrheaInterv,
            groupNameFunc2,
            CSModvars,
            IVInfo,
            GroupIDArr,
            numCols,
            row,
            d,
            SevereDiarrhea);
        pt = result[0];
        row = result[1];
    }

    return pt;
};

const isOdd = (num) =>{return num % 2;};

const GetEffAfAndAgeFromCol = (col) => {
    let EffAF = 0;
    if (isOdd(col)){EffAF = CONSTANTS.CS_Efficacy;}
    else{EffAF = CONSTANTS.CS_AffecFract;}
    let age = Math.floor((col-1)/2) + 1;
    return {'EffAF' : EffAF, 'age' : age};
};

const SetSSData = (pt, CSModvars) => {
    let DiarrIncReduction = getModvarByTag(CSModvars, CONSTANTS.CS_TG_DiarrIncReduction_MV3);
    let SevDiarrIncReduction = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SevDiarrIncReduction_MV);
    for (let row = 1; row < pt.GBRowCount; row++){
        if (pt.IntervRecords[row] !== null){
            for (let col = 1; col < pt.GBColCount; col++){
                let indices = GetEffAfAndAgeFromCol(col);
                let EffAF = indices["EffAF"];
                let age = indices["age"];
                switch(pt.RowIds[row]){
                    case Diarrhea : {
                        let d = CSGetDiarrheaIntervCurrID(pt.IntervRecords[row]["MstID"]);
                        pt.tableData.value[row][col] = DiarrIncReduction["value"][CONSTANTS.CS_Data][d][EffAF][age];
                        pt.Source[pt.SourceMap[row]] = DiarrIncReduction["source"][0][d];
                        break;
                    }
                    case SevereDiarrhea :{
                        let d = CSGetSevereDiarrheaIntervCurrID(pt.IntervRecords[row]["MstID"]);
                        pt.tableData.value[row][col] = SevDiarrIncReduction["value"][CONSTANTS.CS_Data][d][EffAF][age];
                        pt.Source[pt.SourceMap[row]] = SevDiarrIncReduction["source"][0][d];
                        break;
                    }

                    default : break;
                }
            }
        }
    }
    return pt;
};

