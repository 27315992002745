import React, { Component } from "react";
import * as PropTypes from "prop-types";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-blue.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import injectSheet from "react-jss";
import classNames from "classnames";
import "./CoreGbStdTable.css";
import { fieldNamePrefix } from "./other/constants";
import { formatNumber, isNumeric } from "./other/utilities";
import CheckboxCellRenderer from "./CheckboxCellRenderer";

const styles = {
    title: {
        marginBottom: "0.5em",
        fontSize: "1em",
        marginTop: 0
    },
    listOnlineTitle: {
        color: "#0e47a1",
        fontWeight: "bold",
        fontSize: "14px"
    }
};

const injectedStyles = {
    header: {
        backgroundColor: props => props.headerBackgroundColor
    },
    oddRow: {
        backgroundColor: props => [props.banding ? [props.oddRowBackgroundColor] : ["white"], "!important"]
    },
    subheadingRow: {
        backgroundColor: props => [
            props.subheadingRowBackgroundColor ? [props.subheadingRowBackgroundColor] : ["#CCCCCC"],
            "!important"
        ]
    }
};

const getHeaderClass = (packTable, packTableColumnDefinition) => {
    if (!packTableColumnDefinition.headerName) {
        return;
    }

    const rowCellFormats = packTable.cellFormats[packTableColumnDefinition.startRow];

    const field = packTableColumnDefinition.field || Object.keys(rowCellFormats)[packTableColumnDefinition.startColumn];

    const cellFormat = rowCellFormats[field];

    return {
        left: "header-left",
        center: "header-center",
        right: "header-right"
    }[cellFormat.alignment];
};

const getCellFormat = (params, packTable) => {
    const value = packTable.cellFormats[packTable.headerRowCount + params.node.childIndex]
        ? packTable.cellFormats[packTable.headerRowCount + params.node.childIndex][params.colDef.field]
        : {};

    return value;
};

const getCellClasses = (params, packTable) => {
    const cellClass = [];

    const cellFormat = getCellFormat(params, packTable);

    switch (cellFormat.verticalAlignment) {
        case "top":
            cellClass.push("cell-alignment-top");

            break;

        case "bottom":
            cellClass.push("cell-alignment-bottom");

            break;

        default:
            break;
    }

    if (cellFormat.showCommentIndicator) {
        cellClass.push("commented-cell");
    }

    return cellClass;
};

const getRowClass = (params, packTable, classes, useSubheadingRowBackgroundColor) => {
    let value =
        packTable.rowFormats[packTable.headerRowCount + params.node.childIndex] &&
        packTable.rowFormats[packTable.headerRowCount + params.node.childIndex].containsMergedCell &&
        classes.subheadingRow &&
        useSubheadingRowBackgroundColor
            ? classNames("subheading-row", classes.subheadingRow)
            : params.rowIndex % 2 === 1
            ? classes.oddRow
            : undefined;

    return value;
};

const getRowIndentation = (params, packTable) =>
    11 +
    (params.colDef.field === `${fieldNamePrefix}1`
        ? packTable.rowFormats[packTable.headerRowCount + params.node.childIndex]
            ? packTable.rowFormats[packTable.headerRowCount + params.node.childIndex].indentation
            : 0
        : 0);

const parseEditedValue = (params, packTable) => {
    if (
        packTable.fixedColumnFields.includes(params.colDef.colId)
        // ||
        // params.node.childIndex < packTable.headerRowCount
    ) {
        return params.newValue;
    }

    const cellFormat = getCellFormat(params, packTable);

    if (!isNumeric(params.newValue)) {
        if (cellFormat.minimumValue === cellFormat.maximumValue) {
            // Text cell
            return params.newValue;
        } else {
            // Number cell but someone tried to put in text
            return params.oldValue;
        }
    }

    let newValue = Number(params.newValue);

    if (cellFormat.minimumValue === cellFormat.maximumValue) {
        return params.newValue;
    } else {
        if (newValue < cellFormat.minimumValue || newValue > cellFormat.maximumValue) {
            newValue = params.oldValue;
            alert("Input must be between " + cellFormat.minimumValue + " and " + cellFormat.maximumValue);
        }
    }

    return newValue;
};

const createGridColumnDefinition = (
    packTable,
    packTableColumnDefinition,
    classes,
    showThousandsSeparator,
    handleCellChanged,
    handleCheckboxToggled
) => {
    const headerClass = classNames(classes.header, getHeaderClass(packTable, packTableColumnDefinition));

    if (packTableColumnDefinition.children) {
        return {
            headerName: packTableColumnDefinition.headerName,
            headerClass,
            marryChildren: true,
            children: packTableColumnDefinition.children.map(child =>
                createGridColumnDefinition(
                    packTable,
                    child,
                    classes,
                    showThousandsSeparator,
                    handleCellChanged,
                    handleCheckboxToggled
                )
            )
        };
    }

    return {
        headerName: packTableColumnDefinition.headerName,
        field: packTableColumnDefinition.field,
        width: packTableColumnDefinition.width,
        hide: packTableColumnDefinition.width === 0,
        suppressSizeToFit: packTableColumnDefinition.width,
        autoHeight: true,
        headerClass,
        pinned: packTableColumnDefinition.pinned ? "left" : undefined,
        colSpan: params =>
            packTable.rowFormats[packTable.headerRowCount + params.node.childIndex]
                ? packTable.rowFormats[packTable.headerRowCount + params.node.childIndex].containsMergedCell
                    ? packTable.columnCount
                    : 1
                : 1,
        cellClass: params =>
            getCellClasses(params, packTable).concat(packTableColumnDefinition.pinned ? ["lock-pinned"] : []),
        cellStyle: params =>
            (cellFormat => ({
                paddingLeft: `${getRowIndentation(params, packTable)}px`,
                display: "flex",
                justifyContent: {
                    left: "flex-start",
                    center: "center",
                    right: "flex-end"
                }[cellFormat.alignment],
                alignItems: "center",
                whiteSpace: packTableColumnDefinition.wordWrap ? "normal" : "nowrap",
                fontWeight: cellFormat.fontStyles && cellFormat.fontStyles.includes("bold") ? "bold" : "normal",
                fontStyle: cellFormat.fontStyles && cellFormat.fontStyles.includes("italic") ? "italic" : "normal",
                textDecoration:
                    cellFormat.fontStyles && cellFormat.fontStyles.includes("underline") ? "underline" : "none",
                color: cellFormat.fontColor || "black",
                background: cellFormat.BGColor || null
            }))(getCellFormat(params, packTable)),
        suppressMenu: true,
        editable: params => !packTable.readOnly && !getCellFormat(params, packTable).locked,
        valueFormatter: params => {
            return formatNumber(
                params.value,
                packTableColumnDefinition.pinned || packTable.fixedColumnFields.includes(params.colDef.field)
                    ? undefined
                    : getCellFormat(params, packTable).decimalPlaces,
                showThousandsSeparator
            );
        },
        valueParser: params => parseEditedValue(params, packTable),
        cellRendererSelector: params => {
            const cellFormat = getCellFormat(params, packTable);

            if (cellFormat.hasCheckbox) {
                return {
                    component: "checkboxCellRenderer",
                    params: {
                        rowIndex: params.node.childIndex,
                        field: params.colDef.field,
                        value: getCellFormat(params, packTable).checkboxState,
                        onChange: (field, rowIndex, value) => {
                            handleCheckboxToggled({
                                rowIndex,
                                columnId: field,
                                value
                            });
                        }
                    }
                };
            } else if (cellFormat.component) {
                return {
                    component: cellFormat.component,
                    params: {
                        rowIndex: params.node.childIndex,
                        field: params.colDef.field
                    }
                };
            } else {
                return undefined;
            }
        },
        tooltipValueGetter: params => getCellFormat(params, packTable).comment || undefined
    };
};

const createGridColumnDefinitions = (
    packTable,
    classes,
    showThousandsSeparator,
    handleCellChanged,
    handleCheckboxToggled
) =>
    createGridColumnDefinition(
        packTable,
        {
            children: packTable.columnDefinitions
        },
        classes,
        showThousandsSeparator,
        handleCellChanged,
        handleCheckboxToggled
    ).children;

const getHeaderHeight = packTable => {
    const rowFormat = packTable.rowFormats[packTable.headerRowCount - 1];

    return rowFormat && rowFormat.height > 0 ? rowFormat.height : undefined;
};

const getData = packTable => {
    return packTable.data.map((row, index) =>
        packTable.rowFormats[packTable.headerRowCount + index] &&
        packTable.rowFormats[packTable.headerRowCount + index].containsMergedCell
            ? {
                  [`${fieldNamePrefix}1`]: Object.values(row)[0]
              }
            : row
    );
};

const navigateToNextRow = (previousRowIndex, nextRowIndex, packTable) => {
    const direction = previousRowIndex < nextRowIndex ? "down" : "up";

    switch (direction) {
        case "up":
            while (
                packTable.rowFormats[packTable.headerRowCount + nextRowIndex] &&
                packTable.rowFormats[packTable.headerRowCount + nextRowIndex].containsMergedCell
            ) {
                nextRowIndex--;
            }

            return nextRowIndex >= 0 ? nextRowIndex : previousRowIndex;

        case "down":
            while (
                packTable.rowFormats[packTable.headerRowCount + nextRowIndex] &&
                packTable.rowFormats[packTable.headerRowCount + nextRowIndex].containsMergedCell
            ) {
                nextRowIndex++;
            }

            return nextRowIndex < packTable.data.length ? nextRowIndex : previousRowIndex;

        default:
            return previousRowIndex;
    }
};

const navigateToNextCell = (params, packTable) => {
    const { key, previousCellPosition, nextCellPosition } = params;

    if (nextCellPosition === null) {
        return previousCellPosition;
    }

    let nextRowIndex = nextCellPosition.rowIndex;

    switch (key) {
        case 37: // left
            return packTable.fixedColumnFields.includes(nextCellPosition.column.colId)
                ? previousCellPosition
                : nextCellPosition;

        case 38: // up
            nextRowIndex = navigateToNextRow(previousCellPosition.rowIndex, nextCellPosition.rowIndex, packTable);

            return nextRowIndex >= 0
                ? {
                      ...previousCellPosition,
                      rowIndex: nextRowIndex
                  }
                : previousCellPosition;

        case 40: // down
            nextRowIndex = navigateToNextRow(previousCellPosition.rowIndex, nextCellPosition.rowIndex, packTable);

            return nextRowIndex < packTable.data.length
                ? {
                      ...previousCellPosition,
                      rowIndex: nextRowIndex
                  }
                : previousCellPosition;

        default:
            return nextCellPosition;
    }
};

const getDataSourceMenuLabel = sourceMode => {
    switch (sourceMode) {
        case 0:
            return "Data source";

        case 1:
            return "Data source (row)";

        case 2:
            return "Data source (column)";

        case 3:
            return "Data source (row section)";

        default:
            return "Data source";
    }
};

const trimFixedCells = (selectedRange, packTable) => {
    const fixedColumnFields = new Set(packTable.fixedColumnFields);

    const columnIds = selectedRange.columnIds.filter(columnId => !fixedColumnFields.has(columnId));

    const firstRowIndex = Math.max(selectedRange.firstRowIndex, packTable.headerRowCount);

    return {
        ...selectedRange,
        columnIds,
        firstRowIndex
    };
};

const getSelectedRange = (params, packTable) => {
    const rangeSelections = params.api.getCellRanges();

    let fields = [params.column.colId];
    let firstRowIndex = packTable.headerRowCount + params.node.childIndex;
    let lastRowIndex = packTable.headerRowCount + params.node.childIndex;

    if (rangeSelections && rangeSelections.length) {
        if (rangeSelections.length !== 1) {
            return;
        }

        const rangeSelection = rangeSelections[0];

        fields = rangeSelection.columns.map(column => column.colId);
        firstRowIndex = packTable.headerRowCount + rangeSelection.startRow.rowIndex;
        lastRowIndex = packTable.headerRowCount + rangeSelection.endRow.rowIndex;
    }

    return trimFixedCells(
        {
            firstRowIndex: Math.min(firstRowIndex, lastRowIndex),
            lastRowIndex: Math.max(firstRowIndex, lastRowIndex),
            columnIds: fields
        },
        packTable
    );
};

class CoreGbStdTable extends Component {
    constructor(props) {
        super(props);

        this.advancedCellEditor = false;

        this.state = {
            showThousandsSeparator: true
        };
    }

    handleGridReady = params => {
        this.gridApi = params.api;
        this.gridApi.setQuickFilter(this.props.filterText);

        //params.api.sizeColumnsToFit();
    };

    getDefaultContextMenuItems = params => {
        if (this.props.disablePopupMenu) {
            return undefined;
        } else {
            return [
                {
                    name: "Undo",
                    action: this.props.onUndo,
                    disabled: this.props.disableUndo
                },
                {
                    name: "Redo",
                    action: this.props.onRedo,
                    disabled: this.props.disableRedo
                },
                "separator",
                {
                    name: "Cut"
                },
                "copy",
                {
                    name: "Copy all",
                    action: this.props.onCopyAll
                },
                "paste",
                "separator",
                {
                    name: "Decrement decimal",
                    action: () => {
                        this.props.onIncrementDecimalPlaces({
                            ...getSelectedRange(params, this.props.packTable),
                            by: -1
                        });
                    }
                },
                {
                    name: "Increment decimal",
                    action: () => {
                        this.props.onIncrementDecimalPlaces({
                            ...getSelectedRange(params, this.props.packTable),
                            by: 1
                        });
                    }
                },
                "separator",
                {
                    name: "Duplicate across rows",
                    action: () => {
                        this.props.onDuplicateAcrossRows(getSelectedRange(params, this.props.packTable));
                    }
                },
                {
                    name: "Duplicate down columns",
                    action: () => {
                        this.props.onDuplicateDownColumns(getSelectedRange(params, this.props.packTable));
                    }
                },
                "separator",
                {
                    name: "Interpolate",
                    action: () => {
                        this.props.onInterpolate(getSelectedRange(params, this.props.packTable));
                    }
                },
                {
                    name: "Normalize",
                    action: () => {
                        this.props.onNormalize(getSelectedRange(params, this.props.packTable));
                    }
                },
                {
                    name: "Multiply",
                    action: () => {
                        this.props.onMultiply(getSelectedRange(params, this.props.packTable));
                    }
                },
                "separator",
                {
                    name: getDataSourceMenuLabel(this.props.packTable.sourceMode),
                    action: () => {
                        this.props.onSourceRequested(getSelectedRange(params, this.props.packTable));
                    }
                },
                "separator",
                {
                    name: "Show/Hide thousands separator",
                    action: () => {
                        this.props.onToggleThousandsSeparator(!this.state.showThousandsSeparator);

                        this.setState(
                            {
                                showThousandsSeparator: !this.state.showThousandsSeparator
                            },
                            () => {
                                this.reloadColumnDefinitions();
                            }
                        );
                    }
                },
                "separator",
                {
                    name: "Copy to JSON",
                    action: () => {
                        this.props.onCopyPackTableJsonToClipboard();
                    }
                }
            ];
        }
    };

    getContextMenuItems = params => {
        const removedMenuItems = new Set(this.props.removedMenuNames);

        let contextMenuItems = this.getDefaultContextMenuItems(params).filter(
            menuItem => !menuItem.name || !removedMenuItems.has(menuItem.name)
        );

        contextMenuItems = contextMenuItems.filter(
            (menuItem, index) =>
                index === contextMenuItems.length - 1 ||
                menuItem !== "separator" ||
                contextMenuItems[index + 1] !== "separator"
        );

        if (contextMenuItems.length && contextMenuItems[0] === "separator") {
            contextMenuItems = contextMenuItems.slice(1);
        }

        if (contextMenuItems.length && contextMenuItems.slice(-1)[0] === "separator") {
            contextMenuItems.pop();
        }

        if (this.props.customContextMenu) {
            contextMenuItems = [...contextMenuItems, "separator", ...this.props.customContextMenu];
        }

        return contextMenuItems;
    };

    resetColumnDefinitions = columnDefinitions => {
        // Bug in ag-grid means you have to set empty array first
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(columnDefinitions);
    };

    reloadColumnDefinitions = () => {
        // console.log("state");
        // console.log(this.state);

        const columnDefinitions = createGridColumnDefinitions(
            this.props.packTable,
            this.props.classes,
            this.state.showThousandsSeparator,
            this.props.onCellValueChanged,
            this.props.onCheckboxToggled
        );

        this.resetColumnDefinitions(columnDefinitions);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof this.gridApi === "undefined") {
            return;
        }

        this.gridApi.stopEditing(true);
        this.gridApi.setQuickFilter(this.props.filterText);

        if (
            JSON.stringify({
                ...this.props.packTable,
                data: undefined
            }) !==
            JSON.stringify({
                ...prevProps.packTable,
                data: undefined
            })
        ) {
            this.reloadColumnDefinitions();
        }

        if (JSON.stringify(this.props.packTable.data) !== JSON.stringify(prevProps.packTable.data)) {
            this.gridApi.setRowData(this.props.packTable.data);
        }
    }

    render() {
        //console.log(this.props.packTable);

        const columnDefinitions = createGridColumnDefinitions(
            this.props.packTable,
            this.props.classes,
            this.state.showThousandsSeparator,
            this.props.onCellValueChanged,
            this.props.onCheckboxToggled
        );

        const data = getData(this.props.packTable);

        return (
            <div
                className={classNames("GbStdTable", !data.length ? "empty" : undefined, this.props.wrapperClassNames)}
                style={this.props.style}
            >
                {this.props.showTitle
                    ? this.props.packTable.title && (
                          <h1
                              style={{
                                  ...styles.title,
                                  ...this.props.captionStyles
                              }}
                          >
                              {this.props.packTable.title}
                          </h1>
                      )
                    : null}

                <div
                    className={classNames("ag-theme-balham", {
                        rounded: this.props.rounded
                    })}
                    style={{
                        width: this.props.width,
                        maxWidth: this.props.packTable.tableWidth ? this.props.packTable.tableWidth + 2 : undefined,
                        height: this.props.height
                    }}
                >
                    <div className="clipboard-anchor"></div>
                    <AgGridReact
                        onRangeSelectionChanged={params => {
                            this.props.onSelectCells(params.api.getCellRanges());
                        }}
                        ref={this.props.gridRef}
                        domLayout={this.props.height === "auto" ? "autoHeight" : null}
                        defaultColDef={{
                            suppressMovable: true,
                            suppressKeyboardEvent: params => {
                                const key = params.event.key;

                                let navigateToNewCell = false;

                                if (params.editing) {
                                    let rowIndex = params.node.rowIndex;
                                    let columnId = params.column.colId;

                                    if (!this.advancedCellEditor) {
                                        let deltaX = key === "ArrowLeft" ? -1 : key === "ArrowRight" ? 1 : 0;

                                        if (deltaX) {
                                            const gridColumns = params.api.columnController.gridColumns;

                                            const currentColumnIndex = gridColumns.findIndex(
                                                gridColumn => gridColumn.colId === columnId
                                            );

                                            if (
                                                currentColumnIndex + deltaX >=
                                                this.props.packTable.fixedColumnFields.length
                                            ) {
                                                columnId =
                                                    gridColumns[
                                                        Math.max(
                                                            Math.min(
                                                                currentColumnIndex + deltaX,
                                                                gridColumns.length - 1
                                                            ),
                                                            0
                                                        )
                                                    ].colId;
                                            }

                                            navigateToNewCell = true;
                                        }
                                    }

                                    const deltaY = key === "ArrowUp" ? -1 : key === "ArrowDown" ? 1 : 0;

                                    if (deltaY) {
                                        rowIndex = navigateToNextRow(rowIndex, rowIndex + deltaY, this.props.packTable);
                                        navigateToNewCell = true;
                                    }

                                    if (navigateToNewCell) {
                                        params.api.clearRangeSelection();

                                        params.api.setFocusedCell(rowIndex, columnId);

                                        return true;
                                    }
                                }

                                return false;
                            }
                        }}
                        columnDefs={columnDefinitions}
                        headerHeight={getHeaderHeight(this.props.packTable)}
                        getRowHeight={this.props.rowHeight && (() => this.props.rowHeight)}
                        rowData={data}
                        getRowClass={params =>
                            getRowClass(
                                params,
                                this.props.packTable,
                                this.props.classes,
                                !!this.props.subheadingRowBackgroundColor
                            )
                        }
                        enableRangeSelection={true}
                        popupParent={document.querySelector("body")}
                        getContextMenuItems={this.getContextMenuItems}
                        suppressMoveableColumns={true}
                        suppressRowDrag={true}
                        enterMovesDownAfterEdit={false}
                        frameworkComponents={{
                            checkboxCellRenderer: CheckboxCellRenderer,
                            ...(this.props.frameworkComponents || {})
                        }}
                        enableBrowserTooltips={true}
                        navigateToNextCell={params => navigateToNextCell(params, this.props.packTable)}
                        onGridReady={this.handleGridReady}
                        onCellDoubleClicked={() => {
                            this.advancedCellEditor = true;
                        }}
                        onCellEditingStopped={() => {
                            this.advancedCellEditor = false;
                        }}
                        onCellValueChanged={params => {
                            this.props.onCellValueChanged({
                                rowIndex: params.rowIndex,
                                columnId: params.colDef.field,
                                oldValue: params.oldValue,
                                newValue: params.newValue
                            });
                        }}
                        pagination={this.props.pagination}
                        paginationPageSize={this.props.paginationPageSize}
                        height={this.props.height}
                        onCellClicked={this.props.onCellClicked}
                        suppressDragLeaveHidesColumns={true}
                    />
                </div>
            </div>
        );
    }
}

CoreGbStdTable.propTypes = {
    packTable: PropTypes.object.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    headerBackgroundColor: PropTypes.string,
    subheadingRowBackgroundColor: PropTypes.string,
    oddRowBackgroundColor: PropTypes.string,
    bandBackgroundColor: PropTypes.string,
    rounded: PropTypes.bool,
    disableUndo: PropTypes.bool,
    disableRedo: PropTypes.bool,
    onCellValueChanged: PropTypes.func.isRequired,
    onCheckboxToggled: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
    onRedo: PropTypes.func.isRequired,
    onCopyAll: PropTypes.func.isRequired,
    onIncrementDecimalPlaces: PropTypes.func.isRequired,
    onDuplicateAcrossRows: PropTypes.func.isRequired,
    onDuplicateDownColumns: PropTypes.func.isRequired,
    onInterpolate: PropTypes.func.isRequired,
    onNormalize: PropTypes.func.isRequired,
    onMultiply: PropTypes.func.isRequired,
    onSourceRequested: PropTypes.func.isRequired,
    onSelectCells: PropTypes.func.isRequired,
    onToggleThousandsSeparator: PropTypes.func.isRequired,
    onCopyPackTableJsonToClipboard: PropTypes.func.isRequired,
    style: PropTypes.object,
    pagination: PropTypes.bool,
    paginationPageSize: PropTypes.number,
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onCellClicked: PropTypes.func,
    filterText: PropTypes.string,
    showTitle: PropTypes.bool,
    disablePopupMenu: PropTypes.bool,
    removedMenuNames: PropTypes.arrayOf(PropTypes.string),
    customContextMenu: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            action: PropTypes.func,
            disabled: PropTypes.bool
        })
    ),
    captionStyles: PropTypes.object,
    wrapperClassNames: PropTypes.string,
    banding: PropTypes.bool,
    frameworkComponents: PropTypes.object,
    gridRef: PropTypes.object.isRequired
};

CoreGbStdTable.defaultProps = {
    width: "100%",
    height: "auto",
    rounded: false,
    disablePopupMenu: false,
    removedMenuNames: [],
    captionStyles: {},
    wrapperClassNames: ""
};

export default injectSheet(injectedStyles)(CoreGbStdTable);
