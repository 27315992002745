import React from "react";
import PropTypes from "prop-types";
import CSDialog from "./CSDialog";
import OKButton from "../buttons/OKButton";

class WarningDialog extends React.Component {

    static propTypes = {
        onStateChange     : PropTypes.func,
        title             : PropTypes.string,
        message           : PropTypes.string,
    };

    static defaultProps = {
        onStateChange     : () => console.log('onStateChange'),
        title             : "Warning",
        message           : "Unknown warning",
    };

    onClose = () => {
        this.props.onStateChange({ 
            dialogWarningOpen : false, 
            warningMessage    : ''
        });
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={this.props.title}
                onClose={this.onClose}
                content={this.props.message}
                iconType={"Warning"}
                actions={
                    <div>
                        <OKButton onClick={this.onClose} colorType="primary" />
                    </div>
                }
            />
        );
    }
}

export default WarningDialog;