import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import TEdit from '@common/components/TEdit';
import TRangeSlider from "@common/components/TRangeSlider";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSDialog from "./CSDialog";
import CreateProjectionButton from "../buttons/CreateProjectionButton";
 
class ProjInfoDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        selectedCountry                         : PropTypes.string,
        countryISO                              : PropTypes.number,
        projectCreated                          : PropTypes.bool,
        onStateChange                           : PropTypes.func,
        onCreateProjectionFromGuidedExploration : PropTypes.func
    };

    static defaultProps = {
        selectedCountry                         : "Afghanistan",
        countryISO                              : 4,
        projectCreated                          : false,
        onStateChange                           : () => console.log('onStateChange'),
        onCreateProjectionFromGuidedExploration : () => console.log('onCreateProjection'),
    };

    state = {
        projectionName                          : '',
        yearRange                               : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        projectCreated                          : false,
    };

    //==================================================================================================================
    //
    //                                             Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogProjInfoOpen : false
        });
    };

    onErrorDialogShow = (msg) => {
        this.props.onStateChange({
            dialogErrorOpen : true,
            errorMessage    : msg
        });
    };

    onProjNameChange = (value) => {
        this.setState({
            projectionName : value
        });
    };

    onYearChange = (value) => {
        if (value.min > (new Date()).getFullYear()) {
            value.min = (new Date()).getFullYear();
        }

        if (value.max < value.min + 1) {
            value.max = value.min + 1;
        }

        this.setState({
            ...this.state,
            yearRange : value
        });
    };

    onValidate = () => {

        let valid = this.state.projectionName.replace(/^\s+$/, '') !== '';
        let legit = !this.state.projectionName.includes(":") && !this.state.projectionName.includes("/");

        if (!valid) {
            this.onErrorDialogShow(RS('GB_stPleaseEnterProjName'));
        } else if (!legit) {
            this.onErrorDialogShow(RS("GB_stBadChar"));
        }

        return valid && legit;
    };

    onCreateNewProjectionClick = () => {
        if (this.onValidate()) {
            if (this.state.projectCreated) {
                this.props.onStateChange({
                    dialogCloseOpen : true
                });
            } 
            else {
                this.props.onCreateProjectionFromGuidedExploration({
                    CountryISO       : this.props.countryISO,
                    selectedCountry  : this.props.selectedCountry,
                    FirstYear        : this.state.yearRange.min,
                    FinalYear        : this.state.yearRange.max,
                    FileName         : this.state.projectionName,
                    ModList          : ['1', '2', '3', '4', '15'],
                    Notes            : '',
                    Author           : '',
                    Org              : '',
                    arr              : [],
                    AgeGroupOption   : CONSTANTS.FP_Single_Age_Group,
                });
            }
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderCreateFullProjection = () => {
        return (
            <div>
                {this.renderProjectionName()}
                {this.renderCountryNameLabel()}
                {this.renderYears()}
            </div>
        )
    };

    renderCountryNameLabel = () => {

        const styles = {
            projName : {
                width: '465px',
                maxWidth: '100%',
                marginTop: '10px',
                marginBottom: '35px',
                color: '#609cd9',
            }
        };

        return (
            <div>
                <TEdit
                    disabled={true}
                    name="CountryNameField"
                    caption={RS('GB_stSelectedCountry')}
                    value={this.props.selectedCountry}
                    // onChange={}
                    captionStyle={{color: '#609cd9'}}
                    style={styles.projName}
                />

                <br />
            </div>
        )
    };

    renderProjectionName = () => {

        const styles = {
            projName : {
                width: '465px',
                maxWidth: '100%',
                marginBottom: '35px',
                color: '#609cd9',
            }
        };

        return (
            <div>
                <TEdit
                    name="ProjectionNameField"
                    caption={RS('GB_stProjectionName')}
                    value={this.state.projectionName}
                    onChange={this.onProjNameChange}
                    captionStyle={{color: '#609cd9'}}
                    style={styles.projName}
                /> 

                <br />
            </div>
        )
    };

    renderYears = () => {
        return (
            <div style={{marginBottom: '35px', width: '465px', maxWidth:"100%"}}>
                <TRangeSlider
                    minValue={CONSTANTS.CS_MinYear}
                    maxValue={CONSTANTS.CS_MaxYear}
                    minTextLabel={RS('GB_stFirstYear')}
                    maxTextLabel={RS('GB_stFinalYear')}
                    disabledMin={this.state.projectCreated}
                    value={this.state.yearRange}
                    onChange={this.onYearChange}
                />
            </div>
        )
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stProjNameAndTimeFrame')}
                onClose={this.onClose}
                content={
                    <div>
                        {this.renderCreateFullProjection()}
                    </div>
                }
                actions={
                    <div>
                        <CreateProjectionButton onClick={this.onCreateNewProjectionClick} />
                    </div>
                }
            />
        );
    }
}

export default ProjInfoDialog;