import React from 'react';
import PropTypes from "prop-types";
import TCheckBox from '@common/components/TCheckBox';
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {AbortionIncRatioEditor} from "../../data/HealthStatus/Abortion/AbortIncRatioEditor";

class CSAbortionDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object, 
        useFamPlanOutputs         : PropTypes.bool,
        onPackTable1Change        : PropTypes.func,
        onPackTable2Change        : PropTypes.func, 
        onUseFamPlanOutputsChange : PropTypes.func,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        useFamPlanOutputs         : false,
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change        : (packTable) => console.log('onPackTable2Change'),
        onUseFamPlanOutputsChange : (value) => console.log('onUseFamPlanOutputsChange'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable); 
    };

    onPackTable2Change = (packTable) => {
        this.props.onPackTable2Change(packTable);  
    };

    onUseFamPlanOutputsCheckBoxClick = (value) => {
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));
        packTable2 = AbortionIncRatioEditor.onCheckBoxClick(packTable2, value);
        this.onPackTable2Change(packTable2);
        this.props.onUseFamPlanOutputsChange(value);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));
        let useFamPlanOutputs = this.props.useFamPlanOutputs;

        const styles = {
            checkBoxLabelStyle: {
                fontSize: '16px',
                color: '#609cd9'
            },
            checkBox: {
                marginBottom: 20,
            },
        };

        return (
            <div className="innerPage">

                {/*<GBStdTable*/}
                    {/*id={"MySheet1"}*/}
                    {/*packTable={packTable1}*/}
                    {/*minHeight={85}*/}
                    {/*maxHeight={85}*/}
                    {/*bandColor={Theme.editor.bandColor}*/}
                    {/*fixedRowColor={Theme.editor.fixedRowColor}*/}
                    {/*highlightColor={Theme.editor.highlightColor}*/}
                    {/*cellPadding={Theme.editor.cellPadding}*/}
                    {/*readOnly={useFamPlanOutputs}*/}
                    {/*onSheetCellsChanged={this.onPackTable1Change}*/}
                {/*/>*/}
                <EditorNote
                    numberOfTables = {2}
                />

                <CSEditor
                    mstID       = {CONSTANTS.CS_MstPercPregEndAbortSheetID}
                    packTable   = {packTable1}
                    onChange    = {this.onPackTable1Change}
                    showTitle   = {true}
                    readOnly    = {useFamPlanOutputs}
                />

                {/*<GBStdTable*/}
                    {/*id={"MySheet2"}*/}
                    {/*packTable={packTable2}*/}
                    {/*minHeight={85}*/}
                    {/*maxHeight={85}*/}
                    {/*bandColor={Theme.editor.bandColor}*/}
                    {/*fixedRowColor={Theme.editor.fixedRowColor}*/}
                    {/*highlightColor={Theme.editor.highlightColor}*/}
                    {/*cellPadding={Theme.editor.cellPadding}*/}
                    {/*onSheetCellsChanged={this.onPackTable2Change}*/}
                {/*/>*/}

                <TCheckBox
                    style={styles.checkBox}
                    caption={RS('GB_stCalcAbortion')}
                    tooltip={""}
                    labelStyle={styles.checkBoxLabelStyle}
                    value={useFamPlanOutputs}
                    onClick={this.onUseFamPlanOutputsCheckBoxClick}
                />

                {
                    (!useFamPlanOutputs) ?
                        <CSEditor
                            mstID={CONSTANTS.CS_MstAbortIncRatioSheetID}
                            packTable={packTable2}
                            onChange={this.onPackTable2Change}
                            showTitle={true}
                        /> : null
                }

            </div>
        );
    }

}

export default CSAbortionDrawer;