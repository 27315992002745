import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import Data from "../../../data/ExploreDefaultData";
import CONSTANTS from "utilities/CS/CSConst";
import CreateDisplayButton from "../buttons/CreateDisplayButton";
import OptionCheckBox from "../checkboxes/OptionCheckBox";

class CausesOfDeathOptions extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        visible         : PropTypes.bool,
        onAddDisplay    : PropTypes.func,
        language        : PropTypes.number,
    };

    static defaultProps = {
        visible         : true,
        onAddDisplay    : () => console.log('CausesOfDeathOptions onAddDisplay'),
        language        : CONSTANTS.GB_English
    };

    state = {
        content         : Data.exploreCODContent,
        buttonDisabled  : true
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onShowDataBtnClick = () => {
        let newData = this.state.content.map(x => {
            if (x.checked) {
                return ({
                    mode                 : CONSTANTS.CS_ExploreCausesOfDeathMode,
                    firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                    finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                    hFirstYr             : CONSTANTS.CS_FirstYearHistoricalCountryData,
                    hFinalYr             : CONSTANTS.CS_FinalYearHistoricalCountryData,
                    possibleDisplayTypes : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
                    selectedDisplayType  : CONSTANTS.CS_ColumnChart,
                    editorID             : x['editorID'],
                    tabs                 : x['tabs'],
                    mstID                : x['mstID'],
                    title                : x['title'],
                    modvarTag0           : x['modvarTag'][0],
                    modvarTag1           : x['modvarTag'][1],
                });
            }
            return undefined;
        }).filter(x => x);

        this.props.onAddDisplay(newData);
        this.resetPanelToDefault();
    };

    resetPanelToDefault = () => {
        this.setState({
            content        : this.state.content.map(x => ({ ...x, checked : false })),
            buttonDisabled : true
        });
    };

    onCheckBoxChange = (checked, event, name, caption, mstID) => {
        let newContent = this.state.content.map(x => (x.mstID === mstID) ? {...x, checked : checked} : x);
        let checkedContent = newContent.filter(x => x.checked);

        this.setState({
            content        : newContent,
            buttonDisabled : checkedContent.length === 0
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            wrapper : {
                padding: '0 0 10px 0',
                // margin: '10px 15px 10px 0',
                margin: '0 15px 0 0',
            },
            invisible : {
                position: 'absolute',
                left: -9999,
                visibility: 'hidden',

                /* Edge doesn't obey the css visibility correctly, go figure. Here's a hack. */
                height: 0,
                top: 0,
                overflow: 'hidden',
            },
            actions : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
        };

        let wrapperStyles = 
            this.props.visible ?
                styles.wrapper :
                {...styles.wrapper, ...styles.invisible}

        return (
            <div style={wrapperStyles}>
                {
                    this.state.content.map(x => (
                        <OptionCheckBox
                            caption={RS(x['name'])}
                            value={x['checked']}
                            key={x['mstID']}
                            customProp={x['mstID']}
                            onChange={this.onCheckBoxChange}
                        />
                    ))
                }

                <div style={styles.actions}>
                    <CreateDisplayButton
                        disabled={this.state.buttonDisabled}
                        onClick={this.onShowDataBtnClick}
                        language={this.props.language}
                    />
                </div>
                
            </div>
        );
    }

}

export default CausesOfDeathOptions;