import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    CloneObj, lockPackRowCol, generatePackChart
} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";

export const CotrimoxazoleEditor = {
    Create : (CSModvars) => {
        let editorName = ''; // RS('GB_stCotrimoxazole');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable.Title = editorName;
        packTable.ID = CONSTANTS.CS_MstSubNatCotrimSheetID;
        let packChart = generatePackChart(packTable);
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);
        let Cotrim = getModvarByTag(CSModvarsClone, CONSTANTS.VW_TG_TCotrim)["value"];
        Cotrim[1] = pt.tableData.value[1][1];
        Cotrim[2] = pt.tableData.value[1][2];
        return (CSModvarsClone);
    },
};

const Setup = () => {
    let pt = getNewPT();

    const numRows = 2;
    const numCols = 3;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[0][1] = RS('GB_stNational');
    pt.tableData.value[0][2] = RS('GB_stSubnational');

    pt.tableData.value[1][0] = RS('GB_stPercentCotrim');

    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.Alignments[1][2] = CONSTANTS.GB_Right;

    pt.RDec[1][1] = 2;
    pt.RDec[1][2] = 2;

    pt = lockPackRowCol(pt, 1, 1, true);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let Cotrim = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TCotrim)["value"];
    pt.tableData.value[1][1] = Cotrim[1];
    pt.tableData.value[1][2] = Cotrim[2];
    return pt;
};

