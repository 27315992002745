import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSExpandMODrawer from "../../drawers/SelectInterventions/CSExpandMODrawer";

class CSExpandMOBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onOutcomeSwitchChange          : PropTypes.func,
        caption                        : PropTypes.string,
        zoomCaption                    : PropTypes.string,
        isDeskTop                      : PropTypes.bool,
        displayChart                   : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayNutritionCOD            : PropTypes.bool,
        displayBirthOutcomeCOD         : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        isExpandMODrawerClosed         : PropTypes.bool,
        language                       : PropTypes.number,
        onOpenHelpDialog               : PropTypes.func
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        onPageChange                   : () => console.log('onPageChange'),
        onOutcomeSwitchChange          : () => console.log("onOutcomeSwitchChange"),
        caption                        : '',
        zoomCaption                    : '',
        isDeskTop                      : true,
        displayChart                   : true,
        selectedCountry                : 'Afghanistan',
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayNutritionCOD            : true,
        displayBirthOutcomeCOD         : true,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        isExpandMODrawerClosed         : false,
        language                       : CONSTANTS.GB_English,
        onOpenHelpDialog               : () => console.log("onOpenHelpDialog")
    };

    state = {
        saveBtnDisabled          : true,
        openDrawer               : false,
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
        this.props.onStateChange ({
            isExpandMODrawerClosed : false,
        })
    };

    onCloseDrawerBtnClick = () => {
        this.setState({
            openDrawer : false,
            // saveBtnDisabled : true,
        });
        this.props.onStateChange ({
            isExpandMODrawerClosed : true,
        })
    };

    //==================================================================================================================
    //
    //                                              Functions
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            appBarTextStyle  : {
                color: '#cb6814',
                fontSize: '22px',
                fontWeight: 'bold',
                display: 'inline-block',
                marginLeft: '15px',
                marginTop: '16px',
                position: 'absolute',
                userSelect: 'none'
            },
            iconBtnStyle : {
                float: 'right',
                width: 20,
                height: 20,
                padding: 2,
                cursor: 'pointer',
                boxSizing: 'border-box',
                zIndex: 1000
            },

            iconStyle : {
                fontSize: '16px',
                color: "#000000"
            },

            extraBtnStyle : {
                marginTop: 9,
                float: 'right',
                marginRight: 15
            }
        };

        return (
            <CSBlueBox2
                caption          = {RS('GB_stExpandToSeeAllInterv')}
                captionStyle     = {{}}
                description      = ''
                onClick          = {this.onBlueBoxClick}
                isIconButton     = {true}
                iconBtnStyle     = {styles.iconBtnStyle}
                iconStyle        = {styles.iconStyle}
                editContent      = {
                    <TDrawerWithHeader
                        open                = {this.state.openDrawer}
                        onClose             = {this.onCloseDrawerBtnClick}
                        saveBtnCaption      = {RS('GB_stApply')}
                        showSaveBtn         = {false}
                        appBarText          = {RS('GB_stMissedOpportunities')}
                        appBarTextStyle     = {styles.appBarTextStyle}
                        isDeskTop           = {this.props.isDeskTop}
                        onOpenHelpDialog    = {this.props.onOpenHelpDialog}
                        helpFile            = {"missed-opportunities.htm"}
                        content             = {
                                                    <CSExpandMODrawer
                                                        onStateChange                  = {this.props.onStateChange}
                                                        onPageChange                   = {this.props.onPageChange}
                                                        onOutcomeSwitchChange          = {this.props.onOutcomeSwitchChange}
                                                        displayChart                   = {this.props.displayChart}
                                                        selectedCountry                = {this.props.selectedCountry}
                                                        displayMaternalCOD             = {this.props.displayMaternalCOD}
                                                        displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                                                        displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                                                        displayChildCOD                = {this.props.displayChildCOD}
                                                        displayNutritionCOD            = {this.props.displayNutritionCOD}
                                                        displayBirthOutcomeCOD         = {this.props.displayBirthOutcomeCOD}
                                                        displayCPR                     = {this.props.displayCPR}
                                                        deliveryPointsContent          = {this.props.deliveryPointsContent}
                                                        maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                                                        stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                                                        neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                                                        childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                                                        sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                                                        sortByInterventionCost         = {this.props.sortByInterventionCost}
                                                        viewInterventionCost           = {this.props.viewInterventionCost}
                                                        MOCountryData                  = {this.props.MOCountryData}
                                                        maternalIntervSelected         = {this.props.maternalIntervSelected}
                                                        stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                                                        neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                                                        childIntervSelected            = {this.props.childIntervSelected}
                                                        MOResultToDisplay              = {this.props.MOResultToDisplay}
                                                        language                       = {this.props.language}
                                                    />
                                               }
                    />
                }
            />

        );
    }

}

export default CSExpandMOBlueBox;