import * as React from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';

class TLabel extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption      : PropTypes.any,
        onClick      : PropTypes.func,
        style        : PropTypes.object,
        customProp   : PropTypes.any,
        component    : PropTypes.any,
    };

    static defaultProps = {
        caption      : 'Caption', 
        onClick      : () => console.log('onClick'),
        style        : {},
        customProp   : '',
        component    : undefined,
    };

    state = {
        itemIndex : 0
    }; 

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onClick = () => {
        this.props.onClick(this.props.caption, this.props.customProp);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        return (
            <Typography onClick={this.onClick} style={{...this.props.style}} component={this.props.component}>
                {this.props.caption}
            </Typography>
        );
    }
}

export default TLabel;