import React from 'react';
import PropTypes from "prop-types";
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PlayArrow from "@material-ui/icons/PlayArrow";
import Theme from "../../../../../../../app/Theme";

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minWidth: 0, // So the Typography noWrap works
    },
    toolbar: theme.mixins.toolbar,
    paper: {
        borderRight: "none"
    }

});

class TClosedButton extends React.PureComponent {

    static propTypes = {
        onClick : PropTypes.func,
    };

    static defaultProps = {
        onClick : () => console.log("onClick")
    };

    render () {
        const styles = {
            btn: {
                color: "#05225d",
                zIndex: 100,
                cursor: "pointer",
                marginTop: 30,
                padding: 0,
                // left: "calc(100% - 33px)",
                float: 'right',
                marginRight: '8px'
            },
            chevronClosed: {
                fontSize:25,
                fontWeight:"bold"
            },
        };

        return (
            <IconButton
                style={styles.btn}
                onClick={this.props.onClick}
            >
                <PlayArrow
                    style={styles.chevronClosed}
                />
            </IconButton>
        );
    }
}

class TOpenedButton extends React.PureComponent {

    static propTypes = {
        onClick : PropTypes.func,
    };

    static defaultProps = {
        onClick : () => console.log("onClick")
    };

    render () {
        const styles = {
            btn: {
                color: "#05225d",
                zIndex: 100,
                cursor: "pointer",
                marginTop: 30,
                padding: 0,
                // left: "calc(100% - 33px)",
                float: 'right',
                marginRight: '8px'
            },
            chevron: {
                fontSize: 25,
                fontWeight: "bold",
                transform: "rotate(180deg)"
            },
        };

        return (
            <IconButton
                style={styles.btn}
                onClick={this.props.onClick}
            >
                <PlayArrow
                    style={styles.chevron}
                />
            </IconButton>
        );
    }
}

class TAppSideBarNew extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        open             : PropTypes.bool,
        onToggle         : PropTypes.func,
        width            : PropTypes.number,
        allowPlaceholder : PropTypes.bool,
        classes          : PropTypes.object,
        children         : PropTypes.any,
    };

    static defaultProps = {
        open             : true,
        onToggle         : () => console.log("onToggle"),
        width            : 325,
        allowPlaceholder : true,
        children         : null,
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        const drawerWidth = this.props.width;

        const styles = {
            drawer : {
                zIndex: Theme.zIndex.drawer, 
                width: drawerWidth, 
                border: 'none',
                ovrflow: 'hidden',
            },
            outerDiv : {
                height: '100%', 
                width: drawerWidth + 10, 
                overflowY : 'auto',
                overflowX : 'hidden',
            },
            sidebarClosed: {
                height: "100%",
                width: "40px",
            },
            innerContent : {
                height: '100%', 
                width: drawerWidth, 
                borderRight: 'none', 
                ...Theme.toolsSideBar,
            }
        };

        return (
            <React.Fragment>
                {
                    this.props.allowPlaceholder ? 
                        <Drawer
                            variant="persistent"
                            open={!this.props.open}
                            style={styles.drawer}
                        >
                            <div className={this.props.classes.toolbar} />

                            <div style={styles.sidebarClosed}>
                                <TClosedButton onClick={this.props.onToggle} />
                            </div>
                        </Drawer> 
                        : null
                }
               
                <Drawer
                    variant="persistent"
                    open={this.props.open}
                    style={styles.drawer}
                >
                    <div className={this.props.classes.toolbar} />
    
                    <div style={styles.outerDiv}>
                        <div style={styles.innerContent}>
                            
                            <TOpenedButton onClick={this.props.onToggle} />
                            
                            {
                                (this.props.children) ?
                                    this.props.children :
                                    <div>No children given</div>
                            }
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        );

    }
}

export default withStyles(styles)(TAppSideBarNew);