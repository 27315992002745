import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import Icon from '@material-ui/core/Icon';
import TCheckBox from "@common/components/TCheckBox";
import CONSTANTS from "utilities/CS/CSConst";
import scatterPlot from "../../../../../../img/scatter-plot.jpg";
import scatterPlotHighlight from "../../../../../../img/scatter-plot-highlight.jpg";
// import multiGraphIcon from "../../../../../img/exploreData/multiGraphGray.svg"
import multiGraphIcon from "../../../../../../img/exploreData/multiGraphGray.png"
import multiGraphCheckedIcon from "../../../../../../img/exploreData/multiGraphSelected.png"
// import multiGraphCheckedIcon from "../../../../../img/exploreData/multiGraph.svg"
 
class ShowDataDisplayTypes extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onChangeDisplay      : PropTypes.func,
        selectedDisplayType  : PropTypes.string,
        possibleDisplayTypes : PropTypes.array,
        itemKey              : PropTypes.number,
    };

    static defaultProps = {
        onChangeDisplay      : () => console.log('ShowDataDisplayTypes onChangeDisplay'),
        selectedDisplayType  : CONSTANTS.CS_ColumnChart,
        possibleDisplayTypes : [],
        itemKey              : 0,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) || 
            (JSON.stringify(this.state) !== JSON.stringify(nextState))
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderScatterChartOption = () => {
        return (
            <TCheckBox
                caption={''}
                key={'scatter'}
                // icon={<Icon style={{width: '24px', fontSize: '22px'}} className='fa fa-line-chart'/>}
                // checkedIcon={<Icon style={{width: '24px', fontSize: '22px'}} className='fa fa-line-chart'/>}
                icon={<img alt={"scatter plot icon"} style={{height: '30px'}} src={scatterPlot}/>}
                checkedIcon={<img alt={"highlighted scatter plot icon"} style={{height: '30px'}} src={scatterPlotHighlight}/>}
                color={'primary'}
                style={{display: 'inline'}}
                customProp={CONSTANTS.CS_ScatterChart}
                customProp2={this.props.itemKey}
                value={this.props.selectedDisplayType === CONSTANTS.CS_ScatterChart}
                isIcon={true}
                onClick={(checked, event, name, caption, displayType, itemKey) => this.props.onChangeDisplay(itemKey, displayType)}
            />
        )
    };

    renderColumnChartOption = () => {
        return (
            <TCheckBox
                caption={''}
                key={'column'}
                icon={<Icon style={{width: '24px', fontSize: '22px'}} className='fa fa-bar-chart'/>}
                checkedIcon={<Icon style={{width: '24px', fontSize: '22px'}} className='fa fa-bar-chart'/>}
                color={'primary'}
                style={{display: 'inline'}}
                customProp={CONSTANTS.CS_ColumnChart}
                customProp2={this.props.itemKey}
                value={this.props.selectedDisplayType === CONSTANTS.CS_ColumnChart}
                isIcon={true}
                onClick={(checked, event, name, caption, displayType, itemKey) => this.props.onChangeDisplay(itemKey, displayType)}
            />
        )
    };

    renderBarChartOption = () => {
        return (
            <TCheckBox
                caption={''}
                key={'bar'}
                icon={<Icon style={{width: '24px', fontSize: '22px', transform: 'rotate(90deg) scaleX(-1)'}} className='fa fa-bar-chart'/>}
                checkedIcon={<Icon style={{width: '24px', fontSize: '22px', transform: 'rotate(90deg) scaleX(-1)', color:'primary'}} className='fa fa-bar-chart'/>}
                color={'primary'}
                style={{display: 'inline'}}
                customProp={CONSTANTS.CS_BarChart}
                customProp2={this.props.itemKey}
                value={this.props.selectedDisplayType === CONSTANTS.CS_BarChart}
                isIcon={true}
                onClick={(checked, event, name, caption, displayType, itemKey) => this.props.onChangeDisplay(itemKey, displayType)}
            />
        )
    };

    renderTableChartOption = () => {
        return (
            <TCheckBox
                caption={''}
                key={'table'}
                icon={<Icon style={{width: '24px', fontSize: '22px'}} className='fa fa-table'/>}
                checkedIcon={<Icon style={{width: '24px', fontSize: '22px'}} className='fa fa-table'/>}
                color={'primary'}
                style={{display: 'inline'}}
                customProp={CONSTANTS.CS_TableChart}
                customProp2={this.props.itemKey}
                value={this.props.selectedDisplayType === CONSTANTS.CS_TableChart}
                isIcon={true}
                onClick={(checked, event, name, caption, displayType, itemKey) => this.props.onChangeDisplay(itemKey, displayType)}
            />
        )
    };

    renderMultiChartOption = () => {
        return (
            <TCheckBox
                caption={''}
                key={'multi'}
                icon={<img style={{width: '24px'}} src={multiGraphIcon} alt='' />}
                checkedIcon={<img style={{width: '24px'}} src={multiGraphCheckedIcon} alt='' />}
                color={'primary'}
                style={{display: 'inline'}}
                customProp={CONSTANTS.CS_MultiChart}
                customProp2={this.props.itemKey}
                value={this.props.selectedDisplayType === CONSTANTS.CS_MultiChart}
                isIcon={true}
                onClick={(checked, event, name, caption, displayType, itemKey) => this.props.onChangeDisplay(itemKey, displayType)}
            />
        )
    };

    render() {
        return (
            <div style={{height: '26px', display: "inline-block", verticalAlign: "top"}}>
                { (this.props.possibleDisplayTypes.includes(CONSTANTS.CS_ScatterChart)) ? this.renderScatterChartOption() : null }
                { (this.props.possibleDisplayTypes.includes(CONSTANTS.CS_ColumnChart))  ? this.renderColumnChartOption()  : null }
                { (this.props.possibleDisplayTypes.includes(CONSTANTS.CS_BarChart))     ? this.renderBarChartOption()     : null }
                { (this.props.possibleDisplayTypes.includes(CONSTANTS.CS_MultiChart))   ? this.renderMultiChartOption()   : null }
                { (this.props.possibleDisplayTypes.includes(CONSTANTS.CS_TableChart))   ? this.renderTableChartOption()   : null }
            </div>
        );
    }
}

export default ShowDataDisplayTypes;