/* eslint no-unused-vars: 0 */  // --> OFF

import React from 'react';
import ReactDOM from 'react-dom';
import './app/index.css';
import registerServiceWorker from './registerServiceWorker';
import Root from './app/Root'
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
    "Avenir_Health_Avenir_Health_Multiple_Applications_2_Devs__28_January_2021_[v2]_MTYxMTc5MjAwMDAwMA==62ff0b74c26c807c9902b7aeb33d19e6"
);

ReactDOM.render(<Root />, document.getElementById('root'));

registerServiceWorker();
