import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    addRowToPackTable,
    lockPackTable,
    lockPackRowCol,
    indentPackTable,
    addMergedCellsToArray,
    convertNegativeOnesToBlanks,
    addComment,
} from "utilities/GB/GBUtil";

import { getEffectivenessOfInterventionsPackChart } from "../../../../data/CSUtil";
import { CSGetLiSTOnlineGroupStr, CSGetGroupMstIDFromLiSTOnlineID } from "utilities/CS/CSUtil";
import { CSGetMstIDName, getSBCODName, DirectEntryMstIDSet } from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from "utilities/GB/GBConst";

export const SBEfficacyEditor = {
    Create: (IVInfo, CSModvars, language, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv = true) => {
        let editorName = RS("GB_stEffIntSB");
        let packTable = Setup(IVInfo, CSModvars, language, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable.Title = editorName;
        let packChart = getEffectivenessOfInterventionsPackChart(packTable, 0);
        packTable.EditorCat = CONSTANTS.GB_Effectiveness;
        return [packChart, packTable];
    },

    GetSSData: (pt, CSModvars) => {
        let changedModvars = [];
        let SBEfficacyMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_SBEfficacy_MV3);

        for (let row = 2; row < pt.RowIds.length; row++) {
            let IntervRec = pt.IntervRecords[row];
            if (IntervRec !== null) {
                let d = pt.RowIds[row];
                SBEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy][IntervRec["CurrID"]][d] =
                    pt.tableData.value[row][1];
                SBEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][IntervRec["CurrID"]][d] =
                    pt.tableData.value[row][2];
                SBEfficacyMV["source"][d][IntervRec["CurrID"]] = pt.Source[pt.SourceMap[row]];
            }
        }

        changedModvars.push(SBEfficacyMV);

        return changedModvars;
    },
};

const IntervHasData = (SBEfficacy, d, IntervRec) => {
    let result = false;
    /* Set to true if non zero efficacy value is found */
    if (
        SBEfficacy[CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy][IntervRec["CurrID"]][d] > 0 ||
        SBEfficacy[CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy][IntervRec["CurrID"]][d] !==
            SBEfficacy[CONSTANTS.CS_Default][CONSTANTS.CS_Efficacy][IntervRec["CurrID"]][d]
    ) {
        result = true;
    }
    return result;
};

const IntervGroupHasData = (SBEfficacy, d, IVGroupSet) => {
    let result = false;
    IVGroupSet.forEach(function (IntervRec) {
        result = result || IntervHasData(SBEfficacy, d, IntervRec);
    });
    return result;
};

const CODHasData = (SBEfficacy, d, IVGroupSets) => {
    let result = false;
    for (let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
        for (let i = 0; i < IVGroupSets[g].length; i++) {
            let IntervRec = IVGroupSets[g][i];
            result = result || IntervHasData(SBEfficacy, d, IntervRec);
        }
    }
    return result;
};

const Setup = (IVInfo, CSModvars, language, CauseOfDeath, ShowAllItems, ShowNonSelectedInterv) => {
    const selectedIVSet = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SelectedIVSet_MV);
    let SBEfficacy = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SBEfficacy_MV3)["value"];

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    const numCols = 3;
    const numRows = 3;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    pt.tableData.value[0][1] = RS("GB_stStillbirth");
    pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 0, 1, 1, 2);
    pt.tableData.value[1][1] = RS("GB_stEffectiveness");
    pt.tableData.value[1][2] = RS("CS_stAffFract");

    let diseaseSet = [];

    if (CauseOfDeath > 0) {
        diseaseSet = [CauseOfDeath];
    } else {
        for (let d = CONSTANTS.CS_SB_FirstSBCause; d <= CONSTANTS.CS_SB_MaxCauses; d++) {
            diseaseSet.push(d);
        }
    }

    let IVGroupSets = new Array(CONSTANTS.CS_LiSTOnline_MaxGroups);

    for (let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
        IVGroupSets[g] = [];
        if (![CONSTANTS.CS_LiSTOnline_Nutrition].includes(g)) {
            let groupMstID = CSGetGroupMstIDFromLiSTOnlineID(g);
            for (let i = 0; i < IVInfo.length; i++) {
                let IntervRec = IVInfo[i];
                if (selectedIVSet["value"]["intArray"].includes(IntervRec.MstID) || ShowNonSelectedInterv) {
                    if (IntervRec[CONSTANTS.CS_StillbirthEff_ED]) {
                        if (IntervRec["GroupMstID"] === groupMstID) {
                            if (!DirectEntryMstIDSet().includes(IntervRec["MstID"])) {
                                IVGroupSets[g].push(IntervRec);
                            }
                        }
                    }
                }
            }
        }
    }

    let row = 2;

    pt.numSources = 0;

    diseaseSet.forEach(function (d) {
        if (ShowAllItems || CODHasData(SBEfficacy, d, IVGroupSets)) {
            pt = addRowToPackTable(pt);
            let SBCODName = getSBCODName(d);
            pt.tableData.value[row][0] = SBCODName;
            pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
            pt = lockPackTable(pt, row, true);
            row++;
            for (let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
                if (IVGroupSets[g].length !== 0) {
                    if (ShowAllItems || IntervGroupHasData(SBEfficacy, d, IVGroupSets[g])) {
                        pt = addRowToPackTable(pt);
                        pt.tableData.value[row][0] = CSGetLiSTOnlineGroupStr(g, true);
                        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
                        pt = lockPackTable(pt, row, true);
                        pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
                        row++;
                        for (let i = 0; i < IVGroupSets[g].length; i++) {
                            let IntervRec = IVGroupSets[g][i];
                            if (ShowAllItems || IntervHasData(SBEfficacy, d, IntervRec)) {
                                pt.hasData = true;
                                pt = addRowToPackTable(pt);

                                let s;
                                if (IntervRec["MstID"] === CONSTANTS.IV_ChangesInBF) {
                                    s = RS("GB_stBFPromo");
                                } else {
                                    s = CSGetMstIDName(CSModvars, language, IntervRec);
                                }

                                pt.tableData.value[row][0] = s;
                                pt.IntervRecords[row] = IntervRec;
                                pt.RowIds[row] = d;
                                let indent = GB_CONSTANTS.GB_ED_Indent2;
                                if (IntervRec["MstID"] === CONSTANTS.IV_WaterConnectionHome) {
                                    indent += GB_CONSTANTS.GB_ED_Indent1;
                                }
                                pt = indentPackTable(pt, row, true, indent);
                                for (let c = 1; c <= numCols; c++) {
                                    pt.RDec[row][c] = 3;
                                }
                                if (
                                    [CONSTANTS.IV_CalciumSupp, CONSTANTS.IV_FolicAcidSupp].includes(IntervRec["MstID"])
                                ) {
                                    pt = lockPackRowCol(pt, row, 2, true);
                                    if (IntervRec["MstID"] === CONSTANTS.IV_CalciumSupp) {
                                        pt = addComment(pt, row, 2, RS("GB_stCalcDef"));
                                    }
                                    if (IntervRec["MstID"] === CONSTANTS.IV_FolicAcidSupp) {
                                        switch (d) {
                                            case CONSTANTS.CS_SB_Antepartum:
                                                pt = addComment(pt, row, 2, RS("GB_stFolateDefSBAnte"));
                                                break;
                                            case CONSTANTS.CS_SB_Intrapartum:
                                                pt = addComment(pt, row, 2, RS("GB_stFolateDefSBIntra"));
                                                break;
                                        }
                                    }
                                }

                                pt.numSources++;
                                pt.SourceMap[row] = pt.numSources;
                                pt.SourceTitle[pt.numSources] = SBCODName + " - " + pt.tableData.value[row][0];

                                row++;
                            }
                        }
                    }
                }
            }
        }
    });

    // pt = setSourceArrLength(pt, pt.numSources);

    pt.GBRowCount = row;
    pt = resizePackTable(pt, row, numCols);

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for (let row = 0; row < pt.GBRowCount; row++) {
        for (let col = 0; col < pt.GBColCount; col++) {
            if (typeof pt.tableData.value[row][col] === "number") {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.MinAllowedVal[row][col] = 0;
                pt.MaxAllowedVal[row][col] = 1;
            }
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let SBEfficacy = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SBEfficacy_MV3)["value"][CONSTANTS.CS_Data];
    let SBEfficacySrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SBEfficacy_MV3)["source"];
    for (let row = 2; row < pt.RowIds.length; row++) {
        let IntervRec = pt.IntervRecords[row];
        if (IntervRec !== null) {
            let d = pt.RowIds[row];
            pt.tableData.value[row][1] = SBEfficacy[CONSTANTS.CS_Efficacy][IntervRec["CurrID"]][d];
            pt.tableData.value[row][2] = SBEfficacy[CONSTANTS.CS_AffecFract][IntervRec["CurrID"]][d];
            pt.Source[pt.SourceMap[row]] = SBEfficacySrc[d][IntervRec["CurrID"]];
        } else {
            pt.tableData.value[row][1] = "";
            pt.tableData.value[row][2] = "";
        }
    }
    return pt;
};
