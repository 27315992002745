import React from 'react';
//import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSFamPlanHIVDrawer from "../../drawers/Coverage/CSFamPlanHIVDrawer";
import {getModvarByTag} from "../../../../utilities/GB/GBUtil";
import CONSTANTS from "../../../../utilities/CS/CSConst";

class CSFamPlanHIVBlueBox extends React.Component {
    static propTypes = {
        //
    };

    static defaultProps = {
        //
    };

    constructor(props) {
        super(props);

        this.state = {
            openDrawer: false
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer: true
        });
    };

    onCloseDrawer = () => {
        this.setState({
            openDrawer: false
        });
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        return (
            <React.Fragment>
                <CSBlueBox2
                    caption      = {RS('GB_stFamPlanHIV')}
                    captionStyle = {this.props.captionStyle}
                    description  = {this.props.description}
                    onClick      = {this.onBlueBoxClick}
                />

                {
                    (this.state.openDrawer) ?
                        <CSFamPlanHIVDrawer
                            onClose                = {this.onCloseDrawer}
                            CSModvars              = {this.props.CSModvars}
                            firstYear              = {this.props.firstYear}
                            finalYear              = {getModvarByTag(this.props.CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"]}
                            projectionName         = {this.props.projectionName}
                            language               = {this.props.language}
                            IVInfo                 = {this.props.IVInfo}
                            onOpenHelpDialog       = {this.props.onOpenHelpDialog}
                            onCloseDrawer          = {this.onCloseDrawer}
                            onStateChange          = {this.props.onStateChange}
                            HIVAdolIntervsSelected = {this.props.HIVAdolIntervsSelected}
                        /> : null
                }
            </React.Fragment>
        );
    }
}

export default CSFamPlanHIVBlueBox;