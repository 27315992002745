import React from 'react';
import PropTypes from 'prop-types';
import * as colors from "@material-ui/core/colors";
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import TButton from "@common/components/TButton";
import TEdit from "@common/components/TEdit";
import TLabel from "@common/components/TLabel";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";

class LoginBox extends React.Component {

    static propTypes = {
        onLogin             : PropTypes.func.isRequired,
        onPageChange        : PropTypes.func.isRequired,
        Theme               : PropTypes.any.isRequired,
    };

    state = {
        userNameValue       : localStorage.getItem("TOOLS_REMEMBER_ME") ? localStorage.getItem("TOOLS_USER") : "",
        passwordValue       : "",
        rememberMe          : localStorage.getItem("TOOLS_REMEMBER_ME") ? localStorage.getItem("TOOLS_REMEMBER_ME") === "true" : false,
        hasError            : false,
        errorMessage        : "",
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onUserNameChange = (value) => {
        this.setState({
            userNameValue : value,
        });
    };

    onPasswordChange = (value) => {
        this.setState({
            passwordValue : value,
        });
    };

    onLoginBtnClick = () => {
        const { userNameValue, passwordValue, rememberMe } = this.state;
        const { onLogin, onPageChange } = this.props;

        this.setState({
            hasError : false,
            errorMessage: "",
        }, () => {
            switch (true) {
                case (this.state.userNameValue === '') : 
                    this.setState({
                        hasError : true,
                        errorMessage : RS('GB_stEnterEmail')
                    });
                    break;
    
                case (this.state.passwordValue === '') :
                    this.setState({
                        hasError : true,
                        errorMessage : RS('GB_stEnterPassword')
                    });
                    break;
    
                default : 
                    onLogin(userNameValue, passwordValue, rememberMe, () => {
                        onPageChange("ProjectionsPage");
                    }, (msg) => {
                        this.setState({
                            hasError : true,
                            errorMessage : msg
                        });
                    });
            }
        });
    };

    onForgotPasswordClick = () => {
        window.open("https://authuser.avenirhealth.org/?sv=LOL&md=fg", "_blank");
    };

    onCreateAccountBtnClick = () => {
        window.open("https://authuser.avenirhealth.org/?sv=LOL&md=new", "_blank");
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const { Theme } = this.props;
        
        let styles = {
            paperBox : {
                width: 350,
                margin: '0 auto',
                marginBottom: '10px',
                boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 5px 0px, rgba(0, 0, 0, 0.5) 0px 1px 0px 0px, rgba(0, 0, 0, 0.5) 0px 0px 3px 0px",
            },
            dialogTitle : {
                //minWidth: '400px',
                //maxWidth: '800px',
                borderBottom: '1px solid lightgray',
                userSelect: 'none',
                padding: '10px 24px',
                //minHeight: '57px',
            },
            title : {
                fontSize: '20px',
                color: Theme.palette.primary.main,//'#cb6814',
                fontWeight: 'bold',
                padding: '5px 50px 0 0px',
            },
            loginBox : {
                width: 300,
                margin: '0 auto',
                marginTop: '15px'
            },
            edit : {
                marginTop: '10px', 
                width: '100%'
            },
            edit2 : {
                width: '100%'
            },
            forgotPasswordStyle : {
                fontSize: 12,
                textAlign: 'right',
                paddingRight: 0,
                color: colors.grey[500],
                float: 'right',
                //marginTop: -10,
                marginBottom: 10,
                textTransform : 'uppercase',
                cursor: 'pointer'
            },
            raisedButton : {
                marginTop: 20,
                marginBottom: 30,
                marginRight: 20,
                width: '100%',
                fontSize: '16px'
            },
            containerStyle : {
                display: 'block'
            },
            errorBox : {
                background: "#ffcdd2",
                padding: 10,
                borderRadius: 2,
                color: "#b12020"
            }
        };

        return (
            <Paper style={styles.paperBox}>
                <DialogTitle id="alert-dialog-title" style={styles.dialogTitle}>
                    <div style={styles.title}>
                        {RS('GB_stLogInAccount')}
                    </div>
                </DialogTitle>

                <div style={styles.loginBox}>
                    {
                        (this.state.hasError) ?
                            <div style={styles.errorBox}>
                                {this.state.errorMessage}
                            </div>
                            :
                            null
                    }
                    
                    <TEdit
                        name="UsernameEdit"
                        caption={RS('GB_stEmail')}
                        value={this.state.userNameValue}
                        onChange={this.onUserNameChange}
                        style={styles.edit}
                        type={"email"}
                    />

                    <TEdit
                        name="PasswordEdit"
                        caption={RS('GB_stPassword')}
                        value={this.state.passwordValue}
                        onChange={this.onPasswordChange}
                        type="Password"
                        style={styles.edit2}
                    />

                    <TCheckBox
                        caption={RS("GB_stRememberMe")}
                        value={this.state.rememberMe}
                        labelStyle={{marginRight:-10}}
                        //boxStyle={{paddingRight:3}}
                        onClick={() => this.setState({rememberMe: !this.state.rememberMe})}
                    />

                    <TLabel
                        caption={RS('GB_stForgotPassword')}
                        onClick={this.onForgotPasswordClick}
                        style={styles.forgotPasswordStyle}
                    />

                    <TButton
                        caption={RS('GB_stLogIn')}
                        color={"primary"}
                        onClick={this.onLoginBtnClick}
                        style={styles.raisedButton}
                        containerStyle={styles.containerStyle}
                    />

                    <Divider />

                    <TButton
                        caption={this.props.isGuest ? RS('GB_stCreateAccount') : RS('GB_stManageMyAccount')}
                        color={"secondary"}
                        onClick={this.onCreateAccountBtnClick}
                        style={styles.raisedButton}
                        containerStyle={styles.containerStyle}
                    />
                </div>
            </Paper>
        );
    }
};

export default LoginBox;