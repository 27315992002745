import React from 'react';
import PropTypes from "prop-types";
import TRangeSlider from "@common/components/TRangeSlider";
import TComboBox from "@common/components/TComboBox";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {getAgeCohortName, getSexName, getChildCODName, getSBCODName, getMatCODName, CSGetDiscountRateName} from "utilities/CS/CSDataUtil";
import Theme from "../../../app/Theme";

class CSConfigDrawer2 extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onYearChange             : PropTypes.func,
        onAgeCohortChange        : PropTypes.func,
        onSexChange              : PropTypes.func,
        onCODChange              : PropTypes.func,
        onDiscountRateChange     : PropTypes.func,
        onShowIVsChange          : PropTypes.func,
        onDisplayEndpointsChange : PropTypes.func,
        onDisplayCoverageChange  : PropTypes.func,
        onRetroYearsChange       : PropTypes.func,
        onChangeCoverageDisplay  : PropTypes.func,

        firstYear                : PropTypes.number,
        finalYear                : PropTypes.number,
        yearRange                : PropTypes.object,
        ageIdx                   : PropTypes.number,
        sexIdx                   : PropTypes.number,
        discountRateIdx          : PropTypes.number,
        both                     : PropTypes.bool,
        male                     : PropTypes.bool,
        female                   : PropTypes.bool,
        singleAge                : PropTypes.bool,
        fiveYearAge              : PropTypes.bool,
        codIdx                   : PropTypes.number,
        showIVs                  : PropTypes.bool,
        displayEndpoints         : PropTypes.bool,
        displayCoverage          : PropTypes.bool,
        retroYears               : PropTypes.bool,
        coverageDisplay          : PropTypes.string,

        chartOptions             : PropTypes.object,
    };

    static defaultProps = {
        onYearChange             : () => console.log("onYearChange"),
        onAgeCohortChange        : () => console.log("onAgeCohortChange"),
        onSexChange              : () => console.log("onSexChange"),
        onCODChange              : () => console.log("onCODChange"),
        onDiscountRateChange     : () => console.log("onDiscountRateChange"),
        onShowIVsChange          : () => console.log("onShowIVsChange"),
        onDisplayEndpointsChange : () => console.log("onDisplayEndpointsChange"),
        onDisplayCoverageChange  : () => console.log("onDisplayCoverageChange"),
        onRetroYearsChange       : () => console.log("onRetroYearsChange"),
        onChangeCoverageDisplay  : () => console.log("onChangeCoverageDisplay"),

        firstYear                : new Date().getFullYear(),
        finalYear                : CONSTANTS.CS_DefaultFinalYear,
        yearRange                : {},
        ageIdx                   : -1,
        sexIdx                   : -1,
        discountRateIdx          : -1,
        both                     : false,
        male                     : false,
        female                   : false,
        singleAge                : false,
        fiveYearAge              : false,
        codIdx                   : -1,
        showIVs                  : false,
        displayEndpoints         : false,
        displayCoverage          : false,
        retroYears               : false,
        coverageDisplay          : CONSTANTS.CS_TableChart,

        chartOptions             : {},
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState)) 
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                                 Functions
    //
    //==================================================================================================================

    onChangeCoverageDisplay = (value) => {
        if (value === 0) {
            this.props.onChangeCoverageDisplay(CONSTANTS.CS_TableChart);
        } else if (value === 1) {
            this.props.onChangeCoverageDisplay(CONSTANTS.CS_LineChart);
        }
    };

    onRetroYearsChange = (value) => {
        this.props.onRetroYearsChange(value === 1);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderYears = () => {
        return (
            <div style={{marginLeft : '25px'}}>
				<h3 style={{userSelect: 'none', fontSize: 16, fontWeight: "normal"}}>{RS('GB_stSelectYearsToDisplay')}</h3>

                <div style={{marginBottom: '25px', width: '600px', maxWidth: "100%"}}>
                    <TRangeSlider 
                        style            = {{marginBottom: '20px', marginRight: "25px", color: 'red'}}
                        minValue         = {this.props.firstYear}
                        maxValue         = {this.props.finalYear}
                        minTextLabel     = {RS('GB_stFirstYear')}
                        maxTextLabel     = {RS('GB_stFinalYear')}
                        value            = {this.props.yearRange}
                        onChange         = {this.props.onYearChange}
                        onChangeComplete = {() => {}}
                    />
                </div>
            </div>
        );
    };

    renderAgeCohort = () => {
        const styles = {
            comboBox: {
                marginTop: 20,
                marginRight: 25,
                marginBottom: 35,
                paddingLeft: 0,
                width: "100%",
                minWidth: 275,
                display: 'inline-block'
            }
        };

        let cohortNames = [];
        let ageIdx = this.props.ageIdx;
        if (Array.isArray(this.props.chartOptions['age'])) {
            for (let i = 0; i < this.props.chartOptions['age'].length; i++) {
                cohortNames.push(getAgeCohortName(this.props.chartOptions['age'][i], this.props.chartOptions['age'].length > 3))
            }
        }
        else{
            cohortNames.push('Single age');
            cohortNames.push('Five year age');

            if (this.props.both){
                ageIdx = 0;
            }
            else if (this.props.male){
                ageIdx = 1;
            }
        }

        return (
            <div style={{marginLeft : '25px'}}>
                {/*<h3 style={{userSelect: 'none', marginBottom : 0, fontSize: 16, fontWeight: "normal"}}>{RS('GB_stSelectAgeCohort')}</h3>*/}

                <div style={{marginBottom: 0, width: '600px', maxWidth: "100%"}}>
                    <TComboBox
                        caption      = {RS('GB_stSelAgeCohort')}
                        itemIndex    = {ageIdx}
                        onChange     = {this.props.onAgeCohortChange}
                        style        = {styles.comboBox}
                        captionStyle = {{color: Theme.palette.secondary.main}}
                        items        = {cohortNames}
                    />
                </div>
            </div>
        );
    };

    renderSex = () => {
        const styles = {
            comboBox: {
                marginTop: 20,
                marginRight: 25,
                marginBottom: 35,
                paddingLeft: 0,
                width: "100%",
                minWidth: 275,
                display: 'inline-block'
            }
        };

        let sexNames = [];
        let sexIdx = this.props.sexIdx;
        if (Array.isArray(this.props.chartOptions['sex'])) {
            for(let i = 0; i < this.props.chartOptions['sex'].length; i++) {
              sexNames.push(getSexName(this.props.chartOptions['sex'][i]))
            }
        }
        else{
            sexNames.push(getSexName(CONSTANTS.DP_BothSexes));
            sexNames.push(getSexName(CONSTANTS.DP_Male));
            sexNames.push(getSexName(CONSTANTS.DP_Female));

            if (this.props.both){
                sexIdx = 0;
            }
            else if (this.props.male){
                sexIdx = 1;
            }
            else if (this.props.female){
                sexIdx = 2;
            }
        }

        return (
            <div style={{marginLeft : '25px'}}>
                {/*<h3 style={{userSelect: 'none', marginBottom : 0, fontSize: 16, fontWeight: "normal"}}>{RS('GB_stSelectSex')}</h3>*/}

                <div style={{marginBottom: 0, width: '600px', maxWidth: "100%"}}>

                    <TComboBox
                        caption      = {RS('GB_stSelectSex')}
                        itemIndex    = {sexIdx}
                        onChange     = {this.props.onSexChange}
                        style        = {styles.comboBox}
                        captionStyle = {{color: Theme.palette.secondary.main}}
                        items        = {sexNames}
                    />
                </div>
            </div>
        );
    };

    renderDiscountRate = () => {
        const styles = {
            comboBox: {
                marginTop: 20,
                marginRight: 25,
                marginBottom: 35,
                paddingLeft: 0,
                width: "100%",
                minWidth: 275,
                display: 'inline-block'
            }
        };

        let discountRates = [];
        for (let d = 0; d < this.props.chartOptions['discountRates'].length; d++){
            discountRates.push(CSGetDiscountRateName(this.props.chartOptions['discountRates'][d]));
        }

        return (
            <div style={{marginLeft : '25px'}}>
                {/*<h3 style={{userSelect: 'none', marginBottom : 0, fontSize: 16, fontWeight: "normal"}}>{RS('GB_stDiscountRate')}</h3>*/}

                <div style={{marginBottom: 0, width: '600px', maxWidth: "100%"}}>

                    <TComboBox
                        caption      = {RS('GB_stDiscountRate')}
                        itemIndex    = {this.props.discountRateIdx}
                        onChange     = {this.props.onDiscountRateChange}
                        style        = {styles.comboBox}
                        captionStyle = {{color: Theme.palette.secondary.main}}
                        items        = {discountRates}
                    >
                    </TComboBox>
                </div>
            </div>
        );
    };

    renderCauseOfDeath = () => {
        const styles = {
            comboBox: {
                marginTop: 20,
                marginRight: 25,
                marginBottom: 35,
                paddingLeft: 0,
                width: "100%",
                minWidth: 275,
                display: 'inline-block'
            }
        };

        let codNames = [];

        switch (this.props.chartOptions['codCat']) {
            case CONSTANTS.CS_MstMaternalCODCat :  {
                for(let m = 0; m < this.props.chartOptions['cod'].length; m++) {
                    codNames.push(getMatCODName(this.props.chartOptions['cod'][m]));
                }
                break;
            }

            case CONSTANTS.CS_MstStillbirthCODCat : {
                for(let s = 0; s < this.props.chartOptions['cod'].length; s++) {
                    codNames.push(getSBCODName(this.props.chartOptions['cod'][s]));
                }
                break;
            }

            case CONSTANTS.CS_MstNeonatalCODCat : {
                for(let n = 0; n < this.props.chartOptions['cod'].length; n++) {
                    codNames.push(getChildCODName(this.props.chartOptions['cod'][n]));
                }
                break;
            }

            case CONSTANTS.CS_MstChildCODCat : {
                switch (this.props.ageIdx) {
                    case CONSTANTS.CS_AgeSummary : {
                        for(let cAll = 0; cAll < this.props.chartOptions['cod'].length; cAll++) {
                            codNames.push(getChildCODName(this.props.chartOptions['cod'][cAll]));
                        }
                        break;
                    }

                    case CONSTANTS.CS_0t1months : {
                        for(let cnd = CONSTANTS.CS_FirstNNDeath; cnd <= CONSTANTS.CS_FinalStdNNDeath; cnd++) {
                            codNames.push(getChildCODName(this.props.chartOptions['cod'][cnd - 1])); //1 is offset needed for place in cod array
                        }
                        break;
                    }

                    case CONSTANTS.CS_1t59months : {
                        for(let cpd = CONSTANTS.CS_FirstPNNDeath; cpd <= CONSTANTS.CS_FinalStdPNNDeath; cpd++) {
                            codNames.push(getChildCODName(this.props.chartOptions['cod'][cpd - 5])); //5 is offset needed for place in cod array
                        }
                        break;
                    }

                    default : break;
                }
                break;
            }

            default : {
                break;
            }

        }
        return (
            <div style={{marginLeft : '25px'}}>
                {/*<h3 style={{userSelect: 'none', marginBottom : 0, fontSize: 16, fontWeight: "normal"}}>{RS('GB_stSelectCOD')}</h3>*/}

                <div style={{marginBottom: 0, width: '600px', maxWidth: "100%"}}>

                    <TComboBox
                        caption      = {RS('GB_stSelCOD')}//additional child lives saved by intervention by cause
                        itemIndex    = {this.props.codIdx}
                        onChange     = {this.props.onCODChange}
                        style        = {styles.comboBox}
                        captionStyle = {{color: Theme.palette.secondary.main}}
                        items        = {codNames}
                    >
                    </TComboBox>
                </div>
            </div>
        );
    };

    renderShowIVs = () => {
        const styles = {
            checkBox: {
                marginTop: '-10px',
                marginBottom: '50px',
                marginLeft: '24px'
            },
            checkBoxLabelStyle: {
                fontSize: 16,//'18.72px',
                // fontWeight: 'bold',
                color: '#000000'
            }
        };

        return (
            <TCheckBox
                style={styles.checkBox}
                color={'primary'}
                caption={RS('GB_stIncInterv')}
                labelStyle={styles.checkBoxLabelStyle}
                onClick={this.props.onShowIVsChange}
                value={this.props.showIVs}
            />
        );
    };

    renderDisplayEndpoints = () => {
        const styles = {
            checkBox: {
                marginTop: '-10px',
                //marginBottom: '50px',
                marginLeft: '24px'
            },
            checkBoxLabelStyle: {
                fontSize: 16,//'18.72px',
                //fontWeight: 'bold',
                color: '#000000',
                marginBottom: 8
            }
        };

        return (
            <TCheckBox
                style={styles.checkBox}
                color={'primary'}
                caption={RS("GB_stDisplayEndpoints")}
                labelStyle={styles.checkBoxLabelStyle}
                onClick={this.props.onDisplayEndpointsChange}
                value={this.props.displayEndpoints}
            />
        );
    };

    renderDisplayCoverage = () => {
        const styles = {
            checkBox: {
                marginTop: '15px',
                //marginBottom: '50px',
                marginLeft: '24px',
                display: "inline-block"
            },
            checkBox2: {
                marginTop: '-10px',
                marginBottom: '50px',
                marginLeft: '24px',
                display: this.props.displayCoverage ? "block" : "none"
            },
            checkBoxLabelStyle: {
                fontSize: 16,//'18.72px',
                //fontWeight: 'bold',
                color: '#000000'
            },
            subdiv: {
                marginLeft: '24px',
                display: this.props.displayCoverage ? "block" : "none"
            },
            label: {
                marginLeft: 25,
                fontSize: 14,
                marginRight: 10,
                marginTop: 10
            },
            radioCaption: {
                color: '#000000',
                fontSize: 14,
                fontWeight: "normal"
            },
            radio: {
                marginLeft: 25,
                marginTop: 10
            },
            radioOptions: {
                fontSize: 14,
                paddingTop: 9
            },
            radioBtn: {
                paddingBottom: 0
            },
            comboBox: {
                marginTop: 20,
                marginRight: 25,
                marginBottom: 25,
                paddingLeft: 0,
                width: "100%",
                minWidth: 275,
                display: 'inline-block'
            }
        };

        return (
            <div>
                {/*<p style={styles.label}>{RS("GB_stRefDisplay") + ":"}</p>*/}
                <TCheckBox
                    style={styles.checkBox}
                    color={'primary'}
                    caption={RS("GB_stShowRefCovDisplay")}
                    labelStyle={styles.checkBoxLabelStyle}
                    onClick={this.props.onDisplayCoverageChange}
                    value={this.props.displayCoverage}
                />
                <div style={styles.subdiv}>
                    <div style={{marginLeft : '25px', marginTop: 10}}>
                        <div style={{marginBottom: 0, width: '600px', maxWidth: "100%"}}>
                            <TComboBox
                                caption      = {RS('GB_stDisplayType')}
                                itemIndex    = {this.props.coverageDisplay === CONSTANTS.CS_TableChart ? 0 : 1}
                                onChange     = {this.onChangeCoverageDisplay}
                                style        = {styles.comboBox}
                                captionStyle = {{color: Theme.palette.secondary.main}}
                                items        = {[RS("GB_stTable"), RS("GB_stGraph")]}
                            />
                        </div>
                    </div>
                    <div style={{marginLeft : '25px'}}>
                        <div style={{marginBottom: 0, width: '600px', maxWidth: "100%"}}>
                            <TComboBox
                                caption      = {RS('GB_stShowInterv')}
                                itemIndex    = {this.props.retroYears ? 1: 0}
                                onChange     = {this.onRetroYearsChange}
                                style        = {styles.comboBox}
                                captionStyle = {{color: Theme.palette.secondary.main}}
                                items        = {[RS("GB_stCurrCov"), RS("GB_stVaxCov") + " (" +  RS("GB_stWRetroYrs") + ")"]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const styles = {
            dividerLabel: {
                //textDecoration: "underline",
                fontWeight: "bold",
                fontSize: 18,
                marginLeft: 25,
                color: Theme.palette.primary.main
            },
            subdiv: {
                marginLeft: 15
            },
            paren: {
                fontSize: 12,
                fontWeight: "normal"
            }
        };

        return (
            <div>
                {(
                    ((!Array.isArray(this.props.chartOptions['age'])) || (this.props.chartOptions['age'].length > 0)) ||
                    (!Array.isArray(this.props.chartOptions['sex'])) || (this.props.chartOptions['sex'].length > 0) ||
                    (this.props.chartOptions['discountRates'] !== undefined && this.props.chartOptions['discountRates'].length > 0) ||
                    this.props.chartOptions['cod'].length > 0 ||
                    this.props.chartOptions['showIVCB']
                ) ?
                    <p style={styles.dividerLabel}>{RS("GB_stDisplayConfig")}</p> : null
                }
                <div style={styles.subdiv}>
                    {
                        ((!Array.isArray(this.props.chartOptions['age'])) || (this.props.chartOptions['age'].length > 0)) ?
                            this.renderAgeCohort() : null
                    }
                    {
                        ((!Array.isArray(this.props.chartOptions['sex'])) || (this.props.chartOptions['sex'].length > 0)) ?
                            this.renderSex() : null
                    }
                    {
                        ((this.props.chartOptions['discountRates'] !== undefined)) && ((this.props.chartOptions['discountRates'].length > 0)) ?
                            this.renderDiscountRate() : null
                    }
                    {
                        (this.props.chartOptions['cod'].length > 0) ?
                            this.renderCauseOfDeath() : null
                    }

                    {
                        (this.props.chartOptions['showIVCB']) ?
                            this.renderShowIVs() : null
                    }
                </div>

                <p style={styles.dividerLabel}>{RS("GB_stGlobalOpts")}<span style={styles.paren}>{" (" + RS("GB_stAppliesAllActiveDisplays").toLowerCase() + ")"}</span></p>
                <div style={styles.subdiv}>
                    {this.renderYears()}

                    {this.renderDisplayEndpoints()}

                    {this.renderDisplayCoverage()}
                </div>
            </div>
        );
    }

}

export default CSConfigDrawer2;