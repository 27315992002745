import React from "react";
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import YesButton from "../buttons/YesButton";
import NoButton from "../buttons/NoButton";

class CloseProjectionDialog extends React.Component {

    static propTypes = {
        onStateChange     : PropTypes.func,
        onCloseProjection : PropTypes.func,
    };

    static defaultProps = {
        onStateChange     : () => console.log('onStateChange'),
        onCloseProjection : () => console.log('onCloseProjection'),
    };

    onClose = () => {
        this.props.onStateChange({
            dialogCloseOpen : false 
        });
    };

    onYes = () => {
        this.props.onCloseProjection();
        this.onClose();
    };

    onNo = () => {
        this.onClose();
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                onClose={this.onClose}
                iconType="Warning"
                title={RS('GB_stProjectionOpen')}
                content={
                    <div>
                        {RS('GB_stCurrentlyOpenProj')}    
                    </div>
                }
                actions={
                    <div>
                        <NoButton onClick={this.onNo} colorType="secondary" />
                        <YesButton onClick={this.onYes} />
                    </div>
                }
            />
        );
    }
}

export default CloseProjectionDialog;