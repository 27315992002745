import React from 'react';
import PropTypes from "prop-types";
import TAppSideBarNew from "./components/app/TAppSideBarNew";
import CONSTANTS from "utilities/CS/CSConst";
import ExploreListDataSideBarContent from "./ExploreLiSTDataSideBarContent";

class ExploreLiSTDataSideBar extends React.PureComponent {

    static propTypes = {
        sideBarOpen       : PropTypes.bool,
        selectedCountry   : PropTypes.string,
        countryISO        : PropTypes.number,
        countries         : PropTypes.array,
        language          : PropTypes.number,
        IVGroupInfo       : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo    : PropTypes.arrayOf(PropTypes.object),
        IVInfo            : PropTypes.arrayOf(PropTypes.object),
        inputModvars      : PropTypes.arrayOf(PropTypes.object),
        onStateChange     : PropTypes.func,
        onAddTasks        : PropTypes.func,
        onAddDisplay      : PropTypes.func,
        onSideBarToggle   : PropTypes.func,
        menuThreshold     : PropTypes.bool
    };

    static defaultProps = {
        sideBarOpen       : true,
        selectedCountry   : "Afghanistan",
        countryISO        : 4,
        countries         : [],
        language          : CONSTANTS.GB_English,
        IVGroupInfo       : [],
        IVSubGroupInfo    : [],
        IVInfo            : [],
        inputModvars      : [],
        onStateChange     : () => console.log('ExploreLiSTDataSideBar onStateChange'),
        onAddTasks        : () => console.log('ExploreLiSTDataSideBar onAddTasks'),
        onAddDisplay      : () => console.log('ExploreLiSTDataSideBar onAddDisplay'),
        onSideBarToggle   : () => console.log('ExploreLiSTDataSideBar onSideBarToggle'),
        menuThreshold     : false
    };

    render() {
        return (
            <TAppSideBarNew
                open             = {this.props.sideBarOpen}
                onToggle         = {this.props.onSideBarToggle}
                width            = {325}
                allowPlaceholder = {true}
            >
                <ExploreListDataSideBarContent 
                    selectedCountry      = {this.props.selectedCountry}
                    countryISO           = {this.props.countryISO}
                    countries            = {this.props.countries}
                    language             = {this.props.language}
                    IVGroupInfo          = {this.props.IVGroupInfo}
                    IVSubGroupInfo       = {this.props.IVSubGroupInfo}
                    IVInfo               = {this.props.IVInfo}
                    inputModvars         = {this.props.inputModvars}
                    onStateChange        = {this.props.onStateChange}
                    onAddTasks           = {this.props.onAddTasks}
                    onAddDisplay         = {this.props.onAddDisplay}
                    menuThreshold        = {this.props.menuThreshold}
                />
            </TAppSideBarNew>
        );
    }
}

export default ExploreLiSTDataSideBar;