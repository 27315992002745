import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import TComboBox from "@common/components/TComboBox";
import TLabel from "@common/components/TLabel";
import RS from "@common/strings/RS";

class IVApproachFilter extends React.Component {
    static propTypes = {
        onStateChange           : PropTypes.func,
        onOutcomeSwitchChange   : PropTypes.func,
        nutritionSelected       : PropTypes.bool,
        birthOutcomeSelected    : PropTypes.bool,
        style                   : PropTypes.object
    };

    static defaultProps = {
        onStateChange           : () => console.log("IVApproachFilter onStateChange"),
        onOutcomeSwitchChange   : () => console.log("IVApproachFilter onOutcomeSwitchChange"),
        nutritionSelected       : true,
        birthOutcomeSelected    : true,
        style                   : {}
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    onChangeFilter = (val) => {
        this.props.onStateChange({
            nutritionSelected: val === 1 || val === 0,
            birthOutcomeSelected: val === 2 || val === 0
        }, () => {
            //this success function exists to access uncheckInterventions
            if (val === 1) {
                this.props.onOutcomeSwitchChange(false, CONSTANTS.CS_MstBirthOutcomes);
            } else if (val === 2) {
                this.props.onOutcomeSwitchChange(false, CONSTANTS.CS_MstNutrition);
            } else {
                this.props.onOutcomeSwitchChange(true, 0);
            }
        });
    };

    handleFilterIdx = () => {
        let idx = 0;
        if (this.props.nutritionSelected && !this.props.birthOutcomeSelected) {
            idx = 1;
        } else if (!this.props.nutritionSelected && this.props.birthOutcomeSelected) {
            idx = 2;
        }
        return idx;
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            labelStyle : {
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#000000',
                userSelect: 'none',
                //marginBottom: '10px'
            },
            dropdown : {
                marginTop: 0,
                marginBottom: 20
            }
        };

        return (
            <div style={this.props.style}>
                <TLabel
                    caption = {RS('GB_stFilterIV')}
                    style   = {styles.labelStyle}
                />
                <TComboBox
                    caption={""}
                    items={[
                        RS('GB_stShowAllFilter'),
                        RS('GB_stNutrition'),
                        RS('GB_stBirthOutcomes')
                    ]}
                    style={styles.dropdown}
                    itemIndex={this.handleFilterIdx()}
                    onChange={this.onChangeFilter}
                />
            </div>
        );
    }
}

export default IVApproachFilter;