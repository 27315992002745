import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSChildHealthDrawer from "../../drawers/HealthStatus/CSChildHealthDrawer";
import {NutritionalDeficienciesEditor} from "../../data/HealthStatus/ChildHealth/NutritionalDeficienciesEditor";
import {StatusAtBirthEditor} from "../../data/HealthStatus/ChildHealth/StatusAtBirthEditor";
import {IncidenceEditor} from "../../data/HealthStatus/ChildHealth/IncidenceEditor";

class CSChildHealthBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        IVInfo           : PropTypes.arrayOf(PropTypes.object),
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        IVInfo           : [],
        CSModvars        : [],
        onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled : true,
        openDrawer      : false,
        packTable1      : null,
        packTable2      : null,
        packTable3      : null,
        menAChecked     : false,
    };

    componentDidMount() {
        this.setState({
            packTable1 : NutritionalDeficienciesEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable2 : StatusAtBirthEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable3 : IncidenceEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.openDrawer) {
            return {
                packTable1 : NutritionalDeficienciesEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                packTable2 : StatusAtBirthEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                packTable3 : IncidenceEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true, 
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({ 
            openDrawer      : false,
            saveBtnDisabled : true,
            packTable1      : NutritionalDeficienciesEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable2      : StatusAtBirthEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            packTable3      : IncidenceEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    };

    onSaveBtnClick = () => {
        const over100 = 1;
        const under0 = 2;

        let goAhead = true;
        let errorType = -1;
        let tableData = this.state.packTable2.tableData.value;
        for (let row = 1; row < this.state.packTable2.GBRowCount; row++){
            for (let col = 1; col < this.state.packTable2.GBColCount; col++) {
                let value = tableData[row][col];
                if (typeof value === "number") {
                    if (value > 100){
                        goAhead = false;
                        errorType = over100;
                    }
                    if (value < 0){
                        goAhead = false;
                        errorType = under0;
                    }
                }
            }
        }

        if (goAhead) {
            let ModvarsArray1 = NutritionalDeficienciesEditor.GetSSData(this.state.packTable1, this.props.IVInfo, this.props.CSModvars);
            let ModvarsArray2 = StatusAtBirthEditor.GetSSData(this.state.packTable2, this.props.CSModvars);
            let ModvarsArray3 = IncidenceEditor.GetSSData(this.state.packTable3, this.props.CSModvars);

            let ModvarMenACB = {...this.props.CSModvars.find(x => x.tag === CONSTANTS.CS_TG_MenARecommended), value: Boolean(this.state.menAChecked)};

            let changedCSModvars = this.props.CSModvars.map(x => {
                let a = ModvarsArray1.find(y => y.tag === x.tag);
                let b = ModvarsArray2.find(y => y.tag === x.tag);
                let c = ModvarsArray3.find(y => y.tag === x.tag);
                let d = (x.tag === CONSTANTS.CS_TG_MenARecommended) ? ModvarMenACB : undefined;
                return a || b || c || d || x;
            });

            this.setState({
                openDrawer: false,
                saveBtnDisabled: true,
            });

            this.props.onStateChange({
                unchangedProj: false,
                CSModvars: changedCSModvars
            });
        }
        else {
            let msg;
            switch(errorType){
                case over100 : {msg = RS("GB_stValOver100"); break;}
                case under0 : {msg = RS("GB_stValUnderZero"); break;}
                default : {break;}
            }
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : msg
            });
        }
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable3Change = (packTable) => {
        this.setState({
            packTable3      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2 
                caption     = {RS('GB_stChildHealth')}
                description = ""  
                onClick     = {this.onBlueBoxClick}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"baseline_child_health_status.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stChildHealth')}
                        content         = {
                                            <CSChildHealthDrawer
                                                packTable1={this.state.packTable1}
                                                packTable2={this.state.packTable2}
                                                packTable3={this.state.packTable3}
                                                onPackTable1Change={this.onPackTable1Change}
                                                onPackTable2Change={this.onPackTable2Change}
                                                onPackTable3Change={this.onPackTable3Change}
                                                CSModvars={this.props.CSModvars}
                                                passData={(o) => this.setState(o)}
                                            />
                                        }
                    />
                }
            />
        );
    }

}

export default CSChildHealthBlueBox;