import React from 'react';
import PropTypes from "prop-types";
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";

class CreateDisplayButton extends React.PureComponent {

    static propTypes = {
        disabled : PropTypes.bool,
        onClick  : PropTypes.func,
        language : PropTypes.number,
    };

    static defaultProps = {
        disabled : false,
        onClick  : () => console.log('CreateDisplayButton onClick'),
        language : CONSTANTS.GB_English
    };

    render() {
        const { onClick, disabled } = this.props;

        const styles = {
            btn : {
                fontSize: '16px',
                width: this.props.buttonWidth,
            },
        };
        
        return (
            <TButton
                caption={RS("GB_stCreateDisplay")}
                variant={"contained"}
                primary={true}
                style={styles.btn}
                onClick={onClick}
                disabled={disabled}
            />
        );
    }
}

export default CreateDisplayButton;