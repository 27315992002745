import { createVector, createMatrix,  } from "../other/utilities";

export const createSimpleDefaultPackTable = (rowCount = 1, columnCount = 1) => ({
    tableData: {
        value: createMatrix(rowCount, columnCount, "")
    },
    GBFixedRows: 0,
    GBFixedCols: 0,
    GBRowCount: rowCount,
    GBColCount: columnCount,
    TotalRow: 0,
    TotalCol: 0,
    WordWrappedCols: createVector(columnCount, false),
    RDec: createMatrix(rowCount, columnCount, 0),
    MinAllowedVal: createMatrix(rowCount, columnCount, 0),
    MaxAllowedVal: createMatrix(rowCount, columnCount, 0),
    FontStyles: createMatrix(rowCount, columnCount, { intArray: [] }),
    FontColors: createMatrix(rowCount, columnCount, "#000"),
    BGColors: createMatrix(rowCount, columnCount, 536870911),
    GBCellComment: createMatrix(rowCount, columnCount, ""),
    GBUseTriangle: createMatrix(rowCount, columnCount, false),
    LockedCells: createMatrix(rowCount, columnCount, false),
    MergedCells: [],
    IndentRowText: createVector(rowCount, {
        xoffset: 0,
        Value: false
    }),
    RowSubHeadings: createVector(rowCount, false),
    HasCheckBox: createMatrix(rowCount, columnCount, false),
    CheckBoxState: createMatrix(rowCount, columnCount, false),
    Alignments: createMatrix(rowCount, columnCount, 0),
    Title: "",
    editorMstID: 0,
    Proj: 0,
    ModID: 0,
    RowIds: createMatrix(rowCount, 2, [0, 0]),
    ColIds: createMatrix(columnCount, 2, [0, 0]),
    SourceMode: 0,
    SourceMap: [],
    Source: [""],
    GBRowHeights: createVector(rowCount, -1),
    GBColWidths: createVector(columnCount, -1)
});

export const createDefaultPackTable = (
    data,
    fixedRowCount,
    fixedColumnCount,
    rowCount,
    columnCount,
    displayTotalRow,
    displayTotalColumn
) => ({
    tableData: data,
    GBFixedRows: fixedRowCount,
    GBFixedCols: fixedColumnCount,
    GBRowCount: rowCount,
    GBColCount: columnCount,
    TotalRow: displayTotalRow ? 1 : 0,
    TotalCol: displayTotalColumn ? 1 : 0,
    WordWrappedCols: createVector(columnCount, false),
    RDec: createMatrix(rowCount, columnCount, 0),
    MinAllowedVal: createMatrix(rowCount, columnCount, 0),
    MaxAllowedVal: createMatrix(rowCount, columnCount, 0),
    FontStyles: createMatrix(rowCount, columnCount, { intArray: [] }),
    FontColors: createMatrix(rowCount, columnCount, "#000"),
    BGColors: createMatrix(rowCount, columnCount, 536870911),
    GBCellComment: createMatrix(rowCount, columnCount, ""),
    GBUseTriangle: createMatrix(rowCount, columnCount, false),
    LockedCells: createMatrix(rowCount, columnCount, false),
    MergedCells: [],
    IndentRowText: createVector(rowCount, {
        xoffset: 0,
        Value: false
    }),
    RowSubHeadings: createVector(rowCount, false),
    HasCheckBox: createMatrix(rowCount, columnCount, false),
    CheckBoxState: createMatrix(rowCount, columnCount, false),
    Alignments: createMatrix(rowCount, columnCount, 0),
    Title: "",
    editorMstID: 0,
    Proj: 0,
    ModID: 0,
    RowIds: createMatrix(rowCount, 2, [0, 0]),
    ColIds: createMatrix(columnCount, 2, [0, 0]),
    SourceMode: 0,
    SourceMap: [],
    Source: [""],
    SourceTitle: [""],
    GBRowHeights: createVector(rowCount, -1),
    GBColWidths: createVector(columnCount, -1)
});

export default createDefaultPackTable;
