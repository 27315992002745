import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import ExploreExpansionPanel from "../ExploreExpansionPanel";
import CONSTANTS from "utilities/CS/CSConst";
import OutcomeDropdown from "../../dropdowns/OutcomeDropdown";
import Data from "../../../../data/ExploreDefaultData";
import OptionCheckBox from "../../checkboxes/OptionCheckBox";

class EffectivenessOfInterventionsExpPanel extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onAddDisplay      : PropTypes.func,
        language          : PropTypes.number
    };

    static defaultProps = {
        onAddDisplay      : () => console.log('EffectivenessOfInterventionsExpPanel onAddDisplay'),
        language          : CONSTANTS.GB_English
    };

    state = {
        matContent        : Data.exploreMatEffIntContent,
        sbContent         : Data.exploreSBEffIntContent,
        nnContent         : Data.exploreNNEffIntContent,
        childContent      : Data.exploreChildEffIntContent,
        outcomeIdx        : 0,
        buttonDisabled    : true
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onShowDataBtnClick = () => {
        let content = [];

        switch (this.state.outcomeIdx) {
            case CONSTANTS.CS_MstMaternalMortalityExData : {content = this.state.matContent; break;}
            case CONSTANTS.CS_MstStillbirthsExData       : {content = this.state.sbContent; break;}
            case CONSTANTS.CS_MstNeonatalMortalityExData : {content = this.state.nnContent; break;}
            case CONSTANTS.CS_MstChildMortalityExData    : {content = this.state.childContent; break;}
            default                                      : {break;}
        }

        let newData = content.map(x => {
            if (x.checked) {
                return ({
                    editorID             : x['editorID'],
                    mode                 : CONSTANTS.CS_ExploreEffectivenessValuesMode,
                    firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                    finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                    possibleDisplayTypes : [CONSTANTS.CS_BarChart, CONSTANTS.CS_TableChart],
                    selectedDisplayType  : CONSTANTS.CS_TableChart,
                    mstID                : x['mstID'],
                    cod                  : x['mstID'],
                    tabs                 : x['tabs']
                });
            }
            return undefined;
        }).filter(x => x);
        
        this.props.onAddDisplay(newData);
        this.resetPanelToDefault();
    };

    resetPanelToDefault = () => {
        this.setState({
            matContent     : this.state.matContent.map(x => ({ ...x, checked : false })),
            sbContent      : this.state.sbContent.map(x => ({ ...x, checked : false })),
            nnContent      : this.state.nnContent.map(x => ({ ...x, checked : false })),
            childContent   : this.state.childContent.map(x => ({ ...x, checked : false })),
            buttonDisabled : true
        });
    };

    onCheckBoxChange = (checked, event, name, caption, mstID) => {
        let panelContent = [];
        let noneSelected = true;

        switch (this.state.outcomeIdx) {
            case CONSTANTS.CS_MstMaternalMortalityExData : panelContent = JSON.parse(JSON.stringify(this.state.matContent)); break;
            case CONSTANTS.CS_MstStillbirthsExData       : panelContent = JSON.parse(JSON.stringify(this.state.sbContent)); break;
            case CONSTANTS.CS_MstNeonatalMortalityExData : panelContent = JSON.parse(JSON.stringify(this.state.nnContent)); break;
            case CONSTANTS.CS_MstChildMortalityExData    : panelContent = JSON.parse(JSON.stringify(this.state.childContent)); break;
            default                                      : break;
        }

        for (let i = 0; i < panelContent.length; i++) {
            if (panelContent[i]['mstID'] === mstID)
                panelContent[i]['checked'] = checked;
        }

        for (let j = 0; j < panelContent.length; j++) {
            if (panelContent[j]['checked']) {
                noneSelected = false;
            }
        }

        switch (this.state.outcomeIdx) {
            case CONSTANTS.CS_MstMaternalMortalityExData : 
                this.setState({
                    matContent     : panelContent,
                    buttonDisabled : noneSelected
                });
                break;
            
            case CONSTANTS.CS_MstStillbirthsExData : 
                this.setState({
                    sbContent      : panelContent,
                    buttonDisabled : noneSelected
                });
                break;
            
            case CONSTANTS.CS_MstNeonatalMortalityExData : 
                this.setState({
                    nnContent      : panelContent,
                    buttonDisabled : noneSelected
                });
                break;
            
            case CONSTANTS.CS_MstChildMortalityExData : 
                this.setState({
                    childContent   : panelContent,
                    buttonDisabled : noneSelected
                });
            break;
        
            default : break;
        }
    };

    onOutcomeChange = (idx) => {
        this.setState ({
                outcomeIdx : idx
            },
            () => this.resetPanelToDefault()
        );
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    renderContent = () => {
        let panelContent = [];
        let content = [];

        switch (this.state.outcomeIdx) {
            case CONSTANTS.CS_MstMaternalMortalityExData : content = this.state.matContent; break;
            case CONSTANTS.CS_MstStillbirthsExData       : content = this.state.sbContent; break;
            case CONSTANTS.CS_MstNeonatalMortalityExData : content = this.state.nnContent; break;
            case CONSTANTS.CS_MstChildMortalityExData    : content = this.state.childContent; break;
            default                                      : break;
        }

        panelContent.push (
            <OutcomeDropdown
                outcomeIdx      = {this.state.outcomeIdx}
                onOutcomeChange = {this.onOutcomeChange}
                width           = {245}
                key             = {'outcome'}
                language        = {this.props.language}
                adol            = {false}
            />
        );

        for (let i = 0; i < content.length; i++) {
            panelContent.push(
                <OptionCheckBox
                    caption={RS(content[i]['name'])}
                    value={content[i]['checked']}
                    key={content[i]['mstID']}
                    customProp={content[i]['mstID']}
                    onChange={this.onCheckBoxChange}
                />
            );
        }
        
        return (panelContent);
    };

    render() {
        return (
            <ExploreExpansionPanel
                onShowDataBtnClick = {this.onShowDataBtnClick}
                content            = {this.renderContent()}
                panelName          = {RS('GB_stEffInterventions')}
                showDataBtn        = {true}
                buttonDisabled     = {this.state.buttonDisabled}
                buttonWidth        = {'auto'}
                subPanel           = {true}
                panelKey           = {'EffectivenessOfInterventions'}
                key                = {'EffOfInt'}
            />
        );
    }

}

export default EffectivenessOfInterventionsExpPanel;