import CONSTANTS from "utilities/CS/CSConst";

function GetChartOptions(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_OnlyTable(modID, resultID){
    return { modID          : modID,
        resultID       : resultID,
        age            : [],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstNoCODCat,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_TableChart],
        typeOfDisplay  : CONSTANTS.CS_TableChart,
        cardChartType  : CONSTANTS.CS_LineChart
    };
}

// function GetChartOptions_ShowIVs(modID, resultID, chartType){
//     return { modID          : modID,
//              resultID       : resultID,
//              age            : [],
//              ageIdx         : 0,
//              sex            : [],
//              sexIdx         : 0,
//              cod            : [],
//              codIdx         : 0,
//              codCat         : CONSTANTS.CS_MstNoCODCat,
//              showIVCB       : true,
//              showIVs        : true,
//              chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_TableChart],
//              typeOfDisplay  : chartType,
//              cardChartType  : chartType
//         };
// }

function GetChartOptions_TableChart(modID, resultID, chartType){
    return { modID          : modID,
        resultID       : resultID,
        age            : [],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstNoCODCat,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType
    };
}

function GetChartOptions_DiscountRates(modID, resultID, chartType){
    return { modID     : modID,
        resultID       : resultID,
        age            : [],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstNoCODCat,
        discountRates  : [CONSTANTS.CS_3Perc, CONSTANTS.CS_5Perc, CONSTANTS.CS_10Perc],
        discountRateIdx: 0,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType
    };
}

function GetChartOptions_TableChartModified(modID, resultID, chartType){
    return { modID          : modID,
        resultID       : resultID,
        age            : [],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstNoCODCat,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_TableChart],
        typeOfDisplay  : CONSTANTS.CS_TableChart,
        cardChartType  : chartType
    };
}

function GetChartOptions_VT_Chart(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [CONSTANTS.CS_BCG_Det, CONSTANTS.CS_Polio_Det,  CONSTANTS.CS_DPT_Det, CONSTANTS.CS_HiB_Det,
                               CONSTANTS.CS_HepB_Det, CONSTANTS.CS_PCV_Det, CONSTANTS.CS_Rota_Det, CONSTANTS.CS_MenA_Det,
                               CONSTANTS.CS_Malaria_Det, CONSTANTS.CS_Meas_Det, CONSTANTS.CS_VaccB_Det, CONSTANTS.CS_VaccC_Det,
                               CONSTANTS.CS_VaccD_Det],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_NoBarChart(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_NoBarChartShowIVs(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : true,
             showIVs        : true,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
         };
}

function GetChartOptions_NeonatalCOD(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [CONSTANTS.CS_NNDiarr, CONSTANTS.CS_NNSepsis, CONSTANTS.CS_NNPneumonia, CONSTANTS.CS_NNAsphyxia,
                               CONSTANTS.CS_NNPreterm, CONSTANTS.CS_NNTetanus, CONSTANTS.CS_NNCongen, CONSTANTS.CS_NNOther],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNeonatalCODCat,
             showIVCB       : true,
             showIVs        : true,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
    };
}

function GetChartOptions_Ages(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t59months],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

// function GetChartOptions_AgesModified(modID, resultID, chartType){
//     return { modID          : modID,
//         resultID       : resultID,
//         age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t59months],
//         ageIdx         : 0,
//         sex            : [],
//         sexIdx         : 0,
//         cod            : [],
//         codIdx         : 0,
//         codCat         : CONSTANTS.CS_MstNoCODCat,
//         showIVCB       : false,
//         showIVs        : false,
//         chartTypes     : [CONSTANTS.CS_TableChart],
//         typeOfDisplay  : CONSTANTS.CS_TableChart,
//         cardChartType  : chartType
//     };
// }

function GetChartOptions_AgesNoBarChart(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t59months],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : true,
             showIVs        : true,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

// function GetChartOptions_AgesTableChart(modID, resultID, chartType){
//     return { modID          : modID,
//              resultID       : resultID,
//              age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t59months],
//              ageIdx         : 0,
//              sex            : [],
//              sexIdx         : 0,
//              cod            : [],
//              codIdx         : 0,
//              codCat         : CONSTANTS.CS_MstNoCODCat,
//              showIVCB       : false,
//              showIVs        : false,
//              chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
//              typeOfDisplay  : chartType,
//              cardChartType  : chartType
//            };
// }

function GetChartOptions_AgeCohortsTableChart(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months,
                               CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months, CONSTANTS.CS_24t60months],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_AgeCohortsTableChartModified(modID, resultID, chartType){
    return { modID          : modID,
        resultID       : resultID,
        age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months,
            CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months, CONSTANTS.CS_24t60months],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstNoCODCat,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_TableChart],
        typeOfDisplay  : CONSTANTS.CS_TableChart,
        cardChartType  : chartType
    };
}

function GetChartOptions_IncidenceRates(modID, resultID, chartType){
    return { modID          : modID,
        resultID       : resultID,
        age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months,
            CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months, CONSTANTS.CS_24t60months],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstNoCODCat,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_TableChart],
        typeOfDisplay  : CONSTANTS.CS_TableChart,
        cardChartType  : chartType
    };
}

function GetChartOptions_AgeCohortsTableChartNoLine(modID, resultID, chartType){
    return { modID          : modID,
        resultID       : resultID,
        age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months,
            CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months, CONSTANTS.CS_24t60months],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstNoCODCat,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType
    };
}

function GetChartOptions_AgeCohortsTableChartShowIVs(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months,
                               CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months, CONSTANTS.CS_24t60months],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : true,
             showIVs        : true,
             chartTypes     : [CONSTANTS.CS_TableChart],
             typeOfDisplay  : CONSTANTS.CS_TableChart,
             cardChartType  : chartType
           };
}

function GetChartOptions_AgeCohortsTableChart2(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months,
                               CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_TableChart],
             typeOfDisplay  : CONSTANTS.CS_TableChart,
             cardChartType  : chartType
           };
}

function GetChartOptions_AgeTypesNoBarChart(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [CONSTANTS.CS_AgeCohort, CONSTANTS.CS_AgeYear],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstNoCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_ChildCOD(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [CONSTANTS.CS_AgeSummary, CONSTANTS.CS_0t1months, CONSTANTS.CS_1t59months],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [CONSTANTS.CS_NNDiarr, CONSTANTS.CS_NNSepsis, CONSTANTS.CS_NNPneumonia, CONSTANTS.CS_NNAsphyxia,
                               CONSTANTS.CS_NNPreterm, CONSTANTS.CS_NNTetanus, CONSTANTS.CS_NNCongen, CONSTANTS.CS_NNOther,
                               CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis, CONSTANTS.CS_Measles,
                               CONSTANTS.CS_Malaria, CONSTANTS.CS_Pertussis, CONSTANTS.CS_AIDS, CONSTANTS.CS_Injury,
                               CONSTANTS.CS_OtherInfecDis, CONSTANTS.CS_OtherNCD],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstChildCODCat,
             showIVCB       : true,
             showIVs        : true,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_PostNeonatalCOD(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis, CONSTANTS.CS_Measles,
                               CONSTANTS.CS_Malaria, CONSTANTS.CS_Pertussis, CONSTANTS.CS_AIDS, CONSTANTS.CS_Injury,
                               CONSTANTS.CS_OtherInfecDis, CONSTANTS.CS_OtherNCD],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstChildCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_MaternalCOD(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [CONSTANTS.CS_Mat_AntepartHemorr, CONSTANTS.CS_Mat_IntrapartHemorr, CONSTANTS.CS_Mat_PostpartHemorr,
                               CONSTANTS.CS_Mat_HypertensiveDis, CONSTANTS.CS_Mat_Sepsis, CONSTANTS.CS_Mat_Abortion,
                               CONSTANTS.CS_Mat_Embolism, CONSTANTS.CS_Mat_OtherDirectCauses, CONSTANTS.CS_Mat_IndirectCauses],
             codIdx         : 1,
             codCat         : CONSTANTS.CS_MstMaternalCODCat,
             showIVCB       : true,
             showIVs        : true,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_SB(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [CONSTANTS.CS_SB_Antepartum, CONSTANTS.CS_SB_Intrapartum],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstStillbirthCODCat,
             showIVCB       : true,
             showIVs        : true,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_EtiCOD(modID, resultID, chartType){
    return { modID          : modID,
             resultID       : resultID,
             age            : [],
             ageIdx         : 0,
             sex            : [],
             sexIdx         : 0,
             cod            : [CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis],
             codIdx         : 0,
             codCat         : CONSTANTS.CS_MstChildCODCat,
             showIVCB       : false,
             showIVs        : false,
             chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
             typeOfDisplay  : chartType,
             cardChartType  : chartType
           };
}

function GetChartOptions_EtiCOD2(modID, resultID, chartType){
    return { modID          : modID,
        resultID       : resultID,
        age            : [],
        ageIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        cod            : [CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis],
        codIdx         : 0,
        codCat         : CONSTANTS.CS_MstChildCODCat,
        showIVCB       : false,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_TableChart],
        typeOfDisplay  : CONSTANTS.CS_TableChart,
        cardChartType  : chartType
    };
}

function GetChartOptions_DemographyPop(modID, resultID, hiAge, chartType){
    return {
        modID          : modID,
        resultID       : resultID,
        cod            : [],
        codIdx         : 0,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType,
        age            : [],
        ageIdx         : 0,
        sex: {
            both   : true,
            male   : false,
            female : false
        },
        sexIdx     : 0,
        resultType : "line",
        canHaveUA  : false,
        LowAge     : 0,
        HiAge      : hiAge
    };
}

function GetChartOptions_Demography(modID, resultID, chartType){
    return {
        modID          : modID,
        resultID       : resultID,
        age            : [],
        ageIdx         : 0,
        cod            : [],
        codIdx         : 0,
        sex            : [],
        sexIdx         : 0,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType
    };
}

function GetChartOptions_DemographySexOpt(modID, resultID, chartType){
    return {
        modID    : modID,
        resultID : resultID,
        age      : [],
        ageIdx   : 0,
        cod      : [],
        codIdx   : 0,
        sex: {
            both   : true,
            male   : false,
            female : false
        },
        sexIdx         : 0,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType
    };
}

function GetChartOptions_HIVAIM(modID, resultID){
    return {
        modID          : modID,
        resultID       : resultID,
        cod            : [],
        codIdx         : 0,
        showIVs        : false,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        cardChartType  : "line",
        age            : [],
        ageIdx         : 0,
        sex: {
            both   : true,
            male   : false,
            female : false
        },
        sexIdx     : 0,
        resultType : "line",
        canHaveUA  : false,
        LowAge     : 15,
        HiAge      : 19,
        displayTargets :false,
        shouldDisplayUABounds: false,
    };
}

function GetChartOptions_FamPlanPreg(modID, resultID, preg, chartType){
    return {
        modID          : modID,
        resultID       : resultID,
        cod            : [],
        sex            : [],
        age            : [],
        ages           : [CONSTANTS.FP_A15_19],
        showIVs        : [],
        preg           : preg,
        DisAgAges      : true,
        DisAgPreg      : preg.length > 0,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType,
        resultType     : chartType
    };
}

function GetChartOptions_FamPlan(modID, resultID, chartType){
    return {
        modID          : modID,
        resultID       : resultID,
        cod            : [],
        sex            : [],
        age            : [],
        ages           : [CONSTANTS.FP_A15_19],
        methods        : [],
        sources        : [],
        showIVs        : [],
        DisAgAges      : true,
        DisAgMethods   : false,
        DisAgSources   : false,
        chartTypes     : [CONSTANTS.CS_LineChart, CONSTANTS.CS_TableChart],
        typeOfDisplay  : chartType,
        cardChartType  : chartType,
        resultType     : chartType
    };
}

let CSTemplate = {

    ServerInfo : {
        serverAlive       : true,

        version           : {},
        countries         : [],
        IVInfo            : [],

        resultMenus       : {},
        DPTree            : [],                // resultMenus
        AMTree            : [],                // resultMenus
        FPTree            : [],                // resultMenus
        CSTree            : [],                // resultMenus
    },

    AccountInfo : {
        language          : CONSTANTS.GB_English,
        signedIn          : false,
        userName          : '',
        humanName         : '',
        isGuest           : true,
    },

    SessionInfo : {
        sessionExists     : false,
        userLoggedOff     : false,
        disconnectWarning : false,
        TimeToLive        : "0:60:0",
        TimeIdle          : "0:0:0",
        TimeExpire        : "1:0:0",
        TimeTotal         : "0:0:0",
        disconnecting     : false,
    },

    SessionData : {
        storedProjections : [],
        activeProjections : [],
    },

    Projection : {
        projectCreated           : false,
        projectionName           : '',
        projectionNotes          : '',
        selectedCountry          : 'Afghanistan',
        countryISO               : 4,
        firstYear                : new Date().getFullYear(),
        finalYear                : CONSTANTS.CS_DefaultFinalYear,
        finalYearIdx             : (CONSTANTS.CS_DefaultFinalYear - (new Date()).getFullYear() - 4),
        yearRange                : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        subnatProjection         : false,
        includeFamPlan           : true,
        useFamPlanOutputs        : false,
        saveBtnDisabled          : true,
        legendReversed           : false,
        stacked                  : '',
        selectedProjSideBarIdx   : CONSTANTS.CS_ProjectionManagerIdx,
        maternalIntervSelected   : true,
        stillbirthIntervSelected : true,
        neonatalIntervSelected   : true,
        childIntervSelected      : true,
        selectAllIntervBool      : false,
        isExpandMODrawerClosed   : true,
        showAllInterventionsCB   : false,
        chartYears               : {
                                       firstYrIdx : CONSTANTS.CS_DefaultFirstYearIdx,
                                       finalYrIdx : CONSTANTS.CS_DefaultFinalYearIdx,
                                   },
        inputModvars             : [],
        intervDrawerOpen         : false,
        showMOIntervSection      : false,
        chartOptions             : [
            /* Neonatal */
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstNNDeaths, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstNNDeathsByCause, CONSTANTS.CS_BarChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstNNDeathsPrev, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstNNDeathsPrByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_NoBarChartShowIVs(CONSTANTS.GB_CS, CONSTANTS.CS_MstNNDeathsPrByInter, CONSTANTS.CS_LineChart),
            GetChartOptions_NeonatalCOD(CONSTANTS.GB_CS, CONSTANTS.CS_MstNNDeathsPrByInterByCause, CONSTANTS.CS_LineChart),

            /* Child */
            GetChartOptions_Ages(CONSTANTS.GB_CS, CONSTANTS.CS_MstChildDeaths, CONSTANTS.CS_ColumnChart),
            GetChartOptions_Ages(CONSTANTS.GB_CS, CONSTANTS.CS_MstChDeathsByCause, CONSTANTS.CS_ColumnChart),
            GetChartOptions_NoBarChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstChDeathsPrev, CONSTANTS.CS_LineChart),
            GetChartOptions_Ages(CONSTANTS.GB_CS, CONSTANTS.CS_MstChDeathsPrByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_AgesNoBarChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstChDeathsPrByInter, CONSTANTS.CS_LineChart),
            GetChartOptions_ChildCOD(CONSTANTS.GB_CS, CONSTANTS.CS_MstChDeathsPrByInterByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstDAvtdByVaccByCohort, CONSTANTS.CS_LineChart),
            GetChartOptions_PostNeonatalCOD(CONSTANTS.GB_CS, CONSTANTS.CS_MstDAvtdByVaccByCohortByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_AgeTypesNoBarChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstChildDeathsByYearAgeGroup, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstChDeathsByBirthCoh, CONSTANTS.CS_BarChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstChDeathsByCauseByBirthCoh, CONSTANTS.CS_BarChart),
            GetChartOptions_VT_Chart(CONSTANTS.GB_CS, CONSTANTS.CS_MstVaccineCoverage, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumPersVaccByInterv, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumPersVaccByIntervBy1YrAgeGrps, CONSTANTS.CS_LineChart),

            /* Maternal */
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstMDeaths, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstMDeathsByCause, CONSTANTS.CS_BarChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstMDeathsPrev, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstMDeathsPrByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_NoBarChartShowIVs(CONSTANTS.GB_CS, CONSTANTS.CS_MstMDeathsPrByInter, CONSTANTS.CS_LineChart),
            GetChartOptions_MaternalCOD(CONSTANTS.GB_CS, CONSTANTS.CS_MstMDeathsPrByInterByCause, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstAbortion, CONSTANTS.CS_LineChart),

            /* Stillbirth */
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstSB, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstSBByCause, CONSTANTS.CS_BarChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstSBPrev, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstSBPrByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_NoBarChartShowIVs(CONSTANTS.GB_CS, CONSTANTS.CS_MstSBPrByInter, CONSTANTS.CS_LineChart),
            GetChartOptions_SB(CONSTANTS.GB_CS, CONSTANTS.CS_MstSBPrByInterByCause, CONSTANTS.CS_LineChart),

            /* Mortality rates */
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstNNMortalityRate, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstIMortalityRate, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstU5MortalityRate, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstMMortalityRatio, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstMMortalityRate, CONSTANTS.CS_LineChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstSBRate, CONSTANTS.CS_LineChart),
            GetChartOptions_OnlyTable(CONSTANTS.GB_CS, CONSTANTS.CS_MstMortalityRates),
            GetChartOptions_OnlyTable(CONSTANTS.GB_CS, CONSTANTS.CS_MstRedMortByInterv),

            /* Nutrition */
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstBirthTermResults, CONSTANTS.CS_ColumnChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstSubOpBOAvertByInterInd, CONSTANTS.CS_ColumnChart),
            GetChartOptions(CONSTANTS.GB_CS, CONSTANTS.CS_MstLowBirthWeight, CONSTANTS.CS_LineChart),
            GetChartOptions_AgeCohortsTableChartNoLine(CONSTANTS.GB_CS, CONSTANTS.CS_MstPercInVarHtForAgeStunt, CONSTANTS.CS_ColumnChart),
            GetChartOptions_AgeCohortsTableChartNoLine(CONSTANTS.GB_CS, CONSTANTS.CS_MstPercInVarWtForHtWaste, CONSTANTS.CS_ColumnChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstGlobalStuntRate, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstGlobalWasteRate, CONSTANTS.CS_LineChart),
            GetChartOptions_NoBarChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstNumStunted, CONSTANTS.CS_LineChart),
            GetChartOptions_NoBarChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstNumStuntedAverted, CONSTANTS.CS_LineChart),
            GetChartOptions_AgeCohortsTableChartShowIVs(CONSTANTS.GB_CS, CONSTANTS.CS_MstNumStuntedAvertedByInter, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstAdditionalYrsSchoolingGainedInd, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstPercIncEarnStuntDeclineInd, CONSTANTS.CS_LineChart),
            GetChartOptions_DiscountRates(CONSTANTS.GB_CS, CONSTANTS.CS_MstImpLifeEarnDueToStuntReducInd, CONSTANTS.CS_LineChart),
            GetChartOptions_DiscountRates(CONSTANTS.GB_CS, CONSTANTS.CS_MstLifetimeEarningsObtainedInd, CONSTANTS.CS_LineChart),
            GetChartOptions_AgeCohortsTableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstAttrStuntReduct, CONSTANTS.CS_LineChart),
            GetChartOptions_AgeCohortsTableChart2(CONSTANTS.GB_CS, CONSTANTS.CS_MstBFPrevalence, CONSTANTS.CS_LineChart), //make sure to change the string associated with AgeSummary
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstPercExclusiveBF, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstPrevEarlyInitBF, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstPercWomenAnemicResults, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstNumWomenAnemicResults, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstAnemiaCasesPreventedResults, CONSTANTS.CS_LineChart),

            /* Incidence and etiology */
            GetChartOptions_IncidenceRates(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiIncRates, CONSTANTS.CS_LineChart),
            GetChartOptions_AgeCohortsTableChartModified(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumU5CasesByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChartModified(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumU5CasesAvtdByCause, CONSTANTS.CS_LineChart),
            GetChartOptions_EtiCOD2(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiPerc1t59MoDeathsByPath, CONSTANTS.CS_LineChart),
            GetChartOptions_EtiCOD2(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiPerc1t59MoCasesByPath, CONSTANTS.CS_LineChart),  //make sure to change the string associated with AgeSummary
            GetChartOptions_EtiCOD2(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumDeathsByPath, CONSTANTS.CS_LineChart),
            GetChartOptions_EtiCOD2(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumCasesByPath, CONSTANTS.CS_LineChart), //make sure to change the string associated with AgeSummary
            GetChartOptions_EtiCOD(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumDeathsByPathBy1YrAgeGrps, CONSTANTS.CS_LineChart),
            GetChartOptions_EtiCOD(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumCasesByPathBy1YrAgeGrps, CONSTANTS.CS_LineChart), //make sure to change the string associated with AgeSummary
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNumU5CasesByCauseBirthCoh, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNum1t59MoDeathsByPathBirthCoh, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNum1t59MoCasesByPathBirthCoh, CONSTANTS.CS_LineChart),
            GetChartOptions_TableChart(CONSTANTS.GB_CS, CONSTANTS.CS_MstEtiNum1t59MoCasesAvtdByCauseYr1stDose, CONSTANTS.CS_LineChart),

            /* Demography */
            GetChartOptions_DemographyPop(CONSTANTS.GB_DP, CONSTANTS.DP_MstcmTotalPop, 80, CONSTANTS.CS_LineChart),
            GetChartOptions_DemographyPop(CONSTANTS.GB_DP, CONSTANTS.DP_MstcmPop04, 4, CONSTANTS.CS_LineChart),
            GetChartOptions_Demography(CONSTANTS.GB_DP, CONSTANTS.DP_MstcmDispTFR, CONSTANTS.CS_LineChart),
            GetChartOptions_DemographySexOpt(CONSTANTS.GB_DP, CONSTANTS.DP_MstcmTotalLE, CONSTANTS.CS_LineChart),
            GetChartOptions_DemographySexOpt(CONSTANTS.GB_DP, CONSTANTS.DP_MstcmBirths, CONSTANTS.CS_LineChart),

            /* AIM/HIV */
            GetChartOptions_HIVAIM(CONSTANTS.GB_AM, CONSTANTS.DP_MstcmNumNewInf),
            GetChartOptions_HIVAIM(CONSTANTS.GB_AM, CONSTANTS.DP_MstcmAIDSDeaths),

            /* FamPlan */
            GetChartOptions_FamPlanPreg(CONSTANTS.GB_FP, CONSTANTS.FP_MstcmPregnancies, [], CONSTANTS.CS_LineChart),
            GetChartOptions_FamPlanPreg(CONSTANTS.GB_FP, CONSTANTS.FP_MstcmNumUnintendPreg, [CONSTANTS.FP_unintended], CONSTANTS.CS_LineChart),
            GetChartOptions_FamPlan(CONSTANTS.GB_FP, CONSTANTS.FP_MstcmAbortions, CONSTANTS.CS_LineChart),
            GetChartOptions_FamPlan(CONSTANTS.GB_FP, CONSTANTS.FP_MstcmIllAbortions, CONSTANTS.CS_LineChart),
            GetChartOptions_FamPlan(CONSTANTS.GB_FP, CONSTANTS.FP_MstcmUsers, CONSTANTS.CS_LineChart),
        ],
    },

    CSModvars : [
        // Input variables- Demography
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_ARVRegimen_MV3,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_ChildNeedPMTCT_MV,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_ChildOnPMTCT_MV,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_ChildARTByAGPerNum_MV2,              value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_ChildTreatInputs_MV3,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_HAARTBySex_MV,                       value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_ChildARTCalc_MV2,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_SurvRate_MV2,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_SurvRate1970_MV,                     value : null     },

        // Input variables- Family planning
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_Tprevalence_MV,                       value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TTFR_MV,                              value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TActiveMethods_MV,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TMethodName_MV,                       value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TNeedOption_MV,                       value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TMethodMix_MV,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TPercentInUnion_MV,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TPPI_MV,                              value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TTAR_MV,                              value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TAbortionPercent_MV,                  value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TSterility_MV,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TAbortionOption_MV,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP, tag : CONSTANTS.FP_TG_TAgeGroupOption_MV,                   value : null     },

        // Input variables- Age Incidence Model
        { proj : 1, modID : CONSTANTS.GB_HA, tag : CONSTANTS.HA_TGX_CondomsNonMaritalContacts_MV,        value : null     },
        { proj : 1, modID : CONSTANTS.GB_HA, tag : CONSTANTS.HA_TGX_PrEPCoverage_MV,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_HA, tag : CONSTANTS.HA_TGX_PrEPAdherence_MV,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_HA, tag : CONSTANTS.HA_TGX_PrEPEfficacy_MV,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_HA, tag : CONSTANTS.HA_TGX_SexAgeFitType_MV,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_HA, tag : CONSTANTS.HA_TGX_MaleCircumcisionByAge_MV,            value : null     },
        { proj : 1, modID : CONSTANTS.GB_HA, tag : CONSTANTS.HA_TGX_StructCoverages_MV,                  value : null     },

        // Input variables- LiST
        // General
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_FirstYear_MV2,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_FinalYear_MV2,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ProjectionValidDate_MV2,              value : null     },

        // Controls
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_CovBY_MV,                             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ANCMode_MV,                           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ChildbirthMode_MV,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_CalcCovFromInputs_MV2,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_EnterBFImproveData_MV2,               value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_StuntingMatrixOn_MV2,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_WastingMatrixOn_MV2,                  value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_FertilityRisksMatrixOn_MV2,           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_IPTpCBChecked_MV2,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AbortCalcFamPlan_MV2,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SelectedIVSet_MV,                     value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AdolMode_MV,                          value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_InterventionList_MV,                  value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_InterventionGroupList_MV,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_InterventionSubGroupList_MV,          value : null     },

        // Health status
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MenARecommended,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_NutritionalDeficiencies_MV2,          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_VitADefInPW_MV,                       value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_StatusAtBirth_MV,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarInc_MV,                           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevereDiarInc_MV,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumInc_MV,                          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningInc_MV,                         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MaternalAnemia_MV,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RelRisksAnemia_MV2,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercWom15t49LowBMI_MV,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercExposedFalciparum_MV,             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_FolicAcidDeficPrev_MV,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_CongenitalDueToNTDsAF_MV,             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_StuntingDistr_MV,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SingleStuntingDistr_MV,               value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_WastingDistr_MV,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SingleWastingDistr_MV,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_UseDetailedStuntingMatrix_MV,         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_UseDetailedWastingMatrix_MV,          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarPath_MV2,                         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumPath_MV,                         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MenPath_MV,                           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BaselineMort_MV,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercDthByCause_MV2,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AdolMortRatio_MV2,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercAdolDeathsByCause_MV,             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatMortRatio_MV2,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercMatDeathsByCause_MV,              value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercPregEndAbort_MV2,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AbortIncRatio_MV2,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBRate_MV2,                           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBCauses_MV,                          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PerCapitaInc_MV,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_FoodInsecure_MV,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AvgHouseholdSize_MV,                  value : null     },

        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_FirstYearAtWork_MV,                  value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_LastYearAtWork_MV,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_GNIPerCap_MV,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_LaborForceParRate_MV,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_LaborShareInc_MV,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_WorkingYears_MV,                     value : null     },

        // Explore data health status
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BaselineMortLiSTOnline_MV,            value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercDthByCauseLiSTOnline_MV2,         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatMortRatioLiSTOnline_MV2,           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercMatDeathsByCauseLiSTOnline_MV,    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBRateLiSTOnline_MV2,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBCausesLiSTOnline_MV,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AdolMortRatioLiSTOnline_MV2,          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercAdolDeathsByCauseLiSTOnline_MV,   value : null     },

        // Coverage
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_Coverage_MV3,                         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_EarlyInitBFPrev_MV2,                  value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BreastfeedPrev_MV,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RoutDetVaccCov_MV3,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BreastFeedPromoCB_MV,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PentavalentDetVaccCov_MV,             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AgeAndBirthOrderMatrix_MV2,           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BirthIntervalsMatrix_MV2,             value : null     },

        /* Maternal, Stillbirth, and Child Efficacy */
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatEfficacy_MV3,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBEfficacy_MV3,                       value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_Efficacy_MV2,                         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DetVaccEff_MV2,                       value : null     },

        /* Effectiveness of interventions on incidence */
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarrIncReduction_MV3,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevDiarrIncReduction_MV,              value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumIncReduction_MV,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningIncReduction_MV,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RelRisksDiarrhea_MV2,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RelRisksPneumonia_MV2,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RelRisksMeningitis_MV2,               value : null     },

        /* Herd effectiveness of vaccines */
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_CustomHerdVaccEff_MV3,                value : null     },

        /* Effectiveness of nutrition interventions */
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AgeAndBirthOrder_MV2,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BirthIntervals_MV2,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_IntervForBO_MV7,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ImpactsOnStunting_MV,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ImpactsOnWasting_MV4,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ImpactsBFPromotion_MV3,               value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ImpactsOnMatAnemia_MV3,               value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_KMConBF_MV,                           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_LBWF_MV2,                             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ReducStunt_MV,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_EducationAttain_MV,                   value : null     },

        /* Impact of under-nutrition on mortality */
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RelRisksStunting_MV,                  value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RelRisksWasting_MV,                   value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ImpactBFOnMortNeoNat_MV,              value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ImpactBFOnMortPostNat_MV,             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RelRisksIUGR_LBW_MV,                  value : null     },

        // // Calculated variables- LiST
        // // Neonatal, child sectors
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ChDeaths_MV2,                         value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ChDeathsByYear_MV2,                   value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ChDeathsPrevd_MV2,                    value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ChDeathsxCause_MV2,                   value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DeathsByCauseByBirthCohort_MV2,       value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ChDeathsPrevdxCause_MV2,              value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_ChDeathsPrevdxInt_MV3,                value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DeathsPrevByInterByCause_MV3,         value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DetVaccCovResults_MV3,                value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DAvtdByVaccByCohortResult_MV2,        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DAvtdByVaccByCohortByCause_MV3,       value : null     },

        // // Maternal sector
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatDeaths_MV2,                        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatDeathsPrev_MV2,                    value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatDeathsByCauseResults_MV2,          value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatDeathsPrevByCause_MV2,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatDeathsPrevByInter_MV3,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatDeathsPrevByInterByCause_MV3,      value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_NumAbortions_MV2,                     value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercAnemicPregWomen_MV2,              value : null     },

        // // Stillbirth sector
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBResults_MV2,                        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBPrevResults_MV2,                    value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBByCauseResults_MV2,                 value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBPrevByCauseResults_MV2,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBPrevByInterResults_MV3,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBPrevByInterByCause_MV3,             value : null     },

        // // Mortality rates sector
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SBRateResults_MV2,                    value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatMortRatioResult_MV2,               value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MatMortRateResult_MV2,                value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MortalityRateResults_MV2,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RedMortIntervResults_MV3,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_RedMortIntervSortedIDs_MV3,           value : null     },

        // // Nutrition sector
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MAM_PIN_MV,                           value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SAM_PIN_MV,                           value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PInVarHtForAgeStunt_MV2,              value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PInVarWtForHtWaste_MV2,               value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_NumStunted_MV,                        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_NumStuntedAverted_MV,                 value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_NumStuntedAvertedByInter_MV,          value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PStuntRedAccountByIntBaseYr_MV2,      value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BFPrevResult_MV2,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_EarlyInitBFPrev_MV2,                  value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_BirthTermResult_MV2,                  value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercWomenAnemicResults_MV,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_NumWomenAnemicResults_MV,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_AnemiaCasesPreventedResults_MV,       value : null     },

        // // Incidence and etiology sector
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarIncResult_MV2,                    value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevereDiarIncResult_MV,               value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumIncResult_MV2,                   value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningIncResult_MV2,                  value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesResult_MV2,                  value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevereDiarCasesResult_MV,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesResult_MV2,                 value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningCasesResult_MV2,                value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesAvertByInter_MV3,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesAvertByInter_MV4,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevereDiarCasesAvertByInter_MV,       value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevereDiarCasesAvertByInter_MV2,      value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesAvertByInter_MV3,           value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeninCasesAvertByInter_MV3,           value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercDiarDeathsByPath_MV2,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercPneumDeathsByPath_MV3,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercMeningDeathsByPath_MV2,           value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarDeathsByPath_MV2,                 value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumDeathsByPath_MV2,                value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningDeathsByPath_MV2,               value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarDeathsByPathBy1yrAgeGrps_MV,      value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumDeathsByPathBy1yrAgeGrps_MV,     value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningDeathsByPathBy1yrAgeGrps_MV,    value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercDiarCasesByPath_MV2,              value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercSevDiarCasesByPath_MV,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercPneumCasesByPath_MV3,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PercMeningCasesByPath_MV2,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesByPath_MV2,                  value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevereDiarCasesByPath_MV,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesByPath_MV2,                 value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningCasesByPath_MV2,                value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesByPathBy1YrAgeGrps_MV,       value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevereDiarCasesByPathBy1YrAgeGrps_MV, value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesByPathBy1YrAgeGrps_MV,      value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningCasesByPathBy1YrAgeGrps_MV,     value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesByBirthCoh_MV2,              value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesByBirthCoh_MV2,             value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningCasesByBirthCoh_MV2,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevDiarCasesByBirthCoh_MV,            value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesAvtdByYr1stDoseVacc_MV2,     value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesAvtdByYr1stDoseVacc_MV2,    value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningCasesAvtdByYr1stDoseVacc_MV2,   value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevDiarCasesAvtdByYr1stDoseVacc_MV,   value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarDeathsByPathBirthCoh_MV2,         value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumDeathsByPathBirthCoh_MV2,        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningDeathsByPathBirthCoh_MV2,       value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesByPathBirthCoh_MV2,          value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesByPathBirthCoh_MV2,         value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningCasesByPathBirthCoh_MV2,        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_SevDiarCasesByPathBirthCoh_MV,        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarDeathsByPathBirthCohVC_MV,        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumDeathsByPathBirthCohVC_MV,       value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningDeathsByPathBirthCohVC_MV,      value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_DiarCasesByPathBirthCohVC_MV,         value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PneumCasesByPathBirthCohVC_MV,        value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_MeningCasesByPathBirthCohVC_MV,       value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PersonsVaccByInterv_MV2,              value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PersonsVaccByIntervBy1YrAgeGrps_MV,   value : null     },
        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_PersonsVaccByIntervBy1YrAgeGrps_MV2,  value : null     },

        // Demography sector
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_BigPop_MV3,                          value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_TFR_MV,                              value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_Births_MV,                           value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_LE_MV2,                              value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_AdjLE_MV,                            value : null     },
        { proj : 1, modID : CONSTANTS.GB_DP, tag : CONSTANTS.DP_TGX_Src,                                 value : null     },

        // { proj : 1, modID : CONSTANTS.GB_CS, tag : CONSTANTS.CS_TG_CustomCoD_MV,                       value : null     },
    ],

    CSSubNatModvars : [
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TFirstYear,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TTotalPop,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TTFR,                          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TCPR,                          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TIncidence,                    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TPMTCT,                        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TCotrim,                       value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TAdultART,                     value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TFirstTimeEnteringCoverage,    value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TSubnatDefDataMstIDset,        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TSubnatDataSourceArray,        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS_SubNat, tag : CONSTANTS.VW_TG_TUseSubnatData,                value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_Coverage_MV3,                  value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_CalcCovFromInputs_MV2,         value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_CovBY_MV,                      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_FirstYear_MV2,                 value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_EarlyInitBFPrev_MV2,           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_RoutDetVaccCov_MV3,            value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_PentavalentDetVaccCov_MV,      value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_SelectedIVSet_MV,              value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_MenARecommended,               value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_IPTpCBChecked_MV2,             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_EnterBFImproveData_MV2,        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_StuntingMatrixOn_MV2,          value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_WastingMatrixOn_MV2,           value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_BreastfeedPrev_MV,             value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_StuntingDistr_MV,              value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_SingleStuntingDistr_MV,        value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_WastingDistr_MV,               value : null     },
        { proj : 1, modID : CONSTANTS.GB_CS,        tag : CONSTANTS.CS_TG_SingleWastingDistr_MV,         value : null     },
        { proj : 1, modID : CONSTANTS.GB_FP,        tag : CONSTANTS.FP_TG_Tprevalence_MV,                value : null     },
],

    CSResults : [
        { proj : 1, modID : CONSTANTS.GB_CS, resultID : CONSTANTS.CS_MstChildDeaths,                   packTable : null, packChart : null },
        { proj : 1, modID : CONSTANTS.GB_CS, resultID : CONSTANTS.CS_MstChDeathsByCause,               packTable : null, packChart : null },
        { proj : 1, modID : CONSTANTS.GB_CS, resultID : CONSTANTS.CS_MstChDeathsPrByInter,             packTable : null, packChart : null },
        { proj : 1, modID : CONSTANTS.GB_CS, resultID : CONSTANTS.CS_MstMortalityRates,                packTable : null, packChart : null },
        { proj : 1, modID : CONSTANTS.GB_CS, resultID : CONSTANTS.CS_MstU5MortalityRate,               packTable : null, packChart : null },

        { proj : 1, modID : CONSTANTS.GB_CS, resultID : 1,                                             packTable : null, packChart : null },
        { proj : 1, modID : CONSTANTS.GB_CS, resultID : 3,                                             packTable : null, packChart : null },
        { proj : 1, modID : CONSTANTS.GB_CS, resultID : 14,                                            packTable : null, packChart : null },
    ],

    GuidedExploration : {
        MOOptions : {
            deliveryPointsContent  : [
                { mstID : CONSTANTS.CS_MstAllDelivPointsID,      name : 'GB_stAll',               checked : true },
                { mstID : CONSTANTS.CS_MstPericonceptualID,      name : 'GB_stPericonceptual',    checked : true },
                { mstID : CONSTANTS.CS_MstPregnancyID,           name : 'GB_stPregnancy',         checked : true },
                { mstID : CONSTANTS.CS_MstChildbirthID,          name : 'GB_stChildbirth',        checked : true },
                { mstID : CONSTANTS.CS_MstPreventiveID,          name : 'GB_stPreventive',        checked : true },
                { mstID : CONSTANTS.CS_MstVaccinesID,            name : 'GB_stVaccines',          checked : true },
                { mstID : CONSTANTS.CS_MstCurativeID,            name : 'GB_stCurative',          checked : true },
            ],
            maternalCausesOfDeathContent : [
                { mstID : CONSTANTS.CS_Mat_MstCODAll,            name : 'GB_stAll',               checked : true },
                { mstID : CONSTANTS.CS_Mat_MstAntepartHemorr,    name : 'GB_stAntepartumHem',     checked : true },
                { mstID : CONSTANTS.CS_Mat_MstIntrapartHemorr,   name : 'GB_stIntrapartumHem',    checked : true },
                { mstID : CONSTANTS.CS_Mat_MstPostpartHemorr,    name : 'GB_stPostpartumHem',     checked : true },
                { mstID : CONSTANTS.CS_Mat_MstHypertensiveDis,   name : 'GB_stHypertensive',      checked : true },
                { mstID : CONSTANTS.CS_Mat_MstSepsis,            name : 'GB_stSepsis',            checked : true },
                { mstID : CONSTANTS.CS_Mat_MstAbortion,          name : 'GB_stAbortion',          checked : true },
                { mstID : CONSTANTS.CS_Mat_MstEmbolism,          name : 'GB_stEmbolism',          checked : true },
                { mstID : CONSTANTS.CS_Mat_MstOtherDirectCauses, name : 'GB_stOtherDirectCauses', checked : true },
                { mstID : CONSTANTS.CS_Mat_MstIndirectCauses,    name : 'GB_stIndirectCauses',    checked : true },
            ],
            stillbirthCausesOfDeathContent : [
                { mstID : CONSTANTS.CS_SB_MstCODAll,             name : 'GB_stAll',               checked : true },
                { mstID : CONSTANTS.CS_SB_MstAntepartum,         name : 'GB_stAntepartum',        checked : true },
                { mstID : CONSTANTS.CS_SB_MstIntrapartum,        name : 'GB_stIntrapartum',       checked : true },
            ],
            neonatalCausesOfDeathContent : [
                { mstID : CONSTANTS.CS_MstNNCODAll,              name : 'GB_stAll',               checked : true },
                { mstID : CONSTANTS.CS_MstNNDiarr,               name : 'GB_stDiarrhea',          checked : true },
                { mstID : CONSTANTS.CS_MstNNSepsis,              name : 'GB_stSepsis',            checked : true },
                { mstID : CONSTANTS.CS_MstNNPneu,                name : 'GB_stPneumonia',         checked : true },
                { mstID : CONSTANTS.CS_MstNNAsphyxia,            name : 'GB_stAsphyxia',          checked : true },
                { mstID : CONSTANTS.CS_MstNNPreterm,             name : 'GB_stPrematurity',       checked : true },
                { mstID : CONSTANTS.CS_MstNNTetanus,             name : 'GB_stTetnus',            checked : true },
                { mstID : CONSTANTS.CS_MstNNCongen,              name : 'GB_stCongenital',        checked : true },
                { mstID : CONSTANTS.CS_MstNNOther,               name : 'GB_stOther',             checked : true },
            ],
            childCausesOfDeathContent : [
                { mstID : CONSTANTS.CS_MstChildCODAll,           name : 'GB_stAll',               checked : true },
                { mstID : CONSTANTS.CS_MstDiarrhea,              name : 'GB_stDiarrhea',          checked : true },
                { mstID : CONSTANTS.CS_MstPneumonia,             name : 'GB_stPneumonia',         checked : true },
                { mstID : CONSTANTS.CS_MstMeningitis,            name : 'GB_stMeningitis',        checked : true },
                { mstID : CONSTANTS.CS_MstMeasles,               name : 'GB_stMeasles',           checked : true },
                { mstID : CONSTANTS.CS_MstMalaria,               name : 'GB_stMalaria',           checked : true },
                { mstID : CONSTANTS.CS_MstPertussis,             name : 'GB_stPertussis',         checked : true },
                { mstID : CONSTANTS.CS_MstAIDS,                  name : 'GB_stAIDS',              checked : true },
                { mstID : CONSTANTS.CS_MstInjury,                name : 'GB_stInjury',            checked : true },
                { mstID : CONSTANTS.CS_MstOtherInfecDis,         name : 'GB_stOtherInfecDis',     checked : true },
                { mstID : CONSTANTS.CS_MstOtherNCD,              name : 'GB_stOtherNCD',          checked : true },
            ],
            displayMaternalCOD      : true,
            displayStillbirthCOD    : true,
            displayNeonatalCOD      : true,
            displayChildCOD         : true,
            displayCPR              : true,
            sortByDeathsAverted     : true,
            sortByInterventionCost  : false,
            viewInterventionCost    : false,
            displayChart            : true,
            MOResultToDisplay       : CONSTANTS.CS_PotentialDeathsAverted,
        },
        MOCountryData                : [],
        topMOInterventions           : [],
    },
};

export default CSTemplate;