import React from 'react';
import PropTypes from "prop-types";
import { CSEditor } from '../../../../components/CS/CSEditors';
import CONSTANTS from "utilities/CS/CSConst";
import EditorNote from "../../../../components/EditorNote";
import {BFPrevEditor} from "../../data/Coverage/CS/BFPrevEditor";

class CSBreastfeedingDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object,
        onPackTable1Change        : PropTypes.func,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable);
    };

    onPackTable2Change = (packTable) => {
        packTable = BFPrevEditor.RecalcGrid(packTable);
        this.props.onPackTable2Change(packTable);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {2}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstEarlyInitBFSheetID}
                    showTitle={true}
                    packTable={packTable1}
                    onChange={this.onPackTable1Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstLiSTOnlineBFPrevSheetID}
                    showTitle={true}
                    packTable={packTable2}
                    onChange={this.onPackTable2Change}
                />

            </div>
        );
    }

}

export default CSBreastfeedingDrawer;