import React from 'react';
import PropTypes from "prop-types";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import {getModvarCloneByTag} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import {StatusAtBirthEditor} from "../../data/HealthStatus/ChildHealth/StatusAtBirthEditor";
import EditorNote from "../../../../components/EditorNote";

class CSChildHealthDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object, 
        packTable3                : PropTypes.object,  
        onPackTable1Change        : PropTypes.func,
        onPackTable2Change        : PropTypes.func, 
        onPackTable3Change        : PropTypes.func,
        CSModvars                 : PropTypes.array,
        passData                  : PropTypes.func,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        packTable3                : null, 
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change        : (packTable) => console.log('onPackTable2Change'),
        onPackTable3Change        : (packTable) => console.log('onPackTable3Change'),
        CSModvars                 : [],
        passData                  : () => console.log('passData'),
    };

    state = {
        menAChecked : false,
    };

    componentDidMount() {
        let MenARecommended = getModvarCloneByTag(this.props.CSModvars, CONSTANTS.CS_TG_MenARecommended)["value"];
        this.setState({menAChecked: MenARecommended},
            () => this.props.passData({menAChecked: MenARecommended})
        );
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable); 
    };

    onPackTable2Change = (packTable) => {
        packTable = StatusAtBirthEditor.RecalcGrid(packTable);
        this.props.onPackTable2Change(packTable);
    };

    onPackTable3Change = (packTable) => {
        this.props.onPackTable3Change(packTable);  
    };

    onCheckBoxClick = () => {
        let checked = !this.state.menAChecked;
        this.setState({menAChecked: checked},
            () => this.props.passData({menAChecked: checked, saveBtnDisabled : false})
        );
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderCheckBox = () => {
        const styles = {
            checkBox: {
                marginTop: 10,
                marginBottom: 20,
                display:  "block"
            },
            checkBoxLabelStyle: {
                fontSize: '16px',
                cursor: "default"
            }
        };

        return (
            <TCheckBox
                style={styles.checkBox}
                boxStyle={styles.box}
                color='secondary'
                caption={RS('GB_stMenARecommended')}
                tooltip={""}
                labelStyle={styles.checkBoxLabelStyle}
                onClick={() => this.onCheckBoxClick()}
                value={this.state.menAChecked}
            />
        )
    };

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));
        let packTable3 = JSON.parse(JSON.stringify(this.props.packTable3));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {3}
                />

                {this.renderCheckBox()}

                <CSEditor
                    mstID={CONSTANTS.CS_MstNutrDefSheetID}
                    packTable={packTable1}
                    showTitle={true}
                    onChange={this.onPackTable1Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstStatusAtBirthSheetID}
                    packTable={packTable2}
                    showTitle={true}
                    onChange={this.onPackTable2Change}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstDeathCauseIncSheetID}
                    packTable={packTable3}
                    showTitle={true}
                    onChange={this.onPackTable3Change}
                />

            </div>
        );
    }

}

export default CSChildHealthDrawer;