import * as React from "react";
import PropTypes from "prop-types";
import Card from '@material-ui/core/Card/index';
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning"
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TLabel from "@common/components/TLabel";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSExpandResultBlueBox from './CSExpandResultBlueBox';
import {getAgeCohortName, getChildCODName, getMatCODName, getSBCODName, getSexName} from "utilities/CS/CSUtil";
//import Theme from "../../app/Theme";

class TCSResultCard extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange       : PropTypes.func,
        cardTitle           : PropTypes.string,
        cardTitleBackground : PropTypes.string,
        cardContent         : PropTypes.any,
        modID               : PropTypes.number,
        resultID            : PropTypes.number,
        selectedResults     : PropTypes.array,
        resultsContent      : PropTypes.object,
        chartOptions        : PropTypes.arrayOf(PropTypes.object),
        style               : PropTypes.object,
        caption             : PropTypes.string,
        isDeskTop           : PropTypes.bool,
        firstYear           : PropTypes.number,
        finalYear           : PropTypes.number,
        chartYears          : PropTypes.object,
        stacked             : PropTypes.string,
        legendReversed      : PropTypes.bool,
        displayCoverage     : PropTypes.bool,
        retroYears          : PropTypes.bool,
        displayEndpoints    : PropTypes.bool,
        IVInfo              : PropTypes.array,
        IVGroupInfo         : PropTypes.array,
        CSModvars           : PropTypes.array,
        projectionName      : PropTypes.string,
        coverageDisplay     : PropTypes.string,
        language            : PropTypes.number,
        resultCats          : PropTypes.array,
        onOpenHelpDialog    : PropTypes.func
    };

    static defaultProps = {
        onStateChange       : () => console.log("onStateChange"),
        cardTitle           : 'Title',
        cardTitleBackground : '#9cc025', //'#efefef' //'#ace6ac' //#ffd18b
        cardContent         : null,
        modID               : 0,
        resultID            : 0,
        selectedResults     : [],
        resultsContent      : {},
        chartOptions        : [],
        style               : {},
        isDeskTop           : true,
        firstYear           : new Date().getFullYear(),
        finalYear           : CONSTANTS.CS_DefaultFinalYear,
        chartYears          : {},
        stacked             : '',
        legendReversed      : false,
        displayCoverage     : false,
        retroYears          : false,
        displayEndpoints    : false,
        IVInfo              : [],
        IVGroupInfo         : [],
        CSModvars           : [],
        projectionName      : "",
        coverageDisplay     : CONSTANTS.CS_TableChart,
        language            : CONSTANTS.GB_English,
        resultCats          : [],
        onOpenHelpDialog    : () => console.log("onOpenHelpDialog")
    };

    state = {
        openDrawer: false
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onCloseBtnClick = (e) => {
        e.stopPropagation();//this is to prevent the header's click event on the X click event
        let selResults = JSON.parse(JSON.stringify(this.props.selectedResults));
        for (let i = 0; i < selResults.length; i++) {
            if ((selResults[i][1] === this.props.modID) && (selResults[i][0] === this.props.resultID)) {
                selResults.splice(i,1);
            }
        }
        this.props.onStateChange ({
            selectedResults : selResults
        })
    };

    onHeaderClick = () => {
        this.setState({
            openDrawer : true,
        });

        let idx = 0;
        for (let i = 0; i < this.props.chartOptions.length; i++){
            if ((this.props.chartOptions[i]['modID'] === this.props.modID) &&
                (this.props.chartOptions[i]['resultID'] === this.props.resultID)) {
                idx = i;
                break;
            }
        }

        if (this.props.chartOptions[idx]['typeOfDisplay'] === CONSTANTS.CS_BarChart) {
            this.props.onStateChange({
                stacked: 'normal',
                legendReversed: true
            })
        }
        else {
            this.props.onStateChange({
                stacked: 'normal',
                legendReversed: false
            })
        }
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderHeader = () => {
        const styles = {
            cardHeader: {
                height: 50,
                width: '100%',
                padding: '5px 10px',
                boxSizing: 'border-box',
                background : this.props.cardTitleBackground,
                cursor: "pointer"
            },
            cardCaption : {
                float: 'left',
                width: '84%',
                fontSize: '14px',
                userSelect: 'none'
            },
            closeBtnStyle : {
                float: 'right',
                width: 20,
                height: 20,
                padding: 2,
                cursor: 'pointer',
                boxSizing: 'border-box',
                // marginLeft: '5px'
            },
            closeIconStyle : {
                fontSize: '14px',
                color: "#000000"
            },
        };

        //the following allows the removal of line and bar charts when breastfeeding prevalence is showing all ages
        // let chartOptions = JSON.parse(JSON.stringify(this.props.chartOptions));
        // for (let i = 0; i < chartOptions.length; i++) {
        //     if (chartOptions[i].modID === CONSTANTS.GB_CS && chartOptions[i].resultID === CONSTANTS.CS_MstBFPrevalence) {
        //         if (chartOptions[i].ageIdx === 0) {
        //             chartOptions[i].chartTypes = [CONSTANTS.CS_TableChart];
        //             chartOptions[i].typeOfDisplay = CONSTANTS.CS_TableChart;
        //         } else {
        //             chartOptions[i].chartTypes = [CONSTANTS.CS_LineChart, CONSTANTS.CS_BarChart, CONSTANTS.CS_TableChart];
        //         }
        //     }
        // }

        // let opts = this.props.chartOptions.find(x => ((x.modID === this.props.modID) && (x.resultID === this.props.resultID)));
        // let config = [];
        // if (opts.age.length > 0) {
        //     config.push(getAgeCohortName(opts.age[opts.ageIdx]));
        // }
        // if (opts.cod.length > 0) {
        //     if (opts.codCat === CONSTANTS.CS_MstMaternalCODCat) {
        //         config.push(getMatCODName(opts.cod[opts.codIdx]));
        //     } else if (opts.codCat === CONSTANTS.CS_MstStillbirthCODCat) {
        //         config.push(getSBCODName(opts.cod[opts.codIdx]));
        //     } else if (opts.codCat === CONSTANTS.CS_MstNeonatalCODCat) {
        //         config.push(getChildCODName(opts.cod[opts.codIdx]));
        //     } else if (opts.codCat === CONSTANTS.CS_MstChildCODCat) {
        //         config.push(getChildCODName(opts.cod[opts.codIdx + (opts.ageIdx === CONSTANTS.CS_1t59months ? CONSTANTS.CS_FinalStdNNDeath : 0)]));
        //     }
        // }
        // if (opts.sex.length > 0) {
        //     config.push(getSexName(opts.sex[opts.sexIdx]));
        // }
        //
        // let capt = config.length > 0 ? this.props.cardTitle + " (" + config.join(", ") + ")" : this.props.cardTitle;
        let titleMax = 70;

        return ( 
            <div onClick={this.onHeaderClick} style={styles.cardHeader}>

                <div style={styles.cardCaption}>
                    {this.props.cardTitle.length > titleMax ? this.props.cardTitle.substr(0, titleMax).trim() + "..." : this.props.cardTitle}
                </div>

                <Tooltip title={RS('GB_stClose')}>
                    <IconButton
                        className="icon-hover"
                        onClick={this.onCloseBtnClick}
                        style={styles.closeBtnStyle}>
                        <CloseIcon style={styles.closeIconStyle} />
                    </IconButton>
                </Tooltip>

                <CSExpandResultBlueBox
                    onStateChange   = {this.props.onStateChange}
                    caption         = {this.props.cardTitle}
                    resultsContent  = {this.props.resultsContent}
                    modID           = {this.props.modID}
                    resultID        = {this.props.resultID}
                    chartOptions    = {this.props.chartOptions}
                    isDeskTop       = {this.props.isDeskTop}
                    firstYear       = {this.props.firstYear}
                    finalYear       = {this.props.finalYear}
                    chartYears      = {this.props.chartYears}
                    stacked         = {this.props.cardTitle === RS("GB_stChildDeaths") ? '' : 'normal'/*this.props.stacked*/}
                    legendReversed  = {this.props.legendReversed}
                    //iconBtnDisabled = {!this.props.cardContent['props']['packChart']['hasData']}
                    displayEndpoints= {this.props.displayEndpoints}
                    displayCoverage = {this.props.displayCoverage}
                    retroYears      = {this.props.retroYears}
                    IVInfo          = {this.props.IVInfo}
                    IVGroupInfo     = {this.props.IVGroupInfo}
                    CSModvars       = {this.props.CSModvars}
                    projectionName  = {this.props.projectionName}
                    coverageDisplay = {this.props.coverageDisplay}
                    language        = {this.props.language}
                    resultCats      = {this.props.resultCats}
                    openDrawer      = {this.state.openDrawer}
                    passData        = {(o) => this.setState(o)}
                    onOpenHelpDialog= {this.props.onOpenHelpDialog}
                />

            </div>
        );
    };

    renderSummary = () => {
        const styles = {
            outerBoxStyle : {
                marginTop: '7px',
                marginLeft: '10px',
                marginRight: '10px',
                padding: '2px',
                border: '2px solid #434348',
                borderRadius: 5
            },
            boxStyle : {
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '2px solid #7CB5EC',
                borderRadius: 5
            },
            tableNameStyle : {
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: '8px',
                color: '#1d509e'
            },
            lineStyle : {
                marginBottom: '4px'
            },
            textStyle : {
                fontSize: '12px',
                color: "#CB6814",
                display: 'inline',
                fontWeight: 'bold'
            },
            textStyle1 : {
                fontSize: '12px',
                display: 'inline',
            }
        };

        let dat = this.props.resultsContent.packTable.tableData.value;
        let stats = [];
        for (let i = 1; i <= 4; i++) {
            stats.push(<div key={"SummaryStat" + i} style={styles.lineStyle}><div style={styles.textStyle}>{dat[i][0].substr(0, dat[i][0].indexOf(" (")) + ': '}</div><div style={styles.textStyle1}>{dat[i][dat[0].length - 1].toFixed(2)}</div></div>);
        }

        return (
            <div style={styles.outerBoxStyle}>
                <div style={styles.boxStyle}>
                    <div style={styles.tableNameStyle}>{'Summary for ' + dat[0][dat[0].length - 1]}</div>
                    {stats}
                </div>
            </div>
        );
    };

    renderPrevalence = () => {
        const styles = {
            outerBoxStyle : {
                marginTop: '7px',
                marginLeft: '10px',
                marginRight: '10px',
                padding: '2px',
                border: '2px solid #434348',
                borderRadius: 5
            },
            boxStyle : {
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '2px solid #7CB5EC',
                borderRadius: 5
            },
            tableNameStyle : {
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: '8px',
                color: '#1d509e'
            },
            lineStyle : {
                marginBottom: '4px'
            },
            textStyle : {
                fontSize: '14px',
                color: "#CB6814",
                display: 'inline',
                fontWeight: 'bold'
            },
            textStyle1 : {
                fontSize: '12px',
                display: 'inline',
            }
        };

        let dat = this.props.resultsContent.packTable.tableData.value;
        let stats = [];
        for (let i = 1; i <= 5; i++) {
            stats.push(<div key={"PrevalenceStat" + i} style={styles.lineStyle}><div style={i === 1 ? styles.textStyle : {...styles.textStyle, fontSize: 12, color: "black"}}>{(i === 1 ? dat[i][0] + " & " + dat[i + 5][0] : dat[i][0])}</div></div>);
        }
        for (let i = 11; i <= 13; i++) {
            stats.push(<div key={"PrevalenceStat" + i} style={styles.lineStyle}><div style={i === 11 ? styles.textStyle : {...styles.textStyle, fontSize: 12, color: "black"}}>{(i === 11 ? dat[i][0] + " & " + dat[i + 3][0] : dat[i][0])}</div></div>);
        }

        return (
            <div style={styles.outerBoxStyle}>
                <div style={styles.boxStyle}>
                    {/*<div style={styles.tableNameStyle}>{'Summary for ' + dat[0][dat[0].length - 1]}</div>*/}
                    {stats}
                </div>
            </div>
        );
    };

    renderPathogens = () => {
        const styles = {
            outerBoxStyle : {
                marginTop: '7px',
                marginLeft: '10px',
                marginRight: '10px',
                padding: '2px',
                border: '2px solid #434348',
                borderRadius: 5
            },
            boxStyle : {
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '2px solid #7CB5EC',
                borderRadius: 5
            },
            tableNameStyle : {
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: '8px',
                color: '#1d509e'
            },
            lineStyle : {
                marginBottom: '4px'
            },
            textStyle : {
                fontSize: '14px',
                color: "#CB6814",
                display: 'inline',
                fontWeight: 'bold'
            },
            textStyle1 : {
                fontSize: '12px',
                display: 'inline',
            }
        };

        return (
            <div style={styles.outerBoxStyle}>
                <div style={styles.boxStyle}>
                    <div style={styles.tableNameStyle}>{this.props.cardTitle}</div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>{RS("GB_stDiarrhea")}</div></div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>{RS("GB_stPneumonia")}</div></div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>{RS("GB_stMeningitis")}</div></div>
                </div>
            </div>
        );
    };

    renderIntervByCause = () => {
        const styles = {
            outerBoxStyle : {
                marginTop: '7px',
                marginLeft: '10px',
                marginRight: '10px',
                padding: '2px',
                border: '2px solid #434348',
                borderRadius: 5
            },
            boxStyle : {
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '2px solid #7CB5EC',
                borderRadius: 5
            },
            tableNameStyle : {
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: '8px',
                color: '#1d509e'
            },
            lineStyle : {
                marginBottom: '4px'
            },
            textStyle : {
                fontSize: '14px',
                color: "#CB6814",
                display: 'inline',
                fontWeight: 'bold'
            },
            textStyle1 : {
                fontSize: '12px',
                display: 'inline',
            }
        };

        return (
            <div style={styles.outerBoxStyle}>
                <div style={styles.boxStyle}>
                    <div style={styles.tableNameStyle}>{this.props.cardTitle}</div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>{RS("GB_stDiarrheaSevere")}</div></div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>{RS("GB_stPneumonia")}</div></div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>{RS("GB_stMeningitis")}</div></div>
                </div>
            </div>
        );
    };

    renderReducedMort = () => {
        const styles = {
            outerBoxStyle : {
                marginTop: '7px',
                marginLeft: '10px',
                marginRight: '10px',
                padding: '2px',
                border: '2px solid #434348',
                borderRadius: 5
            },
            boxStyle : {
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '2px solid #7CB5EC',
                borderRadius: 5
            },
            tableNameStyle : {
                fontWeight: 'bold',
                fontSize: '14px',
                marginBottom: '8px',
                color: '#1d509e'
            },
            lineStyle : {
                marginBottom: '4px',
                fontSize: 14
            },
            textStyle : {
                fontSize: '14px',
                color: "#CB6814",
                display: 'inline',
                fontWeight: 'bold'
            },
            textStyle1 : {
                fontSize: '12px',
                display: 'inline',
            }
        };

        let dat = this.props.resultsContent.packTable.tableData.value;
        let last = dat.length - 1;

        return (
            <div style={styles.outerBoxStyle}>
                <div style={styles.boxStyle}>
                    <div style={styles.tableNameStyle}>{dat[0][2]}</div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>&nbsp;</div></div>
                    <div style={styles.lineStyle}><div style={styles.textStyle}>{parseFloat(dat[last][2]).toFixed(0) + "%"}</div></div>
                </div>
            </div>
        );
    };

    renderMedia = () => {
        const styles = {
            cardMedia : {
                height: 250,
                width: 325,
                border: (this.props.media === null) ? '1px dotted #CCCCCC' : 'none',
                marginBottom: 0,
                marginRight: 0,
                overflow: 'hidden',
                padding: 10
            },
            labelStyle : {
                fontSize : '18px',
                color : "#CB6814",
                display : 'inline',
                marginLeft: '10px',
                userSelect: 'none'
            },
            warningIcon : {
                fontSize : '30px',
                color : "#CB6814",
                marginTop: '10px',
                marginBottom: '-5px'
            },
            capt: {
                fontWeight: "normal",
                fontSize: 12,
                color: "#333333",
                textAlign: "center"
            }
        };

        let BFPrev = this.props.chartOptions.find(x => x.resultID === CONSTANTS.CS_MstBFPrevalence);
        // let MatLives = this.props.chartOptions.find(x => x.resultID === CONSTANTS.CS_MstMDeathsPrByInterByCause);

        let opts = this.props.chartOptions.find(x => ((x.modID === this.props.modID) && (x.resultID === this.props.resultID)));
        let config = [];

        if (Array.isArray(opts['age'])) {
            if (opts.age.length > 0) {
                config.push(getAgeCohortName(opts.age[opts.ageIdx]));
            }
        }
        else{
            if(opts.age.singleAge){
                config.push('Single age');
            }
            else if (opts.age.fiveYearAge){
                config.push('Five year age');
            }
        }

        if (opts.cod.length > 0) {
            if (opts.codCat === CONSTANTS.CS_MstMaternalCODCat) {
                config.push(getMatCODName(opts.cod[opts.codIdx]));
            } else if (opts.codCat === CONSTANTS.CS_MstStillbirthCODCat) {
                config.push(getSBCODName(opts.cod[opts.codIdx]));
            } else if (opts.codCat === CONSTANTS.CS_MstNeonatalCODCat) {
                config.push(getChildCODName(opts.cod[opts.codIdx]));
            } else if (opts.codCat === CONSTANTS.CS_MstChildCODCat) {
                config.push(getChildCODName(opts.cod[opts.codIdx + (opts.ageIdx === CONSTANTS.CS_1t59months ? CONSTANTS.CS_FinalStdNNDeath : 0)]));
            }
        }

        if (Array.isArray(opts['sex'])) {
            if (opts.sex.length > 0) {
                config.push(getSexName(opts.sex[opts.sexIdx]));
            }
        }
        else{
            if(opts.sex.both){
                config.push(getSexName(CONSTANTS.DP_BothSexes));
            }
            else if (opts.sex.male){
                config.push(getSexName(CONSTANTS.DP_Male));
            }
            else if (opts.sex.female){
                config.push(getSexName(CONSTANTS.DP_Female));
            }
        }

        let capt = config.join(", ");

        let isSummary = this.props.cardTitle === RS("GB_stMortRatesSummary");
        let isPrevalence = this.props.cardTitle === RS("GB_stBFprevalence");
        let isNumDeathsDuePaths = this.props.cardTitle === RS("GB_stNumDeathsDuePaths");
        let isNumCasesByPath = this.props.cardTitle === RS("GB_stNumCasesByPath");
        let isPercDeathsDuePaths = this.props.cardTitle === RS("GB_stPercentOf1t59MoDeathsDueToPathogens");
        let isPercCasesByPath = this.props.cardTitle === RS("GB_stPerc1t59MoCasesByPath");
        let isNumU5Cases = this.props.cardTitle === RS("GB_stNumU5CasesAvtdByCauseRelImpYear");
        let isRedMort = this.props.cardTitle.includes(RS("GB_stRedMortByInterv"));

        let packChart = this.props.cardContent['props']['packChart'];
        let packChartHasData = packChart.chartData.length > 0; // packChart['hasData'];

        if (!packChartHasData) {
            return (
                <div style={styles.cardMedia}>
                    <div style={{marginLeft: '10px'}}>
                        <p style={styles.capt}>{capt}</p>
                        <WarningIcon style={styles.warningIcon}/>
                        <TLabel
                            caption={RS('GB_stNoDataLabel')}
                            style={styles.labelStyle}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div style={styles.cardMedia}>
                {
                    isSummary ? 
                        this.renderSummary() : 
                        isPrevalence && BFPrev["ageIdx"] === 0 ? 
                            this.renderPrevalence() : 
                            (isNumDeathsDuePaths || isNumCasesByPath || isPercDeathsDuePaths || isPercCasesByPath) ? 
                                this.renderPathogens() : 
                                isNumU5Cases ? 
                                    this.renderIntervByCause() : 
                                    isRedMort ? 
                                        this.renderReducedMort() : 
                                        this.props.cardContent
                }
            </div>
        );
    };

    renderCard = () => {

        const styles = {
            card : {
                width: '325px',
                height: '300px',
                display: 'inline-block',
                marginRight: 20,
                marginBottom: 20,
                position: 'relative'
            }
        };

        return (
            <Card style={{...styles.card, ...this.props.style}}>
                {this.renderHeader()}
                {this.renderMedia()}
            </Card>
        ); 
    };

    render() {
        return (
            <div style={{display: 'inline-block'}} >
                {this.renderCard()}
            </div>
        );
    }
}

export default TCSResultCard;