import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';
import {PathogenEditor} from "../../data/HealthStatus/Pathogens/PathogenEditor";

class CSPathogensDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object, 
        onPackTable1Change        : PropTypes.func,
    };

    static defaultProps = {
        packTable1                : null,
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        packTable = PathogenEditor.RecalcGrid(packTable);
        this.props.onPackTable1Change(packTable);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {1}
                />

                {/*<GBStdTable*/}
                    {/*id={"MySheet1"}*/}
                    {/*packTable={packTable1}*/}
                    {/*minHeight={650}*/}
                    {/*maxHeight={650}*/}
                    {/*bandColor={Theme.editor.bandColor}*/}
                    {/*fixedRowColor={Theme.editor.fixedRowColor}*/}
                    {/*highlightColor={Theme.editor.highlightColor}*/}
                    {/*cellPadding={Theme.editor.cellPadding}*/}
                    {/*onSheetCellsChanged={this.onPackTable1Change}*/}
                {/*/>*/}

                <CSEditor
                    mstID={CONSTANTS.CS_MstPathIncMortSheetID}
                    packTable={packTable1}
                    showTitle={true}
                    onChange={this.onPackTable1Change}
                />

            </div>
        );
    }

}

export default CSPathogensDrawer;