import React from "react";
import PropTypes from "prop-types";
import Page from "@common/components/Page";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import ProjectionsPage from "./ProjectionsPage";
import IndicatorsPage from "./IndicatorsPage";
import ConfigurationPage from "./ConfigurationPage";
import ProcessPage from "./ProcessPage";
import NotSignedInPage from "./NotSignedInPage";

class Pages extends React.Component {
    /* prettier-ignore */
    static propTypes = {
        appState             : PropTypes.object,
        appBarHeight         : PropTypes.number,
        onPageChange         : PropTypes.func,
        onStateChange        : PropTypes.func, 
        onAddTasks           : PropTypes.func,
        onLogin              : PropTypes.func,
        onStepChange         : PropTypes.func,
        onProcessProjections : PropTypes.func,
        callAPI              : PropTypes.func,
        onUploadProjection   : PropTypes.func,
        Theme                : PropTypes.any.isRequired,
    };

    /* prettier-ignore */
    static defaultProps = {
        appState             : {},
        appBarHeight         : window.innerWidth < 1375 ? 105 : 64,
        onPageChange         : () => console.log("AppContent onPageChange"),
        onStateChange        : () => console.log("AppContent onStateChange"),
        onAddTasks           : () => console.log("AppContent onAddTasks"),
        onLogin              : () => {},
        onStepChange         : () => {},
        onProcessProjections : () => {},
        callAPI              : () => {},
        onUploadProjection   : () => {},
    };

    render() {
        const { pageID, sideBarWidth = 0 } = this.props.appState;
        const { appBarHeight, Theme } = this.props;

        const styles = {
            appContent: {
                marginTop: appBarHeight,
                marginLeft: sideBarWidth,
                marginBottom: 0,
            },
        };

        const style = styles.appContent;

        switch (pageID) {
            case "HomePage":
                return (
                    <Page pageID={pageID} style={style}>
                        <HomePage
                            isGuest={this.props.appState.isGuest}
                            onPageChange={this.props.onPageChange}
                        />
                    </Page>
                );

            case "NotSignedInPage":
                    return (
                        <Page pageID={pageID} style={style}>
                            <NotSignedInPage
                                onPageChange={this.props.onPageChange}
                            />
                        </Page>
                    );

            case "LoginPage":
                return (
                    <Page pageID={pageID} style={style}>
                        <LoginPage
                            onLogin={this.props.onLogin}
                            onPageChange={this.props.onPageChange}
                            Theme={Theme}
                        />
                    </Page>
                );

            case "ProjectionsPage":
                return (
                    <Page pageID={pageID} style={style}>
                        <ProjectionsPage
                            storedProjections={this.props.appState.storedProjections}
                            activeProjections={this.props.appState.activeProjections}
                            onPageChange={this.props.onPageChange}
                            onStateChange={this.props.onStateChange}
                            onStepChange={this.props.onStepChange}
                            stepsCompleted={this.props.appState.stepsCompleted}
                            projectionsSelected={this.props.appState.projectionsSelected}
                            indicatorsSelected={this.props.appState.indicatorsSelected}
                            onUploadProjection={this.props.onUploadProjection}
                            years={this.props.appState.years}
                            Theme={Theme}
                        />
                    </Page>
                );

            case "IndicatorsPage":
                return (
                    <Page pageID={pageID} style={style}>
                        <IndicatorsPage
                            onPageChange={this.props.onPageChange}
                            onStateChange={this.props.onStateChange}
                            onStepChange={this.props.onStepChange}
                            stepsCompleted={this.props.appState.stepsCompleted}
                            indicatorsSelected={this.props.appState.indicatorsSelected}
                            Theme={Theme}
                        />
                    </Page>
                );

            case "ConfigurationPage":
                return (
                    <Page pageID={pageID} style={style}>
                        <ConfigurationPage
                            onPageChange={this.props.onPageChange}
                            onStateChange={this.props.onStateChange}
                            onStepChange={this.props.onStepChange}
                            stepsCompleted={this.props.appState.stepsCompleted}
                            fileFormat={this.props.appState.fileFormat}
                            years={this.props.appState.years}
                            transposeYears={this.props.appState.transposeYears}
                            recalculateProjs={this.props.appState.recalculateProjs}
                            localCurrency={this.props.appState.localCurrency}
                            fullPrecision={this.props.appState.fullPrecision}
                            MinProjFirstYr={this.props.appState.MinProjFirstYr}
                            MaxProjFinalYr={this.props.appState.MaxProjFinalYr}
                            Theme={Theme}
                />
                    </Page>
                );

            case "ProcessPage":
                return (
                    <Page pageID={pageID} style={style}>
                        <ProcessPage
                            fileFormat={this.props.appState.fileFormat}
                            onPageChange={this.props.onPageChange}
                            onStateChange={this.props.onStateChange}
                            onStepChange={this.props.onStepChange}
                            stepsCompleted={this.props.appState.stepsCompleted}
                            onProcessProjections={this.props.onProcessProjections}
                            projectionsSelected={this.props.appState.projectionsSelected}
                            indicatorsSelected={this.props.appState.indicatorsSelected}
                            API={this.props.callAPI}
                            recalculateProjs={this.props.appState.recalculateProjs}
                            years={this.props.appState.years}
                            MinProjFirstYr={this.props.appState.MinProjFirstYr}
                            MaxProjFinalYr={this.props.appState.MaxProjFinalYr}
                            exportFileName={this.props.appState.exportFileName}
                            Theme={Theme}
                />
                    </Page>
                );

            default:
                return (
                    <Page pageID={pageID} style={style}>
                        {/* <PageMissing pageID={pageID} onPageChange={this.props.onPageChange} /> */}
                    </Page>
                );
        }
    }
}

export default React.memo(Pages);
