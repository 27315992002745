import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    addRowToPackTable,
    addMergedCellsToArray,
    indentPackTable, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {CSGetHerdDiseases, CSGetHerdName, getCSGetDeathCause, getAgeCohortName} from "utilities/CS/CSDataUtil";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const HerdEffectivenessEditor = {
    Create : (CSModvars, vt, ExploreData = false) => {
        let editorName = CSGetHerdName(vt);
        if (ExploreData) {
            editorName = RS('GB_stHerdEff') + ' - ' + editorName;
        }
        let packTable = Setup(CSModvars, vt);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_HerdEffectiveness;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let CustomHerdVaccEff = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CustomHerdVaccEff_MV3);
        let row = 1;
        CSGetHerdDiseases(pt.vt).forEach(function(d){
            row++;
            for(let a = 1; a <= CONSTANTS.CS_24t60months; a++) {
                for(let col = 1; col <= 21; col++) {
                    CustomHerdVaccEff["value"][CONSTANTS.CS_Data][pt.vt][d][col][a] = pt.tableData.value[row][col];
                }
                row++;
            }
            CustomHerdVaccEff["source"][d][pt.vt] = pt.Source[pt.SourceMap[row]];
        });

        changedModvars.push(CustomHerdVaccEff);

        return (changedModvars);
    },
};

const Setup = (CSModvars, vt, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    let numCols = 22;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 1;

    pt = resizePackTable(pt, numRows, numCols);

    if (pt.yearArr.length > 0) {
        let col = 1;
        pt.yearArr.forEach(function (t) {
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            for (let row = 1; row <= CONSTANTS.CS_24t60months; row++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
            }
            col++;
        });
    }
    else{
        for(let col = 1; col <= 21; col++) {
            pt.tableData.value[0][col] = ((col-1) * 5).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
        }
    }

    pt.numSources = 0;

    let row = 0;
    CSGetHerdDiseases(vt).forEach(function(d){
        row++;
        pt.numSources++;
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = getCSGetDeathCause(CSModvars, d);
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
        for(let a = 1; a <= CONSTANTS.CS_24t60months; a++) {
            row++;
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = getAgeCohortName(a, true);
            pt.SourceTitle[pt.numSources] = CSGetHerdName(vt) + ' - ' + getCSGetDeathCause(CSModvars, d);
            pt.SourceMap[row] = pt.numSources;
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            for(let col = 1; col <= 21; col++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
            }
        }
    });

    pt.vt = vt;

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let CustomHerdVaccEff = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CustomHerdVaccEff_MV3);
    if (pt.yearArr.length > 0) {
        let col = 1;
        pt.yearArr.forEach(function (t) {
            let row = 1;
            CSGetHerdDiseases(pt.vt).forEach(function(d){
                row++;
                pt.Source[pt.SourceMap[row]] = CustomHerdVaccEff["source"][d][pt.vt];
                for(let a = 1; a <= CONSTANTS.CS_24t60months; a++) {
                    if (CustomHerdVaccEff["exploreLiST"][pt.vt][d][col][a][t] >= 0) {
                        pt.tableData.value[row][col] = CustomHerdVaccEff["exploreLiST"][pt.vt][d][col][a][t];
                    }
                    row++;
                }
            });
            col++;
        });
    }
    else {
        let row = 0;
        CSGetHerdDiseases(pt.vt).forEach(function(d){
            row++;
            pt.Source[pt.SourceMap[row]] = CustomHerdVaccEff["source"][d][pt.vt];
            for(let a = 1; a <= CONSTANTS.CS_24t60months; a++) {
                row++;
                for(let col = 1; col <= 21; col++) {
                    pt.tableData.value[row][col] = CustomHerdVaccEff["value"][CONSTANTS.CS_Data][pt.vt][d][col][a];
                }
            }
        });
    }
    return pt;
};

