import React from 'react';

const ExploreHeading = (props) => {

    const styles = {
        filterDataDiv: {
            width: '300px',
            backgroundColor: '#FFFFFF',

        },
        filterDataText: {
            color: 'black', // '#05225D',
            fontSize: '18px',
            fontWeight: 'bold',
            userSelect: 'none',
            marginTop: '0px',
            // marginBottom: '-5px',
            marginBottom: '5px',
        }
    };

    return (
        <div style={styles.filterDataDiv}>
            <p style={styles.filterDataText}>{props.string}</p>
        </div>
    );

}

export default ExploreHeading;