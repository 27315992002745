import { fieldNamePrefix } from "./constants";

export const clone = data => JSON.parse(JSON.stringify(data));

export const createVector = (length, value) => Array(length).fill(value);

export const createMatrix = (rowCount, columnCount, value) => Array(rowCount).fill(Array(columnCount).fill(value));

export const size1dArray = (array, row, defaultVal = 0) => {
    let array2 = [];

    for (let x = 0; x < row; x++) {
        array2[x] = defaultVal;
    }

    if (Math.min(row - 1, array.length - 1) > 0) {
        for (let x = 0; x <= Math.min(row - 1, array.length - 1); x++) {
            array2[x] = array[x];
        }
    }

    return array2;
};

export const size2dArray = (array, row, col, defaultVal = 0) => {
    let array2 = [];

    for (let x = 0; x < row; x++) {
        array2[x] = [];
        for (let y = 0; y < col; y++) {
            array2[x][y] = clone(defaultVal);
        }
    }

    if (Math.min(row - 1, array.length - 1) > 0) {
        for (let x = 0; x <= Math.min(row - 1, array.length - 1); x++) {
            if (Math.min(col - 1, array[x].length - 1) > 0) {
                for (let y = 0; y <= Math.min(col - 1, array[x].length - 1); y++) {
                    array2[x][y] = array[x][y];
                }
            }
        }
    }

    return array2;
};

export const createArray = (n, bounds, value) => {
    let len = bounds.shift();

    if (n > 1) {
        return Array(len).fill(createArray(n - 1, bounds, value));
    } else {
        return Array(len).fill(value);
    }
};

//------------------------------------------------------------------------------------------------------

export const hash = function(s, seed = 0) {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;

    for (let i = 0, c; i < s.length; i++) {
        c = s.charCodeAt(i);
        h1 = Math.imul(h1 ^ c, 2654435761);
        h2 = Math.imul(h2 ^ c, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

// https://github.com/lydell/json-stringify-pretty-compact/blob/master/index.js + custom changes

export function stringify(obj, options) {
    options = options || {};
    var indent = JSON.stringify([1], null, get(options, "indent", 2)).slice(2, -3);
    var addMargin = get(options, "margins", false);
    var maxLength = indent === "" ? Infinity : get(options, "maxLength", 80);

    return (function _stringify(obj, currentIndent, reserved) {
        if (obj && typeof obj.toJSON === "function") {
            obj = obj.toJSON();
        }

        var string = JSON.stringify(obj);

        if (string === undefined) {
            return string;
        }

        var length = maxLength - currentIndent.length - reserved;

        if (string.length <= length) {
            if (string[0] !== "{") {
                // TGP
                if (!(string[0] === "[" && string[1] === "[")) {
                    // TGP
                    if (!(string[0] === "[" && string[1] === "{")) {
                        // TGP
                        var prettified = prettify(string, addMargin);
                        if (prettified.length <= length) {
                            return prettified;
                        }
                    }
                }
            }
        }

        if (typeof obj === "object" && obj !== null) {
            var nextIndent = currentIndent + indent;
            var items = [];
            var delimiters;
            var comma = function(array, index) {
                return index === array.length - 1 ? 0 : 1;
            };

            if (Array.isArray(obj)) {
                for (var index = 0; index < obj.length; index++) {
                    items.push(_stringify(obj[index], nextIndent, comma(obj, index)) || "null");
                }
                delimiters = "[]";
            } else {
                Object.keys(obj).forEach(function(key, index, array) {
                    var keyPart = JSON.stringify(key) + ": ";
                    var value = _stringify(obj[key], nextIndent, keyPart.length + comma(array, index));
                    if (value !== undefined) {
                        items.push(keyPart + value);
                    }
                });
                delimiters = "{}";
            }

            if (items.length > 0) {
                return [delimiters[0], indent + items.join(",\n" + nextIndent), delimiters[1]].join(
                    "\n" + currentIndent
                );
            }
        }

        return string;
    })(obj, "", 0);
}

// Note: This regex matches even invalid JSON strings, but since we’re
// working on the output of `JSON.stringify` we know that only valid strings
// are present (unless the user supplied a weird `options.indent` but in
// that case we don’t care since the output would be invalid anyway).
var stringOrChar = /("(?:[^\\"]|\\.)*")|[:,\][}{]/g;

function prettify(string, addMargin) {
    var m = addMargin ? " " : "";
    var tokens = {
        "{": "{" + m,
        "[": "[" + m,
        "}": m + "}",
        "]": m + "]",
        ",": ", ",
        ":": ": "
    };
    return string.replace(stringOrChar, function(match, string) {
        return string ? match : tokens[match];
    });
}

function get(options, name, defaultValue) {
    return name in options ? options[name] : defaultValue;
}

//------------------------------------------------------------------------------------------------------

export const copyToClipboard = text => {
    const element = document.createElement("textarea");

    element.value = text;

    element.setAttribute("readonly", "");
    element.style.position = "absolute";
    element.style.left = "-9999px";

    let anchorElement = document.body;

    const anchorElements = document.getElementsByClassName("clipboard-anchor");

    if (anchorElements && anchorElements.length) {
        anchorElement = anchorElements[0];
    }

    anchorElement.appendChild(element);

    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

    element.select();
    document.execCommand("copy");

    anchorElement.removeChild(element);

    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
};

export const copyJsonToClipboard = text => {
    // TGP Convert to a nicer format
    const json = JSON.parse(text);
    const str = stringify(json, { indent: 4, maxLength: Infinity });

    copyToClipboard(str);
};

export const fieldToColumnIndex = field => parseInt(field.substring(fieldNamePrefix.length), 10) - 1;

export const formatNumber = (value, decimalPlaces, showThousandsSeparator) => {
    let minimumFractionalDigits = decimalPlaces;
    let maximumFractionalDigits = decimalPlaces;

    if (!decimalPlaces && decimalPlaces !== 0) {
        minimumFractionalDigits = 0;
        maximumFractionalDigits = 20;
    }

    if (!isNumeric(value)) {
        return value;
    }

    const number = parseFloat(value);

    return !showThousandsSeparator
        ? number.toFixed(decimalPlaces)
        : number.toLocaleString("en-US", {
              minimumFractionDigits: minimumFractionalDigits,
              maximumFractionDigits: maximumFractionalDigits
          });
};

export const isNumeric = value => (value || value === 0) && !isNaN(value);

export const toDelimitedText = (packTable, delimiter) =>
    packTable.tableData.value.map(row => Object.values(row).join(delimiter)).join("\n");

export const toCsv = packTable => toDelimitedText(packTable, ",");
