import RS from "@common/strings/RS";
import { getIdxFromYear, getModvarCloneByTag, getModvarByTag, lockPackRowCol, lockPackTable } from "../GB/GBUtil.js";
import CONSTANTS from "./CSConst.js";

export const getCSCoDActive = (CSModvars, CoDType, d) => {
    // let modvar = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CustomCoD_MV);
    // let modValue = modvar["value"];
    let result = false;

    switch (CoDType) {
        case CONSTANTS.CS_CoD_MstNNPostNN: {
            if (
                getCSCoDActive(CSModvars, CONSTANTS.CS_CoD_MstNeonatal, d) ||
                getCSCoDActive(CSModvars, CONSTANTS.CS_CoD_MstPostNeonatal, d)
            )
                result = true;
            break;
        }

        case CONSTANTS.CS_CoD_MstNeonatal: {
            /* Standard */
            if (CONSTANTS.CS_NNDeathArray.indexOf(d) > -1) {
                result = true;
            }
            /* Custom */
            // else if (CONSTANTS.CS_NNDeathCustomArray.indexOf(d) > -1) {
            //     if (modValue[CONSTANTS.CS_CustomCoDNN][d - CONSTANTS.CS_CustomNNCoD1 + 1]["status"]) {// if CS.GetCustomCOD(CS_CustomCoDNN, d - CS_CustomNNCOD1 + 1).status < > CS_NotActive then
            //         result = true;
            //     }
            // }
            break;
        }

        case CONSTANTS.CS_CoD_MstPostNeonatal: {
            /* Standard */
            if (CONSTANTS.CS_PNNDeathArray.indexOf(d) > -1) {
                result = true;
            }
            /* Custom */
            // else if (CONSTANTS.CS_PNNDeathCustomArray.indexOf(d) > -1) {
            //     if (modValue[CONSTANTS.CS_CustomCoDPNN][d - CONSTANTS.CS_CustomPNNCoD1 + 1]["status"]) {// if CS.GetCustomCOD(CS_CustomCoDPNN, d - CS_CustomPNNCOD1 + 1).status < > CS_NotActive then
            //         result = true;
            //     }
            // }
            break;
        }

        case CONSTANTS.CS_CoD_MstStillbirth: {
            /* Standard Only */
            result = true;
            break;
        }

        case CONSTANTS.CS_CoD_MstMaternal: {
            /* Standard Only */
            result = true;
            break;
        }

        default: {
            break;
        }
    }
    return result;
};

export const getCSGetDeathCause = (CSModvars, d) => {
    // let modvar = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CustomCoD_MV);
    // let modValue = modvar["value"];
    let result = "";

    if (CONSTANTS.CS_NNDeathArray.indexOf(d) > -1 || CONSTANTS.CS_PNNDeathArray.indexOf(d) > -1) {
        //if d in [CS_NNDiarr..CS_NNOther, CS_Diarrhea..CS_Other] then
        switch (d) {
            case CONSTANTS.CS_NNDiarr: {
                result = RS("GB_stNeoDiarr");
                break;
            }
            case CONSTANTS.CS_NNSepsis: {
                result = RS("GB_stNeoSepsis");
                break;
            }
            case CONSTANTS.CS_NNPneumonia: {
                result = RS("GB_stNeoPneu");
                break;
            }
            case CONSTANTS.CS_NNAsphyxia: {
                result = RS("GB_stNeoAsphyxia");
                break;
            }
            case CONSTANTS.CS_NNPreterm: {
                result = RS("GB_stNeoPrematur");
                break;
            }
            case CONSTANTS.CS_NNTetanus: {
                result = RS("GB_stNeoTetan");
                break;
            }
            case CONSTANTS.CS_NNCongen: {
                result = RS("GB_stNeoCongAnom");
                break;
            }
            case CONSTANTS.CS_NNOther: {
                result = RS("GB_stNeoOther");
                break;
            }
            case CONSTANTS.CS_Diarrhea: {
                result = RS("GB_stDiarrhea");
                break;
            }
            case CONSTANTS.CS_Pneumonia: {
                result = RS("GB_stPneumonia");
                break;
            }
            case CONSTANTS.CS_Meningitis: {
                result = RS("GB_stMeningitis");
                break;
            }
            case CONSTANTS.CS_Measles: {
                result = RS("GB_stMeasles");
                break;
            }
            case CONSTANTS.CS_Malaria: {
                result = RS("GB_stMalaria");
                break;
            }
            case CONSTANTS.CS_Pertussis: {
                result = RS("GB_stPertussis");
                break;
            }
            case CONSTANTS.CS_AIDS: {
                result = RS("GB_stAIDS");
                break;
            }
            case CONSTANTS.CS_Injury: {
                result = RS("GB_stInjury");
                break;
            }
            case CONSTANTS.CS_OtherInfecDis: {
                result = RS("GB_stOtherInfecDis");
                break;
            }
            case CONSTANTS.CS_OtherNCD: {
                result = RS("GB_stOtherNCD");
                break;
            }

            default: {
                break;
            }
        } // result = CSGetDeathCause(d);
    }
    // else if (CONSTANTS.CS_NNDeathCustomArray.indexOf(d) > -1) {  //if d in [CS_CustomNNCod1..CS_CustomNNCod4] then
    //     result = modValue[CONSTANTS.CS_CustomCoDNN][d - CONSTANTS.CS_CustomNNCoD1 + 1]["name"];//CS.GetCustomCOD(CS_CustomCodNN, d - CS_CustomNNCoD1 + 1).Name
    // }
    // else if (CONSTANTS.CS_PNNDeathCustomArray.indexOf(d) > -1) {   //if d in [CS_CustomPNNCod1..CS_CustomPNNCod4] then
    //     result = modValue[CONSTANTS.CS_CustomCoDPNN][d - CONSTANTS.CS_CustomPNNCoD1 + 1]["name"];//CS.GetCustomCOD(CS_CustomCodPNN, d - CS_CustomPNNCoD1 + 1).Name;
    // }

    return result;
};

/* This is a wrapper for the ivRS function.  If there are any cases where the
string which should be displayed for an intervention is different than the
string for that intervention in IVModData.xlsx, those exceptional cases
should be handled here. */
export const getCSIvRs = (CSModvars, language, IntervRec) => {
    let BFEntryMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_EnterBFImproveData_MV2)["value"];
    let result = IntervRec["Names"][language]; /*IvRs(intervRec);*/
    if (IntervRec["MstID"] === CONSTANTS.IV_ChangesInBF) {
        result = RS("GB_stAgeAppropriateBFPractices");
        if (BFEntryMV === CONSTANTS.CS_MstBFPromotionMode) {
            result += " " + RS("GB_stPromoAppend");
        }
    }
    return result;
};

/* Returns a group record which contains strings for the groups and subgroups,
    as well as currentIDs of interventions within the group */
export const getIVGroupRecFromCurrID = (IVGroupInfo, groupID) => {
    let result = {};
    for (let i = 0; i <= IVGroupInfo.length - 1; i++) {
        if (IVGroupInfo[i]["CurrID"] === groupID) {
            result = IVGroupInfo[i];
        }
    }
    return result;
};

/* Returns a group record which contains strings for the groups and subgroups,
    as well as currentIDs of interventions within the group */
export const getIVGroupRecFromMstID = (IVGroupInfo, groupMstID) => {
    let result = {};
    for (let i = 0; i <= IVGroupInfo.length - 1; i++) {
        if (IVGroupInfo[i]["MstID"] === groupMstID) {
            result = IVGroupInfo[i];
        }
    }
    return result;
};

/* Returns a intervention record based on a master ID */
export const getIntervRec = (IVInfo, mstID) => {
    let result = {};
    for (let i = 0; i <= IVInfo.length - 1; i++) {
        if (IVInfo[i]["MstID"] === mstID) {
            result = IVInfo[i];
        }
    }
    return result;
};

//current IDs for input Modvars
export const getChildCODName = (d) => {
    let result = "";
    switch (d) {
        case CONSTANTS.CS_NNDiarr: {
            result = RS("GB_stNeoDiarr");
            break;
        }
        case CONSTANTS.CS_NNSepsis: {
            result = RS("GB_stNeoSepsis");
            break;
        }
        case CONSTANTS.CS_NNPneumonia: {
            result = RS("GB_stNeoPneu");
            break;
        }
        case CONSTANTS.CS_NNAsphyxia: {
            result = RS("GB_stNeoAsphyxia");
            break;
        }
        case CONSTANTS.CS_NNPreterm: {
            result = RS("GB_stNeoPrematur");
            break;
        }
        case CONSTANTS.CS_NNTetanus: {
            result = RS("GB_stNeoTetan");
            break;
        }
        case CONSTANTS.CS_NNCongen: {
            result = RS("GB_stNeoCongAnom");
            break;
        }
        case CONSTANTS.CS_NNOther: {
            result = RS("GB_stNeoOther");
            break;
        }
        case CONSTANTS.CS_Diarrhea: {
            result = RS("GB_stDiarrhea");
            break;
        }
        case CONSTANTS.CS_Pneumonia: {
            result = RS("GB_stPneumonia");
            break;
        }
        case CONSTANTS.CS_Meningitis: {
            result = RS("GB_stMeningitis");
            break;
        }
        case CONSTANTS.CS_Measles: {
            result = RS("GB_stMeasles");
            break;
        }
        case CONSTANTS.CS_Malaria: {
            result = RS("GB_stMalaria");
            break;
        }
        case CONSTANTS.CS_Pertussis: {
            result = RS("GB_stPertussis");
            break;
        }
        case CONSTANTS.CS_AIDS: {
            result = RS("GB_stAIDS");
            break;
        }
        case CONSTANTS.CS_Injury: {
            result = RS("GB_stInjury");
            break;
        }
        case CONSTANTS.CS_OtherInfecDis: {
            result = RS("GB_stOtherInfecDis");
            break;
        }
        case CONSTANTS.CS_OtherNCD: {
            result = RS("GB_stOtherNCD");
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

//current IDs for input Modvars
export const getAdolName = (d) => {
    let result = "";
    switch (d) {
        case CONSTANTS.CS_Adol_HIV: {
            result = RS("DP_stHIVCom");
            break;
        }
        case CONSTANTS.CS_Adol_Diarrhoeal: {
            result = RS("GB_stDiarrhoeal");
            break;
        }
        case CONSTANTS.CS_Adol_Measles: {
            result = RS("GB_stMeasles");
            break;
        }
        case CONSTANTS.CS_Adol_Malaria: {
            result = RS("GB_stMalaria");
            break;
        }
        case CONSTANTS.CS_Adol_LRI: {
            result = RS("GB_stLRI");
            break;
        }
        case CONSTANTS.CS_Adol_TB: {
            result = RS("GB_stTB");
            break;
        }
        case CONSTANTS.CS_Adol_Maternal: {
            result = RS("GB_stMaternal");
            break;
        }
        case CONSTANTS.CS_Adol_OtherCMPN: {
            result = RS("GB_stOtherCMPN");
            break;
        }
        case CONSTANTS.CS_Adol_Congenital: {
            result = RS("GB_stCongenital2");
            break;
        }
        case CONSTANTS.CS_Adol_Neoplasm: {
            result = RS("GB_stNeoplasm");
            break;
        }
        case CONSTANTS.CS_Adol_Cardiovascular: {
            result = RS("GB_stCardiovascular");
            break;
        }
        case CONSTANTS.CS_Adol_Digestive: {
            result = RS("GB_stDigestive");
            break;
        }
        case CONSTANTS.CS_Adol_OtherNCD: {
            result = RS("GB_stOtherNCD");
            break;
        }
        case CONSTANTS.CS_Adol_RTI: {
            result = RS("GB_stRTI");
            break;
        }
        case CONSTANTS.CS_Adol_Drowning: {
            result = RS("GB_stDrowning");
            break;
        }
        case CONSTANTS.CS_Adol_NatDis: {
            result = RS("GB_stNatDis");
            break;
        }
        case CONSTANTS.CS_Adol_InterpVio: {
            result = RS("GB_stInterpVio");
            break;
        }
        case CONSTANTS.CS_Adol_CollectVio: {
            result = RS("GB_stCollectVio");
            break;
        }
        case CONSTANTS.CS_Adol_SelfHarm: {
            result = RS("GB_stSelfHarm");
            break;
        }
        case CONSTANTS.CS_Adol_OtherInj: {
            result = RS("GB_stOtherInj");
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

//current IDs for input Modvars
export const getAdolFullName = (d) => {
    let result = getAdolName(d);
    switch (d) {
        case CONSTANTS.CS_Adol_HIV: {
            result = RS("GB_stHIVAIDS");
            break;
        }
        case CONSTANTS.CS_Adol_Diarrhoeal: {
            result = RS("GB_stDiarrheal");
            break;
        }
        case CONSTANTS.CS_Adol_LRI: {
            result = RS("GB_stLRIFull");
            break;
        }
        case CONSTANTS.CS_Adol_TB: {
            result = RS("GB_stTBFull");
            break;
        }
        case CONSTANTS.CS_Adol_Maternal: {
            result = RS("GB_stMatCauses");
            break;
        }
        case CONSTANTS.CS_Adol_Congenital: {
            result = RS("GB_stCongenital");
            break;
        }
        case CONSTANTS.CS_Adol_Neoplasm: {
            result = RS("GB_stNeoplasms");
            break;
        }
        case CONSTANTS.CS_Adol_Cardiovascular: {
            result = RS("GB_stCardiovascularDisease");
            break;
        }
        case CONSTANTS.CS_Adol_Digestive: {
            result = RS("GB_stDisOfDigestSys");
            break;
        }
        case CONSTANTS.CS_Adol_RTI: {
            result = RS("GB_stRTIFull");
            break;
        }
        case CONSTANTS.CS_Adol_Drowning: {
            result = RS("GB_stDrownings");
            break;
        }
        case CONSTANTS.CS_Adol_NatDis: {
            result = RS("GB_stNatDisFull");
            break;
        }
        case CONSTANTS.CS_Adol_InterpVio: {
            result = RS("GB_stInterpVioFull");
            break;
        }
        case CONSTANTS.CS_Adol_CollectVio: {
            result = RS("GB_stCollectVioFull");
            break;
        }
        case CONSTANTS.CS_Adol_OtherInj: {
            result = RS("GB_stOtherInjuries");
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

//current IDs for input Modvars
export const getAdolFullName2 = (d) => {
    let result = getAdolFullName(d);
    switch (d) {
        case CONSTANTS.CS_Adol_OtherCMPN: {
            result = RS("GB_stOtherCMPNFull");
            break;
        }
        case CONSTANTS.CS_Adol_OtherNCD: {
            result = RS("GB_stOtherNCDFull");
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

//current IDs for input Modvars
export const getSBCODName = (d) => {
    let result = "";
    switch (d) {
        case CONSTANTS.CS_SB_Antepartum: {
            result = RS("GB_stAntepartum");
            break;
        }
        case CONSTANTS.CS_SB_Intrapartum: {
            result = RS("GB_stIntrapartum");
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

//current IDs for input Modvars
export const getMatCODName = (d) => {
    let result = "";
    switch (d) {
        case CONSTANTS.CS_Mat_AntepartHemorr: {
            result = RS("GB_stAntepartumHem");
            break;
        }
        case CONSTANTS.CS_Mat_IntrapartHemorr: {
            result = RS("GB_stIntrapartumHem");
            break;
        }
        case CONSTANTS.CS_Mat_PostpartHemorr: {
            result = RS("GB_stPostpartumHem");
            break;
        }
        case CONSTANTS.CS_Mat_HypertensiveDis: {
            result = RS("GB_stHypertensive");
            break;
        }
        case CONSTANTS.CS_Mat_Sepsis: {
            result = RS("GB_stSepsis");
            break;
        }
        case CONSTANTS.CS_Mat_Abortion: {
            result = RS("GB_stAbortion");
            break;
        }
        case CONSTANTS.CS_Mat_Embolism: {
            result = RS("GB_stEmbolism");
            break;
        }
        case CONSTANTS.CS_Mat_OtherDirectCauses: {
            result = RS("GB_stOtherDirectCauses");
            break;
        }
        case CONSTANTS.CS_Mat_IndirectCauses: {
            result = RS("GB_stIndirectCauses");
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

export const getAgeCohortName = (a, skipCS_1t59months = false) => {
    let result = "";
    if (!skipCS_1t59months) {
        switch (a) {
            case CONSTANTS.CS_AgeSummary: {
                result = RS("GB_stTotal0t59mos");
                break;
            }
            case CONSTANTS.CS_Birth: {
                result = RS("GB_stBirth");
                break;
            }
            case CONSTANTS.CS_0t1months: {
                result = RS("GB_st0t1mos");
                break;
            }
            case CONSTANTS.CS_1t59months: {
                result = RS("GB_st1t59months");
                break;
            }
            case CONSTANTS.CS_1t6months: {
                result = RS("GB_st1t5months");
                break;
            }
            case CONSTANTS.CS_6t12months: {
                result = RS("GB_st6t11months");
                break;
            }
            case CONSTANTS.CS_12t24months: {
                result = RS("GB_st12t23months");
                break;
            }
            case CONSTANTS.CS_24t60months: {
                result = RS("GB_st24t59months");
                break;
            }
            case CONSTANTS.CS_24t36months: {
                result = RS("GB_st24t36mos");
                break;
            }
            case CONSTANTS.CS_36t48months: {
                result = RS("GB_st36t48mos");
                break;
            }
            case CONSTANTS.CS_48t60months: {
                result = RS("GB_st48t60mos");
                break;
            }

            default: {
                break;
            }
        }
    } else {
        switch (a) {
            case CONSTANTS.CS_AgeSummary: {
                result = RS("GB_stTotal0t59mos");
                break;
            }
            case CONSTANTS.CS_Birth: {
                result = RS("GB_stBirth");
                break;
            }
            case CONSTANTS.CS_0t1months: {
                result = RS("GB_st0t1mos");
                break;
            }
            case CONSTANTS.CS_1t6months: {
                result = RS("GB_st1t5months");
                break;
            }
            case CONSTANTS.CS_6t12months: {
                result = RS("GB_st6t11months");
                break;
            }
            case CONSTANTS.CS_12t24months: {
                result = RS("GB_st12t23months");
                break;
            }
            case CONSTANTS.CS_24t60months: {
                result = RS("GB_st24t59months");
                break;
            }
            case CONSTANTS.CS_24t36months: {
                result = RS("GB_st24t36mos");
                break;
            }
            case CONSTANTS.CS_36t48months: {
                result = RS("GB_st36t48mos");
                break;
            }
            case CONSTANTS.CS_48t60months: {
                result = RS("GB_st48t60mos");
                break;
            }

            default: {
                break;
            }
        }
    }
    return result;
};

/* This function returns a string for the year span */
export const CSGetYearName = (AgeId) => {
    let result = "";
    switch (AgeId) {
        case CONSTANTS.CS_0t1Yr: {
            result = "0-1 " + RS("GB_stYearsLowerCase");
            break;
        }
        case CONSTANTS.CS_1t2Yr: {
            result = "1-2 " + RS("GB_stYearsLowerCase");
            break;
        }
        case CONSTANTS.CS_2t3Yr: {
            result = "2-3 " + RS("GB_stYearsLowerCase");
            break;
        }
        case CONSTANTS.CS_3t4Yr: {
            result = "3-4 " + RS("GB_stYearsLowerCase");
            break;
        }
        case CONSTANTS.CS_4t5Yr: {
            result = "4-5 " + RS("GB_stYearsLowerCase");
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetDistrName = (d) => {
    let result = "";
    switch (d) {
        case CONSTANTS.CS_Distr_All: {
            result = RS("GB_stDistAllCases");
            break;
        }
        case CONSTANTS.CS_Distr_Sevr: {
            result = RS("GB_stDistSevrCases");
            break;
        }
        case CONSTANTS.CS_Distr_Fatal: {
            result = RS("GB_stDistFatalCases");
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

export const CSGetPathogenName = (deathCause, pathogen) => {
    let result = "";

    switch (deathCause) {
        case CONSTANTS.CS_Diarrhea:
            switch (pathogen) {
                case CONSTANTS.CS_RotaPathDiar: {
                    result = RS("GB_stRotavirus");
                    break;
                }
                case CONSTANTS.CS_PathBDiar: {
                    result = RS("GB_stPathogenB");
                    break;
                }
                case CONSTANTS.CS_PathCDiar: {
                    result = RS("GB_stPathogenC");
                    break;
                }
                case CONSTANTS.CS_OtherPathsDiar: {
                    result = RS("GB_stAllOtherPathogens");
                    break;
                }
                case CONSTANTS.CS_AllPathsDiar: {
                    result = RS("DP_stTotal");
                    break;
                }
                default:
                    break;
            }
            break;

        case CONSTANTS.CS_Pneumonia:
            switch (pathogen) {
                case CONSTANTS.CS_HibPathPneumVT: {
                    result = RS("GB_stHib");
                    break;
                }
                case CONSTANTS.CS_PneumocPathPneum: {
                    result = RS("GB_stSPneumococcus");
                    break;
                }
                case CONSTANTS.CS_PneumocPathPneumVT: {
                    result = RS("GB_stSPneumococcusVT");
                    break;
                }
                case CONSTANTS.CS_OtherPathsPneum: {
                    result = RS("GB_stAllOtherPathogens");
                    break;
                }
                case CONSTANTS.CS_AllPathsPneum: {
                    result = RS("DP_stTotal");
                    break;
                }
                default:
                    break;
            }
            break;

        case CONSTANTS.CS_Meningitis:
            switch (pathogen) {
                case CONSTANTS.CS_HibPathMeninVT: {
                    result = RS("GB_stHib");
                    break;
                }
                case CONSTANTS.CS_PneumocPathMenin: {
                    result = RS("GB_stSPneumococcus");
                    break;
                }
                case CONSTANTS.CS_PneumocPathMeninVT: {
                    result = RS("GB_stSPneumococcusVT");
                    break;
                }
                case CONSTANTS.CS_MeningitPathMenin: {
                    result = RS("GB_stNMeningNonNmA");
                    break;
                }
                case CONSTANTS.CS_MeningitAPathMeninVT: {
                    result = RS("GB_stNMeningTypeA");
                    break;
                }
                case CONSTANTS.CS_OtherPathsMenin: {
                    result = RS("GB_stAllOtherPathogens");
                    break;
                }
                case CONSTANTS.CS_AllPathsMenin: {
                    result = RS("DP_stTotal");
                    break;
                }
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for SGA and AGA */
export const CSBaselineMortStr = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_NeonatalMR: {
            result = RS("GB_stNNMortRate");
            break;
        }
        case CONSTANTS.CS_InfantMR: {
            result = RS("GB_stIMRate");
            break;
        }
        case CONSTANTS.CS_U5MR: {
            result = RS("GB_stU5MRate");
            break;
        }
        default:
            break;
    }
    return result;
};

export const getSexName = (s) => {
    let result = "";

    switch (s) {
        case CONSTANTS.DP_BothSexes: {
            result = RS("GB_stBothSexes");
            break;
        }
        case CONSTANTS.DP_Male: {
            result = RS("GB_stMale");
            break;
        }
        case CONSTANTS.DP_Female: {
            result = RS("GB_stFemale");
            break;
        }

        default: {
            break;
        }
    }
    return result;
};

export const GetLastIncRedInterv = (d) => {
    let result = 0;

    switch (d) {
        case CONSTANTS.CS_Diarrhea: {
            result = CONSTANTS.CS_NumDiarIntervs;
            break;
        }
        case CONSTANTS.CS_Pneumonia: {
            result = CONSTANTS.CS_NumPneumIntervs;
            break;
        }
        case CONSTANTS.CS_Meningitis: {
            result = CONSTANTS.CS_NumMeningIntervs;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Diarrhea interventions */
export const CSGetDiarrheaIntervMstID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_ImprovedExcretaDisp_DiarInterv: {
            result = CONSTANTS.IV_ImpWaterSource;
            break;
        }
        case CONSTANTS.CS_ImpWaterSource_DiarInterv: {
            result = CONSTANTS.IV_ImprovedSanitation;
            break;
        }
        case CONSTANTS.CS_WaterConnectionHome_DiarInterv: {
            result = CONSTANTS.IV_WaterConnectionHome;
            break;
        }
        case CONSTANTS.CS_HandWashWSoap_DiarInterv: {
            result = CONSTANTS.IV_HandWashWSoap;
            break;
        }
        case CONSTANTS.CS_HygDisposalStools_DiarInterv: {
            result = CONSTANTS.IV_HygDisposalStools;
            break;
        }
        case CONSTANTS.CS_ZincP_DiarInterv: {
            result = CONSTANTS.IV_ZincSupp;
            break;
        }
        case CONSTANTS.CS_VitAPTwoDoses_DiarInterv: {
            result = CONSTANTS.IV_VitASupp;
            break;
        }
        case CONSTANTS.CS_RotavirusVacc_DiarInterv: {
            result = CONSTANTS.IV_RotavirusVacc;
            break;
        }
        case CONSTANTS.CS_DiarVaccPathB_DiarInterv: {
            result = CONSTANTS.IV_DiarVaccPathB;
            break;
        }
        case CONSTANTS.CS_DiarVaccPathC_DiarInterv: {
            result = CONSTANTS.IV_DiarVaccPathC;
            break;
        }
        case CONSTANTS.CS_BFPromotion_DiarInterv: {
            result = CONSTANTS.IV_ChangesInBF;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Diarrhea interventions */
export const CSGetDiarrheaIntervCurrID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.IV_ImpWaterSource: {
            result = CONSTANTS.CS_ImprovedExcretaDisp_DiarInterv;
            break;
        }
        case CONSTANTS.IV_ImprovedSanitation: {
            result = CONSTANTS.CS_ImpWaterSource_DiarInterv;
            break;
        }
        case CONSTANTS.IV_WaterConnectionHome: {
            result = CONSTANTS.CS_WaterConnectionHome_DiarInterv;
            break;
        }
        case CONSTANTS.IV_HandWashWSoap: {
            result = CONSTANTS.CS_HandWashWSoap_DiarInterv;
            break;
        }
        case CONSTANTS.IV_HygDisposalStools: {
            result = CONSTANTS.CS_HygDisposalStools_DiarInterv;
            break;
        }
        case CONSTANTS.IV_ZincSupp: {
            result = CONSTANTS.CS_ZincP_DiarInterv;
            break;
        }
        case CONSTANTS.IV_VitASupp: {
            result = CONSTANTS.CS_VitAPTwoDoses_DiarInterv;
            break;
        }
        case CONSTANTS.IV_RotavirusVacc: {
            result = CONSTANTS.CS_RotavirusVacc_DiarInterv;
            break;
        }
        case CONSTANTS.IV_DiarVaccPathB: {
            result = CONSTANTS.CS_DiarVaccPathB_DiarInterv;
            break;
        }
        case CONSTANTS.IV_DiarVaccPathC: {
            result = CONSTANTS.CS_DiarVaccPathC_DiarInterv;
            break;
        }
        case CONSTANTS.IV_ChangesInBF: {
            result = CONSTANTS.CS_BFPromotion_DiarInterv;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Diarrhea interventions */
export const CSGetSevereDiarrheaIntervMstID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_RotavirusVacc_SevDiarInterv: {
            result = CONSTANTS.IV_RotavirusVacc;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Diarrhea interventions */
export const CSGetSevereDiarrheaIntervCurrID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.IV_RotavirusVacc: {
            result = CONSTANTS.CS_RotavirusVacc_SevDiarInterv;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Pneumonia interventions */
export const CSGetPneumoniaIntervMstID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_HibVacc_PneumInterv: {
            result = CONSTANTS.IV_HibVacc;
            break;
        }
        case CONSTANTS.CS_PneumVacc_PneumInterv: {
            result = CONSTANTS.IV_PneuVacc;
            break;
        }
        case CONSTANTS.CS_ZincP_PneumInterv: {
            result = CONSTANTS.IV_ZincSupp;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Pneumonia interventions */
export const CSGetPneumoniaIntervCurrID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.IV_HibVacc: {
            result = CONSTANTS.CS_HibVacc_PneumInterv;
            break;
        }
        case CONSTANTS.IV_PneuVacc: {
            result = CONSTANTS.CS_PneumVacc_PneumInterv;
            break;
        }
        case CONSTANTS.IV_ZincSupp: {
            result = CONSTANTS.CS_ZincP_PneumInterv;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Meningitis interventions */
export const CSGetMeningitisIntervMstID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_HibVacc_MeningInterv: {
            result = CONSTANTS.IV_HibVacc;
            break;
        }
        case CONSTANTS.CS_PneumVacc_MeningInterv: {
            result = CONSTANTS.IV_PneuVacc;
            break;
        }
        case CONSTANTS.CS_MeningA_MeningInterv: {
            result = CONSTANTS.IV_MeningA;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the master ID for Meningitis interventions */
export const CSGetMeningitisIntervCurrID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.IV_HibVacc: {
            result = CONSTANTS.CS_HibVacc_MeningInterv;
            break;
        }
        case CONSTANTS.IV_PneuVacc: {
            result = CONSTANTS.CS_PneumVacc_MeningInterv;
            break;
        }
        case CONSTANTS.IV_MeningA: {
            result = CONSTANTS.CS_MeningA_MeningInterv;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for Diarrhea interventions */
export const CSGetDiarrheaInterv = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_ImprovedExcretaDisp_DiarInterv: {
            result = RS("GB_stImprovedExcretaDisp");
            break;
        }
        case CONSTANTS.CS_ImpWaterSource_DiarInterv: {
            result = RS("GB_stUseOfImprovedWaterSrce");
            break;
        }
        case CONSTANTS.CS_WaterConnectionHome_DiarInterv: {
            result = RS("GB_stUseOfWaterConnection");
            break;
        }
        case CONSTANTS.CS_HandWashWSoap_DiarInterv: {
            result = RS("GB_stHandWashWSoap");
            break;
        }
        case CONSTANTS.CS_HygDisposalStools_DiarInterv: {
            result = RS("GB_stHygDisposalStools");
            break;
        }
        case CONSTANTS.CS_ZincP_DiarInterv: {
            result = RS("CS_stZincPrevent");
            break;
        }
        case CONSTANTS.CS_VitAPTwoDoses_DiarInterv: {
            result = RS("GB_stVitaAPrev");
            break;
        }
        case CONSTANTS.CS_RotavirusVacc_DiarInterv: {
            result = RS("GB_stRotaVacc");
            break;
        }
        case CONSTANTS.CS_DiarVaccPathB_DiarInterv: {
            result = RS("GB_stDiarrheaVaccPathB");
            break;
        }
        case CONSTANTS.CS_DiarVaccPathC_DiarInterv: {
            result = RS("GB_stDiarrheaVaccPathC");
            break;
        }
        case CONSTANTS.CS_BFPromotion_DiarInterv: {
            result = RS("GB_stBreastFeed");
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for Diarrhea interventions */
export const CSGetSevereDiarrheaInterv = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_RotavirusVacc_SevDiarInterv: {
            result = RS("GB_stRotaVacc");
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for Pneumonia interventions */
export const CSGetPneumoniaInterv = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_HibVacc_PneumInterv: {
            result = RS("GB_stHibVacc");
            break;
        }
        case CONSTANTS.CS_PneumVacc_PneumInterv: {
            result = RS("CS_stPneumVacc");
            break;
        }
        case CONSTANTS.CS_ZincP_PneumInterv: {
            result = RS("CS_stZincPrevent");
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for Meningitis interventions */
export const CSGetMeningitisInterv = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_HibVacc_MeningInterv: {
            result = RS("GB_stHibVacc");
            break;
        }
        case CONSTANTS.CS_PneumVacc_MeningInterv: {
            result = RS("CS_stPneumVacc");
            break;
        }
        case CONSTANTS.CS_MeningA_MeningInterv: {
            result = RS("GB_stMeningA");
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetCauseDeathInterv = (d, i) => {
    let result = "";
    switch (d) {
        case CONSTANTS.CS_Diarrhea: {
            result = CSGetDiarrheaInterv(i);
            break;
        }
        case CONSTANTS.CS_Pneumonia: {
            result = CSGetPneumoniaInterv(i);
            break;
        }
        case CONSTANTS.CS_Meningitis: {
            result = CSGetMeningitisInterv(i);
            break;
        }
        default:
            break;
    }
    return result;
};

export const GetLastPathogen = (d) => {
    let result = 0;

    switch (d) {
        case CONSTANTS.CS_Diarrhea: {
            result = CONSTANTS.CS_NumPathsDiar;
            break;
        }
        case CONSTANTS.CS_Pneumonia: {
            result = CONSTANTS.CS_NumPathsPneum;
            break;
        }
        case CONSTANTS.CS_Meningitis: {
            result = CONSTANTS.CS_NumPathsMenin;
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for Meningitis interventions */
export const CSGetStdDevBandName = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_GT1STD: {
            result = RS("GB_stGT1STD");
            break;
        }
        case CONSTANTS.CS_1t2STD: {
            result = RS("GB_s1t2STD");
            break;
        }
        case CONSTANTS.CS_2t3STD: {
            result = RS("GB_st2t3STD");
            break;
        }
        case CONSTANTS.CS_GT3STD: {
            result = RS("GB_stGT3STD");
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetFirstBFType = (a) => {
    let result = 0;
    switch (a) {
        case CONSTANTS.CS_0t1months: {
            result = CONSTANTS.CS_ExclusiveBF;
            break;
        }
        case CONSTANTS.CS_1t6months: {
            result = CONSTANTS.CS_ExclusiveBF;
            break;
        }
        case CONSTANTS.CS_6t12months: {
            result = CONSTANTS.CS_PartialBF;
            break;
        }
        case CONSTANTS.CS_12t24months: {
            result = CONSTANTS.CS_PartialBF;
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetBFTypeStr = (a) => {
    let result = 0;
    switch (a) {
        case CONSTANTS.CS_ExclusiveBF: {
            result = RS("GB_stExBF");
            break;
        }
        case CONSTANTS.CS_PredominantBF: {
            result = RS("GB_stPredomBF");
            break;
        }
        case CONSTANTS.CS_PartialBF: {
            result = RS("GB_stParBF");
            break;
        }
        case CONSTANTS.CS_NotBF: {
            result = RS("GB_stNotBF");
            break;
        }
        default:
            break;
    }
    return result;
};

/* <1 month, 1-5 months (a = CS_0t1Months, CS_1t6Months):

Exclusive breastfeeding  (bf = CS_ExclusiveBF)
Predominant breastfeeding (bf = CS_PredominantBF)
Partial breastfeeding (bf = CS_PartialBF)
Not breastfeeding (bf = CS_NotBF)

6-11 months, 12-23 months (a = CS_6t12Months, CS_12t24Months):

Any breastfeeding (bf = CS_PartialBF)
Not breastfeeding (bf = CS_NotBF)
*/
export const CSGetBreastfeedingTypeName = (a, bf) => {
    let result = "";

    if (bf === CONSTANTS.CS_NotBF) {
        result = RS("GB_stNotBF");
    } else {
        if ([CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months].includes(a)) {
            switch (bf) {
                case CONSTANTS.CS_ExclusiveBF: {
                    result = RS("GB_stExBF");
                    break;
                }
                case CONSTANTS.CS_PredominantBF: {
                    result = RS("GB_stPredomBF");
                    break;
                }
                case CONSTANTS.CS_PartialBF: {
                    result = RS("GB_stParBF");
                    break;
                }
                default:
                    break;
            }
        } else if ([CONSTANTS.CS_6t12months, CONSTANTS.CS_12t24months].includes(a)) {
            if (bf === CONSTANTS.CS_PartialBF) {
                result = RS("GB_stAnyBreastfeeding");
            }
        }
    }

    return result;
};

/* This function retrieves the string for SGA and AGA */
export const CSGetBirthTerm = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_PreTermSGA: {
            result = RS("GB_stPreTermSGA");
            break;
        }
        case CONSTANTS.CS_PreTermAGA: {
            result = RS("GB_stPreTermAGA");
            break;
        }
        case CONSTANTS.CS_TermSGA: {
            result = RS("GB_stTermSGA");
            break;
        }
        case CONSTANTS.CS_TermAGA: {
            result = RS("GB_stTermAGA");
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for SGA and AGA */
export const CSGetBirthTermAbbrev = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_PreTermSGA: {
            result = RS("GB_stPT_SGA");
            break;
        }
        case CONSTANTS.CS_PreTermAGA: {
            result = RS("GB_stPT_AGA");
            break;
        }
        case CONSTANTS.CS_TermSGA: {
            result = RS("GB_stT_SGA");
            break;
        }
        case CONSTANTS.CS_TermAGA: {
            result = RS("GB_stT_AGA");
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetDetVaccDiseases = (vt) => {
    let result = [];
    switch (vt) {
        case CONSTANTS.CS_Meas_Det: {
            result = [CONSTANTS.CS_Measles];
            break;
        }
        case CONSTANTS.CS_Malaria_Det: {
            result = [CONSTANTS.CS_Malaria];
            break;
        }
        case CONSTANTS.CS_Rota_Det: {
            result = [CONSTANTS.CS_Diarrhea];
            break;
        }
        case CONSTANTS.CS_DPT_Det: {
            result = [CONSTANTS.CS_Pertussis];
            break;
        }
        case CONSTANTS.CS_HiB_Det: {
            result = [CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis];
            break;
        }
        case CONSTANTS.CS_MenA_Det: {
            result = [CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis];
            break;
        }
        case CONSTANTS.CS_PCV_Det: {
            result = [
                CONSTANTS.CS_Pneumonia,
                CONSTANTS.CS_Meningitis,
                CONSTANTS.CS_OtherInfecDis,
                CONSTANTS.CS_OtherNCD,
            ];
            break;
        }
        case CONSTANTS.CS_VaccB_Det: {
            result = [
                CONSTANTS.CS_Diarrhea,
                CONSTANTS.CS_Pneumonia,
                CONSTANTS.CS_Measles,
                CONSTANTS.CS_Malaria,
                CONSTANTS.CS_Meningitis,
                CONSTANTS.CS_Pertussis,
                CONSTANTS.CS_OtherInfecDis,
                CONSTANTS.CS_OtherNCD,
            ];
            break;
        }
        case CONSTANTS.CS_VaccC_Det: {
            result = [
                CONSTANTS.CS_Diarrhea,
                CONSTANTS.CS_Pneumonia,
                CONSTANTS.CS_Measles,
                CONSTANTS.CS_Malaria,
                CONSTANTS.CS_Meningitis,
                CONSTANTS.CS_Pertussis,
                CONSTANTS.CS_OtherInfecDis,
                CONSTANTS.CS_OtherNCD,
            ];
            break;
        }
        case CONSTANTS.CS_VaccD_Det: {
            result = [
                CONSTANTS.CS_Diarrhea,
                CONSTANTS.CS_Pneumonia,
                CONSTANTS.CS_Measles,
                CONSTANTS.CS_Malaria,
                CONSTANTS.CS_Meningitis,
                CONSTANTS.CS_Pertussis,
                CONSTANTS.CS_OtherInfecDis,
                CONSTANTS.CS_OtherNCD,
            ];
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetDetVaccName = (vt) => {
    let result = "";
    switch (vt) {
        case CONSTANTS.CS_BCG_Det: {
            result = RS("GB_stBCG");
            break;
        }
        case CONSTANTS.CS_Polio_Det: {
            result = RS("GB_stPolio");
            break;
        }
        case CONSTANTS.CS_DPT_Det: {
            result = RS("GB_stDPT");
            break;
        }
        case CONSTANTS.CS_HiB_Det: {
            result = RS("GB_stHib");
            break;
        }
        case CONSTANTS.CS_HepB_Det: {
            result = RS("GB_stHepB");
            break;
        }
        case CONSTANTS.CS_PCV_Det: {
            result = RS("GB_stPneumococcal");
            break;
        }
        case CONSTANTS.CS_Rota_Det: {
            result = RS("GB_stRotavirus");
            break;
        }
        case CONSTANTS.CS_Meas_Det: {
            result = RS("CS_stMeasles");
            break;
        }
        case CONSTANTS.CS_MenA_Det: {
            result = RS("GB_stMeningA");
            break;
        }
        case CONSTANTS.CS_VaccB_Det: {
            result = RS("GB_stDiarrheaVaccPathB");
            break;
        }
        case CONSTANTS.CS_VaccC_Det: {
            result = RS("GB_stDiarrheaVaccPathC");
            break;
        }
        case CONSTANTS.CS_Malaria_Det: {
            result = RS("GB_stMalariaVaccine");
            break;
        }
        case CONSTANTS.CS_VaccD_Det: {
            result = RS("GB_stVaccineD");
            break;
        }
        default:
            break;
    }
    return result;
};

/* Return the max doses for various detailed vaccines-- the rest have no doses */
/* the max dose has been specified by Bill Winfrey 12/4/11 aside from BCG, which
I put as one, because that's the max that we can enter in coverage */
export const CSGetDetVaccMaxDose = (vt) => {
    let result = 0;
    switch (vt) {
        case CONSTANTS.CS_BCG_Det: {
            result = CONSTANTS.CS_VaccDoseOne;
            break;
        }
        case CONSTANTS.CS_Polio_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_HepB_Det: {
            result = CONSTANTS.CS_VaccBooster;
            break;
        }
        case CONSTANTS.CS_DPT_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_HiB_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_PCV_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_Rota_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_Meas_Det: {
            result = CONSTANTS.CS_VaccDoseTwo;
            break;
        }
        case CONSTANTS.CS_MenA_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_VaccB_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_VaccC_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_Malaria_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        case CONSTANTS.CS_VaccD_Det: {
            result = CONSTANTS.CS_VaccDoseThree;
            break;
        }
        default:
            break;
    }
    return result;
};

/* Detailed vaccine & associated information */
export const CSGetDoseString = (index) => {
    let result = "";
    switch (index) {
        case CONSTANTS.CS_VaccDoseZero: {
            result = RS("GB_stZeroDoses");
            break;
        }
        case CONSTANTS.CS_VaccDoseOne: {
            result = RS("GB_stSingleDose");
            break;
        }
        case CONSTANTS.CS_VaccDoseTwo: {
            result = RS("GB_stTwoDoses");
            break;
        }
        case CONSTANTS.CS_VaccDoseThree: {
            result = RS("GB_stThreeDoses");
            break;
        }
        case CONSTANTS.CS_VaccBooster: {
            result = RS("GB_stBooster");
            break;
        }
        default:
            break;
    }
    return result;
};

/* For Guided exploration: Setting the selections for cause of death to all, need to set all causes to true */
export const CSGetGuidedCODContentForPercRed = (content) => {
    let totalSelected = 0;

    for (let i = 0; i < content.length; i++) {
        if (content[i]["checked"]) {
            totalSelected++;
        }
    }
    if (totalSelected === 0 || totalSelected > 1) {
        for (let j = 0; j < content.length; j++) {
            content[j]["checked"] = true;
        }
    }
    return content;
};

export const CSGetFullBFName = (bf, a) => {
    return CSGetBreastfeedingTypeName(a, bf); // + ', ' + CSGetAgeName(a);
};

export const CSGetFullStuntName = (sd, a) => {
    return /*CSGetAgeName(a) + ', ' + */ CSGetStdDevBandName(sd);
};

export const CSGetFullWasteName = (sd, a) => {
    return /*CSGetAgeName(a) + ', ' + */ CSGetStdDevBandName(sd);
};

export const CSGetFullAgeAndBirthOrderName = (a, bo) => {
    return CSGetAgeGivingBirth(a) + ", " + CSGetBirthOrder(bo);
};

/* This function retrieves the string for age giving birth */
export const CSGetAgeGivingBirth = (a) => {
    let result = "";
    switch (a) {
        case CONSTANTS.CS_AllYrs: {
            result = RS("GB_stAllAges");
            break;
        }
        case CONSTANTS.CS_LT18Yrs: {
            result = RS("GB_stMALess18Years");
            break;
        }
        case CONSTANTS.CS_18_34Yrs: {
            result = RS("GB_st18_34Yrs");
            break;
        }
        case CONSTANTS.CS_35_49Yrs: {
            result = RS("GB_st35_49Yrs");
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for birth order */
export const CSGetBirthOrder = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_FirstBirth: {
            result = RS("GB_stFirstBirth");
            break;
        }
        case CONSTANTS.CS_SecAndThirdBirths: {
            result = RS("GB_stSecondAndThirdBirths");
            break;
        }
        case CONSTANTS.CS_GTThirdBirth: {
            result = RS("GB_stGTThirdBirth");
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for birth interval */
export const CSGetBirthInterval = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_FirstBirth: {
            result = RS("GB_stFirstBirth");
            break;
        }
        case CONSTANTS.CS_LT18Mths: {
            result = RS("GB_stlessThan18Mnths");
            break;
        }
        case CONSTANTS.CS_18to23Mths: {
            result = RS("GB_st18to23Months");
            break;
        }
        case CONSTANTS.CS_GE24Mths: {
            result = RS("GB_stGE24Months");
            break;
        }
        default:
            break;
    }
    return result;
};

export const GetAgeAndBFType = (MstID) => {
    let a, bf;
    let found = true;

    switch (MstID) {
        case CONSTANTS.CS_MstBFExclusive_0t1M:
        case CONSTANTS.CS_MstBFPredominant_0t1M:
        case CONSTANTS.CS_MstBFPartial_0t1M:
        case CONSTANTS.CS_MstBFNot_0t1M: {
            a = CONSTANTS.CS_0t1months;
            break;
        }

        case CONSTANTS.CS_MstBFExclusive_1t6M:
        case CONSTANTS.CS_MstBFPredominant_1t6M:
        case CONSTANTS.CS_MstBFPartial_1t6M:
        case CONSTANTS.CS_MstBFNot_1t6M: {
            a = CONSTANTS.CS_1t6months;
            break;
        }

        case CONSTANTS.CS_MstBFAny_6t12M:
        case CONSTANTS.CS_MstBFNot_6t12M: {
            a = CONSTANTS.CS_6t12months;
            break;
        }

        case CONSTANTS.CS_MstBFAny_12t24M:
        case CONSTANTS.CS_MstBFNot_12t24M: {
            a = CONSTANTS.CS_12t24months;
            break;
        }

        default: {
            a = 0;
            found = false;
            break;
        }
    }

    switch (MstID) {
        case CONSTANTS.CS_MstBFExclusive_0t1M:
        case CONSTANTS.CS_MstBFExclusive_1t6M: {
            bf = CONSTANTS.CS_ExclusiveBF;
            break;
        }

        case CONSTANTS.CS_MstBFPredominant_0t1M:
        case CONSTANTS.CS_MstBFPredominant_1t6M: {
            bf = CONSTANTS.CS_PredominantBF;
            break;
        }

        case CONSTANTS.CS_MstBFPartial_0t1M:
        case CONSTANTS.CS_MstBFPartial_1t6M:
        case CONSTANTS.CS_MstBFAny_6t12M:
        case CONSTANTS.CS_MstBFAny_12t24M: {
            bf = CONSTANTS.CS_PartialBF;
            break;
        }

        case CONSTANTS.CS_MstBFNot_0t1M:
        case CONSTANTS.CS_MstBFNot_1t6M:
        case CONSTANTS.CS_MstBFNot_6t12M:
        case CONSTANTS.CS_MstBFNot_12t24M: {
            bf = CONSTANTS.CS_NotBF;
            break;
        }

        default: {
            bf = 0;
            found = false;
            break;
        }
    }
    return { Found: found, ID1: a, ID2: bf };
};

export const LockSingleStuntWastCells = (pt, EditorStartYear, EditorEndYear) => {
    let startCol;
    let maxCol = pt.GBColCount;

    pt = lockPackTable(pt, 1, true);

    if (maxCol > 1) {
        /* Only unlock the cells from the current year onwards. This means, the
           year it currently is in real life.  As I write this comment, it is
           2018 in real life, for example, so that is the current year right now. */

        /* Only unlock any cells if the projection's final year is greater than
           the current year in real life.  Otherwise, all the cells should remain
           locked, since by definition they are not "from the current year onwards."
           The "UnlockCells" procedure can't make this happen automatically
           because of the way it's set up.  That's why we need to handle that logic
           here. */
        let currentYear = new Date().getFullYear();
        if (EditorEndYear > currentYear) {
            /* If the first year is earlier than the current year in real life,
               this line gets us the appropriate column from which to begin
               unlocking cells. */
            if (EditorStartYear < currentYear) {
                startCol = getIdxFromYear(EditorStartYear, currentYear) - pt.StartIndex + 1;
            } else {
                /* Otherwise, start unlocking cells from the second column.  This means
               we are going to treat the projection's first year as the first year
               in the conversion algorithms as well, as opposed to the current year
               in real life. */
                startCol = 2;
            }
            for (let col = startCol; col <= maxCol; col++) {
                pt = lockPackRowCol(pt, 1, col, false);
            }
        }
    }
    return pt;
};

export const GetStuntMstFromSDandAge = (sd, a) => {
    let result = -1;
    switch (sd) {
        case CONSTANTS.CS_GT1STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstStunt_GT1Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstStunt_GT1Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstStunt_GT1Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstStunt_GT1Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstStunt_GT1Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_1t2STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstStunt_1t2Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstStunt_1t2Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstStunt_1t2Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstStunt_1t2Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstStunt_1t2Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_2t3STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstStunt_2t3Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstStunt_2t3Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstStunt_2t3Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstStunt_2t3Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstStunt_2t3Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_GT3STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstStunt_GT3Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstStunt_GT3Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstStunt_GT3Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstStunt_GT3Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstStunt_GT3Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        default:
            break;
    }
    return result;
};

export const GetWastMstFromSDandAge = (sd, a) => {
    let result = -1;
    switch (sd) {
        case CONSTANTS.CS_GT1STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstWast_GT1Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstWast_GT1Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstWast_GT1Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstWast_GT1Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstWast_GT1Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_1t2STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstWast_1t2Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstWast_1t2Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstWast_1t2Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstWast_1t2Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstWast_1t2Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_2t3STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstWast_2t3Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstWast_2t3Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstWast_2t3Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstWast_2t3Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstWast_2t3Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_GT3STD: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstWast_GT3Std_LT1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstWast_GT3Std_1t5M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstWast_GT3Std_6t11M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstWast_GT3Std_12t23M;
                    break;
                }
                case CONSTANTS.CS_24t60months: {
                    result = CONSTANTS.CS_MstWast_GT3Std_24t59M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        default:
            break;
    }
    return result;
};

export const GetBFMstFromBFandAge = (bf, a) => {
    let result = -1;
    switch (bf) {
        case CONSTANTS.CS_ExclusiveBF: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstBFExclusive_0t1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstBFExclusive_1t6M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_PredominantBF: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstBFPredominant_0t1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstBFPredominant_1t6M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_PartialBF: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstBFPartial_0t1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstBFPartial_1t6M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstBFAny_6t12M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstBFAny_12t24M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case CONSTANTS.CS_NotBF: {
            switch (a) {
                case CONSTANTS.CS_0t1months: {
                    result = CONSTANTS.CS_MstBFNot_0t1M;
                    break;
                }
                case CONSTANTS.CS_1t6months: {
                    result = CONSTANTS.CS_MstBFNot_1t6M;
                    break;
                }
                case CONSTANTS.CS_6t12months: {
                    result = CONSTANTS.CS_MstBFNot_6t12M;
                    break;
                }
                case CONSTANTS.CS_12t24months: {
                    result = CONSTANTS.CS_MstBFNot_12t24M;
                    break;
                }
                default:
                    break;
            }
            break;
        }
        default:
            break;
    }
    return result;
};

export const GetSDandAge = (MstID) => {
    let a, sd;
    let found = true;

    switch (MstID) {
        case CONSTANTS.CS_MstStunt_GT1Std_LT1M:
        case CONSTANTS.CS_MstWast_GT1Std_LT1M: {
            sd = CONSTANTS.CS_GT1STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_LT1M:
        case CONSTANTS.CS_MstWast_1t2Std_LT1M: {
            sd = CONSTANTS.CS_1t2STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_LT1M:
        case CONSTANTS.CS_MstWast_2t3Std_LT1M: {
            sd = CONSTANTS.CS_2t3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_LT1M:
        case CONSTANTS.CS_MstWast_GT3Std_LT1M: {
            sd = CONSTANTS.CS_GT3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_1t5M:
        case CONSTANTS.CS_MstWast_GT1Std_1t5M: {
            sd = CONSTANTS.CS_GT1STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_1t5M:
        case CONSTANTS.CS_MstWast_1t2Std_1t5M: {
            sd = CONSTANTS.CS_1t2STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_1t5M:
        case CONSTANTS.CS_MstWast_2t3Std_1t5M: {
            sd = CONSTANTS.CS_2t3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_1t5M:
        case CONSTANTS.CS_MstWast_GT3Std_1t5M: {
            sd = CONSTANTS.CS_GT3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_6t11M:
        case CONSTANTS.CS_MstWast_GT1Std_6t11M: {
            sd = CONSTANTS.CS_GT1STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_6t11M:
        case CONSTANTS.CS_MstWast_1t2Std_6t11M: {
            sd = CONSTANTS.CS_1t2STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_6t11M:
        case CONSTANTS.CS_MstWast_2t3Std_6t11M: {
            sd = CONSTANTS.CS_2t3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_6t11M:
        case CONSTANTS.CS_MstWast_GT3Std_6t11M: {
            sd = CONSTANTS.CS_GT3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_12t23M:
        case CONSTANTS.CS_MstWast_GT1Std_12t23M: {
            sd = CONSTANTS.CS_GT1STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_12t23M:
        case CONSTANTS.CS_MstWast_1t2Std_12t23M: {
            sd = CONSTANTS.CS_1t2STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_12t23M:
        case CONSTANTS.CS_MstWast_2t3Std_12t23M: {
            sd = CONSTANTS.CS_2t3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_12t23M:
        case CONSTANTS.CS_MstWast_GT3Std_12t23M: {
            sd = CONSTANTS.CS_GT3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_24t59M:
        case CONSTANTS.CS_MstWast_GT1Std_24t59M: {
            sd = CONSTANTS.CS_GT1STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_24t59M:
        case CONSTANTS.CS_MstWast_1t2Std_24t59M: {
            sd = CONSTANTS.CS_1t2STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_24t59M:
        case CONSTANTS.CS_MstWast_2t3Std_24t59M: {
            sd = CONSTANTS.CS_2t3STD;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_24t59M:
        case CONSTANTS.CS_MstWast_GT3Std_24t59M: {
            sd = CONSTANTS.CS_GT3STD;
            break;
        }
        default: {
            sd = 0;
            found = false;
            break;
        }
    }

    switch (MstID) {
        case CONSTANTS.CS_MstStunt_GT1Std_LT1M:
        case CONSTANTS.CS_MstWast_GT1Std_LT1M: {
            a = CONSTANTS.CS_0t1months;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_LT1M:
        case CONSTANTS.CS_MstWast_1t2Std_LT1M: {
            a = CONSTANTS.CS_0t1months;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_LT1M:
        case CONSTANTS.CS_MstWast_2t3Std_LT1M: {
            a = CONSTANTS.CS_0t1months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_LT1M:
        case CONSTANTS.CS_MstWast_GT3Std_LT1M: {
            a = CONSTANTS.CS_0t1months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_1t5M:
        case CONSTANTS.CS_MstWast_GT1Std_1t5M: {
            a = CONSTANTS.CS_1t6months;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_1t5M:
        case CONSTANTS.CS_MstWast_1t2Std_1t5M: {
            a = CONSTANTS.CS_1t6months;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_1t5M:
        case CONSTANTS.CS_MstWast_2t3Std_1t5M: {
            a = CONSTANTS.CS_1t6months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_1t5M:
        case CONSTANTS.CS_MstWast_GT3Std_1t5M: {
            a = CONSTANTS.CS_1t6months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_6t11M:
        case CONSTANTS.CS_MstWast_GT1Std_6t11M: {
            a = CONSTANTS.CS_6t12months;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_6t11M:
        case CONSTANTS.CS_MstWast_1t2Std_6t11M: {
            a = CONSTANTS.CS_6t12months;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_6t11M:
        case CONSTANTS.CS_MstWast_2t3Std_6t11M: {
            a = CONSTANTS.CS_6t12months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_6t11M:
        case CONSTANTS.CS_MstWast_GT3Std_6t11M: {
            a = CONSTANTS.CS_6t12months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_12t23M:
        case CONSTANTS.CS_MstWast_GT1Std_12t23M: {
            a = CONSTANTS.CS_12t24months;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_12t23M:
        case CONSTANTS.CS_MstWast_1t2Std_12t23M: {
            a = CONSTANTS.CS_12t24months;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_12t23M:
        case CONSTANTS.CS_MstWast_2t3Std_12t23M: {
            a = CONSTANTS.CS_12t24months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_12t23M:
        case CONSTANTS.CS_MstWast_GT3Std_12t23M: {
            a = CONSTANTS.CS_12t24months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT1Std_24t59M:
        case CONSTANTS.CS_MstWast_GT1Std_24t59M: {
            a = CONSTANTS.CS_24t60months;
            break;
        }
        case CONSTANTS.CS_MstStunt_1t2Std_24t59M:
        case CONSTANTS.CS_MstWast_1t2Std_24t59M: {
            a = CONSTANTS.CS_24t60months;
            break;
        }
        case CONSTANTS.CS_MstStunt_2t3Std_24t59M:
        case CONSTANTS.CS_MstWast_2t3Std_24t59M: {
            a = CONSTANTS.CS_24t60months;
            break;
        }
        case CONSTANTS.CS_MstStunt_GT3Std_24t59M:
        case CONSTANTS.CS_MstWast_GT3Std_24t59M: {
            a = CONSTANTS.CS_24t60months;
            break;
        }
        default: {
            a = 0;
            found = false;
            break;
        }
    }

    return { Found: found, ID1: a, ID2: sd };
};

export const GetAgeAndBirthOrderIDs = (MstID) => {
    let a, bo;
    let found = true;

    switch (MstID) {
        case CONSTANTS.CS_MstLessThn18YrsFirstBirth: {
            a = CONSTANTS.CS_LT18Yrs;
            break;
        }
        case CONSTANTS.CS_MstLessThn18YrsSecondAndThirdBirths: {
            a = CONSTANTS.CS_LT18Yrs;
            break;
        }
        case CONSTANTS.CS_MstLessThn18YrsGrThanThirdBirth: {
            a = CONSTANTS.CS_LT18Yrs;
            break;
        }
        case CONSTANTS.CS_Mst18t34YrsFirstBirth: {
            a = CONSTANTS.CS_18_34Yrs;
            break;
        }
        case CONSTANTS.CS_Mst18t34YrsSecondAndThirdBirths: {
            a = CONSTANTS.CS_18_34Yrs;
            break;
        }
        case CONSTANTS.CS_Mst18t34YrsGrThanThirdBirth: {
            a = CONSTANTS.CS_18_34Yrs;
            break;
        }
        case CONSTANTS.CS_Mst35t49YrsFirstBirth: {
            a = CONSTANTS.CS_35_49Yrs;
            break;
        }
        case CONSTANTS.CS_Mst35t49YrsSecondAndThirdBirths: {
            a = CONSTANTS.CS_35_49Yrs;
            break;
        }
        case CONSTANTS.CS_Mst35t49YrsGrThanThirdBirth: {
            a = CONSTANTS.CS_35_49Yrs;
            break;
        }
        default: {
            a = 0;
            found = false;
            break;
        }
    }

    switch (MstID) {
        case CONSTANTS.CS_MstLessThn18YrsFirstBirth: {
            bo = CONSTANTS.CS_FirstBirth;
            break;
        }
        case CONSTANTS.CS_MstLessThn18YrsSecondAndThirdBirths: {
            bo = CONSTANTS.CS_SecAndThirdBirths;
            break;
        }
        case CONSTANTS.CS_MstLessThn18YrsGrThanThirdBirth: {
            bo = CONSTANTS.CS_GTThirdBirth;
            break;
        }
        case CONSTANTS.CS_Mst18t34YrsFirstBirth: {
            bo = CONSTANTS.CS_FirstBirth;
            break;
        }
        case CONSTANTS.CS_Mst18t34YrsSecondAndThirdBirths: {
            bo = CONSTANTS.CS_SecAndThirdBirths;
            break;
        }
        case CONSTANTS.CS_Mst18t34YrsGrThanThirdBirth: {
            bo = CONSTANTS.CS_GTThirdBirth;
            break;
        }
        case CONSTANTS.CS_Mst35t49YrsFirstBirth: {
            bo = CONSTANTS.CS_FirstBirth;
            break;
        }
        case CONSTANTS.CS_Mst35t49YrsSecondAndThirdBirths: {
            bo = CONSTANTS.CS_SecAndThirdBirths;
            break;
        }
        case CONSTANTS.CS_Mst35t49YrsGrThanThirdBirth: {
            bo = CONSTANTS.CS_GTThirdBirth;
            break;
        }
        default: {
            bo = 0;
            found = false;
            break;
        }
    }

    return { Found: found, ID1: a, ID2: bo };
};

export const GetBirthIntervalID = (MstID) => {
    let i;
    let found = true;

    switch (MstID) {
        case CONSTANTS.CS_MstFirstBirth: {
            i = CONSTANTS.CS_FirstBirth;
            break;
        }
        case CONSTANTS.CS_MstLessThan18Mnths: {
            i = CONSTANTS.CS_LT18Mths;
            break;
        }
        case CONSTANTS.CS_Mst18to23Mnths: {
            i = CONSTANTS.CS_18to23Mths;
            break;
        }
        case CONSTANTS.CS_Mst24MnthsOrGr: {
            i = CONSTANTS.CS_GE24Mths;
            break;
        }
        default: {
            i = 0;
            found = false;
            break;
        }
    }

    return { Found: found, ID1: i };
};

export const GetMstIDVars = (mstID) => {
    /* Result will be true if we find the ID's we're looking for. */
    let result;

    switch (mstID) {
        case CONSTANTS.CS_MstBFExclusive_0t1M: /* falls through */
        case CONSTANTS.CS_MstBFPredominant_0t1M: /* falls through */
        case CONSTANTS.CS_MstBFPartial_0t1M: /* falls through */
        case CONSTANTS.CS_MstBFNot_0t1M: /* falls through */

        case CONSTANTS.CS_MstBFExclusive_1t6M: /* falls through */
        case CONSTANTS.CS_MstBFPredominant_1t6M: /* falls through */
        case CONSTANTS.CS_MstBFPartial_1t6M: /* falls through */
        case CONSTANTS.CS_MstBFNot_1t6M: /* falls through */

        case CONSTANTS.CS_MstBFAny_6t12M: /* falls through */
        case CONSTANTS.CS_MstBFNot_6t12M: /* falls through */

        case CONSTANTS.CS_MstBFAny_12t24M: /* falls through */
        case CONSTANTS.CS_MstBFNot_12t24M:
            result = GetAgeAndBFType(mstID);
            break;

        case CONSTANTS.CS_MstEarlyInitBF:
            result = { Found: true, ID1: 0, ID2: 0 };
            break;

        case CONSTANTS.CS_MstStunt_GT1Std_LT1M: /* falls through */
        case CONSTANTS.CS_MstStunt_1t2Std_LT1M: /* falls through */
        case CONSTANTS.CS_MstStunt_2t3Std_LT1M: /* falls through */
        case CONSTANTS.CS_MstStunt_GT3Std_LT1M: /* falls through */

        case CONSTANTS.CS_MstStunt_GT1Std_1t5M: /* falls through */
        case CONSTANTS.CS_MstStunt_1t2Std_1t5M: /* falls through */
        case CONSTANTS.CS_MstStunt_2t3Std_1t5M: /* falls through */
        case CONSTANTS.CS_MstStunt_GT3Std_1t5M: /* falls through */

        case CONSTANTS.CS_MstStunt_GT1Std_6t11M: /* falls through */
        case CONSTANTS.CS_MstStunt_1t2Std_6t11M: /* falls through */
        case CONSTANTS.CS_MstStunt_2t3Std_6t11M: /* falls through */
        case CONSTANTS.CS_MstStunt_GT3Std_6t11M: /* falls through */

        case CONSTANTS.CS_MstStunt_GT1Std_12t23M: /* falls through */
        case CONSTANTS.CS_MstStunt_1t2Std_12t23M: /* falls through */
        case CONSTANTS.CS_MstStunt_2t3Std_12t23M: /* falls through */
        case CONSTANTS.CS_MstStunt_GT3Std_12t23M: /* falls through */

        case CONSTANTS.CS_MstStunt_GT1Std_24t59M: /* falls through */
        case CONSTANTS.CS_MstStunt_1t2Std_24t59M: /* falls through */
        case CONSTANTS.CS_MstStunt_2t3Std_24t59M: /* falls through */
        case CONSTANTS.CS_MstStunt_GT3Std_24t59M: /* falls through */

        case CONSTANTS.CS_MstWast_GT1Std_LT1M: /* falls through */
        case CONSTANTS.CS_MstWast_1t2Std_LT1M: /* falls through */
        case CONSTANTS.CS_MstWast_2t3Std_LT1M: /* falls through */
        case CONSTANTS.CS_MstWast_GT3Std_LT1M: /* falls through */

        case CONSTANTS.CS_MstWast_GT1Std_1t5M: /* falls through */
        case CONSTANTS.CS_MstWast_1t2Std_1t5M: /* falls through */
        case CONSTANTS.CS_MstWast_2t3Std_1t5M: /* falls through */
        case CONSTANTS.CS_MstWast_GT3Std_1t5M: /* falls through */

        case CONSTANTS.CS_MstWast_GT1Std_6t11M: /* falls through */
        case CONSTANTS.CS_MstWast_1t2Std_6t11M: /* falls through */
        case CONSTANTS.CS_MstWast_2t3Std_6t11M: /* falls through */
        case CONSTANTS.CS_MstWast_GT3Std_6t11M: /* falls through */

        case CONSTANTS.CS_MstWast_GT1Std_12t23M: /* falls through */
        case CONSTANTS.CS_MstWast_1t2Std_12t23M: /* falls through */
        case CONSTANTS.CS_MstWast_2t3Std_12t23M: /* falls through */
        case CONSTANTS.CS_MstWast_GT3Std_12t23M: /* falls through */

        case CONSTANTS.CS_MstWast_GT1Std_24t59M: /* falls through */
        case CONSTANTS.CS_MstWast_1t2Std_24t59M: /* falls through */
        case CONSTANTS.CS_MstWast_2t3Std_24t59M: /* falls through */
        case CONSTANTS.CS_MstWast_GT3Std_24t59M:
            result = GetSDandAge(mstID);
            break;

        case CONSTANTS.CS_MstLessThn18YrsFirstBirth: /* falls through */
        case CONSTANTS.CS_MstLessThn18YrsSecondAndThirdBirths: /* falls through */
        case CONSTANTS.CS_MstLessThn18YrsGrThanThirdBirth: /* falls through */
        case CONSTANTS.CS_Mst18t34YrsFirstBirth: /* falls through */
        case CONSTANTS.CS_Mst18t34YrsSecondAndThirdBirths: /* falls through */
        case CONSTANTS.CS_Mst18t34YrsGrThanThirdBirth: /* falls through */
        case CONSTANTS.CS_Mst35t49YrsFirstBirth: /* falls through */
        case CONSTANTS.CS_Mst35t49YrsSecondAndThirdBirths: /* falls through */
        case CONSTANTS.CS_Mst35t49YrsGrThanThirdBirth:
            result = GetAgeAndBirthOrderIDs(mstID);
            break;

        case CONSTANTS.CS_MstFirstBirth: /* falls through */
        case CONSTANTS.CS_MstLessThan18Mnths: /* falls through */
        case CONSTANTS.CS_Mst18to23Mnths: /* falls through */
        case CONSTANTS.CS_Mst24MnthsOrGr:
            result = GetBirthIntervalID(mstID);
            break;

        default:
            result = { Found: false, ID1: 0, ID2: 0 };
            break;
    }

    return result;
};

export const CSGetMstIDName = (CSModvars, language, IntervRec) => {
    let result = "";
    let Obj = GetMstIDVars(IntervRec["MstID"]);
    if (Obj["Found"]) {
        switch (IntervRec["MstID"]) {
            case CONSTANTS.CS_MstBFExclusive_0t1M: /* falls through */
            case CONSTANTS.CS_MstBFPredominant_0t1M: /* falls through */
            case CONSTANTS.CS_MstBFPartial_0t1M: /* falls through */
            case CONSTANTS.CS_MstBFNot_0t1M: /* falls through */

            case CONSTANTS.CS_MstBFExclusive_1t6M: /* falls through */
            case CONSTANTS.CS_MstBFPredominant_1t6M: /* falls through */
            case CONSTANTS.CS_MstBFPartial_1t6M: /* falls through */
            case CONSTANTS.CS_MstBFNot_1t6M: /* falls through */

            case CONSTANTS.CS_MstBFAny_6t12M: /* falls through */
            case CONSTANTS.CS_MstBFNot_6t12M: /* falls through */

            case CONSTANTS.CS_MstBFAny_12t24M: /* falls through */
            case CONSTANTS.CS_MstBFNot_12t24M: {
                result = CSGetFullBFName(Obj["ID2"], Obj["ID1"]);
                break;
            }

            case CONSTANTS.CS_MstEarlyInitBF: {
                result = RS("GB_stEarlyInitBFPrev");
                break;
            }

            case CONSTANTS.CS_MstStunt_GT1Std_LT1M: /* falls through */
            case CONSTANTS.CS_MstStunt_1t2Std_LT1M: /* falls through */
            case CONSTANTS.CS_MstStunt_2t3Std_LT1M: /* falls through */
            case CONSTANTS.CS_MstStunt_GT3Std_LT1M: /* falls through */

            case CONSTANTS.CS_MstStunt_GT1Std_1t5M: /* falls through */
            case CONSTANTS.CS_MstStunt_1t2Std_1t5M: /* falls through */
            case CONSTANTS.CS_MstStunt_2t3Std_1t5M: /* falls through */
            case CONSTANTS.CS_MstStunt_GT3Std_1t5M: /* falls through */

            case CONSTANTS.CS_MstStunt_GT1Std_6t11M: /* falls through */
            case CONSTANTS.CS_MstStunt_1t2Std_6t11M: /* falls through */
            case CONSTANTS.CS_MstStunt_2t3Std_6t11M: /* falls through */
            case CONSTANTS.CS_MstStunt_GT3Std_6t11M: /* falls through */

            case CONSTANTS.CS_MstStunt_GT1Std_12t23M: /* falls through */
            case CONSTANTS.CS_MstStunt_1t2Std_12t23M: /* falls through */
            case CONSTANTS.CS_MstStunt_2t3Std_12t23M: /* falls through */
            case CONSTANTS.CS_MstStunt_GT3Std_12t23M: /* falls through */

            case CONSTANTS.CS_MstStunt_GT1Std_24t59M: /* falls through */
            case CONSTANTS.CS_MstStunt_1t2Std_24t59M: /* falls through */
            case CONSTANTS.CS_MstStunt_2t3Std_24t59M: /* falls through */
            case CONSTANTS.CS_MstStunt_GT3Std_24t59M: {
                result = CSGetFullStuntName(Obj["ID2"], Obj["ID1"]);
                break;
            }

            case CONSTANTS.CS_MstWast_GT1Std_LT1M: /* falls through */
            case CONSTANTS.CS_MstWast_1t2Std_LT1M: /* falls through */
            case CONSTANTS.CS_MstWast_2t3Std_LT1M: /* falls through */
            case CONSTANTS.CS_MstWast_GT3Std_LT1M: /* falls through */

            case CONSTANTS.CS_MstWast_GT1Std_1t5M: /* falls through */
            case CONSTANTS.CS_MstWast_1t2Std_1t5M: /* falls through */
            case CONSTANTS.CS_MstWast_2t3Std_1t5M: /* falls through */
            case CONSTANTS.CS_MstWast_GT3Std_1t5M: /* falls through */

            case CONSTANTS.CS_MstWast_GT1Std_6t11M: /* falls through */
            case CONSTANTS.CS_MstWast_1t2Std_6t11M: /* falls through */
            case CONSTANTS.CS_MstWast_2t3Std_6t11M: /* falls through */
            case CONSTANTS.CS_MstWast_GT3Std_6t11M: /* falls through */

            case CONSTANTS.CS_MstWast_GT1Std_12t23M: /* falls through */
            case CONSTANTS.CS_MstWast_1t2Std_12t23M: /* falls through */
            case CONSTANTS.CS_MstWast_2t3Std_12t23M: /* falls through */
            case CONSTANTS.CS_MstWast_GT3Std_12t23M: /* falls through */

            case CONSTANTS.CS_MstWast_GT1Std_24t59M: /* falls through */
            case CONSTANTS.CS_MstWast_1t2Std_24t59M: /* falls through */
            case CONSTANTS.CS_MstWast_2t3Std_24t59M: /* falls through */
            case CONSTANTS.CS_MstWast_GT3Std_24t59M: {
                result = CSGetFullWasteName(Obj["ID2"], Obj["ID1"]);
                break;
            }

            case CONSTANTS.CS_MstLessThn18YrsFirstBirth: /* falls through */
            case CONSTANTS.CS_MstLessThn18YrsSecondAndThirdBirths: /* falls through */
            case CONSTANTS.CS_MstLessThn18YrsGrThanThirdBirth: /* falls through */
            case CONSTANTS.CS_Mst18t34YrsFirstBirth: /* falls through */
            case CONSTANTS.CS_Mst18t34YrsSecondAndThirdBirths: /* falls through */
            case CONSTANTS.CS_Mst18t34YrsGrThanThirdBirth: /* falls through */
            case CONSTANTS.CS_Mst35t49YrsFirstBirth: /* falls through */
            case CONSTANTS.CS_Mst35t49YrsSecondAndThirdBirths: /* falls through */
            case CONSTANTS.CS_Mst35t49YrsGrThanThirdBirth: {
                result = CSGetFullAgeAndBirthOrderName(Obj["ID1"], Obj["ID2"]);
                break;
            }

            case CONSTANTS.CS_MstFirstBirth: /* falls through */
            case CONSTANTS.CS_MstLessThan18Mnths: /* falls through */
            case CONSTANTS.CS_Mst18to23Mnths: /* falls through */
            case CONSTANTS.CS_Mst24MnthsOrGr: {
                result = CSGetBirthInterval(Obj["ID1"]);
                break;
            }

            default:
                break;
        }
    } else {
        switch (IntervRec["MstID"]) {
            case CONSTANTS.CS_MstBFPromo: {
                result = RS("GB_stBFPromo");
                break;
            }
            case CONSTANTS.CS_MstStunt_GT2StdSingle: {
                result = RS("GB_stGlobalStuntRate");
                break;
            }
            case CONSTANTS.CS_MstWast_GT2StdSingle: {
                result = RS("GB_stGlobalWasteRate");
                break;
            }
            default: {
                result = getCSIvRs(CSModvars, language, IntervRec);
                break;
            }
        }
    }
    return result;
};

export const CSGetAbbrevQualityMeasureStr = (QualityID) => {
    let result = "";
    switch (QualityID) {
        case CONSTANTS.CS_ANC1Mode: {
            result = " (ANC 1)";
            break;
        }
        case CONSTANTS.CS_ANC4Mode: {
            result = " (ANC 4)";
            break;
        }
        case CONSTANTS.CS_FascDelivMode: {
            result = " (Facility delivery)";
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetUtilizationIV = (QualityID) => {
    let result = -1;
    switch (QualityID) {
        case CONSTANTS.CS_ANC1Mode: {
            result = CONSTANTS.IV_ANC1;
            break;
        }
        case CONSTANTS.CS_ANC4Mode: {
            result = CONSTANTS.IV_ANC4;
            break;
        }
        case CONSTANTS.CS_FascDelivMode: {
            result = CONSTANTS.IV_HealthFacDelivery;
            break;
        }
        default:
            break;
    }
    return result;
};

/* Returns a detailed vaccine current ID given a corresponding intervention current ID. */
export const CSGetVaccCurrID = (vt) => {
    let result;
    switch (vt) {
        case CONSTANTS.IV_BCG_Vacc: {
            result = CONSTANTS.CS_BCG_Det;
            break;
        }
        case CONSTANTS.IV_PolioVacc: {
            result = CONSTANTS.CS_Polio_Det;
            break;
        }
        case CONSTANTS.IV_DPT_Vacc: {
            result = CONSTANTS.CS_DPT_Det;
            break;
        }
        case CONSTANTS.IV_HibVacc: {
            result = CONSTANTS.CS_HiB_Det;
            break;
        }
        case CONSTANTS.IV_HepBVacc: {
            result = CONSTANTS.CS_HepB_Det;
            break;
        }
        case CONSTANTS.IV_PneuVacc: {
            result = CONSTANTS.CS_PCV_Det;
            break;
        }
        case CONSTANTS.IV_RotavirusVacc: {
            result = CONSTANTS.CS_Rota_Det;
            break;
        }
        case CONSTANTS.IV_MeasVacc: {
            result = CONSTANTS.CS_Meas_Det;
            break;
        }
        case CONSTANTS.IV_MeningA: {
            result = CONSTANTS.CS_MenA_Det;
            break;
        }
        case CONSTANTS.IV_DiarVaccPathB: {
            result = CONSTANTS.CS_VaccB_Det;
            break;
        }
        case CONSTANTS.IV_DiarVaccPathC: {
            result = CONSTANTS.CS_VaccC_Det;
            break;
        }
        case CONSTANTS.IV_MalVacc: {
            result = CONSTANTS.CS_Malaria_Det;
            break;
        }
        case CONSTANTS.IV_VaccineD: {
            result = CONSTANTS.CS_VaccD_Det;
            break;
        }
        default: {
            result = 0;
            break;
        }
    }
    return result;
};

/* Return the equivalent dose for various detailed vaccines as compared with
    standard vaccines  */
/* From Ingrid: "It would be nice if the routine values for all the
vaccines was automatically entered into the 3 dose value for the cohort
section, IT would be the 3 dose for everything except Measles, where it is
1 dose and Rotavirus where it is 2 dose." */
/* Added BCG as one dose on 12/2/11 due to email with Ingrid */
export const CSGetDetVaccEquivDose = (vt) => {
    let result = CONSTANTS.CS_VaccDoseThree;
    switch (vt) {
        case CONSTANTS.CS_BCG_Det: {
            result = CONSTANTS.CS_VaccDoseOne;
            break;
        }
        case CONSTANTS.CS_Meas_Det: {
            result = CONSTANTS.CS_VaccDoseOne;
            break;
        }
        case CONSTANTS.CS_MenA_Det: {
            result = CONSTANTS.CS_VaccDoseOne;
            break;
        }
        case CONSTANTS.CS_VaccB_Det: {
            result = CONSTANTS.CS_VaccDoseOne;
            break;
        }
        case CONSTANTS.CS_VaccC_Det: {
            result = CONSTANTS.CS_VaccDoseOne;
            break;
        }
        case CONSTANTS.CS_VaccD_Det: {
            result = CONSTANTS.CS_VaccDoseOne;
            break;
        }
        case CONSTANTS.CS_Rota_Det: {
            result = CONSTANTS.CS_VaccDoseTwo;
            break;
        }
        case CONSTANTS.CS_Malaria_Det: {
            result = CONSTANTS.CS_VaccBooster;
            break;
        }
        default: {
            break;
        }
    }
    return result;
};

export const GetBigPop = (modvar, age, sex, t) => {
    return modvar["vValue"][age][sex][t];
};

export const GetPop = (CSModvars, time, sex, age) => {
    let BigPopModvar = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_BigPop_MV3);

    let SumPop = 0;
    switch (age) {
        case CONSTANTS.DP_AllAges: {
            for (let a1 = 0; a1 <= 80; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A0_4: {
            for (let a1 = 0; a1 <= 4; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A5_9: {
            for (let a1 = 5; a1 <= 9; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A10_14: {
            for (let a1 = 10; a1 <= 14; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A15_19: {
            for (let a1 = 15; a1 <= 19; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A20_24: {
            for (let a1 = 20; a1 <= 24; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A25_29: {
            for (let a1 = 25; a1 <= 29; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A30_34: {
            for (let a1 = 30; a1 <= 34; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A35_39: {
            for (let a1 = 35; a1 <= 39; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A40_44: {
            for (let a1 = 40; a1 <= 44; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A45_49: {
            for (let a1 = 45; a1 <= 49; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A50_54: {
            for (let a1 = 50; a1 <= 54; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A55_59: {
            for (let a1 = 55; a1 <= 59; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A60_64: {
            for (let a1 = 60; a1 <= 64; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A65_69: {
            for (let a1 = 65; a1 <= 69; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A70_74: {
            for (let a1 = 70; a1 <= 74; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A75_79: {
            for (let a1 = 75; a1 <= 79; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        case CONSTANTS.DP_A80_Up: {
            for (let a1 = 80; a1 <= 80; a1++) {
                SumPop += GetBigPop(BigPopModvar, a1, sex, time);
            }
            break;
        }
        default:
            break;
    }

    return SumPop;
};

export const GetInCalcStateMode = (CSModvars) => {
    return getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"] === 2;
};

export const SplitFirstGroup = (CSModvars, p1, p2, p3, p4, p5, sex) => {
    let a0, a1, a2, a3, a4, aSum;
    let SurvRate1970 = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_SurvRate1970_MV)["vValue"];
    let SurvRate = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_SurvRate_MV2)["vValue"];

    if (GetInCalcStateMode(CSModvars)) {
        a0 = SurvRate1970[sex][1] * 2 * 1000;
        a1 = SurvRate1970[sex][2] * a0;
        a2 = SurvRate1970[sex][3] * a1;
        a3 = SurvRate1970[sex][4] * a2;
        a4 = SurvRate1970[sex][5] * a3;
    } else {
        a0 = SurvRate[1][sex][[CONSTANTS.DP_FIRST_INDEX]] * 2 * 1000;
        a1 = SurvRate[2][sex][[CONSTANTS.DP_FIRST_INDEX]] * a0;
        a2 = SurvRate[3][sex][[CONSTANTS.DP_FIRST_INDEX]] * a1;
        a3 = SurvRate[4][sex][[CONSTANTS.DP_FIRST_INDEX]] * a2;
        a4 = SurvRate[5][sex][[CONSTANTS.DP_FIRST_INDEX]] * a3;
    }

    aSum = a0 + a1 + a2 + a3 + a4;

    if (aSum > 0) {
        a0 = (a0 / aSum) * p1;
        a1 = (a1 / aSum) * p1;
        a2 = (a2 / aSum) * p1;
        a3 = (a3 / aSum) * p1;
        a4 = (a4 / aSum) * p1;
    } else {
        a0 = 0.3333 * p1 - 0.1636 * p2 - 0.021 * p3 + 0.0796 * p4 - 0.0283 * p5;
        a1 = 0.2595 * p1 - 0.078 * p2 + 0.013 * p3 + 0.01 * p4 - 0.0045 * p5;
        a2 = 0.1924 * p1 + 0.0064 * p2 + 0.0184 * p3 - 0.0256 * p4 + 0.0084 * p5;
        a3 = 0.1329 * p1 + 0.0844 * p2 + 0.0054 * p3 - 0.0356 * p4 + 0.0129 * p5;
        a4 = 0.0819 * p1 + 0.1508 * p2 - 0.0158 * p3 - 0.0284 * p4 + 0.0115 * p5;
    }

    return [a0, a1, a2, a3, a4];
};

export const SplitSecondGroup = (p1, p2, p3, p4, p5) => {
    let a5, a6, a7, a8, a9;
    a5 = 0.0404 * p1 + 0.2 * p2 - 0.0344 * p3 - 0.0128 * p4 + 0.0068 * p5;
    a6 = 0.0093 * p1 + 0.2268 * p2 - 0.0402 * p3 + 0.0028 * p4 + 0.0013 * p5;
    a7 = -0.0108 * p1 + 0.2272 * p2 - 0.0248 * p3 + 0.0112 * p4 - 0.0028 * p5;
    a8 = -0.0198 * p1 + 0.1992 * p2 + 0.0172 * p3 + 0.0072 * p4 - 0.0038 * p5;
    a9 = -0.0191 * p1 + 0.1468 * p2 + 0.0822 * p3 - 0.0084 * p4 - 0.0015 * p5;
    return [a5, a6, a7, a8, a9];
};

export const SplitMiddleGroup = (p1, p2, p3, p4, p5) => {
    let a1, a2, a3, a4, a5;
    a1 = -0.0117 * p1 + 0.0804 * p2 + 0.157 * p3 - 0.0284 * p4 + 0.0027 * p5;
    a2 = -0.002 * p1 + 0.016 * p2 + 0.22 * p3 - 0.04 * p4 + 0.006 * p5;
    a3 = 0.005 * p1 - 0.028 * p2 + 0.246 * p3 - 0.028 * p4 + 0.005 * p5;
    a4 = 0.006 * p1 - 0.04 * p2 + 0.22 * p3 + 0.016 * p4 - 0.002 * p5;
    a5 = 0.0027 * p1 - 0.0284 * p2 + 0.157 * p3 + 0.0804 * p4 - 0.0117 * p5;
    return [a1, a2, a3, a4, a5];
};

export const SplitPenultimateGroup = (p1, p2, p3, p4, p5) => {
    let a1, a2, a3, a4, a5;
    a1 = -0.0015 * p1 - 0.0084 * p2 + 0.0822 * p3 + 0.1468 * p4 - 0.0191 * p5;
    a2 = -0.0038 * p1 + 0.0072 * p2 + 0.0172 * p3 + 0.1992 * p4 - 0.0198 * p5;
    a3 = -0.0028 * p1 + 0.0112 * p2 - 0.0248 * p3 + 0.2272 * p4 - 0.0108 * p5;
    a4 = 0.0013 * p1 + 0.0028 * p2 - 0.0402 * p3 + 0.2268 * p4 + 0.0093 * p5;
    a5 = 0.0068 * p1 - 0.0128 * p2 - 0.0344 * p3 + 0.2 * p4 + 0.0404 * p5;
    return [a1, a2, a3, a4, a5];
};

export const SplitLastGroup = (p1, p2, p3, p4, p5) => {
    let a1, a2, a3, a4, a5;
    a1 = 0.0115 * p1 - 0.0284 * p2 - 0.0158 * p3 + 0.1508 * p4 + 0.0819 * p5;
    a2 = 0.0129 * p1 - 0.0356 * p2 + 0.0054 * p3 + 0.0844 * p4 + 0.1329 * p5;
    a3 = 0.0084 * p1 - 0.0256 * p2 + 0.0184 * p3 + 0.0064 * p4 + 0.1924 * p5;
    a4 = -0.0045 * p1 + 0.01 * p2 + 0.013 * p3 - 0.078 * p4 + 0.2595 * p5;
    a5 = -0.0283 * p1 + 0.0796 * p2 - 0.021 * p3 - 0.1636 * p4 + 0.3333 * p5;
    return [a1, a2, a3, a4, a5];
};

/* Splits the five year age population data into double age data */
export const Calc_Single_Ages = (CSModvars, p5, sex) => {
    let age, k, j;

    let sa = new Array(CONSTANTS.DP_MaxSingleAges + 1);

    for (let a = 0; a <= CONSTANTS.DP_MaxSingleAges; a++) {
        sa[a] = 0;
    }

    let temp;

    temp = SplitFirstGroup(
        CSModvars,
        p5[CONSTANTS.DP_A0_4],
        p5[CONSTANTS.DP_A5_9],
        p5[CONSTANTS.DP_A10_14],
        p5[CONSTANTS.DP_A15_19],
        p5[CONSTANTS.DP_A20_24],
        sex
    );

    j = 0;
    for (let i = 0; i <= 4; i++) {
        sa[j] = temp[i];
        j++;
    }

    temp = SplitSecondGroup(
        p5[CONSTANTS.DP_A0_4],
        p5[CONSTANTS.DP_A5_9],
        p5[CONSTANTS.DP_A10_14],
        p5[CONSTANTS.DP_A15_19],
        p5[CONSTANTS.DP_A20_24]
    );

    j = 5;
    for (let i = 0; i <= 4; i++) {
        sa[j] = temp[i];
        j++;
    }

    temp = SplitPenultimateGroup(
        p5[CONSTANTS.DP_A55_59],
        p5[CONSTANTS.DP_A60_64],
        p5[CONSTANTS.DP_A65_69],
        p5[CONSTANTS.DP_A70_74],
        p5[CONSTANTS.DP_A75_79]
    );

    j = 70;
    for (let i = 0; i <= 4; i++) {
        sa[j] = temp[i];
        j++;
    }

    temp = SplitLastGroup(
        p5[CONSTANTS.DP_A55_59],
        p5[CONSTANTS.DP_A60_64],
        p5[CONSTANTS.DP_A65_69],
        p5[CONSTANTS.DP_A70_74],
        p5[CONSTANTS.DP_A75_79]
    );

    j = 75;
    for (let i = 0; i <= 4; i++) {
        sa[j] = temp[i];
        j++;
    }

    sa[80] = p5[CONSTANTS.DP_A80_Up];

    age = 10;

    while (age < CONSTANTS.DP_MaxSingleAges - 10) {
        k = Math.floor(age / 5) + 1;

        temp = SplitMiddleGroup(p5[k - 2], p5[k - 1], p5[k], p5[k + 1], p5[k + 2]);

        j = age;
        for (let i = 0; i <= 4; i++) {
            sa[j] = temp[i];
            j++;
        }

        age = age + 5;
    }

    return sa;
};

/* Splits population by five year age groups into single age. */
export const DPSplitPop = (CSModvars, FiveYearPop) => {
    let s, a, p5;

    p5 = new Array(CONSTANTS.DP_MAX_AGE + 1);

    for (let i = 0; i < p5.length; i++) {
        p5[i] = new Array(CONSTANTS.DP_Female + 1);
    }

    let BYIndex = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BigPopMV = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_BigPop_MV3);

    for (s = CONSTANTS.DP_Male; s <= CONSTANTS.DP_Female; s++) {
        for (a = CONSTANTS.DP_AllAges; a <= CONSTANTS.DP_MAX_AGE; a++) {
            p5[a] = FiveYearPop[a][s];
        }

        let sa = Calc_Single_Ages(CSModvars, p5, s);

        for (a = 0; a <= CONSTANTS.DP_MaxSingleAges; a++) {
            BigPopMV["vValue"][a][s][BYIndex] = sa[a];
        }
    }

    for (a = 0; a <= CONSTANTS.DP_MaxSingleAges; a++) {
        BigPopMV["vValue"][a][CONSTANTS.DP_BothSexes][BYIndex] =
            BigPopMV["vValue"][a][CONSTANTS.DP_Male][BYIndex] + BigPopMV["vValue"][a][CONSTANTS.DP_Female][BYIndex];
    }

    return BigPopMV;
};

export const GetDPGB_AgeName = (a) => {
    let result = "";
    switch (a) {
        case CONSTANTS.DP_A0_4: {
            result = " 0-4";
            break;
        }
        case CONSTANTS.DP_A5_9: {
            result = " 5-9";
            break;
        }
        case CONSTANTS.DP_A10_14: {
            result = " 10-14 ";
            break;
        } // need space so Excel won't convert to date on paste
        case CONSTANTS.DP_A15_19: {
            result = " 15-19 ";
            break;
        }
        case CONSTANTS.DP_A20_24: {
            result = " 20-24 ";
            break;
        }
        case CONSTANTS.DP_A25_29: {
            result = " 25-29 ";
            break;
        }
        case CONSTANTS.DP_A30_34: {
            result = " 30-34 ";
            break;
        }
        case CONSTANTS.DP_A35_39: {
            result = " 35-39 ";
            break;
        }
        case CONSTANTS.DP_A40_44: {
            result = " 40-44 ";
            break;
        }
        case CONSTANTS.DP_A45_49: {
            result = " 45-49 ";
            break;
        }
        case CONSTANTS.DP_A50_54: {
            result = " 50-54 ";
            break;
        }
        case CONSTANTS.DP_A55_59: {
            result = " 55-59 ";
            break;
        }
        case CONSTANTS.DP_A60_64: {
            result = " 60-64 ";
            break;
        }
        case CONSTANTS.DP_A65_69: {
            result = " 65-69 ";
            break;
        }
        case CONSTANTS.DP_A70_74: {
            result = " 70-74 ";
            break;
        }
        case CONSTANTS.DP_A75_79: {
            result = " 75-79 ";
            break;
        }
        case CONSTANTS.DP_A80_Up: {
            result = " 80+ ";
            break;
        }
        case 18: {
            result = RS("DP_stTotal");
            break;
        }
        default: {
            result = "";
            break;
        }
    }
    return result;
};

export const DirectEntryMstIDSet = () => {
    return [
        CONSTANTS.CS_MstLiSTOnlineDirectEntryOfStunting,
        CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting,
        CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF,
        CONSTANTS.CS_MstDirectEntryFertilityRisks,
    ];
};

export const ResultExcludeMstIDSet = () => {
    return [
        CONSTANTS.IV_IronSuppPreg,
        CONSTANTS.IV_MultiMicronSuppPreg,
        CONSTANTS.IV_PentaVacc,
        CONSTANTS.IV_KangarooMothCare,
        CONSTANTS.IV_FullSuppCarePremat,
        CONSTANTS.IV_OralAntibNeonSeps,
        CONSTANTS.IV_InjectAntibNeonSeps,
        CONSTANTS.IV_FullSuppCareNeonSepsPneu,
    ];
};

export const CSGetAgeWeight = (a) => {
    let result;
    switch (a) {
        case CONSTANTS.CS_0t1months: {
            result = 1 / 60;
            break;
        }
        case CONSTANTS.CS_1t6months: {
            result = (6 - 1) / 60;
            break;
        }
        case CONSTANTS.CS_6t12months: {
            result = (12 - 6) / 60;
            break;
        }
        case CONSTANTS.CS_12t24months: {
            result = (24 - 12) / 60;
            break;
        }
        case CONSTANTS.CS_24t60months: {
            result = (60 - 24) / 60;
            break;
        }
        default: {
            result = 1;
            break;
        }
    }
    return result;
};

/* Formulae are from "Stunting and Wasting Indicators 7aug2015.xlsx", cells D8 to D11.
    Please keep temp, temp2, temp3 by themselves on a line when they appear so that we can easily match them up to the
    chunks of Excel formulae they represent. */
export const CSZScoreCalc = (curYrSingle, firstYrSingle, firstYrSDArr) => {
    let temp, temp2, temp3;
    let result = new Array(CONSTANTS.CS_GT3STD + 1);

    /* $B$5 = firstYrSingle */
    /* D$5  = curYrSingle   */
    /* $B8  = firstYrGT1SD  */
    /* $B9  = firstYr1t2SD  */
    /* $B10 = firstYr2t3SD  */
    /* $B11 = firstYrGT3SD  */

    /* D$5<=$B$5 */
    if (curYrSingle <= firstYrSingle) {
        /* IF(D$5<0.02275,0.02275,D$5) */
        temp = Math.max(curYrSingle, 2.275);

        /* IF(D$5>0.02275,0,(0.02275-D$5)) */
        if (curYrSingle > 2.275) {
            temp2 = 0;
        } else {
            temp2 = 2.275 - curYrSingle;
        }

        /* (($B$5-(IF(D$5<0.02275,0.02275,D$5)))/($B$5-0.02275)) */
        temp3 = (firstYrSingle - temp) / (firstYrSingle - 2.275);

        /* ($B8-((($B$5-(IF(D$5<0.02275,0.02275,D$5)))/($B$5-0.02275))*($B8-0.841345))), */
        result[CONSTANTS.CS_GT1STD] =
            firstYrSDArr[CONSTANTS.CS_GT1STD] - temp3 * (firstYrSDArr[CONSTANTS.CS_GT1STD] - 84.1345);

        /* ($B9-((($B$5-(IF(D$5<0.02275,0.02275,D$5)))/
                ($B$5-0.02275))*($B9-0.135905))+(IF(D$5>0.02275,0,(0.02275-D$5)))), */
        result[CONSTANTS.CS_1t2STD] =
            firstYrSDArr[CONSTANTS.CS_1t2STD] - temp3 * (firstYrSDArr[CONSTANTS.CS_1t2STD] - 13.5905) + temp2;

        /* ($B10-((($B$5-(IF(D$5<0.02275,0.02275,D$5)))/($B$5-0.02275))*($B10-0.0214))-
                ((IF(D$5>0.02275,0,(0.02275-D$5))/0.02275)*0.0214)), */
        result[CONSTANTS.CS_2t3STD] =
            firstYrSDArr[CONSTANTS.CS_2t3STD] -
            temp3 * (firstYrSDArr[CONSTANTS.CS_2t3STD] - 2.14) -
            (temp2 / 2.275) * 2.14;

        /* ($B11-((($B$5-(IF(D$5<0.02275,0.02275,D$5)))/($B$5-0.02275))*($B11-0.00135))-
                ((IF(D$5>0.02275,0,(0.02275-D$5))/0.02275)*0.00135)), */
        result[CONSTANTS.CS_GT3STD] =
            firstYrSDArr[CONSTANTS.CS_GT3STD] -
            temp3 * (firstYrSDArr[CONSTANTS.CS_GT3STD] - 0.135) -
            (temp2 / 2.275) * 0.135;
    } else {
        temp = (curYrSingle - firstYrSingle) / (100 - firstYrSingle);

        result[CONSTANTS.CS_GT1STD] =
            firstYrSDArr[CONSTANTS.CS_GT1STD] + temp * (0.13498 - firstYrSDArr[CONSTANTS.CS_GT1STD]);
        result[CONSTANTS.CS_1t2STD] =
            firstYrSDArr[CONSTANTS.CS_1t2STD] + temp * (2.14 - firstYrSDArr[CONSTANTS.CS_1t2STD]);
        result[CONSTANTS.CS_2t3STD] =
            firstYrSDArr[CONSTANTS.CS_2t3STD] + temp * (13.59046 - firstYrSDArr[CONSTANTS.CS_2t3STD]);
        result[CONSTANTS.CS_GT3STD] =
            firstYrSDArr[CONSTANTS.CS_GT3STD] + temp * (84.13453 - firstYrSDArr[CONSTANTS.CS_GT3STD]);
    }

    return result;
};

export const CSConvertZScoresToIndicator = (SingleDistr, Distr, FirstYrIdx, FinalYrIdx) => {
    for (let t = FirstYrIdx; t <= FinalYrIdx; t++) {
        SingleDistr[t] = 0.0;

        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            SingleDistr[t] =
                SingleDistr[t] +
                (Distr[CONSTANTS.CS_2t3STD][a][t] + Distr[CONSTANTS.CS_GT3STD][a][t]) * CSGetAgeWeight(a);
        }
    }

    return SingleDistr;
};

export const CSConvertIndicatorToZScores = (SingleDistr, Distr, FirstYrIdx, FinalYrIdx) => {
    let curYrSingle, firstYrSingle, curAgeYrDistr;
    let firstYrSDArr = new Array(CONSTANTS.CS_GT3STD + 1);

    if (FirstYrIdx < SingleDistr.length) {
        firstYrSingle = SingleDistr[FirstYrIdx];
        for (let t = FirstYrIdx; t <= FinalYrIdx; t++) {
            curYrSingle = SingleDistr[t];
            for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
                firstYrSDArr[CONSTANTS.CS_GT1STD] = Distr[CONSTANTS.CS_GT1STD][a][FirstYrIdx];
                firstYrSDArr[CONSTANTS.CS_1t2STD] = Distr[CONSTANTS.CS_1t2STD][a][FirstYrIdx];
                firstYrSDArr[CONSTANTS.CS_2t3STD] = Distr[CONSTANTS.CS_2t3STD][a][FirstYrIdx];
                firstYrSDArr[CONSTANTS.CS_GT3STD] = Distr[CONSTANTS.CS_GT3STD][a][FirstYrIdx];

                curAgeYrDistr = CSZScoreCalc(curYrSingle, firstYrSingle, firstYrSDArr);

                Distr[CONSTANTS.CS_GT1STD][a][t] = curAgeYrDistr[CONSTANTS.CS_GT1STD];
                Distr[CONSTANTS.CS_1t2STD][a][t] = curAgeYrDistr[CONSTANTS.CS_1t2STD];
                Distr[CONSTANTS.CS_2t3STD][a][t] = curAgeYrDistr[CONSTANTS.CS_2t3STD];
                Distr[CONSTANTS.CS_GT3STD][a][t] = curAgeYrDistr[CONSTANTS.CS_GT3STD];
            }
        }
    }

    return Distr;
};

export const FindIntervRec = (IVInfo, mstID) => {
    let foundIntervRec = false;
    let result = null;

    for (let i = 0; i < IVInfo.length; i++) {
        if (!foundIntervRec) {
            let IntervRec = IVInfo[i];
            if (IntervRec["MstID"] === mstID) {
                result = IntervRec;
                foundIntervRec = true;
            }
        }
    }
    return result;
};

/* This function retrieves the string for Diarrhea interventions */
export const CSGetMaternalIntervMstID = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_IPTp: {
            result = CONSTANTS.IV_IPT_Mal;
            break;
        }
        case CONSTANTS.CS_BalEnergy: {
            result = CONSTANTS.IV_BalEnergySupp;
            break;
        }
        case CONSTANTS.CS_IronFolateSupp: {
            result = CONSTANTS.IV_IronSuppPreg;
            break;
        }
        case CONSTANTS.CS_MultMicroLowBMI: {
            result = CONSTANTS.IV_MultiMicronSuppPreg;
            break;
        }
        case CONSTANTS.CS_MultMicroNormBMI: {
            result = CONSTANTS.IV_MultiMicronSuppPreg;
            break;
        }
        case CONSTANTS.CS_CalciumSupp: {
            result = CONSTANTS.IV_CalciumSupp;
            break;
        }
        case CONSTANTS.CS_FolicAcidFort: {
            result = CONSTANTS.IV_FolicAcidSupp;
            break;
        }
        case CONSTANTS.CS_ZincFort: {
            result = CONSTANTS.IV_ZincFort;
            break;
        }
        case CONSTANTS.CS_SyphilisTreat: {
            result = CONSTANTS.IV_SyphDetectTr;
            break;
        }
        case CONSTANTS.CS_ZincSuppInPreg: {
            result = CONSTANTS.IV_ZincSuppInPreg;
            break;
        }
        case CONSTANTS.CS_TreatOfBacteriuria: {
            result = CONSTANTS.IV_TreatOfBacteriuria;
            break;
        }
        case CONSTANTS.CS_Omeg3FASupp: {
            result = CONSTANTS.IV_Omeg3FASupp;
            break;
        }
        case CONSTANTS.CS_AspirinLowDose: {
            result = CONSTANTS.IV_AspirinLowDose;
            break;
        }
        case CONSTANTS.CS_CervicalStitch: {
            result = CONSTANTS.IV_CervicalStitch;
            break;
        }
        case CONSTANTS.CS_StopSmokingEduc: {
            result = CONSTANTS.IV_StopSmokingEduc;
            break;
        }
        case CONSTANTS.CS_SpareBOInterv1: {
            result = CONSTANTS.IV_SpareBOInterv1;
            break;
        }
        case CONSTANTS.CS_SpareBOInterv2: {
            result = CONSTANTS.IV_SpareBOInterv2;
            break;
        }
        case CONSTANTS.CS_SpareBOInterv3: {
            result = CONSTANTS.IV_SpareBOInterv3;
            break;
        }
        case CONSTANTS.CS_SpareBOInterv4: {
            result = CONSTANTS.IV_SpareBOInterv4;
            break;
        }
        case CONSTANTS.CS_ITN: {
            result = CONSTANTS.IV_ITN_IRS;
            break;
        }
        case CONSTANTS.CS_AgeAndBirthOrder: {
            result = CONSTANTS.IV_AgeAndBirthOrder;
            break;
        }
        case CONSTANTS.CS_BirthInterval: {
            result = CONSTANTS.IV_BirthIntervals;
            break;
        }
        default:
            break;
    }
    return result;
};

export const ImpactsOnStuntingStr = (i2) => {
    let result = "";

    switch (i2) {
        case CONSTANTS.CS_LowBirthWeight_TermAGA: {
            result = RS("GB_stT_AGA");
            break;
        }
        case CONSTANTS.CS_LowBirthWeight_TermSGA: {
            result = RS("GB_stT_SGA");
            break;
        }
        case CONSTANTS.CS_LowBirthWeight_PreTermAGA: {
            result = RS("GB_stPT_AGA");
            break;
        }
        case CONSTANTS.CS_LowBirthWeight_PreTermSGA: {
            result = RS("GB_stPT_SGA");
            break;
        }
        case CONSTANTS.CS_NotStuntedPrevAge: {
            result = RS("GB_stNotStuntedPrevAge");
            break;
        }
        case CONSTANTS.CS_StuntedPrevAge: {
            result = RS("GB_stStuntedPrevAge");
            break;
        }
        case CONSTANTS.CS_FoodSecureWPromo: {
            result = RS("GB_stFoodSecureWPromo");
            break;
        }
        case CONSTANTS.CS_FoodSecureWOPromo: {
            result = RS("GB_stFoodSecureWOPromo");
            break;
        }
        case CONSTANTS.CS_InsecureWPromoSupp: {
            result = RS("GB_stInsecureWPromoSupp");
            break;
        }
        case CONSTANTS.CS_InsecureWOPromoSupp: {
            result = RS("GB_stInsecureWOPromoSupp");
            break;
        }
        case CONSTANTS.CS_ImpDiarrPerEpisode: {
            result = RS("GB_stImpDiarrPerEpisode");
            break;
        }
        case CONSTANTS.CS_ZincSupp: {
            result = RS("GB_stZincSupp");
            break;
        }
        case CONSTANTS.CS_NotZincSupp: {
            result = RS("GB_stNotZincSupp");
            break;
        }
        case CONSTANTS.CS_ImpactsOnStuntingFinal: {
            result = "-";
            break;
        } // ???
        default:
            break;
    }
    return result;
};

export const ImpactsOnWastingStr = (i2) => {
    let result = "";

    switch (i2) {
        case CONSTANTS.CS_ImpactsOnWaste_Therafeed: {
            result = RS("GB_stSAMrecoveryRate");
            break;
        }
        case CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat: {
            result = RS("GB_stFractionChildrenMovMildWastWithTreat");
            break;
        }
        case CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovModWastWithTreat: {
            result = RS("GB_stFractionChildrenMovModWastWithTreat");
            break;
        }
        case CONSTANTS.CS_ImpactsOnWaste_FractionChildrenMovSevWastWithTreat: {
            result = RS("GB_stFractionChildrenMovSevWastWithTreat");
            break;
        }
        case CONSTANTS.CS_FoodSecureWPromo: {
            result = RS("GB_stFoodSecureWPromo");
            break;
        }
        case CONSTANTS.CS_FoodSecureWOPromo: {
            result = RS("GB_stFoodSecureWOPromo");
            break;
        }
        case CONSTANTS.CS_InsecureWPromoSupp: {
            result = RS("GB_stInsecureWPromoSupp");
            break;
        }
        case CONSTANTS.CS_InsecureWOPromoSupp: {
            result = RS("GB_stInsecureWOPromoSupp");
            break;
        }
        default:
            break;
    }
    return result;
};

/* Impacts on maternal anemia */
export const CSGetMaternalAnemiaCurrID = (i) => {
    let result = "";

    switch (i) {
        case CONSTANTS.CS_IronFolatePW_MatAnemia: {
            result = CONSTANTS.IV_IronSuppPreg;
            break;
        }
        case CONSTANTS.CS_MMicroSuppPW_MatAnemia: {
            result = CONSTANTS.IV_MultiMicronSuppPreg;
            break;
        }
        case CONSTANTS.CS_IronFortNPW_MatAnemia: {
            result = CONSTANTS.IV_BlanketIronSuppFort;
            break;
        }
        case CONSTANTS.CS_IPTP_MatAnemia: {
            result = CONSTANTS.IV_IPT_Mal;
            break;
        }
        case CONSTANTS.CS_ITN_MatAnemia: {
            result = CONSTANTS.IV_ITN_IRS;
            break;
        }
        default:
            break;
    }
    return result;
};

/* Impacts on maternal anemia */
export const CSGetImpactPromoAgeAppropriateBFStr = (i) => {
    let result = "";

    switch (i) {
        case CONSTANTS.CS_BFPromoHealthSystem: {
            result = RS("GB_stHealthSystemPromotion");
            break;
        }
        case CONSTANTS.CS_BFPromoHomeComm: {
            result = RS("GB_stHomeCommPromotion");
            break;
        }
        case CONSTANTS.CS_BFPromoBoth: {
            result = RS("GB_stHealthSystemHomeCommPromo");
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetHerdDiseases = (vt) => {
    let result = [];
    switch (vt) {
        case CONSTANTS.CS_DPT_Herd: {
            result = [CONSTANTS.CS_Pertussis];
            break;
        }
        case CONSTANTS.CS_Hib_Herd: {
            result = [CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis];
            break;
        }
        case CONSTANTS.CS_Measles_Herd: {
            result = [CONSTANTS.CS_Measles];
            break;
        }
        case CONSTANTS.CS_Pneumococcal_Herd: {
            result = [CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis];
            break;
        }
        case CONSTANTS.CS_MeningococcalA_Herd: {
            result = [CONSTANTS.CS_Meningitis];
            break;
        }
        case CONSTANTS.CS_Rotavirus_Herd: {
            result = [CONSTANTS.CS_Diarrhea];
            break;
        }
        case CONSTANTS.CS_Bednets_Herd: {
            result = [CONSTANTS.CS_Malaria];
            break;
        }
        case CONSTANTS.CS_Malaria_Herd: {
            result = [CONSTANTS.CS_Malaria];
            break;
        }
        case CONSTANTS.CS_DiarVaccPathB_Herd:
        case CONSTANTS.CS_DiarVaccPathC_Herd:
        case CONSTANTS.CS_VaccineD_Herd: {
            result = [
                CONSTANTS.CS_Diarrhea,
                CONSTANTS.CS_Pneumonia,
                CONSTANTS.CS_Meningitis,
                CONSTANTS.CS_Measles,
                CONSTANTS.CS_Malaria,
                CONSTANTS.CS_Pertussis,
                CONSTANTS.CS_OtherInfecDis,
                CONSTANTS.CS_OtherNCD,
            ];
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetHerdName = (vt) => {
    let result = "";
    switch (vt) {
        case CONSTANTS.CS_DPT_Herd: {
            result = result = RS("GB_stDPTVacc");
            break;
        }
        case CONSTANTS.CS_Hib_Herd: {
            result = result = RS("CS_stHibVacc");
            break;
        }
        case CONSTANTS.CS_Measles_Herd: {
            result = result = RS("GB_stMeaslesVacc");
            break;
        }
        case CONSTANTS.CS_Pneumococcal_Herd: {
            result = result = RS("GB_stPneumoVacc");
            break;
        }
        case CONSTANTS.CS_MeningococcalA_Herd: {
            result = result = RS("GB_stMeningA");
            break;
        }
        case CONSTANTS.CS_Rotavirus_Herd: {
            result = result = RS("GB_stRotaVacc");
            break;
        }
        case CONSTANTS.CS_Bednets_Herd: {
            result = result = RS("GB_stBednets");
            break;
        }
        case CONSTANTS.CS_Malaria_Herd: {
            result = result = RS("GB_stMalariaVacc");
            break;
        }
        case CONSTANTS.CS_DiarVaccPathB_Herd: {
            result = result = RS("GB_stDiarrheaVaccPathB");
            break;
        }
        case CONSTANTS.CS_DiarVaccPathC_Herd: {
            result = result = RS("GB_stDiarrheaVaccPathC");
            break;
        }
        case CONSTANTS.CS_VaccineD_Herd: {
            result = result = RS("GB_stVaccineD");
            break;
        }
        default:
            break;
    }
    return result;
};

/* This function retrieves the string for SGA and AGA */
export const ImpactsAnemiaMatMortStr = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.CS_Mat_NotAnemic: {
            result = RS("GB_stNotSeverelyAnemic");
            break;
        }
        case CONSTANTS.CS_Mat_Anemic: {
            result = RS("GB_stSeverelyAnemic");
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSGetEditorCatName = (i) => {
    let result = "";
    switch (i) {
        case CONSTANTS.GB_ChildHealth: {
            result = result = RS("GB_stChildHealth");
            break;
        }
        case CONSTANTS.GB_MaternalHealth: {
            result = result = RS("GB_stMaternalHealth");
            break;
        }
        case CONSTANTS.GB_NutritionalStatus: {
            result = result = RS("GB_stNutStat");
            break;
        }
        case CONSTANTS.GB_Pathogens: {
            result = result = RS("GB_stPathogens");
            break;
        }
        case CONSTANTS.GB_ChildMortality: {
            result = result = RS("GB_stChildMortality");
            break;
        }
        case CONSTANTS.GB_MaternalMortality: {
            result = result = RS("GB_stMaternalMortality");
            break;
        }
        case CONSTANTS.GB_Abortion: {
            result = result = RS("GB_stAbortion");
            break;
        }
        case CONSTANTS.GB_Stillbirths: {
            result = result = RS("GB_stStillbirths");
            break;
        }
        case CONSTANTS.GB_HouseholdStatus: {
            result = result = RS("GB_stHouseholdStatus");
            break;
        }
        case CONSTANTS.GB_Coverage: {
            result = result = RS("GB_stCoverage");
            break;
        }
        case CONSTANTS.GB_Effectiveness: {
            result = result = RS("GB_stEffectiveness");
            break;
        }
        case CONSTANTS.GB_ImpactsOnDiarrheaInc: {
            result = result = RS("GB_stImpactsDiar");
            break;
        }
        case CONSTANTS.GB_ImpactsOnMeningInc: {
            result = result = RS("GB_stImpactsMenin");
            break;
        }
        case CONSTANTS.GB_ImpactsOnPneumInc: {
            result = result = RS("GB_stImpactsPneu");
            break;
        }
        case CONSTANTS.GB_HerdEffectiveness: {
            result = result = RS("GB_stHerdEff");
            break;
        }
        case CONSTANTS.GB_NutritionInterventions: {
            result = result = RS("GB_stNutritionInterv");
            break;
        }
        case CONSTANTS.GB_ImpactUnderNutrition: {
            result = result = RS("GB_stImpactUnderNutrition");
            break;
        }
        default:
            break;
    }
    return result;
};

export const CSIVSelected = (CSModvars, IntervRec) => {
    /* Do not clone as this modvar is not being changed. */
    const selectedIVSet = getModvarByTag(CSModvars, CONSTANTS.CS_TG_SelectedIVSet_MV)["value"]["intArray"];

    let result =
        selectedIVSet.includes(IntervRec.MstID) ||
        (IntervRec.MstID === CONSTANTS.IV_ChangeStuntingPrev &&
            selectedIVSet.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfStunting)) ||
        (IntervRec.MstID === CONSTANTS.IV_ChangeWastingPrev &&
            selectedIVSet.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting)) ||
        (IntervRec.MstID === CONSTANTS.IV_ChangesInBF &&
            selectedIVSet.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF));

    return result;
};

export const CSIVGroupIVMatch = (IVInfo, i, GroupRecord) => {
    let result =
        GroupRecord["MstID"] === IVInfo[i]["GroupMstID"] ||
        (IVInfo[i]["MstID"] === CONSTANTS.IV_ChangesInBF &&
            GroupRecord["MstID"] === CONSTANTS.CS_GroupMstID_Breastfeeding);

    return result;
};

/* This function retrieves the string for different discount rates */
export const CSGetDiscountRateName = (d) => {
    let result = "";
    switch (d) {
        case CONSTANTS.CS_3Perc: {
            result = "3%";
            break;
        }
        case CONSTANTS.CS_5Perc: {
            result = "5%";
            break;
        }
        case CONSTANTS.CS_10Perc: {
            result = "10%";
            break;
        }
        default:
            break;
    }
    return result;
};

export const Get_FP_AgeName = (idx) => {
    let str = "";
    switch (idx) {
        case CONSTANTS.FP_All_Ages:
            str = RS("GB_stAllAges");
            break;
        case CONSTANTS.FP_A15_19:
            str = "15-19";
            break;
        case CONSTANTS.FP_A20_24:
            str = "20-24";
            break;
        case CONSTANTS.FP_A25_29:
            str = "25-29";
            break;
        case CONSTANTS.FP_A30_34:
            str = "30-34";
            break;
        case CONSTANTS.FP_A35_39:
            str = "35-39";
            break;
        case CONSTANTS.FP_A40_44:
            str = "40-44";
            break;
        case CONSTANTS.FP_A45_49:
            str = "45-49";
            break;
        default:
            str = "";
    }
    return str;
};

export const Get_FP_UseSingleAgesValue = (DPModvars) => {
    let ageGroupOptionIdx = getModvarByTag(DPModvars, CONSTANTS.FP_TG_TAgeGroupOption_MV)["value"];
    let useSingleAges = ageGroupOptionIdx === CONSTANTS.FP_Single_Age_Group;

    return useSingleAges;
};

export const Get_FP_FirstMethod = (cat) => {
    let firstMethod = 0;
    switch (cat) {
        case CONSTANTS.FP_Condom:
            firstMethod = CONSTANTS.FP_CondomFM;
            break;
        case CONSTANTS.FP_Sterilization:
            firstMethod = CONSTANTS.FP_FSter;
            break;
        case CONSTANTS.FP_Injectable:
            firstMethod = CONSTANTS.FP_Inj3month;
            break;
        case CONSTANTS.FP_Implant:
            firstMethod = CONSTANTS.FP_Implanon;
            break;
        case CONSTANTS.FP_Pill:
            firstMethod = CONSTANTS.FP_StandardPill;
            break;
        case CONSTANTS.FP_TraditionalMeth:
            firstMethod = CONSTANTS.FP_Withdrawal;
            break;
        case CONSTANTS.FP_IUD:
            firstMethod = CONSTANTS.FP_IUDCopper;
            break;
        case CONSTANTS.FP_FertAwareness:
            firstMethod = CONSTANTS.FP_LAM;
            break;
        case CONSTANTS.FP_BarrierMethods:
            firstMethod = CONSTANTS.FP_Vag_Barrier;
            break;
        case CONSTANTS.FP_CustomMethods:
            firstMethod = CONSTANTS.FP_Other;
            break;
        default:
            firstMethod = 0;
    }
    return firstMethod;
};

export const Get_FP_LastMethod = (cat) => {
    let lastMethod = 0;
    switch (cat) {
        case CONSTANTS.FP_Condom:
            lastMethod = CONSTANTS.FP_CondomML;
            break;
        case CONSTANTS.FP_Sterilization:
            lastMethod = CONSTANTS.FP_MSter;
            break;
        case CONSTANTS.FP_Injectable:
            lastMethod = CONSTANTS.FP_Uninject;
            break;
        case CONSTANTS.FP_Implant:
            lastMethod = CONSTANTS.FP_Sino_Implant;
            break;
        case CONSTANTS.FP_Pill:
            lastMethod = CONSTANTS.FP_Peri_coital;
            break;
        case CONSTANTS.FP_TraditionalMeth:
            lastMethod = CONSTANTS.FP_Traditional;
            break;
        case CONSTANTS.FP_IUD:
            lastMethod = CONSTANTS.FP_IUDLngIus;
            break;
        case CONSTANTS.FP_FertAwareness:
            lastMethod = CONSTANTS.FP_SDM;
            break;
        case CONSTANTS.FP_BarrierMethods:
            lastMethod = CONSTANTS.FP_VFT;
            break;
        case CONSTANTS.FP_CustomMethods:
            lastMethod = CONSTANTS.FP_Last_Method;
            break;
        default:
            lastMethod = 0;
    }
    return lastMethod;
};

export const Get_FP_MethodTypeName = (method) => {
    let str = "";
    if (method < CONSTANTS.FP_LastDefMethod) {
        switch (method) {
            case CONSTANTS.FP_CondomFM:
                str = RS("GB_stCondom");
                break;
            case CONSTANTS.FP_CondomML:
                str = RS("GB_stCondom");
                break;
            case CONSTANTS.FP_FSter:
                str = RS("GB_stSterilization");
                break;
            case CONSTANTS.FP_MSter:
                str = RS("GB_stSterilization");
                break;
            case CONSTANTS.FP_Inj3month:
                str = RS("GB_stInjectable");
                break;
            case CONSTANTS.FP_Inj2month:
                str = RS("GB_stInjectable");
                break;
            case CONSTANTS.FP_Inj1month:
                str = RS("GB_stInjectable");
                break;
            case CONSTANTS.FP_Inj6month:
                str = RS("GB_stInjectable");
                break;
            case CONSTANTS.FP_Uninject:
                str = RS("GB_stInjectable");
                break;
            case CONSTANTS.FP_Implanon:
                str = RS("GB_stImplant");
                break;
            case CONSTANTS.FP_Jadelle:
                str = RS("GB_stImplant");
                break;
            case CONSTANTS.FP_Sino_Implant:
                str = RS("GB_stImplant");
                break;
            case CONSTANTS.FP_StandardPill:
                str = RS("GB_stPill");
                break;
            case CONSTANTS.FP_Progestin:
                str = RS("GB_stPill");
                break;
            case CONSTANTS.FP_Peri_coital:
                str = RS("GB_stPill");
                break;
            case CONSTANTS.FP_Withdrawal:
                str = RS("GB_stTraditional");
                break;
            case CONSTANTS.FP_PerAbst:
                str = RS("GB_stTraditional");
                break;
            case CONSTANTS.FP_Traditional:
                str = RS("GB_stTraditional");
                break;
            case CONSTANTS.FP_IUDCopper:
                str = RS("GB_stIUD");
                break;
            case CONSTANTS.FP_IUDLngIus:
                str = RS("GB_stIUD");
                break;
            case CONSTANTS.FP_LAM:
                str = RS("GB_stFertilityAwareness");
                break;
            case CONSTANTS.FP_SDM:
                str = RS("GB_stFertilityAwareness");
                break;
            case CONSTANTS.FP_Vag_Barrier:
                str = RS("GB_stBarrierMethods");
                break;
            case CONSTANTS.FP_VFT:
                str = RS("GB_stBarrierMethods");
                break;
            default:
                break;
        }
    } else {
        str = RS("GB_stCustomMethods");
    }

    return str;
};

export const Get_FP_NumActiveMethods = (DPModvars) => {
    let ActiveMethodsValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TActiveMethods_MV)["value"];
    let numActiveMethods = 0;
    for (let m = 0; m < CONSTANTS.FP_Max_Methods; m++) {
        if (ActiveMethodsValue[m]) {
            numActiveMethods++;
        }
    }

    return numActiveMethods;
};

export const Get_FP_EditorMethodNames = (pt, DPModvars) => {
    let ActiveMethodsValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TActiveMethods_MV)["value"];
    let MethodNameValue = getModvarCloneByTag(DPModvars, CONSTANTS.FP_TG_TMethodName_MV)["value"];

    let row = 1;
    let addCategory;
    let methods;

    for (let cat = CONSTANTS.FP_Condom; cat <= CONSTANTS.FP_CustomMethods; cat++) {
        methods = [];
        addCategory = false;
        for (let i = Get_FP_FirstMethod(cat); i <= Get_FP_LastMethod(cat); i++) {
            if (ActiveMethodsValue[i]) {
                addCategory = true;
                methods.push(MethodNameValue[i]);
            }
        }
        if (addCategory) {
            for (let m = 0; m < methods.length; m++) {
                pt.tableData.value[row][0] = methods[m];
                row++;
            }
        }
    }

    return pt;
};

export const HA_AgeCatStr = (sex, age) => {
    let result = "";
    switch (age) {
        case CONSTANTS.HA_AllAges: {
            result = RS("GB_stAllAges");
            break;
        }
        case CONSTANTS.HA_15_24: {
            if (sex === CONSTANTS.DP_Female) {
                result = RS("GB_stAGYW");
            } else if (sex === CONSTANTS.DP_Male) {
                result = RS("GB_stABYM");
            } else if (sex === CONSTANTS.DP_BothSexes) {
                result = RS("GB_st15_24");
            }
            break;
        }
        case CONSTANTS.HA_25_34: {
            result = RS("GB_st25_34");
            break;
        }
        case CONSTANTS.HA_35_44: {
            result = RS("GB_st35_44");
            break;
        }
        case CONSTANTS.HA_45_54: {
            result = RS("GB_st45Plus");
            break;
        }
        default:
            result = "";
    }
    return result;
};

export const HA_PrEpIntervStr = (id) => {
    let result = "";
    switch (id) {
        case CONSTANTS.HA_Oral: {
            result = RS("GB_stOral");
            break;
        }
        case CONSTANTS.HA_Inject: {
            result = RS("GB_stInjectable");
            break;
        }
        case CONSTANTS.HA_Gel: {
            result = RS("GB_stGel");
            break;
        }
        case CONSTANTS.HA_Ring: {
            result = RS("GB_stRing");
            break;
        }
        default:
            result = "";
    }
    return result;
};

export const getChartOptFieldVal = (chartOptions, field, field2 = "") => {
    let result = 0;
    let found = false;
    if (chartOptions[field] !== undefined) {
        if (field2 !== "") {
            if (chartOptions[field][field2] !== undefined) {
                result = chartOptions[field][field2];
                found = true;
            }
        }
        if (!found) {
            result = chartOptions[field];
        }
    }
    return result;
};

const GetARVRegimen = (ARVRegimen, RegimenPeriod, regimen, PerNum, t) => {
    return ARVRegimen[RegimenPeriod][regimen][PerNum][t];
};

export const GetDPPMTCTValue = (CSModvars, t) => {
    let ARVRegimen = getModvarByTag(CSModvars, CONSTANTS.DP_TGX_ARVRegimen_MV3)["vValue"];
    let NeedPMTCT = getModvarByTag(CSModvars, CONSTANTS.DP_TGX_ChildNeedPMTCT_MV)["vValue"];
    let OnPMTCT = getModvarByTag(CSModvars, CONSTANTS.DP_TGX_ChildOnPMTCT_MV)["vValue"];

    let Value60_1, Value60_2, Value20_1, Value17_1, Value3_1, Result;

    /* if one of these values is not zero, then the user changed a value so the PMTCT that
       is returned must to be calculated */
    if (
        GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_SingleDoseNevir,
            CONSTANTS.DP_Number,
            t
        ) !== 0 ||
        GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_SingleDoseNevir,
            CONSTANTS.DP_Percent,
            t
        ) !== 0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_DualARV, CONSTANTS.DP_Number, t) !==
            0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_DualARV, CONSTANTS.DP_Percent, t) !==
            0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Number, t) !== 0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Percent, t) !== 0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PostnatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Number, t) !== 0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PostnatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Percent, t) !==
            0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_OptA, CONSTANTS.DP_Number, t) !== 0 ||
        GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_TripleARTBefPreg,
            CONSTANTS.DP_Number,
            t
        ) !== 0 ||
        GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_TripleARTDurPreg,
            CONSTANTS.DP_Number,
            t
        ) !== 0 ||
        GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_TripleARTDurPreg_Late,
            CONSTANTS.DP_Number,
            t
        ) !== 0 ||
        GetARVRegimen(ARVRegimen, CONSTANTS.DP_PostnatalProphylaxis, CONSTANTS.DP_OptA, CONSTANTS.DP_Number, t) !== 0
    ) {
        if (NeedPMTCT[t] === 0) {
            Result = 0;
        } else {
            Result = (OnPMTCT[t] / NeedPMTCT[t]) * 100;
        }
    } else {
        /* if all of the values above are zero, then the values from the editor can be returned
       according to the 60-20-20 formula. the 20 for during preganancy is split 17 for  > 4 weeks
       and 3 for < 4 weeks */
        Value60_1 = GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_OptA,
            CONSTANTS.DP_Percent,
            t
        );
        Value60_2 = GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PostnatalProphylaxis,
            CONSTANTS.DP_OptA,
            CONSTANTS.DP_Percent,
            t
        );
        Value20_1 = GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_TripleARTBefPreg,
            CONSTANTS.DP_Percent,
            t
        );
        Value17_1 = GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_TripleARTDurPreg,
            CONSTANTS.DP_Percent,
            t
        );
        Value3_1 = GetARVRegimen(
            ARVRegimen,
            CONSTANTS.DP_PrenatalProphylaxis,
            CONSTANTS.DP_TripleARTDurPreg_Late,
            CONSTANTS.DP_Percent,
            t
        );
        Result = (Value60_1 + Value60_2) / 2 + Value20_1 + Value17_1 + Value3_1;
    }
    return Result;
};

/* 12/16/15 John updated the values */
export const GetUpdatedARVRegimenValues = (CSModvars, FirstYear, FinalYear, values) => {
    let ARVRegimen = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ARVRegimen_MV3);

    let i = 1;
    for (let t = FirstYear; t <= FinalYear; t++) {
        let value = values[i];

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_NoProphylaxis][CONSTANTS.DP_Number][t] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_NoProphylaxis][CONSTANTS.DP_Percent][
            t
        ] = 0; /*100-Value*/
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_TotalTreat][CONSTANTS.DP_Number][t] = 0;

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_SingleDoseNevir][CONSTANTS.DP_Number][
            t
        ] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_SingleDoseNevir][CONSTANTS.DP_Percent][
            t
        ] = 0;

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_DualARV][CONSTANTS.DP_Number][t] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_DualARV][CONSTANTS.DP_Percent][t] = 0;

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_OptA][CONSTANTS.DP_Number][t] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_OptA][CONSTANTS.DP_Percent][
            t
        ] = 0; /*value*0.6*/

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_OptB][CONSTANTS.DP_Number][t] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_OptB][CONSTANTS.DP_Percent][t] = 0;

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_TripleARTBefPreg][CONSTANTS.DP_Number][
            t
        ] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_TripleARTBefPreg][CONSTANTS.DP_Percent][t] =
            value * 0.8; /*0.2*/

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_TripleARTDurPreg][CONSTANTS.DP_Number][
            t
        ] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_TripleARTDurPreg][CONSTANTS.DP_Percent][t] =
            value * 0.15; /*0.17*/

        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_TripleARTDurPreg_Late][CONSTANTS.DP_Number][
            t
        ] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PrenatalProphylaxis][CONSTANTS.DP_TripleARTDurPreg_Late][
            CONSTANTS.DP_Percent
        ][t] = value * 0.05; /*value*0.03*/

        ARVRegimen["vValue"][CONSTANTS.DP_PostnatalProphylaxis][CONSTANTS.DP_NoProphylaxis][CONSTANTS.DP_Number][t] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PostnatalProphylaxis][CONSTANTS.DP_NoProphylaxis][CONSTANTS.DP_Percent][
            t
        ] = 100; /*100-value*/
        ARVRegimen["vValue"][CONSTANTS.DP_PostnatalProphylaxis][CONSTANTS.DP_TotalTreat][CONSTANTS.DP_Number][t] = 0;

        ARVRegimen["vValue"][CONSTANTS.DP_PostnatalProphylaxis][CONSTANTS.DP_OptA][CONSTANTS.DP_Number][t] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PostnatalProphylaxis][CONSTANTS.DP_OptA][CONSTANTS.DP_Percent][
            t
        ] = 0; /*value*0.6*/

        ARVRegimen["vValue"][CONSTANTS.DP_PostnatalProphylaxis][CONSTANTS.DP_OptB][CONSTANTS.DP_Number][t] = 0;
        ARVRegimen["vValue"][CONSTANTS.DP_PostnatalProphylaxis][CONSTANTS.DP_OptB][CONSTANTS.DP_Percent][t] = 0;

        i++;
    }

    return ARVRegimen;
};
