import React from "react";
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import RS from "@common/strings/RS";

class HomeBannerPaper extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isDeskTop    : PropTypes.bool,
    };

    static defaultProps = {
        isDeskTop    : true
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderBannerHeader = () => {

        let styles = {
            whiteText: {
                color: '#FFFFFF',
                margin: '30px 85px',
                textAlign: 'left',
                userSelect: 'none'
            },
            coloredText: {
                color: '#cb6814',
                margin: '30px 85px',
                textAlign: 'left',
                borderBottom: '2px solid lightgray',
                paddingBottom: '10px',
                userSelect: 'none'
            }
        };

        return (
            <h1 style={(this.props.isDeskTop) ? styles.whiteText : styles.coloredText}>
                {RS('GB_stWelcome')}
            </h1>
        );
    };

    renderBannerText = () => {

        let styles = {
            whiteText: {
                color: '#FFFFFF',
                margin: '10px 85px 30px 85px',
                fontWeight: 'normal',
                lineHeight: 1.5,
                textAlign: 'left',
                userSelect: 'none'
            },
            coloredText: {
                color: '#0c3a79',
                margin: '10px 85px 30px 85px',
                fontWeight: 'normal',
                lineHeight: 1.5,
                textAlign: 'left',
                borderBottom: '2px dotted #f78c30',
                paddingBottom: '20px',
                userSelect: 'none'
            }
        };
        return (
            <h2 style={(this.props.isDeskTop) ? styles.whiteText : styles.coloredText}>
                {RS('GB_stWelcomeText')}
            </h2>
        );
    };

    render() {

        const styles = {
            paperBox: {
                position: 'relative',
                width: '100%',
                // height: 355,
                // float: 'right',
                background: 'transparent',
                border: 'none',
                boxShadow: 'none'
            },
        };

        return (
            <Paper style={styles.paperBox}>
                {this.renderBannerHeader()}
                {this.renderBannerText()}
            </Paper>
        )
    }

}

export default HomeBannerPaper;