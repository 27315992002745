import * as React from 'react';
import PropTypes from "prop-types";
import TLabel from "@common/components/TLabel";
import TTabs2 from "@common/components/TTabs2"
import {RESTService2} from "../../../api/server_calls";
import CountryFlags from "./CountryFlags";

class CountryTabs extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onCountryLabelClick : PropTypes.func,
        isDeskTop           : PropTypes.bool,
    };

    static defaultProps = {
        onCountryLabelClick : () => console.log('onCountryLabelClick'),
        isDeskTop           : true,
    };

    state = {
        dataCountries       : []
    };

    componentDidMount() {
        RESTService2.getCountries(
            {modID: 15, ignoreNoHouseholdDataCountry: true, subnatMode: false},
            (res) => {
                // this.makeMap(res);
                this.filterCountries(res);
                // setTimeout(function() {
                //     document.querySelector("#root > div:first-of-type > div:first-of-type > div:nth-of-type(3)").scrollTop = 0;
                // }, 0);
            },
            (msg) => {
                console.log(msg);
            }
        )
    }

    //==================================================================================================================
    //
    //                                             Event Handlers
    //
    //==================================================================================================================

    filterCountries = (data) => {
        this.setState({
            dataCountries: data.map(x => x)
        });
    };

    onCountryClick = (selectedCountry, countryISO) => {
        this.props.onCountryLabelClick(selectedCountry, countryISO);
    };

    //==================================================================================================================
    //
    //                                                  Render
    //
    //==================================================================================================================

    renderTabs = ()  => {
        const styles = {
            wrapper : {
                marginTop: '80px'
            },
            tabSelectedStyle : {
                color: '#f78c30',
                fontWeight: 'bold'
            },
            tabRootStyle : {
                fontSize: '18px'
            },
            tabContentStyle : {
                // marginTop: '185px'
            },
            tabAppBarStyle : {
                position: "static",
            }
        };

        return (
            <div styles={styles.wrapper}>
                <TTabs2
                    tabNames={['A - B','C - E','F - K','L - N','O - R','S','T - Z']}
                    tabContents={[
                        this.renderCountryLabels(['A','B']),
                        this.renderCountryLabels(['C','D','E']),
                        this.renderCountryLabels(['F','G','H','I','J','K']),
                        this.renderCountryLabels(['L','M','N']),
                        this.renderCountryLabels(['O','P','Q','R']),
                        this.renderCountryLabels(['S']),
                        this.renderCountryLabels(['T','U','V','W','X','Y','Z']),
                    ]}
                    tabAppBarStyle={styles.tabAppBarStyle}
                    tabSelectedStyle={styles.tabSelectedStyle}
                    tabRootStyle={styles.tabRootStyle}
                    tabContentStyle={styles.tabContentStyle}
                />
            </div>
        );
    };

    renderCountryLabels = (letters) => {

        const styles = {
            countryList: {
                float: 'left',
                columnGap: '15px',
                //height: (this.props.isDeskTop) ? '500px' : '1200px',
                marginBottom: '25px',
                maxWidth:"100%",
                width: "100%"
            },
            labelStyle: {
                color: '#65A7EB',
                fontSize: '13px',
                padding: '4px',
                marginBottom: '15px',
                '&:hover' : {
                    fontWeight: 'bold'
                }
            },
            countryFlag : {
                marginTop: '0px',
                marginLeft: '0px',
                marginRight: '10px',
                width: '27px',
                float: 'left'
            }
        };

        let countryList = [];
        const firstLetter = new Set();

        for (let j = 0; j < letters.length; j++) {
            firstLetter.add(letters[j])
        }

        this.state.dataCountries.forEach((x, i) => {
            let name = x["countryRegionName"];
            let code = x["countryRegionCode"];
            let key  = code;

            if (name && firstLetter.has(name.charAt(0))) {
                countryList.push(
                    <div className="countryLabel" key={key}>
                        <CountryFlags
                            selectedCountry={name}
                            style={styles.countryFlag}
                        />
                        <TLabel
                            caption={name}
                            onClick={this.onCountryClick}
                            customProp={code}
                            style={styles.labelStyle}
                        />
                    </div>
                );
            }
        });

        return (
            <div style={styles.countryList}>
                {countryList}
            </div>
        );

    };

    render() {
        const styles = {
            tabStyle : {
                marginTop: '80px',
            },
        };

        return (
            <div ref={(ref) => this._div = ref}>
                <div style={styles.tabStyle}>
                    {this.renderTabs()}
                </div>
            </div>
        );
    }
}

export default CountryTabs;