import axios from 'axios';

const allowPingMessages = false;

export const makeUpload = (url, clientId, file, successFn, errorFn) => {

    const client = axios.create();

    let contentType = '';
    let ext = file.name.split('.').pop().toLowerCase();

    switch (ext) {
        case 'csv'  : contentType = 'text/csv'; break;
        case 'xls'  : contentType = 'application/vnd.ms-excel'; break;
        case 'xlsx' : contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
        case 'GTZ'  : contentType = 'application/GoalsTesting_GTZ_FILE'; break;
        case 'pjnz' : contentType = 'application/SPECTRUM_PJNZ_FILE'; break;
        default     : contentType = '';
    }

    console.log({
        methodName : 'makeUpload',
        headers: {
            'Content-Type': contentType,
            'SessionGuid' : clientId,
            'FileTitle'   : file.name
        },
        file: file
    });

    client
        .post(url, file, {
            headers: {
                'Content-Type': contentType,
                'SessionGuid' : clientId,
                'fileTitle'   : file.name.replace('.PJNZ','.pjnz')
            }
        })
        .then(response => {

            let output = {};
            output.methodName = 'makeUpload';
            output.result = response;
            console.log(output);

            successFn();
            //DoThen(response, successFn, errorFn);
        })
        .catch(error => {
            // errorFn();
            DoError(error, errorFn);
        });
};

export const makeRequest = (url, clientId, method, params, jsonParams, successFn, errorFn) => {

    let startTime = Date.now();

    if (
        (method !== "GBLoginService.Ping") ||
        (allowPingMessages)
    ) {
        console.log({
            _ : "SEND",
            methodName : method,
            params: {
                ...params,
                jsonMesg: jsonParams
            },
            timeSent : Date(startTime).toString()
        });
    }

    // console.log(jsonParams);

    axios
        .create({
            baseURL: url,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .post(url, {
            id: clientId,
            method: method,
            params: {
                ...params,
                jsonMesg: JSON.stringify(jsonParams)
            }
        })
        .then(response => {
            DoThen(response, startTime, successFn, errorFn);
        })
        .catch(error => {
            DoError(error, errorFn, method);
        });
};

const DoThen = (response, startTime, successFn, errorFn) => {

    let clientID = response.data.id;
    let method = JSON.parse(response.config.data).method;
    let params = JSON.parse(response.config.data).params;
    let result = response.data.result.Result;

    if (result === 'undefined') {
        result = undefined;
    }
    else if ((typeof result === 'string') && ((result.charAt(0) === '{') || (result.charAt(0) === '['))) {
        result = JSON.parse(response.data.result.Result);
    }
    else {
        result = response.data.result.Result;
    }

    let output = {};
    output._ = "GET";
    output.methodName = method;
    output.params = params;
    output.result = result;
    output.Time = Date.now() - startTime;
    output.timeReceived = Date(Date.now().toString());
    // console.log(output);

    if (
        (method !== "GBLoginService.Ping") ||
        (allowPingMessages)
    ) {
        console.log(output);
    }

    if (result.status === 1) {
        if (typeof errorFn !== 'undefined') {
            try {
                errorFn('The server returned an error: \n' + result.statusText);
            }
            catch(e) {
                DoError({message: 'An error has occurred on the client side after receiving the server data: \n' + e.message}, errorFn);
            }
        }
        else {
            DoError({message: 'The server returned an error: ' + result.statusText}, errorFn);
        }
    }
    else if (typeof successFn !== 'undefined') {
        try {
            if ((typeof result.result !== 'undefined') && (result.result !== null))  {
                successFn(result.result, clientID);
            }
            else {
                successFn(result, clientID);
            }
        }
        catch(e) {
            DoError({message: 'An error has occurred on the client side after receiving the server data: \n' + e.message}, errorFn);
        }
    }
};

const DoError = (e, errorFn) => {

    console.error(e);
    
    try {
        let message = "";

        // Did not contact the server
        if (typeof e.response === 'undefined') {
            switch (e.message) {
                case "Network Error" :
                    message = 'The server may be unavailable at this time.';
                    break;

                default:
                    message = 'An error has occurred with communicating to the server.';
                    break;
            }
        }
        else {
            // Contacted the server, but the server had an error
            if (typeof e.response.request.response !== 'object') {
                message = 'Error returned from the server\n' + e.response.request.response;
            }
            else {
                message = 'Error returned from the server: \n' + JSON.parse(e.response.request.response).error.message;
            }
        }

        if (typeof errorFn !== 'undefined') {
            // Custom error handling
            errorFn(message);
        }
        else { 
            // Generic error handling
            alert(message);
            console.error("Note to client-side programmer: You really should handle this error on the client side.");
        }

    }
    catch(e) {
        alert('An error has occurred on the client side after handling a server error: \n' + e.message);
    }

};