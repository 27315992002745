import React from "react";
import TButton from '@common/components/TButton';
import RS from "@common/strings/RS";

const LoginButton = (props) => {

    const { onClick, colorType = "primary", disabled } = props;

    const disabledColor = '#e4e4e4';
    const color1 = '#f78c30';
    const color2 = '#65A7EB';
    const color3 = '#c03600';

    const styles = {
        btn : {
            // width: '100px',
            fontSize: '16px',
            background : disabled ? disabledColor : colorType === "primary" ? color1 : colorType === "secondary" ? color2 : color3,
            marginLeft: '20px',
            //padding: '2px 10px',
            fontWeight: '400',
        }
    };

    return (
        <TButton
            caption={RS('GB_stLogIn')}
            variant={"contained"}
            primary={true}
            style={styles.btn}
            onClick={onClick}
            disabled={disabled}
        />
    );
};

export default LoginButton;