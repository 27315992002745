import RS from "@common/strings/RS";
import {
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {getEffectivenessOfInterventionsPackChart} from "../../../../../../../../src/data/CSUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const ReducInStuntEditor = {
    Create : (CSModvars) => {
        let editorName = RS('GB_stReducStunt');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable.Title = editorName;
        let packChart = getEffectivenessOfInterventionsPackChart(packTable, 0);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let EducationAttainMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ReducStunt_MV);
        EducationAttainMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy] = pt.tableData.value[1][1];
        EducationAttainMV["source"] = pt.Source[0];
        changedModvars.push(EducationAttainMV);
        return (changedModvars);
    },
};

const Setup = () => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    const numRows = 2;
    let numCols = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[0][1] = RS('GB_stAttainingSchooling');

    pt.tableData.value[1][0] = RS('GB_stOneUnitIncreaseHAZ');
    for (let c = 1; c <= numCols; c++) {
        pt.RDec[1][c] = 3;
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let col = 1; col < pt.GBColCount; col++){
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.MinAllowedVal[1][col] = 0;
        pt.MaxAllowedVal[1][col] = 1;
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let EducationAttain = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ReducStunt_MV);
    pt.tableData.value[1][1] = EducationAttain["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy];
    pt.Source[0] = EducationAttain["source"];
    return pt;
};

