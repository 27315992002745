import React from "react";
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import TRadioGroup from "@common/components/TRadioGroup";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

class LanguageDialog extends React.Component {
    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange: PropTypes.func,
        onSaveLanguage: PropTypes.func,
        language: PropTypes.number,
    };

    static defaultProps = {
        onStateChange: () => console.log("onStateChange"),
        onSaveLanguage: () => console.log("onSaveLanguage"),
        language: CONSTANTS.GB_English,
    };

    state = {
        selectedLanguage: this.props.language, //CONSTANTS.GB_English,
        language: this.props.language,
    };

    componentDidMount() {
        this.setLanguageIdx();
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    setLanguageIdx = () => {
        switch (this.state.language) {
            case CONSTANTS.GB_English: {
                window.App.languageIdx = CONSTANTS.GB_EnglishIdx;
                break;
            }
            case CONSTANTS.GB_French: {
                window.App.languageIdx = CONSTANTS.GB_FrenchIdx;
                break;
            }
            case CONSTANTS.GB_Spanish: {
                window.App.languageIdx = CONSTANTS.GB_SpanishIdx;
                break;
            }
            case CONSTANTS.GB_Portuguese: {
                window.App.languageIdx = CONSTANTS.GB_PortugueseIdx;
                break;
            }
            default: {
                window.App.languageIdx = CONSTANTS.GB_EnglishIdx;
            }
        }
    };

    onHandleRadioGroupChange = (value) => {
        let sl = CONSTANTS.GB_English;

        switch (value) {
            case 0: {
                sl = CONSTANTS.GB_English;
                break;
            }
            case 1: {
                sl = CONSTANTS.GB_French;
                break;
            }
            case 2: {
                sl = CONSTANTS.GB_Spanish;
                break;
            }
            case 3: {
                sl = CONSTANTS.GB_Portuguese;
                break;
            }
            default: {
                sl = CONSTANTS.GB_English;
            }
        }

        window.App.languageIdx = value;

        this.setState({
            selectedLanguage: sl,
        });
    };

    onSaveLanguageClick = () => {
        // this.props.onSaveLanguage(this.state.selectedLanguage);
        this.props.onStateChange(
            {
                language: this.state.selectedLanguage,
                dialogLanguageOpen: false,
            },
            () => {
                this.props.onSaveLanguage(this.state.selectedLanguage);
            }
        );
    };

    onClose = () => {
        this.props.onStateChange({
            dialogLanguageOpen: false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderLanguageDialogContent = () => {
        const styles = {
            // box : {
            //     width: '275px',
            //     margin: '0 auto',
            //     marginBottom: '10px',
            // },
            radioLabelStyle: {
                fontSize: "16px",
                color: "#0c3a79",
                // fontWeight: 'bold',
                display: "inline-block",
            },
        };

        return (
            <div style={styles.box}>
                <TRadioGroup
                    itemIndex={window.App.languageIdx}
                    items={[
                        "English",
                        "Français",
                        "Español",
                        // 'Português'
                    ]}
                    handleChange={this.onHandleRadioGroupChange}
                    labelStyle={styles.radioLabelStyle}
                    radioBtnColor={"secondary"}
                    sortInRow={false}
                />
            </div>
        );
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stSelectLanguage")}
                onClose={this.onClose}
                content={this.renderLanguageDialogContent()}
                actions={
                    <ApplyButton
                        disabled={this.state.language === this.state.selectedLanguage}
                        onClick={this.onSaveLanguageClick}
                        colorType="primary"
                    />
                }
            />
        );
    }
}

export default LanguageDialog;
