import React from "react";
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import TButton from '@common/components/TButton';
import RS from "@common/strings/RS";
import CreateAPITask from "../../api/util/createAPITask";
import ExploreLiSTDataImage from '../../img/exploreLiSTData.svg';
// import CONSTANTS from "utilities/CS/CSConst";

class ExploreLiSTDataPaper extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onAddTasks                 : PropTypes.func,
        onPageChange               : PropTypes.func,
        onExploreLiSTDataBtnClick  : PropTypes.func,
        isDeskTop                  : PropTypes.bool,
        countryISO                 : PropTypes.number,
        fromGuided                 : PropTypes.bool,
    };

    static defaultProps = {
        onAddTasks                : () => console.log('onAddTasks'),
        onPageChange              : () => console.log('ExploreLiSTDataPaper: onPageChange'),
        onExploreLiSTDataBtnClick : () => console.log('onExploreLiSTDataBtnClick'),
        isDeskTop                 : true,
        countryISO                : 4,
        fromGuided                : false,
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onExploreLiSTDataBtnClick = () => {
        if (this.props.fromGuided) {
            this.props.onAddTasks([
                CreateAPITask('getExploreLiSTDataFlag', {countryISO: this.props.countryISO, modList: [15, 3], useRawData: true},
                    () => {
                        this.props.onPageChange('CSExploreLiSTDataForm')
                    },
                    (msg) => {
                        alert(RS('GB_stErrorRetrieveCountryData'));
                    }
                )
            ]);
        }
        else {
            this.props.onExploreLiSTDataBtnClick();
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderExploreLiSTDataImage = () => {

        let styles = {
            img: {
                height: '90px',
                position: 'absolute',
                top: '10px',
                left: '200px'
            }
        };

        return (
            <img style={styles.img} src={ExploreLiSTDataImage} alt='' />
        );
    };

    renderExploreLiSTDataHeader = () => {

        let styles = {
            header: {
                position: 'absolute',
                marginTop: '105px',
                marginLeft: '10px',
                textAlign: 'left',
                color: '#0c3a79',
                userSelect: 'none'
            }
        };

        return (
            <h2 style={styles.header} >
                {RS('GB_stExploreLiSTData')}
            </h2>
        );
    };

    renderExploreLiSTDataText = () => {

        let styles = {
            text: {
                position: 'absolute',
                marginTop: '150px',
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '16px',
                textAlign: 'left',
                lineHeight: 1.5,
                width: '250px',
                userSelect: 'none'
            }
        };

        return (
            <p style={styles.text} >
                {RS('GB_stExploreText')}
            </p>
        );
    };

    renderExploreLiSTDataBtn = () => {

        let styles = {
            raisedButton: {
                width: '125px',
                fontSize: '16px',
                float: 'left'
            },
            raisedButtonDiv: {
                position: 'absolute',
                top: '300px',
                left: '10px'
            }
        };

        return (
            <TButton
                caption={RS('GB_stExplore')}
                color="primary"
                style={styles.raisedButton}
                containerStyle={styles.raisedButtonDiv}
                onClick={this.onExploreLiSTDataBtnClick}
            />
        );
    };

    render() {

        const styles = {
            paperBoxHoriz: {
                position: 'relative',
                width: 300,
                height: 355,
                float: 'left',
                background: 'white'
            },

            paperBoxVert: {
                position: 'relative',
                width: 300,
                height: 355,
                marginBottom: '20px',
                background: 'white'
            },

            infoBox: {
                width: 275,
                margin: '0 auto'
            }
        };

        return (
            <Paper style={(this.props.isDeskTop) ? styles.paperBoxHoriz : styles.paperBoxVert}>
                <div style={styles.infoBox}>
                    {this.renderExploreLiSTDataImage()}
                    {this.renderExploreLiSTDataHeader()}
                    {this.renderExploreLiSTDataText()}
                    {this.renderExploreLiSTDataBtn()}
                </div>
            </Paper>
        )
    }

}

export default ExploreLiSTDataPaper;