import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
    CloneObj,
    getIdxFromYear,
    lockPackTable,
    addRowToPackTable, generatePackChart,
} from "utilities/GB/GBUtil";

import {
    CSConvertIndicatorToZScores,
    LockSingleStuntWastCells
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const SingleStuntWastEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, StuntWast, exploreData = false) => {
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, StuntWast, exploreData);
        packTable = SetSSData(packTable, CSModvars, StuntWast);
        switch (StuntWast) {
            case CONSTANTS.CS_GroupMstID_Stunting :{
                packTable.Title = RS('GB_stStuntingDist');
                packTable.ID = CONSTANTS.CS_MstLiSTOnlineStuntingSheetID;
                break;
            }
            case CONSTANTS.CS_GroupMstID_Wasting :{
                packTable.Title = RS('GB_stWastingDist');
                packTable.ID = CONSTANTS.CS_MstLiSTOnlineWastingSheetID;
                break;
            }
            default : break;
        }
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_Coverage;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars, StuntWast) => {
        let CSModvarsClone = CloneObj(CSModvars);

        let SingleDistr;
        let Distr;
        switch (StuntWast) {
            case CONSTANTS.CS_GroupMstID_Stunting :{
                SingleDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleStuntingDistr_MV)["value"];
                Distr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_StuntingDistr_MV);
                break;
            }
            case CONSTANTS.CS_GroupMstID_Wasting :{
                SingleDistr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_SingleWastingDistr_MV)["value"];
                Distr = getModvarByTag(CSModvarsClone, CONSTANTS.CS_TG_WastingDistr_MV);
                break;
            }
            default : break;
        }
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            SingleDistr[t] = pt.tableData.value[1][col];
            col++;
        }
        Distr["value"] = CSConvertIndicatorToZScores(SingleDistr, Distr["value"], pt.StartIndex, pt.EndIndex);

        return (CSModvarsClone);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, StuntWast, exploreData) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let pt = getNewPT();

    pt.StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    pt.EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    pt.yearArr = [];
    let numCols = pt.EndIndex - pt.StartIndex + 2;//+2 for extra col on the side, and difference math
    for (let t = pt.StartIndex; t <= pt.EndIndex; t++) {
        pt.yearArr.push(t);
    }

    const numRows = 1;

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++) {
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        col++;
    }

    pt.tableData.value[0][0] = RS('GB_stInterventionPercent');

    pt = addRowToPackTable(pt);
    switch (StuntWast) {
        case CONSTANTS.CS_GroupMstID_Stunting : {pt.tableData.value[1][0] = RS('GB_stGlobalStuntRate'); break;}
        case CONSTANTS.CS_GroupMstID_Wasting  : {pt.tableData.value[1][0] = RS('GB_stGlobalWasteRate'); break;}
        default : break;
    }
    pt = LockSingleStuntWastCells(pt, EditorStartYear, EditorEndYear);
    if (exploreData){
        pt = lockPackTable(pt, 1, true, false);
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let col = 0; col < pt.GBColCount; col++){
        if (typeof pt.tableData.value[1][col] === 'number'){
            pt.Alignments[1][col] = CONSTANTS.GB_Right;
            pt.RDec[1][col] = 1;
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars, StuntWast) => {
    let tag;
    switch (StuntWast) {
        case CONSTANTS.CS_GroupMstID_Stunting : {tag = CONSTANTS.CS_TG_SingleStuntingDistr_MV; break;}
        case CONSTANTS.CS_GroupMstID_Wasting  : {tag = CONSTANTS.CS_TG_SingleWastingDistr_MV; break;}
        default : break;
    }
    let SingleDistr = getModvarByTag(CSModvars, tag)["value"];
    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[1][col] = SingleDistr[t];
        if (pt.tableData.value[1][col] < 0){
            pt.tableData.value[1][col] = '';
        }
        col++;
    }
    return pt;
};

