import React from 'react';
import PropTypes from "prop-types";
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CreateAPITask from "../../api/util/createAPITask";
import { SessionStorageUtil } from "../../api/localstorage/util";
import CSDialog from "./CSDialog";
import CreateAccountButton from "../buttons/CreateAccountButton";

const styles = {
    entryText : {
        // display:"block",
        width: "100%"
    }
};

class AccountCreateDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        isGuest        : PropTypes.bool,
        onStateChange  : PropTypes.func,
        onAddTasks     : PropTypes.func,
    };

    static defaultProps = {
        isGuest        : true,
        onStateChange  : () => console.log("onStateChange"),
        onAddTasks     : () => console.log("onAddTasks"),
    };

    state = {
        emailVal : "",
        passwordVal : "",
        confirmPasswordVal : "",
        firstNameVal : "",
        lastNameVal : "",
        locationVal : "",
        orgVal : "",
        newFirstNameVal: "",
        newMiddleNameVal: "",
        newLastNameVal: "",
        oldPasswordVal: "",
        newPasswordVal: "",
        newSalutationVal: "",
        newOrgVal: ""
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogAccountCreateOpen : false
        });
    };

    onCreateAccountBtnClick = () => {
        if (this.props.isGuest) {
            //
        } 
        else {
            let params = {};
            let fullName = SessionStorageUtil.getHumanName().split(" ");
            if (this.state.newFirstNameVal.length > 0) {
                params.newFirstName = this.state.newFirstNameVal;
                fullName[0] = this.state.newFirstNameVal;
            }
            if (this.state.newMiddleNameVal.length > 0) {
                params.newMiddleName = this.state.newMiddleNameVal;
            }
            if (this.state.newLastNameVal.length > 0) {
                params.newLastName = this.state.newLastNameVal;
                fullName[1] = this.state.newLastNameVal;
            }
            if (this.state.oldPasswordVal.length > 0) {
                params.password = this.state.oldPasswordVal;
            }
            if (this.state.newPasswordVal.length > 0) {
                params.newPassword = this.state.newPasswordVal;
            }
            if (this.state.newOrgVal.length > 0) {
                params.organization = this.state.newOrgVal;
            }
            if (this.state.newSalutationVal.length > 0) {
                params.newSalutation = this.state.newSalutationVal;
            }
            fullName = fullName.join(" ");
            if (fullName !== SessionStorageUtil.getHumanName()) {
                params.author = fullName;
            }
            this.props.onAddTasks([
                CreateAPITask('setAccountInfoFlag',
                    params,
                    (res) => {
                        console.log(res);
                    },
                    (msg) => {
                        alert('[' + RS("GB_stError").toUpperCase() + '] ' + msg);
                    }
                )
            ]);
        }
        this.onClose();
    };

    onEmailType = (val) => {
        this.setState({emailVal: val});
    };

    onPasswordType = (val) => {
        this.setState({passwordVal: val});
    };

    onConfirmPasswordType = (val) => {
        this.setState({confirmPasswordVal: val});
    };

    onFirstNameType = (val) => {
        this.setState({firstNameVal: val});
    };

    onLastNameType = (val) => {
        this.setState({lastNameVal: val});
    };

    onLocationType = (val) => {
        this.setState({locationVal: val});
    };

    onOrgType = (val) => {
        this.setState({orgVal: val});
    };

    onNewSalutationType = (val) => {
        this.setState({newSalutationVal: val});
    };

    onNewFirstNameType = (val) => {
        this.setState({newFirstNameVal: val})
    };

    onNewMiddleNameType = (val) => {
        this.setState({newMiddleNameVal: val})
    };

    onNewLastNameType = (val) => {
        this.setState({newLastNameVal: val})
    };

    onOldPasswordType = (val) => {
        this.setState({oldPasswordVal: val})
    };

    onNewPasswordType = (val) => {
        this.setState({newPasswordVal: val})
    };

    onNewOrgType = (val) => {
        this.setState({newOrgVal: val})
    };

    refocus = (elem) => {
        document.querySelector(elem).focus();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderCreateAccountContent = () => {
        return (
            <div>
                <p>
                    {RS("GB_stCreateAccount") + " " + RS("GB_stTo") + " " + RS("GB_stSaveYourWork").toLowerCase()}
                    <span
                        style={{display: (SessionStorageUtil.getHumanName() !== "Guest") ? "none" : "inline-block"}}
                    >
                        {", " + RS("GB_stOr") + " "}
                        <button
                            href={""}
                            style={{cursor:"pointer", color:"blue"}}
                            onClick={() => this.onClose()}>{RS("GB_stUseAsGuest").toLowerCase()}
                        </button>
                    </span>.
                </p>
                <p>{RS("GB_stAccountDisclaimer")}</p>
                {/*<p>(You may create an account at the end of your projection)</p>*/}
                <TEdit
                    name="EmailField"
                    caption={RS("GB_stEmail")}
                    value={this.state.emailVal}
                    onChange={this.onEmailType}
                    style={styles.entryText}
                    type="email"
                    foc={true}
                    hitEnter={() => this.refocus("#PasswordField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="PasswordField"
                    caption={RS("GB_stPassword")}
                    type="password"
                    value={this.state.passwordVal}
                    onChange={this.onPasswordType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#ConfirmPasswordField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="ConfirmPasswordField"
                    caption={RS("GB_stConfirmPassword")}
                    type="password"
                    value={this.state.confirmPasswordVal}
                    onChange={this.onConfirmPasswordType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#FirstNameField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="FirstNameField"
                    caption={RS("GB_stFirstName")}
                    value={this.state.firstNameVal}
                    onChange={this.onFirstNameType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#LastNameField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="LastNameField"
                    caption={RS("GB_stLastName")}
                    value={this.state.lastNameVal}
                    onChange={this.onLastNameType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#LocationField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="LocationField"
                    caption={RS("GB_stLocation")}
                    value={this.state.locationVal}
                    onChange={this.onLocationType}
                    style={styles.entryText}
                    hitEnter={() => this.refocus("#OrgField")}
                    shrinkLabel={true}
                />
                <TEdit
                    name="OrgField"
                    caption={RS("GB_stOrganization")}
                    value={this.state.orgVal}
                    onChange={this.onOrgType}
                    style={styles.entryText}
                    hitEnter={() => this.onCreateAccountBtnClick()}
                    shrinkLabel={true}
                />
            </div>
        )
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stCreateAccount")}
                onClose={this.onClose}
                content={
                    <div>
                        {this.renderCreateAccountContent()}
                    </div>
                }
                actions={
                    <div>
                        <CreateAccountButton onClick={this.onCreateAccountBtnClick} />
                    </div>
                }
            />
        );
    }

}

export default AccountCreateDialog;