import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import MissedOpportunitiesSideBar from "../../pages/CS/TOOLS/MissedOpportunities/sideBar/MissedOpportunitiesSideBar";
import CSHomeListSideBar from "../sidebar/CSHomeListSideBar";
import CSProjectionListSideBar from "../sidebar/CSProjectionListSideBar";
import IconButton from '@material-ui/core/IconButton';
import PlayArrow from "@material-ui/icons/PlayArrow";
import CONSTANTS from "utilities/CS/CSConst";
// import MOSideBar from "../../pages/CS/TOOLS/MissedOpportunities/sideBar/MOSideBar";

class AppSideBarCustom extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onCreateProjBtnClick           : PropTypes.func,
        onGuidedExplorationBtnClick    : PropTypes.func,
        onExploreLiSTDataBtnClick      : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onViewResults                  : PropTypes.func,
        onStateChange                  : PropTypes.func,
        onAddTasks                     : PropTypes.func,
        onHamburgerClick               : PropTypes.func,
        onToggleSideBar                : PropTypes.func,

        MOSideBarOpen                  : PropTypes.bool,
        pageID                         : PropTypes.string.isRequired,
        appMode                        : PropTypes.string,
        selectedCountry                : PropTypes.string,
        countryISO                     : PropTypes.number,
        countries                      : PropTypes.array,
        displayButtons                 : PropTypes.bool,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        projectionName                 : PropTypes.string,
        yearRange                      : PropTypes.object,
        projectCreated                 : PropTypes.bool,
        inputModvars                   : PropTypes.arrayOf(PropTypes.object),
        selectedProjSideBarIdx         : PropTypes.number,
        MOResultToDisplay              : PropTypes.number,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        subnatRegion                   : PropTypes.string,
        subnatSurvey                   : PropTypes.string,
        viewInterventionCost           : PropTypes.bool,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onCreateProjBtnClick           : () => console.log('onCreateProjBtnClick'),
        onGuidedExplorationBtnClick    : () => console.log('onGuidedExplorationBtnClick'),
        onExploreLiSTDataBtnClick      : () => console.log('onExploreLiSTDataBtnClick'),
        onPageChange                   : () => console.log('onPageChange'),
        onViewResults                  : () => console.log('onViewResults'),
        onStateChange                  : () => console.log('onStateChange'),
        onAddTasks                     : () => console.log('onAddTasks'),
        onHamburgerClick               : () => console.log('onHamburgerClick'),
        onToggleSideBar                : () => console.log('onToggleSideBar'),

        MOSideBarOpen                  : true,
        pageID                         : '',
        appMode                        : '',
        selectedCountry                : "Afghanistan",
        countryISO                     : 4,
        countries                      : [],
        displayButtons                 : true,
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayCPR                     : true,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        projectionName                 : '',
        yearRange                      : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
        projectCreated                 : false,
        inputModvars                   : [],
        selectedProjSideBarIdx         : 0,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        subnatRegion                   : RS("GB_stNoRegionSelected"),
        subnatSurvey                   : "",
        viewInterventionCost           : false,
        language                       : CONSTANTS.GB_English
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState)) 
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onLivesSavedToolLinkClick = () => {
        window.open("http://livessavedtool.org/", "_blank");
    };

    onToggleSideBar = () => {
        this.props.onToggleSideBar();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderDrawer = () => {
        if (this.props.appMode === CONSTANTS.CS_MissedOpportunitiesModeStep1) {
            return(this.props.MOSideBarOpen ? this.renderGuidedExplorationSideBar() : null)
        }
        else if (this.props.appMode === CONSTANTS.CS_ExploreLiSTDataModeStep1) {
            return null;
            // return(this.renderExploreLiSTDataSideBar())
        }
        else if ((this.props.pageID === 'CSHomeForm') && (!this.props.projectCreated)) {
            return null;
            // return(this.renderHomeListSideBar());
        }
        else if ((this.props.pageID === 'CSHomeForm') && (this.props.projectCreated)) {
            return null;
            // return(this.renderProjectionListSideBar());
        }
        if ((this.props.pageID === 'CSProjectionLibraryForm') && (!this.props.projectCreated)) {
            return null;
            // return(this.renderHomeListSideBar());
        }
        else if ((this.props.pageID === 'CSProjectionLibraryForm') && (this.props.projectCreated)) {
            return null;
            // return(this.renderProjectionListSideBar());
        }
        // else if ((this.props.pageID === 'CSCountryListForm') && (this))
        else if (
            (this.props.pageID === 'CSProjManagerForm') ||
            (this.props.pageID === 'CSReviewDefaultDataForm') ||
            (this.props.pageID === 'CSMaternalMortalityForm') ||
            (this.props.pageID === 'CSChildMortalityForm') ||
            (this.props.pageID === 'CSHouseholdStatusForm') ||
            (this.props.pageID === 'CSMaternalHealthForm') ||
            (this.props.pageID === 'CSChildHealthForm') ||
            (this.props.pageID === 'CSNutStatusDistForm') ||
            (this.props.pageID === 'CSAbortionForm') ||
            (this.props.pageID === 'CSStillbirthsForm') ||
            (this.props.pageID === 'CSPathogensForm') ||
            (this.props.pageID === 'CSCoverageForm') ||
            (this.props.pageID === 'CSBreastfeedingForm') ||
            (this.props.pageID === 'CSStuntingForm') ||
            (this.props.pageID === 'CSWastingForm') ||
            (this.props.pageID === 'CSSelectInterventionsForm') ||
            (this.props.pageID === 'CSViewResultsForm') ||
            (this.props.pageID === 'CSManageInterventionsForm')
        ) {
            return null;
            // return(this.renderProjectionListSideBar());
        } else {
            return null;
            // return(this.renderHomeListSideBar());
        }
    };

    renderGuidedExplorationSideBar = () => {
        return (
            <MissedOpportunitiesSideBar
                onStateChange                  = {this.props.onStateChange}
                onPageChange                   = {this.props.onPageChange}
                onAddTasks                     = {this.props.onAddTasks}
                appMode                        = {this.props.appMode}
                selectedCountry                = {this.props.selectedCountry}
                countryISO                     = {this.props.countryISO}
                countries                      = {this.props.countries}
                displayButtons                 = {this.props.displayButtons}
                displayMaternalCOD             = {this.props.displayMaternalCOD}
                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                displayChildCOD                = {this.props.displayChildCOD}
                displayCPR                     = {this.props.displayCPR}
                deliveryPointsContent          = {this.props.deliveryPointsContent}
                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                MOResultToDisplay              = {this.props.MOResultToDisplay}
                maternalIntervSelected         = {this.props.maternalIntervSelected}
                stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                childIntervSelected            = {this.props.childIntervSelected}
                nutritionSelected              = {this.props.nutritionSelected}
                birthOutcomeSelected           = {this.props.birthOutcomeSelected}
                viewInterventionCost           = {this.props.viewInterventionCost}
                language                       = {this.props.language}
            />
        )
    };

    renderHomeListSideBar = () => {
        return (
            <CSHomeListSideBar
                onCreateProjBtnClick        = {this.props.onCreateProjBtnClick}
                onGuidedExplorationBtnClick = {this.props.onGuidedExplorationBtnClick}
                onExploreLiSTDataBtnClick   = {this.props.onExploreLiSTDataBtnClick}
            />
        )
    };

    renderProjectionListSideBar = () => {
        return (
            <CSProjectionListSideBar
                onPageChange           = {this.props.onPageChange}
                onViewResults          = {this.props.onViewResults}
                onStateChange          = {this.props.onStateChange}
                pageID                 = {this.props.pageID}
                projectionName         = {this.props.projectionName}
                selectedCountry        = {this.props.selectedCountry}
                yearRange              = {this.props.yearRange}
                projectCreated         = {this.props.projectCreated}
                selectedProjSideBarIdx = {this.props.selectedProjSideBarIdx}
                subnatRegion           = {this.props.subnatRegion}
                subnatSurvey           = {this.props.subnatSurvey}
            />
        )
    };

    render() {
        let styles = {
            btn: {
                // float: "right",
                color: this.props.appMode === CONSTANTS.CS_ProjectionMode ? "white" : "rgb(5, 34, 93)",
                zIndex: 100,
                cursor: "pointer",
                marginTop:30,
                //marginRight: 2,
                padding:0,
                left:"calc(100% - 38px)",
            },
            chevron: {
                fontSize:25,
                fontWeight:"bold",
                transform: this.props.MOSideBarOpen ? "rotate(180deg)" : "none"
            }
        };

        return (
            <div>
                <IconButton
                    style={styles.btn}
                    onClick={this.onToggleSideBar}
                >
                    <PlayArrow
                        style={styles.chevron}
                    />
                </IconButton>
                {this.renderDrawer()}
            </div>
        );
    }

}

export default AppSideBarCustom;