import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    ImpactsAnemiaMatMortStr
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const ImpactsAnemiaMatMortEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stOddsRatioSevereAnemicWomen');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ImpactUnderNutrition;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let RelRisksAnemia = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_RelRisksAnemia_MV2);

        let row = 2;
        for (let d = CONSTANTS.CS_Mat_AntepartHemorr; d <= CONSTANTS.CS_Mat_PostpartHemorr; d++){
            let col = 1;
            for (let c = CONSTANTS.CS_Mat_NotAnemic; c <= CONSTANTS.CS_Mat_Anemic; c++){
                RelRisksAnemia["value"][CONSTANTS.CS_Data][d][c][CONSTANTS.CS_OddsRatio] = pt.tableData.value[row][col];
                col++;
            }
            RelRisksAnemia["source"][d][CONSTANTS.CS_Mat_NotAnemic] = pt.Source[pt.SourceMap[row]];
            row++;
        }

        changedModvars.push(RelRisksAnemia);

        return (changedModvars);
    },
};

const Setup = () => {
    const numCols = 3;
    const numRows = 5;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let i = CONSTANTS.CS_Mat_NotAnemic; i <= CONSTANTS.CS_Mat_Anemic; i++){
        pt.tableData.value[0][col] = ImpactsAnemiaMatMortStr(i);
        pt.tableData.value[1][col] = RS('GB_stOddsRatio');
        for (let row = 2; row <= 4; row++) {
            pt.Alignments[row][col] = CONSTANTS.GB_Right;
            pt.RDec[row][col] = 3;
            pt.MaxAllowedVal[row][col] = 1000;
        }
        col++;
    }

    pt.tableData.value[2][0] = RS('GB_stAntepartumHem');
    pt.tableData.value[3][0] = RS('GB_stIntrapartumHem');
    pt.tableData.value[4][0] = RS('GB_stPostpartumHem');

    pt.numSources = 3;

    pt.SourceMap[2] = 1;
    pt.SourceMap[3] = 2;
    pt.SourceMap[4] = 3;

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let RelRisksAnemia = getModvarByTag(CSModvars, CONSTANTS.CS_TG_RelRisksAnemia_MV2)["value"][
        CONSTANTS.CS_Data];

    let RelRisksAnemiaSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_RelRisksAnemia_MV2)["source"];

    let row = 2;
    for (let d = CONSTANTS.CS_Mat_AntepartHemorr; d <= CONSTANTS.CS_Mat_PostpartHemorr; d++){
        let col = 1;
        for (let c = CONSTANTS.CS_Mat_NotAnemic; c <= CONSTANTS.CS_Mat_Anemic; c++){
            pt.tableData.value[row][col] = RelRisksAnemia[d][c][CONSTANTS.CS_OddsRatio];
            col++;
        }
        pt.Source[pt.SourceMap[row]] = RelRisksAnemiaSrc[d][CONSTANTS.CS_Mat_NotAnemic];
        row++;
    }

    return pt;
};

