import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import RS from "@common/strings/RS";
import LiSTImage from  '../../img/LiSTImg.png';

class TAppSplash extends React.Component {
    
    render() {
        return (
            <div>
                <div style={{margin: '0, auto', textAlign: 'center'}}>
                    <img style={{marginTop: '15px', height: '50px'}} src={LiSTImage} alt='' />
                    <p style={{textAlign: 'center', color: '#0c3a79', fontSize: '22px'}}>{RS('GB_stLoadingLiST')}</p>
                    <CircularProgress size={118} style={{marginTop: '10', marginBottom: '10', margin: '0 auto', display: 'block'}} />
                </div>
            </div>
        );
    }

}

export default TAppSplash;