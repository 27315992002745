import React from "react";
import Theme from '../../app/Theme';
import ErrorBoundaryEditor from "@common/components/ErrorBoundaryEditor";
import GbStdTableMiddle from "../../components/GB/GBStdTableAG/GbStdTableMiddle";

//==================================================================================================================
//
//                          ######## ########  #### ########  #######  ########   ######  
//                          ##       ##     ##  ##     ##    ##     ## ##     ## ##    ## 
//                          ##       ##     ##  ##     ##    ##     ## ##     ## ##       
//                          ######   ##     ##  ##     ##    ##     ## ########   ######  
//                          ##       ##     ##  ##     ##    ##     ## ##   ##         ## 
//                          ##       ##     ##  ##     ##    ##     ## ##    ##  ##    ## 
//                          ######## ########  ####    ##     #######  ##     ##  ######  
//
//==================================================================================================================

const CSEditor = (props) => {
    let { mstID, componentID, packTable, onChange, style, width, showTitle, tableRef, isResult, sourceEditable = true, paren = "" } = props;
    let component;

    if (!onChange) {
        onChange = () => {};
    }

    if (typeof width === 'undefined') {
        width = "100%";
    }

    if ((typeof packTable === 'undefined') || (packTable === null)) {
        component = <CSErrorEditor id={mstID} msg={"PackTable for mstID " + props.mstID + " does not exist!"} />; 
    }
    else {
        component = <GbStdTableMiddle
                        id                  = {componentID}
                        mstID               = {mstID}
                        bandColor           = {Theme.editor.bandColor}
                        packTable           = {packTable}
                        fixedRowColor       = {Theme.editor.fixedRowColor}
                        highlightColor      = {Theme.editor.highlightColor}
                        cellPadding         = {Theme.editor.cellPadding}
                        onPackTableChanged  = {onChange}
                        width               = {width}
                        style               = {style}
                        showTitle           = {showTitle}
                        wrapperClassNames   = {"LiSTOnlineTable"}
                        autosizeColumns     = {true}
                        rounded             = {true}
                        // onMultiply          = {onMultiply}
                        refToTable          = {tableRef}
                        sourceEditable      = {sourceEditable}
                        removedMenuNames={ 
                            (isResult) ? [
                                "Undo", 
                                "Redo", 
                                "Cut",
                                "Paste", 
                                "Duplicate across rows", 
                                "Duplicate down columns", 
                                "Interpolate", 
                                "Normalize",
                                "Multiply",
                                "Data source",
                                "Data source (row)",
                                "Data source (column)"
                            ] : packTable.removedMenuNames
                        }
                        isResult={isResult}
                    />
    }

    return (
        <ErrorBoundaryEditor>
            {paren.length > 0 ?
                <span
                    style={{
                        position:"absolute",
                        fontSize: 12,
                        marginTop: 3,
                        left: 175//packTable.Title.length * 8.5
                    }}
                >
                    {"(" + paren + ")"}
                </span>
            : null}
            {component}
        </ErrorBoundaryEditor>
    );
};

const CSErrorEditor = (props) => {
    let styles = {
        div : {
            width: 300, 
            height: 300, 
            padding: 20, 
            background: '#e8b7b7', 
            boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
        }
    };

    return (
        <div style={styles.div}>
            Something went wrong!  
            <br />
            {props.msg}
        </div>
    );
};

export {
    CSEditor
}