import React from 'react';
import PropTypes from 'prop-types';
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./TRangeSlider.css";

class TRangeSlider extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        for (let key in nextProps){
            if (nextProps.hasOwnProperty(key)) {
               if (nextProps[key] !== this.props[key]) {
                   if (key === 'style') {
                       if (JSON.stringify(nextProps[key]) !== JSON.stringify(this.props[key])) {
                           return true;
                       }
                    }
                    else {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    onChange = (value) => {
        if (
            (value.min < this.props.minValue) ||
            (value.max > this.props.maxValue)
        )
            return;

        this.props.onChange(value);
    };

    render() {
        let {
            draggableTrack,
            minValue,
            maxValue,
            minTextLabel,
            maxTextLabel,
            disabledMin,
            disabledMax,
            value,
            // onChange,
            onChangeComplete,
            style            
        } = this.props;

        const styles = {
            rangeSlider : {
                padding : '30px 20px',
                ...style
            }
        };    
        
        return (
            <div className="TRangeSlider" style={styles.rangeSlider}>
                <InputRange
                    draggableTrack   = {draggableTrack && !disabledMin && !disabledMax}
                    minValue         = {minValue}
                    maxValue         = {maxValue}
                    minTextLabel     = {minTextLabel}
                    maxTextLabel     = {maxTextLabel}
                    disabledMin      = {disabledMin}
                    disabledMax      = {disabledMax}
                    value            = {value}
                    onChange         = {this.onChange}
                    onChangeComplete = {onChangeComplete}
                />
            </div>
        );
    }
};

TRangeSlider.propTypes = {
    draggableTrack   : PropTypes.bool,
    minValue         : PropTypes.number,
    maxValue         : PropTypes.number,
    minTextLabel     : PropTypes.string,
    maxTextLabel     : PropTypes.string,
    disabledMin      : PropTypes.bool,
    disabledMax      : PropTypes.bool,
    value            : PropTypes.object,
    onChange         : PropTypes.func,
    onChangeComplete : PropTypes.func,
    style            : PropTypes.object,
};

TRangeSlider.defaultProps = {
    draggableTrack   : true,
    minValue         : 0,
    maxValue         : 100,
    minTextLabel     : 'Text min',
    maxTextLabel     : 'Text max',
    disabledMin      : false,
    disabledMax      : false,
    value            : {},
    onChange         : undefined,
    onChangeComplete : (value) => console.log('onChangeComplete : ' + value),
    style            : {},
};

export default TRangeSlider;
