import React from 'react';
import PropTypes from "prop-types";
import merge from 'deepmerge';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
// import { deepDiffMapper } from "utilities/GB/GBUtil";

window.Highcharts = Highcharts;
require('highcharts/modules/exporting')(Highcharts);

// import HighchartsExporting from "highcharts/modules/exporting";
// HighchartsExporting(Highcharts);

Highcharts.setOptions({
    lang:{
        thousandsSep : ","
    }
});

const AppBarHeight = 64;

const getDelphiColorToHex = (color) => {

    if (color >= 0) {
        let hexString = color.toString(16);
        hexString = hexString.padStart(6, 0);

        let R = hexString.slice(-2);
        let G = hexString.slice(-4, -2);
        let B = hexString.slice(-6, -4);

        return '#' + (R + G + B);
    }
    else {
        return undefined;
    }
};

const packChartToOptions = (packChart, stacked, legendReversed, chartType, id) => {

    let o;
        
    if (typeof packChart === 'undefined') {
        o = {
            title             : 'Chart Title',
            alignTitle        : 'center',
            subTitle          : '',
            alignSubTitle     : 'center',
            multiSubTitles    : [],
            showLegend        : true,
            legendLocation    : 1,
            legendAlign       : 'right',
            stacking          : false,
            xAxisLabel        : '',
            pointLabels       : ['Point 1', 'Point 2'],
            yAxisLabel        : '',
            manualMinY        : 0, 
            manualStackedMinY : 0, 
            manualMaxY        : null,
            manualStackedMaxY : null,
            RYAxisLabel       : '',
            manualMinRY       : 0,
            subsetLabels      : ['Subset1', 'Subset2', 'Subset3'],
            subsetColors      : [-1, -1, -1], //['#222222', '#888888', '#EEEEEE'],
            SubsetsToLegend   : [1, 1, 1], 
            chartData         : [[100, 200], [300, 400], [500, 600]],
            legendReversed    : false,
        };
    }
    else {
        o = {
            title             : packChart.title,
            alignTitle        : packChart.alignTitle,
            subTitle          : packChart.subTitle,
            alignSubTitle     : packChart.alignSubTitle,
            multiSubTitles    : packChart.multiSubTitles,    
            showLegend        : packChart.showLegend,        
            legendLocation    : packChart.legendLocation,
            legendAlign       : packChart.legendAlign,
            stacking          : stacked,
            xAxisLabel        : packChart.xAxisLabel,
            pointLabels       : packChart.pointLabels,       
            yAxisLabel        : packChart.yAxisLabel,
            manualMinY        : packChart.manualMinY,        
            manualStackedMinY : packChart.manualStackedMinY, 
            manualMaxY        : packChart.manualMaxY,        
            manualStackedMaxY : packChart.manualStackedMaxY, 
            RYAxisLabel       : packChart.RYAxisLabel,       
            manualMinRY       : packChart.manualMinRY,       
            subsetLabels      : packChart.subsetLabels,      
            subsetColors      : packChart.subsetColors,      
            SubsetsToLegend   : packChart.SubsetsToLegend,   
            chartData         : packChart.chartData,
            legendReversed    : legendReversed,
        };   
    }

    const getLegendAlign = (legendLocation) => {

        // eLegendLocation
        const gLegendTop    = 0;
        const gLegendBottom = 1;
        const gLegendLeft   = 2;
        const gLegendRight  = 3;

        switch(legendLocation) {
            case gLegendTop    : return 'top';
            case gLegendBottom : return 'bottom';
            case gLegendLeft   : return 'left';
            case gLegendRight  : return 'right';
            default            : return 'top';
        }
    };

    const getYAxisMax = (manualMaxY, manualStackedMax, stacking) => {
        if (stacking) {
            if (manualStackedMax > 0) {
                return manualStackedMax;
            }
            else {
                return undefined;
            }
        }
        else {
            if (manualMaxY > 0) {
                return manualMaxY;
            }
            else {
                return undefined;
            }
        }
    };

    const getSeries = (subsetLabels, subsetColors, subsetsToLegend, chartData) => {

        let series = chartData.map( (arr2, subset) => {
            return {
                name    : subsetLabels[subset],
                color   : getDelphiColorToHex(subsetColors[subset]),
                index   : subsetsToLegend[subset],
                visible : !(subsetsToLegend[subset] === -1),
                data    : chartData[subset],
                //yAxis: (RYAxisLabel === '') ? 0 : (subset === 0) ? 0 : 1
                // arr2.map((value, point) => {
                //     return {
                //         y: value
                //     }
                // })
            };
        });

        return series;
    };

    let options = {
        chart : {
            type: chartType, //getChartType(o.chartType),
            height: '900px',
            renderTo: id,
            animation: false
            //width:this.state.chartWidth
        },

        title : {
            text: o.title,
            align: o.alignTitle
        },

        subtitle: {
            text: o.subTitle,
            align: o.alignSubTitle
        },

        legend : {
            enabled: o.showLegend,
            verticalAlign: getLegendAlign(o.legendLocation),
            align: o.legendAlign,
            reversed: o.legendReversed
        },

        plotOptions : {
            column : {
                stacking : o.stacking
            },
            bar : {
                stacking : o.stacking
            },
            series: {
                cursor : 'pointer',
                marker : {
                    enabled: false
                },
                // animation: false,
            }
        },

        exporting: {
            enabled : false
        },

        tooltip : {
            enabled: true,
            shared: true,
            crosshairs: true,
            valueDecimals: packChart.rDec
        },

        credits : {
            enabled: false,
            text : '',
            href : '',
            style : {
                cursor: 'pointer'
            }
        },

        xAxis : {
            title : {text: o.xAxisLabel},
            categories : o.pointLabels
        },

        yAxis : [
            {
                title : {text: o.yAxisLabel},
                maxPadding: 0,
                min : 0,
                max : getYAxisMax(o.manualMaxY, o.manualStackedMaxY, o.stacking),
                alignTicks: false
            },
            {
                title : o.RYAxisLabel,
                maxPadding: 0,
                min : o.manualMinRY,
                opposite: true,
                alignTicks: false
            }
        ],

        series : getSeries(o.subsetLabels, o.subsetColors, o.SubsetsToLegend, o.chartData)
    };

    return options;
};

class GBStdChart extends React.PureComponent {

    static propTypes = {
        id             : PropTypes.string,
        packChart      : PropTypes.object,
        stretch        : PropTypes.bool,
        minHeight      : PropTypes.number,
        maxHeight      : PropTypes.number,
        chartType      : PropTypes.string,
        showLegend     : PropTypes.bool,
        options        : PropTypes.object,
        stacked        : PropTypes.string,
        legendReversed : PropTypes.bool,
        credits        : PropTypes.object,
        spaceReduction : PropTypes.number,
    };

    static defaultProps = {
        id             : 'Chart1',
        packChart      : undefined,
        stretch        : false,
        minHeight      : 300,
        maxHeight      : 300,
        chartType      : 'line',
        showLegend     : true,
        options        : {},
        stacked        : '',
        legendReversed : false,
        credits        : {
                            "enabled" : false,
                            "text"    : '',
                            "href"    : '',
                            "style"   : {
                                            "cursor" : 'pointer'
                                        }
                         },
        spaceReduction : 0,
    };

    chart = undefined;
    _isMounted = false;
    // oldOptions = {};

    componentDidMount() {
        // console.log('highchart mounted');
        window.addEventListener('minMax', this.onResize);
        this._isMounted = true; 
        this.chart = this.refs.chart.chart;
    }

    onChartCallback = (chart) => {
        // this.chart = chart;
        // debugger;
        // this.oldOptions = options;
    };

    onResize = () => {
        Highcharts.charts.forEach(chart => {
            if (typeof chart !== 'undefined') {
                chart.reflow();
            }
        });
    }
    
    getChartHeight = () => {

        // // 1) Stretch the chart to the very bottom?
        // if (this.props.stretch) {
        //     if (!(this.state.chart === null)) {
        //         let div = this.state.chart.renderTo;
        //         let space = window.innerHeight - div.offsetTop - this.props.spaceReduction;
        //         return space;
        //     }
        // }

        // 2) Or use a custom min/max height
        let height = window.innerHeight - AppBarHeight;
        let minHeight = this.props.minHeight;
        let maxHeight = this.props.maxHeight;

        if ((height > minHeight) && (height < maxHeight)) {
            return height;
        }
        else if (height < minHeight) {
            return minHeight;
        }
        else if (height > maxHeight) {
            return maxHeight;
        }
        else {
            return undefined;
        }
    };

    exportChart = () => {
        this.chart.exportChart();
    };

    render() {
        const { packChart, stacked, legendReversed, chartType, id } = this.props;

        let options = packChartToOptions(packChart, stacked, legendReversed, chartType, id);

        if (typeof this.props.options.series !== 'undefined') {
            options.series = [];
        }

        if (typeof this.props.options.yAxis !== 'undefined') {
            delete options.yAxis;
        }

        let mergedOptions = merge(options, this.props.options);

        mergedOptions.chart.height                = this.getChartHeight();
        mergedOptions.chart.type                  = this.props.chartType;
        mergedOptions.legend.enabled              = this.props.showLegend;
        mergedOptions.plotOptions.bar.stacking    = this.props.stacked;
        mergedOptions.plotOptions.column.stacking = this.props.stacked;
        mergedOptions.legend.reversed             = this.props.legendReversed;
        mergedOptions.credits                     = this.props.credits;

        let txt = JSON.parse(JSON.stringify(mergedOptions.credits.text));
        let txtArr = txt.split(" ");
        for (let i = 0; i < txtArr.length; i++) {
            if (i > 0 && i % 20 === 0) {
                txtArr[i] = txtArr[i] + "<br>";
            }
        }
        txt = txtArr.join(" ");
        mergedOptions.credits.text = txt;

        if (this.chart) {
            mergedOptions = {
                ...mergedOptions,
                plotOptions : {
                    series : {
                        animation: false
                    }
                }
            };
        }

        // let arr = deepDiffMapper.map(this.oldOptions, mergedOptions);
        // console.log(arr);
        // debugger;

        // For now, we need this because...updating the react component goes wonky if we dont.  TODO: FIX THIS
        mergedOptions = JSON.parse(JSON.stringify(mergedOptions));

        if (typeof this.props.options !== 'undefined') {
            if (typeof this.props.options.tooltip !== 'undefined') {
                if (typeof this.props.options.tooltip.formatter !== 'undefined') {
                    mergedOptions.tooltip.formatter = this.props.options.tooltip.formatter;
                }
            }
        }

        if (typeof this.props.options !== 'undefined') {
            if (typeof this.props.options.chart !== 'undefined') {
                if (typeof this.props.options.chart.events !== 'undefined') {
                    mergedOptions.chart.events = this.props.options.chart.events;
                }
            }
        }

        return (
            <HighchartsReact
                key={this.props.id}
                highcharts={Highcharts}
                options={mergedOptions}
                callback={this.onChartCallback}
                ref={"chart"}
            />
        );
        
    }

}

export default GBStdChart;