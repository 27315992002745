import React from 'react';
import PropTypes from "prop-types";
import TCheckBox from "./TCheckBox";
import FileDownload from "@material-ui/icons/GetApp";

class TDownloadBtn extends React.PureComponent {
    
    static propTypes = {
        onClick  : PropTypes.func,
        style    : PropTypes.object
    };

    static defaultProps = {
        onClick : () => {},
        style   : {}
    };

    render() {
        const styles = {
            downloadButton: {
                cursor: "pointer",
                color : "#9CC025",
                marginTop: 5
            },
            downloadButtonWrapper: {
                display: "inline-block"
            }
        };

        return (
            <TCheckBox
                icon={<FileDownload
                    style={styles.downloadButton}
                />}
                isIcon={true}
                value={false}
                style={{...styles.downloadButtonWrapper, ...this.props.style}}
                caption={""}
                onClick={() => this.props.onClick()}
            />
        )
    }
}

export default TDownloadBtn;