import * as React from 'react';
import PropTypes from 'prop-types';
import TDisconnectionWarning from '@common/components/TDisconnectionWarning';
import CONSTANTS from "utilities/CS/CSConst";
import CreateAPITask from "../../api/util/createAPITask";

class TCSDisconnectWarning extends React.Component {

    static propTypes = {
        show       : PropTypes.bool,
        TimeExpire : PropTypes.string,
        onAddTasks : PropTypes.func,
        language   : PropTypes.number
    };

    static defaultProps = {
        show       : true,
        TimeExpire : '0:00:00',
        onAddTasks : () => { console.log('TCSDisconnectWarning: onAddTasks'); },
        language   : CONSTANTS.GB_English
    };
    
    render() {
        return (
            <TDisconnectionWarning 
                show        = {this.props.show}
                TimeExpire  = {this.props.TimeExpire}
                language    = {this.props.language}
                onKeepAlive = {
                                () => {
                                    this.props.onAddTasks([
                                        CreateAPITask('keepAlive', {}, () => {
                                            this.props.onAddTasks([
                                                CreateAPITask('getStatus', {})
                                            ]);
                                        })
                                    ]);
                                }
                            }
                onLogoff    = {
                                () => {
                                    this.props.onAddTasks([
                                        CreateAPITask('logout', {})
                                    ]);
                                }
                            }
            />
        );
    }

}

export default TCSDisconnectWarning;