import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import TButton from "@common/components/TButton";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSInterventionsDrawer from "../../drawers/SelectInterventions/CSInterventionsDrawer";

class CSInterventionsBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onAddTasks                     : PropTypes.func,
        handleSelectedResults          : PropTypes.func,
        CSModvars                      : PropTypes.arrayOf(PropTypes.object),
        IVGroupInfo                    : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo                 : PropTypes.arrayOf(PropTypes.object),
        IVInfo                         : PropTypes.arrayOf(PropTypes.object),
        includeFamPlan                 : PropTypes.bool,
        captionStyle                   : PropTypes.object,
        description                    : PropTypes.string,
        intervDrawerOpen               : PropTypes.bool,
        displayChart                   : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayAdolescentCOD           : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        adolescentCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        isDeskTop                      : PropTypes.bool,
        showMOIntervSection            : PropTypes.bool,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        adolescentsSelected            : PropTypes.bool,
        selectAllIntervBool            : PropTypes.bool,
        topMOInterventions             : PropTypes.array,
        MOResultToDisplay              : PropTypes.number,
        isExpandMODrawerClosed         : PropTypes.bool,
        language                       : PropTypes.number,
        onOpenHelpDialog               : PropTypes.func
    };

    static defaultProps = {
        onStateChange                  : (value) => console.log('CSInterventionsBlueBox: onStateChange ' + JSON.stringify(value)),
        onPageChange                   : () => console.log('onPageChange'),
        onAddTasks                     : () => console.log('onAddTasks'),
        handleSelectedResults          : () => console.log('handleSelectedResults'),
        CSModvars                      : [],
        IVGroupInfo                    : [],
        IVSubGroupInfo                 : [],
        IVInfo                         : [],
        includeFamPlan                 : false,
        captionStyle                   : {},
        description                    : "Default description",
        intervDrawerOpen               : false,
        selectedCountry                : 'Afghanistan',
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayAdolescentCOD           : false,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        adolescentCausesOfDeathContent : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        isDeskTop                      : true,
        showMOIntervSection            : false,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        adolescentsSelected            : false,
        selectAllIntervBool            : false,
        topMOInterventions             : [],
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        isExpandMODrawerClosed         : true,
        language                       : CONSTANTS.GB_English,
        onOpenHelpDialog               : () => console.log("onOpenHelpDialog")
    };

    state = {
        openDrawer               : false,
        saveBtnDisabled          : true,
        interventionGroupList    : [],
        interventionSubGroupList : [],
        selectedIVSet            : [],
        pregnancyQualityOn       : false,
        childbirthQualityOn      : false,
        includeFamPlan           : false,
        showMOIntervSection      : false,
        maternalIntervSelected   : true,
        stillbirthIntervSelected : true,
        neonatalIntervSelected   : true,
        childIntervSelected      : true,
        adolescentsSelected      : false,
        selectAllIntervBool      : false,
    };

    componentDidMount() {
        this.setState({
            interventionGroupList    : this.props.IVGroupInfo,
            interventionSubGroupList : this.props.IVSubGroupInfo,
            selectedIVSet            : JSON.parse(JSON.stringify(this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV)).value)),
            pregnancyQualityOn       : Boolean(this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_ANCMode_MV)).value),
            childbirthQualityOn      : Boolean(this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_ChildbirthMode_MV)).value),
            includeFamPlan           : this.props.includeFamPlan,
            openDrawer               : this.props.intervDrawerOpen,
            showMOIntervSection      : JSON.parse(JSON.stringify(this.props.showMOIntervSection)),
            maternalIntervSelected   : JSON.parse(JSON.stringify(this.props.maternalIntervSelected)),
            stillbirthIntervSelected : JSON.parse(JSON.stringify(this.props.stillbirthIntervSelected)),
            neonatalIntervSelected   : JSON.parse(JSON.stringify(this.props.neonatalIntervSelected)),
            childIntervSelected      : JSON.parse(JSON.stringify(this.props.childIntervSelected)),
            adolescentsSelected      : JSON.parse(JSON.stringify(this.props.adolescentsSelected)),
            selectAllIntervBool      : JSON.parse(JSON.stringify(this.props.selectAllIntervBool))
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.openDrawer) {
            return {
                interventionGroupList    : props.IVGroupInfo,
                interventionSubGroupList : props.IVSubGroupInfo,
                selectedIVSet            : JSON.parse(JSON.stringify(props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV)).value)),
                maternalIntervSelected   : props.maternalIntervSelected,
                stillbirthIntervSelected : props.stillbirthIntervSelected,
                neonatalIntervSelected   : props.neonatalIntervSelected,
                childIntervSelected      : props.childIntervSelected,
                adolescentsSelected      : props.adolescentsSelected,
                selectAllIntervBool      : props.selectAllIntervBool
            }
        }
        else {
            return null;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true, 
        });

    };

    onCloseDrawerBtnClick = () => {
        this.setState({ 
            openDrawer               : false,
            saveBtnDisabled          : true,
            interventionGroupList    : this.props.IVGroupInfo,
            interventionSubGroupList : this.props.IVSubGroupInfo,
            selectedIVSet            : JSON.parse(JSON.stringify(this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV)).value)),
            pregnancyQualityOn       : Boolean(this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_ANCMode_MV)).value),
            childbirthQualityOn      : Boolean(this.props.CSModvars.find(x => (x.tag === CONSTANTS.CS_TG_ChildbirthMode_MV)).value),
            includeFamPlan           : this.props.includeFamPlan,
            showMOIntervSection      : JSON.parse(JSON.stringify(this.props.showMOIntervSection)),
            // maternalIntervSelected   : this.props.maternalIntervSelected,
            // stillbirthIntervSelected : this.props.stillbirthIntervSelected,
            // neonatalIntervSelected   : this.props.neonatalIntervSelected,
            // childIntervSelected      : this.props.childIntervSelected
        });
        this.props.onStateChange ({
            intervDrawerOpen         : false,
            maternalIntervSelected   : this.state.maternalIntervSelected,
            stillbirthIntervSelected : this.state.stillbirthIntervSelected,
            neonatalIntervSelected   : this.state.neonatalIntervSelected,
            childIntervSelected      : this.state.childIntervSelected,
            adolescentsSelected      : this.state.adolescentsSelected,
            selectAllIntervBool      : this.state.selectAllIntervBool
        });
        if (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) {
            this.props.onStateChange ({
                displayMaternalCOD   : this.state.maternalIntervSelected,
                displayStillbirthCOD : this.state.stillbirthIntervSelected,
                displayNeonatalCOD   : this.state.neonatalIntervSelected,
                displayChildCOD      : this.state.childIntervSelected
            })
        }
    };

    onSaveBtnClick = () => {
        // let AdolMode = false;
        let AgeGroupOption = CONSTANTS.FP_Single_Age_Group;

        if (this.props.adolescentsSelected){
            // AdolMode = true;
            AgeGroupOption = CONSTANTS.FP_Five_Year_Age_Groups;
        }

        this.props.onStateChange(
            {
                CSModvars: this.props.CSModvars.map(x =>
                    // (x.tag === CONSTANTS.CS_TG_AdolMode_MV) ? {...x, value: AdolMode} :
                    (x.tag === CONSTANTS.FP_TG_TAgeGroupOption_MV) ? {...x, value: AgeGroupOption} :
                    (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV) ? {...x, value: this.state.selectedIVSet} :
                    (x.tag === CONSTANTS.CS_TG_ANCMode_MV) ? {...x, value: Number(this.isQualityInterventionSelected())} :
                    (x.tag === CONSTANTS.CS_TG_ChildbirthMode_MV) ? {...x, value: Number(this.isQualityInterventionSelected())} :
                    (x.tag === CONSTANTS.CS_TG_FertilityRisksMatrixOn_MV2) ? {...x, value: Boolean(this.isDirectEntryOfFertilityRisksSelected())} :
                    (x.tag === CONSTANTS.CS_TG_EnterBFImproveData_MV2) ? {...x, value: Number(this.BFEntryMode())} :
                    (x.tag === CONSTANTS.CS_TG_StuntingMatrixOn_MV2) ? {...x, value: Boolean(this.isDirectEntryOfStuntingSelected())} :
                    (x.tag === CONSTANTS.CS_TG_WastingMatrixOn_MV2) ? {...x, value: Boolean(this.isDirectEntryOfWastingSelected())} :
                      x
                ),
                useFamPlanOutputs        : this.state.useFamPlanOutputs,
                showMOIntervSection      : this.state.showMOIntervSection,
                // maternalIntervSelected   : this.state.maternalIntervSelected,
                // stillbirthIntervSelected : this.state.stillbirthIntervSelected,
                // neonatalIntervSelected   : this.state.neonatalIntervSelected,
                // childIntervSelected      : this.state.childIntervSelected
            },
            () => {
                this.setState({
                    openDrawer      : false,
                    saveBtnDisabled : true,
                });

                this.props.onStateChange ({
                    intervDrawerOpen : false,
                    unchangedProj: false
                });
            }
        );
    };

    isQualityInterventionSelected = () => {
        let result = false;

        let selectedIVSet = this.state.selectedIVSet.intArray;

        for(let i = 0; i < this.props.IVInfo.length; i++) {
            if ((selectedIVSet && selectedIVSet.includes(this.props.IVInfo[i].MstID)) && (this.props.IVInfo[i].qualityInterv)) {
                result = true;
            }
        }

        return (result)
    };

    isDirectEntryOfFertilityRisksSelected = () => {
        let result = false;

        let selectedIVSet = this.state.selectedIVSet.intArray;

        if (selectedIVSet && selectedIVSet.includes(CONSTANTS.CS_MstDirectEntryFertilityRisks)) {
            result = CONSTANTS.CS_MstBFPromotionMode;
        }

        return (result)
    };

    BFEntryMode = () => {
        let result = CONSTANTS.CS_MstBFPrevMode;

        let selectedIVSet = this.state.selectedIVSet.intArray;

        if (selectedIVSet && selectedIVSet.includes(CONSTANTS.IV_MstChangesInBF_BFPromo)) {
            result = CONSTANTS.CS_MstBFPromotionMode;
        }

        return (result)
    };

    isDirectEntryOfStuntingSelected = () => {
        let result = false;

        let selectedIVSet = this.state.selectedIVSet.intArray;

        if (selectedIVSet && selectedIVSet.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfStunting)) {
            result = true;
        }

        return (result)
    };

    isDirectEntryOfWastingSelected = () => {
        let result = false;

        let selectedIVSet = this.state.selectedIVSet.intArray;

        if (selectedIVSet && selectedIVSet.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting)) {
            result = true;
        }

        return (result)
    };

    onInterventionListChange = (value) => {
        this.setState({
            selectedIVSet    : value,
            saveBtnDisabled  : false,
        });
    };

    onPregnancyQualityOnChange = (value) => {
        this.setState({
            pregnancyQualityOn : value,
            saveBtnDisabled    : false,
        });
    };

    onChildbirthQualityOnChange = (value) => {
        this.setState({
            childbirthQualityOn : value,
            saveBtnDisabled     : false,
        });
    };

    onIncludeFamPlanChange = (value) => {
        this.setState({
            includeFamPlan  : value,
            saveBtnDisabled : false,
        });
    };

    onVisualizerBtnClick = () => {
        window.open('https://listvisualizer.org/', "_blank");
    };

    updateSelectAllIntervBool = (value) => {
        this.props.onStateChange({selectAllIntervBool: value});
    };

    onSelectAllIntervCBClick = (value, flags) => {
        this.setState({
            saveBtnDisabled: false,
        });

        let selectedIVSet = {'intArray': []};

        if (value) {
            for (let i = 0; i < this.props.IVGroupInfo.length; i++) {
                let IVGroupInfo = this.props.IVGroupInfo[i];
                for (let j = 0; j < this.props.IVInfo.length; j++) {
                    let IVRec = this.props.IVInfo[j];
                    if ((IVRec.GroupMstID === IVGroupInfo.MstID) && this.getIntervShowing(flags, IVRec)) {
                        if (IVRec.MstID !== CONSTANTS.IV_MstContraceptiveUse) {
                            if ((!selectedIVSet.intArray.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF) ||
                                (IVRec.MstID !== CONSTANTS.IV_MstChangesInBF_BFPromo)) &&
                                (!selectedIVSet.intArray.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting) ||
                                    (![CONSTANTS.IV_MstTrMAM, CONSTANTS.IV_MstSAM].includes(IVRec.MstID)))) {
                                selectedIVSet.intArray.push(IVRec.MstID);
                            }
                        }
                    }
                }
            }
        }

        this.onInterventionListChange(selectedIVSet);
        this.updateSelectAllIntervBool(value);
    };

    onOutcomeSwitchChange = (checked, mstID) => {
        this.setState({
            saveBtnDisabled: false,
        });
        switch (mstID) {
            case CONSTANTS.CS_MstMaternalMortality : {
                this.props.onStateChange({maternalIntervSelected: checked});
                if (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) {
                    this.props.onStateChange({displayMaternalCOD: checked})
                }
                break;
            }
            case CONSTANTS.CS_MstStillbirths : {
                this.props.onStateChange({stillbirthIntervSelected: checked});
                if (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) {
                    this.props.onStateChange({displayStillbirthCOD: checked})
                }
                break;
            }
            case CONSTANTS.CS_MstNeonatalMortality : {
                this.props.onStateChange({neonatalIntervSelected: checked});
                if (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) {
                    this.props.onStateChange({displayNeonatalCOD: checked})
                }
                break;
            }
            case CONSTANTS.CS_MstChildMortality : {
                this.props.onStateChange({childIntervSelected: checked});
                if (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) {
                    this.props.onStateChange({displayChildCOD: checked})
                }
                break;
            }
            case CONSTANTS.CS_MstAdolescents : {
                this.props.onStateChange({adolescentsSelected: checked});
                if (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) {
                    this.props.onStateChange({displayAdolescentCOD: checked})
                }
                break;
            }
            case CONSTANTS.CS_MstNutrition : {
                this.props.onStateChange({nutritionSelected: checked});
                break;
            }
            case CONSTANTS.CS_MstBirthOutcomes : {
                this.props.onStateChange({birthOutcomeSelected: checked});
                break;
            }
            default : break;
        }
        if (checked) {
            this.checkInterventions(mstID);
        }
        if (!checked) {
            this.uncheckInterventions(mstID);
        }
        this.props.handleSelectedResults(checked, mstID);
    };

    checkInterventions = (category) => {
        //category check ternary operator allows certainty that it will register as off
        let flags = {
            maternalSelected       : category === CONSTANTS.CS_MstMaternalMortality ? true : this.props.maternalIntervSelected,
            stillbirthSelected     : category === CONSTANTS.CS_MstStillbirths       ? true : this.props.stillbirthIntervSelected,
            neonatalSelected       : category === CONSTANTS.CS_MstNeonatalMortality ? true : this.props.neonatalIntervSelected,
            childSelected          : category === CONSTANTS.CS_MstChildMortality    ? true : this.props.childIntervSelected,
            adolescentSelected     : category === CONSTANTS.CS_MstAdolescents       ? true : this.props.adolescentsSelected,
            nutritionSelected      : category === CONSTANTS.CS_MstNutrition         ? true : this.props.nutritionSelected,
            birthOutcomeSelected   : category === CONSTANTS.CS_MstBirthOutcomes     ? true : this.props.birthOutcomeSelected,
            nutritionIV            : category === CONSTANTS.CS_MstNutrition         ? true : this.props.nutritionSelected,
            birthOutcomeIV         : category === CONSTANTS.CS_MstBirthOutcomes     ? true : this.props.birthOutcomeSelected
        };

        if (this.props.selectAllIntervBool){
            this.onSelectAllIntervCBClick(true, flags);
        }
        else {
            //uncheck any interventions that are no longer included in the interventionLists since the category was switched off
            let selectedIVSet = JSON.parse(JSON.stringify(this.state.selectedIVSet));

            for (let i = 0; i < this.props.IVInfo.length; i++) {
                let IVRec = this.props.IVInfo[i];
                for (let j = 0; j < selectedIVSet.intArray.length; j++) {
                    if (IVRec.MstID === selectedIVSet.intArray[j]) {
                        let RequiredIntervs = IVRec['RequiredIntervs'].intArray;
                        if (RequiredIntervs.length > 0) {
                            for (let k = 0; k < RequiredIntervs.length; k++) {
                                for (let j = 0; j < this.props.IVInfo.length; j++) {
                                    let IVRec2 = this.props.IVInfo[j];
                                    if (IVRec2.MstID === RequiredIntervs[k]) {
                                        /* If master ID not already selected, and if it should be showing with the
                                           present outcome switch settings, add it. */
                                        if (!selectedIVSet.intArray.includes(IVRec2.MstID) && this.getIntervShowing(flags, IVRec2)) {
                                            selectedIVSet.intArray.push(IVRec2.MstID);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.onInterventionListChange(selectedIVSet);
        }
    };

    uncheckInterventions = (category) => {
        //category check ternary operator allows certainty that it will register as off
        let flags = {
            maternalSelected       : category === CONSTANTS.CS_MstMaternalMortality ? false : this.props.maternalIntervSelected,
            stillbirthSelected     : category === CONSTANTS.CS_MstStillbirths       ? false : this.props.stillbirthIntervSelected,
            neonatalSelected       : category === CONSTANTS.CS_MstNeonatalMortality ? false : this.props.neonatalIntervSelected,
            childSelected          : category === CONSTANTS.CS_MstChildMortality    ? false : this.props.childIntervSelected,
            adolescentSelected     : category === CONSTANTS.CS_MstAdolescents       ? false : this.props.adolescentsSelected,
            nutritionSelected      : category === CONSTANTS.CS_MstNutrition         ? false : this.props.nutritionSelected,
            birthOutcomeSelected   : category === CONSTANTS.CS_MstBirthOutcomes     ? false : this.props.birthOutcomeSelected,
            nutritionIV            : category === CONSTANTS.CS_MstNutrition         ? false : this.props.nutritionSelected,
            birthOutcomeIV         : category === CONSTANTS.CS_MstBirthOutcomes     ? false : this.props.birthOutcomeSelected
        };

        if (this.props.selectAllIntervBool){
            this.onSelectAllIntervCBClick(true, flags);
        }
        else {
            //uncheck any interventions that are no longer included in the interventionLists since the category was switched off
            let selectedIVSet = JSON.parse(JSON.stringify(this.state.selectedIVSet));

            //first find the available interventions...
            let IVList = [];

            for (let i = 0; i < this.props.IVInfo.length; i++) {
                if (this.getIntervShowing(flags, this.props.IVInfo[i])) {
                    IVList.push(this.props.IVInfo[i].MstID);
                }
            }

            //...then remove any chosen interventions that are no longer available
            for (let j = selectedIVSet.intArray.length - 1; j >= 0; j--) {
                if (!IVList.includes(selectedIVSet.intArray[j])) {
                    selectedIVSet.intArray.splice(j, 1);
                }
            }

            this.onInterventionListChange(selectedIVSet);
        }
    };

    //==================================================================================================================
    //
    //                                              Functions
    //
    //==================================================================================================================

    getIntervShowing = (flags, IVRec) => {
        return ((flags.nutritionIV && IVRec[CONSTANTS.CS_IsNutritionIV]) ||
                (flags.birthOutcomeIV && IVRec[CONSTANTS.CS_IsBirthOutcomeIV]) ||
                (flags.nutritionSelected && flags.birthOutcomeSelected)) &&

               ((flags.maternalSelected && IVRec[CONSTANTS.CS_MaternalEff_ED])  ||
                (flags.stillbirthSelected && IVRec[CONSTANTS.CS_StillbirthEff_ED]) ||
                (flags.neonatalSelected && IVRec[CONSTANTS.CS_NNEff_ED]) ||
                (flags.childSelected && IVRec[CONSTANTS.CS_PNNEff_ED]) ||
                (flags.adolescentSelected && IVRec[CONSTANTS.CS_AdolEff_ED]));
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2 
                caption      = {RS('GB_stInterventions')}
                captionStyle = {this.props.captionStyle}
                description  = {this.props.description}
                onClick      = {this.onBlueBoxClick}
                editContent  = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        onOpenHelpDialog= {this.props.onOpenHelpDialog}
                        helpFile        = {"interventions.htm"}
                        preventScroll   = {true}
                        appBarText      = {RS('GB_stInterventions')}
                        content         = {
                                            <CSInterventionsDrawer
                                                onStateChange                  = {this.props.onStateChange}
                                                onPageChange                   = {this.props.onPageChange}
                                                onInterventionListChange       = {this.onInterventionListChange}
                                                onPregnancyQualityOnChange     = {this.onPregnancyQualityOnChange}
                                                onChildbirthQualityOnChange    = {this.onChildbirthQualityOnChange}
                                                onIncludeFamPlanChange         = {this.onIncludeFamPlanChange}
                                                onOutcomeSwitchChange          = {this.onOutcomeSwitchChange}
                                                getIntervShowing               = {this.getIntervShowing}
                                                interventionGroupList          = {this.state.interventionGroupList}
                                                interventionSubGroupList       = {this.state.interventionSubGroupList}
                                                selectedIVSet                  = {this.state.selectedIVSet.intArray}
                                                IVInfo                         = {this.props.IVInfo}
                                                // pregnancyQualityOn   = {this.state.pregnancyQualityOn}
                                                // childbirthQualityOn   = {this.state.childbirthQualityOn}
                                                includeFamPlan                 = {this.state.includeFamPlan}
                                                displayChart                   = {this.props.displayChart}
                                                selectedCountry                = {this.props.selectedCountry}
                                                displayMaternalCOD             = {this.props.displayMaternalCOD}
                                                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                                                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                                                displayChildCOD                = {this.props.displayChildCOD}
                                                displayAdolescentCOD           = {this.props.displayAdolescentCOD}
                                                displayCPR                     = {this.props.displayCPR}
                                                deliveryPointsContent          = {this.props.deliveryPointsContent}
                                                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                                                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                                                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                                                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                                                adolescentCausesOfDeathContent = {this.props.adolescentCausesOfDeathContent}
                                                sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                                                sortByInterventionCost         = {this.props.sortByInterventionCost}
                                                viewInterventionCost           = {this.props.viewInterventionCost}
                                                MOCountryData                  = {this.props.MOCountryData}
                                                isDeskTop                      = {this.props.isDeskTop}
                                                showMOIntervSection            = {this.state.showMOIntervSection}
                                                maternalIntervSelected         = {this.props.maternalIntervSelected}
                                                stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                                                neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                                                childIntervSelected            = {this.props.childIntervSelected}
                                                nutritionSelected              = {this.props.nutritionSelected}
                                                birthOutcomeSelected           = {this.props.birthOutcomeSelected}
                                                adolescentsSelected            = {this.props.adolescentsSelected}
                                                selectAllIntervBool            = {this.props.selectAllIntervBool}
                                                topMOInterventions             = {this.props.topMOInterventions}
                                                MOResultToDisplay              = {this.props.MOResultToDisplay}
                                                isExpandMODrawerClosed         = {this.props.isExpandMODrawerClosed}
                                                language                       = {this.props.language}
                                                onOpenHelpDialog               = {this.props.onOpenHelpDialog}
                                                updateSelectAllIntervBool      = {this.updateSelectAllIntervBool}
                                                onSelectAllIntervCBClick       = {this.onSelectAllIntervCBClick}
                                                // maternalIntervSelected         = {this.state.maternalIntervSelected}
                                                // stillbirthIntervSelected       = {this.state.stillbirthIntervSelected}
                                                // neonatalIntervSelected         = {this.state.neonatalIntervSelected}
                                                // childIntervSelected            = {this.state.childIntervSelected}
                                            />
                                          }
                        showExtraBtn    = {true}
                        contentExtraBtn = {
                                                <TButton
                                                    caption={RS('GB_stGoToLiSTVisualizer')}
                                                    containerStyle={{marginTop: 3, float: 'right', marginRight: 15 }}
                                                    color={'secondary'}
                                                    onClick={this.onVisualizerBtnClick}
                                                    variant={'text'}
                                                />
                                          }
                    />
                }
            />
        );
    }

}

export default CSInterventionsBlueBox;