import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    lockPackTable,
    add_NoHistoricalData_MsgToPackTable,
    setSourceArrLength,
} from "utilities/GB/GBUtil";

import { FindIntervRec } from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const MaternalNutritionalDeficienciesEditor = {
    Create: (CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS("GB_stNutrDeficiencies");
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ChildHealth;
        return [packChart, packTable];
    },

    GetSSData: (pt, IVInfo, CSModvars) => {
        let changedModvars = [];

        let VitADefInPWMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_VitADefInPW_MV);
        let NutritionalDeficienciesMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_NutritionalDeficiencies_MV2);

        let FolicAcidDeficPrev = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FolicAcidDeficPrev_MV)["value"];
        let CongenitalDueToNTDsAF = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CongenitalDueToNTDsAF_MV)["value"];

        VitADefInPWMV["value"] = pt.tableData.value[1][1];
        NutritionalDeficienciesMV["value"][CONSTANTS.CS_ZincDefic] = pt.tableData.value[2][1];
        NutritionalDeficienciesMV["value"][CONSTANTS.CS_CalcDefic] = pt.tableData.value[3][1];
        NutritionalDeficienciesMV["value"][CONSTANTS.CS_FolDefic] = pt.tableData.value[4][1];
        NutritionalDeficienciesMV["value"][CONSTANTS.CS_IronDefic] = pt.tableData.value[5][1];

        /* Set calcium spplementation affected fraction */
        let VitADefic = VitADefInPWMV["value"] / 100;
        let ZincDefic = NutritionalDeficienciesMV["value"][CONSTANTS.CS_ZincDefic] / 100;
        let CalcDefic = NutritionalDeficienciesMV["value"][CONSTANTS.CS_CalcDefic] / 100;
        let FolDefic = NutritionalDeficienciesMV["value"][CONSTANTS.CS_FolDefic] / 100;
        let IronDefic = NutritionalDeficienciesMV["value"][CONSTANTS.CS_IronDefic] / 100;

        let FolicAcidSupp = FindIntervRec(IVInfo, CONSTANTS.IV_FolicAcidSupp);
        let ZincFort = FindIntervRec(IVInfo, CONSTANTS.IV_ZincFort);
        let CalciumSupp = FindIntervRec(IVInfo, CONSTANTS.IV_CalciumSupp);
        let ZincSuppInPreg = FindIntervRec(IVInfo, CONSTANTS.IV_ZincSuppInPreg);
        let NNVitASupp = FindIntervRec(IVInfo, CONSTANTS.IV_NNVitASupp);

        let MatEfficacyMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_MatEfficacy_MV3);
        for (let d = 1; d <= CONSTANTS.CS_Mat_MaxDeathCauses; d++) {
            if (FolicAcidSupp !== null) {
                MatEfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d] =
                    FolDefic;
                MatEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d] =
                    FolDefic;
            }

            if (ZincFort !== null) {
                MatEfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][ZincFort["CurrID"]][d] =
                    ZincDefic;
                MatEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][ZincFort["CurrID"]][d] = ZincDefic;
            }

            if (CalciumSupp !== null) {
                MatEfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CalciumSupp["CurrID"]][d] =
                    CalcDefic;
                MatEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CalciumSupp["CurrID"]][d] =
                    CalcDefic;
            }

            if (ZincSuppInPreg !== null) {
                MatEfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][ZincSuppInPreg["CurrID"]][d] =
                    ZincDefic;
                MatEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][ZincSuppInPreg["CurrID"]][d] =
                    ZincDefic;
            }
        }

        let SBEfficacyMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_SBEfficacy_MV3);
        for (let d = 1; d <= CONSTANTS.CS_SB_MaxCauses; d++) {
            if (FolicAcidSupp !== null) {
                SBEfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d] =
                    FolDefic * FolicAcidDeficPrev;
                SBEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d] =
                    FolDefic * FolicAcidDeficPrev;
            }

            if (CalciumSupp !== null) {
                SBEfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CalciumSupp["CurrID"]][d] =
                    CalcDefic;
                SBEfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CalciumSupp["CurrID"]][d] = CalcDefic;
            }
        }

        let EfficacyMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_Efficacy_MV2);
        for (let d = 1; d <= CONSTANTS.CS_MaxDeathCauses; d++) {
            for (let a = CONSTANTS.CS_AgeSummary; a <= CONSTANTS.CS_24t60months; a++) {
                if (FolicAcidSupp !== null) {
                    EfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d][a] =
                        FolDefic;
                    EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d][a] =
                        FolDefic;
                    if (d === CONSTANTS.CS_NNCongen) {
                        EfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d][
                            a
                        ] *= CongenitalDueToNTDsAF;
                        EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][FolicAcidSupp["CurrID"]][d][
                            a
                        ] *= CongenitalDueToNTDsAF;
                    }
                }

                if (NNVitASupp !== null) {
                    EfficacyMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][NNVitASupp["CurrID"]][d][a] =
                        VitADefic;
                    EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][NNVitASupp["CurrID"]][d][a] =
                        VitADefic;
                }
            }
        }

        let MaternalNutritionMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_IntervForBO_MV7);
        for (let term = CONSTANTS.CS_PreTermSGA; term <= CONSTANTS.CS_TermAGA; term++) {
            MaternalNutritionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_FolicAcidFort][term] =
                FolDefic;
            MaternalNutritionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CONSTANTS.CS_FolicAcidFort][
                term
            ] = FolDefic;

            MaternalNutritionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_ZincFort][term] =
                ZincDefic;
            MaternalNutritionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CONSTANTS.CS_ZincFort][term] =
                ZincDefic;

            MaternalNutritionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_CalciumSupp][term] =
                CalcDefic;
            MaternalNutritionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CONSTANTS.CS_CalciumSupp][
                term
            ] = CalcDefic;

            MaternalNutritionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_ZincSuppInPreg][
                term
            ] = ZincDefic;
            MaternalNutritionMV["value"][CONSTANTS.CS_Default][CONSTANTS.CS_AffecFract][CONSTANTS.CS_ZincSuppInPreg][
                term
            ] = ZincDefic;
        }

        let ImpactsOnMatAnemiaMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnMatAnemia_MV3);

        for (let n = CONSTANTS.CS_Preg; n <= CONSTANTS.CS_NonPreg; n++) {
            ImpactsOnMatAnemiaMV["value"][CONSTANTS.CS_Data][n][CONSTANTS.CS_IronFolatePW_MatAnemia][
                CONSTANTS.CS_AffecFract
            ] = IronDefic;
            ImpactsOnMatAnemiaMV["value"][CONSTANTS.CS_Default][n][CONSTANTS.CS_IronFolatePW_MatAnemia][
                CONSTANTS.CS_AffecFract
            ] = IronDefic;

            ImpactsOnMatAnemiaMV["value"][CONSTANTS.CS_Data][n][CONSTANTS.CS_IronFortNPW_MatAnemia][
                CONSTANTS.CS_AffecFract
            ] = IronDefic;
            ImpactsOnMatAnemiaMV["value"][CONSTANTS.CS_Default][n][CONSTANTS.CS_IronFortNPW_MatAnemia][
                CONSTANTS.CS_AffecFract
            ] = IronDefic;
        }

        VitADefInPWMV["source"][0] = pt.Source[pt.SourceMap[1]];
        NutritionalDeficienciesMV["source"][0][CONSTANTS.CS_ZincDefic] = pt.Source[pt.SourceMap[2]];
        NutritionalDeficienciesMV["source"][0][CONSTANTS.CS_CalcDefic] = pt.Source[pt.SourceMap[3]];
        NutritionalDeficienciesMV["source"][0][CONSTANTS.CS_FolDefic] = pt.Source[pt.SourceMap[4]];
        NutritionalDeficienciesMV["source"][0][CONSTANTS.CS_IronDefic] = pt.Source[pt.SourceMap[5]];

        changedModvars.push(VitADefInPWMV);
        changedModvars.push(NutritionalDeficienciesMV);
        changedModvars.push(MatEfficacyMV);
        changedModvars.push(SBEfficacyMV);
        changedModvars.push(EfficacyMV);
        changedModvars.push(MaternalNutritionMV);
        changedModvars.push(ImpactsOnMatAnemiaMV);

        return changedModvars;
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    let numCols = 2;
    pt.yearArr = [];
    let BaseYear;

    if (EditorStartYear > 0 && EditorEndYear > 0) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2; //+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    if (pt.yearArr.length > 0) {
        const numRows = 15;
        pt = resizePackTable(pt, numRows, numCols);
        pt.tableData.value[1][0] = RS("GB_stCalcDef");
    } else {
        const numRows = 6;
        pt = resizePackTable(pt, numRows, numCols);
        pt.tableData.value[1][0] = RS("GB_stPercVitADef");
        pt.tableData.value[2][0] = RS("GB_stPercZincDef");
        pt.tableData.value[3][0] = RS("GB_stCalcDef");
        pt.tableData.value[4][0] = RS("GB_stFolDef");
        pt.tableData.value[5][0] = RS("GB_stIronDef");
    }

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++) {
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        for (let r = 1; r < pt.GBRowCount; r++) {
            pt.Alignments[r][col] = CONSTANTS.GB_Right;
            pt.RDec[r][col] = 2;
        }
        col++;
    }

    for (let r = 1; r < pt.GBRowCount; r++) {
        pt.Alignments[r][1] = CONSTANTS.GB_Right;
        pt.RDec[r][1] = 2;
        pt = lockPackTable(pt, r, pt.yearArr.length > 0, false);
        pt.SourceMap[r] = r;
    }

    pt = setSourceArrLength(pt, 3);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let VitADefInPW = getModvarByTag(CSModvars, CONSTANTS.CS_TG_VitADefInPW_MV);
    let NutritionalDeficiencies = getModvarByTag(CSModvars, CONSTANTS.CS_TG_NutritionalDeficiencies_MV2);

    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            if (NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_CalcDefic][t] >= 0) {
                pt.tableData.value[1][col] = NutritionalDeficiencies["exploreLiST"][CONSTANTS.CS_CalcDefic][t];
            }
            col++;
        }
        pt.Source[pt.SourceMap[1]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_CalcDefic];
    } else {
        pt.tableData.value[1][1] = VitADefInPW["value"];
        pt.Source[pt.SourceMap[1]] = VitADefInPW["source"][0];

        pt.tableData.value[2][1] = NutritionalDeficiencies["value"][CONSTANTS.CS_ZincDefic];
        pt.Source[pt.SourceMap[2]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_ZincDefic];

        pt.tableData.value[3][1] = NutritionalDeficiencies["value"][CONSTANTS.CS_CalcDefic];
        pt.Source[pt.SourceMap[3]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_CalcDefic];

        pt.tableData.value[4][1] = NutritionalDeficiencies["value"][CONSTANTS.CS_FolDefic];
        pt.Source[pt.SourceMap[4]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_FolDefic];

        pt.tableData.value[5][1] = NutritionalDeficiencies["value"][CONSTANTS.CS_IronDefic];
        pt.Source[pt.SourceMap[5]] = NutritionalDeficiencies["source"][0][CONSTANTS.CS_IronDefic];
    }

    return pt;
};
