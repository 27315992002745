import React from "react";
import TCheckBox from '@common/components/TCheckBox';

class OptionCheckBox extends React.PureComponent {

    render() {
        const { caption, value, customProp, onChange } = this.props;

        const styles = {
            checkBoxLabelStyle: {
                color: '#05225d',
                fontSize: '13px',
                lineHeight: '1'
            },
            checkBoxStyle: {
                height: '37px'
            },
        };
    
        return (
            <TCheckBox
                caption={caption}
                value={value}
                customProp={customProp}
                onClick={onChange}
                color={'primary'}
                name={''}
                labelStyle={styles.checkBoxLabelStyle}
                style={styles.checkBoxStyle}
            />
        );
    }
};

export default OptionCheckBox;