import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import DemographyDrawer from "../../drawers/HealthStatus/DemographyDrawer";
import {TFREditor} from "../../data/HealthStatus/Demography/TFREditor";
import {FirstYearPopEditor} from "../../data/HealthStatus/Demography/FirstYearPopEditor";

class DemographyBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange  : PropTypes.func,
        CSModvars      : PropTypes.arrayOf(PropTypes.object),
        firstYear      : PropTypes.number,
    };

    static defaultProps = {
        onStateChange  : () => console.log('onStateChange'),
        CSModvars      : [],
        firstYear      : -1,
    };

    state = {
        saveBtnDisabled : true,
        openDrawer      : false,
        packTable1      : null,
        packTable2      : null,
    };

    componentDidMount() {
        this.setState({
            packTable1 : FirstYearPopEditor.Create(this.props.CSModvars, this.props.firstYear),
            packTable2 : TFREditor.Create(this.props.CSModvars, this.props.firstYear),
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.openDrawer) {
            return {
                packTable1 : FirstYearPopEditor.Create(props.CSModvars, props.firstYear),
                packTable2 : TFREditor.Create(props.CSModvars, props.firstYear),
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true, 
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({ 
            openDrawer      : false,
            saveBtnDisabled : true,
            packTable1      : FirstYearPopEditor.Create(this.props.CSModvars, this.props.firstYear),
            packTable2      : TFREditor.Create(this.props.CSModvars, this.props.firstYear),
        });
    };

    onSaveBtnClick = () => {
        let ModvarsArray1 = FirstYearPopEditor.GetSSData(this.state.packTable1, this.props.CSModvars);
        let ModvarsArray2 = TFREditor.GetSSData(this.state.packTable2, this.props.CSModvars);

        let changedCSModvars = this.props.CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            return a || b || x;
        });

        this.setState({
            openDrawer      : false,
            saveBtnDisabled : true,
        });

        this.props.onStateChange({
            unchangedProj: false,
            CSModvars : changedCSModvars,
        });
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            captionStyle : {
                height: '110px',
                width: '198px',
                display: 'table-cell',
                verticalAlign: 'middle',
                margin: '0 auto',
                textAlign: 'center',
            }
        };

        return (
            <CSBlueBox2 
                caption      = {RS('GB_stDemography')}
                captionStyle = {styles.captionStyle}
                description  = ""
                onClick      = {this.onBlueBoxClick}
                editContent  = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog= {this.props.onOpenHelpDialog}
                        helpFile        = {"demography.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stDemography')}
                        content         = {
                                            <DemographyDrawer
                                                packTable1={this.state.packTable1}
                                                packTable2={this.state.packTable2}
                                                onPackTable1Change={this.onPackTable1Change}
                                                onPackTable2Change={this.onPackTable2Change}
                                            />
                                        }
                    />
                }
            />
        );
    }

}

export default DemographyBlueBox;