import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    getIdxFromYear,
    getYearFromIdx,
    generatePackChart,
    lockPackTable,
    lockPackRowCol,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const PerCapitaIncEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, exploreData = false) => {
        let editorName = RS('GB_stPovFoodSecurity');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, exploreData);
        packTable = SetSSData(packTable, CSModvars);
        if (exploreData) {
            packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        }
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_HouseholdStatus;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];
        let FoodInsecureMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FoodInsecure_MV);
        let PerCapitaIncMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PerCapitaInc_MV);
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            FoodInsecureMV["value"][t] = pt.tableData.value[1][col];
            PerCapitaIncMV["value"][t] = pt.tableData.value[2][col];
            col++;
        }

        FoodInsecureMV["source"][0][0] = pt.Source[pt.SourceMap[1]];
        PerCapitaIncMV["source"][0][0] = pt.Source[pt.SourceMap[2]];

        changedModvars.push(FoodInsecureMV);
        changedModvars.push(PerCapitaIncMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, exploreData) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt.yearArr = [];
    let numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
    for(let t = StartIndex; t <= EndIndex; t++){
        pt.yearArr.push(t);
    }

    const numRows = 3;

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.RDec[1][col] = 3;
        col++;
    }

    pt = lockPackTable(pt, 1, exploreData, false);

    pt.tableData.value[1][0] = RS('GB_stFoodInsecure');
    pt.tableData.value[2][0] = RS('GB_stPerPopBelow1');

    pt.SourceMap[1] = 1;
    pt.SourceMap[2] = 2;

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let FoodInsecure = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FoodInsecure_MV);
    let PerCapitaInc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_PerCapitaInc_MV);
    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        if(FoodInsecure["value"][t] >= 0) {
            pt.tableData.value[1][col] = FoodInsecure["value"][t];
        }
        else{
            pt = lockPackRowCol(pt, 1, col, true);
        }
        if(PerCapitaInc["value"][t] >= 0) {
            pt.tableData.value[2][col] = PerCapitaInc["value"][t];
        }
        col++;
    }
    pt.Source[pt.SourceMap[1]] = FoodInsecure["source"][0][0];
    pt.Source[pt.SourceMap[2]] = PerCapitaInc["source"][0][0];
    return pt;
};

