import React from 'react';
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import CONSTANTS from "utilities/CS/CSConst";
import {DirectEntryMstIDSet} from "utilities/CS/CSDataUtil";
import TExpansionPanel from "@common/components/TExpansionPanel";
import TCheckBox from "@common/components/TCheckBox";
import TSwitch from "@common/components/TSwitch";
import TLabel from "@common/components/TLabel";
import TEdit from '@common/components/TEdit';
import RS from "@common/strings/RS";
import InterventionOptions from "./InterventionOptions"
import {getCheckBoxLabelStyle, getCheckBoxStyle, getBoxStyle} from "../../data/CSUtil";

class ExpansionPanelDataComponent extends React.Component {
    
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            (JSON.stringify(this.props) !== JSON.stringify(nextProps)) ||
            (JSON.stringify(this.state) !== JSON.stringify(nextState)) 
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {

        const { data } = this.props;

        let components = data.map(x => {
            if (x.subGroup) {

                let styles = {
                    nameStyle : {
                        fontSize: '16px',
                        color: '#3B78BC',
                        marginBottom: '8px'
                    }
                };
        
                return (
                    <div key={'n' + x.subGroupMstID} style={styles.nameStyle}>{x.subGroupName}</div>
                );
            }
            else if (x.switch) {
                return (
                    <TSwitch
                        style={x.style}
                        caption={x.name}
                        labelStyle={x.labelStyle}
                        key={x.key}
                        value={x.value}
                        color={x.color}
                        name={''}
                        customProp={x.customProp}
                        customProp2={x.customProp2}
                        onClick={x.onClick}
                    />
                );
            }
            else if (x.checkBox) {
                return (
                    <TCheckBox
                        caption={x.name}
                        labelStyle={x.labelStyle}
                        style={x.style}
                        boxStyle={x.boxStyle}
                        key={x.key}
                        color={x.color}
                        name={''}
                        customProp={x.customProp}
                        customProp2={x.customProp2}
                        value={x.value}
                        onClick={x.onClick}
                        enabled={x.enabled}
                    />
                );
            }
            else if (x.divider) {
                return (
                    <Divider
                        key={x.key} 
                        style={x.style}
                    />
                );
            }

            return undefined;
        }).filter(x => x);

        return components;
    }
}

const ExpansionPanelDataArray = (props) => {

    const { data } = props;
        
    let content = data.map((arr2, i) => {
        let component = <ExpansionPanelDataComponent data={arr2} key={i} />;
        return component;
    });

    return content;
};

class InterventionCheckBoxes extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        interventionGroupNames     : PropTypes.arrayOf(PropTypes.array),
        interventionSubGroupNames  : PropTypes.arrayOf(PropTypes.object),
        selectedIVSet              : PropTypes.array, //PropTypes.arrayOf(PropTypes.object),
        pregnancyQualityOn         : PropTypes.bool,
        childbirthQualityOn        : PropTypes.bool,
        checkBoxInterventionLists  : PropTypes.arrayOf(PropTypes.array),
        onCheckBoxChange           : PropTypes.func,
        onQualitySwitchChange      : PropTypes.func,
        onMoreInfoIconClick        : PropTypes.func,
        maternalIntervSelected     : PropTypes.bool,
        stillbirthIntervSelected   : PropTypes.bool,
        neonatalIntervSelected     : PropTypes.bool,
        childIntervSelected        : PropTypes.bool,
        nutritionSelected          : PropTypes.bool,
        birthOutcomeSelected       : PropTypes.bool,
        adolescentsSelected        : PropTypes.bool,
        onOutcomeSwitchChange      : PropTypes.func,
        MOResultToDisplay          : PropTypes.number,
        language                   : PropTypes.number,
        onStateChange              : PropTypes.func,
        IVInfo                     : PropTypes.array,
        selectAllIntervBool        : PropTypes.bool,
        onSelectAllIntervCBClick   : PropTypes.func,

    };

    static defaultProps = {
        interventionGroupNames     : [],
        interventionSubGroupNames  : [],
        selectedIVSet              : [],
        pregnancyQualityOn         : false,
        childbirthQualityOn        : false,
        checkBoxInterventionLists  : [],
        onCheckBoxChange           : () => {},
        onQualitySwitchChange      : () => {},
        onMoreInfoIconClick        : () => {},
        maternalIntervSelected     : true,
        stillbirthIntervSelected   : true,
        neonatalIntervSelected     : true,
        childIntervSelected        : true,
        nutritionSelected          : true,
        birthOutcomeSelected       : true,
        adolescentsSelected        : false,
        onOutcomeSwitchChange      : () => {},
        MOResultToDisplay          : CONSTANTS.CS_PotentialDeathsAverted,
        language                   : CONSTANTS.GB_English,
        onStateChange              : () => {},
        IVInfo                     : [],
        selectAllIntervBool        : false,
        onSelectAllIntervCBClick   : () => console.log('onSelectAllIntervCBClick')

    };

    state = {
        filterText                 : "",
        // coor                       : 0
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (((JSON.stringify(this.props) !== JSON.stringify(nextProps))) || ((JSON.stringify(this.state) !== JSON.stringify(nextState)))) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Function
    //
    //==================================================================================================================

    onSelectAllIntervCBClick = (value) => {
        let flags = {
            maternalSelected       : this.props.maternalIntervSelected,
            stillbirthSelected     : this.props.stillbirthIntervSelected,
            neonatalSelected       : this.props.neonatalIntervSelected,
            childSelected          : this.props.childIntervSelected,
            adolescentSelected     : this.props.adolescentsSelected,
            nutritionSelected      : this.props.nutritionSelected,
            birthOutcomeSelected   : this.props.birthOutcomeSelected,
            nutritionIV            : this.props.nutritionSelected && !this.props.birthOutcomeSelected,
            birthOutcomeIV         : this.props.birthOutcomeSelected && !this.props.nutritionSelected,
        };

        this.props.onSelectAllIntervCBClick(value, flags);
    };

    checkIfInterventionIsOn = (mstID) => {
        return this.props.selectedIVSet.includes(mstID);
    };

    getCheckBoxEnabled = (mstID) => {
        let result = ![CONSTANTS.IV_MstChangesInBF_BFPromo, CONSTANTS.CS_MstEarlyInitBF].includes(mstID);
        if (!result) {
            switch (mstID){
                case CONSTANTS.IV_MstChangesInBF_BFPromo : {
                    let DirectEntryOfBFSelected = this.props.selectedIVSet.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF);
                    result = !DirectEntryOfBFSelected || (DirectEntryOfBFSelected && (mstID !== CONSTANTS.IV_MstChangesInBF_BFPromo));
                    break;
                }
                case CONSTANTS.CS_MstEarlyInitBF : {
                    let BFPromoSelected = this.props.selectedIVSet.includes(CONSTANTS.IV_MstChangesInBF_BFPromo);
                    result = !BFPromoSelected || (BFPromoSelected && (mstID !== CONSTANTS.CS_MstEarlyInitBF));
                    break;
                }
                default : {break;}
            }
        }
        return result;
    };

    getColorStyle = (mstID) => {
        let result = '';

        if(DirectEntryMstIDSet().includes(mstID)){
            result = 'secondary';
        } else
            result = 'primary';

        return result;
    };

    getSubGroupName = (subGroupMstID) => {
        let name = '';

        for (let i = 0; i < this.props.interventionSubGroupNames.length; i++) {
            if (this.props.interventionSubGroupNames[i].MstID === subGroupMstID) {
                name = this.props.interventionSubGroupNames[i].Name;
            }
        }

        return name;
    };

    getSubGroupNameStyled = (name, subGroupMstID) => {
        let styles = {
            nameStyle : {
                fontSize: '16px',
                color: '#3B78BC',
                marginBottom: '8px'
            }
        };

        return (<div key={'n' + subGroupMstID} style={styles.nameStyle}>{name}</div>)

    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderData = (data) => {
        
        let content = data.map(arr2 => {
            let components = arr2.map(x => {
                if (x.subGroup) {
                    return (
                        this.getSubGroupNameStyled(x.subGroupName, x.subGroupMstID)
                    );
                }
                else if (x.switch) {
                    return (
                        <TSwitch
                            style={x.style}
                            caption={x.name}
                            labelStyle={x.labelStyle}
                            key={x.key}
                            value={x.value}
                            color={x.color}
                            name={''}
                            customProp={x.customProp}
                            customProp2={x.customProp2}
                            onClick={x.onClick}
                        />
                    );
                }
                else if (x.checkBox) {
                    return (
                        <TCheckBox
                            caption={x.name}
                            labelStyle={x.labelStyle}
                            style={x.style}
                            boxStyle={x.boxStyle}
                            key={x.key}
                            color={x.color}
                            name={''}
                            customProp={x.customProp}
                            customProp2={x.customProp2}
                            value={x.value}
                            onClick={x.onClick}
                            enabled={x.enabled}
                        />
                    );
                }
                else if (x.divider) {
                    return (
                        <Divider
                            key={x.key} 
                            style={x.style}
                        />
                    );
                }

                return undefined;
            });

            return components;
        }).filter(x => x);

        return content;
    };

    renderInterventionInfo = () => {

        let interventionData = [];
        let interventionGroupNames = [];

        for (let grp = 0; grp < this.props.checkBoxInterventionLists.length; grp++) {

            const data = this.renderInterventionCBsByGroup(grp);

            if (data !== null) {
                interventionData.push(data);
                interventionGroupNames.push(this.props.interventionGroupNames[grp]);
            }
        }

        return {
            data : interventionData,
            groupNames : interventionGroupNames
        };
    };

    renderInterventionCBsByGroup = (grp) => {
        const CS_name = 0;
        const CS_mstID = 1;
        const CS_groupMstID = 2;
        const CS_subGroupMstID = 4;

        const { checkBoxInterventionLists, selectedIVSet } = this.props;
        const { filterText } = this.state;

        const data = [];
     
        const styles = {
            switchStyle : {
                color : 'secondary',
                paddingBottom : '20px',
                marginBottom : '20px'
            },
            dividerStyle: {
                width: '388px',
                marginLeft: '-24px',
                marginTop: '10px',
                marginBottom: '10px'
            },
        };

        let activeSubGroupMstIDs = [];
        for (let i = 0; i < checkBoxInterventionLists[grp].length; i++) {
            const subGroupMstID = checkBoxInterventionLists[grp][i][CS_subGroupMstID];
            
            if ((activeSubGroupMstIDs.indexOf(subGroupMstID) === -1) &&
                (subGroupMstID !== CONSTANTS.CS_MstCurativeSubGroupHIV) &&
                (subGroupMstID!== CONSTANTS.CS_MstPregnancySubGroupHIV)
            ) {
                activeSubGroupMstIDs.push(subGroupMstID);
            }
        }

        for (let j = 0; j < activeSubGroupMstIDs.length; j++) {

            let activeSubGroupAdded = false;
            let hasSubGroup = activeSubGroupMstIDs[j] !== -1;

            for (let i = 0; i < checkBoxInterventionLists[grp].length; i++) {

                const mstID              = checkBoxInterventionLists[grp][i][CS_mstID];
                const subGroupMstID      = checkBoxInterventionLists[grp][i][CS_subGroupMstID];
                const groupMstID         = checkBoxInterventionLists[grp][i][CS_groupMstID];
                const name               = checkBoxInterventionLists[grp][i][CS_name] || "Name is missing";
                const unknown            = checkBoxInterventionLists[grp];

                const filterOK           = name.toLowerCase().includes(filterText.toLowerCase());
                const subGroupNameMstID  = activeSubGroupMstIDs[j];
                const subGroupName       = this.getSubGroupName(subGroupNameMstID);

                if (filterOK) {
                    if (mstID !== CONSTANTS.IV_MstContraceptiveUse) {
                        if (subGroupMstID === activeSubGroupMstIDs[j]) {
                            let DirectEntryOfWasting =
                                selectedIVSet.includes(CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting);
                            if (
                                (!DirectEntryOfWasting) ||
                                (DirectEntryOfWasting &&
                                ((mstID !== CONSTANTS.IV_MstTrMAM) &&
                                (mstID !== CONSTANTS.IV_MstSAM)))
                            ) {
                                if (hasSubGroup) {
                                    if (!activeSubGroupAdded) {
                                        // Subgroup
                                        data.push({
                                            subGroup : true,
                                            subGroupName : subGroupName,
                                            subGroupMstID : subGroupMstID,
                                        });
                                        activeSubGroupAdded = true;
                                    }
                                }

                                let IVInfo = this.props.IVInfo;

                                const enabled            = this.getCheckBoxEnabled(mstID);
                                const value              = this.checkIfInterventionIsOn(mstID);
                                const color              = this.getColorStyle(mstID);
                                const labelStyle         = getCheckBoxLabelStyle(IVInfo, mstID);
                                const boxStyle           = getBoxStyle(IVInfo, mstID, 'gray');
                                const checkBoxStyle      = getCheckBoxStyle(IVInfo, mstID);
                                const checkBoxLabelStyle = getCheckBoxLabelStyle(IVInfo, mstID);
                                const key                = unknown + mstID;
                
                                const onClick = (checked, event, name, caption, mstID, groupMstID) => {
                                    this.props.onCheckBoxChange(checked, groupMstID, mstID, caption)
                                };

                                if (DirectEntryMstIDSet().includes(mstID)) {
                                    // Switch
                                    data.push({
                                        switch : true,
                                        style : styles.switchStyle,
                                        name :  name,
                                        labelStyle : labelStyle,
                                        key : key,
                                        value : value,
                                        color : 'secondary',
                                        customProp : mstID,
                                        customProp2 : groupMstID,
                                        onClick : onClick
                                    });
                                } 
                                else {
                                    // Checkbox
                                    data.push({
                                        checkBox : true,
                                        name :  name,
                                        labelStyle : checkBoxLabelStyle,
                                        style : checkBoxStyle, 
                                        boxStyle : boxStyle, 
                                        key : key,
                                        color : color, 
                                        value : value,
                                        customProp : mstID,
                                        customProp2 : groupMstID,
                                        onClick : onClick,
                                        enabled : enabled
                                    });
                                }
                            }
                        }
                    }
                }
            }

            // Divider
            if (hasSubGroup && (j < activeSubGroupMstIDs.length - 1) && activeSubGroupAdded) {
                data.push({
                    divider : true,
                    key :  'd' + j,
                    style : styles.dividerStyle
                });
            }
        }

        // Remove final divider line if it exists
        let len = data.length;
        if (len > 0) {
            if (data[len-1].divider) {
                data.pop();
            }
        }
    
        if (data.length > 0) {
            return data;
        }
        else {
            return null;
        }
    };

    render() {

        const styles = {
            checkBox: {
                marginTop: 10,
                marginBottom: 20
            },
            checkBoxLabelStyle: {
                fontSize: '16px',
                // color: '#609cd9'
            },
            divStyle : {
                marginRight: "20px",
                width: '388px',
            },
            filterStyle : {
                width: '388px',
                marginRight: 0
            },
            paragraph1 : {
                marginTop : 0,
                marginBottom : '20px',
                userSelect: 'none',
            },
            labelStyle : {
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#000000',
                userSelect: 'none',
                marginBottom: '10px'
            },
            expansionPanel : {
                marginTop: 0,
                marginBottom: 0
            },
            expansionPanelSummary : {
                backgroundColor: '#0c3a79',
                maxHeight: '45px',
                minHeight: 0,
                width: '390px',
                marginLeft: '-1px'
            },
            header : {
                fontSize: '22px',
                color: '#FFFFFF'
            },
            expandMoreIcon : {
                color: '#FFFFFF'
            },
            expansionPanelDetails : {
                display: 'block',
                backgroundColor: '#ebebeb',
                fontSize: '25px',
                paddingLeft: '24px',
                paddingTop: '5px',
                paddingBottom: '15px'
            },
            chip : {
                visibility: 'hidden'
            },
            switchStyle : {

                // marginLeft: '125px',

            },
            saveColor : {
                color: 'primary'
            }
        };

        const interventionInfo = this.renderInterventionInfo();
        // const content = this.renderData(IVInfo.data);
        // const data = <ExpansionPanelData data={IVInfo.data} />;
        const data = ExpansionPanelDataArray({data : interventionInfo.data});

        return (
            <div style={styles.divStyle}>

                <p style={styles.paragraph1}>{RS('GB_stChooseInterventions')}</p>

                <InterventionOptions
                    maternalIntervSelected   = {this.props.maternalIntervSelected}
                    stillbirthIntervSelected = {this.props.stillbirthIntervSelected}
                    neonatalIntervSelected   = {this.props.neonatalIntervSelected}
                    childIntervSelected      = {this.props.childIntervSelected}
                    nutritionSelected        = {this.props.nutritionSelected}
                    birthOutcomeSelected     = {this.props.birthOutcomeSelected}
                    adolescentsSelected      = {this.props.adolescentsSelected}
                    onOutcomeSwitchChange    = {this.props.onOutcomeSwitchChange}
                    MOResultToDisplay        = {this.props.MOResultToDisplay}
                    onStateChange            = {this.props.onStateChange}
                />

                <TLabel
                    caption = {RS('GB_stSelectIV')}
                    style   = {styles.labelStyle}
                />

                <TEdit
                    caption={RS('GB_stSearchByIntervName')}
                    value={this.state.filterText}
                    onChange={(value) => { this.setState({ filterText: value }); }}
                    style={styles.filterStyle}
                    shrinkLabel={true}
                />

                <TCheckBox
                    style={styles.checkBox}
                    color='secondary'
                    caption={RS('GB_stSelAllInterv')}
                    labelStyle={styles.checkBoxLabelStyle}
                    onClick={this.onSelectAllIntervCBClick}
                    value={this.props.selectAllIntervBool}
                />

                <TExpansionPanel
                    onQualitySwitchChange={this.props.onQualitySwitchChange}
                    onMoreInfoIconClick={this.props.onMoreInfoIconClick}
                    items={interventionInfo.groupNames}
                    content={data}
                    defaultExpanded={true}
                    expansionPanelStyle={styles.expansionPanel}
                    expansionPanelSummaryStyle={styles.expansionPanelSummary}
                    expandMoreIconStyle={styles.expandMoreIcon}
                    expansionPanelDetailsStyle={styles.expansionPanelDetails}
                    headerStyle={styles.header}
                    switchStyle={styles.switchStyle}
                />
            </div>
        );
    }

}

export default InterventionCheckBoxes;
