import * as React from 'react';
import LiSTIcon from '../../img/LiSTIcon.png';
import LiSTLogo from '../../img/LiSTLogo.png';

let styles = {
    logo : {
        marginTop: '10px',
        height: 50
        //width: '80%', //'95%'
    }
};

class CSLogo extends React.PureComponent {
    render() {
        return (
            <img style={styles.logo} src={this.props.isDeskTop? LiSTIcon : LiSTLogo} alt={""} />
        );
    }
}

export default CSLogo;