import React from 'react';
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DotsMenu from "@material-ui/icons/MoreVert";
import SaveIcon from '@material-ui/icons/Save';
import TAvatarDropdown from '@common/components/TAvatarDropdown';
import TButton from '@common/components/TButton';
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
// import Divider from '@material-ui/core/Divider';
import CONSTANTS from "utilities/CS/CSConst";
import { SessionStorageUtil } from "../../api/localstorage/util";
import CSProjectionInfoBtn from "../CS/CSProjectionInfoBtn";
import TestPackTables from "../../editors/CS/data/TestPackTables";
import CSAppBarMenu from "../CS/CSAppBarMenu";
import SourcesIcon from '../../img/sourcesIcon.png';

class AppBarCustom extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        pageID                      : PropTypes.string,
        isGuest                     : PropTypes.bool,
        humanName                   : PropTypes.string,
        signedIn                    : PropTypes.bool,
        projectCreated              : PropTypes.bool,
        isDeskTop                   : PropTypes.bool,
        projectionName              : PropTypes.string,
        appMode                     : PropTypes.string,
        projectionCountry           : PropTypes.string,
        projectionRegion            : PropTypes.string,
        projectionYears             : PropTypes.string,
        projectionSurvey            : PropTypes.string,
        onPageChange                : PropTypes.func,
        onStateChange               : PropTypes.func,
        onLogout                    : PropTypes.func,
        onCreateProjBtnClick        : PropTypes.func,
        onGuidedExplorationBtnClick : PropTypes.func,
        onExploreLiSTDataBtnClick   : PropTypes.func,
        onSaveProjection            : PropTypes.func,
        onCloseProjection           : PropTypes.func,
        language                    : PropTypes.number,
        onOpenHelpDialog            : PropTypes.func,
        projectionNotes             : PropTypes.string,
        subnatProjection            : PropTypes.bool,
        onExtractBtnClick           : PropTypes.func,
    };

    static defaultProps = {
        pageID                      : "CSHomeForm",
        isGuest                     : true,
        humanName                   : '',
        signedIn                    : false,
        projectCreated              : false,
        isDeskTop                   : true,
        projectionName              : '',
        appMode                     : '',
        projectionCountry           : '',
        projectionRegion            : '',
        projectionYears             : '',
        projectionSurvey            : '',
        onPageChange                : (value) => console.log('onPageChange ' + value),
        onStateChange               : (value) => console.log('onStateChange ' + JSON.stringify(value)),
        onLogout                    : () => console.log('onLogout'),
        onCreateProjBtnClick        : () => console.log('onCreateProjBtnClick'),
        onGuidedExplorationBtnClick : () => console.log('onGuidedExplorationBtnClick'),
        onExploreLiSTDataBtnClick   : () => console.log('onExploreLiSTDataBtnClick'),
        onSaveProjection            : () => console.log('onSaveProjection'),
        onCloseProjection           : () => console.log('onCloseProjection'),
        language                    : CONSTANTS.GB_English,
        onOpenHelpDialog            : () => console.log('onOpenHelpDialog'),
        projectionNotes             : "",
        subnatProjection            : false,
        onExtractBtnClick           : () => console.log('onExtractBtnClick'),
    };

    state = {
        fileAnchor                  : null,
        saveAnchor                  : null,
        projAnchor                  : null,
        nameAnchor                  : null,
        projNameValue               : '',
        projNameSaveAs              : '',
        isGuest                     : this.props.isGuest,
        explanationText             : '',
        closeProjReason             : '',
        srcVis                      : false,
        source                      : [],
        hideMenu                    : false,
        isMobile                    : false
    };

    componentDidMount () {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        this.setState({
            hideMenu : window.innerWidth < 1375,
            isMobile : window.innerWidth < 500
        });
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onLanguageClick = () => {
        this.props.onStateChange({
            dialogLanguageOpen : true
        });
        this.setState({fileAnchor: null});
    };

    // onExtractClick = () => {
    //     this.props.onPageChange("ExtractTool");
    //     this.setState({fileAnchor: null});
    // };

    onSupportClick = () => {
        window.open("http://support.avenirhealth.org");
    };

    onCreateProjBtnClick = () => {
        this.props.onCreateProjBtnClick();
        this.setState({projAnchor: null, fileAnchor: null});
    };

    onAboutClick = () => {
        this.props.onStateChange({
            dialogAboutOpen : true,
        });

        this.setState({
            fileAnchor : null
        });
    };

    onNewSupportClick = () => {
        this.props.onStateChange({
            dialogNewSupportOpen : true,
        });

        this.setState({
            fileAnchor : null
        });
    };

    onSignInClick = () => {
        this.props.onStateChange({
            dialogLogInOpen : true
        });
    };

    onManageAccountClick = () => {
        window.open("https://authuser.avenirhealth.org/?sv=LOL&md=mg");
    };

    onSignOutClick = () => {
        this.props.onLogout();
    };

    onSaveClick = () => {
        this.setState({
            saveAnchor : null,
            nameAnchor : null
        });

        if (this.props.isGuest) {
            this.props.onStateChange({
                dialogNotLoggedInOpen : true
            });
        } else {
            this.props.onSaveProjection();
        }
    };

    onSaveAsClick = () => {
        this.setState({ 
            saveAnchor : null,
            nameAnchor : null
        });

        if (this.props.isGuest) {
            this.props.onStateChange({
                dialogNotLoggedInOpen : true
            });
        } else {
            this.props.onStateChange({
                dialogSaveAsOpen: true,
            });
        }
    };

    onCloseProjBtnClick = () => {
        this.props.onStateChange({
            countryISO        : 4,
            selectedCountry   : "Afghanistan",
            yearRange         : { min: (new Date()).getFullYear(), max: CONSTANTS.CS_DefaultFinalYear },
            projectionNotes   : "",
            authorName        : SessionStorageUtil.getHumanName(),
            orgName           : SessionStorageUtil.getOrganization(),
            closeProjReason   : CONSTANTS.CS_CloseUnderProjectionName
        }, () => this.props.onCloseProjection());
        this.setState({nameAnchor: null});
    };

    //--------------------------------------------------

    handleFileBtnClick = event => {
        this.setState({
            fileAnchor : event.currentTarget
        });
    };

    handleFileBtnClose = () => {
        this.setState ({
            fileAnchor: null
        });
    };

    handleSaveBtnClick = event => {
        this.setState({
            saveAnchor: event.currentTarget
        });
    };

    handleCreateProjBtnClick = (event) => {
        this.setState({
            projAnchor : event.currentTarget,
            //fileAnchor: null
        });
    };

    handleProjNameBtnClick = (event) => {
        this.setState({
            nameAnchor: event.currentTarget
        });
    };

    handleSaveBtnClose = () => {
        this.setState({
            saveAnchor : null
        });
    };

    handleCreateProjBtnClose = () => {
        this.setState({
            projAnchor : null
        });
    };

    handleProjNameBtnClose = () => {
        this.setState({
            nameAnchor: null
        });
    };

    //---------------------------------------------------

    onPageChange = (id) => {

        this.setState({
            fileAnchor : null,
            saveAnchor : null
        });

        this.props.onPageChange(id);
    };

    onProjectionLibraryClick = () => {
        this.onPageChange("CSProjectionLibraryForm");
    };

    onDeveloperClick = () => {
        this.onPageChange("GBDeveloperForm");
    };

    onHelpClick = () => {
        const pages = {
            "CSHomeForm": "what_is_list",
            "CSProjectionLibraryForm": "library",
            "CSProjManagerForm": "projection",
            "CSReviewDefaultDataForm": "review-default-data",
            "CSManageInterventionsForm": "interventions",
            "CSViewResultsForm": "view-results",
            "CSCountryListForm": this.props.appMode === CONSTANTS.CS_ExploreLiSTDataMode ? "explore-data" : "missed-opportunities",
            "CSMissedOpportunitiesForm": "missed-opportunities",
            "CSExploreLiSTDataForm": "explore-data"
        };

        if (this.props.pageID !== "ExtractTool") {
            this.props.onOpenHelpDialog && this.props.onOpenHelpDialog(pages[this.props.pageID] + ".htm");
        }
    };

    onEditClick = () => {
        this.onPageChange("CSProjManagerForm");
        this.setState({
            projAnchor : null,
            nameAnchor : null,
            fileAnchor: null
        });
    };

    onSourcesClick = () => {
        this.setState({
            srcVis: !this.state.srcVis,
            source: TestPackTables({
                CSModvars : this.props.CSModvars,
                EditorStartYear : this.props.EditorStartYear,
                EditorEndYear : this.props.EditorEndYear,
                IVInfo : this.props.IVInfo,
                language : this.props.language
            })
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderFullAppBar = () => {

        // const onTestPackTables = () => {
        //     TestPackTables({
        //         CSModvars : this.props.appState.CSModvars, 
        //         EditorStartYear : this.props.appState.firstYear, 
        //         EditorEndYear : this.props.appState.finalYear, 
        //         SubnatMode : false,
        //         IVInfo : this.props.appState.IVInfo,
        //         language : this.props.appState.language,
        //         QualityMode : false,
        //         showRetroYears : false,
        //         single : false,
        //         firstYear : this.props.appState.firstYear,
        //         CauseOfDeath : 2, //CONSTANTS.CS_MstEfficacyNeonatal,
        //         Mode : 0,
        //         useFamPlanOutputs : true,
        //         vt : 1,
        //         IPTpCBChecked : false
        //     });
        // };

        return (
            <div>
                {this.props.projectCreated ? this.renderProjName() : null}

                <div style={{float:"right", marginTop: 7}}>

                    {/* <TButton
                        onClick={onTestPackTables}
                    /> */}

                    {this.renderAppBarMenu()}

                    {/*{this.renderSaveButton()}*/}

                    {this.renderHelpAndResourcesButton()}

                    {/*{this.renderDividerLine()}*/}

                    {this.renderLogInButton()}

                    {this.renderPartialFileManagementMenu()}

                    {/*{this.renderDeveloperButton()}*/}
                </div>
            </div>
        );
    };

    renderAppBarMenu = () => {
        return (
            <CSAppBarMenu
                pageID={this.props.pageID}
                appMode={this.props.appMode}
                isGuest={this.props.isGuest}
                projectCreated={this.props.projectCreated}
                onExploreLiSTDataBtnClick={this.props.onExploreLiSTDataBtnClick}
                onGuidedExplorationBtnClick={this.props.onGuidedExplorationBtnClick}
                onCreateProjBtnClick={this.props.onCreateProjBtnClick}
                onExtractBtnClick={this.props.onExtractBtnClick}
                onPageChange={this.props.onPageChange}
                menuThreshold={this.state.hideMenu}
                isMobile={this.state.isMobile}
            />
        )
    };

    renderVertMenuOnly = () => {
        return (
            <div>
                {this.props.projectionName.length > 0 ? this.renderProjName() : null}

                <div style={{float:"right", marginTop: 7}}>

                    {/*{this.renderSaveButton()}*/}

                    {this.renderHelpAndResourcesButton()}

                    {this.renderDividerLine()}

                    {this.renderLogInButton()}

                    {this.renderFullFileManagementMenu()}
                </div>

            </div>
        );
    };

    renderProjName = () => {
        // const nameAnchor = this.state.nameAnchor;
        const styles = {
            div1 : {
                display: 'inline-block',
                float: this.state.isMobile ? "right" : "left"
            },
            menuItems: {
                color: '#65A7EB',
                fontWeight:"bold",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px'
            },
            anchorOrigin : {
                vertical: 'bottom',
                horizontal: 'left'
            },
            txt : {
                margin: 25,
                width: 'calc(100% - 50px)'
            }
        };

        let subCaption = this.props.projectionCountry + " " + this.props.projectionYears;
        let subCaptionDetails = this.props.projectionRegion + " " + this.props.projectionSurvey;

        return (
            <div style={styles.div1}>
                {this.renderDividerLine()}

                <CSProjectionInfoBtn
                    caption             = {this.props.projectionName.substring(0, 20) + (this.props.projectionName.length > 20 ? "..." : "")}
                    variant             = "text"
                    style               = {styles.menuItems}
                    captionStyles       = {styles.captionStyles}
                    onClick             = {this.handleProjNameBtnClick}
                    pictureRight        = {<i className="fa fa-caret-down" style={{marginTop: 7}}></i>}
                    fullCaption         = {this.props.projectionName}
                    subCaption          = {subCaption}
                    subCaptionDetails   = {subCaptionDetails}
                    onEditClick         = {this.onEditClick}
                    onSaveClick         = {this.onSaveClick}
                    onSaveAsClick       = {this.onSaveAsClick}
                    onCloseProjBtnClick = {this.onCloseProjBtnClick}
                />
                {this.renderSaveButton()}
                {this.renderSourcesButton()}
                <TDrawerWithHeader
                    content={<div style={styles.txt}><p><i>{this.props.projectionNotes}</i></p><p><b>{this.props.subnatProjection ? RS("GB_stNatValSrc") : ""}</b></p>{this.state.source}</div>}
                    showSaveBtn={false}
                    open={this.state.srcVis}
                    appBarText={RS('GB_stAllSources')}
                    showHelp={false}
                    onClose={this.onSourcesClick}
                />
                
                {/* <TButton
                    caption={this.props.projectionName.substring(0, 20) + (this.props.projectionName.length > 20 ? "..." : "")}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.handleProjNameBtnClick}
                    pictureRight={<i className="fa fa-caret-down"></i>}
                />
                {this.renderSaveButton()}

                <Menu
                    id="name-menu"
                    anchorEl={nameAnchor}
                    open={Boolean(nameAnchor)}
                    onClose={this.handleProjNameBtnClose}
                    getContentAnchorEl={null}
                    anchorOrigin={styles.anchorOrigin}
                >
                    <MenuItem onClick={this.onEditClick}>
                        {RS('GB_stEdit')}
                    </MenuItem>

                    <MenuItem onClick={this.onSaveClick}>
                        {RS('GB_stSave')}
                    </MenuItem>

                    <MenuItem onClick={this.onSaveAsClick}>
                        {RS('GB_stSaveAs')}
                    </MenuItem>

                    <MenuItem onClick={this.onCloseProjBtnClick}>
                        {RS('GB_stClose')}
                    </MenuItem>
                </Menu> */}
            </div>
        )
    };

    renderLibraryMenuItem = () => {
        const styles = {
            div1 : {
                display: 'inline-block',
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.pageID === "CSProjectionLibraryForm" ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px'
            }
        };

        return (
            <div
                id={"lib_btn"}
                style={styles.div1}
                onMouseOver={() => document.querySelector("#lib_btn").setAttribute("title", RS("GB_stProjectionLibrary"))}
            >
                {/*{this.renderDividerLine()}*/}
                <TButton
                    caption={RS("GB_stLibrary")}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.onProjectionLibraryClick}
                />
            </div>
        )
    };

    renderDeveloperButton = () => {

        const styles = {
            div1 : {
                display: 'inline-block'
            },
            menuItems: {
                color: '#000000',
                textDecoration: 'none',
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px'
            }
        };

        return(
            <div style={styles.div1}>
                <TButton
                    caption="Developer"
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.onDeveloperClick}
                />
            </div>
        )
    };

    renderExploreLiSTDataButton = () => {

        const styles = {
            div1 : {
                display: 'inline-block'
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.appMode.includes(CONSTANTS.CS_ExploreLiSTDataMode) ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px'
            }
        };

        return(
            <div style={styles.div1}>
                <TButton
                    caption={RS('GB_stExploreLiSTData')}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.props.onExploreLiSTDataBtnClick}
                />
            </div>
        )
    };

    renderGuidedExploration = () => {

        const styles = {
            div1 : {
                display: 'inline-block'
            },
            menuItems: {
                color: '#000000',
                textDecoration: this.props.appMode.includes(CONSTANTS.CS_MissedOpportunitiesMode) ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px'
            }
        };

        return(
            <div style={styles.div1}>
                <TButton
                    caption={RS('GB_stMissedOpportunities')}
                    variant="text"
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.props.onGuidedExplorationBtnClick}
                />
            </div>
        )
    };

    renderCreateProjButton = () => {
        //const projAnchor = this.state.projAnchor;

        const styles = {
            menuItems: {
                color: '#000000',
                textDecoration: ["CSProjManagerForm", "CSReviewDefaultDataForm", "CSManageInterventionsForm", "CSViewResultsForm"].includes(this.props.pageID ) ? "underline rgb(5, 34, 93)" : 'none',
                // textDecorationColor: "rgb(5, 34, 93)",
                textUnderlinePosition: "under",
                // textDecorationThickness: "3px",
                fontSize: '16px',
                paddingLeft: 0,
                paddingRight: 0,
            },
            captionStyles : {
                marginLeft: '10px',
                marginRight: '10px'
            },
            anchorOrigin : {
                vertical: 'bottom',
                horizontal: 'left'
            }
        };

        return (
            <div style={{display: 'inline-block'}}>
                <TButton
                    caption={RS('GB_stProjection')}
                    variant={"text"}
                    style={styles.menuItems}
                    captionStyles={styles.captionStyles}
                    onClick={this.props.projectCreated ? this.handleCreateProjBtnClick : this.props.onCreateProjBtnClick}
                />

                {this.renderProjMenu("desktop")}
            </div>
        )
    };

    renderProjMenu = (screen) => {
        let styles = {
            anchorOrigin : {
                vertical: screen === "desktop" ? 'bottom' : "top",
                horizontal: screen === "tablet" ? -225 : "left"
            }
        };

        let projAnchor = this.state.projAnchor;

        return (
            <Menu
                id="proj-menu"
                anchorEl={projAnchor}
                open={Boolean(projAnchor)}
                onClose={this.handleCreateProjBtnClose}
                getContentAnchorEl={null}
                anchorOrigin={styles.anchorOrigin}
            >
                <MenuItem onClick={this.onCreateProjBtnClick}>
                    {RS('GB_stCreateANewProjection')}
                </MenuItem>

                <MenuItem onClick={this.onEditClick}>
                    {/*{RS('GB_stEdit') + ": " + this.props.projectionName}*/}
                    {RS('GB_stEditYourCurrProj')}
                </MenuItem>
            </Menu>
        );
    };

    renderSaveButton = () => {
        const saveAnchor = this.state.saveAnchor;
        const disabled = !(this.props.projectCreated);

        let styles = {
            div1 : {
                display: this.state.isMobile ? 'none' : 'inline-block'
            },
            iconButton : {
                fontSize: '24px', 
                minHeight: '40px', 
                minWidth: '40px', 
                width: '48px'
            },
            anchorOrigin : {
                vertical: 'bottom', 
                horizontal: 'left'
            }
        };

        return (
            <div style={styles.div1}>

                <IconButton
                    color="secondary"
                    style={styles.iconButton}
                    aria-label="Menu"
                    aria-owns={saveAnchor ? 'save-menu' : null}
                    aria-haspopup="true"
                    onClick={this.onSaveClick/*this.handleSaveBtnClick*/}
                    disabled={disabled}
                >
                    <SaveIcon />
                </IconButton>

                <Menu
                    id="save-menu"
                    anchorEl={saveAnchor}
                    open={Boolean(saveAnchor)}
                    onClose={this.handleSaveBtnClose}
                    getContentAnchorEl={null}
                    anchorOrigin={styles.anchorOrigin}
                >
                    <MenuItem onClick={this.onSaveClick}>
                        {RS('GB_stSave')}
                    </MenuItem>

                    <MenuItem onClick={this.onSaveAsClick}>
                        {RS('GB_stSaveAs')}
                    </MenuItem>
                </Menu>
            </div>
        )
    };

    renderSourcesButton = () => {
        const disabled = !(this.props.projectCreated);

        let styles = {
            div1 : {
                display: 'inline-block'
            },
            iconButton : {
                fontSize: '24px',
                minHeight: '40px',
                minWidth: '40px',
                width: '48px'
            },
            img : {
                height: '23px',
                width: '22px'
            }
        };

        return (
            <div style={styles.div1}>

                <IconButton
                    color="secondary"
                    style={styles.iconButton}
                    onClick={this.onSourcesClick}
                    disabled={disabled}
                >
                    <img style={styles.img} src={SourcesIcon} alt='' />
                </IconButton>
            </div>
        )
    };

    renderHelpAndResourcesButton = () => {
        
        const styles = {
            div1: {
                display: 'inline-block',
                marginLeft: '10px',
                marginRight: '10px'
            },
            captionStyles : {
                fontSize: '18px', 
                fontWeight: 'bold', 
                lineHeight: 0
            },
            buttonStyles : {
                color: '#FFFFFF', 
                minHeight: '20px', 
                minWidth: '20px', 
                height: '20px', 
                width: '20px',
                // boxShadow : 'none',
            },
        };

        return (
            <div style={styles.div1}>
                <TButton
                    caption={'?'}
                    captionStyles={styles.captionStyles}
                    aria-haspopup="true"
                    variant={'fab'}
                    color={'secondary'}
                    onClick={this.onHelpClick}
                    style={styles.buttonStyles}
                >
                </TButton>
            </div>
        )
    };

    renderDividerLine = () => {
        const styles = {
            div1: {
                display: this.state.isMobile ? 'none' : 'inline-flex',
                verticalAlign:"middle"
            },
            div2 : {
                width: '1px', 
                height: '15px', 
                paddingBottom: '5px', 
                background: '#c6c6c6', 
                marginLeft: '0px',
                 marginRight: '8px'
            }
        };

        return (
            <div style={styles.div1}>
                <div style={styles.div2}>

                </div>
            </div>
        )
    };

    renderLogInButton = () => {
        const styles = {
            logInButton: {
                paddingLeft: '0px',
                //marginLeft: '5px',
                marginLeft: '10px',
                marginRight: '10px'
            },
        };

        return(
            <TAvatarDropdown
                isGuest={this.props.isGuest}
                signedIn={!this.props.isGuest}
                humanName={ (SessionStorageUtil.getHumanName() !== "") ? SessionStorageUtil.getHumanName() : this.props.humanName }
                style={styles.logInButton}
                onSignInClick={this.onSignInClick}
                onManageAccountClick={this.onManageAccountClick}
                onSignOutClick={this.onSignOutClick}
            />
        )
    };

    renderPartialFileManagementMenu = () => {
        const { isGuest } = this.props;
        const { fileAnchor }  = this.state;
        
        return (
            <div style={{display: 'inline-block'}}>
                <IconButton
                    aria-label="Menu"
                    aria-owns={fileAnchor ? 'file-menu' : null}
                    aria-haspopup="true"
                    color={'primary'}
                    onClick={this.handleFileBtnClick}
                >
                    <DotsMenu />
                </IconButton>

                <Menu
                    id="file-menu"
                    anchorEl={fileAnchor}
                    open={Boolean(fileAnchor)}
                    onClose={this.handleFileBtnClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                >
                    {/*{*/}
                        {/*!(this.props.isGuest) ?*/}
                            {/*<MenuItem onClick={this.onProjectionLibraryClick}>{RS('GB_stProjectionLibrary')}</MenuItem> :*/}
                            {/*null*/}
                    {/*}*/}

                    {/*{*/}
                        {/*!(this.props.isGuest) ?*/}
                            {/*< Divider /> : */}
                            {/*null*/}
                    {/*}*/}

                    <MenuItem onClick={this.onLanguageClick}>{RS('GB_stLanguage')}</MenuItem>

                    {
                        !isGuest ?
                            <MenuItem onClick={this.props.onExtractBtnClick}>Extract</MenuItem> : 
                            null
                    }

                    <MenuItem onClick={this.onNewSupportClick}>{RS('GB_stSupport')}</MenuItem>

                    <MenuItem onClick={this.onAboutClick}>{RS('GB_stAbout')}</MenuItem>
                </Menu>
            </div>
        )
    };

    renderFullFileManagementMenu = () => {
        //const { isGuest } = this.props;
        const { fileAnchor }  = this.state;
        
        return (
            <div style={{display: 'inline-block'}}>
                <IconButton
                    aria-label="Menu"
                    aria-owns={fileAnchor ? 'file-menu' : null}
                    aria-haspopup="true"
                    color={'primary'}
                    onClick={this.handleFileBtnClick}
                >
                    <DotsMenu />
                </IconButton>

                <Menu
                    id="file-menu"
                    anchorEl={fileAnchor}
                    open={Boolean(fileAnchor)}
                    onClose={this.handleFileBtnClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                >
                    {/*<MenuItem onClick={() => this.setState({fileAnchor: null}, () => this.props.onExploreLiSTDataBtnClick())}>*/}
                    {/*    {RS('GB_stExploreLiSTData')}*/}
                    {/*</MenuItem>*/}

                    {/*<MenuItem onClick={() => this.setState({fileAnchor: null}, () => this.props.onGuidedExplorationBtnClick())}>*/}
                    {/*    {RS('GB_stMissedOpportunities')}*/}
                    {/*</MenuItem>*/}

                    {/*<MenuItem onClick={(e) => this.props.projectCreated ? this.handleCreateProjBtnClick(e) : this.setState({fileAnchor: null}, () => this.props.onCreateProjBtnClick())}>*/}
                    {/*    {RS('GB_stProjection')}*/}
                    {/*</MenuItem>*/}

                    {/*{*/}
                    {/*    !(this.props.isGuest) ?*/}
                    {/*        < Divider /> :*/}
                    {/*        null*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    !(this.props.isGuest) ?*/}
                    {/*        <MenuItem onClick={this.onProjectionLibraryClick}>{RS('GB_stLibrary')}</MenuItem> :*/}
                    {/*        null*/}
                    {/*}*/}

                    {/*<Divider />*/}

                    <MenuItem onClick={this.onLanguageClick}>
                        {RS('GB_stLanguage')}
                    </MenuItem>

                    {/*{*/}
                    {/*    !isGuest ?*/}
                    {/*        <MenuItem onClick={this.props.onExtractBtnClick}>Extract</MenuItem> : */}
                    {/*        null*/}
                    {/*}*/}

                    <MenuItem onClick={this.onNewSupportClick}>
                        {RS('GB_stSupport')}
                    </MenuItem>

                    <MenuItem onClick={this.onAboutClick}>
                        {RS('GB_stAbout')}
                    </MenuItem>
                </Menu>

                {this.renderProjMenu("tablet")}
            </div>
        )
    };

    render() {
        return (
            <div style={{width:"100%"}}>
                {
                    (this.state.hideMenu) ?
                        this.renderVertMenuOnly() :
                        this.renderFullAppBar()
                }
            </div>
        );
    }
}

export default AppBarCustom;