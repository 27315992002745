import * as React from 'react';
import PropTypes from "prop-types";
import Slide from '@material-ui/core/Slide';
import RS from "@common/strings/RS";
import ShowDataSection from "./components/ShowDataSection";
import CONSTANTS from "utilities/CS/CSConst";
import CountryFlags from "../../CountryFlags";
 
class ExploreDataBodyContent extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        selectedCountry             : PropTypes.string,
        displays                    : PropTypes.arrayOf(PropTypes.object),
        IVInfo                      : PropTypes.arrayOf(PropTypes.object),
        inputModvars                : PropTypes.arrayOf(PropTypes.object),
        language                    : PropTypes.number,
        onStateChange               : PropTypes.func,
        onUpdateDisplays            : PropTypes.func,
        onRemoveDisplay             : PropTypes.func,
        style                       : PropTypes.object,
    };

    static defaultProps = {
        selectedCountry             : '',
        displays                    : [],
        IVInfo                      : [],
        inputModvars                : [],
        language                    : CONSTANTS.GB_English,
        onStateChange               : () => console.log('onStateChange'),
        onUpdateDisplays            : () => console.log('onUpdateDisplays'),
        onRemoveDisplay             : () => console.log('onRemoveDisplay'),
        style                       : {},
    };

    shouldComponentUpdate(nextProps) {
        if (
            (this.props.selectedCountry !== nextProps.selectedCountry) || 
            (this.props.displays !== nextProps.displays) || 
            (this.props.language !== nextProps.language) || 
            (this.props.inputModvars !== nextProps.inputModvars) || 
            (this.props.onStateChange !== nextProps.onStateChange) || 
            (this.props.onUpdateDisplays !== nextProps.onUpdateDisplays) || 
            (this.props.onRemoveDisplay !== nextProps.onRemoveDisplay) || 
            (this.props.style !== nextProps.style)
        ) {
            if (nextProps.displays.length > this.props.displays.length) {

                function scrollIt(element) {
                    element.scrollTop = 0;
                }
                  
                const elm = document.getElementById('ExploreContent');
                scrollIt(elm);

            }
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onChangeDisplay = (itemKey, displayType) => {
        let dataArray = JSON.parse(JSON.stringify(this.props.displays));
        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].itemKey === itemKey) {
                dataArray[i].selectedDisplayType = displayType
            }
        }
        
        this.props.onUpdateDisplays(dataArray);
    };

    onChangeYearComplete = (itemKey, yearRange) => {
        let dataArray = JSON.parse(JSON.stringify(this.props.displays));
        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].itemKey === itemKey) {
                dataArray[i].firstYear = yearRange['min'];
                dataArray[i].finalYear = yearRange['max'];
            }
        }
        this.props.onUpdateDisplays(dataArray);
    };
    
    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderPageBody = () => {
        let pageBody = [];
        for(let i = this.props.displays.length - 1; i >= 0; i--) {
            pageBody.push(
                <Slide key={this.props.displays[i]['itemKey']} in={true} timeout={500} direction={'down'} mountOnEnter unmountOnExit>
                    <ShowDataSection
                        key                  = {this.props.displays[i]['itemKey']}
                        onChangeDisplay      = {this.onChangeDisplay}
                        onChangeYearComplete = {this.onChangeYearComplete}
                        exploreShowDataItem  = {this.props.displays[i]}
                        IVInfo               = {this.props.IVInfo}
                        inputModvars         = {this.props.inputModvars}  //{this.props.displays[i].inputModvars}
                        selectedCountry      = {this.props.selectedCountry}
                        language             = {this.props.language}
                        onRemoveDisplay      = {this.props.onRemoveDisplay}
                    />
                </Slide>
            );
        }

        let styles = {
            rightDiv : {
                width: '50%',
                margin: '0 auto',
            },
            centerFlag : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: '25px',
            },
            centerText : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: '25px',
                marginTop: '10px',
                paddingTop: '10px',
                borderTop: '2px solid #ebebeb'
            },
            flag : {
                display: 'block',
                margin: '15px',
                width: '150px',
            }
        };

        if (pageBody.length === 0) {
            return (
                <div style={styles.rightDiv}>
                    <div style={styles.centerFlag}>
                        <CountryFlags selectedCountry={this.props.selectedCountry} style={styles.flag} />
                    </div>
                    <div style={styles.centerText}>
                        {RS('GB_stCreateDisplaysonLeft1') + ' ' + this.props.selectedCountry + '. ' + RS('GB_stCreateDisplaysonLeft2')}
                    </div>
                </div>
            );
        }

        return (pageBody);
    };

    render() {

        let styles = {
            wrapper : {
                ...this.props.style
            }
        };

        if (this.props.displays.length === 0) {
            styles = {
                wrapper : {
                    ...this.props.style,
                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                }
            };
        }

        return (
            <div id="ExploreContent" style={styles.wrapper}>
                <div id="TopOfContent"></div>
                {this.renderPageBody()}
            </div>
        );
    }
}

export default ExploreDataBodyContent;