import React from 'react';
import PropTypes from "prop-types";
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import ApplyButton from "../buttons/ApplyButton";

class TCSRenamedFileDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        fileToRename        : PropTypes.string,
        onStateChange       : PropTypes.func,
        onRenameProjection  : PropTypes.func
    };

    static defaultProps = {
        fileToRename        : "",
        onStateChange       : () => console.log('onStateChange'),
        onRenameProjection  : () => console.log('onRenameProjection')
    };

    state = {
        renamedFile         : this.props.fileToRename
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onType = (val) => {
        this.setState({
            renamedFile: val
        });
    };

    onClose = () => {
        this.props.onStateChange({
            dialogFileRenameOpen: false
        });
    };

    onSave = () => {
        this.props.onRenameProjection(this.props.fileToRename, this.state.renamedFile);
        this.onClose();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0',
            }
        };

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stRename") + " " + RS("GB_stProjection")}
                onClose={this.onClose}
                content={
                    <div>            
                        <TEdit
                            name="CopiedFileNameField"
                            caption={RS("GB_stNewProjName")}
                            value={this.state.renamedFile}
                            onChange={this.onType}
                            style={styles.edit}
                            foc={true}
                            shrinkLabel={true}
                        />
                    </div>
                }
                actions={
                    <div>
                        <ApplyButton onClick={this.onSave} disabled={this.state.renamedFile.length === 0} />
                    </div>
                }
            />
        );
    }

}

export default TCSRenamedFileDialog;