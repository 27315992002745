import React from "react";
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import TButton from '@common/components/TButton';
import RS from "@common/strings/RS";
import GuidedExplorationImage from '../../img/guidedExplorationIcon.png';

class GuidedExplorationPaper extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onGuidedExplorationBtnClick  : PropTypes.func,
        isDeskTop                    : PropTypes.bool,
    };

    static defaultProps = {
        onGuidedExplorationBtnClick : () => console.log('onGuidedExplorationBtnClick'),
        isDeskTop                   : true
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onGuidedExplorationBtnClick = () => {
        this.props.onGuidedExplorationBtnClick();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderGuidedExplorationImage = () => {

        let styles = {
            img: {
                height: '85px',
                position: 'absolute',
                top: '10px',
                left: '210px'
            }
        };

        return (
            <img style={styles.img} src={GuidedExplorationImage} alt='' />
        );
    };

    renderGuidedExplorationHeader = () => {

        let styles = {
            header: {
                position: 'absolute',
                marginTop: '105px',
                marginLeft: '10px',
                // marginRight: '10px',
                textAlign: 'left',
                color: '#0c3a79',
                userSelect: 'none'
                // width: '236px'
            }
        };

        return (
            <h2 style={styles.header} >
                {RS('GB_stMissedOpportunities')}
            </h2>
        );
    };

    renderGuidedExplorationText = () => {

        let styles = {
            text: {
                position: 'absolute',
                marginTop: '150px',
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '16px',
                textAlign: 'left',
                lineHeight: 1.5,
                width: '250px',
                userSelect: 'none'
            }
        };

        return (
            <p style={styles.text} >
                {RS('GB_stGuidedText')}
            </p>
        );
    };

    renderGuidedExplorationBtn = () => {

        let styles = {
            raisedButton: {
                width: '125px',
                fontSize: '16px',
                float: 'left'
            },
            raisedButtonDiv: {
                position: 'absolute',
                top: '300px',
                left: '10px'
            }
        };

        return (
            <TButton
                caption={RS('GB_stReview')}
                color="primary"
                style={styles.raisedButton}
                containerStyle={styles.raisedButtonDiv}
                onClick={this.onGuidedExplorationBtnClick}
            />
        );
    };

    render() {

        const styles = {
            paperBoxHoriz: {
                position: 'relative',
                width: 300,
                height: 355,
                float: 'left',
                marginLeft: '20px',
                marginBottom: '20px',
                background: 'white'
            },

            paperBoxVert: {
                position: 'relative',
                width: 300,
                height: 355,
                marginBottom: '20px',
                background: 'white'
            },

            infoBox: {
                width: 275,
                margin: '0 auto'
            }
        };

        return (
            <Paper style={(this.props.isDeskTop) ? styles.paperBoxHoriz : styles.paperBoxVert}>
                <div style={styles.infoBox}>
                    {this.renderGuidedExplorationImage()}
                    {this.renderGuidedExplorationHeader()}
                    {this.renderGuidedExplorationText()}
                    {this.renderGuidedExplorationBtn()}
                </div>
            </Paper>
        )
    }

}

export default GuidedExplorationPaper;