import React from 'react';
import PropTypes from "prop-types";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import ClearButton from "../buttons/ClearButton";
import ApplyButton from "../buttons/ApplyButton";

class FilterTagsDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange     : PropTypes.func,
        visible           : PropTypes.bool,
        topicList         : PropTypes.array,
        topicFilter       : PropTypes.array
    };

    static defaultProps = {
        onStateChange     : () => console.log('onStateChange'),
        visible           : false,
        topicList         : [],
        topicFilter       : []
    };

    state = {
        chosenTags        : this.props.topicFilter,
        tagsHaveChanged   : false
    };

    UNSAFE_componentWillReceiveProps = (newProps) => {
        this.setState({chosenTags: newProps.topicFilter});
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({dialogFilterTagsOpen: false});
    };

    onFilterBtnClick = () => {
        this.props.onStateChange({topicFilter: this.state.chosenTags});
        this.onClose();
    };

    onClearBtnClick = () => {
        this.props.onStateChange({topicFilter: []});
        this.onClose();
    }

    onCheckBoxChange = (val) => {
        let arr = JSON.parse(JSON.stringify(this.state.chosenTags));
        if (arr.includes(val)) {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (arr[i] === val) {
                    arr.splice(i, 1);
                }
            }
        } 
        else {
            arr.push(val);
        }
        this.setState({
            chosenTags: arr,
            tagsHaveChanged: true
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderTagBox = () => {
        let tags = [];
        let topics = [];

        this.props.topicList.forEach((tag) => {
            let allTags = tag.split(", ");
            allTags.forEach(function(top) {
                if (!topics.includes(top)) {
                    topics.push(top);
                }
            });
        });

        topics.sort((a, b) => {
            let scale = 0;
            if (a.toLowerCase() < b.toLowerCase()) {
                scale = -1;
            } 
            else {
                scale = 1;
            }
            return scale;
        });

        topics.forEach((topic) => {
            tags.push(
                <TCheckBox
                    key={topics.indexOf(topic)}
                    caption={topic}
                    value={this.state.chosenTags.includes(topic)}
                    onClick={() => this.onCheckBoxChange(topic)}
                />
            );
        });

        if (topics.length === 0) {
            return "No topics are available";
        }
        else {
            return (
                <div>
                    {tags}
                </div>
            );
        }
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS("GB_stFilter") + " " + RS("GB_stTags").toLowerCase()}
                onClose={this.onClose}
                minWidth={350}
                content={
                    <div>
                        {this.renderTagBox()}
                    </div>
                }
                actions={
                    <div>
                        <ClearButton onClick={this.onClearBtnClick} disabled={this.state.chosenTags.length === 0} colorType="secondary" />
                        <ApplyButton onClick={this.onFilterBtnClick} disabled={!this.state.tagsHaveChanged} />
                    </div>
                }
            />
        );
    }

}

export default FilterTagsDialog;