import React from 'react';
import PropTypes from "prop-types";
import FileDownload from "@material-ui/icons/GetApp";
import TLabel from "@common/components/TLabel";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import GBStdChart from '../../../../../components/GB/GBStdChart';
import {generateDataPack} from "./GenerateDataPack";
import {getMOChartSubTitle} from "./MOChartSubTitle";
import { CSEditor } from '../../../../../components/CS/CSEditors';
import { uuidv4 } from "../../../../../api/util/uuidv4";

class DisplayMO extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        displayChart                   : PropTypes.bool,
        showLegend                     : PropTypes.bool,
        minHeightChart                 : PropTypes.number,
        maxHeightChart                 : PropTypes.number,
        minHeightTable                 : PropTypes.number,
        maxHeightTable                 : PropTypes.number,
        chartMarginLeft                : PropTypes.number,
        xAxisLabelsPadding             : PropTypes.number,
        stretch                        : PropTypes.bool,
        spaceReduction                 : PropTypes.number,
        legend                         : PropTypes.object,
        creditsEnabled                 : PropTypes.bool,
        MOChartID                      : PropTypes.string,
        MOTableID                      : PropTypes.string,
        showTopInterv                  : PropTypes.bool,
        displayMODownloadButton        : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayAdolescentCOD           : PropTypes.bool,
        displayNutritionCOD            : PropTypes.bool,
        displayBirthOutcomeCOD         : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        adolescentCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        onStateChange                  : PropTypes.func,
        MOResultToDisplay              : PropTypes.number,
        passData                       : PropTypes.func,
        tableRef                       : PropTypes.any,
        language                       : PropTypes.number
    };

    static defaultProps = {
        displayChart                   : true,
        showLegend                     : true,
        minHeightChart                 : 300,
        maxHeightChart                 : 300,
        minHeightTable                 : 400,
        maxHeightTable                 : 400,
        chartMarginLeft                : 300,
        xAxisLabelsPadding             : 25,
        stretch                        : false,
        spaceReduction                 : 0,
        legend                         : {reversed : true},
        creditsEnabled                 : true,
        MOChartID                      : 'MOChart',
        MOTableID                      : 'MOTable',
        showTopInterv                  : false,
        displayMODownloadButton        : true,
        selectedCountry                : 'Afghanistan',
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayAdolescentCOD           : false,
        displayNutritionCOD            : false,
        displayBirthOutcomeCOD         : false,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        adolescentCausesOfDeathContent : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        onStateChange                  : () => console.log('onStateChange'),
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        passData                       : () => console.log("passData"),
        tableRef                       : null,
        language                       : CONSTANTS.GB_English
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        }
        else {
            return false;
        }
    }

    constructor(props) {
        super(props);

        this.chart = React.createRef();
        this.dataPack = [];
    }

    state = {
        tableRef: React.createRef()
    };

    componentDidMount() {
        this.props.onStateChange ({
            topMOInterventions: this.dataPack[CONSTANTS.CS_MOTopInterventions]
        }, () => this.props.passData({chart: this.chart, tableRef: this.state.tableRef}));
    }

    componentDidUpdate() {
        this.props.onStateChange ({
            topMOInterventions: this.dataPack[CONSTANTS.CS_MOTopInterventions]
        });
    }

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    getDelphiColorToHex = (color) => {

        if (color >= 0) {
            let hexString = color.toString(16);
            hexString = hexString.padStart(6, 0);

            let R = hexString.slice(-2);
            let G = hexString.slice(-4, -2);
            let B = hexString.slice(-6, -4);

            return '#' + (R + G + B);
        }
        else {
            return '';
        }
    };

    getDeathsAvertedOptions = (packChart) => {

        const getSeries= (subsetLabels, subsetColors, subsetsToLegend, chartData, toolTipData) => {
            let series = chartData.map( (arr2, subset) => {
                return {
                    name    : subsetLabels[subset],
                    color   : this.getDelphiColorToHex(subsetColors[subset]),
                    index   : subsetsToLegend[subset],
                    visible : !(subsetsToLegend[subset] === -1),
                    data    : chartData[subset],
                    ttData  : toolTipData[subset]
                };
            });

            return series;
        };

        let deathAvertedOptions = {
            chart : {
                marginLeft: this.props.chartMarginLeft
            },

            tooltip : {
                shared: false,
                formatter : (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? function() {
                    // symbol = '●';
                    // symbol = '♦';
                    // symbol = '■';
                    // symbol = '▲';
                    // symbol = '▼';

                    let baseCov = RS('GB_stBaseCoverage');

                    return (
                        '<b>' + this.point.category + '</b><br/>' +
                        '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name +
                        ': ' + (this.point.y).toFixed(0) + ' ' + RS('GB_stDeathsAverted') + '<br/>' + baseCov + (this.series.options.ttData[this.point.x]).toFixed(2) + ' %'
                    )
                } : function() {
                    // symbol = '●';
                    // symbol = '♦';
                    // symbol = '■';
                    // symbol = '▲';
                    // symbol = '▼';

                    let baseCov = RS('GB_stBaseCoverage');

                    return (
                        '<b>' + this.point.category + '</b><br/>' +
                        '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name +
                        ': ' + (this.point.y).toFixed(2) + ' % ' + RS('GB_stReductionInMortality') + '<br/>' + baseCov + (this.series.options.ttData[this.point.x]).toFixed(2) + ' %'
                    )
                } ,
            },
            legend : this.props.legend,

            title : {
                text: (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stPotentialDeathsAverted') : RS('GB_stPercentRedInMort'),
                align: 'left',
                style : {
                    fontWeight: 'bold',
                    color : '#cb6814', //(this.props.showTopInterv) ? '#cb6814' : '#333333',
                    fontFamily: 'Lato'
                }
            },

            subtitle: {
                text: getMOChartSubTitle(this.props.MOResultToDisplay,
                                         this.props.selectedCountry,
                                         this.props.displayMaternalCOD,
                                         this.props.displayStillbirthCOD,
                                         this.props.displayNeonatalCOD,
                                         this.props.displayChildCOD,
                                         this.props.deliveryPointsContent,
                                         this.props.displayAdolescentCOD,
                                         this.props.displayNutritionCOD,
                                         this.props.displayBirthOutcomeCOD),
                align: 'left',
                style : {
                    fontFamily: 'Lato',
                    fontSize : '13px'
                }
            },

            xAxis: {
                labels: {
                    padding: this.props.xAxisLabelsPadding
                },
            },

            series : getSeries(packChart.subsetLabels, packChart.subsetColors, packChart.SubsetsToLegend, packChart.chartData, packChart.toolTipData)
        };

        return(deathAvertedOptions)
    };

    getDeliveryPointsOptions = (packChart) => {

        const getSeries= (subsetLabels, subsetColors, subsetsToLegend, chartData, toolTipData) => {
            let series = chartData.map( (arr2, subset) => {
                return {
                    name    : subsetLabels[subset],
                    color   : this.getDelphiColorToHex(subsetColors[subset]),
                    index   : subsetsToLegend[subset],
                    visible : !(subsetsToLegend[subset] === -1),
                    data    : chartData[subset],
                    ttData  : toolTipData[subset]
                };
            });

            return series;
        };

        let deathAvertedOptions = {
            tooltip : {
                shared: false,
                formatter : (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? function() {
                    // symbol = '●';
                    // symbol = '♦';
                    // symbol = '■';
                    // symbol = '▲';
                    // symbol = '▼';

                    let baseCov = RS('GB_stBaseCoverage');

                    return (
                        '<b>' + this.point.category + '</b><br/>' +
                        '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name +
                        ': ' + (this.point.y).toFixed(0) + ' ' + RS('GB_stDeathsAverted') + '<br/>' + baseCov + (this.series.options.ttData[this.point.x]).toFixed(2) + ' %'
                    )
                } : function() {
                    // symbol = '●';
                    // symbol = '♦';
                    // symbol = '■';
                    // symbol = '▲';
                    // symbol = '▼';

                    let baseCov = RS('GB_stBaseCoverage');

                    return (
                        '<b>' + this.point.category + '</b><br/>' +
                        '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name +
                        ': ' + (this.point.y).toFixed(2) + ' % ' + RS('GB_stReductionInMortality') + '<br/>' + baseCov + (this.series.options.ttData[this.point.x]).toFixed(2) + ' %'
                    )
                } ,
            },
            legend : this.props.legend,

            title : {
                text: (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stPotentialDeathsAverted') : RS('GB_stPercentRedInMort'),
                align: 'left',
                style : {
                    fontWeight: 'bold',
                    color : (this.props.showTopInterv) ? '#cb6814' : '#333333',
                    fontFamily: 'Lato'
                }
            },

            subtitle: {
                text: getMOChartSubTitle(this.props.MOResultToDisplay,
                    this.props.selectedCountry,
                    this.props.displayMaternalCOD,
                    this.props.displayStillbirthCOD,
                    this.props.displayNeonatalCOD,
                    this.props.displayChildCOD,
                    this.props.deliveryPointsContent),
                align: 'left',
                style : {
                    fontFamily: 'Lato',
                    fontSize : '13px'
                },
            },
            chart: {
                events: {
                    render() {
                        var chart = this,
                            rr = chart.renderer,
                            rules = [],
                            axis = chart.xAxis[0],
                            rulesDef = axis.userOptions.horizontalRules,
                            x1 = chart.plotLeft,
                            x2 = chart.plotLeft + chart.plotWidth;

                        // Draw rules
                        rulesDef.forEach(function(rule, index) {
                            var y = axis.toPixels(rule[0] + 0.5),
                                line,
                                label;

                            if (!chart.horRules) {
                                if (rule[0] !== null) {
                                    line = rr.path([
                                        'M', x1, y,
                                        'L', x2, y, 'z'
                                    ]).attr({
                                        stroke: '#aaa',
                                        'stroke-width': 1,
                                        zIndex: 9
                                    })
                                        .add()
                                }
                                label = rr.label(rule[1], 0, 0)
                                    .attr({
                                        rotation: -90,
                                        'text-anchor': 'middle',
                                        'dominant-baseline': 'central',
                                    })
                                    .add();
                            } else {
                                if (chart.horRules[index]) {
                                    if (rule[0] !== null) {
                                        line = chart.horRules[index].line;
                                    }
                                    label = chart.horRules[index].label;

                                    if (rule[0] !== null) {
                                        if (line) {
                                            line.attr({
                                                d: `M ${x1} ${y} L ${x2} ${y} z`
                                            })
                                        }
                                    }
                                }
                            }

                            // Draw labels
                            // First
                            if (index === 0) {
                                if (label) {
                                    label.attr({
                                        y: axis.toPixels(rule[0] / 2)
                                    })
                                }
                                // Last
                            } else if (index === rulesDef.length - 1) {
                                if (label) {
                                    label.attr({
                                        y: axis.toPixels(rulesDef[index - 1][0] + 0.5) +
                                            (axis.toPixels(axis.dataMax) - axis.toPixels(rulesDef[index - 1][0])) / 2

                                    })
                                }
                                // Another
                            } else {
                                if (label) {
                                    label.attr({
                                        y: axis.toPixels(rulesDef[index - 1][0] + 0.5) +
                                            (axis.toPixels(rule[0]) - axis.toPixels(rulesDef[index - 1][0])) / 2
                                    })
                                }
                            }
                            rules.push({
                                line: line,
                                label: label
                            })
                        });
                        chart.horRules = rules
                    }
                },
                marginLeft: this.props.chartMarginLeft
            },

            xAxis: {
                labels: {
                    padding: this.props.xAxisLabelsPadding
                },
                horizontalRules: packChart.verticalLabels
            },

            series : getSeries(packChart.subsetLabels, packChart.subsetColors, packChart.SubsetsToLegend, packChart.chartData, packChart.toolTipData)
        };

        return(deathAvertedOptions)
    };

    getStuntingAvertedOptions = (packChart) => {

        const getSeries= (subsetLabels, subsetColors, subsetsToLegend, chartData, toolTipData) => {
            let series = chartData.map( (arr2, subset) => {
                return {
                    name    : subsetLabels[subset],
                    color   : this.getDelphiColorToHex(subsetColors[subset]),
                    index   : subsetsToLegend[subset],
                    visible : !(subsetsToLegend[subset] === -1),
                    data    : chartData[subset],
                };
            });

            return series;
        };

        let stuntingAvertedOptions = {
            chart : {
                marginLeft: this.props.chartMarginLeft
            },

            tooltip : {
                shared: false,
                formatter : function() {

                    return (
                        '<b>' + this.point.category + '</b><br/>' +
                        '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name + ': ' + (this.point.y).toFixed(0)
                    )
                },
            },

            title : {
                text: RS('GB_stPotentialStuntingAverted'),
                align: 'left',
                style : {
                    fontWeight: 'bold',
                    color : (this.props.showTopInterv) ? '#cb6814' : '#333333',
                    fontFamily: 'Lato'
                }
            },

            subtitle: {
                text: getMOChartSubTitle(this.props.MOResultToDisplay,
                                         this.props.selectedCountry,
                                         this.props.displayMaternalCOD,
                                         this.props.displayStillbirthCOD,
                                         this.props.displayNeonatalCOD,
                                         this.props.displayChildCOD,
                                         this.props.deliveryPointsContent),
                align: 'left',
                style : {
                    fontFamily: 'Lato',
                    fontSize : '13px'
                }
            },

            xAxis: {
                labels: {
                    padding: this.props.xAxisLabelsPadding
                },
            },

            series : getSeries(packChart.subsetLabels, packChart.subsetColors, packChart.SubsetsToLegend, packChart.chartData)
        };

        return(stuntingAvertedOptions)
    };

    getDualAxisChartOptions = (packChart) => {
        const getSeries= (subsetLabels, subsetColors, subsetsToLegend, chartData, toolTipData) => {
            let series = chartData.map( (arr2, subset) => {
                if (subset === (chartData.length - 1)) {
                    // let chartDat = [];
                    // chartData[subset].forEach(function(datum) {
                    //     if (datum === null) {
                    //         chartDat.push(0);
                    //     } else {
                    //         chartDat.push(datum);
                    //     }
                    // });
                    return {
                        name: subsetLabels[subset],
                        color: this.getDelphiColorToHex(subsetColors[subset]),
                        index: subsetsToLegend[subset],
                        visible: !(subsetsToLegend[subset] === -1),
                        data: chartData[subset].slice(0, chartData[subset - 1].length), // this needs to pare down to the length of the columns in order to avoid too many plot points
                        ttData: toolTipData[subset],
                        type: CONSTANTS.CS_ScatterChart,
                        yAxis: 1,
                        marker: {
                            enabled : true,
                            symbol : 'square'
                        }
                    };
                } else {
                    return {
                        name: subsetLabels[subset],
                        color: this.getDelphiColorToHex(subsetColors[subset]),
                        index: subsetsToLegend[subset],
                        visible: !(subsetsToLegend[subset] === -1),
                        data: chartData[subset],
                        ttData: toolTipData[subset],
                        type: CONSTANTS.CS_ColumnChart,
                    };
                }
            });

            return series;
        };

        let options = {
            chart : {
                marginLeft: this.props.chartMarginLeft
            },

            tooltip : {
                shared: false,
                // formatter : function() {
                //
                //     let title = '<b>' + this.point.category + '</b><br/>';
                //
                //     if ((this.series.name === RS('GB_stMatMortRatio')) ||
                //         (this.series.name === RS('GB_stStillbirthRate')) ||
                //         (this.series.name === RS('GB_stNNMortRate')) ||
                //         (this.series.name === RS('GB_stUnder5MortRate'))) {
                //         return (
                //             title +
                //             '<span style="color:' + this.point.color + '"> ■ </span> ' + this.series.name +
                //             ': ' + (this.point.y).toFixed(2)
                //         )
                //     }
                //     else {
                //         return (
                //             title +
                //             '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name +
                //             ': ' + (this.point.y).toFixed(2) + ' %'
                //         )
                //     }
                // },
                formatter : (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? function() {
                    // symbol = '●';
                    // symbol = '♦';
                    // symbol = '■';
                    // symbol = '▲';
                    // symbol = '▼';

                    let title = '<b>' + this.point.category + '</b><br/>';

                    if (this.series.name === RS('GB_stCost')) {
                        return (
                            title +
                            '<span style="color:' + this.point.color + '"> ■ </span> ' + this.series.name +
                            ': ' + (this.point.y).toFixed(0)
                        )
                    }
                    else {

                        let baseCov = RS('GB_stBaseCoverage');

                        return (
                            title +
                            '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name +
                            ': ' + (this.point.y).toFixed(0) + ' ' + RS('GB_stDeathsAverted') + '<br/>' + baseCov + (this.series.options.ttData[this.point.x]).toFixed(2) + ' %'
                        )
                    }
                } : function() {
                    // symbol = '●';
                    // symbol = '♦';
                    // symbol = '■';
                    // symbol = '▲';
                    // symbol = '▼';
                    let title = '<b>' + this.point.category + '</b><br/>';

                    if (this.series.name === RS('GB_stCost')) {
                        return (
                            title +
                            '<span style="color:' + this.point.color + '"> ■ </span> ' + this.series.name +
                            ': ' + (this.point.y).toFixed(0)
                        )
                    }
                    else {

                        let baseCov = RS('GB_stBaseCoverage');

                        return (
                            title +
                            '<span style="color:' + this.point.color + '"> ● </span> ' + this.series.name +
                            ': ' + (this.point.y).toFixed(2) + ' % ' + RS('GB_stReductionInMortality') + '<br/>' + baseCov + (this.series.options.ttData[this.point.x]).toFixed(2) + ' %'
                        )
                    }
                } ,
            },
            legend : {
                reversed: true,
            },

            title : {
                text: (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stPotentialDeathsAverted') : RS('GB_stPercentRedInMort'),
                align: 'left',
                style : {
                    fontWeight: 'bold',
                    color : '#cb6814',
                    fontFamily: 'Lato'
                }
            },

            subtitle: {
                text: getMOChartSubTitle(this.props.MOResultToDisplay,
                    this.props.selectedCountry,
                    this.props.displayMaternalCOD,
                    this.props.displayStillbirthCOD,
                    this.props.displayNeonatalCOD,
                    this.props.displayChildCOD,
                    this.props.deliveryPointsContent),
                align: 'left',
                style : {
                    fontFamily: 'Lato',
                    fontSize : '13px'
                },
            },

            yAxis: [
                {
                    title: {
                        text: (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stCasesDeathAverted') : RS('GB_stPercentRedInMort')
                    },
                    lineWidth: 1,
                    alignTicks: false,
                },
                {
                    title: {
                        text: RS('GB_stIVCostUSD')
                    },
                    opposite: true,
                    lineWidth: 0,
                    alignTicks: false,
                    gridLineWidth : 0
                }
            ],

            series : getSeries(packChart.subsetLabels, packChart.subsetColors, packChart.SubsetsToLegend, packChart.chartData, packChart.toolTipData)
        };

        return(options);
    };

    getCredits = () => {
        let credits = {
            enabled : this.props.creditsEnabled,
            text    : RS('GB_stMOSource'),
            /*eslint-disable no-script-url*/
            href    : 'javascript:window.open("https://doi.org/10.1186/s12889-017-4736-3", "_blank")',
            style   : {
                        cursor: 'pointer',
                        fontSize : '8px'
                      },
            position: {
                align: 'right',
                verticalAlign: 'bottom',
                x: 0,
                y: -20
            }
        };

        return (credits)

    };

    renderDownloadButton = () => {
        const styles = {
            downloadButton: {
                cursor: "pointer",
		        color: "#9CC025"
            },
        };

        return (

            <FileDownload
                style={styles.downloadButton}
                onClick={() => {}}
            />

        )
    };

    render() {
        const styles = {
            titleStyle : {
                fontSize : '18px',
                fontWeight: 'bold',
                color : '#333333',
                marginLeft: '10px',
                marginTop: '19px',
                userSelect: 'none',
            },
            subTitleStyle : {
                fontSize : '13px',
                color : '#333333',
                userSelect: 'none',
                marginLeft: '10px',
                marginTop: '2px',
                marginBottom: '15px',
            },
            editorStyle : {
                marginLeft: '10px'
            }
        };

        let MOOptions = {
            deliveryPointsContent          : this.props.deliveryPointsContent,
            maternalCausesOfDeathContent   : this.props.maternalCausesOfDeathContent,
            stillbirthCausesOfDeathContent : this.props.stillbirthCausesOfDeathContent,
            neonatalCausesOfDeathContent   : this.props.neonatalCausesOfDeathContent,
            childCausesOfDeathContent      : this.props.childCausesOfDeathContent,
            adolescentCausesOfDeathContent : this.props.adolescentCausesOfDeathContent,
            MOResultToDisplay              : this.props.MOResultToDisplay,
            displayMaternalCOD             : this.props.displayMaternalCOD,
            displayStillbirthCOD           : this.props.displayStillbirthCOD,
            displayNeonatalCOD             : this.props.displayNeonatalCOD,
            displayChildCOD                : this.props.displayChildCOD,
            displayAdolescentCOD           : this.props.displayAdolescentCOD,
            displayCPR                     : this.props.displayCPR,
            sortByDeathsAverted            : this.props.sortByDeathsAverted,
            sortByInterventionCost         : this.props.sortByInterventionCost,
            viewInterventionCost           : this.props.viewInterventionCost,
            showTopInterv                  : this.props.showTopInterv,
            displayNutritionCOD            : this.props.displayNutritionCOD,
            displayBirthOutcomeCOD         : this.props.displayBirthOutcomeCOD
            // language                       : this.props.language,
        };

        this.dataPack = generateDataPack(this.props.selectedCountry,this.props.MOCountryData, MOOptions);


        let options;
        let id;
        if (MOOptions.viewInterventionCost) {
            id = "MOChart-MOSection0";
            options = this.getDualAxisChartOptions(this.dataPack[CONSTANTS.CS_PackChart]);
        }
        else if (MOOptions.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) {
            id = "MOChart-MOSection1"; //this.props.MOChartID
            options = this.getStuntingAvertedOptions(this.dataPack[CONSTANTS.CS_PackChart]);
        } else if ((MOOptions.sortByDeathsAverted) || (MOOptions.sortByInterventionCost)) {
            id = "MOChart-MOSection2"; //this.props.MOChartID
            options = this.getDeathsAvertedOptions(this.dataPack[CONSTANTS.CS_PackChart])
        } else {
            id = "MOChart-MOSection3"; //this.props.MOChartID
            options = this.getDeliveryPointsOptions(this.dataPack[CONSTANTS.CS_PackChart])
        }

        options.chart.spacingBottom = 35;

        return (
            (this.props.displayChart) ?
                <div>
                    <GBStdChart
                        key={id + uuidv4()}
                        id={id}
                        packChart={this.dataPack[CONSTANTS.CS_PackChart]}
                        minHeight={this.props.minHeightChart}
                        maxHeight={this.props.maxHeightChart}
                        options={options}
                        showLegend={this.props.showLegend}
                        chartType={'bar'}
                        stacked = 'normal'
                        legendReversed ={true}
                        credits={this.getCredits()}
                        stretch={this.props.stretch}
                        spaceReduction={this.props.spaceReduction}
                        ref={this.chart}
                    />
                    {/*{(this.props.displayMODownloadButton) ? this.renderDownloadButton() : null }*/}
                </div>:
                //<GBStdTable
                //    id={this.props.MOTableID}
                //    packTable={dataPack[CONSTANTS.CS_PackTable]}
                //    minHeight={this.props.minHeightTable}
                //    maxHeight={this.props.maxHeightTable}
                //    stretch={true}
                //    bandColor={Theme.editor.bandColor}
                //    fixedRowColor={Theme.editor.fixedRowColor}
                //    highlightColor={Theme.editor.highlightColor}
                //    cellPadding={Theme.editor.cellPadding}
                //    disableContextMenu={true}
                ///>
                <div>
                    <TLabel
                        caption = {(this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted) ?
                            ((this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ? RS('GB_stPotentialDeathsAverted') : RS('GB_stPercentRedInMort'))
                            : RS('GB_stPotentialStuntingAverted')}
                        style = {styles.titleStyle}
                    />
                    <TLabel
                        caption = {getMOChartSubTitle(this.props.MOResultToDisplay,
                            this.props.selectedCountry,
                            this.props.displayMaternalCOD,
                            this.props.displayStillbirthCOD,
                            this.props.displayNeonatalCOD,
                            this.props.displayChildCOD,
                            this.props.deliveryPointsContent)}
                        style = {styles.subTitleStyle}
                    />
                    <div style={styles.editorStyle}>
                        <CSEditor
                            key={Date.now()}
                            tableRef={this.state.tableRef}
                            mstID={CONSTANTS.CS_MstDisplayMOSheetID}
                            showTitle={true}
                            isResult={true}
                            packTable={this.dataPack[CONSTANTS.CS_PackTable]}
                            sourceEditable={false}
                        />
                    </div>
                </div>

        );
    }
}

export default DisplayMO;




