import React from 'react';
import PropTypes from "prop-types";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import Data from "../../../data/ExploreDefaultData";
import CreateDisplayButton from "../buttons/CreateDisplayButton";
import OutcomeDropdown from "../dropdowns/OutcomeDropdown";
import InterventionExpPanels from '../expansionPanels/InterventionCoverage/InterventionExpPanels';
import {
    getIVGroupRecFromMstID,
    DirectEntryMstIDSet} from "../../../../../../../utilities/CS/CSDataUtil";

class IntervCovOptions extends React.PureComponent {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        visible         : PropTypes.bool,
        language        : PropTypes.number,
        IVGroupInfo     : PropTypes.arrayOf(PropTypes.object),
        IVSubGroupInfo  : PropTypes.arrayOf(PropTypes.object),
        IVInfo          : PropTypes.arrayOf(PropTypes.object),
        inputModvars    : PropTypes.arrayOf(PropTypes.object),
        onAddDisplay    : PropTypes.func,
    };

    static defaultProps = {
        language        : CONSTANTS.GB_English,
        visible         : true,
        IVGroupInfo     : [],
        IVSubGroupInfo  : [],
        IVInfo          : [],
        inputModvars    : [],
        onAddDisplay    : () => console.log('IntervCovOptions onAddDisplay'),
    };

    state = {
        outcomeIdx                  : 0,
        interventionSubGroupList    : [],
        interventionGroupList       : [],
        selectedIVSet               : [],
        exploreBFContent            : [],
        defaultExpanded             : true,
        buttonDisabled              : true,
        allInterv                   : false
    };

    componentDidMount() {
        this.setState({
            interventionSubGroupList : this.props.IVSubGroupInfo, //JSON.parse(JSON.stringify(this.props.inputModvars.find(x => (x.tag === CONSTANTS.CS_TG_InterventionSubGroupList_MV)).value)),
            interventionGroupList    : this.props.IVGroupInfo, //JSON.parse(JSON.stringify(this.props.inputModvars.find(x => (x.tag === CONSTANTS.CS_TG_InterventionGroupList_MV)).value)),
            selectedIVSet            : JSON.parse(JSON.stringify(this.props.inputModvars.find(x => (x.tag === CONSTANTS.CS_TG_SelectedIVSet_MV)).value.intArray)),
            exploreBFContent         : Data.exploreBFContent
        });
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onOutcomeChange = (idx) => {
        this.setState ({
            outcomeIdx : idx
        })
    };

    onCheckBoxChange = (checked, groupMstID, mstID, caption) => {
        let selectedIVSet = [];
        let BFContent = [];
        let noneSelected = true;

        if (groupMstID === CONSTANTS.CS_MstBreastfeeding_ED) {
            BFContent = JSON.parse(JSON.stringify(this.state.exploreBFContent));

            for (let j = 0; j < BFContent.length; j++) {
                if (BFContent[j].mstID === mstID) {
                    BFContent[j].checked = checked;
                }
            }

            for (let k = 0; k < BFContent.length; k++) {
                if (BFContent[k].checked) {
                    noneSelected = false;
                }
            }

            for (let m = 0; m < this.props.IVInfo.length; m++) {
                if (this.state.selectedIVSet.includes(this.props.IVInfo[m].MstID)) {
                    noneSelected = false;
                }
            }

            this.setState({
                exploreBFContent : BFContent,
                buttonDisabled   : noneSelected
            })
        }
        else {
            selectedIVSet = JSON.parse(JSON.stringify(this.state.selectedIVSet));

            for (let i = 0; i < this.props.IVInfo.length; i++){
                if (this.props.IVInfo[i].MstID === mstID) {
                    mstID = this.props.IVInfo[i].MstID;

                    /* If checked and master ID not already added to selectedIVSet, add it. */
                    if (!selectedIVSet.includes(mstID)) {
                        if (checked) {
                            selectedIVSet.push(mstID);
                        }
                    }
                    /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                       *remove the master ID from the selectedIVSet. */
                    else if (!checked){
                        selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                    }

                    if (this.props.IVInfo[i]['RequiredIntervs'].intArray.length > 0) {
                        for (let k = 0; k < this.props.IVInfo[i]['RequiredIntervs'].intArray.length; k++) {
                            for (let j = 0; j < this.props.IVInfo.length; j++) {
                                if (this.props.IVInfo[j].MstID === this.props.IVInfo[i]['RequiredIntervs'].intArray[k]) {
                                    mstID = this.props.IVInfo[j].MstID;

                                    /* If checked and master ID not already added to selectedIVSet, add it. */
                                    if (!selectedIVSet.includes(mstID)) {
                                        if (checked) {
                                            selectedIVSet.push(mstID);
                                        }
                                    }
                                    /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                                       *remove the master ID from the selectedIVSet. */
                                    else if (!checked){
                                        selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            for (let n = 0; n < this.state.exploreBFContent.length; n++) {
                if (this.state.exploreBFContent[n].checked) {
                    noneSelected = false;
                }
            }

            noneSelected = selectedIVSet.length > 0;

            this.setState({
                selectedIVSet    : selectedIVSet,
                buttonDisabled   : !noneSelected,
                allInterv        : false
            })
        }
    };

    onAllIntervClick = (val) => {
        this.setState({
            allInterv: val,
            selectedIVSet: val ? this.props.IVInfo.map(x => x.MstID) : [],
            buttonDisabled: !val
        }, () => {
            //this.onAllBtnClick(val);
        });
    };

    onAllBtnClick = (value) => {

        let selectedIVSet = JSON.parse(JSON.stringify(this.state.selectedIVSet));
        let BFContent = JSON.parse(JSON.stringify(this.state.exploreBFContent));

        for (let i = 0; i < this.props.IVInfo.length; i++){
            switch (this.state.outcomeIdx) {
                case CONSTANTS.CS_MstMaternalMortalityExData : {
                    if(this.props.IVInfo[i][CONSTANTS.CS_MaternalEff_ED]) {
                        let mstID = this.props.IVInfo[i].MstID;
                        /* If checked and master ID not already added to selectedIVSet, add it. */
                        if (!selectedIVSet.includes(mstID)) {
                            if (value) {
                                selectedIVSet.push(mstID);
                            }
                        }
                        /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                           *remove the master ID from the selectedIVSet. */
                        else if (!value){
                            selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                        }
                    }
                    break;
                }
                case CONSTANTS.CS_MstStillbirthsExData       : {
                    if(this.props.IVInfo[i][CONSTANTS.CS_StillbirthEff_ED]) {
                        let mstID = this.props.IVInfo[i].MstID;
                        /* If checked and master ID not already added to selectedIVSet, add it. */
                        if (!selectedIVSet.includes(mstID)) {
                            if (value) {
                                selectedIVSet.push(mstID);
                            }
                        }
                        /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                           *remove the master ID from the selectedIVSet. */
                        else if (!value){
                            selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                        }
                    }
                    break;
                }
                case CONSTANTS.CS_MstNeonatalMortalityExData : {
                    if(this.props.IVInfo[i][CONSTANTS.CS_NNEff_ED]) {
                        let mstID = this.props.IVInfo[i].MstID;
                        /* If checked and master ID not already added to selectedIVSet, add it. */
                        if (!selectedIVSet.includes(mstID)) {
                            if (value) {
                                selectedIVSet.push(mstID);
                            }
                        }
                        /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                           *remove the master ID from the selectedIVSet. */
                        else if (!value){
                            selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                        }
                    }
                    break;
                }
                case CONSTANTS.CS_MstChildMortalityExData    : {
                    if(this.props.IVInfo[i][CONSTANTS.CS_PNNEff_ED]) {
                        let mstID = this.props.IVInfo[i].MstID;
                        /* If checked and master ID not already added to selectedIVSet, add it. */
                        if (!selectedIVSet.includes(mstID)) {
                            if (value) {
                                selectedIVSet.push(mstID);
                            }
                        }
                        /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                           *remove the master ID from the selectedIVSet. */
                        else if (!value){
                            selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                        }
                    }
                    break;
                }
                case CONSTANTS.CS_MstAdolescentsExData    : {
                    if(this.props.IVInfo[i]['GroupMstID'] === CONSTANTS.CS_GroupMstID_FamPlanHIV) {
                        let mstID = this.props.IVInfo[i].MstID;
                        /* If checked and master ID not already added to selectedIVSet, add it. */
                        if (!selectedIVSet.includes(mstID)) {
                            if (value) {
                                selectedIVSet.push(mstID);
                            }
                        }
                        /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                           *remove the master ID from the selectedIVSet. */
                        else if (!value){
                            selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                        }
                    }
                    break;
                }
                default : {break;}
            }
        }

        if ((this.state.outcomeIdx === CONSTANTS.CS_MstNeonatalMortality) || (this.state.outcomeIdx === CONSTANTS.CS_MstChildMortality)) {
            for (let j = 0; j < BFContent.length; j++) {
                BFContent[j].checked = value
            }
        }

        //special cases - SV
        if (value) {
            if (!selectedIVSet.includes(10)) {
                selectedIVSet.push(10);
            }
            if (!selectedIVSet.includes(37)) {
                selectedIVSet.push(37);
            }
            if (!selectedIVSet.includes(504)) {
                selectedIVSet.push(504);
            }
        } else {
            selectedIVSet.splice(selectedIVSet.indexOf(10), 1);
            selectedIVSet.splice(selectedIVSet.indexOf(37), 1);
            selectedIVSet.splice(selectedIVSet.indexOf(504), 1);
        }

        this.setState ({
            selectedIVSet    : selectedIVSet,
            exploreBFContent : BFContent,
            defaultExpanded  : true,
            buttonDisabled   : !value
        })
    };

    getNumberCoverageInterventionsSelected = (selectedIVSet) => {
        let numberInterventionsSelected = 0;

        let FamPlanHIVGroupRecord = getIVGroupRecFromMstID(this.props.IVGroupInfo, CONSTANTS.CS_GroupMstID_FamPlanHIV);
        for (let i = 0; i < this.props.IVInfo.length; i++) {
            if (selectedIVSet.includes(this.props.IVInfo[i].MstID)) {
                if (!DirectEntryMstIDSet().includes(this.props.IVInfo[i].MstID) &&
                    (FamPlanHIVGroupRecord["MstID"] !== this.props.IVInfo[i]["GroupMstID"])){
                    numberInterventionsSelected++;
                }
            }
        }

        return numberInterventionsSelected;
    };

    getNumberFamPlanInterventionsSelected = (selectedIVSet) => {
        let numberInterventionsSelected = 0;
    
        for (let i = 0; i < this.props.IVInfo.length; i++) {
            let IVRec = this.props.IVInfo[i];
            if ([CONSTANTS.CS_MstPercMarried,
                 CONSTANTS.CS_MstPercUsingContra,
                 CONSTANTS.CS_MstContraMethMix,
                 CONSTANTS.CS_MstPercUnintendedPregTermByAbor].includes(IVRec.MstID)) {
                    if (selectedIVSet.includes(IVRec.MstID)) {
                        numberInterventionsSelected++;
                    }
            }
        }
    
        return (
            numberInterventionsSelected
        )
    };

    // getNumberHIVInterventionsSelected = (selectedIVSet) => {
    //     let numberInterventionsSelected = 0;
    
    //     for (let i = 0; i < this.props.IVInfo.length; i++) {
    //         let IVRec = this.props.IVInfo[i];
    //         if ([CONSTANTS.IV_MstCotrimoxazole,
    //              CONSTANTS.IV_MstART,
    //              CONSTANTS.IV_MstPMTCT].includes(IVRec.MstID)) {
    //                 if (selectedIVSet.includes(IVRec.MstID)) {
    //                     numberInterventionsSelected++;
    //                 }
    //         }
    //     }
    
    //     return (
    //         numberInterventionsSelected
    //     )
    // };

    // getHIVAdolIntervsSelected = (selectedIVSet, adolescentsSelected) => {
    //     let numberHIVAdolIntervsSelected = 0;
    
    //     if (adolescentsSelected) {
    //         for (let i = 0; i < this.props.IVInfo.length; i++) {
    //             let IVRec = this.props.IVInfo[i];
    //             if ([CONSTANTS.CS_MstPercUsingCondWNonMarContacts,
    //                 CONSTANTS.CS_MstPercUsingPrEP,
    //                 CONSTANTS.CS_MstPercOnART,
    //                 CONSTANTS.CS_MstPercMalesCircum,
    //                 CONSTANTS.CS_MstPercReceivCompSexEd,
    //                 CONSTANTS.CS_MstPercFemalesReceivEconStrength].includes(IVRec.MstID)) {
    //                 if (selectedIVSet.includes(IVRec.MstID)) {
    //                     if (IVRec[CONSTANTS.CS_AdolEff_ED]) {
    //                         numberHIVAdolIntervsSelected++;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    
    //     return numberHIVAdolIntervsSelected > 0;
    // };

    onShowDataBtnClick = () => {
        let newData = [];

        let selectedIVSet = JSON.parse(JSON.stringify(this.state.selectedIVSet));

        let NumberCoverageInterventionsSelected = this.getNumberCoverageInterventionsSelected(selectedIVSet);
        let NumberFamPlanInterventionsSelected = this.getNumberFamPlanInterventionsSelected(selectedIVSet);
        // let NumberHIVInterventionsSelected = this.getNumberHIVInterventionsSelected(selectedIVSet);

        if (this.state.outcomeIdx === CONSTANTS.CS_MstAdolescentsExData){
            NumberCoverageInterventionsSelected = 0;
        }
        else{
            NumberFamPlanInterventionsSelected = 0;
        }

        if (NumberCoverageInterventionsSelected > 0) {
            newData.push({
                editorID             : CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID,
                mode                 : CONSTANTS.CS_ExploreInterventionCoverageMode,
                firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                hFirstYr             : CONSTANTS.CS_FirstYearHistoricalCountryData,
                hFinalYr             : CONSTANTS.CS_FinalYearHistoricalCountryData,
                possibleDisplayTypes : [CONSTANTS.CS_ScatterChart, CONSTANTS.CS_MultiChart, CONSTANTS.CS_TableChart],
                selectedDisplayType  : CONSTANTS.CS_TableChart,
                mstID                : CONSTANTS.CS_MstLiSTOnlineMixedCoverageSheetID,
                tabs                 : [],
                outcome              : this.state.outcomeIdx,
                selectedIVSet        : JSON.parse(JSON.stringify(this.state.selectedIVSet)),
            });
        }

        if (NumberFamPlanInterventionsSelected > 0) {
            newData.push({
                editorID             : CONSTANTS.FP_mstPrevSheetID,
                mode                 : CONSTANTS.CS_ExploreInterventionCoverageMode,
                firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                hFirstYr             : CONSTANTS.CS_FirstYearHistoricalCountryData,
                hFinalYr             : CONSTANTS.CS_FinalYearHistoricalCountryData,
                possibleDisplayTypes : [CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
                selectedDisplayType  : CONSTANTS.CS_ColumnChart,
                mstID                : CONSTANTS.FP_mstPrevSheetID,
                tabs                 : [],
                outcome              : this.state.outcomeIdx,
            });
        
            newData.push({
                editorID             : CONSTANTS.FP_mstMethMixSheetID,
                mode                 : CONSTANTS.CS_ExploreInterventionCoverageMode,
                firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                hFirstYr             : CONSTANTS.CS_FirstYearHistoricalCountryData,
                hFinalYr             : CONSTANTS.CS_FinalYearHistoricalCountryData,
                possibleDisplayTypes : [CONSTANTS.CS_ColumnChart, CONSTANTS.CS_TableChart],
                selectedDisplayType  : CONSTANTS.CS_ColumnChart,
                mstID                : CONSTANTS.FP_mstMethMixSheetID,
                tabs                 : [],
                outcome              : this.state.outcomeIdx,
            });
        
            newData.push({
                editorID             : CONSTANTS.FP_mstProxDetSheetID,
                mode                 : CONSTANTS.CS_ExploreInterventionCoverageMode,
                firstYear            : CONSTANTS.CS_FirstYearHistoricalCountryData,
                finalYear            : CONSTANTS.CS_FinalYearHistoricalCountryData,
                hFirstYr             : CONSTANTS.CS_FirstYearHistoricalCountryData,
                hFinalYr             : CONSTANTS.CS_FinalYearHistoricalCountryData,
                possibleDisplayTypes : [CONSTANTS.CS_TableChart],
                selectedDisplayType  : CONSTANTS.CS_TableChart,
                mstID                : CONSTANTS.FP_mstProxDetSheetID,
                tabs                 : [],
                outcome              : this.state.outcomeIdx,
            });
        }
        
        // if (NumberHIVInterventionsSelected > 0) {
        //     newData.push({
        //         editorID               : CONSTANTS.HV_mstCoverageSheetID,
        //         mode                   : CONSTANTS.CS_ExploreInterventionCoverageMode,
        //         firstYear              : CONSTANTS.CS_FirstYearHistoricalCountryData,
        //         finalYear              : CONSTANTS.CS_FinalYearHistoricalCountryData,
        //         hFirstYr               : CONSTANTS.CS_FirstYearHistoricalCountryData,
        //         hFinalYr               : CONSTANTS.CS_FinalYearHistoricalCountryData,
        //         possibleDisplayTypes   : [CONSTANTS.CS_TableChart],
        //         selectedDisplayType    : CONSTANTS.CS_TableChart,
        //         mstID                  : CONSTANTS.HV_mstCoverageSheetID,
        //         tabs                   : [],
        //         outcome                : this.state.outcomeIdx,
        //         HIVAdolIntervsSelected : this.getHIVAdolIntervsSelected(selectedIVSet, false),
        //     });
        // }

        this.props.onAddDisplay(newData);
        this.resetPanelToDefault();
    };

    resetPanelToDefault = () => {
        this.setState({
            BFContent        : this.state.exploreBFContent.map(x => ({ ...x, checked : false })),
            selectedIVSet    : [],
            buttonDisabled   : true,
            allInterv        : false
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderContent = () => {
        let content = [];

        content.push (
            <OutcomeDropdown
                key             = {'outcome'}
                outcomeIdx      = {this.state.outcomeIdx}
                onOutcomeChange = {this.onOutcomeChange}
                language        = {this.props.language}
                adol            = {true}
            />
        );

        content.push(
            <TCheckBox
                caption={RS("GB_stSelAllInterv")}
                value={this.state.allInterv}
                onClick={this.onAllIntervClick}
                style={{marginBottom: 20}}
                key={RS("GB_stSelAllInterv")}
            />
        );

        // content.push (
        //     <InterventionButtons
        //         onAllBtnClick = {this.onAllBtnClick}
        //         key           = {'buttons'}
        //     />
        // );

        content.push (
            <InterventionExpPanels
                key                      = {'interventions'}
                onCheckBoxChange         = {this.onCheckBoxChange}
                outcomeIdx               = {this.state.outcomeIdx}
                interventionSubGroupList = {this.state.interventionSubGroupList}
                interventionGroupList    = {this.state.interventionGroupList}
                selectedIVSet            = {this.state.selectedIVSet}
                IVInfo                   = {this.props.IVInfo}
                exploreBFContent         = {this.state.exploreBFContent}
                defaultExpanded          = {false}
                language                 = {this.props.language}
                onAddDisplay             = {this.props.onShowDataBtnClick}
            />
        );

        return (content)
    };

    render() {
        const styles = {
            wrapper : {
                padding: '0 0 10px 0',
                // margin: '10px 15px 10px 0',
                margin: '0 15px 0 0',
            },
            invisible : {
                position: 'absolute',
                left: -9999,
                visibility: 'hidden',

                /* Edge doesn't obey the css visibility correctly, go figure. Here's a hack. */
                height: 0,
                top: 0,
                overflow: 'hidden',
            },
            actions : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
        };

        let wrapperStyles = 
            this.props.visible ?
                styles.wrapper :
                {...styles.wrapper, ...styles.invisible}

        return (
            <div style={wrapperStyles}>
                {
                    this.renderContent()
                }

                <div style={styles.actions}>
                    <CreateDisplayButton
                        disabled={this.state.buttonDisabled}
                        onClick={this.onShowDataBtnClick}
                        language={this.props.language}
                    />
                </div>
                
            </div>
        );
    }

}

export default IntervCovOptions;