import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSHouseholdStatusDrawer from "../../drawers/HealthStatus/CSHouseholdStatusDrawer";
import {PerCapitaIncEditor} from "../../data/HealthStatus/HouseholdStatus/PerCapitaIncEditor";
import {AvgHouseholdSizeEditor} from "../../data/HealthStatus/HouseholdStatus/AvgHouseholdSizeEditor";
import {getModvarCloneByTag} from "utilities/GB/GBUtil";

// const editorID1 = CONSTANTS.CS_MstPerCapitaIncSheetID;
// const editorID2 = CONSTANTS.CS_MstAvgHouseholdSizeSheetID;

class CSHouseholdStatusBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        firstYear        : PropTypes.number,
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        firstYear        : -1,
        onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled : true,
        openDrawer      : false,
        packTable1      : null,
        packTable2      : null,
    };

    componentDidMount() {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        this.setState({
            packTable1 : PerCapitaIncEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackTable],
            packTable2 : AvgHouseholdSizeEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackTable],
        });
    }

    static getDerivedStateFromProps(props, state) {
        let CSModvars = props.CSModvars;
        let firstYear = props.firstYear;
        let finalYear = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        if (!state.openDrawer) {
            return {
                packTable1 : PerCapitaIncEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackTable],
                packTable2 : AvgHouseholdSizeEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackTable],
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true, 
        });
    };

    onCloseDrawerBtnClick = () => {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        this.setState({
            openDrawer      : false,
            saveBtnDisabled : true,
            packTable1      : PerCapitaIncEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackTable],
            packTable2      : AvgHouseholdSizeEditor.Create(CSModvars, firstYear, finalYear)[CONSTANTS.CS_PackTable],
        });
    };

    onSaveBtnClick = () => {
        let ModvarsArray1 = PerCapitaIncEditor.GetSSData(this.state.packTable1, this.props.CSModvars);
        let ModvarsArray2 = AvgHouseholdSizeEditor.GetSSData(this.state.packTable2, this.props.CSModvars);

        let changedCSModvars = this.props.CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            return a || b || x;
        });

        this.setState({
            openDrawer      : false,
            saveBtnDisabled : true,
        });

        this.props.onStateChange({
            unchangedProj: false,
            CSModvars: changedCSModvars,
        });
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2 
                caption     = {RS('GB_stHouseholdStatus')}
                description = ""  
                onClick     = {this.onBlueBoxClick}
                editContent = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"household_status.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stHouseholdStatus')}
                        content         = {
                                            <CSHouseholdStatusDrawer
                                                packTable1={this.state.packTable1}
                                                packTable2={this.state.packTable2}
                                                onPackTable1Change={this.onPackTable1Change}
                                                onPackTable2Change={this.onPackTable2Change}
                                            />
                                        }
                    />
                }
            />
        );
    }

}

export default CSHouseholdStatusBlueBox;