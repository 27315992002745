import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import RS from "@common/strings/RS";
 
class ShowDataSectionHeader extends React.PureComponent {

    static propTypes = {
        onCloseBtnClick : PropTypes.func,
        itemKey         : PropTypes.number,
    };

    static defaultProps = {
        onCloseBtnClick : () => console.log('onCloseBtnClick'),
        itemKey         : 0,
    };

    //shouldComponentUpdate(nextProps, nextState) {
    //    if (
    //        (JSON.stringify(this.props) !== JSON.stringify(nextProps)) || 
    //        (JSON.stringify(this.state) !== JSON.stringify(nextState))
    //    ) {
    //        return true;
    //    }
    //    else {
    //        return false;
    //    }
    //}

    onCloseBtnClick = () => {
        this.props.onCloseBtnClick(this.props.itemKey)
    };

    render() {
        const styles ={
            closeBtn : {
                padding: '6px',
                float: "right",
            },
        };

        return (
            <Tooltip title={RS('GB_stClose')}>
                <IconButton
                    onClick={this.onCloseBtnClick}
                    style={styles.closeBtn}
                >
                    <CloseIcon/>
                </IconButton>
            </Tooltip>
        );
    }
}

export default ShowDataSectionHeader;