import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Theme from '../../app/Theme';
import CSAppBarMenu from "../CS/CSAppBarMenu";

class AppBarHamburgerIcon extends React.PureComponent {

    static propTypes = {
        visible     : PropTypes.bool,
        onClick     : PropTypes.func,
        isDeskTop   : PropTypes.bool
    };

    static defaultProps = {
        visible     : true,
        onClick     : () => console.log('HamburgerIcon onClick'),
        isDeskTop   : true
    };

    render() {
        const styles = {
            hamburger : {
                marginLeft: -12,
                marginRight: 20,
            }
        }

        if (this.props.visible) {
            return (
                <IconButton 
                    aria-label="Menu" 
                    style={styles.hamburger} 
                    onClick={this.props.onClick}
                >
                    <MenuIcon />
                </IconButton>
            )
        }

        return null;
    }
}

class AppBarTitle extends React.PureComponent {

    static propTypes = {
        logo    : PropTypes.any,
        onClick : PropTypes.func,
    };

    static defaultProps = {
        logo    : null,
        onClick : () => console.log('AppBarTitle onClick'),
    };

    render() {
        const styles = {
            logo : {
                marginRight: this.props.isDeskTop ? 10 : 0, //'15px',
                cursor: 'pointer'
            },
        }

        return (
            <div 
                style={styles.logo} 
                onClick={this.props.onClick}
            >
                {this.props.logo}
            </div>
        );
    }
}

class AppBarTitle2 extends React.PureComponent {

    static propTypes = {
        caption : PropTypes.any,
        onClick : PropTypes.func,
        style   : PropTypes.object,
    };

    static defaultProps = {
        caption : null,
        onClick : () => console.log('AppBarTitle onClick'),
        style   : {},
    };

    render() {
        const styles = {
            title : {
                flex: 1,
                fontSize: '28px', 
                cursor: 'pointer',
                ...this.props.style
            },
        };

        return (
            <Typography 
                variant="h2" 
                color="inherit"
                style={styles.title} 
                onClick={this.props.onClick}
            >
                {this.props.caption}
            </Typography>
        );
    }
    
}

class TAppBar extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        logo               : PropTypes.any,
        caption            : PropTypes.any,
        showHamburger      : PropTypes.bool,
        onHamburgerClick   : PropTypes.func,
        hamburgerMenuOpen  : PropTypes.bool,
        onTitleClick       : PropTypes.func,
        position           : PropTypes.string,
        style              : PropTypes.object,
        children           : PropTypes.any,
        titleStyle         : PropTypes.object,
        menuThreshold      : PropTypes.bool,
        isMobile           : PropTypes.bool
    };

    static defaultProps = {
        logo               : null,                                          // <MenuIcon />
        caption            : '',
        showHamburger      : true,
        onTitleClick       : () => console.log('onTitleClick fired'),
        onHamburgerClick   : () => console.log('onHamburgerClick fired'),
        hamburgerMenuOpen  : false,
        position           : "absolute",                                    // static 
        style              : { background : '#508005' },                    // minHeight : '48px'
        children           : null,
        titleStyle         : {},
        menuThreshold      : false,
        isMobile           : false
    };

    shouldComponentUpdate(nextProps, nextState) {
        for (let key in nextProps){
            if (nextProps.hasOwnProperty(key)) {
               if (nextProps[key] !== this.props[key]) {
                   if (key === 'style') {
                       if (JSON.stringify(nextProps[key]) !== JSON.stringify(this.props[key])) {
                           return true;
                       }
                    }
                    else {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onHamburgerClick = () => {
        this.props.onHamburgerClick();
    };

    onTitleClick = () => {
        this.props.onTitleClick();
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        const styles = {
            appBar : {
                zIndex: Theme.zIndex.drawer + 1, 
                ...Theme.appBar, 
                ...this.props.style
            },
        };

        return (
            <AppBar 
                position={this.props.position} 
                style={styles.appBar}
            >

                <Toolbar>
                    <AppBarHamburgerIcon
                        visible={this.props.showHamburger}
                        onClick={this.onHamburgerClick}
                    />

                    <AppBarTitle 
                        onClick={this.onTitleClick}
                        logo={this.props.logo}
                        isDeskTop={this.props.isDeskTop}
                    />

                    <AppBarTitle2
                        caption={this.props.caption}
                        onClick={this.onTitleClick}
                        style={this.props.titleStyle}
                    />

                    {this.props.children}
                </Toolbar>

                {this.props.menuThreshold && !this.props.isMobile ?
                    <div>
                        <CSAppBarMenu
                            pageID={this.props.pageID}
                            appMode={this.props.appMode}
                            isGuest={this.props.isGuest}
                            projectCreated={this.props.projectCreated}
                            onExploreLiSTDataBtnClick={this.props.onExploreLiSTDataBtnClick}
                            onGuidedExplorationBtnClick={this.props.onGuidedExplorationBtnClick}
                            onExtractBtnClick={this.props.onExtractBtnClick}
                            onCreateProjBtnClick={this.props.onCreateProjBtnClick}
                            onPageChange={this.props.onPageChange}
                            display={"block"}
                            menuThreshold={this.props.menuThreshold}
                            isMobile={this.props.isMobile}
                        />
                    </div>
                    : null
                }

                {this.props.hamburgerMenuOpen && this.props.isMobile ?
                    <CSAppBarMenu
                        pageID={this.props.pageID}
                        appMode={this.props.appMode}
                        isGuest={this.props.isGuest}
                        projectCreated={this.props.projectCreated}
                        onExploreLiSTDataBtnClick={this.props.onExploreLiSTDataBtnClick}
                        onGuidedExplorationBtnClick={this.props.onGuidedExplorationBtnClick}
                        onExtractBtnClick={this.props.onExtractBtnClick}
                        onCreateProjBtnClick={this.props.onCreateProjBtnClick}
                        onPageChange={this.props.onPageChange}
                        display={"block"}
                        menuThreshold={this.props.menuThreshold}
                        isMobile={this.props.isMobile}
                    />
                    : null
                }
            </AppBar>
        );
    }
}

export default TAppBar;