import React from 'react';
import PropTypes from "prop-types";
import TComboBox from '@common/components/TComboBox';
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../components/CS/CSEditors';
import EditorNote from '../../../../components/EditorNote';

const styles = {
    comboBox: {
        marginBottom: 20,
        marginRight: 25,
        paddingLeft: 0,
        width: 100,
        display: 'inline-block'
    },
};

class CSEconStatusDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        packTable1                : PropTypes.object,
        packTable2                : PropTypes.object,
        packTable3                : PropTypes.object,
        onPackTable1Change        : PropTypes.func,
        onPackTable2Change        : PropTypes.func,
        onPackTable3Change        : PropTypes.func,
        updateFirstAgeAtWork      : PropTypes.func,
        updateLastAgeAtWork       : PropTypes.func,
        FirstAgeAtWork            : PropTypes.number,
        LastAgeAtWork             : PropTypes.number,
    };

    static defaultProps = {
        packTable1                : null,
        packTable2                : null,
        packTable3                : null,
        onPackTable1Change        : (packTable) => console.log('onPackTable1Change'),
        onPackTable2Change        : (packTable) => console.log('onPackTable2Change'),
        onPackTable3Change        : (packTable) => console.log('onPackTable3Change'),
        updateFirstAgeAtWork      : () => console.log('updateFirstAgeAtWork'),
        updateLastAgeAtWork       : () => console.log('updateLastAgeAtWork'),
        FirstAgeAtWork            : 0,
        LastAgeAtWork             : 0,
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onPackTable1Change = (packTable) => {
        this.props.onPackTable1Change(packTable);
    };

    onPackTable2Change = (packTable) => {
        this.props.onPackTable2Change(packTable);
    };

    onPackTable3Change = (packTable) => {
        this.props.onPackTable3Change(packTable);
    };

    onComboBox1Change = (value) => {
        this.props.updateFirstAgeAtWork(value + CONSTANTS.CS_MinFirstYrAtWork);
    };

    onComboBox2Change = (value) => {
        this.props.updateLastAgeAtWork(value + this.props.FirstAgeAtWork);
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderDropdown1 = () => {
        let FirstAges = [];

        for (let a = CONSTANTS.CS_MinFirstYrAtWork; a <= CONSTANTS.CS_MaxFinalYrAtWork; a++){
            FirstAges.push(a);
        }

        return (
            <TComboBox
                caption={'First age at work'}
                itemIndex={this.props.FirstAgeAtWork - CONSTANTS.CS_MinFirstYrAtWork}
                style={styles.comboBox}
                captionStyle={{color: '#609cd9'}}
                items={FirstAges}
                onChange={this.onComboBox1Change}
            />
        )
    };

    renderDropdown2 = () => {
        let itemIndex = 0;
        let LastAges = [];

        for (let a = this.props.FirstAgeAtWork; a <= CONSTANTS.CS_MaxFinalYrAtWork; a++) {
            LastAges.push(a);
            if (a === this.props.LastAgeAtWork) {
                itemIndex = a - this.props.FirstAgeAtWork;
            }
        }

        return (
            <TComboBox
                caption={'Last age at work'}
                itemIndex={itemIndex}
                style={styles.comboBox}
                captionStyle={{color: '#609cd9'}}
                items={LastAges}
                onChange={this.onComboBox2Change}
            />
        )
    };

    render() {
        let packTable1 = JSON.parse(JSON.stringify(this.props.packTable1));
        let packTable2 = JSON.parse(JSON.stringify(this.props.packTable2));
        let packTable3 = JSON.parse(JSON.stringify(this.props.packTable3));

        return (
            <div className="innerPage">

                <EditorNote
                    numberOfTables = {3}
                />

                {this.renderDropdown1()}
                {this.renderDropdown2()}

                <br />

                <CSEditor
                    mstID       = {CONSTANTS.CS_MstGNIPerCapSheetID}
                    packTable   = {packTable1}
                    onChange    = {this.onPackTable1Change}
                    showTitle   = {true}
                    readOnly    = {false}
                />

                <CSEditor
                    mstID       = {CONSTANTS.CS_MstLaborForceParRateSheetID}
                    packTable   = {packTable2}
                    onChange    = {this.onPackTable2Change}
                    showTitle   = {true}
                    readOnly    = {false}
                />

                <CSEditor
                    mstID       = {CONSTANTS.CS_MstLaborShareIncSheetID}
                    packTable   = {packTable3}
                    onChange    = {this.onPackTable3Change}
                    showTitle   = {true}
                    readOnly    = {false}
                />

            </div>
        );
    }

}

export default CSEconStatusDrawer;