import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
    getIdxFromYear,
    indentPackTable,
    lockPackTable,
    addMergedCellsToArray, 
    generatePackChart
} from "utilities/GB/GBUtil";

import { getSexName, CSGetMstIDName, getIntervRec, GetDPPMTCTValue, GetUpdatedARVRegimenValues } from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

import GB_CONSTANTS from 'utilities/GB/GBConst';
import { getModvarCloneByTag } from "../../../../utilities/GB/GBUtil";

const DP_Male = CONSTANTS.DP_Male;
const DP_Female = CONSTANTS.DP_Female;
const HA_15_24 = CONSTANTS.HA_15_24;
const HA_SexualityEducation = CONSTANTS.HA_SexualityEducation;
const HA_EconomicEmpowerment = CONSTANTS.HA_EconomicEmpowerment;
const HA_TGX_PrEPCoverage_MV = CONSTANTS.HA_TGX_PrEPCoverage_MV;
const HA_TGX_StructCoverages_MV = CONSTANTS.HA_TGX_StructCoverages_MV;
const HA_TGX_MaleCircumcisionByAge_MV = CONSTANTS.HA_TGX_MaleCircumcisionByAge_MV;
const HA_TGX_CondomsNonMaritalContacts_MV = CONSTANTS.HA_TGX_CondomsNonMaritalContacts_MV;

export const HVCoverageEditor = {
    Create : (IVInfo, CSModvars, language, projInfo, method, adolescence) => {
        /* Remove this line once adolescence is working: */
        adolescence = false;

        let EditorStartYear = projInfo.calcYear;
        let EditorEndYear = projInfo.finalYear;
        let editorName = '';//RS('GB_stPrEP');
        let packTable = Setup(IVInfo, CSModvars, language, method, EditorStartYear, EditorEndYear, adolescence);
        packTable = SetSSData(packTable, CSModvars, method, adolescence);
        let packChart = generatePackChart(packTable);
        packTable.Title = editorName;
        return ([packChart, packTable]);
        // packTable.ID = CONSTANTS.CS_MstLiSTOnlineBFPromoSheetID;
        // packTable.EditorCat = CONSTANTS.GB_Coverage;
    },

    GetSSData : (pt, CSModvars, method, adolescence) => {
        /* Remove this line once adolescence is working: */
        adolescence = false;

        let changedModvars = [];

        let PrEPCoverage = {};
        let StructCoverages = {};
        let MaleCircumcisionByAge = {};
        let CondomsNonMaritalContacts = {};

        if (adolescence) {
            PrEPCoverage = getModvarCloneByTag(CSModvars, HA_TGX_PrEPCoverage_MV);
            StructCoverages = getModvarCloneByTag(CSModvars, HA_TGX_StructCoverages_MV);
            MaleCircumcisionByAge = getModvarCloneByTag(CSModvars, HA_TGX_MaleCircumcisionByAge_MV);
            CondomsNonMaritalContacts = getModvarCloneByTag(CSModvars, HA_TGX_CondomsNonMaritalContacts_MV);
        }

        let ChildTreatInputs = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ChildTreatInputs_MV3);
        let HAARTBySex = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_HAARTBySex_MV);

        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++) {
            let t = pt.yearArr[yr];
            // = pt.tableData.value[1][col];
            ChildTreatInputs['vValue'][CONSTANTS.DP_PerChildHIVPosCot][t] = pt.tableData.value[2][col];
            if (!adolescence) {
                ChildTreatInputs['vValue'][CONSTANTS.DP_PerChildHIVRecART][t] = pt.tableData.value[3][col];
            }
            if (adolescence) {
                CondomsNonMaritalContacts['vValue'][t] = pt.tableData.value[3][col];

                PrEPCoverage['vValue'][DP_Female][HA_15_24][method][t] = pt.tableData.value[4][col];
                PrEPCoverage['vValue'][DP_Male][HA_15_24][method][t] = pt.tableData.value[5][col];

                HAARTBySex['vValue'][DP_Male][t] = pt.tableData.value[7][col];
                HAARTBySex['vValue'][DP_Female][t] = pt.tableData.value[8][col];
                ChildTreatInputs['vValue'][CONSTANTS.DP_PerChildHIVRecART][t] = pt.tableData.value[9][col];

                for (let i = 0; i < MaleCircumcisionByAge['vValue'].length; i++) {
                    if (MaleCircumcisionByAge['vValue'][i].age === CONSTANTS.HA_DP_A15_19) {
                        MaleCircumcisionByAge['vValue'][i]['yearVals'][t] = pt.tableData.value[10][col];
                    }
                }

                StructCoverages['vValue'][HA_SexualityEducation][DP_Female][HA_15_24][t] = pt.tableData.value[11][col];
                StructCoverages['vValue'][HA_EconomicEmpowerment][DP_Female][HA_15_24][t] = pt.tableData.value[12][col];
            }
            col++;
        }

        let ARVRegimen = GetUpdatedARVRegimenValues(CSModvars, pt.yearArr[0], pt.yearArr[pt.yearArr.length - 1],
            pt.tableData.value[1]);

        changedModvars.push(PrEPCoverage);
        changedModvars.push(StructCoverages);
        changedModvars.push(MaleCircumcisionByAge);
        changedModvars.push(CondomsNonMaritalContacts);
        changedModvars.push(ChildTreatInputs);
        changedModvars.push(HAARTBySex);
        changedModvars.push(ARVRegimen);

        return (changedModvars);
    },
};

const Setup = (IVInfo, CSModvars, language, method, EditorStartYear, EditorEndYear, adolescence) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    let pt = getNewPT();

    pt.yearArr = [];
    let numCols;

    numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
    for (let t = StartIndex; t <= EndIndex; t++) {
        pt.yearArr.push(t);
    }

    let numRows = 4;
    if (adolescence){
        numRows += 9;
    }

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++) {
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        col++;
    }

    pt.tableData.value[0][0] = RS('GB_stCoveragePerc');

    pt.tableData.value[1][0] = CSGetMstIDName(CSModvars, language, getIntervRec(IVInfo, CONSTANTS.IV_MstPMTCT));
    pt.tableData.value[2][0] = CSGetMstIDName(CSModvars, language, getIntervRec(IVInfo, CONSTANTS.IV_MstCotrimoxazole));

    let lastRow = 2;
    if (!adolescence) {
        pt.tableData.value[3][0] = RS('GB_stPercChildrenOnART');
        lastRow = 3;
    }

    for (let row = 1; row <= lastRow; row++) {
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
    }

    if (adolescence) {
        pt.tableData.value[3][0] = RS('GB_stPercUseCondomWNonMaritalContact');
        pt.tableData.value[4][0] = RS('GB_stPercAdolGirlsAndYWUsePrEP');
        pt.tableData.value[5][0] = RS('GB_stPercAdolBoysAndYMUsePrEP');

        pt.tableData.value[6][0] = RS('GB_stPercAdultsOnART');
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 6, 0, 1, numCols);
        pt = lockPackTable(pt, 6, true);

        pt.tableData.value[7][0] = getSexName(DP_Male);
        pt.tableData.value[8][0] = getSexName(DP_Female);

        for (let row = 7; row <= 8; row++){
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
        }

        pt.tableData.value[9][0] = RS('GB_stPercChildrenOnART');
        pt.tableData.value[10][0] = RS('GB_stPercMalesCircum');
        pt.tableData.value[11][0] = RS('GB_stPercReceivCompSexEdu');
        pt.tableData.value[12][0] = RS('GB_stPercFemReceivEconStrength');

        for (let row = 3; row <= 12; row++){
            pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        }
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 1;
            }
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars, method, adolescence) => {
    let PrEPCoverage = [];
    let StructCoverages = [];
    let MaleCircumcisionByAge = [];
    let CondomsNonMaritalContacts = [];

    if (adolescence) {
        PrEPCoverage = getModvarCloneByTag(CSModvars, HA_TGX_PrEPCoverage_MV)['vValue'];
        StructCoverages = getModvarCloneByTag(CSModvars, HA_TGX_StructCoverages_MV)['vValue'];
        MaleCircumcisionByAge = getModvarCloneByTag(CSModvars, HA_TGX_MaleCircumcisionByAge_MV)['vValue'];
        CondomsNonMaritalContacts = getModvarCloneByTag(CSModvars, HA_TGX_CondomsNonMaritalContacts_MV)['vValue'];
    }

    let ChildTreatInputs = getModvarByTag(CSModvars, CONSTANTS.DP_TGX_ChildTreatInputs_MV3)["vValue"];
    let HAARTBySex = getModvarByTag(CSModvars, CONSTANTS.DP_TGX_HAARTBySex_MV)["vValue"];

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++) {
        let t = pt.yearArr[yr];
        pt.tableData.value[1][col] = GetDPPMTCTValue(CSModvars, t);
        pt.tableData.value[2][col] = ChildTreatInputs[CONSTANTS.DP_PerChildHIVPosCot][t];
        if (!adolescence) {
            pt.tableData.value[3][col] = ChildTreatInputs[CONSTANTS.DP_PerChildHIVRecART][t];
        }
        if (adolescence) {
            pt.tableData.value[3][col] = CondomsNonMaritalContacts[t];

            pt.tableData.value[4][col] = PrEPCoverage[DP_Female][HA_15_24][method][t];
            pt.tableData.value[5][col] = PrEPCoverage[DP_Male][HA_15_24][method][t];

            pt.tableData.value[7][col] = HAARTBySex[DP_Male][t];
            pt.tableData.value[8][col] = HAARTBySex[DP_Female][t];
            pt.tableData.value[9][col] = ChildTreatInputs[CONSTANTS.DP_PerChildHIVRecART][t];

            for (let i = 0; i < MaleCircumcisionByAge.length; i++) {
                if (MaleCircumcisionByAge[i].age === CONSTANTS.HA_DP_A15_19) {
                    pt.tableData.value[10][col] = MaleCircumcisionByAge[i]['yearVals'][t];
                }
            }

            pt.tableData.value[11][col] = StructCoverages[HA_SexualityEducation][DP_Female][HA_15_24][t];
            pt.tableData.value[12][col] = StructCoverages[HA_EconomicEmpowerment][DP_Female][HA_15_24][t];
        }
        col++;
    }

    return pt;
};

