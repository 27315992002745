import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";

let appBarHeight = 64;
const appStatusBar = 0;

class TAppContent extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        sidebarOpen         : PropTypes.bool,
        pageID              : PropTypes.string,
        emptyStyle          : PropTypes.object,
        appMode             : PropTypes.string,
        menuThreshold       : PropTypes.bool,
        MOSideBarOpen       : PropTypes.bool,
    };

    static defaultProps = {
        sidebarOpen         : false,
        pageID              : "CSHomeForm", 
        emptyStyle          : { border: '1px solid black' },
        appMode             : "",
        menuThreshold       : false,
        MOSideBarOpen       : true
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        const isNoSideBarContent = 
            (this.props.pageID === 'CSHomeForm') || 
            (this.props.appMode === CONSTANTS.CS_MissedOpportunitiesMode) || 
            (this.props.appMode === CONSTANTS.CS_ExploreLiSTDataMode) ||
            (this.props.appMode === CONSTANTS.CS_ExploreLiSTDataModeStep1) ||
            (this.props.appMode === CONSTANTS.CS_ProjectionMode) ||
            (this.props.appMode === CONSTANTS.CS_ExtractMode);

        const left = 
            (isNoSideBarContent) ? 0 : 
                (this.props.sidebarOpen) ? ((this.props.pageID === 'CSHomeForm') ? 315 : 335) : 
                    40;

        // if (this.props.menuThreshold) {
            appBarHeight = this.props.menuThreshold ? 105 : 64;
        // }

        let styles = {
            page: {
                transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
                height: 'calc(100% - ' + appBarHeight + 'px - ' + appStatusBar + 'px)',
                overflowY: 'auto',
                position: 'absolute',
                right: 0,
                left: this.props.MOSideBarOpen ? left : 85, //(this.props.sidebarOpen) ? ((this.props.pageID === 'CSHomeForm') ? 315 : 335) : 40,
                top: appBarHeight
            },
        };

        if (typeof this.props.children === 'undefined') { 
            styles = { ...styles, page : { ...styles.page, ...this.props.emptyStyle } }
        }

        return (
            <div style={styles.page}>
                <div>
                    {
                        (this.props.children) ?
                            this.props.children :
                            <div>No children given</div>
                    }
                </div>
            </div>
        );
    }
}

export default TAppContent;