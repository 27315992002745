import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../../components/CS/CSEditors';

class CSImpactUnderNutrition extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        ImpactsAnemiaMatMortEditorPackTable               : PropTypes.object,
        ImpactsBirthOutcomesOnMortEditorPackTable         : PropTypes.object,
        ImpactsStuntOnMortEditorPackTable                 : PropTypes.object,
        ImpactsWasteOnMortEditorPackTable                 : PropTypes.object,
        ImpactsBFOnMortNNEditorPackTable                  : PropTypes.object,
        ImpactsBFOnMortPNNEditorPackTable                 : PropTypes.object,
        onImpactsAnemiaMatMortEditorPackTableChange       : PropTypes.func,
        onImpactsBirthOutcomesOnMortEditorPackTableChange : PropTypes.func,
        onImpactsStuntOnMortEditorPackTableChange         : PropTypes.func,
        onImpactsWasteOnMortEditorPackTableChange         : PropTypes.func,
        onImpactsBFOnMortNNEditorPackTableChange          : PropTypes.func,
        onImpactsBFOnMortPNNEditorPackTableChange         : PropTypes.func,
        underNutritionComboboxIdx                         : PropTypes.number,
        onUnderNutritionComboboxChange                    : PropTypes.func,
    };

    static defaultProps = {
        ImpactsAnemiaMatMortEditorPackTable               : null,
        ImpactsBirthOutcomesOnMortEditorPackTable         : null,
        ImpactsStuntOnMortEditorPackTable                 : null,
        ImpactsWasteOnMortEditorPackTable                 : null,
        ImpactsBFOnMortNNEditorPackTable                  : null,
        ImpactsBFOnMortPNNEditorPackTable                 : null,
        onImpactsAnemiaMatMortEditorPackTableChange       : (packTable) => console.log('onImpactsAnemiaMatMortEditorPackTableChange'),
        onImpactsBirthOutcomesOnMortEditorPackTableChange : (packTable) => console.log('onImpactsBirthOutcomesOnMortEditorPackTableChange'),
        onImpactsStuntOnMortEditorPackTableChange         : (packTable) => console.log('onImpactsStuntOnMortEditorPackTableChange'),
        onImpactsWasteOnMortEditorPackTableChange         : (packTable) => console.log('onImpactsWasteOnMortEditorPackTableChange'),
        onImpactsBFOnMortNNEditorPackTableChange          : (packTable) => console.log('onImpactsBFOnMortNNEditorPackTableChange'),
        onImpactsBFOnMortPNNEditorPackTableChange         : (packTable) => console.log('onImpactsBFOnMortPNNEditorPackTableChange'),
        underNutritionComboboxIdx                         : 0,
        onUnderNutritionComboboxChange                    : () => console.log('onUnderNutritionComboboxChange'),
    };

    state = {
        ImpactsAnemiaMatMortEditorPackTable       : JSON.parse(JSON.stringify(this.props.ImpactsAnemiaMatMortEditorPackTable)),
        ImpactsBirthOutcomesOnMortEditorPackTable : JSON.parse(JSON.stringify(this.props.ImpactsBirthOutcomesOnMortEditorPackTable)),
        ImpactsStuntOnMortEditorPackTable         : JSON.parse(JSON.stringify(this.props.ImpactsStuntOnMortEditorPackTable)),
        ImpactsWasteOnMortEditorPackTable         : JSON.parse(JSON.stringify(this.props.ImpactsWasteOnMortEditorPackTable)),
        ImpactsBFOnMortNNEditorPackTable          : JSON.parse(JSON.stringify(this.props.ImpactsBFOnMortNNEditorPackTable)),
        ImpactsBFOnMortPNNEditorPackTable         : JSON.parse(JSON.stringify(this.props.ImpactsBFOnMortPNNEditorPackTable)),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onComboboxChange = (value) => {
        this.props.onUnderNutritionComboboxChange(value);
    };

    //==================================================================================================================
    //
    //                                                 functions
    //
    //==================================================================================================================

    getImpactStuntingMortalityEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstRelRisksStuntingSheetID}
                packTable={this.state.ImpactsStuntOnMortEditorPackTable}
                showTitle={true}
                onChange={this.props.onImpactsStuntOnMortEditorPackTableChange}
            />
        )
    };

    getImpactWastingMortalityEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstRelRisksWastingSheetID}
                packTable={this.state.ImpactsWasteOnMortEditorPackTable}
                showTitle={true}
                onChange={this.props.onImpactsWasteOnMortEditorPackTableChange}
            />
        )
    };

    getImpactBOMortalityEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstRelRisksIUGR_LBWSheetID}
                packTable={this.state.ImpactsBirthOutcomesOnMortEditorPackTable}
                showTitle={true}
                onChange={this.props.onImpactsBirthOutcomesOnMortEditorPackTableChange}
            />
        )
    };

    getImpactBFMortalityEditors = () => {
        return (
            <div>
                <CSEditor
                    mstID={CONSTANTS.CS_MstImpactBFOnMortNNSheetID }
                    packTable={this.state.ImpactsBFOnMortNNEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onImpactsBFOnMortNNEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstImpactBFOnMortPNNSheetID}
                    packTable={this.state.ImpactsBFOnMortPNNEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onImpactsBFOnMortPNNEditorPackTableChange}
                />
            </div>
        )
    };

    getImpactAnemiaMortalityEditors = () => {
        return (
            <CSEditor
                mstID={CONSTANTS.CS_MstOddsRatioAnemiaSheetID}
                packTable={this.state.ImpactsAnemiaMatMortEditorPackTable}
                showTitle={true}
                onChange={this.props.onImpactsAnemiaMatMortEditorPackTableChange}
            />
        )
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    renderDropdown = () => {
        const styles = {
            comboBox: {
                marginTop: 0,
                marginBottom: 20,
                marginRight: 25,
                paddingLeft: 0,
                width: 325,
                display: 'inline-block'
            },
            captionStyle: {
                marginTop: 0,
                color: '#f78c30'
            }
        };

        let items = [];
        items.push(RS('GB_stImpactStuntingMortality'));
        items.push(RS('GB_stImpactWastingMortality'));
        items.push(RS('GB_stImpactBOMortality'));
        items.push(RS('GB_stImpactBFMortality'));
        items.push(RS('GB_stImpactAnemiaMortality'));

        return (
            <TComboBox
                caption      = ""
                itemIndex    = {this.props.underNutritionComboboxIdx}
                style        = {styles.comboBox}
                onChange     = {this.onComboboxChange}
                captionStyle = {styles.captionStyle}
                items        = {items}
            >
            </TComboBox>
        )
    };

    renderEditors = () => {
        let ImpactStuntingMortality = 0;
        let ImpactWastingMortality = 1;
        let ImpactBOMortality = 2;
        let ImpactBFMortality = 3;
        let ImpactAnemiaMortality = 4;

        let result = '';

        switch (this.props.underNutritionComboboxIdx) {
            case ImpactStuntingMortality : {result = this.getImpactStuntingMortalityEditors(); break;}
            case ImpactWastingMortality  : {result = this.getImpactWastingMortalityEditors(); break;}
            case ImpactBOMortality       : {result = this.getImpactBOMortalityEditors(); break;}
            case ImpactBFMortality       : {result = this.getImpactBFMortalityEditors(); break;}
            case ImpactAnemiaMortality   : {result = this.getImpactAnemiaMortalityEditors(); break;}
            default : break;
        }

        return (result)
    };

    render() {
        return (
            <div>
                {this.renderDropdown()}
                {this.renderEditors()}
            </div>
        );
    }

}

export default CSImpactUnderNutrition;