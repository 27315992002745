import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    indentPackTable,
    lockPackRowCol,
    addMergedCellsToArray,
    generatePackChart, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import {
    getAgeCohortName,
    ImpactsOnStuntingStr
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const ImpactsOnStuntingEditor = {
    Create : (CSModvars) => {
        let editorName;
        editorName = RS('GB_stImpactsStunting');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_NutritionInterventions;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let ImpactsOnStunting = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnStunting_MV);
        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
            for(let row = 2; row < pt.GBRowCount; row++){
                let i = pt.RowIds[row];
                if (i > 0) {
                    if (!pt.lockCells[row].includes(col)) {
                        ImpactsOnStunting["value"][CONSTANTS.CS_Data][i][CONSTANTS.CS_OddsRatio][a] = pt.tableData.value[row][col];
                        ImpactsOnStunting["source"][0][i] = pt.Source[pt.SourceMap[row]];
                    }
                }
            }
            col++;
        }

        changedModvars.push(ImpactsOnStunting);

        return (changedModvars);
    },
};

const Setup = () => {
    const numCols = 6;
    const numRows = 20;

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt = resizePackTable(pt, numRows, numCols);

    pt.GBFixedRows = 2;

    let col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        pt.tableData.value[0][col] = getAgeCohortName(a, true);
        pt.tableData.value[1][col] = RS('GB_stOddsRatio');
        col++;
    }

    let HeaderSet = [2, 7, 10, 15, 17];

    pt.lockCells = [
        [],
        [],
        [],
        [2, 3, 4, 5],
        [2, 3, 4, 5],
        [2, 3, 4, 5],
        [2, 3, 4, 5],
        [],
        [1],
        [1],
        [],
        [1, 2],
        [1, 2],
        [1, 2],
        [1, 2],
        [],
        [],
        [],
        [1, 2],
        [1, 2]
    ];

    pt.numSources = 0;

    let row = 2;
    for(let i = CONSTANTS.CS_ImpactsOnStuntingFirst; i < CONSTANTS.CS_ImpactsOnStuntingFinal; i++){
        if (HeaderSet.includes(row)){
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, 6);
            pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
            switch (row){
                case 2  : {pt.tableData.value[row][0] = RS('GB_stImpactBirthOutcomesStunt'); break;}
                case 7  : {pt.tableData.value[row][0] = RS('GB_stImpactPrevStunting'); break;}
                case 10 : {pt.tableData.value[row][0] = RS('GB_stImpactCompFeedStunt'); break;}
                case 15 : {pt.tableData.value[row][0] = RS('GB_stImpactDiarrhStunt'); break;}
                case 17 : {pt.tableData.value[row][0] = RS('GB_stImpactZincSuppStunt'); break;}
                default : break;
            }
            row++;
        }
        pt.tableData.value[row][0] = ImpactsOnStuntingStr(i);
        pt.numSources++;
        pt.SourceTitle[pt.numSources] = pt.tableData.value[row][0];
        pt.SourceMap[row] = pt.numSources;
        pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
        let col = 1;
        for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++) {
            pt.Alignments[row][col] = CONSTANTS.GB_Right;
            pt.RDec[row][col] = 3;
            pt.MaxAllowedVal[row][col] = 1000;
            if (pt.lockCells[row].includes(col)){
                pt = lockPackRowCol(pt, row, col, true);
            }
            col++;
        }
        pt.RowIds[row] = i;
        row++;
    }

    // pt.GBColWidths[0] = 500;
    // for (let k = 1; k < pt.GBColWidths.length; k++) {
    //     pt.GBColWidths[k] = 70;
    // }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let ImpactsOnStunting = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnStunting_MV)["value"][
        CONSTANTS.CS_Data];
    let ImpactsOnStuntingSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_ImpactsOnStunting_MV)["source"];
    let col = 1;
    for (let a = CONSTANTS.CS_0t1months; a <= CONSTANTS.CS_24t60months; a++){
        for(let row = 2; row < pt.GBRowCount; row++){
            let i = pt.RowIds[row];
            if (i > 0) {
                pt.tableData.value[row][col] = ImpactsOnStunting[i][CONSTANTS.CS_OddsRatio][a];
                pt.Source[pt.SourceMap[row]] = ImpactsOnStuntingSrc[0][i];
                if (pt.lockCells[row].includes(col)){
                    pt.tableData.value[row][col] = '';
                }
            }
        }
        col++;
    }

    return pt;
};

