import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    CloneObj, lockPackRowCol, generatePackChart
} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";

export const PMTCTEditor = {
    Create : (CSModvars) => {
        let editorName = ''; // RS('GB_stPMTCT');
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable.Title = editorName;
        packTable.ID = CONSTANTS.CS_MstSubNatPMTCTSheetID;
        let packChart = generatePackChart(packTable);
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);
        let PMTCT = getModvarByTag(CSModvarsClone, CONSTANTS.VW_TG_TPMTCT)["value"];
        PMTCT[1][CONSTANTS.VW_PrenatalProphylaxis] = pt.tableData.value[1][1];
        PMTCT[2][CONSTANTS.VW_PrenatalProphylaxis] = pt.tableData.value[1][2];
        PMTCT[1][CONSTANTS.VW_PostnatalProphylaxis] = pt.tableData.value[2][1];
        PMTCT[2][CONSTANTS.VW_PostnatalProphylaxis] = pt.tableData.value[2][2];
        return (CSModvarsClone);
    },
};

const Setup = () => {
    let pt = getNewPT();

    const numRows = 3;
    const numCols = 3;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[0][1] = RS('GB_stNational');
    pt.tableData.value[0][2] = RS('GB_stSubnational');

    pt.tableData.value[1][0] = RS('GB_stPrenatalProphylaxis');
    pt.tableData.value[2][0] = RS('GB_stPostnatalProphylaxis');

    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.Alignments[1][2] = CONSTANTS.GB_Right;
    pt.Alignments[2][1] = CONSTANTS.GB_Right;
    pt.Alignments[2][2] = CONSTANTS.GB_Right;

    pt.RDec[1][1] = 2;
    pt.RDec[1][2] = 2;
    pt.RDec[2][1] = 2;
    pt.RDec[2][2] = 2;

    pt = lockPackRowCol(pt, 1, 1, true);
    pt = lockPackRowCol(pt, 2, 1, true);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let PMTCT = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TPMTCT)["value"];
    pt.tableData.value[1][1] = PMTCT[1][CONSTANTS.VW_PrenatalProphylaxis];
    pt.tableData.value[1][2] = PMTCT[2][CONSTANTS.VW_PrenatalProphylaxis];
    pt.tableData.value[2][1] = PMTCT[1][CONSTANTS.VW_PostnatalProphylaxis];
    pt.tableData.value[2][2] = PMTCT[2][CONSTANTS.VW_PostnatalProphylaxis];
    return pt;
};

