import React from "react";
import * as PropTypes from "prop-types";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const createStyles = props => ({
  container: {
    width: props.width
  }
});

const TScatterPlot = props => {
  const styles = createStyles(props);

  const { firstYear, finalYear } = props.series[0];

  const categories = Array.from(
    {
      length: finalYear - firstYear + 1
    },
    (_, i) => firstYear + i
  );

  const options = {
    chart: {
      type: "scatter"
    },
    title: {
      text: props.title,
      useHTML: true,
      style: {
        whiteSpace: "nowrap",
        fontSize: `${props.titleFontSize}px`,
        fontWeight: props.titleFontWeight,
      }
    },
    xAxis: {
      categories,
      startOnTick: true,
      endOnTick: true,
      tickInterval: finalYear - firstYear
    },
    yAxis: {
      title: {
        text: props.yAxisTitle
      },
      min: props.yAxisMinimum,
      max: props.yAxisMaximum
    },
    series: props.series,
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      formatter: function(){
        return (
            '<b>'+ this.series.name +'</b><br/>' +
            'Year: ' + Math.abs(props.series[0].firstYear + this.point.x) +'<br/>' +
            'Percent: ' + Math.abs((this.point.y)).toFixed(props.series[0].rDec[0]) + '%'
        );
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    }
  };

  return (
    <div style={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

TScatterPlot.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.number,
  titleFontWeight: PropTypes.string,
  yAxisTitle: PropTypes.string.isRequired,
  yAxisMinimum: PropTypes.number,
  yAxisMaximum: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      firstYear: PropTypes.number.isRequired,
      finalYear: PropTypes.number.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
      rDec: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  ).isRequired
};

TScatterPlot.defaultProps = {
  width: 400,
  titleFontSize: 12,
  titleFontWeight: 'bold',
  yAxisMinimum: 0
};

export default TScatterPlot;
