import RS from "@common/strings/RS";
import {
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    addRowToPackTable, getModvarByTag
} from "utilities/GB/GBUtil";

import {GetPop, GetDPGB_AgeName, DPSplitPop} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const FirstYearPopEditor = {
    Create : (CSModvars) => {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;
        let editorName = RS('GB_stFYP') + ' (' + BaseYear + ')';
        let packTable = Setup();
        packTable = SetSSData(packTable, CSModvars);
        packTable = FirstYearPopEditor.RecalcGrid(packTable);
        packTable.Title = editorName;
        return (packTable);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let FiveYearPop = new Array(CONSTANTS.DP_A80_Up + 1);

        for (let i = 0; i < FiveYearPop.length; i++) {
            FiveYearPop[i] = new Array(CONSTANTS.DP_Female + 1);
        }

        for (let a = CONSTANTS.DP_AllAges; a <= CONSTANTS.DP_A80_Up; a++) {
            for (let s = CONSTANTS.DP_BothSexes; s <= CONSTANTS.DP_Female; s++) {
                FiveYearPop[a][s] = 0;
            }
        }

        /* Set total population for each age group */
        for (let a = CONSTANTS.DP_A0_4; a <= CONSTANTS.DP_A80_Up; a++) {
            FiveYearPop[a][CONSTANTS.DP_Male] = pt.tableData.value[a][CONSTANTS.DP_Male];
            FiveYearPop[a][CONSTANTS.DP_Female] = pt.tableData.value[a][CONSTANTS.DP_Female];
        }

        /* Aggregate base year population by age */
        for (let a = 1; a <= CONSTANTS.DP_MAX_AGE; a++) {
            FiveYearPop[CONSTANTS.DP_AllAges][CONSTANTS.DP_Male] =
                FiveYearPop[CONSTANTS.DP_AllAges][CONSTANTS.DP_Male] +
                FiveYearPop[a][CONSTANTS.DP_Male];

            FiveYearPop[CONSTANTS.DP_AllAges][CONSTANTS.DP_Female] =
                FiveYearPop[CONSTANTS.DP_AllAges][CONSTANTS.DP_Female] +
                FiveYearPop[a][CONSTANTS.DP_Female];

            FiveYearPop[a][CONSTANTS.DP_BothSexes] =
                FiveYearPop[a][CONSTANTS.DP_Male] +
                FiveYearPop[a][CONSTANTS.DP_Female];
        }

        FiveYearPop[CONSTANTS.DP_AllAges][CONSTANTS.DP_BothSexes] =
            FiveYearPop[CONSTANTS.DP_AllAges][CONSTANTS.DP_Male] +
            FiveYearPop[CONSTANTS.DP_AllAges][CONSTANTS.DP_Female];

        changedModvars.push(DPSplitPop(CSModvars, FiveYearPop));
        return (changedModvars);
    },

    RecalcGrid : (pt) => {
        for (let sex = CONSTANTS.DP_Male; sex <= CONSTANTS.DP_Female; sex++) {
            pt.tableData.value[CONSTANTS.DP_A80_Up+1][sex] = 0;
            for (let age = CONSTANTS.DP_A0_4; age <= CONSTANTS.DP_A80_Up; age++) {
                pt.tableData.value[CONSTANTS.DP_A80_Up+1][sex] += pt.tableData.value[age][sex];
            }
        }

        return (pt);
    },
};

const Setup = () => {
    let pt = getNewPT();

    pt = resizePackTable(pt, 1, 3);

    pt.tableData.value[0][0] = RS('GB_stAge');
    pt.tableData.value[0][1] = RS('GB_stMale');
    pt.tableData.value[0][2] = RS('GB_stFemale');

    for (let age = CONSTANTS.DP_A0_4; age <= CONSTANTS.DP_A80_Up + 1; age++) {
        pt = addRowToPackTable(pt);
        pt.tableData.value[age][0] = GetDPGB_AgeName(age);
        for (let sex = CONSTANTS.DP_Male; sex <= CONSTANTS.DP_Female; sex++) {
            pt.Alignments[age][sex] = CONSTANTS.GB_Right;
            pt.MaxAllowedVal[age][sex] = 999999999;
        }
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let BYIndex = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    for (let age = CONSTANTS.DP_A0_4; age <= CONSTANTS.DP_A80_Up; age++) {
        for (let sex = CONSTANTS.DP_Male; sex <= CONSTANTS.DP_Female; sex++) {
            pt.tableData.value[age][sex] = GetPop(CSModvars, BYIndex, sex, age);
            pt.tableData.value[CONSTANTS.DP_A80_Up+1][sex] += pt.tableData.value[age][sex];
        }
    }
    return pt;
};

