import React from 'react';
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import TLabel from '@common/components/TLabel';
import TListItem from "@common/components/TListItem";
import RS from "@common/strings/RS";

class CSHomeListSideBar extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onCreateProjBtnClick        : PropTypes.func,
        onGuidedExplorationBtnClick : PropTypes.func,
        onExploreLiSTDataBtnClick   : PropTypes.func,
    };

    static defaultProps = {
        onCreateProjBtnClick        : () => console.log('onCreateProjBtnClick'),
        onGuidedExplorationBtnClick : () => console.log('onGuidedExplorationBtnClick'),
        onExploreLiSTDataBtnClick   : () => console.log('onExploreLiSTDataBtnClick'),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onLivesSavedToolLinkClick = () => {
        window.open("http://livessavedtool.org/", "_blank");
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderUseLiSTTodayLabel = () => {
        return (
            <TLabel
                caption={RS('GB_stWhatWouldYouLike')}
                style={{color: '#FFFFFF', marginTop: '5px', marginLeft: '24px', marginBottom: '10px', fontSize: '16px', cursor: 'default'}}
            />
        );
    };

    renderExploreLiSTData = () => {

        const styles = {
            innerListItem: {
                color: '#95c8fc',
                fontSize: '16px',
                marginLeft: '10px'
            }
        };

        return (
            <TListItem
                primaryStyle={styles.innerListItem}
                caption={RS('GB_stExploreLiSTData')}
                onClick={this.props.onExploreLiSTDataBtnClick}
            />
        );
    };

    renderGuidedExploration = () => {

        const styles = {
            innerListItem: {
                color: '#95c8fc',
                fontSize: '16px',
                marginLeft: '10px'
            }
        };
        
        return (
            <TListItem
                primaryStyle={styles.innerListItem}
                caption={RS('GB_stMissedOpportunities')}
                onClick={this.props.onGuidedExplorationBtnClick}
            />
        );
    };

    renderCreateProjection = () => {

        const styles = {
            innerListItem: {
                color: '#95c8fc',
                fontSize: '16px',
                marginLeft: '10px'
            }
        };

        return (
            <TListItem
                primaryStyle={styles.innerListItem}
                caption={RS('GB_stProjection')}
                onClick={this.props.onCreateProjBtnClick}
            />
        );
    };

    renderLivesSavedTool = () => {

        let styles = {
            headerItem2: {
                textAlign: 'left',
                fontSize: '16px',
                color: '#95c8fc',
            },
        };

        return (
            <TListItem
                caption={RS('GB_stReturnToLivesSavedTool')}
                primaryStyle={styles.headerItem2}
                onClick={this.onLivesSavedToolLinkClick}
            />
        );
    };

    render() {
        const styles = {
            divider: {
                backgroundColor: '#FFFFFF',
                height: '1px',
                marginTop: '20px'
            },
            muiList: {
                paddingTop: 0,
                paddingBottom: 0
            },
        };

        return (
            <List dense={false} style={styles.muiList} key={0}>
                {this.renderUseLiSTTodayLabel()}
                {this.renderExploreLiSTData()}
                {this.renderGuidedExploration()}
                {this.renderCreateProjection()}

                <Divider style={styles.divider}/>
                <br />
                {this.renderLivesSavedTool()}
            </List>
        );
    }

}

export default CSHomeListSideBar;