import * as React from "react";
import PropTypes from "prop-types";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';

class TComboBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        caption      : PropTypes.string,
        items        : PropTypes.array,
        itemIndex    : PropTypes.number,
        selectedText : PropTypes.string,
        width        : PropTypes.string,
        display      : PropTypes.string,
        onChange     : PropTypes.func,
        style        : PropTypes.object,
        captionStyle : PropTypes.object,
        menuProps    : PropTypes.object,
        disabled     : PropTypes.bool,
        native       : PropTypes.bool,
    };

    static defaultProps = {
        caption      : 'Caption',  
        items        : ['Item 1', 'Item 2', 'Item 3'],
        itemIndex    : 0, 
        selectedText : '',
        width        : '250px',
        display      : 'inline-block',
        onChange     : (value) => console.log('TComboBox onChange [' + value + ']'),
        style        : {},
        captionStyle : {},
        menuProps    : {},
        disabled     : false,
        native       : false,
    };

    state = {
        itemIndex : 0
    };

    //==================================================================================================================
    //
    //                                                 Events
    //
    //==================================================================================================================

    onChange = (event) => {
        this.props.onChange(event.target.value, this.props.items[event.target.value]);
    };

    onNativeChange = (event) => {
        let value = parseInt(event.target.value, 10);
        this.props.onChange(value, this.props.items[value]);
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    renderItems = () => {
        const items = [];

        for (let i = 0; i <= this.props.items.length-1; i++) {
            if (typeof this.props.items[i] === 'object') {
                Object.entries(this.props.items[i]).forEach(([key, value]) => {
                    items.push(
                        <MenuItem value={i} key={i} style={{backgroundColor: value}}>{this.props.items[i][key]}</MenuItem>
                    );
                });
            }
            else {
                items.push(
                    <MenuItem value={i} key={i}>{this.props.items[i]}</MenuItem>
                );
            }
        }

        return items;
    };

    renderNativeItems = () => {
        const items = [];

        for (let i = 0; i <= this.props.items.length-1; i++) {
            if (typeof this.props.items[i] === 'object') {
                Object.entries(this.props.items[i]).forEach(([key, value]) => {
                    items.push(
                        <option value={i} key={i} style={{backgroundColor: value}}>{this.props.items[i][key]}</option>
                    );
                });
            }
            else {
                items.push(
                    <option value={i} key={i}>{this.props.items[i]}</option>
                );
            }
        }

        return items;
    };

    getItemIndex = (selectedText) => {
        for (let i = 0; i <= this.props.items.length-1; i++) {
            if (this.props.selectedText === this.props.items[i]) {
                this.setState({
                    itemIndex : i
                });
                return;
            }
        }
    };

    render() {

        const selectFieldStyle = {
            //marginTop: 10,
            marginRight: 25,
            paddingLeft: 0,
            width : this.props.width,
            //marginBottom: 30
        };

        return (
            <FormControl style={{display: this.props.display}} disabled={this.props.disabled}>
                <InputLabel style={{...this.props.captionStyle}} htmlFor="name-native-disabled">{this.props.caption}</InputLabel>

                {
                    this.props.native ?
                        <NativeSelect
                            value={this.props.itemIndex}
                            onChange={this.onNativeChange}
                            style={{...selectFieldStyle, ...this.props.style}}
                            input={<Input />}
                        >
                            {this.renderNativeItems()}
                        </ NativeSelect>
                        :
                        <Select
                            value={this.props.itemIndex}
                            onChange={this.onChange}
                            style={{...selectFieldStyle, ...this.props.style}}
                            MenuProps={this.props.menuProps}
                        >
                            {this.renderItems()}
                        </Select>
                }
            </FormControl>
        );
    }
}

export default TComboBox;