import React from "react";
import TButton from "@common/components/TButton";
import RS from "@common/strings/global";

const LoginButton = (props) => {
    const { onClick } = props;

    return (
        <TButton
            caption={RS("GB_stLogIn")}
            onClick={onClick}
            containerStyle={{ marginTop: 10 }}
            captionStyles={{ fontSize: 16 }}
        />
    )
};

export default LoginButton;