import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import GBStdChart from '../../../components/GB/GBStdChart';
import TCSResultCard from './TCSResultCard';
import CONSTANTS from "utilities/CS/CSConst";
import {getAgeCohortName, getChildCODName, getMatCODName, getSBCODName, getSexName} from "utilities/CS/CSUtil";
// import { clone } from "utilities/GB/GBUtil";

class TCSDsCard extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange       : PropTypes.func,
        cardTitle           : PropTypes.string,
        chartID             : PropTypes.string,
        packChart           : PropTypes.object,
        tableID             : PropTypes.string,
        packTable           : PropTypes.object,
        chartType           : PropTypes.string,
        modID               : PropTypes.number,
        resultID            : PropTypes.number,
        selectedResults     : PropTypes.array,
        cardTitleBackground : PropTypes.string,
        chartOptions        : PropTypes.arrayOf(PropTypes.object),
        isDeskTop           : PropTypes.bool,
        firstYear           : PropTypes.number,
        finalYear           : PropTypes.number,
        chartYears          : PropTypes.object,
        stacked             : PropTypes.string,
        legendReversed      : PropTypes.bool,
        displayCoverage     : PropTypes.bool,
        retroYears          : PropTypes.bool,
        displayEndpoints    : PropTypes.bool,
        IVInfo              : PropTypes.array,
        IVGroupInfo         : PropTypes.array,
        CSModvars           : PropTypes.array,
        projectionName      : PropTypes.string,
        coverageDisplay     : PropTypes.string,
        language            : PropTypes.number,
        resultCats          : PropTypes.array,
        onOpenHelpDialog    : PropTypes.func
    };

    static defaultProps = {
        onStateChange       : () => console.log("onStateChange"),
        cardTitle           : 'Caption',
        chartID             : "Chart1",
        packChart           : undefined, //{ chartType : 'line', chartData : [1, 2, 3], subsetLabels: ['Label 1', 'Label 2', 'Label 3'], subsetColors: ['#222222', '#777777', '#BBBBBB'], subsetsToLegend: [1, 1, 1, 1, 1] },
        tableID             : 'Table1',
        packTable           : undefined,
        chartType           : 'line',
        modID               : 0,
        resultID            : 0,
        selectedResults     : [],
        cardTitleBackground : '#9cc025', //'#efefef' //'#ace6ac' //#ffd18b
        chartOptions        : [],
        isDeskTop           : true,
        firstYear           : new Date().getFullYear(),
        finalYear           : CONSTANTS.CS_DefaultFinalYear,
        chartYears          : {},
        stacked             : '',
        legendReversed      : false,
        displayCoverage     : false,
        retroYears          : false,
        displayEndpoints    : false,
        IVInfo              : [],
        IVGroupInfo         : [],
        CSModvars           : [],
        projectionName      : "",
        coverageDisplay     : CONSTANTS.CS_TableChart,
        language            : CONSTANTS.GB_English,
        resultCats          : [],
        onOpenHelpDialog    : () => console.log("onOpenHelpDialog")
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {
        let stacked = '';
        if (this.props.chartType === CONSTANTS.CS_BarChart || this.props.chartType === CONSTANTS.CS_ColumnChart) {
            stacked = 'normal'
        }

        let opts = this.props.chartOptions.find(x => ((x.modID === this.props.modID) && (x.resultID === this.props.resultID)));
        let config = [];
        if (opts.age.length > 0) {
            config.push(getAgeCohortName(opts.age[opts.ageIdx], opts.age.length > 3));
        }
        if (opts.cod.length > 0) {
            if (opts.codCat === CONSTANTS.CS_MstMaternalCODCat) {
                config.push(getMatCODName(opts.cod[opts.codIdx]));
            } else if (opts.codCat === CONSTANTS.CS_MstStillbirthCODCat) {
                config.push(getSBCODName(opts.cod[opts.codIdx]));
            } else if (opts.codCat === CONSTANTS.CS_MstNeonatalCODCat) {
                config.push(getChildCODName(opts.cod[opts.codIdx]));
            } else if (opts.codCat === CONSTANTS.CS_MstChildCODCat) {
                config.push(getChildCODName(opts.cod[opts.codIdx + (opts.ageIdx === CONSTANTS.CS_1t59months ? CONSTANTS.CS_FinalStdNNDeath : 0)]));
            }
        }
        if (opts.sex.length > 0) {
            config.push(getSexName(opts.sex[opts.sexIdx]));
        }

        let pc = JSON.parse(JSON.stringify(this.props.packChart));
        pc.title = config.join(", ");
        pc.alignTitle = "center";

        return (
            <TCSResultCard
                onStateChange       = {this.props.onStateChange}
                cardTitle           = {this.props.cardTitle}
                cardTitleBackground = {this.props.cardTitleBackground}
                cardContent         = {
                                         <GBStdChart
                                             id        = {this.props.chartID}
                                             packChart = {pc}
                                             minHeight = {228}
                                             maxHeight = {228}
                                             options   = {{
                                                             tooltip : {
                                                                 shared: false,
                                                                 formatter: undefined
                                                             },
                                                                title: {
                                                                    style: {
                                                                        color: "#333333",
                                                                        fontSize: 12
                                                                    }
                                                                }
                                                          }}
                                             showLegend = {false}
                                             chartType  = {this.props.chartType}
                                             stacked    = {this.props.cardTitle === RS("GB_stChildDeaths") ? '' : stacked}
                                         />
                                       }
                modID                = {this.props.modID}
                resultID             = {this.props.resultID}
                selectedResults      = {this.props.selectedResults}
                resultsContent       = {{packChart : this.props.packChart, packTable : this.props.packTable}}
                chartOptions         = {this.props.chartOptions}
                style                = {{}}
                isDeskTop            = {this.props.isDeskTop}
                firstYear            = {this.props.firstYear}
                finalYear            = {this.props.finalYear}
                chartYears           = {this.props.chartYears}
                stacked              = {this.props.stacked}
                legendReversed       = {this.props.legendReversed}
                displayEndpoints     = {this.props.displayEndpoints}
                displayCoverage      = {this.props.displayCoverage}
                retroYears           = {this.props.retroYears}
                IVInfo               = {this.props.IVInfo}
                IVGroupInfo          = {this.props.IVGroupInfo}
                CSModvars            = {this.props.CSModvars}
                projectionName       = {this.props.projectionName}
                coverageDisplay      = {this.props.coverageDisplay}
                language             = {this.props.language}
                resultCats           = {this.props.resultCats}
                onOpenHelpDialog     = {this.props.onOpenHelpDialog}
            />
        );
    }
}

export default TCSDsCard;


