import React from "react";
import * as PropTypes from "prop-types";
import Draggable from "react-draggable";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Theme from "../../app/Theme";

const styles = {
  closeButton: {
    cursor: "pointer",
    color: Theme.palette.primary.main//"#000000"
  },
  container: {
    backgroundColor: "white",
    width: "min-content",
    border: "1px solid #d3d3d3",
    position: "absolute",
    borderRadius: '5px',
    left: 0,
    top: 0,
    zIndex: 9999
  },
  document: {
    height: "calc(100% - 75px)"
  },
  footer: {
    // padding: "1rem",
    marginTop: '-22px',
    float: 'right',
    marginRight: '20px',
    fontSize : '16px'
  },
  header: {
    padding: "1rem ",
    cursor: "move",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontColor : '#CB6814',
    fontWeight: 'bold'
  }
};

const DraggableWindow = props => {
  const anchorBounds =
    props.anchorRef && props.anchorRef.getBoundingClientRect && props.anchorRef.getBoundingClientRect();

  const defaultPosition = anchorBounds && {
    x: anchorBounds.left - props.width + props.defaultPositionOffset.x,
    y: anchorBounds.top + anchorBounds.height + props.defaultPositionOffset.y
  };

  return (
    <div>
      {props.open && (
        <Draggable
          bounds="body"
          defaultPosition={defaultPosition}
          onStart={props.onDragStart}
          onStop={props.onDragStop}>
          <div
            style={{
              ...styles.container,
              width: props.width,
              height: props.height
            }}
          >
            <div style={styles.header}>
              <Typography variant="h5" component="h2">
                {props.title}
              </Typography>
              <div>
                <CloseIcon style={styles.closeButton} onClick={props.onClose} />
              </div>
            </div>
            <div style={styles.document}>{props.children}</div>
            <div style={styles.footer}>{props.footer}</div>
          </div>
        </Draggable>
      )}
    </div>
  );
};

DraggableWindow.propTypes = {
  anchorRef: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  footer: PropTypes.element,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultPositionOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  onDragStart: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

DraggableWindow.defaultProps = {
  open: false,
  height: 600,
  width: 480,
  defaultPositionOffset: {
    x: -4,
    y: 4
  }
};

export default DraggableWindow;
