import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSEffectivenessDrawer from "../../drawers/HealthStatus/CSEffectivenessDrawer";
import {DiarrIncEditor} from "../../data/HealthStatus/Effectiveness/EffOnIncidence/Diarrhea/DiarrIncEditor"
import {RelRiskBFDiarrEditor} from "../../data/HealthStatus/Effectiveness/EffOnIncidence/Diarrhea/RelRiskBFDiarrEditor"
import {MeninIncEditor} from "../../data/HealthStatus/Effectiveness/EffOnIncidence/Mening/MeninIncEditor";
import {RelRiskBFMeningEditor} from "../../data/HealthStatus/Effectiveness/EffOnIncidence/Mening/RelRiskBFMeningEditor";
import {PneumIncEditor} from "../../data/HealthStatus/Effectiveness/EffOnIncidence/Pneum/PneumIncEditor";
import {RelRiskBFPneumEditor} from "../../data/HealthStatus/Effectiveness/EffOnIncidence/Pneum/RelRiskBFPneumEditor";
import {HerdEffectivenessEditor} from "../../data/HealthStatus/Effectiveness/HerdEffectivenessEditor";
import {ImpactPromoAgeAppropriateBFEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBF/ImpactPromoAgeAppropriateBFEditor";
import {ImpactPromoEarlyInitBFEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBF/ImpactPromoEarlyInitBFEditor";
import {BirthIntervalsEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/BirthIntervalsEditor";
import {MatAgeAndBirthOrderEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/MatAgeAndBirthOrderEditor";
import {MaternalNutritionEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnBirthOutcomes/MaternalNutritionEditor";
import {ImpactsOnMaternalAnemiaEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnMaternalAnemiaEditor";
import {ImpactsOnWastingEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnWastingEditor";
import {ImpactsOnStuntingEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnStuntingEditor";
import {LowBirthWeightFactorsEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/LowBirthWeightFactorsEditor";
import {ReducInStuntEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnLifetimeEarning/ReducInStuntEditor";
import {EducAttainmentEditor} from "../../data/HealthStatus/Effectiveness/NutritionInterventions/ImpactsOnLifetimeEarning/EducAttainmentEditor";
import {ImpactsAnemiaMatMortEditor} from "../../data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsAnemiaMatMortEditor";
import {ImpactsBirthOutcomesOnMortEditor} from "../../data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBirthOutcomesOnMortEditor";
import {ImpactsStuntOnMortEditor} from "../../data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsStuntOnMortEditor";
import {ImpactsWasteOnMortEditor} from "../../data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsWasteOnMortEditor";
import {ImpactsBFOnMortNNEditor} from "../../data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBFOnMort/ImpactsBFOnMortNNEditor";
import {ImpactsBFOnMortPNNEditor} from "../../data/HealthStatus/Effectiveness/ImpactUnderNutrition/ImpactsBFOnMort/ImpactsBFOnMortPNNEditor";

import CONSTANTS from "utilities/CS/CSConst";
import {MatEfficacyEditor} from "../../data/Effectiveness/MatEfficacyEditor";
import {SBEfficacyEditor} from "../../data/Effectiveness/SBEfficacyEditor";
import {EfficacyEditor} from "../../data/Effectiveness/EfficacyEditor";

class CSEffectivenessBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange           : PropTypes.func,
        IVInfo                  : PropTypes.arrayOf(PropTypes.object),
        CSModvars               : PropTypes.arrayOf(PropTypes.object),
        firstYear               : PropTypes.number,
        language                : PropTypes.number,
        onOpenHelpDialog        : PropTypes.func,
        showAllInterventionsCB  : PropTypes.bool
    };

    static defaultProps = {
        onStateChange           : () => console.log('onStateChange'),
        IVInfo                  : [],
        CSModvars               : [],
        firstYear               : -1,
        language                : CONSTANTS.GB_English,
        onOpenHelpDialog        : () => console.log('onOpenHelpDialog'),
        showAllInterventionsCB  : false,
    };

    state = {
        saveBtnDisabled                : true,
        openDrawer                     : false,

        showHelp                       : true,
        helpFile                       : "effectiveness_of_interventions_maternal_full.htm",
        selIVHelpFile                  : "effectiveness_of_interventions_maternal_full.htm",

        DiarrIncEditorPackTable        : null,
        RelRiskBFDiarrEditorPackTable  : null,
        MeninIncEditorPackTable        : null,
        RelRiskBFMeningEditorPackTable : null,
        PneumIncEditorPackTable        : null,
        RelRiskBFPneumEditorPackTable  : null,
        EffOnIncidenceComboboxIdx      : 0,

        DPTHerdEditorPackTable          : null,
        HibHerdEditorPackTable          : null,
        MeaslesHerdEditorPackTable      : null,
        PneumoHerdEditorPackTable       : null,
        RotaHerdEditorPackTable         : null,
        MeningAHerdEditorPackTable      : null,
        BednetsHerdEditorPackTable      : null,
        MalariaHerdEditorPackTable      : null,
        herdComboboxIdx                 : 0,

        ImpactPromoAgeAppropriateBFEditorPackTable : null,
        ImpactPromoEarlyInitBFEditorPackTable      : null,
        BirthIntervalsEditorPackTable              : null,
        MatAgeAndBirthOrderEditorPackTable         : null,
        MaternalNutritionEditorPackTable           : null,
        ImpactsOnMaternalAnemiaEditorPackTable     : null,
        ImpactsOnWastingEditorPackTable            : null,
        ImpactsOnStuntingEditorPackTable           : null,
        LowBirthWeightFactorsEditorPackTable       : null,
        ReducInStuntEditorPackTable                : null,
        EducAttainmentEditorPackTable              : null,
        nutritionComboboxIdx                       : 0,

        ImpactsAnemiaMatMortEditorPackTable       : null,
        ImpactsBirthOutcomesOnMortEditorPackTable : null,
        ImpactsStuntOnMortEditorPackTable         : null,
        ImpactsWasteOnMortEditorPackTable         : null,
        ImpactsBFOnMortNNEditorPackTable          : null,
        ImpactsBFOnMortPNNEditorPackTable         : null,
        underNutritionComboboxIdx                 : 0,

        MatEfficacyEditorPackTable                : null,
        SBEfficacyEditorPackTable                 : null,
        NeoNatalEfficacyEditor                    : null,
        PostNatalEfficacyEditor                   : null,
        showAllInterventionsCB                    : false
    };

    componentDidMount() {
        this.setState({
            DiarrIncEditorPackTable         : DiarrIncEditor.Create(this.props.IVInfo, this.props.CSModvars)[CONSTANTS.CS_PackTable],
            RelRiskBFDiarrEditorPackTable   : RelRiskBFDiarrEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            MeninIncEditorPackTable         : MeninIncEditor.Create(this.props.IVInfo, this.props.CSModvars)[CONSTANTS.CS_PackTable],
            RelRiskBFMeningEditorPackTable  : RelRiskBFMeningEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            PneumIncEditorPackTable         : PneumIncEditor.Create(this.props.IVInfo, this.props.CSModvars)[CONSTANTS.CS_PackTable],
            RelRiskBFPneumEditorPackTable   : RelRiskBFPneumEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],

            DPTHerdEditorPackTable          : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_DPT_Herd)[CONSTANTS.CS_PackTable],
            HibHerdEditorPackTable          : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Hib_Herd)[CONSTANTS.CS_PackTable],
            MeaslesHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Measles_Herd)[CONSTANTS.CS_PackTable],
            PneumoHerdEditorPackTable       : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Pneumococcal_Herd)[CONSTANTS.CS_PackTable],
            RotaHerdEditorPackTable         : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Rotavirus_Herd)[CONSTANTS.CS_PackTable],
            MeningAHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_MeningococcalA_Herd)[CONSTANTS.CS_PackTable],
            BednetsHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Bednets_Herd)[CONSTANTS.CS_PackTable],
            MalariaHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Malaria_Herd)[CONSTANTS.CS_PackTable],

            ImpactPromoAgeAppropriateBFEditorPackTable : ImpactPromoAgeAppropriateBFEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactPromoEarlyInitBFEditorPackTable      : ImpactPromoEarlyInitBFEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            BirthIntervalsEditorPackTable              : BirthIntervalsEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            MatAgeAndBirthOrderEditorPackTable         : MatAgeAndBirthOrderEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            MaternalNutritionEditorPackTable           : MaternalNutritionEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language)[CONSTANTS.CS_PackTable],
            ImpactsOnMaternalAnemiaEditorPackTable     : ImpactsOnMaternalAnemiaEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language)[CONSTANTS.CS_PackTable],
            ImpactsOnWastingEditorPackTable            : ImpactsOnWastingEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsOnStuntingEditorPackTable           : ImpactsOnStuntingEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            LowBirthWeightFactorsEditorPackTable       : LowBirthWeightFactorsEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ReducInStuntEditorPackTable                : ReducInStuntEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            EducAttainmentEditorPackTable              : EducAttainmentEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],

            ImpactsAnemiaMatMortEditorPackTable       : ImpactsAnemiaMatMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsBirthOutcomesOnMortEditorPackTable : ImpactsBirthOutcomesOnMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsStuntOnMortEditorPackTable         : ImpactsStuntOnMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsWasteOnMortEditorPackTable         : ImpactsWasteOnMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsBFOnMortNNEditorPackTable          : ImpactsBFOnMortNNEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsBFOnMortPNNEditorPackTable         : ImpactsBFOnMortPNNEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.openDrawer) {
            return {
                DiarrIncEditorPackTable         : DiarrIncEditor.Create(props.IVInfo, props.CSModvars)[CONSTANTS.CS_PackTable],
                RelRiskBFDiarrEditorPackTable   : RelRiskBFDiarrEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                MeninIncEditorPackTable         : MeninIncEditor.Create(props.IVInfo, props.CSModvars)[CONSTANTS.CS_PackTable],
                RelRiskBFMeningEditorPackTable  : RelRiskBFMeningEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                PneumIncEditorPackTable         : PneumIncEditor.Create(props.IVInfo, props.CSModvars)[CONSTANTS.CS_PackTable],
                RelRiskBFPneumEditorPackTable   : RelRiskBFPneumEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],

                DPTHerdEditorPackTable          : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_DPT_Herd)[CONSTANTS.CS_PackTable],
                HibHerdEditorPackTable          : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_Hib_Herd)[CONSTANTS.CS_PackTable],
                MeaslesHerdEditorPackTable      : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_Measles_Herd)[CONSTANTS.CS_PackTable],
                PneumoHerdEditorPackTable       : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_Pneumococcal_Herd)[CONSTANTS.CS_PackTable],
                RotaHerdEditorPackTable         : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_Rotavirus_Herd)[CONSTANTS.CS_PackTable],
                MeningAHerdEditorPackTable      : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_MeningococcalA_Herd)[CONSTANTS.CS_PackTable],
                BednetsHerdEditorPackTable      : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_Bednets_Herd)[CONSTANTS.CS_PackTable],
                MalariaHerdEditorPackTable      : HerdEffectivenessEditor.Create(props.CSModvars, CONSTANTS.CS_Malaria_Herd)[CONSTANTS.CS_PackTable],

                ImpactPromoAgeAppropriateBFEditorPackTable : ImpactPromoAgeAppropriateBFEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ImpactPromoEarlyInitBFEditorPackTable      : ImpactPromoEarlyInitBFEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                BirthIntervalsEditorPackTable              : BirthIntervalsEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                MatAgeAndBirthOrderEditorPackTable         : MatAgeAndBirthOrderEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                MaternalNutritionEditorPackTable           : MaternalNutritionEditor.Create(props.IVInfo, props.CSModvars, props.language)[CONSTANTS.CS_PackTable],
                ImpactsOnMaternalAnemiaEditorPackTable     : ImpactsOnMaternalAnemiaEditor.Create(props.IVInfo, props.CSModvars, props.language)[CONSTANTS.CS_PackTable],
                ImpactsOnWastingEditorPackTable            : ImpactsOnWastingEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ImpactsOnStuntingEditorPackTable           : ImpactsOnStuntingEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                LowBirthWeightFactorsEditorPackTable       : LowBirthWeightFactorsEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ReducInStuntEditorPackTable                : ReducInStuntEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                EducAttainmentEditorPackTable              : EducAttainmentEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],

                ImpactsAnemiaMatMortEditorPackTable       : ImpactsAnemiaMatMortEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ImpactsBirthOutcomesOnMortEditorPackTable : ImpactsBirthOutcomesOnMortEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ImpactsStuntOnMortEditorPackTable         : ImpactsStuntOnMortEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ImpactsWasteOnMortEditorPackTable         : ImpactsWasteOnMortEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ImpactsBFOnMortNNEditorPackTable          : ImpactsBFOnMortNNEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
                ImpactsBFOnMortPNNEditorPackTable         : ImpactsBFOnMortPNNEditor.Create(props.CSModvars)[CONSTANTS.CS_PackTable],
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    passData = (newState) => {
        this.setState(newState);
    };

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true, 
        });
    };

    onCloseDrawerBtnClick = () => {
        this.setState({ 
            openDrawer      : false,
            saveBtnDisabled : true,
            DiarrIncEditorPackTable         : DiarrIncEditor.Create(this.props.IVInfo, this.props.CSModvars)[CONSTANTS.CS_PackTable],
            RelRiskBFDiarrEditorPackTable   : RelRiskBFDiarrEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            MeninIncEditorPackTable         : MeninIncEditor.Create(this.props.IVInfo, this.props.CSModvars)[CONSTANTS.CS_PackTable],
            RelRiskBFMeningEditorPackTable  : RelRiskBFMeningEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            PneumIncEditorPackTable         : PneumIncEditor.Create(this.props.IVInfo, this.props.CSModvars)[CONSTANTS.CS_PackTable],
            RelRiskBFPneumEditorPackTable   : RelRiskBFPneumEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],

            DPTHerdEditorPackTable          : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_DPT_Herd)[CONSTANTS.CS_PackTable],
            HibHerdEditorPackTable          : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Hib_Herd)[CONSTANTS.CS_PackTable],
            MeaslesHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Measles_Herd)[CONSTANTS.CS_PackTable],
            PneumoHerdEditorPackTable       : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Pneumococcal_Herd)[CONSTANTS.CS_PackTable],
            RotaHerdEditorPackTable         : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Rotavirus_Herd)[CONSTANTS.CS_PackTable],
            MeningAHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_MeningococcalA_Herd)[CONSTANTS.CS_PackTable],
            BednetsHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Bednets_Herd)[CONSTANTS.CS_PackTable],
            MalariaHerdEditorPackTable      : HerdEffectivenessEditor.Create(this.props.CSModvars, CONSTANTS.CS_Malaria_Herd)[CONSTANTS.CS_PackTable],

            ImpactPromoAgeAppropriateBFEditorPackTable : ImpactPromoAgeAppropriateBFEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactPromoEarlyInitBFEditorPackTable      : ImpactPromoEarlyInitBFEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            BirthIntervalsEditorPackTable              : BirthIntervalsEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            MatAgeAndBirthOrderEditorPackTable         : MatAgeAndBirthOrderEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            MaternalNutritionEditorPackTable           : MaternalNutritionEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language)[CONSTANTS.CS_PackTable],
            ImpactsOnMaternalAnemiaEditorPackTable     : ImpactsOnMaternalAnemiaEditor.Create(this.props.IVInfo, this.props.CSModvars, this.props.language)[CONSTANTS.CS_PackTable],
            ImpactsOnWastingEditorPackTable            : ImpactsOnWastingEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsOnStuntingEditorPackTable           : ImpactsOnStuntingEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            LowBirthWeightFactorsEditorPackTable       : LowBirthWeightFactorsEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ReducInStuntEditorPackTable                : ReducInStuntEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            EducAttainmentEditorPackTable              : EducAttainmentEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],

            ImpactsAnemiaMatMortEditorPackTable       : ImpactsAnemiaMatMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsBirthOutcomesOnMortEditorPackTable : ImpactsBirthOutcomesOnMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsStuntOnMortEditorPackTable         : ImpactsStuntOnMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsWasteOnMortEditorPackTable         : ImpactsWasteOnMortEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsBFOnMortNNEditorPackTable          : ImpactsBFOnMortNNEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            ImpactsBFOnMortPNNEditorPackTable         : ImpactsBFOnMortPNNEditor.Create(this.props.CSModvars)[CONSTANTS.CS_PackTable],
            selIVHelpFile                             : "effectiveness_of_interventions_maternal_full.htm",
            helpFile                                  : "effectiveness_of_interventions_maternal_full.htm"
        });
    };

    onSaveBtnClick = () => {
        let ModvarsArray1 = DiarrIncEditor.GetSSData(this.state.DiarrIncEditorPackTable, this.props.CSModvars);
        let ModvarsArray2 = RelRiskBFDiarrEditor.GetSSData(this.state.RelRiskBFDiarrEditorPackTable, this.props.CSModvars);
        let ModvarsArray3 = MeninIncEditor.GetSSData(this.state.MeninIncEditorPackTable, this.props.CSModvars);
        let ModvarsArray4 = RelRiskBFMeningEditor.GetSSData(this.state.RelRiskBFMeningEditorPackTable, this.props.CSModvars);
        let ModvarsArray5 = PneumIncEditor.GetSSData(this.state.PneumIncEditorPackTable, this.props.CSModvars);
        let ModvarsArray6 = RelRiskBFPneumEditor.GetSSData(this.state.RelRiskBFPneumEditorPackTable, this.props.CSModvars);

        let ModvarsArray7 = BirthIntervalsEditor.GetSSData(this.state.BirthIntervalsEditorPackTable, this.props.CSModvars);
        let ModvarsArray8 = MatAgeAndBirthOrderEditor.GetSSData(this.state.MatAgeAndBirthOrderEditorPackTable, this.props.CSModvars);
        let ModvarsArray9 = MaternalNutritionEditor.GetSSData(this.state.MaternalNutritionEditorPackTable, this.props.CSModvars);
        let ModvarsArray10 = ImpactsOnMaternalAnemiaEditor.GetSSData(this.state.ImpactsOnMaternalAnemiaEditorPackTable, this.props.CSModvars);
        let ModvarsArray11 = ImpactsOnWastingEditor.GetSSData(this.state.ImpactsOnWastingEditorPackTable, this.props.CSModvars);
        let ModvarsArray12 = ImpactsOnStuntingEditor.GetSSData(this.state.ImpactsOnStuntingEditorPackTable, this.props.CSModvars);
        let ModvarsArray13 = LowBirthWeightFactorsEditor.GetSSData(this.state.LowBirthWeightFactorsEditorPackTable, this.props.CSModvars);
        let ModvarsArray14 = ReducInStuntEditor.GetSSData(this.state.ReducInStuntEditorPackTable, this.props.CSModvars);
        let ModvarsArray15 = EducAttainmentEditor.GetSSData(this.state.EducAttainmentEditorPackTable, this.props.CSModvars);

        let ModvarsArray16 = ImpactsAnemiaMatMortEditor.GetSSData(this.state.ImpactsAnemiaMatMortEditorPackTable, this.props.CSModvars);
        let ModvarsArray17 = ImpactsBirthOutcomesOnMortEditor.GetSSData(this.state.ImpactsBirthOutcomesOnMortEditorPackTable, this.props.CSModvars);
        let ModvarsArray18 = ImpactsStuntOnMortEditor.GetSSData(this.state.ImpactsStuntOnMortEditorPackTable, this.props.CSModvars);
        let ModvarsArray19 = ImpactsWasteOnMortEditor.GetSSData(this.state.ImpactsWasteOnMortEditorPackTable, this.props.CSModvars);
        let ModvarsArray20 = ImpactsBFOnMortNNEditor.GetSSData(this.state.ImpactsBFOnMortNNEditorPackTable, this.props.CSModvars);
        let ModvarsArray21 = ImpactsBFOnMortPNNEditor.GetSSData(this.state.ImpactsBFOnMortPNNEditorPackTable, this.props.CSModvars);

        let changedCSModvars = this.props.CSModvars.map(x => {
            let a = ModvarsArray1.find(y => y.tag === x.tag);
            let b = ModvarsArray2.find(y => y.tag === x.tag);
            let c = ModvarsArray3.find(y => y.tag === x.tag);
            let d = ModvarsArray4.find(y => y.tag === x.tag);
            let e = ModvarsArray5.find(y => y.tag === x.tag);
            let f = ModvarsArray6.find(y => y.tag === x.tag);
            let g = ModvarsArray7.find(y => y.tag === x.tag);
            let h = ModvarsArray8.find(y => y.tag === x.tag);
            let i = ModvarsArray9.find(y => y.tag === x.tag);
            let j = ModvarsArray10.find(y => y.tag === x.tag);
            let k = ModvarsArray11.find(y => y.tag === x.tag);
            let l = ModvarsArray12.find(y => y.tag === x.tag);
            let m = ModvarsArray13.find(y => y.tag === x.tag);
            let n = ModvarsArray14.find(y => y.tag === x.tag);
            let o = ModvarsArray15.find(y => y.tag === x.tag);
            let p = ModvarsArray16.find(y => y.tag === x.tag);
            let q = ModvarsArray17.find(y => y.tag === x.tag);
            let r = ModvarsArray18.find(y => y.tag === x.tag);
            let s = ModvarsArray19.find(y => y.tag === x.tag);
            let t = ModvarsArray20.find(y => y.tag === x.tag);
            let u = ModvarsArray21.find(y => y.tag === x.tag);
            return a || b || c || d || e || f || g || h || i || j || k || l || m || n || o || p || q || r || s || t || u || x;
        });

        let ModvarsArray22 = ImpactPromoAgeAppropriateBFEditor.GetSSData(this.state.ImpactPromoAgeAppropriateBFEditorPackTable, changedCSModvars);
        let changedCSModvars0 = changedCSModvars.map(x => {
            let a = ModvarsArray22.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray23 = ImpactPromoEarlyInitBFEditor.GetSSData(this.state.ImpactPromoEarlyInitBFEditorPackTable, changedCSModvars0);
        let changedCSModvars1 = changedCSModvars0.map(x => {
            let a = ModvarsArray23.find(y => y.tag === x.tag);
            return a || x;
        });

        let ModvarsArray24 = HerdEffectivenessEditor.GetSSData(this.state.DPTHerdEditorPackTable, changedCSModvars1);
        let changedCSModvars2 = changedCSModvars1.map(x => {
            let a = ModvarsArray24.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray25 = HerdEffectivenessEditor.GetSSData(this.state.HibHerdEditorPackTable, changedCSModvars2);
        let changedCSModvars3 = changedCSModvars2.map(x => {
            let a = ModvarsArray25.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray26 = HerdEffectivenessEditor.GetSSData(this.state.MeaslesHerdEditorPackTable, changedCSModvars3);
        let changedCSModvars4 = changedCSModvars3.map(x => {
            let a = ModvarsArray26.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray27 = HerdEffectivenessEditor.GetSSData(this.state.PneumoHerdEditorPackTable, changedCSModvars4);
        let changedCSModvars5 = changedCSModvars4.map(x => {
            let a = ModvarsArray27.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray28 = HerdEffectivenessEditor.GetSSData(this.state.RotaHerdEditorPackTable, changedCSModvars5);
        let changedCSModvars6 = changedCSModvars5.map(x => {
            let a = ModvarsArray28.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray29 = HerdEffectivenessEditor.GetSSData(this.state.MeningAHerdEditorPackTable, changedCSModvars6);
        let changedCSModvars7 = changedCSModvars6.map(x => {
            let a = ModvarsArray29.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray30 = HerdEffectivenessEditor.GetSSData(this.state.BednetsHerdEditorPackTable, changedCSModvars7);
        let changedCSModvars8 = changedCSModvars7.map(x => {
            let a = ModvarsArray30.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray31 = HerdEffectivenessEditor.GetSSData(this.state.MalariaHerdEditorPackTable, changedCSModvars8);
        let changedCSModvars9 = changedCSModvars8.map(x => {
            let a = ModvarsArray31.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray32 = MatEfficacyEditor.GetSSData(this.state.MatEfficacyEditorPackTable, changedCSModvars9);
        let changedCSModvars10 = changedCSModvars9.map(x => {
            let a = ModvarsArray32.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray33 = SBEfficacyEditor.GetSSData(this.state.SBEfficacyEditorPackTable, changedCSModvars10);
        let changedCSModvars11 = changedCSModvars10.map(x => {
            let a = ModvarsArray33.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray34 = EfficacyEditor.GetSSData(this.state.NeoNatalEfficacyEditor, changedCSModvars11);
        let changedCSModvars12 = changedCSModvars11.map(x => {
            let a = ModvarsArray34.find(y => y.tag === x.tag);
            return a || x;
        });
        let ModvarsArray35 = EfficacyEditor.GetSSData(this.state.PostNatalEfficacyEditor, changedCSModvars12);
        let changedCSModvars13 = changedCSModvars12.map(x => {
            let a = ModvarsArray35.find(y => y.tag === x.tag);
            return a || x;
        });

        this.setState({
            openDrawer      : false,
            saveBtnDisabled : true,
            selIVHelpFile   : "effectiveness_of_interventions_maternal_full.htm",
            helpFile        : "effectiveness_of_interventions_maternal_full.htm"
        });

        this.props.onStateChange({
            unchangedProj: false,
            CSModvars : changedCSModvars13,
            showAllInterventionsCB: this.state.showAllInterventionsCB
        });
    };

    onTabChange = (idx) => {
        const effectiveness = 0;
        const incidence = 1;
        const vaccines = 2;
        const birthOutcomes = 3;
        const nutrition = 4;
        const underNutrition = 5;

        //special code to prevent birthOutcomes from showing up in french until its been added
        this.setState({showHelp: true});

        switch (idx) {
            case effectiveness : {
                this.setState({helpFile: this.state.selIVHelpFile});
                break;
            }
            case incidence : {
                this.setState({helpFile: "effectiveness_of_interventions_on_incidence.htm"});
                break;
            }
            case vaccines : {
                this.setState({helpFile: "effectiveness_of_vaccines_cohort_approach.htm"});
                break;
            }
            case birthOutcomes : {
                if (this.props.language === CONSTANTS.GB_English){
                    this.setState({helpFile: "effectiveness-and-risk-for-bir.htm"});
                }
                else{
                    this.setState({showHelp: false});
                }
                break;
            }
            case nutrition : {
                this.setState({helpFile: "effectiveness_of_nutrition_interventions.htm"});
                break;
            }
            case underNutrition : {
                this.setState({helpFile: "impact_of_undernutrition_on_mortality.htm"});
                break;
            }
            default :
                break;
        }
    };

    onDiarrIncEditorPackTableChange = (packTable) => {
        this.setState({
            DiarrIncEditorPackTable : packTable,
            saveBtnDisabled         : false,
        });
    };
    onRelRiskBFDiarrEditorPackTableChange = (packTable) => {
        this.setState({
            RelRiskBFDiarrEditorPackTable : packTable,
            saveBtnDisabled               : false,
        });
    };
    onMeninIncEditorPackTableChange = (packTable) => {
        this.setState({
            MeninIncEditorPackTable : packTable,
            saveBtnDisabled         : false,
        });
    };
    onRelRiskBFMeningEditorPackTableChange = (packTable) => {
        this.setState({
            RelRiskBFMeningEditorPackTable : packTable,
            saveBtnDisabled                : false,
        });
    };
    onPneumIncEditorPackTableChange = (packTable) => {
        this.setState({
            PneumIncEditorPackTable : packTable,
            saveBtnDisabled         : false,
        });
    };
    onRelRiskBFPneumEditorPackTableChange = (packTable) => {
        this.setState({
            RelRiskBFPneumEditorPackTable : packTable,
            saveBtnDisabled               : false,
        });
    };
    onEffOnIncidenceComboboxChange = (value) => {
        this.setState ({
            EffOnIncidenceComboboxIdx : value
        })
    };

    onDPTHerdEditorPackTableChange = (packTable) => {
        this.setState({
            DPTHerdEditorPackTable : packTable,
            saveBtnDisabled        : false,
        });
    };
    onHibHerdEditorPackTableChange = (packTable) => {
        this.setState({
            HibHerdEditorPackTable : packTable,
            saveBtnDisabled        : false,
        });
    };
    onMeaslesHerdEditorPackTableChange = (packTable) => {
        this.setState({
            MeaslesHerdEditorPackTable : packTable,
            saveBtnDisabled            : false,
        });
    };
    onPneumoHerdEditorPackTableChange = (packTable) => {
        this.setState({
            PneumoHerdEditorPackTable : packTable,
            saveBtnDisabled           : false,
        });
    };
    onRotaHerdEditorPackTableChange = (packTable) => {
        this.setState({
            RotaHerdEditorPackTable : packTable,
            saveBtnDisabled         : false,
        });
    };
    onMeningAHerdEditorPackTableChange = (packTable) => {
        this.setState({
            MeningAHerdEditorPackTable : packTable,
            saveBtnDisabled            : false,
        });
    };
    onBednetsHerdEditorPackTableChange = (packTable) => {
        this.setState({
            BednetsHerdEditorPackTable : packTable,
            saveBtnDisabled            : false,
        });
    };
    onMalariaHerdEditorPackTableChange = (packTable) => {
        this.setState({
            MalariaHerdEditorPackTable : packTable,
            saveBtnDisabled            : false,
        });
    };
    onHerdComboboxChange = (value) => {
        this.setState ({
            herdComboboxIdx : value
        })
    };

    onImpactPromoAgeAppropriateBFEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactPromoAgeAppropriateBFEditorPackTable : packTable,
            saveBtnDisabled                            : false,
        });
    };
    onImpactPromoEarlyInitBFEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactPromoEarlyInitBFEditorPackTable : packTable,
            saveBtnDisabled                       : false,
        });
    };
    onBirthIntervalsEditorPackTableChange = (packTable) => {
        this.setState({
            BirthIntervalsEditorPackTable : packTable,
            saveBtnDisabled               : false,
        });
    };
    onMatAgeAndBirthOrderEditorPackTableChange = (packTable) => {
        this.setState({
            MatAgeAndBirthOrderEditorPackTable : packTable,
            saveBtnDisabled                    : false,
        });
    };
    onMaternalNutritionEditorPackTableChange = (packTable) => {
        this.setState({
            MaternalNutritionEditorPackTable : packTable,
            saveBtnDisabled                  : false,
        });
    };
    onImpactsOnMaternalAnemiaEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsOnMaternalAnemiaEditorPackTable : packTable,
            saveBtnDisabled                        : false,
        });
    };
    onImpactsOnWastingEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsOnWastingEditorPackTable : packTable,
            saveBtnDisabled                 : false,
        });
    };
    onImpactsOnStuntingEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsOnStuntingEditorPackTable : packTable,
            saveBtnDisabled                  : false,
        });
    };
    onLowBirthWeightFactorsEditorPackTableChange = (packTable) => {
        this.setState({
            LowBirthWeightFactorsEditorPackTable : packTable,
            saveBtnDisabled                      : false,
        });
    };
    onReducInStuntEditorPackTableChange = (packTable) => {
        this.setState({
            ReducInStuntEditorPackTable : packTable,
            saveBtnDisabled                      : false,
        });
    };
    onEducAttainmentEditorPackTableChange = (packTable) => {
        this.setState({
            EducAttainmentEditorPackTable : packTable,
            saveBtnDisabled                      : false,
        });
    };
    onNutritionComboboxChange = (value) => {
        this.setState ({
            nutritionComboboxIdx : value
        })
    };

    onImpactsAnemiaMatMortEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsAnemiaMatMortEditorPackTable : packTable,
            saveBtnDisabled                     : false,
        });
    };
    onImpactsBirthOutcomesOnMortEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsBirthOutcomesOnMortEditorPackTable : packTable,
            saveBtnDisabled                           : false,
        });
    };
    onImpactsStuntOnMortEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsStuntOnMortEditorPackTable : packTable,
            saveBtnDisabled                   : false,
        });
    };
    onImpactsWasteOnMortEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsWasteOnMortEditorPackTable : packTable,
            saveBtnDisabled                   : false,
        });
    };
    onImpactsBFOnMortNNEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsBFOnMortNNEditorPackTable  : packTable,
            saveBtnDisabled                   : false,
        });
    };
    onImpactsBFOnMortPNNEditorPackTableChange = (packTable) => {
        this.setState({
            ImpactsBFOnMortPNNEditorPackTable : packTable,
            saveBtnDisabled                   : false,
        });
    };
    onUnderNutritionComboboxChange = (value) => {
        this.setState ({
            underNutritionComboboxIdx : value
        })
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            captionStyle : {
                height: '110px',
                width: '198px',
                display: 'table-cell',
                verticalAlign: 'middle',
                margin: '0 auto',
                textAlign: 'center',
            }
        };

        return (
            <CSBlueBox2 
                caption      = {RS('GB_stEffectiveness')}
                captionStyle = {styles.captionStyle}
                description  = ""
                onClick      = {this.onBlueBoxClick}
                editContent  = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {this.state.helpFile}
                        showHelp        = {this.state.showHelp}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stEffectiveness')}
                        content         = {
                                            <CSEffectivenessDrawer
                                                onTabChange                            = {this.onTabChange}

                                                DiarrIncEditorPackTable                = {this.state.DiarrIncEditorPackTable}
                                                RelRiskBFDiarrEditorPackTable          = {this.state.RelRiskBFDiarrEditorPackTable}
                                                MeninIncEditorPackTable                = {this.state.MeninIncEditorPackTable}
                                                RelRiskBFMeningEditorPackTable         = {this.state.RelRiskBFMeningEditorPackTable}
                                                PneumIncEditorPackTable                = {this.state.PneumIncEditorPackTable}
                                                RelRiskBFPneumEditorPackTable          = {this.state.RelRiskBFPneumEditorPackTable}
                                                onDiarrIncEditorPackTableChange        = {this.onDiarrIncEditorPackTableChange}
                                                onRelRiskBFDiarrEditorPackTableChange  = {this.onRelRiskBFDiarrEditorPackTableChange}
                                                onMeninIncEditorPackTableChange        = {this.onMeninIncEditorPackTableChange}
                                                onRelRiskBFMeningEditorPackTableChange = {this.onRelRiskBFMeningEditorPackTableChange}
                                                onPneumIncEditorPackTableChange        = {this.onPneumIncEditorPackTableChange}
                                                onRelRiskBFPneumEditorPackTableChange  = {this.onRelRiskBFPneumEditorPackTableChange}
                                                EffOnIncidenceComboboxIdx              = {this.state.EffOnIncidenceComboboxIdx}
                                                onEffOnIncidenceComboboxChange         = {this.onEffOnIncidenceComboboxChange}

                                                DPTHerdEditorPackTable                 = {this.state.DPTHerdEditorPackTable}
                                                HibHerdEditorPackTable                 = {this.state.HibHerdEditorPackTable}
                                                MeaslesHerdEditorPackTable             = {this.state.MeaslesHerdEditorPackTable}
                                                PneumoHerdEditorPackTable              = {this.state.PneumoHerdEditorPackTable}
                                                RotaHerdEditorPackTable                = {this.state.RotaHerdEditorPackTable}
                                                MeningAHerdEditorPackTable             = {this.state.MeningAHerdEditorPackTable}
                                                BednetsHerdEditorPackTable             = {this.state.BednetsHerdEditorPackTable}
                                                MalariaHerdEditorPackTable             = {this.state.MalariaHerdEditorPackTable}
                                                onDPTHerdEditorPackTableChange         = {this.onDPTHerdEditorPackTableChange}
                                                onHibHerdEditorPackTableChange         = {this.onHibHerdEditorPackTableChange}
                                                onMeaslesHerdEditorPackTableChange     = {this.onMeaslesHerdEditorPackTableChange}
                                                onPneumoHerdEditorPackTableChange      = {this.onPneumoHerdEditorPackTableChange}
                                                onRotaHerdEditorPackTableChange        = {this.onRotaHerdEditorPackTableChange}
                                                onMeningAHerdEditorPackTableChange     = {this.onMeningAHerdEditorPackTableChange}
                                                onBednetsHerdEditorPackTableChange     = {this.onBednetsHerdEditorPackTableChange}
                                                onMalariaHerdEditorPackTableChange     = {this.onMalariaHerdEditorPackTableChange}
                                                herdComboboxIdx                        = {this.state.herdComboboxIdx}
                                                onHerdComboboxChange                   = {this.onHerdComboboxChange}

                                                ImpactPromoAgeAppropriateBFEditorPackTable         = {this.state.ImpactPromoAgeAppropriateBFEditorPackTable}
                                                ImpactPromoEarlyInitBFEditorPackTable              = {this.state.ImpactPromoEarlyInitBFEditorPackTable}
                                                BirthIntervalsEditorPackTable                      = {this.state.BirthIntervalsEditorPackTable}
                                                MatAgeAndBirthOrderEditorPackTable                 = {this.state.MatAgeAndBirthOrderEditorPackTable}
                                                MaternalNutritionEditorPackTable                   = {this.state.MaternalNutritionEditorPackTable}
                                                ImpactsOnMaternalAnemiaEditorPackTable             = {this.state.ImpactsOnMaternalAnemiaEditorPackTable}
                                                ImpactsOnWastingEditorPackTable                    = {this.state.ImpactsOnWastingEditorPackTable}
                                                ImpactsOnStuntingEditorPackTable                   = {this.state.ImpactsOnStuntingEditorPackTable}
                                                LowBirthWeightFactorsEditorPackTable               = {this.state.LowBirthWeightFactorsEditorPackTable}
                                                ReducInStuntEditorPackTable                        = {this.state.ReducInStuntEditorPackTable}
                                                EducAttainmentEditorPackTable                      = {this.state.EducAttainmentEditorPackTable}
                                                onImpactPromoAgeAppropriateBFEditorPackTableChange = {this.onImpactPromoAgeAppropriateBFEditorPackTableChange}
                                                onImpactPromoEarlyInitBFEditorPackTableChange      = {this.onImpactPromoEarlyInitBFEditorPackTableChange}
                                                onBirthIntervalsEditorPackTableChange              = {this.onBirthIntervalsEditorPackTableChange}
                                                onMatAgeAndBirthOrderEditorPackTableChange         = {this.onMatAgeAndBirthOrderEditorPackTableChange}
                                                onMaternalNutritionEditorPackTableChange           = {this.onMaternalNutritionEditorPackTableChange}
                                                onImpactsOnMaternalAnemiaEditorPackTableChange     = {this.onImpactsOnMaternalAnemiaEditorPackTableChange}
                                                onImpactsOnWastingEditorPackTableChange            = {this.onImpactsOnWastingEditorPackTableChange}
                                                onImpactsOnStuntingEditorPackTableChange           = {this.onImpactsOnStuntingEditorPackTableChange}
                                                onLowBirthWeightFactorsEditorPackTableChange       = {this.onLowBirthWeightFactorsEditorPackTableChange}
                                                onReducInStuntEditorPackTableChange                = {this.onReducInStuntEditorPackTableChange}
                                                onEducAttainmentEditorPackTableChange              = {this.onEducAttainmentEditorPackTableChange}
                                                nutritionComboboxIdx                               = {this.state.nutritionComboboxIdx}
                                                onNutritionComboboxChange                          = {this.onNutritionComboboxChange}

                                                ImpactsAnemiaMatMortEditorPackTable               = {this.state.ImpactsAnemiaMatMortEditorPackTable}
                                                ImpactsBirthOutcomesOnMortEditorPackTable         = {this.state.ImpactsBirthOutcomesOnMortEditorPackTable}
                                                ImpactsStuntOnMortEditorPackTable                 = {this.state.ImpactsStuntOnMortEditorPackTable}
                                                ImpactsWasteOnMortEditorPackTable                 = {this.state.ImpactsWasteOnMortEditorPackTable}
                                                ImpactsBFOnMortNNEditorPackTable                  = {this.state.ImpactsBFOnMortNNEditorPackTable}
                                                ImpactsBFOnMortPNNEditorPackTable                 = {this.state.ImpactsBFOnMortPNNEditorPackTable}
                                                onImpactsAnemiaMatMortEditorPackTableChange       = {this.onImpactsAnemiaMatMortEditorPackTableChange}
                                                onImpactsBirthOutcomesOnMortEditorPackTableChange = {this.onImpactsBirthOutcomesOnMortEditorPackTableChange}
                                                onImpactsStuntOnMortEditorPackTableChange         = {this.onImpactsStuntOnMortEditorPackTableChange}
                                                onImpactsWasteOnMortEditorPackTableChange         = {this.onImpactsWasteOnMortEditorPackTableChange}
                                                onImpactsBFOnMortNNEditorPackTableChange         = {this.onImpactsBFOnMortNNEditorPackTableChange}
                                                onImpactsBFOnMortPNNEditorPackTableChange         = {this.onImpactsBFOnMortPNNEditorPackTableChange}
                                                underNutritionComboboxIdx                         = {this.state.underNutritionComboboxIdx}
                                                onUnderNutritionComboboxChange                    = {this.onUnderNutritionComboboxChange}

                                                IVInfo                                            = {this.props.IVInfo}
                                                CSModvars                                         = {this.props.CSModvars}
                                                language                                          = {this.props.language}
                                                showAllInterventionsCB                            = {this.props.showAllInterventionsCB}
                                                passData                                          = {this.passData}
                                            />
                                        }
                    />
                }
            />
        );
    }

}

export default CSEffectivenessBlueBox;