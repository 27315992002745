import React from "react";
import PropTypes from "prop-types";
import CSDialog from "./CSDialog";
import YesButton from "../buttons/YesButton";
import NoButton from "../buttons/NoButton";

class SubnatCheckDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
    };

    state = {
        message          : "Are you sure?",
        multiplier       : '',
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogSubnatCheckOpen : false
        });
    };

    onYesBtnClick = () => {
        this.onClose();
        this.props.onYes();
    };

    onNoBtnClick = () => {
        this.onClose();
        this.props.onNo();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={this.state.message}
                onClose={this.onClose}
                content={
                    <div>
                        This action will delete your subnational data configuration.  Do you wish to proceed?
                    </div>
                }
                actions={
                    <div>
                        <NoButton onClick={this.onNoBtnClick} colorType="secondary"/>
                        <YesButton onClick={this.onYesBtnClick} />
                    </div>
                }
            />
        );
    }
}

export default SubnatCheckDialog;