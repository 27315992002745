import React from "react";
import PropTypes from "prop-types";
import TDrawerWithHeader from "@common/components/TDrawerWithHeader";
import RS from "@common/strings/RS";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSFertilityRisksDrawer from "../../drawers/Coverage/CSFertilityRisksDrawer";
import {FertilityRisksEditor} from "../../data/Coverage/CS/FertilityRisksEditor";
import {getModvarByTag} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";

class CSFertilityRisksBlueBox extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange    : PropTypes.func,
        CSModvars        : PropTypes.arrayOf(PropTypes.object),
        captionStyle     : PropTypes.object,
        firstYear        : PropTypes.number,
        onOpenHelpDialog : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
        CSModvars        : [],
        captionStyle     : {},
        firstYear        : -1,
        onOpenHelpDialog : () => console.log('onOpenHelpDialog'),
    };

    state = {
        saveBtnDisabled : true,
        openDrawer      : false,
        packTable1      : null,
        packTable2      : null,
    };

    componentDidMount () {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        this.setState({
            packTable1 : FertilityRisksEditor.Create(CSModvars, firstYear, finalYear, FertilityRisksEditor.MatAgeAndBirthOrderID),
            packTable2 : FertilityRisksEditor.Create(CSModvars, firstYear, finalYear, FertilityRisksEditor.BirthIntervalsID),
        });
    }

    static getDerivedStateFromProps(props, state) {
        let CSModvars = props.CSModvars;
        let firstYear = props.firstYear;
        let finalYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        if (!state.openDrawer) {
            return {
                packTable1 : FertilityRisksEditor.Create(CSModvars, firstYear, finalYear, FertilityRisksEditor.MatAgeAndBirthOrderID),
                packTable2 : FertilityRisksEditor.Create(CSModvars, firstYear, finalYear, FertilityRisksEditor.BirthIntervalsID),
            }
        }
        else {
            return null
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
    };

    onSaveBtnClick = () => {
        let goAhead = true;

        let TotalRow = this.state.packTable1.tableData.value[this.state.packTable1.GBRowCount-1];
        TotalRow.forEach(function(val) {
            if (typeof val === "number" && parseFloat(val.toFixed(1)) !== 100) {
                goAhead = false;
            }
        });

        if (goAhead){
            let TotalRow = this.state.packTable2.tableData.value[this.state.packTable2.GBRowCount-1];
            TotalRow.forEach(function(val) {
                if (typeof val === "number" && parseFloat(val.toFixed(1)) !== 100) {
                    goAhead = false;
                }
            });
        }

        if (goAhead) {

            let ModvarsArray1 = FertilityRisksEditor.GetSSData(this.state.packTable1, this.props.CSModvars, FertilityRisksEditor.MatAgeAndBirthOrderID);
            let ModvarsArray2 = FertilityRisksEditor.GetSSData(this.state.packTable2, this.props.CSModvars, FertilityRisksEditor.BirthIntervalsID);

            let changedCSModvars = this.props.CSModvars.map(x => {
                let a = ModvarsArray1.find(y => y.tag === x.tag);
                let b = ModvarsArray2.find(y => y.tag === x.tag);
                return a || b || x;
            });

            this.setState({
                openDrawer      : false,
                saveBtnDisabled : true,
            });

            this.props.onStateChange({
                CSModvars : changedCSModvars,
                unchangedProj: false
            });
        }
        else {
            this.props.onStateChange({
                dialogErrorOpen : true,
                errorMessage    : RS("GB_stValsMustSumTo100Perc")
            });
        }
    };

    onCloseDrawerBtnClick = () => {
        let CSModvars = this.props.CSModvars;
        let firstYear = this.props.firstYear;
        let finalYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"];
        this.setState({
            openDrawer         : false,
            saveBtnDisabled    : true,
            packTable1         : FertilityRisksEditor.Create(CSModvars, firstYear, finalYear, FertilityRisksEditor.MatAgeAndBirthOrderID),
            packTable2         : FertilityRisksEditor.Create(CSModvars, firstYear, finalYear, FertilityRisksEditor.BirthIntervalsID),
        });
    };

    onPackTable1Change = (packTable) => {
        this.setState({
            packTable1      : packTable,
            saveBtnDisabled : false,
        });
    };

    onPackTable2Change = (packTable) => {
        this.setState({
            packTable2      : packTable,
            saveBtnDisabled : false,
        });
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <CSBlueBox2
                caption      = {RS('GB_stFertilityRisks')}
                captionStyle = {this.props.captionStyle}
                description  = {''}
                onClick      = {this.onBlueBoxClick}
                editContent  = {
                    <TDrawerWithHeader
                        open            = {this.state.openDrawer}
                        onOpenHelpDialog = {this.props.onOpenHelpDialog}
                        helpFile        = {"fertility_risks.htm"}
                        onClose         = {this.onCloseDrawerBtnClick}
                        onSave          = {this.onSaveBtnClick}
                        saveBtnCaption  = {RS('GB_stApply')}
                        saveBtnDisabled = {this.state.saveBtnDisabled}
                        appBarText      = {RS('GB_stFertilityRisks')}
                        content         = {
                            <CSFertilityRisksDrawer
                                packTable1            = {this.state.packTable1}
                                packTable2            = {this.state.packTable2}
                                onPackTable1Change    = {this.onPackTable1Change}
                                onPackTable2Change    = {this.onPackTable2Change}
                            />
                        }
                    />
                }
            />

        );
    }

}

export default CSFertilityRisksBlueBox;