import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import OKButton from "../buttons/OKButton";

class FileWithSameNameDialog extends React.Component {
    static propTypes = {
        onStateChange    : PropTypes.func,
    };

    static defaultProps = {
        onStateChange    : () => console.log('onStateChange'),
    };

    constructor(props) {
        super(props);

        this.state = {
            //
        };
    };

    //==================================================================================================================
    //
    //                                                Function
    //
    //==================================================================================================================

    onClose = () => {
        this.props.onStateChange({
            dialogFileWithSameNameOpen: false
        });
    };

    //==================================================================================================================
    //
    //                                                Render
    //
    //==================================================================================================================

    render() {

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stAlreadyOpen')}
                onClose={this.onClose}
                style={{width: 375}}
                content={
                    <div>
                        {RS("GB_stSameName")}
                    </div>
                }
                actions={
                    <div>
                        <OKButton onClick={this.onClose} />
                    </div>
                }
            />
        );
    }
}

export default FileWithSameNameDialog;