import RS from "@common/strings/RS";
import {
    getModvarCloneByTag,
    indentPackTable,
    getNewPT,
    generatePackChart,
    resizePackTable,
    getYearFromIdx,
    addRowToPackTable,
    addMergedCellsToArray,
    GB_CONSTANTS,
    CONSTANTS,
    ResultExcludeMstIDSet,
    CSGetVaccCurrID,
    CSGetDetVaccEquivDose,
    CSGetGroupMstIDFromLiSTOnlineID,
    CSGetLiSTOnlineGroupStr, 
    CSGetMstIDName,
    DirectEntryMstIDSet
} from "./imports.js";

const RegularCov               = 0;
const EarlyInitCov             = 1;
const VaccineCov               = 2;
const VaccineRetro             = 3;
const ARVRegimen               = 4;
const NeedPMTCT                = 5;
const OnPMTCT                  = 6;
const ChildARTByAgeGroupPerNum = 7;
const ChildTreatInputs         = 8;
const ChildARTCalc             = 9;
const CPR                      = 10;
const CoverageTypes            = 11;

export const getCoverage = (IVInfo, CSModvars, language, projInfo, chartYears, retroYears) => {
    let resultName = RS('GB_stCoverage');
    let cardChartType = CONSTANTS.CS_ColumnChart;
    let packTable = Setup(IVInfo, CSModvars, language, projInfo, chartYears, retroYears);
    packTable.title = resultName;
    let packChart = generatePackChart(packTable);
    return (
        {packChart : packChart, packTable : packTable, resultName : resultName, cardChartType : cardChartType}
    )
};

const SetRowText = (pt, numCols, s, xoffset, IntervRec) => {
    pt.tableData.value[pt.GBRowCount][0] = s;
    pt.IntervRecords[pt.GBRowCount] = IntervRec;
    pt = indentPackTable(pt, pt.GBRowCount, true, xoffset);
    for(let c = 1; c <= numCols; c++){
        pt.RDec[pt.GBRowCount][c] = 1;
    }
    return (pt);
};

const GetARVRegimen = (ARVRegimen, RegimenPeriod, regimen, PerNum, t) => {
    return ARVRegimen[RegimenPeriod][regimen][PerNum][t];
};

const  GetDPPMTCTValue = (Coverage, t) => {
    let Value60_1, Value60_2, Value20_1, Value17_1, Value3_1, Result;

    /* if one of these values is not zero, then the user changed a value so the PMTCT that
       is returned must to be calculated */
    if ((GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_SingleDoseNevir, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_SingleDoseNevir, CONSTANTS.DP_Percent, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_DualARV, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_DualARV, CONSTANTS.DP_Percent, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Percent, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PostnatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PostnatalProphylaxis, CONSTANTS.DP_OptB, CONSTANTS.DP_Percent, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_OptA, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_TripleARTBefPreg, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_TripleARTDurPreg, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_TripleARTDurPreg_Late, CONSTANTS.DP_Number, t) !== 0) ||
        (GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PostnatalProphylaxis, CONSTANTS.DP_OptA, CONSTANTS.DP_Number, t) !== 0)) {
        if (Coverage[NeedPMTCT][t] === 0) {
            Result = 0
        }
        else {
            Result = (Coverage[OnPMTCT][t] / Coverage[NeedPMTCT][t]) * 100;
        }
    }

    /* if all of the values above are zero, then the values from the editor can be returned
       according to the 60-20-20 formula. the 20 for during preganancy is split 17 for  > 4 weeks
       and 3 for < 4 weeks */
    else{
        Value60_1 = GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_OptA, CONSTANTS.DP_Percent, t);
        Value60_2 = GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PostnatalProphylaxis, CONSTANTS.DP_OptA, CONSTANTS.DP_Percent, t);
        Value20_1 = GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_TripleARTBefPreg, CONSTANTS.DP_Percent, t);
        Value17_1 = GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_TripleARTDurPreg, CONSTANTS.DP_Percent, t);
        Value3_1 = GetARVRegimen(Coverage[ARVRegimen], CONSTANTS.DP_PrenatalProphylaxis, CONSTANTS.DP_TripleARTDurPreg_Late, CONSTANTS.DP_Percent, t);
        Result = ((Value60_1 + Value60_2) / 2) + Value20_1 + Value17_1 + Value3_1;
    }
    return (Result);
};

const GetAIMValue = (Coverage, intID, t) =>{
    let result = 0;

    switch(intID){
        case CONSTANTS.IV_PMTCT :{
            result = GetDPPMTCTValue(Coverage, t); break;
        }

        case CONSTANTS.IV_Cotrimoxazole :{
            if (Coverage[ChildARTByAgeGroupPerNum][CONSTANTS.DP_PerChildHIVPosCot][t] === CONSTANTS.DP_Percent) {
                result = Coverage[ChildTreatInputs][CONSTANTS.DP_PerChildHIVPosCot][t]
            }
            else {
                if (Coverage[ChildARTCalc][CONSTANTS.DP_NumNeedCot][CONSTANTS.DP_BothSexes][t] > 0) {
                    result = (Coverage[ChildTreatInputs][CONSTANTS.DP_PerChildHIVPosCot][t] /
                        Coverage[ChildARTCalc][CONSTANTS.DP_NumNeedCot][CONSTANTS.DP_BothSexes][t]) * 100
                }
                else {
                    result = 0;
                }
            }
            break;
        }

        case CONSTANTS.IV_ART :{
            if (Coverage[ChildARTByAgeGroupPerNum][CONSTANTS.DP_PerChildHIVRecART][t] === CONSTANTS.DP_Percent) {
                result = Coverage[ChildTreatInputs][CONSTANTS.DP_PerChildHIVRecART][t]
            }
            else{
                if (Coverage[ChildARTCalc][CONSTANTS.DP_NumNeedART][CONSTANTS.DP_BothSexes][t] > 0) {
                    result = (Coverage[ChildTreatInputs][CONSTANTS.DP_PerChildHIVRecART][t] /
                        Coverage[ChildARTCalc][CONSTANTS.DP_NumNeedART][CONSTANTS.DP_BothSexes][t]) * 100
                }
                else {
                    result = 0;
                }
            }
            break;
        }

        default: break;
    }

    return (result);
};

const GetCovValue = (Coverage, AIM_IV_Set, IntervRec, t, useRetro) =>{
    let result = 0;

    let vt = CSGetVaccCurrID(IntervRec["MstID"]);

    if (AIM_IV_Set.includes(IntervRec["MstID"])){
        result = GetAIMValue(Coverage, IntervRec["MstID"], t);
    }
    if (IntervRec["MstID"] === CONSTANTS.CS_MstCPR) {
        result = Coverage[CPR][CONSTANTS.FP_All_Ages][CONSTANTS.FP_all_need][t];
    } else if (IntervRec["MstID"] === CONSTANTS.CS_MstEarlyInitBF){
        result = Coverage[EarlyInitCov][CONSTANTS.CS_AgeSummary][t];
    }
    else if (vt !== 0){
        if (useRetro){
            result = Coverage[VaccineRetro][vt][CSGetDetVaccEquivDose(vt)][t];
        }
        else {
            result = Coverage[VaccineCov][vt][CSGetDetVaccEquivDose(vt)][t];
        }
    }
    else{
        result = Coverage[RegularCov][IntervRec["CurrID"]][t];
    }
    return (result);
};

const Setup = (IVInfo, CSModvars, language, projInfo, chartYears, retroYears) => {
    let BYIndex = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    const SelectedIVSet = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_SelectedIVSet_MV)["value"]["intArray"];

    let pt = getNewPT();
    const numRows = 1;

    let endPoints = false;//chartOptions['endPoints'];
    let showIVsWithImpact = true;//chartOptions['showIVs'];

    let startYear = chartYears["firstYrIdx"];
    if (retroYears){
        startYear -= 4;
    }

    let numCols;
    pt.yearArr = [];
    if (!endPoints){
        numCols = chartYears["finalYrIdx"] - startYear + 2;//+2 for extra col on the side, and difference math
        for(let t = startYear; t <= chartYears["finalYrIdx"]; t++){
            pt.yearArr.push(t);
        }
    }
    else {
        numCols = 3;//first year, last year, and +2 for extra col on the side, and difference math
        pt.yearArr.push(startYear);
        pt.yearArr.push(chartYears["finalYrIdx"]);
    }

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(projInfo['firstYear'], t);
        col++;
    }

    pt.tableData.value[0][0] = RS('GB_stInterventionPercent');
    pt.hasData = false;

    let Coverage = new Array(CoverageTypes);

    Coverage[RegularCov] = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_Coverage_MV3)["value"];
    Coverage[EarlyInitCov] = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_EarlyInitBFPrev_MV2)["value"];
    Coverage[VaccineCov] = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_RoutDetVaccCov_MV3)["value"];
    Coverage[VaccineRetro] = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_RoutDetVaccCov_MV3)["retro"];
    Coverage[ARVRegimen] = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ARVRegimen_MV3)["vValue"];
    Coverage[NeedPMTCT] = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ChildNeedPMTCT_MV)["vValue"];
    Coverage[OnPMTCT] = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ChildOnPMTCT_MV)["vValue"];
    Coverage[ChildARTByAgeGroupPerNum] = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ChildARTByAGPerNum_MV2)["vValue"];
    Coverage[ChildTreatInputs] = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ChildTreatInputs_MV3)["vValue"];
    Coverage[ChildARTCalc] = getModvarCloneByTag(CSModvars, CONSTANTS.DP_TGX_ChildARTCalc_MV2)["vValue"];
    Coverage[CPR] = getModvarCloneByTag(CSModvars, CONSTANTS.FP_TG_Tprevalence_MV)["value"];

    let AIM_IV_Set = [CONSTANTS.IV_PMTCT, CONSTANTS.IV_Cotrimoxazole, CONSTANTS.IV_ART];

    let IVGroupSets = new Array(CONSTANTS.CS_LiSTOnline_MaxGroups);

    for(let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++) {
        IVGroupSets[g] = [];
        let groupMstID = CSGetGroupMstIDFromLiSTOnlineID(g);
        for (let i = 0; i < IVInfo.length; i++) {
            let IntervRec = IVInfo[i];

            IntervRec.selected = SelectedIVSet.includes(IntervRec.MstID);

            let ScaleupFound = false;

            let startIdx = BYIndex;
            let isVaccine = CSGetVaccCurrID(IntervRec["MstID"]) !== 0;
            if (isVaccine){
                startIdx = 0;
            }

            let baseYrVal = GetCovValue(Coverage, AIM_IV_Set, IntervRec, startIdx, isVaccine);

            /* First loop is to find out if nonZero was found for the intervRec */
            if (isVaccine){
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let t = pt.yearArr[yr];
                    let realYear = t;
                    if (t < 1){
                        realYear += 3;
                    }
                    if (GetCovValue(Coverage, AIM_IV_Set, IntervRec, realYear, (t < 1)) !== baseYrVal) {
                        ScaleupFound = true;
                    }
                }
            }

            if (!retroYears) {
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let t = pt.yearArr[yr];
                    if (GetCovValue(Coverage, AIM_IV_Set, IntervRec, t, false) !== baseYrVal) {
                        ScaleupFound = true;
                    }
                }
            }

            /* Second loop prints out the rows if nonzero was found */
            if ((IntervRec.selected && !retroYears) || (showIVsWithImpact && ScaleupFound)){
                pt.hasData = true;
                if (!IntervRec.selected) {
                    pt.hasDeselectedIntervs = true
                }
                if (IntervRec["GroupMstID"] === groupMstID) {
                    IVGroupSets[g].push(IntervRec);
                }
            }
        }
    }

    let row = 1;

    // let ScaleupFound = false;
    // /* First loop is to find out if nonZero was found for the intervRec */
    // let baseYrVal = CPR[BYIndex];
    // pt.yearArr.forEach(function (t) {
    //     if (CPR[t] !== baseYrVal) {
    //         ScaleupFound = true;
    //     }
    // });
    //
    // /* Second loop prints out the rows if nonzero was found */
    // if (showIVsWithImpact && ScaleupFound){
    //     pt.hasData = true;
    //     pt.hasDeselectedIntervs = true;
    //
    //     pt = addRowToPackTable(pt);
    //     pt.tableData.value[row][0] = 'Family planning';
    //     pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
    //     pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
    //     row++;
    //     pt = addRowToPackTable(pt);
    //
    //     pt.GBRowCount = row;
    //     pt = SetRowText(pt, numCols, 'Contraceptive prevalance', GB_CONSTANTS.GB_ED_Indent1, null);
    //     row = pt.GBRowCount;
    //
    //     let col = 1;
    //     pt.yearArr.forEach(function (t) {
    //         pt.tableData.value[row][col] = CPR[t];
    //         col++;
    //     });
    //
    //     row++;
    // }

    for (let g = 1; g <= CONSTANTS.CS_LiSTOnline_MaxGroups; g++){
        if (IVGroupSets[g].length !== 0) {
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = CSGetLiSTOnlineGroupStr(g, true);
            pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
            row++;

            for(let index = 0; index < IVGroupSets[g].length; index++){
                let IntervRec = IVGroupSets[g][index];
                let mstID = IntervRec["MstID"];
                if (!(ResultExcludeMstIDSet().includes(mstID) || DirectEntryMstIDSet().includes(mstID))){
                    // let SumTotal = IntervRec["SumTotal"];

                    pt.hasData = true;
                    pt = addRowToPackTable(pt);

                    let s;
                    if (mstID === CONSTANTS.IV_ChangesInBF){
                        s = RS('GB_stBFPromo');
                    }
                    else{
                        s = CSGetMstIDName(CSModvars, language, IntervRec);
                    }

                    pt.GBRowCount = row;
                    pt = SetRowText(pt, numCols, s, GB_CONSTANTS.GB_ED_Indent1, IntervRec);
                    row = pt.GBRowCount;

                    // if (IntervRec["RequiredIntervs"].intArray.length > 0) {
                    //     if (!SumTotal && (mstID !== CONSTANTS.IV_PentaVacc)) {
                    //         pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent2)
                    //     } else {
                    //         pt.tableData.value[row][0] = '*' + pt.tableData.value[row][0];
                    //     }
                    // }

                    if (!IntervRec.selected) {
                        pt.tableData.value[row][0] += '*';
                    }

                    col = 1;
                    for (let yr = 0; yr < pt.yearArr.length; yr++){
                        let t = pt.yearArr[yr];
                        let realYear = t;
                        if (t < 1){
                            realYear += 3;
                        }
                        pt.tableData.value[row][col] = GetCovValue(Coverage, AIM_IV_Set, IntervRec, realYear, (t < 1));
                        col++;
                    }

                    row++;
                }
            }
        }
    }

    pt.GBRowCount = row;
    pt = resizePackTable(pt, row, numCols);

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    return pt;
};
