import React from 'react';
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import GBStdChart from "../../../components/GB/GBStdChart";
import {CSEditor} from '../../../components/CS/CSEditors';
import {getCoverage} from '../data/Coverage';
// import {generateResult} from "results/CS/data/generateResult";

const styles = {
    title: {
        marginBottom: "0.5em",
        fontSize: "1em",
        marginTop: "0px"
    },
    rule: {
        margin: "25px"
    }
};

const DisplayCoverage = (props) => {
    const { firstYear, finalYear, projectionName, displayEndpoints, IVInfo, CSModvars, language, chartYears, retroYears, coverageDisplay, stacked, legendReversed } = props;

    let projInfo = {
        "firstYear" : firstYear,
        "finalYear" : finalYear,
        "projName"  : projectionName
    };

    // let resultDisplay = generateResult(IVInfo, IVGroupInfo, CSModvars, language, projInfo, chartYears, retroYears, {modID: CONSTANTS.GB_CS, mstID: CONSTANTS.CS_MstCoverageInd});
    let resultDisplay = getCoverage(IVInfo, CSModvars, language, projInfo, chartYears, retroYears);

    let content = JSON.parse(JSON.stringify(resultDisplay.packTable));
    if (displayEndpoints) {
        for (let i = 0; i < content.tableData.value.length; i++) {
            content.tableData.value[i] = [content.tableData.value[i][0], content.tableData.value[i][1], content.tableData.value[i][content.tableData.value[i].length - 1]];
        }
    }

    let graph = JSON.parse(JSON.stringify(resultDisplay.packChart));

    if (displayEndpoints) {
        for (let i = 0; i < graph.chartData.length; i++) {
            graph.chartData[i] = [graph.chartData[i][0], graph.chartData[i][graph.chartData[i].length - 1]];
        }
        graph.pointLabels = [graph.pointLabels[0], graph.pointLabels[graph.pointLabels.length - 1]];
    }

    if (coverageDisplay === CONSTANTS.CS_TableChart) {
        return (
            <React.Fragment>
                <hr style={styles.rule} />
                <h1 style={styles.title}>{retroYears ? RS("GB_stVaxCov") + " (" +  RS("GB_stWRetroYrs") + ")" : RS("GB_stCurrCov")}</h1>
                <CSEditor
                    mstID={CONSTANTS.CS_MstCoverageInd}
                    showTitle={true}
                    packTable={content}
                    isResult={true}
                />
            </React.Fragment>
        );
    }
    else {
        return (
            <React.Fragment>
                <hr style={styles.rule} />
                <h1 style={styles.title}>{retroYears ? RS("GB_stVaxCov") + " (" +  RS("GB_stWRetroYrs") + ")" : RS("GB_stCurrCov")}</h1>
                <GBStdChart
                    id             = {"coverage_chart"}
                    packChart      = {graph}
                    key            = {1}
                    minHeight      = {500}
                    maxHeight      = {500}
                    chartType      = {coverageDisplay}
                    stacked        = {stacked}
                    legendReversed = {legendReversed}
                    options        = {{
                        tooltip : {
                            shared: false,
                            formatter: undefined
                        },
                    }}
                />
            </React.Fragment>
        );
    }
};

export default DisplayCoverage;