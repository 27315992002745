import React from 'react';
import PropTypes from 'prop-types';
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import YesButton from "../buttons/YesButton";
import NoButton from "../buttons/NoButton";

class DeletionCheckDialog extends React.Component {

    static propTypes = {
        fileToDelete       : PropTypes.string,
        onDeleteProjection : PropTypes.func
    };

    static defaultProps = {
        fileToDelete       : "Projection Name not found",
        onDeleteProjection : () => console.log("onDeleteProjection")
    };

    onYes = () => {
        this.props.onDeleteProjection(this.props.fileToDelete);
        this.props.onStateChange({
            dialogActionsOpen: false,
            dialogDeletionCheckOpen: false
        });
    };

    onClose = () => {
        this.props.onStateChange({
            dialogDeletionCheckOpen: false
        });
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stConfirmDeletion')}
                onClose={this.onClose}
                iconType="Warning"
                content={
                    <div>
                        {RS("GB_stAreYouSure") + " " + this.props.fileToDelete + "?"}
                    </div>
                }
                actions={
                    <div>
                        <NoButton onClick={this.onClose} colorType="secondary" />
                        <YesButton onClick={this.onYes} />
                    </div>
                }
            />
        );
    }
}

export default DeletionCheckDialog;