import * as React from 'react';
import PropTypes from "prop-types";
import 'react-input-range/lib/css/index.css';
import Paper from "@material-ui/core/Paper";
import TExpansionPanel from "@common/components/TExpansionPanel";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import DisplayMO from '../../pages/CS/TOOLS/MissedOpportunities/content/DisplayMO';
import CSExpandMOBlueBox from "../../editors/CS/cards/SelectInterventions/CSExpandMOBlueBox";

class MOInterventionSection extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onOutcomeSwitchChange          : PropTypes.func,
        onSelectAllMOItervClick        : PropTypes.func,
        displayChart                   : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayAdolescentCOD           : PropTypes.bool,
        displayNutritionCOD            : PropTypes.bool,
        displayBirthOutcomeCOD         : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        adolescentCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        isDeskTop                      : PropTypes.bool,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        isExpandMODrawerClosed         : PropTypes.bool,
        language                       : PropTypes.number,
        showMOIntervSection            : PropTypes.bool,
        onOpenHelpDialog               : PropTypes.func,
        IVInfo                         : PropTypes.array
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        onPageChange                   : () => console.log('onPageChange'),
        onOutcomeSwitchChange          : () => console.log("onOutcomeSwitchChange"),
        onSelectAllMOItervClick        : () => console.log('onSelectAllMOItervClick'),
        displayChart                   : true,
        selectedCountry                : 'Afghanistan',
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayAdolescentCOD           : false,
        displayNutritionCOD            : false,
        displayBirthOutcomeCOD         : false,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        adolescentCausesOfDeathContent : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        isDeskTop                      : true,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        isExpandMODrawerClosed         : true,
        language                       : CONSTANTS.GB_English,
        showMOIntervSection            : false,
        onOpenHelpDialog               : () => console.log("onOpenHelpDialog"),
        IVInfo                         : []
    };

    //==================================================================================================================
    //
    //                                             Functions
    //
    //==================================================================================================================

    getLegendOptions = () => {
        let legend = {
            reversed : true,
            itemStyle : {
                fontSize : '10px'
            },
            align: 'left',
            layout: 'horizontal',
            itemDistance: 10,
            itemMarginTop: -10
        };

        return(legend)
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderExpandZoom = () => {
        // let zoomCaption = (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ?
        //                     RS('GB_stViewPotentialStuntingAverted')
        //                     :
        //                     ((this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ?
        //                     RS('GB_stViewPotentialDeathsAverted') : RS('GB_stViewPercentRedInMort'));

        let zoomCaption = RS('GB_stMissedOpportunities');

        return (
            <div>
                <CSExpandMOBlueBox
                    onStateChange                  = {this.props.onStateChange}
                    onPageChange                   = {this.props.onPageChange}
                    onOutcomeSwitchChange          = {this.props.onOutcomeSwitchChange}
                    caption                        = {''}
                    zoomCaption                    = {zoomCaption}
                    isDeskTop                      = {this.props.isDeskTop}
                    displayChart                   = {this.props.displayChart}
                    selectedCountry                = {this.props.selectedCountry}
                    displayMaternalCOD             = {this.props.displayMaternalCOD}
                    displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                    displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                    displayChildCOD                = {this.props.displayChildCOD}
                    displayNutritionCOD            = {this.props.displayNutritionCOD}
                    displayBirthOutcomeCOD         = {this.props.displayBirthOutcomeCOD}
                    displayCPR                     = {this.props.displayCPR}
                    deliveryPointsContent          = {this.props.deliveryPointsContent}
                    maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                    stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                    neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                    childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                    sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                    sortByInterventionCost         = {this.props.sortByInterventionCost}
                    viewInterventionCost           = {this.props.viewInterventionCost}
                    MOCountryData                  = {this.props.MOCountryData}
                    maternalIntervSelected         = {this.props.maternalIntervSelected}
                    stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                    neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                    childIntervSelected            = {this.props.childIntervSelected}
                    MOResultToDisplay              = {this.props.MOResultToDisplay}
                    isExpandMODrawerClosed         = {this.props.isExpandMODrawerClosed}
                    language                       = {this.props.language}
                    onOpenHelpDialog               = {this.props.onOpenHelpDialog}
                />

            </div>
            )
    };

    renderDisplayMO = () => {
        return (
            <DisplayMO
                displayChart                   = {true}
                showLegend                     = {(this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted)}
                minHeightChart                 = {415}
                maxHeightChart                 = {415}
                minHeightTable                 = {415}
                maxHeightTable                 = {415}
                chartMarginLeft                = {150}
                xAxisLabelsPadding             = {20}
                stretch                        = {false}
                spaceReduction                 = {0}
                legend                         = {this.getLegendOptions()}
                creditsEnabled                 = {false}
                MOChartID                      = {'MOChart-IntervSec'}
                MOTableID                      = {'MOTable-IntervSec'}
                showTopInterv                  = {true}
                displayMODownloadButton        = {false}
                selectedCountry                = {this.props.selectedCountry}
                displayMaternalCOD             = {this.props.displayMaternalCOD}
                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                displayChildCOD                = {this.props.displayChildCOD}
                displayAdolescentCOD           = {this.props.displayAdolescentCOD}
                displayNutritionCOD            = {this.props.displayNutritionCOD}
                displayBirthOutcomeCOD         = {this.props.displayBirthOutcomeCOD}
                displayCPR                     = {this.props.displayCPR}
                deliveryPointsContent          = {this.props.deliveryPointsContent}
                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                adolescentCausesOfDeathContent = {this.props.adolescentCausesOfDeathContent}
                sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                sortByInterventionCost         = {this.props.sortByInterventionCost}
                viewInterventionCost           = {this.props.viewInterventionCost}
                MOCountryData                  = {this.props.MOCountryData}
                onStateChange                  = {this.props.onStateChange}
                MOResultToDisplay              = {this.props.MOResultToDisplay}
                language                       = {this.props.language}
            />
        )
    };

    render() {
        let styles = {
            paperBox: {
                width: '388px',
                marginBottom: '15px',
                float: 'left',
                background: '#FFFFFF',
                borderRadius: 0,
                border: '1px solid #ebebeb',
                boxShadow: 'none',
            },
            expansionPanel: {
                marginTop: 0,
                marginBottom: 0,
            },
            expansionPanelSummary : {
                backgroundColor: '#3B78BC',
                maxHeight: '45px',
                minHeight: 0,
                width: '388px',
                marginLeft: '-1px'
            },
            header : {
                fontSize: '22px',
                color: '#FFFFFF'
            },
            expandMoreIcon : {
                color: '#FFFFFF'
            },
            expansionPanelDetails : {
                display: 'block',
                backgroundColor: '#FFFFFF',
                fontSize: '13px',
                paddingLeft: '5px',
                paddingRight: '5px',
                paddingTop: '5px',
                paddingBottom: '0px'
            },
            expansionPanelActionStyle : {
                paddingTop: 0,
                paddingBottom: 5,
                marginTop: '-15px'
            },
            buttonStyle: {
                fontSize: '16px',
            },
        };
        return (

            <Paper style={styles.paperBox}>
                <TExpansionPanel
                    onButtonClick={this.props.onSelectAllMOItervClick}
                    items={[[0, RS('GB_stMissedOpportunities'), false, '', false]]}
                    content={[
                                <div>
                                    {this.renderExpandZoom()}
                                    {(this.props.isExpandMODrawerClosed)? this.renderDisplayMO() : null}
                                </div>
                    ]}
                    defaultExpanded={this.props.showMOIntervSection}
                    expansionPanelStyle={styles.expansionPanel}
                    expansionPanelSummaryStyle={styles.expansionPanelSummary}
                    expandMoreIconStyle={styles.expandMoreIcon}
                    expansionPanelDetailsStyle={styles.expansionPanelDetails}
                    expansionPanelActionStyle={styles.expansionPanelActionStyle}
                    headerStyle={styles.header}
                    showButton={true}
                    buttonLabel={RS('GB_stAddAll')}
                    buttonStyle={styles.buttonStyle}
                    buttonVariant={'text'}
                    buttonToolTip={(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? RS('GB_stTop90StuntingAverted') : RS('GB_stTop90DeathsAverted')}
                 />
             </Paper>
        );
    }
}

export default MOInterventionSection;