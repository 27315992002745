import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getModvarCloneByTag,
    getNewPT,
    resizePackTable,
    CloneObj,
    getIdxFromYear,
    getYearFromIdx,
    lockPackTable,
    generatePackChart,
    add_NoHistoricalData_MsgToPackTable,
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";

export const AbortionIncRatioEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear, useFamPlanOutputs, exploreData = false) => {
        let editorName = RS('GB_stAbortIncRatio');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = AbortionIncRatioEditor.onCheckBoxClick(packTable, useFamPlanOutputs, exploreData);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_Abortion;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars, useFamPlanOutputs) => {
        let changedModvars = [];
        let AbortIncRatioMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_AbortIncRatio_MV2);
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            if (!useFamPlanOutputs) {
                AbortIncRatioMV["value"][t] = pt.tableData.value[1][col];
            }
            else{
                AbortIncRatioMV["value"][t] = pt.databuffer[col];
            }
            col++;
        }
        AbortIncRatioMV["source"][0][0] = pt.Source[0];
        changedModvars.push(AbortIncRatioMV);
        return (changedModvars);
    },

    onCheckBoxClick : (pt, useFamPlanOutputs, exploreData) => {
        if (useFamPlanOutputs) {
            pt.databuffer = CloneObj(pt.tableData.value[1]);
            pt = lockPackTable(pt, 1, true);
            for (let c = 1; c < pt.GBColCount; c++) {
                pt.tableData.value[1][c] = '';
            }
        }
        else {
            pt.tableData.value[1] = CloneObj(pt.databuffer);
            pt = lockPackTable(pt, 1, exploreData, false);
        }

        return (pt);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_OneEditorSrc;

    pt.yearArr = [];
    let numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
    for(let t = StartIndex; t <= EndIndex; t++){
        pt.yearArr.push(t);
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        pt.Alignments[1][col] = CONSTANTS.GB_Right;
        pt.RDec[1][col] = 2;
        col++;
    }

    pt.tableData.value[1][0] = RS('GB_stAbortIncRatio');

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let AbortIncRatio = getModvarByTag(CSModvars, CONSTANTS.CS_TG_AbortIncRatio_MV2);
    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        if (AbortIncRatio["value"][t] >= 0) {
            pt.tableData.value[1][col] = AbortIncRatio["value"][t];
        }
        col++;
    }
    pt.Source[0] = AbortIncRatio["source"][0][0];
    pt.databuffer = CloneObj(pt.tableData.value[1]);
    return pt;
};

