import React from 'react';
import RS from "@common/strings/RS";
import TButton from './TButton';

class ErrorBoundaryPage extends React.Component {

    state = { 
        hasError : false 
    }; 

    static getDerivedStateFromProps(props, state) {
        if (typeof props.children === 'undefined') {
            return {
                hasError: true
            };
        }
        else
            return null

    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { 
            hasError: true 
        };
    }
  
    componentDidCatch(error, info) {
        console.log('Logged: ' + error + ' ------ ' + info);
    }
  
    render() {

        let styles = {
            error : {
                background: '#ffd0ce',
                padding: '20px',
                boxSizing: 'border-box',
                borderRadius: '5px',
                border: '1px solid #BBBBBB',
                width: '320px',
                margin: '0 auto',
            },
            header : {
                fontSize: '24px',
                color: 'red',
                textAlign: 'center',
                padding: '10px',
                fontWeight: 'bold',
            },
            sadFace : {
                fontSize: '50px',
                textAlign: 'center',
                margin: '50px'
            },
            buttons : {
                width: '130px'
            }
        };

        if (this.state.hasError) {
            return (
                <div style={this.props.style}>

                    <div style={styles.error}>

                        <p style={styles.header}>{RS('GB_stPageProblem')}</p>

                        <p style={styles.sadFace}>:(</p>

                        <p>{RS('GB_stPageHasProblem')}</p>

                        <hr />

                        <TButton caption={RS('GB_stReloadPage')} style={styles.buttons}></TButton>
                        <TButton caption={RS('GB_stHome')} style={styles.buttons}></TButton>

                    </div>

                </div>
            );
        }
  
        return this.props.children; 
    }
}

export default ErrorBoundaryPage;