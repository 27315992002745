import React from 'react';
import PropTypes from "prop-types";
import TEdit from "@common/components/TEdit";
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";
import SendNewPasswordButton from "../buttons/SendNewPasswordButton";

class ForgottenPasswordDialog extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange  : PropTypes.func,
    };

    static defaultProps = {
        onStateChange  : () => console.log('onStateChange'),
    };

    state = {
        emailVal : "",
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onType = (val) => {
        this.setState({
            emailVal: val
        });
    };
    
    onClose = () => {
        this.props.onStateChange({
            dialogForgotPasswordOpen: false
        });
    };

    onResetPasswordBtnClick = () => {
        // TODO: Do something here
        this.onClose();
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        const styles = {
            edit : {
                width: '100%',
                margin: '30px 0',
            }
        };

        return (
            <CSDialog
                open={true}
                modal={true}
                title={RS('GB_stForgottenPassword')}
                onClose={this.onClose}
                content={
                    <div>
                        <TEdit
                            name="EmailField"
                            caption={RS("GB_stEmail")}
                            value={this.state.emailVal}
                            onChange={this.onType}
                            style={styles.edit}
                            type="email"
                            foc={true}
                            shrinkLabel={true}
                        />
                    </div>
                }
                actions={
                    <div>
                        <SendNewPasswordButton onClick={this.onResetPasswordBtnClick} />
                    </div>
                }
            />
        );
    }

}

export default ForgottenPasswordDialog;