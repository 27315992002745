import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../../components/CS/CSEditors';

class CSEffRiskBO extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        BirthIntervalsEditorPackTable                      : PropTypes.object,
        MatAgeAndBirthOrderEditorPackTable                 : PropTypes.object,
        MaternalNutritionEditorPackTable                   : PropTypes.object,
        onBirthIntervalsEditorPackTableChange              : PropTypes.func,
        onMatAgeAndBirthOrderEditorPackTableChange         : PropTypes.func,
        onMaternalNutritionEditorPackTableChange           : PropTypes.func,
    };

    static defaultProps = {
        BirthIntervalsEditorPackTable                      : null,
        MatAgeAndBirthOrderEditorPackTable                 : null,
        MaternalNutritionEditorPackTable                   : null,
        onBirthIntervalsEditorPackTableChange              : (packTable) => console.log('onBirthIntervalsEditorPackTableChange'),
        onMatAgeAndBirthOrderEditorPackTableChange         : (packTable) => console.log('onMatAgeAndBirthOrderEditorPackTableChange'),
        onMaternalNutritionEditorPackTableChange           : (packTable) => console.log('onMaternalNutritionEditorPackTableChange'),
    };

    state = {
        BirthIntervalsEditorPackTable              : JSON.parse(JSON.stringify(this.props.BirthIntervalsEditorPackTable)),
        MatAgeAndBirthOrderEditorPackTable         : JSON.parse(JSON.stringify(this.props.MatAgeAndBirthOrderEditorPackTable)),
        MaternalNutritionEditorPackTable           : JSON.parse(JSON.stringify(this.props.MaternalNutritionEditorPackTable)),
    };

    //==================================================================================================================
    //
    //                                                 functions
    //
    //==================================================================================================================

    getImpactsBirthOutcomesEditors = () => {
        return (
            <div>
                <CSEditor
                    mstID={CONSTANTS.CS_MstAgeAndBirthOrderSheetID}
                    packTable={this.state.MatAgeAndBirthOrderEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onMatAgeAndBirthOrderEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstBirthIntervalsSheetID}
                    packTable={this.state.BirthIntervalsEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onBirthIntervalsEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstMaternalNutritionSheetID}
                    packTable={this.state.MaternalNutritionEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onMaternalNutritionEditorPackTableChange}
                />
            </div>
        )
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        return (
            <div>
                {this.getImpactsBirthOutcomesEditors()}
            </div>
        );
    }

}

export default CSEffRiskBO;