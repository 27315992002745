import React from 'react';
import PropTypes from "prop-types";
import TLabel from "@common/components/TLabel";
import CONSTANTS from "utilities/CS/CSConst";
// import LiSTVisualizerInfo from '../../SelectInterventions/LiSTVisualizerInfo'
import YourInterventions from '../../../../components/SelectInterventions/YourInterventions'
import InterventionCheckBoxes from '../../../../components/SelectInterventions/InterventionCheckBoxes'
import MOInterventionSection from "../../../../components/SelectInterventions/MOInterventionSection";

class CSInterventionsDrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onInterventionListChange       : PropTypes.func,
        onPregnancyQualityOnChange     : PropTypes.func,
        onChildbirthQualityOnChange    : PropTypes.func,
        onIncludeFamPlanChange         : PropTypes.func,
        onOutcomeSwitchChange          : PropTypes.func,
        getIntervShowing               : PropTypes.func,
        interventionGroupList          : PropTypes.array,
        interventionSubGroupList       : PropTypes.array,
        selectedIVSet                  : PropTypes.array,
        IVInfo                         : PropTypes.array,
        pregnancyQualityOn             : PropTypes.bool,
        childbirthQualityOn            : PropTypes.bool,
        includeFamPlan                 : PropTypes.bool,
        displayChart                   : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayAdolescentCOD           : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        adolescentCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        isDeskTop                      : PropTypes.bool,
        showMOIntervSection            : PropTypes.bool,
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        nutritionSelected              : PropTypes.bool,
        birthOutcomeSelected           : PropTypes.bool,
        adolescentsSelected            : PropTypes.bool,
        selectAllIntervBool            : PropTypes.bool,
        topMOInterventions             : PropTypes.array,
        MOResultToDisplay              : PropTypes.number,
        isExpandMODrawerClosed         : PropTypes.bool,
        language                       : PropTypes.number,
        onOpenHelpDialog               : PropTypes.func,
        updateSelectAllIntervBool      : PropTypes.func,
        onSelectAllIntervCBClick       : PropTypes.func,
    };

    static defaultProps = {
        onStateChange                  : (value) => console.log('CSInterventionsDrawer: onStateChange ' + JSON.stringify(value)),
        onPageChange                   : () => console.log('CSInterventionsDrawer: onPageChange'),
        onInterventionListChange       : () => console.log('CSInterventionsDrawer: onInterventionListChange'),
        onPregnancyQualityOnChange     : () => console.log('CSInterventionsDrawer: onPregnancyQualityOnChange'),
        onChildbirthQualityOnChange    : () => console.log('CSInterventionsDrawer: onChildbirthQualityOnChange'),
        onIncludeFamPlanChange         : () => console.log('CSInterventionsDrawer: onIncludeFamPlanChange'),
        onOutcomeSwitchChange          : () => console.log('CSInterventionsDrawer: onOutcomeSwitchChange'),
        getIntervShowing               : () => console.log('CSInterventionsDrawer: getIntervShowing'),
        interventionGroupList          : [],
        interventionSubGroupList       : [],
        selectedIVSet                  : [],
        IVInfo                         : [],
        pregnancyQualityOn             : false,
        childbirthQualityOn            : false,
        includeFamPlan                 : false,
        displayChart                   : true,
        selectedCountry                : 'Afghanistan',
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayAdolescentCOD           : false,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        adolescentCausesOfDeathContent : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        isDeskTop                      : true,
        showMOIntervSection            : false,
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        nutritionSelected              : true,
        birthOutcomeSelected           : true,
        adolescentsSelected            : false,
        selectAllIntervBool            : false,
        topMOInterventions             : [],
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        isExpandMODrawerClosed         : true,
        language                       : CONSTANTS.GB_English,
        onOpenHelpDialog               : () => console.log("onOpenHelpDialog"),
        updateSelectAllIntervBool      : () => console.log('updateSelectAllIntervBool'),
        onSelectAllIntervCBClick       : () => console.log('onSelectAllIntervCBClick')
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onInterventionListChange = (value) => {
        this.props.onInterventionListChange(value);
    };

    onPregnancyQualityOnChange = (value) => {
        this.props.onPregnancyQualityOnChange(value);
    };

    onChildbirthQualityOnChange = (value) => {
        this.props.onChildbirthQualityOnChange(value);
    };

    onIncludeFamPlanChange = (value) => {
        this.props.onIncludeFamPlanChange(value);
    };

    onCheckBoxChange = (checked, groupMstID, mstID, caption) => {
        let selectedIVSet = JSON.parse(JSON.stringify(this.props.selectedIVSet));

        let flags = {
            maternalSelected       : this.props.maternalIntervSelected,
            stillbirthSelected     : this.props.stillbirthIntervSelected,
            neonatalSelected       : this.props.neonatalIntervSelected,
            childSelected          : this.props.childIntervSelected,
            adolescentSelected     : this.props.adolescentsSelected,
            nutritionSelected      : this.props.nutritionSelected,
            birthOutcomeSelected   : this.props.birthOutcomeSelected,
            nutritionIV            : this.props.nutritionSelected,
            birthOutcomeIV         : this.props.birthOutcomeSelected
        };

        for (let i = 0; i < this.props.IVInfo.length; i++){
            let IVRec = this.props.IVInfo[i];
            if (IVRec.MstID === mstID) {
                /* If checked and master ID not already added to selectedIVSet, add it. */
                if (!selectedIVSet.includes(mstID)) {
                    if (checked) {
                        selectedIVSet.push(mstID);
                    }
                }
                /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                   *remove the master ID from the selectedIVSet. */
                else if (!checked){
                    selectedIVSet.splice(selectedIVSet.indexOf(mstID), 1);
                    this.props.updateSelectAllIntervBool(false);
                }
                let RequiredIntervs = IVRec['RequiredIntervs'].intArray;
                if (RequiredIntervs.length > 0) {
                    for (let k = 0; k < RequiredIntervs.length; k++) {
                        for (let j = 0; j < this.props.IVInfo.length; j++) {
                            let IVRec2 = this.props.IVInfo[j];
                            if (IVRec2.MstID === RequiredIntervs[k]) {
                                /* If checked and master ID not already added to selectedIVSet, add it. */
                                if (!selectedIVSet.includes(IVRec2.MstID)) {
                                    /* Only add required intervention if it's showing with the current outcome switch
                                       settings. */
                                    if (checked && this.props.getIntervShowing(flags, IVRec2)) {
                                        selectedIVSet.push(IVRec2.MstID);
                                    }
                                }
                                /* Otherwise, if the selectedIVSet *does have the master ID in it, and *unchecked,
                                   *remove the master ID from the selectedIVSet. */
                                else if (!checked) {
                                    selectedIVSet.splice(selectedIVSet.indexOf(IVRec2.MstID), 1);
                                    this.props.updateSelectAllIntervBool(false);
                                }
                            }
                        }
                    }
                }
            }
        }

        if ((mstID === CONSTANTS.CS_MstLiSTOnlineDirectEntryOfWasting) && (checked)){
            for (let i = 0; i < this.props.IVInfo.length; i++) {
                let id = this.props.IVInfo[i].MstID;
                if ((id === CONSTANTS.IV_MstTrMAM) || (id === CONSTANTS.IV_MstSAM)) {
                    if (selectedIVSet.includes(id)) {
                        selectedIVSet.splice(selectedIVSet.indexOf(id), 1);
                        this.props.updateSelectAllIntervBool(false);
                    }
                }
            }
        } 
        else if ((mstID === CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF) && (checked)){
            for (let i = 0; i < this.props.IVInfo.length; i++) {
                let id = this.props.IVInfo[i].MstID;
                if (id === CONSTANTS.IV_MstChangesInBF_BFPromo){
                    if (selectedIVSet.includes(id)) {
                        selectedIVSet.splice(selectedIVSet.indexOf(id), 1);
                        this.props.updateSelectAllIntervBool(false);
                    }
                }
            }
        } 
        else if ((mstID === CONSTANTS.IV_MstChangesInBF_BFPromo) && (checked)){
            for (let i = 0; i < this.props.IVInfo.length; i++) {
                let id = this.props.IVInfo[i].MstID;
                if (id === CONSTANTS.CS_MstLiSTOnlineDirectEntryOfBF){
                    if (selectedIVSet.includes(id)) {
                        selectedIVSet.splice(selectedIVSet.indexOf(id), 1);
                        this.props.updateSelectAllIntervBool(false);
                    }
                }

                if (id === CONSTANTS.CS_MstEarlyInitBF){
                    if (selectedIVSet.includes(id)) {
                        selectedIVSet.splice(selectedIVSet.indexOf(id), 1);
                        this.props.updateSelectAllIntervBool(false);
                    }
                }
            }
        }

        this.onInterventionListChange({"intArray" : selectedIVSet});
    };

    onQualitySwitchChange = (checked, groupID) => {

        if (groupID === CONSTANTS.CS_GroupMstID_Pregnancy) {
            this.onPregnancyQualityOnChange(checked);
        } 
        else if (groupID === CONSTANTS.CS_GroupMstID_Childbirth) {
            this.onChildbirthQualityOnChange(checked);
        }
    };

    onMoreInfoIconClick = () => {
        this.props.onStateChange({
            dialogNutritionOpen: true
        });
    };

    onClearInterventionsClick = () => {
        this.onInterventionListChange({intArray: Array(0)});
    };

    onSelectAllMOIntervClick = () => {

        let selectedIVSet = JSON.parse(JSON.stringify(this.props.selectedIVSet));

        for (let j = 0; j < this.props.topMOInterventions.length; j++) {
            for (let i = 0; i < this.props.IVInfo.length; i++) {
                if (this.props.IVInfo[i].MstID === this.props.topMOInterventions[j])
                    selectedIVSet.push(this.props.IVInfo[i].MstID);
            }
        }

        this.onInterventionListChange({"intArray" : selectedIVSet});
    };

    //==================================================================================================================
    //
    //                                              Functions
    //
    //==================================================================================================================

    getInterventionLists = () => {
        let interventionLists = [];
        let interventionsByGroup = [];

        let flags = {
            maternalSelected       : this.props.maternalIntervSelected,
            stillbirthSelected     : this.props.stillbirthIntervSelected,
            neonatalSelected       : this.props.neonatalIntervSelected,
            childSelected          : this.props.childIntervSelected,
            adolescentSelected     : this.props.adolescentsSelected,
            nutritionSelected      : this.props.nutritionSelected,
            birthOutcomeSelected   : this.props.birthOutcomeSelected,
            nutritionIV            : this.props.nutritionSelected && !this.props.birthOutcomeSelected,
            birthOutcomeIV         : this.props.birthOutcomeSelected && !this.props.nutritionSelected,
        };

        for (let i = 0; i < this.props.interventionGroupList.length; i++) {
            let IVGroupInfo = this.props.interventionGroupList[i];
            interventionsByGroup = [];
            for (let j = 0; j < this.props.IVInfo.length; j++) {
                let IVRec = this.props.IVInfo[j];
                if ((IVRec.GroupMstID === IVGroupInfo.MstID) && this.props.getIntervShowing(flags, IVRec)) {
                    interventionsByGroup.push([
                        this.getIVName(IVRec.MstID, IVRec.Names[this.props.language]),
                        IVRec.MstID,
                        IVRec.GroupMstID,
                        IVRec.qualityInterv,
                        IVRec.SubGroupMstID,
                    ]);
                }
            }
            if (interventionsByGroup.length > 0) {
                interventionLists.push(interventionsByGroup);
            }
        }
        return (interventionLists)
    };

    // start the i loop at 3 so we don't get breastfeeding, stunting and wasting group names
    getAllInterventionGroupNames = () => {
        let showSwitch = true;
        let switchChecked = false;
        let showMoreInfoIcon = false; //true;
        let groupNames = [];

        for (let i = 0; i < this.props.interventionGroupList.length; i++) {

            if (this.props.interventionGroupList[i].MstID === CONSTANTS.CS_GroupMstID_Pregnancy) {
                groupNames.push([
                    this.props.interventionGroupList[i].MstID,
                    this.props.interventionGroupList[i].Names[this.props.language],
                    !showSwitch,
                    'Quality',
                    this.props.pregnancyQualityOn
                ]);
            } else if (this.props.interventionGroupList[i].MstID === CONSTANTS.CS_GroupMstID_Childbirth) {
                groupNames.push([
                    this.props.interventionGroupList[i].MstID,
                    this.props.interventionGroupList[i].Names[this.props.language],
                    !showSwitch,
                    'Quality',
                    this.props.childbirthQualityOn
                ]);
            } else if (this.props.interventionGroupList[i].MstID === CONSTANTS.CS_GroupMstID_NutritionalStatus) {
                groupNames.push([
                    this.props.interventionGroupList[i].MstID,
                    this.props.interventionGroupList[i].Names[this.props.language],
                    !showSwitch,
                    '',
                    !switchChecked,
                    showMoreInfoIcon
                ]);
            } else {
                groupNames.push([
                    this.props.interventionGroupList[i].MstID,
                    this.props.interventionGroupList[i].Names[this.props.language],
                    !showSwitch,
                    '',
                    !switchChecked
                ]);
            }
        }
        return (groupNames);
    };

    getRelevantInterventionGroupNames = (relevantInterventions) => {
        let GroupMstIdIdx2 = 2;
        let GroupMstIdIdx0 = 0;
        let FirstIntervInGroup = 0;
        let allGroupNames = this.getAllInterventionGroupNames();
        let relevantGroupNames = [];

        for (let i = 0; i < relevantInterventions.length; i++) {
            for (let j = 0; j < allGroupNames.length; j++) {
                if (allGroupNames[j][GroupMstIdIdx0] === relevantInterventions[i][FirstIntervInGroup][GroupMstIdIdx2]) {
                    relevantGroupNames.push(allGroupNames[j])
                }
            }
        }
        return (relevantGroupNames)
    };

    getInterventionSubGroupNames = () => {
        let subGroupNames = [];
        for (let i = 0; i < this.props.interventionSubGroupList.length; i++) {
            subGroupNames.push({
                MstID : this.props.interventionSubGroupList[i].MstID,
                Name  : this.props.interventionSubGroupList[i].Names[this.props.language],
            });
        }

        return (subGroupNames);
    };

    getIVName = (mstID, name) =>{
        let result = name;
        if ([CONSTANTS.CS_MstPercMarried,
            CONSTANTS.CS_MstPercUsingContra,
            CONSTANTS.CS_MstContraMethMix,
            CONSTANTS.CS_MstPercUnintendedPregTermByAbor].includes(mstID) && !this.props.adolescentsSelected){
            result = result + ' (15-49)';
        }
        return result;
    };

    getYourInterventionNames = () => {
        const styles = {
            labelStyle: {
                color: '#0c3a79',
                fontSize: '13px',
                lineHeight : '1',
                paddingTop: '5px',
                paddingBottom: '5px'
            }
        };

        let yourInterventionNames = [];

        for (let i = 0; i < this.props.IVInfo.length; i++) {
            let IVInfo = this.props.IVInfo[i];
            if (this.props.selectedIVSet.includes(IVInfo.MstID)) {
                for (let j = 0; j < this.props.interventionGroupList.length; j++) {
                    let IVGroupInfo = this.props.interventionGroupList[j];
                    if (IVInfo.GroupMstID === IVGroupInfo.MstID) {
                        if (![CONSTANTS.CS_MstFamPlanInt, CONSTANTS.CS_MstHIVInt].includes(IVInfo.MstID)) {
                            let IVName = this.getIVName(IVInfo.MstID, IVInfo.Names[this.props.language]);
                            yourInterventionNames.push(
                                <TLabel
                                    key={IVGroupInfo.Names[this.props.language] + ': ' + IVName}
                                    caption={IVGroupInfo.Names[this.props.language] + ': ' + IVName}
                                    style={styles.labelStyle}
                                />
                            );
                        }
                    }
                }
            }
        }

        return yourInterventionNames;
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    render() {
        let selectedIVSet         = JSON.parse(JSON.stringify(this.props.selectedIVSet));
        let pregnancyQualityOn    = this.props.pregnancyQualityOn;
        let childbirthQualityOn   = this.props.childbirthQualityOn;

        let relevantInterventions = this.getInterventionLists();

        const styles = {
            section : {
                float: 'left',
                display: 'inline-block',
                overflowY: "auto",
                height: window.innerHeight - 84
            },
            section1 : {
                float: 'left',
                //position: 'fixed',
                display: 'inline-block',
                overflowY: 'auto',
                overflowX: 'hidden',
                height: window.innerHeight - 84,
                paddingRight: 20,
                paddingLeft: 20
            },
        };

        return (
            <div className="innerPage" style={{height: window.innerHeight - 100}}>

                <div style={styles.section}>
                    <InterventionCheckBoxes
                        interventionGroupNames    = {this.getRelevantInterventionGroupNames(relevantInterventions)}
                        selectedIVSet             = {selectedIVSet}
                        interventionSubGroupNames = {this.getInterventionSubGroupNames()}
                        pregnancyQualityOn        = {pregnancyQualityOn}
                        childbirthQualityOn       = {childbirthQualityOn}
                        checkBoxInterventionLists = {relevantInterventions}
                        onCheckBoxChange          = {this.onCheckBoxChange}
                        onQualitySwitchChange     = {this.onQualitySwitchChange}
                        onMoreInfoIconClick       = {this.onMoreInfoIconClick}
                        maternalIntervSelected    = {this.props.maternalIntervSelected}
                        stillbirthIntervSelected  = {this.props.stillbirthIntervSelected}
                        neonatalIntervSelected    = {this.props.neonatalIntervSelected}
                        childIntervSelected       = {this.props.childIntervSelected}
                        nutritionSelected         = {this.props.nutritionSelected}
                        birthOutcomeSelected      = {this.props.birthOutcomeSelected}
                        adolescentsSelected       = {this.props.adolescentsSelected}
                        onOutcomeSwitchChange     = {this.props.onOutcomeSwitchChange}
                        MOResultToDisplay         = {this.props.MOResultToDisplay}
                        language                  = {this.props.language}
                        onStateChange             = {this.props.onStateChange}
                        IVInfo                    = {this.props.IVInfo}
                        selectAllIntervBool       = {this.props.selectAllIntervBool}
                        onSelectAllIntervCBClick  = {this.props.onSelectAllIntervCBClick}
                    />
                </div>

                <div style={styles.section1}>
                    {/*<LiSTVisualizerInfo />*/}
                    <div>
                        {
                            // (this.props.showMOIntervSection) ?
                                <MOInterventionSection
                                    onStateChange                  = {this.props.onStateChange}
                                    onPageChange                   = {this.props.onPageChange}
                                    onOutcomeSwitchChange          = {this.props.onOutcomeSwitchChange}
                                    onSelectAllMOItervClick        = {this.onSelectAllMOIntervClick}
                                    displayChart                   = {this.props.displayChart}
                                    selectedCountry                = {this.props.selectedCountry}
                                    displayMaternalCOD             = {this.props.displayMaternalCOD}
                                    displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                                    displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                                    displayChildCOD                = {this.props.displayChildCOD}
                                    displayAdolescentCOD           = {this.props.displayAdolescentCOD}
                                    displayNutritionCOD            = {this.props.nutritionSelected}
                                    displayBirthOutcomeCOD         = {this.props.birthOutcomeSelected}
                                    displayCPR                     = {this.props.displayCPR}
                                    deliveryPointsContent          = {this.props.deliveryPointsContent}
                                    maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                                    stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                                    neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                                    childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                                    adolescentCausesOfDeathContent = {this.props.adolescentCausesOfDeathContent}
                                    sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                                    sortByInterventionCost         = {this.props.sortByInterventionCost}
                                    viewInterventionCost           = {this.props.viewInterventionCost}
                                    MOCountryData                  = {this.props.MOCountryData}
                                    isDeskTop                      = {this.props.isDeskTop}
                                    maternalIntervSelected         = {this.props.maternalIntervSelected}
                                    stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                                    neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                                    childIntervSelected            = {this.props.childIntervSelected}
                                    MOResultToDisplay              = {this.props.MOResultToDisplay}
                                    isExpandMODrawerClosed         = {this.props.isExpandMODrawerClosed}
                                    language                       = {this.props.language}
                                    showMOIntervSection            = {this.props.showMOIntervSection}
                                    onOpenHelpDialog               = {this.props.onOpenHelpDialog}
                                    IVInfo                         = {this.props.IVInfo}
                            />
                            // : null
                        }
                    </div>
                    <div>
                        <YourInterventions
                            yourInterventionNames={this.getYourInterventionNames()}
                            onClearInterventionsClick={this.onClearInterventionsClick}
                        />
                    </div>
                </div>

            </div>
        );
    }

}

export default CSInterventionsDrawer;