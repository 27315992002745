import React from "react";
import PropTypes from "prop-types";
import YesButton from "../buttons/YesButton";
import NoButton from "../buttons/NoButton";
import CSDialog from "./CSDialog";

class TCSUnsavedChangesDialog extends React.Component {

    static propTypes = {
        onStateChange     : PropTypes.func,
        onClose           : PropTypes.func,
    };

    static defaultProps = {
        onStateChange     : () => console.log("onStateChange"),
        onClose           : () => console.log("onClose"),
    };

    onClose = (value) => {
        this.props.onClose(value);

        this.props.onStateChange({
            dialogUnsavedChangesOpen : false 
        });
    };

    render() {
        return (
            <CSDialog
                open={true}
                modal={true}
                title={"You have unsaved changes."}
                onClose={this.onClose}
                content={'Do you still want to continue?'}
                iconType={"Warning"}
                actions={
                    <div>
                        <NoButton onClick={this.onClose} colorType="secondary" />
                        <YesButton onClick={this.onClose} colorType="primary" />
                    </div>
                }
            />
        );
    }
}

export default TCSUnsavedChangesDialog;


