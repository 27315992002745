import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getYearFromIdx,
    getModvarCloneByTag,
    getIdxFromYear,
    indentPackTable,
    lockPackTable,
    addMergedCellsToArray,
    addRowToPackTable
} from "utilities/GB/GBUtil";

import {
    CSGetBirthOrder,
    CSGetAgeGivingBirth,
    CSGetBirthInterval
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

export const FertilityRisksEditor = {
    MatAgeAndBirthOrderID : 1,
    BirthIntervalsID : 2,

    Create : (CSModvars, EditorStartYear, EditorEndYear, editorID) => {
        let editorName;
        switch (editorID){
            case FertilityRisksEditor.MatAgeAndBirthOrderID  : {editorName = RS('GB_stMatAgeAndBirthOrder'); break;}
            case FertilityRisksEditor.BirthIntervalsID : {editorName = RS('GB_stBirthIntervals'); break;}
            default : break;
        }
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, editorID);
        packTable = SetSSData(packTable, CSModvars, editorID);
        packTable = FertilityRisksEditor.RecalcGrid(packTable, editorID);
        packTable.Title = editorName;
        packTable.EditorCat = CONSTANTS.GB_Coverage;
        return (packTable);
    },

    RecalcGrid : (pt, editorID) => {
        switch(editorID){
            case FertilityRisksEditor.MatAgeAndBirthOrderID : {
                let col = 1;
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let row = 0;
                    let sum = 0;
                    for (let a = CONSTANTS.CS_LT18Yrs; a <= CONSTANTS.CS_35_49Yrs; a++) {
                        row++;
                        for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GTThirdBirth; i++) {
                            row++;
                            sum += pt.tableData.value[row][col];
                        }
                    }
                    row++;
                    pt.tableData.value[row][col] = sum;
                    col++;
                }
                break;
            }
            case FertilityRisksEditor.BirthIntervalsID : {
                let col = 1;
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let row = 0;
                    let sum = 0;
                    for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GE24Mths; i++) {
                        row++;
                        sum += pt.tableData.value[row][col];
                    }
                    row++;
                    pt.tableData.value[row][col] = sum;
                    col++;
                }
                break;
            }
            default : break;
        }
        return (pt);
    },

    GetSSData : (pt, CSModvars, editorID) => {
        let changedModvars = [];

        switch (editorID){
            case FertilityRisksEditor.MatAgeAndBirthOrderID : {
                let AgeAndBirthOrderMatrixMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_AgeAndBirthOrderMatrix_MV2);

                let col = 1;
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let t = pt.yearArr[yr];
                    let row = 0;
                    for (let a = CONSTANTS.CS_LT18Yrs; a <= CONSTANTS.CS_35_49Yrs; a++) {
                        row++;
                        for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GTThirdBirth; i++) {
                            row++;
                            AgeAndBirthOrderMatrixMV["value"][a][i][t] = pt.tableData.value[row][col];
                        }
                    }
                    col++;
                }
                changedModvars.push(AgeAndBirthOrderMatrixMV);
                break;
            }
            case FertilityRisksEditor.BirthIntervalsID : {
                let BirthIntervalsMatrixMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_BirthIntervalsMatrix_MV2);

                let col = 1;
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let t = pt.yearArr[yr];
                    let row = 0;
                    for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GE24Mths; i++) {
                        row++;
                        BirthIntervalsMatrixMV["value"][i][t] = pt.tableData.value[row][col];
                    }
                    col++;
                }
                changedModvars.push(BirthIntervalsMatrixMV);
                break;
            }
            default : break;
        }

        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, editorID) => {
    let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
    let BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

    let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
    let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

    let pt = getNewPT();

    pt.yearArr = [];
    let numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
    for(let t = StartIndex; t <= EndIndex; t++){
        pt.yearArr.push(t);
    }

    const numRows = 1;

    pt = resizePackTable(pt, numRows, numCols);

    let col = 1;
    for (let yr = 0; yr < pt.yearArr.length; yr++){
        let t = pt.yearArr[yr];
        pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
        pt.Alignments[0][col] = CONSTANTS.GB_Right;
        col++;
    }

    pt.tableData.value[0][0] = RS('GB_stInterventionPercent');

    switch(editorID){
        case FertilityRisksEditor.MatAgeAndBirthOrderID : {
            let row = 0;
            for (let a = CONSTANTS.CS_LT18Yrs; a <= CONSTANTS.CS_35_49Yrs; a++){
                row++;
                pt = addRowToPackTable(pt);
                pt.tableData.value[row][0] = CSGetAgeGivingBirth(a);
                pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
                pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
                pt = lockPackTable(pt, row, true);
                for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GTThirdBirth; i++){
                    row++;
                    pt = addRowToPackTable(pt);
                    pt.tableData.value[row][0] = CSGetBirthOrder(i);
                    pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
                }
            }
            row++;
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = RS('DP_stTotal');
            pt = lockPackTable(pt, row, true);
            break;
        }
        case FertilityRisksEditor.BirthIntervalsID : {
            let row = 0;
            for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GE24Mths; i++){
                row++;
                pt = addRowToPackTable(pt);
                pt.tableData.value[row][0] = CSGetBirthInterval(i);
            }
            row++;
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = RS('DP_stTotal');
            pt = lockPackTable(pt, row, true);
            break;
        }
        default : break;
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    for(let row = 0; row < pt.GBRowCount; row++){
        for(let col = 0; col < pt.GBColCount; col++){
            if (typeof pt.tableData.value[row][col] === 'number'){
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 1;
            }
        }
    }

    return pt;
};

const SetSSData = (pt, CSModvars, editorID) => {
    switch (editorID){
        case FertilityRisksEditor.MatAgeAndBirthOrderID : {
            let AgeAndBirthOrderMatrix = getModvarByTag(CSModvars, CONSTANTS.CS_TG_AgeAndBirthOrderMatrix_MV2)["value"];
            let col = 1;
            for (let yr = 0; yr < pt.yearArr.length; yr++){
                let t = pt.yearArr[yr];
                let row = 0;
                let sum = 0;
                for (let a = CONSTANTS.CS_LT18Yrs; a <= CONSTANTS.CS_35_49Yrs; a++) {
                    row++;
                    for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GTThirdBirth; i++) {
                        row++;
                        pt.tableData.value[row][col] = AgeAndBirthOrderMatrix[a][i][t];
                        sum += pt.tableData.value[row][col];
                    }
                }
                row++;
                pt.tableData.value[row][col] = sum;
                col++;
            }
            break;
        }
        case FertilityRisksEditor.BirthIntervalsID : {
            let BirthIntervalsMatrix = getModvarByTag(CSModvars, CONSTANTS.CS_TG_BirthIntervalsMatrix_MV2)["value"];
            let col = 1;
            for (let yr = 0; yr < pt.yearArr.length; yr++){
                let t = pt.yearArr[yr];
                let row = 0;
                let sum = 0;
                for (let i = CONSTANTS.CS_FirstBirth; i <= CONSTANTS.CS_GE24Mths; i++) {
                    row++;
                    pt.tableData.value[row][col] = BirthIntervalsMatrix[i][t];
                    sum += pt.tableData.value[row][col];
                }
                row++;
                pt.tableData.value[row][col] = sum;
                col++;
            }
            break;
        }
        default : break;
    }
    return pt;
};

