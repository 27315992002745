import React from "react";
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import CSBlueBox2 from "../../../../components/CS/CSBlueBox2";
import CSCoverageDrawer from "../../drawers/Coverage/CSCoverageDrawer";
import {getModvarByTag} from "utilities/GB/GBUtil";

class CSCoverageBlueBox extends React.Component {

    static propTypes = {
        onStateChange            : PropTypes.func,
        IVInfo                   : PropTypes.arrayOf(PropTypes.object),
        CSModvars                : PropTypes.arrayOf(PropTypes.object),
        captionStyle             : PropTypes.object,
        description              : PropTypes.string,
        showExtraBtn             : PropTypes.bool,
        firstYear                : PropTypes.number,
        showAllInterventionsCB   : PropTypes.bool,
        language                 : PropTypes.number,
        onOpenHelpDialog         : PropTypes.func,
        showRetroSwitch          : PropTypes.bool,
        showQualitySwitch        : PropTypes.bool,
        showBFCB                 : PropTypes.bool,
    };

    static defaultProps = {
        onStateChange            : () => console.log('onStateChange'),
        IVInfo                   : [],
        CSModvars                : [],
        captionStyle             : {},
        description              : '',
        showExtraBtn             : true,
        firstYear                : -1,
        showAllInterventionsCB   : false,
        language                 : CONSTANTS.GB_English,
        onOpenHelpDialog         : () => console.log('onOpenHelpDialog'),
        showRetroSwitch          : false,
        showRetroYears           : false,
        showQualitySwitch        : false,
        showBFCB                 : false,
    };

    state = {
        openDrawer               : false,
    };

    onBlueBoxClick = () => {
        this.setState({
            openDrawer : true,
        });
    };

    onCloseDrawer = () => {
        this.setState({
            openDrawer : false,
        });
    };

    render() {
        return (
            <React.Fragment>
                <CSBlueBox2
                    caption      = {RS('GB_stCoverage')}
                    captionStyle = {this.props.captionStyle}
                    description  = {this.props.description}
                    onClick      = {this.onBlueBoxClick}
                />

                {
                    (this.state.openDrawer) ?
                        <CSCoverageDrawer 
                            onClose                = {this.onCloseDrawer}
                            CSModvars              = {this.props.CSModvars}
                            firstYear              = {this.props.firstYear}
                            finalYear              = {getModvarByTag(this.props.CSModvars, CONSTANTS.CS_TG_FinalYear_MV2)["value"]}
                            language               = {this.props.language}
                            IVInfo                 = {this.props.IVInfo}
                            onOpenHelpDialog       = {this.props.onOpenHelpDialog}
                            onCloseDrawer          = {this.onCloseDrawer}
                            showAllInterventionsCB = {this.props.showAllInterventionsCB}
                            onStateChange          = {this.props.onStateChange}
                            showRetroSwitch        = {this.props.showRetroSwitch}
                            showQualitySwitch      = {this.props.showQualitySwitch}
                            showRetroYears         = {this.props.showRetroYears}
                            showBFCB               = {this.props.showBFCB}
                        /> : null
                }
            </React.Fragment>
        );
    }
}

export default CSCoverageBlueBox;