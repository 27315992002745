import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    getModvarCloneByTag,
    addRowToPackTable,
    lockPackTable,
    indentPackTable,
    addMergedCellsToArray,
    lockPackRowCol,
    generatePackChart, getIdxFromYear, getYearFromIdx, add_NoHistoricalData_MsgToPackTable, setSourceArrLength
} from "utilities/GB/GBUtil";

import {
    CSGetPathogenName,
    CSGetDistrName,
    getAgeCohortName, FindIntervRec
} from "utilities/CS/CSDataUtil";

import CONSTANTS from "utilities/CS/CSConst";
import GB_CONSTANTS from 'utilities/GB/GBConst';

/* Indices for options array */
const DistrIdx = 0;
const AgeIdx   = 1;

export const PathogenEditor = {
    Create: (CSModvars, EditorStartYear, EditorEndYear, options = [0, 0]) => {
        let editorName = RS('GB_stBaseIncPathogens');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear, options);
        packTable = SetSSData(packTable, CSModvars);
        packTable = PathogenEditor.RecalcGrid(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_Pathogens;
        return ([packChart, packTable]);
    },

    RecalcGrid:(pt) => {
        let value, sum, setSum, row;

        /* Calculate other and total */
        pt.ColSets[CONSTANTS.CS_Distr_All].forEach(function (col) {
            row = pt.StartNumRows;
            sum = "";
            setSum = false;
            for (let i = CONSTANTS.CS_FirstPathDiar; i <= CONSTANTS.CS_PathCDiar; i++){
                value = pt.tableData.value[row][col];
                if (value !== "") {
                    if (!setSum) {
                        sum = 0;
                        setSum = true;
                    }
                    sum = sum + value;
                }
                row++;
            }
            if (setSum) {
                pt.tableData.value[row][col] = 100 - sum;
                pt.tableData.value[row + 1][col] = 100;
            }
        });

        let ColSet = [];
        pt.ColSets[CONSTANTS.CS_Distr_Sevr].forEach(function (col) {ColSet.push(col);});
        pt.ColSets[CONSTANTS.CS_Distr_Fatal].forEach(function (col) {ColSet.push(col);});

        ColSet.forEach(function (col) {
            row = pt.StartNumRows;
            sum = "";
            setSum = false;
            for (let i = CONSTANTS.CS_FirstPathDiar; i <= CONSTANTS.CS_PathCDiar; i++){
                value = pt.tableData.value[row][col];
                if (value !== "") {
                    if (!setSum) {
                        sum = 0;
                        setSum = true;
                    }
                    sum = sum + value;
                }
                row++;
            }
            if (setSum) {
                pt.tableData.value[row][col] = 100 - sum;
                pt.tableData.value[row + 1][col] = 100;
            }

            row +=3;

            sum = "";
            setSum = false;
            for (let i = CONSTANTS.CS_FirstPathPneum; i <= CONSTANTS.CS_InfluenzaPathPneum; i++){
                value = pt.tableData.value[row][col];
                if (value !== "") {
                    if (!setSum) {
                        sum = 0;
                        setSum = true;
                    }
                    sum = sum + value;
                }
                row++;
            }
            if (setSum) {
                pt.tableData.value[row][col] = 100 - sum;
                pt.tableData.value[row + 1][col] = 100;
            }

            row +=3;

            sum = "";
            setSum = false;
            for (let i = CONSTANTS.CS_FirstPathMenin; i <= CONSTANTS.CS_MeningitAPathMenin; i++){
                value = pt.tableData.value[row][col];
                if (value !== "") {
                    if (!setSum) {
                        sum = 0;
                        setSum = true;
                    }
                    sum = sum + value;
                }
                row++;
            }
            if (setSum) {
                pt.tableData.value[row][col] = 100 - sum;
                pt.tableData.value[row + 1][col] = 100;
            }
        });

        return (pt);
    },

    GetSSData : (pt, IVInfo, CSModvars) => {
        let changedModvars = [];

        let DiarPathMV  = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_DiarPath_MV2);
        let PneumPathMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PneumPath_MV);
        let MenPathMV   = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_MenPath_MV);
        let row = pt.StartNumRows-1;
        pt.diseaseSet.forEach(function (d) {
            row++;
            let pathSet = GetPathSet(d);
            pathSet.forEach(function (i) {
                for (let col = 1; col <= 12; col++) {
                    let values = GetDistAndAge(col);
                    let val = pt.tableData.value[row][col] / 100;
                    switch (d) {
                        case CONSTANTS.CS_Diarrhea : {DiarPathMV["value"][values[0]][i][values[1]] = val; break;}
                        case CONSTANTS.CS_Pneumonia : {PneumPathMV["value"][values[0]][i][values[1]] = val; break;}
                        case CONSTANTS.CS_Meningitis : {MenPathMV["value"][values[0]][i][values[1]] = val; break;}
                        default : break;
                    }
                }
                switch (d) {
                    case CONSTANTS.CS_Diarrhea   : {DiarPathMV["source"][0][0] = pt.Source[pt.SourceMap[row]]; break;}
                    case CONSTANTS.CS_Pneumonia  : {PneumPathMV["source"][0][0] = pt.Source[pt.SourceMap[row]]; break;}
                    case CONSTANTS.CS_Meningitis : {MenPathMV["source"][0][0] = pt.Source[pt.SourceMap[row]]; break;}
                    default : break;
                }
                row++;
            });
        });

        let SevDiarrIncReductionMV = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_SevDiarrIncReduction_MV);
        let DiarrIncReductionMV    = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_DiarrIncReduction_MV3);
        let PneumIncReductionMV    = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_PneumIncReduction_MV);
        let MeningIncReductionMV   = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_MeningIncReduction_MV);
        let DetVaccEffMV           = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_DetVaccEff_MV2);
        let EfficacyMV             = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_Efficacy_MV2);

        let RotavirusVacc        = FindIntervRec(IVInfo, CONSTANTS.IV_RotavirusVacc);
        let DiarVaccPathB        = FindIntervRec(IVInfo, CONSTANTS.IV_DiarVaccPathB);
        let DiarVaccPathC        = FindIntervRec(IVInfo, CONSTANTS.IV_DiarVaccPathC);
        let HibVacc              = FindIntervRec(IVInfo, CONSTANTS.IV_HibVacc);
        let PneuVacc             = FindIntervRec(IVInfo, CONSTANTS.IV_PneuVacc);
        let MeningA              = FindIntervRec(IVInfo, CONSTANTS.IV_MeningA);

        for (let age = CONSTANTS.CS_1t6months; age <= CONSTANTS.CS_24t60months; age++) {
            /* Set associated inputs */
            /* Incidence */

            /* Severe Diarrhea Rotavirus */
            SevDiarrIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_RotavirusVacc_SevDiarInterv][CONSTANTS.CS_AffecFract][age] =
                DiarPathMV["value"][CONSTANTS.CS_Distr_Sevr][CONSTANTS.CS_RotaPathDiar][age];

            /* All Diarrhea Rotavirus */
            DiarrIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_RotavirusVacc_DiarInterv][CONSTANTS.CS_AffecFract][age] =
                DiarPathMV["value"][CONSTANTS.CS_Distr_All][CONSTANTS.CS_RotaPathDiar][age];

            /* All Diarrhea Vaccine B */
            DiarrIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_DiarVaccPathB_DiarInterv][CONSTANTS.CS_AffecFract][age] =
                DiarPathMV["value"][CONSTANTS.CS_Distr_All][CONSTANTS.CS_PathBDiar][age];

            /* All Diarrhea Vaccine C */
            DiarrIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_DiarVaccPathC_DiarInterv][CONSTANTS.CS_AffecFract][age] =
                DiarPathMV["value"][CONSTANTS.CS_Distr_All][CONSTANTS.CS_PathCDiar][age];

            /* HiB*/
            PneumIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_HibVacc_PneumInterv][CONSTANTS.CS_AffecFract][age] =
                PneumPathMV["value"][CONSTANTS.CS_Distr_Sevr][CONSTANTS.CS_HibPathPneumVT][age];

            /* PCV */
            PneumIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_PneumVacc_PneumInterv][CONSTANTS.CS_AffecFract][age] =
                PneumPathMV["value"][CONSTANTS.CS_Distr_Sevr][CONSTANTS.CS_PneumocPathPneum][age];

            /* HiB*/
            MeningIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_HibVacc_MeningInterv][CONSTANTS.CS_AffecFract][age] =
                MenPathMV["value"][CONSTANTS.CS_Distr_Sevr][CONSTANTS.CS_HibPathMeninVT][age];

            /* PCV */
            MeningIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_PneumVacc_MeningInterv][CONSTANTS.CS_AffecFract][age] =
                MenPathMV["value"][CONSTANTS.CS_Distr_Sevr][CONSTANTS.CS_PneumocPathMenin][age];

            /* Meningococcal - A*/
            MeningIncReductionMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_MeningA_MeningInterv][CONSTANTS.CS_AffecFract][age] =
                MenPathMV["value"][CONSTANTS.CS_Distr_Sevr][CONSTANTS.CS_MeningitAPathMeninVT][age];

            /* Detailed vaccine efficacy */
            for (let dose = 1; dose <= CONSTANTS.CS_MaxVaccDose; dose++) {
                /* Rota */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_Rota_Det][CONSTANTS.CS_Diarrhea][dose][age] =
                    DiarPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_RotaPathDiar][age];

                /* Vacc B */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_VaccB_Det][CONSTANTS.CS_Diarrhea][dose][age] =
                    DiarPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PathBDiar][age];

                /* Vacc C */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_VaccC_Det][CONSTANTS.CS_Diarrhea][dose][age] =
                    DiarPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PathCDiar][age];

                /* HiB */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_HiB_Det][CONSTANTS.CS_Pneumonia][dose][age] =
                    PneumPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_HibPathPneumVT][age];

                /* PCV */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_PCV_Det][CONSTANTS.CS_Pneumonia][dose][age] =
                    PneumPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PneumocPathPneum][age];

                /* HiB */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_HiB_Det][CONSTANTS.CS_Meningitis][dose][age] =
                    MenPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_HibPathMeninVT][age];

                /* PCV */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_PCV_Det][CONSTANTS.CS_Meningitis][dose][age] =
                    MenPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PneumocPathMenin][age];

                /* Meningococcal A */
                DetVaccEffMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][CONSTANTS.CS_MenA_Det][CONSTANTS.CS_Meningitis][dose][age] =
                    MenPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_MeningitAPathMeninVT][age];
            }

            /* Standard vaccine efficacy */
            /* Rota */
            if (RotavirusVacc !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][RotavirusVacc["CurrID"]][CONSTANTS.CS_Diarrhea][age] =
                    DiarPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_RotaPathDiar][age];
            }
            /* Vacc B */
            if (DiarVaccPathB !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][DiarVaccPathB["CurrID"]][CONSTANTS.CS_Diarrhea][age] =
                    DiarPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PathBDiar][age];
            }
            /* Vacc C */
            if (DiarVaccPathC !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][DiarVaccPathC["CurrID"]][CONSTANTS.CS_Diarrhea][age] =
                    DiarPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PathCDiar][age];
            }

            /* HiB */
            if (HibVacc !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][HibVacc["CurrID"]][CONSTANTS.CS_Pneumonia][age] =
                    PneumPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_HibPathPneumVT][age];
            }
            /* PCV */
            if (PneuVacc !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][PneuVacc["CurrID"]][CONSTANTS.CS_Pneumonia][age] =
                    PneumPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PneumocPathPneumVT][age];
            }

            /* HiB */
            if (HibVacc !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][HibVacc["CurrID"]][CONSTANTS.CS_Meningitis][age] =
                    MenPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_HibPathMeninVT][age];
            }

            /* PCV */
            if (PneuVacc !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][PneuVacc["CurrID"]][CONSTANTS.CS_Meningitis][age] =
                    MenPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_PneumocPathMeninVT][age];
            }

            /* Meningococcal A */
            if (MeningA !== null) {
                EfficacyMV["value"][CONSTANTS.CS_Data][CONSTANTS.CS_AffecFract][MeningA["CurrID"]][CONSTANTS.CS_Meningitis][age] =
                    MenPathMV["value"][CONSTANTS.CS_Distr_Fatal][CONSTANTS.CS_MeningitAPathMeninVT][age];
            }
        }

        changedModvars.push(DiarPathMV);
        changedModvars.push(PneumPathMV);
        changedModvars.push(MenPathMV);
        changedModvars.push(SevDiarrIncReductionMV);
        changedModvars.push(DiarrIncReductionMV);
        changedModvars.push(PneumIncReductionMV);
        changedModvars.push(MeningIncReductionMV);
        changedModvars.push(DetVaccEffMV);
        changedModvars.push(EfficacyMV);
        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear, options) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSecSrcEditor;

    let exploreData = false;

    let BaseYear;
    pt.yearArr = [];

    if (options[AgeIdx] !== 0) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }

        exploreData = true;
    }

    pt.DistrSet = [];
    switch (options[DistrIdx]){
        case 0  : {pt.DistrSet = [CONSTANTS.CS_Distr_All, CONSTANTS.CS_Distr_Sevr, CONSTANTS.CS_Distr_Fatal]; break;}
        default : {pt.DistrSet = [options[DistrIdx]]; break;}
    }

    pt.AgeSet = [];
    switch (options[AgeIdx]){
        case 0  : {pt.AgeSet = [CONSTANTS.CS_0t1months, CONSTANTS.CS_1t6months, CONSTANTS.CS_6t12months,
                                CONSTANTS.CS_12t24months, CONSTANTS.CS_24t60months]; break;}
        default : {pt.AgeSet = [options[AgeIdx]]; break;}
    }

    pt.ColSets = new Array(CONSTANTS.CS_Distr_Fatal);
    for (let i = 1; i <= CONSTANTS.CS_Distr_Fatal; i++) {
        pt.ColSets[i] = [];
    }

    if (options[AgeIdx] === 0) {
        pt.DistrSet.forEach(function (i) {
            pt.AgeSet.forEach(function (a) {
                pt.ColSets[i].push(((i - pt.DistrSet[0]) * pt.AgeSet.length) + a - (pt.AgeSet[0] - 1));
            });
        });
    }
    else{
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            pt.ColSets[options[DistrIdx]].push(col);
            col++;
        }
    }

    let ColSet = [];
    pt.ColSets[CONSTANTS.CS_Distr_All].forEach(function (col) {ColSet.push(col);});
    pt.ColSets[CONSTANTS.CS_Distr_Sevr].forEach(function (col) {ColSet.push(col);});
    pt.ColSets[CONSTANTS.CS_Distr_Fatal].forEach(function (col) {ColSet.push(col);});

    pt.StartNumRows = 0;
    let numCols;

    if (options[AgeIdx] !== 0) {
        pt.StartNumRows = 2;
        numCols = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            numCols++;
        }
    }
    else {
        pt.StartNumRows = 3;
        numCols = ColSet.length + 1;
        pt.GBFixedRows = 2;
    }

    pt = resizePackTable(pt, pt.StartNumRows, numCols);

    pt.ColAgeIds = [];

    if (options[AgeIdx] !== 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            col++;
        }
    }
    else {
        pt.ColAgeIds = new Array(numCols);
        pt.DistrSet.forEach(function (i) {
            let Offset = ((i - pt.DistrSet[0]) * pt.AgeSet.length);
            pt.tableData.value[0][Offset + 1] = CSGetDistrName(i);
            pt.MergedCells = addMergedCellsToArray(pt.MergedCells, 0, Offset + 1, 1, pt.AgeSet.length);
            pt.AgeSet.forEach(function (a) {
                let col = Offset + a - (pt.AgeSet[0] - 1);
                pt.tableData.value[1][col] = getAgeCohortName(a, true);
                pt.ColAgeIds[col] = a;
            });
        });
    }

    pt.diseaseSet = [CONSTANTS.CS_Diarrhea, CONSTANTS.CS_Pneumonia, CONSTANTS.CS_Meningitis];

    pt = setSourceArrLength(pt, 3);

    let row = pt.StartNumRows-1;

    let srcIndex = 1;

    pt.diseaseSet.forEach(function (d) {
        pt = addRowToPackTable(pt);
        let s = '';
        switch(d){
            case CONSTANTS.CS_Diarrhea   : {s = RS('GB_stSevereDiarrhea'); break;}
            case CONSTANTS.CS_Pneumonia  : {s = RS('GB_stSeverePneumonia'); break;}
            case CONSTANTS.CS_Meningitis : {s = RS('GB_stMeningitis'); break;}
            default : break;
        }
        pt.tableData.value[row][0] = s;
        pt.FontStyles[row][0]["intArray"] = [CONSTANTS.GB_Bold];
        pt.MergedCells = addMergedCellsToArray(pt.MergedCells, row, 0, 1, numCols);
        pt = lockPackTable(pt, row, true);
        pt.SourceTitle[srcIndex] = s;
        row++;

        let pathSet = GetPathSet(d);

        pathSet.forEach(function (i) {
            pt = addRowToPackTable(pt);
            pt.tableData.value[row][0] = CSGetPathogenName(d, i);
            pt = indentPackTable(pt, row, true, GB_CONSTANTS.GB_ED_Indent1);
            pt.hasData = true;

            for (let c = 1; c <= numCols; c++) {
                pt.RDec[row][c] = 1;
            }

            if (exploreData){
                lockPackTable(pt, row, true, false);
            }
            else if (d === CONSTANTS.CS_Diarrhea) {
                if ([CONSTANTS.CS_PathBDiar, CONSTANTS.CS_PathCDiar].includes(i)) {
                    pt.ColSets[CONSTANTS.CS_Distr_Sevr].forEach(function (col) {
                        lockPackRowCol(pt, row, col, true);
                    });
                }
            }
            else{
                pt.ColSets[CONSTANTS.CS_Distr_All].forEach(function (col) {
                    lockPackRowCol(pt, row, col, true);
                });
            }

            if (!exploreData &&
                [CONSTANTS.CS_NumPathsDiar, CONSTANTS.CS_NumPathsPneum, CONSTANTS.CS_NumPathsMenin,
                 CONSTANTS.CS_AllPathsDiar, CONSTANTS.CS_AllPathsPneum, CONSTANTS.CS_AllPathsMenin].includes(i)){
                pt = lockPackTable(pt, row, true);
            }

            pt.SourceMap[row] = srcIndex;

            row++;
            pt = addRowToPackTable(pt);
        });
        srcIndex++;
    });

    if (options[AgeIdx] !== 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            for (let r = 1; r < pt.GBRowCount; r++) {
                pt.Alignments[r][col] = CONSTANTS.GB_Right;
                pt.RDec[r][col] = 1;
            }
            col++;
        }
    }

    pt.GBRowCount = row;
    pt = resizePackTable(pt, row, numCols);

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 70;
    }

    return pt;
};

const GetPathSet = (d) => {
    let pathSet = [];
    switch (d){
        case CONSTANTS.CS_Diarrhea : {
            for (let i = CONSTANTS.CS_FirstPathDiar; i <= CONSTANTS.CS_MaxPathDiar; i++) {
                pathSet.push(i);
            }
            break
        }
        case CONSTANTS.CS_Pneumonia : {
            for (let i = CONSTANTS.CS_FirstPathPneum; i <= CONSTANTS.CS_MaxPathPneum; i++) {
                pathSet.push(i);
            }
            break
        }
        case CONSTANTS.CS_Meningitis : {
            for (let i = CONSTANTS.CS_FirstPathMenin; i <= CONSTANTS.CS_MaxPathMenin; i++){
                pathSet.push(i);
            }
            break
        }
        default : break;
    }
    return (pathSet);
};

const GetDistAndAge = (col) => {
    let dist, age;

    if (col <= 5){
        dist = CONSTANTS.CS_Distr_All;
        /* age constants go from 1 (for 0-1 months) to 5 (for 24-60 months) */
        age = col;
    }
    else if (col <= 10){
        dist = CONSTANTS.CS_Distr_Sevr;
        /* age constants go from 2-5 */
        age = col - 5;
    }
    else {
        dist = CONSTANTS.CS_Distr_Fatal;
        /* age constants go from 2-5 */
        age = col - 10;
    }
    return[dist, age];
};

const SetSSData = (pt, CSModvars) => {
    let DiarPath  = getModvarByTag(CSModvars, CONSTANTS.CS_TG_DiarPath_MV2);
    let PneumPath = getModvarByTag(CSModvars, CONSTANTS.CS_TG_PneumPath_MV);
    let MenPath   = getModvarByTag(CSModvars, CONSTANTS.CS_TG_MenPath_MV);

    let ColSet = [];
    pt.ColSets[CONSTANTS.CS_Distr_All].forEach(function (col) {ColSet.push(col);});
    pt.ColSets[CONSTANTS.CS_Distr_Sevr].forEach(function (col) {ColSet.push(col);});
    pt.ColSets[CONSTANTS.CS_Distr_Fatal].forEach(function (col) {ColSet.push(col);});

    let row = pt.StartNumRows-1;
    pt.diseaseSet.forEach(function (d) {
        row++;
        let pathSet = GetPathSet(d);
        pathSet.forEach(function (i) {
            if (pt.ColAgeIds.length !== 0){
                ColSet.forEach(function (col) {
                    let distr = -1;
                    for (let j = 1; j <= CONSTANTS.CS_Distr_Fatal; j++){
                        if (pt.ColSets[j].includes(col)){
                            distr = j;
                        }
                    }
                    if (distr !== -1){
                        let val = 0;
                        switch (d) {
                            case CONSTANTS.CS_Diarrhea : {val = DiarPath["value"][distr][i][pt.ColAgeIds[col]]; break;}
                            case CONSTANTS.CS_Pneumonia : {val = PneumPath["value"][distr][i][pt.ColAgeIds[col]]; break;}
                            case CONSTANTS.CS_Meningitis : {val = MenPath["value"][distr][i][pt.ColAgeIds[col]]; break;}
                            default : break;
                        }
                        pt.tableData.value[row][col] = val * 100;
                    }
                });
            }
            else {
                let col = 1;
                for (let yr = 0; yr < pt.yearArr.length; yr++){
                    let t = pt.yearArr[yr];
                    let distr = -1;
                    for (let j = 1; j <= CONSTANTS.CS_Distr_Fatal; j++){
                        if (pt.ColSets[j].includes(col)){
                            distr = j;
                        }
                    }
                    if (distr !== -1){
                        let val = 0;
                        switch (d) {
                            case CONSTANTS.CS_Diarrhea : {val = DiarPath["exploreLiST"][distr][i][pt.AgeSet[0]][t]; break;}
                            case CONSTANTS.CS_Pneumonia : {val = PneumPath["exploreLiST"][distr][i][pt.AgeSet[0]][t]; break;}
                            case CONSTANTS.CS_Meningitis : {val = MenPath["exploreLiST"][distr][i][pt.AgeSet[0]][t]; break;}
                            default : break;
                        }
                        pt.tableData.value[row][col] = '';
                        if(val >= 0) {
                            pt.tableData.value[row][col] = val * 100;
                        }
                    }
                    col++;
                }
            }
            if (d === CONSTANTS.CS_Diarrhea) {
                if ([CONSTANTS.CS_PathBDiar, CONSTANTS.CS_PathCDiar].includes(i)) {
                    pt.ColSets[CONSTANTS.CS_Distr_Sevr].forEach(function (col) {
                        pt.tableData.value[row][col] = '';
                    });
                }
            }
            else{
                pt.ColSets[CONSTANTS.CS_Distr_All].forEach(function (col) {
                    pt.tableData.value[row][col] = '';
                });
            }
            switch (d) {
                case CONSTANTS.CS_Diarrhea   : {pt.Source[pt.SourceMap[row]] = DiarPath["source"][0][0]; break;}
                case CONSTANTS.CS_Pneumonia  : {pt.Source[pt.SourceMap[row]] = PneumPath["source"][0][0]; break;}
                case CONSTANTS.CS_Meningitis : {pt.Source[pt.SourceMap[row]] = MenPath["source"][0][0]; break;}
                default : break;
            }
            row++;
        });
    });
    return pt;
};

