import RS from "@common/strings/RS";
import { GBGetModName, getCalcYearIdx } from "../GB/GBUtil.js";
import { default as GB_CONSTANTS } from "../GB/GBConst.js";

/*--------------------------------------------------------------------------------------------------------------------*/
/*                                                                                                                    */
/*                                                 Results                                                            */
/*                                                                                                                    */
/*--------------------------------------------------------------------------------------------------------------------*/

export const AddResultExHeaderInfo = (projInfo, chartOptions, ResultExArray, currRow) => {
    ResultExArray[currRow][GB_CONSTANTS.EX_FQNameCol] = projInfo["projName"];
    ResultExArray[currRow][GB_CONSTANTS.EX_CountryNameCol] = projInfo.country;
    ResultExArray[currRow][GB_CONSTANTS.EX_ISOCodeCol] = projInfo.ISO3;
    ResultExArray[currRow][GB_CONSTANTS.EX_SubnatRegNameCol] = projInfo.subnatRegion;
    ResultExArray[currRow][GB_CONSTANTS.EX_ModNameCol] = GBGetModName(chartOptions.modID);
    return ResultExArray;
};

export const AddCurrRowDataToResultExArray = (chartYears, ResultExArray, currRow, values) => {
    for (let col = GB_CONSTANTS.EX_FirstYearCol; col < chartYears.ExDataStartCol; col++) {
        ResultExArray[currRow][col] = "";
    }

    let col = chartYears.ExDataStartCol;

    for (let t = chartYears.firstExYearIndex; t <= chartYears.finalExYearIndex; t++) {
        ResultExArray[currRow][col] = values[t];
        col++;
    }
    return ResultExArray;
};

export const InitResultExArray = years => {
    let ResultExArray = [];

    ResultExArray[GB_CONSTANTS.EX_VersionRow] = [];
    ResultExArray[GB_CONSTANTS.EX_HeadingsRow] = [];

    ResultExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_FQNameCol] = RS("GB_stFileName");
    ResultExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_CountryNameCol] = RS("GB_stCountry");
    ResultExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_ISOCodeCol] = RS("GB_stISO3");
    ResultExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_SubnatRegNameCol] = RS("GB_stSubnationalRegion");
    ResultExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_ModNameCol] = RS("GB_stModule");
    ResultExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_IndNameCol] = RS("GB_stIndicator");
    ResultExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_ConfigCol] = RS("GB_stConfig");

    for (let year = years.min; year <= years.max; year++) {
        let col = GB_CONSTANTS.EX_FirstYearCol + year - years.min;
        ResultExArray[GB_CONSTANTS.EX_HeadingsRow][col] = year;
    }

    return ResultExArray;
};

export const ExtractResultFromPT = (ResultExArray, pt, CSModvars, projInfo, chartYears, chartOptions, addHeaderToConfig = true) => {
    let currRow = ResultExArray.length;

    let headerStr = "";
    for (let row = pt.GBFixedRows; row < pt.tableData.value.length; row++) {
        let rowMerged = false;

        for (let i = 0; i < pt.MergedCells.length; i++) {
            rowMerged = rowMerged || pt.MergedCells[i].startRow === row;
        }
        if (rowMerged) {
            headerStr = pt.tableData.value[row][0] + "; ";
        }

        if (!rowMerged) {
            ResultExArray[currRow] = [];
            ResultExArray = AddResultExHeaderInfo(projInfo, chartOptions, ResultExArray, currRow);
            ResultExArray[currRow][GB_CONSTANTS.EX_IndNameCol] = pt.title;
            ResultExArray[currRow][GB_CONSTANTS.EX_ConfigCol] = pt.tableData.value[row][0];

            if(addHeaderToConfig){
                ResultExArray[currRow][GB_CONSTANTS.EX_ConfigCol] = headerStr +
                    ResultExArray[currRow][GB_CONSTANTS.EX_ConfigCol];
            }
            
            for (let col = GB_CONSTANTS.EX_FirstYearCol; col < chartYears.ExDataStartCol; col++) {
                ResultExArray[currRow][col] = "";
            }

            let ExArrCol = chartYears.ExDataStartCol;
            let packCol = chartYears.firstExYearIndex - chartYears.firstYrIdx + 1;

            for (let t = chartYears.firstExYearIndex; t <= chartYears.finalExYearIndex; t++) {
                ResultExArray[currRow][ExArrCol] = pt.tableData.value[row][packCol];
                packCol++;
                ExArrCol++;
            }

            currRow++;
        }
    }

    return ResultExArray;
};

/*--------------------------------------------------------------------------------------------------------------------*/
/*                                                                                                                    */
/*                                                 Editors                                                            */
/*                                                                                                                    */
/*--------------------------------------------------------------------------------------------------------------------*/

export const InitEditExArray = projInfo => {
    let EditExArray = [];

    EditExArray[GB_CONSTANTS.EX_VersionRow] = [];
    EditExArray[GB_CONSTANTS.EX_HeadingsRow] = [];

    //Initial Headings Row
    EditExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_FQNameCol] = RS("GB_stFileName");
    EditExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_CountryNameCol] = RS("GB_stCountry");
    EditExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_ISOCodeCol] = RS("GB_stISO3");
    EditExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_SubnatRegNameCol] = RS("GB_stSubnationalRegion");
    EditExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_ModNameCol] = RS("GB_stModule");
    EditExArray[GB_CONSTANTS.EX_HeadingsRow][GB_CONSTANTS.EX_IndNameCol] = RS("GB_stEditor");
    return EditExArray;
};

export const ExtractEditFromPT = (EditExArray, pt, CSModvars, projInfo, chartYears, chartOptions) => {
    let currRow = EditExArray.length;

    EditExArray[currRow] = [];
    EditExArray[currRow][GB_CONSTANTS.EX_FQNameCol] = projInfo.name;
    EditExArray[currRow][GB_CONSTANTS.EX_CountryNameCol] = projInfo.country;
    EditExArray[currRow][GB_CONSTANTS.EX_ISOCodeCol] = projInfo.countryCode;
    EditExArray[currRow][GB_CONSTANTS.EX_SubnatRegNameCol] = projInfo.subnatRegion;
    EditExArray[currRow][GB_CONSTANTS.EX_ModNameCol] = "ModName";
    EditExArray[currRow][GB_CONSTANTS.EX_IndNameCol] = pt.title;
    currRow = currRow + 1;

    for (let row = 0; row < pt.tableData.value.length; row++) {
        EditExArray[currRow] = [];
        let rowMerged = false;
        for (let i = 0; i < pt.MergedCells.length; i++) {
            rowMerged = rowMerged || pt.MergedCells[i].startRow === row;
        }
        if (rowMerged) {
            EditExArray[currRow][0] = pt.tableData.value[row][0];
            for (let col = 1; col < pt.tableData.value[row].length; col++) {
                EditExArray[currRow][GB_CONSTANTS.EX_FQNameCol + col] = "";
            }
        }
        if (!rowMerged) {
            for (let col = 0; col < pt.tableData.value[row].length; col++) {
                EditExArray[currRow][GB_CONSTANTS.EX_FQNameCol + col] = pt.tableData.value[row][col];
            }
        }
        currRow++;
    }

    return EditExArray;
};

export const GetFirstExYear = (projInfo, minYear) => {
    /* First year of data which will be written out for the current projection being processed. */
    return Math.max(minYear, projInfo.calcYear);
};

export const GetFinalExYear = (projInfo, maxYear) => {
    /* Final year of data which will be written out for the current projection being processed. */
    return Math.min(maxYear, projInfo.finalYear);
};

export const GetFirstExYearIndex = (projInfo, minYear) => {
    /* Index of the "FirstExYear." */
    return GetFirstExYear(projInfo, minYear) - projInfo.calcYear + getCalcYearIdx(projInfo.firstYear);
};

export const GetFinalExYearIndex = (projInfo, maxYear) => {
    /* Index of the "FinalExYear." */
    return GetFinalExYear(projInfo, maxYear) - projInfo.calcYear + getCalcYearIdx(projInfo.firstYear);
};

export const GetExDataStartCol = (projInfo, minYear) => {
    /* Column in output file at which data will begin for current projection. */
    let result = GB_CONSTANTS.EX_FirstYearCol;
    if (projInfo.calcYear > minYear) {
        result = result + (projInfo.calcYear - minYear);
    }
    return result;
};