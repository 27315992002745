window.languageIdx = localStorage.getItem("TOOL_LANG") ? localStorage.getItem("TOOL_LANG") - 1 : 0;

export default function RS(strID) {
    let returnedString = '';

    if (typeof strID === "number") {
        returnedString = window.extractStrings[strID]['lang'][window.App.languageIdx];
    }
    else {
        let found = false;

        for (let i = 0; i < window.extractStrings.length; i++) {
            if (window.extractStrings[i]['stringID'] === strID) {
                returnedString = window.extractStrings[i]['lang'][window.App.languageIdx];
                found = true;
                break;
            }
        }

        if (!found) {
            for (let i = 0; i < window.strings2.length; i++) {
                if (window.strings2[i]['stringID'] === strID) {
                    returnedString = window.strings2[i]['lang'][window.App.languageIdx];
                    found = true;
                    break;
                }
            }
        }

        if (!found) {
            let badStringID = strID;
            if (badStringID === '') {
                badStringID = "*The strID was blank*";
            }
            console.error("RS stringID does not exist: " + badStringID);
        }
    }

    return returnedString;
}
