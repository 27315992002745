import React from 'react';
import PropTypes from "prop-types";
import TExpansionPanel from "@common/components/TExpansionPanel";
import TCheckBox from "@common/components/TCheckBox";
import RS from "@common/strings/RS";

class MOExpansionPanel extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange              : PropTypes.func,
        content                    : PropTypes.arrayOf(PropTypes.object),
        panelName                  : PropTypes.string,
        allMstID                   : PropTypes.number,
        defaultExtended            : PropTypes.bool,
        expansionPanelDetailsStyle : PropTypes.object,
        saveBtnDisabled            : PropTypes.bool,
        onChange                   : PropTypes.func,
    };

    static defaultProps = {
        onStateChange              : () => console.log('onStateChange'),
        content                    : [],
        panelName                  : '',
        allMstID                   : 0,
        defaultExtended            : false,
        expansionPanelDetailsStyle : {},
        saveBtnDisabled            : true,
        onChange                   : () => console.log('onChange')
    };

    state = {
        content : JSON.parse(JSON.stringify(this.props.content))
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onCheckBoxChange = (checked, mstID) => {

        let panelContent = JSON.parse(JSON.stringify(this.state.content));
        let all = 0;
        let selected = true;

        for (let i = 0; i < panelContent.length; i++) {
            if (panelContent[i]['mstID'] === this.props.allMstID)
                all = i;
        }

        if (mstID === this.props.allMstID) {
            for (let i = 0; i < panelContent.length; i++) {
                panelContent[i]['checked'] = checked;
            }
        } else {
            for (let i = 0; i < panelContent.length; i++) {
                if (panelContent[i]['mstID'] === mstID)
                    panelContent[i]['checked'] = checked;
            }
        }

        for (let i = 0; i < panelContent.length; i++) {
            if ((panelContent[i]['checked'] === false) && (panelContent[i]['mstID'] !== this.props.allMstID)) {
                selected = false;
            }
        }

        panelContent[all]['checked'] = selected;

        this.setState({
            content : panelContent
        });

        this.props.onChange(panelContent)
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    getContent = () => {

        let panelContent = [];

        const styles = {
            checkBoxLabelStyle: {
                color: '#05225d',
                fontSize: '13px'
            },
        };

        for (let i = 0; i < this.state.content.length; i++) {
            panelContent.push(
                <TCheckBox
                    caption={RS(this.state.content[i]['name'])}
                    labelStyle={styles.checkBoxLabelStyle}
                    boxStyle={this.props.boxStyle}
                    key={this.state.content[i]['mstID']}
                    color={'primary'}
                    name={''}
                    customProp={this.state.content[i]['mstID']}
                    value={this.state.content[i]['checked']}
                    onClick={(checked, event, name, caption, mstID) => this.onCheckBoxChange(checked, mstID)}
                />
            )
        }

        return (panelContent);
    };

    renderExpansionPanel = () => {
        let styles = {
            expansionPanel: {
                marginTop: 3,
                marginBottom: 3,
                boxShadow: 'none'
            },
            expansionPanelSummary : {
                backgroundColor: '#0C3A79',
                maxHeight: '40px',
                height: '40px',
                minHeight: 0,
                paddingLeft: '24px',
                paddingRight: '10px',
                width: '285px',
                // marginLeft: '-13px'
            },
            header : {
                fontSize: '16px',
                color: '#FFFFFF'
            },
            expandMoreIconStyle : {
                color: '#FFFFFF',
                marginRight: 0
            },
            expansionPanelDetails : {
                display: 'block',
                backgroundColor: '#FFFFFF',
                fontSize: '13px',
                paddingLeft: '25px',
                paddingRight: 0,
                paddingTop: '5px',
                paddingBottom: '15px',
                // marginLeft: '-10px',
                // marginRight: '-10px'
            },
            expansionPanelActionStyle : {
                paddingTop: 0,
                paddingBottom: '10px',
                background: '#FFFFFF',
                marginTop: '-8px',
                // marginRight: '-10px'
            }
        };

        let extended = this.props.defaultExtended;

        return (
            <TExpansionPanel
                items={[[0, this.props.panelName, false, '', false]]}
                content={[this.getContent()]}
                defaultExpanded={extended}
                expansionPanelStyle={styles.expansionPanel}
                expansionPanelSummaryStyle={styles.expansionPanelSummary}
                expandMoreIconStyle={styles.expandMoreIconStyle}
                expansionPanelDetailsStyle={{...styles.expansionPanelDetails, ...this.props.expansionPanelDetailsStyle}}
                expansionPanelActionStyle={styles.expansionPanelActionStyle}
                headerStyle={styles.header}
            />
        );
    };

    render() {
        return (
            this.renderExpansionPanel()
        );
    }

}

export default MOExpansionPanel;