import React from 'react';
import PropTypes from "prop-types";
import RS from "@common/strings/RS";
import DeathsAvertedOptions from "./DeathsAvertedOptions"
import CONSTANTS from "utilities/CS/CSConst";

class Outcomes extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayCPR                     : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        language                       : CONSTANTS.GB_English
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true;
        }
        else {
            return false;
        }
    }

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderHeader = () => {
        let styles = {
            headerStyle: {
                color: '#000000',
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: '10px',
                marginBottom: '10px',
                userSelect: 'none'
            }
        };

        return (
            <div>
                <p style={styles.headerStyle}>{RS('GB_stOutcomes')}</p>
            </div>
        );
    };

    renderSwitches = () => {
        return (
            <DeathsAvertedOptions
                displayMaternalCOD             = {this.props.displayMaternalCOD}
                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                displayChildCOD                = {this.props.displayChildCOD}
                displayCPR                     = {this.props.displayCPR}
                onStateChange                  = {this.props.onStateChange}
                MOResultToDisplay              = {this.props.MOResultToDisplay}
                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
            />
        );
    };

    render() {
        return (
            <div>
                {this.renderHeader()}
                {this.renderSwitches()}
            </div>
        );
    }

}

export default Outcomes;