import React from 'react';
import PropTypes from "prop-types";
import TSwitch from "@common/components/TSwitch";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import {CSGetGuidedCODContentForPercRed} from "utilities/CS/CSDataUtil";

class DeathsAvertedOptions extends React.Component {

    //==================================================================================================================

    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayCPR                     : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onSwitchChange = (checked, mstID) => {

        if (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) {
            if (mstID === CONSTANTS.CS_CoD_MstMaternal) {
                this.props.onStateChange({
                    displayMaternalCOD: checked,
                    maternalIntervSelected : checked,
                });
                if ((!checked) && (!this.props.displayStillbirthCOD) &&
                    (!this.props.displayNeonatalCOD) && (!this.props.displayChildCOD)) {
                    this.props.onStateChange({
                        displayCPR: false
                    })
                }

            } else if (mstID === CONSTANTS.CS_CoD_MstStillbirth) {
                this.props.onStateChange({
                    displayStillbirthCOD: checked,
                    stillbirthIntervSelected : checked,
                });
                if ((!this.props.displayMaternalCOD) && (!checked) &&
                    (!this.props.displayNeonatalCOD) && (!this.props.displayChildCOD)) {
                    this.props.onStateChange({
                        displayCPR: false
                    })
                }

            } else if (mstID === CONSTANTS.CS_CoD_MstNeonatal) {
                this.props.onStateChange({
                    displayNeonatalCOD: checked,
                    neonatalIntervSelected : checked
                });
                if ((!this.props.displayMaternalCOD) && (!this.props.displayStillbirthCOD) &&
                    (!checked) && (!this.props.displayChildCOD)) {
                    this.props.onStateChange({
                        displayCPR: false
                    })
                }

            } else if (mstID === CONSTANTS.CS_CoD_MstPostNeonatal) {
                this.props.onStateChange({
                    displayChildCOD: checked,
                    childIntervSelected : checked
                });
                if ((!this.props.displayMaternalCOD) && (!this.props.displayStillbirthCOD) &&
                    (!this.props.displayNeonatalCOD) && (!checked)) {
                    this.props.onStateChange({
                        displayCPR: false
                    })
                }

            } else if (mstID === CONSTANTS.CS_Display_CPR) {
                this.props.onStateChange({
                    displayCPR: checked
                });
                if ((!this.props.displayMaternalCOD) && (!this.props.displayStillbirthCOD) &&
                    (!this.props.displayNeonatalCOD) && (!this.props.displayChildCOD)) {
                    this.props.onStateChange({
                        displayCPR: false
                    })
                }
            }
        }
        else {
            let maternal   = false;
            let stillbirth = false;
            let neonatal   = false;
            let child      = false;

            switch (mstID) {
                case CONSTANTS.CS_CoD_MstMaternal     : {maternal   = true; break;}
                case CONSTANTS.CS_CoD_MstStillbirth   : {stillbirth = true; break;}
                case CONSTANTS.CS_CoD_MstNeonatal     : {neonatal   = true; break;}
                case CONSTANTS.CS_CoD_MstPostNeonatal : {child      = true; break;}
                default : {break;}
            }

            let maternalContent = JSON.parse(JSON.stringify(this.props.maternalCausesOfDeathContent));
            let stillbirthContent = JSON.parse(JSON.stringify(this.props.stillbirthCausesOfDeathContent));
            let neonatalContent = JSON.parse(JSON.stringify(this.props.neonatalCausesOfDeathContent));
            let childContent = JSON.parse(JSON.stringify(this.props.childCausesOfDeathContent));

            if (maternal) {
                maternalContent = CSGetGuidedCODContentForPercRed(maternalContent)
            }

            else if (stillbirth) {
                stillbirthContent = CSGetGuidedCODContentForPercRed(stillbirthContent)
            }

            else if (neonatal) {
                neonatalContent = CSGetGuidedCODContentForPercRed(neonatalContent)
            }

            else if (child) {
                childContent = CSGetGuidedCODContentForPercRed(childContent)
            }

            this.props.onStateChange({
                displayMaternalCOD             : maternal,
                displayStillbirthCOD           : stillbirth,
                displayNeonatalCOD             : neonatal,
                displayChildCOD                : child,
                maternalIntervSelected         : maternal,
                stillbirthIntervSelected       : stillbirth,
                neonatalIntervSelected         : neonatal,
                childIntervSelected            : child,
                maternalCausesOfDeathContent   : maternalContent,
                stillbirthCausesOfDeathContent : stillbirthContent,
                neonatalCausesOfDeathContent   : neonatalContent,
                childCausesOfDeathContent      : childContent,
            });
        }
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================

    renderContent = () => {

        const styles = {
            switchLabelStyle : {
                fontSize   : '14px',
                color      : '#0c3a79',
            },
            switchStyle : {
                marginTop: 0,
            },
            divStyle : {
                float: 'left',
                height: '30px',
                paddingRight: '15px'
            },
            divStyleHeight1 : {
                height: '95px'
            },
            divStyleHeight2 : {
                height: '65px'
            }
        };

        return (
            <div style={(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted)? styles.divStyleHeight1 : styles.divStyleHeight2}>
                <div  style={styles.divStyle} key={CONSTANTS.CS_CoD_MstMaternal}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stMaternal')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.displayMaternalCOD}
                        name       = {''}
                        customProp = {CONSTANTS.CS_CoD_MstMaternal}
                        onClick    = {(checked, event, name, caption, groupID) => this.onSwitchChange(checked, groupID)}
                    />
                </div>
                <div style={styles.divStyle} key={CONSTANTS.CS_CoD_MstStillbirth}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stStillbirth')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.displayStillbirthCOD}
                        name       = {''}
                        customProp = {CONSTANTS.CS_CoD_MstStillbirth}
                        onClick    = {(checked, event, name, caption, groupID) => this.onSwitchChange(checked, groupID)}
                    />
                </div>
                <div style={styles.divStyle} key={CONSTANTS.CS_CoD_MstNeonatal}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stNeonatal')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.displayNeonatalCOD}
                        name       = {''}
                        customProp = {CONSTANTS.CS_CoD_MstNeonatal}
                        onClick    = {(checked, event, name, caption, groupID) => this.onSwitchChange(checked, groupID)}
                    />
                </div>
                <div  style={styles.divStyle} key={CONSTANTS.CS_CoD_MstPostNeonatal}>
                    <TSwitch
                        style      = {styles.switchStyle}
                        caption    = {RS('GB_stChildren')}
                        labelStyle = {styles.switchLabelStyle}
                        value      = {this.props.displayChildCOD}
                        name       = {''}
                        customProp = {CONSTANTS.CS_CoD_MstPostNeonatal}
                        onClick    = {(checked, event, name, caption, mstID) => this.onSwitchChange(checked, mstID)}
                    />
                </div>
                {
                    (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted) ?
                        <div  style={styles.divStyle} key={CONSTANTS.CS_Display_CPR}>
                            <TSwitch
                                style      = {styles.switchStyle}
                                color      = {'secondary'}
                                enabled    = {this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted}
                                caption    = {RS('GB_stCPR')}
                                labelStyle = {styles.switchLabelStyle}
                                value      = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialDeathsAverted)? this.props.displayCPR : false}
                                name       = {''}
                                customProp = {CONSTANTS.CS_Display_CPR}
                                onClick    = {(checked, event, name, caption, mstID) => this.onSwitchChange(checked, mstID)}
                            />
                        </div>
                        :
                        null
                }
            </div>
        )

    };

    render() {
        return (
            this.renderContent()
        );
    }

}

export default DeathsAvertedOptions;