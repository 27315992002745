import React from 'react';
import PropTypes from "prop-types";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    inputRoot: {
        color: 'inherit',
        width: '264px', //'100%',
    },
    inputInput: {
        paddingRight: theme.spacing(1),
        paddingLeft: 30,
        transition: theme.transitions.create('width'),
        width: '264px', //'100%',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('#f3f3f3', 0.15),
      '&:hover': {
        backgroundColor: fade('#f3f3f3', 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
    //   width: '100%',
      width: '264px', //'100%',
      border: '1px solid #888888',
      padding: '0 5px',
    //   background: '#f3f3f3',
      marginBottom: 7
    },
    searchIcon: {
      width: 20,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
});

class FilterEdit extends React.PureComponent {

    static propTypes = {
        value    : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange : PropTypes.func,
    };

    static defaultProps = {
        value    : '',
        onChange : () => console.log('onChange'),
    };  

    onChange = (e) => {
        this.props.onChange(e.target.value);
    };

    render() {
        const { classes } = this.props;
        
        return (

            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={this.props.value}
                onChange={this.onChange}
              />
            </div>
        );
    }
}

export default withStyles(styles)(FilterEdit);