import React from 'react';
import RS from "@common/strings/RS";
import CSDialog from "./CSDialog";

const SupportDialog = (props) => {
    const { onStateChange } = props;

    const onClose = () => {
        onStateChange({
            dialogNewSupportOpen : false
        });
    };

    return (
        <CSDialog
            open={true}
            modal={true}
            title={RS('GB_stSupport')}
            onClose={onClose}
            minWidth={300}
            // iconType="Error"
            content={
                <div>
                    <a href="/LiSTOnlineHelpEnglish/HTML/index.html" target={"_blank"}>{RS("GB_stLiSTManual")}</a>
                    <br />
                    <br />
                    
                    <a href="https://www.youtube.com/playlist?list=PLjHP2EMffiyREHd4OMFyGvBwcX6ixPgLn" target={"_blank"}>{RS("GB_stVideoTutorials")}</a>
                    <br />
                    <br />

                    <a href="https://spectrummodel.zendesk.com/hc/en-us/requests/new" target={"_blank"}>{RS("GB_stSubmitASupportRequest")}</a>
                </div>
            }
            actions={
                <div>
                </div>
            }
        />
    );
};

export default SupportDialog;