import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    CloneObj, lockPackTable, generatePackChart
} from "utilities/GB/GBUtil";
import CONSTANTS from "utilities/CS/CSConst";

export const CPREditor = {
    Create : (CSModvars) => {
        let editorName = ''; // RS('GB_stCPR');
        let packTable = Setup(CSModvars);
        packTable = SetSSData(packTable, CSModvars);
        packTable = CPREditor.RecalcGrid(packTable);
        packTable.Title = editorName;
        packTable.ID = CONSTANTS.CS_MstSubNatCPRSheetID;
        let packChart = generatePackChart(packTable);
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let CSModvarsClone = CloneObj(CSModvars);
        let Incidence = getModvarByTag(CSModvarsClone, CONSTANTS.VW_TG_TCPR)["value"];
        Incidence[1] = pt.tableData.value[1][1];
        Incidence[2] = pt.tableData.value[2][1];
        return (CSModvarsClone);
    },

    RecalcGrid : (pt) => {
        let TFR = pt.tableData.value[1][1];
        let SubNatTFR = pt.tableData.value[2][1];
        pt.tableData.value[3][1] = (SubNatTFR / TFR);
        return (pt);
    },
};

const Setup = (CSModvars) => {
    let pt = getNewPT();

    const numRows = 4;
    const numCols = 2;

    pt = resizePackTable(pt, numRows, numCols);

    pt.tableData.value[0][1] = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TFirstYear)["value"];
    pt.tableData.value[1][0] = RS('GB_stNationalCPR');
    pt.tableData.value[2][0] = RS('GB_stSubnationalCPR');
    pt.tableData.value[3][0] = RS('GB_stRatioNationalCPR');

    pt.Alignments[0][1] = CONSTANTS.GB_Right;
    pt.Alignments[1][1] = CONSTANTS.GB_Right;
    pt.Alignments[2][1] = CONSTANTS.GB_Right;
    pt.Alignments[3][1] = CONSTANTS.GB_Right;

    pt.RDec[1][1] = 1;
    pt.RDec[2][1] = 1;
    pt.RDec[3][1] = 2;

    pt = lockPackTable(pt, 1, true);
    pt = lockPackTable(pt, 3, true);

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let Incidence = getModvarByTag(CSModvars, CONSTANTS.VW_TG_TCPR)["value"];
    pt.tableData.value[1][1] = Incidence[1];
    pt.tableData.value[2][1] = Incidence[2];
    return pt;
};

