import RS from "@common/strings/RS";
import {
    getModvarByTag,
    getNewPT,
    resizePackTable,
    generatePackChart,
    getModvarCloneByTag,
    getIdxFromYear,
    getYearFromIdx,
    addRowToPackTable, add_NoHistoricalData_MsgToPackTable, convertNegativeOnesToBlanks
} from "utilities/GB/GBUtil";

import CONSTANTS from "utilities/CS/CSConst";
import {
    CSGetBFTypeStr,
    getAgeCohortName
} from "utilities/CS/CSDataUtil";

export const RelRiskBFMeningEditor = {
    Create : (CSModvars, EditorStartYear, EditorEndYear) => {
        let editorName = RS('GB_stRiskMeningitisBF');
        let packTable = Setup(CSModvars, EditorStartYear, EditorEndYear);
        packTable = SetSSData(packTable, CSModvars);
        packTable = convertNegativeOnesToBlanks(packTable);
        packTable = add_NoHistoricalData_MsgToPackTable(packTable);
        packTable.Title = editorName;
        let packChart = generatePackChart(packTable);
        packTable.EditorCat = CONSTANTS.GB_ImpactsOnMeningInc;
        return ([packChart, packTable]);
    },

    GetSSData : (pt, CSModvars) => {
        let changedModvars = [];

        let RelRisksMeningitis = getModvarCloneByTag(CSModvars, CONSTANTS.CS_TG_RelRisksMeningitis_MV2);

        let bf = 1;
        for (let row = 2; row < pt.GBRowCount; row++){
            for (let col = 1; col < pt.GBColCount; col++){
                RelRisksMeningitis["value"][CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy][row-1][col] = pt.tableData.value[row][col];
            }
            RelRisksMeningitis["source"][0][bf] = pt.Source[pt.SourceMap[row]];
            bf++;
        }

        changedModvars.push(RelRisksMeningitis);

        return (changedModvars);
    },
};

const Setup = (CSModvars, EditorStartYear, EditorEndYear) => {
    let pt = getNewPT();

    pt.SourceMode = CONSTANTS.GB_RowSrcEditor;

    pt.GBFixedRows = 2;

    pt.AgeSet = [CONSTANTS.CS_0t1months,
        CONSTANTS.CS_1t6months,
        CONSTANTS.CS_6t12months,
        CONSTANTS.CS_12t24months,
        CONSTANTS.CS_24t60months];

    let numCols = 6;
    pt.yearArr = [];
    let BaseYear;

    if ((EditorStartYear > 0) && (EditorEndYear > 0)) {
        let BYIndex = getModvarByTag(CSModvars, CONSTANTS.CS_TG_CovBY_MV)["value"];
        BaseYear = getModvarByTag(CSModvars, CONSTANTS.CS_TG_FirstYear_MV2)["value"] + BYIndex - 1;

        let StartIndex = getIdxFromYear(BaseYear, EditorStartYear);
        let EndIndex = getIdxFromYear(BaseYear, EditorEndYear);

        numCols = EndIndex - StartIndex + 2;//+2 for extra col on the side, and difference math
        for (let t = StartIndex; t <= EndIndex; t++) {
            pt.yearArr.push(t);
        }
    }

    const numRows = 2;

    pt = resizePackTable(pt, numRows, numCols);

    if (pt.yearArr.length > 0) {
        let col = 1;
        for (let yr = 0; yr < pt.yearArr.length; yr++){
            let t = pt.yearArr[yr];
            pt.tableData.value[0][col] = getYearFromIdx(BaseYear, t).toString();
            pt.Alignments[0][col] = CONSTANTS.GB_Right;
            for (let row = 1; row <= CONSTANTS.CS_24t60months; row++) {
                pt.Alignments[row][col] = CONSTANTS.GB_Right;
                pt.RDec[row][col] = 2;
            }
            col++;
        }
    }
    else{
        pt.AgeSet.forEach(function (a) {
            pt.tableData.value[0][a] = getAgeCohortName(a, true);
            pt.tableData.value[1][a] = RS('GB_stRelRisk');
        });
    }

    let row = 2;

    pt.numSources = 0;

    for (let bf = 1; bf <= CONSTANTS.CS_MaxBFType; bf++){
        pt = addRowToPackTable(pt);
        pt.tableData.value[row][0] = CSGetBFTypeStr(bf);
        for(let col = 1; col < pt.GBColCount; col++) {
            pt.Alignments[row][col] = CONSTANTS.GB_Right;
            pt.RDec[row][col] = 2;
        }
        pt.numSources++;
        pt.SourceMap[row] = pt.numSources;
        row++;
    }

    pt.GBColWidths[0] = 500;
    for (let k = 1; k < pt.GBColWidths.length; k++) {
        pt.GBColWidths[k] = 170;
    }

    return pt;
};

const SetSSData = (pt, CSModvars) => {
    let RelRisksMeningitis = getModvarByTag(CSModvars, CONSTANTS.CS_TG_RelRisksMeningitis_MV2)["value"][
        CONSTANTS.CS_Data][CONSTANTS.CS_Efficacy];
    let RelRisksMeningitisSrc = getModvarByTag(CSModvars, CONSTANTS.CS_TG_RelRisksMeningitis_MV2)["source"];

    let bf = 1;
    for (let row = 2; row < pt.GBRowCount; row++){
        for (let col = 1; col < pt.GBColCount; col++){
            pt.tableData.value[row][col] = RelRisksMeningitis[row-1][col];
        }
        pt.Source[pt.SourceMap[row]] = RelRisksMeningitisSrc[0][bf];
        bf++;
    }

    return pt;
};

