import React from 'react';
import PropTypes from "prop-types";
import CONSTANTS from "utilities/CS/CSConst";
import MOSideBar from "../../../../pages/CS/TOOLS/MissedOpportunities/sideBar/MOSideBar";
import MOChartOptions from "../../../../pages/CS/TOOLS/MissedOpportunities/content/MOChartOptions";
import DisplayMO from "../../../../pages/CS/TOOLS/MissedOpportunities/content/DisplayMO";

class CSExpandMODrawer extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        onStateChange                  : PropTypes.func,
        onPageChange                   : PropTypes.func,
        onOutcomeSwitchChange          : PropTypes.func,
        displayChart                   : PropTypes.bool,
        selectedCountry                : PropTypes.string,
        displayMaternalCOD             : PropTypes.bool,
        displayStillbirthCOD           : PropTypes.bool,
        displayNeonatalCOD             : PropTypes.bool,
        displayChildCOD                : PropTypes.bool,
        displayNutritionCOD            : PropTypes.bool,
        displayBirthOutcomeCOD         : PropTypes.bool,
        displayCPR                     : PropTypes.bool,
        deliveryPointsContent          : PropTypes.arrayOf(PropTypes.object),
        maternalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        stillbirthCausesOfDeathContent : PropTypes.arrayOf(PropTypes.object),
        neonatalCausesOfDeathContent   : PropTypes.arrayOf(PropTypes.object),
        childCausesOfDeathContent      : PropTypes.arrayOf(PropTypes.object),
        sortByDeathsAverted            : PropTypes.bool,
        sortByInterventionCost         : PropTypes.bool,
        viewInterventionCost           : PropTypes.bool,
        MOCountryData                  : PropTypes.arrayOf(PropTypes.object),
        maternalIntervSelected         : PropTypes.bool,
        stillbirthIntervSelected       : PropTypes.bool,
        neonatalIntervSelected         : PropTypes.bool,
        childIntervSelected            : PropTypes.bool,
        MOResultToDisplay              : PropTypes.number,
        language                       : PropTypes.number
    };

    static defaultProps = {
        onStateChange                  : () => console.log('onStateChange'),
        onPageChange                   : () => console.log('onPageChange'),
        onOutcomeSwitchChange          : () => console.log('onOutcomeSwitchChange'),
        displayChart                   : true,
        selectedCountry                : 'Afghanistan',
        displayMaternalCOD             : true,
        displayStillbirthCOD           : true,
        displayNeonatalCOD             : true,
        displayChildCOD                : true,
        displayNutritionCOD            : true,
        displayBirthOutcomeCOD         : true,
        deliveryPointsContent          : [],
        maternalCausesOfDeathContent   : [],
        stillbirthCausesOfDeathContent : [],
        neonatalCausesOfDeathContent   : [],
        childCausesOfDeathContent      : [],
        displayCPR                     : true,
        sortByDeathsAverted            : true,
        sortByInterventionCost         : false,
        viewInterventionCost           : false,
        MOCountryData                  : [],
        maternalIntervSelected         : true,
        stillbirthIntervSelected       : true,
        neonatalIntervSelected         : true,
        childIntervSelected            : true,
        MOResultToDisplay              : CONSTANTS.CS_PotentialDeathsAverted,
        language                       : CONSTANTS.GB_English
    };

    componentDidMount() {
        let chartHeight = document.querySelector('#MODrawerChart').clientHeight;

        document.querySelector('#MODrawerSideBar').style.height = chartHeight + 'px';
    }

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    state = {
        chart: null,
        tableRef: null
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    
    getLegendOptions = () => {
        let legend = {
            reversed : true,
        };

        return(legend)
    };

    renderMOChartOptions = () => {
        return (
            <MOChartOptions
                onStateChange            = {this.props.onStateChange}
                displayChart             = {this.props.displayChart}
                sortByDeathsAverted      = {this.props.sortByDeathsAverted}
                sortByInterventionCost   = {this.props.sortByInterventionCost}
                viewInterventionCost     = {this.props.viewInterventionCost}
                displayMaternalCOD       = {this.props.displayMaternalCOD}
                displayStillbirthCOD     = {this.props.displayStillbirthCOD}
                displayNeonatalCOD       = {this.props.displayNeonatalCOD}
                displayChildCOD          = {this.props.displayChildCOD}
                maternalIntervSelected   = {this.props.maternalIntervSelected}
                stillbirthIntervSelected = {this.props.stillbirthIntervSelected}
                neonatalIntervSelected   = {this.props.neonatalIntervSelected}
                childIntervSelected      = {this.props.childIntervSelected}
                MOResultToDisplay        = {this.props.MOResultToDisplay}
                chart                    = {this.state.chart}
                tableRef                 = {this.state.tableRef}
                passData                 = {(o) => this.setState(o)}
            />
        )
    };

    renderDisplayMO = () => {
        return (
            <DisplayMO
                displayChart                   = {this.props.displayChart}
                showLegend                     = {(this.props.MOResultToDisplay !== CONSTANTS.CS_PotentialStuntingAverted)}
                minHeightChart                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 600 : 1000}
                maxHeightChart                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 600 : 1000}
                minHeightTable                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 400 : 1000}
                maxHeightTable                 = {(this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? 400 : 1000}
                chartMarginLeft                = {300}
                xAxisLabelsPadding             = {25}
                stretch                        = {false}
                spaceReduction                 = {0}
                legend                         = {this.getLegendOptions()}
                creditsEnabled                 = {true}
                MOChartID                      = {'MOChart-Drawer'}
                MOTableID                      = {'MOTable-Drawer'}
                showTopInterv                  = {false}
                displayMODownloadButton        = {true}
                selectedCountry                = {this.props.selectedCountry}
                displayMaternalCOD             = {this.props.displayMaternalCOD}
                displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                displayChildCOD                = {this.props.displayChildCOD}
                displayNutritionCOD            = {this.props.displayNutritionCOD}
                displayBirthOutcomeCOD         = {this.props.displayBirthOutcomeCOD}
                displayCPR                     = {this.props.displayCPR}
                deliveryPointsContent          = {this.props.deliveryPointsContent}
                maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                sortByDeathsAverted            = {this.props.sortByDeathsAverted}
                sortByInterventionCost         = {this.props.sortByInterventionCost}
                viewInterventionCost           = {this.props.viewInterventionCost}
                MOCountryData                  = {this.props.MOCountryData}
                onStateChange                  = {this.props.onStateChange}
                MOResultToDisplay              = {this.props.MOResultToDisplay}
                passData                       = {(o) => this.setState(o)}
                language                       = {this.props.language}
                //tableRef                       = {this.state.tableRef}
            />
        )
    };

    render() {
        const styles = {
            sideBar : {
                display : 'inline',
                float   : 'left',
                width   : '305px',
                borderRight: '1px solid lightgrey',
                minHeight : '100%',
                height : document.documentElement.clientHeight,
                marginTop : (this.props.MOResultToDisplay === CONSTANTS.CS_PotentialStuntingAverted) ? '26px' : 0
            },
            content : {
                marginLeft: '325px',
                width : 'auto'
            }
        };

        return (
            <div className="innerPage">
                <div id="MODrawerSideBar" style={styles.sideBar}>
                    <MOSideBar
                        onStateChange                  = {this.props.onStateChange}
                        onPageChange                   = {this.props.onPageChange}
                        onOutcomeSwitchChange          = {this.props.onOutcomeSwitchChange}
                        displayButtons                 = {false}
                        displayMaternalCOD             = {this.props.displayMaternalCOD}
                        displayStillbirthCOD           = {this.props.displayStillbirthCOD}
                        displayNeonatalCOD             = {this.props.displayNeonatalCOD}
                        displayChildCOD                = {this.props.displayChildCOD}
                        displayCPR                     = {this.props.displayCPR}
                        deliveryPointsContent          = {this.props.deliveryPointsContent}
                        maternalCausesOfDeathContent   = {this.props.maternalCausesOfDeathContent}
                        stillbirthCausesOfDeathContent = {this.props.stillbirthCausesOfDeathContent}
                        neonatalCausesOfDeathContent   = {this.props.neonatalCausesOfDeathContent}
                        childCausesOfDeathContent      = {this.props.childCausesOfDeathContent}
                        MOResultToDisplay              = {this.props.MOResultToDisplay}
                        maternalIntervSelected         = {this.props.maternalIntervSelected}
                        stillbirthIntervSelected       = {this.props.stillbirthIntervSelected}
                        neonatalIntervSelected         = {this.props.neonatalIntervSelected}
                        childIntervSelected            = {this.props.childIntervSelected}
                        nutritionSelected              = {this.props.displayNutritionCOD}
                        birthOutcomeSelected           = {this.props.displayBirthOutcomeCOD}
                        viewInterventionCost           = {this.props.viewInterventionCost}
                        language                       = {this.props.language}
                    />
                </div>
                <div id="MODrawerChart" style={styles.content}>
                    {this.renderMOChartOptions()}
                    {this.renderDisplayMO()}
                </div>
            </div>
        );
    }

}

export default CSExpandMODrawer;