import React from 'react';
import PropTypes from "prop-types";
import TComboBox from "@common/components/TComboBox";
import RS from "@common/strings/RS";
import CONSTANTS from "utilities/CS/CSConst";
import { CSEditor } from '../../../../../components/CS/CSEditors';

class CSEffOnIncidence extends React.Component {

    //==================================================================================================================
    //
    //                                             Props and State
    //
    //==================================================================================================================

    static propTypes = {
        DiarrIncEditorPackTable                : PropTypes.object,
        RelRiskBFDiarrEditorPackTable          : PropTypes.object,
        MeninIncEditorPackTable                : PropTypes.object,
        RelRiskBFMeningEditorPackTable         : PropTypes.object,
        PneumIncEditorPackTable                : PropTypes.object,
        RelRiskBFPneumEditorPackTable          : PropTypes.object,
        onDiarrIncEditorPackTableChange        : PropTypes.func,
        onRelRiskBFDiarrEditorPackTableChange  : PropTypes.func,
        onMeninIncEditorPackTableChange        : PropTypes.func,
        onRelRiskBFMeningEditorPackTableChange : PropTypes.func,
        onPneumIncEditorPackTableChange        : PropTypes.func,
        onRelRiskBFPneumEditorPackTableChange  : PropTypes.func,
        EffOnIncidenceComboboxIdx              : PropTypes.number,
        onEffOnIncidenceComboboxChange         : PropTypes.func
    };

    static defaultProps = {
        DiarrIncEditorPackTable                : null,
        RelRiskBFDiarrEditorPackTable          : null,
        MeninIncEditorPackTable                : null,
        RelRiskBFMeningEditorPackTable         : null,
        PneumIncEditorPackTable                : null,
        RelRiskBFPneumEditorPackTable          : null,
        onDiarrIncEditorPackTableChange        : (packTable) => console.log('onDiarrIncEditorPackTableChange'),
        onRelRiskBFDiarrEditorPackTableChange  : (packTable) => console.log('onRelRiskBFDiarrEditorPackTableChange'),
        onMeninIncEditorPackTableChange        : (packTable) => console.log('onMeninIncEditorPackTableChange'),
        onRelRiskBFMeningEditorPackTableChange : (packTable) => console.log('onRelRiskBFMeningEditorPackTableChange'),
        onPneumIncEditorPackTableChange        : (packTable) => console.log('onPneumIncEditorPackTableChange'),
        onRelRiskBFPneumEditorPackTableChange  : (packTable) => console.log('onRelRiskBFPneumEditorPackTableChange'),
        EffOnIncidenceComboboxIdx              : 0,
        onEffOnIncidenceComboboxChange         : () => console.log('onEffOnIncidenceComboboxChange'),
    };

    state = {
        DiarrIncEditorPackTable        : JSON.parse(JSON.stringify(this.props.DiarrIncEditorPackTable)),
        RelRiskBFDiarrEditorPackTable  : JSON.parse(JSON.stringify(this.props.RelRiskBFDiarrEditorPackTable)),
        MeninIncEditorPackTable        : JSON.parse(JSON.stringify(this.props.MeninIncEditorPackTable)),
        RelRiskBFMeningEditorPackTable : JSON.parse(JSON.stringify(this.props.RelRiskBFMeningEditorPackTable)),
        PneumIncEditorPackTable        : JSON.parse(JSON.stringify(this.props.PneumIncEditorPackTable)),
        RelRiskBFPneumEditorPackTable  : JSON.parse(JSON.stringify(this.props.RelRiskBFPneumEditorPackTable)),
    };

    //==================================================================================================================
    //
    //                                              Event Handlers
    //
    //==================================================================================================================

    onComboboxChange = (value) => {
        this.props.onEffOnIncidenceComboboxChange(value);
    };

    //==================================================================================================================
    //
    //                                                 functions
    //
    //==================================================================================================================

    getDiarrIncEditors = () => {
        return (
            <div>
                <CSEditor
                    mstID={CONSTANTS.CS_MstDiarrIncReductionSheetID}
                    packTable={this.state.DiarrIncEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onDiarrIncEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstRelRisksDiarrheaSheetID}
                    packTable={this.state.RelRiskBFDiarrEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onRelRiskBFDiarrEditorPackTableChange}
                />
            </div>
        )
    };

    getPneumIncEditors = () => {
        return (
            <div>
                <CSEditor
                    mstID={CONSTANTS.CS_MstPneumIncReductionSheetID}
                    packTable={this.state.PneumIncEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onPneumIncEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstRelRisksPneumoniaSheetID}
                    packTable={this.state.RelRiskBFPneumEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onRelRiskBFPneumEditorPackTableChange}
                />
            </div>
        )
    };

    getMeninIncEditors = () => {
        return (
            <div>
                <CSEditor
                    mstID={CONSTANTS.CS_MstMeningIncReductionSheetID}
                    packTable={this.state.MeninIncEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onMeninIncEditorPackTableChange}
                />

                <CSEditor
                    mstID={CONSTANTS.CS_MstRelRisksMeningitisSheetID}
                    packTable={this.state.RelRiskBFMeningEditorPackTable}
                    showTitle={true}
                    onChange={this.props.onRelRiskBFMeningEditorPackTableChange}
                />
            </div>
        )
    };

    //==================================================================================================================
    //
    //                                                 Render
    //
    //==================================================================================================================
    renderDropdown = () => {
        const styles = {
            comboBox: {
                marginTop: 0,
                marginBottom: 20,
                marginRight: 25,
                paddingLeft: 0,
                width: 325,
                display: 'inline-block'
            },
            captionStyle: {
                marginTop: 0,
                color: '#f78c30'
            }
        };

        let items = [];
        items.push(RS('GB_stImpactsDiar'));
        items.push(RS('GB_stImpactsPneu'));
        items.push(RS('GB_stImpactsMenin'));

        return (
            <TComboBox
                caption      = ""
                itemIndex    = {this.props.EffOnIncidenceComboboxIdx}
                style        = {styles.comboBox}
                onChange     = {this.onComboboxChange}
                captionStyle = {styles.captionStyle}
                items        = {items}
            >
            </TComboBox>
        )
    };

    renderEditors = () => {
        let diarrInc = 0;
        let pneumInc = 1;
        let meninInc = 2;

        let result = '';

        switch (this.props.EffOnIncidenceComboboxIdx) {
            case diarrInc : {result = this.getDiarrIncEditors(); break;}
            case pneumInc : {result = this.getPneumIncEditors(); break;}
            case meninInc : {result = this.getMeninIncEditors(); break;}
            default : break;
        }

        return (result)
    };

    render() {
        return (
            <div>
                {this.renderDropdown()}
                {this.renderEditors()}
            </div>
        );
    }

}

export default CSEffOnIncidence;