const CONSTANTS = {
    CS_Proj: 1,
    GB_CS: 15,
    GB_CS_SubNat: 39,
    GB_DP: 1,
    GB_FP: 3,
    GB_AM: 4,
    GB_HA: 35,

    CS_MinArrVal: 0,
    CS_ResultsDrawerBreakpoint: 1450,

    CS_ProjectionMode: "ProjectionMode",
    CS_MissedOpportunitiesMode: "MissedOpportunitiesMode",
    CS_MissedOpportunitiesModeStep1: "MissedOpportunitiesModeStep1",
    CS_ExploreLiSTDataMode: "ExploreLiSTDataMode",
    CS_ExploreLiSTDataModeStep1: "ExploreLiSTDataModeStep1",
    CS_ExtractMode: "ExtractMode",

    CS_CloseUnderProjectionName: "CloseUnderProjectionName",
    CS_DeletedFile: "DeletedFile",
    CS_LogoutMode: "logout",

    //Projection side bar index(es)
    CS_ProjectionManagerIdx: 0,
    CS_ManageInterventionIdx: 1,
    CS_ReviewDefaultDataIdx: 2,
    CS_ViewResultsIdx: 3,

    //Approaches
    CS_StandardApproach: 0,
    CS_NutritionApproach: 1,

    //Screen size breakpoints
    CS_SmScreen: 768,
    CS_MdScreen: 992,
    CS_LgScreen: 1200,

    /* Fields in IVObj.  Flags for interventions determined by IVModData.xlsx and sent by the server. */
    CS_Coverage_ED: "Coverage_ED",
    CS_MaternalEff_ED: "MaternalEff_ED",
    CS_StillbirthEff_ED: "StillbirthEff_ED",
    CS_NNEff_ED: "NNEff_ED",
    CS_PNNEff_ED: "PNNEff_ED",
    CS_AdolEff_ED: "AdolEff_ED",
    CS_DisplayMat_ED: "DisplayMat_ED",
    CS_DisplaySB_ED: "DisplaySB_ED",
    CS_Display_ED: "Display_ED",
    CS_FromOtherModule: "FromOtherModule",
    CS_IsNutritionIV: "isNutritionIV",
    CS_IsBirthOutcomeIV: "isBirthOutcomeIV",

    //Languages to return to server
    GB_English: 1,
    GB_French: 2,
    GB_Arabic: 3,
    GB_Spanish: 4,
    GB_Russian: 5,
    GB_Portuguese: 6,
    GB_Chinese: 7,
    GB_Indonesian: 8,

    marginTop: 0, //Language in Radio group
    GB_EnglishIdx: 0,
    GB_FrenchIdx: 1,
    GB_SpanishIdx: 2,
    GB_PortugueseIdx: 3,

    CS_PackChart: 0,
    CS_PackTable: 1,
    CS_PackObjArray: 2,
    CS_ResultName: 2,
    CS_CardChartType: 3,

    CS_MOTopInterventions: 2,
    CS_PotentialDeathsAverted: 0,
    CS_PercentReductionInMortality: 1,
    CS_PotentialStuntingAverted: 2,

    CS_PieChart: "pie",
    CS_LineChart: "line",
    CS_BarChart: "bar",
    CS_ColumnChart: "column",
    CS_TableChart: "table",
    CS_ScatterChart: "scatter",
    CS_AreaChart: "area",
    CS_MultiChart: "multi",

    CS_NNChartColor: "#65A7EB",
    CS_SBChartColor: "#959595",
    CS_ChildChartColor: "#3B78BC",
    CS_MatChartColor: "#F78C30",
    CS_IVCostChartColor: "#C03600",
    CS_AgeSummaryChartColor: 7944716, //2007940,

    CS_EffectivenessChartColor: 12351547,
    CS_AffectedFractionChartColor: 15443813,

    CS_DiarIncChartColor: 12351547,
    CS_SevDiarIncChartColor: 15443813,
    CS_PneumIncChartColor: 9803157,
    CS_MeningIncChartColor: 3181815,

    CS_LeftJust: 0,
    CS_RightJust: 1,
    CS_CenterJust: 2,

    CS_SortByDeathsAverted: 0,
    CS_SortByDeliveryPoints: 1,
    CS_SortByInterventionCost: 2,

    CS_DefaultFirstYearIdx: 2,
    CS_DefaultFinalYearIdx: 12,
    CS_MinYear: 2000,
    CS_MinYearMax: 2046,
    CS_MaxYear: 2050,
    CS_DefaultFinalYear: 2030,
    CS_MaxNativeProjectionYear: 1985,
    CS_MODefaultFirstYear: 1996,
    CS_MOFinalYear: "2020",
    CS_FirstYearHistoricalCountryData: 1996,
    CS_FinalYearHistoricalCountryData: new Date().getFullYear() - 2,

    CS_QualityInactive: 0,
    CS_ANC1Mode: 1,
    CS_ANC4Mode: 2,
    CS_FascDelivMode: 3,

    /* These are master ID's and cannot change. */
    CS_MstBFPrevMode: 1,
    CS_MstBFPromotionMode: 2,

    //Delivery points for Intervention types
    CS_MstAllIntCovID: 0,
    CS_MstAllDelivPointsID: 0,
    CS_MstPericonceptualID: 1,
    CS_MstPregnancyID: 2,
    CS_MstChildbirthID: 3,
    CS_MstVaccinesID: 4,
    CS_MstPreventiveID: 5,
    CS_MstCurativeID: 6,

    //Outcomes for potential deaths
    CS_MstMortaltiyOutcomes: 0,
    CS_MstStuntingOutcomes: 1,

    //Outcomes for potential deaths averted (cause of death types)
    CS_CoD_MstAll: 0,
    CS_CoD_MstNNPostNN: 0,
    CS_CoD_MstStillbirth: 1,
    CS_CoD_MstNeonatal: 2,
    CS_CoD_MstPostNeonatal: 3,
    CS_CoD_MstMaternal: 4,
    CS_CoD_MstAdolescent: 5,
    CS_NumCoDTypes: 5,
    CS_Display_CPR: 6,

    /* Sex constants */
    DP_BothSexes: 0,
    DP_Male: 1,
    DP_Female: 2,

    DP_PerChildHIVPosCot: 1,
    DP_PerChildHIVRecART: 2,
    DP_Number: 0,
    DP_Percent: 1,
    DP_NumNeedCot: 1,
    DP_NumNeedART: 3,
    DP_PrenatalProphylaxis: 1,
    DP_PostnatalProphylaxis: 2,

    /* ARV Regimen Treatments*/
    DP_NoProphylaxis: 1,
    DP_SingleDoseNevir: 2,
    DP_DualARV: 3,
    DP_OptA: 4,
    DP_OptB: 5,
    DP_TripleARTBefPreg: 6,
    DP_TripleARTDurPreg: 7,
    DP_TripleARTDurPreg_Late: 8,
    DP_TotalTreat: 9,

    //Stillbirth causes of death
    CS_SB_MstCODAll: 0,
    CS_SB_MstAntepartum: 1,
    CS_SB_MstIntrapartum: 2,

    //Maternal causes of death
    CS_Mat_MstCODAll: 0,
    CS_Mat_MstAntepartHemorr: 1,
    CS_Mat_MstPostpartHemorr: 2,
    CS_Mat_MstHypertensiveDis: 3,
    CS_Mat_MstSepsis: 5,
    CS_Mat_MstAbortion: 6,
    CS_Mat_MstOtherDirectCauses: 9,
    CS_Mat_MstIndirectCauses: 12,
    CS_Mat_MstIntrapartHemorr: 13,
    CS_Mat_MstEmbolism: 14,

    //Children 0-1 months causes of death
    CS_MstNNCODAll: 0,
    CS_MstNNDiarr: 1,
    CS_MstNNAsphyxia: 3,
    CS_MstNNPreterm: 4,
    CS_MstNNTetanus: 5,
    CS_MstNNCongen: 6,
    CS_MstNNOther: 7,
    CS_MstNNSepsis: 17,
    CS_MstNNPneu: 18,

    //children 1-59 months causes of death
    CS_MstChildCODAll: 0,
    CS_MstDiarrhea: 8,
    CS_MstPneumonia: 9,
    CS_MstMeasles: 10,
    CS_MstMalaria: 11,
    CS_MstAIDS: 12,
    CS_MstOther: 13,
    CS_MstPertussis: 14,
    CS_MstMeningitis: 15,
    CS_MstInjury: 16,
    CS_MstOtherInfecDis: 27,
    CS_MstOtherNCD: 28,

    //******************************************************************************************
    //*******
    //*******  These constants do not have corresponding constants in desktop LiST
    //*******  They correspond to the editor tabs in the effectiveness editors
    //*******
    //******************************************************************************************

    CS_LiSTOnline_Periconcep: 1,
    CS_LiSTOnline_Preg: 2,
    CS_LiSTOnline_Childbirth: 3,
    CS_LiSTOnline_Nutrition: 4,
    CS_LiSTOnline_Preventive: 5,
    CS_LiSTOnline_Vaccines: 6,
    CS_LiSTOnline_Curative: 7,
    CS_LiSTOnline_FamPlanHIV: 8,
    CS_LiSTOnline_MaxGroups: 8,

    //Group master IDs for the coverage tabs in desktop LiST. These are needed to map for the interventions associated
    //with each of the checkboxes under Intervention Coverage in the historical country data drawer
    CS_GroupMstID_Periconceptual: 1,
    CS_GroupMstID_Pregnancy: 4,
    CS_GroupMstID_Childbirth: 7,
    CS_GroupMstID_Breastfeeding: 13,
    CS_GroupMstID_Preventive: 14,
    CS_GroupMstID_Vaccines: 15,
    CS_GroupMstID_Curative: 16,
    CS_GroupMstID_Stunting: 1001,
    CS_GroupMstID_Wasting: 1002,
    CS_GroupMstID_FertilityRisks: 1003, //MaternalAgeAndBirthOrder and BirthIntervals are the two editors for Fertility risks
    CS_GroupMstID_BirthIntervals: 1004, //currently not in use
    CS_GroupMstID_NutritionalStatus: 1005,
    CS_GroupMstID_FamPlanHIV: 1006,

    //Editor names for country data drawer expansion panel summaries, These CANNOT change because expansion panels for
    //CountryDataDrawer are dependent on them being numbered properly and in ascending order
    CS_MstIntCoverageID_LOL: 0,
    CS_MstCausesOfDeathID_LOL: 1,
    CS_MstEffIntMaternalID_LOL: 2,
    CS_MstEffIntStillbirthID_LOL: 3,
    CS_MstEffIntNeonatalID_LOL: 4,
    CS_MstEffIntChildID_LOL: 5,
    CS_MstEffIncidenceID_LOL: 6,
    CS_MstHerdVaccinesID_LOL: 7,
    CS_MstEffNutritionID_LOL: 8,
    CS_MstUnderNutritionID_LOL: 9,

    //Causes of death
    CS_COD_Maternal_LOL: 9,
    CS_COD_Stillbirth_LOL: 10,
    CS_COD_Neonatal_LOL: 11,
    CS_COD_PostNeonatal_LOL: 12,

    //Effectiveness of interventions - Maternal
    CS_Mat_AntepartHemorr_LOL: 13,
    CS_Mat_IntrapartHemorr_LOL: 14,
    CS_Mat_PostpartHemorr_LOL: 15,
    CS_Mat_HypertensiveDis_LOL: 16,
    CS_Mat_Sepsis_LOL: 17,
    CS_Mat_Abortion_LOL: 18,
    CS_Mat_Embolism_LOL: 19,
    CS_Mat_OtherDirectCauses_LOL: 20,
    CS_Mat_IndirectCauses_LOL: 21,

    //Effectiveness of interventions - Stillbirths
    CS_SB_Antepartum_LOL: 22,
    CS_SB_Intrapartum_LOL: 23,

    //Effectiveness of interventions - Neonatal
    CS_NN_Diarrhea_LOL: 24,
    CS_NN_Sepsis_LOL: 25,
    CS_NN_PPneumonia_LOL: 26,
    CS_NN_Asphyxia_LOL: 27,
    CS_NN_Prematurity_LOL: 28,
    CS_NN_Tetanus_LOL: 29,
    CS_NN_Congenital_LOL: 30,
    CS_NN_Other_LOL: 31,

    //Effectiveness of interventions - Child
    CS_Child_Diarrhea_LOL: 32,
    CS_Child_Pneumonia_LOL: 33,
    CS_Child_Meningitis_LOL: 34,
    CS_Child_Measles_LOL: 35,
    CS_Child_Malaria_LOL: 36,
    CS_Child_Pertussis_LOL: 37,
    CS_Child_Injury_LOL: 38,
    CS_Child_Other_LOL: 39,

    //Effectiveness of interventions on incidence
    CS_ImpactsDiarInc_LOL: 40,
    CS_ImpactsSeverePneumInc_LOL: 41,
    CS_ImpactsMeningInc_LOL: 42,

    //Herd Vaccines
    CS_Herd_DPT_LOL: 43,
    CS_Herd_Hib_LOL: 44,
    CS_Herd_Measles_LOL: 45,
    CS_Herd_Pneumococcal_LOL: 46,
    CS_Herd_MeninA_LOL: 47,
    CS_Herd_Rotavirus_LOL: 48,
    CS_Herd_Bednets_LOL: 49,
    CS_Herd_Malaria_LOL: 50,

    //Effectiveness of Nutrition
    CS_ImpactsOnBirthOutcomes_LOL: 51,
    CS_ImpactsOnStunting_LOL: 52,
    CS_ImpactsOnWasting_LOL: 53,
    CS_ImpactsOnMaternalAnemia_LOL: 54,
    CS_ImpactsOnBF_LOL: 55,
    CS_LowBirthWeightFactors_LOL: 56,

    //Impact of under-nutrition on mortality
    CS_ImpStuntingMort_LOL: 57,
    CS_ImpactWastingMort_LOL: 58,
    CS_ImpactBirthOutcomesOnMort_LOL: 59,
    CS_ImpactBFMort_LOL: 60,
    CS_ImpactAnemiaMatMort_LOL: 61,

    //intervention switches
    CS_MstLiSTOnlineDirectEntryOfStunting: 13500,
    CS_MstLiSTOnlineDirectEntryOfWasting: 13501,
    CS_MstLiSTOnlineDirectEntryOfBF: 13502,
    CS_MstDirectEntryFertilityRisks: 13013, //CS_MstMaternalAgeOfBirthOrder

    //values for potential checkboxes in coverage drawer
    CS_Pentavalent_CCFI: 3,

    //Interventions that should NOT be displayed as a checkbox in the intervention drawer, and should not be in the coverage editor
    //for subnational wizard
    IV_MstContraceptiveUse: 47,
    IV_MstCotrimoxazole: 35,
    IV_MstART: 36,
    IV_MstPMTCT: 14,

    CS_MstCurativeSubGroupHIV: 27,
    CS_MstPregnancySubGroupHIV: 29,

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////                                                                                                          /////
    /////    Explore LiST Data                                                                                     /////
    /////                                                                                                          /////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //Names for expansion panel summaries on the Explore LiST Data (ELD) sideBar, These CANNOT change because expansion panels
    //for the sideBar are dependent on them being numbered properly and in ascending order
    CS_ExploreSnapshotDataMode: 0,
    CS_ExploreInterventionCoverageMode: 1,
    CS_ExploreCausesOfDeathMode: 2,
    CS_ExploreEffectivenessValuesMode: 3,
    CS_ExploreHealthStatusMode: 4,

    //Explore Snapshot Data
    CS_MstOverallInformation_ED: 0,

    //these are used for intervention outcome swithes
    CS_MstMaternalMortality: 0,
    CS_MstStillbirths: 1,
    CS_MstNeonatalMortality: 2,
    CS_MstChildMortality: 3,
    CS_MstNutrition: 4,
    CS_MstBirthOutcomes: 5,
    CS_MstAdolescents: 6,

    //Explore COD
    CS_MstMaternalMortalityExData: 0,
    CS_MstStillbirthsExData: 1,
    CS_MstNeonatalMortalityExData: 2,
    CS_MstChildMortalityExData: 3,
    CS_MstAdolescentsExData: 4,

    //Adolescent sub categories
    CS_Mst5t9Mort: 7,
    CS_Mst10t14Mort: 8,
    CS_Mst15t19FemaleMort: 9,
    CS_Mst15t19MaleMort: 10,
    CS_Mst15t19Mort: 11,

    //Explore Effectiveness on Incidence
    CS_MstImpactsDiarrheaIncidence_ED: 0,
    CS_MstRiskDiarrheaWhenBF_ED: 1,
    CS_MstImpactsSeverePneuIncidence_ED: 2,
    CS_MstRiskPneumoniaWhenBF_ED: 3,
    CS_MstImpactsMeninIncidence_ED: 4,
    CS_MstRiskMeningitisWhenBF_ED: 5,

    //Explore Herd effectiveness of vaccines
    CS_MstDPTVacc_ED: 0,
    CS_MstHibVacc_ED: 1,
    CS_MstMeaslesVacc_ED: 2,
    CS_MstPneuVacc_ED: 3,
    CS_MstMeninA_ED: 4,
    CS_MstRotaVacc_ED: 5,
    CS_MstBednets_ED: 6,
    CS_MstMalariaVacc_ED: 7,

    //Explore Effectiveness Nutrition interventions groups
    CS_MstNoNutritonGroup_ED: 0,
    CS_MstImpactsBirthOutcomesGroup_ED: 1,
    CS_MstImpactsWastingGroup_ED: 2,
    CS_MstImpactsBreastFeedingGroup_ED: 3,
    CS_MstImpactsMatAnemiaGroup_ED: 4,

    //Explore Effectiveness Nutrition interventions
    CS_MstMaternalAgeAndBirthOrder_ED: 0,
    CS_MstBirthIntervals_ED: 1,
    CS_MstMaternalNutrition_ED: 2,
    CS_MstImpactsStunting_ED: 3,
    CS_MstSevereAcuteMalnutrition_ED: 4,
    CS_MstModerateAcuteMalnutrition_ED: 5,
    CS_MstImpactCompFeedingOnWasting_ED: 6,
    CS_MstPregnantWomen_ED: 7,
    CS_MstNonPregnantWomen_ED: 8,
    CS_MstImpactAgeAppropriateBreastFeeding_ED: 9,
    CS_MstImpactEarlyInitiationBreastFeeding_ED: 10,
    CS_MstLowBirthWeightFactors_ED: 11,

    //Explore Effectiveness Impact of under-nutrition
    CS_MstStuntingOnMort_ED: 0,
    CS_MstWastingOnMort_ED: 1,
    CS_MstBirthOutcomesOnMort_ED: 2,
    CS_MstBreastFeedingOnNeonatalMort_ED: 3,
    CS_MstBreastFeedingOnChildMort_ED: 4,
    CS_MstAnemiaOnMatMort_ED: 5,

    //Explore Health status groups
    CS_MstNoHealthStatusGroup_ED: 0,
    CS_MstBaselineChildHealthStatusGroup_ED: 1,
    CS_MstBaselineMaternalHealthStatusGroup_ED: 2,
    CS_MstNutritionStatusGroup_ED: 3,
    CS_MstPathogensGroup_ED: 4,
    CS_MstAbortionGroup_ED: 5,
    CS_MstHouseholdStatusGroup_ED: 6,

    //Explore Health status
    CS_MstNutritionalDeficiencies_ED: 0,
    CS_MstStatusAtBirth_ED: 1,
    CS_MstIncidence_ED: 2,
    CS_MstFaliciparum_ED: 3,
    CS_MstAnemiaWRA_ED: 4,
    CS_MstSevereAnemia_ED: 5,
    CS_MstLowBMIAmongWRA_ED: 6,
    CS_MstStuntingDist_ED: 7,
    CS_MstWastingDist_ED: 8,
    CS_MstDistAllCases_ED: 9,
    CS_MstDistSevereCases_ED: 10,
    CS_MstDistFatalCases_ED: 11,
    CS_MstSpontaneousAbortion_ED: 12,
    CS_MstAbortionIncidenceRatio_ED: 13,
    CS_MstPovertyFoodSecurity_ED: 14,
    CS_MstAveHouseholdSize_ED: 15,

    //Explore intervention group list     WARNING!!!! these are being used as current id as well, so if the order needs to change
    //a regular current ID will need to be added so the expansion panel displays as desired
    CS_MstPericonceptual_ED: 1,
    CS_MstPregnancy_ED: 4,
    CS_MstChildbirth_ED: 7,
    CS_MstBreastfeeding_ED: 13,
    CS_MstPreventive_ED: 14,
    CS_MstVaccines_ED: 15,
    CS_MstCurative_ED: 16,

    //Ages for breastfeedig
    CS_MstLessThan1Month: 0,
    CS_Mst1_5Months: 1,
    CS_Mst6_11Months: 2,
    CS_Mst12_23Months: 3,

    VW_Male: 1,
    VW_Female: 2,

    /* PMTCT constants */
    VW_PrenatalProphylaxis: 1,
    VW_PostnatalProphylaxis: 2,

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////                                                                                                          /////
    /////                                          Input Modvars                                                   /////
    /////                                                                                                          /////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /* Subnational Wizard */
    VW_TG_TFirstYear: "<FirstYear MV>",
    VW_TG_TTotalPop: "<TotalPop MV>",
    VW_TG_TTFR: "<TFR MV>",
    VW_TG_TCPR: "<CPR MV>",
    VW_TG_TIncidence: "<Incidence MV>",
    VW_TG_TPMTCT: "<PMTCT MV>",
    VW_TG_TCotrim: "<Cotrim MV>",
    VW_TG_TAdultART: "<AdultART MV>",
    VW_TG_TFirstTimeEnteringCoverage: "<FirstTimeEnteringCoverage MV>",
    VW_TG_TSubnatDefDataMstIDset: "<SubnatDefDataMstIDset MV>",
    VW_TG_TSubnatDataSourceArray: "<SubnatDataSourceArray MV>",
    VW_TG_TUseSubnatData: "<UseSubnatData MV>",

    /* Demography */
    DP_TGX_ARVRegimen_MV3: "<ARVRegimen MV3>",
    DP_TGX_ChildNeedPMTCT_MV: "<ChildNeedPMTCT MV>",
    DP_TGX_ChildOnPMTCT_MV: "<ChildOnPMTCT MV>",
    DP_TGX_ChildARTByAGPerNum_MV2: "<ChildARTByAgeGroupPerNum MV2>",
    DP_TGX_ChildTreatInputs_MV3: "<ChildTreatInputs MV3>",
    DP_TGX_HAARTBySex_MV: "<HAARTBySex MV>",
    DP_TGX_ChildARTCalc_MV2: "<ChildARTCalc MV2>",
    DP_TGX_SurvRate_MV2: "<SurvRate MV2>",
    DP_TGX_SurvRate1970_MV: "<SurvRate1970 MV>",

    /* Family planning */
    FP_TG_Tprevalence_MV: "prevalence MV",
    FP_TG_TTFR_MV: "TFR MV",
    FP_TG_TActiveMethods_MV: "ActiveMethods MV",
    FP_TG_TMethodName_MV: "MethodName MV",
    FP_TG_TNeedOption_MV: "NeedOption MV",
    FP_TG_TMethodMix_MV: "MethodMix MV",
    FP_TG_TPercentInUnion_MV: "PercentInUnion MV",
    FP_TG_TPPI_MV: "PPI MV",
    FP_TG_TTAR_MV: "TAR MV",
    FP_TG_TAbortionPercent_MV: "AbortionPercent MV",
    FP_TG_TSterility_MV: "Sterility MV",
    FP_TG_TAbortionOption_MV: "AbortionOption MV",
    FP_TG_TAgeGroupOption_MV: "AgeGroupOption MV",

    /* Age Incidence Model */
    HA_TGX_CondomsNonMaritalContacts_MV: "<CondomsNonMaritalContacts MV>",
    HA_TGX_PrEPCoverage_MV: "<PrEPCoverage MV>",
    HA_TGX_PrEPAdherence_MV: "<PrEPAdherence MV>",
    HA_TGX_PrEPEfficacy_MV: "<PrEPEfficacy MV>",
    HA_TGX_SexAgeFitType_MV: "<SexAgeFitType MV>",
    HA_TGX_MaleCircumcisionByAge_MV: "<MaleCircumcisionByAge MV>",
    HA_TGX_StructCoverages_MV: "<StructCoverages MV>",

    /* LiST */
    /* General */
    CS_TG_FirstYear_MV2: "<First Year MV2>",
    CS_TG_FinalYear_MV2: "<Final Year MV2>",
    CS_TG_ProjectionValidDate_MV2: "<Projection Valid Date and Time: MV2>",

    /* Controls */
    CS_TG_CovBY_MV: "<CovBY MV>",
    CS_TG_ANCMode_MV: "<ANCMode MV>",
    CS_TG_ChildbirthMode_MV: "<ChildbirthMode MV>",
    CS_TG_CalcCovFromInputs_MV2: "<CalcCovFromInputs MV2>",
    CS_TG_EnterBFImproveData_MV2: "<BFEntryMode MV3>",
    CS_TG_StuntingMatrixOn_MV2: "<StuntingMatrixOn MV2>",
    CS_TG_WastingMatrixOn_MV2: "<WastingMatrixOn MV2>",
    CS_TG_FertilityRisksMatrixOn_MV2: "<FertilityRisksMatrixOn MV2>",
    CS_TG_IPTpCBChecked_MV2: "<IPTpCBStatus MV2>",
    CS_TG_AbortCalcFamPlan_MV2: "<AbortCalcFamPlan MV2>",
    CS_TG_SelectedIVSet_MV: "<Selected intervention set MV>",
    CS_TG_AdolMode_MV: "<AdolMode MV>",
    // CS_TG_InterventionList_MV                 : '<InterventionList MV>',
    // CS_TG_InterventionGroupList_MV            : '<InterventionGroupList MV>',
    // CS_TG_InterventionSubGroupList_MV         : '<InterventionSubGroupList MV>',

    /* Health status */
    CS_TG_MenARecommended: "<MenA recommended>",
    CS_TG_NutritionalDeficiencies_MV2: "<Nutritional Deficiencies MV2>",
    CS_TG_VitADefInPW_MV: "<VitA deficiency in PW MV>",
    CS_TG_StatusAtBirth_MV: "<Status At Birth MV>",
    CS_TG_DiarInc_MV: "<DiarInc MV>",
    CS_TG_SevereDiarInc_MV: "<SevereDiarInc MV>",
    CS_TG_PneumInc_MV: "<Pneumonia Incidence MV>",
    CS_TG_MeningInc_MV: "<Meningitis Incidence MV>",
    CS_TG_MaternalAnemia_MV: "<Maternal Anemia MV>",
    CS_TG_RelRisksAnemia_MV2: "<RelRisksAnemia MV2>",
    CS_TG_PercWom15t49LowBMI_MV: "<Percentage of women aged 15-49 with low BMI MV>",
    CS_TG_PercExposedFalciparum_MV: "<Percent exposed to falciparum>",
    CS_TG_FolicAcidDeficPrev_MV: "<Folic acid deficient prevalence>",
    CS_TG_CongenitalDueToNTDsAF_MV: "<CongenitalDueToNTDsAF>",
    CS_TG_StuntingDistr_MV: "<Stunting distribution MV>",
    CS_TG_SingleStuntingDistr_MV: "<Single Stunting distribution MV>",
    CS_TG_WastingDistr_MV: "<Wasting distribution MV>",
    CS_TG_SingleWastingDistr_MV: "<Single Wasting distribution MV>",
    CS_TG_UseDetailedStuntingMatrix_MV: "<Use Detailed Stunting Matrix MV>",
    CS_TG_UseDetailedWastingMatrix_MV: "<Use Detailed Wasting Maxtrix MV>",
    CS_TG_DiarPath_MV2: "<Diarrhea pathogens MV2>",
    CS_TG_PneumPath_MV: "<Pneumonia pathogens MV>",
    CS_TG_MenPath_MV: "<Meningitis pathogens MV>",
    CS_TG_BaselineMort_MV: "<BaselineMort MV>",
    CS_TG_PercDthByCause_MV2: "<PercDthByCause MV2>",
    CS_TG_AdolMortRatio_MV2: "<AdolMortRatio MV2>",
    CS_TG_PercAdolDeathsByCause_MV: "<PercAdolDeathsByCause MV>",
    CS_TG_MatMortRatio_MV2: "<MatMortRatio MV2>",
    CS_TG_PercMatDeathsByCause_MV: "<PercMatDeathsByCause MV>",
    CS_TG_PercPregEndAbort_MV2: "<PercPregEndAbort MV2>",
    CS_TG_AbortIncRatio_MV2: "<AbortIncRatio MV 2>",
    CS_TG_SBRate_MV2: "<SBRate MV2>",
    CS_TG_SBCauses_MV: "<SBCauses MV>",
    CS_TG_PerCapitaInc_MV: "<PerCapitaInc MV>",
    CS_TG_FoodInsecure_MV: "<FoodInsecure MV>",
    CS_TG_AvgHouseholdSize_MV: "<Average Household Size MV>",
    CS_TG_FirstYearAtWork_MV: "<First year at work MV>",
    CS_TG_LastYearAtWork_MV: "<Last year at work MV>",
    CS_TG_GNIPerCap_MV: "<GNIPerCap MV>",
    CS_TG_LaborForceParRate_MV: "<LaborForceParRate MV>",
    CS_TG_LaborShareInc_MV: "<LaborShareInc MV>",
    CS_TG_WorkingYears_MV: "<WorkingYears MV>",

    /* Explore data health status */
    CS_TG_BaselineMortLiSTOnline_MV: "<BaselineMortLiSTOnline MV>",
    CS_TG_PercDthByCauseLiSTOnline_MV2: "<PercDthByCauseLiSTOnline MV2>",
    CS_TG_MatMortRatioLiSTOnline_MV2: "<MatMortRatioLiSTOnline MV2>",
    CS_TG_PercMatDeathsByCauseLiSTOnline_MV: "<PercMatDeathsByCauseLiSTOnline MV>",
    CS_TG_SBRateLiSTOnline_MV2: "<SBRateLiSTOnline MV2>",
    CS_TG_SBCausesLiSTOnline_MV: "<SBCausesLiSTOnline MV>",
    CS_TG_AdolMortRatioLiSTOnline_MV2: "<AdolMortRatioLiSTOnline MV2>",
    CS_TG_PercAdolDeathsByCauseLiSTOnline_MV: "<PercAdolDeathsByCauseLiSTOnline MV2>",

    /* Coverage */
    CS_TG_Coverage_MV3: "<Coverage MV3>",
    CS_TG_BreastfeedPrev_MV: "<Breastfeeding Prevalence MV>",
    CS_TG_RoutDetVaccCov_MV3: "<RoutDetVaccCov MV3>",
    CS_TG_BreastFeedPromoCB_MV: "<Breastfeeding Promotion Checkboxes MV>",
    CS_TG_PentavalentDetVaccCov_MV: "<PentavalentDetVaccCov MV>",
    CS_TG_AgeAndBirthOrderMatrix_MV2: "<Age And Birth Order Matrix MV2>",
    CS_TG_BirthIntervalsMatrix_MV2: "<Birth Intervals Matrix MV2>",

    /* Maternal, Stillbirth, and Child Efficacy */
    CS_TG_MatEfficacy_MV3: "<Maternal Efficacy MV3>",
    CS_TG_SBEfficacy_MV3: "<SBEfficacy MV3>",
    CS_TG_Efficacy_MV2: "<Efficacy MV2>",
    CS_TG_DetVaccEff_MV2: "<DetVaccEff MV2>",

    /* Effectiveness of interventions on incidence */
    CS_TG_DiarrIncReduction_MV3: "<DiarrIncReduction MV3>",
    CS_TG_SevDiarrIncReduction_MV: "<Severe Diarrhea Incidence Reduction>",
    CS_TG_PneumIncReduction_MV: "<Pneumonia Incidence Reduction MV>",
    CS_TG_MeningIncReduction_MV: "<Meningitis Incidence Reduction MV>",
    CS_TG_RelRisksDiarrhea_MV2: "<RelRisksDiarrhea MV2>",
    CS_TG_RelRisksPneumonia_MV2: "<RelRisksPneumonia MV2>",
    CS_TG_RelRisksMeningitis_MV2: "<RelRisksMeningitis MV2>",

    /* Herd effectiveness of vaccines */
    CS_TG_CustomHerdVaccEff_MV3: "<Custom herd vaccine efficacy MV 3>",

    /* Effectiveness of nutrition interventions */
    CS_TG_AgeAndBirthOrder_MV2: "<Age And Birth Order MV2>",
    CS_TG_BirthIntervals_MV2: "<Birth Intervals MV2>",
    CS_TG_IntervForBO_MV7: "<Maternal Nutrition MV7>",
    CS_TG_ImpactsOnStunting_MV: "<Impacts On Stunting MV>",
    CS_TG_ImpactsOnWasting_MV4: "<Impacts On Wasting MV4>",
    CS_TG_ImpactsOnMatAnemia_MV3: "<Impacts On Maternal Anemia MV3>",
    CS_TG_ImpactsBFPromotion_MV3: "<ImpactsBFPromotion MV3>",
    CS_TG_KMConBF_MV: "<Kangaroo Mother Care on Breastfeeding MV>",
    CS_TG_LBWF_MV2: "<Low birth weight factor MV2>",
    CS_TG_ReducStunt_MV: "<ReducStunt MV>",
    CS_TG_EducationAttain_MV: "<EducationAttain MV>",

    /* Impact of under-nutrition on mortality */
    CS_TG_RelRisksStunting_MV: "<RelRisksStunting MV>",
    CS_TG_RelRisksWasting_MV: "<RelRisksWasting MV>",
    CS_TG_ImpactBFOnMortNeoNat_MV: "<ImpactBFOnMortNeoNat MV>",
    CS_TG_ImpactBFOnMortPostNat_MV: "<ImpactBFOnMort MV>",
    CS_TG_RelRisksIUGR_LBW_MV: "<RelRisksIUGR_LBW MV>",

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////                                                                                                          /////
    /////                                         Result Modvars                                                   /////
    /////                                                                                                          /////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Neonatal, child sectors
    CS_TG_ChDeaths_MV2: "<ChDeaths MV2>",
    CS_TG_ChDeathsByYear_MV2: "<ChDeathsByYear MV2>",
    CS_TG_ChDeathsPrevd_MV2: "<ChDeathsPrevd MV2>",
    CS_TG_ChDeathsxCause_MV2: "<ChDeathsxCause MV2>",
    CS_TG_DeathsByCauseByBirthCohort_MV2:
        "<Cause-specific deaths in children under five years of age by year of birth cohort2>",
    CS_TG_ChDeathsPrevdxCause_MV2: "<ChDeathsPrevdxCause MV2>",
    CS_TG_ChDeathsPrevdxInt_MV3: "<ChDeathsPrevdxInt MV3>",
    CS_TG_DeathsPrevByInterByCause_MV3: "<Child deaths prevented by intervention by cause MV3>",
    CS_TG_DetVaccCovResults_MV3: "<DetVaccCovResults MV3>",
    CS_TG_DAvtdByVaccByCohortResult_MV2: "<DAvtdByVaccByCohortResult MV2>",
    CS_TG_DAvtdByVaccByCohortByCause_MV3: "<DAvtdByVaccByCohortByCause MV3>",

    // Maternal sector
    CS_TG_MatDeaths_MV2: "<MatDeaths MV2>",
    CS_TG_MatDeathsPrev_MV2: "<MatDeathsPrev MV2>",
    CS_TG_MatDeathsByCauseResults_MV2: "<MatDeathsByCauseResults MV2>",
    CS_TG_MatDeathsPrevByCause_MV2: "<MatDeathsPrevByCause MV2>",
    CS_TG_MatDeathsPrevByInter_MV3: "<MatDeathsPrevByInter MV3>",
    CS_TG_MatDeathsPrevByInterByCause_MV3: "<Maternal deaths prevented by intervention by cause MV3>",
    CS_TG_NumAbortions_MV2: "<Number of Abortions MV2>",
    CS_TG_PercAnemicPregWomen_MV2: "<Percent of pregnant women who are anemic MV2>",

    // Stillbirth sector
    CS_TG_SBResults_MV2: "<SBResults MV2>",
    CS_TG_SBPrevResults_MV2: "<SBPrevResults MV2>",
    CS_TG_SBByCauseResults_MV2: "<SBByCauseResults MV2>",
    CS_TG_SBPrevByCauseResults_MV2: "<SBPrevByCauseResults MV2>",
    CS_TG_SBPrevByInterResults_MV3: "<SBPrevByInterResults MV3>",
    CS_TG_SBPrevByInterByCause_MV3: "<Stillbirths prevented by intervention by cause MV3>",

    // Mortality rates sector
    CS_TG_SBRateResults_MV2: "<SBRateResults MV2>",
    CS_TG_MatMortRatioResult_MV2: "<MatMortRatioResult MV2>",
    CS_TG_MatMortRateResult_MV2: "<MatMortRateResult MV2>",
    CS_TG_MortalityRateResults_MV2: "<MortalityRateResults MV2>",
    CS_TG_RedMortIntervResults_MV3: "<RedMortIntervResults MV3>",
    CS_TG_RedMortIntervSortedIDs_MV3: "<RedMortIntervSortedIDs MV3>",

    // Nutrition sector
    CS_TG_MAM_PIN_MV: "<MAM_PIN MV>",
    CS_TG_SAM_PIN_MV: "<SAM_PIN MV>",
    CS_TG_PInVarHtForAgeStunt_MV2: "<PInVarHtForAgeStunt MV2>",
    CS_TG_PInVarWtForHtWaste_MV2: "<PInVarWtForHtWaste MV2>",
    CS_TG_NumStunted_MV: "<NumStunted MV>",
    CS_TG_NumStuntedAverted_MV: "<NumStuntedAverted MV>",
    CS_TG_NumStuntedAvertedByInter_MV: "<NumStuntedAvertedByInter MV>",
    CS_TG_PStuntRedAccountByIntBaseYr_MV2: "<PStuntRedAccountByIntBaseYr MV2>",
    CS_TG_BFPrevResult_MV2: "<BFPrevResult MV2>",
    CS_TG_EarlyInitBFPrev_MV2: "<Early Initiation BF prevalence MV2>",
    CS_TG_BirthTermResult_MV2: "<Birth Term Results MV2>",
    CS_TG_PercWomenAnemicResults_MV: "<Percent of women with anemia MV>",
    CS_TG_NumWomenAnemicResults_MV: "<Number of women with anemia MV>",
    CS_TG_AnemiaCasesPreventedResults_MV: "<Number of anemia cases prevented MV>",

    // Incidence and etiology sector
    CS_TG_DiarIncResult_MV2: "<DiarIncResult MV2>",
    CS_TG_SevereDiarIncResult_MV: "<Severe Diarrhea Incidence Rates Result>",
    CS_TG_PneumIncResult_MV2: "<PneumIncResult MV2>",
    CS_TG_MeningIncResult_MV2: "<Meningitis Incidence Result MV2>",
    CS_TG_DiarCasesResult_MV2: "<Number of diarrhea Cases MV2>",
    CS_TG_SevereDiarCasesResult_MV: "<Number of Severe Diarrhea Cases Result>",
    CS_TG_PneumCasesResult_MV2: "<Number of pneumonia cases MV2>",
    CS_TG_MeningCasesResult_MV2: "<Number of Meningitis Cases- Result MV2>",
    CS_TG_DiarCasesAvertByInter_MV3: "<DiarCasesAvertByInter MV3>",
    CS_TG_DiarCasesAvertByInter_MV4: "<DiarCasesAvertByInter MV4>",
    CS_TG_SevereDiarCasesAvertByInter_MV: "<Number of Severe Diarrhea Cases Averted by Intervention Result>",
    CS_TG_SevereDiarCasesAvertByInter_MV2: "<Number of Severe Diarrhea Cases Averted by Intervention Result2>",
    CS_TG_PneumCasesAvertByInter_MV3: "<PneumCasesAvertByInter MV3>",
    CS_TG_MeninCasesAvertByInter_MV3: "<MeninCasesAvertByInter MV3>",
    CS_TG_PercDiarDeathsByPath_MV2: "<PDiarrMortByPathResult MV2>",
    CS_TG_PercPneumDeathsByPath_MV3: "<PPneumMortByPathResult MV3>",
    CS_TG_PercMeningDeathsByPath_MV2: "<PMeningMortByPathResult MV2>",
    CS_TG_DiarDeathsByPath_MV2: "<Number of deaths due to pathogens by year of impact - Diarrhea MV2>",
    CS_TG_PneumDeathsByPath_MV2: "<Number of deaths due to pathogens by year of impact - Pneumonia MV2>",
    CS_TG_MeningDeathsByPath_MV2: "<Number of deaths due to pathogens by year of impact - Meningitis MV2>",
    CS_TG_DiarDeathsByPathBy1yrAgeGrps_MV:
        "<Number of deaths due to pathogens by year of impact by 1 year age groups - Diarrhea MV>",
    CS_TG_PneumDeathsByPathBy1yrAgeGrps_MV:
        "<Number of deaths due to pathogens by year of impact by 1 year age groups - Pneumonia MV>",
    CS_TG_MeningDeathsByPathBy1yrAgeGrps_MV:
        "<Number of deaths due to pathogens by year of impact by 1 year age groups - Meningitis MV>",
    CS_TG_PercDiarCasesByPath_MV2: "<PDiarIncWInfectResult MV2>",
    CS_TG_PercSevDiarCasesByPath_MV: "<Percent of 1-59 Month Severe Diarrhea Cases by Pathogen>",
    CS_TG_PercPneumCasesByPath_MV3: "<PPneumIncWInfectResult MV3>",
    CS_TG_PercMeningCasesByPath_MV2: "<PMeningIncWInfectResult MV2>",
    CS_TG_DiarCasesByPath_MV2: "<Number of cases by pathogen by year of impact - Diarrhea MV2>",
    CS_TG_SevereDiarCasesByPath_MV: "<Number of Severe Diarrhea Cases by Pathogen by Year of Impact Result>",
    CS_TG_PneumCasesByPath_MV2: "<Number of cases by pathogen by year of impact - Pneumonia MV2>",
    CS_TG_MeningCasesByPath_MV2: "<Number of cases by pathogen by year of impact - Meningitis MV2>",
    CS_TG_DiarCasesByPathBy1YrAgeGrps_MV:
        "<Number of cases by pathogen by year of impact by 1 year age groups - Diarrhea MV>",
    CS_TG_SevereDiarCasesByPathBy1YrAgeGrps_MV:
        "<Number of Severe Diarrhea Cases by Pathogen by Year of Impact by 1 year age groups Result>",
    CS_TG_PneumCasesByPathBy1YrAgeGrps_MV:
        "<Number of cases by pathogen by year of impact by 1 year age groups - Pneumonia MV>",
    CS_TG_MeningCasesByPathBy1YrAgeGrps_MV:
        "<Number of cases by pathogen by year of impact by 1 year age groups - Meningitis MV>",
    CS_TG_DiarCasesByBirthCoh_MV2: "<Number of cases by cause by birth cohort - Diarrhea MV2>",
    CS_TG_PneumCasesByBirthCoh_MV2: "<Number of cases by cause by birth cohort - Pneumonia MV2>",
    CS_TG_MeningCasesByBirthCoh_MV2: "<Number of cases by cause by birth cohort - Meningitis MV2>",
    CS_TG_SevDiarCasesByBirthCoh_MV: "<Number of cases by cause by birth cohort - Severe diarrhea MV>",
    CS_TG_DiarCasesAvtdByYr1stDoseVacc_MV2:
        "<Cases averted by cause by year that the first dose of a vaccine was received by a child - Diarrhea MV2>",
    CS_TG_PneumCasesAvtdByYr1stDoseVacc_MV2:
        "<Cases averted by cause by year that the first dose of a vaccine was received by a child - Pneumonia MV2>",
    CS_TG_MeningCasesAvtdByYr1stDoseVacc_MV2:
        "<Cases averted by cause by year that the first dose of a vaccine was received by a child - Meningitis MV2>",
    CS_TG_SevDiarCasesAvtdByYr1stDoseVacc_MV:
        "<Cases averted by cause by year that the first dose of a vaccine was received by a child - Severe diarrhea MV>",
    CS_TG_DiarDeathsByPathBirthCoh_MV2: "<Number of deaths by pathogen - Diarrhea MV2>",
    CS_TG_PneumDeathsByPathBirthCoh_MV2: "<Number of deaths by pathogen - Pneumonia MV2>",
    CS_TG_MeningDeathsByPathBirthCoh_MV2: "<Number of deaths by pathogen - Meningitis MV2>",
    CS_TG_DiarCasesByPathBirthCoh_MV2: "<Number of cases by pathogen - Diarrhea MV2>",
    CS_TG_PneumCasesByPathBirthCoh_MV2: "<Number of cases by pathogen - Pneumonia MV2>",
    CS_TG_MeningCasesByPathBirthCoh_MV2: "<Number of cases by pathogen - Meningitis MV2>",
    CS_TG_SevDiarCasesByPathBirthCoh_MV: "<Number of cases by pathogen - Severe diarrhea MV>",
    CS_TG_DiarDeathsByPathBirthCohVC_MV: "<Number of deaths by pathogen - Diarrhea - Vaccinated cohort MV>",
    CS_TG_PneumDeathsByPathBirthCohVC_MV: "<Number of deaths by pathogen - Pneumonia - Vaccinated cohort MV>",
    CS_TG_MeningDeathsByPathBirthCohVC_MV: "<Number of deaths by pathogen - Meningitis - Vaccinated cohort MV>",
    CS_TG_DiarCasesByPathBirthCohVC_MV: "<Number of cases by pathogen - Diarrhea - Vaccinated cohort MV>",
    CS_TG_PneumCasesByPathBirthCohVC_MV: "<Number of cases by pathogen - Pneumonia - Vaccinated cohort MV>",
    CS_TG_MeningCasesByPathBirthCohVC_MV: "<Number of cases by pathogen - Meningitis - Vaccinated cohort MV>",
    CS_TG_PersonsVaccByInterv_MV2: "<Number of persons vaccinated by intervention MV2>",
    CS_TG_PersonsVaccByIntervBy1YrAgeGrps_MV: "<Number of persons vaccinated by intervention by 1 year age groups MV>",
    CS_TG_PersonsVaccByIntervBy1YrAgeGrps_MV2:
        "<Number of persons vaccinated by intervention by 1 year age groups MV2>",

    /* Demography */
    DP_TGX_BigPop_MV3: "<BigPop MV3>",
    DP_TGX_TFR_MV: "<TFR MV>",
    DP_TGX_Births_MV: "<Births MV>",
    DP_TGX_LE_MV2: "<LE MV2>",
    DP_TGX_AdjLE_MV: "<AdjLE MV>",
    DP_TGX_Src: "<DPSources MV>",

    /* Time constants */
    DP_FIRST_INDEX: 1,
    DP_MaxSingleAges: 80,

    DP_AllAges: 0,
    DP_A0_4: 1,
    DP_A5_9: 2,
    DP_A10_14: 3,
    DP_A15_19: 4,
    DP_A20_24: 5,
    DP_A25_29: 6,
    DP_A30_34: 7,
    DP_A35_39: 8,
    DP_A40_44: 9,
    DP_A45_49: 10,
    DP_A50_54: 11,
    DP_A55_59: 12,
    DP_A60_64: 13,
    DP_A65_69: 14,
    DP_A70_74: 15,
    DP_A75_Up: 16,
    DP_A75_79: 16,
    DP_A80_Up: 17,
    DP_MAX_AGE: 17,

    /*EPW Ages*/
    HA_15_24: 1,
    HA_25_34: 2,
    HA_35_44: 3,
    HA_45_54: 4,

    HA_MAX_AGE: 17,

    HA_AllAges: 0,

    HA_DP_FIRST_INDEX: 1,
    HA_DP_AllAges: 0,
    HA_DP_H_No: 1,
    HA_DP_H_Max: 8,
    HA_DP_A10_14: 3,
    HA_DP_A15_19: 4,
    HA_DP_A20_24: 5,
    HA_DP_A25_29: 6,
    HA_DP_A30_34: 7,
    HA_DP_A35_39: 8,
    HA_DP_A40_44: 9,
    HA_DP_A45_49: 10,
    HA_DP_A50_54: 11,
    HA_DP_MaxSingleAges: 80,

    /* PrEP method constants */
    HA_FirstMethod: 1,
    HA_Oral: 1,
    HA_Inject: 2,
    HA_Gel: 3,
    HA_Ring: 4,
    HA_FinalMethod: 4,
    HA_FemaleOnlyMethods: [3, 4],

    /*Editors with logistic curves*/
    HA_STIPrevLogID: 0,
    HA_PartnerLogID: 1,
    HA_CondomUseLogID: 2,
    HA_MaleCircumLogID: 3,
    HA_NumLogEditors: 4,

    HA_CondomPromo: 1,
    HA_ViolencePrev: 2,
    HA_HTC_selftesting: 3,
    HA_SocialAssetBuild: 4,
    HA_FamilyPlanning: 5,

    HA_CareGiverPrograms: 6,
    HA_EducationalSubsidies: 7,
    HA_CashTransfers: 8,
    HA_EconomicEmpowerment: 9,

    HA_SexualityEducation: 10,
    HA_OutOfSchoolProgram: 11,
    HA_CommunityMobilization: 12,

    //Editors
    CS_MstLinkIntsSheetID: 1,
    CS_MstExportIntsSheetID: 2,

    CS_MstNutrDefSheetID: 3,
    CS_MstStatusAtBirthSheetID: 4,
    CS_MstDeathCauseIncSheetID: 5,

    CS_MstIPTpSheetID: 6,
    CS_MstMaternalAnemiaSheetID: 7,
    CS_MstMatAnemiaAFSheetID: 8,
    CS_MstPercWom15t49LowBMISheetID: 9,

    CS_MstSingleStuntingDistrSheetID: 10,
    CS_MstStuntingDistrSheetID: 11,
    CS_MstSingleWastingDistrSheetID: 12,
    CS_MstWastingDistrSheetID: 13,

    CS_MstPathIncMortSheetID: 14,

    CS_MstBaselineMortSheetID: 15,
    CS_MstDeathsByCauseNNSheetID: 16,
    CS_MstDeathsByCausePNNSheetID: 17,
    CS_MstDeathsByCause5t9SheetID: 136,
    CS_MstDeathsByCause10t14SheetID: 137,
    CS_MstDeathsByCause15t19FemaleSheetID: 138,
    CS_MstDeathsByCause15t19MaleSheetID: 139,
    CS_MstDeathsByCause15t19SheetID: 140,

    CS_MstMatMortRatioSheetID: 18,
    CS_MstMatDeathsByCauseSheetID: 19,

    CS_MstPercPregEndAbortSheetID: 20,
    CS_MstAbortIncRatioSheetID: 21,

    CS_MstStillbirthRateSheetID: 22,
    CS_MstStillbirthsByCauseSheetID: 23,

    CS_MstPerCapitaIncSheetID: 24,
    CS_MstAvgHouseholdSizeSheetID: 25,

    CS_MstCoveragePericonceptualSheetID: 26,
    CS_MstCoveragePregnancySheetID: 27,
    CS_MstBirthSurveyCoverageSheetID: 28,
    CS_MstDelivLevelCoverageSheetID: 29,
    CS_MstDelivLevelIntSheetID: 30,
    CS_MstDelivIntCoverageSheetID: 31,
    CS_MstBFPrevSheetID: 32,
    CS_MstEarlyInitBFPrevSheetID: 33,
    CS_MstCoverageBFPromoSheetID: 34,
    CS_MstSingleStuntingMatrixSheetID: 35,
    CS_MstStuntingMatrixSheetID: 36,
    CS_MstCoveragePreventiveSheetID: 37,
    CS_MstVaccineCoverageSheetID: 38,
    CS_MstDetVaccineCovSheetID: 39,
    CS_MstCoverageCurativeSheetID: 40,
    CS_MstSingleWastingMatrixSheetID: 41,
    CS_MstWastingMatrixSheetID: 42,
    CS_MstAgeAndBirthOrderMatrixSheetID: 43,
    CS_MstBirthIntervalsMatrixSheetID: 44,

    CS_MstMatIntEfficacySheetID: 45,
    CS_MstMatDelEfficacySheetID: 46,
    CS_MstSBIntEfficacySheetID: 47,
    CS_MstSBDelEfficacySheetID: 48,
    CS_MstNNIntEfficacySheetID: 49,
    CS_MstNNDelEfficacySheetID: 50,
    CS_MstPNNIntEfficacySheetID: 51,
    CS_MstDetVaccEfficacySheetID: 52,

    CS_MstDiarrIncReductionSheetID: 53,
    CS_MstRelRisksDiarrheaSheetID: 54,

    CS_MstPneumIncReductionSheetID: 55,
    CS_MstRelRisksPneumoniaSheetID: 56,

    CS_MstMeningIncReductionSheetID: 57,
    CS_MstRelRisksMeningitisSheetID: 58,

    CS_MstVaccineEffSheetID: 59,
    CS_MstAgeAndBirthOrderSheetID: 60,
    CS_MstMaternalNutritionSheetID: 61,
    CS_MstBirthIntervalsSheetID: 62,
    CS_MstImpactsOnStuntingSheetID: 63,
    CS_MstImpactsOnSevereWastingSheetID: 64,
    CS_MstImpactsOnModerateWastingSheetID: 65,
    CS_MstImpactCompFeedWastingSheetID: 66,
    CS_MstImpactsOnMatAnemiaSheetID: 67,
    CS_MstImpactBFImprovementSheetID: 68,
    CS_MstImpactEarlyInitBFSheetID: 69,
    CS_MstLBWFSheetID: 70,
    CS_MstRelRisksStuntingSheetID: 71,
    CS_MstRelRisksWastingSheetID: 72,
    CS_MstRelRisksIUGR_LBWSheetID: 73,
    CS_MstImpactBFOnMortNNSheetID: 74,
    CS_MstImpactBFOnMortPNNSheetID: 75,
    CS_MstOddsRatioAnemiaSheetID: 76,

    CS_MstStaffBaselineDataSheetID: 77,
    CS_MstOtherDirIndirCostsSheetID: 78,
    CS_MstProgramCostsSheetID: 79,
    CS_MstLogisticsWastageSheetID: 80,
    CS_MstInfrastructureInvestSheetID: 81,
    CS_MstOtherHlthSysCostsSheetID: 82,
    CS_MstInefficienciesSheetID: 83,

    CS_MstMenARecommendedSheetID: 84,

    CS_MstLiSTOnlinePericonceptualSheetID: 85,
    CS_MstLiSTOnlinePregnancySheetID: 86,
    CS_MstLiSTOnlineDelivIntCovSheetID: 87,
    CS_MstLiSTOnlinePreventiveSheetID: 88,
    CS_MstLiSTOnlineVaccineCovSheetID: 89,
    CS_MstLiSTOnlineCurativeSheetID: 90,
    CS_MstLiSTOnlineAgeAndBirthOrderSheetID: 91,
    CS_MstLiSTOnlineBirthIntervalsSheetID: 92,
    CS_MstLiSTOnlineMixedCoverageSheetID: 93,
    CS_MstLiSTOnlineBFPromoSheetID: 94,
    CS_MstLiSTOnlineStuntingSheetID: 95,
    CS_MstLiSTOnlineWastingSheetID: 96,
    CS_MstLiSTOnlineMatIntEffSheetID: 97,
    CS_MstLiSTOnlineSBIntEffSheetID: 98,
    CS_MstLiSTOnlineNNIntEffSheetID: 99,
    CS_MstLiSTOnlinePNNIntEffSheetID: 100,
    CS_MstUtilizationSheetID: 101,
    CS_MstLiSTOnlineQualitySheetID: 102,
    CS_MstLiSTOnlineQualityMatIntEffSheetID: 103,
    CS_MstLiSTOnlineQualitySBIntEffSheetID: 104,
    CS_MstLiSTOnlineQualityNNIntEffSheetID: 105,
    CS_MstLiSTOnlineQualityPNNIntEffSheetID: 106,
    CS_MstLiSTOnlineBFPrevSheetID: 107,
    CS_MstLiSTOnlineSingleStuntingSheetID: 108,
    CS_MstLiSTOnlineSingleWastingSheetID: 109,

    CS_MstExpandResultsSheetID: 110,
    CS_MstDisplayCODSheetID: 111,
    CS_MstDisplayMOSheetID: 112,
    CS_ShowDataSheetID: 113,

    CS_MstSubNatFYPSheetID: 114,
    CS_MstSubNatTFRSheetID: 115,
    CS_MstSubNatIncSheetID: 116,
    CS_MstSubNatPMTCTSheetID: 117,
    CS_MstSubNatCotrimSheetID: 118,
    CS_MstSubNatARTSheetID: 119,
    CS_MstSubNatCPRSheetID: 120,

    CS_MstFirstYearPopSheetID: 121,
    CS_MstTFRSheetID: 122,

    CS_MstCoveragePregnancyQualitySheetID: 123,
    CS_MstDelivIntCoverageQualitySheetID: 124,

    CS_MstGNIPerCapSheetID: 125,
    CS_MstLaborForceParRateSheetID: 126,
    CS_MstLaborShareIncSheetID: 127,

    CS_MstWorkingYearsSheetID: 128,

    CS_MstReducStuntSheetID: 129,
    CS_MstEducationAttainSheetID: 130,

    CS_MstAdolIntEfficacySheetID: 131,
    CS_MstBaselineAdolMortSheetID: 132,
    CS_MstDeathsByCauseAdolSheetID: 133,

    CS_MstMatNutrDefSheetID: 134,

    CS_MstEarlyInitBFSheetID: 135,

    FP_mstMethMixSheetID: 3,
    FP_mstProxDetSheetID: 7,
    FP_mstPrevSheetID: 9,

    HV_mstCoverageSheetID: 5000,
    HV_mstPercMalesCircumSheetID: 5001,
    HV_mstPercOnARTSheetID: 5002,
    HV_mstPercUsingCondomsNonMaritalContactSheetID: 5003,
    HV_mstPrEPSheetID: 5004,
    HV_mstStrucAndSocialEnablersSheetID: 5005,

    CS_mstFertilityRisksSheetID: 5006,
    CS_mstSingleStuntWastSheetID: 5007,
    CS_mstStuntWastSheetID: 5008,
    CS_mstEfficacySheetID: 5009,
    CS_mstIncidenceSheetID: 5010,
    CS_mstPercExposedFalciparumSheetID: 5011,
    CS_mstSevereAnemiaSheetID: 5012,
    CS_mstSingleStuntingDistrSheetID: 5013,
    CS_mstSingleWastingDistrSheetID: 5014,
    CS_mstStuntingDistrSheetID: 5015,
    CS_mstWastingDistrSheetID: 5016,

    //These master IDs need to be different than those in DP and FP on desktop because there is no distinction between
    //LiST and DP/FP editors in LOL and the values assigned to them are already used by LiST editors.
    DP_MstFirstYearPopSheetID: 121,
    FP_MstTFRSheetID: 122,

    //Effectiveness editor constants
    CS_MstEfficacyMaternal: 0,
    CS_MstEfficacyStillbirth: 1,
    CS_MstEfficacyNeonatal: 2,
    CS_MstEfficacyPostNeonatal: 3,

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////                                                                                                          /////
    /////    Results - the following constants should be used for results, do not use the ones above               /////
    /////                                                                                                          /////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // DemProj results
    DP_MstcmTotalPop: 24,
    DP_MstcmPop04: 27,
    DP_MstcmDispTFR: 41,
    DP_MstcmTotalLE: 10, //42,
    DP_MstcmBirths: 22,
    DP_MstcmDeaths: 23,
    DP_MstcmIMR: 11,

    // AIM results
    DP_MstcmNumHIV: 58,
    DP_MstcmNumNewInf: 60,
    DP_MstcmAIDSDeaths: 62,
    DP_MstcmChNeedPMTCT: 119,
    DP_MstcmChRecPMTCT: 120,
    DP_Mstcm1To4HIVPop: 130,

    // FamPlan results
    FP_MstcmPrevalence: 2,
    FP_MstcmUsers: 6,
    FP_MstcmAbortions: 15,
    FP_MstcmPregnancies: 16,
    FP_MstcmTF: 17,
    FP_MstcmIllAbortions: 28,
    FP_MstcmNumUnintendPreg: 44,

    // LiST result sectors
    CS_NeonatalSec: 1,
    CS_ChildSec: 2,
    CS_MaternalSec: 3,
    CS_StillbirthSec: 4,
    CS_MortRateSec: 5,
    CS_NutritionSec: 6,
    CS_EtiologySec: 7,
    CS_ToolSec: 8,
    CS_AltFormInputsSec: 9,
    CS_MaxResultSectors: 9,

    // LiST results
    CS_MstNNDeaths: 1,
    CS_MstNNDeathsPrev: 2,
    CS_MstNNDeathsByCause: 3,
    CS_MstNNDeathsPrByCause: 4,
    CS_MstNNDeathsPrByInter: 5,
    CS_MstChildDeathsByYearAgeGroup: 6,
    CS_MstChildDeaths: 7,
    CS_MstChDeathsPrev: 8,
    CS_MstChDeathsByCause: 9,
    CS_MstChDeathsPrByCause: 10,
    CS_MstChDeathsPrByInter: 11,
    CS_MstVaccineCoverage: 12,
    CS_MstDAvtdByVaccByCohort: 13,
    CS_MstMDeaths: 14,
    CS_MstMDeathsPrev: 15,
    CS_MstMDeathsByCause: 16,
    CS_MstMDeathsPrByCause: 17,
    CS_MstMDeathsPrByInter: 18,
    CS_MstAbortion: 19,
    CS_MstSB: 20,
    CS_MstSBPrev: 21,
    CS_MstSBByCause: 22,
    CS_MstSBPrByCause: 23,
    CS_MstSBPrByInter: 24,
    CS_MstSBRate: 25,
    CS_MstMMortalityRatio: 26,
    CS_MstNNMortalityRate: 27,
    CS_MstIMortalityRate: 28,
    CS_MstU5MortalityRate: 29,
    CS_MstMortalityRates: 30,
    CS_MstRedMortByInterv: 31,
    CS_MstPercentWasted: 32, //replaced
    CS_MstPercentStunted: 33, //replaced
    CS_MstAvgHeightLength: 34,
    CS_MstBFPrevalence: 35,
    CS_MstDiarIncidence: 36,
    CS_MstIUGRResult: 37,
    CS_MstWriteCostFile: 38,
    CS_MstChImpStuntByInterBY: 39,
    CS_MstChImpStuntByInterIY: 40,
    CS_MstTotalNumCases: 41,
    CS_MstAdditionalNumCases: 42,
    CS_MstDrugSuppCostsInd: 43, // replaced
    CS_MstLaborCostsInd: 44,
    CS_MstDrugSuppQuantInd: 45,
    CS_MstPersonnelTime: 46,
    CS_MstNumOutpVisits: 47,
    CS_MstNumInpatDays: 48,
    CS_MstTotalIntervCosts: 49,
    CS_MstNNDeathsPrByInterByCause: 50,
    CS_MstChDeathsPrByInterByCause: 51,
    CS_MstMDeathsPrByInterByCause: 52,
    CS_MstSBPrByInterByCause: 53,
    CS_MstDAvtdByVaccByCohortByCause: 54,
    CS_MstOtherDirectCosts: 55,
    CS_MstIndirectCosts: 56,
    CS_MstDrugsInd: 57, // replaced with aggregate
    CS_MstSuppliesInd: 58, // replaced with aggregate
    CS_MstDrugCostsInd: 59, // replaced with aggregate
    CS_MstSupplyCostsInd: 60, // replaced with aggregate
    CS_MstPerc1t59MoDiarCasesByPath: 61,
    CS_MstPerc1t59MoDiarDeathsByPath: 62,
    CS_MstMMortalityRate: 63,
    CS_MstPerc1t59MoPneumCasesByPath: 64,
    CS_MstPerc1t59MoPneumDeathsByPath: 65,
    CS_MstPneumIncidence: 66,
    CS_MstNumU5DiarCases: 67,
    CS_MstNumU5PneumCases: 68,
    CS_MstBirthTermResults: 69,
    CS_MstPercInVarHtForAgeStunt: 70,
    CS_MstPercInVarWtForHtWaste: 71,
    CS_MstPercPregWomenAnemicResult: 72,
    CS_MstNumU5DiarCasesAvtd: 73,
    CS_MstNumU5PneumCasesAvtd: 74,
    CS_MstNumU5MeningCasesAvtd: 75,
    CS_MstChDeathsByCauseByBirthCoh: 76,
    CS_MstEtiNumU5CasesByCauseBirthCoh: 77,
    CS_MstEtiNum1t59MoCasesAvtdByCauseYr1stDose: 78,
    CS_MstEtiNum1t59MoDeathsByPathBirthCoh: 79,
    CS_MstEtiNum1t59MoCasesByPathBirthCoh: 80,
    CS_MstEtiNumPersVaccByInterv: 81,
    CS_MstPercPregWomenAnemic: 82,
    CS_MstEtiIncRates: 83,
    CS_MstEtiNumU5CasesByCause: 84,
    CS_MstEtiNumU5CasesAvtdByCause: 85,
    CS_MstEtiPerc1t59MoDeathsByPath: 86,
    CS_MstEtiPerc1t59MoCasesByPath: 87,
    CS_MstExCoverageIND: 88,
    CS_MstSourcesInd: 89,
    CS_MstMeningIncidence: 90,
    CS_MstNumU5MeningCases: 91,
    CS_MstPerc1t59MoMeningCasesByPath: 92,
    CS_MstPerc1t59MoMeningDeaths: 93,
    CS_MstNumU5DiarCasesByBirthCoh: 94,
    CS_MstNum1t59MoDiarCasesAvtdByYr1stDose: 95,
    CS_MstNum1t59MoDiarCasesByPathBirthCoh: 96,
    CS_MstNum1t59MoDiarDeathsByPathBirthCoh: 97,
    CS_MstNumU5PneumCasesByBirthCoh: 98,
    CS_MstNum1t59MoPneumCasesAvtdYr1stDose: 99,
    CS_MstNum1t59MoPneumCasesByPathBirthCoh: 100,
    CS_MstNum1t59MoPneumDeathsByPathBirthCoh: 101,
    CS_MstNumU5MeningCasesByBirthCoh: 102,
    CS_MstNum1t59MoMeningCasesAvtdByYr1stDose: 103,
    CS_MstNum1t59MoMeningCasesByPathBirthCoh: 104,
    CS_MstNum1t59MoMeningDeathsByPathBirthCoh: 105,
    CS_MstChDeathsByBirthCoh: 106,
    CS_MstEtiNum1t59MoDeathsByPathBirthCohVC: 107,
    CS_MstEtiNum1t59MoCasesByPathBirthCohVC: 108,
    CS_MstNum1t59MoDiarCasesByPathBirthCohVC: 109,
    CS_MstNum1t59MoDiarDeathsByPathBirthCohVC: 110,
    CS_MstNum1t59MoPneumCasesByPathBirthCohVC: 111,
    CS_MstNum1t59MoPneumDeathsByPathBirthCohVC: 112,
    CS_MstNum1t59MoMeningCasesByPathBirthCohVC: 113,
    CS_MstNum1t59MoMeningDeathsByPathBirthCohVC: 114,
    CS_MstAnemiaWRA: 115,
    CS_MstProgramCosts: 116,
    CS_MstTotalCosts: 117,
    CS_MstPercWomenAnemicResults: 118,
    CS_MstNumWomenAnemicResults: 119,
    CS_MstAnemiaCasesPreventedResults: 120,
    CS_MstPercExclusiveBF: 121,
    CS_MstPrevEarlyInitBF: 122,
    CS_MstGlobalStuntRate: 123,
    CS_MstGlobalWasteRate: 124,
    CS_MstAttrStuntReduct: 125,
    CS_MstNumStunted: 126,
    CS_MstNumStuntedAverted: 127,
    CS_MstNumStuntedAvertedByInter: 128,
    CS_MstEtiNumDeathsByPathBy1YrAgeGrps: 129,
    CS_MstEtiNumCasesByPathBy1YrAgeGrps: 130,
    CS_MstEtiNumPersVaccByIntervBy1YrAgeGrps: 131,
    CS_MstNumDiarCasesByPathBy1YrAgeGrps: 132,
    CS_MstNumDiarDeathsByPathBy1YrAgeGrps: 133,
    CS_MstNumPneumCasesByPathBy1YrAgeGrps: 134,
    CS_MstNumPneumDeathsByPathBy1YrAgeGrps: 135,
    CS_MstNumMeningCasesByPathBy1YrAgeGrps: 136,
    CS_MstNumMeningDeathsByPathBy1YrAgeGrps: 137,
    CS_MstNumSevrDiarCasesByPathBy1YrAgeGrps: 138,
    CS_MstCoverageInd: 139,
    CS_MstMatIntEfficacyInd: 140,
    CS_MstSBIntEfficacyInd: 141,
    CS_MstNNIntEfficacyInd: 142,
    CS_MstPNNIntEfficacyInd: 143,
    CS_MstIncReductionInd: 144,
    CS_MstVaccineEffInd: 145,
    CS_MstNutritionIntEfficacyInd: 146,
    CS_MstImpactUnderNutritionMortInd: 147,
    CS_MstLogisticsWastage: 148,
    CS_MstInfrastructureInvestment: 149,
    CS_MstOtherHlthSysCosts: 150,
    CS_MstInefficiencies: 151,
    CS_MstEtiNumDeathsByPath: 153,
    CS_MstEtiNumCasesByPath: 154,
    CS_MstLowBirthWeight: 155,
    CS_MstAdditionalYrsSchoolingGainedInd: 156,
    CS_MstLifetimeEarningsObtainedInd: 157,
    CS_MstAdolDeaths: 158,
    CS_MstAdolDeathsPrev: 159,
    CS_MstAdolDeathsByCause: 160,
    CS_MstAdolDeathsPrByCause: 161,
    CS_MstAdolDeathsPrByInter: 162,
    CS_MstAdolDeathsPrByInterByCause: 163,
    CS_MstPercIncEarnStuntDeclineInd: 164,
    CS_MstImpLifeEarnDueToStuntReducInd: 165,
    CS_MstSubOpBOAvertByInterInd: 166,
    CS_MaxResultMstIDs: 166,

    /* Default Constants */
    CS_Default: 1,
    CS_Data: 2,

    /* User results */
    CS_bUser: 1,
    /* Estimate Bounds */
    CS_bMin: 2,
    CS_bMax: 3,
    /* CSUA */
    CS_bLow: 4,
    CS_bMed: 5,
    CS_bHigh: 6,

    /* Age Constants */
    /* [Standard] age cohorts */
    CS_AgeSummary: 0,
    CS_Birth: 0,
    CS_0t1months: 1,
    CS_1t59months: 2,
    CS_1t6months: 2,
    CS_6t12months: 3,
    CS_12t24months: 4,
    CS_24t60months: 5,

    /* Additional age cohorts - for detailed vaccines */
    CS_24t36months: 6,
    CS_36t48months: 7,
    CS_48t60months: 8,

    /* Years */
    CS_0t1Yr: 0,
    CS_1t2Yr: 1,
    CS_2t3Yr: 2,
    CS_3t4Yr: 3,
    CS_4t5Yr: 4,
    CS_5t6Yr: 5,

    /* Configuration options */
    CS_AgeCohort: 0,
    CS_AgeYear: 1,

    /* Discount rate */
    CS_3Perc: 0,
    CS_5Perc: 1,
    CS_10Perc: 2,

    /* Nutritional Deficiencies */
    CS_VitADefic: 1,
    CS_ZincDefic: 2,
    CS_CalcDefic: 3,
    CS_FolDefic: 4,
    CS_IronDefic: 5,
    CS_VitB6Defic: 6,
    CS_VitB12Defic: 7,
    CS_VitCDefic: 8,
    CS_CoppDefic: 9,
    CS_MagDefic: 10,
    CS_NiaDefic: 11,
    CS_PhosDefic: 12,
    CS_RiboDefic: 13,
    CS_ThiaDefic: 14,

    CS_Efficacy: 1,
    CS_OddsRatio: 1,
    CS_RelRisk: 1,
    CS_AffecFract: 2,

    /* Vaccine doses */
    CS_VaccDoseZero: 0,
    CS_VaccDoseOne: 1,
    CS_VaccDoseTwo: 2,
    CS_VaccDoseThree: 3,
    CS_VaccBooster: 4,
    CS_MaxVaccDose: 4,

    /* Standard deviation Constants */
    CS_GT1STD: 1,
    CS_1t2STD: 2,
    CS_2t3STD: 3,
    CS_GT3STD: 4,

    /* Anemia among women of reproductive age (WRA) */
    CS_Preg_Anemia: 1,
    CS_NonPreg_Anemia: 2,
    CS_Preg_IronDeficAnemia: 3,
    CS_NonPreg_IronDeficAnemia: 4,
    CS_MaxMaternalAnemia: 4,

    CS_WRA_Anemia: 2, //used for results since the values displayed are for WRA, not just non-pregnant women
    CS_WRA_IronDeficAnemia: 4, //used for results since the values displayed are for WRA, not just non-pregnant women

    /* Under sized babies categories */
    CS_PreTermSGA: 1,
    CS_PreTermAGA: 2,
    CS_TermSGA: 3,
    CS_TermAGA: 4,

    /* Breastfeeding constants */
    CS_ExclusiveBF: 1,
    CS_PredominantBF: 2,
    CS_PartialBF: 3,
    CS_NotBF: 4,
    CS_MaxBFType: 4,

    /* Constants for distribution of pathogens among incident, fatal, and all cases. */
    CS_Distr_All: 1,
    CS_Distr_Sevr: 2,
    CS_Distr_Fatal: 3,

    /* Mortality rate constants */
    CS_NeonatalMR: 1,
    CS_InfantMR: 2,
    CS_U5MR: 3,
    CS_MaxBaselineMort: 3,

    /* Percent of child deaths by proximate causes constants */
    CS_Neonatal: 1,
    CS_PostNN: 2,

    /* Fert-brth outcomes RR's */
    /* Age and Birth Order */
    /* Mother's Age */
    CS_AllYrs: 1,
    CS_LT18Yrs: 2,
    CS_18_34Yrs: 3,
    CS_35_49Yrs: 4,

    /* Birth order of child */
    CS_FirstBirth: 1,
    CS_SecAndThirdBirths: 2,
    CS_GTThirdBirth: 3,

    /* Birth Intervals */
    //CS_FirstBirth                             : 1  // use CS_FirstBirth from above
    CS_LT18Mths: 2,
    CS_18to23Mths: 3,
    CS_GE24Mths: 4,

    /* Maternal nutrition */
    CS_IPTp: 1,
    CS_FirstMatNutrIV: 1,
    CS_BalEnergy: 2,
    CS_IronFolateSupp: 3, // ingrid wanted this swapped with CS_MultMicro
    CS_MultMicroLowBMI: 4,
    CS_MultMicroNormBMI: 5,
    CS_CalciumSupp: 6,
    CS_FolicAcidFort: 7,
    CS_ZincFort: 8,
    CS_SyphilisTreat: 9,
    CS_ZincSuppInPreg: 10,
    CS_TreatOfBacteriuria: 11,
    CS_Omeg3FASupp: 12,
    CS_AspirinLowDose: 13,
    CS_CervicalStitch: 14,
    CS_StopSmokingEduc: 15,
    CS_SpareBOInterv1: 16,
    CS_SpareBOInterv2: 17,
    CS_SpareBOInterv3: 18,
    CS_SpareBOInterv4: 19,
    CS_MaternalNutrDevModeEditorMax: 19,
    CS_ITN: 20,
    CS_MaternalNutrMax: 20,
    CS_AgeAndBirthOrder: 21, // add-ons for arrays in CSproj
    CS_BirthInterval: 22, // add-ons for arrays in CSproj
    CS_MaxMatNutrIV: 22,

    //Categories for Causes of death
    CS_MstNoCODCat: 0,
    CS_MstMaternalCODCat: 1,
    CS_MstStillbirthCODCat: 2,
    CS_MstNeonatalCODCat: 3,
    CS_MstChildCODCat: 4,

    //current IDs for input Modvars
    // maternal causes of death
    CS_Mat_MinDeathCauses: 1,
    CS_Mat_AntepartHemorr: 1,
    CS_Mat_IntrapartHemorr: 2,
    CS_Mat_PostpartHemorr: 3,
    CS_Mat_HypertensiveDis: 4,
    CS_Mat_Sepsis: 5,
    CS_Mat_Abortion: 6,
    CS_Mat_Embolism: 7,
    CS_Mat_OtherDirectCauses: 8,
    CS_Mat_IndirectCauses: 9,
    CS_Mat_MaxDeathCauses: 9,

    CS_Mat_NotAnemic: 1,
    CS_Mat_Anemic: 2,

    /* Impacts on maternal anemia constants */
    CS_IronFolatePW_MatAnemia: 1,
    CS_MMicroSuppPW_MatAnemia: 2,
    CS_IronFortNPW_MatAnemia: 3,
    CS_IPTP_MatAnemia: 4,
    CS_ITN_MatAnemia: 5,
    CS_MAX_MatAnemia: 5,

    CS_Preg: 1,
    CS_NonPreg: 2,

    // stillborth causes of death
    CS_SB_FirstSBCause: 1,
    CS_SB_Antepartum: 1,
    CS_SB_Intrapartum: 2,
    CS_SB_MaxCauses: 2,

    // Abortions
    CS_TotalAbort: 1,
    CS_UnsafeAbort: 2,
    CS_SafeAbort: 3,

    // Percent deaths by cause constants for children
    CS_DxC_Default: 1,
    CS_DxC_Normalized: 2,
    CS_DxC_NoAIDS: 3,

    /* Causes of Death (CoD) Constants */
    /* Do not change order without adjusting PercReductMortStunt,                 */
    /* percReductMortWaste, CorrectedAF in CSproj                                 */
    /* Must also change CS_TRelRisksStunting (in CSDefs) and all associated loops */
    CS_FirstNNDeath: 1, //CONSTANTS.CS_NNDiarr,
    CS_NNDiarr: 1,
    CS_NNSepsis: 2,
    CS_NNPneumonia: 3,
    CS_NNAsphyxia: 4,
    CS_NNPreterm: 5,
    CS_NNTetanus: 6,
    CS_NNCongen: 7,
    CS_NNOther: 8,
    CS_FinalStdNNDeath: 8, //CONSTANTS.CS_NNOther,
    CS_CustomNNCoD1: 9,
    CS_FirstCustNNDeath: 9, //CONSTANTS.CS_CustomNNCoD1,
    CS_CustomNNCoD2: 10,
    CS_CustomNNCoD3: 11,
    CS_CustomNNCoD4: 12,
    CS_FinalCustNNDeath: 12, //CONSTANTS.CS_CustomNNCoD4,
    CS_FinalNNDeath: 12, //CONSTANTS.CS_CustomNNCoD4,
    CS_FirstPNNDeath: 13, //CONSTANTS.CS_Diarrhea,
    CS_Diarrhea: 13,
    CS_Pneumonia: 14,
    CS_Meningitis: 15,
    CS_Measles: 16,
    CS_Malaria: 17,
    CS_Pertussis: 18,
    CS_AIDS: 19,
    CS_Injury: 20,
    CS_OtherInfecDis: 21,
    CS_OtherNCD: 22,
    CS_FinalStdPNNDeath: 22, //CONSTANTS.CS_Other,
    CS_MaxDefDeathCauses: 22, //CONSTANTS.CS_Other,
    CS_CustomPNNCoD1: 23,
    CS_FirstCustPNNDeath: 23, //CONSTANTS.CS_CustomPNNCoD1,
    CS_CustomPNNCoD2: 24,
    CS_CustomPNNCoD3: 25,
    CS_CustomPNNCoD4: 26,
    CS_FinalCustPNNDeath: 26, //CONSTANTS.CS_CustomPNNCoD4,
    CS_MaxDeathCauses: 26,
    CS_FinalPostNNDeath: 26, //CONSTANTS.CS_MaxDeathCauses,

    /* Other neonatal cause of death constants */

    CS_MaxNumStdNNCoD: 8, //CONSTANTS.CS_FinalStdNNDeath - CONSTANTS.CS_FirstNNDeath + 1,
    CS_MaxNumCustNNCoD: 4, //CONSTANTS.CS_CustomNNCoD4 - CONSTANTS.CS_CustomNNCoD1 + 1,

    /* Other post neonatal cause of death constants */
    CS_MaxNumStdPNNCoD: 9, //CONSTANTS.CS_FinalStdPNNDeath - CONSTANTS.CS_FirstPNNDeath + 1,
    CS_MaxNumCustPNNCoD: 4, //CONSTANTS.CS_CustomPNNCoD4 - CONSTANTS.CS_CustomPNNCoD1 + 1,

    CS_NNDeathArray: [
        1, //CONSTANTS.CS_NNDiarr,
        2, //CONSTANTS.CS_NNSepsis,
        3, //CONSTANTS.CS_NNPneumonia,
        4, //CONSTANTS.CS_NNAsphyxia,
        5, //CONSTANTS.CS_NNPreterm,
        6, //CONSTANTS.CS_NNTetanus,
        7, //CONSTANTS.CS_NNCongen,
        8, //CONSTANTS.CS_NNOther
    ],

    CS_NNDeathCustomArray: [
        9, //CONSTANTS.CS_CustomNNCoD1,
        10, //CONSTANTS.CS_CustomNNCoD2,
        11, //CONSTANTS.CS_CustomNNCoD3,
        12, //CONSTANTS.CS_CustomNNCoD4
    ],

    CS_PNNDeathArray: [
        13, //CONSTANTS.CS_Diarrhea,
        14, //CONSTANTS.CS_Pneumonia,
        15, //CONSTANTS.CS_Meningitis,
        16, //CONSTANTS.CS_Measles,
        17, //CONSTANTS.CS_Malaria,
        18, //CONSTANTS.CS_Pertussis,
        19, //CONSTANTS.CS_AIDS,
        20, //CONSTANTS.CS_Injury,
        21, //CONSTANTS.CS_Other
    ],

    CS_PNNDeathCustomArray: [
        22, //CONSTANTS.CS_CustomPNNCoD1,
        23, //CONSTANTS.CS_CustomPNNCoD2,
        24, //CONSTANTS.CS_CustomPNNCoD3,
        25, //CONSTANTS.CS_CustomPNNCoD4
    ],

    /* need constants for the GBListBoxFm to determine which COD is being modified.
    can't use the constants for PNN and NN because their value could change and
    then old projections wouldn't work. we could use the master IDs, but this just
    seems to be simpler to do and less confusing. in addition it allows us to use
    just one ModVar for both PNN and NN since the parameter showing which COD number
    is being used in the get/set will be the same regardless of whether it is PNN or NN*/
    CS_CustomCoD1: 1,
    CS_CustomCoD2: 2,
    CS_CustomCoD3: 3,
    CS_CustomCoD4: 4,
    CS_MaxNumCustCoD: 4,

    CS_CustomCoDNN: 1,
    CS_CustomCoDPNN: 2,

    CS_Adol_HIV: 1,
    CS_Adol_FirstCause: 1,
    CS_Adol_Diarrhoeal: 2,
    CS_Adol_Measles: 3,
    CS_Adol_Malaria: 4,
    CS_Adol_LRI: 5,
    CS_Adol_TB: 6,
    CS_Adol_Maternal: 7,
    CS_Adol_OtherCMPN: 8,
    CS_Adol_Congenital: 9,
    CS_Adol_Neoplasm: 10,
    CS_Adol_Cardiovascular: 11,
    CS_Adol_Digestive: 12,
    CS_Adol_OtherNCD: 13,
    CS_Adol_RTI: 14,
    CS_Adol_Drowning: 15,
    CS_Adol_NatDis: 16,
    CS_Adol_InterpVio: 17,
    CS_Adol_CollectVio: 18,
    CS_Adol_SelfHarm: 19,
    CS_Adol_OtherInj: 20,
    CS_Adol_MaxCauses: 20,

    /* Diarrheal incidence reduction interventions. Each one of these corresponds
       with an intervention from the IV module. Also used for severe diarrhea
       cases averted. */
    CS_ImprovedExcretaDisp_DiarInterv: 1, // CS_IV_ImprovedSanitation
    CS_FirstDiarInterv: 1,
    CS_ImpWaterSource_DiarInterv: 2, // CS_IV_ImpWaterSource
    CS_WaterConnectionHome_DiarInterv: 3, // CS_IV_WaterConnectionHome
    CS_HandWashWSoap_DiarInterv: 4, // CS_IV_HandWashWSoap
    CS_HygDisposalStools_DiarInterv: 5, // CS_IV_HygDisposalStools
    CS_ZincP_DiarInterv: 6, // CS_IV_ZincP
    CS_VitAPTwoDoses_DiarInterv: 7, // CS_IV_VitASupp
    CS_RotavirusVacc_DiarInterv: 8, // CS_IV_RotavirusVacc
    CS_FirstVacc_DiarInterv: 8,
    CS_DiarVaccPathB_DiarInterv: 9, // CS_IV_DiarVaccPathB
    CS_DiarVaccPathC_DiarInterv: 10, // CS_IV_DiarVaccPathC
    CS_BFPromotion_DiarInterv: 11, // CS_IV_ChangesInBF
    CS_NumDiarIntervs: 11,

    /* Severe diarrheal incidence reduction interventions. Each one of these
       corresponds with an intervention from the IV module. Used by the
       Effectiveness of interventions on incidence --> Impacts on diarrhea
       incidence editor only.*/
    CS_RotavirusVacc_SevDiarInterv: 1,

    /* Pneumonia incidence reduction interventions. Each one of these corresponds
    with an intervention from the IV module. */
    CS_HibVacc_PneumInterv: 1, // CS_IV_HibVacc
    CS_FirstPneumInterv: 1,
    CS_PneumVacc_PneumInterv: 2, // CS_IV_PneumVacc
    CS_ZincP_PneumInterv: 3, // CS_IV_ZincP
    CS_NumPneumIntervs: 3,

    /* Meningitis incidence reduction interventions. Each one of these corresponds
       with an intervention from the IV module. */
    CS_HibVacc_MeningInterv: 1, // CS_IV_HibVacc
    CS_FirstMeningInterv: 1,
    CS_PneumVacc_MeningInterv: 2, // CS_IV_PneumVacc
    CS_MeningA_MeningInterv: 3, // CS_IV_MeningA
    CS_NumMeningIntervs: 3,

    /* Reduction in mortality by intervention display constants */
    CS_CovIncIntervPrgm: 1,
    CS_PercRedU5MortDueInterv: 2,
    CS_PercU5MortRedAttrInterv: 3,

    /* Impact of IUGR/Low birth weight on stunting  */
    CS_ImpactsOnStuntingFirst: 1,
    CS_LowBirthWeight_TermAGA: 1,
    CS_LowBirthWeight_TermSGA: 2,
    CS_LowBirthWeight_PreTermAGA: 3,
    CS_LowBirthWeight_PreTermSGA: 4,

    /* Impact of previous stunting on stunting */
    //Swapped these two so positive would be first.
    CS_NotStuntedPrevAge: 5,
    CS_StuntedPrevAge: 6,

    /* Impact of complementary feeding on stunting
       These same constants are used for impact of compementary feeding on wasting
       as well even though there is a gap between the last constant for wasting,
       CS_ImpactsOnWaste_TreatMAM and CS_FoodSecureWPromo, this gap can be saved for
       future use do if the LiST folks decide that the other items impacting stunting
       also need to impact wasting */
    CS_FoodSecureWPromo: 7,
    CS_FoodSecureWOPromo: 8,
    CS_InsecureWPromoSupp: 9,
    CS_InsecureWOPromoSupp: 10,

    /* Impact of diarrhea on stunting */
    CS_ImpDiarrPerEpisode: 11,

    /* Impact of zinc supplementation on stunting */
    CS_ZincSupp: 12,
    CS_NotZincSupp: 13,
    CS_ImpactsOnStuntingFinal: 14,

    /* Impacts on Wasting Editor constants */
    CS_ImpactsOnWaste_Therafeed: 1, //treatment for severe wasting
    CS_ImpactsOnWaste_FractionChildrenMovMildWastWithTreat: 2, //treatment for moderate wasting

    /* Custom intervention comment */

    /* note that 7-10 from above */

    CS_ImpactsOnWaste_FractionChildrenMovModWastWithTreat: 11,
    CS_ImpactsOnWaste_FractionChildrenMovSevWastWithTreat: 12,
    CS_ImpactsOnWaste_MAX: 12,

    /* Impacts on Wasting CSProj constants */
    CS_ImpactsOnWaste_All: 0,
    CS_ImpactsOnWaste_CompFeed: 3,
    CS_ImpactsOnWaste_Severe: 1,
    CS_ImpactsOnWaste_Moderate: 2,
    CS_ImpactsOnWaste_OverallReducSevereWaste: 0,
    CS_ImpactsOnWaste_OverallPercRecovRateModerateWaste: 1,

    /* Breastfeeding Promotion constants */
    CS_BFPromoHealthSystem: 1,
    CS_BFPromoHomeComm: 2,
    CS_BFPromoBoth: 3,

    CS_ImpactPromoBF: 1,
    CS_ImpactPromoEarlyBF: 2,

    CS_EarlyInitBF: 1,
    CS_LateInitBF: 2,

    CS_PromotedBF: 1,
    CS_NotPromotedBF: 2,
    CS_PromotionBF: 1,
    CS_KangarooBF: 2,

    CS_MinFirstYrAtWork: 15,
    CS_MaxFinalYrAtWork: 80,

    /* Diarrhea

       CS_AllPathsDiar is used to save data for all pathogens combined.
       If you don't need to save data for all pathogens combined, loop
       from CS_FirstPathDiar to CS_NumPathsDiar. Otherwise, loop from
       CS_FirstPathDiar to CS_MaxPathDiar. Likewise for pneumonia and
       meningitis. */
    CS_RotaPathDiar: 1,
    CS_FirstPathDiar: 1,
    CS_PathBDiar: 2,
    CS_PathCDiar: 3,
    CS_OtherPathsDiar: 4,
    CS_NumPathsDiar: 4,
    CS_AllPathsDiar: 5,
    CS_MaxPathDiar: 5,

    /* Pneumonia  */
    CS_HibPathPneumVT: 1,
    CS_FirstPathPneum: 1,
    CS_PneumocPathPneum: 2,
    CS_PneumocPathPneumVT: 3,
    CS_OtherPathsPneum: 4,
    CS_NumPathsPneum: 4,
    CS_AllPathsPneum: 5,
    CS_MaxPathPneum: 5,

    /* Meningitis */
    CS_HibPathMeninVT: 1,
    CS_FirstPathMenin: 1,
    CS_PneumocPathMenin: 2,
    CS_PneumocPathMeninVT: 3,
    CS_MeningitPathMenin: 4,
    CS_MeningitAPathMeninVT: 5,
    CS_OtherPathsMenin: 6,
    CS_NumPathsMenin: 6,
    CS_AllPathsMenin: 7,
    CS_MaxPathMenin: 7,

    //( Herd constants - change with great risk, it might mess up CSDataRW )...these SHOULD NOT BE CHANGED!!
    //(* There are 3 "constants" categories that need consideration: Detailed vaccines(below),
    //   Herd vaccines, Current ID from IVConst.Inc *)
    CS_FirstVacc_Herd: 1,
    CS_Rotavirus_Herd: 1,
    CS_Measles_Herd: 2,
    CS_Hib_Herd: 3,
    CS_Pneumococcal_Herd: 4,
    CS_MeningococcalA_Herd: 5,
    CS_DPT_Herd: 6,
    CS_Bednets_Herd: 7,
    CS_DiarVaccPathB_Herd: 8,
    CS_DiarVaccPathC_Herd: 9,
    CS_Malaria_Herd: 10,
    CS_VaccineD_Herd: 11,
    CS_LastVacc_Herd: 11,

    /* Detailed vaccines */
    /* There are 3 "constants" categories that need consideration: Detailed vaccines,
        Herd vaccines(above), Current ID from IVConst.Inc */
    CS_BCG_Det: 1, //Jared 5/23/17 changed these around a bit.
    CS_FirstVacc_Det: 1,
    CS_Polio_Det: 2,
    CS_DPT_Det: 3,
    CS_HiB_Det: 4,
    CS_HepB_Det: 5,
    CS_PCV_Det: 6,
    CS_Rota_Det: 7,
    CS_MenA_Det: 8,
    CS_Malaria_Det: 9,
    CS_Meas_Det: 10,
    CS_LastRegVacc_Det: 10,
    CS_VaccB_Det: 11,
    CS_VaccC_Det: 12,
    CS_VaccD_Det: 13,
    CS_LastVacc_Det: 13,

    CS_FirstProj: 0,

    GB_Bold: 0,
    GB_Italic: 1,
    GB_Underline: 2,

    GB_Left: 0,
    GB_Right: 1,
    GB_Center: 2,

    GB_OneEditorSrc: 0,
    GB_RowSrcEditor: 1,
    GB_ColSrcEditor: 2,
    GB_RowSecSrcEditor: 3,

    GB_ChildHealth: 1,
    GB_MaternalHealth: 2,
    GB_NutritionalStatus: 3,
    GB_Pathogens: 4,
    GB_ChildMortality: 5,
    GB_MaternalMortality: 6,
    GB_Abortion: 7,
    GB_Stillbirths: 8,
    GB_HouseholdStatus: 9,
    GB_Coverage: 10,
    GB_Effectiveness: 11,
    GB_ImpactsOnDiarrheaInc: 12,
    GB_ImpactsOnMeningInc: 13,
    GB_ImpactsOnPneumInc: 14,
    GB_HerdEffectiveness: 15,
    GB_NutritionInterventions: 16,
    GB_ImpactUnderNutrition: 17,
    GB_EconomicStatus: 18,

    BASIC_ResultPackTable: {
        ID: 0,
        GBRowCount: 1,
        GBColCount: 1,
        GBFixedRows: 1,
        GBFixedCols: 1,
        tableData: {
            value: [],
        },
        RDec: [],
        MergedCells: [],
        LockedCells: [],
        FontColors: [],
        GBColWidths: [],
        GBCellComment: [],
        GBUseTriangle: [],
        title: "",
        hasData: true,
        hasDeselectedIntervs: false,
        IntervRecords: [null],
        RowIds: [],
        MinAllowedVal: [],
        MaxAllowedVal: [],
        FontStyles: [],
        FontSizes: [],
        IndentRowText: [{ xoffset: 0, Value: false }],
        Alignments: [],
        yearArr: [],
        Source: [],
        SourceTitle: [],
        SourceMap: [],
        SourceMode: 1,
        EditorCat: 0,
        useHeadersInChartLbls: false,
        useSubHeadersInChartLbls: true,
    },

    BASIC_ResultPackChart: {
        title: "",
        alignTitle: "middle",
        subTitle: "",
        alignSubTitle: "middle",
        MultiSubTitles: ["", ""],
        chartType: "line",
        legendAlign: "center",
        ShowLegend: true,
        xAxisLabel: "",
        pointLabels: [],
        yAxisLabel: "",
        RYAxisLabel: "",
        ManualMinY: 0,
        ManualMaxY: 0,
        ManualStackedMinY: 0,
        ManualStackedMaxY: 0,
        chartData: [],
        subsetLabels: [],
        subsetColors: [],
        subsetChartTypes: [0],
        subsetPointTypes: [-1],
        subsetLineTypes: [-1],
        SubsetsToLegend: [0],
        hasData: true,
        hasDeselectedIntervs: false,
        RDec: 0,
        verticalLabels: [],
        chartLabelLeftMargin: 75,
    },

    /////
    ///// IV INTERVENTIONS
    /////

    /* Intervention Master ID's from IVModData.xlsx (***Cannot change!!!***) */
    /* Periconceptual */
    IV_ContraceptiveUse: 47,
    IV_FolicAcidSupp: 7,
    IV_BlanketIronSuppFort: 446,
    IV_ZincFort: 534,

    /* Pregnancy */
    IV_ANC1: 504,
    IV_ANC4: 37,
    IV_IPT_Mal: 3,
    IV_SyphDetectTr: 1,
    IV_CalciumSupp: 72,
    IV_ZincSuppInPreg: 548,
    IV_TreatOfBacteriuria: 532,
    IV_Omeg3FASupp: 533,
    IV_AspirinLowDose: 529,
    IV_CervicalStitch: 530,
    IV_StopSmokingEduc: 531,
    IV_SpareBOInterv1: 536,
    IV_SpareBOInterv2: 537,
    IV_SpareBOInterv3: 538,
    IV_SpareBOInterv4: 539,
    IV_IronSuppPreg: 235,
    IV_MultiMicronSuppPreg: 9,
    IV_BalEnergySupp: 8,
    IV_MalCaseMgmt: 172,

    IV_PMTCT: 14,
    IV_AgeAndBirthOrder: 233,
    IV_BirthIntervals: 234,

    /* Childbirth */
    IV_HealthFacDelivery: 10,
    IV_CleanCordCare: 255,

    /* Breastfeeding */
    IV_ChangesInBF: 17,

    /* Preventive */
    IV_ImpWaterSource: 40,
    IV_ImprovedSanitation: 42,
    IV_HandWashWSoap: 43,
    IV_HygDisposalStools: 44,
    IV_NNVitASupp: 540,
    IV_VitASupp: 46,
    IV_ZincSupp: 24,
    IV_WaterConnectionHome: 41,
    IV_ITN_IRS: 22,
    IV_CompFeedPrevStunting: 86,
    IV_CompFeedPrevWasting: 482,
    IV_ApprCompFeed: 489,

    /* Vaccines */
    IV_BCG_Vacc: 49,
    IV_PolioVacc: 34,
    IV_PentaVacc: 175,
    IV_DPT_Vacc: 33,
    IV_HibVacc: 25,
    IV_HepBVacc: 167,
    IV_PneuVacc: 26,
    IV_RotavirusVacc: 45,
    IV_MeningA: 191,
    IV_MalVacc: 251,
    IV_MeasVacc: 21,
    IV_DiarVaccPathB: 192,
    IV_DiarVaccPathC: 193,
    IV_VaccineD: 252,

    /* Curative */
    IV_KangarooMothCare: 16,
    IV_FullSuppCarePremat: 322,
    IV_OralAntibNeonSeps: 69,
    IV_InjectAntibNeonSeps: 70,
    IV_FullSuppCareNeonSepsPneu: 13,
    IV_Cotrimoxazole: 35,
    IV_ART: 36,

    //

    IV_MstSAM: 71,
    IV_MstTrMAM: 237,
    IV_MstChangesInBF_BFPromo: 17,

    IV_MstMicronSupp: 254,
    IV_MstIronSuppPreg: 235,
    IV_MstMultiMicronSuppPreg: 9,

    IV_MstPentaVacc: 175,
    IV_MstDPT_Vacc: 33,
    IV_MstHibVacc: 25,
    IV_MstHepBVacc: 167,

    IV_MstCaseMgmtPrematBabies: 321,
    IV_MstKangarooMothCare: 16,
    IV_MstFullSuppCarePremat: 322,

    IV_MstCaseMgmtNeonSepsPneu: 75,
    IV_MstOralAntibNeonSeps: 69,
    IV_MstInjectAntibNeonSeps: 70,
    IV_MstFullSuppCareNeonSepsPneu: 13,

    //

    IV_ChangeStuntingPrev: 39,
    IV_ChangeWastingPrev: 195,

    /* Breastfeeding prevalence */
    CS_MstBFExclusive_0t1M: 10000,
    CS_MstBFPredominant_0t1M: 10001,
    CS_MstBFPartial_0t1M: 10002,
    CS_MstBFNot_0t1M: 10003,
    CS_MstBFExclusive_1t6M: 10004,
    CS_MstBFPredominant_1t6M: 10005,
    CS_MstBFPartial_1t6M: 10006,
    CS_MstBFNot_1t6M: 10007,
    CS_MstBFAny_6t12M: 10008,
    CS_MstBFNot_6t12M: 10009,
    CS_MstBFAny_12t24M: 10010,
    CS_MstBFNot_12t24M: 10011,
    CS_MstEarlyInitBF: 10012,
    CS_MstBFPromo: 10013,

    /* Stunting */
    CS_MstStunt_GT1Std_LT1M: 10500,
    CS_MstStunt_1t2Std_LT1M: 10501,
    CS_MstStunt_2t3Std_LT1M: 10502,
    CS_MstStunt_GT3Std_LT1M: 10503,
    CS_MstStunt_GT1Std_1t5M: 10504,
    CS_MstStunt_1t2Std_1t5M: 10505,
    CS_MstStunt_2t3Std_1t5M: 10506,
    CS_MstStunt_GT3Std_1t5M: 10507,
    CS_MstStunt_GT1Std_6t11M: 10508,
    CS_MstStunt_1t2Std_6t11M: 10509,
    CS_MstStunt_2t3Std_6t11M: 10510,
    CS_MstStunt_GT3Std_6t11M: 10511,
    CS_MstStunt_GT1Std_12t23M: 10512,
    CS_MstStunt_1t2Std_12t23M: 10513,
    CS_MstStunt_2t3Std_12t23M: 10514,
    CS_MstStunt_GT3Std_12t23M: 10515,
    CS_MstStunt_GT1Std_24t59M: 10516,
    CS_MstStunt_1t2Std_24t59M: 10517,
    CS_MstStunt_2t3Std_24t59M: 10518,
    CS_MstStunt_GT3Std_24t59M: 10519,
    CS_MstStunt_GT2StdSingle: 10520,

    /* Wasting */
    CS_MstWast_GT1Std_LT1M: 11000,
    CS_MstWast_1t2Std_LT1M: 11001,
    CS_MstWast_2t3Std_LT1M: 11002,
    CS_MstWast_GT3Std_LT1M: 11003,
    CS_MstWast_GT1Std_1t5M: 11004,
    CS_MstWast_1t2Std_1t5M: 11005,
    CS_MstWast_2t3Std_1t5M: 11006,
    CS_MstWast_GT3Std_1t5M: 11007,
    CS_MstWast_GT1Std_6t11M: 11008,
    CS_MstWast_1t2Std_6t11M: 11009,
    CS_MstWast_2t3Std_6t11M: 11010,
    CS_MstWast_GT3Std_6t11M: 11011,
    CS_MstWast_GT1Std_12t23M: 11012,
    CS_MstWast_1t2Std_12t23M: 11013,
    CS_MstWast_2t3Std_12t23M: 11014,
    CS_MstWast_GT3Std_12t23M: 11015,
    CS_MstWast_GT1Std_24t59M: 11016,
    CS_MstWast_1t2Std_24t59M: 11017,
    CS_MstWast_2t3Std_24t59M: 11018,
    CS_MstWast_GT3Std_24t59M: 11019,
    CS_MstWast_GT2StdSingle: 11020,

    /* FamPlan CPR Prevalence */
    CS_MstCPR: 12500,

    /* Fertility risks */
    CS_MstLessThn18YrsFirstBirth: 12557,
    CS_MstLessThn18YrsSecondAndThirdBirths: 12558,
    CS_MstLessThn18YrsGrThanThirdBirth: 12559,
    CS_Mst18t34YrsFirstBirth: 12560,
    CS_Mst18t34YrsSecondAndThirdBirths: 12561,
    CS_Mst18t34YrsGrThanThirdBirth: 12562,
    CS_Mst35t49YrsFirstBirth: 12563,
    CS_Mst35t49YrsSecondAndThirdBirths: 12564,
    CS_Mst35t49YrsGrThanThirdBirth: 12565,
    CS_MstFirstBirth: 12566,
    CS_MstLessThan18Mnths: 12567,
    CS_Mst18to23Mnths: 12568,
    CS_Mst24MnthsOrGr: 12569,

    /* FamPlan Adolescence MasterIDs */
    CS_MstFamPlanInt: 13599,
    CS_MstPercMarried: 13600,
    CS_MstPercUsingContra: 13601,
    CS_MstContraMethMix: 13602,
    CS_MstPercUnintendedPregTermByAbor: 13603,

    /* HIV Adolescence MasterIDs */
    CS_MstHIVInt: 13604,
    CS_MstPercUsingCondWNonMarContacts: 13605,
    CS_MstPercUsingPrEP: 13606,
    CS_MstPercOnART: 13607,
    CS_MstPercMalesCircum: 13608,
    CS_MstPercReceivCompSexEd: 13609,
    CS_MstPercFemalesReceivEconStrength: 13610,

    /* Age group constants */
    FP_Single_Age_Group: 1,
    FP_Five_Year_Age_Groups: 2,

    /* Method types */
    FP_Condom: 1,
    FP_Sterilization: 2,
    FP_Injectable: 3,
    FP_Implant: 4,
    FP_Pill: 5,
    FP_TraditionalMeth: 6,
    FP_IUD: 7,
    FP_FertAwareness: 8,
    FP_BarrierMethods: 9,
    FP_CustomMethods: 10,

    /* Method constants */
    FP_All_Methods: 0,
    /* condoms */
    FP_CondomFM: 1,
    FP_CondomML: 2,
    /* sterilization */
    FP_FSter: 3,
    FP_MSter: 4,

    /* vaginal barriers and tablets */
    FP_Vag_Barrier: 5,
    FP_VFT: 6,

    /* traditional contraceptive methods */
    FP_Withdrawal: 7,
    FP_PerAbst: 8, //periodic abstinence
    FP_Traditional: 9,

    /* injectables */
    FP_Inj3month: 10,
    FP_Inj2month: 11,
    FP_Inj1month: 12,
    FP_Inj6month: 13,
    FP_Uninject: 14,

    /* implants */
    FP_Implanon: 15,
    FP_Jadelle: 16,
    FP_Sino_Implant: 17,

    /* pills */
    FP_StandardPill: 18,
    FP_Progestin: 19,
    FP_Peri_coital: 20,

    /* IUD */
    FP_IUDCopper: 21,
    FP_IUDLngIus: 22,

    FP_LAM: 23,
    FP_SDM: 24,
    FP_Other: 25,

    FP_LastDefMethod: 25,
    FP_FirstCustMethod: 26,
    FP_LastCustMethod: 37,
    FP_MstDefMeth: 31,
    FP_CustMethodMax: 12,
    FP_Max_Methods: 43, //FP_MstDefMeth+FP_CustMethodMax,
    FP_First_Method: 1,
    FP_Last_Method: 43, //FP_Max_Methods,

    /* Age constants */
    FP_All_Ages: 0,
    FP_A15_19: 1,
    FP_A20_24: 2,
    FP_A25_29: 3,
    FP_A30_34: 4,
    FP_A35_39: 5,
    FP_A40_44: 6,
    FP_A45_49: 7,
    FP_MAX_AGE: 7,

    /* Need constants */
    FP_all_need: 0,
    FP_spacing: 1,
    FP_limiting: 2,
    FP_max_need: 2,

    /* Pregnancy constants */
    FP_all_PREG: 0,
    FP_intended: 1, //FP_wanted   : 1,
    FP_unintended: 2, //FP_unwanted : 2,
    FP_mistimed: 3,
    FP_MAX_PREG: 3,

    /* Abortion constants */
    FP_specify_TAR: 1,
    FP_calculate_abortions: 2,

    /* Extract file constants */

    /* Standard format column and row constants */
    EX_FQNameCol: 0,
    EX_CountryNameCol: 1,
    EX_ISOCodeCol: 2,
    EX_SubnatRegNameCol: 3,
    EX_ModNameCol: 4,
    EX_IndNameCol: 5,
    EX_ConfigCol: 6,
    EX_FirstYearCol: 7,
    EX_NumStandardCols: 8,

    EX_VersionRow: 0,
    EX_VersionCol: 0,
    EX_HeadingsRow: 1,
};

export default CONSTANTS;
